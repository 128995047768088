import './utils.scss';
import {createMuiTheme} from '@material-ui/core/styles';

export const palette = {
  azure: {
    500: '#00b7f1',
  },
  indigo: {
    500: '#17406f',
    400: '#3f51b6',
  },
  blue: {
    500: '#2671ff',
    300: '#c9dbff',
    100: '#e9f0ff',
    50: '#F7FAFF',
  },
  black: {
    500: '#1f292e',
  },
  white: {
    500: '#ffffff',
  },
  red: {
    10: '#FAE7EB',
    50: '#ED9FAF',
    100: '#ED9FAF',
    200: '#FACBD5',
    300: '#ED9FAF',
    400: '#DE5773',
    500: '#D10F37',
  },
  orange: {
    200: '#FFEEDE',
    300: '#FFD0A5',
    500: '#eb6f07',
    400: '#FF9F30',
  },
  yellow: {
    200: '#FFE8B2',
    400: '#FFC700',
    500: '#F4B92D',
  },
  green: {
    50: '#dff7e4',
    100: '#c7e8ce',
    400: '#64E17B',
    500: '#41ca5a',
    600: '#0fc330',
    700: '#0eab2b',
  },
  gray: {
    600: '#27363E',
    500: '#3D4C59',
    400: '#8995A0',
    350: '#B5BBC3',
    300: '#D4D9E0',
    250: '#d8d8d8',
    200: '#E9E9F0',
    100: '#F4F7FB',
  },
  rose: {
    500: '#E91E63',
    400: '#F06292',
  },
  tomato: {
    500: '#FF5653',
    400: '#FF7777',
  },
  lime: {
    500: '#7CB342',
    400: '#AFD580',
  },
  mint: {
    600: '#1A896A',
    500: '#1AE395',
    100: '#CAF4E1',
  },
  eucaliptus: {
    500: '#00934E',
  },
  teal: {
    500: '#00BBD4',
    400: '#80DEEA',
  },
  lilach: {
    600: '#8C0DAF',
    500: '#C83CEE',
    400: '#D083E8',
  },
  purple: {
    600: '#6826AB',
    500: '#8947CD',
    200: '#E1D1F2',
  },
  brown: {
    500: '#8D5635',
    400: '#BF8563',
  },
  mango: {
    500: '#FF8F24',
  },
  navyblue: {
    500: '#1F2C9C',
  },
  eggplant: {
    500: '#800661',
  },
};

const slider = {
  red: {
    slider: palette.gray[250],
    thumb: palette.tomato[400],
    draggable: palette.white[500],
  },
};

const typography = {
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  h1: {
    fontSize: '30px',
    color: palette.black[500],
  },
  h2: {
    fontWeight: 300,
    fontSize: '22px',
    color: palette.gray[500],
  },
  h3: {
    // missing in design system
    fontSize: '20px',
    fontWeight: 500,
    color: palette.gray[600],
  },
  h4: {
    // missing in design system
    fontSize: '20px',
    fontWeight: 400,
    color: palette.gray[600],
  },
  h5: {
    fontWeight: 500,
    fontSize: '16px',
    color: palette.gray[500],
    lineHeight: '16px',
  },
  caption: {
    fontSize: '16px',
    color: palette.gray[500],
    lineHeight: '16px',
    letterSpacing: 'normal',
  },
  body1: {
    fontSize: '14px',
    color: palette.gray[500],
    lineHeight: '18px',
  },
  subtitle1: {
    // missing in design system
    fontSize: '14px',
    color: palette.gray[500],
    lineHeight: '14px',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '14px',
    color: palette.gray[400],
    lineHeight: '14px',
  },
  subtitle3: {
    fontWeight: 500,
    fontSize: '14px',
    color: palette.gray[500],
    lineHeight: '14px',
  },
  subtitle4: {
    fontWeight: 500,
    fontSize: '12px',
    color: palette.gray[500],
    lineHeight: '12px',
  },
  hintText: {
    // missing in design system
    fontWeight: 400,
    fontSize: '14px',
    color: palette.gray[400],
    lineHeight: '14px',
  },
};

export default createMuiTheme({
  palette,
  typography,
  slider,
  props: {
    MuiTypography: {
      variantMapping: {
        subtitle1: 'div',
        subtitle2: 'div',
      },
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backdropFilter: 'blur(3px)',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
    },
  },
  zIndex: {
    modal: 1,
  },
});
