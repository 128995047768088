// @flow
import React from 'react';
import {get, has} from 'lodash';
import TileStatElement from 'dashboards/components/TileStatElement';
import {STAT_TILES_VALUES} from 'dashboards/components/modals/statTile/StatTileSettings';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const TileStatTemplate = ({
  tileData,
  metrics,
  isLoading,
  isResize,
}: {
  tileData: Object,
  metrics: Array,
  isLoading: boolean,
  isResize: boolean,
}) => {
  const expressionTree = Object.values(tileData.andtGauge.expressionTrees)[0];
  const scalarTransformsType = get(expressionTree, 'scalarTransforms[0].function', 'current');
  const currentValue = get(metrics, `[0].scalarTransforms.${scalarTransformsType}.value`, 0);
  const expressionTreeHasChildren = has(expressionTree, 'expressionTree.root.children[0]');
  const isMultipleFunctions = has(expressionTree, 'expressionTree.root.children[0].children[0]');
  const nameMeasure = get(
    expressionTree,
    `expressionTree.root${
      expressionTreeHasChildren ? `${isMultipleFunctions ? '.children[0].children[0]' : '.children[0]'}` : ''
    }.searchObject.expression[0].value`,
    'N/A',
  );
  const displayProperties = get(tileData, 'andtGauge.displayProperties.byTreeExp[0].options.andtGauge', EMPTY_OBJECT);
  const tileType = get(displayProperties, 'mainValue.tileType', STAT_TILES_VALUES.NUMBER);
  const isAbbreviate = get(displayProperties, 'mainValue.abbreviate', false);
  const isDecimal = get(displayProperties, 'mainValue.displayDecimal', false);
  const isShowGraph = get(displayProperties, 'mainValue.showGraph', true);
  const isShowMeasure = get(displayProperties, 'mainValue.showMeasure', true);
  const unitText = get(displayProperties, 'mainValue.unitText', '');
  const inputNoData = get(displayProperties, 'mainValue.inputNoData', 'No Data');
  const minScale = get(displayProperties, 'mainValue.minScale');
  const maxScale = get(displayProperties, 'mainValue.maxScale');
  const isShowValueLabels = get(displayProperties, 'mainValue.isShowValueLabels', true);
  const thresholds = get(displayProperties, 'mainValue.thresholds', EMPTY_ARRAY);

  return (
    <TileStatElement
      id={tileData.id}
      tileType={tileType}
      currentValue={currentValue}
      isLoading={isLoading}
      isShowGraph={isShowGraph}
      title={tileData.title.text}
      nameMeasure={nameMeasure}
      isAbbreviate={isAbbreviate}
      isDecimal={isDecimal}
      isShowMeasure={isShowMeasure}
      unitText={unitText}
      isResize={isResize}
      inputNoData={inputNoData}
      metrics={metrics}
      minScale={minScale}
      maxScale={maxScale}
      isShowValueLabels={isShowValueLabels}
      thresholds={thresholds}
    />
  );
};

export default React.memo(TileStatTemplate);
