import React, {Fragment} from 'react';
import './OptionComponentCheckboxHeaders.module.scss';
import Tooltip, {TYPES} from '../../Tooltip';

type PropTypes = {
  data: Object,
  isSelected: boolean,
};

const OptionComponentCheckboxHeaders = (props: PropTypes) => {
  if (props.data.type === 'HEADER') {
    return (
      <div styleName="container-header" className="form-option">
        <div styleName="list-header" className="andt-dropdown-option-header">
          {props.data.label}
        </div>
      </div>
    );
  }
  return (
    <div styleName="container" className="form-option">
      {props.isSelected ? (
        <div styleName="ico" className="icon  icn-selection16-checkboxon" />
      ) : (
        <div styleName="ico" className="icon icn-selection16-checkboxoff" />
      )}
      {props.data.email ? (
        <Tooltip content={props.data.email} type={TYPES.SMALL}>
          <Fragment>
            <div styleName="label">{typeof props.data === 'string' ? props.data : props.data.label}</div>
            {props.data.count && <div styleName="group">{props.data.count}</div>}
          </Fragment>
        </Tooltip>
      ) : (
        <Fragment>
          <div styleName="label">{typeof props.data === 'string' ? props.data : props.data.label}</div>
          {props.data.count && <div styleName="group">{props.data.count}</div>}
        </Fragment>
      )}
    </div>
  );
};

export default OptionComponentCheckboxHeaders;
