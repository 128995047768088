import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {setRoute} from 'common/store/actions';
import * as api from 'admin.customers/services/api';
import * as actions from 'admin.customers/store/actions';

const fetchCustomers = makeAsyncEpic(actions.fetchCustomers, api.fetchCustomers);
const fetchEditCustomerData = makeAsyncEpic(actions.fetchEditCustomerData, api.fetchEditCustomerData);
const fetchCustomerDefaultSettings = makeAsyncEpic(
  actions.fetchCustomerDefaultSettings,
  api.fetchCustomerDefaultSettings,
);
const updateCustomer = makeAsyncEpic(actions.updateCustomer, api.updateCustomer);
const deleteCustomer = makeAsyncEpic(actions.deleteCustomer, api.deleteCustomer);
const createCustomer = makeAsyncEpic(actions.createCustomer, api.createCustomer);

const navigateBack = () => {
  const queryParams = window.location.hash.split('?')[1];
  return [setRoute(`/admin/customers${queryParams ? `?${queryParams}` : ''}`)];
};
const createOrUpdateSuccess = (action$) =>
  action$.ofType(actions.updateCustomer.success.TYPE, actions.createCustomer.success.TYPE).flatMap(() => {
    return navigateBack();
  });
const handleFailure = (action$) =>
  action$
    .ofType(actions.fetchEditCustomerData.failure.TYPE, actions.fetchCustomerDefaultSettings.failure.TYPE)
    .flatMap(() => {
      return navigateBack();
    });

const customersEpic = combineEpics(
  fetchCustomers,
  fetchEditCustomerData,
  fetchCustomerDefaultSettings,
  updateCustomer,
  deleteCustomer,
  createCustomer,
  createOrUpdateSuccess,
  handleFailure,
);

export default customersEpic;
