import React, {useCallback, useMemo} from 'react';
import {emptyExpressionTree} from 'metrics/store/reducers/expressionBuilderReducer';
import makeFirstSearchObjectCustomizer from 'common/utils/metrics/makeFirstSearchObjectCustomizer';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import ThreeDotsDropDown from 'common/componentsV2/ddl/ThreeDotsDropDown';
import {get, omit, cloneDeepWith} from 'lodash';
import * as selectors from 'profile/store/selectors';
import {success} from 'common/utils/notifications/notificationsService';

const MetricNameMenu = ({metric, onCopy}: {metric: Object, onCopy: Function}) => {
  const {
    location: {pathname},
  } = useHistory();
  const dispatch = useDispatch();
  const me = useSelector(selectors.getUserProfile);
  const isImpersonating = get(me, 'impersonatorId');

  const metricDebugInfo = useMemo(() => {
    const leanMe = omit(me, ['imageSrc', '__v', 'aptrinsicHmac', 'profiles', 'createdAt', 'appSettings', 'disabled']);
    if (me.organization) {
      leanMe.organization = omit(me, ['settings']);
    }

    return {
      env: {
        href: window.location.href,
        host: window.location.host,
      },
      userInfo: leanMe,
      metricInfo: omit(metric, ['baseline', 'dataPoints']),
    };
  }, [me, metric]);

  const handleChangeDashboardSettings = useCallback(
    (option) => {
      if (option.value === 'copyMetricAsText') {
        navigator.clipboard.writeText(metric.name);
        dispatch(success({description: 'Metric copied successfully'}));
        return;
      }
      if (option.value === 'copyInfo') {
        navigator.clipboard.writeText(JSON.stringify(metricDebugInfo));
        dispatch(success({description: 'Info copied successfully'}));
        return;
      }
      window.sessionStorage.setItem(
        'metricExplorerTree',
        JSON.stringify(
          cloneDeepWith(
            get(metric, 'compositeObject.expressionTree', emptyExpressionTree),
            makeFirstSearchObjectCustomizer(metric),
          ),
        ),
      );
      window.open('/#!/r/metrics-explorer');
    },
    [metric, onCopy, metricDebugInfo],
  );

  const options = useMemo(
    () => [
      ...(!pathname.includes('metrics-explorer')
        ? [
            {
              value: 'showInMetricExplorer',
              label: 'Show in Metric Explorer',
            },
          ]
        : []),
      {
        value: 'copyMetricAsText',
        label: 'Copy Metric as Text',
      },
      ...(isImpersonating
        ? [
            {
              value: 'copyInfo',
              label: 'copy info.',
            },
          ]
        : []),
    ],
    [pathname, isImpersonating],
  );

  return (
    <ThreeDotsDropDown
      options={options}
      onChange={handleChangeDashboardSettings}
      menuWidth={200}
      menuPortalTarget={document.body}
    />
  );
};

export default MetricNameMenu;
