import {createSelector} from 'reselect';
import {isCustomerAdmin, isAnodotAdmin, getOrganization, getOrganizationSettings} from 'profile/store/selectors';
import {getCommon} from 'common/store/selectors';
import get from 'lodash/get';

export const options = [
  {
    label: 'SAML',
    nameField: 'samlConfig',
  },
  {
    label: 'Google OAuth',
    nameField: 'googleOauthConfig',
  },
  {
    label: 'Azure Active Directory',
    nameField: 'azureAdConfig',
  },
];

export const getSidebarTabs = createSelector(
  isCustomerAdmin,
  isAnodotAdmin,
  (isCustomerAdminUser, isAnodotAdminUser) => [
    {
      id: 1,
      label: 'Details & Security',
      titleBlock: 'Profile Settings',
    },
    // {
    //   id: 2,
    //   label: 'Notification Settings',
    // },
    {
      id: 3,
      label: 'API Tokens',
      titleBlock: 'Account Settings',
      marginTopTitle: 11,
      isVisible: isAnodotAdminUser || isCustomerAdminUser,
    },
    {
      id: 4,
      label: 'Authentication',
      isVisible: isAnodotAdminUser || isCustomerAdminUser,
    },
  ],
);

export const getUserSettings = (state) => state.userSettings;

export const getSamlConfig = createSelector(
  getOrganizationSettings,
  (settings) => get(settings, ['auth', 'saml']),
);

export const getAzureAdConfig = createSelector(
  getOrganizationSettings,
  (settings) => get(settings, ['auth', 'azureAd']),
);

export const getOauthConfig = createSelector(
  getOrganizationSettings,
  (settings) => get(settings, ['auth', 'oauth']),
);

const getEnableSaml = createSelector(
  getSamlConfig,
  (samlConfig) => (get(samlConfig, 'enable') ? options[0].nameField : false),
);

const getEnableAzureAd = createSelector(
  getAzureAdConfig,
  (azureAdConfig) => (get(azureAdConfig, 'enable') ? options[2].nameField : false),
);

const getEnableOAuth = createSelector(
  getOauthConfig,
  (oAuthConfig) => (get(oAuthConfig, ['google', 'enable']) ? options[1].nameField : false),
);

export const getEnableRadio = createSelector(
  [getEnableSaml, getEnableAzureAd, getEnableOAuth],
  (saml, azure, oauth) => [saml, azure, oauth].filter((item) => Boolean(item))[0],
);

export const getVerificationId = createSelector(
  getOrganizationSettings,
  (settings) => get(settings, ['auth', 'verificationId']),
);

export const getOrganizationId = createSelector(
  getOrganization,
  (organization) => get(organization, '_id'),
);

export const getDomainsOauth = createSelector(
  getOauthConfig,
  (oAuthConfig) => get(oAuthConfig, ['google', 'domains'], []),
);

export const getJwtTtl = createSelector(
  getOrganizationSettings,
  (organizationSetting) => get(organizationSetting, ['accessTokens', 'jwtTtl']),
);

export const getApiTokens = createSelector(
  getOrganization,
  (organization) => get(organization, 'apiToken'),
);

export const getIsFetching = createSelector(
  getCommon,
  (common) => get(common, ['outstandingLoadingBarRequests', 'anodot/user.settings/UPDATE_SSO']),
);

export const getTokens = createSelector(
  getUserSettings,
  (userSettings) => get(userSettings, 'tokens'),
);

export const getPassword = createSelector(
  getUserSettings,
  (userSettings) => get(userSettings, 'password'),
);
