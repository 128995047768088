// @flow
import React, {useCallback} from 'react';
import SearchBox from 'common/componentsV2/SearchBox';
import {StringParam, useQueryParam} from 'use-query-params';

const SearchQueryFilter = () => {
  const [searchQuery, setSearchQuery] = useQueryParam('searchQuery', StringParam);
  const onFilter = useCallback(
    (val) => {
      setSearchQuery(val || undefined);
    },
    [setSearchQuery],
  );
  return <SearchBox onFilter={onFilter} filter={searchQuery} placeHolder="Search" />;
};

export default SearchQueryFilter;
