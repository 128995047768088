// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  fetchFacebookAdsAccounts as fetchFacebookAdsAccountsAction,
  fetchFacebookAdsReports as fetchFacebookAdsReportsAction,
  fetchFacebookAdsTemplates as fetchFacebookAdsTemplatesAction,
  setSelectedFacebookAdsTemplate as setSelectedFacebookAdsTemplateAction,
  setSelectedStreamKeyVal as setSelectedStreamKeyValAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import {isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import AccountsDropdown from './AccountsDropdown';
import ReportsDropdown from './ReportsDropdown';
import Templates from './Templates';
import './Templates.module.scss';

type PropTypes = {
  dataStream: Object,
  isLoadingAccounts: Boolean,
  accounts: Array,
  isLoadingReports: Boolean,
  reports: Array,
  isLoadingTemplates: Boolean,
  templates: Array,

  fetchFacebookAdsAccounts: Function,
  fetchFacebookAdsReports: Function,
  fetchFacebookAdsTemplates: Function,
  setSelectedStreamKeyVal: Function,
  setSelectedFacebookAdsTemplate: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isLoadingAccounts: selectors.getFacebookAdsAccounts(state).isLoading,
    accounts: selectors.getFacebookAdsAccountsItems(state),
    isLoadingReports: selectors.getFacebookAdsReports(state).isLoading,
    reports: selectors.getFacebookAdsReportTypes(state),
    isLoadingTemplates: selectors.getFacebookAdsTemplates(state).isLoading,
    templates: selectors.getFacebookAdsTemplatesData(state),
  }),
  {
    fetchFacebookAdsAccounts: fetchFacebookAdsAccountsAction,
    fetchFacebookAdsReports: fetchFacebookAdsReportsAction,
    fetchFacebookAdsTemplates: fetchFacebookAdsTemplatesAction,
    setSelectedStreamKeyVal: setSelectedStreamKeyValAction,
    setSelectedFacebookAdsTemplate: setSelectedFacebookAdsTemplateAction,
  },
)
export default class FacebookAdsData extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {dataSourceId} = this.props.dataStream;
    this.props.fetchFacebookAdsAccounts(dataSourceId);
    this.props.fetchFacebookAdsReports(dataSourceId);
    this.props.fetchFacebookAdsTemplates();
  }

  onAccountChange = (val) => {
    this.props.setSelectedStreamKeyVal({adAccountId: val.id});
  };

  onReportChange = (val) => {
    this.props.setSelectedStreamKeyVal({reportType: val.id});
  };

  onTemplateChange = (val) => {
    const selectedTemplate = this.props.templates.find((item) => item.id === val);
    this.props.setSelectedStreamKeyVal({basedOnTemplateId: val});
    this.props.setSelectedFacebookAdsTemplate({
      metrics: selectedTemplate.metrics,
      dimensions: selectedTemplate.dimensions,
    });
  };

  render() {
    const {
      dataStream,
      isLoadingAccounts,
      accounts,
      isLoadingReports,
      reports,
      isLoadingTemplates,
      templates,
    } = this.props;

    const selectedAccountIndex = accounts.findIndex((item) => dataStream.adAccountId === item.id);
    const selectedReportIndex = reports.findIndex((item) => dataStream.reportType === item.id);

    return (
      <div className="shell-col" styleName="root">
        <PanelHeader title="Select Account" isEditable={isStreamEditable(this.props.dataStream.state)} />

        <AccountsDropdown
          isLoading={isLoadingAccounts}
          items={accounts}
          selectedItem={accounts[selectedAccountIndex]}
          disabled={!isStreamEditable(dataStream.state) && !isStreamRunning(dataStream.state)}
          onChange={this.onAccountChange}
          ddlId="fbds_accounts_ddl"
          ddlTitle="Choose account"
          placeholder="Choose an account"
        />

        <PanelHeader title="Select Report Level" isEditable="true" />

        <ReportsDropdown
          isLoading={isLoadingReports}
          items={reports}
          selectedItem={reports[selectedReportIndex]}
          disabled={!isStreamEditable(dataStream.state) && !isStreamRunning(dataStream.state)}
          onChange={this.onReportChange}
          ddlId="fbds_reports_ddl"
          ddlTitle="Choose report level"
          placeholder="Choose report level"
        />

        <PanelHeader title="Select Template" isEditable="true" />

        <Templates
          isLoading={isLoadingTemplates}
          items={templates}
          onChange={this.onTemplateChange}
          selectedItem={dataStream.basedOnTemplateId || null}
        />
      </div>
    );
  }
}
