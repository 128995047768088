// @flow
import React from 'react';
import {segmentClickEvent} from 'common/store/actions';
import {connect} from 'react-redux';
import './FormDdlActionBar.module.scss';

type PropTypes = {
  isApplyDisabled?: boolean,
  isClearAllDisabled?: boolean,
  isVisible: boolean,
  onClearAll?: Function,
  onApply?: Function,
  className?: String,
  clearButtonText?: String,
  ApplyButtonText?: String,
  segmentClickEvent: Function,
};

@connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)
export default class FormDdlActionBar extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    isApplyDisabled: false,
    isClearAllDisabled: false,
    onClearAll: null,
    onApply: null,
    className: '',
    clearButtonText: 'Clear All',
    ApplyButtonText: 'Apply',
  };

  handleClearAll = () => {
    this.props.segmentClickEvent({
      type: 'click',
      name: 'clear all',
    });
    this.props.onClearAll();
  };

  handleApply = () => {
    this.props.segmentClickEvent({
      type: 'click',
      name: 'apply',
    });
    this.props.onApply();
  };

  render() {
    const {isApplyDisabled, isClearAllDisabled, isVisible, onClearAll, onApply, className} = this.props;
    const rootClass = className || '';
    const clearStyle = ['action-btn', onClearAll && onApply ? 'secondary' : '', isClearAllDisabled ? 'disabled' : ''];
    const applyStyle = ['action-btn', isApplyDisabled ? 'disabled' : ''];

    if (!isVisible) {
      return null;
    }

    return (
      <div styleName="root" className={rootClass}>
        <div styleName="action-bar">
          {onClearAll && (
            <div styleName={clearStyle.join(' ')}>
              <span role="presentation" onClick={this.handleClearAll}>
                {this.props.clearButtonText}
              </span>
            </div>
          )}
          {onApply && (
            <div styleName={applyStyle.join(' ')}>
              <span role="presentation" onClick={this.handleApply}>
                {this.props.ApplyButtonText}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
