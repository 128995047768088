// @flow
import React from 'react';
import {connect} from 'react-redux';
import {fetchCubes, fetchMetadata, setSelectedEditor as setSelectedEditorAction} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import {isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import {getEditorValidationMessage} from 'bc/services/gaService';
import PanelHeader from '../../../PanelHeader';
import GaTemplates from './GaTemplates';
import GaSegments from './GaSegments';

import './DimensionsAndMetrics.module.scss';

const List = ({title, list}: any) => (
  <div styleName="dm-list">
    <div styleName="title">{title}</div>
    <div styleName="scroll">
      {list.map((_item) => (
        <div key={_item.id}>{_item.label}</div>
      ))}
    </div>
  </div>
);

type PropTypes = {
  selectedDimensions: Array,
  selectedMetrics: Array,
  setSelectedEditor: Function,
  basedOnTemplate: string,
  dataStreamState: boolean,
  dataStream: Object,
  fetchMetadata: Function,
  fetchCubes: Function,
};

class DimensionsAndMetrics extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    this.props.fetchMetadata();
    this.props.fetchCubes();
  }

  render() {
    const {
      basedOnTemplate,
      selectedMetrics,
      selectedDimensions,
      dataStreamState,
      dataStream,
      setSelectedEditor,
    } = this.props;
    const validationMessages = getEditorValidationMessage(dataStream);
    const isEditable = isStreamEditable(dataStreamState) || isStreamRunning(dataStreamState);

    return (
      <div className="shell-col" automation-id="streamQueryMeasuresAndDimensions">
        <PanelHeader
          title="Measures & Dimensions"
          onSelect={() => setSelectedEditor({type: 'dm'})}
          isEditable={isEditable}
        />
        {validationMessages && (
          <div styleName="errors-container">
            <div styleName="error">{validationMessages}</div>
          </div>
        )}
        <GaTemplates />
        <div styleName="dm-container-title">
          Based on
          {basedOnTemplate && basedOnTemplate.name}
        </div>
        <div styleName="dm-container">
          <List title="List of Measures" list={selectedMetrics} />
          <List title="List of Dimensions" list={selectedDimensions} />
        </div>
        <GaSegments />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    dataStreamState: selectors.getSelectedDataStream(state).state,
    selectedDimensions: selectors.getGoogleAnalyticsStreamSelectedDimensions(state),
    selectedMetrics: selectors.getGoogleAnalyticsStreamSelectedMetrics(state),
    basedOnTemplate: selectors.getGoogleAnalyticsStreamBaseTemplate(state),
  }),
  {
    setSelectedEditor: setSelectedEditorAction,
    fetchMetadata,
    fetchCubes,
  },
)(DimensionsAndMetrics);
