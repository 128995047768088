// @flow

import React, {Fragment} from 'react';
import connect from 'react-redux/es/connect/connect';
import {editUsersBulk, toggleBulkDeleteUsersModal} from 'admin.users/store/actions';
import Button, {COLORS as BUTTON_COLORS} from 'common/componentsV2/Button';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import UGFooterAddToGroups from 'admin.users/components/footer/UGFooterAddToGroups';
import UGFooterMore from 'admin.users/components/footer/UGFooterMore';
import UGPrimaryGroup from 'admin.users/components/footer/UGPrimaryGroup';
import BulkDeleteUsersModal from 'admin.users/components/footer/BulkDeleteUsersModal';
import ModalLoadingSpinner from 'common/componentsV2/modal/ModalLoadingSpinner';
import * as selectors from 'admin.users/store/selectors';
import {getProfileId} from 'profile/store/selectors';
import './UGFooterGeneral.module.scss';

type PropTypes = {
  isLoadingEditBulk: boolean,
  isLoadingDeleteBulk: boolean,
  selectedUsers: Array,
  meId: string,
  editUsersBulk: Function,
  toggleBulkDeleteUsersModal: Function,
};

@connect(
  (state) => ({
    isLoadingEditBulk: selectors.editUsersBulkApiIsLoading(state),
    isLoadingDeleteBulk: selectors.deleteUsersBulkApiIsLoading(state),
    selectedUsers: selectors.getSelectedUsersCheckbox(state),
    meId: getProfileId(state),
  }),
  {
    editUsersBulk,
    toggleBulkDeleteUsersModal,
  },
)
export default class UGFooter extends React.PureComponent {
  props: PropTypes;

  state = {
    isModalOpen: false,
    isPrimaryGroupModalOpen: false,
    primaryGroupSelected: null,
  };

  onModalClickClose = () => {
    this.setState({isModalOpen: false});
  };

  onGroupsChange = (values) => {
    this.props.editUsersBulk({
      action: 'groups',
      data: values,
    });
  };

  onDisableClick = () => {
    if (this.props.selectedUsers.indexOf(this.props.meId) !== -1) {
      this.setState({isModalOpen: true});
      return;
    }
    this.props.editUsersBulk({
      action: 'disable',
    });
  };

  onMoreMenuItemClick = (val) => {
    switch (val.value) {
      case 'enable':
        this.props.editUsersBulk({
          action: val.value,
        });
        break;
      case 'primaryGroup':
        this.setState({isPrimaryGroupModalOpen: true});
        break;
      default:
    }
  };

  onDeleteClick = () => {
    if (this.props.selectedUsers.indexOf(this.props.meId) !== -1) {
      this.setState({isModalOpen: true});
      return;
    }
    this.props.toggleBulkDeleteUsersModal({isOpen: true});
  };

  onClosePrimaryGroupModal = () => {
    this.setState({isPrimaryGroupModalOpen: false, primaryGroupSelected: null});
  };

  onPrimaryGroupChange = (item) => {
    this.setState({primaryGroupSelected: item});
  };

  handlePrimaryGroupApplyChanges = () => {
    this.props.editUsersBulk({
      action: 'primaryGroup',
      primaryGroup: this.state.primaryGroupSelected,
    });
    this.setState({isPrimaryGroupModalOpen: false, primaryGroupSelected: null});
  };

  modalRef = (el) => {
    if (el) {
      el.select.focus();
    }
  };

  renderPrimaryGroupModal = () => (
    <Modal
      isOpen={this.state.isPrimaryGroupModalOpen}
      size={SIZES.SMALL}
      onClose={this.onClosePrimaryGroupModal}
      isCloseButtonHidden
      isNotCentered
      classStyle="modal-hidden"
    >
      <div styleName="primary-group-modal">
        <UGPrimaryGroup
          onChange={this.onPrimaryGroupChange}
          refSelectComponent={this.modalRef}
          value={this.state.primaryGroupSelected ? this.state.primaryGroupSelected.id : null}
          onMenuClose={this.onClosePrimaryGroupModal}
          onPrimaryGroupApply={this.handlePrimaryGroupApplyChanges}
        />
      </div>
    </Modal>
  );

  render() {
    return (
      <Fragment>
        {this.renderPrimaryGroupModal()}
        {this.props.isLoadingEditBulk || this.props.isLoadingDeleteBulk ? <ModalLoadingSpinner /> : null}
        <Modal isOpen={this.state.isModalOpen} onClose={this.onModalClickClose} size={SIZES.SMALL}>
          <div styleName="modal-content">
            <h5>Not Authorized</h5>
            <p>You cannot disable or delete yourself.</p>
            <Button colorSchema={BUTTON_COLORS.BLUE_500} text="Got It" onClick={this.onModalClickClose} />
          </div>
        </Modal>
        <UGFooterAddToGroups onApply={this.onGroupsChange} />
        <div styleName="button-wrapper">
          <Button
            colorSchema={BUTTON_COLORS.TRANSPARENT}
            text="Disable"
            onClick={this.onDisableClick}
            icon="icn-action16-pause"
          />
        </div>
        <div styleName="button-wrapper">
          <Button
            colorSchema={BUTTON_COLORS.TRANSPARENT}
            text="Delete"
            onClick={this.onDeleteClick}
            icon="icn-action16-delete"
          />
        </div>
        <UGFooterMore onApply={this.onMoreMenuItemClick} />
        <BulkDeleteUsersModal />
      </Fragment>
    );
  }
}
