import {makeAction, makeAsyncAction} from 'common/utils/simplifiedAsync';

const mac = (actionName) => makeAction(actionName, 'anodot/alerts.management/');
const macAsync = (actionName) => makeAsyncAction(actionName, 'anodot/alerts.management/');

//* **simulation filters
export const selectedAlertFilterChanged = mac('SELECTED_ALERT_FILTER_CHANGED');
export const validateSelectedAlertConditions = makeAsyncAction('VALIDATE_SELECTED_ALERT_CONDITIONS');
export const initiateValidateSelectedAlertConditions = mac('INITIATE_VALIDATE_SELECTED_ALERT_CONDITIONS');

export const resetExpressionBuilder = mac('RESET_EXPRESSION_BUILDER');
export const setSimulationFilters = mac('SET_SIMULATION_FILTERS');
export const setAlertDefinition = mac('SET_ALERT_DEFINITION');
export const setSelectedAlertType = mac('SET_SELECTED_ALERT_TYPE');
export const setSelectedAlertSignificance = mac('SET_SELECTED_ALERT_SCORE');
export const setSelectedAlertMinDuration = mac('SET_SELECTED_ALERT_MIN_DURATION');
export const setSelectedAlertMinDurationScale = mac('SET_SELECTED_ALERT_MIN_DURATION_SCALE');
export const setSelectedAlertTimeScale = mac('SET_SELECTED_ALERT_TIME_SCALE');
export const setSelectedAlertDirection = mac('SET_SELECTED_ALERT_DIRECTION');
export const setSelectedAlertShouldShowEvents = mac('SET_SELECTED_ALERT_SHOULD_SHOW_EVENTS');
export const setIsInfluencingEvents = mac('SET_IS_INFLUENCING_EVENTS');
export const setSelectedAlertNoDataDuration = mac('SET_SELECTED_ALERT_NO_DATA_DURATION');
export const setSelectedAlertNoDataEnabled = mac('SET_SELECTED_ALERT_NO_DATA_ENABLED');
export const setSelectedAlertStaticThreshold = mac('SET_SELECTED_ALERT_STATIC_THRESHOLD');
export const setSelectedAlertUpdatePolicy = mac('SET_SELECTED_ALERT_UPDATE_POLICY');
export const setSelectedAlertAutoTuneByAnodot = mac('SET_SELECTED_ALERT_AUTO_TUNE_BY_ANODOT');
export const setSelectedAlertMinDeltaAutoBoolVal = mac('SET_SELECTED_ALERT_MIN_DELTA_AUTO_BOOL_VAL');

export const setDeltaDurationEnabled = mac('SET_DELTA_DURATION_ENABLED');
export const setDeltaDurationNumOfPoints = mac('SET_DELTA_DURATION_NUM_OF_POINTS');

export const setSelectedAlertMinDeltaEstimation = mac('SET_SELECTED_ALERT_MIN_DELTA_ESTIMATION');
export const setSelectedAlertNotifyOnlyOpenBoolVal = mac('SET_SELECTED_ALERT_NOTIFY_ONLY_OPEN_BOOL_VAL');

export const getVolumeCondition = macAsync('GET_VOLUME_CONDITION');
export const disableVolumeCondition = mac('DISABLE_VOLUME_CONDITION');
export const setAutoVolumeConditionValues = mac('SET_VOLUME_CONDITION');
export const setVolumeNumOfLastPoints = mac('SET_VOLUME_NUM_OF_LAST_POINTS');
export const setVolumeRollup = mac('SET_VOLUME_ROLLUP');
export const setVolumeEnabled = mac('SET_VOLUME_ENABLED');
export const setVolumeBound = mac('SET_VOLUME_BOUND');
export const setVolumeValue = mac('SET_VOLUME_VALUE');
export const setEnableAutoTuning = mac('SET_ENABLE_VOLUME_AUTO_TUNING');

export const setSelectedAlertInfluencingMetrics = mac('SET_SELECTED_ALERT_INFLUENCING_METRICS');
export const setSelectedAlertInfluencingMetricsSubCondition = mac(
  'SET_SELECTED_ALERT_INFLUENCING_METRICS_SUB_CONDITION',
);
export const addSelectedAlertInfluencingMetricsSubCondition = mac(
  'ADD_SELECTED_ALERT_INFLUENCING_METRICS_SUB_CONDITION',
);
export const removeSelectedAlertInfluencingMetricsSubCondition = mac(
  'REMOVE_SELECTED_ALERT_INFLUENCING_METRICS_SUB_CONDITION',
);
export const fetchInfluencingMetricsInfo = macAsync('FETCH_INFLUENCING_METRICS_INFO');
export const setSuppress = mac('SET_SUPPRESS');

export const setSelectedAlertInfluencingMetricsSubConditionV2 = mac(
  'SET_SELECTED_ALERT_INFLUENCING_METRICS_SUB_CONDITION_V2',
);

export const addConditionToSelectedAlert = mac('ADD_CONDITION_TO_SELECTED_ALERT');
export const removeConditionFromSelectedAlert = mac('REMOVE_CONDITION_FROM_SELECTED_ALERT');

export const setSelectedAlertMinDeltaAbs = mac('SET_SELECTED_ALERT_MIN_DELTA_ABS');
export const setSelectedAlertMinDeltaPercent = mac('SET_SELECTED_ALERT_MIN_DELTA_PERCENT');
export const setSelectedAlertMaxMetricsAbs = mac('SET_SELECTED_ALERT_MAX_METRICS_ABS');
export const setSelectedAlertMaxMetricsPercentage = mac('SET_SELECTED_ALERT_MAX_METRICS_PERCENTAGE');
export const setSelectedAlertMinMetricsAbs = mac('SET_SELECTED_ALERT_MIN_METRICS_ABS');
export const setSelectedAlertMinMetricsPercentage = mac('SET_SELECTED_ALERT_MIN_METRICS_PERCENTAGE');

export const setAdvancedModeOpen = mac('SET_ADVANCED_MODE_OPEN');

// NEW ALERTS MNG 2.0
//-------------------
// actions with the UX
export const setSelectedAlert = mac('SET_SELECTED_ALERT');
export const setAlertListOrder = mac('SET_ALERT_LIST_ORDER');
export const setAlertFiltersKeyVal = mac('SET_ALERT_FILTERS_KEY_VAL');
export const setAlertFiltersIsOpen = mac('SET_ALERT_FILTERS_IS_OPEN');
export const setAlertFiltersIsMore = mac('SET_ALERT_FILTERS_IS_MORE');
export const setAlertFilters = mac('SET_ALERT_FILTERS');
export const setAlertFiltersView = mac('SET_ALERT_FILTERS_VIEW');
export const fetchTriggeredAlertsByConfiguration = macAsync('FETCH_TRIGGERED_ALERTS_BY_CONFIGURATION');
export const singleAlertCheckboxClick = mac('SINGLE_ALERT_CHECKBOX_CLICK');
export const allAlertCheckboxClick = mac('ALL_ALERT_CHECKBOX_CLICK');
export const setCheckboxAll = mac('SET_CHECKBOX_ALL');
export const setAlertManagementKeyVal = mac('SET_ALERT_MANAGEMENT_KEY_VAL');
export const setSelectedAlertTab = mac('SET_SELECTED_ALERT_TAB');
export const setAlertToScrollAndOpen = mac('SET_ALERT_TO_SCROLL_AND_OPEN');
export const showAlertSuccessMessage = mac('SHOW_ALERT_SUCCESS_MESSAGE');
export const showAlertFailureMessage = mac('SHOW_ALERT_FAILURE_MESSAGE');

// actions with data
export const fetchAlertConfigurations = macAsync('FETCH_ALERT_CONFIGURATIONS');
export const createAlert = macAsync('CREATE_ALERT');
export const deleteAlert = macAsync('DELETE_ALERT');
export const deleteAlertBulk = mac('DELETE_ALERT_BULK');
export const deleteAlertBulkApi = macAsync('DELETE_ALERT_BULK_API');
export const clearAlertFromList = mac('CLEAR_ALERT_FROM_LIST');
export const pauseAlert = macAsync('PAUSE_ALERT');
export const resumeAlert = macAsync('RESUME_ALERT');
export const pauseResumeAlertStart = mac('PAUSE_RESUME_ALERT_START');
export const subscribeToAlert = macAsync('SUBSCRIBE_TO_ALERT');
export const unsubscribeToAlert = macAsync('UNSUBSCRIBE_TO_ALERT');
export const subscribeUnsubscribeToAlertStart = mac('SUBSCRIBE_UNSUBSCRIBE_TO_ALERT_START');
export const duplicateAlert = mac('DUPLICATE_ALERT');
export const editAlertBulkApi = macAsync('EDIT_ALERT_BULK_API');
export const editAlertBulk = mac('EDIT_ALERT_BULK');
export const removeAlertTag = mac('REMOVE_ALERT_TAG');
export const executeEstimation = macAsync('EXECUTE_ESTIMATION');
export const calculateEstimatedRate = mac('CALCULATE_ESTIMATED_RATE');

// DASHBOARDS
// dashboard by id - for connected tiles
export const fetchDashboard = macAsync('FETCH_DASHBOARD');

// alert from stream
export const createAlertsFromStream = mac('CREATE_ALERTS_FROM_STREAM');
export const createAlertsFromStreamApi = macAsync('CREATE_ALERTS_FROM_STREAM_API');

export const fetchAlertsConfigurationTotal = macAsync('FETCH_ALERTS_CONFIGURATION_TOTAL');
export const fetchDataStreamMetricsCountApiCall = macAsync('FETCH_DATA_STREAM_METRICS_COUNT_API_CALL');
export const fetchDataStreamMetricsCount = mac('FETCH_DATA_STREAM_METRICS_COUNT');
export const setStreamMetricCountToAlert = mac('SET_STREAM_METRIC_COUNT_TO_ALERT');

// new simple alert
export const toggleSimpleAlertModal = mac('TOGGLE_SIMPLE_ALERT_MODAL');
export const createSimpleAlert = mac('CREATE_SIMPLE_ALERT');
export const openAlertNameModal = mac('OPEN_ALERT_NAME_MODAL');
export const simpleAlertGoAdvancedOptions = mac('SIMPLE_ALERT_GO_ADVANCED_OPTIONS');
export const simpleAlertSetIsRatio = mac('SIMPLE_ALERT_SET_IS_RATIO');
export const simpleAlertSetFirstMeasure = mac('SIMPLE_ALERT_SET_FIRST_MEASURE');
export const simpleAlertSetSecondMeasure = mac('SIMPLE_ALERT_SET_SECOND_MEASURE');
export const simpleAlertSetDimensions = mac('SIMPLE_ALERT_SET_DIMENSIONS');
export const simpleAlertSetDirection = mac('SIMPLE_ALERT_SET_DIRECTION');
export const simpleAlertSetTimeValue = mac('SIMPLE_ALERT_SET_TIME_VALUE');
export const simpleAlertSetTimeScale = mac('SIMPLE_ALERT_SET_TIME_SCALE');
export const simpleAlertSetRecipients = mac('SIMPLE_ALERT_SET_RECIPIENTS');
export const simpleAlertCalculateEstimatedRate = mac('SIMPLE_ALERT_CALCULATE_ESTIMATED_RATE');
export const simpleAlertCreateAnother = mac('SIMPLE_ALERT_CREATE_ANOTHER');
export const simpleAlertReset = mac('SIMPLE_ALERT_RESET');

export const executeAlertSimulation = macAsync('EXECUTE_ALERT_SIMULATION');
export const fetchAlert = macAsync('FETCH_ALERT');
export const updateAlert = macAsync('UPDATE_ALERT');
export const createNewAlert = macAsync('CREATE_NEW_ALERT');

export const getAlertsTags = macAsync('GET_ALERTS_TAGS');

// Dynamic Routing
export const fetchSimulationData = macAsync('FETCH_SIMULATION_DATA');
