// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setSelectedEditor} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import SchedularData from 'bc/components/streams/editor/common/SchedularData';
import {isStreamEditable} from 'bc/services/dataStreamService';
import {get} from 'lodash';
import './ParquetSchedular.module.scss';

type PropTypes = {
  dataStreamState: boolean,
  uiState: Object,
  setSelectedEditor: Function,
  isUiStateDirty: boolean,
};

@connect(
  (state) => ({
    dataStreamState: selectors.getSelectedDataStream(state).state,
    uiState: selectors.getSelectedDataStream(state).uiState,
    isUiStateDirty: selectors.isParquetStreamUiStateDirty(state),
  }),
  {
    setSelectedEditor,
  },
)
export default class ParquetSchedular extends React.PureComponent {
  props: PropTypes;

  editClicked = () => {
    this.props.setSelectedEditor({
      type: 'schedular_parquet',
      editorHeight: 'calc(100% - 210px)',
      maxHeight: '600px',
    });
  };

  render() {
    const {dataStreamState, uiState, isUiStateDirty} = this.props;
    const isEditable = isStreamEditable(dataStreamState) && get(uiState, 'analysisResult.columns') && !isUiStateDirty;

    return (
      <div className="shell-col" automation-id="parquetScheduler" styleName={!isEditable ? 'disabled' : ''}>
        <PanelHeader title="Schedule file collection" onSelect={this.editClicked} isEditable={isEditable} />
        <SchedularData />
      </div>
    );
  }
}
