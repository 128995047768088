// @flow
import React, {PureComponent} from 'react';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentCheckboxHeaders from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentCheckboxHeaders';

type PropTypes = {
  sortedFlattenChannels: Array<{
    label: String,
    value: String,
    type: String,
    icon: String,
    color: String,
  }>,
  sortAlphabetic: boolean,
  value: Array<{
    label: String,
    value: String,
    type: String,
    icon: String,
    color: String,
  }>,
  onChange: Function,
  onMenuClose: Function,
  footerComponent?: any,
  placeholder?: String,
  disabled?: boolean,
  menuWidth?: Number,
  // eslint-disable-next-line react/no-unused-prop-types
  refSelectComponent: Object,
  automationId?: String,
  isFullWidthButton: boolean,
};

export default class UsersChannelsFilter extends PureComponent {
  props: PropTypes;

  static defaultProps = {
    footerComponent: null,
    placeholder: 'Select',
    disabled: false,
    menuWidth: 270,
    automationId: '',
  };

  handleToggle = (val) => {
    if (!val && typeof this.props.onMenuClose === 'function') {
      this.props.onMenuClose();
    }
  };

  render() {
    const {
      sortedFlattenChannels,
      value,
      onChange,
      footerComponent,
      placeholder,
      automationId,
      isFullWidthButton,
      sortAlphabetic,
    } = this.props;

    const myValue = value || [];
    return (
      <FormDdlSelect
        placement="auto"
        disabled={this.props.disabled}
        selected={myValue}
        options={sortedFlattenChannels || []}
        sortAlphabetic={sortAlphabetic}
        button={
          <BigDropdownButton
            isPristine={!myValue.length}
            placeholder={placeholder}
            label={myValue.length === 1 ? myValue[0].label : `${myValue.length} Selected`}
            shortBlueStyle
            isFullWidth={isFullWidthButton}
          />
        }
        isMulti
        optionComponent={<OptionComponentCheckboxHeaders />}
        onChange={onChange}
        width={this.props.menuWidth}
        height={260}
        footerComponent={footerComponent}
        onToggle={this.handleToggle}
        automationId={automationId}
      />
    );
  }
}
