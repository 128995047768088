// @flow
import React from 'react';
import {useField} from 'react-final-form';
import Box from '@material-ui/core/Box';
import {TypographyBox} from 'common/componentsV2/boxTools';
import Grid from '@material-ui/core/Grid';
import SubTitle from 'userSettings/components/SubTitle';
import Input from 'common/componentsV2/Input';
import SelectAndt, {TYPE_NEW_NO_SEARCH, THEME_NOT_HIGHLIGHTED} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropsType = {
  options: Array,
};

const SessionTimeout = ({options}: PropsType) => {
  const {
    input: {value: inputValue, onChange: actionInputChange},
    meta: {invalid},
  } = useField('jwtTtl', {
    validate: (val, allValues) => {
      switch (allValues.selectTimeoutScale.value) {
        case options[0].value:
          return !(val >= 1 && val <= 180 * 24 * 60);
        case options[1].value:
          return !(val >= 1 && val <= 180 * 24);
        case options[2].value:
          return !(val >= 1 && val <= 180);
        default:
          return !(val >= 1 && val <= 180 * 24);
      }
    },
  });
  const {
    input: {value: selectValue, onChange: actionSelectChange},
  } = useField('selectTimeoutScale');

  const handleInputChange = (e) => {
    actionInputChange(e.target.value);
  };

  return (
    <Box mb={7}>
      <SubTitle title="User Session Timeout" />
      <Grid container>
        <Grid item>
          <TypographyBox variant="body1" mr={6}>
            Set Timeout:
          </TypographyBox>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              <Box width={100} height={36} mr={1}>
                <Input type="number" onChange={handleInputChange} value={inputValue} isInvalid={invalid} fullSize />
              </Box>
            </Grid>
            <Grid item>
              <SelectAndt
                type={TYPE_NEW_NO_SEARCH}
                theme={THEME_NOT_HIGHLIGHTED}
                onChange={actionSelectChange}
                optionHeight={40}
                automationId="SelectTimeout"
                value={selectValue || options[0]}
                options={options}
                buttonWidth={140}
              />
            </Grid>
          </Grid>
          <TypographyBox mt={1.5} variant="hintText">
            Maximum 180 days
          </TypographyBox>
          <TypographyBox variant="hintText">
            Note: Changing the user session timeout disconnects all active users
          </TypographyBox>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SessionTimeout;
