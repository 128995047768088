import {get} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {fetchCompositeName} from 'metrics/store/actions';
import {stringifyQS} from 'common/utils/http';
import useDeepCompareEffect from 'common/utils/useDeepCompareEffect';
import * as metricsSelectors from 'metrics/store/selectors';
import {cleanupSpecialChars} from 'metrics/services/metricsService';

const useCompositeName = ({compositeObject}) => {
  const dispatch = useDispatch();
  const compositeNameResponse = useSelector(metricsSelectors.getCompositeNameResponse);
  const {expressionTree} = compositeObject;

  const isEmptyFunction =
    expressionTree.root &&
    (expressionTree.root.type === 'metric' ||
      (expressionTree.root.function === '' &&
        expressionTree.root.children.length === 1 &&
        expressionTree.root.children[0].type === 'metric'));

  useDeepCompareEffect(() => {
    if (compositeObject) {
      if (!isEmptyFunction) {
        dispatch(
          fetchCompositeName({
            urlExt: stringifyQS({
              index: 0,
              size: 5,
              includeSuggestions: true,
              maxDataPoints: 500,
            }),
            body: {
              ...compositeObject,
              namingSchema: 'COMPOSITE_V2',
              excludeComposites: true,
              mtype: 'COMPOSITE',
              name: {
                auto: 'true',
                prefix: '',
              },
              scalarTransforms: null,
            },
          }),
        );
      }
    }
  }, [compositeObject]);

  const nameComposition = get(compositeNameResponse, 'data.nameComposition', {suggested: []});

  const suggested = (nameComposition.suggested || []).map((item) => `${item.key}=${item.value}`).join('.');

  return {
    makeCompositeNameProps: (alertTitle, manualCompositeName) => {
      const compositeWhat = nameComposition.what || manualCompositeName;
      return {
        name: isEmptyFunction
          ? null
          : {
              auto: false,
              // eslint-disable-next-line max-len
              prefix: compositeWhat ? `what=${cleanupSpecialChars(compositeWhat)}.${suggested}` : suggested,
            },
        title: cleanupSpecialChars(`${alertTitle}_${nameComposition.what || manualCompositeName}`),
      };
    },
    isManualCompositeNameInput: !compositeNameResponse.isLoading && !isEmptyFunction && nameComposition.what === '',
    isCompositeNameLoading: compositeNameResponse.isLoading,
  };
};

export default useCompositeName;
