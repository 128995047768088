export default [
  {
    id: 0,
    name: 'title',
    title: 'Title',
    isSortable: true,
  },
  {
    id: 1,
    name: 'owner',
    title: 'Owner',
    isSortable: true,
  },
  {
    id: 2,
    name: 'alertsConnected',
    title: 'Alerts Connected',
    isSortable: true,
  },
  {
    id: 3,
    name: 'dateCreated',
    title: 'Date Modified',
    isSortable: true,
  },
];
