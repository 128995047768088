// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc.cubes/store/selectors';
import {setEditableTopCubeKeyVal} from 'bc.cubes/store/actions';
import './CubeDescription.module.scss';

type PropTypes = {
  className: string,
  editableTopCube: Object,

  setEditableTopCubeKeyVal: Function,
};

@connect(
  (state) => ({
    editableTopCube: selectors.getEditableTopCube(state),
  }),
  {
    setEditableTopCubeKeyVal,
  },
)
export default class CubeDescription extends PureComponent {
  props: PropTypes;

  onDescriptionChange = (event) => {
    this.props.setEditableTopCubeKeyVal({description: event.target.value});
  };

  render() {
    const {className, editableTopCube} = this.props;

    return (
      <div styleName="root" className={className}>
        <div className="andt-section-header">Add Description</div>
        <textarea value={editableTopCube.description || ''} onChange={this.onDescriptionChange} />
      </div>
    );
  }
}
