import {createSelector} from 'reselect';
import {get, omitBy} from 'lodash';
import {getSelectedItems as getUsersAndChannels} from 'alerts.channels/store/selectors';
import {getQueryParamsViews, getQueryParamsTagsViews} from 'alerts.console/store/selectors';
import {getMergedTags} from 'alerts.management/store/selectors';
import {
  DEFAULT_QUERY_PARAMS,
  FILTER_KEY_HEADERS,
  ALLOWED_FILTER_KEYS,
} from 'alerts.console/services/alertsConsoleService';

const findAvailableItems = (filterName, values = '') => {
  const selectedItems = ALLOWED_FILTER_KEYS[filterName].filter((item) => values.indexOf(item.value) !== -1);
  return selectedItems && selectedItems.length !== 0 ? selectedItems : null;
};

const aggregateFilterValues = (values) => {
  switch (values.length) {
    case 1:
      return values[0].label;
    case 2:
      return `${values[0].label}, ${values[1].label}`;
    default:
      return `${values[0].label}, ${values[1].label}, +${values.length - 2}`;
  }
};

const combineFeedbackValues = (changedFeedback, filterKeyHeader) => {
  const arr = [];
  if (changedFeedback.positiveFeedback) {
    arr.push(filterKeyHeader.positiveFeedback.label);
  }
  if (changedFeedback.negativeFeedback) {
    arr.push(filterKeyHeader.negativeFeedback.label);
  }
  return arr.join(', ');
};

export const getChangedFilters = createSelector(
  getQueryParamsViews,
  (filters) => omitBy(filters, (value, key) => value === DEFAULT_QUERY_PARAMS[key]),
);

export const getChangedTypes = createSelector(
  getChangedFilters,
  (changedFilters) => findAvailableItems('types', changedFilters.types),
);

export const getChangedSeverities = createSelector(
  getChangedFilters,
  (changedFilters) => findAvailableItems('severities', changedFilters.severities),
);

export const getSelectedTags = createSelector(
  getMergedTags,
  getQueryParamsTagsViews,
  (tagList, tag) => {
    const selectedTagsArr = tag ? tag.split(',') : null;
    return selectedTagsArr && tagList.length !== 0
      ? tagList.filter((item) => selectedTagsArr.indexOf(item.value) !== -1)
      : null;
  },
);

export const getFiltersButtonTooltipItems = createSelector(
  getChangedFilters,
  getUsersAndChannels,
  getSelectedTags,
  (changedFilters, usersAndChannels, tags) => {
    const filterItemsList = [];
    Object.keys(changedFilters).forEach((filterKey) => {
      const item = {
        id: get(FILTER_KEY_HEADERS[filterKey], 'id', null),
        header: get(FILTER_KEY_HEADERS[filterKey], 'label', null),
      };
      switch (filterKey) {
        case FILTER_KEY_HEADERS.searchQuery.id:
          filterItemsList.push({
            ...item,
            value: changedFilters[filterKey],
          });
          break;
        case FILTER_KEY_HEADERS.constRange.id:
          filterItemsList.push({
            ...item,
            value: ALLOWED_FILTER_KEYS[filterKey][changedFilters[filterKey]].text,
          });
          break;
        case FILTER_KEY_HEADERS.status.id:
        case FILTER_KEY_HEADERS.ack.id:
          filterItemsList.push({
            ...item,
            value: ALLOWED_FILTER_KEYS[filterKey][changedFilters[filterKey]].label,
          });
          break;
        case FILTER_KEY_HEADERS.types.id:
          filterItemsList.push({
            id: FILTER_KEY_HEADERS.types.id,
            header: FILTER_KEY_HEADERS.types.label,
            value: aggregateFilterValues(findAvailableItems(filterKey, changedFilters[filterKey])),
          });
          break;
        case FILTER_KEY_HEADERS.severities.id:
          filterItemsList.push({
            id: FILTER_KEY_HEADERS.severities.id,
            header: FILTER_KEY_HEADERS.severities.label,
            value: aggregateFilterValues(findAvailableItems(filterKey, changedFilters[filterKey])),
          });
          break;
        default:
          break;
      }
    });
    if (usersAndChannels && usersAndChannels.length) {
      filterItemsList.push({
        id: FILTER_KEY_HEADERS.usersAndChannels.id,
        header: FILTER_KEY_HEADERS.usersAndChannels.label,
        value: aggregateFilterValues(usersAndChannels),
      });
    }
    if (changedFilters.positiveFeedback || changedFilters.negativeFeedback) {
      filterItemsList.push({
        id: FILTER_KEY_HEADERS.feedback.id,
        header: FILTER_KEY_HEADERS.feedback.title,
        value: combineFeedbackValues(changedFilters, FILTER_KEY_HEADERS),
      });
    }
    if (tags && tags.length) {
      filterItemsList.push({
        id: FILTER_KEY_HEADERS.labels.id,
        header: FILTER_KEY_HEADERS.labels.label,
        value: aggregateFilterValues(tags),
      });
    }

    return filterItemsList;
  },
);
