import {createSelector} from 'reselect';
import {selectors as commonSelectors} from 'common';
import * as profileSelectors from 'profile/store/selectors';
import {get} from 'lodash';

const {getAuditLog} = commonSelectors;
const EMPTY_ARRAY = [];

export const isOpenFilters = createSelector(
  profileSelectors.getMeAppSettings,
  (settings) => get(settings, 'activityLog.isFiltersBarOpen', true),
);

export const getAuditLogData = createSelector(
  getAuditLog,
  (auditLog) => get(auditLog, 'fetchAuditLog.data', EMPTY_ARRAY),
);

export const auditLogDataIsLoading = createSelector(
  getAuditLog,
  (auditLog) => get(auditLog, 'fetchAuditLog.isLoading', false),
);
