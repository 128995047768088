// @flow
import React from 'react';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';
import * as selectors from 'bc/store/selectors';
import {setStreamSchemaColumnKeyVal} from 'bc/store/actions';
import {get} from 'lodash';
import UnitsSelect from './UnitsSelect';
import './AggregationModal.module.scss';

type PropTypes = {
  isOpen: boolean,
  onClose: Function,
  col: Object,
  isEditable: boolean,

  unitList: Array,
  schema: Object,
  setStreamSchemaColumnKeyVal: Function,
};

@connect(
  (state) => ({
    unitList: selectors.getDataStreams(state).unitList,
    schema: selectors.getSelectedDataStream(state).schema,
  }),
  {
    setStreamSchemaColumnKeyVal,
  },
)
export default class UnitsModal extends React.PureComponent {
  props: PropTypes;

  constructor(props) {
    super(props);
    const metricTags = get(this.props.schema.columns.find((f) => f.id === this.props.col.id), 'metricTags', null);
    this.originalUnitLabel = metricTags ? get(metricTags, 'unit', null) : null;
    this.state = {
      unit: this.props.unitList.find((unit) => unit.label === this.originalUnitLabel),
      isSame: true,
    };
  }

  originalUnitLabel = null;

  handleClose = (isOk) => {
    if (isOk) {
      const metricTags = get(this.props.schema.columns.find((f) => f.id === this.props.col.id), 'metricTags', null);

      if (this.state.unit) {
        this.props.setStreamSchemaColumnKeyVal({
          colId: this.props.col.id,
          key: 'metricTags',
          value: {...metricTags, unit: this.state.unit.label},
        });
      } else {
        delete metricTags.unit;
        this.props.setStreamSchemaColumnKeyVal({colId: this.props.col.id, key: 'metricTags', value: {...metricTags}});
      }
    }
    this.props.onClose();
  };

  onValueChange = (newValue) => {
    this.setState({
      unit: newValue,
      isSame: this.originalUnitLabel === (newValue ? newValue.label : null),
    });
  };

  render() {
    const {isOpen, onClose, col, isEditable} = this.props;

    return (
      <div>
        <Modal show={isOpen} dialogClassName="bc overflow-override" onHide={onClose} bsSize="small">
          <Modal.Header bsClass="bc-modal-header">
            <Modal.Title>
              <span styleName="property" className="ellipsis">
                {col.name}
              </span>
              <span styleName="action">Units & Symbols</span>
            </Modal.Title>
            <button
              type="button"
              className="btn btn-flat btn-icon-36 btn-secondary"
              automation-id="unitsButton"
              onClick={() => this.handleClose(false)}
            >
              <i className="icon icn-icon-table-delete" />
            </button>
          </Modal.Header>

          <Modal.Body styleName="body">
            <UnitsSelect
              onChange={this.onValueChange}
              defaultValue={this.state.unit}
              items={this.props.unitList}
              isLoading={false}
            />
          </Modal.Body>

          <Modal.Footer styleName="footer">
            <button
              type="button"
              className="btn btn-raised btn-outline "
              automation-id="UnitsAddButton"
              onClick={() => this.handleClose(true)}
              disabled={!isEditable || this.state.isSame}
            >
              ADD
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
