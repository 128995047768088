// @flow
import React, {Fragment, useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {useQueryParams} from 'use-query-params';
import {PANEL_SIZES, QUERY_PARAM_MAP, REGIONS_PANEL_TABS} from 'topologyGeneral/services/sidePanelService';
import {getTpSettings} from 'topologyGeneral/services/mapService';
import TabsBar from 'topologyGeneral/components/common/TabsBar';
import LocationsList from 'topologyGeneral/components/sidePanel/LocationsList';
import SearchSubPanel from 'topologyGeneral/components/sidePanel/SearchSubPanel';
import MainIssuesSubPanel from 'topologyGeneral/components/sidePanel/issuesSubPanel/MainIssuesSubPanel';
import TinyScrollBox from 'common/componentsV2/boxTools/TinyScrollBox';
import {getGtpMapRegionsHighestLevel} from 'topologyGeneral/store/selectors';
import './SidePanel.module.scss';

const RegionsPanel = ({mapRef, nodeHoveredId, isVisible}: PropTypes) => {
  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAM_MAP);
  const regionItems = useSelector(getGtpMapRegionsHighestLevel);

  useEffect(() => {
    const {current: map} = mapRef;
    if (!queryParams.regionId && map) {
      const tpSettings = getTpSettings();
      map.setView(tpSettings.center, tpSettings.zoom, {
        animate: false,
      });
    }
  }, [queryParams.regionId]);

  const tabClicked = useCallback((tabIdLoc) => {
    setQueryParams({regionsTabId: tabIdLoc});
  }, []);

  const locItemClicked = (locItem) => {
    const newSelectedLocationId = queryParams.regionId === locItem.id ? undefined : locItem.id;
    setQueryParams({regionId: newSelectedLocationId});
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Fragment>
      <Box display="flex" borderBottom="1px solid #d4d9e0" mr={2} ml={2} mt={2.5} pl={0.5}>
        <TabsBar
          tabs={[REGIONS_PANEL_TABS.locations, REGIONS_PANEL_TABS.issues]}
          selectedTabId={queryParams.regionsTabId}
          onTabClick={tabClicked}
        />
      </Box>
      {queryParams.regionsTabId === REGIONS_PANEL_TABS.locations.id && (
        <TinyScrollBox
          position="absolute"
          top={50}
          bottom={0}
          right={3}
          left={0}
          css={{overflowY: 'auto', overflowX: 'hidden'}}
        >
          <Box width={PANEL_SIZES.innerPanelWidth} ml={2}>
            <Box fontSize={14} lineHeight="20px" fontWeight={400} mb={1}>{`Regions (${regionItems.length})`}</Box>
            <LocationsList items={regionItems} onItemClick={locItemClicked} selectedItemId={nodeHoveredId} />
          </Box>
        </TinyScrollBox>
      )}
      {queryParams.regionsTabId === REGIONS_PANEL_TABS.issues.id && (
        <Box position="absolute" top={46} bottom={0} right={0} left={0}>
          <MainIssuesSubPanel regions={regionItems} />
        </Box>
      )}
      {queryParams.regionsTabId === REGIONS_PANEL_TABS.search.id && (
        <Box position="absolute" top={46} bottom={0} right={0} left={0}>
          <SearchSubPanel />
        </Box>
      )}
    </Fragment>
  );
};

RegionsPanel.propTypes = {
  mapRef: PropTypes.objectOf(PropTypes.any).isRequired,
  nodeHoveredId: PropTypes.string,
  isVisible: PropTypes.bool,
};

RegionsPanel.defaultProps = {
  nodeHoveredId: undefined,
  isVisible: true,
};

export default RegionsPanel;
