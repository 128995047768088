/* eslint-disable react/no-unused-prop-types */
// @flow
import React from 'react';
import {Modal} from 'react-bootstrap';
import {get} from 'lodash';

type PropTypes = {
  isOpen: boolean,
  className: String,
  modalSize: String,
  title: String,
  message: Node,
  children: any,
  onClose: Function,
  onConfirm: Function,
};

class ConfirmationModal extends React.PureComponent {
  props: PropTypes;

  state = {};

  render() {
    return (
      <Modal
        show={this.props.isOpen}
        dialogClassName={this.props.className ? `bc ${this.props.className}` : 'bc'}
        bsSize={get(this.props, 'modalSize', 'small')}
        onHide={this.props.onClose}
      >
        <Modal.Header bsClass="bc-modal-header">
          <Modal.Title>{this.props.title}</Modal.Title>
          <button type="button" className="btn btn-flat btn-icon-36 btn-secondary" onClick={this.props.onClose}>
            <i className="icon icn-icon-table-delete" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body-message">
            {this.props.message}
            {this.props.children}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-flat btn-secondary"
            onClick={this.props.onClose}
            automation-id="no-confirm"
          >
            {get(this.props, 'buttons[0]', 'NO')}
          </button>
          <button
            type="button"
            className="btn btn-flat btn-primary"
            onClick={this.props.onConfirm}
            automation-id="yes-confirm"
          >
            {get(this.props, 'buttons[1]', 'YES')}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmationModal;
