// @flow
import React, {Fragment, useState, useRef, useCallback, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {get} from 'lodash';
import {Transition} from 'react-transition-group';
import useClickOutside from 'common/hooks/useClickOutside';
import useKeydown from 'common/hooks/useKeydown';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {DEFAULT_QUERY_PARAMS} from 'alerts.console/services/alertsConsoleService';
import * as selectors from 'insightsPanel/store/selectors';
import * as profileSelectors from 'profile/store/selectors';
import {segmentClickEvent} from 'common/store/actions';
import * as actions from 'insightsPanel/store/actions';
import {panelSliding, numLastDaysOptions} from 'insightsPanel/services/constants';
import InsightsWidgets from './widgets/InsightsWidgets';
import './InsightsPanel.module.scss';
import SmartTooltip from '../common/componentsV2/Tooltip';

type PropTypes = {
  enabled: Boolean,
  setQueryParams: Function,
};

const InsightsPanel = (props: PropTypes) => {
  const dispatch = useDispatch();
  const numLastDays = useSelector(selectors.getNumLastDays);
  const insightsPanelAppSettings = useSelector(profileSelectors.getInsightsPanel);
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [isWidgetsRender, setIsWidgetsRender] = useState(false);

  const segmentTracking = (name) => {
    dispatch(
      segmentClickEvent({
        category: 'Insights Panel',
        name,
      }),
    );
  };

  const togglePanel = (open) => {
    if (open) {
      setIsPanelVisible(true);
      setIsWidgetsRender(true);
      dispatch(actions.setIsPanelOpen(true));
      segmentTracking('open');
    } else {
      setIsPanelVisible(false);
      dispatch(actions.setIsPanelOpen(false));
    }
  };

  const setDate = (dateObj, updateUserSettings = false) => {
    dispatch(actions.setNumLastDays(dateObj));
    if (updateUserSettings) dispatch(actions.updateInsightsPanelTime(dateObj)); // save the time range in appSettings.
    if (isPanelVisible) {
      segmentTracking(dateObj.segmentName);
    }
  };

  const ref = useRef();

  const closePanel = useCallback(() => togglePanel(false));

  const panelShortCut = () => {
    if (!isPanelVisible) {
      togglePanel(true);
    } else {
      togglePanel(false);
    }
  };

  useClickOutside(ref, () => closePanel(), [...numLastDaysOptions.map((i) => i.label), 'icon icn-general16-checkmark']);

  useKeydown(panelShortCut, 80); // 80 = p

  const filterAlerts = (queryObj) => {
    const date = {
      constRange: numLastDays.constRange,
      startTime: null,
      endTime: null,
    };
    const mergedQueryParams = {
      ...DEFAULT_QUERY_PARAMS,
      ...date,
      ...queryObj,
    };
    props.setQueryParams(mergedQueryParams);
  };

  const setDefaultTimeRange = () => {
    const dateObj = get(insightsPanelAppSettings, 'timeRange', null);
    if (dateObj) {
      setDate(dateObj);
    } else {
      setDate(numLastDaysOptions[0]);
    }
  };

  useEffect(() => {
    setDefaultTimeRange();
  }, []);

  return props.enabled ? (
    <Fragment>
      <SmartTooltip content="Insights Panel (p)" placement="left" delay={100}>
        <div styleName="open-panel-btn" onClick={() => togglePanel(true)}>
          <i className="icon icn-general24-insight" />
        </div>
      </SmartTooltip>

      <Transition in={isPanelVisible} timeout={0}>
        {(state) => (
          <div
            styleName="root"
            ref={ref}
            style={{...panelSliding.defaultStyle, ...panelSliding.transitionStyles[state]}}
          >
            <header>
              <SelectAndt
                id="AlertInsightsDate"
                buttonWidth={130}
                automationId="AlertInsightsDate-dropdown"
                extraClassName="alerts-dropdown-btn"
                options={numLastDaysOptions}
                optionHeight={40}
                type={TYPE_NEW_NO_SEARCH}
                theme={THEME_BLUE_LEAN}
                onChange={(item) => {
                  setDate(item, true);
                }}
                value={numLastDays}
                placeholder="All"
              />
            </header>
            {isWidgetsRender && <InsightsWidgets onFilterAlerts={filterAlerts} />}
          </div>
        )}
      </Transition>
    </Fragment>
  ) : null;
};

export default InsightsPanel;
