/* eslint-disable no-param-reassign */
import {combineEpics} from 'redux-observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import * as api from 'metrics/services/api';
import {getUniqueId} from 'common/utils/guid';
import {get} from 'lodash';
import {makeAsyncEpic, makeFlatAsyncEpic} from 'common/utils/simplifiedAsync';
import {treeVisitor} from 'metrics/services/metricsService';
import * as selectors from '../selectors';
import * as actions from '../actions';
import {success} from '../../../common/utils/notifications/notificationsService';

const fetchPropAndVal = makeAsyncEpic(actions.fetchPropAndVal, api.fetchPropAndVal);
const fetchPropAndValListApi = makeFlatAsyncEpic(actions.fetchPropAndValListApi, api.fetchPropAndValListApi);
const fetchOriginInfo = makeAsyncEpic(actions.fetchOriginInfo, api.fetchOriginInfo);
const fetchPropsApi = makeFlatAsyncEpic(actions.fetchPropsApi, api.fetchPropsApi);
const execution = makeFlatAsyncEpic(actions.execute, api.execute);
const validation = makeAsyncEpic(actions.validate, api.validate);
const fetchFunctionsMetaDataApi = makeAsyncEpic(actions.fetchFunctionsMetaDataApi, api.fetchFunctionsMetaData);
const fetchComposites = makeAsyncEpic(actions.fetchComposites, api.fetchComposites);
const createComposite = makeAsyncEpic(actions.createComposite, api.createComposite);
const fetchPropertiesApi = makeFlatAsyncEpic(actions.fetchPropertiesApi, api.fetchPropertiesApi);
const fetchCompositeName = makeAsyncEpic(actions.fetchCompositeName, api.fetchCompositeName);
const fetchLastUsedMeasures = makeAsyncEpic(actions.fetchLastUsedMeasures, api.fetchLastUsedMeasures);

const setLastExpressionSearch = makeAsyncEpic(actions.setLastExpressionSearch, api.setLastExpression);
const fetchLastExpressionSearches = makeAsyncEpic(actions.fetchLastExpressionSearches, api.fetchLastExpressionSearches);

const createCompositeSuccess = (action$) =>
  action$.ofType(actions.createComposite.success.TYPE).flatMap(() => {
    const successObj = {
      title: 'Saved to Composite',
      description: `The expression was saved as Composite`,

      settings: {
        uid: getUniqueId(),
      },
    };
    return [success(successObj)];
  });

const fetchFunctionsMetaData = (action$, {getState}) =>
  action$.ofType(actions.fetchFunctionsMetaData.TYPE).flatMap(({payload, meta}) => {
    const functionsMetaData = selectors.getFunctionDefinitions(getState());
    if (functionsMetaData && functionsMetaData.length > 0) {
      return [];
    }
    return [actions.fetchFunctionsMetaDataApi(payload, meta)];
  });

const isCacheStillValid = (cacheDataObj) => {
  if (!cacheDataObj) {
    return false;
  }

  const VALIDATION_LIMIT = 1000 * 60 * 2;
  const validationTime = new Date().getTime() - VALIDATION_LIMIT;
  return cacheDataObj.time > validationTime;
};

const fetchProps = (action$, {getState}) =>
  action$.ofType(actions.fetchProps.TYPE).flatMap(({payload, meta}) => {
    const cacheData = selectors.getCacheData(getState());
    const currentCallCacheData = meta === 'noCache' ? null : cacheData.find((obj) => obj.index === meta);
    if (currentCallCacheData) {
      if (isCacheStillValid(currentCallCacheData)) {
        return [actions.retrievePropsCached(currentCallCacheData)]; // clean if needed
      }
      return [
        actions.retrievePropsCached(currentCallCacheData), // clean if needed
        actions.fetchPropsApi(payload, meta), // fetch new
      ];
    }
    return [actions.fetchPropsApi(payload, meta)];
  });

const fetchProperties = (action$, {getState}) =>
  action$.ofType(actions.fetchProperties.TYPE).flatMap(({payload}) => {
    const expressionTree = selectors.getExpressionTree(getState());
    const functionNode = {root: {}};
    treeVisitor(expressionTree.root, 'children', (childNode) => {
      if (childNode.id === payload) {
        functionNode.root = childNode;
      }
    });
    const body = {
      // TODO -- GABPAC -- fill it all with real data, not only the expression tree
      name: {auto: true, prefix: null},
      displayOnly: true,
      excludeComposites: true,
      filter: {
        function: 'alphanumeric',
        parameters: [{name: 'Top N', value: 10}],
        children: [],
        id: getUniqueId(),
        type: 'function',
      },
      expressionTree: functionNode,
      scalarTransforms: [
        {
          function: 'current',
          children: [],
          id: getUniqueId(),
          parameters: [],
          type: 'function',
        },
      ],
      context: '',
    };
    return [actions.fetchPropertiesApi(body, payload)];
  });

const fetchPropAndValList = (action$, {getState}) =>
  action$.ofType(actions.fetchPropAndValList.TYPE).flatMap(({payload, meta}) => {
    const cacheData = selectors.getCacheData(getState());
    const currentCallCacheData = meta === 'noCache' ? null : cacheData.find((obj) => obj.index === meta);

    if (currentCallCacheData) {
      if (isCacheStillValid(currentCallCacheData)) {
        return [actions.retrievePropAndValListCached(currentCallCacheData)]; // clean if needed
      }
      return [
        actions.retrievePropAndValListCached(currentCallCacheData), // clean if needed
        actions.fetchPropAndValListApi(payload, meta), // fetch new
      ];
    }
    return [actions.fetchPropAndValListApi(payload, meta)];
  });

const processTreeOutsideState = (action$) =>
  action$
    .ofType(actions.processTreeOutsideState.TYPE)
    .flatMap((action) => [actions.setTreeOutsideState(action.payload)]);

const resetPagination = (action$, {dispatch, getState}) =>
  action$
    .ofType(
      actions.setExpression.TYPE,
      actions.setFunctionParameter.TYPE,
      actions.setFunction.TYPE,
      actions.deleteFunction.TYPE,
      actions.createNewFunction.TYPE,
      actions.selectTreeBranch.TYPE,
      actions.retrieveStoredState.TYPE,
    )
    .flatMap((action) => {
      const selectedBranchId = get(selectors.getSelectedBranch(getState()), 'root.id');

      if (action.type === actions.setFunctionParameter.TYPE && !action.payload.newValue) {
        return [];
      }

      if (action.type === actions.setExpression.TYPE && action.payload.value.some((item) => item.value === '')) {
        return [];
      }

      if (selectedBranchId !== action.payload) {
        dispatch(actions.resetPreviewPagination());
      }
      return [];
    });

const expressionBuilderEpic = combineEpics(
  resetPagination,
  fetchPropAndVal,
  fetchProps,
  fetchPropsApi,
  fetchPropAndValListApi,
  fetchPropAndValList,
  execution,
  validation,
  fetchFunctionsMetaData,
  fetchFunctionsMetaDataApi,
  fetchPropertiesApi,
  fetchProperties,
  processTreeOutsideState,
  fetchCompositeName,
  fetchOriginInfo,
  fetchComposites,
  createComposite,
  createCompositeSuccess,
  setLastExpressionSearch,
  fetchLastExpressionSearches,
  fetchLastUsedMeasures,
);
export default expressionBuilderEpic;
