// @flow
/* eslint-disable */
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import Loading from 'metrics/components/Loading';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import SideBarTabs from 'common/componentsV2/sideBarTabs/SideBarTabs';
import CopyToClipboardButton from 'common/components/CopyToClipboardButton';
import {TinyScrollBox} from 'common/componentsV2/boxTools';
import {bcTypes, getTypeDetails} from 'bc/services/bcTypes';
import {makeSupportLink} from 'common/utils/browserLoacation';
import {
  fetchDataStreamCardinality as fetchDataStreamCardinalityAction,
  getViewStreamSummaryDimetricsTimeDefinition as getViewStreamSummaryDimetricsTimeDefinitionAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import * as commonSelectors from 'profile/store/selectors';
import ImpactsList from 'impact/ImpactsList';
import {isAgent, isAgentMetrics30, isStreamRunning} from 'bc/services/dataStreamService';
import SchedulerSection from './SchedulerSection';
import DataSection from './DataSection';
import LastRunData from './LastRunData';
import CreatedSection from './CreatedSection';
import MeasuresDimesionsData from './MeasuresDimesionsData';
import InfoSection from './infoTab/InfoSection';
import SimpleTable from './SimpleTable';
import './ViewStreamSummaryModalV2.module.scss';

const TABS = {
  overview: {
    id: 'overview',
    label: 'Overview',
  },
  query: {
    id: 'query',
    label: 'Query',
  },
  log: {
    id: 'log',
    label: 'Collection Log',
  },
  info: {
    id: 'info',
    label: 'Alerts & Dashboards',
  },
  impact: {
    id: 'impact',
    label: 'Impact',
  },
};
const AGENTS_TABS = {
  overview: {
    id: 'overview',
    label: 'Overview',
  },
};

const getTabList = (dataStream, isImpactEnabled, isAgentDisp, isAgentMetrics30) => {
  const {query, impact, ...rest} = TABS;
  if (isAgentDisp && isAgentMetrics30 && isImpactEnabled) {
    return {...rest, impact};
  }
  if (isAgentDisp && isAgentMetrics30) {
    return {...rest};
  }
  if (isAgentDisp) {
    return AGENTS_TABS;
  }
  if ((dataStream.customQuery || dataStream.type === bcTypes.bigquery.type) && isImpactEnabled) {
    return TABS;
  }
  if ((dataStream.customQuery || dataStream.type === bcTypes.bigquery.type) && !isImpactEnabled) {
    return {...rest, query};
  }
  if (!dataStream.customQuery && dataStream.type !== bcTypes.bigquery.type && isImpactEnabled) {
    return {...rest, impact};
  }
  return rest;
};

const MetricsAmount = (props: {current: string, startTime: string, endTime: string}) => (
  <div styleName="metric-amount">
    <div styleName="amount">{props.current || props.current === 0 ? props.current : <Loading />}</div>
    <div styleName="amount-title">Metrics</div>
    {props.startTime && props.endTime ? (
      <div styleName="amount-subtitle">{`(Calculated from ${props.startTime} to ${props.endTime})`}</div>
    ) : null}
  </div>
);

const DimReduce = () => (
  <div styleName="dim-reduce">
    <div styleName="icon" className="icon icn-general16-info" />
    <span>Dimension to Measure mapping rules are applied on this stream.</span>
    <a
      href={makeSupportLink('https://support.anodot.com/hc/en-us/articles/360009537879')}
      rel="noopener noreferrer"
      target="_blank"
    >
      Learn More
    </a>
  </div>
);

const OverviewTab = (props: {
  metricsTotal: Number,
  metricsTotalStartTime: string,
  metricsTotalEndTime: string,
  dataStream: Object,
  timeZoneName: String,
  dataSource: Object,
  timeColumn: String,
  isTimeDefinitionLoading: boolean,
  selectedTabId: String,
  tabList: Array,
}) => {
  if (props.selectedTabId !== props.tabList.overview.id) {
    return null;
  }

  return (
    <div styleName="overview-tab">
      <TinyScrollBox width={280} pr={1.25} height={1} css={{overflowY: 'auto', overflowX: 'hidden', flexShrink: 0}}>
        <MetricsAmount
          current={props.metricsTotal !== 0 ? props.metricsTotal : 'NA'}
          startTime={props.metricsTotalStartTime}
          endTime={props.metricsTotalEndTime}
        />
        {props.dataStream.hasDimreduce && <DimReduce />}
        <CreatedSection timeZoneName={props.timeZoneName} dataStream={props.dataStream} />
        <DataSection dataStream={props.dataStream} dataSource={props.dataSource} />
        <SchedulerSection
          dataStream={props.dataStream}
          timeZoneName={props.timeZoneName}
          timeColumn={props.timeColumn}
          isTimeDefinitionLoading={props.isTimeDefinitionLoading}
        />
        {!isAgent(props.dataStream.family) ? (
          <LastRunData dataStream={props.dataStream} timeZoneName={props.timeZoneName} />
        ) : null}
      </TinyScrollBox>
      <TinyScrollBox width={1} pl={3} height={1} css={{overflowY: 'auto', overflowX: 'hidden', flexGrow: 1}}>
        <MeasuresDimesionsData />
      </TinyScrollBox>
    </div>
  );
};

const InfoTab = (props: {dataStream: Object, selectedTabId: String, tabList: Array}) => {
  if (!props.tabList.info || props.selectedTabId !== props.tabList.info.id) {
    return null;
  }

  return (
    <div styleName="info-tab">
      <InfoSection dataStreamId={isAgent(props.dataStream.family) ? props.dataStream.schemaId : props.dataStream.id} />
    </div>
  );
};

const HistoryLog = (props: {dataStream: Object, selectedTabId: String, tabList: Array, timeZoneName: string}) => {
  if (!props.tabList.log || props.selectedTabId !== props.tabList.log.id) {
    return null;
  }

  return <SimpleTable dataStream={props.dataStream} timeZoneName={props.timeZoneName} />;
};

const QueryTab = (props: {dataStream: Object, selectedTabId: String, tabList: Array}) => {
  if (isAgent(props.dataStream.family) || !props.tabList.query || props.selectedTabId !== props.tabList.query.id) {
    return null;
  }

  return (
    <div styleName="query-tab">
      <div styleName="query-text">{props.dataStream.query}</div>
      <div styleName="copy-link-wrapper">
        <div>
          <CopyToClipboardButton copyStr={props.dataStream.query} tooltipMessage="Copy Query">
            <div styleName="copy-link">
              <i className="icon icn-action16-copy" />
              <span>Copy Text</span>
            </div>
          </CopyToClipboardButton>
        </div>
      </div>
    </div>
  );
};

const ImpactsTab = (props: {selectedTabId: String, tabList: Array}) => {
  if (!props.tabList.impact || props.selectedTabId !== props.tabList.impact.id) {
    return null;
  }
  return (
    <div styleName="overview-tab">
      <TinyScrollBox width="100%" pr={1.25} height={1} css={{overflowY: 'auto', overflowX: 'hidden', flexShrink: 0}}>
        <ImpactsList />
      </TinyScrollBox>
    </div>
  );
};

type PropTypes = {
  isOpen: Boolean,
  onClose: Function,

  dataStream: Object,
  dataSource: Object,
  metricsTotal: Number,
  timeZoneName: String,
  timeColumn: String,
  isTimeDefinitionLoading: boolean,
  fetchDataStreamCardinality: Function,
  getViewStreamSummaryDimetricsTimeDefinition: Function,
  isImpactEnabled: boolean,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    dataSource: selectors.getSelectedDataStreamSource(state),
    metricsTotal: selectors.getSelectedStreamCardinalityTotal(state),
    timeZoneName: commonSelectors.getTimeZoneName(state),
    timeColumn: selectors.getStreamSummaryTimeDefintionTimeColumn(state),
    isTimeDefinitionLoading: selectors.getStreamSummaryTimeDefintionIsLoading(state),
    isImpactEnabled: commonSelectors.getImpactEnabled(state),
  }),
  {
    fetchDataStreamCardinality: fetchDataStreamCardinalityAction,
    getViewStreamSummaryDimetricsTimeDefinition: getViewStreamSummaryDimetricsTimeDefinitionAction,
  },
)
export default class ViewStreamSummaryModalV2 extends PureComponent {
  props: PropTypes;

  constructor(props) {
    super(props);
    this.state = {
      typeDetails: getTypeDetails(this.props.dataSource.type),
      selectedTabId: TABS.overview.id,
      tabList: getTabList(
        this.props.dataStream,
        this.props.isImpactEnabled,
        isAgent(this.props.dataStream.family),
        isAgentMetrics30(this.props.dataStream.config),
      ),
      metricsTotalStartTime: null,
      metricsTotalEndTime: null,
    };
  }

  componentDidMount() {
    const {fetchDataStreamCardinality, getViewStreamSummaryDimetricsTimeDefinition, dataStream} = this.props;
    let metricAmountStartTime = moment().subtract(168, 'hours');
    const metricAmountEndTime = moment();

    const startOfMonth = moment().startOf('month');
    if (startOfMonth.unix() > metricAmountStartTime.unix()) {
      metricAmountStartTime = startOfMonth;
    }

    this.setState({
      metricsTotalStartTime: metricAmountStartTime.format('DD MMM.'),
      metricsTotalEndTime: metricAmountEndTime.format('DD MMM.'),
    });

    getViewStreamSummaryDimetricsTimeDefinition(dataStream.id);
    fetchDataStreamCardinality({
      startTime: metricAmountStartTime.unix(),
      endTime: metricAmountEndTime.unix(),
      stream: encodeURIComponent(dataStream.name.toLowerCase()),
    });
  }

  tabClicked = (tabId) => {
    if (tabId !== this.state.selectedTabId) {
      this.setState({
        selectedTabId: tabId,
      });
    }
  };

  render() {
    const {
      dataStream,
      dataSource,
      metricsTotal,
      timeZoneName,
      timeColumn,
      isTimeDefinitionLoading,
      isImpactEnabled,
    } = this.props;

    if (!dataStream || !dataSource) {
      return null;
    }

    TABS.log.isVisible = isStreamRunning(dataStream.state) && ![bcTypes.kinesis.type, bcTypes.eventhubs.type].includes(dataStream.type);
    const {typeDetails, selectedTabId, tabList, metricsTotalStartTime, metricsTotalEndTime} = this.state;
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          onClose={this.props.onClose}
          size={SIZES.XLARGE}
          isCloseButtonHidden
          styleName="vs2-modal-root"
        >
          <div styleName="header">
            <div styleName="title">
              <span styleName="stream-name">{dataStream.name}</span>
              <span styleName="stream-source">
                <div className={`image-${typeDetails.iconStyle}`} styleName="type-image" />
                {dataSource.name}
              </span>
            </div>
            <i className="icon icn-general16-closea" onClick={this.props.onClose} />
          </div>

          <div styleName="body">
            <div styleName="sidebar">
              <SideBarTabs tabs={Object.values(tabList)} selectedTabId={selectedTabId} onTabClick={this.tabClicked} />
            </div>
            <div styleName="main-view">
              <OverviewTab
                dataStream={dataStream}
                dataSource={dataSource}
                isAgent={isAgent(dataStream.family)}
                metricsTotal={metricsTotal}
                metricsTotalStartTime={metricsTotalStartTime}
                metricsTotalEndTime={metricsTotalEndTime}
                timeZoneName={timeZoneName}
                timeColumn={timeColumn}
                isTimeDefinitionLoading={isTimeDefinitionLoading}
                selectedTabId={selectedTabId}
                tabList={tabList}
              />
              <QueryTab
                dataStream={dataStream}
                selectedTabId={selectedTabId}
                tabList={tabList}
                isAgent={isAgent(dataStream.family)}
              />
              <InfoTab
                dataStream={dataStream}
                selectedTabId={selectedTabId}
                tabList={tabList}
                isAgent={isAgent(dataStream.family)}
              />
              <HistoryLog
                dataStream={dataStream}
                selectedTabId={selectedTabId}
                tabList={tabList}
                timeZoneName={timeZoneName}
                isAgent={isAgent(dataStream.family)}
              />
              {isImpactEnabled && (
                <ImpactsTab dataStream={dataStream} selectedTabId={selectedTabId} tabList={tabList} />
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
/* eslint-enable */
