// @flow

import React from 'react';
import {connect} from 'react-redux';

import ConfirmationModal from 'common/components/modals/ConfirmationModal';

import SimpleCollectionTitles from 'common/components/simpleCollectionMng/SimpleCollectionTitles';
import SimpleCollectionMng from 'common/components/simpleCollectionMng/SimpleCollectionMng';
import {fetchUserEventsSources, crateUserEventsSource, deleteUserEventsSource} from '../store/actions';
import * as selectors from '../store/selectors';

import CreateNewEvent from '../components/createNewEvent/CreateNewEvent';
import UserEventsSourceRow from '../components/userEventRow/UserEventsRow';

import './userEvents.module.scss';

type PropsType = {
  sources: Array<Object>,
  isLoading: boolean,

  fetchUserEventsSources: Function,
  crateUserEventsSource: Function,
  deleteUserEventsSource: Function,

  isCreateLoading: boolean,
  isDeleteLoading: boolean,
};

@connect(
  (state) => ({
    sources: selectors.getUserEventsSourcesItems(state),
    isLoading: selectors.getUserEventsSourcesIsLoading(state),
    isCreateLoading: selectors.getCreateUserEventsSource(state),
  }),
  {
    fetchUserEventsSources,
    crateUserEventsSource,
    deleteUserEventsSource,
  },
)
export default class UserEventsSources extends React.PureComponent {
  props: PropsType;

  state = {
    isModalOpen: false,
  };

  componentDidMount() {
    this.props.fetchUserEventsSources();
  }

  deleteCategoryName = '';

  deleteCategoryId = '';

  closeModal = () => {
    this.setState({isModalOpen: false});
  };

  openModal = () => {
    this.setState({isModalOpen: true});
  };

  onConfirmModal = () => {
    this.props.deleteUserEventsSource(this.deleteCategoryId, this.deleteCategoryId);
    this.setState({isModalOpen: false});
  };

  deleteRow = (categoryId, categoryName) => {
    this.deleteCategoryId = categoryId;
    this.deleteCategoryName = categoryName;
    this.openModal(categoryId);
  };

  createNewItem = (name, img) => {
    const newSource = {
      source: {
        name,
        imageUrl: img.length === 0 ? undefined : img,
        owner: 'user',
      },
    };
    this.props.crateUserEventsSource(newSource);
  };

  render() {
    const {isLoading, sources, isCreateLoading, isDeleteLoading} = this.props;

    return (
      <div styleName="main-container" className="user-categories">
        <div styleName="events-wrapper">
          <CreateNewEvent onCreateNewItem={this.createNewItem} isLoading={isCreateLoading} submitName="Add Source" />

          <SimpleCollectionMng
            rowIdentifierName="id"
            name="name"
            items={sources}
            onDelete={this.deleteRow}
            titlesNode={<SimpleCollectionTitles />}
            itemNode={<UserEventsSourceRow />}
            mainLoading={isDeleteLoading}
            isLoading={isLoading}
          />

          <ConfirmationModal
            onClose={this.closeModal}
            onConfirm={this.onConfirmModal}
            title="Delete event source"
            message={`Are you sure you want to delete ${this.deleteCategoryName}?`}
            isOpen={this.state.isModalOpen}
          />
        </div>
      </div>
    );
  }
}
