/* eslint-disable */
import * as actions from 'alerts.management/store/actions';
import {stringifyQS} from 'common/utils/http';
import {difference, pickBy} from 'lodash';
import * as api from 'alerts.management/services/api';
import {Observable} from 'rxjs/Observable';
import {combineEpics} from 'redux-observable';
import * as chartActions from 'charts/timeSeries/store/actions';
import {generateChartSeriesMetricModel, processSeriesData} from 'charts/timeSeries/services/timeSeriesDataService';
import {addPlotLine, endLoad, pushSeries} from 'charts/timeSeries/services/timeSeriesHchartService';
import * as amSelectors from 'alerts.management/store/selectors';
import * as profileSelectors from 'profile/store/selectors';
import {getThresholdPlotLineConfig} from 'charts/timeSeries/services/timeSeriesHchartSettingsService';
import * as selectors from 'metrics/store/selectors';
import Highcharts from 'highcharts';
import {getUniqueId} from '../../../common/utils/guid';
import {getContextConditionsForType} from '../../services/alertsService';
import {makeFlatAsyncEpic} from '../../../common/utils/simplifiedAsync';

const fetchSimulationData = makeFlatAsyncEpic(actions.fetchSimulationData, api.simulationExecute);

const executeAlertSimulation = (action$, {getState}) =>
  action$
    .ofType(actions.executeAlertSimulation.TYPE)
    .debounceTime(500)
    .switchMap((action) => {
      if (
        !location.href.includes('/alert-manager/clone/') &&
        !location.href.includes('/alert-manager/edit/') &&
        !location.href.includes('/alert-manager/new')
      ) {
        // TODO: remove this workaround
        return [];
      }

      const previewOptions = selectors.getPreviewOptions(getState());

      const {alert, formValues} = action.payload;
      const index = action.type === actions.selectedAlertFilterChanged.TYPE ? 0 : action.payload.index || 0;

      const volumeConEnabled = profileSelectors.getAutoVolumeEnabled(getState());
      let allowedConditions = amSelectors.getConditions(getState());
      if (!volumeConEnabled) {
        allowedConditions = allowedConditions.filter((con) => con.type !== 'VOLUME_CONDITION');
      }

      const filter = amSelectors.getSelectedAlertSimulationFilters(getState());
      const expressionTreeModel = selectors.getExpressionTreeModelPayload(getState());

      const contextConditions = getContextConditionsForType(filter.type.value);
      const stateConditions = allowedConditions.map((condition) => condition.type);
      const toRemove = difference(stateConditions, contextConditions);
      allowedConditions = allowedConditions.filter((condition) => !toRemove.includes(condition.type));

      const body = {
        settings: {
          type: filter.type.value,
          rollup: filter.rollup,
          aggregateAnomalies: action.payload.quickSimMode || false,
          isInfluencingEvents: formValues.isInfluencingEvents || false,
          conditions: allowedConditions,
          userEventsRequest: {
            filter: {
              categories: [],
              q: {
                q: null,
                expression: formValues.events.map((item) => item.getExpressionTreeObjectification()),
                ids: null,
              },
            },
            aggregation: {
              aggregationField: null,
              resolution: filter.rollup,
              topEventSize: 0,
              maxBuckets: 100,
            },
          },
        },
        query: {
          composite: {
            ...expressionTreeModel,
            excludeComposites: !formValues.showComposites,
            namingSchema: 'COMPOSITE_V2',
            mtype: 'ALERT',
            context: `alertId=${alert.id || 'new alert'}.alertName=${formValues.title}.context=alertEditor`,
            filter: {
              function: previewOptions.sort,
              parameters: [
                {
                  name: 'Top N',
                  value: previewOptions.show,
                },
              ],
              children: [],
              id: getUniqueId(),
              type: 'function',
            },
          },
        },
      };

      const urlExt = stringifyQS(
        pickBy(
          {
            startBucketMode: action.payload.startBucketMode,
            analysisToDate: action.payload.analysisToDate,
            index: action.payload.index,
            size: action.payload.quickSimMode ? 1 : action.payload.size || 10,
            // quickSimMode: action.payload.quickSimMode || undefined,
            fromDate: action.payload.quickSimMode ? 1 : undefined,
          },
          (item) => item !== undefined,
        ),
      );

      return api
        .simulationExecute({...action, payload: {body, urlExt}})
        .map((payload) => actions.executeAlertSimulation.success(payload, {...action.meta, index}))
        .catch((error) => Observable.of(actions.executeAlertSimulation.failure(error, action.meta)));
    });

const highChartCreated = (action$, {getState}) =>
  action$
    .ofType(chartActions.highChartCreated.TYPE)
    .do((action) => {
      const {theme} = action.payload;
      const previewOptions = selectors.getPreviewOptions(getState());

      if (!action.payload.chartId.startsWith('simulation-chart-')) {
        return;
      }

      const hchart = Highcharts.charts.find((chart) => chart && chart.renderTo.id === action.payload.chartId);

      const fromDate = Math.ceil(previewOptions.dateRange.startDate / 1000);
      const toDate = Math.ceil(previewOptions.dateRange.endDate / 1000);
      const metricId = action.payload.chartId.split('simulation-chart-')[1];
      const metricData = amSelectors
        .getAlertSimulation(getState())
        .data.metrics.find((metric) => metric.id === metricId);

      const metricsModel = generateChartSeriesMetricModel(metricData);

      const anomalies = metricData.anomalies
        ? metricData.anomalies.map((anomaly) => ({
            ...anomaly,
            status: 'CLOSE',
            startTime: anomaly.fromDate,
            endTime: anomaly.toDate,
            direction: anomaly.direction ? anomaly.direction.toUpperCase() : '',
          }))
        : [];

      const processedSeriesData = processSeriesData(
        metricData.dataPoints,
        metricData.baseline,
        anomalies.filter((anomaly) => anomaly.meetsCondition),
        anomalies.filter((anomaly) => !anomaly.meetsCondition),
      );
      pushSeries(hchart, {byTreeExp: []}, metricsModel, processedSeriesData, true, theme);

      const filter = amSelectors.getSelectedAlertSimulationFilters(getState());

      const threshold = filter.conditions.find((condition) => condition.type === 'THRESHOLD_CONDITION');
      if (threshold) {
        const metricThreshold = {};
        if (threshold.minValue) {
          metricThreshold[threshold.lowerBound] = threshold.minValue;
        }
        if (threshold.maxValue) {
          metricThreshold[threshold.upperBound] = threshold.maxValue;
        }
        Object.keys(metricThreshold).forEach((key, index) => {
          const thresholdConfig = getThresholdPlotLineConfig();
          thresholdConfig.id = `threshold_${index}`;
          thresholdConfig.value = metricThreshold[key];
          thresholdConfig.label.text = key;
          addPlotLine(hchart, thresholdConfig);
        });
      }

      endLoad(hchart, fromDate, toDate);
    })
    .flatMap(() => []);

const simulationEpic = combineEpics(executeAlertSimulation, highChartCreated, fetchSimulationData);

export default simulationEpic;
/* eslint-enable */
