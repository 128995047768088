// @flow
/* eslint-disable max-len */
import React, {Fragment} from 'react';
import {isEqual} from 'lodash';
import {Box} from '@material-ui/core';
import Checkbox from 'common/componentsV2/Checkbox';
import {TypographyBox} from 'common/componentsV2/boxTools';
import TooltipArea from 'common/componentsV2/TooltipArea';
import SelectDimensions from 'alerts.management/components/editor/simulationArea/dynamicRouting/SelectDimensions';
import RoutingModal from 'alerts.management/components/editor/simulationArea/dynamicRouting/RoutingModal';
import {makeStyles} from '@material-ui/core/styles';
import {useField} from 'react-final-form';

const useStyles = makeStyles(() => ({
  dimensionWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
}));

const tooltipText = (
  <React.Fragment>
    Dynamic Routing (mail only)
    <br />
    Dynamic routing enables alert triggers to be sent to different recipients based on specified dimension values
    configured for the alert. For example, you can configure an alert to be sent to Person A if its origin is in the USA
    and to Person B if its origin is in Canada.
  </React.Fragment>
);

const DynamicRouting = () => {
  const classes = useStyles();
  const {
    meta: {touched, error},
  } = useField('dynamicRouting', {isEqual});

  const {
    input: {value: isInUseDynamicRouting, onChange: onChangeIsInUseDynamicRouting},
  } = useField('dynamicRouting.isInUseDynamicRouting');

  const {
    input: {value: selectedDimension, onChange: onChangeSelectedDimension},
  } = useField('dynamicRouting.selectedDimension');

  const {
    input: {value: selectedRoutingFile, onChange: onChangeSelectedRoutingFile},
  } = useField('dynamicRouting.selectedRoutingFile');

  return (
    <Fragment>
      <TooltipArea automationId="dynamicRoutingContainer" text={tooltipText}>
        {(info) => (
          <Checkbox isChecked={isInUseDynamicRouting} onChange={onChangeIsInUseDynamicRouting}>
            <div className="text16med lineHeight_16 ml_0-5 position_relative">
              Use Dynamic Routing for this alert {info}
            </div>
          </Checkbox>
        )}
      </TooltipArea>

      {isInUseDynamicRouting && (
        <Box mt={1.5} name="dynamicRouting">
          <Box className={classes.dimensionWrapper} mb={2}>
            <SelectDimensions value={selectedDimension} onChange={onChangeSelectedDimension} />
          </Box>
          <Box mt={2}>
            <RoutingModal value={selectedRoutingFile} onChange={onChangeSelectedRoutingFile} />
          </Box>
          {touched && error && (
            <TypographyBox variant="subtitle2" mt={1.5} color="red.500">
              {error}
            </TypographyBox>
          )}
        </Box>
      )}
    </Fragment>
  );
};

export default React.memo(DynamicRouting);
