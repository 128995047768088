import {useMemo} from 'react';
import {get, values} from 'lodash';
import useCompositeExecute from 'dashboards/hooks/useCompositeExecute';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const useExpressionTreesTile = ({tileData, chartId, timeScale, filters, dateRange, isVisible}) => {
  const isGraphTile = tileData && tileData.lineChart;
  const isStatTile = tileData && tileData.andtGauge;

  const {byTreeExp, expressionTrees} = useMemo(() => {
    const dataKey = isGraphTile ? 'lineChart' : 'andtGauge';
    if (isGraphTile || isStatTile) {
      return {
        expressionTrees: tileData[dataKey].expressionTrees,
        // resolution: tileData[dataKey].resolution.value,
        byTreeExp: tileData[dataKey].seriesProperties && tileData[dataKey].seriesProperties.byTreeExp,
      };
    }
    return {};
  }, [isGraphTile, tileData]);

  const {compositeDataMap, compositeErrorsMap, isCompositeDataLoading} = useCompositeExecute({
    refetchRelativeDateRange: true,
    expressionTrees,
    dateRange,
    filters,
    chartId,
    resolution: timeScale,
    isVisible,
    cacheOnlyLast: true,
  });

  const metrics = useMemo(
    () =>
      Object.entries(compositeDataMap).flatMap(([treeId, item]) =>
        (item.metrics || EMPTY_ARRAY).map((metric) => ({...metric, treeId, compositeObject: item.compositeObject})),
      ),
    [compositeDataMap],
  );

  const compositeData = Object.values(compositeDataMap)[0] || EMPTY_OBJECT;

  const totalAmount = compositeData.size;
  const providedResolution = compositeData.resolution;

  const validationMessage = get(values(get(compositeData, 'validation.failures')), '[0][0].message', '');
  const validationId = get(values(get(compositeData, 'validation.failures')), '[0][0].id', 0);

  return {
    metrics,
    byTreeExp,
    totalAmount,
    providedResolution,
    validationMessage: validationId === 25 ? undefined : validationMessage,
    isCompositeDataLoading,
    compositeErrorsMap,
  };
};

export default useExpressionTreesTile;
