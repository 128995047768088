// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {intersection} from 'lodash';
import Button, {COLORS as ButtonColors, HEIGHTS as ButtonHeights} from 'common/componentsV2/Button';
import ConfModal from 'common/componentsV2/modal/ConfModal';
import MailChipsInput, {getChipItem} from 'common/componentsV2/MailChipsInput';
import Info from 'common/componentsV2/Info';
import * as selectors from 'admin.users/store/selectors';
import {getCustomersData} from 'admin.customers/store/selectors';
import {isAnodot} from 'profile/store/selectors';
import {
  addToUsersList,
  removeFromUsersList,
  setNewUserModalOrg,
  setNewUserModalKeyVal,
  fetchGroupsEditNewUser,
  createUsers,
} from 'admin.users/store/actions';
import SelectAndt, {
  THEME_NOT_HIGHLIGHTED,
  TYPE_NEW_NO_SEARCH,
  TYPE_NEW_SEARCH,
  THEME_BLUE_LEAN,
  THEME_HIGHLIGHTED,
  TYPE_NEW_MULTI,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import './NewUserFrom.module.scss';
import {MAX_USERS_FOR_INVITE} from 'admin.users/services/usersAndGroupsService';

type PropTypes = {
  isCreateUsersLoading: boolean,
  isEmailExistOpen: boolean,
  emailFailures: Array,
  isMeAnodot: boolean,
  usersList: Array,
  message: String,
  inputValue: String,
  emailListError: String,
  customersData: Array,
  groupsList: Array,
  isGroupsLoading: boolean,
  selectedGroups: Array,
  selectedDefaultGroup: Object,
  allowedUserRoles: Array,
  selectedRole: Object,
  selectedOrg: Object,
  addToUsersList: Function,
  removeFromUsersList: Function,
  setNewUserModalOrg: Function,
  setNewUserModalKeyVal: Function,
  fetchGroupsEditNewUser: Function,
  createUsers: Function,
};

@connect(
  (state) => ({
    isCreateUsersLoading: selectors.createUsersIsLoading(state),
    isEmailExistOpen: selectors.getNewUserModalIsEmailExistOpen(state),
    emailFailures: selectors.createUsersDataValidationFailures(state),
    isMeAnodot: isAnodot(state),
    usersList: selectors.getNewUserModalUsersList(state),
    message: selectors.getNewUserModalUsersMessage(state),
    inputValue: selectors.getNewUserModalUsersInputValue(state),
    emailListError: selectors.getNewUserModalUsersListError(state),
    customersData: getCustomersData(state),
    groupsList: selectors.getGroupsDataList(state),
    isGroupsLoading: selectors.fetchGroupsEditNewUserIsLoading(state),
    selectedGroups: selectors.getNewUserModalUsersSelectedGroups(state),
    selectedDefaultGroup: selectors.getNewUserModalUsersSelectedDefaultGroup(state),
    allowedUserRoles: selectors.getNewUserModalAllowedRoles(state),
    selectedRole: selectors.getNewUserModalUsersSelectedRole(state),
    selectedOrg: selectors.getNewUserModalUsersSelectedOrg(state),
  }),
  {
    addToUsersList,
    removeFromUsersList,
    setNewUserModalOrg,
    setNewUserModalKeyVal,
    fetchGroupsEditNewUser,
    createUsers,
  },
)
export default class NewUserForm extends React.PureComponent {
  props: PropTypes;

  state = {
    validationError: '',
  };

  static getDerivedStateFromProps(props, state) {
    const {usersList} = props;
    if (usersList && usersList.length > MAX_USERS_FOR_INVITE) {
      return {
        ...state,
        validationError: `For security reasons, you can invite up to ${MAX_USERS_FOR_INVITE} users in a single invite.`,
      };
    }
    return {
      ...state,
      validationError: '',
    };
  }

  addMailChip = (item) => {
    this.props.addToUsersList(item);
  };

  removeMailChip = (item) => {
    this.props.removeFromUsersList(item);
  };

  onMessageChange = (e) => {
    this.props.setNewUserModalKeyVal({message: e.target.value});
  };

  onMailChipValueChange = (value) => {
    this.props.setNewUserModalKeyVal({inputValue: value});
  };

  userRoleSelected = (selectedRole) => {
    this.props.setNewUserModalKeyVal({selectedRole});
  };

  userOrgSelected = (selectedOrg) => {
    this.props.setNewUserModalOrg(selectedOrg);
    this.props.fetchGroupsEditNewUser({ownerOrganization: selectedOrg._id});
  };

  groupsSelected = (items) => {
    if (!items || !items.length) {
      this.props.setNewUserModalKeyVal({
        groups: [],
        defaultGroup: '',
      });
    } else if (items.length === 1) {
      this.props.setNewUserModalKeyVal({
        groups: [items[0].id],
        defaultGroup: items[0].id,
      });
    } else {
      const groups = items.map((item) => item.id);

      if (intersection([this.props.selectedDefaultGroup.id || ''], groups).length === 0) {
        this.props.setNewUserModalKeyVal({
          groups,
          defaultGroup: groups[0],
        });
      } else {
        this.props.setNewUserModalKeyVal({
          groups,
        });
      }
    }
  };

  userDefaultGroupSelected = (selectedDefaultGroup) => {
    this.props.setNewUserModalKeyVal({defaultGroup: selectedDefaultGroup.id});
  };

  inviteClicked = () => {
    const {
      message,
      inputValue,
      usersList,
      selectedOrg,
      selectedRole,
      selectedGroups,
      selectedDefaultGroup,
    } = this.props;

    if (!inputValue) {
      this.props.createUsers({
        emails: usersList.map((u) => u.value),
        groups: selectedGroups.length ? selectedGroups.map((item) => item.id) : [],
        defaultGroup: selectedDefaultGroup ? selectedDefaultGroup.id : '',
        role: selectedRole.role,
        ownerOrganization: selectedOrg ? selectedOrg._id : null,
        personalMsg: message,
      });
    } else {
      const chipItem = getChipItem(inputValue, usersList);
      if (chipItem && chipItem.error) {
        this.props.addToUsersList(chipItem);
      } else if (chipItem && !chipItem.error) {
        this.props.createUsers({
          emails: usersList.map((u) => u.value).concat(chipItem.value),
          groups: selectedGroups.length ? selectedGroups.map((item) => item.id) : [],
          defaultGroup: selectedDefaultGroup ? selectedDefaultGroup.id : '',
          role: selectedRole.role,
          ownerOrganization: selectedOrg ? selectedOrg._id : null,
          personalMsg: message,
        });
        this.props.addToUsersList(chipItem);
      }
    }
  };

  onEmailModalClose = () => {
    this.props.setNewUserModalKeyVal({isEmailExistOpen: false});
  };

  render() {
    const {
      isCreateUsersLoading,
      isEmailExistOpen,
      emailFailures,
      isMeAnodot,
      usersList,
      message,
      inputValue,
      emailListError,
      customersData,
      groupsList,
      isGroupsLoading,
      selectedGroups,
      selectedDefaultGroup,
      allowedUserRoles,
      selectedRole,
      selectedOrg,
    } = this.props;
    const {validationError} = this.state;

    return (
      <div styleName="container">
        <div styleName="header">New Users</div>
        <div styleName="row">
          <div styleName="label">Add users by mail:</div>
          <MailChipsInput
            value={inputValue}
            items={usersList}
            automationId="addUsersEmails"
            onAddItem={this.addMailChip}
            onRemoveItem={this.removeMailChip}
            onValueChange={this.onMailChipValueChange}
          />
          <div styleName="label error">{emailListError || this.state.validationError}</div>
        </div>
        <div styleName="row">
          <div styleName="label">Invitation Message</div>
          <textarea
            styleName="message-input"
            automation-id="invitationMessage"
            value={message}
            rows="3"
            placeholder="Will show up in the invitation mail"
            onChange={this.onMessageChange}
          />
        </div>
        {isMeAnodot && !!customersData.length && (
          <div styleName="row">
            <div styleName="label">Customer</div>
            <SelectAndt
              automationId="selectCustomer"
              type={TYPE_NEW_SEARCH}
              theme={THEME_NOT_HIGHLIGHTED}
              onChange={this.userOrgSelected}
              options={customersData}
              placeholder="Select"
              buttonWidth={230}
              optionHeight={40}
              getOptionLabel={(val) => val.name}
              getOptionValue={(val) => val._id}
            />
          </div>
        )}
        <div styleName="roles-group-row">
          <div styleName="roles">
            <div styleName="label">
              <span>Roles</span>
              <a
                href="https://support.anodot.com/hc/en-us/articles/360022482333-Roles-and-Permissions-"
                target="_blank"
                rel="noreferrer noopener"
                styleName="info-wrapper"
              >
                <Info text="Learn More About Roles" styleName="info" />
              </a>
            </div>
            <SelectAndt
              automationId="selectUserRole"
              type={TYPE_NEW_NO_SEARCH}
              theme={THEME_NOT_HIGHLIGHTED}
              onChange={this.userRoleSelected}
              options={allowedUserRoles}
              value={selectedRole}
              placeholder="Select"
              optionHeight={40}
              getOptionLabel={(val) => val.name}
              getOptionValue={(val) => val.role}
            />
          </div>
          <div styleName="groups">
            <div styleName="label">
              Assign to Groups
              <span styleName="sub">Optional</span>
            </div>
            <SelectAndt
              automationId="addGroupsNewUsersModal"
              type={TYPE_NEW_MULTI}
              theme={THEME_HIGHLIGHTED}
              onChange={this.groupsSelected}
              options={groupsList}
              value={selectedGroups}
              isMulti
              placeholder="Select"
              optionHeight={40}
              isClearable={false}
              closeMenuOnSelect={false}
              getOptionLabel={(val) => val.name}
              getOptionValue={(val) => val.id}
              isLoading={isGroupsLoading}
              disabled={isGroupsLoading}
            />
          </div>
        </div>
        {selectedGroups.length ? (
          <div styleName="default-group-row">
            <div styleName="label">Primary Group is</div>
            <SelectAndt
              automationId="newUsersModalSelectDefaultGroup"
              type={TYPE_NEW_NO_SEARCH}
              theme={THEME_BLUE_LEAN}
              onChange={this.userDefaultGroupSelected}
              options={selectedGroups}
              value={selectedDefaultGroup}
              placeholder="Select"
              optionHeight={40}
              buttonWidth={150}
              menuWidth={200}
              disabled={selectedGroups.length === 1}
              getOptionLabel={(val) => val.name}
              getOptionValue={(val) => val.id}
            />
          </div>
        ) : null}
        <div styleName="footer">
          <Button
            text="Invite"
            automationId="newUsersInviteButton"
            isLoading={isCreateUsersLoading}
            fixedWidth={96}
            colorSchema={ButtonColors.BLUE_500}
            height={ButtonHeights.TALL}
            isDisabled={
              isCreateUsersLoading ||
              isEmailExistOpen ||
              !!emailListError ||
              !!validationError ||
              (!usersList.length && !inputValue) ||
              (isMeAnodot && !selectedOrg)
            }
            onClick={this.inviteClicked}
          />
        </div>

        <ConfModal
          isOpen={isEmailExistOpen}
          isCancelable={false}
          isStatic={false}
          onClose={this.onEmailModalClose}
          onConfirm={this.onEmailModalClose}
          confirmText="OK"
          title="Emails Already in Use"
        >
          <div>
            <span>These emails are being used in Anodot and cannot be invited:</span>
            {emailFailures.map((failure) => (
              <div styleName="failures" key={`failure-${failure.email}`}>
                {failure.email}
              </div>
            ))}
          </div>
        </ConfModal>
      </div>
    );
  }
}
