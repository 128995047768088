import React, {useState, useEffect, useCallback, useMemo, Fragment, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@material-ui/core';
import MinimizingModal from 'common/componentsV2/modal/MinimizingModal';

import {getBucketStartTimeEnabled, getIsTimelineEnabled} from 'profile/store/selectors';
import {copyToClipBoard} from 'common/store/actions';
import {
  fetchTriggeredAlertApi,
  setIsOpen,
  setIsMinimize,
  setInvestigationSettings,
  setIncidentFilter,
} from 'investigation/store/actions';
import {fetchUsers} from 'admin.users/store/actions';
import {
  getIsOpen,
  getIsMinimize,
  getFetchTriggeredAlertIsLoading,
  getAnomalyId,
  getTriggerId,
  getInvModalTrigger,
} from 'investigation/store/selectors';
import {INVESTIGATION_MODAL_TABS, modalRouting} from 'investigation/services/investigationService';
import {StringParam, BooleanParam, useQueryParams} from 'use-query-params';
import {isEmpty} from 'lodash';
import InvModalHeader from '../components/header/InvModalHeader';
import InvTabsBar from '../components/tabs/InvTabsBar';
import OverviewTab from '../components/overview/OverviewTab';
import IncidentTab from '../components/incident/IncidentTab';
import CorrelationsTab from '../components/correlations/CorrelationsTab';
import TimelineTab from '../components/timeline/TimelineTab';
import MinimizeTitle from './MinimizeTitle';

const InvestigationModal = () => {
  const firstUpdate = useRef(true);
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsOpen);
  const isMinimize = useSelector(getIsMinimize);
  const anomalyId = useSelector(getAnomalyId);
  const triggerId = useSelector(getTriggerId);
  const trigger = useSelector(getInvModalTrigger);
  const isTimelineEnabled = useSelector(getIsTimelineEnabled);
  const startBucketMode = useSelector(getBucketStartTimeEnabled);
  const isTriggeredLoading = useSelector(getFetchTriggeredAlertIsLoading);
  const [queryParams, setQueryParams] = useQueryParams({
    [modalRouting.ANOMALY_ID]: StringParam,
    [modalRouting.TRIGGER_ID]: StringParam,
    [modalRouting.INVESTIGATION_MODAL]: BooleanParam,
  });

  const [selectedTabId, setSelectedTabId] = useState(INVESTIGATION_MODAL_TABS.overview.id);
  const [availableTabs, setAvailableTabs] = useState([]);

  const checkFF = () =>
    Object.values(INVESTIGATION_MODAL_TABS).map((tab) => {
      if (tab.id === INVESTIGATION_MODAL_TABS.timeline.id) {
        return {...tab, isEnabled: isTimelineEnabled};
      }
      return tab;
    });

  const cleanQueryParams = () => {
    if (
      queryParams[modalRouting.ANOMALY_ID] ||
      queryParams[modalRouting.TRIGGER_ID] ||
      queryParams[modalRouting.INVESTIGATION_MODAL]
    ) {
      setQueryParams({
        [modalRouting.ANOMALY_ID]: undefined,
        [modalRouting.TRIGGER_ID]: undefined,
        [modalRouting.INVESTIGATION_MODAL]: undefined,
      });
    }
  };

  useEffect(() => {
    setAvailableTabs(checkFF());
    firstUpdate.current = false;
    dispatch(fetchUsers());
  }, []);

  // fetch triggered alert
  useEffect(() => {
    if (anomalyId && triggerId) {
      setSelectedTabId(INVESTIGATION_MODAL_TABS.overview.id);
      dispatch(
        fetchTriggeredAlertApi({
          triggerId,
          startBucketMode,
        }),
      );
    }
  }, [triggerId, anomalyId]);

  // fetch tokenMapIncident
  useEffect(() => {
    if (!isEmpty(trigger)) {
      dispatch(setIncidentFilter());
    }
  }, [isTriggeredLoading]);

  const onClose = () => {
    dispatch(setIsOpen(false));
    cleanQueryParams();
    setSelectedTabId(INVESTIGATION_MODAL_TABS.overview.id);
  };

  const onMinimize = () => {
    dispatch(setIsMinimize(!isMinimize));
    if (isMinimize) {
      cleanQueryParams();
    }
  };

  const onShareClicked = () => {
    const text = `${window.location.href.split('?')[0]}?anomalyIdInv=${anomalyId}&triggerIdInv=${triggerId}`;
    dispatch(
      copyToClipBoard({
        event: {},
        text,
        description:
          'You can send this link to anyone who has an Anodot account with the sufficient permission to view this data',
        title: 'Link to this investigation copied',
      }),
    );
  };

  const onCloseBalloon = useCallback((tabName, operation) => {
    dispatch(setInvestigationSettings(true, {tabName, operation}));
  }, []);

  const tabClicked = useCallback((tabId) => {
    setSelectedTabId(tabId);
  }, []);

  const overviewInfoCubeClicked = useCallback((tabId) => {
    if (tabId) {
      setSelectedTabId(tabId);
    }
  }, []);

  const selectedTabComponent = useMemo(() => {
    switch (selectedTabId) {
      case INVESTIGATION_MODAL_TABS.overview.id:
        return (
          <OverviewTab
            onCubeClick={overviewInfoCubeClicked}
            onShareClicked={onShareClicked}
            onCloseBalloon={onCloseBalloon}
          />
        );
      case INVESTIGATION_MODAL_TABS.incident.id:
        return <IncidentTab />;
      case INVESTIGATION_MODAL_TABS.correlations.id:
        return <CorrelationsTab />;
      case INVESTIGATION_MODAL_TABS.timeline.id:
        return <TimelineTab />;
      default:
        return null;
    }
  }, [selectedTabId]);

  return (
    <MinimizingModal
      isOpen={isOpen}
      isMinimize={isMinimize}
      onMinimize={onMinimize}
      onCloseBtnClick={onClose}
      titleWhenMinimize={<MinimizeTitle isLoading={isTriggeredLoading} title={trigger ? trigger.title : null} />}
    >
      <Fragment>
        <InvModalHeader isLoading={isTriggeredLoading} onShareClicked={onShareClicked} />
        <Box pr={2.5} pl={2.5}>
          <Box mt={3} mb={3}>
            <InvTabsBar tabs={availableTabs} onTabClick={tabClicked} selectedTabId={selectedTabId} />
          </Box>
          {selectedTabComponent}
        </Box>
      </Fragment>
    </MinimizingModal>
  );
};

export default InvestigationModal;
