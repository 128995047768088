import React from 'react';
import {useSelector} from 'react-redux';
import * as alertsConsoleSelectors from 'alerts.console/store/selectors';
import Checkbox from 'common/componentsV2/Checkbox';

type PropTypes = {
  setQueryParams: Function,
  // Connect
  // TODO Yoav: check how to validate Props with useSelector.
  // queryParams: Object,
};

export default function FeedbackPositiveFilter(props: PropTypes) {
  const queryParams = useSelector((state) => alertsConsoleSelectors.getQueryParamsViews(state));

  const {positiveFeedback} = queryParams;

  const toggleFilter = (checked) => {
    const {setQueryParams} = props;

    setQueryParams({positiveFeedback: checked || undefined});
  };

  return (
    <div>
      <Checkbox isChecked={positiveFeedback} text="Marked as “Good Catch”" onChange={toggleFilter} />
      <i className="icon icn-general24-like" style={{color: '#8995a0', fontSize: '16px', marginLeft: '5px'}} />
    </div>
  );
}
