import React from 'react';
import {Box} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';

// Example of usage: <TypographyBox variant='h2' width={100} height={100} color="purple.500">Test</TypographyBox>

const TypographyBox = ({variant, isEllipsis, ...rest}: {variant: String, isEllipsis: Boolean}) => {
  const theme = useTheme();
  return <Box {...theme.typography[variant]} {...(isEllipsis ? {...theme.typography.ellipsis} : null)} {...rest} />;
};

export default TypographyBox;
