import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import * as actions from 'userEvents/store/actions';
import eventsCategoriesReducer from './eventsCategoriesReducer';
import eventsSourcesReducer from './eventsSourcesReducer';

export default combineReducers({
  eventsCategories: eventsCategoriesReducer,
  eventSources: eventsSourcesReducer,
  fetchPropAndValListApi: makeAsyncReducer(actions.fetchPropAndValListApi, {
    shouldDestroyData: true,
    includeUpdateAt: true,
  }),
  fetchPropsApi: makeAsyncReducer(actions.fetchPropsApi, {
    shouldDestroyData: true,
    includeUpdateAt: true,
  }),
  execute: makeAsyncReducer(actions.execute, {
    shouldDestroyData: true,
    includeUpdateAt: true,
  }),
});
