// @flow
import React from 'react';
import {connect} from 'react-redux';
import {bcTypes} from 'bc/services/bcTypes';
import {REGIONS} from 'bc/services/genericStorageService';
import * as selectors from 'bc/store/selectors';
import {createDataSource, resetAddSourceError} from 'bc/store/actions';
import '../../DataSourceModal.module.scss';
import CopyToClipboardButton from 'common/components/CopyToClipboardButton';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import DataSourceModal from '../../DataSourceModal';

type PropTypes = {
  isOpen: boolean,
  isViewOnly: boolean,
  isEditable: boolean,
  sourceType: Object,
  source: Object,
  onClose: Function,
  roleARN: string,

  // connect
  isLoading: boolean,
  sourceError: Object,

  createDataSource: Function,
  resetAddSourceError: Function,
};

@connect(
  (state) => ({
    isLoading: selectors.getAddDataSourceState(state).isLoading,
    sourceError: selectors.getAddDataSourceState(state).error,
  }),
  {
    createDataSource,
    resetAddSourceError,
  },
)
export default class KinesisSourceModal extends React.PureComponent {
  props: PropTypes;

  // eslint-disable-next-line no-extra-boolean-cast
  state = !!this.props.source
    ? {
        streamName: this.props.source.kStreamName,
        region: REGIONS.find((reg) => reg.value === this.props.source.region),
        roleARN: this.props.source.roleARN,
      }
    : {
        streamName: '',
        roleARN: '',
        region: REGIONS[0],
      };

  valueChanged = (val, inputId) => {
    const newState = {};
    newState[inputId] = val;
    this.setState(newState);
  };

  regionChanged = (val) => {
    this.setState({region: val});
  };

  createBtnClicked = () => {
    this.props.createDataSource({
      type: bcTypes.kinesis.type,
      name: `Kinesis_${this.state.streamName} Data Source ${new Date().getTime().toString()}`,
      kStreamName: this.state.streamName,
      region: this.state.region.value,
      roleARN: this.state.roleARN,
    });
  };

  onClose = () => {
    this.props.resetAddSourceError();
    this.props.onClose();
  };

  render() {
    const {isOpen, source, sourceType, isLoading, sourceError, isViewOnly, isEditable} = this.props;
    const {streamName, region, roleARN} = this.state;
    const selectedIndex = REGIONS.findIndex((val) => val.value === region.value);

    return (
      <DataSourceModal
        isOpen={isOpen}
        isProcessing={isLoading}
        isViewOnly={isViewOnly}
        isRenameEnabled={isEditable}
        source={source}
        sourceType={sourceType}
        logoImageClass="source-kinesis"
        onClose={this.onClose}
        isContinueDisabled={!streamName || !roleARN}
        onContinue={this.createBtnClicked}
      >
        <div styleName="inputs-wrapper">
          <SelectAndt
            id="s3RegionDdl1"
            automationId="s3Regions"
            styleName="region-dropdown"
            className="andt-dropdown"
            disabled={isViewOnly}
            options={REGIONS}
            getOptionLabel={(val) => val.text}
            getOptionValue={(val) => val.value}
            type={TYPE_NO_SEARCH}
            theme={THEME_LIGHT}
            value={REGIONS[selectedIndex]}
            onChange={(item) => this.regionChanged(item)}
            placeholder="Choose Region"
          />
          <input
            type="text"
            className={!isLoading && sourceError ? 'invalid' : ''}
            onChange={(e) => this.valueChanged(e.target.value, 'streamName')}
            placeholder="Stream name"
            required
            readOnly={isViewOnly}
            disabled={isViewOnly}
            value={streamName}
          />

          <input type="text" readOnly disabled placeholder="Integration Token" value={sourceType.externalId} />

          <CopyToClipboardButton
            copyStr={sourceType.externalId}
            tooltipMessage="Copy the External ID to clipboard"
            styleName="copy-clipboard-btn-pos"
            shouldUseIcon
          />

          <input
            type="text"
            styleName="role-margin"
            readOnly={isViewOnly}
            disabled={isViewOnly}
            onChange={(e) => this.setState({roleARN: e.target.value})}
            placeholder="Role ARN"
            value={roleARN}
          />

          {!isLoading && sourceError ? (
            <div styleName="error-wrapper error-wrapper-s3">
              <span styleName="error-message">
                <i className="icon icn-warning-icon" /> {sourceError.uMessage}
              </span>
            </div>
          ) : null}

          <div styleName="extra-info">
            <span>*Update the kinesis stream policy to enable Anodot access the stream. Follow these</span>
            <a
              href="https://support.anodot.com/hc/en-us/articles/360012563774"
              rel="noopener noreferrer"
              target="_blank"
            >
              Authorization Guidelines
            </a>
            <span>to grant access</span>
          </div>
        </div>
      </DataSourceModal>
    );
  }
}
