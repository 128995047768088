// @flow
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import {Box} from '@material-ui/core';
import Button, {COLORS} from 'common/componentsV2/Button';
import * as selectors from '../../store/selectors';
import './SaveAlertSelectExpression.module.scss';
import {validateExpression} from '../../services/metricsService';

type PropTypes = {
  onCancel: Function,
  onSave: Function,
  expressionTrees: Object,
  typeOfSave: string,
};

const SaveAlertSelectExpression = (props: PropTypes) => {
  const [isDDPristine, setDDPristine] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [isValid, setIsValid] = useState(true);
  const expressionTrees = useSelector(selectors.getExpressionTrees);
  const getOptionsListOfExpressions = () => {
    const ret = [];
    props.expressionTrees.forEach((et, index) => {
      const element = {value: index, label: `Expression ${String.fromCharCode(65 + index)}`};
      ret.push(element);
    });
    return ret;
  };

  let options = getOptionsListOfExpressions();
  const [selectedExpression, setSelectedExpression] = useState(options[0]);

  useEffect(() => {
    options = getOptionsListOfExpressions();
  }, []);

  const handleChange = (val) => {
    setDDPristine(false);
    const validation = validateExpression(expressionTrees[val.value].expressionTree);
    if (props.typeOfSave === 'alert') {
      const message = `
      ${
        validation.errorFunctionDisplay
          ? 'Cannot create an alert from this expression because it uses a “Display only” function.'
          : ''
      }
      ${
        validation.errorEmptyExpression
          ? 'Cannot create an alert from this expression because it has an empty expression.'
          : ''
      }
      `;
      setErrorMessage(message);
      setIsValid(!(validation.errorFunctionDisplay || validation.errorEmptyExpression));
    }
    if (props.typeOfSave === 'composite') {
      const message = `
      ${
        validation.errorEmptyFunction
          ? 'Cannot create a composite from this expression because it lacks a function.'
          : ''
      }
      ${
        validation.errorEmptyExpression
          ? 'Cannot create an alert from this expression because it has an empty expression.'
          : ''
      }
      `;
      setErrorMessage(message);
      setIsValid(!(validation.errorEmptyFunction || validation.errorEmptyExpression));
    }

    setSelectedExpression(val);
  };

  let subtitle = '';
  if (props.typeOfSave === 'alert') {
    subtitle = 'An alert can only be on a single expression, please select one from the list';
  }
  if (props.typeOfSave === 'composite') {
    subtitle = 'A composite can only be on a single expression, please select one from the list';
  }

  return (
    <div styleName="container" className="idle-external-click">
      <div styleName="title">Select an Expression</div>
      <div styleName="subtitle">{subtitle}</div>
      <FormDdlSelect
        selected={selectedExpression}
        options={options}
        button={
          <BigDropdownButton
            isPristine={isDDPristine}
            placeholder="Select Expression"
            label={selectedExpression.label || null}
          />
        }
        optionComponent={<OptionComponentSimple />}
        onChange={handleChange}
        width={252}
      />
      {!isValid && (
        <Box color="red.500" fontSize="12">
          {errorMessage}
        </Box>
      )}
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <Box mr={1}>
          <Button text="Cancel" colorSchema={COLORS.GRAY_300} onClick={props.onCancel} />
        </Box>
        <Button
          isDisabled={!isValid || !selectedExpression}
          text={props.typeOfSave === 'alert' ? 'Create Alert' : 'Save'}
          colorSchema={COLORS.BLUE_500}
          onClick={() => props.onSave(props.typeOfSave, selectedExpression.value)}
        />
      </Box>
    </div>
  );
};

export default SaveAlertSelectExpression;
