// @flow
import React from 'react';
import {connect} from 'react-redux';
import SelectAndt, {
  THEME_NOT_HIGHLIGHTED,
  TYPE_NEW_MULTI_NO_SEARCH,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import Box from '@material-ui/core/Box';
import * as selectors from 'alerts.management/store/selectors';
import * as profileSelector from 'profile/store/selectors';
import * as actions from 'alerts.management/store/actions';
import TooltipArea from 'common/componentsV2/TooltipArea';

type PropTypes = {
  notifyOnlyOpen: boolean,
  onChange: Function,
  onUpdatePolicyChange: Function,
  updatePolicyInterval: Object,
  alertType: String,
  isUpdatePolicyEnabled: boolean,
};

const fullList = [
  {value: 'opens', label: 'Open', isDisabled: true},
  {value: 'updates', label: 'Update'},
  {value: 'closes', label: 'Close'},
];

const getOptions = (type, isUpdatePolicyEnabled) =>
  ({
    anomaly: isUpdatePolicyEnabled
      ? fullList
      : [{value: 'opens', label: 'Open', isDisabled: true}, {value: 'closes', label: 'Close'}],
    static: fullList,
    noData: [
      {value: 'opens', label: 'Open', isDisabled: true},
      {value: 'updates', label: 'Update'},
      {value: 'closes', label: 'Close', isDisabled: true},
    ],
  }[type]);

const SendAlertOnCondition = (props: PropTypes) => (
  <TooltipArea
    text="Choose when to get alerts: when anomalies open, update and close"
    automation-id="sendAlertOnConditionContainer"
  >
    {(info) => (
      <React.Fragment>
        <div className="text16reg lineHeight_16 mb_1">
          Send alert when anomalies
          {info}
        </div>
        <Box width={241}>
          <SelectAndt
            multiDelimiter=", "
            key={props.alertType}
            automationId="alertTypeFilter"
            type={TYPE_NEW_MULTI_NO_SEARCH}
            theme={THEME_NOT_HIGHLIGHTED}
            onChange={(item) => {
              props.onUpdatePolicyChange({
                enabled: !!item.find((option) => option.value === 'updates'),
                value: props.updatePolicyInterval.value,
              });
              props.onChange(!item.find((option) => option.value === 'closes'));
            }}
            options={getOptions(props.alertType, props.isUpdatePolicyEnabled)}
            value={getOptions(props.alertType, props.isUpdatePolicyEnabled).filter(
              (option) =>
                option.value === 'opens' ||
                (props.updatePolicyInterval.enabled && option.value === 'updates') ||
                (!props.notifyOnlyOpen && option.value === 'closes'),
            )}
            isMulti
            placeholder="Nothing selected"
            optionHeight={40}
            menuWidth={155}
            isClearable={false}
            closeMenuOnSelect={false}
            noAutoReorder
          />
        </Box>
      </React.Fragment>
    )}
  </TooltipArea>
);

export default connect(
  (state) => {
    const {notifyOnlyOpen, type, updatePolicyInterval} = selectors.getSelectedAlertSimulationFilters(state);
    const isAlertEnableAnomalyUpdate = profileSelector.getIsAlertEnableAnomalyUpdate(state);
    return {
      notifyOnlyOpen,
      alertType: type.value,
      updatePolicyInterval,
      isUpdatePolicyEnabled: type.value === 'anomaly' ? isAlertEnableAnomalyUpdate : true,
    };
  },
  {
    onChange: actions.setSelectedAlertNotifyOnlyOpenBoolVal,
    onUpdatePolicyChange: actions.setSelectedAlertUpdatePolicy,
  },
)(SendAlertOnCondition);
