// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setSelectedEditor} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import {isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import {getEditorValidationMessage} from 'bc/services/fuService';
import DimensionsAndMetrics from 'bc/components/streams/editor/common/DimensionsAndMetrics';

type PropTypes = {
  // connect
  dataStream: Object,
  dimensions: Array,
  metrics: Array,
  timestampColumnObj: Object,
  isNotEmpty: boolean,
  isLoadingAnalysis: boolean,

  setSelectedEditor: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    dimensions: selectors.getSalesforceDimensions(state),
    metrics: selectors.getSalesforceMetrics(state),
    timestampColumnObj: selectors.getSalesforceTimestampColumnObj(state),
    isLoadingAnalysis: selectors.getSalesforceIsLoading(state),
    isNotEmpty: selectors.isSalesforceDimatricsNotEmpty(state),
  }),
  {
    setSelectedEditor,
  },
)
export default class SalesforceDimensionsAndMetrics extends React.PureComponent {
  props: PropTypes;

  setSelectedEditor = () => {
    this.props.setSelectedEditor({
      type: 'dm_salesforce',
      editorHeight: 'calc(100% - 210px)',
    });
  };

  render() {
    const {dataStream, dimensions, metrics, isLoadingAnalysis, isNotEmpty, timestampColumnObj} = this.props;
    const isEditable = (isStreamEditable(dataStream.state) || isStreamRunning(dataStream.state)) && isNotEmpty;

    return (
      <DimensionsAndMetrics
        dataStream={dataStream}
        dimensions={dimensions}
        metrics={metrics}
        timeDefinition={timestampColumnObj}
        isLoading={isLoadingAnalysis}
        isEditable={isEditable}
        validatorFunction={getEditorValidationMessage}
        onSelectEditor={this.setSelectedEditor}
        dimensionColumnName="Dimension Fields"
        measureColumnsName="Measure Fields"
        dateColumnsName="Timestamp Field"
      />
    );
  }
}
