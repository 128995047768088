// @flow
import React, {PureComponent} from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {post, get, delete_} from 'common/utils/http';
import {isEmpty} from 'lodash';

type PropTypes = {
  isOpen: boolean,
  onClose: Function,
  onChange: Function,
  streamId: String,

  // connect
  dataStream: Object,
};

let streamId = null;

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {},
)
export default class HackFileJsonModal extends PureComponent {
  props: PropTypes;

  state = {
    sparseValue: '',
    dimredValue: '',
  };

  componentDidMount() {
    streamId = this.props.streamId || this.props.dataStream.id;
    get(`api/v1/bc/data-streams/${streamId}/sparse_filter`).promise.then((res) => {
      if (!isEmpty(res)) {
        this.setState({sparseValue: JSON.stringify(res)});
      }
    });
    get(`api/v1/bc/data-streams/${streamId}/dimred`).promise.then((res) => {
      if (!isEmpty(res)) {
        this.setState({dimredValue: JSON.stringify(res)});
      }
    });
    // fetchFuPreview({fileFormat, dataSourceId});
  }

  handleClose = (isOk, event) => {
    event.preventDefault(); // we don't want to close the editor (FuDimensionsAndMetricsEditor)
    if (isOk && this.state.hasUpdates) {
      this.props.onChange(this.state.updateObj);
    }
    this.props.onClose();
  };

  handleChangeDimReduce = (event) => {
    this.setState({dimredValue: event.target.value});
  };

  handleChangeSparceFilter = (event) => {
    this.setState({sparseValue: event.target.value});
  };

  handleSubmitDimReduce = () => {
    post(`api/v1/bc/data-streams/${streamId}/dimred`, JSON.parse(this.state.dimredValue))
      .promise.then(() => {
        // eslint-disable-next-line no-alert
        alert('success');
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('error occurred');
      });
  };

  handleSubmitSparceFilter = () => {
    if (isEmpty(this.state.sparseValue)) {
      delete_(`api/v1/bc/data-streams/${streamId}/sparse_filter`)
        .promise.then(() => {
          // eslint-disable-next-line no-alert
          alert('clean success');
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert('error occurred');
        });
    } else {
      post(`api/v1/bc/data-streams/${streamId}/sparse_filter`, JSON.parse(this.state.sparseValue))
        .promise.then(() => {
          // eslint-disable-next-line no-alert
          alert('post success');
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert('error occurred');
        });
    }
  };

  render() {
    const {isOpen, onClose} = this.props;

    return (
      <div>
        <Modal show={isOpen} onHide={onClose} bsSize="large" dialogClassName="bc" backdrop="static">
          <Modal.Header bsClass="bc-modal-header">
            <Modal.Title>
              <span>
                Stream Extra
                {this.props.streamId ? '(Read Only)' : ''}
              </span>
            </Modal.Title>
            <button
              type="button"
              className="btn btn-flat btn-icon-36 btn-secondary"
              onClick={(event) => this.handleClose(false, event)}
            >
              <i className="icon icn-icon-table-delete" />
            </button>
          </Modal.Header>

          <Modal.Body>
            <div className="shell-col" style={{minHeight: '550px'}}>
              <div>DimReduce JSON</div>

              <div>
                <textarea
                  style={{width: '500px', height: '200px'}}
                  value={this.state.dimredValue}
                  onChange={this.handleChangeDimReduce}
                />
              </div>

              <div>
                <button type="button" disabled={this.props.streamId} onClick={this.handleSubmitDimReduce}>
                  Submit
                </button>
              </div>

              <br />
              <div>Sparse Filter JSON</div>

              <div>
                <textarea
                  style={{width: '500px', height: '200px'}}
                  value={this.state.sparseValue}
                  onChange={this.handleChangeSparceFilter}
                />
              </div>

              <div>
                <button type="button" disabled={this.props.streamId} onClick={this.handleSubmitSparceFilter}>
                  Submit
                </button>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer />
        </Modal>
      </div>
    );
  }
}
