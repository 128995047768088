// @flow
import React from 'react';
import AnomalyAlert from './anomalyAlert/AnomalyAlert';
import StaticAlert from './staticAlert/StaticAlert';
import NoDataAlert from './noDataAlert/NoDataAlert';

const GraphContent = (props: {
  open: Boolean,
  opacity: Number,
  alert: Object,
  uiGroupName: string,
  onChangeChartWrapperStyle: Function,
}) => {
  const {open, opacity, alert, uiGroupName, onChangeChartWrapperStyle} = props;

  const style = {
    graphsWrapper: {
      opacity,
      transition: ['opacity .3s ease-in-out'],
    },
  };

  return (
    <div style={style.graphsWrapper}>
      {open && alert.type === 'anomaly' && (
        <AnomalyAlert
          isSingleLine={false}
          specialHeader
          alert={alert}
          uiGroupName={uiGroupName}
          getWrapperStyle={onChangeChartWrapperStyle}
        />
      )}
      {open && alert.type === 'static' && (
        <StaticAlert
          isSingleLine={false}
          specialHeader
          alert={alert}
          uiGroupName={uiGroupName}
          getWrapperStyle={onChangeChartWrapperStyle}
        />
      )}
      {open && alert.type === 'noData' && (
        <NoDataAlert
          isSingleLine={false}
          specialHeader
          alert={alert}
          uiGroupName={uiGroupName}
          getWrapperStyle={onChangeChartWrapperStyle}
        />
      )}
    </div>
  );
};

export default GraphContent;
