// @flow
import React, {PureComponent} from 'react';
import connect from 'react-redux/es/connect/connect';
import * as alertsConsoleSelectors from 'alerts.console/store/selectors';

import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {ACK} from 'alerts.console/services/alertsConsoleService';

type PropTypes = {
  setQueryParams: Function,
  // connect
  queryParams: Object,
};

@connect(
  (state) => ({
    queryParams: alertsConsoleSelectors.getQueryParamsViews(state),
  }),
  {},
)
export default class AckFilter extends PureComponent {
  props: PropTypes;

  toggleFilter(value) {
    const {setQueryParams} = this.props;

    setQueryParams({ack: value});
  }

  render() {
    const optionCurrent = ACK.findIndex((i) => this.props.queryParams.ack === i.value);
    return (
      <SelectAndt
        id="ackFilter"
        automationId="AlertAckFilter"
        extraClassName="alerts-dropdown-btn"
        options={ACK}
        optionHeight={40}
        type={TYPE_NEW_NO_SEARCH}
        theme={THEME_HIGHLIGHTED}
        onChange={(item) => this.toggleFilter(item.value)}
        value={ACK[optionCurrent].value ? ACK[optionCurrent] : null}
        placeholder="All"
      />
    );
  }
}
