// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  setSegmentStreamClearAllDiametrics as setSegmentStreamClearAllDiametricsAction,
  setStreamDiametricsEditorFilterTextInput as setStreamDiametricsEditorFilterTextInputAction,
  setSegmentStreamDiametricsChange as setSegmentStreamDiametricsChangeAction,
  removeSegmentStreamDiametrics as removeSegmentStreamDiametricsAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import DimensionsAndMetricsEditor from 'bc/components/streams/editor/common/DimensionsAndMetricsEditor';

type PropTypes = {
  dimensions: Array<Object>,
  metrics: Array<Object>,
  filteredUnassignedColumns: Array<Object>,
  filterTextInput: String,
  setSegmentStreamClearAllDiametrics: Function,
  setStreamDiametricsEditorFilterTextInput: Function,
  setSegmentStreamDiametricsChange: Function,
  removeSegmentStreamDiametrics: Function,
  isStreamUpdating: Boolean,
};

@connect(
  (state) => ({
    dimensions: selectors.getSegmentStreamSelectedDimensions(state),
    metrics: selectors.getSegmentStreamSelectedMetrics(state),
    filterTextInput: selectors.getStreamMetricsDimentionsFilterTextInput(state),
    filteredUnassignedColumns: selectors.getFilteredSegmentStreamUnAssignedColumns(state),
    isStreamUpdating: selectors.getIsDataStreamUpdating(state),
  }),
  {
    setSegmentStreamClearAllDiametrics: setSegmentStreamClearAllDiametricsAction,
    setStreamDiametricsEditorFilterTextInput: setStreamDiametricsEditorFilterTextInputAction,
    setSegmentStreamDiametricsChange: setSegmentStreamDiametricsChangeAction,
    removeSegmentStreamDiametrics: removeSegmentStreamDiametricsAction,
  },
)
export default class SegmentDimensionsAndMetricsEditor extends React.PureComponent {
  props: PropTypes;

  render() {
    const {
      metrics,
      dimensions,
      filterTextInput,
      filteredUnassignedColumns,
      removeSegmentStreamDiametrics,
      setStreamDiametricsEditorFilterTextInput,
      setSegmentStreamDiametricsChange,
      setSegmentStreamClearAllDiametrics,
      isStreamUpdating,
    } = this.props;

    return (
      <DimensionsAndMetricsEditor
        dimensions={dimensions}
        metrics={metrics}
        filteredUnassignedColumns={filteredUnassignedColumns}
        filterTextInput={filterTextInput}
        isStreamUpdating={isStreamUpdating}
        setStreamClearAllDiametrics={setSegmentStreamClearAllDiametrics}
        setMetricDimentionEditorFilterTextInput={setStreamDiametricsEditorFilterTextInput}
        setStreamDiametricsChange={setSegmentStreamDiametricsChange}
        removeStreamDiametrics={removeSegmentStreamDiametrics}
        isRemoveDateSection
      />
    );
  }
}
