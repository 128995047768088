// @flow
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {fetchChannels} from 'alerts.channels/store/actions';
import {isEqual} from 'lodash';
import {useField} from 'react-final-form';
import {useDispatch, useSelector} from 'react-redux';
import * as channelsSelectors from 'alerts.channels/store/selectors';
import * as usersSelectors from 'admin.users/store/selectors';

import UsersChannelsFilter from 'alerts.channels/components/UsersChannelsFilter';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {Box} from '@material-ui/core';
import TooltipArea from 'common/componentsV2/TooltipArea';

const Recipients = () => {
  const {
    input: {value: subscribers, onChange: onChangeSubscribers},
    meta: {touched, error},
  } = useField('subscribers', {isEqual});

  const {
    input: {value: channels, onChange: onChangeChannels},
  } = useField('channels', {isEqual});

  const selectRef = useRef();
  const dispatch = useDispatch();

  const isLoadingChannels = useSelector(channelsSelectors.getChannelsLoading);
  const isLoadingUsers = useSelector(usersSelectors.getUsersIsLoading);
  const sortedFlattenChannels = useSelector(channelsSelectors.getSortedFlattenChannels);

  useEffect(() => {
    dispatch(fetchChannels());
  }, []);

  const value = useMemo(
    () =>
      [...subscribers, ...channels]
        .map((item) => sortedFlattenChannels.find((option) => option.value === item))
        .filter((item) => item),
    [subscribers, channels, sortedFlattenChannels],
  );

  const handleChange = useCallback((arr) => {
    onChangeSubscribers(arr.filter((item) => item.type === 'USERS').map((item) => item.value));
    onChangeChannels(arr.filter((item) => item.type !== 'USERS').map((item) => item.value));
  }, []);

  return (
    <TooltipArea automationId="recipientsContainer" text="Who will get the alert triggeres in case of an anomaly">
      {(info) => (
        <Box width="102%">
          <div className="text16reg lineHeight_16 mb_1">
            Which recipients should receive these alerts?
            {info}
          </div>
          <Box width={230} position="relative">
            <Box
              component="input"
              width={0}
              height={0}
              border={0}
              fontSize={0}
              position="absolute"
              name="subscribers"
              onFocus={() => selectRef.current.focus()}
              zIndex={-1}
            />
            <UsersChannelsFilter
              refSelectComponent={(el) => {
                selectRef.current = el;
              }}
              placeholder={isLoadingChannels || isLoadingUsers ? 'Loading...' : 'Select'}
              onChange={handleChange}
              sortedFlattenChannels={sortedFlattenChannels}
              value={value}
              footerComponent={
                <FormDdlActionBar
                  isVisible
                  isClearAllDisabled={!value || !value.length}
                  onClearAll={() => {
                    onChangeSubscribers([]);
                    onChangeChannels([]);
                  }}
                />
              }
            />
          </Box>
          {touched && error && (
            <TypographyBox variant="subtitle2" mt={0.5} color="red.500">
              {error}
            </TypographyBox>
          )}
        </Box>
      )}
    </TooltipArea>
  );
};

export default Recipients;
