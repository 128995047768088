import React from 'react';
import {Route} from 'react-router-dom';
import AddGraphModalContainer from 'dashboards/components/modals/AddGraphModalContainer';
import {defaultEmptyGraphTile, defaultEmptyStatTile, defaultEmptyTextTile} from 'dashboards/utils';
import AddStatModalContainer from 'dashboards/components/modals/AddStatModalContainer';
import AddTextTileModal from 'dashboards/components/modals/AddTextTileModal';
import ShareModal from 'dashboards/components/modals/ShareModal';
import {OPTION_TILES_VALUES} from 'dashboards/services/dashboardService';
import SaveModal from 'dashboards/components/modals/SaveModal';
import {STAT_TILES_VALUES} from '../modals/statTile/StatTileSettings';

type PropsType = {
  matchUrl: string,
  actualLayout: Array<Object>,
  dashboardId: string,
  returnToDashboardPage: Function,
  onConfirmSave: Function,
  isLoading: boolean,
};

const AddTileRouter = ({
  matchUrl,
  actualLayout,
  dashboardId,
  returnToDashboardPage,
  onConfirmSave,
  isLoading,
}: PropsType) => (
  <React.Fragment>
    <Route
      path={`${matchUrl}/graph`}
      render={() => (
        <AddGraphModalContainer
          dashboardId={dashboardId}
          layout={actualLayout}
          data={defaultEmptyGraphTile}
          onClose={returnToDashboardPage}
          type={OPTION_TILES_VALUES.NEW}
        />
      )}
    />
    <Route
      path={[`${matchUrl}/stat`, `${matchUrl}/gauge`]}
      render={({match}) => (
        <AddStatModalContainer
          dashboardId={dashboardId}
          layout={actualLayout}
          data={defaultEmptyStatTile}
          onClose={returnToDashboardPage}
          type={OPTION_TILES_VALUES.NEW}
          tileType={match.url.includes('gauge') ? STAT_TILES_VALUES.GAUGE : STAT_TILES_VALUES.NUMBER}
        />
      )}
    />
    <Route
      path={`${matchUrl}/text`}
      render={() => (
        <AddTextTileModal
          dashboardId={dashboardId}
          layout={actualLayout}
          data={defaultEmptyTextTile}
          onClose={returnToDashboardPage}
          type={OPTION_TILES_VALUES.NEW}
        />
      )}
    />
    <Route
      path={`${matchUrl}/share`}
      render={() => <ShareModal onClose={returnToDashboardPage} dashboardId={dashboardId} />}
    />
    <Route
      path={`${matchUrl}/save`}
      render={() => <SaveModal onClose={returnToDashboardPage} onConfirm={onConfirmSave} isLoading={isLoading} />}
    />
  </React.Fragment>
);

export default AddTileRouter;
