import {combineEpics} from 'redux-observable';
import * as alertsConsoleAction from 'alerts.console/store/actions';
import * as chartActions from 'charts/timeSeries/store/actions';
import * as commonActions from 'common/store/actions';
import * as profileActions from 'profile/store/actions';
import * as alertsConsoleSelector from 'alerts.console/store/selectors';
import {getBucketStartTimeEnabled, getProfileId, getTimeZoneName} from 'profile/store/selectors';
import * as api from 'alerts.console/services/api';

import {generateChartSeriesMetricModel, processSeriesData} from 'charts/timeSeries/services/timeSeriesDataService';
import {CHART_COLORS, getThresholdPlotLineConfig} from 'charts/timeSeries/services/timeSeriesHchartSettingsService';
import {
  addPlotBand,
  addPlotLine,
  endLoad,
  pushSeries,
  startLoad,
} from 'charts/timeSeries/services/timeSeriesHchartService';
import {getApiTreeForNode, getEmptyTree} from 'common/utils/angularServices';
import {compact, get, has, isNil, pickBy} from 'lodash';
import {push} from 'connected-react-router';

import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';

import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {Observable} from 'rxjs/Observable';
import {getDateValue, rangeTypes} from 'common/utils/dateRangeService';
import {info} from 'common/utils/notifications/notificationsService';
import Highcharts from 'highcharts';
import * as actions from '../actions';

const timeSeriesCharts = new Map();

const fetchTriggeredAlertsAcknowledged = makeAsyncEpic(
  actions.fetchTriggeredAlertsAcknowledged,
  api.fetchTriggeredAlerts,
);
const fetchTriggeredAlertsNotAcknowledged = makeAsyncEpic(
  actions.fetchTriggeredAlertsNotAcknowledged,
  api.fetchTriggeredAlerts,
);
const setStar = makeAsyncEpic(actions.setStar, api.setStar);
const fetchTriggeredAlertForSnooze = makeAsyncEpic(
  actions.fetchTriggeredAlertForSnooze,
  api.fetchTriggeredAlertForSnooze,
);
const snoozeAllTriggerMetrics = makeAsyncEpic(actions.snoozeAllTriggerMetrics, api.snoozeTriggerMetrics);
const endSnoozeAllTriggerMetrics = makeAsyncEpic(actions.endSnoozeAllTriggerMetrics, api.endSnoozeTriggerMetrics);
const snoozeTriggerMetrics = makeAsyncEpic(actions.snoozeTriggerMetrics, api.snoozeTriggerMetrics);
const endSnoozeTriggerMetrics = makeAsyncEpic(actions.endSnoozeTriggerMetrics, api.endSnoozeTriggerMetrics);
const STLTriggerMetrics = makeAsyncEpic(actions.STLTriggerMetrics, api.stlTriggerMetrics);
const endStlTriggerMetrics = makeAsyncEpic(actions.endStlTriggerMetrics, api.endStlTriggerMetrics);
const stlAllTriggerMetrics = makeAsyncEpic(actions.stlAllTriggerMetrics, api.stlTriggerMetrics);
const endStlAllTriggerMetrics = makeAsyncEpic(actions.endStlAllTriggerMetrics, api.endStlTriggerMetrics);
const setMarkAsReadRequest = makeAsyncEpic(actions.setMarkAsReadRequest, api.addMarkAsRead);

const applySnoozeToMetrics = (action$, {getState}) =>
  action$.ofType(actions.applySnoozeToMetrics.TYPE).switchMap((action) => {
    const allSnoozedMetrics = alertsConsoleSelector.getSnoozedAllMetrics(getState());

    const addSnoozedPayload = {
      userId: action.payload.userId,
      duration: action.payload.duration,
      metricIds: allSnoozedMetrics.filter((m) => !isNil(m.snooze)).map((m) => m.id),
      includeSTL: false,
      timeScale: action.payload.timeScale,
    };

    const removeSnoozedPayload = {
      userId: action.payload.userId,
      duration: action.payload.duration, // needed for alert.snoozeSummary
      metricIds: allSnoozedMetrics.filter((m) => isNil(m.snooze)).map((m) => m.id),
      includeSTL: false,
      timeScale: action.payload.timeScale,
    };

    const {alertConfigurationId} = action.payload;

    if (addSnoozedPayload.metricIds.length === allSnoozedMetrics.length) {
      const addAllPayload = {
        userId: addSnoozedPayload.userId,
        triggerId: action.payload.triggerId,
        duration: addSnoozedPayload.duration,
        includeSTL: false,
        timeScale: action.payload.timeScale,
      };
      return [actions.snoozeAllTriggerMetrics(addAllPayload, {...addAllPayload, alertConfigurationId})];
    }
    if (removeSnoozedPayload.metricIds.length === allSnoozedMetrics.length) {
      const removeAllPayload = {
        userId: addSnoozedPayload.userId,
        triggerId: action.payload.triggerId,
        includeSTL: false,
        timeScale: action.payload.timeScale,
      };
      return [actions.endSnoozeAllTriggerMetrics(removeAllPayload, {...removeAllPayload, alertConfigurationId})];
    }
    return compact([
      addSnoozedPayload.metricIds.length > 0
        ? actions.snoozeTriggerMetrics(addSnoozedPayload, {
            ...addSnoozedPayload,
            alertConfigurationId,
          })
        : null,
      removeSnoozedPayload.metricIds.length > 0
        ? actions.endSnoozeTriggerMetrics(removeSnoozedPayload, {
            ...removeSnoozedPayload,
            alertConfigurationId,
          })
        : null,
    ]);
  });

const applySTLtoMetrics = (action$, {getState}) =>
  action$.ofType(actions.applySTLtoMetrics.TYPE).switchMap((action) => {
    const allStlMetrics = alertsConsoleSelector.getSTLAllMetrics(getState());

    const addStlPayload = {
      userId: action.payload.userId,
      duration: action.payload.duration,
      metricIds: allStlMetrics.filter((m) => !isNil(m.stopLearning)).map((m) => m.id),
      timeScale: action.payload.timeScale,
    };

    const removeStlPayload = {
      userId: action.payload.userId,
      duration: action.payload.duration,
      metricIds: allStlMetrics.filter((m) => isNil(m.stopLearning)).map((m) => m.id),
      timeScale: action.payload.timeScale,
    };

    const {alertConfigurationId} = action.payload;

    if (addStlPayload.metricIds.length === allStlMetrics.length) {
      // add All
      const addAllPayload = {
        userId: addStlPayload.userId,
        triggerId: action.payload.triggerId,
        duration: addStlPayload.duration,
        timeScale: action.payload.timeScale,
      };
      return [actions.stlAllTriggerMetrics(addAllPayload, {...addAllPayload, alertConfigurationId})];
    }

    if (removeStlPayload.metricIds.length === allStlMetrics.length) {
      // remove All
      const removeAllPayload = {
        userId: removeStlPayload.userId,
        triggerId: action.payload.triggerId,
        timeScale: action.payload.timeScale,
      };

      return [actions.endStlAllTriggerMetrics(removeAllPayload, {...removeAllPayload, alertConfigurationId})];
    }

    return compact([
      addStlPayload.metricIds.length > 0
        ? actions.STLTriggerMetrics(addStlPayload, {
            ...addStlPayload,
            alertConfigurationId,
          })
        : null,
      removeStlPayload.metricIds.length > 0
        ? actions.endStlTriggerMetrics(removeStlPayload, {
            ...removeStlPayload,
            alertConfigurationId,
          })
        : null,
    ]);
  });

const snoozeSuccesses = (action$) =>
  action$
    .ofType(
      actions.snoozeTriggerMetrics.success.TYPE,
      actions.snoozeAllTriggerMetrics.success.TYPE,
      actions.endSnoozeTriggerMetrics.success.TYPE.TYPE,
      actions.endSnoozeAllTriggerMetrics.success.TYPE,
    )
    .switchMap(() => [actions.fetchAlerts()]);

const stlSuccesses = (action$) =>
  action$
    .ofType(
      actions.STLTriggerMetrics.success.TYPE,
      actions.stlAllTriggerMetrics.success.TYPE,
      actions.endSnoozeTriggerMetrics.success.TYPE,
      actions.endSnoozeAllTriggerMetrics.success.TYPE,
    )
    .switchMap(() => [actions.fetchAlerts()]);

const fetchAlertMetricDataPoints = (action$, {getState}) =>
  action$.ofType(actions.fetchAlertMetricDataPoints.TYPE).flatMap((action) => {
    // TODO menny: add cancellation behaviour here (use rx.scan)

    const hchart = timeSeriesCharts.get(action.meta.chartId);
    const data =
      action.meta.stateGroup === 'ack'
        ? alertsConsoleSelector.getAckMetrics(getState())[action.meta.key]
        : alertsConsoleSelector.getNoAckMetrics(getState())[action.meta.key];

    startLoad(hchart);

    // if we have an alert that's open we cancel the request.
    if (data && data.data) {
      return [actions.fetchAlertMetricDataPoints.success(data.data, action.meta)];
    }

    const res = getEmptyTree();
    res.expressionTree.root.searchObject = {
      ids: [action.payload.metricId],
    };

    const newAction = {
      ...action,
      payload: {
        ...action.payload,
        body: {
          composite: getApiTreeForNode(res.expressionTree.root, res, null, {
            alertId: action.meta.alertId, // the alert trigger ID
            // we dont measure metricID cause it will cause the metrics name to be invalid
            // metricId: action.meta.metricId,
            context: 'alertsConsole',
          }),
        },
      },
    };

    return api
      .fetchAlertMetricDataPoints(newAction, getState)
      .map((payload) => actions.fetchAlertMetricDataPoints.success(payload, newAction.meta))
      .catch((error) => Observable.of(actions.fetchAlertMetricDataPoints.failure(error, newAction.meta)));
  });

const fetchAlertMetricDataPointsSuccess = (action$, {getState}) =>
  action$
    .ofType(actions.fetchAlertMetricDataPoints.success.TYPE)
    .do(({payload, meta}) => {
      const alerts =
        meta.stateGroup === 'ack'
          ? alertsConsoleSelector.getAckAlerts(getState())
          : alertsConsoleSelector.getNoAckAlerts(getState());
      const alertGroupe = alerts.alertGroups.find((a) => a.id === meta.alertId);
      const hchart = timeSeriesCharts.get(meta.chartId);
      const chartData =
        meta.stateGroup === 'ack'
          ? alertsConsoleSelector.getAckMetrics(getState())[meta.key]
          : alertsConsoleSelector.getNoAckMetrics(getState())[meta.key];

      if (!hchart) {
        return; // console item was probably collapsed
      }
      if (!hchart || !get(payload, 'metrics.length') || !payload.validation.passed) {
        endLoad(hchart, chartData.startDate, chartData.endDate);
        return;
      }

      const isAnomaly = has(meta.intervals, '[0].anomalyId');
      const isStatic = alertGroupe ? alertGroupe.alerts[0].type === 'static' : false;
      const metricData = payload.metrics[0];
      const metricsModel = generateChartSeriesMetricModel(metricData);
      const processedSeriesData = processSeriesData(
        metricData.dataPoints,
        metricData.baseline,
        isAnomaly || isStatic ? meta.intervals : null,
        null,
      );
      const isVisible = !chartData.hiddenSeries.find((metricId) => metricId === meta.metricId);

      const metricThreshold = {};
      const noDataZonesConfig = {
        zones: [],
        zoneColor: CHART_COLORS.NO_DATA_LINE_COLOR,
        zoneDashStyle: 'Dot',
      };

      meta.intervals.forEach((interval, index) => {
        if (interval.direction === 'UP') {
          if (!metricThreshold.upper || metricThreshold.upper > interval.threshold) {
            metricThreshold.upper = interval.threshold;
          }
        } else if (interval.direction === 'DOWN') {
          if (!metricThreshold.lower || metricThreshold.lower > interval.threshold) {
            metricThreshold.lower = interval.threshold;
          }
        } else if (interval.lastSeenTime) {
          const noDataFrom = interval.lastSeenTime * 1000;
          const noDataTo = interval.endTime * 1000;
          const isBackFilled = metricData.dataPoints.some((p) => p[0] > noDataFrom && p[0] < noDataTo);
          const z = {
            startValue: noDataFrom,
            endValue: noDataTo,
          };
          if (!isBackFilled) {
            z.color = 'rgba(61, 76, 89, 0)';
          }
          noDataZonesConfig.zones.push(z);
          addPlotBand(
            hchart,
            {
              from: noDataFrom,
              to: noDataTo,
              zIndex: 3,
              color: 'rgba(61, 76, 89, 0.1)',
              id: `nodata-plot-band-${index}`,
            },
            'x',
          );
        }
      });

      pushSeries(
        hchart,
        chartData.seriesProperties,
        metricsModel,
        processedSeriesData,
        isVisible,
        false,
        noDataZonesConfig,
      );

      Object.keys(metricThreshold).forEach((key, index) => {
        const thresholdConfig = getThresholdPlotLineConfig();
        thresholdConfig.id = `threshold_${index}`;
        thresholdConfig.value = metricThreshold[key];
        thresholdConfig.label.text = key;
        addPlotLine(hchart, thresholdConfig);
      });

      endLoad(
        hchart,
        chartData.zoomRange ? chartData.zoomRange.startDate : chartData.startDate,
        chartData.zoomRange ? chartData.zoomRange.endDate : chartData.endDate,
      );

      if (chartData.zoomRange && hchart.andt) {
        hchart.andt.showResetZoomButton();
      }
    })
    .flatMap(() => []);

const fetchAlertMetricDataPointsFailure = (action$) =>
  action$
    .ofType(actions.fetchAlertMetricDataPoints.failure.TYPE)
    .do(({meta}) => {
      const hchart = timeSeriesCharts.get(meta.chartId);
      if (hchart) {
        return endLoad(hchart);
      }
      return null;
    })
    .flatMap(() => []);

const highChartCreated = (action$) =>
  action$
    .ofType(chartActions.highChartCreated.TYPE)
    .do((action) => {
      const hchart = Highcharts.charts.find((chart) => chart && chart.renderTo.id === action.payload.chartId);
      timeSeriesCharts.set(action.payload.chartId, hchart);
    })
    .flatMap(() => []);

const highChartDestroyed = (action$) =>
  action$
    .ofType(chartActions.highChartDestroyed.TYPE)
    .do((action) => {
      timeSeriesCharts.delete(action.payload.chartId);
    })
    .flatMap(() => []);

const fetchAlerts = (action$, {getState}) =>
  action$.ofType(actions.fetchAlerts.TYPE).switchMap(() => {
    const curState = getState();
    const startBucketMode = getBucketStartTimeEnabled(curState);
    const timeZone = getTimeZoneName(curState);
    const meId = getProfileId(curState);
    const queryParamsView = alertsConsoleSelector.getQueryParamsViews(curState);
    const dateRange = getDateValue(
      {
        constRange: queryParamsView.constRange,
        startDate: queryParamsView.startTime,
        endDate: queryParamsView.endTime,
        relativeLast: queryParamsView.relativeLast,
        relativeNext: queryParamsView.relativeNext,
      },
      true,
      timeZone,
    );
    const isConst = dateRange.constRange === rangeTypes.c.value || dateRange.constRange === rangeTypes.r.value;

    const params = {
      ...queryParamsView,
      searchQuery: encodeURIComponent(queryParamsView.searchQuery),
      constRange: dateRange.constRange,
      startTime: dateRange.startDate,
      endTime: isConst ? dateRange.endDate : null,
      startBucketMode,
    };

    const cleanParams = pickBy(params, (v) => !isNil(v) && v !== '');

    const acknowledgedParams = {...cleanParams, starredBy: meId};
    const notAcknowledgedParams = {...cleanParams, notStarredBy: meId};

    return [
      actions.fetchTriggeredAlertsAcknowledged(acknowledgedParams),
      actions.fetchTriggeredAlertsNotAcknowledged(notAcknowledgedParams),
    ];
  });

const setAlertsFilters = (action$) =>
  action$.ofType(actions.setAlertsFilters.TYPE).switchMap(() => [actions.fetchAlerts()]);

const triggerTheSnackBar = (payload, triggerIds) => {
  const uid = 'filterByTriggerMsg';
  const queryParamsData = alertsConsoleSelector.getQueryParamsData(payload.getState());
  const queryString = Object.keys(queryParamsData)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParamsData[key])}`)
    .join('&');

  const notificationObj = {
    title: 'Alert Console is Filtered by an Alert',
    description: 'You’re seeing only data relevant to the Alert you clicked on',
    actions: [
      {
        label: 'Show all data ',
        callback: () => {
          payload.dispatch(
            push({
              pathname: '/alerts-console/',
              search: queryString,
            }),
          );
          payload.dispatch(commonActions.removeNotification(uid));
        },
      },
    ],
    settings: {
      canClose: true,
      autoDismiss: 0,
      uid,
    },
  };
  return [info(notificationObj), actions.setSelectedTriggeredAlert(triggerIds)];
};

const fetchTriggeredAlertsAcknowledgedSuccess = (action$, payload) =>
  action$.ofType(actions.fetchTriggeredAlertsAcknowledged.success.TYPE).switchMap(() => {
    const queryParamsView = alertsConsoleSelector.getQueryParamsViews(payload.getState());
    if (
      queryParamsView.ref &&
      queryParamsView.ref === 'email' &&
      (queryParamsView.triggerIds || queryParamsView.alertConfigurationIds)
    ) {
      return triggerTheSnackBar(payload, queryParamsView.triggerIds);
    }
    return [];
  });

const fetchTriggeredAlertsNotAcknowledgedSuccess = (action$, payload) =>
  action$.ofType(actions.fetchTriggeredAlertsNotAcknowledged.success.TYPE).switchMap(() => {
    const queryParamsView = alertsConsoleSelector.getQueryParamsViews(payload.getState());
    if (
      queryParamsView.ref &&
      queryParamsView.ref === 'email' &&
      (queryParamsView.triggerIds || queryParamsView.alertConfigurationIds)
    ) {
      return triggerTheSnackBar(payload, queryParamsView.triggerIds);
    }
    return [];
  });

const fetchTriggeredAlertsTotalLastDay = makeAsyncEpic(
  alertsConsoleAction.fetchTriggeredAlertsTotalLastDay,
  api.fetchTriggeredAlertsTotal,
);

const fetchTriggeredAlertsTotal = makeAsyncEpic(
  alertsConsoleAction.fetchTriggeredAlertsTotal,
  api.fetchTriggeredAlertsTotal,
);

const timeZoneChanged = (action$) =>
  action$.ofType(profileActions.timeZoneChanged.TYPE).switchMap(() => {
    return [];
  });

const setMarkAsRead = (action$) =>
  action$.ofType(actions.setMarkAsRead.TYPE).switchMap(({payload}) => [actions.setMarkAsReadRequest(payload)]);

const alertsConsoleEpic = combineEpics(
  fetchTriggeredAlertsAcknowledged,
  fetchTriggeredAlertsNotAcknowledged,
  fetchTriggeredAlertsAcknowledgedSuccess,
  fetchTriggeredAlertsNotAcknowledgedSuccess,
  fetchAlerts,
  setAlertsFilters,
  setStar,
  fetchTriggeredAlertForSnooze,
  snoozeAllTriggerMetrics,
  endSnoozeAllTriggerMetrics,
  snoozeTriggerMetrics,
  endSnoozeTriggerMetrics,
  applySnoozeToMetrics,
  applySTLtoMetrics,
  STLTriggerMetrics,
  endStlTriggerMetrics,
  stlAllTriggerMetrics,
  endStlAllTriggerMetrics,
  setMarkAsRead,
  setMarkAsReadRequest,
  snoozeSuccesses,
  stlSuccesses,
  fetchAlertMetricDataPoints,
  fetchAlertMetricDataPointsSuccess,
  fetchAlertMetricDataPointsFailure,
  highChartCreated,
  highChartDestroyed,
  fetchTriggeredAlertsTotal,
  fetchTriggeredAlertsTotalLastDay,
  timeZoneChanged,
);
export default alertsConsoleEpic;
