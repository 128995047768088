// @flow
import React from 'react';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import {bcTypes} from 'bc/services/bcTypes';

import S3SchedularModal from 'bc/components/streams/editor/byType/s3/s3Schedular/S3SchedularModal';
import CoralogixSchedularModal from 'bc/components/streams/editor/byType/coralogix/corlogixSchedular/CoralogixSchedularModal';
import SqlSchedularModal from 'bc/components/streams/editor/byType/sql/sqlSchedular/SqlSchedularModal';

import './SchedulerEditorModal.module.scss';

type PropTypes = {
  dataStream: Object,
  isModalOpen: Boolean,
  onClose: Function,
};

export default class SchedulerEditorModal extends React.PureComponent {
  props: PropTypes;

  getEditor = () => {
    switch (this.props.dataStream.type) {
      case bcTypes.s3.type:
        return <S3SchedularModal onClose={this.props.onClose} />;
      case bcTypes.mysql.type:
        return <SqlSchedularModal onClose={this.props.onClose} />;
      case bcTypes.coralogix.type:
        return <CoralogixSchedularModal onClose={this.props.onClose} />;

      default:
        return null;
    }
  };

  render() {
    return (
      <Modal isOpen={this.props.isModalOpen} onClose={this.doNothing} size={SIZES.SMALL} isCloseButtonHidden>
        <div styleName="root">
          <header>Scheduling Options</header>
          {this.getEditor()}
        </div>
      </Modal>
    );
  }
}
