const makeFirstSearchObjectCustomizer = (metric) => {
  let hasMatch = false;
  return (value) => {
    if (value && value.type === 'metric' && value.searchObject && !hasMatch) {
      hasMatch = true;
      return {
        ...value,
        searchObject: {
          ...value.searchObject,
          expression: [
            ...value.searchObject.expression.filter((expressionItem) => expressionItem.key !== 'what'),
            {
              type: 'property',
              key: 'what',
              value: metric.what,
              exact: true,
            },
            ...(metric.origin
              ? [
                  {
                    type: 'origin',
                    originType: metric.origin.type,
                    key: 'originId', // metric.origin.title,
                    value: metric.origin.id,
                    exact: true,
                  },
                ]
              : []),
            ...metric.properties
              .filter((item) => item.key !== 'func' && item.key !== 'mtype')
              .map((property) => ({
                type: 'property',
                key: property.key,
                value: property.value,
                exact: true,
              })),
          ],
        },
      };
    }
    return undefined;
  };
};

export default makeFirstSearchObjectCustomizer;
