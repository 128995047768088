/* eslint-disable no-shadow */
// @flow
import React, {Fragment} from 'react';
import {Box} from '@material-ui/core';
import {connect} from 'react-redux';
import {useField} from 'react-final-form';
import {findExpressionInTree, findFuncInTree} from 'common/utils/angularServices';
import * as selectors from 'alerts.management/store/selectors';
import * as metricSelectors from 'metrics/store/selectors';
import {setSelectedAlertInfluencingMetrics, setSuppress} from 'alerts.management/store/actions';
import {getMinInfluencingMetricsConditionRollup} from 'profile/store/selectors';
import {get} from 'lodash';
import TimeScaleCondition from './TimeScaleCondition';
import WhenSection from './WhenSection';
import SubConditionsV2 from './SubConditionsV2';
import FailOnAbsence from './FailOnAbsence';
import './InfluencingMetrics.module.scss';

type PropTypes = {
  setSelectedAlertInfluencingMetrics: Function,
  id: string,
  influencingMetrics: Object,
  simulationFiltersValidationResult: Object,
  alertTreeModel: Object,
  influencingMetricsInfo: Object,
  simulationFilter: Object,
  setSuppress: Function,
  minInfluencingMetricsConditionRollup: string,
};

const InfluencingMetricsFilterError = () => {
  const {
    meta: {touched, error},
  } = useField('influencingMetricsFilter');
  return touched && error ? (
    <div styleName="errors">
      <div>{error}</div>
    </div>
  ) : null;
};

const TriggerOnSentence = ({alertTreeModel}: {alertTreeModel: Object}) => {
  let isWahtProblematicFunction = false;
  ['ratioPairs', 'pairs', 'divideSeries', 'subtractSeries'].forEach((funcName) => {
    if (findFuncInTree(alertTreeModel.expressionTree.root, funcName)) {
      isWahtProblematicFunction = true;
    }
  });
  if (isWahtProblematicFunction) {
    return (
      <div styleName="trigger-on">
        <span>Trigger the current alert</span>
      </div>
    );
  }

  const expression = findExpressionInTree(alertTreeModel.expressionTree.root);
  const findWhatExpression = expression ? expression.searchObject.expression.find((exp) => exp.key === 'what') : null;
  const whatValue = findWhatExpression ? findWhatExpression.value : null;
  if (whatValue) {
    return (
      <div styleName="trigger-on">
        <span>Trigger the alert on</span>
        <span styleName="on-measure">{whatValue}</span>
      </div>
    );
  }
  return (
    <div styleName="trigger-on">
      <span>Trigger the current alert</span>
    </div>
  );
};

class influencingMetrics extends React.PureComponent {
  props: PropTypes;

  updateRollup = (rollupObj) => {
    this.props.setSelectedAlertInfluencingMetrics({id: this.props.id, ...rollupObj});
  };

  setSuppress = (value) => {
    const {id, setSuppress} = this.props;
    setSuppress({id, value});
  };

  matchingPropertiesErrorMsgs = (failure) => {
    const userSelectionMatchingProperties = this.props.influencingMetrics.matchingProperties;
    const matchingProperties = get(this.props.influencingMetricsInfo, 'data.matchingProperties', []);
    const totalMet = get(this.props.influencingMetricsInfo, 'data.total', 0);

    if (failure.id === 6026) {
      if (
        userSelectionMatchingProperties.length &&
        matchingProperties.length &&
        userSelectionMatchingProperties.length === matchingProperties.length
      ) {
        return 'matching property collision, please refine influencing metric definition';
      }
      if (userSelectionMatchingProperties.length) {
        return 'Please select more matching properties to avoid collision.';
      }
      if (matchingProperties.length && !userSelectionMatchingProperties.length) {
        return 'Please select matching properties.';
      }
      if (!matchingProperties.length && !userSelectionMatchingProperties.length && totalMet !== 1) {
        // eslint-disable-next-line max-len
        return 'This measure cannot be used, there are no matching dimensions between the alert metrics and the influencing metrics.';
      }
      return `${failure.message}.`;
    }
    return `${failure.message}.`;
  };

  render() {
    const {
      influencingMetrics,
      influencingMetricsInfo: influencingMetricsInfoProp,
      simulationFiltersValidationResult,
      simulationFilter: {type},
      minInfluencingMetricsConditionRollup,
    } = this.props;
    const conditionErrors = get(simulationFiltersValidationResult, `data.failures[${influencingMetrics.id}]`, []);
    const influencingMetricsInfoErrors = get(influencingMetricsInfoProp, 'data.validation.failures', []);
    const influencingMetricsInfoIsLoading = get(influencingMetricsInfoProp, 'isLoading', false);
    const conditionErrorsIsLoading = simulationFiltersValidationResult.isLoading;
    const hasInfluencingMetric = !!get(influencingMetricsInfoProp, 'data.what', false);
    const disabledStyleName = hasInfluencingMetric ? '' : 'section-disabled';

    const rollupHierarchy = ['short', 'medium', 'long', 'longlong', 'weekly'];
    const clientRollup = minInfluencingMetricsConditionRollup.replace('ROLLUP', '').toLowerCase();
    const lessThanMinRollups = rollupHierarchy.slice(0, rollupHierarchy.indexOf(clientRollup));

    return (
      <div styleName="main">
        <div styleName="section">
          <div styleName="header">Influencing Metrics</div>
          <TriggerOnSentence alertTreeModel={this.props.alertTreeModel} />
        </div>

        {/* 'when' section */}
        <div styleName="section section-wide">
          <WhenSection
            id={this.props.id}
            influencingMetrics={this.props.influencingMetrics}
            alertTreeModel={this.props.alertTreeModel}
          />
        </div>

        {/* sub-conditions V2 */}
        <div styleName={`section section-wide sub-conditions ${disabledStyleName}`}>
          <SubConditionsV2 id={influencingMetrics.id} subConditions={influencingMetrics.subConditions} />
        </div>

        {/* time scale */}

        <div styleName={`section ${disabledStyleName}`}>
          <TimeScaleCondition
            rollup={influencingMetrics.rollup}
            type={type.value}
            onChange={this.updateRollup}
            useAnomalyValues={influencingMetrics.useAnomalyValues}
            omitRollups={lessThanMinRollups}
          />
        </div>

        <div styleName={`section section-wide ${disabledStyleName}`}>
          <FailOnAbsence failOnAbsence={influencingMetrics.failOnAbsence} onSetSuppress={this.setSuppress} />
        </div>
        <Box
          display="flex"
          justifyContent="center"
          alignItems={conditionErrorsIsLoading || influencingMetricsInfoIsLoading ? 'center' : 'stretch'}
          flexDirection="column"
          width={1}
        >
          {conditionErrorsIsLoading || influencingMetricsInfoIsLoading ? (
            <i className="icon ion-load-c spin" styleName="error-loader" />
          ) : (
            <Fragment>
              <InfluencingMetricsFilterError />
              {influencingMetricsInfoErrors.map((er) => (
                <div styleName="errors">
                  <div key={er.message}>{er.message}</div>
                </div>
              ))}
              {hasInfluencingMetric &&
                conditionErrors.map((f) => (
                  <div styleName="errors">
                    <div key={f.message}>{this.matchingPropertiesErrorMsgs(f)}</div>
                  </div>
                ))}
            </Fragment>
          )}
        </Box>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    influencingMetrics: selectors.getSelectedAlertInfluencingMetrics(state).filter((c) => c.id === props.id)[0],
    influencingMetricsInfo: selectors.influencingMetricsInfo(state)[props.id],
    simulationFiltersValidationResult: selectors.getSimulationFiltersValidationResults(state),
    alertTreeModel: metricSelectors.getExpressionTreeModelPayload(state),
    simulationFilter: selectors.getSelectedAlertSimulationFilters(state),
    minInfluencingMetricsConditionRollup: getMinInfluencingMetricsConditionRollup(state),
  }),
  {
    setSelectedAlertInfluencingMetrics,
    setSuppress,
  },
)(influencingMetrics);
