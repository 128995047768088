// @flow
import React, {Fragment} from 'react';
import {useField} from 'react-final-form';
import Box from '@material-ui/core/Box';
import TimeZone from 'common/componentsV2/TimeZone';

import SubTitle from '../SubTitle';

const TimeZoneSettings = () => {
  const {
    input: {value, onChange},
  } = useField('timeZoneName');

  return (
    <Fragment>
      <SubTitle title="Settings" />
      <Box display="flex" mb={3}>
        <Box mr={1.5} width={200}>
          <Box mb={1}>Time Zone</Box>
          <TimeZone value={value} onChange={onChange} />
        </Box>
      </Box>
    </Fragment>
  );
};

export default TimeZoneSettings;
