// @flow
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as selectors from 'alerts.console/store/selectors';
import * as actions from 'alerts.console/store/actions';
import {REFRESH_ALERTS, TABLE_TITLES} from 'alerts.console/services/alertsConsoleService';
import AlertsCounter from 'alerts.console/components/alertsList/AlertsCounter';
import TitleHeaderTableOrder from 'common/componentsV2/table/TitleHeaderTableOrder';
import IntervalsDropDown from 'common/components/intervalsDropDown/IntervalsDropDown';

type PropTypes = {
  setQueryParams: Function,
  dateRange: Object,
};

const colsGap = 12;

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
  },
  startTime: {
    display: 'flex',
    alignItems: 'center',
    width: 97 + colsGap,
  },
  updatedTime: {
    display: 'flex',
    alignItems: 'center',
    width: 97 + colsGap,
  },
  duration: {
    display: 'flex',
    alignItems: 'center',
    width: 60 + colsGap,
  },
  score: {
    display: 'flex',
    alignItems: 'center',
    width: 45 + colsGap,
  },
  metrics: {
    display: 'flex',
    alignItems: 'center',
    width: 45 + colsGap,
  },
  severity: {
    display: 'flex',
    alignItems: 'center',
    width: 75 + colsGap,
  },
};

const AlertsListHeader = (props: PropTypes) => {
  const dispatch = useDispatch();
  const scrollbarWidth = useSelector(selectors.getScrollbarWidth);
  // const isListScrolled = useSelector(selectors.getIsListScrolled);
  const queryParamsViews = useSelector(selectors.getQueryParamsViews);
  const displayedTotalAlerts = useSelector(selectors.getDisplayedTotalAlerts);
  const alertsTriggeredTotal = useSelector(selectors.getAlertsTriggeredTotal);

  const handleTitleOrderChange = (columnName) => {
    let updatedDirection = 'desc';

    if (columnName === queryParamsViews.sort) {
      updatedDirection = queryParamsViews.order === 'desc' ? 'asc' : 'desc';
    }
    props.setQueryParams({sort: columnName, order: updatedDirection});
  };

  return (
    <div style={styles.wrapper}>
      <div style={styles.rightSide}>
        <AlertsCounter
          displayedTotalAlerts={displayedTotalAlerts}
          alertsTriggeredTotal={alertsTriggeredTotal}
          dateRange={props.dateRange}
        />
        <IntervalsDropDown
          onAction={() => dispatch(actions.fetchAlerts())}
          listOfIntervals={REFRESH_ALERTS}
          dataSegment="alerts-console-refresh"
        />
      </div>

      <div style={{...styles.leftSide, marginRight: scrollbarWidth || 2}}>
        {TABLE_TITLES.map((field) => (
          <div key={field.id} style={styles[field.name]}>
            <TitleHeaderTableOrder
              onChange={handleTitleOrderChange}
              title={field.title}
              selectedDirection={queryParamsViews.order}
              columnName={field.name}
              isSortable={field.isSortable}
              isActive={field.name === queryParamsViews.sort}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AlertsListHeader;
