import React from 'react';
import './LabelAndValueRow.module.scss';

type PropTypes = {
  title: string,
  additionalTitle: Node,
  children: Node,
  isBreakLine: boolean,
};

const LabelAndValueRow = ({title, additionalTitle, children, isBreakLine}: PropTypes) => (
  <div styleName="container">
    <header styleName="header">
      <h3 styleName="title">{title}</h3>
      {additionalTitle}
    </header>
    <div styleName={['value', isBreakLine ? 'break-line' : ''].join(' ').trim()}>{children}</div>
  </div>
);

export default LabelAndValueRow;
