// @flow
import React, {PureComponent} from 'react';
import {components} from 'react-select2';
import connect from 'react-redux/es/connect/connect';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {getDataMangerQueryParams, getDatamanagerStreamsCurrentTypes} from 'bc/store/selectors';
import {getSourceIcon} from 'bc/services/dataManagerService';
import {segmentClickEvent} from 'common/store/actions';

import './DataManagerFilters.module.scss';

type PropTypes = {
  setQueryParams: Function,

  // connect
  dataQueryParams: Object,
  typesList: Array,
  segmentClickEvent: Function,
};

@connect(
  (state) => ({
    dataQueryParams: getDataMangerQueryParams(state),
    typesList: getDatamanagerStreamsCurrentTypes(state),
  }),
  {
    segmentClickEvent,
  },
)
export default class TypeFilter extends PureComponent {
  props: PropTypes;

  onChange = (values) => {
    const {setQueryParams} = this.props;
    const currentValues = values.length ? values.map((i) => i.value).join(',') : undefined;
    this.props.segmentClickEvent({
      category: 'data-management-filter',
      name: `types-${currentValues}`,
    });
    setQueryParams({type: currentValues});
  };

  onClearAll = () => {
    this.props.setQueryParams({type: undefined});
  };

  render() {
    const {dataQueryParams, typesList} = this.props;
    const selectTypesArr = dataQueryParams.type ? dataQueryParams.type.split(',') : [];
    const optionCurrent = typesList.filter((i) => selectTypesArr.includes(i.value));

    return (
      <SelectAndt
        id="typeFilter"
        automationId="DataManagerTypeFilter"
        extraClassName="alerts-dropdown-btn"
        options={typesList}
        buttonWidth={190}
        optionHeight={40}
        menuWidth={240}
        type={TYPE_NEW_MULTI}
        theme={THEME_HIGHLIGHTED}
        onChange={this.onChange}
        value={optionCurrent}
        isMulti
        placeholder="All"
        isClearable={false}
        closeMenuOnSelect={false}
        customComponent={{
          Option: (p) => (
            <components.Option {...p}>
              <div
                styleName={p.isSelected ? 'option-wrap-selected' : 'option-wrap'}
                className={p.isSelected ? 'icn-selection16-checkboxon' : 'icn-selection16-checkboxoff'}
              >
                <span styleName="logo">{getSourceIcon(p.value)}</span>
                <span styleName="title">{p.children}</span>
              </div>
            </components.Option>
          ),
        }}
        menuFooterComponent={
          <FormDdlActionBar
            isVisible
            isClearAllDisabled={!optionCurrent || !optionCurrent.length}
            onClearAll={this.onClearAll}
          />
        }
      />
    );
  }
}
