// @flow
import React, {Fragment, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as selectors from 'alerts.management/store/selectors';
import * as actions from 'alerts.management/store/actions';
import ExpandableAdd from 'common/componentsV2/expandableSections/ExpandableAdd';
import TooltipArea from 'common/componentsV2/TooltipArea';
import ConfModal from 'common/componentsV2/modal/ConfModal';
import InfluencingMetrics from './influencingMetrics/InfluencingMetrics';

const AddInfluencingMetrics = () => {
  const dispatch = useDispatch();
  const [removeCondId, setRemoveCondId] = useState(null);

  const addConditionToSelectedAlert = useCallback((...args) => dispatch(actions.addConditionToSelectedAlert(...args)), [
    dispatch,
  ]);
  const removeConditionFromSelectedAlert = useCallback((...args) => setRemoveCondId(args), [dispatch]);

  const influencingMetricsIds = useSelector(selectors.getInfluencingMetricsIds);

  const availableInfluencingMetricsCondition = useSelector(selectors.getAvailableInfluencingMetricsCondition);

  const onConfirm = () => {
    dispatch(actions.removeConditionFromSelectedAlert(...removeCondId));
    setRemoveCondId(null);
  };
  const onCancel = () => {
    setRemoveCondId(null);
  };

  return (
    <Fragment>
      <TooltipArea text="Tooltip content">
        {() => (
          <ExpandableAdd
            automationId="influencingMetrics"
            label={<React.Fragment>Add influencing metrics</React.Fragment>}
            onAdd={() => addConditionToSelectedAlert(availableInfluencingMetricsCondition.dataObj, {})}
            onRemove={removeConditionFromSelectedAlert}
          >
            {influencingMetricsIds.map((id) => (
              <InfluencingMetrics key={id} id={id} />
            ))}
          </ExpandableAdd>
        )}
      </TooltipArea>
      {removeCondId ? (
        <ConfModal
          title="Remove Influencing Metrics Condition"
          isDanger
          confirmText="Remove"
          isOpen={removeCondId}
          onClose={onCancel}
          onConfirm={onConfirm}
        >
          <div className="text14reg lineHeight_18">Are you sure you want to remove this condition from the alert?</div>
        </ConfModal>
      ) : null}
    </Fragment>
  );
};

export default React.memo(AddInfluencingMetrics);
