// @flow
import React, {useEffect, useRef, useMemo, useState, useCallback} from 'react';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import {AutoSizer, List, CellMeasurerCache, CellMeasurer} from 'react-virtualized';
import useCrosshairSync from 'common/utils/useCrosshairSync';
import {Box} from '@material-ui/core';
import {TinyScrollBox} from 'common/componentsV2/boxTools';
import LoadingPlaceholder from 'charts/timeSeries/components/LoadingPlaceholder';
import MetricExplorerSimulation from './MetricExplorerSimulation';

type PropTypes = {
  metrics: Array,
  isLoading: boolean,
  isAnyLoading: boolean,
  isInvalid: boolean,
  timeScale: string,
  isZoomLoading: boolean,
  seriesProperties: Object,
  totalAmount: number,
  byTreeExp: Array,
  dateRange: Object,
};

const overscanIndicesGetter = ({
  // eslint-disable-next-line no-unused-vars
  direction,
  cellCount,
  // eslint-disable-next-line no-unused-vars
  scrollDirection,
  overscanCellsCount,
  startIndex,
  stopIndex,
}) => ({
  overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
  overscanStopIndex: Math.min(cellCount - 1, stopIndex + overscanCellsCount),
});

const ChildrenRenderer = ({onMount, children}: {onMount: Function, children: React.Node}) => {
  useEffect(() => {
    if (onMount) {
      onMount();
    }
  }, []);
  return children;
};

const MetricExplorerList = ({
  metrics,
  isLoading,
  isAnyLoading,
  isInvalid,
  timeScale,
  isZoomLoading,
  seriesProperties,
  totalAmount,
  byTreeExp,
  dateRange,
}: PropTypes) => {
  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        defaultHeight: 300,
        minHeight: 115,
        fixedWidth: true,
      }),
    [],
  );

  const chartIds = useMemo(() => metrics.map((metric, index) => `expression-builder-${metric.id}-${index}`), [metrics]);

  const mouseMoveHandler = useCrosshairSync(chartIds);

  const [overscanValue, setOverscanValue] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [scrollToIndex, setScrollToIndex] = useState(-1);
  const mouseOverHandler = useCallback(() => {
    if (overscanValue === 0) {
      setOverscanValue(5);
    }
  }, [overscanValue]);
  const mouseLeaveHandler = useCallback(() => {
    setOverscanValue(0);
  });
  const list = useRef();

  useEffect(() => {
    if (list.current && isLoading) {
      list.current.scrollToPosition(0);
      if (overscanValue !== 0) {
        setOverscanValue(0);
      }
    }
  }, [isLoading, seriesProperties]);

  useEffect(() => {
    setScrollToIndex(0);
  }, [metrics.length]);

  const Row = useMemo(
    () => ({
      index,
      // eslint-disable-next-line no-unused-vars
      key,
      parent,
      style,
    }: {
      index: number,
      key: string,
      parent: string,
      style: Object,
    }) => {
      const metric = metrics[index];
      if (index === metrics.length) {
        return (
          <CellMeasurer cache={cache} columnIndex={0} key={`${key}_lastRow`} parent={parent} rowIndex={index}>
            {({measure, registerChild}) => (
              <ChildrenRenderer onMount={measure}>
                <div ref={registerChild} key={key} style={style}>
                  {metrics && !isAnyLoading && totalAmount ? (
                    <Box display="flex" justifyContent="center" mt={4} mb={20}>
                      <Box
                        display="flex"
                        alignContent="center"
                        justifyContent="center"
                        borderRadius={6}
                        color="#8995a0"
                        p="12px"
                        width="fit-content"
                        fontSize="14px"
                        fontWeight="500"
                        border="solid 2px #d4d9e0"
                      >
                        {`Showing ${metrics.length} out of ${totalAmount} Metrics`}
                      </Box>
                    </Box>
                  ) : null}
                </div>
              </ChildrenRenderer>
            )}
          </CellMeasurer>
        );
      }
      return (
        <CellMeasurer cache={cache} columnIndex={0} key={`${metric.id}_${index}`} parent={parent} rowIndex={index}>
          {({measure, registerChild}) => (
            <div ref={registerChild} style={style}>
              {isLoading ? (
                <ChildrenRenderer onMount={measure}>
                  <Box flexShrink={0} flexGrow={1} mt={3} height={250}>
                    <LoadingPlaceholder />
                  </Box>
                </ChildrenRenderer>
              ) : (
                <Box flexShrink={0} flexGrow={1}>
                  <MetricExplorerSimulation
                    isZoomLoading={isZoomLoading}
                    index={index}
                    metric={metric}
                    timeScale={timeScale}
                    onEndLoad={measure}
                    mouseMoveHandler={mouseMoveHandler}
                    seriesProperties={seriesProperties}
                    expressionTreeId={metric.treeId}
                    byTreeExp={byTreeExp}
                    dateRange={dateRange}
                  />
                </Box>
              )}
            </div>
          )}
        </CellMeasurer>
      );
    },
    [timeScale, metrics, isAnyLoading, isInvalid, isZoomLoading, seriesProperties, dateRange, byTreeExp, isLoading],
  );
  return (
    <Box display="flex" flexDirection="column" position="relative" height={1} mt={0.5}>
      <Box
        width={1}
        zIndex={1}
        height={12}
        position="absolute"
        css={{backgroundImage: 'linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255,255,255))'}}
      />
      <Box
        onMouseOver={mouseOverHandler}
        onMouseLeave={mouseLeaveHandler}
        width={1}
        display="flex"
        flexDirection="column"
        flexGrow={1}
        flexShrink={0}
        pr={1}
      >
        <AutoSizer>
          {({height, width}) => (
            <TinyScrollBox>
              <List
                ref={list}
                overscanRowCount={overscanValue}
                overscanIndicesGetter={overscanIndicesGetter}
                width={width}
                height={height}
                rowCount={metrics.length + 1}
                rowHeight={cache.rowHeight}
                rowRenderer={Row}
              />
            </TinyScrollBox>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
};

export default React.memo(MetricExplorerList);
