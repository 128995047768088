// @flow

import React from 'react';
import './PageLayout.module.scss';

type PropTypes = {
  header?: Node,
  children: Node,
  shouldOverflowY?: Boolean,
  shouldBottomBar?: Boolean,
  shouldFullWidth?: Boolean,
};
export default class PageLayout extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    shouldOverflowY: false,
    shouldBottomBar: false,
    header: false,
    shouldFullWidth: false,
  };

  render() {
    const {shouldOverflowY, header, children, shouldBottomBar, shouldFullWidth} = this.props;
    const isOverflowY = shouldOverflowY ? 'auto-scroll' : '';
    const isBottomBar = shouldBottomBar ? 'bottomBar' : '';
    const isFullWidth = shouldFullWidth ? 'fullWidth' : '';
    return (
      <div styleName={`page-layout ${isOverflowY} ${isBottomBar}`}>
        <div styleName={`content-wrapper ${isFullWidth}`}>
          {header && <div styleName="content page-header">{header}</div>}
          <div styleName="content" className="shell-col">
            {children}
          </div>
        </div>
      </div>
    );
  }
}
