// @flow
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import SmartTooltip from 'common/componentsV2/Tooltip';
import ConfirmationModal from 'common/components/modals/ConfirmationModal';
import {getEditorValidationMessage, getStreamQueryId} from 'bc/services/dataStreamService';
import {getTypeDetails, bcTypes, isBcTypeEmptyMetricsOrDimensionsAllowed} from 'bc/services/bcTypes';
import {runAsyncQueryDataStream, createStreamSchemaNewColumn} from 'bc/store/actions';
import {replaceRoute, setRoute} from 'common/store/actions';
import {get} from 'lodash';
import Button, {COLORS} from 'common/componentsV2/Button';
import './StreamEditorUserActions.module.scss';

type PropTypes = {
  // connect
  stream: Object,
  isStreamNeedUpdating: boolean,
  isSelectedStreamUniqueName: boolean,

  runAsyncQueryDataStream: Function,
  createStreamSchemaNewColumn: Function,
  replaceRoute: Function,
  setRoute: Function,
};

const EMPTY_ARRAY = [];
const CUSTOM_METRIC_NAME = 'Event Count';
const CUSTOM_DIMENSION_NAME = 'Dummy Dimension';
const TRANSFORM = {
  name: 'const',
  parameters: ['1'],
};
const DUMMY_TRANSFORM = {
  name: 'dummy',
  parameters: ['1'],
};

@connect(
  (state) => ({
    stream: selectors.getSelectedDataStream(state),
    isStreamNeedUpdating: selectors.getIsDataStreamNeedUpdating(state),
    isSelectedStreamUniqueName: selectors.getIsSelectedDataStreamUniqueName(state),
  }),
  {
    runAsyncQueryDataStream,
    createStreamSchemaNewColumn,
    replaceRoute,
    setRoute,
  },
)
export default class StreamEditorUserActions extends React.PureComponent {
  props: PropTypes;

  state = {isModalOpen: false};

  componentWillUnmount() {
    if (this.cancelTimeoutId) {
      clearTimeout(this.cancelTimeoutId);
    }
  }

  onConfirmModal = () => {
    this.closeModal();

    const newCol = {};
    if (this.isGenerateCustomDimension()) {
      newCol.colName = CUSTOM_DIMENSION_NAME;
      newCol.colType = 'dimension';
      newCol.transform = DUMMY_TRANSFORM;
    } else if (this.isGenerateCustomMetric()) {
      newCol.colName = CUSTOM_METRIC_NAME;
      newCol.colType = 'metric';
      newCol.transform = TRANSFORM;
      newCol.targetType = 'counter';
    }

    if (this.isGenerateCustomDimension() || this.isGenerateCustomMetric()) {
      this.props.createStreamSchemaNewColumn(newCol, {
        runStreamPreview: false,
        goToTablePreviewRoute: false,
        isAsyncQuery: true,
      });
    } else {
      this.props.runAsyncQueryDataStream(this.props.stream);
    }

    this.cancelTimeoutId = setTimeout(() => {
      this.props.replaceRoute('/bc/data-manager');
    }, 1000);
  };

  nextNoMeasuresOrDeimnesionsSelected = () => {
    const newCol = {};
    if (this.isGenerateCustomDimension()) {
      newCol.colName = CUSTOM_DIMENSION_NAME;
      newCol.colType = 'dimension';
      newCol.transform = DUMMY_TRANSFORM;
    } else {
      newCol.colName = CUSTOM_METRIC_NAME;
      newCol.colType = 'metric';
      newCol.transform = TRANSFORM;
      newCol.targetType = 'counter';
    }

    this.props.createStreamSchemaNewColumn(newCol, {
      runStreamPreview: false,
      goToTablePreviewRoute: true,
    });
  };

  isGenerateCustomMetric = () => {
    const {stream} = this.props;
    return (
      (stream.type === bcTypes.mparticle.type &&
        !get(stream, 'schema.columns', EMPTY_ARRAY).find((item) => item.name === CUSTOM_METRIC_NAME)) ||
      (isBcTypeEmptyMetricsOrDimensionsAllowed(stream.type) &&
        !get(stream.metrics, 'length', 0) &&
        !get(stream, 'schema.columns', EMPTY_ARRAY).find((item) => item.name === CUSTOM_METRIC_NAME))
    );
  };

  isGenerateCustomDimension = () => {
    const {stream} = this.props;
    return (
      isBcTypeEmptyMetricsOrDimensionsAllowed(stream.type) &&
      !get(stream.dimensions, 'length', 0) &&
      !get(stream, 'schema.columns', EMPTY_ARRAY).find(
        (item) => get(item, 'transform.name', '') === DUMMY_TRANSFORM.name,
      )
    );
  };

  getValidationMessage = (isAsync) => {
    const {stream, isStreamNeedUpdating, isSelectedStreamUniqueName} = this.props;

    if (isStreamNeedUpdating) {
      return 'Data stream updating';
    }
    if (!isSelectedStreamUniqueName) {
      return 'Data stream must have unique name';
    }
    if (isAsync && get(stream, 'uiState.uniqeQueryId') === getStreamQueryId(stream)) {
      return 'No changes since last query';
    }

    return getEditorValidationMessage(stream);
  };

  closeModal = () => {
    this.setState({isModalOpen: false});
  };

  openModal = () => {
    this.setState({isModalOpen: true});
  };

  renderActionButton = () => {
    const {stream} = this.props;
    if (!stream) {
      return null;
    }

    const {isAsyncQuery} = getTypeDetails(stream.type);
    const isGenerateCustomMetric = this.isGenerateCustomMetric();
    const isGenerateCustomDimension = this.isGenerateCustomDimension();
    const validationMsg = this.getValidationMessage(isAsyncQuery);
    const RUN_QUERY = isAsyncQuery ? 'QUERY' : 'NEXT';

    const continueToPreviewLink = (
      <Button
        colorSchema={COLORS.GHOST_BLUE}
        extraClassName="next-button"
        iconRight="icn-arrow16-arrowright"
        text={RUN_QUERY}
        onClick={() => this.props.setRoute(`/bc/data-streams/${this.props.stream.id}/preview`)}
        automationId="streamUserActionsButton"
      />
    );

    const continueToDataManagerLink = (
      <Fragment>
        <Button
          colorSchema={COLORS.GHOST_BLUE}
          extraClassName="next-button"
          iconRight="icn-arrow16-arrowright"
          text={RUN_QUERY}
          onClick={this.openModal}
          automationId="streamUserActionsButton"
        />
        <ConfirmationModal
          onClose={this.closeModal}
          onConfirm={this.onConfirmModal}
          title="Run Query"
          message={
            // eslint-disable-next-line max-len
            "Querying Adobe Analytics may take up to 30 mins. Once the query is done you can continue to the 'Stream Table' by clicking 'Edit' on the stream"
          }
          isOpen={this.state.isModalOpen}
        />
      </Fragment>
    );

    const generateMeasuresOrDimensionsColumn = (
      <Button
        colorSchema={COLORS.GHOST_BLUE}
        extraClassName="next-button"
        iconRight="icn-arrow16-arrowright"
        text={RUN_QUERY}
        onClick={this.nextNoMeasuresOrDeimnesionsSelected}
        automationId="streamUserActionsButton"
      />
    );

    const disabledContinueLink = (
      <SmartTooltip placement="right" content={validationMsg}>
        <div>
          <Button
            colorSchema={COLORS.GHOST_BLUE}
            extraClassName="next-button next-disabled"
            iconRight={this.props.isStreamNeedUpdating ? 'icon ion-load-c spin' : 'icn-arrow16-arrowright'}
            text={RUN_QUERY}
            automationId="streamUserActionsDisabledButton"
          />
        </div>
      </SmartTooltip>
    );

    if (!validationMsg) {
      if (isAsyncQuery) {
        return continueToDataManagerLink;
      }
      if (isGenerateCustomMetric || isGenerateCustomDimension) {
        return generateMeasuresOrDimensionsColumn;
      }
      return continueToPreviewLink;
    }

    return disabledContinueLink;
  };

  render() {
    return this.renderActionButton();
  }
}
