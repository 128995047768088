// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {GROUP_COLOR_SCHEMAS} from 'anodot-objects-models';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import './UserGroupsInfoItem.module.scss';
import {getMyGroups} from 'admin.users/store/selectors';

type PropTypes = {
  myGroups: Array,
};

@connect(
  (state) => ({
    myGroups: getMyGroups(state),
  }),
  {},
)
export default class UserGroupsInfoItem extends PureComponent {
  props: PropTypes;

  tooltipComponent = () => {
    const {myGroups} = this.props;

    if (!myGroups.length || myGroups.length === 1) {
      return null;
    }

    return (
      <span>
        {myGroups.map((myGroup) => (
          <span key={`gr-user-tooltip-${myGroup._id}`}>
            {myGroup.name}
            <br />
          </span>
        ))}
      </span>
    );
  };

  render() {
    const {myGroups} = this.props;

    const backGroundColor = myGroups.length === 1 ? myGroups[0].colorSchema : GROUP_COLOR_SCHEMAS.GRAY;
    const groupsText = myGroups.length === 1 ? myGroups[0].name : `${myGroups.length} Groups`;

    return (
      <Tooltip content={this.tooltipComponent()} type={TYPES.SMALL} placement="right">
        <div styleName={`root ${backGroundColor}`}>
          <i className="icn-general16-group" styleName="gr-icon" />
          {groupsText}
        </div>
      </Tooltip>
    );
  }
}
