// @flow
import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {fetchPropertiesApi} from 'metrics/store/actions';
import {
  getCurrentCall,
  getExpressionTree,
  getPropertiesApiData,
  getPropertiesApiIsLoading,
} from 'metrics/store/selectors';
import {getUniqueId} from 'common/utils/guid';

const baseBody = {
  // TODO -- GABPAC -- fill it all with real data, not only the expression tree
  name: {auto: true, prefix: null},
  displayOnly: true,
  excludeComposites: true,
  filter: {
    function: 'alphanumeric',
    parameters: [{name: 'Top N', value: 10}],
    children: [],
    id: getUniqueId(),
    type: 'function',
  },
  scalarTransforms: [
    {
      function: 'current',
      children: [],
      id: getUniqueId(),
      parameters: [],
      type: 'function',
    },
  ],
  context: '',
};
const setBodyForFunction = (expTree) => ({
  ...baseBody,
  expressionTree: expTree,
});
const setBodyForMeasure = (expTree) => ({
  ...baseBody,
  expressionTree: {root: expTree.root.children[0]},
});

const SelectDimensions = (props: {onChange: Function, value: String}) => {
  const dispatch = useDispatch();
  const expressionTree = useSelector(getExpressionTree);
  const properties = useSelector(getPropertiesApiData);
  const currentCall = useSelector(getCurrentCall);
  const propertiesApiIsLoading = useSelector(getPropertiesApiIsLoading);
  const [cleanProperties, setCleanProperties] = useState([]);

  useEffect(
    () => () => {
      if (!cleanProperties.find((item) => item.value === props.value)) {
        props.onChange(null);
      }
    },
    [],
  );

  useEffect(() => {
    if (properties.length) {
      setCleanProperties(
        properties
          .filter((prop) => prop.charAt(0) !== '#' && prop.charAt(0) !== '@' && prop !== 'what')
          .map((cleanProp) => ({label: cleanProp, value: cleanProp})),
      );
    }
  }, [properties]);

  useEffect(() => {
    if (currentCall === 'noCache') {
      setCleanProperties([]);
    }
    if (expressionTree.root.function.length) {
      dispatch(fetchPropertiesApi(setBodyForFunction(expressionTree)));
    }
    if (!expressionTree.root.function.length) {
      dispatch(fetchPropertiesApi(setBodyForMeasure(expressionTree)));
    }
  }, [currentCall]);

  return (
    <Fragment>
      <div className="text16reg lineHeight_16 mr_1">Route alert by:</div>
      <SelectAndt
        menuWidth={190}
        buttonWidth={190}
        placeholder={propertiesApiIsLoading ? 'Loading...' : 'Select Dimension'}
        disabled={propertiesApiIsLoading}
        id="alertSettingsMinimumDurationFilter"
        automationId="AlertAckFilter"
        extraClassName="alerts-dropdown-btn"
        options={cleanProperties}
        optionHeight={40}
        type={TYPE_NEW_NO_SEARCH}
        theme={THEME_NOT_HIGHLIGHTED}
        onChange={(item) => props.onChange(item.value)}
        value={cleanProperties.find((item) => item.value === props.value)}
      />
    </Fragment>
  );
};

export default React.memo(SelectDimensions);
