// @flow
import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {getMeOwnerOrganization, getLeanUserProfile} from 'profile/store/selectors';
import Box from '@material-ui/core/Box';
import Checkbox from 'common/componentsV2/Checkbox';
import {useField} from 'react-final-form';
import {TypographyBox} from 'common/componentsV2/boxTools';
import Grid from '@material-ui/core/Grid';
import Input from 'common/componentsV2/Input';
import {makeStyles} from '@material-ui/core/styles';
import CopyToClipboardButton from 'common/components/CopyToClipboardButton';
import {getBaseClusterURL} from 'common/utils/http';

import '../../pages/userSettings.module.scss';

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.orange[500],
  },
  link: {
    wordBreak: 'break-all',
  },
  subLabel: {
    fontWeight: 700,
    fontSize: '14px',
  },
}));

const AzureActiveDirectoryAuth = () => {
  const getUserOrg = useSelector((state) => getMeOwnerOrganization(state));
  const leanMe = useSelector((state) => getLeanUserProfile(state));

  const {
    input: {onChange: handleEnforce, checked},
  } = useField('enforceAzure', {type: 'checkbox'});
  const {
    input: {onChange: handleClientId, value: valueClientId},
    meta: {invalid: validClient, touched: touchedClientId},
  } = useField('clientId', {validate: (val) => !val});
  const {
    input: {onChange: handleDirectory, value: valueDirectory},
    meta: {invalid: validDirectory, touched: touchedDirectory},
  } = useField('directoryId', {validate: (val) => !val});
  const {
    input: {onChange: handleSecret, value: valueSecret},
    meta: {invalid: validSecret, touched: touchedClientSecret},
  } = useField('clientSecret', {validate: (val) => !val});
  const {
    input: {onChange: handleAutoProv, checked: checkedAutoProv},
  } = useField('autoProvisioningAzure', {type: 'checkbox'});
  const {
    input: {onChange: handleAutoProvRole, checked: checkedAutoProvRole},
  } = useField('autoProvisionRolelessUsersAzure', {type: 'checkbox'});

  const loginUrl = `${getBaseClusterURL(leanMe)}/signin/?orgId=${getUserOrg}`;
  const redirectUrl = `${getBaseClusterURL(leanMe)}/api/v1/signin/openid/azure/return/${getUserOrg}`;

  const classes = useStyles();
  return (
    <Fragment>
      <Box width={480} mb={2.5}>
        <Box mb={1} mt={2.5}>
          <Checkbox
            isChecked={checked}
            onChange={handleEnforce}
            text="For Admin users, enforce a single sign-on authentication."
          />
        </Box>
        <Box mt={1}>
          <TypographyBox variant="body1" mb={0.5}>
            The Admin password is ignored.
          </TypographyBox>
          <TypographyBox variant="hintText">
            {/* eslint-disable-next-line max-len */}
            We recommend not to enforce a single sign-on authentication before verifying that Authentication has been
            set up successfully.
          </TypographyBox>
        </Box>
      </Box>
      <Box py={2.5} borderTop={1} borderColor="gray.200">
        <Grid container>
          <Grid item>
            <TypographyBox variant="body1" width={220}>
              Identity Provider Details:
            </TypographyBox>
          </Grid>
          <Grid item>
            <Box width={400}>
              <Box mb={3.5}>
                <TypographyBox variant="body1">
                  Application (client) ID: <span className={classes.label}>Required</span>
                </TypographyBox>
                <Input
                  type="text"
                  value={valueClientId}
                  onChange={handleClientId}
                  isInvalid={touchedClientId && validClient}
                  fullSize
                />
              </Box>
              <Box mb={3.5}>
                <TypographyBox variant="body1">
                  Directory (tenant) ID: <span className={classes.label}>Required</span>
                </TypographyBox>
                <Input
                  type="text"
                  value={valueDirectory}
                  onChange={handleDirectory}
                  isInvalid={touchedDirectory && validDirectory}
                  fullSize
                />
              </Box>
              <Box mb={3.5}>
                <TypographyBox variant="body1">
                  Client Secret: <span className={classes.label}>Required</span>
                </TypographyBox>
                <Input
                  type="text"
                  value={valueSecret}
                  onChange={handleSecret}
                  isInvalid={touchedClientSecret && validSecret}
                  fullSize
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box py={2.5} borderTop={1} borderColor="gray.200">
        <Grid container>
          <Grid item>
            <TypographyBox variant="body1" width={220}>
              Service Provider Details:
            </TypographyBox>
          </Grid>
          <Grid item>
            <Box width={400}>
              <Box mb={3.5}>
                <TypographyBox variant="body1">Redirect URI:</TypographyBox>
                <Box position="relative">
                  <Input type="text" value={redirectUrl} fullSize isDisabled />
                  <Box styleName="copyBtn">
                    <CopyToClipboardButton copyStr={redirectUrl} tooltipMessage="Copied to clipboard" shouldUseIcon />
                  </Box>
                </Box>
              </Box>
              <TypographyBox variant="body1" className={classes.subLabel}>
                Login URL:{' '}
                <Box component="a" href={loginUrl} className={classes.link}>
                  {loginUrl}
                </Box>
              </TypographyBox>
              <TypographyBox variant="hintText">Use this URL for SSO or use the default sign-in form</TypographyBox>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box py={2.5} borderTop={1} borderColor="gray.200">
        <Box width={480}>
          <Box mb={2.5}>
            <Checkbox isChecked={checkedAutoProv} onChange={handleAutoProv} text="Auto provision new users" />
          </Box>
          <Checkbox
            isChecked={checkedAutoProvRole}
            onChange={handleAutoProvRole}
            text="Automatically add users without a role in Active Directory"
          />
          <Box mb={2} mt={1}>
            <TypographyBox variant="hintText">
              {/* eslint-disable-next-line max-len */}
              Yes - Any user in your active directory will be able to log in to Anodot. Users without a specific role
              will be defined as read-only users.
            </TypographyBox>
          </Box>
          <TypographyBox variant="hintText">
            {/* eslint-disable-next-line max-len */}
            No - Users who do not have an Anodot-related role in your Active Directory, will not be able to log in to
            Anodot.
          </TypographyBox>
        </Box>
      </Box>
    </Fragment>
  );
};

export default AzureActiveDirectoryAuth;
