import {get, isString} from 'lodash';
import {getEditorValidationMessage as fuGetEditorValidationMessage} from './fuService';

export const regionsUrls = [
  {value: 'coralogix-esapi.coralogix.com', text: 'Europe'},
  {value: 'esapi.coralogix.us', text: 'US'},
  {value: 'es-api.app.coralogix.in', text: 'India'},
];

export const REGIONS = [
  {value: 'us-east-1', text: 'US East (N. Virginia)'},
  {value: 'us-east-2', text: 'US East (Ohio)'},
  {value: 'us-west-1', text: 'US West (N. California)'},
  {value: 'us-west-2', text: 'US West (Oregon)'},
  {value: 'ca-central-1', text: 'Canada (Central)'},
  {value: 'ap-south-1', text: 'Asia Pacific (Mumbai)'},
  {value: 'ap-northeast-1', text: 'Asia Pacific (Tokyo)'},
  {value: 'ap-northeast-2', text: 'Asia Pacific (Seoul)'},
  {value: 'ap-northeast-3', text: 'Asia Pacific (Osaka-Local)'},
  {value: 'ap-southeast-1', text: 'Asia Pacific (Singapore)'},
  {value: 'ap-southeast-2', text: 'Asia Pacific (Sydney)'},
  {value: 'cn-north-1', text: 'China (Beijing)'},
  {value: 'cn-northwest-1', text: 'China (Ningxia)'},
  {value: 'eu-central-1', text: 'EU (Frankfurt)'},
  {value: 'eu-west-1', text: 'EU (Ireland)'},
  {value: 'eu-west-2', text: 'EU (London)'},
  {value: 'eu-west-3', text: 'EU (Paris)'},
  {value: 'sa-east-1', text: 'South America (São Paulo)'},
];

export const fileDatePatterns = {
  yyyyMMdd: {
    key: 'yyyyMMdd',
    label: 'yyyyMMdd - only daily',
    order: 1,
  },
  yyyyMMddHH: {
    key: 'yyyyMMddHH',
    label: 'yyyyMMddHH - daily & hourly',
    order: 2,
  },
  yyyyMMddHHmm: {
    key: 'yyyyMMddHHmm',
    label: 'yyyyMMddHHmm',
    order: 3,
  },
};

export const allowedPollingIntervalsByFileDatePattern = {
  yyyyMMdd: ['daily'],
  yyyyMMddHH: ['daily', 'h12', 'h8', 'h6', 'h4', 'h3', 'h2', 'hourly'],
  yyyyMMddHHmm: ['daily', 'h12', 'h8', 'h6', 'h4', 'h3', 'h2', 'hourly', 'm15', 'm5'],
};

export const getEditorValidationMessage = (stream, isAll = false) => {
  const isPristine = !get(stream, 'uiState.analysisResult');
  const isDirty =
    stream.path !== get(stream, 'uiState.path') ||
    stream.fileNameSuffix !== get(stream, 'uiState.fileNameSuffix') ||
    stream.fileNamePrefix !== get(stream, 'uiState.fileNamePrefix') ||
    stream.fileNamePattern !== get(stream, 'uiState.fileNamePattern');
  const res = fuGetEditorValidationMessage(stream, isAll) || [];
  if (isString(res)) {
    return res;
  }

  if (isPristine && !isAll) {
    res.push('Fill files to collect section');
  }
  if (!isPristine && isDirty && !isAll) {
    res.push('Files to collect mismatch');
  }

  if (isAll) {
    return res;
  }
  return res[0];
};
