// @flow
import React from 'react';
import {Field, useForm} from 'react-final-form';
import Box from '@material-ui/core/Box';
import './RadioItem.module.scss';

type PropsType = {
  item: Object,
  groupName: string,
  children?: Node,
  theme?: string,
  disabled?: boolean,
};

export const THEMES = {
  SWITCHER: 'switcher',
};

const THEMES_STYLE = {
  [THEMES.SWITCHER]: {
    before: {
      backgroundColor: '#2671ff',
    },
    after: {
      backgroundColor: '#d4d9e0',
    },
    grip: {
      backgroundColor: '#17406f',
    },
    gripUnchecked: {
      backgroundColor: '#8995a0',
    },
  },
};

const RadioItem = React.memo(({item, groupName, children, theme, disabled}: PropsType) => {
  const toggleTheme = THEMES_STYLE[theme];
  const isSwitcher = theme === THEMES.SWITCHER;
  const {getFieldState} = useForm();

  const handleClick = (onChange) => {
    if (getFieldState('radioSignOnConfig').value === item.nameField) {
      onChange(' ');
    }
  };

  return (
    <Field name={groupName} type="radio" value={item.nameField}>
      {({input}) => (
        <Box>
          <label styleName={isSwitcher ? 'toggle-group' : ''}>
            <input
              id={input.name}
              type="radio"
              name={groupName}
              value={input.value}
              onChange={input.onChange}
              checked={input.checked}
              onClick={() => handleClick(input.onChange)}
            />
            <span>{item.label}</span>
            {isSwitcher && (
              <div styleName={`onoffswitch ${disabled ? 'disabled' : ''}`} aria-hidden="true">
                <div styleName="onoffswitch-label">
                  <div styleName="onoffswitch-inner">
                    <span styleName="before" style={toggleTheme.before} />
                    <span styleName="after" style={toggleTheme.after} />
                  </div>
                  <div
                    styleName="onoffswitch-switch"
                    style={input.checked ? toggleTheme.grip : toggleTheme.gripUnchecked}
                  />
                </div>
              </div>
            )}
          </label>
          {input.checked && children}
        </Box>
      )}
    </Field>
  );
});

RadioItem.defaultProps = {
  children: null,
  theme: THEMES.SWITCHER,
  disabled: false,
};

export default RadioItem;
