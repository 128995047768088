// @flow
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {useQueryParams} from 'use-query-params';
import SelectAndt, {THEME_BLUE_LEAN_MODIFIED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import TinyScrollBox from 'common/componentsV2/boxTools/TinyScrollBox';
import {segmentClickEvent} from 'common/store/actions';
import {
  QUERY_PARAM_MAP,
  PANEL_SIZES,
  ISSUES_OPTIONS,
  filteredIssuesList,
} from 'topologyGeneral/services/sidePanelService';
import IssueDateRange from 'topologyGeneral/components/sidePanel/issuesSubPanel/IssueDateRange';
import IssuesSubList from 'topologyGeneral/components/sidePanel/issuesSubPanel/IssuesSubList';
import {getGtpMapIssues} from 'topologyGeneral/store/selectors';
import './IssuesSubPanel.module.scss';

const MainIssuesSubPanel = ({regions}: PropTypes) => {
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAM_MAP);
  const issues = useSelector(getGtpMapIssues);

  const [selectedIssueOption, setSelectedIssueOption] = useState(ISSUES_OPTIONS[0]);
  const [issuesSecNumbers, setIssuesSecNumbers] = useState({
    total: 0,
    relevant: 0,
  });
  const [filteredIssues, setFilteredIssues] = useState([]);

  useEffect(() => {
    const fil = filteredIssuesList(issues, regions);
    setFilteredIssues(fil.filteredIssues);
    setIssuesSecNumbers({
      total: fil.total,
      relevant: fil.relevant,
    });
  }, [regions]);

  useEffect(() => {
    if (selectedIssueOption.value !== queryParams.issuesType) {
      const found = ISSUES_OPTIONS.find((item) => item.value === queryParams.issuesType);
      if (found) {
        setSelectedIssueOption(found);
      }
    }
  }, [queryParams.issuesType]);

  const onIssueTypeSelect = (item) => {
    if (selectedIssueOption.value !== item.value && item.value === 'anomalies') {
      dispatch(segmentClickEvent({category: 'topology/dropdown', name: 'Drop down Anomalies'}));
    }
    if (selectedIssueOption.value !== item.value && item.value === 'alerts') {
      dispatch(segmentClickEvent({category: 'topology/dropdown', name: 'Drop down Alerts'}));
    }

    setQueryParams({issuesType: item.value});
  };

  return (
    <Box position="absolute" top={0} bottom={0} left={0} right={0} css={{overflowY: 'auto', overflowX: 'hidden'}}>
      <Box styleName="issues-type-wrapper">
        <span styleName="issues-ddl-wrapper">
          <SelectAndt
            automationId="tpIssuesType"
            type={TYPE_NEW_NO_SEARCH}
            theme={THEME_BLUE_LEAN_MODIFIED}
            onChange={onIssueTypeSelect}
            options={ISSUES_OPTIONS}
            value={selectedIssueOption}
            placeholder="Num"
            optionHeight={40}
            menuWidth={170}
            getOptionLabel={(val) => val.label}
            getOptionValue={(val) => val.value}
          />
          <span styleName="issues-label">from the</span>
        </span>
        <span styleName="date-range-wrapper">
          <IssueDateRange />
        </span>
      </Box>
      <Box fontSize={14} lineHeight="20px" fontWeight={400} mt={1} mr={2} ml={2} mb={1}>
        {`Alerts (${issuesSecNumbers.relevant}/${issuesSecNumbers.total})`}
      </Box>
      <TinyScrollBox
        position="absolute"
        top={64}
        bottom={0}
        left={0}
        right={3}
        css={{overflowY: 'auto', overflowX: 'hidden'}}
      >
        <Box width={PANEL_SIZES.innerPanelWidth} ml={2}>
          <IssuesSubList issues={filteredIssues} />
        </Box>
      </TinyScrollBox>
    </Box>
  );
};

MainIssuesSubPanel.propTypes = {
  regions: PropTypes.arrayOf(PropTypes.object),
  // mapRef: PropTypes.objectOf(PropTypes.any).isRequired,
};

MainIssuesSubPanel.defaultProps = {
  regions: [],
};

export default MainIssuesSubPanel;
