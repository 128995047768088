// @flow
import React from 'react';
import './Button.module.scss';
import {segmentClickEvent} from 'common/store/actions';
import {connect} from 'react-redux';
import Spinner, {SIZES} from './Spinner';

const EMPTY_OBJECT = {};

type PropTypes = {
  colorSchema: string,
  height: string,
  width: string,
  fixedWidth?: number,
  isLoading: boolean,
  text: string,
  onClick: Function,
  isDisabled: boolean,
  additionalProps?: Object,
  extraClassName?: string,
  icon?: string,
  iconRight?: string,
  spinnerColor?: string,
  automationId: string,
  colorBar: string,
  type?: string,
  segmentClickEvent: Function,
};

export const COLORS = {
  // TODO - colorSchema
  AZURE_500: 'azure-500',
  GRAY_200: 'gray-200', // #e9e9f0;
  GRAY_300: 'gray-300',
  GRAY_400: 'gray-400',
  GRAY_500: 'gray-500',
  INDIGO_500: 'indigo-500',
  BLUE_500: 'blue-500',
  RED_500: 'red-500',
  GREEN_500: 'green-500',
  WHITE: 'white',
  TRANSPARENT: 'transparent',
  TRANSPARENT_BLUE: 'transparent-blue',
  GHOST_BLUE: 'ghost-blue',
  GHOST_LIGHT_BLUE: 'ghost-blue-light',
};

export const HEIGHTS = {
  TIGHT30: 'tight-30',
  TIGHT28: 'tight-28',
  REGULAR: 'regular-height',
  TALL: 'tall',
};

export const WIDTHS = {
  WIDE: 'wide',
  REGULAR: 'regular-width',
  NARROW: 'narrow',
  NARROW_TIGHT: 'narrowTight',
};

@connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)
export default class Button extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    fixedWidth: 0,
    additionalProps: EMPTY_OBJECT,
    extraClassName: '',
    icon: '',
    iconRight: '',
    spinnerColor: '',
    type: 'button',
  };

  state = {
    containerWidth: 0,
  };

  buttonStyle = [];

  setInitialWidth = (elm) => {
    if (elm) {
      this.setState({containerWidth: elm.offsetWidth});
    }
  };

  handleClick = () => {
    this.props.segmentClickEvent({
      type: 'click',
      name: this.props.text || this.props.icon,
      automationId: this.props.automationId,
    });
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    const {
      isLoading,
      colorSchema,
      colorBar,
      height,
      width,
      text,
      isDisabled,
      icon,
      iconRight,
      additionalProps,
      fixedWidth,
      extraClassName,
      automationId,
      type,
    } = this.props;

    this.buttonStyle = ['andt-button', colorSchema, height, width];

    const iconStyleName = ['icon-button'];

    let {spinnerColor} = this.props;

    if ((icon && text) || (iconRight && text)) {
      iconStyleName.push('icon-and-text');
    }

    if (icon && !text) {
      iconStyleName.push('only-icon');
    }

    if (spinnerColor === '') {
      if (colorSchema === COLORS.WHITE) {
        spinnerColor = '#3d4c59'; // gray-500
      } else {
        spinnerColor = '#ffffff';
      }
    }

    return (
      // eslint-disable-next-line react/button-has-type
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        style={fixedWidth !== 0 ? {width: fixedWidth} : null}
        {...additionalProps}
        onClick={this.handleClick}
        disabled={isDisabled}
        styleName={this.buttonStyle.join(' ')}
        className={extraClassName}
        automation-id={automationId}
      >
        <div styleName="container" ref={this.setInitialWidth}>
          {isLoading ? (
            <div style={{position: 'absolute', right: this.state.containerWidth + SIZES.SMALL_30}}>
              <Spinner color={spinnerColor} size={SIZES.SMALL_30} />
            </div>
          ) : null}
          {icon ? <div styleName={iconStyleName.join(' ')} className={`icon ${icon}`} /> : null}
          <div styleName="text-container">
            {colorBar && <div styleName="color-bar" style={{backgroundColor: colorBar}} />}
            <div style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{text}</div>
          </div>
          {iconRight ? <div styleName={iconStyleName.join(' ')} className={`icon ${iconRight}`} /> : null}
        </div>
      </button>
    );
  }
}
