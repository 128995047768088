// @flow
import React, {PureComponent, Fragment} from 'react';
import './AlertType.module.scss';

const mapper = {
  noData: {
    color: '#B2730B',
    text: 'No data',
  },
  static: {
    color: '#1f2c9c',
    text: 'Static',
  },
  anomaly: {
    color: '#2671ff',
    text: 'Anomaly',
  },
};

type PropTypes = {
  type: ?String,
};

export default class AlertType extends PureComponent {
  props: PropTypes;

  render() {
    const {type} = this.props;

    return (
      <Fragment>
        <span styleName="alert-type" style={{color: mapper[type].color}}>
          {mapper[type].text}
        </span>
      </Fragment>
    );
  }
}
