import React from 'react';
import Reports from './reports/Reports';
import GoogleAucDimensionsAndMetrics from './googleAucDimensionsAndMetrics/GoogleAucDimensionsAndMetrics';
import StreamEditorPanel from '../../StreamEditorPanel';
import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';

const GoogleAuctionsStreamEditor = () => (
  <div automation-id="googleAdsStreamEditor" className="shell-col">
    <StreamEditorSteps>
      <div className="shell-col">
        <StreamProperties />
        <Reports />
      </div>
      <GoogleAucDimensionsAndMetrics />
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default GoogleAuctionsStreamEditor;
