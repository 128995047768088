/* eslint-disable no-param-reassign */
// @flow

import React from 'react';
import connect from 'react-redux/es/connect/connect';
import SelectAndt, {TYPE_NEW_MULTI, THEME_HIGHLIGHTED_TRANSPARENT} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import './AlertManagerFooterTags.module.scss';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import {segmentClickEvent} from 'common/store/actions';
import shallowEqual from 'common/utils/shallowEqual';
import * as selectors from '../../../store/selectors';

type PropTypes = {
  onApply: Function,
  tags: [],
  segmentClickEvent: Function,
  tagsListWithSelectedCheckboxes: [],
  selectedAlertsCheckbox: [],
};

@connect(
  (state) => ({
    tagsListWithSelectedCheckboxes: selectors.getTagsListWithSelectedCheckboxes(state),
    selectedAlertsCheckbox: selectors.getSelectedAlertsCheckbox(state),
  }),
  {
    segmentClickEvent,
  },
)
export default class AlertManagerFooterTags extends React.PureComponent {
  props: PropTypes;

  state = {
    tags: [],
    menuIsOpen: false,
    isApplyDisabled: false,
  };

  componentDidMount() {
    this.resetTagsState(this.props);
  }

  componendDidUpdate(prevProps) {
    if (!shallowEqual(prevProps, this.props)) {
      this.resetTagsState(this.props);
    }
    if (this.props.selectedAlertsCheckbox !== prevProps.selectedAlertsCheckbox) {
      this.onMenuClose();
    }
  }

  isFirsTimeOpen = true;

  tagsListNormalized = [];

  resetTagsState = (props) => {
    const size = props.selectedAlertsCheckbox.length;
    this.tagsListNormalized = props.tagsListWithSelectedCheckboxes.map((tag) => ({
      label: tag.name,
      value: tag.name,
      count: tag.count,
      isSome: tag.ids.length > 0,
      isAll: tag.ids.length === size,
    }));
    const selectedTags = [];
    this.tagsListNormalized.forEach((tagNorm) => {
      if (tagNorm.isSome || tagNorm.isAll) {
        selectedTags.push(tagNorm);
      }
    });
    this.setState({tags: selectedTags});
  };

  handleTagsChange = (val) => {
    val.forEach((v) => {
      if (this.state.tags.findIndex((tag) => tag.label === v.label) === -1) {
        v.isAll = true;
        v.isSome = true;
      }
    });
    this.setState({tags: val});
  };

  handleCreate = (val) => {
    const obj = {
      label: val,
      value: val,
      count: this.props.selectedAlertsCheckbox.length,
      isSome: true,
      isAll: true,
    };
    this.tagsListNormalized.push(obj);
    this.setState((prevState) => ({tags: [...prevState.tags, obj]}));
    this.forceUpdate();
  };

  handleTagsApplyChanges = () => {
    this.isFirsTimeOpen = true;
    this.props.segmentClickEvent({category: 'alert-manager', name: 'bulk-labels-add'});
    this.props.onApply(this.state.tags);
    this.setState({tags: [], menuIsOpen: false});
  };

  onMenuOpen = () => {
    this.resetTagsState(this.props);
    this.setState({menuIsOpen: true});
  };

  onMenuClose = () => {
    this.resetTagsState(this.props);
    this.setState({menuIsOpen: false});
  };

  customFilter = (option, searchText) => {
    // eslint-disable-next-line no-underscore-dangle
    if (option.data.__isNew__) {
      this.setState({isApplyDisabled: true});
      return true;
    }
    if (option.data.label.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1) {
      this.setState({isApplyDisabled: false});
      return true;
    }
    return false;
  };

  render() {
    return (
      <SelectAndt
        automationId="bulkTags"
        type={TYPE_NEW_MULTI}
        theme={THEME_HIGHLIGHTED_TRANSPARENT}
        onChange={this.handleTagsChange}
        options={this.tagsListNormalized}
        value={this.state.tags}
        isMulti
        useBoxHalf
        onMenuOpen={this.onMenuOpen}
        onMenuClose={this.onMenuClose}
        placeholder=""
        optionHeight={40}
        offsetLeft={83}
        menuIsOpen={this.state.menuIsOpen}
        menuWidth={330}
        isClearable={false}
        closeMenuOnSelect={false}
        closeMenuOnScroll={false}
        forceResorting
        isCreatable
        onCreateOption={this.handleCreate}
        filterOption={this.customFilter}
        menuFooterComponent={
          <FormDdlActionBar
            isVisible
            isApplyDisabled={this.state.isApplyDisabled}
            onApply={this.handleTagsApplyChanges}
          />
        }
        menuPlacement="top"
        customComponent={{
          DropdownIndicator: (p) => (
            <div {...p.innerProps} styleName="footer-button-like">
              <i className="icn-general16-tags" />
              <span>Labels</span>
            </div>
          ),
        }}
      />
    );
  }
}
