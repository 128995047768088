// @flow
import React, {Fragment, useCallback} from 'react';
import './EditCustomerForm.module.scss';
import {Field, useField} from 'react-final-form';
import Input from 'common/componentsV2/Input';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import TimeZone from 'common/componentsV2/TimeZone';
import {FIRST_DAY_OF_WEEK, NUMBER_OF_METRICS_DELTA} from 'admin.customers/services/customersService';
import EditCustomerFormTimeScales from './EditCustomerFormTimeScales';

const DAY = 60 * 60 * 24; // seconds in 1 day
const asDays = {
  format: (val) => val && val / DAY,
  parse: (val) => val && val * DAY,
};
const asMinutes = {
  format: (val) => val && val / 60,
  parse: (val) => val && val * 60,
};
const isLocalHost = window.location.href.indexOf('localhost') >= 0;
const isPOC = window.location.href.indexOf('app-poc') >= 0;

const getSelectedFirstDayOfWeek = (val) => {
  return FIRST_DAY_OF_WEEK.find((option) => option.id === val);
};
const EditCustomerFormServerSettings = () => {
  const {
    input: {value: isNew},
  } = useField('isNew');
  const {
    input: {value: maxMetricsAllowed, onChange: setMaxMetricsAllowed},
  } = useField('customer.serverConfiguration.maxMetricsAllowed');

  const setNumberOfMetrics = useCallback(
    (event, numberOfMetricsField) => {
      const newValue = event.target.value;
      if (parseFloat(numberOfMetricsField.value) * NUMBER_OF_METRICS_DELTA === parseFloat(maxMetricsAllowed)) {
        setMaxMetricsAllowed(newValue * NUMBER_OF_METRICS_DELTA);
      }
      numberOfMetricsField.onChange(event);
    },
    [maxMetricsAllowed, setMaxMetricsAllowed, NUMBER_OF_METRICS_DELTA],
  );

  const metrics = (
    <Fragment>
      <div styleName="row">
        <Field name="customer.numberOfMetrics">
          {({input, meta}) => (
            <Fragment>
              <div styleName="label">Number Of Metrics</div>
              <Input
                name={input.name}
                value={input.value}
                onChange={(event) => {
                  setNumberOfMetrics(event, input);
                }}
                type="number"
                isInvalid={meta.error}
                invalidMessage={meta.error}
              />
            </Fragment>
          )}
        </Field>
      </div>
      <div styleName="row">
        <Field name="customer.serverConfiguration.maxMetricsAllowed">
          {({input}) => (
            <Fragment>
              <div styleName="label">Metrics Top Limit</div>
              <Input name={input.name} value={input.value} onChange={input.onChange} type="number" />
            </Fragment>
          )}
        </Field>
      </div>
    </Fragment>
  );
  const noData = (
    <div styleName="split-row">
      <Field name="customer.serverConfiguration.noDataMinDuration" {...asMinutes}>
        {({input}) => (
          <div styleName="split-field">
            <div styleName="label">No data min duration (minutes)</div>
            <Input name={input.name} value={input.value} onChange={input.onChange} type="number" min="0" />
          </div>
        )}
      </Field>
      <Field name="customer.serverConfiguration.noDataMinCompositeDuration" {...asMinutes}>
        {({input}) => (
          <div styleName="split-field">
            <div styleName="label">Composite: No data min duration (minutes)</div>
            <Input name={input.name} value={input.value} onChange={input.onChange} type="number" min="0" />
          </div>
        )}
      </Field>
    </div>
  );
  const dataRetentions = (
    <div styleName="split-row">
      <div styleName="split-field">
        <div styleName="label">Data Retentions</div>
        <Field name="customer.serverConfiguration.retentions.SHORTROLLUP" {...asDays}>
          {({input}) => (
            <Fragment>
              <div styleName="label">SHORT (days)</div>
              <Input name={input.name} value={input.value} onChange={input.onChange} type="number" />
            </Fragment>
          )}
        </Field>
        <Field name="customer.serverConfiguration.retentions.MEDIUMROLLUP" {...asDays}>
          {({input}) => (
            <Fragment>
              <div styleName="label">MEDIUM (days)</div>
              <Input name={input.name} value={input.value} onChange={input.onChange} type="number" />
            </Fragment>
          )}
        </Field>
        <Field name="customer.serverConfiguration.retentions.LONGROLLUP" {...asDays}>
          {({input}) => (
            <Fragment>
              <div styleName="label">LONG (days)</div>
              <Input name={input.name} value={input.value} onChange={input.onChange} type="number" />
            </Fragment>
          )}
        </Field>
        <Field name="customer.serverConfiguration.retentions.LONGLONGROLLUP" {...asDays}>
          {({input}) => (
            <Fragment>
              <div styleName="label">LONGLONG (days)</div>
              <Input name={input.name} value={input.value} onChange={input.onChange} type="number" />
            </Fragment>
          )}
        </Field>
        <Field name="customer.serverConfiguration.retentions.WEEKLY" {...asDays}>
          {({input}) => (
            <Fragment>
              <div styleName="label">WEEKLY (days)</div>
              <Input name={input.name} value={input.value} onChange={input.onChange} type="number" />
            </Fragment>
          )}
        </Field>
      </div>
      <div styleName="split-field">
        <div styleName="label">Anomaly Retentions</div>
        <Field name="customer.serverConfiguration.anomalyRetentions.SHORTROLLUP" {...asDays}>
          {({input}) => (
            <Fragment>
              <div styleName="label">SHORT (days)</div>
              <Input name={input.name} value={input.value} onChange={input.onChange} type="number" />
            </Fragment>
          )}
        </Field>
        <Field name="customer.serverConfiguration.anomalyRetentions.MEDIUMROLLUP" {...asDays}>
          {({input}) => (
            <Fragment>
              <div styleName="label">MEDIUM (days)</div>
              <Input name={input.name} value={input.value} onChange={input.onChange} type="number" />
            </Fragment>
          )}
        </Field>
        <Field name="customer.serverConfiguration.anomalyRetentions.LONGROLLUP" {...asDays}>
          {({input}) => (
            <Fragment>
              <div styleName="label">LONG (days)</div>
              <Input name={input.name} value={input.value} onChange={input.onChange} type="number" />
            </Fragment>
          )}
        </Field>
        <Field name="customer.serverConfiguration.anomalyRetentions.LONGLONGROLLUP" {...asDays}>
          {({input}) => (
            <Fragment>
              <div styleName="label">LONGLONG (days)</div>
              <Input name={input.name} value={input.value} onChange={input.onChange} type="number" />
            </Fragment>
          )}
        </Field>
        <Field name="customer.serverConfiguration.anomalyRetentions.WEEKLY" {...asDays}>
          {({input}) => (
            <Fragment>
              <div styleName="label">WEEKLY (days)</div>
              <Input name={input.name} value={input.value} onChange={input.onChange} type="number" />
            </Fragment>
          )}
        </Field>
      </div>
    </div>
  );
  const composite = (
    <Fragment>
      <div styleName="row">
        <Field name="customer.serverConfiguration.compositeCalculationDelay">
          {({input}) => (
            <Fragment>
              <div styleName="label">Composite Calculation Delay (Sec.)</div>
              <Input name={input.name} value={input.value} onChange={input.onChange} type="number" />
            </Fragment>
          )}
        </Field>
      </div>
      <div styleName="split-row">
        <Field name="customer.serverConfiguration.compositeMaxMetrics">
          {({input}) => (
            <div styleName="split-field">
              <div styleName="label">Composite Max Metrics</div>
              <Input name={input.name} value={input.value} onChange={input.onChange} type="number" />
            </div>
          )}
        </Field>
        <Field name="customer.serverConfiguration.compositeMaxMetricsDisplay">
          {({input}) => (
            <div styleName="split-field">
              <div styleName="label">Composite Max Metrics Display (Adhoc)</div>
              <Input name={input.name} value={input.value} onChange={input.onChange} type="number" />
            </div>
          )}
        </Field>
      </div>
    </Fragment>
  );
  const samplesLimit = (
    <div styleName="row">
      <Field name="customer.serverConfiguration.maxSamplesPerMinuteAllowed" {...asMinutes}>
        {({input, meta}) => (
          <Fragment>
            <div styleName="label">Samples Per Second Limit</div>
            <Input
              name={input.name}
              value={input.value}
              onChange={input.onChange}
              type="number"
              isInvalid={meta.error}
              invalidMessage={meta.error}
            />
          </Fragment>
        )}
      </Field>
    </div>
  );
  const userEventRetention = (
    <div styleName="row">
      <Field name="customer.serverConfiguration.userEventRetention" {...asDays}>
        {({input, meta}) => (
          <Fragment>
            <div styleName="label">User Event Retention (days)</div>
            <Input
              name={input.name}
              value={input.value}
              onChange={input.onChange}
              type="number"
              isInvalid={meta.error}
              invalidMessage={meta.error}
            />
          </Fragment>
        )}
      </Field>
    </div>
  );
  const timeZone = (
    <div styleName="split-row">
      <Field name="customer.serverConfiguration.tz">
        {({input}) => (
          <div styleName="split-field">
            <div styleName="label">Data Time Zone</div>
            <TimeZone value={input.value} onChange={input.onChange} />
          </div>
        )}
      </Field>
      <Field name="customer.serverConfiguration.firstDayOfWeek">
        {({input}) => (
          <div styleName="split-field">
            <div styleName="label">First day of week</div>
            <SelectAndt
              automationId="selectSelfServicePackage"
              type={TYPE_NEW_NO_SEARCH}
              theme={THEME_NOT_HIGHLIGHTED}
              onChange={(opt) => {
                input.onChange(opt.id);
              }}
              value={getSelectedFirstDayOfWeek(input.value)}
              options={FIRST_DAY_OF_WEEK}
              placeholder="Required"
              buttonWidth={230}
              optionHeight={40}
              minMenuHeight={120}
              getOptionLabel={(val) => val.text}
              getOptionValue={(val) => val.id}
            />
          </div>
        )}
      </Field>
    </div>
  );
  const timeScale = (
    <div styleName="row">
      <div styleName="label">Time Scales</div>
      <Field name="customer.serverConfiguration.rollups">{() => <EditCustomerFormTimeScales />}</Field>
    </div>
  );

  return (
    <Fragment>
      <div styleName="title">Server Settings</div>
      {metrics}
      {noData}
      {!isNew && (isLocalHost || isPOC) && dataRetentions}
      {composite}
      {samplesLimit}
      {userEventRetention}
      {timeZone}
      {timeScale}
    </Fragment>
  );
};
export default EditCustomerFormServerSettings;
