// @flow
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import ErrorModal from 'common/components/modals/ErrorModal';
import ConfirmationModal from 'common/components/modals/ConfirmationModal';
import {startDataStream} from 'bc/store/actions';
import {segmentCausingEvent} from 'common/store/actions';
import * as selectors from 'bc/store/selectors';
import * as metricsSelectors from 'metrics/store/selectors';
import {getActiveSteamsLimit} from 'profile/store/selectors';
import SmartTooltip from 'common/componentsV2/Tooltip';
import Button, {COLORS} from 'common/componentsV2/Button';
import {
  streamStartActions,
  isStreamEditable,
  isEditRunning,
  getTableValidationMessage,
  isFileStream,
  getStreamStatsErrorMessage,
  isStreamItAlertsAndDashboardsFlow,
} from 'bc/services/dataStreamService';
import {isEmpty} from 'lodash';
import {cleanHash} from 'common/utils/utilsService';
import StreamsLimitModal from 'bc/components/dataManager/StreamsLimitModal';
import AlertsFromStreamModal from './AlertsFromStreamModal';
import AlertsAndDashboardsStreamModal from './AlertsAndDashboardsStreamModal';
import SelectStreamItOrCreateAlertsFlowModal from './SelectStreamItOrCreateAlertsFlowModal';
import SelectLiveStreamItModal from './SelectLiveStreamItModal';
import './StreamItButton.module.scss';

type PropTypes = {
  startDataStream: Function,
  segmentCausingEvent: Function,
  dataStream: Object,
  stats: Object,
  validationMeta: Object,
  isLoading: boolean,
  activeStreamsCount: Number,
  activeSteamsLimit: Number,
};

@connect(
  (state) => ({
    isLoading: selectors.getIsPreviewLoading(state),
    dataStream: selectors.getSelectedDataStream(state),
    stats: selectors.getSelectedStreamStats(state),
    validationMeta: metricsSelectors.getMetricValidationMeta(state),
    activeStreamsCount: selectors.getActiveStreamsCount(state),
    activeSteamsLimit: getActiveSteamsLimit(state),
  }),
  {
    startDataStream,
    segmentCausingEvent,
  },
)
export default class StreamItButton extends React.PureComponent {
  props: PropTypes;

  constructor(props) {
    super(props);
    if (
      !this.state.isErrorsModalOpen &&
      this.state.isShowErrorModal &&
      this.props.stats.data &&
      this.props.stats.data.totalRowsProcessed === 0 &&
      isFileStream(this.props.dataStream.type)
    ) {
      // TODO: Check what can be done instead
      // eslint-disable-next-line react/no-did-update-set-state
      this.state = {isModalOpen: false, isErrorsModalOpen: true, isShowErrorModal: false};
    }
  }

  state = {
    isModalOpen: false,
    isErrorsModalOpen: false,
    isShowErrorModal: true,
    shouldRewind: false,
    isLimitModalOpen: false,
  };

  componentDidUpdate() {
    if (
      !this.state.isErrorsModalOpen &&
      this.state.isShowErrorModal &&
      this.props.stats.data &&
      this.props.stats.data.errors &&
      !isEmpty(this.props.stats.data.errors)
    ) {
      // TODO: Check what can be done instead
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({isErrorsModalOpen: true, isShowErrorModal: false});
    }
  }

  streamItModalMessage = () => (
    <Fragment>
      <span>
        {this.props.stats.data ? this.props.stats.data.totalMetrics : <i className="icon ion-load-c spin" />} metrics
        are ready for processing.
      </span>
      <br />
      <span>
        You can save time by setting up alerts on data from this stream now, or set them up later separately.
        <br />
        Alerts you create now will start working only after the stream becomes <span className="bold">live</span>
      </span>
    </Fragment>
  );

  streamItDirectModalMessage = () => (
    <Fragment>
      <span>
        {`You are about to stream ${
          this.props.stats.data ? this.props.stats.data.totalMetrics : <i className="icon ion-load-c spin" />
        } metrics for processing, are you sure?`}
      </span>
    </Fragment>
  );

  getErrorMessage = (key) => {
    const {stats, dataStream} = this.props;
    let str = '';
    if (key === '5' && !isFileStream(dataStream.type)) {
      str = `${stats.data.errors[key]} records with error: ${getStreamStatsErrorMessage('105')}`;
    } else {
      str = `${stats.data.errors[key]} rows with error: ${getStreamStatsErrorMessage(key)}`;
    }
    return str;
  };

  statsCollectionError = () => {
    const {stats} = this.props;
    if (stats.data && stats.data.errors && !isEmpty(stats.data.errors)) {
      return (
        <Fragment>
          <div styleName="error-top" automation-id="collectionErrorMessage">
            {`Scanned ${stats.data.totalRowsProcessed} rows.`}
          </div>
          {Object.keys(stats.data.errors).map((key) => (
            <span styleName="error-item" key={key}>
              {this.getErrorMessage(key)}
              <br />
            </span>
          ))}
          <div styleName="error-bottom">Continue with these errors or return to update the Stream Query.</div>
        </Fragment>
      );
    }
    if (stats.data && stats.data.totalRowsProcessed === 0) {
      return (
        <Fragment>
          <div styleName="error-top" automation-id="collectionErrorMessage">
            No rows found
          </div>
          <span styleName="error-item">
            No records found in the selected time range. Either no files exist in range, or files are empty
            <br />
          </span>
          <div styleName="error-bottom">Continue with these errors or return to update the Stream Query.</div>
        </Fragment>
      );
    }
    return '';
  };

  openModal = () => {
    const {dataStream, activeStreamsCount, activeSteamsLimit} = this.props;
    if (!isEditRunning(dataStream.id) && activeStreamsCount >= activeSteamsLimit) {
      this.openStreamsListModal();
    } else if (isStreamItAlertsAndDashboardsFlow(dataStream) && !isEditRunning(dataStream.id)) {
      this.onCreateAlerts();
    } else {
      this.setState({isModalOpen: true});
    }
  };

  onShouldRewind = (val) => {
    this.setState(() => ({shouldRewind: val}));
  };

  onCreateAlerts = () => {
    this.props.segmentCausingEvent({
      category: 'bc/create-alert',
      name: 'simple-create',
    });
    this.setState({
      isModalOpen: false,
      isAlertsFromStreamModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      isErrorsModalOpen: false,
      isAlertsFromStreamModalOpen: false,
    });
  };

  onConfirmModal = () => {
    if (isEditRunning(this.props.dataStream.id)) {
      this.props.segmentCausingEvent({
        category: `bc/data-streams/${this.props.dataStream.id}/stream-it-modal`,
        name: `shouldRecollect: ${this.state.shouldRewind}`,
      });
    }
    this.props.startDataStream({
      id: this.props.dataStream.id,
      action: streamStartActions.start,
      origin: cleanHash(window.location.hash),
      shouldRewind: this.state.shouldRewind,
    });
    this.setState({isModalOpen: false, isAlertsFromStreamModalOpen: false});
  };

  renderStreamItModal = () => {
    const {dataStream} = this.props;
    if (
      (dataStream.type === 'salesforce' && !dataStream.timestampColumn && isEmpty(dataStream.timeDefinition)) ||
      dataStream.type === 'local_file' ||
      window.isOnPremise
    ) {
      return (
        <ConfirmationModal
          onClose={this.closeModal}
          onConfirm={this.onConfirmModal}
          title="Streaming Data"
          message={this.streamItDirectModalMessage()}
          isOpen={this.state.isModalOpen}
        />
      );
    }

    if (isEditRunning(dataStream.id)) {
      return (
        <SelectLiveStreamItModal
          onClose={this.closeModal}
          onStreamData={this.onConfirmModal}
          onShouldRewind={this.onShouldRewind}
          shouldRewindState={this.state.shouldRewind}
          dataStreamId={dataStream.uiState.id}
          dataStreamName={dataStream.name}
          isOpen={this.state.isModalOpen}
        />
      );
    }

    return (
      <SelectStreamItOrCreateAlertsFlowModal
        onClose={this.closeModal}
        onStreamData={this.onConfirmModal}
        onCreateAlerts={this.onCreateAlerts}
        title="Create Alerts from this Stream"
        message={this.streamItModalMessage()}
        isOpen={this.state.isModalOpen}
      />
    );
  };

  openStreamsListModal = () => {
    this.setState({isLimitModalOpen: true});
  };

  closeStreamsListModal = () => {
    this.setState({isLimitModalOpen: false});
  };

  render() {
    const {dataStream, isLoading, validationMeta} = this.props;
    const validationMsg = getTableValidationMessage(dataStream, validationMeta);

    const streamItBtn = validationMsg ? (
      <SmartTooltip placement="left" content={validationMsg}>
        <div>
          <Button colorSchema={COLORS.BLUE_500} text="Stream" automationId="dataCollectorStreamTableDisabledButton" />
        </div>
      </SmartTooltip>
    ) : (
      <Button
        colorSchema={COLORS.BLUE_500}
        text="Stream"
        automationId="dataCollectorStreamTableEnabledButton"
        onClick={this.openModal}
      />
    );

    return (
      <Fragment>
        <div styleName="stream-it">
          {isLoading || (!isStreamEditable(dataStream.state) && !isEditRunning(dataStream.id)) ? null : streamItBtn}
        </div>

        {this.state.isErrorsModalOpen ? '' : this.renderStreamItModal()}

        {this.state.isLimitModalOpen ? (
          <StreamsLimitModal
            isOpen={this.state.isLimitModalOpen}
            onClose={this.closeStreamsListModal}
            scenario="active"
          />
        ) : null}

        {dataStream && !isStreamItAlertsAndDashboardsFlow(dataStream) && (
          <AlertsFromStreamModal
            isOpen={this.state.isAlertsFromStreamModalOpen}
            onCreateClicked={this.onConfirmModal}
            onClose={this.closeModal}
          />
        )}
        {dataStream && isStreamItAlertsAndDashboardsFlow(dataStream) && (
          <AlertsAndDashboardsStreamModal
            isOpen={this.state.isAlertsFromStreamModalOpen}
            onCreateClicked={this.onConfirmModal}
            onClose={this.closeModal}
          />
        )}

        <ErrorModal
          onClose={this.closeModal}
          title="Collection Errors"
          errorMsg={this.statsCollectionError()}
          isOpen={this.state.isErrorsModalOpen}
        />
      </Fragment>
    );
  }
}
