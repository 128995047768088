import React from 'react';
import ChannelField from './ChannelField';

const StandartTemplate = ({channel}: {channel: Object}) => {
  const channelType = channel.channelMeta.id;

  let firstFieldElement;
  if (channelType === 'pagerduty') {
    firstFieldElement = <ChannelField label="Service Key" value={channel.channelData.serviceKey} />;
  } else if (channelType === 'sns') {
    firstFieldElement = <ChannelField label="Topic" value={channel.channelData.topic} />;
  } else if (channelType === 'opsgenie') {
    firstFieldElement = <ChannelField label="Opsgenie instance URL" value={channel.channelData.baseUrl} />;
  } else {
    firstFieldElement = <ChannelField label="URL" value={channel.channelData.url} />;
  }

  return (
    <div>
      <div className="mb_1-5">{firstFieldElement}</div>
      <div className="mb_1-5">
        <ChannelField label="Time Zone" value={channel.timezone || 'Browser'} />
      </div>
      <ChannelField label="Created By" value={channel.createdBy} />
    </div>
  );
};

export default StandartTemplate;
