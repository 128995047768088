// @flow
import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useQueryParams} from 'use-query-params';
import {getDate, rangeTypes} from 'common/utils/dateRangeService';
import DateRangesDdl, {THEME} from 'common/components/dateTime/DateRangesDdl';
import {getTimeZoneName} from 'profile/store/selectors';
// import {getTMLeftPanelConstRange} from 'topologyLeaflet/store/selectors';
import {segmentClickEvent} from 'common/store/actions';
import {QUERY_PARAM_MAP} from 'topologyLeaflet/services/leftPanelService';

const IssueDateRange = () => {
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAM_MAP);
  const timeZoneName = useSelector(getTimeZoneName);

  const dateRangeChange = (dr) => {
    const isCustom = dr.constRange === rangeTypes.c.value;
    const isRelative = dr.constRange === rangeTypes.r.value;
    const times = {
      startDate: isCustom ? dr.startDate : undefined,
      endDate: isCustom ? dr.endDate : undefined,
      relativeLast: isRelative ? dr.relativeLast : undefined,
      relativeNext: isRelative && String(dr.relativeNext) !== String(0) ? dr.relativeNext : undefined,
    };
    const newState = {constRange: dr.constRange, ...times};

    setQueryParams(newState);
    dispatch(segmentClickEvent({category: 'topology/dropdown', name: `time-frame-${dr.constRange}`}));
  };

  const dateRange = getDate(
    {
      constRange: queryParams.constRange,
      startDate: queryParams.startDate,
      endDate: queryParams.endDate,
      relativeLast: queryParams.relativeLast,
      relativeNext: queryParams.relativeNext,
    },
    timeZoneName,
  );
  return <DateRangesDdl onChange={dateRangeChange} dateRange={dateRange} isUnix theme={THEME.BLUE_LEAN} />;
};

export default IssueDateRange;
