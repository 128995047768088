// @flow
/* eslint-disable no-shadow */
import React from 'react';
import {connect} from 'react-redux';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import Button, {COLORS} from 'common/componentsV2/Button';
import {createDataStream, setDataManagerModalFilter, setSelectedDataSource} from 'bc/store/actions';
import {getSourceIcon, getStreamCountText, getStreamsCount} from 'bc/services/dataManagerService';
import {setRoute} from 'common/store/actions';
import {getTypeDetails} from 'bc/services/bcTypes';
import {TinyScrollBox} from 'common/componentsV2/boxTools';
import SearchBox from 'common/componentsV2/SearchBox';
import {ReactComponent as NoData} from 'bc/svgs/no-streams.svg';
import * as selectors from 'bc/store/selectors';
import Highlighter from 'react-highlight-words';

import './NewStreamBySourceModal.module.scss';

type PropTypes = {
  onClose: Function,
  isOpen: Boolean,
  isSingleSource: Boolean,
  sourceType: string,
  sourceName: string,
  onAddNewSource: Function,

  // connect
  dataSources: Array,
  dataStreams: Array,
  sourceFilter: String,
  setDataManagerModalFilter: Function,
  setSelectedDataSource: Function,
  selectedDataSource: Object,
  setRoute: Function,
  createDataStream: Function,
};

@connect(
  (state) => ({
    dataSources: selectors.getDataSourcesItemsOrderedForModal(state),
    dataStreams: selectors.getDataStreamsItemsOrdered(state),
    sourceFilter: selectors.getDataManagerModalFilter(state),
    selectedDataSource: selectors.getSelectedDataSource(state),
  }),
  {
    setDataManagerModalFilter,
    setSelectedDataSource,
    setRoute,
    createDataStream,
  },
)
export default class NewStreamBySourceModal extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    this.props.setSelectedDataSource(null);
  }

  onFilter = (val) => {
    this.props.setDataManagerModalFilter(val);
  };

  onClickFunction = (id) => {
    this.props.setSelectedDataSource(id);
  };

  onNewStream = () => {
    this.props.createDataStream({
      type: this.props.selectedDataSource.type,
      dataSourceId: this.props.selectedDataSource.id,
    });
  };

  gotoSources = () => {
    this.props.setRoute('/integrations?filterText=&selectedCategory=all&selectedTab=sources&origin=newStreamsModal');
  };

  render() {
    const {
      isOpen,
      onClose,
      dataSources,
      dataStreams,
      sourceFilter,
      selectedDataSource,
      isSingleSource,
      sourceType,
      sourceName,
      onAddNewSource,
    } = this.props;
    const dataSourcesFiltered = isSingleSource ? dataSources.filter((ds) => ds.type === sourceType) : dataSources;
    let typeTitle = '';
    const TypeHeader = ({source}) => {
      if (getTypeDetails(source.type).name !== typeTitle) {
        typeTitle = getTypeDetails(source.type).name;
        return <div styleName="source-title">{typeTitle}</div>;
      }
      return null;
    };

    return (
      <Modal isOpen={isOpen} onClose={onClose} size={SIZES.MEDIUM}>
        <div styleName={['root', isSingleSource ? 'single-source' : ''].join(' ')}>
          <header>{isSingleSource ? `${sourceName} is already in use` : `Select a Source for the Stream`}</header>
          <div styleName="modal-body">
            {isSingleSource ? (
              <div styleName="info">
                There are already a few sources of that type, choose an existing one or add a new source to create a
                stream
              </div>
            ) : (
              <div styleName="modal-search">
                <SearchBox onFilter={this.onFilter} filter={sourceFilter} placeHolder="Search an existing source" />
              </div>
            )}

            <div styleName="modal-content">
              <TinyScrollBox width="100%" pr={1.25} height={1} css={{overflowY: 'auto'}}>
                {!dataSourcesFiltered.length && (
                  <div styleName="no-data-container">
                    <div styleName="icon">
                      <NoData />
                    </div>
                    <div styleName="title">No Data Sources to Show</div>
                  </div>
                )}
                {isSingleSource && <div styleName="list-title">{`${sourceName} existing sources`}</div>}
                {dataSourcesFiltered.map((source) => (
                  <div key={source.id}>
                    {!isSingleSource && <TypeHeader source={source} />}
                    <div styleName="itemContainer">
                      <span
                        styleName={[
                          'titleWrapper',
                          selectedDataSource && selectedDataSource.id === source.id ? 'selected' : '',
                        ].join(' ')}
                        onClick={() => this.onClickFunction(source.id)}
                      >
                        <span styleName="logo">{getSourceIcon(source.type)}</span>
                        <span styleName="name">
                          <Highlighter autoEscape searchWords={[sourceFilter]} textToHighlight={source.name} />
                        </span>
                        <span styleName="type">{getStreamCountText(getStreamsCount(source.id, dataStreams))}</span>
                      </span>
                    </div>
                  </div>
                ))}
              </TinyScrollBox>
            </div>
          </div>

          <div styleName="footer">
            <div styleName="left-button">
              <Button
                colorSchema={COLORS.BLUE_500}
                text="Add a New Source"
                onClick={isSingleSource ? onAddNewSource : this.gotoSources}
                automationId="addNewSource"
              />
            </div>

            <div>
              <Button
                colorSchema={COLORS.BLUE_500}
                isDisabled={!selectedDataSource}
                text="Create Stream"
                onClick={this.onNewStream}
                automationId="newStreamdButton"
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
