// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/StreamEditorPanelHeader';
import SchedularDateRange from 'bc/components/streams/editor/common/SchedularDateRange';
import PollingInterval from 'bc/components/streams/editor/common/PollingInterval';
import {getResolutionByPollingInterval, getAllowedRangeTypesByPollingInterval} from 'bc/services/dataStreamService';
import * as commonSelectors from 'profile/store/selectors';

import '../../sql/sqlSchedular/SqlSchedularEditor.module.scss';

type PropTypes = {
  // connect
  timeZoneName: string,
  dataStream: Object,
  setSelectedStreamKeyVal: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    timeZoneName: commonSelectors.getTimeZoneName(state),
  }),
  {
    setSelectedStreamKeyVal,
  },
)
export default class AdobeSchedulerEditor extends React.PureComponent {
  props: PropTypes;

  pollingChanged = (val) => {
    this.props.setSelectedStreamKeyVal({
      pollingInterval: val,
      pollingResolution: getResolutionByPollingInterval(val),
      queryServiceState: null,
      reportId: null,
    });
  };

  dateRangeChanged = (val) => {
    this.props.setSelectedStreamKeyVal({
      historicalDateRange: val,
      queryServiceState: null,
      reportId: null,
    });
  };

  delayMinutesChanged = (e) => {
    const val = parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0;
    this.props.setSelectedStreamKeyVal({
      delayMinutes: val,
      queryServiceState: null,
      reportId: null,
    });
  };

  render() {
    const {dataStream, timeZoneName} = this.props;

    return (
      <div styleName="root">
        <PanelHeader title="Schedule Report" />
        <div styleName="inner-root">
          <div styleName="item">
            <span styleName="item-title">Query every</span>
            <PollingInterval
              pollingInterval={dataStream.pollingInterval}
              allowedValues={['daily', 'hourly']}
              styleName="item-width"
              onChange={(v) => this.pollingChanged(v)}
            />
          </div>

          <div styleName="item collect-on">
            <span className="ellipsis" styleName="item-title">
              Delay (Minutes)
            </span>
            <span styleName="item-description">
              To minimize partial results in your reports, set the delay according to the time it takes the data to be
              available
            </span>
            <input type="number" onChange={this.delayMinutesChanged} min="0" value={dataStream.delayMinutes} />
          </div>
        </div>

        <div styleName="inner-root">
          <div styleName="item">
            <span className="ellipsis" styleName="item-title">
              Collect Since
            </span>
            <span styleName="item-description">Time span of data to query while initializing a stream.</span>
            <div styleName="item-width">
              <SchedularDateRange
                dateRange={dataStream.historicalDateRange}
                title="Time span"
                isUnix
                allowedRangeTypes={getAllowedRangeTypesByPollingInterval(dataStream.pollingInterval)}
                timeZoneName={timeZoneName}
                onChange={(v) => this.dateRangeChanged(v)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
