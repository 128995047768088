// @flow
import React from 'react';
import './AlertTriggerPanel.module.scss';
import connect from 'react-redux/es/connect/connect';
import {fetchTriggeredAlertsByConfiguration} from 'alerts.management/store/actions';
import * as selectors from 'alerts.management/store/selectors';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import AlertTriggersTable from './AlertTriggersTable';

type PropTypes = {
  alert: Object,

  fetchTriggeredAlertsByConfiguration: Function,
  isLoading: boolean,
  triggers: Array,
  totalTriggers: Number,
};

@connect(
  (state) => ({
    isLoading: selectors.getTriggeredAlertsByConfigurationIsLoading(state),
    triggers: selectors.getTriggeredAlertsByConfigurationList(state),
    totalTriggers: selectors.getTriggeredAlertsByConfigurationCount(state),
  }),
  {
    fetchTriggeredAlertsByConfiguration,
  },
)
export default class AlertTriggerPanel extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    const thirtyDaysTimestamp = Math.floor(Date.now() / 1000) - 60 * 60 * 24 * 30;

    const queryAPI = {
      startTime: thirtyDaysTimestamp,
      alertConfigurationIds: this.props.alert.id,
    };

    this.props.fetchTriggeredAlertsByConfiguration(queryAPI);
  }

  render() {
    const {isLoading, triggers, totalTriggers, alert} = this.props;

    return (
      <div styleName="container">
        <div styleName="column column-1">
          {isLoading ? (
            <div styleName="spinner">
              <Spinner color="#3d4c59" size={SIZES.BIG_60} />
            </div>
          ) : (
            <AlertTriggersTable triggers={triggers} totalTriggers={totalTriggers} alertId={alert.id} />
          )}
        </div>
      </div>
    );
  }
}
