// @flow
import React, {useEffect, useState, useRef, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import {AutoSizer, List} from 'react-virtualized';
import Spinner, {SIZES as SpinnerSizes} from 'common/componentsV2/Spinner';
import TitleHeaderTableOrder from 'common/componentsV2/table/TitleHeaderTableOrder';
import CustomersTableRow from './CustomersTableRow';
import {getCustomersListOrder, getIsLoadingCustomers} from '../../store/selectors';
import {setCustomersListOrder} from '../../store/actions';
import {getFilteredCustomersSectionCustomersList} from '../../store/filterSelector';
import './CustomersTable.module.scss';

const columns = [
  {id: 0, name: 'name', title: 'Name'},
  {id: 1, name: 'id', title: 'Id'},
  {id: 2, name: 'paying', title: 'Paying'},
  {id: 3, name: 'self-service', title: 'Self Service'},
  {id: 4, name: 'tags', title: 'Tags'},
  {id: 5, name: 'status', title: 'Status'},
];

const customersTable = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoadingCustomers);
  const customers = useSelector(getFilteredCustomersSectionCustomersList);
  const listOrder = useSelector(getCustomersListOrder);

  const [scrollBarWidth, setScrollBarWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(500);
  const listRef = useRef(null);

  const recalculateScrolbarWidth = useCallback(() => {
    const listElm = document.getElementsByClassName('ReactVirtualized__List');
    const innerElm = document.getElementsByClassName('ReactVirtualized__Grid__innerScrollContainer');
    let newWidth = 0;
    if (listElm && listElm.length > 0 && innerElm && innerElm.length > 0) {
      newWidth = listElm[0].offsetWidth - innerElm[0].offsetWidth;
    }
    setScrollBarWidth(newWidth);
  }, [setScrollBarWidth]);

  useEffect(() => {
    recalculateScrolbarWidth();
    if (listRef) {
      listRef.current.recomputeRowHeights(0);
    }
  }, [customers.length]);

  const handleTitleOrderChange = useCallback(
    (columnName) => {
      let updatedDirection = 'desc';

      if (columnName === listOrder.column) {
        updatedDirection = listOrder.direction === 'desc' ? 'asc' : 'desc';
      }
      dispatch(setCustomersListOrder({column: columnName, direction: updatedDirection}));
    },
    [listOrder, setCustomersListOrder],
  );

  const handleResize = useCallback(
    (width, height) => {
      setTableHeight(height - 82);
      recalculateScrolbarWidth();
    },
    [recalculateScrolbarWidth, setTableHeight],
  );

  const rowRenderer = useCallback(
    ({index, style}) => {
      const customer = customers[index];

      return <CustomersTableRow key={customer.id} customer={customer} style={style} />;
    },
    [customers],
  );

  if (isLoading) {
    return (
      <div styleName="spinner-wrapper">
        <Spinner color="#3d4c59" size={SpinnerSizes.XX_BIG_150} />
      </div>
    );
  }

  return (
    <div styleName="table">
      <ReactResizeDetector handleWidth handleHeight onResize={handleResize} />
      <div styleName="table-header" style={{paddingRight: `${scrollBarWidth}px`}}>
        {columns.map((col) => (
          <div styleName={`col-header col-${col.name}`} key={`Customer-list-${col.id}`}>
            <TitleHeaderTableOrder
              title={col.title}
              columnName={col.name}
              onChange={handleTitleOrderChange}
              selectedDirection={listOrder.direction}
              isSortable
              isActive={col.name === listOrder.column}
            />
          </div>
        ))}
      </div>

      <div className="shell-col">
        <AutoSizer disableHeight>
          {({width}) => (
            <List
              ref={listRef}
              width={width}
              height={tableHeight}
              rowCount={customers.length}
              rowHeight={49}
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
};
export default customersTable;
