import React from 'react';
import {connect} from 'react-redux';
import HeaderRow from 'common/componentsV2/accordion/HeaderRow';
import OuterRow from 'common/componentsV2/accordion/OuterRow';
import * as actions from 'admin.cs-portal/store/actions';
import * as selectors from 'admin.cs-portal/store/selectors';

import './ErrorsView.module.scss';

type PropTypes = {
  alertErrors: Array,
  alertErrorMessages: Object,
  areAlertErrorsLoading: Boolean,
};

@connect(
  (state) => ({
    selectedCustomer: selectors.getSelectedCustomer(state),
    alertErrors: selectors.getLeanAlertsErrors(state),
    alertErrorMessages: selectors.getAlertErrorMessages(state),
    areAlertErrorsLoading: selectors.getAreAlertErrorsLoading(state),
  }),
  {
    fetchAlertsErrors: actions.fetchAlertsErrors,
  },
)
export default class AlertErrors extends React.PureComponent {
  props: PropTypes;

  renderAlertErrors = () => {
    const {alertErrors, alertErrorMessages} = this.props;
    const err = [];
    alertErrors.map((alert, index) =>
      err.push(
        <OuterRow
          key={alert.alert_id + alert.alert_name}
          titles={[alert.alert_name, alert.alert_type, alert.total_alert_errors, alert.total_composite_errors]}
          isOpen={false}
          canOpen={false}
          leftAlignLastItem
          showTooltip
          tooltip={[
            null,
            null,
            this.makeTooltip(alertErrorMessages[index], 'alert'),
            this.makeTooltip(alertErrorMessages[index], 'composite'),
          ]}
          tooltipIndices={[2, 3]}
        />,
      ),
    );
    return (
      <div key={`${alertErrors.length}header`} style={{maxHeight: '30vh', overflowY: 'auto'}}>
        <HeaderRow
          titles={['Name', 'Error Type', 'Alert Error Message', 'Composite Error Message']}
          leftAlignLastItem
        />
        {err}
      </div>
    );
  };

  makeTooltip = (messages, type) => {
    const err = [];
    if (Object.keys(messages[type]).length === 0) {
      return (
        <p>
          No
          {type} errors.
        </p>
      );
    }
    Object.keys(messages[type]).forEach((message, index) => {
      if (messages[type][message] === 1) {
        // eslint-disable-next-line react/no-array-index-key
        err.push(<p key={index + message}>{message}</p>);
      } else {
        err.push(
          // eslint-disable-next-line react/no-array-index-key
          <p key={index + message}>
            {message} ({messages[type][message]}
            x)
          </p>,
        );
      }
    });
    return <div style={{textAlign: 'left'}}>{err}</div>;
  };

  render() {
    const {alertErrors, areAlertErrorsLoading} = this.props;
    return (
      <div styleName="container">
        <header styleName="section-header">
          <span styleName="title">Alert Errors ({!areAlertErrorsLoading && alertErrors ? alertErrors.length : 0})</span>
        </header>
        {this.renderAlertErrors()}
      </div>
    );
  }
}
