// @flow
import React from 'react';
import {connect} from 'react-redux';
import {get} from 'lodash';
import {Table, Column, Cell} from 'fixed-data-table-2';
import ReactResizeDetector from 'react-resize-detector';
import SmartTooltip from 'common/components/SmartTooltip';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';

import {isAnodotUser as isAnodotUserSelector} from 'profile/store/selectors';
import * as selectors from 'admin.features/store/selectors';
import * as actions from 'admin.features/store/actions';

import Header from 'admin.features/components/Header';
import ToggleSwitch from 'common/componentsV2/toggleSwitch/ToggleSwitch';
import FeaturesFlagsFilter from '../components/FeaturesFlagsFilter';

import './FeaturesFlags.module.scss';

type PropTypes = {
  // connect
  customersView: Object,
  globalsView: Object,
  isAnodotUser: Boolean,
  fetchCustomers: Function,
  toggleFeatureCustomer: Function,
  toggleFeatureGlobal: Function,
  toggleAll: Function,
  updateCustomersAndGlobalIsLoading: Boolean,
  customersAndGlobalIsLoading: Boolean,
  defaultsIsLoading: Boolean,
};

const SIZE = 750;

@connect(
  (state) => ({
    customersView: selectors.getDataUIStateFiltered(state),
    customersData: selectors.getMergedCustomersOrigin(state),
    globalsView: selectors.getMergedGlobalFiltered(state),
    globalsData: selectors.getMergedGlobalOrigin(state),
    updatedCustomersAndGlobal: selectors.getUpdateCustomersAndGlobal(state),
    defaultsIsLoading: selectors.getDefaultsIsLoading(state),
    customersAndGlobalIsLoading: selectors.getCustomersAndGlobalIsLoading(state),
    updateCustomersAndGlobalIsLoading: selectors.getUpdateCustomersAndGlobalIsLoading(state),
    isAnodotUser: isAnodotUserSelector(state),
    customersDifference: selectors.getCustomersDifference(state),
    globalsDifference: selectors.getGlobalsDifference(state),
  }),
  {
    fetchCustomers: actions.fetchCustomers,
    toggleFeatureCustomer: actions.toggleFeatureCustomer,
    toggleFeatureGlobal: actions.toggleFeatureGlobal,
    toggleAll: actions.toggleAll,
  },
)
export default class FeatureFlags extends React.PureComponent {
  props: PropTypes;

  state = {
    tableWidth: SIZE,
    tableHeight: SIZE,
    columnWidths: [],
    searchOption: 'customers',
  };

  componentDidMount() {
    this.props.fetchCustomers();
  }

  componentDidUpdate(prevProps) {
    // because of the shitty virtualized library (the Table),
    // we force the render every time the user search or filter something.
    if (this.props.customersView.length !== prevProps.customersView.length) {
      this.forceUpdate();
    }
  }

  setInitialTableSize = (elm) => {
    if (!elm) {
      return;
    }
    this.handleResize(elm.clientWidth, elm.clientHeight);
  };

  handleResize = (width, height) => {
    this.setState({
      tableWidth: width || SIZE,
      tableHeight: height || SIZE,
    });
  };

  getHeight = () => {
    const totalRowsHeight = this.props.customersView.length * 50 + 70;
    const elementHeight = get(this.state, 'tableHeight', SIZE);

    if (totalRowsHeight > elementHeight) {
      return elementHeight;
    }
    return totalRowsHeight;
  };

  tooltipContent = (globalObj) => (
    <div>
      <div>{globalObj.name}</div>
      <div>{globalObj.description}</div>
    </div>
  );

  render() {
    const {
      globalsView,
      toggleAll,
      customersView,
      defaultsIsLoading,
      customersAndGlobalIsLoading,
      updateCustomersAndGlobalIsLoading,
      toggleFeatureCustomer,
      toggleFeatureGlobal,
      isAnodotUser,
    } = this.props;

    const isLoading = defaultsIsLoading || customersAndGlobalIsLoading || updateCustomersAndGlobalIsLoading;

    return (
      <div styleName="root" className="shell-col">
        <Header />

        <main styleName="main">
          <FeaturesFlagsFilter />

          <div
            styleName={isLoading ? 'table disabled' : 'table'}
            id="file-preview-table-container"
            ref={this.setInitialTableSize}
          >
            {isLoading && (
              <div styleName="loading">
                <Spinner color="#3d4c59" size={SIZES.X_BIG_90} />
              </div>
            )}
            <ReactResizeDetector handleWidth handleHeight onResize={this.handleResize} />
            <Table
              rowHeight={50}
              headerHeight={50}
              onColumnResizeEndCallback={this.handleColumnResize}
              rowsCount={customersView.length}
              width={get(this.state, 'tableWidth', SIZE)}
              height={this.getHeight()}
            >
              <Column
                allowCellsRecycling
                header={
                  <Cell>
                    <span styleName="feature-global-name">{!isLoading ? 'Customers' : null}</span>
                  </Cell>
                }
                cell={({rowIndex}) => (
                  <Cell>
                    <div styleName="feature-global-name customers">
                      {customersView[rowIndex].id === 'all' ? (
                        <small style={{color: '#B3B3B3'}}> {customersView[rowIndex].name}</small>
                      ) : (
                        <SmartTooltip content={`ID: ${customersView[rowIndex].id}`}>
                          <span> {customersView[rowIndex].name}</span>
                        </SmartTooltip>
                      )}
                    </div>
                  </Cell>
                )}
                fixed
                width={250}
                columnKey="customers-column"
              />

              {globalsView.map((i) => (
                <Column
                  header={
                    <Cell>
                      <div
                        style={{
                          width: '100%',
                          top: 0,
                          position: 'absolute',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '100%',
                            height: 15,
                            alignItems: 'center',
                          }}
                        >
                          <SmartTooltip content={`Global is ${i.globalDefault ? 'on' : 'off'}`}>
                            <div
                              style={{
                                position: 'absolute',
                                top: -3,
                                right: -1,
                              }}
                            >
                              <ToggleSwitch
                                id={i.displayName}
                                name={i.name}
                                isChecked={i.globalDefault}
                                disabled={isAnodotUser}
                                onToggle={(id, name, isChecked) => toggleFeatureGlobal({id, name, isChecked})}
                                theme="green"
                                size="small"
                              />
                            </div>
                          </SmartTooltip>
                          {i.rndOnly && <span styleName="feature-global-label">R&D</span>}
                        </div>
                        <div>
                          <SmartTooltip content={this.tooltipContent(i)}>
                            <span styleName="feature-global-name">{i.displayName}</span>
                          </SmartTooltip>
                        </div>
                      </div>
                    </Cell>
                  }
                  cell={({rowIndex}) => (
                    <Cell>
                      <div
                        styleName={
                          i.globalDefault && !isAnodotUser ? 'hor-switch-container' : 'hor-switch-container disabled'
                        }
                      >
                        {customersView[rowIndex].id === 'all' ? (
                          <SmartTooltip
                            content={customersView[rowIndex].isTrueInAllCustomers[i.name] ? 'remove all' : 'Mark all'}
                          >
                            <i
                              styleName="toggle-all-checkbox"
                              key={`${i.name}_ALL`}
                              className={[
                                'icon',
                                customersView[rowIndex].isTrueInAllCustomers[i.name]
                                  ? 'icn-icon-checkbox-full'
                                  : 'icn-icon-checkbox',
                              ].join(' ')}
                              onClick={toggleAll.bind(this, {
                                name: i.name,
                                isChecked: customersView[rowIndex].isTrueInAllCustomers[i.name],
                              })}
                              role="presentation"
                            />
                          </SmartTooltip>
                        ) : (
                          <ToggleSwitch
                            id={customersView[rowIndex].id}
                            name={i.name}
                            isChecked={customersView[rowIndex].ff[i.name]}
                            onToggle={(id, name, isChecked) => toggleFeatureCustomer({id, name, isChecked})}
                          />
                        )}
                      </div>
                    </Cell>
                  )}
                  fixed={false}
                  width={200}
                  columnKey={`feature-${i.name}-column`}
                  key={i.name}
                />
              ))}
            </Table>
          </div>
        </main>
      </div>
    );
  }
}
