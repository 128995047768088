// @flow
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useField, useForm} from 'react-final-form';
import {Box, makeStyles} from '@material-ui/core';
import {getIsTreeEmpty} from 'metrics/store/selectors';
import {getUniqueId} from 'common/utils/guid';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import get from 'lodash/get';
import QuickActions from 'alerts.management/components/editor/simulationArea/QuickActions';
import TileStatElement from 'dashboards/components/TileStatElement';
import useExpressionBuilder from 'dashboards/hooks/useExpressionBuilder';

const defaultPreviewOptions = {
  '*': {
    sort: 'alphanumeric',
    show: 10,
  },
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: 600,
    padding: 24,
    borderRadius: 10,
    backgroundColor: theme.palette.gray[200],
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.gray[500],
  },
  wrapperTile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 220,
    height: 140,
    borderRadius: 6,
    backgroundColor: theme.palette.white[500],
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, .15)',
    paddingTop: 12,
  },
  name: {
    padding: '0 8px',
    borderRadius: 4,
    backgroundColor: theme.palette.purple[200],
    lineHeight: '28px',
    color: theme.palette.purple[600],
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flexShrink: 0,
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const StatContainer = () => {
  const classes = useStyles();
  const isTreeEmpty = useSelector(getIsTreeEmpty);
  const form = useForm();
  const formVal = form.getState().values;

  const {
    input: {value: expressionTreeType},
  } = useField('expressionTreeType');

  const {
    input: {value: isShowComposites},
  } = useField('showComposites');

  const {
    input: {value: dateRange},
  } = useField('dateRange');

  const {
    input: {value: timeScale},
  } = useField('timeScale');

  const {
    input: {value: scalarTransformType},
  } = useField('scalarTransformType');

  const scalarTransforms = useMemo(
    () => [
      {
        children: [],
        function: scalarTransformType,
        id: getUniqueId(),
        parameters: [],
        type: 'function',
      },
    ],
    [scalarTransformType],
  );

  const {metrics, isCompositeDataLoading} = useExpressionBuilder({
    dateRange,
    timeScale,
    multiPreviewOptions: defaultPreviewOptions,
    isShowComposites,
    scalarTransforms,
  });

  const isMetricsNotEmpty = metrics.length > 0 && !isTreeEmpty;
  const nameMeasure = (isMetricsNotEmpty && metrics[0].what) || 'N/A';

  if (isCompositeDataLoading) {
    return <Spinner color="gray.500" size={SIZES.MEDIUM_50} />;
  }

  if (isTreeEmpty) {
    return (
      <QuickActions defaultFunction={expressionTreeType === 'simple' ? 'sumSeries' : ''} maxExpressionsCount={3} />
    );
  }

  return (
    <Box className={classes.wrapperTile}>
      <TileStatElement
        id="statTilePreview"
        tileType={formVal.tileType}
        isLoading={isCompositeDataLoading}
        isShowMeasure={formVal.showMeasure}
        isShowTitle={formVal.title}
        isShowTitlePreviewGauge
        title={formVal.title}
        isResize={false}
        nameMeasure={nameMeasure}
        currentValue={get(metrics[0], `scalarTransforms[${scalarTransformType}].value`, 0)}
        isAbbreviate={formVal.abbreviate}
        isDecimal={formVal.decimal}
        unitText={formVal.unit.value}
        metrics={metrics}
        isShowGraph={formVal.showGraph}
        inputNoData={formVal.inputNoData}
        minScale={formVal.minScale}
        maxScale={formVal.maxScale}
        thresholds={formVal.thresholds}
        isShowValueLabels={formVal.isShowValueLabels}
        scalarTransformType={scalarTransformType}
      />
    </Box>
  );
};

export default StatContainer;
