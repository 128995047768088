import {composeReducers} from 'common/utils/reducers';
import * as commonActions from 'common/store/actions';
import * as actions from '../actions';

const EMPTY_ARRAY = [];

const initialState = {
  listOrder: {column: 'name', direction: 'asc'},
  editModal: {
    isOpen: false,
    editingUserId: null,
  },
  newModal: {
    isOpen: false,
    users: EMPTY_ARRAY,
    inputValue: '',
    selectedRole: {role: 'customer-user', name: 'User'},
    selectedOrg: null,
    groups: EMPTY_ARRAY,
    defaultGroup: '',
    message: '',
    isEmailExistOpen: false,
  },
  userStatusModal: {
    isOpen: false,
    userId: null,
    action: null,
  },
  deleteModal: {
    isOpen: false,
    userId: null,
    isDelegate: false,
    delegateUserId: 'andt-none',
  },
  bulkDeleteModal: {
    isOpen: false,
    isDelegate: false,
    delegateUserId: 'andt-none',
  },
  selectedUsersCheckbox: EMPTY_ARRAY,
};

const usersReducer = composeReducers((state = {...initialState}, {type, payload}) => {
  switch (type) {
    case actions.setUserListOrder.TYPE: {
      return {
        ...state,
        listOrder: {...payload},
      };
    }
    case actions.toggleUserEditModal.TYPE: {
      return {
        ...state,
        editModal: {
          isOpen: payload.isOpen,
          editingUserId: payload.userId || null,
        },
      };
    }
    case actions.setUserEditModalKeyVal.TYPE: {
      return {
        ...state,
        editModal: {
          ...state.editModal,
          ...payload,
        },
      };
    }
    case actions.toggleNewUserModal.TYPE: {
      return {
        ...state,
        newModal: {
          isOpen: payload.isOpen,
          users: EMPTY_ARRAY,
          inputValue: '',
          selectedRole: {role: 'customer-user', name: 'User'},
          selectedOrg: null,
          groups: EMPTY_ARRAY,
          defaultGroup: '',
          message: '',
          isEmailExistOpen: false,
        },
      };
    }
    case actions.setNewUserModalKeyVal.TYPE: {
      return {
        ...state,
        newModal: {
          ...state.newModal,
          ...payload,
        },
      };
    }
    case actions.setNewUserModalOrg.TYPE: {
      return {
        ...state,
        newModal: {
          ...state.newModal,
          selectedOrg: payload,
          selectedRole:
            payload._id === '5418101dfcba5daa31e38c93'
              ? {role: 'anodot-user', name: 'Anodot User'}
              : {role: 'customer-user', name: 'User'},
        },
      };
    }
    case actions.addToUsersList.TYPE: {
      return {
        ...state,
        newModal: {
          ...state.newModal,
          users: [...state.newModal.users, payload],
          inputValue: '',
        },
      };
    }
    case actions.removeFromUsersList.TYPE: {
      return {
        ...state,
        newModal: {
          ...state.newModal,
          users: state.newModal.users.filter((user) => user.id !== payload.id),
        },
      };
    }
    case actions.toggleUserChangeStatusConfirmationModal.TYPE: {
      return {
        ...state,
        userStatusModal: {
          isOpen: payload.isOpen,
          userId: payload.userId || null,
          action: payload.action || null, // 'enable', 'disable'
        },
      };
    }
    case actions.toggleDeleteUserModal.TYPE: {
      return {
        ...state,
        deleteModal: {
          isOpen: payload.isOpen,
          userId: payload.userId || null,
          isDelegate: false,
          delegateUserId: 'andt-none',
        },
      };
    }
    case actions.setDeleteUserModalKeyVal.TYPE: {
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          ...payload,
        },
      };
    }
    case actions.setUsersCheckboxAll.TYPE: {
      return {
        ...state,
        selectedUsersCheckbox: [...payload],
      };
    }
    case actions.singleUserCheckboxClick.TYPE: {
      const index = state.selectedUsersCheckbox.findIndex((userId) => userId === payload);
      if (index > -1) {
        return {
          ...state,
          selectedUsersCheckbox: [
            ...state.selectedUsersCheckbox.slice(0, index),
            ...state.selectedUsersCheckbox.slice(index + 1),
          ],
        };
      }
      return {
        ...state,
        selectedUsersCheckbox: [...state.selectedUsersCheckbox, payload],
      };
    }
    case actions.toggleBulkDeleteUsersModal.TYPE: {
      return {
        ...state,
        bulkDeleteModal: {
          isOpen: payload.isOpen,
          isDelegate: false,
          delegateUserId: 'andt-none',
        },
      };
    }
    case actions.setBulkDeleteUsersModalKeyVal.TYPE: {
      return {
        ...state,
        bulkDeleteModal: {
          ...state.bulkDeleteModal,
          ...payload,
        },
      };
    }
    case actions.deleteUsersBulkApi.TYPE: {
      return {
        ...state,
        selectedUsersCheckbox: EMPTY_ARRAY,
      };
    }
    case commonActions.routeChanged.TYPE: {
      return {
        ...state,
        selectedUsersCheckbox: EMPTY_ARRAY,
      };
    }
    default:
      return state;
  }
});

export default usersReducer;
