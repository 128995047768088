import React from 'react';
import {noop} from 'lodash';
import {makeStyles, useTheme} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import {segmentClickEvent} from 'common/store/actions';
import Spinner, {SIZES} from './Spinner';

const getColorByProp = (theme, prop) => {
  const keys = prop.split('.');
  return theme.palette[keys[0]][keys[1]];
};

const matchingFontColorMap = {
  'gray.300': 'gray.500',
  'gray.200': 'gray.500',
};

const useStyles = makeStyles((theme) => ({
  root: {
    pointerEvents: ({isLoading}) => (isLoading ? 'none' : 'auto'),
    borderRadius: '6px',
    border: 0,
    fontSize: '14px',
    fontWeight: 400,
    margin: 0,
    height: '35px',
    padding: '0 20px',
    backgroundColor: (props) =>
      props.colorSchema ? getColorByProp(theme, props.colorSchema) : theme.palette.blue[500],
    color: (props) => matchingFontColorMap[props.colorSchema] || theme.palette.white[500],
    position: 'relative',
    opacity: ({isDisabled}) => (isDisabled ? 0.5 : 1),
    '&:hover': {
      opacity: ({isDisabled}) => (isDisabled ? 0.5 : 0.9),
    },
  },
  spinnerWrapper: {
    borderRadius: '6px',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: (props) =>
      props.colorSchema ? getColorByProp(theme, props.colorSchema) : theme.palette.blue[500],
  },
}));

type PropTypes = {
  type: string,
  colorSchema: string,
  children: React.Node,
  isLoading: boolean,
  onClick: Function,
  text: string,
  automationId: string,
  isDisabled: boolean,
};

// eslint-disable-next-line
const AsyncButton = ({type, children, onClick, isLoading, colorSchema, text, automationId, isDisabled}: PropTypes) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles({isLoading, colorSchema, isDisabled});
  const handleClick = () => {
    dispatch(
      segmentClickEvent({
        type: 'click',
        name: text || children,
        automationId,
      }),
    );
    if (onClick) {
      onClick();
    }
  };

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      automation-id={automationId}
      className={classes.root}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={isLoading ? noop : handleClick}
      disabled={isDisabled}
    >
      {text || children}
      {isLoading && (
        <div className={classes.spinnerWrapper}>
          <Spinner color={theme.palette.white[500]} size={SIZES.SMALL_30} />
        </div>
      )}
    </button>
  );
};

export default AsyncButton;
