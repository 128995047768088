// @flow
import React, {Component} from 'react';
import {getDate, rangeTypes} from 'common/utils/dateRangeService';
import DateRangesDdl, {THEME} from 'common/components/dateTime/DateRangesDdl';
import {connect} from 'react-redux';
import * as commonSelectors from 'profile/store/selectors';
import * as alertsConsoleSelectors from 'alerts.console/store/selectors';

type PropTypes = {
  setQueryParams: Function,
  // connect
  timeZoneName: string,
  queryParams: Object,
};

@connect(
  (state) => ({
    timeZoneName: commonSelectors.getTimeZoneName(state),
    queryParams: alertsConsoleSelectors.getQueryParamsViews(state),
  }),
  {},
)
export default class DateRangeFilter extends Component {
  props: PropTypes;

  // eslint-disable-next-line react/sort-comp
  dateRangeChange = (dr) => {
    const {setQueryParams} = this.props;
    const isCustom = dr.constRange === rangeTypes.c.value;
    const isRelative = dr.constRange === rangeTypes.r.value;
    const times = {
      startTime: isCustom ? dr.startDate : null, // null is OK, before api req will calc relative date
      endTime: isCustom ? dr.endDate : null,
      relativeLast: isRelative ? dr.relativeLast : null,
      relativeNext: isRelative && String(dr.relativeNext) !== String(0) ? dr.relativeNext : null,
    };

    setQueryParams({constRange: dr.constRange, ...times});
  };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.queryParams.startTime !== this.props.queryParams.startTime ||
      nextProps.queryParams.endTime !== this.props.queryParams.endTime ||
      nextProps.queryParams.constRange !== this.props.queryParams.constRange ||
      nextProps.queryParams.relativeLast !== this.props.queryParams.relativeLast ||
      nextProps.queryParams.relativeNext !== this.props.queryParams.relativeNext
    );
  }

  render() {
    const {queryParams, timeZoneName} = this.props;

    const dateRange = getDate(
      {
        constRange: queryParams.constRange,
        startDate: queryParams.startTime,
        endDate: queryParams.endTime,
        relativeLast: queryParams.relativeLast,
        relativeNext: queryParams.relativeNext,
      },
      timeZoneName,
    );

    return (
      <DateRangesDdl
        onChange={this.dateRangeChange}
        dateRange={dateRange}
        theme={THEME.HIGHLIGHTED}
        allowedRangeTypes={['h1', 'h4', 'd1', 'd3', 'w1', 'w2', 'm1', 'm3', 'm6', 'y1']}
        allowedCustomDates={false}
        isUnix
      />
    );
  }
}
