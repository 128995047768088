// @flow
import React, {useState, useEffect} from 'react';
import {Box} from '@material-ui/core';
import Spinner, {SIZES as SpinnerSizes} from 'common/componentsV2/Spinner';
import TitleHeaderTableOrder from 'common/componentsV2/table/TitleHeaderTableOrder';
import MetricsTableRow from './MetricsTableRow';
import './MetricsTable.module.scss';

type PropTypes = {
  metrics: Array<Object>,
  isLoading: boolean,
  timeZoneName: string,
  optionalTitle: string,
  listOrder: Object,
  setListOrder: Function,
};

const EMPTY_ARRAY = [];
const DEFAULT_SORT_COLUMN = 'score';

const MetricsTable = ({metrics, isLoading, timeZoneName, optionalTitle, listOrder, setListOrder}: PropTypes) => {
  const [selectedItemsIdsList, setSelectedItemsIdsList] = useState(EMPTY_ARRAY);
  const [sortedMetrics, setSortedMetrics] = useState(EMPTY_ARRAY);

  const sortingFunction = (arr, sortBy, direction) => {
    return arr.sort((a, b) => {
      const itemA = a[sortBy];
      const itemB = b[sortBy];

      if (itemA < itemB) {
        return -1 * direction;
      }
      if (itemA > itemB) {
        return direction;
      }
      return 0;
    });
  };

  useEffect(() => {
    const sortBy = listOrder.column ? listOrder.column : DEFAULT_SORT_COLUMN;
    const direction = !listOrder.direction || listOrder.direction === 'desc' ? -1 : 1;

    setSelectedItemsIdsList([]);
    setSortedMetrics([...sortingFunction(metrics, sortBy, direction)]);
  }, [metrics, listOrder]);

  const handleTitleOrderChange = (columnName) => {
    let updatedDirection = 'desc';
    if (columnName === listOrder.column) {
      updatedDirection = listOrder.direction === 'desc' ? 'asc' : 'desc';
    }

    setListOrder({
      column: columnName,
      direction: updatedDirection,
    });
  };

  const setSelectedItem = (itemId) => {
    if (selectedItemsIdsList.indexOf(itemId) === -1) {
      setSelectedItemsIdsList((state) => [...state, itemId]);
    } else {
      setSelectedItemsIdsList((state) => state.filter((item) => item !== itemId));
    }
  };

  if (isLoading) {
    return (
      <div styleName="spinner-wrapper">
        <Spinner color="#3d4c59" size={SpinnerSizes.XX_BIG_100} />
      </div>
    );
  }

  const columns = [
    {
      id: 0,
      name: 'title',
      title: optionalTitle || '',
      isSortable: false,
    },
    // Until BE will add this field to metrics in anomalies/metrics API
    // {
    //   id: 1,
    //   name: 'impact',
    //   title: 'Impact',
    //   isSortable: true,
    // },
    {
      id: 2,
      name: 'duration',
      title: 'Duration',
      isSortable: true,
    },
    {
      id: 3,
      name: 'deltaPercentage',
      title: 'Delta',
      isSortable: true,
    },
    {
      id: 4,
      name: 'score',
      title: 'Score',
      isSortable: true,
    },
  ];

  return (
    <Box styleName="table">
      <div styleName="table-header">
        <div styleName="col-is-open" />
        {columns.map((col) => (
          <div styleName={`col-header col-${col.name}`} key={`user-events-list-${col.id}`}>
            <TitleHeaderTableOrder
              title={col.title}
              columnName={col.name}
              onChange={handleTitleOrderChange}
              selectedDirection={listOrder.direction}
              isSortable={col.isSortable}
              isActive={col.name === listOrder.column}
            />
          </div>
        ))}
      </div>

      {sortedMetrics.map((metric) => (
        <MetricsTableRow
          key={metric.id}
          metric={metric}
          columns={columns}
          timeZoneName={timeZoneName}
          setSelectedItem={setSelectedItem}
          isSelected={selectedItemsIdsList.indexOf(metric.id) !== -1}
        />
      ))}
    </Box>
  );
};

export default MetricsTable;
