// @flow
import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {useSelector} from 'react-redux';
import * as profileSelector from 'profile/store/selectors';
import CSSTransition from 'react-transition-group/cjs/CSSTransition';
import {Box, makeStyles} from '@material-ui/core';
import {getMyGroups} from 'admin.users/store/selectors';
import {GROUP_COLOR_SCHEMAS} from 'anodot-objects-models';
import {ReactComponent as UserIcon} from 'app/images/profile_empty.svg';
import UserGroupsInfoItem from './UserGroupsInfoItem';
import {HEIGHT_SUBMENU_ITEM} from './NavBarSubMenuItem';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 240,
    display: 'block',
    opacity: 1,
    position: 'fixed',
    left: 60,
    bottom: 20,
    zIndex: 9999,
    height: 'auto',
    backgroundColor: theme.palette.white[500],
    color: theme.palette.gray[500],
    overflow: 'hidden',
    boxShadow: '0 4px 14px -1px rgba(0, 0, 0, 0.15)',
    borderRadius: 16,
    whiteSpace: 'nowrap',
    textAlign: 'left',
    paddingBottom: 12,
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  userData: {
    color: theme.palette.gray[400],
    fontSize: 14,
    lineHeight: 1.1,
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  userName: {
    display: 'flex',
    alignItems: 'baseline',
    lineHeight: 1,
    color: theme.palette.gray[500],
    fontSize: 18,
    fontWeight: 300,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  subMenuItem: {
    lineHeight: '39px',
    height: HEIGHT_SUBMENU_ITEM,
    textAlign: 'left',
    color: theme.palette.gray[500],
    paddingTop: 0,
    paddingLeft: 10,
    paddingBottom: 0,
    borderRadius: 4,
    fontSize: 16,
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.blue[100],
      color: theme.palette.blue[500],
    },
  },
  itemWrapper: {
    marginBottom: 0,
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 46,
    width: 60,
    position: 'relative',
    margin: 0,
    padding: '20px 0',
    '&:before': {
      content: '""',
      opacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: 5,
      borderRadius: '0 3px 3px 0',
      backgroundColor: theme.palette.blue[500],
      animation: 'navanimationback ease-in-out 0.3s',
      animationIterationCount: 1,
      transformOrigin: '50% 50%',
    },
  },
  activeItem: {
    '&:before': {
      opacity: 1,
      animation: 'navanimation ease-in-out 0.3s',
      animationIterationCount: 1,
      transformOrigin: '50% 50%',
    },
  },
  userTriggerSubmenu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      '& $userIcon': {
        borderColor: theme.palette.blue[500],
      },
    },
  },
  userIcon: {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.gray[400]}`,
    marginTop: 0,
    marginLeft: 0,
  },
  gray: {
    borderColor: theme.palette.gray[400],
  },
  rose: {
    borderColor: theme.palette.rose[500],
  },
  mint: {
    borderColor: theme.palette.mint[500],
  },
  yellow: {
    borderColor: theme.palette.yellow[500],
  },
  teal: {
    borderColor: theme.palette.teal[500],
  },
  indigo: {
    borderColor: theme.palette.indigo[500],
  },
  lilach: {
    borderColor: theme.palette.lilach[500],
  },
  tomato: {
    borderColor: theme.palette.tomato[500],
  },
  eucaliptus: {
    borderColor: theme.palette.eucaliptus[500],
  },
  active: {
    backgroundColor: theme.palette.blue[100],
    color: theme.palette.blue[500],
  },
  '@keyframes navanimation': {
    '0%': {
      opacity: 0,
      transform: 'translate(-10px, 0)',
    },
    '100%': {
      opacity: 1,
      transform: 'translate(0, 0)',
    },
  },
  '@keyframes navanimationback': {
    '0%': {
      opacity: 1,
      transform: 'translate(0, 0)',
    },

    '100%': {
      opacity: 0,
      transform: 'translate(-10px, 0)',
    },
  },
}));

type NavBarUserProps = {
  me: Object,
  userRole: string,
  handleClick: Function,
  isSelectedItem: boolean,
};

const body = document.getElementsByTagName('body')[0];

const NavBarUser = React.memo(({me, userRole, handleClick, isSelectedItem}: NavBarUserProps) => {
  const [isHover, setIsHover] = useState(false);
  const myGroups = useSelector(getMyGroups);
  const isCustomerAdmin = useSelector(profileSelector.isCustomerAdmin);
  const isReadOnlyUser = useSelector(profileSelector.isReadOnlyUser);
  const toggleHover = () => setIsHover(() => !isHover);
  const classes = useStyles();
  // const isUserGroup = me.groups && me.groups.length > 0 ? classes.groups : '';
  const groupBgColor = myGroups.length === 1 ? classes[myGroups[0].colorSchema] : classes[GROUP_COLOR_SCHEMAS.GRAY];
  const renderUserSubMenu = (
    <CSSTransition timeout={300} classNames="sub-menu-item__animation" automation-id="subMenu" appear in>
      <div className={classes.wrapper}>
        <Box px={2.5} pt={2.5}>
          <Box pb={1}>
            <div className={classes.userData}>{me.organization.name}</div>
          </Box>
          <Box pb={1}>
            <div className={classes.userName}>
              <div className={classes.ellipsis}>
                {me.firstName} {me.lastName.split('')[0]}.
              </div>
              &ensp;
              {(isCustomerAdmin || isReadOnlyUser) && <div className={classes.userData}>{userRole}</div>}
            </div>
          </Box>
          <div className={classes.userData}>{me.email}</div>
          {me.groups && me.groups.length > 0 && (
            <Box mt={2}>
              <UserGroupsInfoItem />
            </Box>
          )}
        </Box>
        <Box mx={1.5} mt={7.5}>
          <Box
            component="a"
            href="#!/r/user/settings"
            className={`${classes.subMenuItem} ${isSelectedItem ? classes.active : ''}`}
            display="flex"
            alignItems="center"
            automation-id="Settings"
          >
            <Box mr={1}>
              <i className="icon icn-nav16-settings" />
            </Box>
            Settings
          </Box>
          <div
            className={classes.subMenuItem}
            onClick={handleClick('logout')}
            role="presentation"
            automation-id="Logout"
          >
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <i className="icon icn-nav16-logout" />
              </Box>
              Log out
            </Box>
          </div>
        </Box>
      </div>
    </CSSTransition>
  );
  return (
    <div className={classes.itemWrapper} automation-id="User Menu">
      <div className={`${classes.item} ${isSelectedItem ? classes.activeItem : ''}`}>
        <div className={classes.userTriggerSubmenu} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
          <i className={`${classes.userIcon} ${groupBgColor}`}>
            <UserIcon width={16} height={16} />
          </i>
          {isHover && ReactDOM.createPortal(renderUserSubMenu, body)}
        </div>
      </div>
    </div>
  );
});

export default NavBarUser;
