// @flow
import React, {PureComponent} from 'react';
import {Box} from '@material-ui/core';
import {connect} from 'react-redux';
import CopyToClipboardButton from 'common/components/CopyToClipboardButton';
import NameFormat20 from 'metrics/components/metricName/NameFormat20';
import MetricNameMenu from 'common/components/MetricNameMenu';

type PropTypes = {
  metric: Object,
  highlightText: string,
  isSingleLine: boolean,
  isDisableCopy: boolean,
  specialHeader: boolean,
};

class MetricName extends PureComponent {
  props: PropTypes;

  render() {
    const {metric, highlightText, isSingleLine, isDisableCopy, specialHeader} = this.props;

    const extraProps = isSingleLine
      ? {
          position: 'absolute',
          right: -7,
          top: 0,
          paddingLeft: 1,
          height: '24px',
        }
      : {};

    return (
      <Box position="relative" css={{'&:hover .copy-wrapper': {opacity: '1'}}}>
        <NameFormat20
          isSpecialHeader={specialHeader}
          isSingleLine={isSingleLine}
          metric={metric}
          highlightText={highlightText || null}
        >
          {!isDisableCopy && (
            <Box
              className="copy-wrapper"
              p={0.25}
              height={15}
              ml={0.5}
              css={{transition: '200ms', opacity: 0}}
              {...extraProps}
            >
              <CopyToClipboardButton
                copyStr={metric.name}
                shouldUseIcon
                metricNameMenu={<MetricNameMenu metric={metric} />}
              />
            </Box>
          )}
        </NameFormat20>
      </Box>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    metric: ownProps.metric,
  }),
  {},
)(MetricName);
