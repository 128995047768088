// @flow
import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import CopyToClipboardButton from 'common/components/CopyToClipboardButton';
import SelectAndt, {TYPE_SIMPLE} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import './FiltersPanel.module.scss';
import {segmentClickEvent} from '../store/actions';

type PropTypes = {
  isOpen: boolean,
  isClearAll: boolean,
  isSaveView: boolean,
  isShareLink: boolean,
  copySuccessMessage: String,
  children: Node,
  onClose: Function,
  onClearAll: Function,
  onSaveViewChange: Function,
  automationId: string,
};

const FILTERS_SAVE_OPTIONS = [
  {label: 'Save view', value: 'SAVE', id: 'save'},
  {label: 'Reset to Default', value: 'RESET_MANUFACTURE', id: 'reset'},
];

const FiltersPanel = ({
  isOpen,
  isClearAll,
  onClose,
  onClearAll,
  children,
  onSaveViewChange,
  copySuccessMessage = null,
  isSaveView = true,
  isShareLink = true,
  automationId,
}: PropTypes) => {
  const dispatch = useDispatch();

  const onSaveViewValueChange = useCallback(
    (item) => {
      onSaveViewChange(item.id);
    },
    [onSaveViewChange],
  );

  const handleOnClose = useCallback(() => {
    dispatch(segmentClickEvent({type: 'click', name: 'filterPanelClose'}));
    onClose();
  }, [dispatch, onClose]);

  const handleOnClearAll = useCallback(() => {
    dispatch(segmentClickEvent({type: 'click', name: 'filterPanelClearAll'}));
    onClearAll();
  }, [dispatch, onClose]);

  const filterStyleName = ['filters-wrapper', isOpen ? 'open' : ''].join(' ');
  const filterActionStyleName = ['filters-actions-wrapper', !isSaveView ? 'hidden' : ''].join(' ');

  return (
    <div styleName={filterStyleName} automation-id={automationId}>
      <div styleName="panel-root">
        {onClose ? (
          <i
            role="presentation"
            className="icon icn-general16-closea"
            onClick={handleOnClose}
            styleName="close-button"
          />
        ) : null}
        <div styleName="header-row">
          <span styleName="header">Filters</span>
          {isClearAll && (
            <span
              role="presentation"
              styleName="clear-all"
              onClick={handleOnClearAll}
              automation-id="clear_all_filters"
            >
              Clear All
            </span>
          )}
        </div>
        {children}
      </div>
      <div styleName={filterActionStyleName}>
        <SelectAndt
          styleName="select-wrapper"
          id="saveFiltersView"
          automationId="saveFiltersView"
          options={FILTERS_SAVE_OPTIONS}
          type={TYPE_SIMPLE}
          customComponent={{
            DropdownIndicator: () => (
              <div styleName="filters-actions">
                <i className="icon icn-general16-filter" />
                <span>Save View</span>
              </div>
            ),
          }}
          onChange={onSaveViewValueChange}
        />
        {isShareLink && (
          <div styleName="filters-actions">
            <CopyToClipboardButton
              successMessage={copySuccessMessage}
              copyStr={window.location.href}
              tooltipMessage="Copy Link"
            >
              <div>
                <i className="icon icn-general16-share" />
                <span>Share Link</span>
              </div>
            </CopyToClipboardButton>
          </div>
        )}
      </div>
      <div styleName="spacer" />
      <div styleName="color-mask" />
    </div>
  );
};

export default FiltersPanel;
