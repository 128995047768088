import React from 'react';
import {useSelector} from 'react-redux';
import {isEqual} from 'lodash';
import {useField} from 'react-final-form';
import {Box} from '@material-ui/core';
import DateRangesDdl from 'common/components/dateTime/DateRangesDdl';
import TimeScaleSelector from 'common/componentsV2/TimeScaleSelector';
import {getTimeZoneName} from 'profile/store/selectors';

const DateRanges = ({isFooterComponent = false}: {isFooterComponent: boolean}) => {
  const timeZoneName = useSelector(getTimeZoneName);
  const {
    input: {value, onChange},
  } = useField('dateRange', {isEqual});

  const {
    input: {value: timeScaleValue, onChange: timeScaleOnChange},
  } = useField('timeScale');
  const menuWidth = isFooterComponent ? 270 : 160;

  return (
    <DateRangesDdl
      dateRange={value}
      onChange={onChange}
      menuWidth={menuWidth}
      timeZoneName={timeZoneName}
      footerComponent={
        isFooterComponent && (
          <Box bgcolor="blue.100" p={1.5} fontWeight={500} height={60}>
            <TimeScaleSelector
              value={timeScaleValue}
              onChange={timeScaleOnChange}
              extraStyleName="menu-footer"
              title="Time Scale"
              isFooterComponent
            />
          </Box>
        )
      }
    />
  );
};

export default DateRanges;
