// @flow
import React, {PureComponent} from 'react';
import connect from 'react-redux/es/connect/connect';
import * as selectors from 'alerts.management/store/selectors';
import * as actions from 'alerts.management/store/actions';
import {VOLUME_BOUND_OPTIONS, VOLUME_DURATION_OPTIONS} from 'alerts.management/services/durationAndScaleService';
import SmartTooltip from 'common/components/SmartTooltip';
import SelectAndt, {THEME_BLUE_DARK, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import ToggleSwitch from 'common/componentsV2/toggleSwitch/ToggleSwitch';
import Checkbox from 'common/componentsV2/Checkbox';
import Spinner, {SIZES as SPINNER_SIZES} from 'common/componentsV2/Spinner';
import {makeSupportLink} from 'common/utils/browserLoacation';
import ConditionContainer from './ConditionContainer';

import './VolumeCondition.module.scss';

type PropTypes = {
  // connect
  volumeConditions: Object,
  validRollupOptions: Array,
  isLoadingAutoVolume: Boolean,
  setVolumeNumOfLastPoints: Function,
  setVolumeRollup: Function,
  setVolumeEnabled: Function,
  setVolumeBound: Function,
  setEnableAutoTuning: Function,
  setVolumeValue: Function,
};

@connect(
  (state) => ({
    volumeConditions: selectors.getVolumeConditions(state),
    validRollupOptions: selectors.getValidRollupOptions(state),
    isLoadingAutoVolume: selectors.getIsLoadingAutoVolumeCondition(state),
  }),
  {
    setVolumeRollup: actions.setVolumeRollup,
    setVolumeEnabled: actions.setVolumeEnabled,
    setVolumeNumOfLastPoints: actions.setVolumeNumOfLastPoints,
    setVolumeBound: actions.setVolumeBound,
    setEnableAutoTuning: actions.setEnableAutoTuning,
    setVolumeValue: actions.setVolumeValue,
  },
)
export default class VolumeCondition extends PureComponent {
  props: PropTypes;

  state = {
    isErrorMsgDisplay: true,
  };

  selectedRollup = (options, currentRollup) => {
    let selectedRollup = options.find((i) => i.value === currentRollup);
    if (selectedRollup) {
      return selectedRollup;
    }
    selectedRollup = options.length > 1 ? options[1] : options[0];
    return selectedRollup;
  };

  selectedNumOfLastPoints = (options, currentNumLastPoints) => {
    const numOfLastPointsItem = options.find((i) => i.value === currentNumLastPoints);
    return numOfLastPointsItem || options[0];
  };

  setVolumeNumOfLastPoints = (lastPoint) => {
    this.props.setVolumeNumOfLastPoints(lastPoint);
  };

  setVolumeRollup = (rollupValue) => {
    this.props.setVolumeRollup(rollupValue);
  };

  setVolumeBound = (boundValue) => {
    this.props.setVolumeBound(boundValue);
  };

  setVolumeValue = (event) => {
    this.props.setVolumeValue(event.target.value === '' || undefined ? null : event.target.value);
  };

  setEnableAutoTuning = (enableAutoTuning) => {
    const {setEnableAutoTuning} = this.props;
    setEnableAutoTuning(enableAutoTuning);
  };

  setVolumeEnabled = (enabled) => {
    const {setVolumeEnabled} = this.props;
    setVolumeEnabled(enabled);
  };

  setIsInputDisplay = () => {
    const {
      volumeConditions: {invalidCondition, value, enableAutoTuning},
    } = this.props;
    if (invalidCondition) {
      return true;
    }
    if (enableAutoTuning) {
      return value !== null && value !== undefined;
    }
    return !enableAutoTuning;
  };

  errorMsg = {
    volumeConditions: this.props.volumeConditions,
    invalidCondition: (errorMsg) => (
      <div>
        <h3>
          <span>
            <SmartTooltip placement="top" content={errorMsg}>
              <i styleName="info" className="icon icn-general16-info" />
            </SmartTooltip>{' '}
            New alert condition
          </span>

          <i
            onClick={() => this.setState((prevState) => ({isErrorMsgDisplay: !prevState.isErrorMsgDisplay}))}
            className="icon icn-general16-closeb"
            styleName="close"
          />
        </h3>
        <p>
          This condition is here to help you reduce unimportant alerts, but works only for metrics that are defined as a
          sum.
          <br />
          <a
            rel="noopener noreferrer"
            href={makeSupportLink('https://support.anodot.com/hc/en-us/articles/360010343780')}
            target="_blank"
          >
            Learn more
          </a>
        </p>
      </div>
    ),
    noValue: () => (
      <div>
        <h3>
          <span>
            <i styleName="warning" className="icon icn-general16-warning" /> Condition is not yet in effect
          </span>
        </h3>
        <p>We’re unable to calculate the threshold value right now.</p>
        <p>The condition is valid and will take effect once the value can be calculated.</p>
        <p style={{fontWeight: 500}}>In the meantime, all alerts will be sent as usual.</p>
      </div>
    ),
  };

  render() {
    const {
      volumeConditions: {invalidCondition, enabled, numLastPoints, rollup, bound, value, enableAutoTuning, errorMsg},
      validRollupOptions,
      isLoadingAutoVolume,
    } = this.props;
    const rollupOptions = [...validRollupOptions];
    const selectedRollup = this.selectedRollup(rollupOptions, rollup);
    const numOfLastPointsOptions = VOLUME_DURATION_OPTIONS[selectedRollup.value].numLastPoints;
    const selectedNumOfLastPoints = this.selectedNumOfLastPoints(numOfLastPointsOptions, numLastPoints);
    const boundOptions = Object.values(VOLUME_BOUND_OPTIONS);
    const selectedBound = boundOptions.find((i) => i.value === bound) || boundOptions[0];

    return (
      <div styleName="main-container">
        <ConditionContainer isDisabled={invalidCondition} className="condition-title" title="Ignore Low Volume Metrics">
          <div styleName={invalidCondition ? 'disabled' : ''}>
            <Checkbox
              isDisabled={invalidCondition}
              isChecked={enabled}
              onChange={() => this.setVolumeEnabled(enabled)}
              className="cb2"
            />
          </div>

          <div className="shell-col" automation-id="">
            <div styleName={invalidCondition ? 'values-wrapper disabled' : 'values-wrapper'}>
              <p>Alerts will not be triggered if the</p>
              <div>
                <p>metric value in the last</p>{' '}
                <SelectAndt
                  automationId="volumeNumOfLastPoints"
                  disabled={enableAutoTuning || !enabled}
                  type={TYPE_NEW_NO_SEARCH}
                  theme={THEME_BLUE_DARK}
                  onChange={this.setVolumeNumOfLastPoints}
                  options={numOfLastPointsOptions}
                  value={isLoadingAutoVolume ? '' : selectedNumOfLastPoints}
                  isMulti={false}
                  optionHeight={40}
                  buttonWidth={53}
                  menuWidth={75}
                  isClearable={false}
                  closeMenuOnSelect={false}
                />
                <SelectAndt
                  automationId="volumeRollup"
                  disabled={enableAutoTuning || !enabled}
                  type={TYPE_NEW_NO_SEARCH}
                  theme={THEME_BLUE_DARK}
                  onChange={this.setVolumeRollup}
                  options={rollupOptions}
                  value={isLoadingAutoVolume ? '' : selectedRollup}
                  isMulti={false}
                  optionHeight={40}
                  buttonWidth={73}
                  menuWidth={115}
                  isClearable={false}
                  closeMenuOnSelect={false}
                />{' '}
                <p>is</p>{' '}
                <SelectAndt
                  automationId="volumeBound"
                  disabled={enableAutoTuning || !enabled}
                  type={TYPE_NEW_NO_SEARCH}
                  theme={THEME_BLUE_DARK}
                  onChange={this.setVolumeBound}
                  options={boundOptions}
                  value={isLoadingAutoVolume ? '' : selectedBound}
                  isMulti={false}
                  optionHeight={40}
                  buttonWidth={85}
                  menuWidth={115}
                  isClearable={false}
                  closeMenuOnSelect={false}
                />
              </div>

              <div>
                <p>than</p>
                <div styleName="input-wrapper">
                  {this.setIsInputDisplay() && (
                    <input
                      disabled={enableAutoTuning || !enabled}
                      type="number"
                      name="volume-value"
                      value={isLoadingAutoVolume ? '' : value}
                      onChange={this.setVolumeValue}
                    />
                  )}

                  {(value === null || value === undefined) && enableAutoTuning && !invalidCondition && (
                    <span styleName="no-value-wrapper">
                      <i className="icon icn-general16-warning" />
                    </span>
                  )}
                </div>
              </div>
            </div>

            {(value === null || value === undefined) && enableAutoTuning && !invalidCondition && (
              <div styleName="msg-wrapper">{this.errorMsg.noValue()}</div>
            )}

            {this.state.isErrorMsgDisplay && invalidCondition && (
              <div styleName="msg-wrapper">{this.errorMsg.invalidCondition(errorMsg)}</div>
            )}

            <div styleName={invalidCondition ? 'automatic-calc-wrapper disabled' : 'automatic-calc-wrapper'}>
              {isLoadingAutoVolume && (
                <div styleName="loader-wrapper">
                  <Spinner color="#fff" size={SPINNER_SIZES.SMALL_30} />
                </div>
              )}
              <ToggleSwitch
                disabled={!enabled || invalidCondition}
                isChecked={enableAutoTuning}
                onToggle={() => this.setEnableAutoTuning(enableAutoTuning)}
              />
              <p>Auto-Calculate</p>
            </div>
          </div>
        </ConditionContainer>
      </div>
    );
  }
}
