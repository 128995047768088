// @flow
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Box} from '@material-ui/core';
import {useQueryParams} from 'use-query-params';
import {makeStyles} from '@material-ui/core/styles';
import {Carousel} from 'react-responsive-carousel';
import {palette} from 'app/styles/theme';
import Tooltip from 'common/componentsV2/Tooltip';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import DomainItemTooltip from 'topologyGeneral/components/tooltips/DomainItemTooltip';
import {getBadgeColor, MAP_ITEMS_COLOR_PALETTE} from 'topologyGeneral/services/mapService';
import {QUERY_PARAM_MAP} from 'topologyGeneral/services/sidePanelService';
import {getGtpMapDomains, isLoadingGtpMapRegionsSitesList} from 'topologyGeneral/store/selectors';
import './SidePanel.module.scss';

const CAROUSEL_SETTINGS = {
  showArrows: false,
  showIndicators: true,
  infiniteLoop: false,
  showThumbs: false,
  showStatus: false,
  emulateTouch: true,
  transitionTime: 400,
};

const useDomainItemStyles = makeStyles(() => ({
  root: {
    width: '63px',
    height: '61px',
    cursor: 'pointer',
    marginRight: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0,
  },
  levelGen: {
    height: '32px',
    width: '32px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 1,
    fontSize: '12px',
    fontWeight: 400,
    color: palette.white['500'],
  },
  title: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color: palette.gray['500'],
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  faded: {
    opacity: 0.3,
  },
}));

const DomainItem = ({item, isFaded, badgeColor, onClick}) => {
  if (!item) {
    return null;
  }

  const classes = useDomainItemStyles();

  const onClickLocale = () => {
    if (onClick) {
      onClick(item);
    }
  };

  return (
    <Tooltip content={<DomainItemTooltip item={item} />} placement="top" extraTtClass="domain-item-tt">
      <Box className={`${classes.root} ${isFaded ? classes.faded : ''}`} onClick={onClickLocale}>
        <Box className={`${classes.levelGen}`} bgcolor={badgeColor}>{`${item.percent}%`}</Box>
        <Box className={classes.title}>
          <Box component="span">{item.title}</Box>
        </Box>
      </Box>
    </Tooltip>
  );
};

DomainItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  isFaded: PropTypes.bool,
  badgeColor: PropTypes.string,
  onClick: PropTypes.func,
};

DomainItem.defaultProps = {
  isFaded: false,
  badgeColor: MAP_ITEMS_COLOR_PALETTE.green,
  onClick: null,
};

const DomainCarousel = () => {
  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAM_MAP);
  const domainItems = useSelector(getGtpMapDomains);
  const isSitesListLoading = useSelector(isLoadingGtpMapRegionsSitesList);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    setCurrentSlide(0);
  }, [queryParams.regionId]);

  useEffect(() => {
    const slidesBank = [];
    let slide = null;
    let curItem = 0;
    while (curItem < domainItems.length) {
      if (curItem % 5 === 0) {
        slide = [];
        slidesBank.push(slide);
      }

      slide.push({...domainItems[curItem]});
      curItem += 1;
    }
    setSlides(slidesBank);
  }, [domainItems, queryParams.regionId]);

  const onDomainItemClick = (item) => {
    setQueryParams({domainId: queryParams.domainId === item.id ? undefined : item.id});
  };

  const onSlideChange = (slideIndex) => {
    setCurrentSlide(slideIndex);
  };

  if (isSitesListLoading) {
    return (
      <Box
        styleName="domain-carousel"
        display="flex"
        flexDirection="column"
        width="100%"
        height={98}
        alignItems="center"
        justifyContent="center"
      >
        <Spinner color="#3d4c59" size={SIZES.BIG_60} />
      </Box>
    );
  }

  return (
    <Box styleName="domain-carousel" display="flex" flexDirection="column" width="100%" height={98}>
      <Box mb={2}>{`Technology (${domainItems.length})`}</Box>
      <Carousel
        {...CAROUSEL_SETTINGS}
        showIndicators={slides.length > 1}
        selectedItem={currentSlide}
        onChange={onSlideChange}
      >
        {slides.map((slide, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box height={61} width="100%" display="flex" key={`domain-slide-${index}`}>
            {(slide || []).map((innerItem) => (
              <DomainItem
                key={`domain-item-${innerItem.id}`}
                item={innerItem}
                isFaded={queryParams.domainId && queryParams.domainId !== innerItem.id}
                badgeColor={getBadgeColor(innerItem.percent)}
                onClick={onDomainItemClick}
              />
            ))}
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

DomainCarousel.propTypes = {
  // mapRef: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DomainCarousel;
