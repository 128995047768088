// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import DataTablePreviewModal from 'bc/components/streams/editor/common/DataTablePreviewModal';
import {get} from 'lodash';
import {fetchSqlTablePreview as fetchSqlTablePreviewAction} from 'bc/store/actions';

type PropTypes = {
  isOpen: boolean,
  onClose: Function,

  // connect
  dataStream: Object,
  fetchSqlTablePreview: Function,
  isLoading: Boolean,
  previewData: Object,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    previewData: selectors.getSqlTablePreviewData(state),
    isLoading: selectors.getSqlTablePreview(state).isLoading,
  }),
  {
    fetchSqlTablePreview: fetchSqlTablePreviewAction,
  },
)
export default class SqlFilePreview extends PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {
      dataStream: {dataSourceId, type, tableName, schemaName},
      previewData,
      isLoading,
      fetchSqlTablePreview,
    } = this.props;

    if (!previewData.rows && !isLoading) {
      fetchSqlTablePreview({
        type,
        dataSourceId,
        tableName,
        schemaName: schemaName || '',
      });
    }
  }

  render() {
    const {isOpen, onClose, dataStream, previewData, isLoading, fetchSqlTablePreview} = this.props;

    return (
      <DataTablePreviewModal
        isOpen={isOpen}
        onClose={onClose}
        title="Table Preview"
        subTitle={[get(dataStream, 'schemaName', ''), get(dataStream, 'tableName', '')].join('.')}
        previewData={previewData}
        isLoading={isLoading}
        fetchPreview={fetchSqlTablePreview}
        disabled="true"
      />
    );
  }
}
