// @flow
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {getNewDataManagerEnabled} from 'profile/store/selectors';
import DataManager from 'bc/pages/DataManager';
import DataManagerNew from 'bc/pages/DataManagerNew';

type PropTypes = {
  // connect
  isNewDataManagerEnabled: Boolean,
};

@connect(
  (state) => ({
    isNewDataManagerEnabled: getNewDataManagerEnabled(state),
  }),
  {},
)
export default class DataManagerRouter extends React.Component {
  props: PropTypes;

  render() {
    return (
      <Fragment>
        {this.props.isNewDataManagerEnabled ? <DataManagerNew {...this.props} /> : <DataManager {...this.props} />}
      </Fragment>
    );
  }
}
