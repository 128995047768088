import {combineEpics} from 'redux-observable';
import * as api from 'alerts.management/services/api';
import {getTimeScaleFromRollup} from 'alerts.management/services/durationAndScaleService';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as profileSelectors from 'profile/store/selectors';
import * as profileActions from 'profile/store/actions';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import {cloneDeep, unionWith, union} from 'lodash';
import {gotoState, getToState} from 'common/utils/angularServices';
import {success, warning, error} from 'common/utils/notifications/notificationsService';
import {getDataStreamsItems} from 'bc/store/selectors';
import {pollingIntervals} from 'bc/services/dataStreamService';
import {TYPE} from 'alerts.management/components/editor/simpleAlertForm/AlertNameEdit';
import {push} from 'connected-react-router';
import {alertConditionTypes} from 'alerts.management/services/alertsService';
import * as selectors from '../selectors';
import * as filterSelectors from '../filterSelector';
import * as actions from '../actions';

const EMPTY_OBJECT = {};

const fetchTriggeredAlertsByConfiguration = makeAsyncEpic(
  actions.fetchTriggeredAlertsByConfiguration,
  api.fetchTriggeredAlertsByConfiguration,
);

const fetchAlertConfigurations = makeAsyncEpic(actions.fetchAlertConfigurations, api.fetchAlertConfigurations);

const fetchAlertsConfigurationTotal = makeAsyncEpic(
  actions.fetchAlertsConfigurationTotal,
  api.fetchAlertsConfigurationTotal,
);

//* alert actions
const deleteAlert = makeAsyncEpic(actions.deleteAlert, api.deleteAlert);
const createAlert = makeAsyncEpic(actions.createAlert, api.createAlert);
const pauseAlert = makeAsyncEpic(actions.pauseAlert, api.pauseAlert);
const resumeAlert = makeAsyncEpic(actions.resumeAlert, api.resumeAlert);
const subscribeToAlert = makeAsyncEpic(actions.subscribeToAlert, api.subscribeToAlert);
const unsubscribeToAlert = makeAsyncEpic(actions.unsubscribeToAlert, api.unsubscribeToAlert);
const editAlertBulkApi = makeAsyncEpic(actions.editAlertBulkApi, api.editAlertBulkApi);
const deleteAlertBulkApi = makeAsyncEpic(actions.deleteAlertBulkApi, api.deleteAlertBulkApi);
const executeEstimation = makeAsyncEpic(actions.executeEstimation, api.executeEstimation);
const fetchDashboard = makeAsyncEpic(actions.fetchDashboard, api.fetchDashboard);
const fetchDataStreamMetricsCountApiCall = makeAsyncEpic(
  actions.fetchDataStreamMetricsCountApiCall,
  api.fetchDataStreamMetricsCountApiCall,
);
const getAlertsTags = makeAsyncEpic(actions.getAlertsTags, api.getAlertsTags);

// const getVolumeConditionSimple = makeAsyncEpic(actions.getVolumeConditionSimple, api.getVolumeCondition);

const deleteAlertSuccess = (action$, {getState}) =>
  action$.ofType(actions.deleteAlert.success.TYPE).flatMap(() => {
    const id = selectors.getDeleteAlertId(getState());
    return [actions.clearAlertFromList(id)];
  });

const fetchDataStreamMetricsCount = (action$, {getState}) =>
  action$.ofType(actions.fetchDataStreamMetricsCount.TYPE).flatMap(() => {
    const itemData = selectors.getAlertForSelectedId(getState()).data.expressionTreeModel;
    const callObj = {
      alias: itemData.alias,
      calculationDelay: itemData.calculationDelay,
      displayOnly: itemData.displayOnly,
      excludeComposites: itemData.excludeComposites,
      expressionTree: itemData.expressionTree,
      id: itemData.id,
      includeCubes: itemData.includeCubes,
      mtype: itemData.mtype,
      name: itemData.name,
      namingSchema: itemData.namingSchema,
      originId: itemData.originId,
      owner: itemData.owner,
      startBucketMode: itemData.startBucketMode,
      title: itemData.title,
      watermarkBased: itemData.watermarkBased,
    };
    return [actions.fetchDataStreamMetricsCountApiCall(callObj)];
  });

const fetchDataStreamMetricsCountSuccess = (action$, {getState}) =>
  action$.ofType(actions.fetchDataStreamMetricsCountApiCall.success.TYPE).flatMap(({payload}) => {
    const id = selectors.getSelectedItemId(getState());
    return [actions.setStreamMetricCountToAlert({id, size: payload.size})];
  });

const getPauseRequest = (time) => {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const hour = 60 * 60;
  const day = hour * 24;
  const week = day * 7;

  let setTimestamp = currentTimestamp;
  let setWillPause = false;
  switch (time) {
    case 'resume':
      setTimestamp = null;
      break;
    case 'hour':
      setTimestamp = currentTimestamp + hour;
      break;
    case 'day':
      setTimestamp = currentTimestamp + day;
      break;
    case 'week':
      setTimestamp = currentTimestamp + week;
      break;
    case 'indefinitely':
      setWillPause = true;
      break;
    default:
  }

  return {
    paused: setWillPause,
    pauseTime: setTimestamp,
  };
};

const deleteAlertBulk = (action$, {getState}) =>
  action$.ofType(actions.deleteAlertBulk.TYPE).flatMap(() => {
    const alerts = selectors.getSelectedAlertsCheckbox(getState());
    return [actions.deleteAlertBulkApi(alerts, alerts)];
  });

const editAlertBulk = (action$, {getState}) =>
  action$.ofType(actions.editAlertBulk.TYPE).flatMap(({payload}) => {
    const curState = getState();
    const alertItems = selectors.getAlertConfigurationsItems(curState);

    const checkIfTagBelongsToAlert = (id, value, field) => {
      const selectedAlert = alertItems.find((alert) => alert.data.id === id);
      if (!selectedAlert.data[field]) {
        return false;
      }
      if (field === 'alertTags') {
        return selectedAlert.data[field].findIndex((f) => f.name === value.label || f.name === value) > -1;
      }
      return selectedAlert.data[field].findIndex((f) => f === value.label || f === value.value) > -1;
    };

    const body = [];
    const metaBody = [];
    const selectedCheckboxes = selectors.getSelectedAlertsCheckbox(curState);

    selectedCheckboxes.forEach((checkboxId) => {
      const obj = {
        id: checkboxId,
        data: {},
      };

      if (payload.fieldName === 'paused' || payload.fieldName === 'resumed') {
        const request = getPauseRequest(payload.time);
        obj.data.paused = request.paused;
        obj.data.pauseTime = request.pauseTime;
      } else if (payload.fieldName === 'alertTags') {
        const data = {alertTags: []};
        payload.values.forEach((value) => {
          if (value.isAll || checkIfTagBelongsToAlert(checkboxId, value.label, 'alertTags')) {
            data.alertTags = [...data.alertTags, {name: value.label}];
          }
        });
        obj.data = data;
      } else if (payload.fieldName === 'recipients') {
        let subscribers = [];
        let channels = [];

        payload.values.forEach((value) => {
          if (value.field === 'subscribers') {
            if (value.isAll || checkIfTagBelongsToAlert(checkboxId, value, 'subscribers')) {
              subscribers = [...subscribers, value.value];
            }
          }
          if (value.field === 'channels') {
            if (value.isAll || checkIfTagBelongsToAlert(checkboxId, value, 'channels')) {
              channels = [...channels, value.value];
            }
          }
        });

        obj.data.subscribers = subscribers;
        obj.data.channels = channels;
      }
      if (payload.assignOwner) {
        const checkedAlert = alertItems.find((alert) => alert.data.id === checkboxId);
        const {data, ...tag} = checkedAlert;
        tag.owner = payload.assignOwner.email;
        tag.ownerId = payload.assignOwner.value;
        obj.data.ownerId = payload.assignOwner.value;
        obj.data.tag = JSON.stringify(tag);

        metaBody.push({
          id: checkboxId,
          data: {
            ownerId: payload.assignOwner.value,
          },
          tag,
        });
      }

      body.push(obj);
    });

    if (payload.assignOwner) {
      return [actions.editAlertBulkApi(body, {body: metaBody, fieldName: 'assignOwner'})];
    }

    return [actions.editAlertBulkApi(body, {body, fieldName: payload.fieldName})];
    // return [];
  });

const removeAlertTag = (action$, {getState}) =>
  action$.ofType(actions.removeAlertTag.TYPE).flatMap(({payload}) => {
    const selectedAlert = selectors.getAlertForSelectedId(getState());
    const selectedAlertTags = selectedAlert.data.alertTags;
    const newAlertTags = selectedAlertTags.filter((tag) => tag.name !== payload);

    const obj = {
      id: selectedAlert.data.id,
      data: {
        alertTags: newAlertTags.length ? newAlertTags : null,
      },
    };

    // return [];
    return [actions.editAlertBulkApi([obj], {body: [obj], fieldName: 'alertTags', noBulkScreen: true})];
  });

const editAlertBulkApiSuccess = (action$, {getState}) =>
  // eslint-disable-next-line complexity
  action$.ofType(actions.editAlertBulkApi.success.TYPE).flatMap(({payload, meta}) => {
    const failures = payload.filter((res) => res.success === false);
    const successes = payload.filter((res) => res.success === true);
    const alertConfigurationsItems = selectors.getAlertConfigurationsItems(getState());
    const failedAlerts = [];
    const failedAlertsDetails = [];
    let failedAlertsText = '';

    failures.forEach((failedAlertResponse) => {
      const alert = alertConfigurationsItems.find((item) => item.data.id === failedAlertResponse.id);
      failedAlerts.push({alert, failedAlertResponse});
      const errorObj = failedAlertResponse.eventValidationResult.compositeValidation.failures;
      let errorMess = '';
      if (Object.values(errorObj).length > 0) {
        errorMess = Object.values(errorObj)[0][0].message;
      }
      failedAlertsText += `${alert.data.title} - Error: ${errorMess}\n\n`;
      failedAlertsDetails.push({
        name: alert.data.title,
        errorMess,
      });
    });

    let title = '';
    let description = '';

    switch (meta.fieldName) {
      case 'alertTags':
        if (failures.length === 0) {
          title = 'Labels Modified Successfully';
          description = null;
        } else if (successes.length > 0) {
          title = 'Some of the Labels Could Not Be Modified';
          description = 'Something went wrong, Please try again later.';
        } else {
          title = 'Labels Could Not Be Modified';
          description = 'Something went wrong, Please try again later.';
        }
        break;
      case 'paused':
        if (failures.length === 0) {
          title = 'Alerts Paused Successfully';
          description = null;
        } else if (successes.length > 0) {
          title = 'Some of the Alerts Were Not Paused';
          description = 'Something went wrong, Please try again later.';
        } else {
          title = 'Could Not Pause the Alerts';
          description = 'Something went wrong, Please try again later.';
        }
        break;
      case 'resumed':
        if (failures.length === 0) {
          title = 'Alerts Resumed Successfully';
          description = null;
        } else if (successes.length > 0) {
          title = 'Some of the Alerts Could Not Be Resumed';
          description = 'Something went wrong, Please try again later.';
        } else {
          title = 'Could Not Resume the Alerts';
          description = 'Something went wrong, Please try again later.';
        }
        break;
      case 'recipients':
        if (failures.length === 0) {
          title = 'Recipients Modified Successfully';
          description = null;
        } else if (successes.length > 0) {
          title = 'Some of the Recipients Could Not Be Modified';
          description = 'Something went wrong, Please try again later.';
        } else {
          title = 'Recipients Could Not Be Modified';
          description = 'Something went wrong, Please try again later.';
        }
        break;
      case 'assignOwner':
        if (failures.length === 0) {
          title = 'Owner Modified Successfully';
          description = null;
        } else if (successes.length > 0) {
          title = 'Some of the Owners Could Not Be Modified';
          description = 'Something went wrong, Please try again later.';
        } else {
          title = 'Owners Could Not Be Modified';
          description = 'Something went wrong, Please try again later.';
        }
        break;
      default:
    }

    const notificationObj = {
      title,
      description,
      moreInfo: failedAlertsText,
      autoDismiss: 5,
      dismissible: true,
      isOpenInfoInModal: true,
      settings: {
        canClose: true,
        uid: 'bulkOperation',
      },
    };

    if (failures.length === 0) {
      return [success(notificationObj)];
    }
    if (successes.length > 0) {
      return [warning(notificationObj)];
    }
    return [error(notificationObj)];
  });

const deleteAlertBulkSuccess = (action$) =>
  action$.ofType(actions.deleteAlertBulkApi.success.TYPE).flatMap(() => {
    const notificationObj = {
      title: 'Alerts Deleted Successfully',
      autoDismiss: 5,
      dismissible: true,
      isOpenInfoInModal: true,
      settings: {
        canClose: true,
        uid: 'bulkDeleteOperation',
      },
    };
    return [success(notificationObj)];
  });

// eslint-disable-next-line max-len
const pauseResumeAlertStart = (action$, {getState}) =>
  action$.ofType(actions.pauseResumeAlertStart.TYPE).flatMap(({payload}) => {
    const alertConfigurationsItems = selectors.getAlertConfigurationsItems(getState());
    const userProfile = profileSelectors.getUserProfile(getState());
    const selectedItem = alertConfigurationsItems.find((item) => item.data.id === payload.id);

    const request = getPauseRequest(payload.time);
    const newState = {
      pausedBy: userProfile.email,
      pausedTime: request.pauseTime,
      pausedId: userProfile._id,
      data: {
        paused: request.paused,
        pauseTime: request.pauseTime,
      },
    };

    if (payload.action === 'pause') {
      return [actions.pauseAlert({...payload, newState, request}, selectedItem)];
    }
    return [actions.resumeAlert({...payload, newState, request}, selectedItem)];
  });

const subscribeUnsubscribeToAlertStart = (action$, {getState}) =>
  action$.ofType(actions.subscribeUnsubscribeToAlertStart.TYPE).flatMap(({payload}) => {
    const alertConfigurationsItems = selectors.getAlertConfigurationsItems(getState());
    const userProfile = profileSelectors.getUserProfile(getState());
    const selectedItem = alertConfigurationsItems.find((item) => item.data.id === payload.id);
    const subscribers = [...(selectedItem.data.subscribers || [])];

    if (payload.action === 'subscribe') {
      subscribers.push(userProfile._id);
    } else {
      const index = subscribers.findIndex((subscriber) => subscriber === userProfile._id);
      if (index > -1) {
        subscribers.splice(index, 1);
      }
    }

    const newState = {
      subscribers,
      data: {
        subscribers,
      },
    };

    if (payload.action === 'subscribe') {
      return [actions.subscribeToAlert({...payload, newState}, selectedItem)];
    }
    return [actions.unsubscribeToAlert({...payload, newState}, selectedItem)];
  });

const duplicateAlert = (action$, {getState}) =>
  action$.ofType(actions.duplicateAlert.TYPE).flatMap(() => {
    const curState = getState();
    const originAlert = selectors.getDuplicateAlertData(curState);
    const me = profileSelectors.getUserProfile(curState);
    const ownerId = me.defaultGroup || me._id;
    const owner = me.defaultGroup ? 'andt-group' : me.email;
    const tmpAlert = {
      owner,
      ownerId,
      subscribers: unionWith(
        [
          {
            email: me.email,
            muted: false,
          },
        ],
        originAlert.subscribers,
        (a, b) => a.email === b.email,
      ),
      data: cloneDeep(originAlert.data),
      extraEmails: cloneDeep(originAlert.extraEmails),
    };
    tmpAlert.data.id = null;
    tmpAlert.data.expressionTreeModel.id = null;
    tmpAlert.data.title = `${tmpAlert.data.title}-copy`;
    tmpAlert.data.expressionTreeModel.title = tmpAlert.data.title;
    tmpAlert.data.ownerId = ownerId;
    tmpAlert.data.subscribers = union([me._id], originAlert.data.subscribers);
    tmpAlert.data.connectedTiles = null;

    getToState('alerts.clone').data.alertDefinition = tmpAlert;
    getToState('alerts.clone').data.originHref = window.location.href;
    gotoState('alerts.clone', {
      id: originAlert.data.id,
    });
    return [];
  });

// eslint-disable-next-line max-len
const allAlertCheckboxClick = (action$, {getState}) =>
  action$.ofType(actions.allAlertCheckboxClick.TYPE).flatMap(({payload}) => {
    if (!payload) {
      return [actions.setCheckboxAll([])];
    }
    const list = selectors.getFilteredAlertManagerHeadersList(getState());
    const idsList = list.map((item) => item.id);
    return [actions.setCheckboxAll(idsList)];
  });

const updateUserSettingsFilterQuery = (action$, {getState}) =>
  action$.ofType(actions.setAlertFiltersView.TYPE).flatMap(({payload}) => {
    const currentAppSettings = profileSelectors.getMeAppSettings(getState());
    const changedFilters = payload === 'reset' ? EMPTY_OBJECT : filterSelectors.getChangedFilters(getState());

    const appSettings = {
      ...currentAppSettings,
      alertManager: {
        ...currentAppSettings.alertManager,
        changedFilters,
      },
    };

    return [profileActions.updateUserSettings(appSettings)];
  });

const calculateEstimatedRate = (action$, {getState}) =>
  action$.ofType(actions.calculateEstimatedRate.TYPE).flatMap(() => {
    const toDate = Math.floor(Date.now() / 1000);
    const fromDate = toDate - 60 * 60 * 24 * 7;
    const url = `fromDate=${fromDate}&index=0&size=1&startBucketMode=false&toDate=${toDate}`;
    const alertItems = selectors.getAlertConfigurationsItems(getState());
    const selectedAlert = alertItems.find((alert) => alert.data.id === selectors.getSelectedItemId(getState()));
    const autoVolumeEnabled = profileSelectors.getAutoVolumeEnabled(getState());
    let allowedConditions = selectedAlert.data.conditions;
    if (!autoVolumeEnabled) {
      // Feature Flag
      allowedConditions = selectedAlert.data.conditions.filter(
        (con) => con.type !== alertConditionTypes.volumeCondition,
      );
    }
    let type;
    if (selectedAlert.data.staticAlert) {
      type = 'static';
    } else if (!selectedAlert.data.anomalyAlert) {
      type = 'noData';
    } else {
      type = 'anomaly';
    }

    const bodyObj = {
      settings: {
        type,
        rollup: selectedAlert.data.rollups,
        aggregateAnomalies: true,
        conditions: allowedConditions,
      },
      query: {
        composite: {
          name: null,
          displayOnly: selectedAlert.data.expressionTreeModel.displayOnly,
          mtype: selectedAlert.data.expressionTreeModel.mtype,
          namingSchema: selectedAlert.data.expressionTreeModel.namingSchema,
          excludeComposites: selectedAlert.data.expressionTreeModel.excludeComposites,
          includeCubes: selectedAlert.data.expressionTreeModel.includeCubes,
          title: selectedAlert.data.expressionTreeModel.title,
          id: selectedAlert.data.expressionTreeModel.id,
          expressionTree: {
            ...selectedAlert.data.expressionTreeModel.expressionTree,
          },
          context: `alertId=${selectedAlert.data.id}.alertName=${selectedAlert.data.title}.context=alertManager`,
        },
      },
    };

    return [actions.executeEstimation({urlExt: url, body: bodyObj}, selectedAlert.data.id)];
  });

const createSimpleAlert = (action$, {getState}) =>
  action$.ofType(actions.createSimpleAlert.TYPE).flatMap(() => {
    const autoVolumeEnabled = profileSelectors.getAutoVolumeEnabled(getState());
    const alert = selectors.getSimpleAlertObject(getState());
    const currentVolumeCondition = selectors.getVolumeConditions(getState());
    const conditionsWithoutVolume = alert.conditions.filter((con) => con.type !== alertConditionTypes.volumeCondition);

    if (autoVolumeEnabled) {
      alert.conditions = [...conditionsWithoutVolume, currentVolumeCondition];
    }

    if (!autoVolumeEnabled) {
      alert.conditions = [...conditionsWithoutVolume];
    }

    return [
      actions.createAlert(
        {
          qs: {allowEmptyCompositeRes: true, origin: 'simple-alert'},
          body: alert.exportClientFormat(),
        },
        alert,
      ),
    ];
  });

const simpleAlertCreateAnother = (action$) =>
  action$
    .ofType(actions.simpleAlertCreateAnother.TYPE)
    .flatMap(() => [actions.createSimpleAlert(), actions.simpleAlertReset()]);

const createAlertSuccess = (action$, {getState, dispatch}) =>
  action$.ofType(actions.createAlert.success.TYPE).flatMap(({payload, meta}) => {
    const clickAction = (id) => {
      dispatch(actions.setAlertToScrollAndOpen(id));
      dispatch(actions.toggleSimpleAlertModal(false));
      actions.setAlertToScrollAndOpen(id);
      dispatch(
        push({
          pathname: '/alert-manager',
        }),
      );
    };
    if (payload && payload.event) {
      let alertTitle = payload.event.title.substr(0, 36);
      if (alertTitle.length < payload.event.title.length) {
        alertTitle = `${alertTitle}...`;
      }
      const description = `"${alertTitle}". You can see and edit your alert in Alert Management`;
      const notificationObj = {
        title: 'Alert created Successfully',
        description,
        autoDismiss: 5,
        dismissible: true,
        actions: [{label: 'See alert', callback: () => clickAction(payload.event.id)}],
        settings: {
          canClose: true,
        },
      };

      return [success(notificationObj)];
    }
    if (payload && payload.eventValidationResult && payload.eventValidationResult.alertValidation) {
      if (payload.eventValidationResult.alertValidation.failures[0].id === 6009) {
        const counter = selectors.getRetryNameCounter(getState());
        const alert = meta;
        if (counter > 1) {
          return [actions.openAlertNameModal({type: TYPE.DUPLICATE_NAME, alert})];
        }
        const newName = `Copy of ${alert.title}`;
        alert.title = newName;
        alert.expressionTreeModel.title = newName;
        return [
          actions.createAlert(
            {
              qs: {allowEmptyCompositeRes: true},
              body: alert.exportClientFormat(),
            },
            alert,
          ),
        ];
      }
    }
    return [];
  });

const simpleAlertGoAdvancedOptions = (action$, {getState}) =>
  action$.ofType(actions.simpleAlertGoAdvancedOptions.TYPE).flatMap(() => {
    const alert = selectors.getSimpleAlertObject(getState());
    if (alert) {
      getToState('alerts.edit').data.alertDefinition = alert.exportClientFormat();
    }

    getToState('alerts.edit').data.originHref = window.location.href;
    getToState('alerts.edit').data.isFromSimpleAlert = true;
    gotoState('alerts.edit', {
      id: alert ? alert.id : '',
    });
    return [actions.simpleAlertReset(), actions.toggleSimpleAlertModal(false)];
  });

const simpleAlertCalculateEstimatedRate = (action$, {getState}) =>
  action$.ofType(actions.simpleAlertCalculateEstimatedRate.TYPE).flatMap(() => {
    const url = 'fromDate=1&quickSimMode=true';
    const alert = selectors.getSimpleAlertObject(getState());
    const autoVolumeEnabled = profileSelectors.getAutoVolumeEnabled(getState());
    let allowedConditions = alert.conditions;
    if (!autoVolumeEnabled) {
      // Feature Flag
      allowedConditions = alert.conditions.filter((con) => con.type !== alertConditionTypes.volumeCondition);
    }
    if (!alert) {
      return [];
    }
    const bodyObj = {
      settings: {
        type: 'anomaly',
        rollup: alert.rollups,
        aggregateAnomalies: true,
        conditions: allowedConditions,
      },
      query: {
        composite: {
          name: null,
          displayOnly: alert.expressionTreeModel.displayOnly,
          mtype: alert.expressionTreeModel.mtype,
          namingSchema: alert.expressionTreeModel.namingSchema || 'COMPOSITE_V2',
          excludeComposites: alert.expressionTreeModel.excludeComposites,
          includeCubes: false,
          title: alert.title,
          id: alert.expressionTreeModel.id,
          expressionTree: alert.expressionTreeModel.expressionTree,
        },
      },
    };

    return [actions.executeEstimation({urlExt: url, body: bodyObj})];
  });

const onSimpleAlertSetFirstMeasure = (action$, {getState}) =>
  action$.ofType(actions.simpleAlertSetFirstMeasure.TYPE).flatMap(({payload}) => {
    const actionsToReturn = [];
    const curState = getState();
    const userProfile = profileSelectors.getUserProfile(curState);
    const selectedRecipients = selectors.getSimpleAlertRecipients(curState);
    const streams = getDataStreamsItems(curState);

    if (!selectedRecipients || !selectedRecipients.length) {
      actionsToReturn.push(
        actions.simpleAlertSetRecipients([
          {
            label: `${userProfile.firstName} ${userProfile.lastName}`,
            value: userProfile._id,
            type: 'USERS',
            icon: 'icn-users-grey-9-icon',
          },
        ]),
      );
    }

    if (streams && streams.length) {
      const streamFound = streams.find((stream) => stream.id === payload.streamId);
      if (streamFound) {
        const rollup = pollingIntervals[streamFound.pollingInterval].timeScale;
        const timeScale = getTimeScaleFromRollup(rollup);
        actionsToReturn.push(actions.simpleAlertSetTimeScale(timeScale, {rollup}));
      }
    }

    return actionsToReturn;
  });

const onSimpleAlertSetSecondMeasure = (action$, {getState}) =>
  action$.ofType(actions.simpleAlertSetSecondMeasure.TYPE).flatMap(({payload}) => {
    const curState = getState();
    const streams = getDataStreamsItems(curState);
    const selectedDurVal = selectors.getSimpleAlertSelectedDurationValue(curState);

    if (streams && streams.length) {
      const streamFound = streams.find((stream) => stream.id === payload.streamId);
      if (streamFound) {
        const rollupHierarchy = ['short', 'medium', 'long', 'longlong', 'weekly'];
        const rollup = pollingIntervals[streamFound.pollingInterval].timeScale;

        if (rollupHierarchy.indexOf(rollup) > rollupHierarchy.indexOf(selectedDurVal.rollup)) {
          const timeScale = getTimeScaleFromRollup(rollup);
          return [actions.simpleAlertSetTimeScale(timeScale, {rollup})];
        }
      }
    }
    return [];
  });

const fetchAlert = makeAsyncEpic(actions.fetchAlert, api.fetchAlert);
const updateAlert = makeAsyncEpic(actions.updateAlert, api.updateAlert);
const createNewAlert = makeAsyncEpic(actions.createNewAlert, api.createAlert);

export const showAlertSuccessMessage = (action$) =>
  action$.ofType(actions.showAlertSuccessMessage.TYPE).map(({payload}) => success(payload));

export const showAlertFailureMessage = (action$) =>
  action$.ofType(actions.showAlertFailureMessage.TYPE).map(({payload}) => error(payload));

const alertsConfigurationsEpic = combineEpics(
  fetchAlertConfigurations,
  fetchAlertsConfigurationTotal,
  fetchTriggeredAlertsByConfiguration,
  createAlert,
  deleteAlert,
  deleteAlertBulk,
  pauseAlert,
  resumeAlert,
  editAlertBulk,
  editAlertBulkApiSuccess,
  editAlertBulkApi,
  deleteAlertBulkApi,
  deleteAlertBulkSuccess,
  allAlertCheckboxClick,
  deleteAlertSuccess,
  duplicateAlert,
  subscribeToAlert,
  unsubscribeToAlert,
  pauseResumeAlertStart,
  subscribeUnsubscribeToAlertStart,
  updateUserSettingsFilterQuery,
  calculateEstimatedRate,
  executeEstimation,
  removeAlertTag,
  fetchDashboard,
  createSimpleAlert,
  simpleAlertCalculateEstimatedRate,
  simpleAlertGoAdvancedOptions,
  onSimpleAlertSetFirstMeasure,
  onSimpleAlertSetSecondMeasure,
  createAlertSuccess,
  fetchDataStreamMetricsCountApiCall,
  fetchDataStreamMetricsCountSuccess,
  fetchDataStreamMetricsCount,
  simpleAlertCreateAnother,
  fetchAlert,
  updateAlert,
  createNewAlert,
  showAlertSuccessMessage,
  showAlertFailureMessage,
  getAlertsTags,
);
export default alertsConfigurationsEpic;
