import React, {useMemo} from 'react';
import {useField} from 'react-final-form';
import SelectStandart from 'common/componentsV2/ddl/SelectStandart';
import {getCreateInitialConnection} from 'alerts.channels/store/selectors';
import {useSelector} from 'react-redux';
import {get} from 'lodash';

const validate = (value) => {
  return !value && 'Jira Issue Type is mandatory';
};

const JiraIssueType = () => {
  const {
    input: {value, onChange},
    // meta,
  } = useField('jiraIssueType', {validate});

  const {
    input: {value: selectedProjectId},
    // meta,
  } = useField('jiraProjectId');

  const projectsResponse = useSelector(getCreateInitialConnection);
  const options = useMemo(() => {
    const project = get(projectsResponse, 'data.projects', []).find((item) => item.id === selectedProjectId);
    if (project) {
      return project.issuetypes.map((item) => ({value: item.id, label: item.name}));
    }
    return [];
  }, [projectsResponse, selectedProjectId]);

  const selectedOption = useMemo(() => options.find((item) => item.value === value), [options, value]);

  return (
    <div>
      {/* eslint-disable-next-line */}
      <div>Jira Issue Type</div>
      <SelectStandart
        id="jiraIssueType"
        onChange={(item) => onChange(item.value)}
        value={selectedOption}
        options={options}
      />
    </div>
  );
};

export default JiraIssueType;
