// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setSelectedEditor} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import {isStreamEditable} from 'bc/services/dataStreamService';
import getEditorValidationMessage from 'bc/services/mParticleService';
import DimensionsAndMetrics from 'bc/components/streams/editor/common/DimensionsAndMetrics';

type PropTypes = {
  dataStream: Object,
  dimensions: Array,
  metrics: Array,
  isLoading: boolean,
  setSelectedEditor: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    dimensions: selectors.getMParticleStreamSelectedDimensions(state),
    metrics: selectors.getMParticleStreamSelectedMetrics(state),
    isLoading: selectors.getEventTypeMetadata(state).isLoading,
  }),
  {
    setSelectedEditor,
  },
)
export default class MParticleDimensionsAndMetrics extends React.PureComponent {
  props: PropTypes;

  setSelectedEditor = () => {
    this.props.setSelectedEditor({
      type: 'dm_mparticle',
      editorHeight: 'calc(100% - 210px)',
    });
  };

  render() {
    const {dataStream, dimensions, metrics, isLoading} = this.props;
    const isEditable = isStreamEditable(dataStream.state);

    return (
      <DimensionsAndMetrics
        dataStream={dataStream}
        dimensions={dimensions}
        metrics={metrics}
        isLoading={isLoading}
        isEditable={isEditable}
        dimensionColumnName="Dimension Fields"
        measureColumnsName="Measure Fields"
        validatorFunction={getEditorValidationMessage}
        onSelectEditor={() => this.setSelectedEditor()}
      />
    );
  }
}
