import * as actions from 'bc/store/actions';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';
import {get} from 'lodash';
import {getUniqueId} from 'common/utils/guid';
import {isEditRunning} from 'bc/services/dataStreamService';

const salesforceDataStreamReducer = composeReducers((state, {type, payload}) => {
  const getSelectedIndex = (id = state.selectedItemId) => state.streams.data.findIndex((item) => item.id === id);

  const red = (item, itemPayload) => ({...item, ...itemPayload});

  const redWrapper = (_payload, index = getSelectedIndex()) => ({
    ...state,
    streams: {
      ...state.streams,
      data: reduceArrayItem(red, state.streams.data, index, _payload || payload),
    },
  });

  // gets sources ids used by items recursively
  const getSourceIdsArr = (sourcesIdArr, item) => {
    if (item.sourceColumn) {
      if (!sourcesIdArr.includes(item.sourceColumn)) {
        sourcesIdArr.push(item.sourceColumn);
      }
    } else if (item.transform && item.transform.input) {
      item.transform.input.forEach((a) => {
        getSourceIdsArr(sourcesIdArr, a);
      });
    }
    return sourcesIdArr;
  };

  const getIsItemSourceMerged = (unifiedSchema, itemId, itemName) =>
    unifiedSchema.find(
      (a) =>
        ((a.sourceColumn && a.sourceColumn === itemId) ||
          (getSourceIdsArr([], a)[0] && getSourceIdsArr([], a)[0] === itemId)) &&
        a.name !== itemName &&
        a.isMerged,
    );

  const getOriginaByName = (itemName, schemaColumns) => {
    let itemFound = null;
    // eslint-disable-next-line
    for (const item of schemaColumns) {
      if (item.transform && item.transform.input) {
        if (
          getSourceIdsArr([], item)[0] &&
          getSourceIdsArr([], item)[0].name &&
          getSourceIdsArr([], item)[0].name === itemName
        ) {
          itemFound = item;
          break;
        }
      }
      if ((item.sourceColumn && item.sourceColumn === itemName) || item.name === itemName) {
        itemFound = item;
        break;
      }
    }
    return itemFound;
  };

  switch (type) {
    case actions.setSalesforceStreamKeyVal.TYPE: {
      const payloadInner = {...payload};
      return {...redWrapper(payloadInner), isStreamNeedUpdating: true};
    }

    case actions.setSalesforceObjectData.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      const selectedObject = stream.objects[0];
      const res = {
        customQuery: false,
        schema: {
          columns: [],
          sourceColumns: [],
        },
        metrics: [],
        dimensions: [],
        timeDefinition: null,
        timestampColumn: null,
        uiState: {
          eventMetadata: payload,
          unAssignedColumns: [],
          selectedRadio: 'table',
        },
      };

      // add the id for live stream
      if (stream.uiState && stream.uiState.id) {
        res.uiState.id = stream.uiState.id;
      }

      const unAssignedColumnsCur = get(stream, 'uiState.unAssignedColumns', []);
      const schemaColumnsCur = get(stream, 'schema.columns', []);
      const dimensionsCur = get(stream, 'dimensions', []);
      const metricsCur = get(stream, 'metrics', []);
      const isFieldsFill =
        (stream &&
          stream.uiState &&
          stream.uiState.eventMetadata &&
          stream.uiState.eventMetadata.fields &&
          stream.uiState.eventMetadata.fields.length) ||
        false;

      payload.fields.forEach((field) => {
        const fieldName = `${selectedObject}.${field.name}`;
        if (stream.timeDefinition && stream.timeDefinition.field && stream.timeDefinition.field === fieldName) {
          res.timeDefinition = stream.timeDefinition;
          res.timestampColumn = fieldName;
        } else if (dimensionsCur.includes(fieldName) || metricsCur.includes(fieldName)) {
          const itemFound = getOriginaByName(fieldName, schemaColumnsCur);
          if (itemFound !== null) {
            res.schema.sourceColumns.push({
              id: fieldName,
              name: fieldName,
            });
            res.schema.columns.push(itemFound);
            if (itemFound.type === 'dimension') {
              res.dimensions.push(fieldName);
            } else {
              res.metrics.push(fieldName);
            }
          }
        } else {
          // not in dimensions / metrics
          // eslint-disable-next-line no-lonely-if
          if (isEditRunning(stream.id) && !isFieldsFill) {
            res.uiState.unAssignedColumns.push(fieldName);
          } else if (unAssignedColumnsCur.includes(fieldName)) {
            res.uiState.unAssignedColumns.push(fieldName);
          } else if (field.possibleTypes.includes('dimension')) {
            res.dimensions.push(fieldName);
            res.schema.columns.push({
              id: getUniqueId(),
              sourceColumn: fieldName,
              name: fieldName,
              type: 'dimension',
            });
            res.schema.sourceColumns.push({
              id: fieldName,
              name: fieldName,
            });
          } else {
            res.uiState.unAssignedColumns.push(fieldName);
          }
        }
      });

      // get a list of all the current indexes
      const existingIndexArr = [...res.dimensions, ...res.metrics];

      // compare and copy the user created columns from the current schema
      if (stream.schema && stream.schema.columns) {
        stream.schema.columns.forEach((item) => {
          if (
            item.sourceColumn !== undefined ||
            (item.transform &&
              item.transform.input &&
              item.transform.input.length === 1 &&
              getSourceIdsArr([], item).length === 1)
          ) {
            const sourceColumn = item.sourceColumn || getSourceIdsArr([], item)[0];
            if (
              existingIndexArr.includes(sourceColumn) &&
              getIsItemSourceMerged(res.schema.columns, sourceColumn, item.name)
            ) {
              // user added columns that are a copy of existing column
              res.schema.columns.push({...item});
            }
          } else if (item.transform && item.transform.name === 'const') {
            // user added const column
            res.schema.columns.push({...item});
          } else if (item.transform && item.transform.input && item.transform.input.length > 1) {
            // user added concat columns
            let shouldPush = true;
            getSourceIdsArr([], item).forEach((id) => {
              if (!existingIndexArr.includes(id)) {
                shouldPush = false;
              }
            });
            if (shouldPush) {
              res.schema.columns.push({...item});
            }
          }
        });
      }

      return {...redWrapper(res), isStreamNeedUpdating: true};
    }

    case actions.setSalesforceStreamDiametricsChange.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      const streamModifications = {
        uiState: {...stream.uiState},
      };
      let allowed = true;

      const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
      };

      if (payload.source.droppableId === payload.destination.droppableId) {
        switch (payload.destination.droppableId) {
          case 'dmAllColumns': {
            streamModifications.uiState.unAssignedColumns = reorder(
              stream.uiState.unAssignedColumns,
              payload.source.index,
              payload.destination.index,
            );
            break;
          }
          case 'dmMetrics': {
            streamModifications.metrics = reorder(stream.metrics, payload.source.index, payload.destination.index);
            break;
          }
          case 'dmDimensions': {
            streamModifications.dimensions = reorder(
              stream.dimensions,
              payload.source.index,
              payload.destination.index,
            );
            break;
          }
          case 'dmDate': {
            break;
          }
          default:
            return state;
        }
      } else {
        switch (payload.destination.droppableId) {
          case 'dmAllColumns': {
            streamModifications.uiState.unAssignedColumns = [...stream.uiState.unAssignedColumns];
            streamModifications.uiState.unAssignedColumns.splice(payload.destination.index, 0, payload.draggableId);
            break;
          }
          case 'dmMetrics': {
            streamModifications.metrics = [...stream.metrics];
            streamModifications.metrics.splice(payload.destination.index, 0, payload.draggableId);
            break;
          }
          case 'dmDimensions': {
            streamModifications.dimensions = [...stream.dimensions];
            streamModifications.dimensions.splice(payload.destination.index, 0, payload.draggableId);
            break;
          }
          case 'dmDate': {
            let item;
            let itemName;
            const {pollingInterval} = stream.pollingInterval;
            const historicalDateRange = pollingInterval === 'daily' ? {constRange: 'h1'} : {constRange: 'd1'};
            if (stream.uiState.eventMetadata.fields) {
              const objName = stream.objects[0];
              item = stream.uiState.eventMetadata.fields.find((a) => `${objName}.${a.name}` === payload.draggableId);
              itemName = `${objName}.${item.name}`;
            } else {
              item = stream.uiState.eventMetadata.streamSchema.find((a) => a.path === payload.draggableId);
              itemName = item.path;
            }
            if (item.possibleTypes.find((a) => a === 'timestamp')) {
              if (stream.timestampColumn) {
                streamModifications.uiState.unAssignedColumns = [...stream.uiState.unAssignedColumns];
                streamModifications.uiState.unAssignedColumns.splice(0, 0, stream.timestampColumn);
              }
              streamModifications.timestampColumn = itemName;
              streamModifications.historicalDateRange = historicalDateRange;
              streamModifications.timeDefinition = item.timeDefinition;
              streamModifications.timeZone = stream.timeZone;
            } else {
              allowed = false;
            }
            break;
          }
          default:
            return state;
        }

        if (allowed) {
          switch (payload.source.droppableId) {
            case 'dmAllColumns': {
              streamModifications.uiState.unAssignedColumns = [
                ...(streamModifications.uiState.unAssignedColumns || stream.uiState.unAssignedColumns),
              ];
              streamModifications.uiState.unAssignedColumns.splice(
                streamModifications.uiState.unAssignedColumns.findIndex((a) => a === payload.draggableId),
                1,
              );
              break;
            }
            case 'dmMetrics': {
              streamModifications.metrics = [...stream.metrics];
              streamModifications.metrics.splice(
                streamModifications.metrics.findIndex((a) => a === payload.draggableId),
                1,
              );
              break;
            }
            case 'dmDimensions': {
              streamModifications.dimensions = [...stream.dimensions];
              streamModifications.dimensions.splice(
                streamModifications.dimensions.findIndex((a) => a === payload.draggableId),
                1,
              );
              break;
            }
            case 'dmDate': {
              streamModifications.timestampColumn = null;
              streamModifications.timeDefinition = null;
              break;
            }
            default:
              return state;
          }
        }
      }
      return redWrapper(streamModifications);
    }

    case actions.setSalesforceStreamClearAllDiametrics.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      const objName = stream.objects ? stream.objects[0] : '';
      const meta = stream.uiState.eventMetadata.fields || stream.uiState.eventMetadata.streamSchema;
      const mod = {
        metrics: [],
        dimensions: [],
        historicalDateRange: null,
        timestampColumn: null,
        timeDefinition: null,
        schema: {
          columns: [],
          sourceColumns: [],
        },
        uiState: {
          ...stream.uiState,
          unAssignedColumns: meta.map((a) => {
            if (a.name) {
              return `${objName}.${a.name}`;
            }
            return a.path;
          }),
        },
      };
      return redWrapper(mod);
    }

    case actions.removeSalesforceStreamDiametrics.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      let updateSchema = false;
      const mod = {};
      if (stream.metrics.indexOf(payload) >= 0) {
        updateSchema = true;
        mod.metrics = stream.metrics.filter((a) => a !== payload);
      } else if (stream.dimensions.indexOf(payload) >= 0) {
        updateSchema = true;
        mod.dimensions = stream.dimensions.filter((a) => a !== payload);
      } else if (stream.timestampColumn === payload) {
        updateSchema = true;
        mod.timestampColumn = null;
        mod.historicalDateRange = null;
        mod.timeDefinition = null;
      }
      mod.uiState = {
        ...stream.uiState,
        unAssignedColumns: [...stream.uiState.unAssignedColumns, payload],
      };
      if (updateSchema) {
        mod.schema = {
          columns: stream.schema.columns.filter((a) => {
            if (a.sourceColumn) {
              return a.sourceColumn !== payload;
            }
            return !getSourceIdsArr([], a).includes(payload);
          }),
          sourceColumns: stream.schema.sourceColumns.filter((a) => a.name !== payload),
        };
      }
      return redWrapper(mod);
    }

    default:
      return state;
  }
});

export default salesforceDataStreamReducer;
