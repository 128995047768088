// @flow
import React, {useState, useEffect, Fragment} from 'react';
import {useSelector} from 'react-redux';
import Script from 'react-load-script';
import {getMeOwnerOrganization} from 'profile/store/selectors';
import {loadStyleSheet} from 'common/utils/css';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import TopologyPage from 'topologyLeaflet/pages/TopologyPage';
import GeneralTopologyPage from 'topologyGeneral/pages/GeneralTopologyPage';
import './GeneralTopologyPage.module.scss';

const TopologyRouter = (props) => {
  const ownerOrganization = useSelector(getMeOwnerOrganization);
  const [d3Loaded, setD3Loaded] = useState(false);

  useEffect(() => {
    loadStyleSheet('leafletStyles', 'statics/leaflet/leaflet.css');
  }, []);

  const handleD3ScriptLoad = () => {
    setD3Loaded(true);
  };

  return (
    <Fragment>
      {!d3Loaded && <Script url="statics/leaflet/d3.min.js" onLoad={handleD3ScriptLoad} />}

      {d3Loaded ? (
        <Fragment>
          {ownerOrganization === '5e7b4b25443e1b000dccabcc' ? (
            <TopologyPage {...props} />
          ) : (
            <GeneralTopologyPage {...props} />
          )}
        </Fragment>
      ) : (
        <div styleName="spinner">
          <Spinner color="#3d4c59" size={SIZES.XX_BIG_100} />
        </div>
      )}
    </Fragment>
  );
};

export default TopologyRouter;
