const initialState = {
  isInUseDynamicRouting: false,
  selectedDimension: null,
  selectedRoutingFile: null,
};

export const setDynamicRoutingFromData = (dynamicRouting) => {
  if (dynamicRouting && dynamicRouting.metaId) {
    return {
      isInUseDynamicRouting: true,
      selectedDimension: dynamicRouting.dimensions[0].name || dynamicRouting.dimensionsNames[0],
      selectedRoutingFile: dynamicRouting.metaId,
    };
  }
  return initialState;
};

export const setDynamicRoutingFromUI = (dynamicRouting) => {
  if (dynamicRouting.isInUseDynamicRouting) {
    return {
      metaId: dynamicRouting.selectedRoutingFile,
      dimensions: [
        {
          name: dynamicRouting.selectedDimension,
        },
      ],
    };
  }
  return null;
};

export const TITLES = {
  TITLE: {
    label: 'title',
    type: 'string',
  },
  CREATION_DATE: {
    label: 'creationTime',
    type: 'number',
  },
  EDIT_DATE: {
    label: 'editTime',
    type: 'number',
  },
};

export const setDynamicRoutingValidation = (dynamicRouting) => {
  if (
    dynamicRouting.isInUseDynamicRouting &&
    !dynamicRouting.selectedDimension &&
    !dynamicRouting.selectedRoutingFile
  ) {
    return 'You must select Dimension and Routing table file.';
  }

  if (dynamicRouting.isInUseDynamicRouting && !dynamicRouting.selectedDimension) {
    return 'You must select Dimension.';
  }

  if (dynamicRouting.isInUseDynamicRouting && !dynamicRouting.selectedRoutingFile) {
    return 'You must select a routing table file.';
  }

  return null;
};

export const compareFunction = {
  number: {
    desc: (a, b) => b - a,
    asc: (a, b) => a - b,
  },
  string: {
    desc: (a, b) => {
      const nameA = a.toUpperCase();
      const nameB = b.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
    asc: (a, b) => {
      const nameA = a.toUpperCase();
      const nameB = b.toUpperCase();
      if (nameB < nameA) {
        return -1;
      }
      if (nameB > nameA) {
        return 1;
      }
      return 0;
    },
  },
};
