// @flow
import React, {Fragment} from 'react';
import {palette} from 'app/styles/theme';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {getFormattedDateMini} from 'common/utils/dateService';
import RadioButton from 'common/componentsV2/RadioButton';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 48,
    borderBottom: `1px solid ${palette.gray['200']}`,
    cursor: 'pointer',
  },
  radio: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeWrapper: {
    backgroundColor: '#ccc',
  },
}));

const RoutingModalTableItem = (props: {
  timeZone: String,
  id: String,
  onChange: Function,
  activeTable: Boolean,
  title: String,
  creationTime: String,
  editTime: String,
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid
        container
        onClick={() => props.onChange(props.id)}
        className={classes.wrapper}
        style={{backgroundColor: props.activeTable ? palette.blue['100'] : null}}
      >
        <Grid item sm={8}>
          <Grid container>
            <Grid item sm={1} className={classes.radio}>
              <RadioButton checked={props.activeTable} />
            </Grid>
            <Grid item sm={11}>
              <span className="text16reg lineHeight_16">{props.title}</span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={2}>
          <span className="text14reg lineHeight_14">{getFormattedDateMini(props.creationTime, props.timeZone)}</span>
        </Grid>
        <Grid item sm={2}>
          <span className="text14reg lineHeight_14">
            {!props.editTime ? 'Never' : getFormattedDateMini(props.editTime, props.timeZone)}
          </span>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default React.memo(RoutingModalTableItem);
