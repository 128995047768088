// @flow
import React from 'react';
import {TypographyBox} from 'common/componentsV2/boxTools';

type PropsType = {
  title: string,
};

const SubTitle = ({title}: PropsType) => (
  <TypographyBox variant="h5" pt={1} pb={3.5} borderTop={1} borderColor="gray.200">
    {title}
  </TypographyBox>
);

export default SubTitle;
