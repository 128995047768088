// @flow
import React, {PureComponent} from 'react';
import connect from 'react-redux/es/connect/connect';
import './CubeItemDetails.module.scss';
import SelectAndt, {THEME_BLUE, TYPE_SIMPLE, DIRECTION_LEFT} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import ConfirmationModal from 'common/components/modals/ConfirmationModal';
import Loading from 'metrics/components/Loading';
import {
  editCube as editCubeAction,
  deleteCube as deleteCubeAction,
  setExpandedCube as setExpandedCubeAction,
  setSelectedSchema as setSelectedSchemaAction,
  setSelectedCube as setSelectedCubeAction,
  duplicateCube as duplicateCubeAction,
  updateCubeDescription,
  fetchCubesCount as fetchCubesCountAction,
} from '../store/actions';
import * as selectors from '../store/selectors';

type PropTypes = {
  streamSchemaId: string,
  cubeDetails: any,
  isCubeExpanded: boolean,

  // connect
  editCube: Function,
  deleteCube: Function,
  setExpandedCube: Function,
  duplicateCube: Function,
  setSelectedSchema: Function,
  setSelectedCube: Function,
  updateCubeDescription: Function,
  fetchCubesCount: Function,
};

@connect(
  (state) => ({
    expandedCube: selectors.getExpandedCube(state),
  }),
  {
    editCube: editCubeAction,
    deleteCube: deleteCubeAction,
    setExpandedCube: setExpandedCubeAction,
    duplicateCube: duplicateCubeAction,
    setSelectedSchema: setSelectedSchemaAction,
    setSelectedCube: setSelectedCubeAction,
    updateCubeDescription,
    fetchCubesCount: fetchCubesCountAction,
  },
)
export default class CubeItemDetails extends PureComponent {
  props: PropTypes;

  constructor(props) {
    super(props);
    this.state = {
      descriptionValue: this.props.cubeDetails.description,
      isDeleteDialogOpen: false,
    };
  }

  actionItemSelected = (dd) => {
    const {editCube, duplicateCube} = this.props;
    switch (dd.value) {
      case 'edit':
        editCube(this.props);
        break;
      case 'delete':
        this.setState({isDeleteDialogOpen: true});
        break;
      case 'duplicate':
        duplicateCube(this.props);
        break;
      default:
    }
  };

  handleCloseDeleteDialog = () => {
    this.setState({isDeleteDialogOpen: false});
  };

  handleConfirmDelete = () => {
    const {deleteCube, streamSchemaId, cubeDetails} = this.props;
    deleteCube({
      schemaId: streamSchemaId,
      topLevelCubes: cubeDetails,
    });
  };

  handleDescriptionChange = (e) => {
    this.setState({descriptionValue: e.target.value});
  };

  updateDescription = () => {
    this.props.updateCubeDescription(this.state.descriptionValue);
  };

  handleOnClick = () => {
    const {
      setExpandedCube,
      setSelectedSchema,
      setSelectedCube,
      cubeDetails,
      streamSchemaId,
      fetchCubesCount,
    } = this.props;
    setExpandedCube(cubeDetails.id);
    setSelectedSchema(streamSchemaId);
    setSelectedCube(cubeDetails.id);

    if (!cubeDetails.uiState || isNaN(cubeDetails.uiState.numMetrics)) {
      fetchCubesCount({schemaId: streamSchemaId, topLevelCubes: cubeDetails});
    }
  };

  render() {
    const {cubeDetails, isCubeExpanded} = this.props;

    let numMetrics = null;
    if (isCubeExpanded) {
      numMetrics = cubeDetails.uiState.isLoadingNumMetrics ? (
        <Loading styleName="spinner" />
      ) : (
        <p styleName="big-number">{cubeDetails.uiState ? cubeDetails.uiState.numMetrics : '--'}</p>
      );
    }

    return (
      <div styleName={isCubeExpanded ? 'cube-details open' : 'cube-details'}>
        <div styleName="cube-revealed-header">
          <a href="#1" onClick={this.handleOnClick} styleName="cube-name">
            {cubeDetails.name}
          </a>
          <div styleName="cube-dropdown">
            <SelectAndt
              options={[
                {value: 'duplicate', label: 'Duplicate'},
                {value: 'edit', label: 'Edit'},
                {value: 'delete', label: 'Delete'},
              ]}
              type={TYPE_SIMPLE}
              theme={THEME_BLUE}
              onChange={this.actionItemSelected}
              direction={DIRECTION_LEFT}
              automationId="cubeItemDetails"
            />
          </div>
          <ConfirmationModal
            onClose={this.handleCloseDeleteDialog}
            onConfirm={this.handleConfirmDelete}
            title="Delete Cube"
            message={`Are you sure you want to delete cube ${cubeDetails.name}?`}
            isOpen={this.state.isDeleteDialogOpen}
          />
        </div>
        {isCubeExpanded ? (
          <div styleName="cube-all-details">
            <div styleName="column-1 container-column">
              <div>
                <p styleName="detail-title">Amount Of Metrics</p>
                {numMetrics}
                <p styleName="sub-title">In the last week</p>
                {/* </div>
              <div>
                <p styleName='detail-title'>Use Cases</p>
                <p>Alert</p>
                <p>Dashboard</p>
                <p>Anomalies</p> */}
              </div>
            </div>
            <div styleName="column-2 container-column">
              <div>
                <p styleName="detail-title">Metrics & Dimensions Summary</p>
                <p styleName="sub-title">Metric</p>
                <p>{cubeDetails.measure}</p>
              </div>
              <div>
                <p styleName="detail-title">List of Dimensions</p>
                {cubeDetails.dimensions.map((dimension) => (
                  <p key={`dim${dimension}`}>{dimension}</p>
                ))}
              </div>
            </div>

            <div styleName="column-3 container-column description">
              <p styleName="detail-title">Add Description</p>
              <textarea
                onBlur={this.updateDescription}
                onChange={this.handleDescriptionChange}
                value={this.state.descriptionValue}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
