import {createSelector} from 'reselect';
import {selectors as commonSelectors} from 'common';
import {getUsersData} from 'admin.users/store/selectors';
import {getQueryParamsViews} from 'alerts.console/store/selectors';
import {getSlackappEnabled} from 'profile/store/selectors';

const EMPTY_ARRAY = [];

const CHANNELS_AND_USER = () => ({
  WEBHOOK: {
    label: 'Webhook',
    type: 'WEBHOOK',
    data: [],
    icon: 'icn-webhook-grey-9-icon',
  },
  PAGER_DUTY: {
    label: 'Pager_Duty',
    type: 'PAGER_DUTY',
    data: [],
    icon: 'icn-pager_duty-grey-9-icon',
  },
  EMAIL: {
    label: 'Email',
    type: 'EMAIL',
    data: [],
    icon: 'icn-general16-mail',
  },
  SLACK: {
    label: 'Slack',
    type: 'SLACK',
    data: [],
    icon: 'icn-slack-grey-9-icon',
  },
  JIRA: {
    label: 'Jira',
    type: 'JIRA',
    data: [],
    icon: 'icn-slack-grey-9-icon',
  },
  USERS: {
    label: 'Users',
    type: 'USERS',
    data: [],
    icon: 'icn-users-grey-9-icon',
  },
  OPS_GENIE: {
    label: 'Opsgenie',
    type: 'OPS_GENIE',
    data: [],
    icon: 'icn-general16-placeholder',
  },
  MS_TEAMS: {
    label: 'MS Teams',
    type: 'MS_TEAMS',
    data: [],
    icon: 'icn-general16-placeholder',
  },
  TAMTAM: {
    label: 'Tam Tam',
    type: 'TAMTAM',
    data: [],
    icon: 'icn-general16-placeholder',
  },
  SNS: {
    label: 'SNS',
    type: 'SNS',
    data: [],
    icon: 'icn-general16-placeholder',
  },
  SLACKAPP: {
    label: 'Slack App',
    type: 'SLACKAPP',
    data: [],
    icon: 'icn-general16-placeholder',
  },
});

export const {getChannels} = commonSelectors;

export const getChannelsDataSection = createSelector(
  getChannels,
  (channels) => channels.data,
);

export const getFetchChannels = createSelector(
  getChannelsDataSection,
  (dataSection) => dataSection.fetchChannels,
);

export const getChannelsData = createSelector(
  getChannelsDataSection,
  (dataSection) => dataSection.fetchChannels.data || EMPTY_ARRAY,
);

export const getActiveChannels = createSelector(
  getChannelsData,
  (data) => data.filter((channel) => channel.state === 'ACTIVE'),
);

export const getPostChannel = createSelector(
  getChannelsDataSection,
  (dataSection) => dataSection.postChannel,
);

export const getTestChannel = createSelector(
  getChannelsDataSection,
  (dataSection) => dataSection.testChannel,
);

export const getPutChannel = createSelector(
  getChannelsDataSection,
  (dataSection) => dataSection.putChannel,
);

export const getCreateInitialConnection = createSelector(
  getChannelsDataSection,
  (dataSection) => dataSection.createInitialConnection,
);

export const getChannelsLoading = createSelector(
  getChannelsDataSection,
  (dataSection) => dataSection.fetchChannels.isLoading !== false,
);

export const getChannelsUpdatedAt = createSelector(
  getChannelsDataSection,
  (dataSection) => dataSection.fetchChannels.updateAt,
);

export const getChannelsAndUsers = createSelector(
  getUsersData,
  getChannelsData,
  (users = [], channels = []) => {
    const newUsersArr = [];
    const newChannelsArr = [];
    if (users.length) {
      users.forEach((i) => {
        if (i && i._id) {
          newUsersArr.push({
            label: `${i.firstName} ${i.lastName}`,
            value: i._id,
            type: 'USERS',
            email: i.email,
            icon: 'icn-users-grey-9-icon',
          });
        }
      });
    }

    if (channels && channels.length) {
      // eslint-disable-next-line complexity
      channels.forEach((i) => {
        let icon;
        if (!i) {
          icon = '';
          return;
        }
        switch (i.channelMeta.type) {
          case 'EMAIL':
            icon = 'icn-general16-mail';
            break;
          case 'SLACK':
            icon = 'icn-other16-slack';
            break;
          case 'WEBHOOK':
            icon = 'icn-webhook-grey-9-icon';
            break;
          case 'PAGER_DUTY':
            icon = 'icn-other16-pagerduty';
            break;
          case 'JIRA':
            icon = 'icn-other16-jira';
            break;
          case 'OPS_GENIE':
            icon = 'icn-other16-opsgenie';
            break;
          case 'MS_TEAMS':
            icon = 'icn-other16-opsgenie';
            break;
          case 'TAMTAM':
            icon = 'icn-other16-tamtam';
            break;
          case 'SNS':
            icon = 'icn-other16-sns';
            break;
          case 'SLACKAPP':
            icon = 'icn-other16-slack';
            break;
          default:
            break;
        }
        if (i && i.id && i.name && i.channelMeta.type && i.state === 'ACTIVE') {
          newChannelsArr.push({
            label: i.name,
            value: i.id,
            type: i.channelMeta.type,
            icon,
          });
        }
      });
    }

    return [...newUsersArr, ...newChannelsArr];
  },
);

export const getSortedFlattenChannels = createSelector(
  getChannelsAndUsers,
  getSlackappEnabled,
  (data, slackappChannelEnabled) => {
    const options = CHANNELS_AND_USER();

    const sortedFlattenArr = [];

    function sorting(arr) {
      return arr.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    }

    data.forEach((i) => {
      if (options[i.type]) {
        options[i.type].data.push(i);
      }
    });
    Object.values(options).forEach((i) => {
      if (i.data.length > 0 && (i.type !== 'SLACKAPP' || (i.type === 'SLACKAPP' && slackappChannelEnabled))) {
        sortedFlattenArr.push({
          label: i.label,
          value: i.type,
          type: 'HEADER',
          icon: i.icon,
          color: i.color,
        });
        sortedFlattenArr.push(...sorting(i.data));
      }
    });

    return sortedFlattenArr;
  },
);

export const getSelectedItems = createSelector(
  getSortedFlattenChannels,
  getQueryParamsViews,
  (channels, queryParams) => {
    const combineIds =
      (queryParams.channels ? queryParams.channels : '') +
      (queryParams.subscribers ? `,${queryParams.subscribers}` : '');

    const selectedItems = channels.filter((item) => combineIds.indexOf(item.value) !== -1);
    return selectedItems && selectedItems.length !== 0 ? selectedItems : null;
  },
);
