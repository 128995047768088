// @flow
import React, {Fragment} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button, {COLORS} from 'common/componentsV2/Button';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '10px 0 8px',
  },
  btnWrapper: {
    display: 'flex',
  },
  inputWrapper: {
    position: 'relative',
    overflow: 'hidden',
    height: 33,
  },
  input: {
    position: 'absolute',
    top: 6,
    left: 9,
    width: 116,
    height: 200,
    opacity: 0,
    filter: 'alpha(opacity=0)',
    fontSize: 300,
    cursor: 'pointer',
  },
  footerLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  footerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const RoutingModalFooter = (props: {
  onUploadFile: Function,
  onClose: Function,
  selectedTable: Object,
  onImplementTable: Function,
}) => {
  const classes = useStyles();

  const goTo = () => {
    window.open('https://support.anodot.com/hc/en-us/articles/360017101440');
  };

  return (
    <Fragment>
      <Grid container className={classes.wrapper}>
        <Grid item sm={7} className={classes.footerLeft}>
          <Box className={classes.btnWrapper} mr={0.5}>
            <Button
              text="Demo file & Instructions"
              onClick={goTo}
              colorSchema={COLORS.TRANSPARENT}
              icon="icn-action16-download"
            />
          </Box>
          <Box className={classes.btnWrapper}>
            <Box className={classes.inputWrapper}>
              <Button text="Upload new" colorSchema={COLORS.TRANSPARENT} icon="icn-action16-upload" />
              <input onChange={props.onUploadFile} type="file" name="file" className={classes.input} placeholder="" />
            </Box>
          </Box>
        </Grid>
        <Grid item sm={5} className={classes.footerRight}>
          <Box>
            <Button text="Cancel" colorSchema={COLORS.GRAY_300} onClick={props.onClose} />
          </Box>
          <Box component="span" ml={1.5}>
            <Button
              text="Select Table"
              colorSchema={COLORS.BLUE_500}
              onClick={props.onImplementTable}
              isDisabled={!props.selectedTable}
            />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default React.memo(RoutingModalFooter);
