import React from 'react';
import {useField} from 'react-final-form';
import Input from 'common/componentsV2/Input';
import TooltipArea from 'common/componentsV2/TooltipArea';
import AutocompleteSelection from 'common/componentsV2/autocompleteSelection/AutocompleteSelection';

const AlertName = ({alertTitleInputRef}: {alertTitleInputRef: Object}) => {
  const {
    input: {value, onChange},
    meta,
  } = useField('title');

  return (
    <TooltipArea automationId="alertNameContainer" text="Set a name for this alert">
      {(info) => (
        <React.Fragment>
          <div className="text16reg lineHeight_16 mb_1">
            Alert name
            {info}
          </div>
          <AutocompleteSelection onChange={onChange} inputValue={value} automationId="alertNameAutocomplete">
            {(handleChange, handleKeyDown) => (
              <Input
                automationId="alertTitle"
                additionalProps={{name: 'title', ref: alertTitleInputRef, autoComplete: 'off'}}
                isInvalid={meta.touched && meta.error}
                invalidMessage={meta.error}
                value={value}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            )}
          </AutocompleteSelection>
        </React.Fragment>
      )}
    </TooltipArea>
  );
};

export default AlertName;
