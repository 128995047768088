// @flow
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';
import {get} from 'lodash';
import * as selectors from 'bc/store/selectors';
import {createAlertsFromStream} from 'bc/store/actions';
import MultiSelectFormDdl from 'common/componentsV2/ddl/multiSelectFormDdl/MultiSelectFormDdl';
import Checkbox from 'common/componentsV2/Checkbox';
import Button, {COLORS} from 'common/componentsV2/Button';
import './AlertsFromStreamModal.module.scss';

const EMPTY_ARRAY = [];

type PropTypes = {
  isOpen: boolean,
  onClose: Function,
  onCreateClicked: Function,
  schemaColumnsMetrics: Array,
  schemaColumnsDimensions: Array,
  createAlertsFromStream: Function,
};

@connect(
  (state) => ({
    schemaColumnsMetrics: selectors.getSelectedDataStreamSchemaColumnsMetrics(state),
    schemaColumnsDimensions: selectors.getSelectedDataStreamSchemaColumnsDimensions(state),
  }),
  {
    createAlertsFromStream,
  },
)
export default class AlertsFromStreamModal extends React.PureComponent {
  props: PropTypes;

  state = {
    selectedItems: EMPTY_ARRAY,
  };

  handleClose = () => {
    this.props.onClose();
  };

  onMultiSelect = (res, identifier) => {
    this.setState((prevState) => {
      const newSelectedItems = prevState.selectedItems.map((item) => {
        if (item.metricId === identifier) {
          return {
            metricId: identifier,
            dimensions: res,
          };
        }
        return item;
      });

      return {selectedItems: newSelectedItems};
    });
  };

  onMeasureSelect = (res) => {
    const {selectedItems} = this.state;

    let newCheckedList = null;
    if (res.isSelected) {
      newCheckedList = [...selectedItems, {metricId: res.value}];
    } else {
      newCheckedList = selectedItems.filter((item) => item.metricId !== res.value);
    }

    this.setState({
      selectedItems: newCheckedList,
    });
  };

  onCreateStream = () => {
    this.props.createAlertsFromStream({
      selectedItems: this.state.selectedItems,
    });
    if (this.props.onCreateClicked) {
      this.props.onCreateClicked();
    }
  };

  getAlertsCountMessage = () => {
    const {selectedItems} = this.state;

    if (!selectedItems.length) {
      return <span>No Alerts</span>;
    }
    if (selectedItems.length === 1) {
      return <span>1 Alert</span>;
    }
    return <span>{`${selectedItems.length} Alerts`}</span>;
  };

  render() {
    const {isOpen, onClose, schemaColumnsDimensions, schemaColumnsMetrics} = this.props;
    const {selectedItems} = this.state;

    return (
      <Fragment>
        <Modal id="measure-dimensions-modal" show={isOpen} dialogClassName="bc" onHide={onClose} bsSize="large">
          <Modal.Header bsClass="bc-modal-header">
            <Modal.Title>
              <span>Create Alerts from this Stream</span>
              <div styleName="description">
                <span styleName="header">Check the measures you want to be alerted on.</span>
                <br />
                <span styleName="header">Select up to 3 dimensions per measure.</span>
                <br />
                <span styleName="header">You can edit these alerts later.</span>
              </div>
            </Modal.Title>
            <button
              className="btn btn-flat btn-icon-36 btn-secondary"
              type="button"
              onClick={() => this.handleClose(false)}
            >
              <i className="icon icn-general16-closeb" styleName="new-close" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div styleName="alerts-name-header">
              <span styleName="header">Alerts Name</span>
            </div>
            <div styleName="alerts-name-wrapper">
              <span>{'Anomaly in {{measure_name}} by {{dimension_name}} from {{stream_name}}'}</span>
            </div>

            <div styleName="measure-dimension-section">
              <div styleName="row">
                <div styleName="col col-measure">
                  <span styleName="header">Create alert for:</span>
                </div>
                <div styleName="col">
                  <span styleName="header">By:</span>
                </div>
              </div>

              {schemaColumnsMetrics.map((item, index) => (
                <MeasureSelectItem
                  measure={item}
                  key={item.id}
                  dimensions={schemaColumnsDimensions}
                  selectedDimensions={get(
                    selectedItems.find((selectedItem) => selectedItem.metricId === item.id),
                    'dimensions',
                    EMPTY_ARRAY,
                  )}
                  onDimensionSelect={this.onMultiSelect}
                  onMeasureSelect={this.onMeasureSelect}
                  isEven={index % 2 === 0}
                  isSelected={selectedItems.find((selectedItem) => selectedItem.metricId === item.id)}
                  appendToElementById="measure-dimensions-modal"
                />
              ))}
            </div>

            <div styleName="actions-footer-section">
              <Button
                colorSchema={COLORS.BLUE_500}
                text="Create"
                isDisabled={!selectedItems.length}
                onClick={this.onCreateStream}
              />
              <div styleName="alerts-count">
                <span>{this.getAlertsCountMessage()}</span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

const MeasureSelectItem = (props: {
  measure: Object,
  dimensions: Array,
  selectedDimensions: Array,
  onDimensionSelect: Function,
  onMeasureSelect: Function,
  isSelected: boolean,
  isEven: boolean,
  appendToElementById: string,
}) => {
  const onMeasureClicked = () => {
    props.onMeasureSelect({
      value: props.measure.id,
      isSelected: !props.isSelected,
    });
  };

  return (
    <div styleName={['row', props.isEven ? 'even' : ''].join(' ')}>
      <Checkbox
        isChecked={props.isSelected}
        styleName="col col-measure pad-left-8"
        text={props.measure.name}
        onChange={onMeasureClicked}
      />
      <div styleName="col">
        {props.isSelected && (
          <MultiSelectFormDdl
            options={props.dimensions}
            componentId={props.measure.id}
            onSelect={props.onDimensionSelect}
            placeholder="Select up to 3 Dimensions"
            selectedOptions={props.selectedDimensions}
            maxSelectedItems={3}
            optionValueKey="id"
            optionLabelKey="name"
            appendToElementById={props.appendToElementById}
          />
        )}
      </div>
    </div>
  );
};
