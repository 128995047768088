// @flow
import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import Highcharts from 'highcharts';
import {isString} from 'lodash';
import fastdom from 'fastdom';
import TimeSeriesChart from 'charts/timeSeries/components/TimeSeriesChart';
import UserEventsTooltipTemplate from 'charts/timeSeries/components/UserEventsTooltipTemplate';
import {
  EVENT_PLOT_LINE_COLOR,
  EVENTS_PLOT_LINE_ID,
  getPlotLineOptions,
} from 'charts/timeSeries/services/timeSeriesEventService';
import {addPlotLine, removePlotLine} from 'charts/timeSeries/services/timeSeriesHchartService';
import './UserEvents.module.scss';

type PropTypes = {
  disableActions: boolean,
  id: string, // required
  eventsMeta: Object,
  tooltipConditions: Object,
  height: Number,
  index: Number,
  enableClientZoom: boolean,
  theme: Object,
  tooltip: Object,
  bucketStartTimeEnabled: boolean,
  timeScale: string,
  timeZoneName: string,
  events: Array,
  shouldShowEvents: boolean,
  eventsResolution: string,
  selectedEventId: string,
  chartContainerStyle: Object,

  onRangeSelection: Function,
  onMouseMove: Function,
  onMouseLeave: Function,
  onEventClick: Function,
};

const TimeSeriesWithEventsChart = ({
  disableActions,
  id,
  eventsMeta,
  tooltipConditions,
  height,
  index,
  enableClientZoom,
  theme,
  tooltip,
  bucketStartTimeEnabled,
  timeScale,
  timeZoneName,
  events,
  shouldShowEvents,
  eventsResolution,
  selectedEventId,
  chartContainerStyle,
  onRangeSelection,
  onMouseMove,
  onMouseLeave,
  onEventClick,
}: PropTypes) => {
  const [hoveredEvent, setHoveredEvent] = useState(null);

  let tooltipElm = document.getElementsByClassName('anch-chart-events-tooltip');
  if (!tooltipElm.length) {
    tooltipElm = document.createElement('div');
    tooltipElm.className = 'anch-chart-events-tooltip';
    document.body.appendChild(tooltipElm);
  } else {
    // eslint-disable-next-line prefer-destructuring
    tooltipElm = tooltipElm[0];
  }

  useEffect(() => {
    const hchart = Highcharts.charts.find((chart) => chart && chart.renderTo.id === eventsMeta.chartId);

    if (shouldShowEvents) {
      removePlotLine(hchart, EVENTS_PLOT_LINE_ID, 'x');
      events.forEach((event) => {
        const plotLineOptions = getPlotLineOptions(event, event.id, selectedEventId === event.id);

        addPlotLine(hchart, plotLineOptions, 'x');
      });
    } else {
      removePlotLine(hchart, EVENTS_PLOT_LINE_ID, 'x');
    }
  }, [selectedEventId, shouldShowEvents]);

  const onChartClick = (e) => {
    const eventLabel = (e.path || e.composedPath()).find(
      (i) => i.className && isString(i.className) && i.className.indexOf('andt-user-events-icon') !== -1,
    );

    if (eventLabel) {
      if (onEventClick) {
        const clsName = eventLabel.attributes.getNamedItem('data-plot-line-id').value;
        const findEvent = events.find((ev) => ev.id === clsName);
        onEventClick(findEvent, e);
        ReactDOM.unmountComponentAtNode(tooltipElm);
      }
    }
  };

  const mouseLeaveHandler = (e) => {
    ReactDOM.unmountComponentAtNode(tooltipElm);

    if (onMouseLeave) {
      onMouseLeave(e);
    }
  };

  const mouseMoveHandler = (chartMouseData, chartId, e) => {
    const eventLabel = (e.path || e.composedPath()).find(
      (i) => i.className && isString(i.className) && i.className.indexOf('andt-user-events-icon') !== -1,
    );

    const clearEventPlotLinesHover = () => {
      const userEventPlotLines = document.getElementById(chartId).getElementsByClassName(EVENTS_PLOT_LINE_ID);
      fastdom.mutate(() => {
        // eslint-disable-next-line
        for (const ev of userEventPlotLines) {
          ev.setAttribute('stroke', EVENT_PLOT_LINE_COLOR.normal);
        }
      });
    };

    const positionTooltip = () => {
      if (tooltipElm) {
        let tooltipTop = 0;
        let tooltipLeft = 0;

        fastdom.measure(() => {
          const labelBounds = eventLabel.getBoundingClientRect();
          const tooltipBounds = tooltipElm.getBoundingClientRect();
          tooltipTop = `${labelBounds.y - tooltipBounds.height - 5}px`;
          tooltipLeft = `${labelBounds.x + labelBounds.width / 2 - tooltipBounds.width / 2}px`;
        });
        fastdom.mutate(() => {
          const {style} = tooltipElm;
          style.top = tooltipTop;
          style.left = tooltipLeft;
        });
      }
    };

    if (eventLabel) {
      clearEventPlotLinesHover();
      const clsName = eventLabel.attributes.getNamedItem('data-plot-line-id').value;
      fastdom.mutate(() => {
        document
          .getElementById(chartId)
          .getElementsByClassName(clsName)[0]
          .setAttribute('stroke', EVENT_PLOT_LINE_COLOR.hover);
      });

      if (!hoveredEvent || hoveredEvent.id !== clsName) {
        const findEvent = events.find((event) => event.id === clsName);
        if (findEvent) {
          ReactDOM.render(
            <UserEventsTooltipTemplate event={findEvent} resolution={eventsResolution} timeZoneName={timeZoneName} />,
            tooltipElm,
          );
          positionTooltip();
        } else {
          ReactDOM.unmountComponentAtNode(tooltipElm);
        }
        setHoveredEvent(findEvent || null);
      }
    } else {
      if (hoveredEvent) {
        setHoveredEvent(null);
        ReactDOM.unmountComponentAtNode(tooltipElm);
      }
      clearEventPlotLinesHover();
    }

    if (onMouseMove) {
      onMouseMove(chartMouseData, chartId);
    }
  };

  return (
    <React.Fragment>
      <TimeSeriesChart
        disableActions={disableActions}
        id={id}
        eventsMeta={eventsMeta}
        tooltipConditions={tooltipConditions}
        height={height}
        index={index}
        enableClientZoom={enableClientZoom}
        theme={theme}
        tooltip={tooltip}
        bucketStartTimeEnabled={bucketStartTimeEnabled}
        timeScale={timeScale}
        timeZoneName={timeZoneName}
        onClick={onChartClick}
        onMouseMove={mouseMoveHandler}
        onMouseLeave={mouseLeaveHandler}
        onRangeSelection={onRangeSelection}
        chartContainerStyle={chartContainerStyle}
      />
    </React.Fragment>
  );
};

export default React.memo(TimeSeriesWithEventsChart);
