// @flow
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Form} from 'react-final-form';
import {
  getCustomerDefaultSettings,
  getEditCustomerData,
  getIsLoadingCustomerData,
  getIsLoadingDefaultSettings,
  getIsSubmittingCustomerData,
} from 'admin.customers/store/selectors';
import {
  createCustomer,
  fetchCustomerDefaultSettings,
  fetchEditCustomerData,
  updateCustomer,
} from 'admin.customers/store/actions';
import AsyncButton from 'common/componentsV2/AsyncButton';
import Button, {COLORS, WIDTHS} from 'common/componentsV2/Button';
import Spinner, {SIZES as SpinnerSizes} from 'common/componentsV2/Spinner';
import {isEmpty} from 'lodash';
import {NEW_CUSTOMER_TEMPLATE} from 'admin.customers/services/customersService';
import EditCustomerFormContent from './EditCustomerFormContent';
import './EditCustomerForm.module.scss';

const EMAIL_VALIDATION_REGEX = /.+@.+\..+/;

type PropTypes = {
  onClose: Function,
};
const DAY = 60 * 60 * 24; // seconds in 1 day

const EditCustomerForm = ({onClose}: PropTypes) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoadingCustomerData);
  const isLoadingDefaultSettings = useSelector(getIsLoadingDefaultSettings);
  const isSubmitting = useSelector(getIsSubmittingCustomerData);
  const {id: updatedCustomerId} = useParams();
  const updatedCustomer = useSelector(getEditCustomerData);
  const defaultSettings = useSelector(getCustomerDefaultSettings);

  const isNew = updatedCustomerId === 'new';
  const onRestoreDefaults = useCallback(
    (form) => {
      form.change('customer.numberOfMetrics', NEW_CUSTOMER_TEMPLATE.customer.numberOfMetrics);
      form.change('customer.serverConfiguration.maxMetricsAllowed', defaultSettings.maxMetricsAllowed);
      form.change('customer.serverConfiguration.retentions', defaultSettings.retentions);
      form.change('customer.serverConfiguration.anomalyRetentions', defaultSettings.anomalyRetentions);
      form.change('customer.serverConfiguration.compositeCalculationDelay', defaultSettings.compositeCalculationDelay);
      form.change(
        'customer.serverConfiguration.maxSamplesPerMinuteAllowed',
        defaultSettings.maxSamplesPerMinuteAllowed,
      );
      form.change(
        'customer.serverConfiguration.noDataMinCompositeDuration',
        defaultSettings.noDataMinCompositeDuration,
      );
      form.change('customer.serverConfiguration.noDataMinDuration', defaultSettings.noDataMinDuration);
      form.change('customer.serverConfiguration.compositeMaxMetrics', defaultSettings.compositeMaxMetrics);
      form.change(
        'customer.serverConfiguration.compositeMaxMetricsDisplay',
        defaultSettings.compositeMaxMetricsDisplay,
      );
      form.change('customer.serverConfiguration.tz', defaultSettings.tz);
      form.change('customer.serverConfiguration.firstDayOfWeek', defaultSettings.firstDayOfWeek);
      form.change('customer.serverConfiguration.rollups', defaultSettings.rollups);
      form.change('customer.serverConfiguration.userEventRetention', defaultSettings.userEventRetention);

      form.change('customer.settings.bc.enabled', NEW_CUSTOMER_TEMPLATE.customer.settings.bc.enabled);
      form.change('customer.settings.apiTokens.enabled', NEW_CUSTOMER_TEMPLATE.customer.settings.apiTokens.enabled);
      form.change('customer.settings.payingCustomer', NEW_CUSTOMER_TEMPLATE.customer.settings.payingCustomer);
      form.change('customer.settings.sierra.selfService', NEW_CUSTOMER_TEMPLATE.customer.settings.sierra.selfService);
      form.change(
        'customer.settings.sierra.selfServicePackage',
        NEW_CUSTOMER_TEMPLATE.customer.settings.sierra.selfServicePackage,
      );
      form.change(
        'customer.settings.auth.oauth.google.enable',
        NEW_CUSTOMER_TEMPLATE.customer.settings.auth.oauth.google.enable,
      );
    },
    [defaultSettings],
  );

  useEffect(() => {
    dispatch(fetchCustomerDefaultSettings());
    if (!isNew) {
      dispatch(fetchEditCustomerData({customerId: updatedCustomerId}));
    }
  }, [updatedCustomerId]);

  const formValidation = useCallback(
    (values) => {
      const errors = {customer: {}, admin: {}};
      // Do not validate before data is loaded
      if (!isNew && isEmpty(values.customer)) return errors;
      if (!values.customer.name) {
        errors.customer.name = 'Required';
      }
      if (isNew && !values.customer.dnsPrefix) {
        errors.customer.dnsPrefix = 'Required';
      }
      if (
        values.customer.dnsPrefix &&
        (['in', 'ap', 'app'].indexOf(values.customer.dnsPrefix) !== -1 || values.customer.dnsPrefix.startsWith('app-'))
      ) {
        errors.customer.dnsPrefix = 'DNS Prefix is not valid';
      }
      if (
        values.customer.settings.sierra &&
        values.customer.settings.sierra.selfService &&
        !values.customer.settings.sierra.selfServicePackage
      ) {
        errors.customer.settings = {sierra: {selfServicePackage: 'Required'}};
      }
      if (
        parseInt(values.customer.numberOfMetrics, 10) >
        parseInt(values.customer.serverConfiguration.maxMetricsAllowed, 10)
      ) {
        errors.customer.numberOfMetrics = 'Number Of Metrics cannot exceed Metrics Top Limit';
      }
      if (parseInt(values.customer.serverConfiguration.maxSamplesPerMinuteAllowed, 10) > 40000 * 60) {
        errors.customer.serverConfiguration = {
          maxSamplesPerMinuteAllowed: '40,000 samples per sec is max value allowed',
        };
      }
      if (parseInt(values.customer.serverConfiguration.userEventRetention, 10) > 7300 * DAY) {
        errors.customer.serverConfiguration = {
          userEventRetention: '7300 days is the maximal value allowed',
        };
      }
      if (isNew) {
        if (!values.admin.firstName) {
          errors.admin.firstName = 'Required';
        }
        if (!values.admin.lastName) {
          errors.admin.lastName = 'Required';
        }
        if (!values.admin.email) {
          errors.admin.email = 'Required';
        } else if (!EMAIL_VALIDATION_REGEX.test(values.admin.email)) {
          errors.admin.email = 'A valid email is required.';
        }
        if (!values.admin.confirmEmail) {
          errors.admin.confirmEmail = 'Required';
        }
        if (values.admin.email && values.admin.confirmEmail && values.admin.email !== values.admin.confirmEmail) {
          errors.admin.confirmEmail = 'Emails do not match';
        }
      }
      return errors;
    },
    [isNew],
  );

  const onSubmit = useCallback(
    (payload) => {
      if (isNew) {
        dispatch(createCustomer({customer: payload.customer, admin: payload.admin}));
      } else {
        dispatch(updateCustomer({customer: payload.customer}));
      }
    },
    [isNew],
  );

  if (isLoading || isLoadingDefaultSettings) {
    return (
      <div styleName="spinner-wrapper">
        <Spinner color="#3d4c59" size={SpinnerSizes.XX_BIG_150} />
      </div>
    );
  }

  return (
    <Form
      initialValues={{
        isNew,
        customer: isNew ? {...NEW_CUSTOMER_TEMPLATE.customer, serverConfiguration: defaultSettings} : updatedCustomer,
        admin: isNew ? NEW_CUSTOMER_TEMPLATE.admin : null,
      }}
      onSubmit={onSubmit}
      validate={formValidation}
      keepDirtyOnReinitialize // Keep values after submit
      initialValuesEqual={() => true} // In order to update form after API call changes updatedCustomer
    >
      {({handleSubmit, invalid, form}) => (
        <form onSubmit={handleSubmit}>
          <div styleName="container">
            <div styleName="header">{isNew ? 'New Customer' : 'Edit Customer'}</div>
            <EditCustomerFormContent />
            <div styleName="footer">
              <span styleName="form-btn">
                <Button
                  text="Restore to defaults"
                  colorSchema={COLORS.BLUE_500}
                  onClick={() => onRestoreDefaults(form)}
                  width={WIDTHS.REGULAR}
                />
              </span>
              <span styleName="form-btn">
                <Button text="Cancel" colorSchema={COLORS.GRAY_400} onClick={onClose} />
              </span>
              <AsyncButton
                type="submit"
                isDisabled={invalid || isLoading || isLoadingDefaultSettings || isSubmitting}
                isLoading={isLoading || isLoadingDefaultSettings || isSubmitting}
              >
                {isNew ? 'Create' : 'Save'}
              </AsyncButton>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
export default EditCustomerForm;
