// @flow
import React from 'react';
import {Grid, Box, Modal, Backdrop, makeStyles} from '@material-ui/core';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import Button, {COLORS} from 'common/componentsV2/Button';

type PropsType = {
  isOpen: boolean,
  onClose: Function,
  onConfirm: Function,
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: '400px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding: '24px 24px 16px 24px',
    transform: 'translate(-50%, -50%)',
    backgroundColor: `${theme.palette.white[500]}`,
    borderRadius: '8px',
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
  },
}));

const ConfirmModal = ({isOpen, onClose, onConfirm}: PropsType) => {
  const classes = useStyles();

  return (
    <Modal open={isOpen} BackdropComponent={Backdrop}>
      <Box className={classes.root}>
        <TypographyBox variant="caption">Revoke Refresh Token</TypographyBox>
        <TypographyBox variant="body1" pt={1} pb={5}>
          You are about to revoke a refresh token. Are you sure you want to proceed?
        </TypographyBox>
        <Grid container justify="flex-end" spacing={2}>
          <Grid item>
            <Button automationId="btnCancel" text="Cancel" colorSchema={COLORS.GRAY_500} onClick={onClose} />
          </Grid>
          <Grid item>
            <Button automationId="btnCancel" text="Revoke" colorSchema={COLORS.BLUE_500} onClick={onConfirm} />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
