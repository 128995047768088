// @flow
import React, {useCallback, useMemo, useEffect, useState} from 'react';
import {get, debounce} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import * as selectors from 'metrics/store/selectors';
import {getAllFilters} from 'dashboards/store/selectors';
import * as metricsActions from 'metrics/store/actions';
import SimpleTransparentSelectAction from 'common/componentsV2/ddl/SimpleTransparentSelectAction';
import SimpleButtonSelect from 'common/componentsV2/ddl/SimpleButtonSelect';
import SortedFilterOptions from './SortedFilterOptions';

const filterEditableOptions = [
  {
    value: 'clear',
    label: 'Clear',
  },
  {
    value: 'remove',
    label: 'Remove',
  },
];

const filterMenuOptions = [
  {
    value: 'clear',
    label: 'Clear',
  },
];

type PropTypes = {
  value: Array,
  onChange: Function,
  filter: Array,
  onRemove: Function,
  onApply: Function,
  onBlur: Function,
  allFilters: Array,
  isEditable: boolean,
  appliedValue: Array,
};

const makeDefaultItem = (key) => ({value: '', key, label: 'Select'});

const Filter = ({
  value,
  onChange,
  onApply,
  onBlur,
  filter,
  onRemove,
  allFilters,
  isEditable,
  appliedValue,
}: PropTypes) => {
  const [lastSearchedDimensions, setLastSearchedDimensions] = useState(null);
  const dispatch = useDispatch();
  const cacheData = useSelector(selectors.getCacheData);
  const filtersResponse = useSelector(getAllFilters);
  const isFetchPropAndValListApiLoading = useSelector(selectors.getIsFetchPropAndValApiListLoading);

  const activeFilters = useMemo(() => allFilters.flatMap((f) => (f[0] ? [f[0].key] : [])), [allFilters]);
  // eslint-disable-next-line no-unused-vars
  const dimensions = useMemo(
    () =>
      get(filtersResponse, 'data.properties', [])
        .sort((prev, next) => prev.localeCompare(next, undefined, {ignorePunctuation: true}))
        .filter((item) => !activeFilters.includes(item))
        .map((item) => ({value: item, label: item})),
    [filtersResponse, activeFilters],
  );

  const fetchProps = useCallback(
    (key) => {
      const cacheKey = JSON.stringify({key, filter});
      if (!cacheData.find((obj) => obj.index === cacheKey)) {
        dispatch(
          metricsActions.fetchPropsApi(
            {
              expression: '',
              filter,
              properties: [key],
              size: 500,
            },
            cacheKey,
          ),
        );
      }
    },
    [filter],
  );

  const fetchDimensionsList = (expression) => {
    const fetchPayload = {
      expression: expression || '',
      listDescription: [
        {
          valueType: 'dimensions',
          count: 50,
        },
      ],
      filter: [],
      search: '',
    };
    const key = JSON.stringify(fetchPayload);
    if (!cacheData.find((obj) => obj.index === key)) {
      dispatch(metricsActions.fetchPropAndValListApi(fetchPayload, key));
    }
    setLastSearchedDimensions(key);
  };

  const filteredDimensionsList = useMemo(
    () =>
      get(cacheData.find((obj) => obj.index === lastSearchedDimensions), 'fetchedData', [])
        .slice(1)
        .map((item) => item.value)
        .filter((item) => !activeFilters.includes(item))
        .map((item) => ({value: item, label: item})),
    [cacheData, activeFilters, lastSearchedDimensions],
  );

  const first = value.length > 0 && value[0].key;

  const selectedArr = useMemo(
    () =>
      value
        .map((item) => ({
          value: item.value,
          label: item.value === '*' ? 'All' : item.label || item.value,
        }))
        .filter((item) => item.value),
    [value],
  );

  const selectedAppliedArr = useMemo(
    () =>
      appliedValue
        .map((item) => ({
          value: item.value,
          label: item.value === '*' ? 'All' : item.label || item.value,
        }))
        .filter((item) => item.value),
    [appliedValue],
  );

  const properties = useMemo(() => {
    if (!first) {
      return [];
    }
    const arr = get(cacheData.find((obj) => obj.index === JSON.stringify({key: first, filter})), 'fetchedData', []);
    if (arr.length === 0) {
      return selectedArr.map((item) => ({
        ...item,
        key: first,
      }));
    }
    return [
      // {value: '*', key: first, label: 'All'},
      ...arr.map((prop) => ({
        key: first,
        value: prop.value,
        label: prop.value,
        isDisabled: selectedArr.some((item) => item.value === '*'),
      })),
    ];
  }, [cacheData, first, selectedArr, filter]);

  const onSelectDimension = useCallback(
    (item) => {
      if (item.value) {
        onChange([makeDefaultItem(item.value)]);
      }
    },
    [fetchProps, onChange],
  );

  useEffect(() => {
    if (first) {
      fetchProps(first);
    }
  }, [first, filter]);

  const onChangeProps = useCallback(
    (item) => {
      if (item.length === 0) {
        onChange([makeDefaultItem(first)], true);
      } else if (item.some((opt) => opt.value === '*')) {
        onChange([{value: '*', key: first, label: 'All'}], true);
      } else {
        onChange(item.map((opt) => ({...opt, key: first})).filter((opt) => opt.value !== ''), true);
      }
    },
    [onChange, first],
  );

  const onClearAll = useCallback(() => {
    onChange([makeDefaultItem(first)], true);
  }, [onChange, first]);

  const onSelectFilterMenuOption = useCallback(
    (option) => {
      if (option.value === 'remove') {
        onRemove();
      }
      if (option.value === 'clear') {
        onChange([makeDefaultItem(first)]);
      }
    },
    [onRemove, onChange, first],
  );

  const debouncedInputChange = debounce(fetchDimensionsList, 200);

  return (
    <div className="dashboard-filter-wrapper">
      {first ? (
        <SimpleTransparentSelectAction
          menuWidth={150}
          label={first}
          options={isEditable ? filterEditableOptions : filterMenuOptions}
          onChange={onSelectFilterMenuOption}
        />
      ) : (
        isEditable && (
          <SimpleButtonSelect
            text="Filters"
            icon="icn-general16-filter"
            onChange={onSelectDimension}
            options={filteredDimensionsList}
            value={value}
            onMenuOpen={fetchDimensionsList}
            onInputChange={debouncedInputChange}
            isLoading={isFetchPropAndValListApiLoading}
          />
        )
      )}
      {first && (
        <SortedFilterOptions
          options={properties}
          value={selectedArr}
          onChange={onChangeProps}
          onClearAll={onClearAll}
          onApply={onApply}
          onBlur={onBlur}
          appliedValue={selectedAppliedArr}
        />
      )}
    </div>
  );
};

export default React.memo(Filter);
