// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import ConfModal from 'common/componentsV2/modal/ConfModal';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import * as selector from 'admin.users/store/selectors';
import {getMeOwnerOrganization} from 'profile/store/selectors';
import {deleteGroup, setDeleteGroupModalKeyVal, toggleDeleteGroupModal} from 'admin.users/store/actions';
import AssetsCountSection from 'admin.users/components/AssetsCountSection';
import './UserGroupsSections.module.scss';

type PropTypes = {
  isDelegate: boolean,
  delegateUserId: String,
  isOpen: boolean,
  groupItem: Object,
  ownerOrganization: String,
  itemsList: Array,
  toggleDeleteGroupModal: Function,
  setDeleteGroupModalKeyVal: Function,
  deleteGroup: Function,
};

@connect(
  (state) => ({
    isDelegate: selector.getGroupsDeleteModalIsDelegate(state),
    delegateUserId: selector.getGroupsDeleteModalDelegateUserId(state),
    isOpen: selector.getGroupsDeleteModalIsOpen(state),
    groupItem: selector.getGroupsDeleteModalGroupItem(state),
    ownerOrganization: getMeOwnerOrganization(state),
    itemsList: selector.getUsersGroupsListsWithoutDeleteGroup(state),
  }),
  {
    toggleDeleteGroupModal,
    setDeleteGroupModalKeyVal,
    deleteGroup,
  },
)
export default class DeleteUserModal extends React.PureComponent {
  props: PropTypes;

  setIsUserDelegate = (e) => {
    if (e.target.value === 'no') {
      this.props.setDeleteGroupModalKeyVal({isDelegate: false, delegateUserId: 'andt-none'});
    } else {
      this.props.setDeleteGroupModalKeyVal({isDelegate: true, delegateUserId: ''});
    }
  };

  userDefaultSelected = (item) => {
    this.props.setDeleteGroupModalKeyVal({delegateUserId: item.value});
  };

  onModalClose = () => {
    this.props.toggleDeleteGroupModal({isOpen: false});
  };

  onConfirm = () => {
    this.props.deleteGroup({
      ownerOrganization: this.props.ownerOrganization,
      groupId: this.props.groupItem.id,
      delegateUserId: this.props.delegateUserId,
    });
    this.props.toggleDeleteGroupModal({isOpen: false});
  };

  customFilterOption = (option, rawInput) => {
    const {itemsList} = this.props;
    const checkFunction = (variable, rInput) => variable.label.toLowerCase().indexOf(rInput.toLowerCase()) > -1;

    if (option.data.type === 'HEADER') {
      if (rawInput.length === 0) {
        return true;
      }
      return !!itemsList.find(
        (item) => item.type.toLowerCase() === option.data.value.toLowerCase() && checkFunction(item, rawInput),
      );
    }
    return checkFunction(option, rawInput);
  };

  render() {
    const {isOpen, groupItem, itemsList, delegateUserId, isDelegate} = this.props;

    const selectedItem = itemsList.find((item) => item.value === delegateUserId) || null;

    return (
      <ConfModal
        isOpen={isOpen}
        onClose={this.onModalClose}
        onConfirm={this.onConfirm}
        isDanger
        isConfirmDisabled={isDelegate && !delegateUserId}
        confirmText="Delete Group"
        title={`Delete ${groupItem.name}?`}
      >
        <div styleName="delete-body">
          <span>Deleting a group is permanent and cannot be undone.</span>
          {groupItem.name && <AssetsCountSection header={`${groupItem.name} has:`} />}
          <div styleName="delegate-row">
            <div styleName="header">What would you like to do:</div>
            <label key="no">
              <input type="radio" name="delegate" value="no" checked={!isDelegate} onChange={this.setIsUserDelegate} />
              <span>Delete all of these entities from Anodot</span>
            </label>
            <div styleName="delegate">
              <label key="yes">
                <input
                  type="radio"
                  name="delegate"
                  value="yes"
                  checked={isDelegate}
                  onChange={this.setIsUserDelegate}
                />
                <span>Delegate them to:</span>
              </label>
              <div>
                <SelectAndt
                  automationId="setGroupDeleteSelect"
                  type={TYPE_NEW_SEARCH}
                  theme={THEME_BLUE_LEAN}
                  onChange={this.userDefaultSelected}
                  options={itemsList}
                  value={selectedItem}
                  placeholder="Select Group / User"
                  optionHeight={40}
                  buttonWidth={200}
                  menuWidth={300}
                  disabled={!isDelegate}
                  filterOption={this.customFilterOption}
                />
              </div>
            </div>
          </div>
        </div>
      </ConfModal>
    );
  }
}
