// @flow
import React, {PureComponent} from 'react';
import calcWidthInPer from 'common/componentsV2/accordion/widthCalc';

import './OuterRow.module.scss';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import SmartTooltip from 'common/components/SmartTooltip';

type PropTypes = {
  titles: Array,
  isOpen: Boolean,
  isLoading?: Boolean,
  onClick: Function,
  canOpen?: Boolean,
  leftAlignLastItem?: Boolean,
  showTooltip?: Boolean,
  tooltip: Array,
  tooltipIndices: Array,
};

export default class OuterRow extends PureComponent {
  props: PropTypes;

  static defaultProps = {
    isLoading: false,
    canOpen: true,
    leftAlignLastItem: false,
    showTooltip: false,
  };

  render() {
    const {
      titles,
      isOpen,
      onClick,
      isLoading,
      canOpen,
      leftAlignLastItem,
      showTooltip,
      tooltip,
      tooltipIndices,
    } = this.props;

    const style = {
      item: {
        width: calcWidthInPer(titles.length),
      },
    };

    const titleStyle = `titles${leftAlignLastItem ? ' left' : ''}`;

    const comp = (
      <div styleName={`container${canOpen ? '' : ' noOpen'}`} onClick={onClick}>
        {canOpen ? (
          <div styleName="arrows">
            {isLoading ? <Spinner color="#3d4c59" size={SIZES.SMALL_30} /> : null}
            {!isLoading && !isOpen && <span className="icon icn-arrow16-chevronright" />}
            {!isLoading && isOpen && <span className="icon icn-arrow16-chevrondown" />}
          </div>
        ) : null}
        <div styleName={titleStyle}>
          {titles.map((i, index) => (
            <div className="ellipsis" style={style.item} key={i}>
              {showTooltip && tooltipIndices.includes(index) ? (
                <SmartTooltip content={tooltip[index]}>
                  <span>{i}</span>
                </SmartTooltip>
              ) : (
                <span>{i}</span>
              )}
            </div>
          ))}
        </div>
      </div>
    );
    return comp;
  }
}
