import React, {memo} from 'react';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {Box} from '@material-ui/core';
import {getInvModalTrigger, getInvScore} from '../../store/selectors';
import {getTimeZoneName} from '../../../profile/store/selectors';
import {palette} from '../../../app/styles/theme';
import {
  getCorrectTimezoneName,
  getFormattedHumanDuration,
  getFormattedMDH,
  getFormattedTime,
  getIsBetweenTime,
} from '../../../common/utils/dateService';
import {SIZE as BadgeSizes, TriggerType} from '../tabs/Badges';
import Tooltip, {TYPES} from '../../../common/componentsV2/Tooltip';

const IncidentDetails = () => {
  const trigger = useSelector(getInvModalTrigger);
  const score = useSelector(getInvScore);
  const timeZoneName = useSelector(getTimeZoneName);
  const style = {
    score: {
      fontSize: '14px',
      lineHeight: '14px',
      fontWeight: 500,
      padding: '4px',
      height: '22px',
      borderRadius: '4px',
      border: 0,
      display: 'inline-block',
      backgroundColor: palette.gray['200'],
      color: palette.black['500'],
    },
    infoLine: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '14px',
    },
  };

  if (isEmpty(trigger)) {
    return null;
  }

  const isOpen = trigger.status.toLowerCase() === 'open';
  const durText = getFormattedHumanDuration(trigger.duration, isOpen ? 'absolute' : null).trim();
  const fullDurText = isOpen ? ` for ${durText}` : `, lasted ${durText}`;
  const statusText = isOpen ? 'Open' : 'Closed';
  const isSameDay = getIsBetweenTime(trigger.startTime, undefined, 'day', timeZoneName);
  const {startTime} = trigger;
  return (
    <Box mb={2} display="flex" alignItems="baseline">
      {' '}
      <Box mr={1}>
        <TriggerType type={trigger.type} size={BadgeSizes.SMALL} />
      </Box>
      <Box mr={2}>
        <Box style={style.score}>{`Score: ${Math.round(score * 100)}`}</Box>
      </Box>
      <Box mr={3} color="gray.400" display="flex">
        <Box mr={0.25} color="gray.400">
          Status:
        </Box>
        <Box color="gray.500" fontWeight={400} display="flex">
          <Box color={isOpen ? 'red.500' : 'gray.500'} fontWeight={500} mr={isOpen ? 0.5 : 0}>
            {statusText}
          </Box>
          <span>{fullDurText}</span>
        </Box>
      </Box>
      <Box mr={3} style={style.infoLine}>
        <Box mr={0.25} color="gray.400">
          Anomaly Started:{' '}
        </Box>
        <Tooltip content={getCorrectTimezoneName(timeZoneName)} type={TYPES.SMALL}>
          <Box color="gray.500" fontWeight={400}>
            {isSameDay ? getFormattedTime(startTime, timeZoneName) : getFormattedMDH(startTime, timeZoneName)}
          </Box>
        </Tooltip>
      </Box>
      <Box style={style.infoLine}>
        <Box mr={0.25} color="gray.400">
          Alert triggered:
        </Box>
        <Tooltip content={getCorrectTimezoneName(timeZoneName)} type={TYPES.SMALL}>
          <Box color="gray.500" fontWeight={400}>
            {isSameDay
              ? getFormattedTime(trigger.startTime, timeZoneName)
              : getFormattedMDH(trigger.startTime, timeZoneName)}
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default memo(IncidentDetails);
