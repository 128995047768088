// @flow
import React, {useState, useRef} from 'react';
import {isEmpty} from 'lodash';
import CSSTransition from 'react-transition-group/CSSTransition';

import NavBarSubMenuItem from './NavBarSubMenuItem';

import './AppNavBar.module.scss';

type NavBarMenuParentProps = {
  item: Object,
  isSelectedItem: Boolean,
  isUnderSeparator: Boolean,
  position: string,
  selectedItem: string,
  handleClick: Function,
};

const NavBarMenuParent = ({
  item,
  isSelectedItem,
  isUnderSeparator,
  position,
  selectedItem,
  handleClick,
}: NavBarMenuParentProps) => {
  if (isEmpty(item.items)) {
    return '';
  }

  const [isHover, setIsHover] = useState(false);
  const onToggleHover = () => {
    setIsHover(() => !isHover);
  };
  const parentElement = useRef();

  return (
    <div styleName="item-wrapper" automation-id={item.title} ref={parentElement}>
      <div styleName={['item', isSelectedItem ? 'active' : ''].join(' ')}>
        <div styleName="item-link" onMouseEnter={onToggleHover} onMouseLeave={onToggleHover}>
          <i styleName={isUnderSeparator ? 'under' : ''} className={`icon ${item.iconName}`} />
          {isHover && (
            <CSSTransition timeout={300} classNames="sub-menu-item__animation" appear in>
              <NavBarSubMenuItem
                item={item}
                position={position}
                handleClick={handleClick}
                selectedItem={selectedItem}
                parentElement={parentElement}
              />
            </CSSTransition>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBarMenuParent;
