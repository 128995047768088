// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import SearchBox from 'common/componentsV2/SearchBox';
import './AlertFilters.module.scss';
import * as filterSelector from 'alerts.management/store/filterSelector';

type PropTypes = {
  setQueryParams: Function,
  searchQuery: String,
};

@connect(
  (state) => ({
    searchQuery: filterSelector.getAlertFiltersSearchQuery(state),
  }),
  {},
)
export default class SearchQueryFilter extends React.PureComponent {
  props: PropTypes;

  onFilter = (val) => {
    this.props.setQueryParams({searchQuery: val});
  };

  render() {
    const {searchQuery} = this.props;
    return <SearchBox onFilter={this.onFilter} filter={searchQuery} placeHolder="Search by title, description..." />;
  }
}
