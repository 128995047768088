// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import TitleHeaderTableOrder from 'common/componentsV2/table/TitleHeaderTableOrder';
import {TABLE_TITLES} from 'bc/services/dataManagerService';
import {getDataMangerQueryParams} from 'bc/store/selectors';

import './DataManagerListHeader.module.scss';

type PropTypes = {
  setQueryParams: Function,
  dataQueryParams: Object,
};

@connect(
  (state) => ({
    dataQueryParams: getDataMangerQueryParams(state),
  }),
  {},
)
export default class DataManagerListHeader extends PureComponent {
  props: PropTypes;

  handleTitleOrderChange = (columnName) => {
    const {
      dataQueryParams: {sort, order},
    } = this.props;
    let updatedDirection = 'desc';

    if (columnName === sort) {
      updatedDirection = order === 'desc' ? 'asc' : 'desc';
    }
    this.props.setQueryParams({sort: columnName, order: updatedDirection});
  };

  render() {
    const {
      dataQueryParams: {sort, order},
    } = this.props;

    return (
      <div styleName="container">
        {TABLE_TITLES.map((field) => (
          <div key={field.id} styleName={`header ${field.name}`}>
            <TitleHeaderTableOrder
              onChange={this.handleTitleOrderChange}
              title={field.title}
              selectedDirection={order}
              columnName={field.name}
              isSortable={field.isSortable}
              isActive={field.name === sort}
            />
          </div>
        ))}
      </div>
    );
  }
}
