// @flow
import React, {useCallback, useEffect, useMemo} from 'react';
import {Redirect} from 'react-router-dom';
import createDecorator from 'final-form-focus';
import {useDispatch, useSelector} from 'react-redux';
import {Field, Form} from 'react-final-form';
import {getUniqueId} from 'common/utils/guid';
import {get, identity, pickBy} from 'lodash';
import {Backdrop, Box, makeStyles, Modal} from '@material-ui/core';
import useExecuteSimulation from 'dashboards/hooks/useExecuteSimulation';
import useCompositeName from 'dashboards/hooks/useCompositeName';
import useTileAlertSync from 'dashboards/hooks/useTileAlertSync';
import Input from 'common/componentsV2/Input';
import Button, {COLORS} from 'common/componentsV2/Button';
import {TypographyBox} from 'common/componentsV2/boxTools';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import * as amSelectors from 'alerts.management/store/selectors';
import * as metricsSelectors from 'metrics/store/selectors';
import {fetchComposites} from 'metrics/store/actions';
import {fetchAlertConfigurations} from 'alerts.management/store/actions';
import AsyncButton from 'common/componentsV2/AsyncButton';
import {removeAliasRootFuncFromTree, removeUIData} from 'dashboards/utils';

const focusOnErrors = createDecorator();
type PropTypes = {onClose: Function, data: Object, dashboardId: string, tileUrl: string};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    backgroundColor: theme.palette.white[500],
    borderRadius: 6,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
    padding: 24,
  },
}));

const conditions = [
  {
    type: 'DELTA_CONDITION',
    id: getUniqueId(),
    deltaAbsolute: null,
    deltaPercentage: null,
    enableAutoTuning: true,
    deltaDuration: {
      enabled: null,
      rollup: 'medium',
      minDuration: 7200,
    },
  },
  {
    type: 'SIGNIFICANCE_CONDITION',
    id: getUniqueId(),
    minSignificance: 0.75,
    enableAutoTuning: false,
  },
  {
    type: 'DURATION_CONDITION',
    id: getUniqueId(),
    minDuration: 7200,
    enableAutoTuning: false,
  },
  {
    type: 'DIRECTION_CONDITION',
    id: getUniqueId(),
    direction: 'down',
    enableAutoTuning: false,
  },
];

const AlertModal = ({onClose, data, dashboardId, tileUrl}: PropTypes) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const compositeObject = useMemo(() => {
    const dataKey = data.lineChart ? 'lineChart' : 'andtGauge';
    if (data.lineChart || data.andtGauge) {
      return removeAliasRootFuncFromTree(removeUIData(Object.values(data[dataKey].expressionTrees)[0]));
    }
    return undefined;
  }, [data]);

  const alertList = useSelector(amSelectors.getAlertConfigurationsItems);
  const compositesList = useSelector(metricsSelectors.getComposites);

  const {data: simulationData, isLoading} = useExecuteSimulation({compositeObject, conditions});
  const {createAlert} = useTileAlertSync({dashboardId});
  // eslint-disable-next-line max-len
  const {makeCompositeNameProps, isManualCompositeNameInput, isCompositeNameLoading} = useCompositeName({
    compositeObject,
  });

  useEffect(() => {
    dispatch(fetchComposites());
    dispatch(fetchAlertConfigurations());
  }, []);

  const validate = useCallback(
    (values) => {
      const composites = (compositesList || [])
        .map((item) => item.composite.title)
        .filter((item) => !alertList.some((listItem) => listItem.data.title === item));

      const titleNoUnique =
        alertList.some((item) => item.data.title === values.title) && 'An alert with that name already exists';

      const compositeNotUnique =
        composites.some((item) => item === values.title) && 'Composite with that title already exists';

      const titleMandatory = !values.title && 'Alert name is mandatory';
      // eslint-disable-next-line max-len
      const compositeNameMandatory =
        isManualCompositeNameInput && !values.compositeName && 'Composite name is mandatory';

      const isCompositeNameTaken =
        (compositesList || []).some(
          // eslint-disable-next-line max-len
          (item) =>
            get(item, 'composite.name.prefix') ===
            `what=${values.compositeName}.func=${compositeObject.expressionTree.root.function}`,
        ) && 'Composite with that name already exists';

      return pickBy(
        {
          title: titleNoUnique || compositeNotUnique || titleMandatory,
          compositeName: compositeNameMandatory || isCompositeNameTaken,
        },
        identity,
      );
    },
    [compositesList, alertList, isManualCompositeNameInput],
  );

  const estimationArr = (get(simulationData, 'totalEstimation') || '').split('/');

  return (
    <Modal open BackdropComponent={Backdrop} onClose={() => onClose()}>
      <Box width={1} height={1} display="flex" justifyContent="center" alignItems="center">
        <Form
          decorators={[focusOnErrors]}
          initialValues={{title: get(data, 'title.text'), compositeName: ''}}
          validate={validate}
          onSubmit={(values) =>
            createAlert({
              compositeObject: {
                ...compositeObject,
                ...makeCompositeNameProps(values.title, values.compositeName),
                displayOnly: false, // TODO: check the behavior
                scalarTransforms: null,
              },
              conditions,
              tileId: data.id,
              title: values.title,
              dashboardId,
            })
          }
        >
          {({handleSubmit, submitErrors, submitSucceeded, submitting}) => (
            <Box automation-id="addGraphStatTileModal" className={classes.wrapper}>
              {submitSucceeded && <Redirect to={tileUrl} />}
              <Box fontSize={24} mb={4} fontWeight={300}>
                Create Alert from Dashboard Tile
              </Box>
              {isCompositeNameLoading ? (
                <Box alignSelf="center">
                  <Spinner color="gray.500" size={SIZES.MEDIUM_50} />
                </Box>
              ) : (
                <React.Fragment>
                  <Box fontSize={16} mb={1.5}>
                    Alert Name
                  </Box>

                  <Box display="flex" mb={4}>
                    <Box height={36} flexGrow={1} mr={1}>
                      <Field name="title">
                        {({input: {value, onChange}, meta: {error, touched}}) => (
                          <Input
                            additionalProps={{name: 'title'}}
                            isInvalid={touched && error}
                            invalidMessage={error}
                            fullSize
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      </Field>
                    </Box>
                  </Box>

                  {isManualCompositeNameInput && (
                    <React.Fragment>
                      <Box fontSize={16} mb={1.5}>
                        Composite Name
                      </Box>
                      <Box display="flex" mb={4}>
                        <Box height={36} flexGrow={1} mr={1}>
                          <Field name="compositeName">
                            {({input: {value, onChange}, meta: {error, touched}}) => (
                              <Input
                                additionalProps={{name: 'compositeName'}}
                                isInvalid={touched && error}
                                invalidMessage={error}
                                fullSize
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          </Field>
                        </Box>
                      </Box>
                    </React.Fragment>
                  )}

                  <Box
                    mb={7}
                    bgcolor="#e9f0ff"
                    display="flex"
                    width={278}
                    height={59}
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={6}
                    margin="0 auto"
                  >
                    {isLoading ? (
                      <Spinner size={50} />
                    ) : (
                      <React.Fragment>
                        <Box fontSize={16}>Estimated rate of</Box>
                        <Box fontSize={35.4} fontWeight={500} color="gray.500" mx={1}>
                          {estimationArr[0] && estimationArr[0] !== 'No alerts' ? parseInt(estimationArr[0], 10) : 0}
                        </Box>
                        <TypographyBox width={48} variant="subtitle1" textAlign="center">
                          Alerts /{` ${estimationArr[1] || 'Day'}`}
                        </TypographyBox>
                      </React.Fragment>
                    )}
                  </Box>

                  {Object.values(submitErrors || {}).map((error) => (
                    <Box
                      alignSelf="center"
                      borderRadius={6}
                      position="relative"
                      width={287}
                      bgcolor="red.500"
                      py={1}
                      px={1.5}
                      color="white.500"
                      fontWeight={500}
                      fontSize={16}
                    >
                      {error}
                    </Box>
                  ))}

                  <Box mt={2} alignSelf="center" display="flex">
                    <div className="mr_1-5">
                      <Button automationId="cancel" text="Cancel" colorSchema={COLORS.GRAY_300} onClick={onClose} />
                    </div>
                    <AsyncButton colorSchema="blue.500" isLoading={submitting} onClick={handleSubmit}>
                      Create
                    </AsyncButton>
                  </Box>
                </React.Fragment>
              )}
            </Box>
          )}
        </Form>
      </Box>
    </Modal>
  );
};

export default AlertModal;
