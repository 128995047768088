// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as selectors from 'alerts.console/store/selectors';
import * as actions from 'alerts.console/store/actions';
import ReactResizeDetector from 'react-resize-detector';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import AlertConsoleEmptyState from 'alerts.console/components/alertsList/AlertConsoleEmptyState';
import AlertGroupsList from './AlertGroupsList';
import './AlertsListBody.module.scss';

type PropTypes = {
  // connect
  ackAlerts: Array<Object>,
  ackAlertsIsLoading: boolean,
  noAckAlerts: Array<Object>,
  noAckAlertsIsLoading: boolean,
  scrollbarWidth: Number,
  alertsTriggeredTotal: Number,
  ackTotal: Number,
  noAckTotal: Number,
  changeScrollbarWidth: Function,
  listScrolled: Function,
  setIsAlertsListScrolling: Function,
};

@connect(
  (state) => ({
    ackAlerts: selectors.getAckAlerts(state),
    ackAlertsIsLoading: selectors.getAckAlertsIsLoading(state),
    noAckAlerts: selectors.getNoAckAlerts(state),
    noAckAlertsIsLoading: selectors.getNoAckAlertsIsLoading(state),
    scrollbarWidth: selectors.getScrollbarWidth(state),
    alertsTriggeredTotal: selectors.getAlertsTriggeredTotal(state),
    ackTotal: selectors.getAckTotal(state),
    noAckTotal: selectors.getNoAckTotal(state),
  }),
  {
    changeScrollbarWidth: actions.changeScrollbarWidth,
    listScrolled: actions.listScrolled,
    setIsAlertsListScrolling: actions.setIsAlertsListScrolling,
  },
)
class AlertsListBody extends PureComponent {
  props: PropTypes;

  state = {
    isAlertsListScrolling: false,
  };

  _timeout = null;

  handleResize = () => {
    this.props.changeScrollbarWidth(this.listBody);
  };

  onScroll = (e) => {
    const {listScrolled, setIsAlertsListScrolling} = this.props;
    const elementScroll = e.target.scrollTop;

    if (this._timeout) {
      clearTimeout(this._timeout);
    }
    this._timeout = setTimeout(() => {
      this._timeout = null;
      this.setState({isAlertsListScrolling: false});
      setIsAlertsListScrolling(false);
    }, 300);
    if (!this.state.isAlertsListScrolling) {
      this.setState({isAlertsListScrolling: true});
      setIsAlertsListScrolling(true);
    }

    if (elementScroll > 0) {
      listScrolled(true);
    } else if (elementScroll === 0) {
      listScrolled(false);
    }
  };

  listBodyRef = (e) => {
    this.listBody = e;
  };

  render() {
    const {
      ackAlerts,
      noAckAlerts,
      ackAlertsIsLoading,
      noAckAlertsIsLoading,
      scrollbarWidth,
      alertsTriggeredTotal,
      ackTotal,
      noAckTotal,
    } = this.props;
    const containerStyle = {
      width: scrollbarWidth ? 'calc(100% - 8px)' : '100%',
    };
    return (
      <div styleName="container" ref={this.listBodyRef} onScroll={this.onScroll}>
        <ReactResizeDetector handleHeight handleWidth onResize={this.handleResize} />

        {ackAlertsIsLoading === false && (
          <div style={containerStyle}>
            <AlertGroupsList
              uiGroupName="ack"
              alertGroups={ackAlerts}
              isAlertsListScrolling={this.state.isAlertsListScrolling}
            />
          </div>
        )}

        {noAckAlertsIsLoading === false && (
          <div style={containerStyle}>
            <AlertGroupsList
              uiGroupName="noAck"
              alertGroups={noAckAlerts}
              isAlertsListScrolling={this.state.isAlertsListScrolling}
            />
          </div>
        )}

        {(ackAlertsIsLoading !== false || noAckAlertsIsLoading !== false) && (
          <div styleName="loader">
            <Spinner color="#3d4c59" size={SIZES.XX_BIG_150} />
          </div>
        )}

        {ackAlertsIsLoading === false && noAckAlertsIsLoading === false && !!(!ackTotal && !noAckTotal) && (
          <AlertConsoleEmptyState alertsCount={alertsTriggeredTotal} />
        )}
      </div>
    );
  }
}

export default AlertsListBody;
