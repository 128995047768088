import React, {useState} from 'react';
import {Box} from '@material-ui/core';
import './MetricExplorerModalBottomPanel.module.scss';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import Button, {COLORS} from 'common/componentsV2/Button';
import {useDispatch, useSelector} from 'react-redux';
import FormDdl from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdl';
import {useForm} from 'react-final-form';
import {segmentClickEvent} from 'common/store/actions';
import * as selectors from '../../store/selectors';
import ShowCompositesContainter from './ShowCompositesContainer';
import * as actions from '../../store/actions';

type PropTypes = {
  onSave: Function,
  onCancel: Function,
};

const MetricExplorerModalBottomPanel = ({onSave, onCancel}: PropTypes) => {
  const [isMoreOptionsPanelOpen, setMoreOptionsPanelOpen] = useState(false);
  const form = useForm();
  const dispatch = useDispatch();
  const isExecuteValid = useSelector(selectors.getIsExecuteValid);

  const handleClearAll = () => {
    dispatch(segmentClickEvent({type: 'click', name: 'clear all'}));
    dispatch(actions.clearAllMetricsExplorer());
    form.reset();
  };

  const renderMoreOptionsPanel = () => (
    <div>
      <div styleName="more-option">
        <div>Show Composites</div>
        <ShowCompositesContainter />
      </div>
      <div styleName="more-option button" onClick={handleClearAll}>
        <div>Clear All</div>
      </div>
    </div>
  );

  const toggleActionMenuPanel = (isOpen) => {
    setMoreOptionsPanelOpen(isOpen);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSave = () => {
    if (isExecuteValid) {
      onSave();
    }
  };

  const renderButtonSave = () => (
    <Button onClick={handleSave} colorSchema={COLORS.BLUE_500} text="Save as influencing metrics" />
  );

  return (
    <div styleName="container">
      <Box
        display="flex"
        justifyContent="space-between"
        maxWidth={1680}
        width={1}
        px="20px"
        alignItems="center"
        className="idle-external-click"
      >
        <div styleName="left-side-control">
          <FormDdl
            popoverComponent={renderMoreOptionsPanel()}
            buttonComponent={<div styleName="three-dots-button" className="icon icn-icon-3-dots" />}
            onToggle={(isOpen) => toggleActionMenuPanel(isOpen)}
            width={200}
            isOpen={isMoreOptionsPanelOpen}
            placement="top"
            automationId="metricExplorerMoreOptionsButton"
          />
        </div>
        <div styleName="buttons-container">
          <Button onClick={handleCancel} colorSchema={COLORS.TRANSPARENT} text="Cancel" />
          {isExecuteValid ? (
            <div>{renderButtonSave()}</div>
          ) : (
            <Tooltip
              content={
                <span>
                  The expression is invalid and <br /> therefore cannot be save
                </span>
              }
              type={TYPES.SMALL}
              extraTtClass="validation-tooltip"
            >
              <div>{renderButtonSave()}</div>
            </Tooltip>
          )}
        </div>
      </Box>
    </div>
  );
};

export default MetricExplorerModalBottomPanel;
