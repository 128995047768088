import {useCallback, useMemo, useRef} from 'react';
import {values, get, isEmpty} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import * as amSelectors from 'alerts.management/store/selectors';
import {
  createNewAlert,
  updateAlert as updateAlertAction,
  fetchAlertConfigurations,
  getVolumeCondition,
  showAlertSuccessMessage,
  showAlertFailureMessage,
} from 'alerts.management/store/actions';
import * as profileSelectors from 'profile/store/selectors';
import {getUsersGroupsListsFull} from 'admin.users/store/selectors';
import useAsyncAction from 'common/utils/useAsyncAction';
import {updateDashboard} from 'dashboards/store/actions';
import * as commonSelectors from 'common/store/selectors';
import {makeTileAlertsPayload, makeVolumeCondition, makeNewAlertDataPayload} from '../utils';
import useDashboardState from './useDashboardState';

const alertResponseValidator = (response) => {
  const alertValidation = get(
    values(get(response, 'eventValidationResult.alertValidation.failures')),
    '[0].message',
    '',
  );
  const compositeValidation = get(
    values(get(response, 'eventValidationResult.compositeValidation.failures')),
    '[0][0].message',
    '',
  );
  return {
    alertValidation,
    compositeValidation,
  };
};

const useTileAlertSync = ({dashboardId}) => {
  const createNewAlertResponse = useSelector(amSelectors.getCreateNewAlertResponse);
  const updateAlertResponse = useSelector(amSelectors.getUpdateAlertResponse);
  const volumeConditionResponse = useSelector(amSelectors.getVolumeConditionResponse);
  const dashboardState = useDashboardState(dashboardId);
  const newAlert = useRef();
  newAlert.current = createNewAlertResponse;
  const usersGroups = useSelector(getUsersGroupsListsFull);
  const userProfile = useSelector(profileSelectors.getUserProfile);
  const autoVolumeEnabled = useSelector(profileSelectors.getAutoVolumeEnabled);
  const ownerId = userProfile.defaultGroup || userProfile._id;
  const ownerEmail = useMemo(() => (usersGroups.find((item) => item.value === ownerId) || {}).email, [ownerId]);
  const users = useSelector(commonSelectors.getUsers);
  const subscribers = users.data.users.data
    .filter((user) => userProfile._id === user._id)
    .map((user) => ({email: user.email, muted: false}));

  const dispatch = useDispatch();
  const volumeCondition = useRef();

  const getVolumeConditionAsync = useAsyncAction(getVolumeCondition, volumeConditionResponse);
  volumeCondition.current = volumeConditionResponse.data;

  const createNewAlertAsync = useAsyncAction(createNewAlert, createNewAlertResponse, alertResponseValidator);
  const updateAlertAsync = useAsyncAction(updateAlertAction, updateAlertResponse, alertResponseValidator);

  const updateDashboardAsync = useAsyncAction(updateDashboard, dashboardState);

  const createAlert = useCallback(
    ({compositeObject, conditions, title, tileId}) => {
      const alertPayload = makeNewAlertDataPayload({
        compositeObject,
        ownerId,
        dashboardId,
        tileId,
        conditions,
        title,
      });

      return (autoVolumeEnabled ? getVolumeConditionAsync(alertPayload) : Promise.resolve())
        .then(() =>
          createNewAlertAsync({
            body: {
              owner: ownerEmail,
              ownerId,
              subscribers,
              data: {
                ...alertPayload,
                // eslint-disable-next-line max-len
                conditions: alertPayload.conditions.concat(
                  get(volumeCondition, 'current.rollup') ? [makeVolumeCondition(volumeCondition.current)] : [],
                ),
              },
              extraEmails: [],
              connectedTiles: [
                {
                  dashboardId,
                  tileId,
                },
              ],
            },
            qs: {allowEmptyCompositeRes: true},
          }),
        )
        .then((errors) => {
          if (errors) {
            return errors;
          }
          return updateDashboardAsync(makeTileAlertsPayload(dashboardState.data, tileId, newAlert.current.eventId));
        })
        .then((errors) => {
          if (!isEmpty(errors)) {
            dispatch(
              showAlertFailureMessage({
                title: 'Alert creation failure',
                moreInfo: Object.values(errors)[0],
              }),
            );
          } else {
            dispatch(
              showAlertSuccessMessage({
                title: 'Alert created successfully',
                moreInfo: `[${title}] was created successfully`,
              }),
            );
            // TODO: change the architecture to push the alert into alerts collection in store
            dispatch(fetchAlertConfigurations());
          }
        });
    },
    [ownerId, ownerEmail, dispatch, dashboardState, autoVolumeEnabled, subscribers],
  );

  const updateAlert = (alertPayload) =>
    updateAlertAsync({
      body: alertPayload,
      qs: {allowEmptyCompositeRes: true},
      alertId: alertPayload.data.id,
    }).then((errors) => {
      if (!isEmpty(errors)) {
        dispatch(
          showAlertFailureMessage({
            title: 'Alert update failure',
            moreInfo: Object.values(errors)[0],
          }),
        );
      } else {
        dispatch(
          showAlertSuccessMessage({
            title: 'Alert updated successfully',
            moreInfo: `[${alertPayload.data.title}] was updated successfully`,
          }),
        );
      }
    });

  return {
    createAlert,
    isCreating: createNewAlertResponse.isLoading,
    updateAlert,
    isUpdating: updateAlertResponse.isLoading,
  };
};

export default useTileAlertSync;
