// @flow
import React, {Fragment, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {alertTypes} from 'alerts.management/services/alertsService';
import * as selectors from 'alerts.management/store/selectors';
import * as profileSelector from 'profile/store/selectors';
import ToggleButton from 'common/componentsV2/ToggleButton';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import StaticThresholdSimple from './StaticThresholdSimple';
import SignificanceCondition from './SignificanceCondition';
import MinDurationCondition from './MinDurationCondition';
import TimeScaleCondition from './TimeScaleCondition';
import DirectionCondition from './DirectionCondition';
import VolumeCondition from './VolumeCondition';
import FiltersBlock from '../simulationArea/FilterBlock';
import EventsInput from '../simulationArea/conditions/EventsInput';
import MinimumDeltaCondition from './MinimumDeltaCondition';
import NumberOfMetricsInAnomaly from './NumberOfMetricsInAnomaly';
import AddInfluencingMetrics from './AddInfluencingMetrics';
import MetricsValuesInAnomaly from './MetricsValuesInAnomaly';

const Settings = () => {
  const autoVolumeEnabled = useSelector(profileSelector.getAutoVolumeEnabled);
  const influencingNoDataEnabled = useSelector(profileSelector.getInfluencingNoDataEnabled);

  const type = useSelector(selectors.getSimulationFiltersType);

  const [anomalyType, staticType, noDataType] = useMemo(
    () => [
      type.value === alertTypes.anomaly.value,
      type.value === alertTypes.static.value,
      type.value === alertTypes.noData.value,
    ],
    [type.value],
  );

  const significanceCondition = useSelector(selectors.getSignificanceCondition);
  const directionCondition = useSelector(selectors.getDirectionCondition);
  const volumeCondition = useSelector(selectors.getVolumeConditionFilter);
  const deltaCondition = useSelector(selectors.getDeltaCondition);
  const isInfluencingStaticEnabled = useSelector(profileSelector.getInfluencingStaticEnabled);

  return (
    <Fragment>
      <Box mb={3}>
        <Grid container direction="column" spacing={4}>
          {(anomalyType || staticType) && (
            <Grid item>
              <TimeScaleCondition />
            </Grid>
          )}
          {(anomalyType || staticType) && (
            <Grid item>
              <MinDurationCondition />
            </Grid>
          )}
          {anomalyType && directionCondition && (
            <Grid item>
              <DirectionCondition />
            </Grid>
          )}
          {anomalyType && significanceCondition && (
            <Grid item>
              <SignificanceCondition />
            </Grid>
          )}
          {staticType && (
            <Grid item>
              <StaticThresholdSimple />
            </Grid>
          )}
        </Grid>
      </Box>

      <ToggleButton
        byPass={noDataType || staticType}
        labelClosed="Show more conditions"
        labelOpened="Show less conditions"
        automationId="toggleShowMoreOrLessConditions"
      >
        <Box>
          <Grid container spacing={3} direction="column">
            {anomalyType && (
              <Grid item>
                <FiltersBlock
                  labelColor="purple.600"
                  borderColor="rgba(104, 38, 171, 0.2)"
                  label="Auto-Tuned Settings"
                  icon="icn-general16-magic"
                  automationId="autoTunedSettings"
                >
                  {anomalyType && deltaCondition && <MinimumDeltaCondition />}
                  {autoVolumeEnabled && anomalyType && volumeCondition && <VolumeCondition />}
                </FiltersBlock>
              </Grid>
            )}
            {(anomalyType || staticType) && (
              <Grid item>
                <EventsInput />
              </Grid>
            )}
            {anomalyType && (
              <Grid item>
                <Box maxWidth={371}>
                  <NumberOfMetricsInAnomaly />
                </Box>
              </Grid>
            )}

            {anomalyType && (
              <Grid item>
                <MetricsValuesInAnomaly />
              </Grid>
            )}

            {(anomalyType ||
              (staticType && isInfluencingStaticEnabled) ||
              (influencingNoDataEnabled && noDataType)) && (
              <Grid item>
                <AddInfluencingMetrics />
              </Grid>
            )}
          </Grid>
        </Box>
      </ToggleButton>
    </Fragment>
  );
};

export default React.memo(Settings);
