import {createSelector} from 'reselect';
import {differenceWith, isEqual} from 'lodash';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

export const getFeaturesFlag = (state) => state.featuresFlags;

// Data
export const getFeaturesFlagsData = createSelector(
  getFeaturesFlag,
  (i) => i.data || EMPTY_OBJECT,
);

export const getCustomersAndGlobal = createSelector(
  getFeaturesFlagsData,
  (data) => data.customersAndGlobal || EMPTY_ARRAY,
);

export const getDefaults = createSelector(
  getFeaturesFlagsData,
  (data) => data.defaults || EMPTY_ARRAY,
);

export const getUpdateCustomersAndGlobal = createSelector(
  getFeaturesFlagsData,
  (data) => data.updateCustomersAndGlobal || EMPTY_ARRAY,
);

export const getUpdateCustomersAndGlobalIsLoading = createSelector(
  getFeaturesFlagsData,
  (data) => data.updateCustomersAndGlobal.isLoading,
);

export const getCustomersAndGlobalIsLoading = createSelector(
  getCustomersAndGlobal,
  (customers) => customers.isLoading,
);

export const getDefaultsIsLoading = createSelector(
  getDefaults,
  (defaults) => defaults.isLoading,
);

// View
export const getFeaturesFlagsViews = createSelector(
  getFeaturesFlag,
  (i) => i.views || EMPTY_OBJECT,
);

export const getMergedGlobal = createSelector(
  getFeaturesFlagsViews,
  (views) => views.mergedGlobal || EMPTY_ARRAY,
);

export const getMergedCustomers = createSelector(
  getFeaturesFlagsViews,
  (views) => views.mergedCustomers || EMPTY_ARRAY,
);

export const getMergedGlobalOrigin = createSelector(
  getMergedGlobal,
  (mg) => mg.dataOrigin || EMPTY_ARRAY,
);

export const getMergedGlobalUI = createSelector(
  getMergedGlobal,
  (mg) => mg.dataUIState || EMPTY_ARRAY,
);

export const getMergedCustomersOrigin = createSelector(
  getMergedCustomers,
  (mc) => mc.dataOrigin || EMPTY_ARRAY,
);

export const getMergedCustomersUI = createSelector(
  getMergedCustomers,
  (mc) => mc.dataUIState || EMPTY_ARRAY,
);

export const getFilters = createSelector(
  getFeaturesFlagsViews,
  (views) => views.filters,
);

export const getQueryCustomers = createSelector(
  getFilters,
  (filters) => filters.queryCustomers,
);

export const getDataUIStateFiltered = createSelector(
  getQueryCustomers,
  getMergedCustomersUI,
  (value, dataUIState) => {
    const filteredCustomers = dataUIState.filter(
      (i) =>
        i.id === 'all' ||
        i.name.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !== -1 ||
        i.id.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !== -1,
    );
    return filteredCustomers || EMPTY_ARRAY;
  },
);

export const getQueryGlobals = createSelector(
  getFilters,
  (filters) => filters.queryGlobals,
);

export const getRndOnly = createSelector(
  getFilters,
  (filters) => filters.rndOnly,
);

export const getGlobalDefaultOn = createSelector(
  getFilters,
  (filters) => filters.globalDefaultOn,
);

export const getMergedGlobalFiltered = createSelector(
  getFilters,
  getMergedGlobalUI,
  (filters, dataUIState) => {
    const queryFilter = filters.queryGlobals.toLocaleLowerCase();
    const rndOnlyFilter = filters.rndOnly;
    const globalDefaultFilter = filters.globalDefaultOn;

    return dataUIState.filter((i) => {
      const name = i.name.toLocaleLowerCase();
      const displayName = i.displayName.toLocaleLowerCase();

      if (rndOnlyFilter && i.rndOnly) {
        return false;
      }
      if (globalDefaultFilter && !i.globalDefault) {
        return false;
      }
      if (name.indexOf(queryFilter) === -1 && displayName.indexOf(queryFilter) === -1) {
        return false;
      }
      return true;
    });
  },
);

// the difference is just for the modal to present the user the changes that he or she made.
// we send to the BE all the FF, includes the ones that didn't changes.
export const getCustomersDifference = createSelector(
  getMergedCustomersUI,
  getMergedCustomersOrigin,
  (customerView, customerData) => differenceWith(customerView.filter((i) => i.id !== 'all'), customerData, isEqual),
);

export const getGlobalsDifference = createSelector(
  getMergedGlobalUI,
  getMergedGlobalOrigin,
  (globalsView, globalsData) => differenceWith(globalsView.filter((i) => i.id !== 'all'), globalsData, isEqual),
);
