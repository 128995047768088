// @flow

/* eslint-disable */
import React from 'react';
import {Box} from '@material-ui/core';
import {TypographyBox} from 'common/componentsV2/boxTools';
import SmartTooltip from 'common/components/SmartTooltip';
import {alertTypes, serverAnomalyDirection} from 'alerts.management/services/alertsService';
import {isFinite} from 'lodash';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import InputAuto from '../simulationArea/utils/InputAuto';
import FieldError from '../simulationArea/FieldError';

type PropTypes = {
  staticThreshold: Object,
  direction: String,
  alertType: String,
  onChange: Function,
};

const lowerBounds = [
  {
    desc: 'smaller than',
    text: <span>Below</span>,
    value: 'LOWER',
  },
  {
    desc: 'smaller than or equal to',
    text: <span>Below & Equals to</span>,
    value: 'LOWER_EQUAL',
  },
];

const upperBounds = [
  {
    desc: 'larger than',
    text: <span>Above</span>,
    value: 'UPPER',
  },
  {
    desc: 'larger than or equal to',
    text: <span>Above & Equals to</span>,
    value: 'UPPER_EQUAL',
  },
];

const getStringValue = (val) => {
  if (val !== undefined && val !== null) {
    return val.toString();
  }
  return '';
};

class StaticThresholdCondition extends React.PureComponent {
  props: PropTypes;

  state = {
    isInvalidValues: false,
  };

  componentDidMount() {
    this.setValidity(this.props.staticThreshold.maxValue, this.props.staticThreshold.minValue);
  }

  setValidity = (maxValue, minValue) => {
    const maxVal = parseInt(maxValue, 10);
    const minVal = parseInt(minValue, 10);
    let isInvalidValues = false;

    let isRequired = false;

    if (isFinite(minVal) && isFinite(maxVal)) {
      isInvalidValues = minVal > maxVal;
    }

    if (!isFinite(minVal) && !isFinite(maxVal)) {
      isRequired = true;
    }

    this.setState({isInvalidValues, isRequired});
  };

  parseValue = (val) => (Number.isFinite(parseFloat(val)) ? parseFloat(val) : null);

  handleBlurMax = (e) => {
    const val = this.parseValue(e.target.value);
    this.props.onChange({maxValue: val});
    this.setValidity(val, this.props.staticThreshold.minValue);
  };

  handleBlurMin = (e) => {
    const val = this.parseValue(e.target.value);
    this.props.onChange({minValue: val});
    this.setValidity(this.props.staticThreshold.maxValue, val);
  };

  handleBoundChange = (isUpBounds, value) => {
    const toUpdate = {};
    if (isUpBounds) {
      toUpdate.upperBound = value;
    } else {
      toUpdate.lowerBound = value;
    }
    this.props.onChange(toUpdate);
  };

  render() {
    const {direction, staticThreshold, alertType} = this.props;
    const isInvalid = this.state.isInvalidValues || this.state.isRequired;

    const ttMesage = this.state.isInvalidValues ? (
      <span>
        Upper threshold is smaller <br /> than lower threshold
      </span>
    ) : (
      <span>
        At least one threshold <br /> is required
      </span>
    );

    const lowerBound = staticThreshold.lowerBound || lowerBounds[1].value;
    const upperBound = staticThreshold.upperBound || upperBounds[1].value;

    return (
      <React.Fragment>
        {(alertType === alertTypes.static.value ||
          direction === serverAnomalyDirection.both ||
          direction === serverAnomalyDirection.up) && (
          <Box mt={2.5} automation-id="alertSettingStaticMetricValueLarger">
            <div className="text16med lineHeight_16 mb_1">Minimum threshold of Spikes</div>
            <Box mb={1.5} display="flex" alignItems="center">
              <TypographyBox variant="subtitle2" mr={1}>
                Trigger the alert only if it spikes
              </TypographyBox>
              <ThresholdBounds isUpBounds bound={upperBound} onSelect={this.handleBoundChange} />
            </Box>
            <Box width={114} height={36}>
              <SmartTooltip content={ttMesage}>
                <InputAuto
                  fullSize
                  allowEmpty
                  delay={1000}
                  type="number"
                  className={`dark-input ${isInvalid ? 'invalid' : ''}`}
                  isInvalid={isInvalid}
                  placeHolder="e.g 5000"
                  value={getStringValue(this.props.staticThreshold.maxValue)}
                  onChange={this.handleBlurMax}
                  additionalProps={{
                    name:
                      (isInvalid &&
                        (this.props.staticThreshold.maxValue === null ||
                          this.props.staticThreshold.maxValue === undefined) &&
                        'anomalyThresholdsFilter') ||
                      undefined,
                  }}
                />
              </SmartTooltip>
            </Box>
          </Box>
        )}

        {(alertType === alertTypes.static.value ||
          direction === serverAnomalyDirection.both ||
          direction === serverAnomalyDirection.down) && (
          <Box mt={2.5} automation-id="alertSettingStaticMetricValueSmaller">
            <div className="text16med lineHeight_16 mb_1">Minimum threshold of Drops</div>
            <Box mb={1.5} display="flex" alignItems="center">
              <TypographyBox variant="subtitle2" mr={1}>
                Trigger the alert only if it drops
              </TypographyBox>
              <ThresholdBounds isUpBounds={false} bound={lowerBound} onSelect={this.handleBoundChange} />
            </Box>
            <Box width={114} height={36}>
              <SmartTooltip content={ttMesage}>
                <InputAuto
                  fullSize
                  allowEmpty
                  delay={1000}
                  type="number"
                  className={`dark-input ${isInvalid ? 'invalid' : ''}`}
                  isInvalid={isInvalid}
                  placeHolder="e.g 2000"
                  value={getStringValue(this.props.staticThreshold.minValue)}
                  onChange={this.handleBlurMin}
                  additionalProps={{
                    name:
                      (isInvalid &&
                        (this.props.staticThreshold.minValue === null ||
                          this.props.staticThreshold.minValue === undefined) &&
                        'anomalyThresholdsFilter') ||
                      undefined,
                  }}
                />
              </SmartTooltip>
            </Box>
          </Box>
        )}
        <FieldError name="anomalyThresholdsFilter" />
      </React.Fragment>
    );
  }
}

export default StaticThresholdCondition;

// eslint-disable-next-line react/no-multi-comp
class ThresholdBounds extends React.PureComponent {
  props: {
    isUpBounds: Boolean,
    bound: String,
    onSelect: Function,
  };

  render() {
    const bounds = this.props.isUpBounds ? upperBounds : lowerBounds;
    const selectedIndex = bounds.findIndex((val) => val.value === this.props.bound);
    const formattedBounds = bounds.map((item) => ({...item, label: item.text.props.children}));

    return (
      <SelectAndt
        id={`ddl-threshold-${this.props.isUpBounds ? 'up' : 'down'}`}
        className="alerts-dropdown-btn"
        options={formattedBounds}
        menuWidth={180}
        buttonHeight={50}
        optionHeight={40}
        type={TYPE_NEW_NO_SEARCH}
        theme={THEME_BLUE_LEAN}
        value={formattedBounds[selectedIndex]}
        onChange={(v) => this.props.onSelect(this.props.isUpBounds, v.value)}
        placeholder={null}
        automationId="staticThresholdCondition"
      />
    );
  }
}
