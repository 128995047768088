// @flow
import StreamEditorCancelActions from 'bc/components/streams/editor/StreamEditorCancelActions';
import WizardStepMenu, {crumbs} from 'bc/components/streams/footer/WizardStepMenu';
import SavePartialStream from 'bc/components/streams/footer/SavePartialStream';
import Footer from 'bc/components/streams/footer/Footer';
import React from 'react';
import {connect} from 'react-redux';
import {fetchDataStreams, setSelectedDataStream, setSelectedEditor} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import GAStreamEditor from 'bc/components/streams/editor/byType/googleAnalytics/GoogleAnalyticsStreamEditor';
import GoogleAdsStreamEditor from 'bc/components/streams/editor/byType/googleAds/GoogleAdsStreamEditor';
import GoogleAuctionsStreamEditor from 'bc/components/streams/editor/byType/googleAuctions/GoogleAuctionsStreamEditor';
import AdobeAnalyticsStreamEditor from 'bc/components/streams/editor/byType/adobe/AdobeAnalyticsStreamEditor';
import FileUploadStreamEditor from 'bc/components/streams/editor/byType/fileUpload/FileUploadStreamEditor';
import S3StreamEditor from 'bc/components/streams/editor/byType/s3/S3StreamEditor';
import AwsCurStreamEditor from 'bc/components/streams/editor/byType/awsCur/AwsCurStreamEditor';
import ParquetStreamEditor from 'bc/components/streams/editor/byType/parquet/ParquetStreamEditor';
import GoogleStorageStreamEditor from 'bc/components/streams/editor/byType/googleStorage/GoogleStorageStreamEditor';
import SqlStreamEditor from 'bc/components/streams/editor/byType/sql/SqlStreamEditor';
import BigQueryEditor from 'bc/components/streams/editor/byType/bigQuery/BigQueryEditor';
import MParticleStreamEditor from 'bc/components/streams/editor/byType/mParticle/MParticleStreamEditor';
import KinesisStreamEditor from 'bc/components/streams/editor/byType/kinesis/KinesisStreamEditor';
import SalesforceStreamEditor from 'bc/components/streams/editor/byType/salesforce/SalesforceStreamEditor';
import SegmentStreamEditor from 'bc/components/streams/editor/byType/segment/SegmentStreamEditor';
import FacebookAdsStreamEditor from 'bc/components/streams/editor/byType/facebookAds/FacebookAdsStreamEditor';
import CoralogixStreamEditor from 'bc/components/streams/editor/byType/coralogix/CoralogixStreamEditor';
import StreamName from 'bc/components/streams/editor/StreamName';
import {bcTypes, getTypeDetails} from 'bc/services/bcTypes';
import Loader from 'common/components/Loader';
import {setRoute} from 'common/store/actions';
import {get} from 'lodash';
import AdBlockDetect from 'react-ad-block-detect';
import AdblockStreamModal from 'bc/components/streams/preview/AdblockStreamModal';
import Button, {COLORS} from 'common/componentsV2/Button';
import {isEditRunning} from 'bc/services/dataStreamService';
import './DataStreamEditor.module.scss';

type PropTypes = {
  setSelectedDataStream: Function,
  fetchDataStreams: Function,
  setSelectedEditor: Function,
  setRoute: Function,
  isLoading: boolean,
  dataStream: Object,
  match: Object,
  streamsUpdateAt: Number,
};

@connect(
  (state) => ({
    isLoading: selectors.getIsDataStreamsLoading(state),
    dataStream: selectors.getSelectedDataStream(state),
    streamsUpdateAt: selectors.getDataStreams(state).streams.updateAt,
  }),
  {
    fetchDataStreams,
    setSelectedDataStream,
    setSelectedEditor,
    setRoute,
  },
)
export default class DataStreamEditor extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    this.props.setSelectedDataStream(this.props.match.params.id);

    if (!this.props.streamsUpdateAt) {
      // if this is a fresh load of the app directly in the editor page,
      // then we need to load all streams (#!/bc/data-streams/<streamID>)
      this.props.fetchDataStreams();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.dataStream !== this.props.dataStream &&
      get(this.props.dataStream, 'queryServiceState') === 'complete'
    ) {
      this.props.setRoute(`/bc/data-streams/${this.props.dataStream.id}/preview`);
    }
  }

  componentWillUnmount() {
    this.props.setSelectedEditor();
  }

  isStreamRunning = () => this.props.dataStream && this.props.dataStream.state === 'running';

  // eslint-disable-next-line complexity
  getEditorByType = () => {
    if (this.props.dataStream) {
      switch (this.props.dataStream.type) {
        case bcTypes.google_ads.type:
          return <GoogleAdsStreamEditor />;
        case bcTypes.google_auctions.type:
          return <GoogleAuctionsStreamEditor />;
        case bcTypes.google_analytics.type:
          return <GAStreamEditor />;
        case bcTypes.adobe.type:
          return <AdobeAnalyticsStreamEditor />;
        case bcTypes.bigquery.type:
          return <BigQueryEditor />;
        case bcTypes.local_file.type:
          return <FileUploadStreamEditor />;
        case bcTypes.s3.type:
          return <S3StreamEditor />;
        case bcTypes.aws_cur.type:
          return <AwsCurStreamEditor />;
        case bcTypes.athena.type:
          return <ParquetStreamEditor />;
        case bcTypes.mysql.type:
        case bcTypes.psql.type:
        case bcTypes.mssql.type:
        case bcTypes.mariadb.type:
        case bcTypes.redshift.type:
        case bcTypes.snowflake.type:
        case bcTypes.oracle.type:
        case bcTypes.databricks.type:
        case bcTypes.teradata.type:
        case bcTypes.athena_sql.type:
        case bcTypes.timestream.type:
          return <SqlStreamEditor />;
        case bcTypes.mparticle.type:
          return <MParticleStreamEditor />;
        case bcTypes.google_storage.type:
          return <GoogleStorageStreamEditor />;
        case bcTypes.kinesis.type:
        case bcTypes.eventhubs.type:
          return <KinesisStreamEditor />;
        case bcTypes.salesforce.type:
          return <SalesforceStreamEditor />;
        case bcTypes.segment.type:
          return <SegmentStreamEditor />;
        case bcTypes.facebook_ads.type:
          return <FacebookAdsStreamEditor />;
        case bcTypes.coralogix.type:
          return <CoralogixStreamEditor />;
        default:
          return null;
      }
    }
    return null;
  };

  getEditorTitle = () => {
    if (this.isStreamRunning()) {
      return 'Edit Stream';
    }
    return 'Stream Query';
  };

  render() {
    if (this.props.isLoading !== false) {
      return <Loader />;
    }
    if (!this.props.dataStream) {
      return <div styleName="stream-not-found">The data stream you were looking for cannot be found</div>;
    }

    return (
      <div styleName="root">
        {this.props.dataStream.type === bcTypes.google_ads.type ? (
          <AdBlockDetect>
            <AdblockStreamModal isOpen="true" />
          </AdBlockDetect>
        ) : (
          ''
        )}

        <div styleName="header" automation-id="streamQueryHeader">
          <div styleName="title">
            <span className="andt-page-header">{this.getEditorTitle()}</span>
            <div automation-id="streamQueryNameTextbox">{getTypeDetails(this.props.dataStream.type).name}</div>
          </div>
          <StreamName />
        </div>
        <div styleName="editor">{this.getEditorByType()}</div>

        <Footer
          leftChildren={<StreamEditorCancelActions />}
          centerChildren={<WizardStepMenu current={crumbs.stream.key} />}
          rightChildren={
            [
              'mysql',
              'databricks',
              'psql',
              'mssql',
              'mariadb',
              'redshift',
              'bigquery',
              's3',
              'kinesis',
              'salesforce',
              'snowflake',
              'facebook_ads',
              'google_analytics',
              'teradata',
              'google_auctions',
              'coralogix',
              'athena_sql',
              'timestream',
            ].includes(this.props.dataStream.type) && isEditRunning(this.props.dataStream.id) ? (
              <SavePartialStream />
            ) : (
              <Button
                colorSchema={COLORS.BLUE_500}
                text="Stream"
                extraClassName="is-disabled"
                automation-id="dataCollectorStreamTableDisabledButton"
              />
            )
          }
        />
      </div>
    );
  }
}
