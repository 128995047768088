// @flow
import React, {PureComponent} from 'react';
import connect from 'react-redux/es/connect/connect';
import * as alertsConsoleSelectors from 'alerts.console/store/selectors';
import Checkbox from 'common/componentsV2/Checkbox';
import {ALERT_STATUS} from '../../../services/alertsConsoleService';

type PropTypes = {
  setQueryParams: Function,
  // connect
  queryParams: Object,
};

@connect(
  (state) => ({
    queryParams: alertsConsoleSelectors.getQueryParamsViews(state),
  }),
  {},
)
export default class AlertStatusFilter extends PureComponent {
  props: PropTypes;

  toggleFilter = (openOnly) => {
    const {setQueryParams} = this.props;
    const currentValue = openOnly ? ALERT_STATUS[1].value : ALERT_STATUS[0].value;

    setQueryParams({status: currentValue}); // undefined
  };

  render() {
    const {queryParams} = this.props;
    return (
      <Checkbox
        isChecked={queryParams.status === ALERT_STATUS[1].value}
        text="Show Open Alerts Only"
        onChange={this.toggleFilter}
      />
    );
  }
}
