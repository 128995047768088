// @flow
import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Box, makeStyles} from '@material-ui/core';
import Checkbox from 'common/componentsV2/Checkbox';
import CopyToClipboardButton from 'common/components/CopyToClipboardButton';
import MetricNameMenuDashboard from 'common/components/MetricNameMenuDashboard';
import MetricNameMenu from 'common/components/MetricNameMenu';
import NameFormat20 from 'metrics/components/metricName/NameFormat20';
import {getGraphColoringDashboardV2Enabled} from 'profile/store/selectors';
import {updateColor} from 'charts/timeSeries/services/timeSeriesHchartService';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '98%',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#e9f0ff !important',
    },
    '&:hover $copyButtonWrapper': {
      opacity: 1,
    },
  },
  checkboxWrapper: {
    '& input[type="checkbox"] + label::before': {
      color: ({index, metric, tileData}) => updateColor({index, metric, tileData}),
    },
    '& input[type="checkbox"]:checked + label::before': {
      color: ({index, metric, tileData}) => updateColor({index, metric, tileData}),
    },
    marginRight: '8px',
    marginTop: '2px',
    flexShrink: 0,
  },
  copyButtonWrapper: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    marginRight: '6px',
    opacity: 0,
    transition: '200ms',
    '& .copy-container-wrapper': {
      backgroundColor: 'transparent',
    },
  },
  valueWrapper: {
    flexShrink: 0,
    alignItems: 'center',
    backgroundColor: ({index, metric, tileData}) => updateColor({index, metric, tileData}),
    borderRadius: '10px',
    height: '18px',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'bold',
    paddingLeft: '4px',
    paddingRight: '4px',
    marginLeft: '8x',
    position: 'absolute',
    right: '0',
  },
}));

type PropTypes = {
  highlightSeries: Object,
  rowRefs: any,
  legendRefs: any,
  metrics: Array,
  index: number,
  style: any,
  hiddenSeriesMap: any,
  toggleVisibility: Function,
  tileData: Object,
  onChangeColor: Function,
  dashboardId: string,
};

const initialValueStyle = {display: 'none'};

const LegendRow = ({
  highlightSeries,
  rowRefs,
  legendRefs,
  metrics,
  index,
  style,
  hiddenSeriesMap,
  toggleVisibility,
  tileData,
  onChangeColor,
  dashboardId,
}: PropTypes) => {
  const metric = metrics[index];
  const classes = useStyles({index, tileData, metric});

  // eslint-disable-next-line no-return-assign,no-param-reassign
  const rowRefsMemoized = useCallback((el) => (rowRefs.current[index] = el), [index]);
  // eslint-disable-next-line no-return-assign,no-param-reassign
  const legendRefsMemoized = useCallback((el) => (legendRefs.current[index] = el), [index]);

  const onMouseOver = useCallback(() => highlightSeries(index), [index]);
  const onMouseOut = useCallback(() => highlightSeries(), []);
  const isEnabledGraphColoring = useSelector(getGraphColoringDashboardV2Enabled);

  // Note - isMetricName - from the CopyToClipboardButton will open the DropDown menu
  // Delete it to make the copy work as a stringified object
  return (
    <div
      className={classes.wrapper}
      style={style}
      ref={rowRefsMemoized}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      automation-id={`metricExplorerMetricPresentation_${index + 1}`}
    >
      <div className={classes.checkboxWrapper}>
        <Checkbox isChecked={!hiddenSeriesMap[index]} onChange={() => toggleVisibility(index)} />
      </div>
      <Box position="relative" css={{'&:hover .copy-wrapper': {opacity: '1'}}}>
        <NameFormat20 isSingleLine isSpecialHeader={false} metric={metric} highlightText={null}>
          <div className={classes.copyButtonWrapper}>
            <CopyToClipboardButton
              forceHiddenOverflow
              copyStr={metric}
              shouldUseIcon
              metricNameMenu={
                dashboardId && isEnabledGraphColoring ? (
                  <MetricNameMenuDashboard
                    metric={metric}
                    tileData={tileData}
                    onChangeColor={onChangeColor}
                    index={index}
                    dashboardId={dashboardId}
                  />
                ) : (
                  <MetricNameMenu metric={metric} />
                )
              }
            />
          </div>
        </NameFormat20>
      </Box>
      <div
        className={classes.valueWrapper}
        ref={legendRefsMemoized}
        style={initialValueStyle}
        automation-id={`metricExplorerMetricDataPoints_${index + 1}`}
      >
        -
      </div>
    </div>
  );
};

export default React.memo(LegendRow);
