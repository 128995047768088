import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './IntegrationsBox.module.scss';
import Button, {COLORS} from 'common/componentsV2/Button';
import {useSelector} from 'react-redux';
import {getOrganizationFeatureFlags, getUserProfile} from 'profile/store/selectors';
import {getDataSources} from 'bc/store/selectors';
import {createDataSource as createDataSourceAction} from 'bc/store/actions';
import NewStreamBySourceModal from 'bc/components/dataManager/NewStreamBySourceModal';
import Highlighter from 'react-highlight-words';
import {StringParam, useQueryParams} from 'use-query-params';
import {useHistory} from 'react-router-dom';
import {getIsSourceInUse, getAction, getIcon} from './IntegrationsPageService';
import FileUploadToAws from '../../components/dataSourceTypes/FileUploadToAws';

type PropTypes = {
  boxData: Object,
  isFeatured: boolean,
  isChannel: boolean,
};

const IntegrationsBox = ({boxData, isFeatured, isChannel}: PropTypes) => {
  const history = useHistory();
  const organizationFeatureFlags = useSelector(getOrganizationFeatureFlags);
  const dataSources = useSelector(getDataSources);
  const me = useSelector(getUserProfile);
  const [isCreateSourceModalOpen, setCreateSourceModalOpen] = useState(false);
  const [isNewStreamModalOpen, setNewStreamModalOpen] = useState(false);
  const [isUseCreateAnotherModal, setUseCreateAnotherModal] = useState(false);
  const createDataSource = createDataSourceAction;

  const isInUse = getIsSourceInUse(boxData, dataSources);
  const icon = getIcon(boxData.useIcon || boxData.type, isChannel);

  const isFeatureFlagOpen = useMemo(() => {
    if (typeof boxData.featureFlag === 'undefined') {
      return true;
    }
    return organizationFeatureFlags[boxData.featureFlag];
  }, [boxData, organizationFeatureFlags]);

  const [query] = useQueryParams({
    selectedCategory: StringParam,
    filterText: StringParam,
    selectedTab: StringParam,
    origin: StringParam,
  });

  const {selectedCategory, filterText, selectedTab, origin} = query;

  useEffect(() => {
    if (origin !== 'newStreamsModal') {
      setUseCreateAnotherModal(isInUse);
    }
  }, [isInUse, origin]);

  const handleAddSourceClick = useCallback(() => {
    if (boxData.actionLink && boxData.actionLink.length > 0) {
      window.open(boxData.actionLink, '_blank');
    } else {
      setCreateSourceModalOpen(true);
    }
    if (isChannel) {
      const search = `filterText=${filterText}&selectedCategory=${selectedCategory}&selectedTab=${selectedTab}`;
      history.push(`/integrations/new/${boxData.type}?${search}`);
    }
  }, [history.location.search]);

  const openActionModal = () => {
    setUseCreateAnotherModal(false);
    setCreateSourceModalOpen(true);
    setNewStreamModalOpen(false);
  };

  const actionModal = useMemo(() => {
    if (isChannel) {
      return null;
    }

    const action = getAction(boxData, isChannel);

    if (isCreateSourceModalOpen || isNewStreamModalOpen) {
      if (isUseCreateAnotherModal) {
        setNewStreamModalOpen(true);
        return null;
      }
      const modal = action(boxData, null, createDataSource, false, me);
      return React.cloneElement(modal, {
        isOpen: isCreateSourceModalOpen,
        onClose: () => setCreateSourceModalOpen(false),
      });
    }
    return null;
  }, [isCreateSourceModalOpen, isNewStreamModalOpen, isChannel]);

  const actionButton = useMemo(() => {
    if (boxData.type === 'local_file') {
      return (
        <div styleName="action" href="" data-segment="name: file-upload, category: data-collectors">
          <FileUploadToAws text="Start" sourceType={boxData} />
        </div>
      );
    }
    if (isFeatureFlagOpen) {
      return <Button text={boxData.actionText} colorSchema={COLORS.BLUE_500} onClick={handleAddSourceClick} />;
    }
    return null;
  }, [boxData]);

  return (
    <div styleName={['container', isFeatured ? 'featured' : ''].join(' ')}>
      <div>{icon}</div>
      {isInUse && <div styleName="inUse">In Use</div>}
      <div styleName="header">
        <div styleName="title">
          <Highlighter autoEscape searchWords={[filterText]} textToHighlight={boxData.name} />
        </div>
        {boxData.isNew && <div styleName="new">New</div>}
        {boxData.isSoon && <div styleName="soon">Soon</div>}
      </div>
      <div styleName="description">{boxData.description}</div>
      <div styleName="button-container">{actionButton}</div>
      <div>{actionModal}</div>
      {isNewStreamModalOpen && (
        <NewStreamBySourceModal
          isOpen={isCreateSourceModalOpen}
          onClose={() => setCreateSourceModalOpen(false)}
          isSingleSource
          sourceType={boxData.type}
          sourceName={boxData.name}
          onAddNewSource={openActionModal}
        />
      )}
    </div>
  );
};

export default IntegrationsBox;
