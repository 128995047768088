import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {Observable} from 'rxjs/Observable';
import {getBucketStartTimeEnabled} from 'profile/store/selectors';
import {getMetricResolutions} from 'metrics/store/selectors';
import * as actions from 'topologyGeneral/store/actions';
import * as selectors from 'topologyGeneral/store/selectors';
import * as api from 'topologyGeneral/services/api';
import * as mapService from 'topologyGeneral/services/mapService';
import {MAX_METRICS_FOR_SELECTED_ISSUE} from 'topologyGeneral/services/sidePanelService';
import moment from 'moment';
import {get, omit} from 'lodash';

import Highcharts from 'highcharts';
import {generateChartSeriesMetricModel, processSeriesData} from 'charts/timeSeries/services/timeSeriesDataService';
import {endLoad, pushSeries, startLoad} from 'charts/timeSeries/services/timeSeriesHchartService';
import {getApiTreeForNode, getEmptyTree} from 'common/utils/angularServices';
import {getDateValue, rangeTypes} from 'common/utils/dateRangeService';
import * as chartActions from 'charts/timeSeries/store/actions';

const timeSeriesCharts = new Map();

const BUFFER_TIMES = {
  alerts: {
    '1m': 120,
    '5m': 600,
    '1h': 3600,
    '1d': 86400,
    '1w': 0,
  },
  anomalies: {
    '1m': 60 * 60 * 13,
    '5m': 60 * 60 * 13,
    '1h': 60 * 60 * 24 * 15,
    '1d': 60 * 60 * 24 * 15,
    '1w': 60 * 60 * 24 * 15,
  },
};

const cleanId = (dirtyId, prefix) => {
  return `${prefix}_${mapService.cleanForLegalId(dirtyId)}`;
};

const createRegionItem = (data, level, isLeaf) => {
  const nodesTotal = data.nodesCount;

  return {
    parentRegionId: data.parentRegionId ? cleanId(data.parentRegionId, 'reg') : undefined,
    serverParentRegionId: data.parentRegionId || undefined,
    id: cleanId(data.id, 'reg'),
    serverId: data.id,
    title: data.name,
    nodeIds: data.nodeIds,
    siteIds: data.siteIds,
    nodesTotal, // will be part of data in future
    geoPoint: [data.geoLocation.lat, data.geoLocation.lon],
    level,
    isLeaf,
  };
};

const createSiteItem = (data, region) => {
  const nodesTotal = data.nodes.length;

  return {
    ...omit(data, ['parentRegionId', 'geoLocation', 'cells', 'nodes', 'domainsCount', 'domains', 'id', 'name']),
    parentRegionName: region ? region.title : '',
    parentRegionId: data.parentRegionId ? cleanId(data.parentRegionId, 'reg') : undefined,
    serverParentRegionId: data.parentRegionId || undefined,
    id: cleanId(data.id, 'site'),
    serverId: data.id,
    title: data.name,
    nodes: data.nodes || [],
    cells: (data.cells || []).reduce(
      (acc, curVal) => {
        const azimuth = parseInt(curVal.azimuth, 10);
        if (azimuth > 60 && azimuth <= 180) {
          acc.rightWing.push(curVal);
        } else if (azimuth > 180 && azimuth <= 300) {
          acc.leftWing.push(curVal);
        } else {
          acc.topWing.push(curVal);
        }
        return acc;
      },
      {leftWing: [], rightWing: [], topWing: []},
    ),
    nodesTotal,
    geoPoint: [data.geoLocation.lat, data.geoLocation.lon],
  };
};

const createDomainCarouselItem = (data) => {
  return {
    id: data.id,
    title: data.name,
    nodesTotal: data.nodeCount,
    nodeIds: data.nodeIds,
    cellIds: data.cellIds,
  };
};

const processAnomalyIntervals = (anomalyIntervals) => {
  if (!anomalyIntervals || anomalyIntervals.length === 0) {
    return null;
  }

  return (anomalyIntervals || []).map((a) => ({
    startTime: a.startDate,
    endTime: a.endDate,
    duration: null,
    status: a.state,
    score: a.score,
    direction: a.directionUp ? 'UP' : 'DOWN',
    peak: a.peakValue,
    deltaAbsolute: a.absoluteDelta,
    deltaPercentage: a.percentageDelta,
  }));
};

const extractMetricAndProperties = (res, metrics, timeScale, issueType) => {
  metrics.forEach((metric) => {
    let startTime = null;
    let endTime = null;
    switch (issueType) {
      case 'alerts':
        startTime = metric.displayStartTime;
        endTime = metric.displayEndTime + (BUFFER_TIMES.alerts[timeScale] || 0);
        break;
      case 'anomalies':
        startTime = metric.currentAnomalyIntervals[0].startDate - (BUFFER_TIMES.anomalies[timeScale] || 0);
        endTime = Math.min(
          metric.currentAnomalyIntervals[0].endDate + (BUFFER_TIMES.anomalies[timeScale] || 0),
          Math.floor(moment.now() / 1000),
        );
        break;
      default:
    }

    res.push({
      metricId: metric.id,
      intervals: metric.intervals || null,
      currentAnomalyIntervals: processAnomalyIntervals(metric.currentAnomalyIntervals),
      otherAnomalyIntervals: processAnomalyIntervals(metric.otherAnomalyIntervals),
      what: metric.what,
      properties: metric.properties,
      tags: metric.tags,
      origin: metric.origin,
      name: metric.name,
      entityMapping: metric.entityMapping,
      startTime,
      endTime,
    });
  });
};

const generateIssueFromAlert = (alert, issueType, resolutions) => {
  const isOpen = alert.status === 'OPEN';
  const isNoData = alert.type === 'noData';
  const isAnomaly = alert.type === 'anomaly';
  const isStatic = alert.type === 'static';
  const resolution = Object.values(resolutions).find((a) => a.value2 === alert.timeScale).value;
  const total = alert.summary.totalMetrics;
  const metrics = [];
  extractMetricAndProperties(metrics, alert.metrics, alert.timeScale, issueType);

  let investigationUrl = null;
  if (isAnomaly) {
    investigationUrl = `/#!/anomalies?tabs=main;0&activeTab=1&anomalies=;0(${alert.groupId})&duration=;1(1)&durationScale=;minutes(minutes)&delta=;1(1)&deltaType=;percentage(percentage)&resolution=;${resolution}(${resolution})&score=;0(0)&state=;both(both)&direction=;both(both)&alertId=;(${alert.id})&sort=;significance(significance)&q=;()&constRange=;1h(c)&startDate=;0(0)&endDate=;0(0)`;
  }

  return {
    id: alert.id,
    alertId: alert.id,
    anomalyId: alert.groupId,
    name: alert.title,
    entityMapping: alert.entityMapping,
    startTime: alert.startTime,
    isOpen,
    isNoData,
    isStatic,
    isAnomaly,
    total,
    totalText: 'Metrics',
    resolution,
    timeScale: alert.timeScale,
    investigationUrl,
    metrics: metrics.slice(0, MAX_METRICS_FOR_SELECTED_ISSUE),
  };
};

const generateIssuesFromAlertGroups = (alerts, issueType, resolutions) => {
  if (!alerts || !alerts.length) {
    return [];
  }

  const issues = alerts.map((alert) => {
    return generateIssueFromAlert(alert, issueType, resolutions);
  });

  return issues.sort((a, b) => b.startTime - a.startTime);
};

const generateIssueFromAnomaly = (anomaly, issueType, resolutions) => {
  const isOpen = anomaly.state === 'open';
  const timeScale = Object.values(resolutions).find((a) => a.value === anomaly.resolution).value2;
  const {total} = anomaly.metricsCount;
  const metrics = [];
  extractMetricAndProperties(metrics, anomaly.metrics, timeScale, issueType);

  const investigationUrl = `/#!/anomalies?tabs=main;0&activeTab=1&anomalies=;0(${anomaly.id})&duration=;1(1)&durationScale=;minutes(minutes)&delta=;1(1)&deltaType=;percentage(percentage)&resolution=;${anomaly.resolution}(${anomaly.resolution})&score=;0(0)&state=;both(both)&direction=;both(both)&sort=;significance(significance)&q=;()&constRange=;1h(c)&startDate=;0(0)&endDate=;0(0)`;

  return {
    id: anomaly.id,
    alertId: null,
    anomalyId: anomaly.id,
    name: anomaly.metrics[0].what,
    entityMapping: anomaly.entityMapping,
    startTime: anomaly.startDate,
    isOpen,
    isNoData: false,
    isStatic: false,
    isAnomaly: true,
    total,
    totalText: 'Metrics',
    resolution: anomaly.resolution,
    timeScale,
    investigationUrl,
    metrics: metrics.slice(0, MAX_METRICS_FOR_SELECTED_ISSUE),
  };
};

const generateIssuesFromAnomalies = (anomalies, issueType, resolutions) => {
  if (!anomalies || !anomalies.length) {
    return [];
  }

  const issues = anomalies.map((anomaly) => {
    return generateIssueFromAnomaly(anomaly, issueType, resolutions);
  });

  return issues;
};

const fetchTopologyMapRegions = makeAsyncEpic(actions.fetchTopologyMapRegions, api.fetchTopologyMapRegions);
const fetchTopologyAnomalies = makeAsyncEpic(actions.fetchTopologyAnomalies, api.fetchTopologyAnomalies);
const fetchTopologyAlerts = makeAsyncEpic(actions.fetchTopologyAlerts, api.fetchTopologyAlerts);
const fetchTopologyAnomaly = makeAsyncEpic(actions.fetchTopologyAnomaly, api.fetchTopologyAnomaly);
const fetchTopologyAlert = makeAsyncEpic(actions.fetchTopologyAlert, api.fetchTopologyAlert);

const fetchTopologyMapRegionsSuccess = (action$) =>
  action$.ofType(actions.fetchTopologyMapRegions.success.TYPE).switchMap(({payload}) => {
    const createRegionItemList = (regions, ids, level, result) => {
      if (!regions || !regions.length || level > 10) {
        return;
      }

      if (!ids) {
        const other = regions.filter((r) => r.parentRegionId);
        const thisLevel = regions.filter((r) => !r.parentRegionId);
        const processed = thisLevel.map((r) => {
          const hasChild = other.some((it) => it.parentRegionId === r.id);
          return createRegionItem(r, level, !hasChild);
        });
        result.push(...processed);
        createRegionItemList(other, thisLevel.map((l) => l.id), 1, result);
      } else {
        const other1 = regions.filter((r) => ids.indexOf(r.parentRegionId) === -1);
        const thisLevel1 = regions.filter((r) => ids.indexOf(r.parentRegionId) !== -1);
        const processed1 = thisLevel1.map((r) => {
          const hasChild = other1.some((it) => it.parentRegionId === r.id);
          return createRegionItem(r, level, !hasChild);
        });
        result.push(...processed1);
        createRegionItemList(other1, thisLevel1.map((l) => l.id), level + 1, result);
      }
    };

    const res = [];
    createRegionItemList(payload.regions, null, 0, res);

    return [actions.setViewsKeyVal({regions: res.sort((a, b) => b.percent - a.percent)})];
  });

const fetchTopologyMapRegionSitesList = (action$, {getState}) =>
  action$.ofType(actions.fetchTopologyMapRegionSitesList.TYPE).switchMap((action) => {
    const curState = getState();
    const curData = selectors.getFetchGtpMapRegionsSitesListData(curState);

    if (curData.regionId === action.payload.regionId) {
      return [actions.fetchTopologyMapRegionSitesList.success(curData, action.meta)];
    }

    return api
      .fetchTopologyMapRegionSitesList(action, getState)
      .map((payload) => actions.fetchTopologyMapRegionSitesList.success(payload, action.meta))
      .catch((error) => Observable.of(actions.fetchTopologyMapRegionSitesList.failure(error, action.meta)));
  });

const fetchTopologyMapRegionSitesListSuccess = (action$) =>
  action$.ofType(actions.fetchTopologyMapRegionSitesList.success.TYPE).switchMap(({payload, meta}) => {
    const sites = payload.sites.map((site) => createSiteItem(site, meta.region));
    const domains = Object.values(payload.domains).map((dom) => createDomainCarouselItem(dom));

    if (meta && meta.map) {
      const {current: map} = meta.map;
      const sitesBounds = mapService.getEntitiesBounds(sites);

      if (sitesBounds && sitesBounds.center) {
        map.setView(sitesBounds.center);
      } else if (sitesBounds && sitesBounds.maxPoint) {
        map.fitBounds([sitesBounds.minPoint, sitesBounds.maxPoint], {
          maxZoom: mapService.MAX_SITES_ZOOM,
          padding: [0, 0],
          animate: false,
        });
      }
    }

    return [
      actions.setViewsKeyVal({
        sitesList: {
          regionId: payload.regionId,
          total: payload.total,
        },
        sites,
        domains,
        links: payload.links,
      }),
    ];
  });

const getTopologyAlerts = (action$, {getState}) =>
  action$.ofType(actions.getTopologyAlerts.TYPE).switchMap(({payload}) => {
    const startBucketMode = getBucketStartTimeEnabled(getState());
    if (startBucketMode) {
      return [
        actions.fetchTopologyAlerts({...payload, startBucketMode}),
        actions.setViewsKeyVal({isFetchingIssues: true}),
      ];
    }
    return [actions.fetchTopologyAlerts(payload), actions.setViewsKeyVal({isFetchingIssues: true})];
  });

const getTopologyAnomalies = (action$, {getState}) =>
  action$.ofType(actions.getTopologyAnomalies.TYPE).switchMap(({payload}) => {
    const startBucketMode = getBucketStartTimeEnabled(getState());
    if (startBucketMode) {
      return [
        actions.fetchTopologyAnomalies({...payload, startBucketMode}),
        actions.setViewsKeyVal({isFetchingIssues: true}),
      ];
    }
    return [actions.fetchTopologyAnomalies(payload), actions.setViewsKeyVal({isFetchingIssues: true})];
  });

const fetchTopologyAnomaliesSuccess = (action$, {getState}) =>
  action$.ofType(actions.fetchTopologyAnomalies.success.TYPE).switchMap(({payload}) => {
    const curState = getState();
    const resolutions = getMetricResolutions(curState);
    if (!payload || !payload.anomalies || !payload.anomalies.length) {
      return [actions.setViewsKeyVal({issues: [], isFetchingIssues: false})];
    }

    const issues = generateIssuesFromAnomalies(payload.anomalies, 'anomalies', resolutions);
    return [actions.setViewsKeyVal({issues, isFetchingIssues: false})];
  });

const fetchTopologyAlertsSuccess = (action$, {getState}) =>
  action$.ofType(actions.fetchTopologyAlerts.success.TYPE).switchMap(({payload}) => {
    const curState = getState();
    const resolutions = getMetricResolutions(curState);
    if (!payload || !payload.alerts || !payload.alerts.length) {
      return [actions.setViewsKeyVal({issues: [], isFetchingIssues: false})];
    }

    const issues = generateIssuesFromAlertGroups(payload.alerts, 'alerts', resolutions);
    return [actions.setViewsKeyVal({issues, isFetchingIssues: false})];
  });

const fetchTopologyAnomaliesFailure = (action$) =>
  action$.ofType(actions.fetchTopologyAnomalies.failure.TYPE).switchMap(() => {
    return [actions.setViewsKeyVal({isFetchingIssues: false})];
  });

const fetchTopologyAlertsFailure = (action$) =>
  action$.ofType(actions.fetchTopologyAlerts.failure.TYPE).switchMap(() => {
    return [actions.setViewsKeyVal({isFetchingIssues: false})];
  });

const getSingleTopologyIssue = (action$, {getState}) =>
  action$.ofType(actions.getSingleTopologyIssue.TYPE).switchMap(({payload}) => {
    const startBucketMode = getBucketStartTimeEnabled(getState());

    if (payload.issuesType === 'alerts') {
      return [
        actions.fetchTopologyAlert({
          alertId: payload.issueId,
          qs: {
            startBucketMode,
          },
        }),
        actions.setViewsKeyVal({isFetchingSingleIssue: true}),
      ];
    }
    if (payload.issuesType === 'anomalies') {
      const dateRange = getDateValue(rangeTypes.y1.key, true);
      return [
        actions.fetchTopologyAnomaly({
          anomalyId: payload.issueId,
          qs: {
            startBucketMode,
            startDate: dateRange.startDate,
          },
        }),
        actions.setViewsKeyVal({isFetchingSingleIssue: true}),
      ];
    }
    return [];
  });

const fetchTopologyAlertSuccess = (action$, {getState}) =>
  action$.ofType(actions.fetchTopologyAlert.success.TYPE).switchMap(({payload}) => {
    const curState = getState();
    const resolutions = getMetricResolutions(curState);
    const payloadIssue = generateIssueFromAlert(payload.alert, 'alerts', resolutions);
    return [
      actions.setViewsKeyVal({
        selectedIssue: payloadIssue,
        isFetchingSingleIssue: false,
      }),
    ];
  });

const fetchTopologyAnomalySuccess = (action$, {getState}) =>
  action$.ofType(actions.fetchTopologyAnomaly.success.TYPE).switchMap(({payload}) => {
    const curState = getState();
    const resolutions = getMetricResolutions(curState);
    const payloadIssue = generateIssueFromAnomaly(payload.anomaly, 'anomalies', resolutions);
    return [
      actions.setViewsKeyVal({
        selectedIssue: payloadIssue,
        isFetchingSingleIssue: false,
      }),
    ];
  });

const fetchTopologyAlertFailure = (action$) =>
  action$.ofType(actions.fetchTopologyAlert.failure.TYPE).switchMap(() => {
    return [actions.setViewsKeyVal({selectedIssue: null, isFetchingSingleIssue: false})];
  });

const fetchTopologyAnomalyFailure = (action$) =>
  action$.ofType(actions.fetchTopologyAnomaly.failure.TYPE).switchMap(() => {
    return [actions.setViewsKeyVal({selectedIssue: null, isFetchingSingleIssue: false})];
  });

/**
 * Metric Charts and Data Points - Section
 */
const fetchMetricDataPoints = (action$, {getState}) =>
  action$.ofType(actions.fetchMetricDataPoints.TYPE).flatMap((action) => {
    const curState = getState();
    const selectedIssue = selectors.getGtpMapSelectedIssue(curState);
    const startBucketMode = getBucketStartTimeEnabled(curState);
    const includeBaseline = false;

    const hchart = timeSeriesCharts.get(action.meta.chartId);
    const data = selectors.getTMFetchMetricDataPoints(curState)[action.meta.chartId];

    startLoad(hchart);

    const newMeta = {
      ...action.meta,
      isNoData: selectedIssue.isNoData,
    };

    if (data && data.data) {
      return [actions.fetchMetricDataPoints.success(data.data, newMeta)];
    }

    const res = getEmptyTree();
    res.expressionTree.root.searchObject = {
      ids: [action.payload],
    };

    const newAction = {
      ...action,
      payload: {
        startTime: action.meta.startTime,
        endTime: action.meta.endTime,
        includeBaseline,
        startBucketMode,
        resolution: selectedIssue.resolution,
        body: {
          composite: getApiTreeForNode(res.expressionTree.root, res, null, {
            issueId: selectedIssue.id,
            context: 'generalTopologyIssuesPanel',
          }),
        },
      },
    };

    return api
      .fetchMetricDataPoints(newAction, getState)
      .map((payload) => actions.fetchMetricDataPoints.success(payload, newMeta))
      .catch((error) => Observable.of(actions.fetchMetricDataPoints.failure(error, newMeta)));
  });

const fetchMetricDataPointsSuccess = (action$) =>
  action$
    .ofType(actions.fetchMetricDataPoints.success.TYPE)
    .do(({payload, meta}) => {
      const hchart = timeSeriesCharts.get(meta.chartId);

      if (!hchart) {
        return; // console item was probably collapsed
      }
      if (!get(payload, 'metrics.length') || !payload.validation.passed) {
        endLoad(hchart, meta.startDate, meta.endDate);
        return;
      }

      const seriesProperties = {byTreeExp: []};
      const metricData = payload.metrics[0];
      const metricsModel = generateChartSeriesMetricModel(metricData);
      const processedSeriesData = processSeriesData(
        metricData.dataPoints,
        metricData.baseline,
        !meta.isNoData ? meta.intervals || meta.currentAnomalyIntervals : null,
        meta.otherAnomalyIntervals,
      );
      pushSeries(hchart, seriesProperties, metricsModel, processedSeriesData, true);

      endLoad(hchart, meta.startDate, meta.endDate);
    })
    .flatMap(() => []);

const fetchMetricDataPointsFailure = (action$) =>
  action$
    .ofType(actions.fetchMetricDataPoints.failure.TYPE)
    .do(({meta}) => {
      const hchart = timeSeriesCharts.get(meta.chartId);
      if (hchart) {
        return endLoad(hchart);
      }
      return null;
    })
    .flatMap(() => []);

const highChartCreated = (action$) =>
  action$
    .ofType(chartActions.highChartCreated.TYPE)
    .do((action) => {
      const hchart = Highcharts.charts.find((chart) => chart && chart.renderTo.id === action.payload.chartId);
      timeSeriesCharts.set(action.payload.chartId, hchart);
      startLoad(hchart);
    })
    .flatMap(() => []);

const highChartDestroyed = (action$) =>
  action$
    .ofType(chartActions.highChartDestroyed.TYPE)
    .do((action) => {
      timeSeriesCharts.delete(action.payload.chartId);
    })
    .flatMap(() => []);

export default combineEpics(
  fetchTopologyMapRegions,
  fetchTopologyMapRegionsSuccess,
  fetchTopologyMapRegionSitesList,
  fetchTopologyMapRegionSitesListSuccess,
  fetchTopologyAnomalies,
  fetchTopologyAlerts,
  getTopologyAlerts,
  getTopologyAnomalies,
  fetchTopologyAnomaliesSuccess,
  fetchTopologyAlertsSuccess,
  fetchTopologyAnomaliesFailure,
  fetchTopologyAlertsFailure,

  fetchTopologyAnomaly,
  fetchTopologyAlert,
  getSingleTopologyIssue,
  fetchTopologyAlertSuccess,
  fetchTopologyAnomalySuccess,
  fetchTopologyAlertFailure,
  fetchTopologyAnomalyFailure,

  fetchMetricDataPoints,
  fetchMetricDataPointsSuccess,
  fetchMetricDataPointsFailure,
  highChartCreated,
  highChartDestroyed,
);
