// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import FiltersPanel from 'common/componentsV2/FiltersPanel';
import {setDataManagerFiltersIsOpen} from 'bc/store/actions';
import {getDataManagerFiltersIsOpen, getDataMangerQueryParamsIsChanged} from 'bc/store/selectors';
import {DEFAULT_QUERY_PARAMS} from 'bc/services/dataManagerService';
import SearchQueryFilter from './SearchQueryFilter';
import StatusFilter from './StatusFilter';
import TypeFilter from './TypeFilter';
import OwnerFilter from './OwnerFilter';

import './DataManagerFilters.module.scss';

type PropTypes = {
  setQueryParams: Function,
  setDataManagerFiltersIsOpen: Function,

  // connect
  isFilterBarOpen: Boolean,
  isQueryParamChanged: Boolean,
};

@connect(
  (state) => ({
    isFilterBarOpen: getDataManagerFiltersIsOpen(state),
    isQueryParamChanged: getDataMangerQueryParamsIsChanged(state),
  }),
  {
    setDataManagerFiltersIsOpen,
  },
)
export default class DataManagerFilters extends React.PureComponent {
  props: PropTypes;

  defaultQueryParams = {...DEFAULT_QUERY_PARAMS};

  onCloseFiltersClicked = () => {
    this.props.setDataManagerFiltersIsOpen(false);
  };

  resetFilters = () => {
    this.props.setQueryParams({
      searchQuery: DEFAULT_QUERY_PARAMS.searchQuery,
      status: DEFAULT_QUERY_PARAMS.status,
      type: DEFAULT_QUERY_PARAMS.type,
      owner: DEFAULT_QUERY_PARAMS.owner,
    });
  };

  render() {
    const {setQueryParams, isFilterBarOpen, isQueryParamChanged} = this.props;

    return (
      <FiltersPanel
        isOpen={isFilterBarOpen}
        isClearAll={isQueryParamChanged}
        onClearAll={this.resetFilters}
        isSaveView={false}
        isShareLink={false}
        onClose={this.onCloseFiltersClicked}
      >
        <div styleName="filters-row">
          <SearchQueryFilter setQueryParams={setQueryParams} />
        </div>
        <div styleName="filters-row">
          <span styleName="filters-row-header">Stream Status</span>
          <StatusFilter setQueryParams={setQueryParams} />
        </div>
        <div styleName="filters-row">
          <span styleName="filters-row-header">Owner</span>
          <OwnerFilter setQueryParams={setQueryParams} />
        </div>
        <div styleName="filters-row">
          <span styleName="filters-row-header">Source Type</span>
          <TypeFilter setQueryParams={setQueryParams} />
        </div>
      </FiltersPanel>
    );
  }
}
