import {makeAction, makeAsyncAction} from 'common/utils/simplifiedAsync';

const mac = (actionName) => makeAction(actionName, 'anodot/metrics/');

const macAsync = (actionName) => makeAsyncAction(actionName, 'anodot/metrics/');

export const setMetricsResolutions = mac('SET_METRICS_RESOLUTIONS');
export const fetchCustomerMetricsCount = macAsync('FETCH_CUSTOMER_METRICS_COUNT');
export const fetchMetricsTotal = macAsync('FETCH_METRICS_TOTAL');

export const fetchOriginTitleCache = macAsync('FETCH_ORIGIN_TITLE_CACHE');
export const fetchCompositeName = macAsync('FETCH_COMPOSITE_NAME');

export const fetchPropAndVal = macAsync('FETCH_PROP_AND_VAL');
export const fetchPropAndValListApi = macAsync('FETCH_PROP_AND_VAL_LIST_API');
export const fetchPropAndValList = mac('FETCH_PROP_AND_VAL_LIST');
export const retrievePropAndValListCached = mac('RETRIEVE_PROP_AND_VAL_LIST_CACHED');
export const fetchProps = mac('FETCH_PROPS');
export const fetchPropsApi = macAsync('FETCH_PROPS_API');
export const retrievePropsCached = mac('RETRIEVE_PROPS_CACHED');
export const execute = macAsync('EXECUTE');
export const executeAllTrees = mac('EXECUTE_ALL_TREES');
export const validate = macAsync('VALIDATE');
export const setPreviewTimeRange = mac('SET_PREVIEW_TIME_RANGE'); // GABPAC todo -- temporary
export const setExpressionBuilderContext = mac('SET_EXPRESSION_BUILDER_CONTEXT');
export const updateSimulation = mac('UPDATE_SIMULATION');
export const resetPreviewPagination = mac('RESET_PREVIEW_PAGINATION');
export const fetchFunctionsMetaData = mac('FETCH_FUNCTIONS_META_DATA');
export const fetchFunctionsMetaDataApi = macAsync('FETCH_FUNCTIONS_META_DATA_API');
export const fetchOriginInfo = macAsync('FETCH_ORIGIN_INFO');
export const fetchPropertiesApi = macAsync('FETCH_PROPERTIES_API');
export const fetchProperties = mac('FETCH_PROPERTIES');
export const fetchComposites = macAsync('FETCH_COMPOSITES');
export const createComposite = macAsync('CREATE_COMPOSITE');
export const resetValidation = mac('RESET_VALIDATION');

export const setCombinedMetric = mac('SET_COMBINED_METRIC');
export const setExpression = mac('SET_EXPRESSION');
export const setAllExpressionsSelected = mac('SET_ALL_EXPRESSIONS_SELECTED');
export const setMergedMetrics = mac('SET_MERGED_METRICS');
export const setMergedMetricsLoading = mac('SET_MERGED_METRICS_LOADING');
export const setCompositeObject = mac('SET_COMPOSITE_OBJECT');
export const setFunctionParameter = mac('SET_FUNCTION_PARAMETER');
export const setFunction = mac('SET_FUNCTION');
export const createNewFunction = mac('SET_NEW_FUNCTION');
export const deleteFunction = mac('DELETE_FUNCTION');
export const selectTreeBranch = mac('SELECT_TREE_BRANCH');
export const setTreeOutsideState = mac('SET_TREE_OUTSIDE_STATE');
export const processTreeOutsideState = mac('PROCESS_TREE_OUTSIDE_STATE');

export const initExpressionTreeModel = mac('INIT_EXPRESSION_TREE_MODEL');
export const initAlertSettings = mac('INIT_ALERT_SETTINGS');
export const expressionTreeUpdated = mac('EXPRESSION_TREE_UPDATED');
export const setLastExpressionSearch = macAsync('SET_LAST_EXPRESSION_SEARCH');
export const fetchLastExpressionSearches = macAsync('FETCH_LAST_EXPRESSION_SEARCHES');
export const fetchLastUsedMeasures = macAsync('FETCH_LAST_USED_MEASURES');
export const createNewExpression = mac('CREATE_NEW_EXPRESSION');
export const removeExpression = mac('REMOVE_EXPRESSION');
export const duplicateExpressionTree = mac('DUPLICATE_EXPRESSION_TREE');
export const setTreeVisible = mac('SET_TREE_VISIBLE');
export const setExpressionTree = mac('SET_EXPRESSION_TREE');

// metric-explorer
// Chart Display Properties;
export const setChartType = mac('SET_CHART_TYPE');
export const setChartStackingOption = mac('SET_CHART_STACKING_OPTION');
export const setYAxisOption = mac('SET_Y_AXIS_OPTION');
export const setLowerRange = mac('SET_LOWER_RANGE');
export const setHigherRange = mac('SET_HIGHER_RANGE');
export const setYAxisDirection = mac('SET_Y_AXIS_DIRECTION');
export const updateSettings = mac('UPDATE_SETTINGS');
export const setNewDashboardName = mac('SET_NEW_DASHBOARD_NAME');
export const setSelectedTreeChartDisplay = mac('SET_SELECTED_TREE_CHART_DISPLAY');
export const setMultipleProperties = mac('SET_MULTIPLE_PROPERTIES');
export const clearAllMetricsExplorer = mac('CLEAR_ALL_METRICS_EXPLORER');
export const dragExpressionNode = mac('DRAG_EXPRESSION_NODE');

export const setViewStateProperty = mac('setViewStateProperty');
export const refetchExecuteData = mac('refetchExecuteData');

// modal management
export const storeCurrentState = mac('STORE_CURRENT_TREE');
export const retrieveStoredState = mac('RETRIEVE_STORED_TREE');
export const setMetricExplorerModalOpen = mac('METRIC_EXPLORER_MODAL_OPEN');
