// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import {isStreamEditable, isStreamRunning, isAlowedEditRunning} from 'bc/services/dataStreamService';
import * as selectors from 'bc/store/selectors';
import {getIsStreamOwnerEnabled} from 'profile/store/selectors';
import './StreamName.module.scss';

const MIN_LENGTH = 2;

type PropTypes = {
  stream: Object,
  dataStreams: Array,
  isStreamOwner: boolean,
  setSelectedStreamKeyVal: Function,
};

@connect(
  (state) => ({
    stream: selectors.getSelectedDataStream(state),
    dataStreams: selectors.getDataStreamsItems(state),
    isStreamOwner: getIsStreamOwnerEnabled(state),
  }),
  {
    setSelectedStreamKeyVal,
  },
)
export default class StreamName extends React.PureComponent {
  props: PropTypes;

  state = {
    name: this.props.stream.name,
    isVisited: false,
    errorMsg: '',
  };

  onBlur = () => {
    this.setState((prevState) => {
      const curName = prevState.name.trim().replace(/'/g, '');
      if (curName !== this.props.stream.name) {
        this.props.setSelectedStreamKeyVal({name: curName});
      }
      return {
        isVisited: true,
      };
    });
  };

  onChange = (e) => {
    const {stream, dataStreams} = this.props;
    const val = e.target.value.replace("'", '');
    let msg = '';
    if (!val || val.trim().length === 0) {
      msg = 'Stream name is empty, fill in the stream name to continue.';
    } else if (dataStreams.length) {
      if (dataStreams.some((i) => i.name === val.trim() && i.id !== stream.id)) {
        msg = 'A stream with that name already exists, fill in a unique name to continue.';
      }
    }
    if (this.state.errorMsg !== msg || !this.state.isVisited) {
      this.props.setSelectedStreamKeyVal({name: val.trim()});
    }
    this.setState({
      errorMsg: msg,
      name: val,
      isVisited: true,
    });
  };

  render() {
    const {isVisited, name, errorMsg} = this.state;
    const {stream, isStreamOwner} = this.props;
    const errorStyle = isVisited && errorMsg !== '' ? 'error' : '';
    const isTitleEditable =
      (isAlowedEditRunning(stream.type) && isStreamRunning(stream.state)) || isStreamEditable(stream.state);

    if (isStreamOwner) {
      return null;
    }

    return (
      <div styleName="root">
        <div>
          <input
            type="text"
            automation-id="streamNameTextbox"
            styleName={`search-input ${errorStyle}`}
            minLength={MIN_LENGTH}
            value={name}
            onChange={this.onChange}
            onBlur={this.onBlur}
            disabled={!isTitleEditable}
            placeholder="Set a name for the stream"
          />
        </div>
        <div styleName="err_msg">{errorMsg}</div>
      </div>
    );
  }
}
