import React from 'react';
import './SmallDropdownButton.module.scss';

type PropTypes = {
  value: string,
  disable: boolean,
};

const SmallDropdownButton = (props: PropTypes) => (
  <div className="form-button" styleName={['container', props.disable ? 'disable' : ''].join(' ')}>
    <div styleName="value">{props.value}</div>
    <div styleName="triangle" className="icon icn-arrow16-triangledown" />
  </div>
);

export default SmallDropdownButton;
