// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {getBadgeColor, useTooltipStyles} from 'topologyGeneral/services/mapService';

const RegionTooltip = ({item}: PropTypes) => {
  const classes = useTooltipStyles();

  return (
    <Box className="gtp-tp-wrapper" pt={1.5} pb={1.5} pl={2} pr={2} borderRadius="6px">
      <Box display="flex">
        <Box component="span" className={classes.title14Bold} mr={0.5}>
          {item.title}
        </Box>
        <Box component="span" className={classes.alertsBadge} pr={0.75} pl={0.75} bgcolor={getBadgeColor(item.percent)}>
          {item.alertsTotal.toLocaleString()}
        </Box>
        <Box component="span" className={classes.title12BoldLight} pl={0.25}>
          Alerts
        </Box>
      </Box>
      <Box display="flex" pt={1}>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Box component="span" className={classes.title12BoldLight}>
            Alerted Nodes
          </Box>
          <Box component="span" className={classes.title14Normal}>
            {item.alertedNodes.toLocaleString()}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box component="span" className={classes.title12BoldLight}>
            Total Nodes
          </Box>
          <Box component="span" className={classes.title14Normal}>
            {item.nodesTotal.toLocaleString()}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

RegionTooltip.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default RegionTooltip;
