// @flow
import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import Button, {COLORS, WIDTHS} from 'common/componentsV2/Button';
import {segmentClickEvent} from 'common/store/actions';
import FilterButton from 'common/componentsV2/FilterButton';
import {commasSepNumber} from 'common/utils/numbers';
import {setDataManagerFiltersIsOpen} from 'bc/store/actions';
import {
  getDataManagerFiltersIsOpen,
  getDataMangerFilterToolTip,
  getDataStreamsItemsOrdered,
  getDataStreamsItemsOrderedAndFiltered,
  getDataManagerOpenedSources,
} from 'bc/store/selectors';
import {getUserProfile, getIsOnPremNewStreamEnabled} from 'profile/store/selectors';
import Tooltip from 'common/componentsV2/Tooltip';
import {fetchMetricsUsage} from 'insightsPanel/store/actions';
import {
  getMetricsUsageDataLimit,
  getMetricsUsageDataMonthlyUsage,
  getMetricsUsage,
} from 'insightsPanel/store/selectors';
import NewStreamBySourceModal from '../dataManager/NewStreamBySourceModal';

import './DataManagerHeader.module.scss';

type PropTypes = {
  setDataManagerFiltersIsOpen: Function,
  dataStreamsCount: Array,
  onCollapseAll: Function,
  onExpandAll: Function,
  savedOpenSources: Array,

  // connect
  isFilterBarOpen: Boolean,
  filterTooltip: Object,
  filteredDataStreamsCount: Number,
  dataStreamsCount: Number,
  fetchMetricsUsage: Function,
  metricsLimit: Number,
  metricsMonthlyUsage: Number,
  segmentClickEvent: Function,
  userProfile: Object,
  isOnPremNewStreamEnabled: Boolean,
};

@connect(
  (state) => ({
    isFilterBarOpen: getDataManagerFiltersIsOpen(state),
    filterTooltip: getDataMangerFilterToolTip(state),
    filteredDataStreamsCount: getDataStreamsItemsOrderedAndFiltered(state).length,
    dataStreamsCount: getDataStreamsItemsOrdered(state).length,
    metricsUsage: getMetricsUsage(state),
    metricsLimit: getMetricsUsageDataLimit(state),
    metricsMonthlyUsage: getMetricsUsageDataMonthlyUsage(state),
    savedOpenSources: getDataManagerOpenedSources(state),
    userProfile: getUserProfile(state),
    isOnPremNewStreamEnabled: getIsOnPremNewStreamEnabled(state),
  }),
  {
    setDataManagerFiltersIsOpen,
    fetchMetricsUsage,
    segmentClickEvent,
  },
)
export default class AlertManagerHeader extends React.PureComponent {
  props: PropTypes;

  state = {
    isModalOpen: false,
  };

  componentDidMount() {
    this.props.fetchMetricsUsage();
  }

  openModal = () => {
    this.props.segmentClickEvent({category: 'data-management-main', name: 'new-stream-main-click'});
    this.setState({isModalOpen: true});
  };

  closeModal = () => {
    this.setState({isModalOpen: false});
  };

  onFilterButtonClick = () => {
    const value = this.props.isFilterBarOpen ? 'collapse-filter' : 'open-filter';
    this.props.segmentClickEvent({category: 'data-management-filter', name: `${value}`});
    this.props.setDataManagerFiltersIsOpen(!this.props.isFilterBarOpen);
  };

  onMenuButtonClick = (action) => {
    if (action === 'expand') {
      this.props.segmentClickEvent({category: 'data-management-main', name: 'expand-all'});
      this.props.onExpandAll();
    } else {
      this.props.segmentClickEvent({category: 'data-management-main', name: 'collapse-all'});
      this.props.onCollapseAll();
    }
  };

  tooltipBody = () => {
    const {metricsMonthlyUsage, metricsLimit} = this.props;

    if (metricsMonthlyUsage === 0 && metricsLimit === 0) {
      return (
        <div styleName="tooltip-wrap">
          <div>
            <span>Not Enough Data</span>
          </div>
        </div>
      );
    }

    const progressBarPercentage = Math.round((metricsMonthlyUsage / metricsLimit) * 100);
    return (
      <div styleName="tooltip-wrap">
        <div>
          <strong>{`For  ${moment().format('MMM')} 1 to ${moment().format('MMM D')}`}</strong>
        </div>
        <div>
          <strong>Used</strong>
          <span>{`: ${progressBarPercentage}% (${commasSepNumber(metricsMonthlyUsage)}) `}</span>
        </div>
        <div>
          <strong>Total Capacity</strong>
          <span>{`: ${' '}${commasSepNumber(metricsLimit)}`}</span>
        </div>
        <div>
          <strong>Remaining</strong>
          <span>{`: ${' '}${commasSepNumber(metricsLimit - metricsMonthlyUsage)}`}</span>
        </div>
      </div>
    );
  };

  render() {
    const {
      filteredDataStreamsCount,
      dataStreamsCount,
      metricsMonthlyUsage,
      metricsLimit,
      savedOpenSources,
      userProfile,
      isOnPremNewStreamEnabled,
    } = this.props;
    const {isModalOpen} = this.state;
    const duration = 750;
    const progressBarPercentage = Math.round((metricsMonthlyUsage / metricsLimit) * 100);
    const buttonSufix = !savedOpenSources || !savedOpenSources.length ? 'expand' : 'collapse';

    // new stream button enabled check
    const authorizedRoles = ['anodot-admin', 'anodot-user', 'reseller-admin', 'reseller-user', 'customer-admin'];
    let isAuthorizedNewStream = userProfile.roles.some((r) => authorizedRoles.includes(r));
    if (isAuthorizedNewStream && window.isOnPremise) {
      // also check feature flag for OnPremise users
      isAuthorizedNewStream = isOnPremNewStreamEnabled;
    }

    const setFillColor = (percentage) => {
      if (percentage === 0) {
        return '#8995a0';
      }
      if (percentage < 85) {
        return '#2671FF';
      }
      if (percentage >= 85 && percentage < 95) {
        return '#EB6F07';
      }
      return '#D10F37';
    };

    const defaultStyle = {
      transition: `width ${duration}ms ease-out`,
      width: 0,
      backgroundColor: setFillColor(0),
    };
    const transitionStyles = {
      transition: `width ${duration}ms ease-out`,
      width: `${progressBarPercentage}%`,
      backgroundColor: setFillColor(progressBarPercentage),
    };

    let automationIdFilters = '';
    this.props.filterTooltip.forEach((item) => {
      automationIdFilters = `${automationIdFilters + item.id}:${item.value}|`;
    });

    return (
      <header styleName="page-header">
        <div styleName="container">
          <Title type={TITLE_TYPES.PAGE_TITLE}>Sources</Title>
          <Title type={TITLE_TYPES.PAGE_LINE_COUNTER}>
            (
            {filteredDataStreamsCount !== dataStreamsCount
              ? `${filteredDataStreamsCount}/${dataStreamsCount}`
              : `${dataStreamsCount}`}
            )
          </Title>
          <FilterButton
            tooltipItems={this.props.filterTooltip}
            onClick={this.onFilterButtonClick}
            automationId={`applied_filters ${automationIdFilters}`}
          />
        </div>
        <div styleName="buttons-wrap">
          <div styleName="metrics-wrap">
            <span styleName="metrics-text">Metrics Usage</span>
            <span styleName="metrics-widget">
              <Tooltip content={this.tooltipBody()} delay={300} placement="bottom">
                <div styleName="progressBar-wrapper">
                  <span styleName="progressBar-fill" style={{...defaultStyle, ...transitionStyles}} />
                </div>
              </Tooltip>
            </span>
          </div>
          <div styleName="dropdown-option-wrap">
            <Button
              fixedWidth={35}
              width={WIDTHS.NARROW_TIGHT}
              colorSchema={COLORS.GRAY_300}
              icon={`icn-action16-${buttonSufix}`}
              onClick={() => this.onMenuButtonClick(buttonSufix)}
              automationId="dataManagerExpendAll"
            />
          </div>
          {isAuthorizedNewStream && (
            <Button
              colorSchema={COLORS.BLUE_500}
              icon="icn-action16-plusa"
              text="New Stream"
              automationId="dataManagerNewSourceButton"
              onClick={this.openModal}
            />
          )}
        </div>

        {isModalOpen && <NewStreamBySourceModal isOpen={isModalOpen} onClose={this.closeModal} />}
      </header>
    );
  }
}
