// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {fetchChannels} from 'alerts.channels/store/actions';
import * as channelsSelectors from 'alerts.channels/store/selectors';
import {fetchUsers} from 'admin.users/store/actions';
import * as usersSelectors from 'admin.users/store/selectors';

import UsersChannelsFilter from 'alerts.channels/components/UsersChannelsFilter';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';

type PropTypes = {
  setQueryParams: Function,
  fetchChannels: Function,
  fetchUsers: Function,
  isLoadingChannels: Boolean,
  isLoadingUsers: Boolean,
  sortedFlattenChannels: Array<Object>,
  selectedItems: Array<Object>,
};

@connect(
  (state) => ({
    isLoadingChannels: channelsSelectors.getChannelsLoading(state),
    isLoadingUsers: usersSelectors.getUsersIsLoading(state),
    sortedFlattenChannels: channelsSelectors.getSortedFlattenChannels(state),
    selectedItems: channelsSelectors.getSelectedItems(state),
  }),
  {
    fetchChannels,
    fetchUsers,
  },
)
export default class ChannelsFilter extends PureComponent {
  props: PropTypes;

  componentDidMount() {
    this.props.fetchChannels();
    this.props.fetchUsers();
  }

  setToUrl = (source, arr) => {
    this.props.setQueryParams({
      [source]: arr.length ? arr.map((i) => i).join(',') : undefined,
    });
  };

  onChange = (values) => {
    const subscribers = [];
    const channels = [];

    values.forEach((i) => {
      if (i.type === 'USERS') {
        subscribers.push(i.value);
      } else {
        channels.push(i.value);
      }
    });
    this.setToUrl('subscribers', subscribers);
    this.setToUrl('channels', channels);
  };

  onClearAll = () => {
    this.props.setQueryParams({subscribers: undefined, channels: undefined});
  };

  render() {
    const {isLoadingChannels, isLoadingUsers, selectedItems, sortedFlattenChannels} = this.props;

    return (
      <UsersChannelsFilter
        placeholder={isLoadingChannels || isLoadingUsers ? 'Loading...' : 'Select'}
        onChange={this.onChange}
        sortedFlattenChannels={sortedFlattenChannels}
        value={selectedItems}
        automationId="channelsFilter"
        footerComponent={
          <FormDdlActionBar
            isVisible
            isClearAllDisabled={!selectedItems || !selectedItems.length}
            onClearAll={this.onClearAll}
          />
        }
      />
    );
  }
}
