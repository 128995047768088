// @flow
import React, {useCallback} from 'react';
import Button, {COLORS, WIDTHS} from 'common/componentsV2/Button';
import {useHistory} from 'react-router-dom';
import SimpleButtonIconList from 'common/componentsV2/ddl/SimpleButtonIconList';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import channelTypes from 'channels/constants/channelTypes';
import FilterButton from 'common/componentsV2/FilterButton';

import './ChannelsHeader.module.scss';
import {useSelector} from 'react-redux';
import {getSlackappEnabled} from 'profile/store/selectors';

type PropTypes = {
  setFiltersOpen: Function,
  areFiltersOpen: boolean,
  dataStreamsCount: number,
  filteredDataStreamsCount: number,
  onCollapseAll: Function,
  onExpandAll: Function,
  listState: string,
};

const ChannelsHeader = ({
  filteredDataStreamsCount,
  dataStreamsCount,
  onExpandAll,
  onCollapseAll,
  areFiltersOpen,
  setFiltersOpen,
  listState,
}: PropTypes) => {
  const isSlackappEnabled = useSelector(getSlackappEnabled);
  const history = useHistory();
  const onMenuButtonChange = useCallback(
    (state) => {
      if (state === 'EXPAND') {
        onExpandAll();
      } else {
        onCollapseAll();
      }
    },
    [onExpandAll, onCollapseAll],
  );

  const onFilterButtonClick = useCallback(() => setFiltersOpen(!areFiltersOpen), [areFiltersOpen]);

  return (
    <header styleName="page-header">
      <div styleName="container">
        <Title type={TITLE_TYPES.PAGE_TITLE}>Channels</Title>
        <Title type={TITLE_TYPES.PAGE_LINE_COUNTER}>
          (
          {filteredDataStreamsCount !== dataStreamsCount
            ? `${filteredDataStreamsCount}/${dataStreamsCount}`
            : `${dataStreamsCount}`}
          )
        </Title>
        <FilterButton tooltipItems={[]} onClick={onFilterButtonClick} />
      </div>
      <div styleName="buttons-wrap">
        <div className="mr_1-5">
          <Button
            fixedWidth={35}
            width={WIDTHS.NARROW_TIGHT}
            colorSchema={COLORS.GRAY_300}
            icon={listState === 'COLLAPSE' ? 'icn-action16-expand' : 'icn-action16-collapse'}
            onClick={() => onMenuButtonChange(listState === 'COLLAPSE' ? 'EXPAND' : 'COLLAPSE')}
            automationId="metricExplorerSplitGraphButton"
          />
        </div>
        <SimpleButtonIconList
          text="New Channel"
          icon="icn-action16-plusa"
          onSelect={(option) => history.push(`/channels/new/${option.value}${history.location.search}`)}
          options={channelTypes.filter((channelType) => (isSlackappEnabled ? true : channelType.id !== 'slackapp'))}
        />
      </div>
    </header>
  );
};

export default ChannelsHeader;
