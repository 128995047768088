import React from 'react';
import {noop} from 'lodash';
import {Subject} from 'rxjs';
import TextInput from './TextInput';
import ExpressionLine from './ExpressionLine';
import ExpressionItem from './ExpressionItem';
import './ExpressionBuilder.module.scss';
import MultiLevelDualData from '../ddl/multiSelectFormDdl/FormDdlList/MultiLevelDualData';
import MultiOptionComponent from '../ddl/multiSelectFormDdl/FormDdlList/MultiOptionComponent';
import MenuSectionSearch from './MenuSectionSearch';
import {TOP_PANEL_OPTIONS, TOP_PANEL_OPTIONS_ARR, hasParentWithClass} from './ExpressionBuilderService';
import SmallDropdownButton from '../ddl/multiSelectFormDdl/SmallDropdownButton';
import OptionComponentDefault from '../ddl/multiSelectFormDdl/OptionComponentDefault';
import FormDdlSelect from '../ddl/multiSelectFormDdl/FormDdlSelect';
import deletePropFromObj from '../../utils/deletePropFromObj';

type PropTypes = {
  expression: Array<ExpressionItem>,
  fetchFirstPanel: Function,
  mainPanelOptions: Array<ExpressionItem>,
  secondaryPanelOptions: Array<ExpressionItem>,
  secondarySearch: Function,
  isPrimaryPanelLoading: boolean,
  isSecondaryPanelLoading: boolean,
  onExpressionChange: Function,
  onClick: Function,
  useTopMenu: boolean,
  customPlaceholder: string,
};

export default class ExpressionBuilder extends React.PureComponent {
  props: PropTypes;

  state = {
    isOpen: false,
    isFocusOnSecondPanel: false,
    topMenuSelection: TOP_PANEL_OPTIONS.ALL.value,
    topMenuOptions: TOP_PANEL_OPTIONS_ARR,
    expressionSnapshot: [],
    updateSnapshotAfterClose: [],

    mainSearchQuery: '',
    secondarySearchQuery: '',
    currentSelectedKey: '',
    streamsAvailable: [],
  };

  componentDidMount() {
    this.callFetchFirstPanelDebounced = new Subject()
      .debounceTime(400)
      .distinctUntilChanged((x, y) => x === y)
      .map((x) => this.callFetchFirstPanel(x));

    this.callFetchSubscription = this.callFetchFirstPanelDebounced.subscribe();
    this.callFetchFirstPanel();
    this.setTopMenuValues();
  }

  componentDidUpdate(prevProps) {
    if (!this.isExpressionsEqual(prevProps.expression, this.props.expression)) {
      this.setTopMenuValues();
    }
  }

  componentWillUnmount() {
    this.callFetchSubscription.unsubscribe();
  }

  resetTopMenu = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    let selection = this.state.topMenuSelection;

    if (this.state.topMenuSelection === 'measures' && this.isExpressionContainsValue('Measures')) {
      selection = 'all';
    }

    if (this.state.topMenuSelection === 'streams' && this.isExpressionContainsValue('Streams')) {
      selection = 'all';
    }

    this.setState({
      topMenuSelection: selection,
    });
  };

  setTopMenuValues = () => {
    const topMenuOptions = ['all'];
    if (!this.isExpressionContainsValue('Measures')) {
      topMenuOptions.push('measures');
    }
    topMenuOptions.push('dimensions');
    if (!this.isExpressionContainsValue('Streams')) {
      topMenuOptions.push('streams');
    }

    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({topMenuOptions});

    this.callFetchFirstPanel();
    this.resetTopMenu();
  };

  // selectedKey = '';
  isExpressionsEqual = (expression1, expression2) => {
    if (expression1.length !== expression2.length) {
      return false;
    }
    let ret = true;
    expression1.forEach((expItem, index) => {
      ret = expItem.isEqual(expression2[index]) && ret;
    });
    return ret;
  };

  setMainSearchQuery = (val) => {
    let value = val;
    this.setState({mainSearchQuery: val});
    if (val[val.length - 1] === '*') {
      value = val.slice(0, -1);
    }
    this.callFetchFirstPanelDebounced.next(value);
    // this.callFetchFirstPanel();
  };

  setSecondaryQuery = (val) => {
    if (this.state.secondarySearchQuery !== val) {
      const isSearchBigger = val.length - this.state.secondarySearchQuery.length > 0;
      const specialChars = ['*', '?'];
      let haveSomeSpecialChar = false;
      specialChars.forEach((char) => {
        if (val.includes(char)) {
          haveSomeSpecialChar = true;
        }
      });
      this.setState({secondarySearchQuery: val});
      if (this.props.secondaryPanelOptions.length < 50 && isSearchBigger && !haveSomeSpecialChar) {
        return;
      }
      this.props.secondarySearch({
        expression: val,
        filter: this.getSearchFilter(),
        properties: [this.state.currentSelectedKey],
        size: 50,
      });
    }
  };

  backToFirstPanel = () => {
    this.setState({
      isFocusOnSecondPanel: false,
      mainSearchQuery: '',
      secondarySearchQuery: '',
    });
    this.resetTopMenu();
    this.callFetchFirstPanel();
  };

  setCurrentSelectedKey = (val) => {
    // this.selectedKey = val;
    const {expression, onExpressionChange} = this.props;
    this.setState({currentSelectedKey: val});
    if (val === '') {
      const orfanExpressionItemIndex = this.props.expression.findIndex((expItem) => expItem.itemValues.length === 0);
      if (orfanExpressionItemIndex > -1) {
        const retExpression = [
          ...expression.slice(0, orfanExpressionItemIndex),
          ...expression.slice(orfanExpressionItemIndex + 1),
        ];
        onExpressionChange(retExpression);
      }
    }
    if (val === 'Search') {
      setTimeout(() => {
        this.searchTextInput.textInput.focus();
      }, 300);
    }
  };

  getIsExact = (expressionItem) => {
    let ret = expressionItem.isExact;

    if (expressionItem.value === 'Search' || expressionItem.value === 'Forecasts') {
      ret = false;
    }
    if (expressionItem.isNot) {
      ret = false;
    }
    return ret;
  };

  getSearchFilter = (currentSelectedKey) => {
    const retArr = [];
    const currentKey = currentSelectedKey || this.state.currentSelectedKey;
    this.props.expression.forEach((expresionItem) => {
      if (expresionItem.value !== currentKey) {
        const ret = {
          isExact: this.getIsExact(expresionItem),
          key: expresionItem.value === 'Measures' ? 'what' : expresionItem.value, // TODO move this to the server
          type: 'property',
          value: expresionItem.itemsToString(),
        };
        switch (expresionItem.value) {
          case 'Streams':
            ret.type = 'origin';
            ret.originType = 'Stream';
            ret.key = 'originId';
            break;
          case 'Alerts':
            ret.type = 'origin';
            ret.originType = 'Alert';
            ret.key = 'originId';
            break;
          case 'Composites':
            ret.type = 'origin';
            ret.originType = 'Composite';
            ret.key = 'originId';
            break;
          case 'Forecasts':
            ret.type = 'origin';
            ret.originType = 'Forecast';
            ret.key = 'originTitle';
            break;
          default:
        }
        retArr.push(ret);
      }
    });
    return retArr;
  };

  removeInnerValue = (val) => {
    const {expression, onExpressionChange} = this.props;
    const valParent = val.parent === 'what' ? 'Measures' : val.parent;
    const parentIndex = expression.findIndex((expressionItem: ExpressionItem) => expressionItem.value === valParent);
    const parent: ExpressionItem = expression[parentIndex].duplicateExpression();
    parent.removeItemValue(val);
    let retExpression = [...expression.slice(0, parentIndex), parent, ...expression.slice(parentIndex + 1)];
    if (!this.state.isFocusOnSecondPanel && parent.itemValues.length === 0) {
      retExpression = [...expression.slice(0, parentIndex), ...expression.slice(parentIndex + 1)];
    }
    onExpressionChange(retExpression);
    this.setState({updateSnapshotAfterClose: retExpression});
    if (retExpression.length === 0) {
      this.resetTopMenu();
    }
    // this.searchTextInput.forceFocus();
  };

  addInnerValue = (val) => {
    const {expression, onExpressionChange} = this.props;
    const {currentSelectedKey} = this.state;
    const compare = currentSelectedKey || val.parent;
    const parentIndex = expression.findIndex((expressionItem: ExpressionItem) => expressionItem.value === compare);
    const parent: ExpressionItem = expression[parentIndex].duplicateExpression();
    const itemAllInParent = parent.itemValues.find((item) => item.value === '*');
    if (itemAllInParent) {
      parent.removeItemValue(itemAllInParent);
      parent.isNot = true;
    }
    if (val.value === '*') {
      parent.itemValues = [val];
    } else {
      parent.addItemValue(val);
    }
    const retExpression = [...expression.slice(0, parentIndex), parent, ...expression.slice(parentIndex + 1)];
    onExpressionChange(retExpression);
  };

  addValue = (val) => {
    this.props.onExpressionChange([...this.props.expression, val]);
    this.setCurrentSelectedKey(val.value);
  };

  isExpressionContainsValue = (value) =>
    this.props.expression.findIndex((expressionItem: ExpressionItem) => expressionItem.value === value) > -1;

  callFetchFirstPanel = () => {
    const {fetchFirstPanel} = this.props;
    const {mainSearchQuery} = this.state;
    if (mainSearchQuery.indexOf('@') === 0) {
      fetchFirstPanel({
        expression: mainSearchQuery.substring(1),
        listDescription: TOP_PANEL_OPTIONS.SOURCES.listDescription,
        filter: this.getSearchFilter(),
        search: '',
      });
    } else if (mainSearchQuery.indexOf('#') === 0) {
      fetchFirstPanel({
        expression: mainSearchQuery.substring(1),
        listDescription: TOP_PANEL_OPTIONS.TAGS.listDescription,
        filter: this.getSearchFilter(),
        search: '',
      });
    } else if (mainSearchQuery.length > 0) {
      fetchFirstPanel({
        expression: mainSearchQuery,
        listDescription: TOP_PANEL_OPTIONS.SEARCH.listDescription,
        filter: this.getSearchFilter(),
        search: '',
      });
    } else {
      fetchFirstPanel({
        expression: '',
        listDescription: TOP_PANEL_OPTIONS.ALL.listDescription,
        filter: this.getSearchFilter(),
        search: '',
      });
    }
  };

  getSelectedItems = () => {
    const retArr = [];
    this.props.expression.forEach((expressionItem: ExpressionItem) => {
      expressionItem.itemValues.forEach((innerItem) => retArr.push(innerItem));
    });
    return retArr;
  };

  handleInnerChipRemoveClicked = (expression: ExpressionItem) => {
    this.removeInnerValue(expression);
  };

  handleInnerTextInputChange = (value) => {
    this.setSecondaryQuery(value);
  };

  handleChipClick = (expressionItem: ExpressionItem) => {
    this.setCurrentSelectedKey(expressionItem.value);
    const {secondaryPanelOptions, secondarySearch, expression, onClick, onExpressionChange} = this.props;
    if (['Streams', 'Alerts', 'Composites', 'Forecasts'].some((ele) => ele === expressionItem.label)) {
      return;
    }
    if (expressionItem.isLegacy) {
      const chipIndex = expression.findIndex((ei: ExpressionItem) => ei.isLegacy);
      const retExpression = [...expression.slice(0, chipIndex), ...expression.slice(chipIndex + 1)];
      this.setCurrentSelectedKey('');
      onExpressionChange(retExpression);
      return;
    }
    onClick();
    if (
      expressionItem.value === 'search' ||
      expressionItem.value === 'Streams' ||
      expressionItem.value === 'Forecasts'
    ) {
      return;
    }

    if (
      secondaryPanelOptions &&
      secondaryPanelOptions.length &&
      secondaryPanelOptions[0].parent === expressionItem.value
    ) {
      this.setState({
        isOpen: true,
        expressionSnapshot: expression,
      });
      if (!this.state.isFocusOnSecondPanel) {
        this.setState({
          isFocusOnSecondPanel: true,
        });
        this.resetTopMenu();
      }
      return;
    }
    secondarySearch({
      expression: '',
      filter: this.getSearchFilter(expressionItem.value),
      properties: [expressionItem.value],
      size: 50,
    });
    if (this.state.isFocusOnSecondPanel) {
      this.setState({
        isOpen: true,
        expressionSnapshot: expression,
      });
    } else {
      this.setState({
        isFocusOnSecondPanel: true,
        isOpen: true,
        expressionSnapshot: expression,
      });
      this.resetTopMenu();
    }
  };

  handleClickOutside = (expressionItem, e) => {
    if (e.target.getAttribute('aria-label') === 'grid') {
      return;
    }
    if (hasParentWithClass('option-list-item', e.target)) {
      return;
    }
    if (e.target.classList.contains('no-close')) {
      return;
    }
    if (e.target.classList.contains('close-inner-chip')) {
      return;
    }
    if (hasParentWithClass('form-button', e.target)) {
      return;
    }
    if (hasParentWithClass('form-option', e.target)) {
      return;
    }
    if (e.target.classList.contains('form-option')) {
      return;
    }
    if (e.target.classList.contains('list-header')) {
      return;
    }
    if (this.state.currentSelectedKey !== '') {
      this.setCurrentSelectedKey('');
    }
    this.setState({secondarySearchQuery: ''});
  };

  handleClickOutsideDropdown = (e) => {
    if (
      e.target.classList.contains('text-input') ||
      e.target.classList.contains('value-chip') ||
      e.target.classList.contains('close-inner-chip') ||
      e.target.classList.contains('form-option') ||
      hasParentWithClass('form-option', e.target)
    ) {
      return;
    }

    this.setCurrentSelectedKey('');
    this.setState((prevState) => ({
      isOpen: false,
      isFocusOnSecondPanel: false,
      mainSearchQuery: '',
      expressionSnapshot: prevState.updateSnapshotAfterClose,
    }));
    this.resetTopMenu();
    // this.searchTextInput.forceFocus();
  };

  handleClear = () => {
    const {expression, onExpressionChange} = this.props;
    if (expression.length === 0) {
      return;
    }
    const lastExpressionItem: ExpressionItem = expression[expression.length - 1];
    const lastInnerExpressionItem: ExpressionItem =
      lastExpressionItem.itemValues[lastExpressionItem.itemValues.length - 1];
    if (lastExpressionItem.itemValues.length > 0) {
      this.removeInnerValue(lastInnerExpressionItem);
    } else {
      const retExpression = expression.slice(0, expression.length - 2);
      onExpressionChange(retExpression);
    }
  };

  handleInnerClear = () => {
    const {expression} = this.props;
    const {currentSelectedKey} = this.state;
    const currentItem: ExpressionItem = expression.find((item: ExpressionItem) => item.value === currentSelectedKey);
    if (currentItem) {
      this.removeInnerValue(currentItem.itemValues[currentItem.itemValues.length - 1]);
    }
  };

  handleTexInputClick = () => {
    const {fetchFirstPanel, expression} = this.props;
    this.setCurrentSelectedKey('');
    this.setState({
      isOpen: true,
      expressionSnapshot: expression,
      isFocusOnSecondPanel: false,
    });
    this.resetTopMenu();
    this.searchTextInput.forceFocus();
    this.callFetchFirstPanel();
    fetchFirstPanel({
      expression: '',
      listDescription: TOP_PANEL_OPTIONS.ALL.listDescription,
      filter: this.getSearchFilter(),
      search: '',
    });
    this.props.onClick();
  };

  handleDropdownSelect = (val: ExpressionItem) => {
    const {expression, secondarySearch} = this.props;
    this.setState({
      streamsAvailable: [],
    });
    // selection of a property
    if (!val) {
      return;
    }
    if (val.value === 'streams-all-sources') {
      this.setState({
        isFocusOnSecondPanel: false,
      });
      return;
    }
    if (val.parent) {
      if (val.parent === 'Measures' || val.parent === 'Search') {
        this.searchTextInput.textInput.focus();
      }
      if (val.parent === 'Measures' && (val.streamsAvailable || []).length > 0) {
        this.setState({
          isFocusOnSecondPanel: true,
          streamsAvailable: val.streamsAvailable,
        });
      }
      if (val.isNew) {
        // eslint-disable-next-line no-param-reassign
        val.label = val.value;
      }
      const parentInExpression = expression.find((expressionItem) => expressionItem.value === val.parent);
      this.setSecondaryQuery('');
      // value of the same parent already exists
      if (parentInExpression) {
        const isAlreadySelected = parentInExpression.itemValues.find(
          (expressionItem) => expressionItem.value === val.value,
        );
        // Unselect what was already selected
        if (isAlreadySelected) {
          this.removeInnerValue(val);
          // this.setMainSearchQuery('');
          return;
        }
        this.setCurrentSelectedKey(val.parent);
        this.addInnerValue(val);
      }
      // value has no parent already selected
      else {
        this.addValue(new ExpressionItem(val.parent, val.parent, null, [val]));
        this.setCurrentSelectedKey('');
        // this.addInnerValue(val);
        if (val.parent === 'Streams' || val.parent === 'Forecasts') {
          this.searchTextInput.forceFocus();
        }
      }
      this.setMainSearchQuery('');
      return;
    }
    // selection of a dimension
    secondarySearch({
      expression: '',
      filter: this.getSearchFilter(),
      properties: [val.value],
      size: 50,
    });
    this.setState({
      isFocusOnSecondPanel: true,
    });
    this.resetTopMenu();
    this.setMainSearchQuery('');
    this.addValue(val);
  };

  onTopPanelChange = (val) => {
    const {fetchFirstPanel} = this.props;
    this.setState({topMenuSelection: val});
    fetchFirstPanel({
      expression: '',
      listDescription: Object.values(TOP_PANEL_OPTIONS).find((option) => option.value === val).listDescription,
      filter: this.getSearchFilter(),
      search: '',
    });
  };

  onBackToFirstTabClicked = () => {
    this.backToFirstPanel();
    this.searchTextInput.forceFocus();
  };

  onKeyboardSelect = () => {
    const {mainSearchQuery} = this.state;
    if (mainSearchQuery.length === 0) {
      return;
    }
    const innerValue = new ExpressionItem(mainSearchQuery, mainSearchQuery, 'search', null);
    const newValue = new ExpressionItem('search', 'Search', null, [innerValue]);
    this.addValue(newValue);
    this.setMainSearchQuery('');
    this.setCurrentSelectedKey('');
  };

  // handleOnFocusItem = (item: ExpressionItem) => {
  //   this.setState({focusedDDItem: item});
  // };

  onNavigation = (val) => {
    if (this.dropdown) {
      this.dropdown.handleNavigation(val);
    }
  };

  onDDKeyboardEvent = (evt) => {
    const key = evt.key.toLocaleLowerCase();
    if (key.length <= 1) {
      if (!this.state.isFocusOnSecondPanel) {
        // GABPAC -- TODO -- this was commented when moving to states instead of props. Check what about it latter
        // this.searchTextInput.forceFocus();
      }
    }
  };

  handleMainQueryChange = (val) => {
    this.resetTopMenu();
    this.setMainSearchQuery(val);
  };

  handleInnerChipInputBlur = (e) => {
    e.persist();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  handleOnEscape = () => {
    this.setCurrentSelectedKey('');
    this.setState((prevState) => ({
      isOpen: false,
      isFocusOnSecondPanel: false,
      mainSearchQuery: '',
      expressionSnapshot: prevState.updateSnapshotAfterClose,
    }));
    this.resetTopMenu();
  };

  handlePaste = (val) => {
    let value = '';

    try {
      value = JSON.parse(val.clipboardData.getData('text/plain'));
      const {what} = value;
      const {properties} = value;
      const newExpression = [];
      newExpression.push(
        new ExpressionItem('Measures', 'Measures', null, [new ExpressionItem(what, what, 'Measures')]),
      );
      properties.forEach((prop) => {
        newExpression.push(
          new ExpressionItem(prop.key, prop.key, null, [new ExpressionItem(prop.value, prop.value, prop.key, null)]),
        );
      });
      this.props.onExpressionChange(newExpression);
      setTimeout(() => {
        this.setState({mainSearchQuery: ''});
      }, 0);
    } catch (e) {
      // eslint-disable-next-line no-empty
    }
  };

  renderInnerComponents = () => {
    const {expression, customPlaceholder} = this.props;
    const {mainSearchQuery, secondarySearchQuery, isOpen} = this.state;
    return (
      <div
        automation-id="metricExplorerSearchExpression"
        styleName={['height-limiter', isOpen ? 'open' : ''].join(' ')}
      >
        <div styleName={['container', isOpen ? 'open' : ''].join(' ')}>
          <ExpressionLine
            expression={expression}
            searchQuery={secondarySearchQuery}
            onInnerChipRemoveClicked={this.handleInnerChipRemoveClicked}
            onClickOutside={this.handleClickOutside}
            onChipClick={this.handleChipClick}
            onTextInputChange={this.handleInnerTextInputChange}
            currentSelectedKey={this.state.currentSelectedKey}
            textInput={
              <TextInput
                isInner
                onChange={this.setSecondaryQuery}
                onClick={noop}
                onBlur={this.handleInnerChipInputBlur}
                onSelect={noop}
                onClear={this.handleInnerClear}
                onEscape={this.handleOnEscape}
                value={secondarySearchQuery}
                onArrowUpDown={noop}
                onNavigation={this.onNavigation}
                onPaste={noop}
              />
            }
          />
          <TextInput
            ref={(inp) => {
              this.searchTextInput = inp;
            }}
            isInner={false}
            onChange={this.handleMainQueryChange}
            onClick={this.handleTexInputClick}
            onBlur={noop}
            onSelect={this.onKeyboardSelect}
            onClear={this.handleClear}
            onEscape={this.handleOnEscape}
            value={mainSearchQuery}
            onNavigation={this.onNavigation}
            onPaste={this.handlePaste}
            placeholder={
              this.props.expression.length === 0 ? customPlaceholder || 'Search measures, dimensions, streams...' : ''
            }
          />
        </div>
      </div>
    );
  };

  sortSecondaryPanelOptions = (returnArr) => {
    const selectedKey = this.state.expressionSnapshot.find((item) => this.state.currentSelectedKey === item.value);
    let itemValues = null;
    if (selectedKey) {
      // eslint-disable-next-line prefer-destructuring
      itemValues = selectedKey.itemValues;
    }
    if (itemValues) {
      returnArr.sort((a, b) => {
        const isASelected = itemValues.find((itemValue) => itemValue.value === a.value);
        const isBSelected = itemValues.find((itemValue) => itemValue.value === b.value);
        if (isASelected && !isBSelected) {
          return -1;
        }
        if (isBSelected && !isASelected) {
          return 1;
        }
        return 0;
      });
      const checkedItems = [];
      returnArr.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.uiData = {};
        if (itemValues.findIndex((itemValue) => itemValue.value === item.value) > -1) {
          checkedItems.push(item);
        }
      });
      if (checkedItems && checkedItems.length) {
        checkedItems[checkedItems.length - 1].uiData = {lastSelected: true};
      }
    }
    return returnArr;
  };

  getSecondaryPanelOptions = () => {
    const {secondaryPanelOptions, expression} = this.props;
    const {secondarySearchQuery, currentSelectedKey, streamsAvailable} = this.state;
    deletePropFromObj(secondaryPanelOptions, 'lastSelected');

    const secondaryPanelOptionsWithAll = [...secondaryPanelOptions];
    const isCurrentChipIsNot = this.getCurrentChipIsNot();
    if (secondaryPanelOptions.length && !isCurrentChipIsNot) {
      secondaryPanelOptionsWithAll.unshift(new ExpressionItem('*', 'All (*)', currentSelectedKey, null));
    }

    const currentChip = expression.find((item) => item.value === this.state.currentSelectedKey);
    if (streamsAvailable.length > 1) {
      const streams = streamsAvailable.map(
        (str) =>
          new ExpressionItem(
            str.id,
            str.streamName,
            'Streams',
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            str.type,
          ),
      );
      streams.push(new ExpressionItem('streams-all-sources', 'All Sources', 'Streams', null, false, true));
      return streams;
    }
    let newItemValues = [];
    const customItems = [];
    if (currentChip) {
      const currentChipItemValues = currentChip.itemValues;
      newItemValues = currentChipItemValues.filter((itemValue) => itemValue.isNew);
      currentChipItemValues.forEach((itemValue) => {
        const found = secondaryPanelOptionsWithAll.findIndex((option) => option.value === itemValue.value) > -1;
        if (!found) {
          customItems.push(itemValue);
        }
      });
    }

    const ret = secondaryPanelOptionsWithAll.filter((option) => {
      const label = option.label.toLocaleLowerCase();
      return label.indexOf(secondarySearchQuery.toLocaleLowerCase()) > -1;
    });

    let returnArr = [...customItems, ...newItemValues, ...ret];

    returnArr = this.sortSecondaryPanelOptions(returnArr);

    returnArr = returnArr.filter((option) => {
      const label = option.label.toLocaleLowerCase();
      return label.indexOf(secondarySearchQuery.toLocaleLowerCase()) > -1;
    });

    if (secondarySearchQuery) {
      const newOption = new ExpressionItem(
        secondarySearchQuery,
        `New: "${secondarySearchQuery}"`,
        this.state.currentSelectedKey,
        null,
        false,
        true,
      );
      const allMatching = new ExpressionItem(
        `${secondarySearchQuery}*`,
        `Select All Matching (${secondarySearchQuery}*)`,
        this.state.currentSelectedKey,
        null,
        false,
        true,
      );
      returnArr.unshift(allMatching);
      returnArr.push(newOption);
    }
    if (secondaryPanelOptions.length >= 49) {
      returnArr.push({label: 'There are more possible values, please search to see them', footer: true});
    }

    return returnArr;
  };

  renderTopMenu = () => {
    if (!this.props.useTopMenu) {
      return null;
    }
    if (this.state.mainSearchQuery.length > 0) {
      return null;
    }
    return (
      <MenuSectionSearch
        selectedIndex={this.state.topMenuOptions.findIndex((opt) => opt === this.state.topMenuSelection)}
        options={this.state.topMenuOptions}
        onChange={this.onTopPanelChange}
      />
    );
  };

  onSecondPanelDropdownChange = (val) => {
    const {expression, onExpressionChange} = this.props;
    const currentChipIndex = expression.findIndex(
      (item: ExpressionItem) => item.value === this.state.currentSelectedKey,
    );
    const newCurrentChip = expression[currentChipIndex].duplicateExpression();
    newCurrentChip.isNot = val.isNot;
    const retExpression = [
      ...expression.slice(0, currentChipIndex),
      newCurrentChip,
      ...expression.slice(currentChipIndex + 1),
    ];
    onExpressionChange(retExpression);
  };

  getCurrentChipIsNot = () => {
    const currentChip = this.props.expression.find(
      (item: ExpressionItem) => item.value === this.state.currentSelectedKey,
    );
    if (currentChip) {
      return currentChip.isNot;
    }
    return false;
  };

  renderSecondPanelDropdown = () => {
    const options = [
      {
        value: 'is',
        label: 'is',
        symbol: '=',
        isNot: false,
      },
      {
        value: 'isNot',
        label: 'is not',
        symbol: '!=',
        isNot: true,
      },
    ];

    const value = this.getCurrentChipIsNot() ? options[1] : options[0];
    const disable = this.getIsAllSelected();
    return (
      <FormDdlSelect
        options={options}
        selected={value}
        button={<SmallDropdownButton disable={disable} value={value.label} />}
        onChange={this.onSecondPanelDropdownChange}
        width={150}
        optionComponent={<OptionComponentDefault />}
        disabled={disable}
      />
    );
  };

  getEmptyList = () => {
    const empty = new ExpressionItem(
      this.state.mainSearchQuery,
      `Search for ${this.state.mainSearchQuery}`,
      'Search',
      null,
      false,
      true,
    );
    return [empty];
  };

  getIsAllSelected = () => {
    const currentChip: ExpressionItem = this.props.expression.find(
      (item: ExpressionItem) => item.value === this.state.currentSelectedKey,
    );
    if (currentChip) {
      const index = currentChip.itemValues.findIndex((item) => item.value === '*');
      if (index > -1) {
        return true;
      }
    }
    return false;
  };

  render() {
    const {mainPanelOptions, isPrimaryPanelLoading, isSecondaryPanelLoading} = this.props;
    const {mainSearchQuery, secondarySearchQuery} = this.state;
    // TODO - measure selection still part of the options - filter it out (or maybe in the server?)
    const secondLevelOptions = this.getSecondaryPanelOptions();
    const highlightText = this.state.isFocusOnSecondPanel ? secondarySearchQuery : mainSearchQuery;
    let mainPanelOptionsList = mainPanelOptions;
    if (mainSearchQuery.length && mainPanelOptions.length === 0) {
      mainPanelOptionsList = this.getEmptyList();
    }
    return (
      <MultiLevelDualData
        ref={(dropdown) => {
          this.dropdown = dropdown;
        }}
        optionComponent={<MultiOptionComponent />} // TODO - create default Option Component
        options={mainPanelOptionsList}
        selectedOptions={this.getSelectedItems()}
        secondLevelOptions={secondLevelOptions}
        isLoadingFirstPanel={isPrimaryPanelLoading}
        highlightText={highlightText}
        isFocusOnSecondPanel={this.state.isFocusOnSecondPanel}
        onBackToFirstTabClicked={this.onBackToFirstTabClicked}
        button={this.renderInnerComponents()}
        onSelect={this.handleDropdownSelect}
        rowHeight={40}
        width={384}
        height={343}
        usePropsForOpenState
        isOpen={this.state.isOpen}
        onFocusedItem={noop}
        isMulti
        onToggle={noop}
        onClickOutside={this.handleClickOutsideDropdown}
        isLoadingSecondPanel={isSecondaryPanelLoading}
        useHeader={this.state.topMenuSelection === TOP_PANEL_OPTIONS.ALL.value}
        onKeyboardEvent={this.onDDKeyboardEvent}
        topPanelComponent={this.renderTopMenu()}
        topSecondPanelComponent={this.renderSecondPanelDropdown()}
        selectAllSecondPanel={this.getIsAllSelected()}
        isSecondarySearchQuery={this.state.secondarySearchQuery.length}
        automationId={`expression-builder-${this.state.topMenuSelection}-`}
      />
    );
  }
}
