import React from 'react';
import StreamEditorPanel from 'bc/components/streams/editor/StreamEditorPanel';
import StreamEditorSteps from 'bc/components/streams/editor/StreamEditorSteps';
import StreamProperties from 'bc/components/streams/editor/StreamProperties';
import SegmentDimensionsAndMetrics from './segmentDimensionsAndMetrics/SegmentDimensionsAndMetrics';

import MessageTypes from './MessageTypes';

import './SegmentStreamEditor.module.scss';

const SegmentStreamEditor = () => (
  <div className="shell-col">
    <StreamEditorSteps>
      <div styleName="left-container">
        <StreamProperties />
        <MessageTypes />
      </div>
      <SegmentDimensionsAndMetrics />
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default SegmentStreamEditor;
