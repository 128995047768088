// @flow

import React from 'react';
import VirtualizedListOfComponents from 'common/componentsV2/VirtualizedListOfComponents';
import DDLLoader from 'common/componentsV2/DDLLoader';
import FormDdl from '../FormDdl';
import './MultiLevelDropdown.module.scss';
import EmptyOptions from './EmptyOptions';

type PropTypes = {
  optionComponent: Node,
  options: Array,
  selectedOptions: Array,
  secondLevelOptions: Array,
  isLoadingFirstPanel: boolean,
  isLoadingSecondPanel: boolean,
  button: Node,
  onSelect: Function,
  rowHeight: Number,
  position: String,
  placement: String,
  width: Number,
  height: Number,
  disabled: boolean,
  isOpen: boolean,
  onFocusedItem: Function,
  isMulti: boolean,
  highlightText: string,
  isFocusOnSecondPanel: boolean,
  onBackToFirstTabClicked: Function,
  onToggle: Function,
  onClickOutside: Function,
  topPanelComponent: Node,
  topSecondPanelComponent: Node,
  useHeader: boolean,
  onKeyboardEvent: Function,
  selectAllSecondPanel: boolean,
  automationId: string,
  isSecondarySearchQuery: boolean,
};

export default class MultiLevelDualData extends React.PureComponent {
  props: PropTypes;

  handleNavigation = (val) => {
    if (this.props.isFocusOnSecondPanel) {
      if (this.secondPanel) {
        this.secondPanel.handleNavigation(val);
      }
    } else if (this.firstPanel) {
      this.firstPanel.handleNavigation(val);
    }
  };

  handleSelect = (val) => {
    this.props.onSelect(val);
  };

  renderFirstTabList = () => {
    const {
      optionComponent,
      options,
      selectedOptions,
      isLoadingFirstPanel,
      rowHeight,
      width,
      height,
      highlightText,
      isFocusOnSecondPanel,
      onFocusedItem,
      isMulti,
      useHeader,
      onKeyboardEvent,
      automationId,
    } = this.props;

    if (isLoadingFirstPanel) {
      return (
        <div styleName="spinner">
          <DDLLoader />
        </div>
      );
    }
    if (options.length === 0) {
      return (
        <div styleName="empty-options">
          <EmptyOptions />
          <span>No values found</span>
        </div>
      );
    }
    return (
      <VirtualizedListOfComponents
        ref={(firstPanel) => {
          this.firstPanel = firstPanel;
        }}
        optionComponent={optionComponent}
        options={options}
        onSelect={this.handleSelect}
        rowHeight={rowHeight}
        panelWidth={width}
        panelHeight={this.props.topPanelComponent ? height : height + 50}
        highlightText={highlightText}
        isFocused={!isFocusOnSecondPanel}
        onItemFocus={onFocusedItem}
        selectedValue={selectedOptions}
        selectedValuesArr={selectedOptions}
        isMultiValues={isMulti}
        useHeader={useHeader}
        onKeyboardEvent={onKeyboardEvent}
        useExternalNavigation
        automationId={`${automationId}-first-panel`}
      />
    );
  };

  renderFirstTab = () => {
    const {width} = this.props;
    return (
      <div style={{width}} styleName="first-tab" id="first-tab">
        {!!this.props.topPanelComponent && <div styleName="list-header">{this.props.topPanelComponent}</div>}
        {this.renderFirstTabList()}
      </div>
    );
  };

  renderSecondTabList = () => {
    const {
      optionComponent,
      selectedOptions,
      secondLevelOptions,
      isLoadingSecondPanel,
      onBackToFirstTabClicked,
      rowHeight,
      width,
      height,
      highlightText,
      isFocusOnSecondPanel,
      onFocusedItem,
      isMulti,
      useHeader,
      onKeyboardEvent,
      automationId,
    } = this.props;

    if (isLoadingSecondPanel) {
      return (
        <div styleName="spinner">
          <DDLLoader />
        </div>
      );
    }

    if (secondLevelOptions.length === 0) {
      return (
        <div styleName="empty-options">
          <EmptyOptions />
          <span>No values found</span>
        </div>
      );
    }
    return (
      <VirtualizedListOfComponents
        ref={(secondPanel) => {
          this.secondPanel = secondPanel;
        }}
        optionComponent={optionComponent}
        options={secondLevelOptions}
        onSelect={this.handleSelect}
        onBack={onBackToFirstTabClicked}
        rowHeight={rowHeight}
        panelWidth={width}
        panelHeight={height}
        highlightText={highlightText}
        isFocused={isFocusOnSecondPanel}
        onItemFocus={onFocusedItem}
        selectedValuesArr={selectedOptions}
        isMultiValues={isMulti}
        useMulti
        useHeader={useHeader}
        onKeyboardEvent={onKeyboardEvent}
        useExternalNavigation
        initialFocusIndex={this.props.isSecondarySearchQuery ? 1 : -1}
        selectAll={this.props.selectAllSecondPanel}
        automationId={`${automationId}-second-panel`}
      />
    );
  };

  renderSecondTab = () => {
    const {secondLevelOptions, isLoadingSecondPanel, onBackToFirstTabClicked, width} = this.props;
    let secondLevelTitle = '';
    if (secondLevelOptions && secondLevelOptions.length && secondLevelOptions[0].parent) {
      secondLevelTitle = isLoadingSecondPanel ? '' : secondLevelOptions[0].parent;
    }
    return (
      <div style={{width}} styleName="second-tab" id="second-tab">
        <h2 styleName="list-header" className="list-header">
          <span styleName="with-chevron" onClick={onBackToFirstTabClicked} />
          <span>{secondLevelTitle}</span>
          {secondLevelTitle.length > 0 && this.props.topSecondPanelComponent}
        </h2>
        {this.renderSecondTabList()}
      </div>
    );
  };

  renderInnerComponent = () => {
    const {width, isFocusOnSecondPanel} = this.props;

    return (
      <div style={{width}} styleName="container" className="multi-level-dual-data">
        <div style={{width: width * 2}} styleName={`pane ${isFocusOnSecondPanel ? 'second' : 'first'}`}>
          {this.renderFirstTab()}
          {this.renderSecondTab()}
        </div>
      </div>
    );
  };

  render() {
    const {onToggle, position, placement, isOpen, disabled, width, onClickOutside, button, automationId} = this.props;
    return (
      <FormDdl
        buttonComponent={button}
        popoverComponent={this.renderInnerComponent()}
        onToggle={onToggle}
        position={position}
        placement={placement}
        isOpen={isOpen}
        disabled={disabled}
        width={width}
        onClickOutside={onClickOutside}
        automationId={`${automationId}-formDdl`}
      />
    );
  }
}
