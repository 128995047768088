// @flow
import React, {PureComponent} from 'react';
import MetricName from 'metrics/components/metricName/MetricName';
import {connect} from 'react-redux';
import * as commonSelectors from 'profile/store/selectors';
import * as metricsSelectors from 'metrics/store/selectors';
import * as alertConsole from 'alerts.console/store/selectors';
import TimeSeriesChart from 'charts/timeSeries/components/TimeSeriesChart';
import {fetchAlertMetricDataPoints as fetchAlertMetricDataPointsAction} from 'alerts.console/store/actions';
import {CHART_HEIGHT} from 'alerts.console/services/alertsConsoleService';
import '../alertContent.module.scss';

let counter = 0;
const TOOLTIP_OPTIONS = {
  showMetricName: false,
  showAnomalyData: false,
  hideMeetIcons: true,
};

type PropTypes = {
  metric: Object,
  alertId: string,
  uiGroupName: string,
  startDate: number,
  endDate: number,
  timeScale: string,
  // connect
  bucketStartTimeEnabled: Boolean,
  timeZoneName: String,
  queryParamsViews: Object,
  resolutions: Object,
  startBucketMode: boolean,
  fetchAlertMetricDataPoints: Function,
  isSingleLine?: boolean,
  specialHeader: boolean,
};

@connect(
  (state) => ({
    resolutions: metricsSelectors.getMetricResolutions(state),
    startBucketMode: commonSelectors.getBucketStartTimeEnabled(state),
    queryParamsViews: alertConsole.getQueryParamsViews(state),
    timeZoneName: commonSelectors.getTimeZoneName(state),
    bucketStartTimeEnabled: commonSelectors.getBucketStartTimeEnabled(state),
  }),
  {
    fetchAlertMetricDataPoints: fetchAlertMetricDataPointsAction,
  },
)
export default class StaticAlertMetric extends PureComponent {
  props: PropTypes;

  static defaultProps = {
    isSingleLine: true,
  };

  constructor(props) {
    super(props);
    const {metric, alertId, uiGroupName} = this.props;

    this.id = `console.static.chart_${(counter += 1)}`;
    this.chartEventsMeta = {
      stateGroup: uiGroupName,
      key: `${alertId}_${metric.id}`, // unique ID for chart
      chartId: this.id,
    };
  }

  componentDidMount() {
    // use didMount: ensures chart was created
    const {fetchAlertMetricDataPoints, metric, alertId, timeScale, startDate, endDate, startBucketMode} = this.props;
    fetchAlertMetricDataPoints(
      {
        metricId: metric.id,
        endDate,
        startDate,
        startBucketMode,
        includeBaseline: false,
        timeScale: Object.values(this.props.resolutions).find((a) => a.value2 === timeScale).value,
      },
      {
        ...this.chartEventsMeta,
        metricId: metric.id,
        intervals: metric.intervals,
        alertId,
      },
    );
  }

  render() {
    const {
      metric,
      queryParamsViews,
      timeScale,
      timeZoneName,
      bucketStartTimeEnabled,
      isSingleLine,
      specialHeader,
    } = this.props;

    return (
      <div styleName="tile">
        <div styleName={['header', specialHeader ? 'extra-line' : ''].join(' ')}>
          <div styleName="header-row">
            <MetricName
              specialHeader={specialHeader}
              isSingleLine={isSingleLine}
              metric={metric}
              highlightText={queryParamsViews.searchQuery}
            />
          </div>

          <div styleName="header-row">
            <div styleName="row-left" />
          </div>
        </div>

        <div styleName={['chart', specialHeader ? 'special-height' : ''].join(' ')}>
          <TimeSeriesChart
            id={this.id}
            tooltip={TOOLTIP_OPTIONS}
            height={CHART_HEIGHT}
            bucketStartTimeEnabled={bucketStartTimeEnabled}
            timeScale={timeScale}
            timeZoneName={timeZoneName}
            enableClientZoom
            eventsMeta={this.chartEventsMeta}
          />
        </div>
      </div>
    );
  }
}
