import {get, put, delete_, post, stringifyQS} from 'common/utils/http';
import {isEmpty, get as _get} from 'lodash';

const root = 'api/v1/dashboards'; // replace module-name with actual module
const lastView = 'api/v1/lastview';

export const fetchDashboardUserSettings = ({payload}) =>
  get(`${root}/${payload.dashboardId}/users/${payload.userId}/settings?schemaType=dashboardsV2`);
export const updateDashboardUserSettings = ({payload}) =>
  put(`${root}/${payload.dashboardId}/users/${payload.userId}/settings?schemaType=dashboardsV2`, payload);

export const fetchDashboardsTotal = () => get(`${root}/total`);
export const createDuplicateDashboard = ({payload, meta}) =>
  post(`${root}?schemaType=${_get(meta, 'isV1', false) ? 'dashboards' : 'dashboardsV2'}`, payload);
export const updateDashboardSettings = ({payload, meta}) =>
  put(`${root}/${meta.dashboardId}/settings?schemaType=${meta.isV1 ? 'dashboards' : 'dashboardsV2'}`, payload);
export const fetchDashboardsV1 = ({payload}) =>
  get(`${root}?schemaType=dashboards${!isEmpty(payload) ? `&${stringifyQS(payload)}` : ''}`);
export const fetchDashboards = ({payload}) =>
  get(`${root}?schemaType=dashboardsV2${!isEmpty(payload) ? `&${stringifyQS(payload)}` : ''}`);
export const fetchDashboard = ({payload}) =>
  get(`${root}/${payload.id}?schemaType=dashboardsV2${!isEmpty(payload) ? `&${stringifyQS(payload)}` : ''}`);
// eslint-disable-next-line no-unused-vars
export const deleteDashboard = ({payload, meta}) =>
  delete_(`${root}/${meta.dashboardId}?schemaType=${meta.isV1 ? 'dashboards' : 'dashboardsV2'}`);
// eslint-disable-next-line no-unused-vars
export const fetchLastViewedDashboards = ({payload, meta}) => get(`${lastView}/${meta.userId}?type=dashboardsV2`);
export const setLastViewedDashboard = ({payload, meta}) => post(`${lastView}/${meta.userId}`, payload);
export const updateDashboard = ({payload}) => put(`${root}/${payload._id}?schemaType=dashboardsV2`, payload);
export const setFavorite = ({payload, meta}) =>
  post(`${root}/${meta.dashboardId}/star?schemaType=${meta.isV1 ? 'dashboards' : 'dashboardsV2'}`, payload);
export const fetchDataPoints = ({payload}) => post(`api/v1/metrics/composite/execute?${payload.urlExt}`, payload.body);

export const fetchDashboardV1 = ({payload}) => get(`${root}/${payload.id}?schemaType=dashboard`);
export const fetchDashboardListV1 = () => get(`${root}?filterBy=all&index=0&order=asc&q=&size=200&sort=name`);
export const fetchDashboardListV2 = () =>
  get(`${root}?schemaType=dashboardsV2&filterBy=all&index=0&order=asc&q=&size=200&sort=name`);
export const updateDashboardV1 = ({payload}) => put(`${root}/addTile/${payload.id}?schemaType=dashboard`, payload.body);
export const addTile = ({payload}) => put(`${root}/addTile/${payload.id}?schemaType=dashboardsV2`, payload.body);
export const getAllFilters = ({payload}) => post('api/v1/search/properties', payload);
// eslint-disable-next-line max-len
export const getAnonymousInvitation = ({payload}) =>
  post('api/v1/anonymous-invitation?schemaType=dashboardsV2', payload);
export const revokeAnonymousInvitations = ({payload}) =>
  post('api/v1/anonymous-invitation/revoke?schemaType=dashboardsV2', payload);
export const getAnonymousUser = ({payload}) => post('api/v1/anonymise', payload);
export const getTriggeredAlerts = ({payload}) => get(`api/v1/alerts/triggered?${stringifyQS(payload)}`);
export const uploadDashboard = ({payload, meta}) =>
  post(`${root}/import-file?schemaType=${meta.isV2 ? 'dashboardsV2' : 'dashboards'}`, payload);
