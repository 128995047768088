// @flow

import React from 'react';
import {segmentClickEvent} from 'common/store/actions';
import {connect} from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import {AutoSizer, List} from 'react-virtualized';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/debounceTime';
import shallowEqual from 'common/utils/shallowEqual';

import {Observable} from 'rxjs/Observable';

type PropTypes = {
  optionComponent: Node,
  options: Array,
  onSelect: Function,
  onBack: Function,
  rowHeight: Number,
  highlightText: String,
  panelWidth: Number,
  panelHeight: Number,
  isFocused: boolean,
  selectedValue: Object,
  isMultiValues: boolean,
  selectedValuesArr: Array,
  useMulti: boolean,
  useHeader: boolean,
  onKeyboardEvent: Function,
  getOptionLabel: Function,
  useExternalNavigation: boolean,
  selectAll: boolean,
  isAsyncData: boolean,
  externalScrollToIndex: Number,
  initialFocusIndex: Number,

  dontUseKeyboard: boolean,
  automationId: string,
  segmentClickEvent: Function,
};

@connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)
export default class VirtualizedListOfComponents extends React.PureComponent {
  props: PropTypes;

  state = {
    focusedItem: -1,
    scrollToIndex: -1,
    filteredOptions: [],
  };

  // TODO --- Clear focus when no item is being pointed (on the search value, for exemple)

  componentDidMount() {
    this.setFilteredOptions();
    if (!this.props.useExternalNavigation) {
      this.keyboardEvent$ = Observable.fromEvent(document, 'keydown');

      this.unsubscribable = this.keyboardEvent$.subscribe((evt) => {
        this.handleKeyboardEvent(evt);
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!shallowEqual(prevProps.options, this.props.options)) {
      this.setFilteredOptions();
    }
    if (this.list) {
      this.list.forceUpdateGrid();
    }
    if (prevProps.initialFocusIndex !== this.props.initialFocusIndex) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({focusedItem: this.props.initialFocusIndex, scrollToIndex: this.props.initialFocusIndex});
    }
  }

  componentWillUnmount() {
    if (this.unsubscribable && typeof this.unsubscribable.unsubscribe === 'function') {
      this.unsubscribable.unsubscribe();
      this.unsubscribable = null;
      this.keyboardEvent$ = null;
    }
  }

  handleNavigation = (val) => {
    this.handleKeyboardEvent(val);
  };

  label = (option) => (this.props.getOptionLabel ? this.props.getOptionLabel(option) : option.label);

  handleKeyboardEvent = (evt) => {
    const {focusedItem} = this.state;

    if (!this.props.isFocused) {
      return;
    }

    if (this.props.dontUseKeyboard) {
      return;
    }
    switch (evt.key) {
      case 'ArrowDown': {
        let jump = 1;
        if (focusedItem < this.state.filteredOptions.length - 1) {
          const nextOption = this.state.filteredOptions[focusedItem + 1];
          if (nextOption.header || nextOption.footer) {
            jump = 2;
          }
          this.setState({focusedItem: focusedItem + jump, scrollToIndex: focusedItem + jump});
        } else {
          this.setState({focusedItem: 0, scrollToIndex: 0});
        }
        break;
      }
      case 'ArrowUp': {
        let jump = 1;
        if (focusedItem > 0) {
          const prevOption = this.state.filteredOptions[focusedItem - 1];
          if (prevOption.header || prevOption.footer) {
            jump = 2;
          }
          this.setState({focusedItem: focusedItem - jump, scrollToIndex: focusedItem - jump});
        } else {
          this.setState((prevState) => ({
            focusedItem: prevState.filteredOptions.length - 1,
            scrollToIndex: prevState.filteredOptions.length - 1,
          }));
        }
        break;
      }
      case 'ArrowRight':
        if (
          this.state.filteredOptions[this.state.focusedItem] &&
          (this.state.filteredOptions[this.state.focusedItem].itemValues ||
            this.state.filteredOptions[this.state.focusedItem].multi)
        ) {
          this.handleSelect();
        }
        break;
      case 'ArrowLeft':
        if (typeof this.props.onBack === 'function') {
          this.props.onBack();
        }
        break;
      case 'Escape':
        this.setState({focusedItem: null});
        break;
      case 'Enter':
        if (this.state.focusedItem === -1 && typeof this.props.onBack === 'function') {
          this.props.onBack();
        }
        this.setState({focusedItem: -1, scrollToIndex: -1});
        evt.preventDefault();
        evt.stopPropagation();
        this.handleSelect(focusedItem === null ? 0 : focusedItem);
        break;
      default:
    }
    if (this.props.onKeyboardEvent) {
      this.props.onKeyboardEvent(evt);
    }

    if (this.list) {
      this.list.forceUpdateGrid();
    }
  };

  handleMouseEnter = (i) => {
    this.setState({focusedItem: i});
    if (this.list) {
      this.list.forceUpdateGrid();
    }
  };

  handleSelect = (index) => {
    if (!this.props.isFocused) {
      return;
    }
    if (
      this.state.filteredOptions[this.state.focusedItem] &&
      this.state.filteredOptions[this.state.focusedItem].header
    ) {
      return;
    }
    if (this.state.focusedItem === -1) {
      this.setState({focusedItem: index});
    }
    const indexItemToCall = this.state.focusedItem === -1 ? index : this.state.focusedItem;
    let itemToCall = this.state.filteredOptions[indexItemToCall];
    if ((itemToCall || {}).header) {
      itemToCall = this.state.filteredOptions[indexItemToCall + 1];
    }
    this.props.segmentClickEvent({
      type: 'option-selected',
      name: this.props.automationId || 'virtualized list',
      value: itemToCall,
      automationId: this.props.automationId,
    });
    this.props.onSelect(itemToCall);
    if (this.list) {
      this.list.forceUpdateGrid();
    }
  };

  handleOnClick = (index, option) => {
    if (option.footer || option.header) {
      return;
    }
    this.props.segmentClickEvent({
      type: 'option-selected',
      name: this.props.automationId || 'virtualized list',
      value: this.state.filteredOptions[index],
      automationId: this.props.automationId,
    });
    this.props.onSelect(this.state.filteredOptions[index]);
  };

  rowRenderer = ({index, style}) => {
    const option = this.state.filteredOptions[index];
    const focusedItem =
      this.props.useExternalNavigation && this.props.externalScrollToIndex
        ? this.props.externalScrollToIndex
        : this.state.focusedItem;
    const {selectedValue, isMultiValues, selectedValuesArr, automationId} = this.props;
    let isSelected = false;
    if (isMultiValues && selectedValuesArr) {
      isSelected =
        selectedValuesArr.findIndex((value) => value.value === option.value && value.parent === option.parent) > -1;
    } else if (this.state.filteredOptions[index].multi) {
      isSelected = this.state.filteredOptions[index].multi.findIndex((multiItem) => multiItem === selectedValue) > -1;
    } else if (option.value) {
      isSelected = option.value === selectedValue.value;
    } else {
      isSelected = this.label(option) === this.label(selectedValue);
    }
    if (this.props.selectAll) {
      isSelected = true;
    }
    return (
      <div
        automation-id={`${automationId}-${index}`}
        style={style}
        key={option.id || option.value || this.label(option)}
      >
        <div className="option-list-item" onMouseUp={() => this.handleOnClick(index, option)}>
          {React.cloneElement(this.props.optionComponent, {
            data: option,
            getOptionLabel: this.props.getOptionLabel,
            roleState: focusedItem === index ? 'focused' : '',
            highlightText: this.props.highlightText,
            useMulti: this.props.useMulti,
            isAsyncData: this.props.isAsyncData,
            isSelected,
          })}
        </div>
      </div>
    );
  };

  setFilteredOptions = () => {
    this.setState({
      filteredOptions: !this.props.useHeader
        ? this.props.options.filter((option) => !option.header)
        : this.props.options,
    });
  };

  render() {
    return (
      <div style={{width: this.props.panelWidth, minHeight: this.props.panelHeight}}>
        <AutoSizer>
          {({height, width}) => (
            <List
              ref={(list) => {
                this.list = list;
              }}
              width={width}
              height={height}
              rowCount={this.state.filteredOptions.length}
              rowHeight={this.props.rowHeight}
              rowRenderer={this.rowRenderer}
              scrollToIndex={
                this.props.useExternalNavigation && this.props.externalScrollToIndex
                  ? this.props.externalScrollToIndex
                  : this.state.scrollToIndex
              }
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}
