import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {debounce, get} from 'lodash';
import {makeStyles} from '@material-ui/core';
import {useField} from 'react-final-form';
import {
  makeFiltersPayload,
  makeUpdateLegendPayload,
  makeUpdateTilePayload,
  parseFiltersPayload,
} from 'dashboards/utils';
import {updateDashboard} from 'dashboards/store/actions';
import useExpressionTreesTile from 'dashboards/components/metricTiles/useExpressionTreesTile';
import CollapsedMetricsList from 'metrics/components/metricExplorer/CollapsedMetricsList';
import TileHeader from 'dashboards/components/metricTiles/TileHeader';
import {getDashboardUserSettings} from 'dashboards/store/selectors';
import {getDateValue} from 'common/utils/dateRangeService';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const useStyles = makeStyles(() => ({
  wrapperTile: {
    flexGrow: 1,
    borderRadius: 6,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 6,
    '&:hover': {
      '@global': {
        '.editMode, .editWrapper': {
          display: 'flex',
          visibility: 'visible',
        },
      },
    },
  },
}));

const GraphTileContainer = ({
  tileId,
  dashboardData,
  tileData,
  onMouseMove,
  isOwnerUser,
  matchUrl,
  isAnonymous,
  isVisible,
}: {
  tileId: string,
  onMouseMove: Function,
  dashboardData: Object,
  tileData: Object,
  isOwnerUser: boolean,
  isAnonymous: boolean,
  matchUrl: string,
  isVisible: boolean,
}) => {
  const chartId = `chart_${tileId}`;

  const classes = useStyles();
  const dispatch = useDispatch();
  const {data: dashboardUserSettings} = useSelector(getDashboardUserSettings);
  const tileSettingsValue = get(dashboardUserSettings, `tiles[${tileId}]`, EMPTY_OBJECT);

  const {
    input: {value: isResize, onChange: setResize},
  } = useField('isResize');
  const {
    input: {value: isEditableMode},
  } = useField('isEditableMode');
  const {
    input: {value: dateRange},
  } = useField('dateRange');
  const {
    input: {value: timeScale},
  } = useField('timeScale');
  const {
    input: {value: filters},
  } = useField('filters');

  const commonValues = useMemo(
    () => ({
      dateRange: getDateValue(dateRange),
      timeScale,
      filters: parseFiltersPayload(makeFiltersPayload(filters)),
    }),
    [dateRange, timeScale, filters],
  );

  const computedValues = useMemo(
    () => ({
      dateRange: getDateValue(get(tileSettingsValue, 'dateRange', dateRange)),
      timeScale: get(tileSettingsValue, 'timeScale', timeScale),
      filters: parseFiltersPayload(get(tileSettingsValue, 'selectorsFilter.selectors', makeFiltersPayload(filters))),
    }),
    [tileSettingsValue, dateRange, timeScale, filters],
  );

  const {metrics, byTreeExp, validationMessage, isCompositeDataLoading, compositeErrorsMap} = useExpressionTreesTile({
    ...computedValues,
    tileData,
    chartId,
    isVisible,
  });

  const onChartResize = useCallback(
    debounce((size, isLegendRight) => {
      const tile = makeUpdateLegendPayload(tileData, size, isLegendRight);
      if (!isAnonymous && isOwnerUser) {
        dispatch(updateDashboard(makeUpdateTilePayload(dashboardData, tile, tileData.id)));
      }
    }, 2000),
    [dashboardData, tileData, isAnonymous, isOwnerUser],
  );

  const isLegendEnabled = get(tileData, 'lineChart.legend.enabled', false);
  const isLegendRight = get(tileData, 'lineChart.legend.position') === 'right';
  const staticLine = get(tileData, 'lineChart.staticLine');

  const legendSize = useMemo(
    () => ({
      w: get(tileData, 'lineChart.legend.horizontalRatio'),
      h: get(tileData, 'lineChart.legend.ratio'),
    }),
    [tileData],
  );

  const isLoading = isCompositeDataLoading && metrics.length === 0;

  return (
    <div
      className={`${classes.wrapperTile} ${isEditableMode ? classes.editMode : ''} bgcolor_white-500 pl_0-5 pb_${
        tileData.lineChart.legend.enabled ? 0 : '1-5'
      }`}
    >
      <TileHeader
        dashboardData={dashboardData}
        tileData={tileData}
        isEditableMode={isEditableMode}
        matchUrl={matchUrl}
        formValues={computedValues}
        commonValues={commonValues}
        isOwnerUser={isOwnerUser}
        isAnonymous={isAnonymous}
        validationMessage={validationMessage}
        compositeErrorsMap={compositeErrorsMap}
        isCompositeDataLoading={isCompositeDataLoading}
      />
      <div className="display_flex flexDirection_column flexGrow_1 pr_1">
        <CollapsedMetricsList
          isExtendedTooltip
          onChartResize={onChartResize}
          dateRange={computedValues.dateRange}
          chartId={chartId}
          isZoomLoading={isLoading && get(dateRange, 'constRange') === 'c'}
          timeScale={timeScale}
          metrics={metrics || EMPTY_ARRAY}
          isLoading={isLoading}
          legendSize={legendSize}
          isLegendEnabled={isLegendEnabled && metrics.length > 0}
          isLegendRight={isLegendRight}
          isTile
          onMouseMove={onMouseMove}
          isResize={isResize}
          setResize={setResize}
          byTreeExp={byTreeExp}
          staticLine={staticLine}
          tileData={tileData}
          dashboardId={dashboardData._id}
        />
      </div>
    </div>
  );
};

export default React.memo(GraphTileContainer);
