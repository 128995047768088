// @flow
import React, {Fragment, useCallback, useState} from 'react';
import {Field, useField} from 'react-final-form';
import Input from 'common/componentsV2/Input';
import ToggleSwitch from 'common/componentsV2/toggleSwitch/ToggleSwitch';
import TagChipsInput from 'common/componentsV2/TagChipsInput';
import './EditCustomerForm.module.scss';

const parseDns = (value) => {
  if (!value) return value;
  const re = new RegExp('^[a-z1-9\\-]*$');
  if (value && !re.test(value)) {
    return value.toLowerCase().replace(/[^a-z0-9\\-]/g, '-');
  }
  return value;
};

const EditCustomerFormCustomerData = () => {
  const {
    input: {value: isNew},
  } = useField('isNew');
  const {
    input: {value: dnsPrefix, onChange: setDnsPrefix},
  } = useField('customer.dnsPrefix');

  const changeName = useCallback(
    (event, nameField) => {
      // Auto adjust DNS Prefix only if it was not modified from default
      if (parseDns(nameField.value) === dnsPrefix) {
        setDnsPrefix(parseDns(event.target.value));
      }
      nameField.onChange(event);
    },
    [dnsPrefix, setDnsPrefix],
  );

  const [tagsInput, setTagsInput] = useState('');

  return (
    <Fragment>
      <Field name="customer.name">
        {({input}) => (
          <div styleName="row">
            <div styleName="label">Name</div>
            <Input
              name={input.name}
              value={input.value}
              onChange={(event) => {
                changeName(event, input);
              }}
              isDisabled={!isNew}
              additionalProps={{maxLength: '50'}}
              placeHolder="Required"
            />
          </div>
        )}
      </Field>
      <Field name="customer.dnsPrefix" parse={parseDns}>
        {({input, meta}) => (
          <div styleName="row">
            <div styleName="label">DNS Prefix</div>
            <Input
              name={input.name}
              value={input.value}
              onChange={input.onChange}
              isDisabled={!isNew}
              placeHolder="Required"
              isInvalid={meta.error && meta.error !== 'Required'}
              invalidMessage={meta.error}
            />
            <span styleName="sub">Should be DNS name compatible, valid chars: a-z,0-9,-.</span>
          </div>
        )}
      </Field>
      {!isNew && (
        <Field name="customer.disabled">
          {({input, meta}) => (
            <div styleName="row">
              <div styleName="label">Enabled</div>
              <ToggleSwitch
                name={input.name}
                theme="blue"
                isChecked={!input.value}
                onToggle={() => input.onChange(!input.value)}
              />
              {/* If modified to be disabled, show warning */}
              {input.value && meta.modified && (
                <span styleName="error">
                  This will deactivate customer from the system. Customer users will not be able to login to the system
                  and customer data will be rejected.
                </span>
              )}
            </div>
          )}
        </Field>
      )}
      <Field name="customer.description" parse={(v) => v}>
        {({input}) => (
          <div styleName="row">
            <div styleName="label">Description</div>
            <Input name={input.name} value={input.value} onChange={input.onChange} />
          </div>
        )}
      </Field>
      <Field name="customer.tags">
        {({input}) => (
          <div styleName="row">
            <div styleName="label">Tags</div>
            <TagChipsInput
              name={input.name}
              value={tagsInput}
              items={
                input.value
                  ? input.value.map((tag) => {
                      return {
                        label: tag,
                        id: tag,
                      };
                    })
                  : []
              }
              onAddItems={(tagToAdd) => {
                input.onChange(input.value.concat(tagToAdd.map((tag) => tag.label)));
                setTagsInput('');
              }}
              onRemoveItem={(tagToDelete) => {
                input.onChange(input.value.filter((tag) => tag !== tagToDelete.label));
              }}
              onValueChange={setTagsInput}
            />
          </div>
        )}
      </Field>
      <Field name="customer.settings.auth.oauth.google.enable">
        {({input}) => (
          <div styleName="row">
            <div styleName="label">Google OAuth</div>
            <ToggleSwitch
              name={input.name}
              theme="blue"
              isChecked={input.value}
              onToggle={() => input.onChange(!input.value)}
            />
          </div>
        )}
      </Field>
      <Field name="customer.settings.payingCustomer">
        {({input}) => (
          <div styleName="row">
            <div styleName="label">Paying Customer</div>
            <ToggleSwitch
              name={input.name}
              theme="blue"
              isChecked={input.value}
              onToggle={() => input.onChange(!input.value)}
            />
          </div>
        )}
      </Field>
    </Fragment>
  );
};
export default EditCustomerFormCustomerData;
