// @flow
import React from 'react';
import {CSSTransition} from 'react-transition-group';
import {pick} from 'lodash';

type PropTypes = {
  children: any,
};

const FADE_PROPS = [
  'in',
  'mountOnEnter',
  'unmountOnExit',
  'appear',
  'enter',
  'exit',
  'in',
  'onEnter',
  'onEntering',
  'onEntered',
  'onExit',
  'onExiting',
  'onExited',
];

const Fade = (props: PropTypes) => (
  <CSSTransition {...pick(props, FADE_PROPS)} timeout={500} classNames="fade">
    {props.children}
  </CSSTransition>
);

export default Fade;
