/* eslint-disable */

import React, {useMemo} from 'react';
import * as style from "./selectAndt/SelectAndtStyling";
import {THEME_BLUE_LEAN} from "./selectAndt/SelectAndt";
import Select from "react-select2";
import Control from "./selectAndt/innerComponents/Control";
import DropdownIndicatorCaretNoTransform from "./selectAndt/innerComponents/DropdownIndicatorCaretNoTransform";
import MenuListVirtualize from "./selectAndt/innerComponents/MenuListVirtualize";
import OptionSelectedSigned from "./selectAndt/innerComponents/OptionSelectedSigned";
import EmptyComponent from "./selectAndt/innerComponents/EmptyComponent";
import ValueContainer from "./selectAndt/innerComponents/ValueContainer";

const components = {
  Control,
  DropdownIndicator: DropdownIndicatorCaretNoTransform,
  MenuList: MenuListVirtualize,
  Option: OptionSelectedSigned,
  SingleValue: EmptyComponent,
  Placeholder: EmptyComponent,
  ValueContainer,
};

const getIsOptionDisabled = (option) => {
  const optionHeaderTypesArr = ['HEADER', 'header'];
  return (
    option.disabled || option.isHeader || optionHeaderTypesArr.indexOf(option.type) !== -1 || option.category === true
  );
};

const NoSearchBlueLeanSelect = ({options, onChange, value, menuWidth, buttonWidth, maxMenuHeight, minMenuHeight}) => {
  const styles = useMemo(() => style.newSearchStyle(THEME_BLUE_LEAN, {
    menuWidth,
    buttonWidth,
    maxMenuHeight,
    minMenuHeight,
  }), [menuWidth, buttonWidth, maxMenuHeight, minMenuHeight]);
  return (
    <Select
      isSearchable={false}
      className="select-menu"
      styles={styles}
      menuPlacement="auto"
      minMenuHeight={200}
      maxMenuHeight={300}
      maxHeight={300}
      optionHeight={40}
      isOptionDisabled={getIsOptionDisabled}
      hideSelectedOptions={false}
      animationDuration={1}
      captureMenuScroll
      menuShouldScrollIntoView={false}
      options={options}
      onChange={onChange}
      value={value}
      components={components}
    />
  );
};

export default React.memo(NoSearchBlueLeanSelect);
