// @flow
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SmartTooltip from 'common/componentsV2/Tooltip';
import Highlighter from 'react-highlight-words';
import ImpactChip from 'impact/ImpactChip';
import {themesOptions} from 'impact/impactTheme';
import {setFeedback, deleteFeedback, updateFeedback} from 'alerts.console/store/actions';
import Feedback from 'feedback/Feedback';
import {FEEDBACK_TYPE, NOT_INTERESTING_REASONS, FEEDBACK_ORIGIN} from 'feedback/services/constants';
import {getUserProfile} from 'profile/store/selectors';
import AcknowledgeIndicator from '../alertActions/AcknowledgeIndicator';
import SnoozeIndicator from '../alertActions/SnoozeActionIndicator';
import AlertType from './AlertType';
import './alertContent.module.scss';

const AlertTitle = (props: {
  alert: Object,
  searchWords: string,
  meId: Object,
  onMouseOver: Boolean,
  isRowOpen: Boolean,
}) => {
  const {alert, searchWords, meId, onMouseOver, isRowOpen} = props;
  const dispatch = useDispatch();
  const meProfile = useSelector(getUserProfile);

  const setFeedbackParams = (type) => ({
    type,
    userId: meId,
    triggeredId: alert.id,
    origin: FEEDBACK_ORIGIN.ALERT_CONSOLE,
    userName: `${meProfile.firstName}${' '}${meProfile.lastName}` || 'Unknown',
  });

  const setIsFeedbackByMe = (type) => {
    const feedbackByMe = alert.feedback.find((f) => f.userId === meId);
    if (feedbackByMe) {
      return feedbackByMe.type === FEEDBACK_TYPE[type];
    }
    return false;
  };

  const setFeedbacks = (type) => alert.feedback.filter((f) => f.type === FEEDBACK_TYPE[type] && f.userId !== meId);

  const othersGoodCatch = setFeedbacks(FEEDBACK_TYPE.GOOD_CATCH);
  const otherNotInteresting = setFeedbacks(FEEDBACK_TYPE.NOT_INTERESTING);
  const isGoodCatchByMe = setIsFeedbackByMe(FEEDBACK_TYPE.GOOD_CATCH);
  const isNotInterestingByMe = setIsFeedbackByMe(FEEDBACK_TYPE.NOT_INTERESTING);

  const setGoodCatch = () => {
    const params = setFeedbackParams(FEEDBACK_TYPE.GOOD_CATCH);
    dispatch(setFeedback(params, {alertId: params.triggeredId}));
  };

  const deleteGoodCatch = () => {
    const feedback = props.alert.feedback.find((f) => f.userId === meId);
    const params = setFeedbackParams(FEEDBACK_TYPE.GOOD_CATCH);
    dispatch(deleteFeedback({...params, id: feedback.id}, {alertId: params.triggeredId, type: params.type}));
  };

  const setNotInteresting = () => {
    const params = setFeedbackParams(FEEDBACK_TYPE.NOT_INTERESTING);
    dispatch(setFeedback(params, {alertId: params.triggeredId}));
  };

  const deleteNotInteresting = () => {
    const feedback = props.alert.feedback.find((f) => f.userId === meId);
    const params = setFeedbackParams(FEEDBACK_TYPE.NOT_INTERESTING);
    dispatch(deleteFeedback({...params, id: feedback.id}, {alertId: params.triggeredId, type: params.type}));
  };

  const updateGoodCatch = ({comment}) => {
    const feedback = props.alert.feedback.find((f) => f.userId === meId);
    const params = setFeedbackParams(FEEDBACK_TYPE.GOOD_CATCH);
    dispatch(
      updateFeedback({
        ...params,
        id: feedback.id,
        comment,
      }),
    );
  };

  const updateNotInteresting = ({reason, comment}) => {
    const feedback = props.alert.feedback.find((f) => f.userId === meId);
    const params = setFeedbackParams(FEEDBACK_TYPE.NOT_INTERESTING);
    dispatch(
      updateFeedback({
        ...params,
        id: feedback.id,
        reason: reason ? NOT_INTERESTING_REASONS.find((r) => r.value === reason).title : '',
        comment,
      }),
    );
  };

  return (
    <div styleName="title">
      <div styleName="text">
        <SmartTooltip placement="top" content={alert.title} delay={1000}>
          <span className="ellipsis">
            <Highlighter
              autoEscape
              highlightStyle={{backgroundColor: '#fcd176'}}
              searchWords={[searchWords]}
              textToHighlight={alert.title}
            />
          </span>
        </SmartTooltip>
        {alert.type !== 'anomaly' && <AlertType type={alert.type} />}
        {alert.impact && (
          <ImpactChip impact={alert.impact} themeName={themesOptions.tiny} tooltipContent="Impact for this Alert" />
        )}
      </div>

      <div styleName="indicators">
        <AcknowledgeIndicator starredBy={alert.acknowledgedBy} meId={meId} />
        <SnoozeIndicator
          triggerId={alert.id}
          alertConfigurationId={alert.alertConfigurationId}
          summary={alert.summary}
          timeScale={alert.timeScale}
          alertType={alert.type}
        />
      </div>
      {alert.type === 'anomaly' && (
        <div styleName="feedback">
          <Feedback
            isGoodCatchByMe={isGoodCatchByMe}
            isNotInterestingByMe={isNotInterestingByMe}
            othersGoodCatch={othersGoodCatch}
            otherNotInteresting={otherNotInteresting}
            onSetGoodCatch={setGoodCatch}
            onSetNotInteresting={setNotInteresting}
            onRemoveGoodCatch={deleteGoodCatch}
            onRemoveNotInteresting={deleteNotInteresting}
            onUpdateGoodCatch={updateGoodCatch}
            onUpdateNotInteresting={updateNotInteresting}
            isGoodCatchDisplay={othersGoodCatch.length || isGoodCatchByMe || onMouseOver || isRowOpen}
            isNotInterestingDisplay={otherNotInteresting.length || isNotInterestingByMe || onMouseOver || isRowOpen}
          />
        </div>
      )}
    </div>
  );
};

export default AlertTitle;
