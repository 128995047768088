// @flow
import React, {PureComponent} from 'react';
import {SEVERITY} from 'alerts.console/services/alertsConsoleService';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import connect from 'react-redux/es/connect/connect';
import {getChangedSeverities} from 'alerts.console/store/filterSelector';

type PropTypes = {
  setQueryParams: Function,
  changedSeverities: Object,
};

@connect(
  (state) => ({
    changedSeverities: getChangedSeverities(state),
  }),
  {},
)
export default class SeverityFilter extends PureComponent {
  props: PropTypes;

  onChange = (values) => {
    const {setQueryParams} = this.props;
    const currentType = values.length ? values.map((i) => i.value).join(',') : undefined;

    setQueryParams({severities: currentType});
  };

  render() {
    return (
      <SelectAndt
        automationId="alertSeverityFilter"
        type={TYPE_NEW_MULTI_NO_SEARCH}
        theme={THEME_HIGHLIGHTED}
        onChange={this.onChange}
        options={SEVERITY}
        value={this.props.changedSeverities}
        isMulti
        placeholder="All"
        optionHeight={40}
        menuWidth={155}
        isClearable={false}
        closeMenuOnSelect={false}
        noAutoReorder
      />
    );
  }
}
