// @flow
import React, {Fragment, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import SearchBox from 'common/componentsV2/SearchBox';
import {Box} from '@material-ui/core';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import FiltersPanel from 'common/componentsV2/FiltersPanel';
import * as selectors from 'dashboards/store/selectors';
import {getUsersGroupsListsWithoutDeleteGroup} from 'admin.users/store/selectors';
import {DEFAULT_PARAMS} from 'dashboards/services/dashboardService';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import UsersChannelsFilter from 'alerts.channels/components/UsersChannelsFilter';
import isEqual from 'lodash/isEqual';

type PropTypes = {
  toggleFilters: Function,
  setQueryParams: Function,
  onFilter: Function,
  onChange: Function,
  dataQueryParams: Object,
};

const DashboardsFilters = React.memo(
  ({toggleFilters, setQueryParams, onFilter, onChange, dataQueryParams}: {...PropTypes}) => {
    const optionsOwner = useSelector(getUsersGroupsListsWithoutDeleteGroup);
    const optionsTags = useSelector(selectors.getTagsList);
    const isOpen = useSelector(selectors.isOpenFilters);

    const selectedOptionOwner = useMemo(
      () => optionsOwner.filter((item) => (dataQueryParams.ownerUser || '').includes(item.value)),
      [dataQueryParams.ownerUser, optionsOwner],
    );
    const selectedOptionTags = useMemo(
      () => optionsTags.filter((tag) => (dataQueryParams.tags || '').includes(tag.name)),
      [optionsTags, dataQueryParams.tags],
    );
    const sortedTagsList = useMemo(() => optionsTags.sort((a, b) => b.count - a.count), [optionsTags]);

    const onChangeHandler = useCallback((items, nameField) => {
      const value = items.length ? items.map((item) => item.value || item.name).join(',') : undefined;
      onChange({value}, nameField);
    }, []);

    const onClearAll = useCallback((nameField) => {
      setQueryParams({[nameField]: undefined});
    }, []);

    return (
      <Fragment>
        <FiltersPanel
          isOpen={isOpen}
          isSaveView={false}
          isShareLink={false}
          onClose={toggleFilters}
          onClearAll={() => setQueryParams(DEFAULT_PARAMS)}
          isClearAll={!isEqual(dataQueryParams, DEFAULT_PARAMS)}
        >
          <SearchBox placeHolder="Search Dashboards" filter={dataQueryParams.q || ''} onFilter={onFilter} />
          <Box mt={2.5} width={210}>
            <div className="text14reg lineHeight_14 mb_0-5">Dashboard Owner</div>
            <UsersChannelsFilter
              onChange={(items) => onChangeHandler(items, 'ownerUser')}
              sortedFlattenChannels={optionsOwner}
              value={selectedOptionOwner}
              footerComponent={
                <FormDdlActionBar
                  isVisible
                  isClearAllDisabled={!selectedOptionOwner || !selectedOptionOwner.length}
                  onClearAll={() => onClearAll('ownerUser')}
                />
              }
            />
          </Box>
          <Box mt={2.5} width={210} pb={2.5}>
            <div className="text14reg lineHeight_14 mb_0-5">Labels</div>
            <SelectAndt
              automationId="dashboardsLabels"
              type={TYPE_NEW_MULTI}
              theme={THEME_HIGHLIGHTED}
              onChange={(items) => onChangeHandler(items, 'tags')}
              options={sortedTagsList}
              value={selectedOptionTags}
              isMulti
              placeholder="Select"
              optionHeight={40}
              menuWidth={270}
              isClearable={false}
              closeMenuOnSelect={false}
              getOptionLabel={(val) => (val.val ? val.val : val.name)}
              getOptionValue={(val) => val.name}
              minMenuHeight={300}
              menuFooterComponent={
                <FormDdlActionBar
                  isVisible
                  isClearAllDisabled={!selectedOptionTags || !selectedOptionTags.length}
                  onClearAll={() => onClearAll('tags')}
                />
              }
            />
          </Box>
        </FiltersPanel>
      </Fragment>
    );
  },
);

export default DashboardsFilters;
