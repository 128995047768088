// @flow
import React from 'react';

const styles = {
  wrapper: {
    height: 20,
    display: 'flex',
    justifyContent: 'flex - end',
  },

  text: {
    fontSize: 14,
    fontWeight: '500',
    color: '#8995a0',
  },
};

const AlertsCounter = (propTypes: {displayedTotalAlerts: Number, alertsTriggeredTotal: Number, dateRange: String}) => {
  const {displayedTotalAlerts, alertsTriggeredTotal, dateRange} = propTypes;
  return (
    <div style={styles.wrapper}>
      <span style={styles.text}>
        {`Showing ${displayedTotalAlerts}/${alertsTriggeredTotal} Alerts from the ${dateRange}`}
      </span>
    </div>
  );
};

export default AlertsCounter;
