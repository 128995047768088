// @flow
import React from 'react';
import Checkbox from 'common/componentsV2/Checkbox';
import SmartTooltip from 'common/components/SmartTooltip';
import ConditionContainer from './ConditionContainer';
import './EventsCondition.module.scss';

const options = [{value: true, text: 'YES'}, {value: false, text: 'NO'}];

type PropTypes = {
  shouldShowEvents: boolean,
  isInfluencingEvents: boolean,
  onChange: Function,
  onSetIsInfluencingEvents: Function,
  regressorsEnabled: boolean,
  isImpersonating: boolean,
};

const regressorsTooltipContent = (enabled) => {
  if (enabled) {
    return 'Check to activate regressors';
  }
  return 'Correlate Events should be On to enable regressors';
};

const EventsSelector = (props: PropTypes) => (
  <ConditionContainer title="Correlate Events" subTitle="Correlate events to the anomalies in the alert">
    <div styleName="buttons-container" automation-id="alertSettingsEventsButtons">
      {options.map((s) => (
        <button
          type="button"
          key={s.value}
          className={`btn btn-raised btn-outline btn-outline-large ${
            s.value === props.shouldShowEvents ? 'btn-outline-light' : 'btn-outline-dark'
          }`}
          onClick={() => props.onChange(s.value)}
        >
          {s.text}
        </button>
      ))}
    </div>
    <SmartTooltip content={regressorsTooltipContent(props.shouldShowEvents)}>
      <div styleName={props.shouldShowEvents ? 'regressors-checkbox-wrapper' : 'regressors-checkbox-wrapper disabled'}>
        {props.regressorsEnabled && props.isImpersonating && (
          <Checkbox
            isDisabled={!props.shouldShowEvents}
            text="Influencing Events"
            isChecked={props.isInfluencingEvents}
            onChange={props.onSetIsInfluencingEvents}
          />
        )}
      </div>
    </SmartTooltip>
  </ConditionContainer>
);

export default EventsSelector;
