import React, {useState, useRef, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    borderRadius: '6px',
    backgroundColor: theme.palette.gray[200],
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.gray[500],
    width: '100%',
    padding: '12px',
    border: 'none',
    resize: 'none',
    '&:focus': {
      backgroundColor: theme.palette.gray[100],
      border: '2px solid rgba(38, 113, 255, 0.3)',
      padding: '10px',
      color: '#3d4c59',
    },
  },
}));

type PropTypes = {
  minRows: number,
  maxRows: number,
  onChange: Function,
  onKeyDown: Function,
  placeholder: string,
  automationId: string,
  value: string,
};

const TextArea = (props: PropTypes) => {
  const textareaLineHeight = 24;
  const ref = useRef();

  const [rows, setRows] = useState(props.minRows);
  const classes = useStyles();

  const updateHeight = () => {
    const {minRows, maxRows} = props;

    const previousRows = ref.current.rows;
    ref.current.rows = minRows;
    let currentRows = Math.floor(ref.current.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      ref.current.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      ref.current.rows = maxRows;
      currentRows = maxRows;
      ref.current.scrollTop = ref.current.scrollHeight;
    }

    setRows(currentRows);
  };

  const handleChange = (event) => {
    updateHeight();
    props.onChange(event);
  };

  useEffect(() => {
    const currentRows = Math.floor(ref.current.scrollHeight / textareaLineHeight);
    setRows(Math.max(currentRows - 1, props.minRows));
  }, []);

  return (
    <textarea
      automation-id={props.automationId}
      ref={ref}
      className={classes.root}
      rows={rows}
      value={props.value}
      placeholder={props.placeholder}
      onChange={handleChange}
      onKeyDown={props.onKeyDown}
    />
  );
};

export default TextArea;
