// @flow
/* eslint-disable */
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedEditor} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import SchedularData from 'bc/components/streams/editor/common/SchedularData';
import {isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import SchedulerEditorModal from 'bc/components/streams/editor/scheduler/SchedulerEditorModal';

import '../../sql/sqlSchedular/SqlSchedular.module.scss';

type PropTypes = {
  // connect
  dataStream: Object,
  setSelectedEditor: Function,
  isLoadingLabels: Boolean,
  labels: Object,
  isLoadingMeasures: Boolean,
  measures: Object,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),

    isLoadingLabels: selectors.getCoralogixStreamLabelsIsLoading(state),
    labels: selectors.getCoralogixStreamLabelsItems(state),
  }),
  {
    setSelectedEditor,
  },
)
export default class CoralogixSchedular extends React.PureComponent {
  props: PropTypes;

  state = {
    isModalOpen: false,
  };

  onClose = () => {
    this.setState({isModalOpen: false});
  };

  editClicked = () => {
    this.setState({isModalOpen: true});
  };

  render() {
    const {dataStream} = this.props;
    const isEditable = isStreamEditable(dataStream.state) || isStreamRunning(dataStream.state);

    return (
      <div className="shell-col" automation-id="bigQueryScheduler" styleName={!isEditable ? 'disabled' : ''}>
        <PanelHeader title="Query Schedule" onSelect={this.editClicked} isEditable={isEditable} />
        <SchedularData />
        {this.state.isModalOpen ? (
          <SchedulerEditorModal dataStream={dataStream} isModalOpen={this.state.isModalOpen} onClose={this.onClose} />
        ) : null}
      </div>
    );
  }
}
