import React, {useEffect, useRef} from 'react';
import Highcharts from 'highcharts';
import {palette} from 'app/styles/theme';
import {COLOR_NAME, COLORS_PICKER} from 'common/componentsV2/ColorPicker';
import {isString} from 'lodash';
import {
  DECIMAL_COUNT,
  getUnitType,
  OPTIONS_UNIT_TYPE,
  OPTIONS_UNIT_TYPE_VALUES,
} from 'dashboards/services/dashboardService';
import {format, Humanize} from 'common/utils/utilsService';
import {useField, useFormState} from 'react-final-form';

type PropTypes = {
  id: string,
  value: number,
  min: number,
  max: number,
  thresholds: Array<Object>,
  isShowValueLabels: boolean,
  unitText: string,
  isDecimal: boolean,
  autoUnitType: string,
  inputNoData: string,
  isResize: boolean,
  isMetricEmpty: boolean,
};

const NUMBER_DECIMAL_COMPARE = 10;
const NUMBER_DECIMAL_M = 1000000;

const getClosestToThreshold = (thresholds, index, maxYAxis) => {
  const number = Number(thresholds[index].value);

  const computedMaxValues = [...thresholds.map((item) => Number(item.value)), maxYAxis].filter((v) => v > number);
  if (thresholds.length > 1) {
    return Math.min(...computedMaxValues);
  }

  return undefined;
};

const plotBandsUpdater = (thresholds, isValue, currentYAxis) => {
  if (!isValue) {
    return {
      color: COLOR_NAME.BLUE,
      thickness: 3,
      from: 0,
      to: undefined,
      outerRadius: '104%',
      innerRadius: '102%',
    };
  }

  return thresholds.map((item, index) => {
    const {color, value: valueThresholds} = item;
    return {
      color: palette[COLORS_PICKER[color].color][COLORS_PICKER[color].contrast],
      thickness: 3,
      from: Number(valueThresholds),
      to: getClosestToThreshold(thresholds, index, currentYAxis),
      outerRadius: '104%',
      innerRadius: '102%',
    };
  });
};

const getMinValue = (minValue, thresholds) => {
  const thresholdsMin = Math.min(...thresholds.map((t) => Number(t.value)));
  if (
    ((minValue === null || minValue === undefined) && thresholds.some((o) => Number(o.value) > 0)) ||
    minValue > thresholdsMin
  ) {
    return 0;
  }

  if (minValue === null || minValue === undefined) {
    return null;
  }

  if (isString(minValue)) {
    return Number(minValue);
  }
  return null;
};

const getMaxValue = (maxValue, value, thresholds) => {
  const maxThresholds = Math.max(...thresholds.map((item) => Number(item.value)));
  if (maxThresholds > value && maxThresholds > maxValue) {
    return Number(maxThresholds);
  }

  return Number(maxValue) || null;
};

const stopColors = (thresholds, currentYAxis) => {
  // eslint-disable-next-line max-len
  const computedColors = thresholds.map((obj) => [
    Number(obj.value) / Number(currentYAxis),
    palette[COLORS_PICKER[obj.color].color][COLORS_PICKER[obj.color].contrast],
  ]);
  return computedColors.sort((a, b) => a[0] - b[0]);
};

const formatYAxisLabel = (value) => {
  const abbreviateValue = Humanize.humanize(value, DECIMAL_COUNT);
  return `<div>${abbreviateValue.number}${abbreviateValue.postfix}</div>`;
};

const gaugeOptions = {
  chart: {
    type: 'solidgauge',
    spacing: [0, 0, 0, 0],
  },
  lang: {
    noData: 'No Data',
  },
  noData: {
    position: {
      verticalAlign: 'bottom',
      y: -25,
    },
    style: {
      color: '#8995a0',
      fontSize: '22px',
      lineHeight: '30px',
      backgroundColor: 'transparent',
      fontWeight: 400,
    },
  },

  title: null,

  pane: {
    center: ['50%', '75%'],
    size: '140%',
    startAngle: -90,
    endAngle: 90,
    background: {
      backgroundColor: '#e9f0ff',
      innerRadius: '80%',
      outerRadius: '100%',
      borderWidth: 0,
      shape: 'arc',
    },
  },

  exporting: {
    enabled: false,
  },

  tooltip: {
    enabled: false,
  },

  // the value axis
  yAxis: {
    stops: [[0, COLORS_PICKER[COLOR_NAME.BLUE.color]]],
    lineWidth: 0,
    tickWidth: 0,
    minorTickInterval: null,
    startOnTick: false,
    endOnTick: true,
    softMin: 0,
    maxPadding: 0,
    minPadding: 0,
    tickAmount: 2,
    labels: {
      y: 10,
      distance: '90%',
      style: {
        fontSize: '12px',
        color: '#27363e',
      },
    },
  },

  plotOptions: {
    solidgauge: {
      pointStart: 0,
      dataLabels: {
        borderWidth: 0,
        useHTML: true,
        padding: 0,
        verticalAlign: 'bottom',
        allowOverlap: true,
      },
      innerRadius: '80%',
    },
  },
};

const GaugeChart = ({
  id,
  value,
  min,
  max,
  isShowValueLabels,
  thresholds,
  unitText,
  isDecimal,
  autoUnitType,
  inputNoData,
  isResize,
  isMetricEmpty,
}: PropTypes) => {
  const chartRpm = useRef();
  const updUnitText = unitText === OPTIONS_UNIT_TYPE_VALUES.AUTO && autoUnitType ? autoUnitType : unitText;
  const isValue = value || value === 0;
  const {
    input: {onChange: onChangeIsDecimal},
  } = useField('decimal', {type: 'checkbox'});
  const form = useFormState();
  const {modified} = form;

  const formatDataLabel = () => {
    const isDecimalValue = (value < NUMBER_DECIMAL_COMPARE && value % 1 > 0) || value > NUMBER_DECIMAL_M;
    if (isDecimalValue && !modified.decimal) {
      onChangeIsDecimal(true);
    }
    const renderUnitType = getUnitType({value: updUnitText});
    const isRight = (OPTIONS_UNIT_TYPE.find((item) => item.value === unitText) || {}).display === 'right';
    const abbreviateValue = Humanize.humanize(value, DECIMAL_COUNT);
    const computedDecimal =
      isDecimalValue && isDecimal
        ? Number(abbreviateValue.number).toFixed(DECIMAL_COUNT)
        : Math.trunc(abbreviateValue.number);
    return `
      <div style="display: flex;align-items: baseline;">
       ${!isRight ? `<div style="font-size:15px;">${renderUnitType}</div>` : ''}
       <div>
        ${isDecimal ? format(abbreviateValue.number, 3, '.', '.', DECIMAL_COUNT) : computedDecimal}${
      abbreviateValue.postfix
    }
      </div>
       ${isRight ? `<div style="font-size:15px;">${renderUnitType}</div>` : ''}
      </div>
    `;
  };

  useEffect(() => {
    Highcharts.setOptions({
      lang: {
        noData: inputNoData,
      },
    });
    const minYAxis = isValue ? getMinValue(min, thresholds) : 0;
    const maxYAxis = isValue ? getMaxValue(max, value, thresholds) : 100;
    chartRpm.current = Highcharts.chart(
      `container-${id}-gauge`,
      Highcharts.merge(gaugeOptions, {
        yAxis: {
          max: maxYAxis,
          labels: {
            enabled: isShowValueLabels,
          },
        },

        series: [
          {
            name: 'RPM',
            data: [1],
            dataLabels: {
              y: 5,
              padding: 0,
              style: {
                fontSize: '22px',
                lineHeight: '26px',
                fontWeight: 400,
                textAlign: 'center',
              },
              formatter: value || value === 0 ? formatDataLabel : () => null,
              useHTML: true,
            },
          },
        ],
      }),
    );
    // hack to get min YAxis value
    const isSetZeroYAxis = thresholds.some(
      (o) => Number(o.value) !== 0 && Number(o.value) <= chartRpm.current.yAxis[0].min,
    );
    chartRpm.current.yAxis[0].setExtremes(isSetZeroYAxis ? 0 : minYAxis, maxYAxis);
    chartRpm.current.update({
      yAxis: {
        plotBands: plotBandsUpdater(thresholds, isValue, chartRpm.current.yAxis[0].max),
        stops: stopColors(thresholds, chartRpm.current.yAxis[0].max),
        labels: {
          formatter: (e) => formatYAxisLabel(e.isFirst ? chartRpm.current.yAxis[0].min : chartRpm.current.yAxis[0].max),
        },
      },
    });

    return () => {
      chartRpm.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (chartRpm.current) {
      chartRpm.current.series[0].update({
        data: value !== null ? [value] : [],
        dataLabels: {
          formatter: value || value === 0 ? formatDataLabel : () => null,
        },
      });
    }
  }, [value, unitText, isDecimal, autoUnitType]);

  useEffect(() => {
    if (chartRpm.current) {
      const minYAxis = isValue ? getMinValue(min, thresholds) : 0;
      const maxYAxis = isValue ? getMaxValue(max, value, thresholds) : 100;
      const isSetZeroYAxis = thresholds.some(
        (o) => Number(o.value) !== 0 && Number(o.value) <= chartRpm.current.yAxis[0].min,
      );
      chartRpm.current.yAxis[0].setExtremes(isSetZeroYAxis ? 0 : minYAxis, maxYAxis);
      chartRpm.current.yAxis[0].update({
        labels: {
          enabled: isShowValueLabels,
          formatter: (e) => formatYAxisLabel(e.isFirst ? chartRpm.current.yAxis[0].min : chartRpm.current.yAxis[0].max),
        },
        plotBands: plotBandsUpdater(thresholds, isValue, chartRpm.current.yAxis[0].max),
        stops: stopColors(thresholds, chartRpm.current.yAxis[0].max),
      });
    }
  }, [min, max, thresholds, isShowValueLabels]);

  useEffect(() => {
    if (chartRpm.current) {
      if (value === null) {
        chartRpm.current.hideNoData();
        chartRpm.current.showNoData(inputNoData);
      } else {
        chartRpm.current.hideNoData();
      }
    }
  }, [inputNoData]);

  useEffect(() => {
    if (chartRpm.current) {
      chartRpm.current.reflow();
    }
  }, [chartRpm, isResize, isMetricEmpty]);

  return <div className="display_flex width_1 flexGrow_1" id={`container-${id}-gauge`} />;
};

export default React.memo(GaugeChart);
