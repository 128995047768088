// @flow
import React from 'react';
import {segmentClickEvent} from 'common/store/actions';
import {connect} from 'react-redux';
import './Input.module.scss';

const EMPTY_OBJECT = {};

type PropTypes = {
  placeHolder: string,
  isDisabled: boolean,
  isInvalid: boolean,
  type: string,
  additionalProps?: Object,
  className?: String,
  value: string,
  onChange: Function,
  onBlur: Function,
  onFocus: Function,
  onKeyDown: Function,
  isAutoFocus: boolean,
  invalidMessage?: string,
  wrapInvalidMessage?: boolean,
  automationId: string,
  fullSize: Boolean,
  style?: Object,
  segmentClickEvent: Function,
};

@connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)
export default class Input extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    additionalProps: EMPTY_OBJECT,
    className: '',
    invalidMessage: '',
    wrapInvalidMessage: false,
    style: EMPTY_OBJECT,
  };

  handleFocus = () => {
    this.props.segmentClickEvent({type: 'focus', automationId: this.props.automationId});
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  render() {
    const {
      placeHolder,
      isDisabled,
      isInvalid,
      additionalProps,
      isAutoFocus,
      className,
      invalidMessage,
      type,
      value,
      onChange,
      onBlur,
      onKeyDown,
      fullSize,
      automationId,
      wrapInvalidMessage,
      style,
    } = this.props;

    return (
      <React.Fragment>
        <input
          {...additionalProps}
          type={type}
          className={className}
          styleName={['andt-input', isInvalid ? 'invalid' : null, fullSize ? 'full-size' : null].join(' ')}
          style={style}
          placeholder={placeHolder}
          disabled={isDisabled}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={this.handleFocus}
          onKeyDown={onKeyDown}
          autoFocus={isAutoFocus}
          automation-id={automationId}
        />
        {isInvalid && invalidMessage && (
          <div styleName={['invalid-message', wrapInvalidMessage ? 'wrap' : null].join(' ')}>{invalidMessage}</div>
        )}
      </React.Fragment>
    );
  }
}
