/* eslint-disable react/no-unescaped-entities */
// @flow
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {isEditRunning, isStreamEditable} from 'bc/services/dataStreamService';
import {get, isEmpty} from 'lodash';
import {segmentClickEvent as segmentTrackingMouseEventAction} from 'common/store/actions';
import * as selectors from 'bc/store/selectors';
import {
  setSelectedStreamKeyVal,
  setSelectedStreamUiKeyVal,
  setBigQueryStreamQuery as setBigQueryStreamQueryAction,
  fetchBigQueryPreview as fetchBigQueryPreviewAction,
  fetchBigQueryVerifyQuery,
} from 'bc/store/actions';

import QueryEditorModal from '../sql/queryEditorModal/QueryEditorModal';
import './ComposeQuery.module.scss';

const DEFAULT_QUERY = `select event_time, col1, col2
from my-dataset.my-table_{{from_time(yyyyMMdd)}}
where event_time>={{from_time}} and event_time<{{to_time}}
order by event_time

-- Query text has to start with SELECT
-- sample SQL for Bigquery`;

const GUIDELINES = [
  <Fragment>
    Include a timestamp column, a measure and a dimension. Include an "order by" clause based on the timestamp column.
  </Fragment>,
  <Fragment>
    Parameters:
    <br /> - &#123;&#123;from_time(yyyy-MM-dd)&#125;&#125; - use the date pattern in the table name for segmented tables
    <br /> - &#123;&#123;from_time&#125;&#125; - default: epoch microseconds
    <br /> - &#123;&#123;to_time&#125;&#125; - default: epoch microseconds
  </Fragment>,
  <Fragment>Consider data readiness delay</Fragment>,
  <Fragment>Consider data records timezone</Fragment>,
  <Fragment>Consider historical records to collect</Fragment>,
];

type PropTypes = {
  dataStream: Object,
  isLoadingDataSets: boolean,
  dataSetsError: Object,
  previewData: Object,
  previewDataError: Object,
  queryPreviewColumns: Object,
  isPreviewDataLoading: boolean,
  isVerifyQueryLoading: boolean,
  verifyQueryData: Object,

  // connect
  fetchBigQueryPreview: Function,
  fetchBigQueryVerifyQuery: Function,
  setSelectedStreamKeyVal: Function,
  setSelectedStreamUiKeyVal: Function,
  setBigQueryStreamQuery: Function,
  segmentClickEvent: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isLoadingDataSets: selectors.getBigQueryStreamDataSetsIsLoading(state),
    dataSetsError: selectors.getBigQueryStreamDataSetsError(state),
    previewData: selectors.getBigQueryPreviewData(state),
    queryPreviewColumns: selectors.getBigQueryStreamQueryPreviewItems(state).columns,
    previewDataError: selectors.getBigQueryStreamQueryPreviewError(state),
    isPreviewDataLoading: selectors.getBigQueryStreamQueryPreviewIsLoading(state),
    isVerifyQueryLoading: selectors.getBigQueryStreamVerifyQueryIsLoading(state),
    verifyQueryData: selectors.getBigQueryStreamVerifyQueryData(state),
  }),
  {
    fetchBigQueryPreview: fetchBigQueryPreviewAction,
    fetchBigQueryVerifyQuery,
    setSelectedStreamKeyVal,
    setSelectedStreamUiKeyVal,
    setBigQueryStreamQuery: setBigQueryStreamQueryAction,
    segmentClickEvent: segmentTrackingMouseEventAction,
  },
)
export default class ComposeQuery extends React.PureComponent {
  props: PropTypes;

  state = {
    isOpenQueryEditorModal: false,
  };

  componentDidMount() {
    const {dataStream, queryPreviewColumns} = this.props;
    if (isEditRunning(dataStream.id) && isEmpty(queryPreviewColumns)) {
      const dataArr = [];
      dataStream.dimensions.forEach((item) => dataArr.push({name: item, type: 'dimention'}));
      dataStream.metrics.forEach((item) => dataArr.push({name: item, type: 'metric'}));
      dataArr.push({name: dataStream.timestampColumn, type: 'timestamp'});
      this.props.setSelectedStreamUiKeyVal({
        ...dataStream.uiState,
        queryPreviewColumns: dataArr,
      });
    }
  }

  closeQueryEditorModal = () => {
    this.setState({isOpenQueryEditorModal: false});
  };

  openQueryEditorModal = () => {
    const {dataStream, segmentClickEvent} = this.props;
    this.setState({isOpenQueryEditorModal: true});
    segmentClickEvent({category: `Data collectors - ${dataStream.type}`, name: 'Query editor'});
  };

  handelContinueClick = (payload) => {
    this.props.fetchBigQueryVerifyQuery({...payload, type: this.props.dataStream.type});
  };

  toggleLegacySQL = (useLegacySQL) => {
    this.props.setSelectedStreamKeyVal({useLegacySQL});
  };

  render() {
    const {
      dataStream,
      isLoadingDataSets,
      dataSetsError,
      previewData,
      previewDataError,
      isPreviewDataLoading,
      isVerifyQueryLoading,
      queryPreviewColumns,
      verifyQueryData,
      fetchBigQueryPreview,
      setBigQueryStreamQuery,
    } = this.props;

    const query = dataStream.query ? dataStream.query : DEFAULT_QUERY;

    const isDisabled =
      (!isStreamEditable(dataStream.state) && !isEditRunning(dataStream.id)) ||
      !get(dataStream, 'projectId', false) ||
      isLoadingDataSets ||
      dataSetsError.uMessage;

    return (
      <div className="shell-col" styleName="root">
        <button
          type="button"
          className="btn btn-raised"
          styleName="btn-compose"
          automation-id="bigQueryComposeQueryButton"
          disabled={isDisabled}
          onClick={this.openQueryEditorModal}
        >
          Compose Query
        </button>

        {this.state.isOpenQueryEditorModal && (
          <QueryEditorModal
            isOpen={this.state.isOpenQueryEditorModal}
            onClose={this.closeQueryEditorModal}
            dataStream={dataStream}
            query={query}
            runQueryPreview={fetchBigQueryPreview}
            setStreamQuery={setBigQueryStreamQuery}
            previewData={previewData}
            previewDataError={previewDataError}
            isPreviewDataLoading={isPreviewDataLoading}
            queryPreviewColumns={queryPreviewColumns}
            fetchVerifyQueryFunction={this.handelContinueClick}
            useLegacySQL={dataStream.useLegacySQL}
            toggleLegacySQL={this.toggleLegacySQL}
            isVerifyQueryLoading={isVerifyQueryLoading}
            verifyQueryData={verifyQueryData}
            guidelines={GUIDELINES}
          />
        )}
      </div>
    );
  }
}
