// @flow
import React, {Fragment, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PageLayout from 'common/componentsV2/PageLayout';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import SideBarTabs from 'common/componentsV2/sideBarTabs/SideBarTabs';
import Box from '@material-ui/core/Box';
import {fetchUsers} from 'admin.users/store/actions';
import {getUsersData} from 'admin.users/store/selectors';

import DetailsAndSecuritySection from 'userSettings/components/detailsAndSecuritySection/DetailsAndSecuritySection';
import AuthenticationSection from 'userSettings/components/authenticationSection/AuthenticationSection';

import * as selectors from 'userSettings/store/selectors';
import ApiTokenManagement from '../components/apiTokenManagement/ApiTokenManagement';

const header = (
  <header>
    <Title type={TITLE_TYPES.PAGE_TITLE}>Settings</Title>
  </header>
);

const UserSettings = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);
  const getSidebarTabs = useSelector((state) => selectors.getSidebarTabs(state));
  const users = useSelector((state) => getUsersData(state));

  useEffect(() => {
    if (!users.length > 0) {
      dispatch(fetchUsers());
    }
  }, []);

  const actionTab = (tabId) => {
    setActiveTab(() => tabId);
  };

  const renderBody = () => {
    switch (activeTab) {
      case getSidebarTabs[0].id:
        return <DetailsAndSecuritySection />;
      case getSidebarTabs[1].id:
        return <ApiTokenManagement />;
      case getSidebarTabs[2].id:
        return <AuthenticationSection />;
      default:
        return '';
    }
  };

  return (
    <Fragment>
      <PageLayout header={header} shouldOverflowY>
        <Box display="flex">
          <Box width={305} pr={8}>
            <SideBarTabs tabs={getSidebarTabs} selectedTabId={activeTab} onTabClick={actionTab} />
          </Box>
          <Box>{renderBody}</Box>
        </Box>
      </PageLayout>
    </Fragment>
  );
};

export default UserSettings;
