import React, {useEffect, useState} from 'react';
import {Box} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {getCacheData} from 'metrics/store/selectors';
import * as metricsActions from 'metrics/store/actions';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import SmallDropdownButtonMulti from 'common/componentsV2/ddl/multiSelectFormDdl/SmallDropdownButtonMulti';
import OptionComponentCheckbox from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentCheckbox';
import {getCommonDimensions} from './utils';

const makeDimensionsPayload = (measure) => ({
  expression: '',
  listDescription: [
    {
      valueType: 'dimensions',
      count: 20,
    },
  ],
  filter: [
    {
      isExact: true,
      key: 'what',
      type: 'property',
      value: measure,
    },
  ],
  search: '',
});

type PropTypes = {
  value: Array,
  onChange: Function,
  firstMeasure: Object,
  secondMeasure: Object,
  onRemove: Function,
};

const GroupByDimensions = ({value, onChange, firstMeasure, secondMeasure, onRemove}: PropTypes) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (firstMeasure.value) {
      dispatch(
        metricsActions.fetchPropAndValList(
          makeDimensionsPayload(firstMeasure.value),
          `dimensions-${firstMeasure.value}`,
        ),
      );
    }
  }, [firstMeasure.value]);

  useEffect(() => {
    if (secondMeasure.value) {
      dispatch(
        metricsActions.fetchPropAndValList(
          makeDimensionsPayload(secondMeasure.value),
          `dimensions-${secondMeasure.value}`,
        ),
      );
    }
  }, [secondMeasure.value]);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleChange = (val) => {
    if (val.length <= 3) {
      onChange(val);
    }
  };

  const fetchedCache = useSelector(getCacheData);
  const avalableDimensions = getCommonDimensions(firstMeasure, secondMeasure, fetchedCache);
  return (
    <div>
      <Box fontSize={16} mb={1}>
        Group By Dimensions
      </Box>
      <Box display="flex" alignItems="center">
        <Box width={200} mr={1}>
          <FormDdlSelect
            options={avalableDimensions}
            automationId="simpleAlertDimensions"
            selected={value}
            button={<SmallDropdownButtonMulti value={value} modifier="groupByDimension" />}
            onChange={handleChange}
            width={311}
            isMulti
            optionComponent={<OptionComponentCheckbox />}
            toggleOpen={isOpen}
            specialCharacterLastSorting="#"
          />
        </Box>
        <div
          onClick={() => {
            onChange([]);
            onRemove();
          }}
          className="color_gray-400"
          role="button"
        >
          <i className="icon icon icn-general16-closea" />
        </div>
      </Box>
    </div>
  );
};

export default GroupByDimensions;
