import {createSelector} from 'reselect';
import {getSelectedDataStream} from 'bc/store/selectors';

const getRoot = (state) => state.impact;

const getImpactData = createSelector(
  getRoot,
  (impact) => impact.data,
);

const getImpactView = createSelector(
  getRoot,
  (impact) => impact.view,
);

//  Data
export const getSingleImpact = createSelector(
  getImpactData,
  (data) => data.fetchSingleImpact,
);

export const getSingleImpactData = createSelector(
  getSingleImpact,
  (impact) => impact.data,
);

export const getIsImpactLoading = createSelector(
  getSingleImpact,
  (impact) => impact.isLoading,
);

export const getImpactsList = createSelector(
  getImpactData,
  (data) => data.fetchImpactsList,
);

export const getImpactsListData = createSelector(
  getImpactsList,
  (impacts) => impacts.data,
);

export const getIsImpactsListLoading = createSelector(
  getImpactsList,
  (impacts) => impacts.isLoading,
);

export const getUpdateImpactIsLoading = createSelector(
  getImpactData,
  (data) => data.updateImpact.isLoading,
);

export const getCreateImpactIsLoading = createSelector(
  getImpactData,
  (data) => data.createImpact.isLoading,
);

export const getRemoveImpactIsLoading = createSelector(
  getImpactData,
  (data) => data.removeImpact.isLoading,
);

export const getAlertsTriggered = createSelector(
  getImpactData,
  (data) => data.alertsTriggeredData,
);

export const getAlertsTriggeredData = createSelector(
  getAlertsTriggered,
  (alerts) => alerts.data,
);

export const getIsAlertsTriggeredLoading = createSelector(
  getAlertsTriggered,
  (alert) => alert.isLoading,
);

export const getTriggeredAlertsByMeasures = createSelector(
  getImpactData,
  (data) => data.fetchTriggeredAlertsByMeasure,
);

export const getIsLoadingTriggeredAlertsByMeasures = createSelector(
  getTriggeredAlertsByMeasures,
  (data) => data.isLoading,
);

// View
export const getModalFromEmail = createSelector(
  getImpactView,
  (view) => view.modalFromEmail,
);

export const getIsModalOpen = createSelector(
  getModalFromEmail,
  (modalFromEmail) => modalFromEmail.isOpen,
);

export const getImpacts = createSelector(
  getImpactView,
  (view) => view.impacts,
);

export const getAlertTriggeredView = createSelector(
  getImpactView,
  (view) => view.alertsTriggered,
);

export const getMergedMeasures = createSelector(
  getSelectedDataStream,
  getImpactsListData,
  (dataStream, measuresImpact) => {
    const bcMeasures = dataStream.schema.columns.filter((col) => col.type === 'metric').map((i) => ({measure: i.name}));

    if (measuresImpact.length) {
      return bcMeasures.map((bcMeasure) => {
        const found = measuresImpact.find((impact) => impact.measure.toLowerCase() === bcMeasure.measure.toLowerCase());
        if (found) {
          return found;
        }
        return bcMeasure;
      });
    }
    return bcMeasures;
  },
);
