// @flow
import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {get} from 'lodash';
import {serverAnomalyDirection} from 'alerts.management/services/alertsService';
import * as selectors from 'alerts.management/store/selectors';
import * as actions from 'alerts.management/store/actions';
import {ExpandableSwitch} from 'common/componentsV2/ExpandableSections';
import TooltipArea from 'common/componentsV2/TooltipArea';
import StaticThresholdCondition from './StaticThresholdCondition';

const MetricsValuesInAnomaly = () => {
  const dispatch = useDispatch();
  const setSelectedAlertStaticThreshold = useCallback(
    (...args) => dispatch(actions.setSelectedAlertStaticThreshold(...args)),
    [dispatch],
  );
  const addConditionToSelectedAlert = useCallback((...args) => dispatch(actions.addConditionToSelectedAlert(...args)), [
    dispatch,
  ]);
  const removeConditionFromSelectedAlert = useCallback(
    (...args) => dispatch(actions.removeConditionFromSelectedAlert(...args)),
    [dispatch],
  );

  const type = useSelector(selectors.getSimulationFiltersType);

  const thresholdCondition = useSelector(selectors.getThresholdCondition);
  const directionCondition = useSelector(selectors.getDirectionCondition);
  const staticThresholdCondition = useSelector(selectors.getStaticThresholdCondition) || {};
  const availableStaticThresholdCondition = useSelector(selectors.getAvailableStaticThresholdCondition);
  const [manualVisibility, setManualVisibility] = useState(false);

  const direction = get(directionCondition, 'direction', serverAnomalyDirection.both);

  const isMinValueActive = type.value === 'static' || ['down', 'both'].includes(direction);
  const isMaxValueActive = type.value === 'static' || ['up', 'both'].includes(direction);

  const isOneThresholdExists =
    (staticThresholdCondition.minValue !== null &&
      staticThresholdCondition.minValue !== undefined &&
      isMinValueActive) ||
    (staticThresholdCondition.maxValue !== null &&
      staticThresholdCondition.maxValue !== undefined &&
      isMaxValueActive) ||
    manualVisibility;

  const handleSwitch = (isHidden) => {
    if (isHidden) {
      if (availableStaticThresholdCondition) {
        addConditionToSelectedAlert(availableStaticThresholdCondition.dataObj, {});
      }
      setManualVisibility(true);
    } else {
      if (staticThresholdCondition) {
        removeConditionFromSelectedAlert(staticThresholdCondition.id);
      }
      setManualVisibility(false);
    }
  };

  return (
    <TooltipArea text="Use this to set a threshold for metrics to surpass in order to trigger an alert">
      {(info) => (
        <ExpandableSwitch
          label={
            <React.Fragment>
              Metrics values in an anomaly
              {info}
            </React.Fragment>
          }
          expanded={isOneThresholdExists || false}
          onChange={handleSwitch}
          automationId="metricsValuesInAnomaly"
        >
          {isOneThresholdExists && (
            <StaticThresholdCondition
              key={staticThresholdCondition.id}
              staticThreshold={thresholdCondition}
              direction={direction}
              alertType={type.value}
              onChange={setSelectedAlertStaticThreshold}
            />
          )}
        </ExpandableSwitch>
      )}
    </TooltipArea>
  );
};

export default React.memo(MetricsValuesInAnomaly);
