// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import Checkbox from 'common/componentsV2/Checkbox';
import * as filterSelector from 'alerts.management/store/filterSelector';

type PropTypes = {
  setQueryParams: Function,
  isNotifications: String,
};

@connect(
  (state) => ({
    isNotifications: filterSelector.getAlertFiltersNotifications(state),
  }),
  {},
)
export default class NotificationsFilter extends React.PureComponent {
  props: PropTypes;

  onChange = (val) => {
    this.props.setQueryParams({notifications: val});
  };

  render() {
    const {isNotifications} = this.props;
    return <Checkbox isChecked={isNotifications === true} text="Alerts with Notifications" onChange={this.onChange} />;
  }
}
