// @flow
import React, {Fragment, useMemo} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/core/styles';
import moment from 'moment';
import {escape, isEmpty, isNil, omitBy} from 'lodash';
import {computeDecimal} from 'common/utils/numbers';
import formatNumber from 'common/utils/formatNumber';
import {getCorrectTimezoneName} from 'common/utils/dateService';
import {getMetricName, resolutionTypes} from 'metrics/services/metricsService';
import theme, {palette} from 'app/styles/theme';

const AnomalyTemplate = (props: {
  anomalyPoint: Object,
  type: string,
  tooltipConditions: Object,
  hideMeetIcons: boolean,
}) => {
  if (!props.anomalyPoint) {
    return null;
  }
  const anoOptions = props.anomalyPoint.anomalyOptions;
  const hasSignificance = !isNil(anoOptions.significance) && anoOptions.significance >= 0;
  const hasDelta = !isEmpty(anoOptions.delta);

  const {significance, delta, duration} = props.tooltipConditions || {};

  const wrongSignificance = significance && anoOptions.significance < significance.minSignificance;

  const wrongDelta =
    delta && (anoOptions.deltaAbsolute < delta.deltaAbsolute || anoOptions.deltaPercentage < delta.deltaPercentage);
  const wrongDuration = duration && anoOptions.durationAsSeconds < duration.value;

  return (
    <ThemeProvider theme={theme}>
      <Box color="gray.400" fontWeight={500} fontSize={14}>
        {props.type === 'anomalySecondary' && (
          <Box display="flex" justifyContent="center" mb={1} textAlign="center">
            <Box width={144} fontSize={12}>
              This Anomaly did not meet all the conditions
            </Box>
          </Box>
        )}
        {hasSignificance && (
          <Box color={wrongSignificance ? 'red.500' : 'gray.400'} display="flex" alignItems="center">
            {!props.hideMeetIcons && (
              <React.Fragment>
                {wrongSignificance ? (
                  <i className="icon icn-general16-closea" />
                ) : (
                  <i className="icon icn-general16-checkmark" />
                )}
              </React.Fragment>
            )}
            <Box mx={0.5}>Score:</Box>
            <Box display="inline" color="gray.500">
              {Math.round(anoOptions.significance * 100)}
            </Box>
          </Box>
        )}
        {hasDelta && (
          <Box color={wrongDelta ? 'red.500' : 'gray.400'} display="flex" alignItems="center">
            {!props.hideMeetIcons && (
              <React.Fragment>
                {wrongDelta ? (
                  <i className="icon icn-general16-closea" />
                ) : (
                  <i className="icon icn-general16-checkmark" />
                )}
              </React.Fragment>
            )}
            <Box mx={0.5}>Delta:</Box>
            <Box display="inline" color="gray.500">
              {anoOptions.delta}{' '}
            </Box>
          </Box>
        )}
        <Box color={wrongDuration ? 'red.500' : 'gray.400'} display="flex" alignItems="center">
          {!props.hideMeetIcons && (
            <React.Fragment>
              {wrongDuration ? (
                <i className="icon icn-general16-closea" />
              ) : (
                <i className="icon icn-general16-checkmark" />
              )}
            </React.Fragment>
          )}
          <Box mx={0.5}>Duration:</Box>
          <Box display="inline" color="gray.500">
            {anoOptions.duration}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const MetricNameTemplate = (props: {metric: Object}) => {
  const {metric} = props;
  if (metric.properties) {
    return (
      <Box className="metric-name-tooltip" css={{boxShadow: 'none'}}>
        {metric.what && (
          <Fragment>
            Measure <span className="what-segment">{escape(metric.what)}</span>
            <div className="name-sep" />
          </Fragment>
        )}
        {metric.properties.concat(metric.tags).map((p) => (
          <div className="dimensions-list">
            {p.key}
            <span className="dimension-segment">{escape(p.value)}</span>
          </div>
        ))}
      </Box>
    );
  }

  return (
    <Box className="metric-name-tooltip" css={{boxShadow: 'none'}}>
      {escape(getMetricName(metric))}
    </Box>
  );
};

const PointValueTemplate = (props: {minPoint: Object}) => {
  const {minPoint} = props;
  // const {metric} = minPoint.series.options.andtVal;
  // const unit = getMetricUnits(metric);
  const percentVal = minPoint.percentage ? `${minPoint.percentage.toFixed(2)}% | ` : '';
  // const unitTemplate = unit ? <span className="point-unit">{unit || ''}</span> : null;

  return (
    <Box display="flex" justifyContent="flex-start" my={1}>
      <Box
        bgcolor={minPoint.series.color}
        borderRadius={10}
        lineHeight={1}
        py={0.25}
        fontSize={16}
        fontWeight="bold"
        px={1}
        color="#fff"
      >
        {percentVal + formatNumber(parseFloat(minPoint.y.toFixed(computeDecimal(minPoint.y))), 3, ',', '.')}
        {/* old abbreviation abbrNum(minPoint.y, computeDecimal(minPoint.y)) */}
      </Box>
    </Box>
  );
};

const DateTemplate = (props: {date: Object, timeScale: String, timeZoneName: String}) => {
  const roolup = Object.values(resolutionTypes).find((a) => a.value2 === props.timeScale).value;
  const momentDate = moment(props.date);
  const timeZone = getCorrectTimezoneName(props.timeZoneName);

  if (roolup === 'weekly') {
    const weekFromDate = props.date + 60 * 60 * 24 * 7 * 1000;
    const sameMonth = momentDate.isSame(moment(weekFromDate), 'month');

    if (sameMonth) {
      return (
        <span>
          {' '}
          {momentDate.tz(timeZone).format('D')} ‒
          {moment(weekFromDate)
            .tz(timeZone)
            .format('D MMM')}{' '}
          ({timeZone})
        </span>
      );
    }
    return (
      <span>
        {momentDate.tz(timeZone).format('D MMM')} ‒
        {moment(weekFromDate)
          .tz(timeZone)
          .format('D MMM')}{' '}
        ({timeZone})
      </span>
    );
  }

  if (roolup === 'longlong') {
    return (
      <span>
        {momentDate.tz(timeZone).format('ddd, MMM D')} ({props.timeZoneName})
      </span>
    );
  }
  return <span>{momentDate.tz(timeZone).format('ddd, MMM D [@] h:mm A')}</span>;
};

const useStyles = makeStyles(() => ({
  tooltipWrapper: {
    maxWidth: 493,
    borderRadius: 8,
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    padding: 8,
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
    fontSize: 12,
    color: palette.gray[500],
    fontWeight: 400,
  },
  measureWrapper: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: 400,
    color: '#8996a0',
    marginBottom: '4px',
  },
  propsWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: 4,
    fontSize: 14,
    fontWeight: 500,
  },
  propsListKey: {
    width: 80,
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    flexShrink: 0,
    marginRight: 8,
    fontSize: 12,
    color: palette.gray[400],
    lineHeight: '16px',
    overflow: 'hidden',
  },
  propsListValue: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 56,
    maxWidth: 348,
    lineHeight: '16px',
    color: palette.mint[600],
    wordBreak: 'break-all',
  },
  whatText: {
    color: '#6826ab',
    fontWeight: 500,
  },
  propertyWrapper: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 400,
    color: '#8996a0',
  },
  propertyText: {
    color: '#009263',
    fontWeight: 500,
  },
}));

const lastPropsKeys = ['target_type', 'mtype', 'func'];

const ExtendedData = ({metric}: {metric: Object}) => {
  const classes = useStyles();

  const metricProps = useMemo(() => metric.properties.concat(metric.tags.map((t) => ({...t, value: `#${t.value}`}))), [
    metric,
  ]);

  const lastProps = useMemo(
    () =>
      lastPropsKeys
        .map((key) => {
          const prop = metricProps.find((item) => item.key === key);
          return (
            prop && {
              ...prop,
              isLast: true,
            }
          );
        })
        .filter((item) => item),
    [metricProps],
  );

  const propsList = useMemo(
    () => [...Object.values(omitBy(metricProps, (item) => lastPropsKeys.includes(item.key))), ...lastProps],
    [metricProps, lastProps],
  );

  return (
    <React.Fragment>
      {metric.what && (
        <div className={classes.measureWrapper}>
          <div className={classes.whatText}>{metric.what}</div>
        </div>
      )}

      {propsList.map((kv, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={classes.propsWrapper} key={`${kv.key}_${index}`}>
          <div className={classes.propsListKey}>{kv.key}</div>
          <div className={classes.propsListValue}>{kv.value}</div>
        </div>
      ))}
    </React.Fragment>
  );
};

const TooltipTemplate = (props: {
  minPoint: Object,
  date: Object,
  timeScale: String,
  timeZoneName: String,
  bucketStartTimeEnabled: Boolean,
  showMetricName: boolean,
  anomalyPoint: Object,
  showAnomalyData: boolean,
  tooltipConditions: Object,
  isExtendedTooltip: boolean,
  hideMeetIcons: boolean,
}) => {
  if (!props.minPoint.series) {
    return null;
  }
  const classes = useStyles();
  // const {color} = props.minPoint.series;
  const {metric, type} = props.minPoint.series.options.andtVal;
  const {tooltipConditions, isExtendedTooltip, hideMeetIcons} = props;

  return (
    <div className={classes.tooltipWrapper}>
      <DateTemplate
        date={props.date}
        timeScale={props.timeScale}
        timeZoneName={props.timeZoneName}
        bucketStartTimeEnabled={props.bucketStartTimeEnabled}
      />
      <span>
        <PointValueTemplate minPoint={props.minPoint} />
        {props.showMetricName && <MetricNameTemplate metric={metric} />}
        {props.anomalyPoint && props.showAnomalyData && (
          <AnomalyTemplate
            anomalyPoint={props.anomalyPoint}
            type={type}
            tooltipConditions={tooltipConditions}
            hideMeetIcons={hideMeetIcons}
          />
        )}
      </span>
      {isExtendedTooltip && (
        <Box mt={1}>
          <ExtendedData metric={metric} />
        </Box>
      )}
    </div>
  );
};

export default TooltipTemplate;
