// @flow
import React, {Fragment} from 'react';
import DataManagerListHeader from 'bc/components/dataManager/DataManagerListHeader';
import DataManagerListBody from 'bc/components/dataManager/DataManagerListBody';

type PropTypes = {
  setQueryParams: Function,
  listState: String,
  onListStateChange: Function,
};

export default class DataManagerList extends React.PureComponent {
  props: PropTypes;

  render() {
    const {setQueryParams, listState, onListStateChange} = this.props;

    return (
      <Fragment>
        <DataManagerListHeader setQueryParams={setQueryParams} />
        <DataManagerListBody
          listState={listState}
          onListStateChange={onListStateChange}
          setQueryParams={setQueryParams}
        />
      </Fragment>
    );
  }
}
