// @flow
import {PureComponent} from 'react';

function isUndefined(value) {
  return value === undefined;
}

function isNil(value) {
  // eslint-disable-next-line
  return value == null;
}

function isObject(value) {
  const type = typeof value;
  // eslint-disable-next-line
  return value != null && (type == 'object' || type == 'function');
}

function startsWith(value, searchString, position) {
  const pos = position || 0;
  return value.substr(pos, searchString.length) === searchString;
}

function endsWith(value, searchString, position) {
  const subjectString = value.toString();
  let pos = position;
  if (
    typeof position !== 'number' ||
    !isFinite(position) ||
    Math.floor(position) !== position ||
    position > subjectString.length
  ) {
    pos = subjectString.length;
  }
  pos -= searchString.length;
  const lastIndex = subjectString.lastIndexOf(searchString, pos);
  return lastIndex !== -1 && lastIndex === pos;
}

/**
 * React Query Params Component base class
 * Support: https://github.com/jeff3dx/react-query-params
 */
export default class ReactQueryParams extends PureComponent {
  props: {
    history: any,
  };

  constructor(props) {
    super();
    this.history = props.history;
  }

  /* Clear the query param cache */
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      // changing state has nothing to do with queryParams props
      this.queryParamsCache = null;
    }

    if (super.componentDidUpdate) {
      super.componentDidUpdate();
    }
  }

  /**
   * Convert boolean string to boolean type.
   * Any query param set to "true" or "false" will be converted to a boolean type.
   * @param {string} value - the query param string value
   */
  boolify = (value) => {
    if (typeof value === 'string') {
      const value2 = value.toLowerCase().trim();
      if (value2 === 'true') {
        return true;
      }
      if (value2 === 'false') {
        return false;
      }
    }
    return value;
  };

  /**
   * If query param string is object-like try to parse it
   */
  queryParamToObject = (value) => {
    let result = value;
    if (
      typeof value === 'string' &&
      ((startsWith(value, '[') && endsWith(value, ']')) || (startsWith(value, '{') && endsWith(value, '}')))
    ) {
      try {
        result = JSON.parse(value);
        if (Array.isArray(result) && result.length === 1 && typeof result[0] === 'number') {
          result = value;
        }
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex);
        // Can't parse so fall back to verbatim value
        result = value;
      }
    }
    return result;
  };

  queryParamsCache;

  resolveSearchParams() {
    const searchParams = {};

    if (this.history.location.search) {
      const urlSearchPairs = this.history.location.search.slice(1).split('&');
      urlSearchPairs.forEach((pair) => {
        const splitPair = pair.split('=');
        searchParams[splitPair[0]] = decodeURIComponent(splitPair[1] || '');
      });
    }
    return searchParams;
  }

  /**
   * Returns a map of all query params including default values. Params that match
   * the default value do not show up in the URL but are still available here.
   */
  get queryParams() {
    if (isNil(this.queryParamsCache)) {
      const searchParams = this.resolveSearchParams();

      const defaults = this.defaultQueryParams || {};
      const all = {...defaults, ...searchParams};
      Object.keys(all).forEach((key) => {
        all[key] = this.boolify(all[key]);
        all[key] = this.queryParamToObject(all[key]);
      });
      this.queryParamsCache = all;
    }
    return this.queryParamsCache;
  }

  /**
   * Get one query param value.
   * @param {string} key - The query param key
   */
  getQueryParam(key) {
    const defaults = this.defaultQueryParams || {};
    const searchParams = this.resolveSearchParams();
    let result = isUndefined(searchParams[key]) ? searchParams[key] : defaults[key];
    result = this.boolify(result);
    result = this.queryParamToObject(result);
    return result;
  }

  /**
   * Set query param values. Merges changes similar to setState().
   * @param {object} params - Object of key:values to overlay on current query param values.
   * @param {boolean} addHistory - true = add browser history, default false.
   */
  setQueryParams(params, addHistory = false) {
    const searchParams = this.resolveSearchParams();

    const nextQueryParams = {...searchParams, ...params};
    const defaults = this.defaultQueryParams || {};

    Object.keys(nextQueryParams).forEach((key) => {
      // If it's an object value (object, array, etc.) convert it to a string
      if (isObject(nextQueryParams[key])) {
        try {
          nextQueryParams[key] = JSON.stringify(nextQueryParams[key]);
        } catch (ex) {
          // eslint-disable-next-line no-console
          console.log(`react-query-params -- Failed to serialize queryParam ${key}`, ex);
          nextQueryParams[key] = '';
        }
      }
      // Remove params that match the default
      if (nextQueryParams[key] === defaults[key]) {
        delete nextQueryParams[key];
      }
    });

    let search = `?${Object.keys(nextQueryParams)
      .map((key) => `${key}=${encodeURIComponent(nextQueryParams[key])}`)
      .join('&')}`;
    search = search === '?' ? '' : search;

    if (addHistory && search !== this.history.location.search) {
      // skip search='?'
      this.history.push(this.history.location.pathname + search);
    } else {
      this.history.replace(this.history.location.pathname + search);
    }

    // Clear the cache
    this.queryParamsCache = null;

    this.forceUpdate();
  }
}
