import {createSelector} from 'reselect';

const EMPTY_ARRAY = [];

//* ** root channelsSelectors
export const getBusinessCollectors = (state) => state.bc;
export const getCubesRoot = (state) => state.cubesRoot;
export const getUserEvents = (state) => state.userEvents;
export const getAlertsManagement = (state) => state.alertManagement;
export const getAlertsConsole = (state) => state.alertsConsole;
export const getMetrics = (state) => state.metrics;
export const getCharts = (state) => state.charts;
export const getChannels = (state) => state.channels;
export const getUsers = (state) => state.users;
export const getCommon = (state) => state.common;
export const getRouting = (state) => state.router;
export const getEmailDigest = (state) => state.emailDigest;
export const getTopologyMap = (state) => state.topologyMap;
export const getDashboards = (state) => state.dashboards;
export const getNotifications = (state) => state.notifications;
export const getInvestigation = (state) => state.investigation;
export const getGeneralTopologyMap = (state) => state.generalTpMap;
export const getAuditLog = (state) => state.activityLog;

export const getOutstandingLoadingBarRequestsCount = createSelector(
  getCommon,
  ({outstandingLoadingBarRequests}) => Object.values(outstandingLoadingBarRequests).filter((item) => !!item).length,
);

export const getConfigurationsData = createSelector(
  getCommon,
  ({configurationsData}) => {
    const ret = {configurationsData};
    return (ret.configurationsData || {}).configurationFile || EMPTY_ARRAY;
  },
);

export const getRoutingLocation = createSelector(
  getRouting,
  (routing) => routing.location || {},
);

export const getQuery = createSelector(
  getRoutingLocation,
  (location) => location.query || {},
);

export const getNotificationsUids = createSelector(
  getNotifications,
  (notifications) => {
    const uids = notifications.map((ele) => ele.uid);
    return uids || EMPTY_ARRAY;
  },
);
