// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/StreamEditorPanelHeader';
import SchedularDateRange from 'bc/components/streams/editor/common/SchedularDateRange';
import '../../sql/sqlSchedular/SqlSchedularEditor.module.scss';

type PropTypes = {
  // connect
  dataStream: Object,
  setSelectedStreamKeyVal: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    setSelectedStreamKeyVal,
  },
)
export default class AdobeSchedulerEditor extends React.PureComponent {
  props: PropTypes;

  dateRangeChanged = (val) => {
    this.props.setSelectedStreamKeyVal({
      historicalDateRange: val,
    });
  };

  render() {
    const {dataStream} = this.props;

    return (
      <div styleName="root">
        <PanelHeader title="Query Schedule" />
        <div styleName="inner-root">
          <div styleName="item" automation-id="gadsSchedulerEditorItem">
            <span className="ellipsis" styleName="item-title">
              Collect Since
            </span>
            <span styleName="item-description">Time span of data to query while initializing a stream.</span>
            <div styleName="item-width">
              <SchedularDateRange
                dateRange={dataStream.historicalDateRange}
                title="Time span"
                isUnix
                allowedRangeTypes={['d1', 'd3', 'w1', 'm1', 'm3']}
                onChange={this.dateRangeChanged}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
