import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {setFilesListOrder} from 'assets/store/actions';
import {getScrollbarWidth, getFilesListOrder} from 'assets/store/selectors';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {TypographyBox} from 'common/componentsV2/boxTools';
import TitleHeaderTableOrder from 'common/componentsV2/table/TitleHeaderTableOrder';
import {commonStyle} from 'assets/services/service';

const fields = [
  {id: 0, name: 'title', title: 'Title'},
  {id: 1, name: 'type', title: 'Type'},
  {id: 2, name: 'owner', title: 'owner'},
  {id: 3, name: 'added', title: 'Added'},
  {id: 4, name: 'lastEdited', title: 'Last Edited'},
];

const useStyles = makeStyles(({palette}) => ({
  main: {
    padding: commonStyle.tableRow.padding,
    borderBottom: `solid 1px ${palette.gray['300']}`,
  },
  item: {
    padding: commonStyle.tableItem.padding,
  },
}));

const TableHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const scrollbarWidth = useSelector(getScrollbarWidth);
  const order = useSelector(getFilesListOrder);

  const handleTitleOrderChange = (columnName) => {
    let updatedDirection = 'desc';

    if (columnName === order.column) {
      updatedDirection = order.direction === 'desc' ? 'asc' : 'desc';
    }
    dispatch(setFilesListOrder({column: columnName, direction: updatedDirection}));
  };

  return (
    <div>
      <Grid
        container
        className={classes.main}
        style={{paddingBottom: 4, paddingRight: commonStyle.tableRow.paddingRight + (scrollbarWidth || 0)}}
      >
        {fields.map((field) => (
          <Grid item className={classes.item} sm={field.name === 'title' ? 4 : 2} key={field.name}>
            <TypographyBox variant="subtitle1" component="span">
              <TitleHeaderTableOrder
                onChange={handleTitleOrderChange}
                title={field.title}
                selectedDirection={order.direction}
                columnName={field.name}
                isSortable
                isActive={field.name === order.column}
              />
            </TypographyBox>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default TableHeader;
