// @flow
import React, {PureComponent} from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import {get, isNil} from 'lodash';
import * as selectors from 'bc/store/selectors';
import * as profileSelectors from 'profile/store/selectors';
import {bcTypes, getTypeDetails} from 'bc/services/bcTypes';
import {getPollingIntervalOption, isAgent} from 'bc/services/dataStreamService';
import SelectAndt, {THEME_LIGHT, TYPE_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import PollingInterval from 'bc/components/streams/editor/common/PollingInterval';
import TimeZone from 'bc/components/streams/editor/common/TimeZone';
import {
  setIsTimeZoneConfirmModalOpen as setIsTimeZoneConfirmModalOpenAction,
  updateDataStreamScheduling as updateDataStreamSchedulingAction,
  updateStreamName as updateStreamNameAction,
  updateAgentOwner as updateAgentOwnerAction,
} from 'bc/store/actions';
import GaMaxDelay from 'bc/components/streams/editor/scheduler/GaMaxDelay';
import ConfirmationModal from 'common/components/modals/ConfirmationModal';
import {getUsersIsLoading} from 'admin.users/store/selectors';
import LiveStreamItemWrap from './LiveStreamItemWrap';
import './EditLiveStreamModal.module.scss';

type PropTypes = {
  isOpen: boolean,
  onClose: Function,

  // connect
  dataStream: Object,
  updateDataStreamScheduling: Function,
  setIsTimeZoneConfirmModalOpen: Function,
  updateStreamName: Function,
  updateAgentOwner: Function,
  isS3ShortIntervalEnabled: Boolean,
  isStreamRenameEnabled: Boolean,
  ownersList: Array,
  isLoadingOwnersList: Boolean,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isS3ShortIntervalEnabled: profileSelectors.getIsS3ShortIntervalEnabled(state),
    isStreamRenameEnabled: profileSelectors.getStreamRenameEnabled(state),
    ownersList: selectors.getStreamPossibleOwnersList(state),
    isLoadingOwnersList: getUsersIsLoading(state),
  }),
  {
    updateDataStreamScheduling: updateDataStreamSchedulingAction,
    setIsTimeZoneConfirmModalOpen: setIsTimeZoneConfirmModalOpenAction,
    updateStreamName: updateStreamNameAction,
    updateAgentOwner: updateAgentOwnerAction,
  },
)
export default class EditLiveStreamModal extends PureComponent {
  props: PropTypes;

  state = {
    owner: get(this.props.dataStream, 'owner', null),
    pollingInterval: get(this.props.dataStream, 'pollingInterval', null),
    delayMinutes: get(this.props.dataStream, 'delayMinutes', null),
    maxBackFillIntervals: get(this.props.dataStream, 'maxBackFillIntervals', null),
    maxMissingFiles: get(this.props.dataStream, 'maxMissingFiles', null),
    timeZone: get(this.props.dataStream, 'timeZone', null),
    maxGoldenDelayMinutes: get(this.props.dataStream, 'maxGoldenDelayMinutes', null),
    name: get(this.props.dataStream, 'name', ''),
    isTimeZoneConfirmModalOpen: false,
    newTimeZoneObj: null,
  };

  onValChange = (fieldName, val) => {
    this.setState({[fieldName]: val});
  };

  onEventChange = (fieldName, value) => {
    const val = parseInt(value, 10) >= 0 ? parseInt(value, 10) : 0;
    this.setState({[fieldName]: val});
  };

  timeZoneChanged = (obj) => {
    if ([bcTypes.google_storage.type, bcTypes.s3.type].includes(this.props.dataStream.type)) {
      if (
        this.props.dataStream.timeDefinition.timeZone === undefined ||
        this.props.dataStream.timeDefinition.timeZone === obj.timeZone
      ) {
        this.setState(obj);
      } else {
        this.setState({newTimeZoneObj: obj});
        this.openConfirmTimeZoneModal();
      }
    } else {
      this.setState(obj);
    }
  };

  closeAbortConfirmModal = () => {
    this.setState({isTimeZoneConfirmModalOpen: false});
    this.setState({timeZone: this.props.dataStream.timeDefinition.timeZone});
  };

  onConfirmTimeZoneChange = () => {
    const timeZone = this.state.newTimeZoneObj;
    this.setState(timeZone);
    this.setState({isTimeZoneConfirmModalOpen: false});
  };

  openConfirmTimeZoneModal = () => {
    this.props.setIsTimeZoneConfirmModalOpen(true);
    this.setState({isTimeZoneConfirmModalOpen: true});
  };

  onSaveClick = () => {
    const res = {
      id: this.props.dataStream.id,
    };
    ['maxBackFillIntervals', 'delayMinutes', 'pollingInterval', 'timeZone', 'maxMissingFiles'].map((item) => {
      if (!isNil(this.state[item])) {
        res[item] = this.state[item];
        return true;
      }
      return false;
    });
    // 'maxGoldenDelayMinutes' can be null so it can not be part of the above map
    if (this.props.dataStream.type === 'google_analytics') {
      res.maxGoldenDelayMinutes = this.state.maxGoldenDelayMinutes;
    }

    if (res.length > 1) {
      this.props.updateDataStreamScheduling(res);
    }

    if (isAgent(this.props.dataStream.family) && this.state.owner && this.state.owner !== this.props.dataStream.owner) {
      this.props.updateAgentOwner({
        id: this.props.dataStream.id,
        owner: this.state.owner,
      });
    }

    if (this.props.isStreamRenameEnabled && this.props.dataStream.name !== this.state.name) {
      this.props.updateStreamName({
        id: this.props.dataStream.id,
        newName: this.state.name.trim(),
      });
    }

    this.props.onClose();
  };

  getPollingIntervalTitle = (type) => {
    switch (type) {
      case bcTypes.s3.type:
        return 'Collection Interval';
      case bcTypes.google_storage.type:
        return 'Collect Files Every';
      default:
        return 'Query Every';
    }
  };

  getDelayMinutesTitle = (type) => {
    switch (type) {
      case bcTypes.google_storage.type:
        return 'Delay (Minutes)';
      default:
        return 'Minimal Delay (Minutes)';
    }
  };

  isKinesis = () => [bcTypes.kinesis.type, bcTypes.eventhubs.type].includes(this.props.dataStream.type);

  isS3 = () => this.props.dataStream.type === bcTypes.s3.type;

  render() {
    const {
      dataStream,
      onClose,
      isS3ShortIntervalEnabled,
      isStreamRenameEnabled,
      ownersList,
      isLoadingOwnersList,
    } = this.props;
    const {
      owner,
      pollingInterval,
      delayMinutes,
      maxBackFillIntervals,
      maxMissingFiles,
      timeZone,
      maxGoldenDelayMinutes,
      name,
    } = this.state;

    const currentOwnerIndex = owner ? ownersList.findIndex((option) => option.id === owner) : -1;

    /* eslint-disable no-nested-ternary */
    return (
      <div>
        <Modal show={this.props.isOpen} dialogClassName="bc modal-lg edit-live" onHide={onClose}>
          <Modal.Header bsClass="bc-modal-header">
            <Modal.Title styleName="vss-modal-title">
              <div>
                <span styleName="title-main">{isAgent(dataStream.family) ? 'Edit Pipeline' : 'Edit Stream'}</span>
              </div>
              <div styleName="title-sub-block">
                <div className={`image-${getTypeDetails(dataStream.type).iconStyle}`} styleName="type-image" />
                <input
                  styleName="vss-section-input title-sub-input"
                  type="string"
                  onChange={(e) => this.onValChange('name', e.target.value)}
                  value={name}
                  readOnly={isAgent(dataStream.family) || !isStreamRenameEnabled}
                  disabled={isAgent(dataStream.family) || !isStreamRenameEnabled}
                />
              </div>
            </Modal.Title>
            <button className="btn btn-flat btn-icon-36 btn-secondary" onClick={onClose} type="submit">
              <i className="icon icn-icon-table-delete" />
            </button>
          </Modal.Header>

          <Modal.Body>
            <div className="modal-body-message" styleName="vss-modal-body">
              <div styleName="vss-modal-body-part">
                <div styleName="vss-modal-column">
                  {/* Upper section */}
                  <div styleName="vss-modal-section">
                    {isAgent(dataStream.family) ? (
                      <LiveStreamItemWrap title="Owner">
                        <div role="presentation" style={{maxWidth: '300px'}} onClick={(evt) => evt.preventDefault()}>
                          <SelectAndt
                            type={TYPE_SEARCH}
                            theme={THEME_LIGHT}
                            onChange={(val) => this.onValChange('owner', val.id)}
                            options={ownersList}
                            getOptionLabel={(val) => val.name}
                            getOptionValue={(val) => val.id}
                            buttonWidth={225}
                            optionHeight={40}
                            disabled={isLoadingOwnersList}
                            value={isLoadingOwnersList || currentOwnerIndex === -1 ? '' : ownersList[currentOwnerIndex]}
                            placeholder={isLoadingOwnersList ? 'Loading...' : currentOwnerIndex === -1 ? 'Not Set' : ''}
                            automationId="setsStreamOwner"
                          />
                        </div>
                      </LiveStreamItemWrap>
                    ) : (
                      <div styleName="vss-section-header">Schedule Settings</div>
                    )}
                  </div>

                  <div styleName="vss-modal-section">
                    {!this.isKinesis() && pollingInterval && dataStream.type !== 'aws_cur' && (
                      <LiveStreamItemWrap title={this.getPollingIntervalTitle(dataStream.type)}>
                        <PollingInterval
                          pollingInterval={pollingInterval}
                          allowedValues={getPollingIntervalOption(
                            dataStream.type,
                            dataStream.fileNamePattern,
                            isS3ShortIntervalEnabled,
                          )}
                          styleName="item-width"
                          theme={THEME_LIGHT}
                          value={pollingInterval}
                          onChange={(val) => this.onValChange('pollingInterval', val)}
                        />
                      </LiveStreamItemWrap>
                    )}

                    {delayMinutes >= 0 && delayMinutes !== null && dataStream.type !== 'google_ads' && (
                      <LiveStreamItemWrap title={this.getDelayMinutesTitle(dataStream.type)}>
                        <input
                          styleName="vss-section-input"
                          type="number"
                          min="0"
                          onChange={(e) => this.onEventChange('delayMinutes', e.target.value)}
                          value={delayMinutes}
                        />
                      </LiveStreamItemWrap>
                    )}

                    {// eslint-disable-next-line max-len
                    !this.isKinesis() && !this.isS3() && maxBackFillIntervals >= 0 && maxBackFillIntervals !== null && (
                      <LiveStreamItemWrap title="Backfill Policy">
                        <input
                          styleName="vss-section-input"
                          type="number"
                          min="0"
                          onChange={(e) => this.onEventChange('maxBackFillIntervals', e.target.value)}
                          value={maxBackFillIntervals}
                        />
                      </LiveStreamItemWrap>
                    )}

                    {this.isS3() && maxMissingFiles >= 0 && maxMissingFiles !== null && (
                      <LiveStreamItemWrap title="Lagging Files Policy">
                        <input
                          styleName="vss-section-input"
                          type="number"
                          min="0"
                          onChange={(e) => this.onEventChange('maxBackFillIntervals', e.target.value)}
                          value={maxBackFillIntervals != null ? maxBackFillIntervals : maxMissingFiles}
                        />
                      </LiveStreamItemWrap>
                    )}

                    {!this.isKinesis() && timeZone && (
                      <LiveStreamItemWrap title="Time Zone">
                        <TimeZone
                          timeZone={timeZone}
                          extraClassName="blue-style"
                          styleName="item-width"
                          theme={THEME_LIGHT}
                          onChange={(v) => this.timeZoneChanged(v)}
                        />
                      </LiveStreamItemWrap>
                    )}

                    {dataStream.type === 'google_analytics' && (
                      <LiveStreamItemWrap title="Maximal Delay">
                        <GaMaxDelay
                          theme={THEME_LIGHT}
                          minValueAllowed={delayMinutes}
                          maxGoldenDelayMinutes={maxGoldenDelayMinutes}
                          onChange={(val) => this.onValChange('maxGoldenDelayMinutes', val)}
                        />
                      </LiveStreamItemWrap>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer styleName="footer">
            <button className="btn btn-flat" onClick={onClose} type="button">
              CANCEL
            </button>
            <button className="btn btn-raised" disabled={false} onClick={this.onSaveClick} type="button">
              SAVE
            </button>
          </Modal.Footer>
        </Modal>

        {this.state.isTimeZoneConfirmModalOpen && (
          <ConfirmationModal
            onClose={this.closeAbortConfirmModal}
            onConfirm={this.onConfirmTimeZoneChange}
            title="Timezone Changed"
            message="You have chosen a Timezone different than the data records’ timezone. This could result in ignored
             rows in the streaming process. Press OK to confirm, or cancel to revert to the records’ timezone."
            isOpen={this.state.isTimeZoneConfirmModalOpen}
            buttons={['Cancel', 'OK']}
          />
        )}
      </div>
    );
  }
}
