import {cloneDeep, get} from 'lodash';
import {getChannelIcon} from 'channels/icons/channelsIconService';
import {getSourceIcon} from 'bc/services/dataManagerService';
import {bcTypes} from '../../services/bcTypes';

export const getAction = (sourceData) => {
  const type = bcTypes[sourceData.type];
  if (type) {
    if (type.action) {
      return type.action;
    }
    return bcTypes[sourceData.type].view;
  }
  return null;
};

export const getIsSourceInUse = (sourceData, dataSources) => {
  const data = get(dataSources, 'sources.data', []).map((d) => d.type);
  return data.findIndex((d) => d === sourceData.type) > -1;
};

const getCategoriesList = (configurations) => {
  const ret = [];
  if (!configurations.filters) {
    return ret;
  }
  configurations.filters.forEach((group) => {
    group.categories.forEach((cat) => {
      ret.push({categoryName: cat.categoryName, categoryTitle: cat.categoryTitle, items: []});
    });
  });
  return ret;
};

const getBCTypeData = (type) => {
  return bcTypes[type];
};

export const getIcon = (type, isChannel) => {
  if (isChannel) {
    return getChannelIcon(type);
  }
  return getSourceIcon(type);
};

// eslint-disable-next-line import/prefer-default-export
export const getParsedItemsList = (configurations) => {
  const categoriesList = getCategoriesList(configurations);
  categoriesList.unshift({categoryName: 'featured', categoryTitle: 'Featured', items: []});
  const categoryObjects = cloneDeep(categoriesList);
  if (!configurations.sourcesItems) {
    return categoryObjects;
  }
  configurations.sourcesItems.forEach((item) => {
    (item.categories || []).forEach((itemCat) => {
      const index = categoryObjects.findIndex((catObj) => catObj.categoryName === itemCat);
      if (index > -1) {
        const newItem = cloneDeep(item);
        const extraSourceDetail = getBCTypeData(newItem.name);
        if (extraSourceDetail) {
          newItem.action = extraSourceDetail.action;
          newItem.featureFlag = extraSourceDetail.featureFlag;
        }
        categoryObjects[index].items.push(newItem);
      }
    });
  });
  return categoryObjects.filter((obj) => obj.items.length > 0);
};

export const initDefaults = {
  selectedCategory: 'all',
  filterText: '',
  selectedTab: 'sources',
};
