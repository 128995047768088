// @flow
import React, {Fragment, useCallback, useMemo} from 'react';
import {useField} from 'react-final-form';
import {isEqual} from 'lodash';
import {useSelector, useDispatch} from 'react-redux';
import {Box} from '@material-ui/core';
import Button, {WIDTHS, COLORS} from 'common/componentsV2/Button';
import {getSelectedExpressionId, getExpressionTrees} from 'metrics/store/selectors';

import DateRanges from 'metrics/components/metricExplorer/DateRanges';
import Show from 'metrics/components/metricExplorer/components/Show';
import Sort from 'metrics/components/metricExplorer/components/Sort';

import TimeScaleSelectorField from 'metrics/components/metricExplorer/TImeScaleSelectorField';
import ChartRenderSettingsMenu from 'metrics/components/metricExplorer/ChartSettings/ChartRenderSettingsMenu';
import {getEnableRefreshButtonMetricExplorer, getIsImpersonate} from 'profile/store/selectors';
import Tooltip from 'common/componentsV2/Tooltip';
import {refetchExecuteData} from 'metrics/store/actions';
import {clearChartCache} from 'dashboards/store/actions';
import './MetricExplorerChartHeaderTools.module.scss';

type PropTypes = {
  isLoading: boolean,
  totalAmount: number,
  metrics: Array,
  providedResolution: string,
  setCollapsedView: Function,
  isCollapsedView: boolean,
  noSettings: boolean,
};

const MetricExplorerChartHeaderTools = ({
  providedResolution,
  isLoading,
  totalAmount,
  metrics,
  isCollapsedView,
  setCollapsedView,
  noSettings,
}: PropTypes) => {
  const dispatch = useDispatch();
  const selectedTreeId = useSelector(getSelectedExpressionId);
  const expressionTrees = useSelector(getExpressionTrees);
  const isImpersonate = useSelector(getIsImpersonate);
  const isEnableRefreshButton = useSelector(getEnableRefreshButtonMetricExplorer);

  const {
    input: {value, onChange},
  } = useField('multiPreviewOptions', {isEqual});

  const multiPreviewOptions = useMemo(
    () =>
      expressionTrees.reduce(
        (acc, expressionTree) => ({
          ...acc,
          [expressionTree.id]: value[expressionTree.id] || value['*'],
        }),
        value,
      ),
    [expressionTrees, value],
  );

  const handleOnShowChange = useCallback(
    (selectedValue) => {
      const newObj = {...multiPreviewOptions};
      Object.keys(multiPreviewOptions).forEach((key) => {
        newObj[key] = {...newObj[key], show: selectedValue};
      });
      onChange(newObj);
    },
    [multiPreviewOptions],
  );

  const handleOnSortChange = useCallback(
    (selectedValue) => {
      const newObj = {...multiPreviewOptions};
      Object.keys(multiPreviewOptions).forEach((key) => {
        newObj[key] = {...newObj[key], sort: selectedValue};
      });

      onChange(newObj);
    },
    [multiPreviewOptions],
  );

  const handleRefreshButton = useCallback(() => {
    dispatch(clearChartCache({}, {chartId: 'expressionTreesPreview'}));
    dispatch(refetchExecuteData());
  }, [dispatch]);

  return (
    <Fragment>
      <div styleName="container">
        <div styleName="top-container">
          <div styleName="main-title" automation-id="metricExplorerGraphTitle">
            {isLoading ? (
              'Loading...'
            ) : (
              <div className="display_flex alignItems_center">
                {`Showing ${metrics ? metrics.length : 0} out of ${Math.max(totalAmount, metrics.length)} Metrics`}
                {(isImpersonate || isEnableRefreshButton) && (
                  <Tooltip content="Refresh">
                    <i className="icon icn-general16-refresh ml_1" role="button" onClick={handleRefreshButton} />
                  </Tooltip>
                )}
              </div>
            )}
          </div>
          {!noSettings && (
            <div styleName="buttons-container">
              <div styleName="chart-render-button">
                <ChartRenderSettingsMenu />
              </div>
              <Button
                fixedWidth={35}
                width={WIDTHS.NARROW_TIGHT}
                colorSchema={COLORS.GRAY_300}
                icon={isCollapsedView ? 'icn-action16-expand' : 'icn-action16-collapse'}
                onClick={() => setCollapsedView(!isCollapsedView)}
                automationId="metricExplorerSplitGraphButton"
              />
            </div>
          )}
        </div>
      </div>

      <Box mb={0.5} mt={1} flexShrink={0}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <Box display="flex" flexDirection="column" mr={3}>
              <div styleName="subtitle">Time Frame</div>
              <DateRanges />
            </Box>
            <Box display="flex" flexDirection="column" flexShrink={0}>
              <div styleName="subtitle">Time Scale</div>
              <TimeScaleSelectorField providedResolution={providedResolution} />
            </Box>
          </Box>
          <Box display="flex" position="relative">
            <Box display="flex">
              <Box display="flex" flexDirection="column" mr={3}>
                <div styleName="subtitle">Show</div>
                <Show onChange={handleOnShowChange} value={multiPreviewOptions[selectedTreeId].show} />
              </Box>
              <Box display="flex" flexDirection="column">
                <div styleName="subtitle">Sort By</div>
                <Sort onChange={handleOnSortChange} value={multiPreviewOptions[selectedTreeId].sort} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default MetricExplorerChartHeaderTools;
