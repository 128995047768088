// @flow
import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {get} from 'lodash';
import * as selectors from 'bc/store/selectors';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';

import './AwsCurSchedular.module.scss';

type PropTypes = {
  dataStream: Object,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {},
)
export default class AwsCurSchedular extends React.PureComponent {
  props: PropTypes;

  render() {
    const collectSince =
      get(this.props.dataStream.historicalDateRange, 'startDate', '') !== ''
        ? moment.unix(this.props.dataStream.historicalDateRange.startDate).format('MM-YYYY')
        : '';

    return (
      <div className="shell-col" automation-id="awsCurScheduler">
        <PanelHeader title="Schedule file collection" isEditable isEditHidden />
        <div>
          <span styleName="title">Collect Every</span>
          <span styleName="data">day</span>
        </div>
        <div>
          <span styleName="title">Time Zone</span>
          <span styleName="data">UTC</span>
        </div>
        <div>
          <span styleName="title">Collect Since</span>
          <span styleName="data">{collectSince}</span>
        </div>
      </div>
    );
  }
}
