// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import SearchBox from 'common/componentsV2/SearchBox';
import * as filterSelector from 'admin.users/store/filterSelector';
import {setUsersGroupsFiltersKeyVal} from 'admin.users/store/actions';

type PropTypes = {
  setQueryParams: Function,
  searchQuery: String,
  setUsersGroupsFiltersKeyVal: Function,
};

@connect(
  (state) => ({
    searchQuery: filterSelector.getFiltersSearchQuery(state),
  }),
  {
    setUsersGroupsFiltersKeyVal,
  },
)
export default class UGSearchQueryFilter extends React.PureComponent {
  props: PropTypes;

  onFilter = (val) => {
    this.props.setQueryParams({searchQuery: val});
    this.props.setUsersGroupsFiltersKeyVal({searchQuery: val});
  };

  render() {
    const {searchQuery} = this.props;
    return (
      <SearchBox onFilter={this.onFilter} filter={searchQuery} placeHolder="Search" automationId="usersSearchFilter" />
    );
  }
}
