// @flow
import React from 'react';
import {connect} from 'react-redux';
import ReactQueryParams from 'common/components/ReactQueryParams';

import PageLayout from 'common/componentsV2/PageLayout';
import './AlertManager.module.scss';
import {
  allAlertCheckboxClick as allAlertCheckboxClickAction,
  fetchAlertConfigurations as fetchAlertConfigurationsAction,
  setAlertFilters as setAlertFiltersAction,
  setAlertToScrollAndOpen,
} from 'alerts.management/store/actions';
import * as alertsSelectors from 'alerts.management/store/selectors';
import * as filterSelector from 'alerts.management/store/filterSelector';
import {getMeAppSettings, getMeOwnerOrganization, getPagesVisited} from 'profile/store/selectors';

import {DEFAULT_QUERY_PARAMS} from 'alerts.management/services/alertManagementService';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {fetchGroups, fetchUsers} from 'admin.users/store/actions';
import {fetchChannels} from 'alerts.channels/store/actions';
import {fetchDashboards, fetchDashboardsV1} from 'dashboards/store/actions';
import {fetchDataStreams} from 'bc/store/actions';
import AlertManagerFilters from 'alerts.management/components/manager/AlertManagerFilters';
import Footer from 'common/componentsV2/Footer';
import {cloneDeep, get, isEmpty, isEqual} from 'lodash';
import BottomPanel, {BOTTOM_PANEL_TYPE} from 'common/componentsV2/BottomPanel';
import AlertsManagerTable from '../components/manager/AlertsManagerTable';
import AlertManagerFooter from '../components/manager/footer/AlertManagerFooter';
import AlertManagerHeader from '../components/manager/AlertManagerHeader';
import * as selectors from '../store/selectors';

type PropTypes = {
  // connect
  fetchDashboards: Function,
  fetchDashboardsV1: Function,
  setAlertToScrollAndOpen: Function,
  fetchDataStreams: Function,
  setAlertFilters: Function,
  selectedCheckboxes: Array,
  allAlertCheckbox: boolean,
  fetchAlertConfigurations: Function,
  fetchUsers: Function,
  fetchChannels: Function,
  allAlertCheckboxClick: Function,
  fetchGroups: Function,
  alertConfigurationsTotal: Number,
  savedFilterView: Object,
  isFiltersOpen: Boolean,
  featureOnBoarding: Boolean,
  allAlertsCheckboxState: String,
  alertToScrollAndOpen: String,
  ownerOrganization: String,
  tagList: Array,
  filters: Object,
};

@connect(
  (state) => ({
    isLoading: alertsSelectors.getAlertConfigurationsIsLoading(state),
    selectedCheckboxes: alertsSelectors.getSelectedAlertsCheckbox(state),
    allAlertCheckbox: alertsSelectors.getAllAlertCheckbox(state),
    savedFilterView: filterSelector.getAlertManagerFilersViewSaved(state),
    alertConfigurationsTotal: alertsSelectors.getAlertConfigurationsCount(state),
    isFiltersOpen: get(getMeAppSettings(state), 'alertManager.isFiltersBarOpen', false),
    pagesVisited: getPagesVisited(state),
    allAlertsCheckboxState: selectors.getAllAlertsCheckboxState(state),
    alertToScrollAndOpen: selectors.getAlertToScrollAndOpen(state),
    ownerOrganization: getMeOwnerOrganization(state),
    tagsList: selectors.getTagsList(state),
    filters: selectors.getAlertFilters(state),
  }),
  {
    setAlertFilters: setAlertFiltersAction,
    fetchAlertConfigurations: fetchAlertConfigurationsAction,
    fetchUsers,
    fetchChannels,
    fetchDataStreams,
    allAlertCheckboxClick: allAlertCheckboxClickAction,
    fetchGroups,
    fetchDashboards,
    fetchDashboardsV1,
    setAlertToScrollAndOpen,
  },
)
export default class AlertManager extends ReactQueryParams {
  props: PropTypes;

  defaultQueryParams = {...DEFAULT_QUERY_PARAMS};

  componentDidMount() {
    if (!isEmpty(this.props.savedFilterView) && isEqual(this.queryParams, DEFAULT_QUERY_PARAMS)) {
      this.setQueryParamsWrapper(this.props.savedFilterView);
    } else {
      this.props.setAlertFilters(this.queryParams);
    }
    this.props.fetchAlertConfigurations();
    this.props.fetchUsers();
    this.props.fetchChannels();
    this.props.fetchDataStreams();
    this.props.fetchGroups({ownerOrganization: this.props.ownerOrganization});
    this.props.fetchDashboards();
    this.props.fetchDashboardsV1();

    const paramIndex = this.props.location.search.indexOf('alertIdToScrollTo=');
    if (paramIndex !== -1) {
      const searchParams = this.props.location.search.slice(paramIndex + 18);
      this.props.setAlertToScrollAndOpen(searchParams.split('&')[0]);
      // eslint-disable-next-line prefer-destructuring
      window.location.hash = window.location.hash.split('?')[0];
    }
  }

  componentDidUpdate(prevProps) {
    if (super.componentDidUpdate) {
      super.componentDidUpdate();
    }

    const {setAlertFilters, location} = this.props;
    if (prevProps.location.search !== location.search) {
      setAlertFilters(this.queryParams);
    }
    if (prevProps.tagsList.length !== this.props.tagsList.length) {
      this.updateTagsQuery(this.props.tagsList);
    }
  }

  setQueryParamsWrapper = (queryObj) => {
    // whose is not available anymore. But some users still have it in their defaults

    // eslint-disable-next-line no-param-reassign
    delete queryObj.whose;
    this.setQueryParams(queryObj, true);
  };

  updateTagsQuery = (tagList) => {
    if (this.props.filters.tag) {
      const index = (tagList || []).findIndex((val) => this.props.filters.tag.split(',').includes(val.name));
      if (index === -1) {
        const newFilter = cloneDeep(this.props.filters);
        newFilter.tag = null;
        this.setQueryParams(newFilter, true);
      }
    }
  };

  render() {
    const {isLoading, selectedCheckboxes, allAlertsCheckboxState, allAlertCheckboxClick, isFiltersOpen} = this.props;

    return (
      <div styleName="page-footer-container">
        <div styleName="page-container">
          <PageLayout header={<AlertManagerHeader />}>
            <div styleName="page-content-wrapper">
              <AlertManagerFilters setQueryParams={this.setQueryParamsWrapper} />
              <div styleName="table-wrapper" automation-id="Alert manager table">
                {isLoading ? (
                  <div styleName="spinner">
                    <Spinner color="#3d4c59" size={SIZES.XX_BIG_100} />
                  </div>
                ) : (
                  <AlertsManagerTable />
                )}
              </div>
            </div>
          </PageLayout>
        </div>
        <BottomPanel type={BOTTOM_PANEL_TYPE.blue} isActive={selectedCheckboxes.length > 0}>
          <Footer
            selectedItemsCount={selectedCheckboxes.length}
            checkboxState={allAlertsCheckboxState}
            onCheckboxClicked={allAlertCheckboxClick}
            leftPadding={isFiltersOpen ? 306 : 0}
          >
            <AlertManagerFooter />
          </Footer>
        </BottomPanel>
      </div>
    );
  }
}
