// @flow
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import FilterButton from 'common/componentsV2/FilterButton';
import Button, {COLORS} from 'common/componentsV2/Button';
import OnBoarding from 'onBoarding/Onboarding';
import * as commonSelectors from 'profile/store/selectors';
import * as onBoardingSelectors from 'onBoarding/store/selectors';
import * as alertsConsoleSelectors from 'alerts.console/store/selectors';
import * as filterSelector from 'alerts.console/store/filterSelector';
import * as actions from 'alerts.console/store/actions';
// import {setAlertFiltersIsOpen} from 'alerts.management/store/actions';
import {segmentCausingEvent} from 'common/store/actions';
import NewAlertButton from 'alerts.management/components/editor/simpleAlertForm/NewAlertButton';
import useKeydown from 'common/hooks/useKeydown';
import './AlertsConsoleHeader.module.scss';

const AlertsConsoleHeader = () => {
  const dispatch = useDispatch();
  const isFiltersBarOpen = useSelector(alertsConsoleSelectors.getIsFiltersBarOpen);
  const userProfile = useSelector(commonSelectors.getUserProfile);
  const isOnBoardingDismiss = useSelector(commonSelectors.getIsOnBoardingDismiss);
  const onBoardingTasks = useSelector(onBoardingSelectors.getMergedTasks);
  const isAllTasksAccomplished = useSelector(onBoardingSelectors.getIsAllTasksAccomplished);
  const featureOnBoarding = useSelector(commonSelectors.getFeatureOnBoarding);
  const featureNewAlertManagerEnabled = useSelector(commonSelectors.getAlertManagerEnabled);
  const isFirstTimeVisit = useSelector(alertsConsoleSelectors.getIsFirstTimeVisit);
  const filtersButtonTooltipItems = useSelector(filterSelector.getFiltersButtonTooltipItems);

  const [alertMngUrl, setAlertMngUrl] = useState(null);
  const [initialAllTasksAccomplished] = useState(isAllTasksAccomplished);

  const reportSegment = (category, name) => {
    dispatch(
      segmentCausingEvent({
        segment: {category, name},
      }),
    );
  };

  const toggleFiltersBar = () => {
    dispatch(actions.updateFilterBtn(isFiltersBarOpen));
  };

  const goto = () => {
    const {origin} = window.location;
    window.open(`${origin}/#!/${alertMngUrl}`, '_top');
  };

  useKeydown(toggleFiltersBar, 70); // 70 = f

  useEffect(() => {
    setAlertMngUrl(featureNewAlertManagerEnabled ? 'r/alert-manager' : 'alerts');
  }, [featureNewAlertManagerEnabled]);

  useEffect(() => {
    if (isAllTasksAccomplished && initialAllTasksAccomplished !== isAllTasksAccomplished) {
      reportSegment('onBoarding', 'all-task-completed');
    }
  }, [isAllTasksAccomplished]);

  return (
    <header styleName="page-header">
      <div styleName="container" automation-id="Alert Console Header">
        <Title type={TITLE_TYPES.PAGE_TITLE}>Alert Console</Title>
        <FilterButton tooltipItems={filtersButtonTooltipItems} onClick={toggleFiltersBar} />
      </div>

      <div styleName="container right">
        <div styleName="onBoarding-container">
          {featureOnBoarding && !isOnBoardingDismiss && (
            <OnBoarding
              isAllTasksAccomplished={isAllTasksAccomplished}
              tasks={onBoardingTasks}
              shouldBeOpen={isFirstTimeVisit}
              userData={userProfile}
            />
          )}
        </div>

        <Button colorSchema={COLORS.GRAY_200} text="Alert Management" onClick={goto} />

        <NewAlertButton />
      </div>
    </header>
  );
};

export default AlertsConsoleHeader;
