// @flow

import React from 'react';
import Highlighter from 'react-highlight-words';
import './OptionComponent.module.scss';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';

type PropTypes = {
  roleState: String,
  highlightText: String,
  data: Object,
  isSelected: boolean,
  getOptionLabel: Function,
  isAsyncData: boolean,
};
export default class OptionComponent extends React.PureComponent {
  props: PropTypes;

  state = {};

  label = (option) => (this.props.getOptionLabel ? this.props.getOptionLabel(option) : option.label);

  render() {
    const isMulti = !!this.props.data.multi;
    const isAsync = this.props.isAsyncData;
    return (
      // eslint-disable-next-line prefer-template,no-useless-concat
      <div styleName={'container' + ' ' + this.props.roleState}>
        <div styleName="name-and-count">
          {this.props.isSelected && <i styleName="isSelected" className="icon icn-general16-checkmark" />}
          <Tooltip content={this.label(this.props.data)} type={TYPES.SMALL}>
            <div styleName="label-container">
              <Highlighter
                autoEscape
                searchWords={[this.props.highlightText]}
                textToHighlight={this.label(this.props.data)}
              />
            </div>
          </Tooltip>
          {isMulti && !isAsync && <span styleName="count">{`(${this.props.data.multi.length})`}</span>}
        </div>
        {isMulti && <div styleName="chevron" className="icon icn-arrow16-chevronright" />}
      </div>
    );
  }
}
