// @flow
import React from 'react';
import {connect} from 'react-redux';
import {error as errorNotification} from 'common/utils/notifications/notificationsService';
import {throttle, postLogReactError} from './logError';

type PropTypes = {
  children: Node,
  showFallback?: Boolean,
  triggerSnackbar: Function,
  fallbackElement?: any,
};

const notificationObj = {
  title: 'Error',
  description: 'Unfortunately, UI error has occurred',
  settings: {
    canClose: true,
    autoDismiss: 10,
    uid: 'error_boundary',
  },
};

@connect(
  () => ({}),
  {triggerSnackbar: errorNotification},
)
export default class ErrorBoundary extends React.Component {
  props: PropTypes;

  // *updates the state if an error occurs, called during the “render” phase.
  static getDerivedStateFromError() {
    return {hasError: true};
  }

  static defaultProps = {
    showFallback: false,
    fallbackElement: (
      <h5
        style={{
          margin: '0 0 9px 0',
          padding: 0,
          fontSize: '16px',
          color: '#ff3d35',
        }}
      >
        Something went wrong.
      </h5>
    ),
  };

  constructor(props) {
    super(props);
    this.logError = throttle(postLogReactError, 1000);
    this.state = {hasError: false};
  }

  // *provides side effects and can access this component instance if needed.
  componentDidCatch(error, errorInfo) {
    this.logError(error, errorInfo);
    this.props.triggerSnackbar({
      ...notificationObj,
      description: error.toString(),
    });
  }

  render() {
    const {fallbackElement, showFallback, children} = this.props;

    if (this.state.hasError && showFallback) {
      return <div>{fallbackElement}</div>;
    }
    return children;
  }
}
