const SNOWFLAKE_REGIONS = [
  {text: 'AWS US East (Ohio)', value: 'us-east-2.aws'},
  {text: 'AWS US East (N. Virginia)', value: 'us-east-1'},
  {text: 'AWS US East (Commercial Gov - N. Virginia)', value: 'us-east-1-gov.aws'},
  {text: 'AWS US West (Oregon)', value: 'us-west-2'},
  {text: 'AWS Canada (Central)', value: 'ca-central-1.aws'},
  {text: 'AWS EU (Ireland)', value: 'eu-west-1'},
  {text: 'AWS Europe (London)', value: 'eu-west-2.aws'},
  {text: 'AWS EU (Frankfurt)', value: 'eu-central-1'},
  {text: 'AWS Asia Pacific (Tokyo)', value: 'ap-northeast-1.aws'},
  {text: 'AWS Asia Pacific (Mumbai)', value: 'ap-south-1.aws'},
  {text: 'AWS Asia Pacific (Singapore)', value: 'ap-southeast-1'},
  {text: 'AWS Asia Pacific (Sydney)', value: 'ap-southeast-2'},
  {text: 'GCP US Central1 (Iowa)', value: 'us-central1.gcp'},
  {text: 'GCP Europe West2 (London)', value: 'europe-west2.gcp'},
  {text: 'GCP Europe West4 (Netherlands)', value: 'europe-west4.gcp'},
  {text: 'Azure West US 2 (Washington)', value: 'west-us-2.azure'},
  {text: 'Azure Central US (Iowa)', value: 'central-us.azure'},
  {text: 'Azure East US 2 (Virginia)', value: 'east-us-2.azure'},
  {text: 'Azure US Gov Virginia', value: 'us-gov-virginia.azure'},
  {text: 'Azure Canada Central (Toronto)', value: 'canada-central.azure'},
  {text: 'Azure West Europe (Netherlands)', value: 'west-europe.azure'},
  {text: 'Azure Switzerland North (Zurich)', value: 'switzerland-north.azure'},
  {text: 'Azure Southeast Asia (Singapore)', value: 'southeast-asia.azure'},
  {text: 'Azure Australia East (New South Wales)', value: 'australia-east.azure'},
];

export default SNOWFLAKE_REGIONS;
