import * as actions from 'bc/store/actions';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';
import {isEqual} from 'lodash';
import {getUniqueId} from 'common/utils/guid';

// eslint-disable-next-line complexity
const fileDiametricsDataStreamReducer = composeReducers((state, {type, payload}) => {
  const getSelectedIndex = (id = state.selectedItemId) => state.streams.data.findIndex((item) => item.id === id);

  const red = (item, itemPayload) => ({...item, ...itemPayload});

  const redWrapper = (_payload, index = getSelectedIndex()) => ({
    ...state,
    streams: {
      ...state.streams,
      data: reduceArrayItem(red, state.streams.data, index, _payload || payload),
    },
  });

  // gets sources ids used by items recursively
  const getSourceIdsArr = (sourcesIdArr, item) => {
    if (item.sourceColumn !== undefined) {
      if (!sourcesIdArr.includes(item.sourceColumn)) {
        sourcesIdArr.push(item.sourceColumn);
      }
    } else if (item.transform && item.transform.input) {
      item.transform.input.forEach((a) => {
        getSourceIdsArr(sourcesIdArr, a);
      });
    }
    return sourcesIdArr;
  };

  // looks for fileSchema item in the schema
  // note: sourceColumn might have a number stored as a string
  const getItemInSchema = (columns, item) =>
    columns.find(
      (a) =>
        (a.sourceColumn && item.index.toString() === a.sourceColumn.toString()) ||
        (a.transform &&
          a.transform.input &&
          a.transform.input.length === 1 &&
          item.index.toString() === getSourceIdsArr([], a)[0].toString()),
    );

  const getIsItemSourceMerged = (unifiedFileSchema, itemId, itemName) =>
    unifiedFileSchema.find(
      (a) =>
        ((a.sourceColumn && a.sourceColumn.toString() === itemId.toString()) ||
          (getSourceIdsArr([], a)[0] && getSourceIdsArr([], a)[0].toString() === itemId.toString())) &&
        a.name !== itemName &&
        a.isMerged,
    );

  switch (type) {
    case actions.setGoogleStorageStreamDiametricsAnalysisSchema.TYPE: {
      const fileSchema = payload.fileSchema || []; // file schema may be null for corrupted files
      const propsToUpdate = {
        uiState: {
          analysisResult: {...payload}, // keep last analysis schema so we'll be aware of columns with type=null
          unAssignedColumns: fileSchema.filter((a) => !a.type).map((a) => a.index), // keep sort idx of col type=null
        },
        fileFormat: payload.fileFormat,
        dimensions: fileSchema.filter((a) => a.type === 'dimension').map((a) => a.index),
        metrics: fileSchema.filter((a) => a.type === 'metric').map((a) => a.index),
        timeDefinition: payload.timeDefinition || {},
      };
      return {...redWrapper(propsToUpdate)};
    }

    case actions.setFileStreamDiametricsAnalysisSchema.TYPE:
    case actions.setS3StreamDiametricsAnalysisSchema.TYPE: {
      const fileSchema = payload.fileSchema || []; // file schema may be null for corrupted files
      const stream = state.streams.data[getSelectedIndex()];

      let unifiedFileSchema = [];
      let itemMatch = {};
      let unifiedTimeDefinition = payload.timeDefinition || {};

      // compare and merge the payload and current fileSchema
      if (stream.uiState && stream.uiState.analysisResult && stream.uiState.analysisResult.fileSchema) {
        // loop the new file schema
        fileSchema.forEach((newItem) => {
          itemMatch = {...newItem};
          // loop the old file schema
          stream.uiState.analysisResult.fileSchema.forEach((oldItem) => {
            // look for item match in the old file schema
            if (
              (stream.fileFormat && stream.fileFormat.hasHeader === true && isEqual(newItem, oldItem)) ||
              ((!stream.fileFormat || stream.fileFormat.hasHeader !== true) &&
                (newItem.index === oldItem.index &&
                  newItem.type === oldItem.type &&
                  isEqual(newItem.possibleTypes, oldItem.possibleTypes)))
            ) {
              // look for the item in metrics or dimensions
              if (stream.metrics.includes(newItem.index) || stream.dimensions.includes(newItem.index)) {
                itemMatch = {
                  ...newItem,
                  ...getItemInSchema(stream.schema && stream.schema.columns ? stream.schema.columns : [], newItem),
                  isMerged: true,
                };
              } else {
                itemMatch = {
                  ...newItem,
                };
                if (itemMatch.type) {
                  delete itemMatch.type;
                }
              }
            }
          });
          unifiedFileSchema.push({...itemMatch});

          // look for the item in the timeDefinition
          if (
            stream.timeDefinition &&
            typeof stream.timeDefinition.timeColumnIdx !== 'undefined' &&
            newItem.index === stream.timeDefinition.timeColumnIdx
          ) {
            unifiedTimeDefinition = {...stream.timeDefinition};
            itemMatch = {
              ...newItem,
              type: 'time',
            };
            unifiedFileSchema.push({...itemMatch});
          }
        });
      } else if (stream.metrics.length || stream.dimensions.length) {
        // no uiState and running stream
        fileSchema.forEach((newItem) => {
          itemMatch = {...newItem};
          if (
            stream.timeDefinition &&
            typeof stream.timeDefinition.timeColumnIdx !== 'undefined' &&
            newItem.index === stream.timeDefinition.timeColumnIdx
          ) {
            unifiedTimeDefinition = {...stream.timeDefinition};
            itemMatch = {
              ...newItem,
              type: 'time',
            };
          }
          // look for the item in metrics or dimensions (the item will not be in the old schema)
          else if (stream.metrics.includes(newItem.index) || stream.dimensions.includes(newItem.index)) {
            itemMatch = {
              ...newItem,
              ...getItemInSchema(stream.schema && stream.schema.columns ? stream.schema.columns : [], newItem),
              isMerged: true,
            };
          } else {
            itemMatch = {
              ...newItem,
            };
            if (itemMatch.type) {
              delete itemMatch.type;
            }
          }
          unifiedFileSchema.push({...itemMatch});
        });
      } else {
        // no uiState and no dimensions, metrics and schema
        unifiedFileSchema = fileSchema;
      }

      // extract the updated unifiedFileSchema
      const propsToUpdate = {
        uiState: {
          ...stream.uiState,
          analysisResult: {...payload},
          unAssignedColumns: unifiedFileSchema.filter((item) => !item.type).map((item) => item.index),
        },
        fileFormat: payload.fileFormat,
        dimensions: unifiedFileSchema.filter((item) => item.type === 'dimension').map((item) => item.index),
        metrics: unifiedFileSchema.filter((item) => item.type === 'metric').map((item) => item.index),
        timeDefinition: unifiedTimeDefinition,
        schema: {
          columns: [],
          sourceColumns: [],
        },
      };

      // schema population
      let unifiedItem = {};
      propsToUpdate.metrics.forEach((itemId) => {
        const metric = unifiedFileSchema.find((a) => a.index === itemId);
        unifiedItem = {
          id: metric.id || getUniqueId(),
          name: metric.name,
          type: 'metric',
        };
        if (metric.transform) {
          unifiedItem.transform = {...metric.transform};
        } else {
          unifiedItem.sourceColumn = metric.index.toString();
        }
        if (metric.targetType) {
          unifiedItem.targetType = metric.targetType;
        }
        if (metric.hidden !== undefined) {
          unifiedItem.hidden = metric.hidden;
        }
        propsToUpdate.schema.columns.push(unifiedItem);
        propsToUpdate.schema.sourceColumns.push({
          id: metric.index,
          index: metric.index,
        });
      });
      propsToUpdate.dimensions.forEach((itemId) => {
        const dimension = unifiedFileSchema.find((a) => a.index === itemId);
        unifiedItem = {
          id: dimension.id || getUniqueId(),
          name: dimension.name,
          type: 'dimension',
        };
        if (dimension.transform) {
          unifiedItem.transform = {...dimension.transform};
        } else {
          unifiedItem.sourceColumn = dimension.index.toString();
        }
        if (dimension.hidden !== undefined) {
          unifiedItem.hidden = dimension.hidden;
        }
        propsToUpdate.schema.columns.push(unifiedItem);
        propsToUpdate.schema.sourceColumns.push({
          id: dimension.index,
          index: dimension.index,
        });
      });

      // get a list of all the current indexes
      const existingIndexArr = [...propsToUpdate.dimensions, ...propsToUpdate.metrics];

      // compare and copy the user created columns from the current schema
      if (stream.schema && stream.schema.columns) {
        stream.schema.columns.forEach((item) => {
          if (
            item.sourceColumn !== undefined ||
            (item.transform &&
              item.transform.input &&
              item.transform.input.length === 1 &&
              getSourceIdsArr([], item).length === 1)
          ) {
            const sourceColumn = item.sourceColumn || getSourceIdsArr([], item)[0];
            if (
              existingIndexArr.includes(parseInt(sourceColumn, 10)) &&
              getIsItemSourceMerged(unifiedFileSchema, sourceColumn, item.name)
            ) {
              // user added columns that are a copy of existing column
              propsToUpdate.schema.columns.push({...item});
            }
          } else if (item.transform && item.transform.name === 'const') {
            // user added const column
            propsToUpdate.schema.columns.push({...item});
          } else if (item.transform && item.transform.input && item.transform.input.length > 1) {
            // user added concat columns
            let shouldPush = true;
            getSourceIdsArr([], item).forEach((id) => {
              if (
                !existingIndexArr.includes(parseInt(id, 10)) ||
                getIsItemSourceMerged(unifiedFileSchema, id, item.name) === undefined
              ) {
                shouldPush = false;
              }
            });
            if (shouldPush) {
              propsToUpdate.schema.columns.push({...item});
            }
          }
        });
      }
      return {...redWrapper(propsToUpdate)};
    }

    case actions.setFileStreamClearAllDiametrics.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      const {fileSchema} = stream.uiState.analysisResult;
      const mod = {
        metrics: [],
        dimensions: [],
        schema: {
          columns: [],
          sourceColumns: [],
        },
        timeDefinition: {...stream.timeDefinition, timeColumnIdx: null},
        uiState: {
          ...stream.uiState,
          unAssignedColumns: fileSchema.map((a) => a.index),
        },
      };
      return redWrapper(mod);
    }

    case actions.removeFileStreamDiametrics.TYPE: {
      const removeId = payload.startsWith('id_') ? +payload.substr(3) : payload;
      const stream = state.streams.data[getSelectedIndex()];
      let updateSchema = false;
      const mod = {};
      if (stream.metrics.indexOf(removeId) >= 0) {
        updateSchema = true;
        mod.metrics = stream.metrics.filter((a) => a !== removeId);
      } else if (stream.dimensions.indexOf(removeId) >= 0) {
        updateSchema = true;
        mod.dimensions = stream.dimensions.filter((a) => a !== removeId);
      } else if (stream.timeDefinition.timeColumnIdx === removeId) {
        updateSchema = true;
        mod.timeDefinition = {...stream.timeDefinition, timeColumnIdx: null};
      }
      mod.uiState = {
        ...stream.uiState,
        unAssignedColumns: [...stream.uiState.unAssignedColumns, removeId],
      };
      if (updateSchema) {
        mod.schema = {
          columns: stream.schema.columns.filter((a) => {
            if (a.sourceColumn) {
              return parseInt(a.sourceColumn, 10) !== removeId;
            }
            return !getSourceIdsArr([], a).includes(removeId.toString());
          }),
          sourceColumns: stream.schema.sourceColumns.filter((a) => parseInt(a.id, 10) !== removeId),
        };
      }
      return redWrapper(mod);
    }

    case actions.setFileStreamDiametricsTimeDefinition.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      if (payload.timeZone) {
        return {
          ...redWrapper({
            ...stream,
            ...payload,
            timeDefinition: {...stream.timeDefinition, ...payload},
          }),
        };
      }
      return {
        ...redWrapper({
          ...stream,
          timeDefinition: {...stream.timeDefinition, ...payload},
        }),
      };
    }

    case actions.setFileStreamDiametricsChange.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      const streamModifications = {
        uiState: {...stream.uiState},
      };

      const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
      };

      if (payload.source.droppableId === payload.destination.droppableId) {
        switch (payload.destination.droppableId) {
          case 'dmAllColumns': {
            streamModifications.uiState.unAssignedColumns = reorder(
              stream.uiState.unAssignedColumns,
              payload.source.index,
              payload.destination.index,
            );
            break;
          }
          case 'dmMetrics': {
            streamModifications.metrics = reorder(stream.metrics, payload.source.index, payload.destination.index);
            break;
          }
          case 'dmDimensions': {
            streamModifications.dimensions = reorder(
              stream.dimensions,
              payload.source.index,
              payload.destination.index,
            );
            break;
          }
          case 'dmDate': {
            break;
          }
          default:
            return state;
        }
      } else {
        const draggableId = payload.draggableId.startsWith('id_')
          ? +payload.draggableId.substr(3)
          : payload.draggableId;
        const {fileSchema} = stream.uiState.analysisResult;
        switch (payload.destination.droppableId) {
          case 'dmAllColumns': {
            streamModifications.uiState.unAssignedColumns = [...stream.uiState.unAssignedColumns];
            streamModifications.uiState.unAssignedColumns.splice(payload.destination.index, 0, draggableId);
            break;
          }
          case 'dmMetrics': {
            streamModifications.metrics = [...stream.metrics];
            streamModifications.metrics.splice(payload.destination.index, 0, draggableId);
            break;
          }
          case 'dmDimensions': {
            streamModifications.dimensions = [...stream.dimensions];
            streamModifications.dimensions.splice(payload.destination.index, 0, draggableId);
            break;
          }
          case 'dmDate': {
            if (parseInt(stream.timeDefinition.timeColumnIdx, 10) >= 0) {
              streamModifications.uiState.unAssignedColumns = [...stream.uiState.unAssignedColumns];
              streamModifications.uiState.unAssignedColumns.splice(0, 0, stream.timeDefinition.timeColumnIdx);
            }
            const index = fileSchema.findIndex((a) => a.index === draggableId);
            streamModifications.timeDefinition = fileSchema[index].timeDefinition
              ? {...fileSchema[index].timeDefinition}
              : {
                  ...stream.timeDefinition,
                  timeColumnIdx: index,
                };
            break;
          }
          default:
            return state;
        }

        switch (payload.source.droppableId) {
          case 'dmAllColumns': {
            streamModifications.uiState.unAssignedColumns = [
              ...(streamModifications.uiState.unAssignedColumns || stream.uiState.unAssignedColumns),
            ];
            streamModifications.uiState.unAssignedColumns.splice(
              streamModifications.uiState.unAssignedColumns.findIndex((a) => a === draggableId),
              1,
            );
            break;
          }
          case 'dmMetrics': {
            streamModifications.metrics = [...stream.metrics];
            streamModifications.metrics.splice(streamModifications.metrics.findIndex((a) => a === draggableId), 1);
            break;
          }
          case 'dmDimensions': {
            streamModifications.dimensions = [...stream.dimensions];
            streamModifications.dimensions.splice(
              streamModifications.dimensions.findIndex((a) => a === draggableId),
              1,
            );
            break;
          }
          case 'dmDate': {
            streamModifications.timeDefinition = {
              ...stream.timeDefinition,
              ...streamModifications.timeDefinition,
              timeColumnIdx: null,
            };
            break;
          }
          default:
            return state;
        }
      }
      return redWrapper(streamModifications);
    }
    default:
      return state;
  }
});

export default fileDiametricsDataStreamReducer;
