// @flow
import React, {PureComponent} from 'react';
import {TYPE} from 'alerts.console/services/alertsConsoleService';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import connect from 'react-redux/es/connect/connect';
import {getChangedTypes} from 'alerts.console/store/filterSelector';

type PropTypes = {
  setQueryParams: Function,
  changedTypes: Object,
};

@connect(
  (state) => ({
    changedTypes: getChangedTypes(state),
  }),
  {},
)
export default class TypeFilter extends PureComponent {
  props: PropTypes;

  onChange = (values) => {
    const {setQueryParams} = this.props;
    const currentType = values.length ? values.map((i) => i.value).join(',') : undefined;

    setQueryParams({types: currentType});
  };

  render() {
    return (
      <SelectAndt
        automationId="alertTypeFilter"
        type={TYPE_NEW_MULTI_NO_SEARCH}
        theme={THEME_HIGHLIGHTED}
        onChange={this.onChange}
        options={TYPE}
        value={this.props.changedTypes}
        isMulti
        placeholder="All"
        optionHeight={40}
        menuWidth={155}
        isClearable={false}
        closeMenuOnSelect={false}
        noAutoReorder
        minMenuHeight={100}
      />
    );
  }
}
