import React from 'react';
import {success} from 'common/utils/notifications/notificationsService';
import {fetchChannels, putChannel} from 'alerts.channels/store/actions';
import useAsyncAction from 'common/utils/useAsyncAction';
import {getActiveChannels, getFetchChannels, getPutChannel} from 'alerts.channels/store/selectors';
import {keyBy} from 'lodash';
import channelTypes from 'channels/constants/channelTypes';
import AsyncButton from 'common/componentsV2/AsyncButton';
import {Backdrop, makeStyles, Modal} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import * as amSelectors from 'alerts.management/store/selectors';

const useStyles = makeStyles(({palette}) => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    height: 318,
    backgroundColor: palette.white[500],
    borderRadius: 7,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.3)',
    margin: 'auto',
    padding: '16px 24px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: '12px',
  },
  text: {
    fontSize: '14px',
    marginBottom: '40px',
  },
  logo: {
    width: '20px',
    height: '20px',
    marginRight: '6px',
  },
}));

const channelsByValue = keyBy(channelTypes, 'value');

const DeleteChannel = ({match, onClose}: {match: Object, onClose: Function}) => {
  const {channelId} = match.params;
  const channelsData = useSelector(getActiveChannels);
  const classes = useStyles();
  const dispatch = useDispatch();
  const alertConfigurations = useSelector(amSelectors.getAlertConfigurations);
  const {isLoading: isFetchLoading} = useSelector(getFetchChannels);
  const {isLoading: isPutLoading} = useSelector(getPutChannel);
  const putChannelAsync = useAsyncAction(putChannel, getPutChannel);
  const fetchChannelsAsync = useAsyncAction(fetchChannels, getFetchChannels);

  const channel = channelsData.find((item) => item.id === channelId);
  if (!channel) {
    return null;
  }
  const alerts = alertConfigurations.items.filter((alert) => alert.data.channels.includes(channel.id));
  const channelType = channel.channelMeta.id;
  const onConfirm = () => {
    putChannelAsync({channelType, channelId, body: {...channel, state: 'inactive', type: undefined}})
      .then(() => fetchChannelsAsync({refresh: true}))
      .then(() => {
        dispatch(
          success({
            settings: {
              autoDismiss: 2.5,
            },
            title: 'Channel Deleted',
            description: `${channelType} Channel: ${channel.name} was deleted.`,
          }),
        );
        onClose();
      });
  };

  return (
    <Modal disableEnforceFocus open BackdropComponent={Backdrop} onClose={onClose}>
      <div className={classes.wrapper}>
        <React.Fragment>
          <div className={classes.title}>
            {`Delete ${channelsByValue[channelType].label} Channel: ${channel.name}?`}
          </div>
          <div className="mb_1">
            <span className="fontWeight_500">Deleting a Channel is permanent and cannot be undone,</span> This channel
            is the recipient of{' '}
            <span className="fontWeight_500">
              {alerts.length} {alerts.length === 1 ? 'alert' : 'alerts'}
            </span>
          </div>
          <div className="flexGrow_1">Alerts won’t be affected</div>

          <div className="display_flex justifyContent_space-between alignItems_center alignSelf_flex-end">
            <div className="mr_1-5">
              <AsyncButton
                automationId="cancelDeleteChannel"
                text="Cancel"
                colorSchema="gray.300"
                isLoading={false}
                onClick={onClose}
              />
            </div>
            <AsyncButton
              colorSchema="red.500"
              isLoading={isPutLoading || isFetchLoading}
              automationId="deleteChannel"
              text="Delete"
              onClick={onConfirm}
            />
          </div>
        </React.Fragment>
      </div>
    </Modal>
  );
};

export default DeleteChannel;
