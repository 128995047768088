import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
  label: {
    color: '#415058',
    lineHeight: 1,
    fontSize: (props) => (props.isSubtitle ? 14 : 16),
  },
  optional: {
    lineHeight: 1,
    fontSize: 14,
    color: '#3d4c59',
    fontStyle: 'italic',
    opacity: 0.5,
    paddingRight: '2px',
  },
});

export default ({children, isOptional, isSubtitle}: {isOptional: Boolean, isSubtitle: Boolean, children: Object}) => {
  const classes = useStyles({isSubtitle});
  return (
    <Grid container justify="space-between">
      <Grid item className={classes.label}>
        {children}
      </Grid>
      {isOptional && (
        <Grid item className={classes.optional}>
          Optional
        </Grid>
      )}
    </Grid>
  );
};
