// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {fetchUsers, fetchGroups} from 'admin.users/store/actions';
import SourcesList from 'bc/components/dataManager/SourcesList';
import StreamsList from 'bc/components/dataManager/StreamsList';
import SearchBox from 'common/components/SearchBox';
import {
  setDataManagerFilterTextInput as setDataManagerFilterTextInputAction,
  setDataManagerStreamPreviewId as setDataManagerStreamPreviewIdAction,
} from 'bc/store/actions';

import './DataManager.module.scss';
import {Modal} from 'react-bootstrap';
import Button, {COLORS} from 'common/componentsV2/Button';
import * as profileSelectors from 'profile/store/selectors';
import {getIsFirstTimeVisit, getDataStreamsItems} from '../store/selectors';

type PropTypes = {
  filterTextInput: String,
  setDataManagerFilterTextInput: Function,
  setDataManagerStreamPreviewId: Function,
  location: Object,
  dataStream: Number,
  isFirstTimeVisit: Boolean,
  featureOnBoarding: Boolean,
  ownerOrganization: String,
  fetchUsers: Function,
  fetchGroups: Function,
};

@connect(
  (state) => ({
    isFirstTimeVisit: getIsFirstTimeVisit(state),
    featureOnBoarding: profileSelectors.getFeatureOnBoarding(state),
    filterTextInput: state.bc.dataManager.filterTextInput,
    dataStream: getDataStreamsItems(state).length,
    ownerOrganization: profileSelectors.getMeOwnerOrganization(state),
    isImpactEnabled: profileSelectors.getImpactEnabled(state),
  }),
  {
    setDataManagerFilterTextInput: setDataManagerFilterTextInputAction,
    setDataManagerStreamPreviewId: setDataManagerStreamPreviewIdAction,
    fetchUsers,
    fetchGroups,
  },
)
export default class DataManager extends PureComponent {
  props: PropTypes;

  constructor(props) {
    super(props);
    this.state = {
      firstTimeFlag: true,
      isFirstTimeModalOpen: false,
    };
  }

  componentDidMount() {
    const {location} = this.props;
    let streamPreviewId = null;

    if (location.search) {
      const queries = this.props.location.search.substring(1).split('&');

      queries.some((query) => {
        const q = query.split('=');
        streamPreviewId = q[0] === 'streamPreviewId' ? q[1] : null;
        return streamPreviewId;
      });
    }
    this.props.fetchUsers();
    this.props.fetchGroups({ownerOrganization: this.props.ownerOrganization});
    this.props.setDataManagerStreamPreviewId(streamPreviewId);
  }

  componentDidUpdate() {
    const {isFirstTimeVisit, dataStream, featureOnBoarding} = this.props;
    const {firstTimeFlag} = this.state;

    if (firstTimeFlag && isFirstTimeVisit && (dataStream && dataStream > 0) && featureOnBoarding) {
      // TODO: Check what can be done instead
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({isFirstTimeModalOpen: true, firstTimeFlag: false});
    }
  }

  ftueModalClicked = () => {
    this.setState((prevState) => ({
      isFirstTimeModalOpen: !prevState.isFirstTimeModalOpen,
      firstTimeFlag: false,
    }));
  };

  render() {
    const {setDataManagerFilterTextInput, filterTextInput} = this.props;

    return (
      <div styleName="root">
        <div styleName="top">
          <div className="andt-page-header">Streams</div>
          {/* <Impacts /> */}
          <div>
            <SearchBox filter={filterTextInput} isAutoStretch onFilter={setDataManagerFilterTextInput} />
          </div>
        </div>
        <div styleName="master-detail">
          <div styleName="left">
            <SourcesList />
          </div>
          <div styleName="separator" />
          <div styleName="right">
            <StreamsList />
          </div>
        </div>
        <Modal dialogClassName="first-time-modal" show={this.state.isFirstTimeModalOpen}>
          <Modal.Header bsClass="bc-modal-header">
            <Modal.Title>Data Streams</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Use popular data streaming services to get your information to Anodot.</p>
            <div className="ftue-img-wrapper">
              <div className="ftue-img" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button colorSchema={COLORS.BLUE_500} text="Got It" onClick={this.ftueModalClicked} />
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
