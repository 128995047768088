import {useSelector} from 'react-redux';
import {get, isEmpty, values} from 'lodash';
import {useMemo, useRef} from 'react';
import * as metricsSelectors from 'metrics/store/selectors';
import {getSelectedBranch} from 'metrics/services/metricsService';
import useDeepCompareMemo from 'common/utils/useDeepCompareMemo';
import getCompositeObject from 'common/utils/metrics/getCompositeObject';
import useCompositeExecute from 'dashboards/hooks/useCompositeExecute';

const EMPTY_ARRAY = [];

const useExpressionBuilder = ({
  dateRange,
  timeScale,
  multiPreviewOptions,
  isShowComposites,
  cacheOnlyLast,
  scalarTransforms,
  chartId,
}) => {
  const isTreeEmpty = useSelector(metricsSelectors.getIsTreeEmpty);
  const areTreesEmpty = useSelector(metricsSelectors.getAreTreesEmpty);
  const expressionTrees = useSelector(metricsSelectors.getExpressionTrees);
  const lastProvidedResolution = useRef();
  const isAllSelected = useSelector(metricsSelectors.getAllExpressionsSelected);
  const selectedTreeId = useSelector(metricsSelectors.getSelectedExpressionId);

  // TODO: refactor and make 1 selector to prepare data
  const selectedTree = expressionTrees.find((et) => et.id === selectedTreeId);
  const allTree = selectedTree.expressionTree;
  const selectedBranch = useSelector(metricsSelectors.getSelectedBranch);
  const selectedBranchId = get(selectedBranch, 'root.id');
  const selectedBranchFromSelectedTree = getSelectedBranch(allTree, selectedBranchId);

  const expressionTreesComposites = useDeepCompareMemo(() => {
    return (isAllSelected
      ? Object.values(expressionTrees)
      : [
          {
            id: selectedTreeId,
            expressionTree: selectedBranchFromSelectedTree,
          },
        ]
    ).reduce((acc, expressionTreeItem) => {
      return {
        ...acc,
        [isAllSelected ? expressionTreeItem.id : selectedBranchId]: getCompositeObject(
          {
            dateRange,
            timeScale,
            ...multiPreviewOptions['*'],
            ...multiPreviewOptions[expressionTreeItem.id],
          },
          expressionTreeItem.expressionTree,
          isShowComposites,
          scalarTransforms,
        ),
      };
    }, {});
  }, [
    ...expressionTrees.map((item) => item.expressionTree),
    multiPreviewOptions,
    selectedBranchId,
    isAllSelected,
    dateRange,
    timeScale,
    isShowComposites,
    scalarTransforms,
  ]);

  const {compositeDataMap, compositeErrorsMap, isCompositeDataLoading} = useCompositeExecute({
    expressionTrees: expressionTreesComposites,
    dateRange,
    filters: EMPTY_ARRAY,
    chartId: chartId || 'expressionTreesPreview',
    resolution: timeScale,
    isVisible: true,
    cacheOnlyLast,
  });

  const visibleTrees = useMemo(
    () =>
      expressionTrees
        .map((et) => ({id: et.id, invisible: et.invisible}))
        .filter((e) => !e.invisible)
        .map((e) => e.id),
    [expressionTrees],
  );

  const metrics = useMemo(() => {
    const list = (isAllSelected
      ? Object.entries(compositeDataMap).flatMap(([treeId, item]) =>
          (item.metrics || EMPTY_ARRAY).map((metric) => ({...metric, treeId, compositeObject: item.compositeObject})),
        )
      : get(compositeDataMap[selectedBranchId], 'metrics', []).map((metric) => ({
          ...metric,
          treeId: selectedTreeId,
          compositeObject: compositeDataMap[selectedBranchId].compositeObject,
        }))
    ).filter((metric) => visibleTrees.some((it) => it === metric.treeId));
    let ordered = [];
    const expressionTreesIds = expressionTrees.map((et) => et.id);
    expressionTreesIds.forEach((id) => {
      const selectedMetrics = list.filter((metric) => metric.treeId === id);
      if (selectedMetrics && selectedMetrics.length) {
        ordered = ordered.concat(selectedMetrics);
      }
    });
    return ordered;
  }, [compositeDataMap, JSON.stringify(visibleTrees), isAllSelected, selectedTreeId, selectedBranchId]);

  const providedResolution = useMemo(
    () =>
      isEmpty(compositeDataMap) ||
      Object.values(compositeDataMap).some((item, index, arr) => item.resolution !== arr[0].resolution)
        ? ''
        : Object.values(compositeDataMap)[0].resolution,
    [compositeDataMap],
  );

  if (providedResolution && timeScale === '') {
    lastProvidedResolution.current = providedResolution;
  }

  const totalAmount = Object.values(compositeDataMap).reduce((sum, cData) => sum + (cData.size || 0), 0);
  const noValidTrees = Object.values(compositeErrorsMap).every((cData) =>
    get(values(get(cData, 'failures')), '[0][0].message', ''),
  );

  let validationMessage = '';
  let validationId = 0;
  // Store first occurred validation error
  Object.values(compositeErrorsMap).forEach((cData) => {
    validationMessage = validationMessage || get(values(get(cData, 'failures')), '[0][0].message');
    validationId = validationId || get(values(get(cData, 'failures')), '[0][0].id');
  });

  // TODO: use more advanced mechanism to understand that user actually using zoom
  const isZoomLoading = isCompositeDataLoading && dateRange.constRange === 'c';
  const isLoading = isCompositeDataLoading && !isZoomLoading;

  const needsQuickActions = (isTreeEmpty && (!isAllSelected || expressionTrees.length === 1)) || areTreesEmpty;
  const isMultiExpressions = isAllSelected && expressionTrees.length > 1;

  return {
    isAllSelected,
    metrics,
    totalAmount,
    noValidTrees,
    isLoading,
    isCompositeDataLoading,
    needsQuickActions,
    validationId,
    validationMessage,
    isMultiExpressions,
    isZoomLoading,
    providedResolution: lastProvidedResolution.current,
  };
};

export default useExpressionBuilder;
