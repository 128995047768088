import React from 'react';
import PropTypes from 'prop-types';
import './MinimizingModal.module.scss';

const MinimizingModal = ({
  children,
  isOpen,
  isMinimize,
  onCloseBtnClick,
  onMinimize,
  backgroundColor,
  titleWhenMinimize,
}: PropTypes) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div>
      <div styleName={`bg ${isMinimize ? 'hide' : 'show'}`} />
      <div styleName={`container ${isMinimize ? 'hide' : 'show'}`}>
        <div styleName={`action-buttons ${isMinimize ? 'small' : ''}`}>
          {!isMinimize && (
            <div onClick={onMinimize} styleName="btn" style={{marginRight: 8}}>
              <i className="icon icn-Minimize16" style={{fontSize: 16}} />
            </div>
          )}
          <div onClick={onCloseBtnClick} styleName="btn">
            <i className="icon icn-general16-closea" style={{fontSize: 16}} />
          </div>
        </div>

        <div
          styleName={`wrapper ${isMinimize ? 'hide' : 'show'}`}
          style={{backgroundColor}}
          onClick={isMinimize ? onMinimize : null}
        >
          {isMinimize && titleWhenMinimize && <div styleName="title-when-minimize">{titleWhenMinimize}</div>}
          <div styleName={`children ${isMinimize ? 'hide' : 'show'}`}>{children && children}</div>
        </div>
      </div>
    </div>
  );
};

MinimizingModal.defaultProps = {
  backgroundColor: '#ffffff',
  titleWhenMinimize: <div />,
};

MinimizingModal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isMinimize: PropTypes.bool.isRequired,
  onCloseBtnClick: PropTypes.func.isRequired,
  onMinimize: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  titleWhenMinimize: PropTypes.node,
};

export default MinimizingModal;
