import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {success} from 'common/utils/notifications/notificationsService';
import * as profileActions from 'profile/store/actions';
import {updateUser as updateUserAngular} from 'common/utils/angularServices';
import {setRoute} from 'common/store/actions';
import * as api from 'admin.users/services/api';
import moment from 'moment';
import * as actions from '../actions';
import {getFilteredUserSectionUsersList} from '../filterSelector';
import {getSelectedUsersCheckbox, getUsersUpdatedAt} from '../selectors';

// time in seconds
const DEBOUNCE_TIME = 300;

const fetchUserFilterCall = (action, getState) => {
  if (!localStorage.getItem('andt-token')) {
    return false;
  }
  const updateAt = getUsersUpdatedAt(getState());
  const range =
    moment()
      .unix()
      .valueOf() - moment(updateAt).valueOf();
  return range > DEBOUNCE_TIME || range === 0;
};

const impersonate = makeAsyncEpic(actions.impersonate, api.impersonate);
const unimpersonate = makeAsyncEpic(actions.unimpersonate, api.unimpersonate);

const createUsers = makeAsyncEpic(actions.createUsers, api.createUsers);

const usersEpic = makeAsyncEpic(actions.fetchUsers, api.fetchUsers, fetchUserFilterCall);
const fetchUsersEnforced = makeAsyncEpic(actions.fetchUsersEnforced, api.fetchUsers);
const fetchUser = makeAsyncEpic(actions.fetchUser, api.fetchUser);
const updateUser = makeAsyncEpic(actions.updateUser, api.updateUser);
const deleteUser = makeAsyncEpic(actions.deleteUser, api.deleteUser);
const updateUserStatus = makeAsyncEpic(actions.updateUserStatus, api.updateUserStatus);
const resetPasswordRequest = makeAsyncEpic(actions.resetPasswordRequest, api.resetPasswordRequest);
const fetchOwnerDashboardsCount = makeAsyncEpic(actions.fetchOwnerDashboardsCount, api.fetchOwnerDashboardsCount);
const fetchOwnerAnoboardsCount = makeAsyncEpic(actions.fetchOwnerAnoboardsCount, api.fetchOwnerAnoboardsCount);
const fetchOwnerAlertsCount = makeAsyncEpic(actions.fetchOwnerAlertsCount, api.fetchOwnerAlertsCount);
const fetchOwnerChannelsCount = makeAsyncEpic(actions.fetchOwnerChannelsCount, api.fetchOwnerChannelsCount);
const fetchOwnerCompositesCount = makeAsyncEpic(actions.fetchOwnerCompositesCount, api.fetchOwnerCompositesCount);
const fetchOwnerRefreshTokensCount = makeAsyncEpic(
  actions.fetchOwnerRefreshTokensCount,
  api.fetchOwnerRefreshTokensCount,
);
const fetchOwnerStreamsCount = makeAsyncEpic(actions.fetchOwnerStreamsCount, api.fetchOwnerStreamsCount);
const editUsersBulkApi = makeAsyncEpic(actions.editUsersBulkApi, api.editUsersBulkApi);
const deleteUsersBulkApi = makeAsyncEpic(actions.deleteUsersBulkApi, api.deleteUsersBulkApi);

const allUsersCheckboxClick = (action$, {getState}) =>
  action$.ofType(actions.allUsersCheckboxClick.TYPE).flatMap(({payload}) => {
    if (!payload) {
      return [actions.setUsersCheckboxAll([])];
    }
    const list = getFilteredUserSectionUsersList(getState());
    const idsList = list.map((item) => item.id);
    return [actions.setUsersCheckboxAll(idsList)];
  });

const editUsersBulk = (action$, {getState}) =>
  action$.ofType(actions.editUsersBulk.TYPE).flatMap(({payload}) => {
    const body = {
      userIds: getSelectedUsersCheckbox(getState()),
      action: payload.action,
    };

    switch (payload.action) {
      case 'groups':
        body.allGroups = payload.data.filter((item) => item.isSome && item.isAll).map((gr) => gr.value);
        body.someGroups = payload.data.filter((item) => item.isSome && !item.isAll).map((gr) => gr.value);
        break;
      case 'primaryGroup':
        body.primaryGroupId = payload.primaryGroup.id;
        break;
      default:
    }

    return [actions.editUsersBulkApi(body)];
  });

const deleteUsersBulkSuccess = (action$) =>
  action$.ofType(actions.deleteUsersBulkApi.success.TYPE).flatMap(({payload}) => {
    if (payload.success) {
      const notificationObj = {
        title: 'Users Deleted Successfully',
        autoDismiss: 5,
        dismissible: true,
        settings: {
          canClose: true,
          uid: 'bulkDeleteOperation',
        },
      };
      return [success(notificationObj)];
    }
    return [];
  });

const fetchUserOnUserModalToggleOn = (action$) =>
  action$.ofType(actions.toggleUserEditModal.TYPE).flatMap(({payload}) => {
    if (payload.userId) {
      return [actions.fetchUser(payload.userId), actions.setUserEditModalKeyVal({orgName: payload.orgName})];
    }
    return [];
  });

const fetchUserSuccess = (action$) =>
  action$.ofType(actions.fetchUser.success.TYPE).flatMap(({payload}) => {
    if (payload) {
      return [
        actions.setUserEditModalKeyVal({
          ...payload,
          originalRole: payload.roles[0],
        }),
      ];
    }
    return [];
  });

const createUsersSuccess = (action$) =>
  action$.ofType(actions.createUsers.success.TYPE).flatMap(({payload}) => {
    if (payload && !payload.users && !payload.validationResult.passed) {
      return [actions.setNewUserModalKeyVal({isEmailExistOpen: true})];
    }
    return [actions.toggleNewUserModal({isOpen: false})];
  });

const getUserAssetsCount = (action$) =>
  action$.ofType(actions.getUserAssetsCount.TYPE).flatMap(({payload}) => {
    if (payload) {
      return [
        actions.fetchOwnerDashboardsCount(payload),
        actions.fetchOwnerAnoboardsCount(payload),
        actions.fetchOwnerAlertsCount(payload),
        actions.fetchOwnerChannelsCount(payload),
        actions.fetchOwnerCompositesCount(payload),
        actions.fetchOwnerRefreshTokensCount(payload),
      ];
    }
    return [];
  });

const impersonateSuccess = (action$, {dispatch}) =>
  action$.ofType(actions.impersonate.success.TYPE).flatMap(({payload}) => {
    if (payload) {
      localStorage.setItem('andt-token', payload.token);
      localStorage.setItem('andt-token-ttl', JSON.parse(atob(payload.token.split('.')[1])).exp);
      localStorage.setItem('andt-token-iat', JSON.parse(atob(payload.token.split('.')[1])).iat);
      updateUserAngular(payload.user);
      dispatch(profileActions.setMe(payload.user));
      return [actions.fetchUsersEnforced(), setRoute('/alerts-console')];
    }
    return [];
  });

const unimpersonateSuccess = (action$, {dispatch}) =>
  action$.ofType(actions.unimpersonate.success.TYPE).flatMap(({payload}) => {
    if (payload) {
      localStorage.setItem('andt-token', payload.token);
      localStorage.setItem('andt-token-ttl', JSON.parse(atob(payload.token.split('.')[1])).exp);
      localStorage.setItem('andt-token-iat', JSON.parse(atob(payload.token.split('.')[1])).iat);
      updateUserAngular(payload.user);
      dispatch(profileActions.setMe(payload.user));
      return [setRoute('/admin/users-groups')];
    }
    return [];
  });

const usersEpics = combineEpics(
  impersonate,
  unimpersonate,
  impersonateSuccess,
  unimpersonateSuccess,
  createUsers,
  usersEpic,
  fetchUsersEnforced,
  fetchUser,
  updateUser,
  deleteUser,
  updateUserStatus,
  resetPasswordRequest,
  fetchUserOnUserModalToggleOn,
  fetchUserSuccess,
  createUsersSuccess,
  getUserAssetsCount,
  fetchOwnerDashboardsCount,
  fetchOwnerAnoboardsCount,
  fetchOwnerAlertsCount,
  fetchOwnerChannelsCount,
  fetchOwnerCompositesCount,
  fetchOwnerRefreshTokensCount,
  fetchOwnerStreamsCount,
  allUsersCheckboxClick,
  editUsersBulkApi,
  editUsersBulk,
  deleteUsersBulkApi,
  deleteUsersBulkSuccess,
);

export default usersEpics;
