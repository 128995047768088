export const generalErrorMsg = {
  title: 'General Error',
  description: 'Sorry, an error occurred, please refresh or try again.',
  settings: {
    autoDismiss: 5,
  },
};

export const timeoutErrorMsg = {
  title: 'Timeout Error',
  description: 'Request timeout error occurred.',
  settings: {
    autoDismiss: 5,
  },
};

export const clipboardCopied = {
  description: 'Text copied to clipboard!',
  settings: {
    autoDismiss: 2.5,
  },
};
