// @flow
import React, {PureComponent} from 'react';
import connect from 'react-redux/es/connect/connect';
import Gauge from 'common/componentsV2/gauges/Gauge';

import {fetchDataStreams as fetchDataStreamsAction} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';

type PropTypes = {
  selectedItem: Number,
  index: Number,
  description: String,
  // Connect
  value: Number,
  isLoading: Boolean,
  fetchDataStreams: Function,
};

@connect(
  (state) => ({
    value: selectors.getActiveStreamsTotal(state),
    isLoading: selectors.getIsDataStreamsLoading(state),
  }),
  {
    fetchDataStreams: fetchDataStreamsAction,
  },
)
export default class StreamsGauge extends PureComponent {
  props: PropTypes;

  fetchData = () => {
    const {value, fetchDataStreams} = this.props;
    if (value === null) {
      fetchDataStreams();
    }
  };

  render() {
    const {value, isLoading, description, index, selectedItem} = this.props;
    return (
      <Gauge
        description={description}
        value={value}
        isLoading={isLoading}
        selectedItem={selectedItem}
        index={index}
        fetchData={this.fetchData}
      />
    );
  }
}
