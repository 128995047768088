import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import {palette} from 'app/styles/theme';
import {TypographyBox} from 'common/componentsV2/boxTools';

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: palette.teal['500'],
    display: 'flex',
    padding: '4px 8px',
    borderRadius: 16,
    marginRight: 8,
    marginBottom: 4,
  },
  key: {
    maxWidth: 135,
    color: palette.white['500'],
    padding: '2px 4px',
  },
  value: {
    maxWidth: 205,
    backgroundColor: palette.teal['400'],
    color: palette.gray['500'],
    padding: '2px 6px',
    borderRadius: 16,
  },

  closeIcon: {
    position: 'relative',
    top: 0,
    left: 2,
    fontSize: 16,
    opacity: '0.7',
    cursor: 'pointer',
    color: palette.teal['400'],
    '&:hover': {
      opacity: '1',
    },
  },
}));

const FilterChipItem = ({dimension, onSelect}: PropTypes) => {
  const classes = useStyles();

  const setFilter = useCallback(() => {
    onSelect(dimension);
  }, [dimension]);

  return (
    <Box className={classes.wrapper}>
      <TypographyBox className={classes.key} variant="subtitle2" isEllipsis>
        {dimension.key}
      </TypographyBox>
      <TypographyBox className={classes.value} variant="subtitle2" isEllipsis>
        {dimension.value}
      </TypographyBox>
      <i className={`icon icn-general16-closea ${classes.closeIcon}`} onClick={setFilter} />
    </Box>
  );
};

FilterChipItem.propTypes = {
  dimension: PropTypes.objectOf(PropTypes.any).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default memo(FilterChipItem);
