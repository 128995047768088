// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import * as commonSelectors from 'profile/store/selectors';
import {
  setSelectedEditor as setSelectedEditorAction,
  setSelectedStreamKeyVal as setSelectedStreamKeyValAction,
} from 'bc/store/actions';
import {pollingIntervals, isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import * as dateRangeService from 'common/utils/dateRangeService';

import PanelHeader from '../PanelHeader';
import './Scheduler.module.scss';

type PropTypes = {
  pollingInterval: string,
  pollingResolution: string,
  historicalDateRange: string,
  timeZoneName: string,
  delayMinutes: number,
  maxGoldenDelayMinutes: number,
  type: string,
  setSelectedEditor: Function,
  setSelectedStreamKeyVal: Function,
  dataStream: boolean,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    pollingInterval: selectors.getSelectedDataStream(state).pollingInterval,
    pollingResolution: selectors.getSelectedDataStream(state).pollingResolution,
    historicalDateRange: selectors.getSelectedDataStream(state).historicalDateRange,
    delayMinutes: selectors.getSelectedDataStream(state).delayMinutes,
    maxGoldenDelayMinutes: selectors.getSelectedDataStream(state).maxGoldenDelayMinutes,
    timeZoneName: commonSelectors.getTimeZoneName(state),
    type: selectors.getSelectedDataStream(state).type,
  }),
  {
    setSelectedEditor: setSelectedEditorAction,
    setSelectedStreamKeyVal: setSelectedStreamKeyValAction,
  },
)
export default class Scheduler extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {setSelectedStreamKeyVal, dataStream, delayMinutes, maxGoldenDelayMinutes} = this.props;
    if (dataStream.state) {
      if (delayMinutes === undefined && maxGoldenDelayMinutes === undefined) {
        setSelectedStreamKeyVal({
          delayMinutes: 120,
          maxGoldenDelayMinutes: 120,
        });
      }
    }
  }

  getPullingInterval = (streamType) => {
    if (streamType === 'google_analytics') {
      return null;
    }
    const {pollingInterval} = this.props;
    return (
      <div>
        <span styleName="title">Polling interval</span>
        <span styleName="data">{` ${pollingInterval}`}</span>
      </div>
    );
  };

  getPollingResolution = (streamType, pollingResolution, pollingInterval) => {
    if (streamType === 'google_analytics' && !pollingResolution) {
      return <span styleName="data">{pollingIntervals[pollingInterval].label}</span>;
    }
    return <span styleName="data">{pollingResolution.charAt(0).toUpperCase() + pollingResolution.slice(1)}</span>;
  };

  render() {
    const {
      setSelectedEditor,
      dataStream,
      historicalDateRange,
      timeZoneName,
      pollingResolution,
      pollingInterval,
      type,
      delayMinutes,
      maxGoldenDelayMinutes,
    } = this.props;

    const isEditable = isStreamEditable(dataStream.state) || isStreamRunning(dataStream.state);

    return (
      <div className="shell-col">
        <PanelHeader
          title="Historical & Live time properties"
          onSelect={() => setSelectedEditor({type: 'scheduler', editorHeight: '570px', noDisplayFlex: true})}
          isEditable={isEditable}
        />
        <div>
          <span styleName="title">Historical time span</span>
          <span styleName="data">{` ${dateRangeService.getDate(historicalDateRange, timeZoneName).dateText}`}</span>
        </div>
        <div>
          <span styleName="title">Historical & Live time scale</span>
          {this.getPollingResolution(type, pollingResolution, pollingInterval)}
        </div>
        <div>
          <span styleName="title">Minimal Delay (Hours)</span>
          <span styleName="data">{delayMinutes / 60}</span>
        </div>
        <div>
          <span styleName="title">Maximal Delay (Hours)</span>
          <span styleName="data">
            {' '}
            {maxGoldenDelayMinutes === null ? 'Wait for Golden data' : maxGoldenDelayMinutes / 60}
          </span>
        </div>
        {this.getPullingInterval(type)}
      </div>
    );
  }
}
