// @flow
import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {get, keyBy} from 'lodash';
import {Route, useHistory, useRouteMatch} from 'react-router-dom';
import {Form, FormSpy} from 'react-final-form';
import {fetchDashboard, getAllFilters, setLastViewDashboard, updateDashboard} from 'dashboards/store/actions';
import {getProfileId} from 'profile/store/selectors';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import PageLayout from 'common/componentsV2/PageLayout';
import useCrosshairSync from 'common/utils/useCrosshairSync';
import {emptyExpressionTree} from 'metrics/store/reducers/expressionBuilderReducer';
import {tileActions, allowedUserTileActions, OPTION_TILES_VALUES} from 'dashboards/services/dashboardService';
import 'react-grid-layout/css/styles.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-resizable/css/styles.css';
import * as metricsActions from 'metrics/store/actions';
import {generateLayout, makeDeleteTilePayload, makeFiltersPayload} from 'dashboards/utils';
import useAsyncAction from 'common/utils/useAsyncAction';
import useDashboardSync, {getDateParams, getTimeScale} from 'dashboards/hooks/useDashboardSync';
import useDashboardState from 'dashboards/hooks/useDashboardState';
import './Dashboard.module.scss';
import './demo.css';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {useQueryParam, StringParam} from 'use-query-params';
import DashboardHeader from 'dashboards/components/DashboardHeader';
import DashboardBody from 'dashboards/components/DashboardBody';
import AddTileRouter from 'dashboards/components/routers/AddTileRouter';
import TileActionsRouter from 'dashboards/components/routers/TileActionsRouter';
import {getUsersData} from 'admin.users/store/selectors';
import {fetchUsers} from 'admin.users/store/actions';
import TileFullSizeContainer from 'dashboards/components/metricTiles/TileFullSizeContainer';
import {getIsDashboardOwnerSelectorFactory} from '../store/selectors';

const useStyles = makeStyles(() => ({
  root: {
    padding: '14px 12px 12px',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const editableStyle = {backgroundColor: '#ececf8'};
const defaultStyle = {backgroundColor: '#f9f9fd'};

const Dashboards = ({onReset}: {onReset: Function}) => {
  const history = useHistory();
  const {url: matchUrl, params} = useRouteMatch();
  const [actualLayout, setActualLayout] = useState();
  const gridRef = useRef();
  const tileDateRangeRef = useRef(null);
  const dispatch = useDispatch();
  const classes = useStyles();
  const dashboard = useDashboardState(params.id);
  const {data: dashboardData, isLoading} = dashboard;
  const getFullSizeMatch = useRouteMatch(`${matchUrl}/:tileId/:mode`);
  const isFullSizeTile = getFullSizeMatch ? getFullSizeMatch.params.mode === OPTION_TILES_VALUES.FULL_SIZE : null;
  const tileId = getFullSizeMatch && isFullSizeTile ? getFullSizeMatch.params.tileId : null;
  const {initialDashboardState, syncDashboard} = useDashboardSync(params.id, tileId);
  const userId = useSelector(getProfileId);
  const usersData = useSelector(getUsersData);
  const isOwnerUserSelector = useMemo(() => getIsDashboardOwnerSelectorFactory(params.id), [params.id]);
  const isOwnerUser = useSelector(isOwnerUserSelector);

  const computedTileOptions = useMemo(
    () =>
      tileActions.map((option) => {
        if (!isOwnerUser && !allowedUserTileActions.includes(option.value)) {
          return {...option, disabled: true};
        }
        return option;
      }),
    [isOwnerUser],
  );

  const [invitationId] = useQueryParam('invitationId', StringParam);
  useEffect(() => {
    if (!invitationId && !usersData.length) {
      dispatch(fetchUsers());
    }
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(fetchDashboard({id: params.id}));
      if (!invitationId) {
        dispatch(setLastViewDashboard({type: 'dashboardsV2', itemId: params.id, data: {}}, {userId}));
        dispatch(getAllFilters({expression: '*', size: 1250}));
      }
    }
  }, [userId, matchUrl]);

  const layouts = useMemo(() => ({lg: generateLayout(get(dashboardData, 'tiles', []))}), [
    dashboardData && dashboardData.tiles,
  ]);

  useEffect(() => {
    if (dashboardData) {
      // Sets actual layout state after compaction by library (important for subsequent adding tiles to correct place)
      setActualLayout(gridRef.current ? gridRef.current.state.layout : layouts.lg);
    }
  }, [layouts]);

  const onUpdateDashboard = useCallback(
    (values, formProps) => {
      if (Object.values(formProps.getState().modified).some((field) => field === true)) {
        syncDashboard(values);
      }
    },
    [syncDashboard],
  );

  const updateDashboardAsync = useAsyncAction(updateDashboard, dashboard);

  const resetExpression = useCallback(() => {
    dispatch(
      metricsActions.initExpressionTreeModel({
        expressionTree: emptyExpressionTree,
      }),
    );
  }, [dispatch]);

  const onLayoutChange = (layout) => {
    setActualLayout(layout);
  };

  const returnBack = useCallback(
    // eslint-disable-next-line no-shadow
    (matchUrl) => {
      const url = `${matchUrl}${history.location.search}`;
      resetExpression();
      history.replace(url);
    },
    [resetExpression],
  );

  const tiles = useMemo(() => keyBy(get(dashboardData, 'tiles', []), 'id'), [dashboardData]);

  const onConfirmDeleteTile = useCallback(
    (id) => {
      const deleteTilePayload = makeDeleteTilePayload(dashboardData, actualLayout, id);
      updateDashboardAsync(deleteTilePayload)
        .then(() => {
          returnBack(matchUrl);
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.log(error));
    },
    [dashboardData, actualLayout, returnBack],
  );

  const chartIds = useMemo(() => layouts.lg.map((l) => `chart_${l.i}`), [layouts.lg]);
  const mouseMoveHandler = useCrosshairSync(chartIds);

  const handleConfirmSave = (values, form) => {
    if (!invitationId && values) {
      const {filters, dateRange, timeScale} = values;
      const updTiles = actualLayout.map((options, i) => {
        const tile = dashboardData.tiles[i];
        return {
          ...tile,
          sizeX: options.w,
          sizeY: options.h,
          col: options.x,
          row: options.y,
        };
      });
      const settingsPayload = {
        dateRange: getDateParams(dateRange),
        selectorsFilter: filters ? {selectors: makeFiltersPayload(filters)} : {},
        timeScale: getTimeScale(timeScale),
      };
      updateDashboardAsync({...dashboardData, ...settingsPayload, tiles: updTiles}).then(() => {
        form.change('isEditableMode', false);
        if (history.location.pathname !== matchUrl) {
          returnBack(matchUrl);
        }
      });
    }
  };

  const handleClickSaveEdit = ({modified, form, values}) => {
    const {dateRange, filters, timeScale} = modified;
    if (dateRange || filters || timeScale) {
      history.push(`${matchUrl}/save`);
    } else {
      handleConfirmSave(values, form);
    }
  };

  const returnToDashboard = useCallback(() => returnBack(matchUrl), [matchUrl]);

  if (!initialDashboardState || !dashboardData || !actualLayout) {
    return (
      <div className="display_flex justifyContent_center alignItems_center flexGrow_1">
        <Spinner color="gray.500" size={SIZES.MEDIUM_50} />
      </div>
    );
  }

  if (!userId) {
    return null;
  }

  return (
    <PageLayout shouldFullWidth>
      <Form initialValues={initialDashboardState} onSubmit={onUpdateDashboard}>
        {({handleSubmit, values, form, modified}) => (
          <div className={classes.root} style={values.isEditableMode ? editableStyle : defaultStyle}>
            <FormSpy subscription={{values: true}} onChange={handleSubmit} />
            <AddTileRouter
              dashboardId={params.id}
              matchUrl={matchUrl}
              actualLayout={actualLayout}
              returnToDashboardPage={returnToDashboard}
              onConfirmSave={() => handleConfirmSave(values, form)}
              isLoading={isLoading}
            />
            <Route
              path={[`${matchUrl}/:tileId/${OPTION_TILES_VALUES.FULL_SIZE}`, `${matchUrl}/:tileId`]}
              render={({
                match: {
                  params: {tileId: id},
                  url: tileUrl,
                },
              }) => {
                return (
                  <TileActionsRouter
                    tileUrl={tileUrl}
                    returnToDashboardPage={() => returnBack(tileUrl)}
                    onConfirmDeleteTile={onConfirmDeleteTile}
                    isLoading={isLoading}
                    id={id}
                    tiles={tiles}
                    actualLayout={actualLayout}
                    dashboardId={params.id}
                    isOwnerUser={isOwnerUser}
                    dashboardData={dashboardData}
                  />
                );
              }}
            />
            <Route
              path={`${matchUrl}/:tileId/${OPTION_TILES_VALUES.FULL_SIZE}`}
              /* eslint-disable-next-line react/no-children-prop */
              children={({match}) => {
                if (match) {
                  return (
                    <TileFullSizeContainer
                      history={history}
                      invitationId={invitationId}
                      tileData={tiles[get(match, 'params.tileId')]}
                      dashboardData={dashboardData}
                      returnToDashboardPage={returnToDashboard}
                      matchUrl={match ? match.url : matchUrl}
                      handleSaveEdit={handleClickSaveEdit}
                      isEditableMode={values.isEditableMode}
                      isLoading={isLoading}
                      isOwnerUser={isOwnerUser}
                      computedTileOptions={computedTileOptions}
                      tileId={get(match, 'params.tileId')}
                    />
                  );
                }
                return (
                  <Fragment>
                    <DashboardHeader
                      history={history}
                      invitationId={invitationId}
                      dashboardData={dashboardData}
                      onReset={onReset}
                      tileDateRangeRef={tileDateRangeRef}
                      returnToDashboardPage={returnToDashboard}
                      matchUrl={match ? match.url : matchUrl}
                      handleSaveEdit={() => handleClickSaveEdit({modified, form, values})}
                      isLoading={isLoading}
                      isOwnerUser={isOwnerUser}
                      computedTileOptions={computedTileOptions}
                      tileId={get(match, 'params.tileId')}
                      values={values}
                    />
                    <DashboardBody
                      isOwnerUser={isOwnerUser}
                      tiles={tiles}
                      dashboardData={dashboardData}
                      invitationId={invitationId}
                      mouseMoveHandler={mouseMoveHandler}
                      onLayoutChange={onLayoutChange}
                      layouts={layouts}
                      gridRef={gridRef}
                    />
                  </Fragment>
                );
              }}
            />
          </div>
        )}
      </Form>
    </PageLayout>
  );
};

export default Dashboards;
