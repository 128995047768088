// @flow
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {subConditionTypes} from 'alerts.management/services/alertsService';
import {setSelectedAlertInfluencingMetricsSubConditionV2} from 'alerts.management/store/actions';
import Input from 'common/componentsV2/Input';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import './SubConditionsV2.module.scss';

const EMPTY_ARRAY = [];
const EMPTY_STRING = '';

const SUB_CONDITIONS = [
  {
    label: 'Greater than',
    type: subConditionTypes.above,
    upperBound: 'UPPER',
    defaultValue: [
      {
        type: subConditionTypes.above,
        upperBound: 'UPPER',
        maxValue: 100,
      },
    ],
  },
  {
    label: 'Greater than or equals to',
    type: subConditionTypes.above,
    upperBound: 'UPPER_EQUAL',
    defaultValue: [
      {
        type: subConditionTypes.above,
        upperBound: 'UPPER_EQUAL',
        maxValue: 100,
      },
    ],
  },
  {
    label: 'Less than',
    type: subConditionTypes.below,
    lowerBound: 'LOWER',
    defaultValue: [
      {
        type: subConditionTypes.below,
        lowerBound: 'LOWER',
        minValue: 100,
      },
    ],
  },
  {
    label: 'Less than or equals to',
    type: subConditionTypes.below,
    lowerBound: 'LOWER_EQUAL',
    defaultValue: [
      {
        type: subConditionTypes.below,
        lowerBound: 'LOWER_EQUAL',
        minValue: 100,
      },
    ],
  },
  {
    label: 'In the range of',
    type: subConditionTypes.between,
    upperBound: 'LOWER_EQUAL',
    lowerBound: 'UPPER_EQUAL',
    defaultValue: [
      {
        type: subConditionTypes.between,
        upperBound: 'LOWER_EQUAL',
        lowerBound: 'UPPER_EQUAL',
        maxValue: 2000,
        minValue: 100,
      },
    ],
  },
  {
    label: 'Not in the range of',
    type: 'notInRange',
    defaultValue: [
      {
        type: subConditionTypes.above,
        upperBound: 'UPPER_EQUAL',
        maxValue: 2000,
      },
      {
        type: subConditionTypes.below,
        lowerBound: 'LOWER_EQUAL',
        minValue: 100,
      },
    ],
  },
];

type PropTypes = {
  subConditions: Array,
  id: String,
};

const SubConditionsV2 = ({subConditions, id}: PropTypes) => {
  const dispatch = useDispatch();
  const [selectedSubCondition, setSelectedSubCondition] = useState(null);
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);

  const setSelectedValue = (subCondArr) => {
    const subConds = subCondArr || EMPTY_ARRAY;
    if (subConds.length === 2) {
      const subCondition1 = subConds[0];
      const subCondition2 = subConds[1];
      if (
        (subCondition1.type === subConditionTypes.above && subCondition2.type === subConditionTypes.below) ||
        (subCondition1.type === subConditionTypes.below && subCondition2.type === subConditionTypes.above)
      ) {
        setMinValue(subCondition1.minValue || subCondition2.minValue || null);
        setMaxValue(subCondition1.maxValue || subCondition2.maxValue || null);
        setSelectedSubCondition(SUB_CONDITIONS[5]);
      }
    } else if (subConds.length === 1) {
      const subC = subConds[0];
      const foundSubCond = SUB_CONDITIONS.find(
        (sc) => sc.type === subC.type && sc.upperBound === subC.upperBound && sc.lowerBound === subC.lowerBound,
      );
      setMinValue(foundSubCond ? subC.minValue : null);
      setMaxValue(foundSubCond ? subC.maxValue : null);
      setSelectedSubCondition(foundSubCond);
    } else {
      setSelectedSubCondition(null);
    }
  };

  useEffect(() => {
    setSelectedValue(subConditions);
  }, [subConditions]);

  const onChange = (item) => {
    dispatch(
      setSelectedAlertInfluencingMetricsSubConditionV2({
        id,
        subConditions: item.defaultValue,
      }),
    );
  };

  const onInputChange = (payload) => {
    const newVal = selectedSubCondition.defaultValue.map((dvc) => {
      const newDvc = {...dvc};
      if (payload.maxValue !== undefined && dvc.maxValue !== undefined) {
        newDvc.maxValue = payload.maxValue;
        if (dvc.minValue !== undefined && minValue !== null) {
          newDvc.minValue = minValue;
        }
      }
      if (payload.minValue !== undefined && dvc.minValue !== undefined) {
        newDvc.minValue = payload.minValue;
        if (dvc.maxValue !== undefined && maxValue !== null) {
          newDvc.maxValue = maxValue;
        }
      }
      return newDvc;
    });
    dispatch(
      setSelectedAlertInfluencingMetricsSubConditionV2({
        id,
        subConditions: newVal,
      }),
    );
  };

  const getSubConditionComponentsByType = (subCond) => {
    if (!subCond) {
      return null;
    }

    switch (subCond.type) {
      case subConditionTypes.above:
        return (
          <div className="display_flex mt_1">
            <Input
              styleName="regular-input"
              type="number"
              value={maxValue || EMPTY_STRING}
              onChange={(e) => onInputChange({maxValue: e.target.value})}
            />
          </div>
        );
      case subConditionTypes.below:
        return (
          <div className="mt_1 display_flex">
            <Input
              styleName="regular-input"
              type="number"
              value={minValue || EMPTY_STRING}
              onChange={(e) => onInputChange({minValue: e.target.value})}
            />
          </div>
        );
      case subConditionTypes.between:
      case 'notInRange':
        return (
          <div className="display_flex mt_1">
            <Input
              styleName="regular-input"
              type="number"
              value={minValue || EMPTY_STRING}
              onChange={(e) => onInputChange({minValue: e.target.value})}
            />
            <span styleName="connection">to</span>
            <Input
              styleName="regular-input"
              type="number"
              value={maxValue || EMPTY_STRING}
              onChange={(e) => onInputChange({maxValue: e.target.value})}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div styleName="root">
      <div className="display_flex alignItems_center">
        <div className="display_inline-block mr_1">Is</div>
        <SelectAndt
          id="subConditionFilter"
          automationId="subConditionFilter"
          extraClassName="alerts-dropdown-btn"
          options={SUB_CONDITIONS}
          optionHeight={40}
          menuWidth={250}
          type={TYPE_NEW_NO_SEARCH}
          theme={THEME_NOT_HIGHLIGHTED}
          onChange={onChange}
          getOptionValue={(val) => val.label}
          value={selectedSubCondition}
          placeholder="Not Correct"
        />
      </div>
      {getSubConditionComponentsByType(selectedSubCondition)}
    </div>
  );
};

export default React.memo(SubConditionsV2);
