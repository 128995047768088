// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {getGroupSectionGroupList} from 'admin.users/store/selectors';
import {getMeOwnerOrganization} from 'profile/store/selectors';
import {addGroupsToUser, fetchGroups} from 'admin.users/store/actions';
import {segmentClickEvent} from 'common/store/actions';
import SelectAndt, {THEME_HIGHLIGHTED_TRANSPARENT, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import './UsersTable2.module.scss';

type PropTypes = {
  user: Object,
  // connect
  groups: Array<Object>,
  ownerOrganization: String,
  fetchGroups: Function,
  addGroupsToUser: Function,
  segmentClickEvent: Function,
};

@connect(
  (state) => ({
    groups: getGroupSectionGroupList(state),
    ownerOrganization: getMeOwnerOrganization(state),
  }),
  {
    fetchGroups,
    addGroupsToUser,
    segmentClickEvent,
  },
)
export default class AddToGroupDropdown extends PureComponent {
  props: PropTypes;

  state = {
    selectedValue: [],
    isOpen: false,
  };

  onMenuOpen = () => {
    this.props.fetchGroups({ownerOrganization: this.props.ownerOrganization});
    this.setState({isOpen: true});
  };

  onMenuClose = () => {
    this.setState({isOpen: false, selectedValue: []});
  };

  onChange = (items) => {
    this.setState({selectedValue: items});
  };

  applyAddUserToGroups = () => {
    this.props.segmentClickEvent({
      type: 'click',
      name: 'Add to Group',
    });
    this.props.addGroupsToUser({
      ownerOrganization: this.props.ownerOrganization,
      userId: this.props.user.id,
      body: {groupIds: this.state.selectedValue.map((val) => val.id)},
    });
    this.onMenuClose();
  };

  render() {
    const {groups} = this.props;
    const {selectedValue, isOpen} = this.state;

    return (
      <div styleName={['user-no-groups', isOpen ? 'enforce-visible' : ''].join(' ')}>
        <SelectAndt
          isInputVisible
          automationId="addToGroupDropdown"
          type={TYPE_NEW_MULTI}
          theme={THEME_HIGHLIGHTED_TRANSPARENT}
          onChange={this.onChange}
          value={selectedValue}
          options={groups}
          getOptionLabel={(val) => val.name}
          getOptionValue={(val) => val.id}
          onMenuOpen={this.onMenuOpen}
          onMenuClose={this.onMenuClose}
          optionHeight={40}
          buttonWidth={100}
          menuWidth={230}
          menuIsOpen={isOpen}
          noMultiOptionsInnerState
          isMulti
          showValue={false}
          isClearable={false}
          closeMenuOnSelect={false}
          placeholder="+ Add to Group"
          menuPlacement="top"
          menuFooterComponent={
            <FormDdlActionBar isVisible isApplyDisabled={!selectedValue.length} onApply={this.applyAddUserToGroups} />
          }
          customComponent={{
            DropdownIndicator: () => (
              <div styleName="add-group-ddl">
                <i className="icn-action16-plusa" />
                Add to Group
              </div>
            ),
          }}
        />
      </div>
    );
  }
}
