// @flow
import escapeRegexp from 'escape-string-regexp';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/delay';
import {
  ACTION_ASYNC_REQUEST_SUFFIX,
  ACTION_ASYNC_SUCCESS_SUFFIX,
  ACTION_ASYNC_FAILURE_SUFFIX,
  ACTION_ASYNC_IGNORE_SUFFIX,
} from 'common/utils/simplifiedAsync';

import {error} from 'common/utils/notifications/notificationsService';
import {signOut} from 'profile/store/actions';
import {generalErrorMsg, timeoutErrorMsg} from 'common/utils/notifications/generalNotificationCodes';
import {addLoading, removeLoading} from '../actions';

type Action = {type: string, payload?: any, meta?: any};
type ExtendedAction = Action & {typePrefix: string};

const makeEpic = (suffixes, metaPredicate, mapper) => ($action) => {
  const regex = new RegExp(suffixes.map((e) => `(${escapeRegexp(e)}$)`).join('|'));
  return $action
    .filter(({meta}) => !meta || !metaPredicate(meta))
    .map((action) => ({...action, typePrefix: action.type.replace(regex, '')}))
    .filter(({type, typePrefix}: ExtendedAction) => typePrefix !== type)
    .map((a: ExtendedAction) => mapper(a));
};

export const startedAsyncRequests = makeEpic(
  [ACTION_ASYNC_REQUEST_SUFFIX],
  (meta) => meta.shouldIgnoreGlobalLoading,
  ({typePrefix}) => addLoading({typePrefix}),
);

export const completedAsyncRequests = makeEpic(
  [ACTION_ASYNC_SUCCESS_SUFFIX, ACTION_ASYNC_FAILURE_SUFFIX, ACTION_ASYNC_IGNORE_SUFFIX],
  (meta) => meta.shouldIgnoreGlobalLoading,
  ({typePrefix}) => removeLoading({typePrefix}),
);

export const errorAsyncRequests = makeEpic(
  [ACTION_ASYNC_FAILURE_SUFFIX],
  (meta) => meta.shouldIgnoreGlobalError,
  ({payload}) => {
    if (payload.status === 401) {
      const errMessage = payload.data && payload.data.andtErrorCode === 102 ? payload.data.message : '';
      return signOut({}, {errMessage, status: 401});
    }

    if (payload.andtErrorCode === 408 && payload.message === 'Timeout Error') {
      return error(timeoutErrorMsg);
    }

    const errorData = payload.errorHandler ? payload.errorHandler(payload) || generalErrorMsg : generalErrorMsg;
    return error(errorData);
  },
);
