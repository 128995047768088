// @flow
import React, {PureComponent, Fragment} from 'react';
import {Box} from '@material-ui/core';
import {abbrNum, setMaxNumberToDisplay} from 'common/utils/numbers';
import '../alertContent.module.scss';

type PropTypes = {
  metric: Object,
  size?: String,
};

class AnomalyAlertDelta extends PureComponent {
  props: PropTypes;

  static defaultProps = {
    size: 'sm',
  };

  findTheExtremeInterval = (value) => {
    const {metric} = this.props;
    let maxInterval = {};
    let maxValue = 0;

    Object.keys(metric.intervals).forEach((key) => {
      const interval = metric.intervals[key];

      if (interval.direction === value) {
        if (interval.deltaAbsolute > maxValue) {
          maxValue = interval.deltaAbsolute;
          maxInterval = interval;
        }
      }
    });
    return maxInterval;
  };

  render() {
    const {lowerAbsoluteDelta, lowerPercentageDelta, upperAbsoluteDelta, upperPercentageDelta} = this.props.metric;

    const upperInterval = upperAbsoluteDelta ? this.findTheExtremeInterval('UP') : null;
    const lowerInterval = lowerAbsoluteDelta ? this.findTheExtremeInterval('DOWN') : null;

    const upperPeak = upperAbsoluteDelta ? abbrNum(upperInterval.peak + -1 * upperInterval.deltaAbsolute, 2) : null;
    const lowerPeak = lowerAbsoluteDelta ? abbrNum(lowerInterval.peak + lowerAbsoluteDelta, 2) : null;

    return (
      <Fragment>
        {upperAbsoluteDelta && (
          <div styleName={this.props.size === 'sm' ? 'delta' : `delta ${this.props.size}`}>
            <Box p="2px" styleName="delta-icon" className="icon icn-arrow16-arrowup" color="#ff8f24" />
            <div styleName="delta-value">
              <Box component="span" color="#ff8f24" fontWeight={500} fontSize={14} styleName="percent">
                {setMaxNumberToDisplay(upperPercentageDelta)}%
              </Box>{' '}
              <Box component="span" color="gray.400" fontWeight={500} fontSize={14}>
                (
                <Box component="span" color="gray.500">
                  {upperPeak}{' '}
                </Box>
                to
                <Box component="span" color="gray.500">
                  {' '}
                  {abbrNum(upperInterval.peak, 2)}
                </Box>
                )
              </Box>
            </div>
          </div>
        )}

        {lowerAbsoluteDelta && (
          <div styleName={this.props.size === 'sm' ? 'delta' : `delta ${this.props.size}`}>
            <Box styleName="delta-icon" className="icon icn-arrow16-arrowdown" color="#ff8f24" />

            <Box styleName="delta-value">
              <Box component="span" color="#ff8f24" fontWeight={500} fontSize={14} styleName="percent">
                {setMaxNumberToDisplay(lowerPercentageDelta)}%
              </Box>{' '}
              <Box component="span" color="gray.400" fontWeight={500} fontSize={14}>
                (
                <Box component="span" color="gray.500">
                  {lowerPeak}{' '}
                </Box>
                <Box component="span" color="gray.400">
                  to
                </Box>{' '}
                <Box component="span" color="gray.500">
                  {abbrNum(lowerInterval.peak, 2)}
                </Box>
                )
              </Box>
            </Box>
          </div>
        )}
      </Fragment>
    );
  }
}

export default AnomalyAlertDelta;
