// @flow
import React, {Fragment, PureComponent} from 'react';
import moment from 'moment';
import * as selectors from 'alerts.console/store/selectors';
import * as commonSelectors from 'profile/store/selectors';
import {connect} from 'react-redux';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {Modal} from 'react-bootstrap';
import Calendar from 'common/components/dateTime/Calender';
import TimeInput from 'common/components/dateTime/TimeInput';
import * as alertsConsoleActions from 'alerts.console/store/actions';

import SelectAndt, {DIRECTION_LEFT, THEME_BLUE, TYPE_SIMPLE} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import SnoozeActionMetrics from './SnoozeActionMetrics';
import './SnoozeAction.module.scss';

const HOUR = 60 * 60;
const QUICK_SNOOZE_OPTIONS = [
  {key: 'endSnooze', text: 'End Snooze'},
  {key: '1h', text: '1 Hour', value: HOUR},
  {key: '6h', text: '6 Hours', value: HOUR * 6},
  {key: '1d', text: '1 Day', value: HOUR * 24},
  {key: '1w', text: '1 Week', value: HOUR * 24 * 7},
  {key: 'more', text: 'Custom'},
];

type PropTypes = {
  alertConfigurationId: string,
  triggerId: string,
  summary: Object,
  timeScale: String,
  alertType: String,
  // connect
  meId: string,
  isLoadingMetrics: boolean,
  isSnoozeAllInSnoozedMetrics: Array,
  isStlAllInMetrics: Array,
  filteredMetrics: Array,
  metricsFilter: string,
  isReadOnlyUser: boolean,
  fetchTriggeredAlertForSnooze: Function,
  setSnoozeMetricsFilter: Function,
  resetSnoozeModalState: Function,
  snoozeAllTriggerMetrics: Function,
  endSnoozeAllTriggerMetrics: Function,
  setSnoozeIndicationForMetric: Function,
  setSTLIndicationForMetric: Function,
  applySnoozeToMetrics: Function,
  applySTLtoMetrics: Function,
  stlAllTriggerMetrics: Function,
  endStlAllTriggerMetrics: Function,
};

@connect(
  (state) => ({
    meId: commonSelectors.getProfileId(state),
    isLoadingMetrics: selectors.isLoadingSnoozeData(state),
    isSnoozeAllInSnoozedMetrics: selectors.getIsSnoozeAllInSnoozedMetrics(state),

    isStlAllInMetrics: selectors.getIsStlAllInMetrics(state),

    filteredMetrics: selectors.getSnoozedFilteredMetrics(state),
    metricsFilter: selectors.getSnoozedMetricsFilter(state),
    isReadOnlyUser: commonSelectors.isReadOnlyUser(state),
  }),
  {
    fetchTriggeredAlertForSnooze: alertsConsoleActions.fetchTriggeredAlertForSnooze,
    setSnoozeMetricsFilter: alertsConsoleActions.setSnoozeMetricsFilter,
    resetSnoozeModalState: alertsConsoleActions.resetSnoozeModalState,

    snoozeAllTriggerMetrics: alertsConsoleActions.snoozeAllTriggerMetrics,
    endSnoozeAllTriggerMetrics: alertsConsoleActions.endSnoozeAllTriggerMetrics,
    setSnoozeIndicationForMetric: alertsConsoleActions.setSnoozeIndicationForMetric,
    applySnoozeToMetrics: alertsConsoleActions.applySnoozeToMetrics,

    stlAllTriggerMetrics: alertsConsoleActions.stlAllTriggerMetrics,
    endStlAllTriggerMetrics: alertsConsoleActions.endStlAllTriggerMetrics,
    setSTLIndicationForMetric: alertsConsoleActions.setSTLIndicationForMetric,
    applySTLtoMetrics: alertsConsoleActions.applySTLtoMetrics,
  },
)
export default class SnoozeAction extends PureComponent {
  props: PropTypes;

  constructor(props) {
    super(props);

    this.tomorrowsDate = moment()
      .add(1, 'days')
      .toDate();
    this.minDate = new Date();
    this.state = {
      isModalOpen: false,
      summaryTtlDate: this.setTtlDate(),
    };
  }

  setTtlDate = () => {
    const {snoozeSummary, stlSummary} = this.props.summary;

    if (snoozeSummary) {
      return moment.unix(snoozeSummary.minResumeTime).toDate();
    }
    if (stlSummary) {
      return moment.unix(stlSummary.minResumeTime).toDate();
    }
    return null;
  };

  openModal = () => {
    this.setState({isModalOpen: true});
    this.props.fetchTriggeredAlertForSnooze(this.props.triggerId);
  };

  closeModal = () => {
    const {resetSnoozeModalState} = this.props;

    this.setState({isModalOpen: false});
    resetSnoozeModalState();
  };

  dateChanged = (date) => {
    this.setState({summaryTtlDate: date});
  };

  setSnooze = () => {
    const {meId, alertConfigurationId, triggerId, timeScale, applySnoozeToMetrics, applySTLtoMetrics} = this.props;

    const params = {
      duration: this.state.summaryTtlDate
        ? moment(this.state.summaryTtlDate).unix() - moment().unix()
        : moment(this.tomorrowsDate).unix() - moment().unix(), // put in var and use once
      userId: meId,
      alertConfigurationId,
      triggerId,
      timeScale,
    };

    // we already set the metrics indication (setSnoozeIndicationForMetric) now we apply the duration
    applySnoozeToMetrics(params);
    applySTLtoMetrics(params);
    this.closeModal();
  };

  snoozeDdlItemSelected = (option) => {
    const key = option.value;
    const {
      triggerId,
      meId,
      alertConfigurationId,
      snoozeAllTriggerMetrics,
      endSnoozeAllTriggerMetrics,
      timeScale,
    } = this.props;

    switch (key) {
      case 'endSnooze': {
        const model = {
          triggerId,
          userId: meId,
          includeSTL: this.props.alertType === 'anomaly',
          timeScale,
        };
        // send as meta cause we set snooze only after success
        endSnoozeAllTriggerMetrics(model, {...model, alertConfigurationId});
        break;
      }
      case '1h':
      case '6h':
      case '1d':
      case '1w': {
        const model = {
          triggerId,
          userId: meId,
          duration: QUICK_SNOOZE_OPTIONS.find((a) => a.key === key).value,
          includeSTL: this.props.alertType === 'anomaly',
          timeScale,
        };
        // send as meta cause we set snooze only after success
        snoozeAllTriggerMetrics(model, {...model, alertConfigurationId});
        break;
      }
      default:
        this.openModal();
        break;
    }
  };

  getSnoozeOptions = () => {
    const {summaryTtlDate} = this.state;
    const ret = [];

    QUICK_SNOOZE_OPTIONS.forEach((opt) => {
      switch (opt.key) {
        case 'endSnooze':
          if (summaryTtlDate) {
            ret.push({value: opt.key, label: opt.text});
          }
          break;
        default:
          ret.push({value: opt.key, label: opt.text});
      }
    });
    return ret;
  };

  render() {
    const {isModalOpen, summaryTtlDate} = this.state;

    const {
      filteredMetrics,
      metricsFilter,
      isLoadingMetrics,
      setSnoozeMetricsFilter,
      isSnoozeAllInSnoozedMetrics,
      setSnoozeIndicationForMetric,
      isStlAllInMetrics,
      setSTLIndicationForMetric,
      alertType,
    } = this.props;

    return (
      <Fragment>
        <div onClick={(e) => e.preventDefault()} styleName="snooze-container" role="button">
          <SelectAndt
            options={this.getSnoozeOptions()}
            type={TYPE_SIMPLE}
            theme={THEME_BLUE}
            onChange={this.snoozeDdlItemSelected}
            direction={DIRECTION_LEFT}
            automationId="alertSnoozeAction"
            customComponent={{
              DropdownIndicator: (props) => (
                <SmartTooltip {...props} placement="top" content="Snooze" delay={400}>
                  <div styleName="value-container">
                    <i className="icon icn-action24-snooze" styleName="snooze-icon action" />
                  </div>
                </SmartTooltip>
              ),
            }}
          />
        </div>

        <Modal show={isModalOpen} dialogClassName="bc modal-xlg" bsSize="large" onHide={this.closeModal}>
          <div onClick={(e) => e.preventDefault()}>
            <Modal.Header bsClass="bc-modal-header">
              <Modal.Title>Custom Snooze</Modal.Title>
              <button className="btn btn-flat btn-icon-36 btn-secondary" onClick={this.closeModal} type="button">
                <i className="icon icn-icon-table-delete" />
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-body-message" styleName="snooze-modal-body">
                <div styleName="left-panel">
                  <Calendar
                    date={summaryTtlDate || this.tomorrowsDate}
                    shownDate={summaryTtlDate || this.tomorrowsDate}
                    onChange={this.dateChanged}
                    minDate={this.minDate}
                  />

                  <TimeInput date={summaryTtlDate || this.tomorrowsDate} onChange={this.dateChanged} />
                </div>

                <div styleName="right-panel">
                  <SnoozeActionMetrics
                    filteredMetrics={filteredMetrics}
                    metricsFilter={metricsFilter}
                    metricsFilterChanged={setSnoozeMetricsFilter}
                    isLoading={isLoadingMetrics}
                    setSnoozeIndicationForMetric={setSnoozeIndicationForMetric}
                    setSTLIndicationForMetric={setSTLIndicationForMetric}
                    isSnoozeAllInSnoozedMetrics={isSnoozeAllInSnoozedMetrics}
                    isStlAllInMetrics={isStlAllInMetrics}
                    alertType={alertType}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-flat btn-secondary" onClick={() => this.closeModal(true)} type="button">
                CANCEL
              </button>
              <button className="btn btn-flat btn-primary" onClick={this.setSnooze} type="button">
                OK
              </button>
            </Modal.Footer>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
