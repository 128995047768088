import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {composeReducers} from 'common/utils/reducers';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import * as actions from '../actions';

const EMPTY_ARRAY = [];

const dataSourcesReducer = composeReducers(
  {
    addSourceState: makeAsyncReducer(actions.createDataSource, {
      shouldDestroyData: false,
      errorHandler: bcErrorHandler,
    }),
  },
  {
    updateSourceState: makeAsyncReducer(actions.updateDataSource, {
      shouldDestroyData: false,
      errorHandler: bcErrorHandler,
    }),
  },
  {
    deleteSourceState: makeAsyncReducer(actions.deleteDataSource, {
      shouldDestroyData: false,
      errorHandler: bcErrorHandler,
    }),
  },
  {testSourceState: makeAsyncReducer(actions.testDataSource, {shouldDestroyData: false, errorHandler: bcErrorHandler})},
  (
    state = {
      selectedItemId: null,
    },
    {type, payload},
  ) => {
    switch (type) {
      case actions.createDataSource.TYPE:
        return {...state, addSourceState: {...state.addSourceState, data: payload}};
      case actions.createDataSource.success.TYPE:
        return {
          ...state,
          selectedItemId: payload.id,
          addSourceState: {
            ...state.addSourceState,
            isLoading: false,
            error: null,
            data: {},
          },
          sources: {...state.sources, data: [...state.sources.data, payload]},
        };
      case actions.updateDataSource.TYPE:
        return {
          ...state,
          sources: {
            ...state.sources,
            data: state.sources.data.map((i) => (i.id === payload.id ? {...i, ...payload} : i)),
          },
        };
      case actions.updateDataSource.success.TYPE:
        return {
          ...state,
          sources: {
            ...state.sources,
            data: state.sources.data.map((i) => (i.id === payload.id ? {...i, ...payload} : i)),
          },
        };
      case actions.resetAddSourceError.TYPE:
        return {...state, addSourceState: {...state.addSourceState, error: null}};
      case actions.setSelectedDataSource.TYPE:
        return {...state, selectedItemId: payload === state.selectedItemId ? null : payload};
      case actions.deleteDataSource.success.TYPE:
        return {...state, sources: {...state.sources, data: state.sources.data.filter((i) => i.id !== payload)}};
      default:
        return state;
    }
  },
  {sources: makeAsyncReducer(actions.fetchDataSources, {defaultData: EMPTY_ARRAY, shouldDestroyData: false})},
);

export default dataSourcesReducer;
