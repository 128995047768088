// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc.cubes/store/selectors';
import {Table, Column, Cell} from 'fixed-data-table-2';
import Loader from 'common/components/Loader';
import ReactResizeDetector from 'react-resize-detector';
import {get, omit} from 'lodash';
import './CubePreview.module.scss';

const EMPTY_OBJECT = {};

type PropTypes = {
  className: string,
  isLoading: boolean,
  cubePreview: Object,
  editableTopCube: Object,
};

@connect(
  (state) => ({
    isLoading: selectors.getSelectedCubePreviewIsLoading(state),
    cubePreview: selectors.getSelectedCubePreviewData(state),
    editableTopCube: selectors.getEditableTopCube(state),
  }),
  {},
)
export default class CubePreview extends PureComponent {
  props: PropTypes;

  state = {
    columnWidths: {},
    defaultColWidth: 100,
  };

  handleResize = (width, height) => {
    this.setState({
      tableWidth: width || 400,
      tableHeight: height || 200,
      defaultColWidth: width / 3,
    });
  };

  setInitialTableSize = (elm) => {
    if (!elm) {
      return;
    }
    this.handleResize(elm.clientWidth, elm.clientHeight);
  };

  handleColumnResize = (newColWidth, colKey) => {
    const columnWidth = this.state.columnWidths;
    const locColWidths = columnWidth || {};
    locColWidths[colKey] = newColWidth;
    this.setState({columnWidths: locColWidths});
  };

  render() {
    const {className, isLoading, cubePreview, editableTopCube} = this.props;
    const {tableWidth, tableHeight, defaultColWidth} = this.state;

    if (isLoading === undefined || !get(editableTopCube, 'dimensions', []).length) {
      return (
        <div styleName="root" className={className}>
          Preview Not Available Yet
        </div>
      );
    }

    if (isLoading) {
      return (
        <div styleName="root" className={className}>
          <Loader />
        </div>
      );
    }

    return (
      <div styleName="root" ref={this.setInitialTableSize} className={className}>
        <ReactResizeDetector handleWidth handleHeight onResize={this.handleResize} />

        <Table
          rowHeight={50}
          rowsCount={get(cubePreview, 'metrics.length', 0)}
          headerHeight={50}
          isColumnResizing={false}
          onColumnResizeEndCallback={this.handleColumnResize}
          width={tableWidth || 400}
          height={tableHeight || 200}
        >
          {editableTopCube.dimensions.map((dim) => (
            <Column
              key={dim}
              columnKey={dim}
              header={<Cell>{dim}</Cell>}
              width={get(this.state, 'columnWidths', EMPTY_OBJECT)[dim] || defaultColWidth}
              minWidth={20}
              allowCellsRecycling
              fixed={false}
              isResizable
              cell={<TextCell cubePreview={cubePreview} />}
            />
          ))}
        </Table>
      </div>
    );
  }
}

const TextCell = (props: any) => (
  <Cell {...omit(props, 'cubePreview')}>{props.cubePreview.metrics[props.rowIndex][props.columnKey]}</Cell>
);
