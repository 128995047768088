import 'moment-timezone';
import './pieChartsSettingsService.module.scss';

export const FEEDBACK = {
  basicColor: ['#2671ff', '#17406f'],
  borderColor: '#f4f7fb',
  borderWidth: 1,
};

export const ALERTS_SEVERITY = {
  basicColor: ['#d10f37', '#eb6f07', '#f4b92d', '#00b7f1', '#8995a0'],
  borderColor: '#f4f7fb',
  borderWidth: 1,
};

export const ALERTS_TYPE = {
  basicColor: ['#2671ff', '#3f51b6', '#17406f'],
  borderColor: '#f4f7fb',
  borderWidth: 1,
};

export const TOKEN_MAP = {
  basicColor: [
    '#1A896A',
    '#279677',
    '#34A384',
    '#40AF90',
    '#4DBC9D',
    '#5AC9AA',
    '#67D6B7',
    '#74E3C4',
    '#80EFD0',
    '#8DFCDD',
  ],
  borderColor: null,
  borderWidth: 0,
};

const TOOLTIP = {
  shadow: false,
  useHTML: true,
  outside: true,
  backgroundColor: 'transparent',
  borderWidth: 0,
  hideDelay: 50,
};

const DATA_LABELS = {
  enabled: true,
  crop: false,
  shadow: false,
  useHTML: true,
  formatter() {
    /* eslint-disable react/no-this-in-sfc */
    if (this.point.percentage) {
      return `<span style="width: 27px;font-family: Roboto;font-size: 11px;font-weight: 500;color: #fff;">
        ${Math.round(this.point.percentage)}%</span>`;
    }
    /* eslint-enable react/no-this-in-sfc */
    return null;
  },
  distance: -15,
};

export const getHchartConfig = (
  data = {},
  onClickEvent = false,
  labels = false,
  theme = FEEDBACK,
  tooltipFormatter,
  isSelectable = false,
  animation = true,
) => ({
  chart: {
    type: 'pie',
    credits: {
      enabled: false,
    },
    margin: isSelectable ? [2, 2, 2, 2] : [0, 0, 0, 0],
    spacingTop: 0,
    spacingBottom: 0,
    spacingLeft: 0,
    spacingRight: 0,
  },
  title: {text: null},
  plotOptions: {
    series: {
      cursor: onClickEvent ? 'pointer' : 'default',
      events: onClickEvent ? {click: onClickEvent} : false,
      states: {
        hover: {
          enabled: false,
        },
        select: {
          enabled: isSelectable,
          lineWidthPlus: 5,
        },
      },
      animation: animation ? {duration: 500} : false,
      marker: {
        enabled: false,
      },
    },
    pie: {
      colors: theme.basicColor,
      dataLabels: labels ? DATA_LABELS : {enabled: false},
      animation: {
        enabled: false,
      },
      shadow: false,
      allowPointSelect: isSelectable,
      slicedOffset: 0,
      borderWidth: data.some((i) => i.percentage === 100) ? 0 : theme.borderWidth,
      borderColor: theme.borderColor,
      states: {
        hover: {
          brightness: 0,
          enabled: false,
        },
        inactive: {
          enabled: false,
        },
      },
    },
  },
  tooltip:
    tooltipFormatter && tooltipFormatter.formatter
      ? {...TOOLTIP, formatter: tooltipFormatter.formatter}
      : {enabled: false},
  legend: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  series: [
    {
      showInLegend: false,
      data,
    },
  ],
});
