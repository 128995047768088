import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {combineEpics, createEpicMiddleware} from 'redux-observable';
import {reducer as notifications} from 'react-notification-system-redux';
import {epic as customersEpic, reducer as adminCustomers} from 'admin.customers';
import {epic as csEpic, reducer as adminCsPortal} from 'admin.cs-portal';
import {epic as emailDigestEpic, reducer as emailDigest} from 'admin.email-digest';
import expressionBuilder from './common/componentsV2/ExpressionBuilderV2/reducer/reducer';
import {epic as collectorsEpic, reducer as bc} from './bc';
import {epic as cubesEpic, reducer as cubesRoot} from './bc.cubes';
import {epic as commonEpic, reducer as common} from './common';
import {epic as alertManagementEpic, reducer as alertManagement} from './alerts.management';
import {epic as alertsConsoleEpic, reducer as alertsConsole} from './alerts.console';
import {epic as metricsEpic, reducer as metrics} from './metrics';
import {epic as channelsEpic, reducer as channels} from './alerts.channels';
import {epic as usersEpic, reducer as users} from './admin.users';
import {epic as appEpic, reducer as appNavBar} from './app/index';
import {epic as onBoardingEpic, reducer as onBoarding} from './onBoarding/index';
import {epic as chartEpic} from './charts';
import {epic as userEventsEpic, reducer as userEvents} from './userEvents';
import {epic as profileEpic, reducer as profile} from './profile';
import {epic as featuresEpic, reducer as featuresFlags} from './admin.features';
import {epic as trainTrackerEpic, reducer as trainTracker} from './admin.trainTracker';
import {epic as dnsTrackerEpic, reducer as dnsTracker} from './admin.dnsTracker';
import {epic as dashboardsEpic, reducer as dashboards} from './dashboards';
import {epic as insightsPanelEpic, reducer as insightsPanel} from './insightsPanel';
import {epic as userSettingsEpic, reducer as userSettings} from './userSettings';
import {epic as topologyMapEpic, reducer as topologyMap} from './topologyLeaflet';
import {epic as generalTpMapEpic, reducer as generalTpMap} from './topologyGeneral';
import {epic as impactEpic, reducer as impact} from './impact';
import {epic as assetsEpic, reducer as assets} from './assets';
import {epic as investigationEpic, reducer as investigation} from './investigation';
import {
  epic as alertActionsEpic,
  reducer as alertActions,
} from './alerts.management/components/editor/simulationArea/alertActions';
import {epic as activityLogEpic, reducer as activityLog} from './admin.activityLog';

const createRootReducer = (history) =>
  combineReducers({
    appNavBar,
    profile,
    bc,
    cubesRoot,
    common,
    alertManagement,
    alertsConsole,
    metrics,
    router: connectRouter(history),
    notifications,
    userEvents,
    channels,
    users,
    featuresFlags,
    trainTracker,
    onBoarding,
    dashboards,
    adminCustomers,
    adminCsPortal,
    expressionBuilder,
    dnsTracker,
    emailDigest,
    insightsPanel,
    userSettings,
    topologyMap,
    generalTpMap,
    impact,
    assets,
    investigation,
    alertActions,
    activityLog,
  });

const rootEpic = combineEpics(
  profileEpic,
  commonEpic,
  collectorsEpic,
  cubesEpic,
  alertManagementEpic,
  alertsConsoleEpic,
  metricsEpic,
  userEventsEpic,
  chartEpic,
  channelsEpic,
  usersEpic,
  appEpic,
  featuresEpic,
  trainTrackerEpic,
  onBoardingEpic,
  dashboardsEpic,
  customersEpic,
  csEpic,
  dnsTrackerEpic,
  emailDigestEpic,
  insightsPanelEpic,
  userSettingsEpic,
  topologyMapEpic,
  generalTpMapEpic,
  impactEpic,
  assetsEpic,
  investigationEpic,
  alertActionsEpic,
  activityLogEpic,
);

const isProduction = /^https?:\/\/app\.anodot\.com/.test(location.href);
const epicMiddleware = createEpicMiddleware(rootEpic);
let store;

export default function configureStore(hashHistory) {
  // eslint-disable-next-line
  const composeEnhancers = isProduction ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  store = createStore(
    createRootReducer(hashHistory),
    composeEnhancers(applyMiddleware(routerMiddleware(hashHistory), epicMiddleware)),
  );

  return store;
}

export const getConfiguredStore = () => store;
