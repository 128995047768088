// @flow
import React from 'react';
import {segmentClickEvent} from 'common/store/actions';
import {connect} from 'react-redux';
import './SwitchButton.module.scss';

type PropTypes = {
  isChecked: boolean,
  onChange: Function,
  eventKey?: String,
  disabled: boolean,
  automationId: string,
  segmentClickEvent: Function,
};

let switchIndex = 0;

@connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)
class SwitchButton extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    eventKey: null,
  };

  state = {
    switchIndex,
  };

  componentDidMount() {
    this.setState({
      switchIndex,
    });
    switchIndex += 1;
  }

  toggleChange = () => {
    const isChecked = !this.props.isChecked;
    this.props.segmentClickEvent({
      type: 'toggle',
      name: this.props.automationId,
    });
    if (this.props.eventKey) {
      this.props.onChange({
        eventKey: this.props.eventKey,
        value: isChecked,
      });
    } else {
      this.props.onChange(isChecked);
    }
  };

  render() {
    return (
      <div>
        <input
          type="checkbox"
          onChange={this.toggleChange}
          checked={this.props.isChecked}
          styleName="toggle"
          id={`switch-${this.state.switchIndex}`}
          disabled={this.props.disabled}
          automation-id={this.props.automationId}
        />
        <label htmlFor={`switch-${this.state.switchIndex}`} />
      </div>
    );
  }
}

export default SwitchButton;
