// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Loading from 'metrics/components/Loading';
import * as selectors from 'bc/store/selectors';
import {fetchMParticleEventTypes as fetchMParticleEventTypesAction} from 'bc/store/actions';
import '../../ViewStreamSummaryModalV2.module.scss';

type PropTypes = {
  dataStream: Object,

  // connect
  eventTypes: Array,
  isEventTypesLoading: boolean,
  fetchMParticleEventTypes: Function,
};

@connect(
  (state) => ({
    eventTypes: selectors.getMParticleEventTypesItems(state),
    isEventTypesLoading: selectors.getMParticleEventTypesIsLoading(state),
  }),
  {
    fetchMParticleEventTypes: fetchMParticleEventTypesAction,
  },
)
export default class MParticleDataSection extends PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {fetchMParticleEventTypes, isEventTypesLoading, eventTypes} = this.props;

    if (!isEventTypesLoading && !eventTypes.length) {
      fetchMParticleEventTypes();
    }
  }

  render() {
    const {
      dataStream: {eventType},
      eventTypes,
    } = this.props;
    const foundEventType = eventTypes.find((evType) => evType.type === eventType);

    return (
      <div styleName="section">
        <div styleName="vs-section-header">EVENT TYPE DATA</div>
        <div styleName="vs-row">
          <div styleName="vs-label">Event Type</div>
          {foundEventType ? (
            <div styleName="vs-value">{foundEventType.displayName}</div>
          ) : (
            <Loading styleName="spinner" />
          )}
        </div>
      </div>
    );
  }
}
