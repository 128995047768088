// @flow
import React from 'react';
import {connect} from 'react-redux';
import {getTypeDetails} from 'bc/services/bcTypes';
import './StreamTableTop.module.scss';
import {abbrNum} from 'common/utils/numbers';
import * as selectors from 'bc/store/selectors';
import * as metricsSelectors from 'metrics/store/selectors';
import {
  getOrganization,
  getOrganizationMetricsLimit,
  getOrganizationMetricsLimitWithGrace,
  getUserProfile,
} from 'profile/store/selectors';
import {get} from 'lodash';
import {fetchStreamsStats} from 'bc/store/actions';
import HackFileJsonModal from './HackFileJsonModal';

let hackCounter = 0;

type PropTypes = {
  dataStream: Object,
  customerMetricsLimit: Number,
  orgName: String,
  totalForStream: Number,

  customerMetricsCount: Number,
  customerMetricsCountIsLoading: Boolean,
  customerMetricsLimitWithGrace: Number,
  isStatsLoading: Boolean,
  me: Object,
  fetchStreamsStats: Function,
};

@connect(
  (state) => ({
    customerMetricsLimit: getOrganizationMetricsLimit(state),
    orgName: get(getOrganization(state), 'name', ''),
    dataStream: selectors.getSelectedDataStream(state),
    totalForStream: selectors.getSelectedStreamStatsTotalMetrics(state),
    customerMetricsCount: metricsSelectors.getCustomerMetricsCountRes(state),
    customerMetricsLimitWithGrace: getOrganizationMetricsLimitWithGrace(state),
    isStatsLoading: metricsSelectors.getCustomerMetricsCountIsLoading(state),
    me: getUserProfile(state),
  }),
  {
    fetchStreamsStats,
  },
)
export default class StreamTableTop extends React.PureComponent {
  props: PropTypes;

  state = {
    showWarning: false,
    hackIsOpenFileJsonModal: false,
  };

  componentDidMount() {
    const {me} = this.props;

    if (me && me.ownerOrganization) {
      this.props.fetchStreamsStats({
        _id: me.ownerOrganization,
      });
    }
  }

  onMouseOut = () => {
    if (
      ((this.props.totalForStream + this.props.customerMetricsCount) / this.props.customerMetricsLimit) * 100 > 80 &&
      this.state.showWarning
    ) {
      this.setState({showWarning: false});
    }
  };

  onMouseOver = () => {
    if (
      ((this.props.totalForStream + this.props.customerMetricsCount) / this.props.customerMetricsLimit) * 100 > 80 &&
      !this.state.showWarning
    ) {
      this.setState({showWarning: true});
    }
  };

  hackFileJsonClicked = () => {
    this.setState((prevState) => ({
      hackIsOpenFileJsonModal: !prevState.hackIsOpenFileJsonModal,
    }));
  };

  hackStarter = () => {
    hackCounter += 1;
    if (hackCounter % 5 === 0) {
      hackCounter = 0;
      if (!this.state.hackIsOpenFileJsonModal) {
        this.hackFileJsonClicked();
      }
    }
  };

  render() {
    const {
      customerMetricsCountIsLoading,
      totalForStream,
      customerMetricsCount,
      customerMetricsLimitWithGrace,
    } = this.props;
    const totalsIndicator =
      ((this.props.totalForStream + this.props.customerMetricsCount) / this.props.customerMetricsLimit) * 100;
    let totalsContainerStyle = 'totals-container';
    let warnTitle = 'Warning';
    let warnClass = '';
    let warnVerb = 'reach';
    if (totalsIndicator > 100) {
      totalsContainerStyle += ' too-much';
      warnVerb = 'exceed';
    } else if (totalsIndicator > 80) {
      totalsContainerStyle += ' warn';
    }
    if (!customerMetricsCountIsLoading) {
      warnTitle = totalForStream + customerMetricsCount <= customerMetricsLimitWithGrace ? 'Warning' : 'Error';
      warnClass = 'warn-error';
    }
    const href = `mailto:support@anodot.com?Subject=${this.props.orgName}: Request to increase account metric limits`;

    return (
      <div id="stream-table-top" styleName="root">
        <div className="shell-col" styleName="title-container">
          <div role="presentation" className="andt-page-header" onClick={this.hackStarter}>
            Stream Table
            {this.state.hackIsOpenFileJsonModal && (
              <HackFileJsonModal isOpen={this.state.hackIsOpenFileJsonModal} onClose={this.hackFileJsonClicked} />
            )}
          </div>
          <div className="andt-section-header" styleName="section-header">
            {this.props.dataStream.name} ({getTypeDetails(this.props.dataStream.type).name})
          </div>
        </div>
        <div className="shell-col" styleName={totalsContainerStyle}>
          <div
            role="presentation"
            styleName="number-metrics-section"
            onMouseOver={this.onMouseOver}
            onMouseOut={this.onMouseOut}
          >
            <div className="andt-page-header" styleName="number" automation-id="totalMetricsNumber">
              {this.props.isStatsLoading ? (
                <i className="icon ion-load-c spin" />
              ) : (
                abbrNum(this.props.totalForStream, 0)
              )}
            </div>
            <div styleName="account-metrics-info">
              <span styleName="term">Account Total</span>
              <span styleName="value">{abbrNum(this.props.customerMetricsLimit, 0)}</span>
            </div>

            <span>
              <div styleName="account-metrics-info">
                <span styleName="term">Used</span>
                <span styleName="value">
                  {this.props.customerMetricsCountIsLoading ? (
                    <i className="icon ion-load-c spin" />
                  ) : (
                    abbrNum(this.props.customerMetricsCount, 0)
                  )}
                </span>
              </div>
              <div styleName="account-metrics-info">
                <span styleName="term">Available</span>
                <span styleName="value">
                  {this.props.customerMetricsCountIsLoading ? (
                    <i className="icon ion-load-c spin" />
                  ) : (
                    abbrNum(this.props.customerMetricsLimit - this.props.customerMetricsCount, 0)
                  )}
                </span>
              </div>
            </span>

            <div
              role="presentation"
              styleName={[this.state.showWarning ? 'warning-message show' : 'warning-message', warnClass].join(' ')}
              onMouseOver={this.onMouseOver}
              onMouseOut={this.onMouseOut}
            >
              <div styleName="header">
                <span>{warnTitle}</span>
              </div>
              <div styleName="body">
                You are about to {warnVerb} your account metric limit.
                <br />
                <b>Tune the stream</b>
              </div>
              <div styleName="footer">
                <a href={href} target="_blank" rel="noreferrer noopener">
                  Contact Anodot to increase metric limit.
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
