// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setSelectedEditor} from 'bc/store/actions';
import {segmentCausingEvent} from 'common/store/actions';
import * as selectors from 'bc/store/selectors';
import {isStreamEditable, isStreamRunning, isEditRunning} from 'bc/services/dataStreamService';
import {getEditorValidationMessage} from 'bc/services/genericStorageService';
import DimensionsAndMetrics from 'bc/components/streams/editor/common/DimensionsAndMetrics';
import {get} from 'lodash';

type PropTypes = {
  dataStream: Object,
  dimensions: Array<Object>,
  metrics: Array<Object>,
  timeDefinition: Object,
  setSelectedEditor: Function,
  segmentCausingEvent: Function,
  isUiStateDirty: boolean,
  isLoading: boolean,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    dimensions: selectors.getFileStreamSelectedDimensions(state),
    metrics: selectors.getFileStreamSelectedMetrics(state),
    timeDefinition: selectors.getFileStreamSelectedTimeDefinitionCol(state),
    isUiStateDirty: selectors.isS3StreamUiStateDirty(state),
    isLoading: selectors.isS3CsvFormatAnalysisLoading(state),
  }),
  {
    setSelectedEditor,
    segmentCausingEvent,
  },
)
export default class S3DimensionsAndMetrics extends React.PureComponent {
  props: PropTypes;

  setSelectedEditor = () => {
    const editVar = isEditRunning(this.props.dataStream.id) ? 'live-' : '';
    this.props.segmentCausingEvent({category: 'bc/stream-query', name: `edit-s3-${editVar}diametrics`});
    this.props.setSelectedEditor({
      type: 'dm_s3',
      editorHeight: 'calc(100% - 210px)',
    });
  };

  render() {
    const {dataStream, dimensions, metrics, timeDefinition, isUiStateDirty, isLoading} = this.props;
    // const isLoading = !get(dataStream, 'uiState.analysisResult');
    const isEditable =
      (isStreamEditable(dataStream.state) || isStreamRunning(dataStream.state)) &&
      get(dataStream, 'uiState.analysisResult.fileSchema') &&
      !isUiStateDirty;

    return (
      <DimensionsAndMetrics
        dataStream={dataStream}
        dimensions={dimensions}
        metrics={metrics}
        timeDefinition={timeDefinition}
        isLoading={isLoading}
        isEditable={isEditable}
        validatorFunction={getEditorValidationMessage}
        onSelectEditor={() => this.setSelectedEditor()}
      />
    );
  }
}
