// @flow
import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

const BROWSER = 'Browser';
const timeZones = moment.tz.names().map((item) => ({
  label: item,
  value: item,
}));
timeZones.unshift({
  label: BROWSER,
  value: BROWSER,
});

type PropTypes = {
  value: string,
  onChange: Function,
  selectProps: Object,
};

const onSelectTimeZone = (tz, newTz, action) => {
  if (tz === newTz) {
    return;
  }

  action(newTz);
};

const TimeZone = (props: PropTypes) => {
  const timeZone = timeZones.find((val) => props.value === val.label) || null;
  const selected = timeZone && timeZone.label ? timeZone : null;

  return (
    <SelectAndt
      type={TYPE_NEW_SEARCH}
      theme={THEME_NOT_HIGHLIGHTED}
      onChange={(newTz) => onSelectTimeZone(props.value, newTz.label, props.onChange)}
      automationId="timeZones"
      value={selected || timeZones[0]}
      options={timeZones}
      optionHeight={40}
      {...props.selectProps}
    />
  );
};

export default TimeZone;
