// @flow
import React, {useEffect, useCallback, useState, useMemo, useContext, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {get, values, isEqual, pick} from 'lodash';
import {FormSpy, useField, useForm} from 'react-final-form';
import {updateSimulation} from 'metrics/store/actions';
import {selectedAlertFilterChanged} from 'alerts.management/store/actions';
import {setExecuteParams} from 'userEvents/store/actions';
import {getRetentionsValues, getIsEnableSimulationReferenceDate} from 'profile/store/selectors';
import * as amSelectors from 'alerts.management/store/selectors';
import * as metricsSelectors from 'metrics/store/selectors';
import {getSimulationUserEventsDataRange} from 'alerts.management/services/alertsService';
import hashcode from 'common/utils/hashcode';
import omitDeepLodash from 'common/utils/omitDeep';
import useExpressionBuilder from 'dashboards/hooks/useExpressionBuilder';
import AlertSettingsContext from './context';
import PreviewTemplate from './PreviewTemplate';
import QuickActions from './QuickActions';

type PropTypes = {
  alert: Object,
  executeSimulation: Function,
};

const EMPTY_ARRAY = [];

const AlertPreview = ({alert, executeSimulation}: PropTypes) => {
  const dispatch = useDispatch();
  const isExpressionInitialized = useRef();
  const [isMounted, setIsMounted] = useState(false);
  const retentions = useSelector(getRetentionsValues);
  const isReferenceDateEnabled = useSelector(getIsEnableSimulationReferenceDate);
  const isTreeEmpty = useSelector(metricsSelectors.getIsTreeEmpty);
  const alertSimulation = useSelector(amSelectors.getAlertSimulation);
  const simulationMetrics = useSelector(amSelectors.getAlertSimulationUniqueMetrics);
  const previewOptions = useSelector(metricsSelectors.getPreviewOptions);
  const alertType = useSelector(amSelectors.getAlertType);
  const simulationFilters = useSelector(amSelectors.getSelectedAlertSimulationFilters);
  const simulationFiltersValidation = useSelector(amSelectors.getSimulationFiltersValidationResults);
  const simulationFiltersValidationResult = simulationFiltersValidation.data;
  const expressionTree = useSelector(metricsSelectors.getExpressionTree);
  const isRootSelected = useSelector(metricsSelectors.getIsRootSelected);
  const isExpressionInputSelected = useSelector(metricsSelectors.getIsExpressionInputSelected);

  const {
    input: {value: showComposites},
  } = useField('showComposites');

  const {
    isSimulationViewActive,
    setSimulationViewActive,
    isAutoSimulationEnabled,
    setAutoSimulationEnabled,
    isAutoSimulationPristine,
  } = useContext(AlertSettingsContext);

  const getLabel = () => {
    if (alertType === 'anomaly' && isSimulationViewActive) {
      return 'Anomaly Simulation';
    }
    if (alertType === 'static' && isSimulationViewActive) {
      return 'Static Simulation';
    }

    return 'Metric Data';
  };

  const isMetricExplorerOpen = useSelector(metricsSelectors.getIsMetricExplorerModalOpen);
  const areFiltersValid = simulationFiltersValidationResult && simulationFiltersValidationResult.passed;
  const isAlertSimulationLoaded = alertSimulation.isLoading === false;
  const lastOptions = useRef();
  const lastSimulationHash = useRef();
  const selectedBranch = useSelector(metricsSelectors.getSelectedBranch);

  const {
    input: {value: isInfluencingEvents},
  } = useField('isInfluencingEvents');

  const {
    input: {value: referenceDate},
  } = useField('referenceDate');

  const {
    input: {value: dateRange},
  } = useField('dateRange');

  const {
    input: {value: timeScale},
  } = useField('timeScale');

  const {
    input: {value: sort},
  } = useField('sort');

  const multiPreviewOptions = useMemo(
    () => ({
      '*': {
        sort,
        show: previewOptions.show,
      },
    }),
    [sort, previewOptions.show],
  );

  const formProps = useForm();

  const onUpdateSimulation = useCallback(
    ({values: payloadRaw}) => {
      const payload = pick(payloadRaw, ['dateRange', 'sort', 'timeScale', 'showComposites']);
      if (isEqual(lastOptions.current, payload)) {
        return;
      }
      lastOptions.current = payload;
      const dateRangeState = formProps.getFieldState('dateRange');
      const timeScaleState = formProps.getFieldState('timeScale');

      if (!isAutoSimulationEnabled) {
        setSimulationViewActive(false);
      }
      if (
        !dateRangeState ||
        !isEqual(dateRangeState.initial.constRange, dateRangeState.value.constRange) ||
        timeScaleState.modified
      ) {
        dispatch(
          setExecuteParams({
            dateRange: getSimulationUserEventsDataRange(payload.timeScale, retentions),
            aggregation: {
              aggregationField: null,
              maxBuckets: null,
              resolution: payload.timeScale,
              topEventSize: 10,
            },
          }),
        );
      }

      dispatch(updateSimulation({...payload, show: 10, cache: true}));
    },
    [dispatch, isAutoSimulationEnabled, referenceDate],
  );

  const {
    metrics: metricSearchMetrics,
    totalAmount: metricSearchTotalAmount,
    validationMessage: compositeValidationMessage,
    isCompositeDataLoading,
    isZoomLoading,
    validationId,
  } = useExpressionBuilder({
    isShowComposites: showComposites,
    dateRange,
    timeScale,
    multiPreviewOptions,
    cacheOnlyLast: true,
  });

  const currentHash = hashcode([
    omitDeepLodash(expressionTree, ['id', 'uiData']),
    simulationFilters,
    isAutoSimulationEnabled,
  ]);

  useEffect(() => {
    if ((isTreeEmpty || isExpressionInputSelected) && isAutoSimulationEnabled && isExpressionInitialized.current) {
      setAutoSimulationEnabled(false);
    }
  }, [isTreeEmpty, isExpressionInputSelected]);

  // Hide simulation view when user changes alert type
  useEffect(() => {
    if (alertType === 'noData') {
      setSimulationViewActive(false);
    }
  }, [alertType]);

  // If any of inputs for simulation gets invalid then hide simulation
  useEffect(() => {
    if (areFiltersValid === false) {
      setSimulationViewActive(false);
    }
  }, [areFiltersValid]);

  // Show simulation view after simulation was loaded and only if root is selected
  useEffect(() => {
    if (isAlertSimulationLoaded && isRootSelected && !isTreeEmpty && alertType !== 'noData') {
      if (isAutoSimulationEnabled && !isSimulationViewActive) {
        setSimulationViewActive(true);
      }
      lastSimulationHash.current = currentHash;
    }
  }, [isAlertSimulationLoaded]);

  // Execute simulation after expression was updated only if root is selected
  useEffect(() => {
    if (
      isRootSelected &&
      isAutoSimulationEnabled &&
      !isTreeEmpty &&
      alertType !== 'noData' &&
      alertSimulation.isLoading !== true
    ) {
      if (lastSimulationHash.current === currentHash) {
        setSimulationViewActive(true);
      } else {
        executeSimulation(0, undefined, !isAutoSimulationEnabled);
        isExpressionInitialized.current = true;
      }
    }
    if (!isAutoSimulationEnabled) {
      setSimulationViewActive(false);
    }
  }, [isAutoSimulationEnabled, isTreeEmpty, timeScale, expressionTree]);

  // Execute simulation after any of filters was changed if auto-simulation enabled

  useEffect(() => {
    if (
      simulationFiltersValidationResult &&
      areFiltersValid &&
      isAutoSimulationEnabled &&
      alertType !== 'noData' &&
      alertSimulation.isLoading !== true
    ) {
      if (isExpressionInitialized.current) {
        executeSimulation(0, undefined, !isAutoSimulationEnabled);
      }
    }
  }, [simulationFiltersValidationResult]);

  // Execute simulation after reference date changed
  useEffect(() => {
    if (
      isAutoSimulationPristine &&
      alertType !== 'noData' &&
      isAutoSimulationEnabled &&
      isExpressionInitialized.current
    ) {
      executeSimulation(0, undefined, !isAutoSimulationEnabled);
    }
  }, [referenceDate]);

  useEffect(() => {
    dispatch(selectedAlertFilterChanged({filter: {...simulationFilters, isInfluencingEvents}}));
  }, [isInfluencingEvents]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const simulationTotalAmount = simulationMetrics.length < 10 ? simulationMetrics.length : metricSearchTotalAmount;
  /* always show composite response size according to AL-8743
        get(
          alertSimulation,
          isEmptyFunction ? 'data.compositeValidation.totalMetricsOutput' : 'data.totalMetricsAnalyzed',
          0,
        );
      */

  const isLoading = isSimulationViewActive ? alertSimulation.isLoading : isCompositeDataLoading;
  const isAnyLoading = alertSimulation.isLoading || isCompositeDataLoading;
  const totalAmount = isSimulationViewActive ? simulationTotalAmount : metricSearchTotalAmount;

  const simulationFiltersValidationMessage = get(
    values(get(simulationFiltersValidationResult, 'failures')),
    '[0][0].message',
    '',
  );
  const simulationValidationMessage = get(
    values(get(alertSimulation, 'data.simulationValidation.failures')),
    '[0].message',
    '',
  );
  const validationMessage =
    (!isSimulationViewActive && compositeValidationMessage) ||
    simulationFiltersValidationMessage ||
    (isSimulationViewActive && simulationValidationMessage);

  const metrics = isSimulationViewActive ? simulationMetrics : metricSearchMetrics;

  if (isMetricExplorerOpen) {
    return null;
  }

  if (isTreeEmpty) {
    lastOptions.current = null;
    return isMounted ? <QuickActions maxExpressionsCount={5} /> : null;
  }

  return (
    <React.Fragment>
      <FormSpy subscription={{values: true}} onChange={onUpdateSimulation} />
      <PreviewTemplate
        isReferenceDateEnabled={isReferenceDateEnabled}
        rootId={get(selectedBranch, 'root.id')}
        timeScale={timeScale}
        totalAmount={Math.max(totalAmount, get(metrics, 'length', 0))}
        isLoading={isLoading}
        isZoomLoading={isZoomLoading}
        isLoadingMore={isSimulationViewActive ? alertSimulation.isLoadMore : previewOptions.show > 10}
        isAnyLoading={isAnyLoading}
        isSimulating={isAutoSimulationEnabled && alertSimulation.isLoading}
        loadingLabel="Auto-Simulate"
        label={getLabel()}
        metrics={metrics || EMPTY_ARRAY}
        alert={alert.data}
        isSimulation={isSimulationViewActive}
        executeSimulation={executeSimulation}
        alertSimulation={alertSimulation}
        validationMessage={validationMessage}
        validationId={validationId}
        blurLabel={isSimulationViewActive}
        isLoadingBarEnabled={alertType !== 'noData'}
      />
    </React.Fragment>
  );
};

export default React.memo(AlertPreview);
