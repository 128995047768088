/* eslint-disable */

import React, {useMemo} from 'react';
import Select from 'react-select2';
import Button, {COLORS as BUTTON_COLORS} from 'common/componentsV2/Button';
import * as style from 'common/componentsV2/ddl/selectAndt/SelectAndtStyling';
import Control from 'common/componentsV2/ddl/selectAndt/innerComponents/Control';
import DropdownIndicatorCaretNoTransform from 'common/componentsV2/ddl/selectAndt/innerComponents/DropdownIndicatorCaretNoTransform';
import MenuListVirtualize from 'common/componentsV2/ddl/selectAndt/innerComponents/MenuListVirtualize';
import EmptyComponent from 'common/componentsV2/ddl/selectAndt/innerComponents/EmptyComponent';
import ValueContainer from 'common/componentsV2/ddl/selectAndt/innerComponents/ValueContainer';
import InputInMenu from 'common/componentsV2/ddl/selectAndt/innerComponents/InputInMenu';
import OptionSelectedSigned from 'common/componentsV2/ddl/selectAndt/innerComponents/OptionSelectedSigned';

const styles = style.newMultiStyle(
  'THEME_HIGHLIGHTED_TRANSPARENT',
  {
    menuWidth: 290,
    buttonWidth: undefined,
    minMenuHeight: 300,
    offsetLeft: 0,
  },
  true,
  true
);

const components = {
  Control,
  DropdownIndicator: DropdownIndicatorCaretNoTransform,
  MenuList: MenuListVirtualize,
  Option: OptionSelectedSigned,
  SingleValue: EmptyComponent,
  Placeholder: EmptyComponent,
  ValueContainer,
  Input: InputInMenu,
};

// eslint-disable-next-line max-len
const SimpleButtonSelect = ({
  options,
  onChange,
  value,
  text,
  icon,
  isSearchable,
  onMenuOpen,
  isLoading,
  onInputChange,
}: {
  options: Array,
  onChange: Function,
  onMenuOpen: Function,
  value: Object,
  text: string,
  icon: string,
  isSearchable: boolean,
  isLoading: boolean,
  onInputChange: Function,
}) => {
  const customComponents = useMemo(
    () => ({
      ...components,
      DropdownIndicator: () => (
        <div className="position_absolute left_0">
          <Button colorSchema={BUTTON_COLORS.BLUE_500} text={text} icon={icon} />
        </div>
      ),
    }),
    [text, icon]
  );

  return (
    <Select
      onInputChange={onInputChange}
      onMenuOpen={onMenuOpen}
      isSearchable={isSearchable}
      className="select-menu"
      styles={styles}
      components={customComponents}
      menuPlacement="auto"
      minMenuHeight={300}
      maxMenuHeight={300}
      maxHeight={300}
      optionHeight={40}
      hideSelectedOptions={false}
      animationDuration={1}
      isInputInMenu
      captureMenuScroll
      menuShouldScrollIntoView={false}
      options={options}
      onChange={onChange}
      value={value}
      isLoading={false}
    />
  );
};

export default SimpleButtonSelect;
