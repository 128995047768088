// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {Group} from 'anodot-objects-models';
import Input from 'common/componentsV2/Input';
import {getGroupsModalGroupColorSchema, getGroupsModalGroupName} from 'admin.users/store/selectors';
import {setGroupProps} from 'admin.users/store/actions';
import GroupColorPicker from './GroupColorPicker';
import './Sections.module.scss';

type PropTypes = {
  groupName: String,
  selectedColor: String,
  setGroupProps: Function,
};

@connect(
  (state) => ({
    groupName: getGroupsModalGroupName(state),
    selectedColor: getGroupsModalGroupColorSchema(state),
  }),
  {
    setGroupProps,
  },
)
export default class PropertiesSection extends React.PureComponent {
  props: PropTypes;

  colorClicked = (color) => {
    this.props.setGroupProps({selectedColor: color});
  };

  groupTitleChanged = (e) => {
    this.props.setGroupProps({groupName: e.target.value});
  };

  render() {
    const {groupName} = this.props;

    return (
      <div styleName="properties">
        <span styleName="cat-label">Name:</span>
        <Input
          styleName="group-title-pos"
          placeHolder="e.g. Marketing"
          value={groupName}
          isInvalid={Group.isGroupNameInvalid(Group.cleanGroupName(groupName))}
          onChange={this.groupTitleChanged}
        />
        <div styleName="group-color-wrapper">
          <GroupColorPicker onClick={this.colorClicked} selectedColor={this.props.selectedColor} />
        </div>
      </div>
    );
  }
}
