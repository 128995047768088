import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {getUniqueId} from 'common/utils/guid';
import userEventsHandler from 'userEvents/services/errors';
import * as actions from 'userEvents/store/actions';
import ExpressionItem from 'common/componentsV2/ExpressionBuilderV2/ExpressionItem';

const initialState = {
  firstPanel: [],
  expression: [],
  secondPanel: [],
  events: [],
  dateRange: {},
  aggregation: null,
  shouldShowEvents: false,
};

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const getEventsAfterMoreEventsExecuted = (stateEvents, updatedEventId, payload) => {
  const filteredEvents = stateEvents.filter((ev) => ev.id !== updatedEventId);
  const foundEvent = stateEvents.find((ev) => ev.id === updatedEventId);
  const updatedEvent = {
    ...foundEvent,
    topEvents: payload.events.map((ev) => ev.topEvents[0]),
  };
  return [...filteredEvents, updatedEvent];
};

const eventsExpressionBuilderReducer = composeReducers(
  makeAsyncReducer(actions.fetchUserEventsCategories, {dataProp: 'items', defaultData: EMPTY_ARRAY}),
  {
    newUserEventCategory: makeAsyncReducer(actions.createUserEventsCategories, {
      defaultData: EMPTY_ARRAY,
      errorHandler: userEventsHandler,
    }),
  },
  {deleteUserEventsCategory: makeAsyncReducer(actions.deleteUserEventsCategory, {})},

  (state = initialState, {type, payload, meta}) => {
    switch (type) {
      case actions.fetchPropAndValListApi.success.TYPE: {
        const firstPanel = payload.properties.properties.map((prop) => new ExpressionItem(prop, prop, null, []));
        return {
          ...state,
          firstPanel,
        };
      }
      case actions.fetchPropsApi.success.TYPE: {
        const secondPanel = payload.propertyValues.map(
          (prop) => new ExpressionItem(prop.value, prop.value, prop.key, null),
        );
        return {
          ...state,
          secondPanel,
        };
      }
      case actions.execute.success.TYPE: {
        return {
          ...state,
          events: payload.events.map((event) => ({...event, id: getUniqueId()})),
          eventsResolution: payload.resolution,
        };
      }
      case actions.executeMoreTopEvents.success.TYPE: {
        return {
          ...state,
          events: getEventsAfterMoreEventsExecuted(state.events, meta.eventId, payload),
        };
      }
      case actions.setExpression.TYPE: {
        return {
          ...state,
          expression: payload,
        };
      }
      case actions.setShouldShowEvents.TYPE: {
        return {
          ...state,
          shouldShowEvents: payload,
        };
      }
      case actions.setExecuteParams.TYPE: {
        return {
          ...state,
          dateRange: payload.dateRange || EMPTY_OBJECT,
          aggregation: payload.aggregation || null,
        };
      }

      default:
        return state;
    }
  },
);

export default eventsExpressionBuilderReducer;
