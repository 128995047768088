import {isFunction} from 'lodash';

const EMPTY_OBJECT = {};

/*
 composes reducers from left to right,
 returns same state if nothing was changed:
 example:
 reducerA = (state, action, ...args) => {...}
 reducerB = (state, action, ...args) => {...}
 reducerC = (state, action, ...args) => {...}

 composeReducers(reducerA, reducerB, reducerC) returns a reducer like this:
 newState = reducerA(state, action, ...args)
 newState = reducerB(newState, action, ...args)
 newState = reducerC(newState, action, ...args)
 return newState

 instead of a reducer you can provide a reducersMap (like in combineReducers)
 example:
 state = { name: 'bla', items: [ 1, 2, 3 ] }
 mainReducer = (state, action, ...args) => {...}
 childReducer = (items, action, ...args) => {...}

 we need to run mainReducer on the state and childReducer on the 'items' property:
 composeReducers(
 mainReducer,
 { items: childReducer }
 )
 */
export function composeReducers(...reducers) {
  return (state, ...args) => reducers.reduce((newState, reducer) => runReducer(reducer, newState, ...args), state);
}

/*
 if reducerOrReducersMap is a reducer function, runs the reducer args
 if it's a reducersMap (like in combineReducers),
 runs all the reducers on the respective properties
 */
function runReducer(reducerOrReducersMap, state, ...args) {
  return isFunction(reducerOrReducersMap)
    ? reducerOrReducersMap(state, ...args)
    : runChildReducers(reducerOrReducersMap, state, ...args);
}

function runChildReducers(reducersMap, state, ...args) {
  if (state === null || state === undefined) {
    return state;
  }
  return Object.keys(reducersMap).reduce(
    (newState, key) => runChildReducer(key, reducersMap[key], newState, ...args),
    state,
  );
}

function runChildReducer(key, reducer, state, ...args) {
  const oldChildState = state[key];
  const newChildState = runReducer(reducer, oldChildState, ...args);
  if (oldChildState === newChildState) {
    return state;
  }

  const updatedState = {...state};
  updatedState[key] = newChildState;
  return updatedState;
}

/*
 reduces a specific item in an array, returns the same state if nothing was changed
 examples:
 arr = [1,2,3]
 index = 1
 itemReducer = (item, action, ...args) => item + 1
 reduceArrayItem(itemReducer, arr, 1) == [1,3,3]

 state = {
 arr: [1,2,3]
 }
 reduceArrayItem({arr: itemReducer}, state, 1) == {
 arr: [1,3,3]
 }
 */
export function reduceArrayItem(reducer, state, index, ...args) {
  const oldItem = state[index];
  const newItem = runReducer(reducer, oldItem, ...args);
  return oldItem === newItem ? state : [...state.slice(0, index), newItem, ...state.slice(index + 1)];
}

export const emptyReducer = (state = EMPTY_OBJECT) => state;
