// @flow
import React from 'react';
import Tooltip, {TYPES} from './Tooltip';
import './Info.module.scss';

type PropTypes = {
  text: Node,
  className?: string,
  placement: string,
};
export default class Info extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <Tooltip content={this.props.text} type={TYPES.LARGE} placement={this.props.placement}>
        <div styleName="info-icon" className={`icon icn-general16-info ${this.props.className}`} />
      </Tooltip>
    );
  }
}
