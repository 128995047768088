import React, {useState, useMemo, useEffect, Fragment} from 'react';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {
  getInvAlertMeasures,
  getInvModalTrigger,
  getActionsInvestigationSettings,
  getIncidentTokenMapIsLoading,
  getFetchTriggeredAlertIsLoading,
  getOverview,
  getInvWhere,
  getInvOtherMeasures,
} from 'investigation/store/selectors';
import {INVESTIGATION_MODAL_TABS} from 'investigation/services/investigationService';
import {Box, makeStyles} from '@material-ui/core';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {palette} from 'app/styles/theme';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {getFormattedHumanDuration} from 'common/utils/dateService';
import {
  ALERT_ACTION_PARAMS,
  ICONS_TYPES,
} from 'alerts.management/components/editor/simulationArea/alertActions/services/service';
import Button, {COLORS} from 'common/componentsV2/Button';
import BaloonMessage from 'common/componentsV2/BaloonMessage';
import ContentLoader from 'common/componentsV2/ContentLoader';
import DimensionChip from '../dimensions/DimensionChip';
import Direction from '../general/Direction';

const css = makeStyles(() => ({
  actionBtn: {
    maxWidth: 254,
  },
  infoCubeContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: 200,
    width: 285,
    backgroundColor: palette.gray['100'],
    borderRadius: '10px',
    cursor: ({isClickable}) => (isClickable ? 'pointer' : 'default'),
    '&:hover': {
      backgroundColor: ({isClickable}) => (isClickable ? palette.blue[100] : null),
    },
  },
  infoCubeWrapper: {
    display: 'flex',

    flexShrink: '0',
    alignItems: 'center',
    margin: 16,
    marginBottom: 0,
  },
  infoCubeNumber: {
    height: 30,
    width: 30,
    borderRadius: '50%',
    border: 'solid 2px',
    borderColor: palette.gray['300'],
    fontWeight: 500,
    color: palette.gray['400'],
    lineHeight: '16px',
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '6px',
  },
  infoCubeTitle: {
    fontWeight: 500,
    color: palette.gray['400'],
    lineHeight: '18px',
    fontSize: 18,
  },
  infoCubeCompWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  whatTabChip: {
    maxWidth: 270,
  },
}));

const WhatInfoCube = ({onIsLoading}: {onIsLoading: Function}) => {
  const alertMeasures = useSelector(getInvAlertMeasures);
  const trigger = useSelector(getInvModalTrigger);
  const isLoading = useSelector(getFetchTriggeredAlertIsLoading);
  const overview = useSelector(getOverview);
  const classes = css();
  const what = alertMeasures[0];

  useEffect(() => {
    onIsLoading(isLoading);
  }, [isLoading]);

  if (!alertMeasures.length || isEmpty(trigger)) {
    return null;
  }

  const isOpen = trigger.status.toLowerCase() === 'open';
  const durText = getFormattedHumanDuration(trigger.duration, isOpen ? 'absolute' : null).trim();

  const fullDurText = isOpen ? ` for ${durText}` : `, lasted ${durText}`;
  const statusText = isOpen ? 'Open' : 'Closed';

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <Box display="flex" alignItems="center" fontSize={20} height="30px" fontWeight={500}>
        <Direction isDrop={overview.isDrop} isSpike={overview.isSpike} percentageText={overview.percentageText} />
      </Box>
      <Box>
        <InnerChip className={classes.whatTabChip} title={what} displayOnly type="measure" />
      </Box>
      <Box fontSize="16px" color="gray.400" lineHeight="19px" mt={1} fontWeight={400}>
        {overview.fromToText}
      </Box>
      <Box fontSize="16px" color="gray.400" lineHeight="19px" mt={1.5} fontWeight={400} display="flex">
        <Box color={isOpen ? 'red.500' : 'gray.500'} fontWeight={500} mr={isOpen ? 0.5 : 0}>
          {statusText}
        </Box>
        <span>{fullDurText}</span>
      </Box>
    </Box>
  );
};

const WhereInfoCube = ({onIsLoading}: {onIsLoading: Function}) => {
  const where = useSelector(getInvWhere);
  const isTriggerLoading = useSelector(getFetchTriggeredAlertIsLoading);
  const isTokenMapLoading = useSelector(getIncidentTokenMapIsLoading);
  const isLoading = isTriggerLoading || isTokenMapLoading;

  useEffect(() => {
    onIsLoading(isLoading);
  }, [isLoading]);

  if (isEmpty(where)) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <Box display="flex" alignItems="center" color="gray.500" fontSize={16} lineHeight="19px" fontWeight={400}>
        It seems like the anomaly
      </Box>
      <Box display="flex" alignItems="center" color="gray.500" fontSize={16} lineHeight="19px" fontWeight={400}>
        <Box component="span">occurs</Box>
        <Box component="span" fontWeight={700} mr={0.5} ml={0.5}>{`${where.percentage}%`}</Box>
        <Box component="span">in:</Box>
      </Box>
      <Box display="flex" alignItems="center" fontSize={16} fontWeight={500} mt={2}>
        <Tooltip content={where.key} type={TYPES.LARGE}>
          <Box component="span" mr={0.5} className="ellipsis" maxWidth={125}>
            {where.key}
          </Box>
        </Tooltip>
        <DimensionChip label={where.value} />
      </Box>
    </Box>
  );
};

const CorrelationsInfoCube = ({onIsLoading}: {onIsLoading: Function}) => {
  const classes = css();
  const otherMeasures = useSelector(getInvOtherMeasures);
  const isTriggerLoading = useSelector(getFetchTriggeredAlertIsLoading);
  const isTokenMapLoading = useSelector(getIncidentTokenMapIsLoading);
  const isLoading = isTriggerLoading || isTokenMapLoading;

  useEffect(() => {
    onIsLoading(isLoading);
  }, [isLoading]);

  return (
    <Box width="100%">
      <Box>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          color="gray.500"
          fontSize={16}
          lineHeight="19px"
          fontWeight={400}
        >
          {otherMeasures.length ? (
            <Fragment>
              <TypographyBox variant="caption" textAlign="center">
                We found a strong correlation with anomalies in:
              </TypographyBox>
              <Box display="flex" flexDirection="column" alignItems="center" fontSize={16} fontWeight={500} mt={2}>
                <InnerChip className={classes.whatTabChip} title={otherMeasures[0]} displayOnly type="measure" />
              </Box>
            </Fragment>
          ) : (
            <Fragment>
              <TypographyBox variant="caption" textAlign="center" lineHeight="20px">
                We found the incident is contained within the original measure.
              </TypographyBox>

              <TypographyBox variant="h5" textAlign="center" mt={2}>
                No other measures are affected
              </TypographyBox>
            </Fragment>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const AlertActionsCube = ({
  onShareClicked,
  onCloseBalloon,
  onIsLoading,
}: {
  onShareClicked: Function,
  onCloseBalloon: Function,
  onIsLoading: Function,
}) => {
  const classes = css();
  const actionsSettings = useSelector(getActionsInvestigationSettings);
  const trigger = useSelector(getInvModalTrigger);
  const isLoading = useSelector(getFetchTriggeredAlertIsLoading);

  useEffect(() => {
    onIsLoading(isLoading);
  }, [isLoading]);

  if (isEmpty(trigger)) {
    return null;
  }

  const {alertActions} = trigger;

  const goTo = (url) => {
    window.open(url);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-evenly"
      width="100%"
      height="100%"
    >
      {alertActions &&
        alertActions.map((action) => (
          <Box key={action[ALERT_ACTION_PARAMS.ACTION_ID]}>
            <Button
              extraClassName={classes.actionBtn}
              text={action[ALERT_ACTION_PARAMS.BTN_NAME]}
              colorSchema={COLORS.BLUE_500}
              icon={`icon ${[ICONS_TYPES[action[ALERT_ACTION_PARAMS.ACTION_TYPE]]]}`}
              onClick={() => goTo(action[ALERT_ACTION_PARAMS.DATA][ALERT_ACTION_PARAMS.DATA_URL])}
            />
          </Box>
        ))}
      {(!alertActions || alertActions.length < 3) && (
        <Box>
          <Button
            extraClassName={classes.actionBtn}
            text="Send to.."
            colorSchema={COLORS.GHOST_BLUE}
            icon="icn-action16-send"
            onClick={onShareClicked}
          />
        </Box>
      )}
      {(!alertActions || !alertActions.length) && (!actionsSettings || !actionsSettings.isBalloonOpened) && (
        <Box>
          <BaloonMessage
            onClose={() => onCloseBalloon('actions', 'isBalloonOpened')}
            width={255}
            link="Add an action"
            icon="icn-general24-actions"
            url={`${window.location.origin}/#!/r/alert-manager/edit/${trigger.alertConfigurationId}/settings?ref=investigation`}
          >
            <Box>Did you know you can add actions to alerts?</Box>
          </BaloonMessage>
        </Box>
      )}
    </Box>
  );
};

const STEPS = [
  {
    number: '1',
    id: 'what',
    title: 'What happened?',
    body: <WhatInfoCube />,
    targetTab: INVESTIGATION_MODAL_TABS.incident.id,
  },
  {
    number: '2',
    id: 'where',
    title: 'Where did it happen?',
    body: <WhereInfoCube />,
    targetTab: INVESTIGATION_MODAL_TABS.incident.id,
  },
  {
    number: '3',
    id: 'why',
    title: 'Why did it happen?',
    body: <CorrelationsInfoCube />,
    targetTab: INVESTIGATION_MODAL_TABS.correlations.id,
  },
  {
    number: '4',
    id: 'actions',
    title: 'What to do next?',
    body: <AlertActionsCube />,
  },
];

const InfoCube = ({
  num,
  title,
  children,
  isClickable,
  onClick,
  onShareClicked,
  onCloseBalloon,
}: {
  num: Number,
  title: String,
  children: any,
  isClickable: boolean,
  onClick: Function,
  onShareClicked: Function,
  onCloseBalloon: Function,
}) => {
  const classes = css({isClickable});

  const [isLoading, setIsLoading] = useState(undefined);

  const onIsLoading = (isLoadingFromComp) => {
    setIsLoading(isLoadingFromComp);
  };

  return (
    <Box className={classes.infoCubeContainer} onClick={onClick}>
      {isLoading && <ContentLoader rows={[{position: 'absolute', zIndex: '1', width: '100%', height: '100%'}]} />}
      <Box className={classes.infoCubeWrapper}>
        <Box className={classes.infoCubeNumber}>{num}</Box>
        <Box className={classes.infoCubeTitle}>{title}</Box>
      </Box>
      <Box className={classes.infoCubeCompWrapper}>
        {React.cloneElement(children, {onShareClicked, onCloseBalloon, onIsLoading})}
      </Box>
    </Box>
  );
};

const OverviewTab = ({
  onCubeClick,
  onShareClicked,
  onCloseBalloon,
}: {
  onCubeClick: Function,
  onShareClicked: Function,
  onCloseBalloon: Function,
}) => {
  const infoCubes = useMemo(
    () => (
      <Fragment>
        {STEPS.map((st) => (
          <InfoCube
            num={st.number}
            title={st.title}
            key={`overview-step-${st.number}`}
            isClickable={!!st.targetTab}
            onClick={() => (st.targetTab ? onCubeClick(st.targetTab) : null)}
            onShareClicked={st.id === 'actions' ? onShareClicked : null}
            onCloseBalloon={st.id === 'actions' ? onCloseBalloon : null}
          >
            {st.body}
          </InfoCube>
        ))}
      </Fragment>
    ),
    [],
  );

  return (
    <Box display="flex" alignItems="center" height="100%" justifyContent="space-between">
      {infoCubes}
    </Box>
  );
};

export default OverviewTab;
