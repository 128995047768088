// @flow
import React from 'react';
import PageLayout from 'common/componentsV2/PageLayout';
import {fetchDnsTrackerData} from 'admin.dnsTracker/store/actions';
import PageHeader from 'admin.dnsTracker/components/PageHeader';
import {connect} from 'react-redux';
import Loader from 'common/components/Loader';
import Highlighter from 'react-highlight-words';
import * as selectors from '../store/selectors';
import './DnsTrackerPage.module.scss';

type PropTypes = {
  // connect
  isLoading: boolean,
  data: Array<Object>,
  searchStr: string,

  fetchDnsTrackerData: Function,
};

@connect(
  (state) => ({
    isLoading: selectors.getDnsTrackerIsLoading(state),
    data: selectors.getDnsTrackerFilteredArr(state),
    searchStr: selectors.getDnsTrackerSearchString(state),
  }),
  {
    fetchDnsTrackerData,
  },
)
export default class DnsTrackerPage extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {fetchDnsTrackerData: fetchData} = this.props;
    fetchData();
  }

  render() {
    const {isLoading, data, searchStr} = this.props;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <PageLayout header={<PageHeader />}>
        <div styleName="root">
          {data.map((a) => (
            <div styleName="item" key={a}>
              <Highlighter autoEscape searchWords={[searchStr]} textToHighlight={a} />
            </div>
          ))}
        </div>
      </PageLayout>
    );
  }
}
