import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Highlighter from 'react-highlight-words';
import {Box} from '@material-ui/core';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {getFormattedDateMini, getFormattedDateFull} from 'common/utils/dateService';
import TableItemActions from 'assets/components/actions/TableItemActions';
import Tooltip from 'common/componentsV2/Tooltip';
import {commonStyle, TYPES} from 'assets/services/service';

type PropTypes = {
  id: String,
  title: String,
  type: String,
  ownerName: String,
  creationTime: Number,
  editTime: Number,
  searchedWords: String,
};

const useStyles = makeStyles(({palette}) => ({
  main: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: commonStyle.tableRow.padding,
    backgroundColor: palette.white['500'],
    borderBottom: `solid 1px ${palette.gray['200']}`,
    cursor: 'default',
    '&:hover': {
      backgroundColor: palette.blue['100'],
    },
  },
  item: {
    padding: commonStyle.tableItem.padding,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  typeWrapper: {
    borderRadius: 12,
    backgroundColor: palette.gray['200'],
    padding: '2px 8px',
    display: 'inline-block',
  },
  typeIcon: {
    position: 'relative',
    top: 1,
    marginRight: 5,
  },
}));

const TableItem = ({id, type, title, creationTime, editTime, ownerName, searchedWords}: PropTypes) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);

  return (
    <Grid container className={classes.main} onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Grid item className={classes.item} sm={4}>
        <Tooltip content={title}>
          <TypographyBox variant="caption" component="span">
            <Highlighter
              autoEscape
              highlightStyle={{backgroundColor: '#fcd176'}}
              searchWords={[searchedWords]}
              textToHighlight={title}
            />
          </TypographyBox>
        </Tooltip>
      </Grid>
      <Grid item className={classes.item} sm={2}>
        <Tooltip content={type}>
          <Box className={classes.typeWrapper}>
            <i
              className={
                type === TYPES.LOOKUP
                  ? `${classes.typeIcon} icn-general16-document`
                  : `${classes.typeIcon} icn-general16-split`
              }
            />
            <TypographyBox variant="subtitle1" component="span">
              {type === TYPES.LOOKUP ? 'Lookup Table' : 'Dynamic Routing'}
            </TypographyBox>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item className={classes.item} sm={2}>
        <Tooltip content={ownerName}>
          <TypographyBox variant="subtitle1" component="span">
            {ownerName}
          </TypographyBox>
        </Tooltip>
      </Grid>
      <Grid item className={classes.item} sm={2}>
        <Tooltip content={getFormattedDateFull(creationTime)}>
          <TypographyBox variant="subtitle1" component="span">
            {getFormattedDateMini(creationTime)}
          </TypographyBox>
        </Tooltip>
      </Grid>
      <Grid item className={classes.item} sm={2}>
        <Tooltip content={getFormattedDateFull(editTime)}>
          <TypographyBox variant="subtitle1" component="span">
            {getFormattedDateMini(editTime)}
          </TypographyBox>
        </Tooltip>
      </Grid>
      {hover && (
        <TableItemActions
          file={{
            id,
            type,
            title,
            creationTime,
            editTime,
            ownerName,
          }}
        />
      )}
    </Grid>
  );
};

export default TableItem;
