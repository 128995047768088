import {combineReducers} from 'redux';
import {composeReducers} from 'common/utils/reducers';
import * as actions from './actions';

const mergedCustomers = composeReducers((state = [], {type, payload}) => {
  switch (type) {
    case actions.mergeCustomers.TYPE: {
      const featuresDefaults = {};
      payload.defaults.data.forEach((i) => {
        featuresDefaults[i.name] = i.customerDefault;
      });

      return {
        ...state,
        dataOrigin: payload.customersAndGlobal.data.customers.map((i) => ({
          ...i,
          ff: {
            ...featuresDefaults,
            ...i.ff,
          },
        })),
        dataUIState: [
          {
            name: 'Mark all',
            id: 'all',
            isTrueInAllCustomers: {
              ...featuresDefaults,
            },
          },
          ...payload.customersAndGlobal.data.customers.map((i) => ({
            ...i,
            ff: {
              ...featuresDefaults,
              ...i.ff,
            },
          })),
        ],
      };
    }

    case actions.checkIfAll.TYPE: {
      const checkIfAll = () => {
        const tmpObj = {};
        const filteredArr = state.dataUIState.filter((i) => i.id !== 'all');

        Object.keys(state.dataUIState[0].isTrueInAllCustomers).forEach((k) => {
          tmpObj[k] = filteredArr.every((i) => i.ff[k] === true);
        });
        return tmpObj;
      };

      const newDataUIState = state.dataUIState
        ? state.dataUIState.map((i) => {
            if (i.id === 'all') {
              return {
                ...i,
                isTrueInAllCustomers: checkIfAll(),
              };
            }
            return {...i};
          })
        : [];

      return {
        ...state,
        dataUIState: newDataUIState,
      };
    }

    case actions.toggleAll.TYPE: {
      return {
        ...state,
        dataUIState: state.dataUIState.map((i) => ({
          ...i,
          ff: {
            ...i.ff,
            [payload.name]: !payload.isChecked,
          },
        })),
      };
    }

    case actions.toggleFeatureCustomer.TYPE: {
      const toggledData = state.dataUIState.map((i) => {
        if (i.id === payload.id) {
          return {
            ...i,
            ff: {
              ...i.ff,
              [payload.name]: !payload.isChecked,
            },
          };
        }
        return i;
      });

      return {
        ...state,
        dataUIState: toggledData,
      };
    }

    case actions.resetCustomers.TYPE: {
      return state;
    }

    default:
      return state;
  }
});

const filters = composeReducers(
  (
    state = {
      queryCustomers: '',
      queryGlobals: '',
      rndOnly: false,
      globalDefaultOn: false,
    },
    {type, payload},
  ) => {
    switch (type) {
      case actions.changeQueryCustomers.TYPE: {
        return {
          ...state,
          queryCustomers: payload,
        };
      }
      case actions.changeQueryGlobal.TYPE: {
        return {
          ...state,
          queryGlobals: payload,
        };
      }
      case actions.changeRndOnly.TYPE: {
        return {
          ...state,
          rndOnly: payload,
        };
      }
      case actions.changeGlobalDefaultOn.TYPE: {
        return {
          ...state,
          globalDefaultOn: payload,
        };
      }
      default:
        return state;
    }
  },
);

const mergedGlobal = composeReducers((state = [], {type, payload}) => {
  switch (type) {
    case actions.mergeGlobal.TYPE: {
      const merge = payload.defaults.data.map((i) => ({
        ...i,
        globalDefault:
          payload.customersAndGlobal.data.global[i.name] === undefined
            ? i.globalDefault
            : payload.customersAndGlobal.data.global[i.name],
      }));

      return {
        ...state,
        dataOrigin: merge,
        dataUIState: [...merge],
      };
    }

    case actions.toggleFeatureGlobal.TYPE: {
      const toggledData = state.dataUIState.map((i) => {
        if (i.name === payload.name) {
          return {
            ...i,
            globalDefault: !payload.isChecked,
          };
        }
        return i;
      });

      return {
        ...state,
        dataUIState: toggledData,
      };
    }

    case actions.resetGlobal.TYPE: {
      return state;
    }

    default:
      return state;
  }
});

export default combineReducers({
  mergedCustomers,
  mergedGlobal,
  filters,
});
