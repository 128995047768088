// @flow
import React from 'react';
import 'moment-timezone';
import Slider from 'common/componentsV2/Slider';

import './SchedulerEditorModal.module.scss';

type PropTypes = {
  maxMissingFiles: String,
  onChange: Function,
};

export default class MaxMissingFiles extends React.PureComponent {
  props: PropTypes;

  render() {
    const {onChange, maxMissingFiles} = this.props;
    return (
      <div>
        <div styleName="titleWrapper">Retry Policy:</div>
        <div styleName="slideWrapper">
          <div styleName="slideWrapper-text">
            <input
              type="number"
              min="0"
              max="10"
              onChange={(e) => onChange(e.target.value)}
              styleName="textInput"
              automation-id="schedulerEditorModalMaxMissingFiles"
              value={maxMissingFiles}
            />
          </div>
          <div styleName="slideWrapper-slider">
            <Slider
              automation-id="schedulerEditorModalMaxMissingFilesSlider"
              automationId="Slider"
              value={maxMissingFiles}
              onChange={(val) => onChange(val)}
              onRelease={(val) => onChange(val)}
              min={0}
              max={10}
              step={1}
            />
          </div>
        </div>
        <div styleName="slideTextWrapper">
          {maxMissingFiles === 0 && 'We will not try to collect the data again if it did not arrive the first time.'}
          {maxMissingFiles === 1 &&
            `We will try to collect the data 1 more time.
            For example, if it doesn't arrive at 12:00 AM, we will try again at 12:15.`}
          {maxMissingFiles > 1 &&
            `We will try to collect the data ${maxMissingFiles} additional times.
            For example, if it doesn't arrive at 12:00 AM, we will try again at 12:15, 12:30 and so on.`}
        </div>
      </div>
    );
  }
}
