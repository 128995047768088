// @flow
import React, {Fragment, useMemo, useEffect} from 'react';
import TooltipArea from 'common/componentsV2/TooltipArea';
import {useSelector, useDispatch, useStore} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import {Form, Field} from 'react-final-form';
import {Box} from '@material-ui/core';
import Input from 'common/componentsV2/Input';
import SelectChipsInput from 'common/componentsV2/SelectChipsInput';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {getUsersGroupsListsWithoutDeleteGroup} from 'admin.users/store/selectors';
import Button, {COLORS} from 'common/componentsV2/Button';
import * as actions from 'dashboards/store/actions';
import {getUserName, optionsIcons, OPTION_TILES_VALUES, optionsMode} from 'dashboards/services/dashboardService';
import useAsyncAction from 'common/utils/useAsyncAction';
import {
  getUpdateDashboardSettings,
  getCreateDuplicateDashboards,
  getTagsOptions,
  getDataDashboards,
} from 'dashboards/store/selectors';
import ColorPicker, {COLOR_NAME} from 'common/componentsV2/ColorPicker';
import IconPicker from 'common/componentsV2/IconPicker';
import {getProfileId, getMeOwnerOrganization, getUserProfile} from 'profile/store/selectors';
import useDashboardState from 'dashboards/hooks/useDashboardState';
import NoSearchBlueLeanSelect from 'common/componentsV2/ddl/NoSearchBlueLeanSelect';

const isRequired = (val) => val === undefined;
const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const MODAL_TYPES = {...OPTION_TILES_VALUES};
const colorOptions = [
  COLOR_NAME.GRAY,
  COLOR_NAME.PURPLE,
  COLOR_NAME.AZURE,
  COLOR_NAME.ROSE,
  COLOR_NAME.YELLOW,
  COLOR_NAME.MANGO,
  COLOR_NAME.NAVYBLUE,
  COLOR_NAME.EUCALIPTUS,
  COLOR_NAME.LIME,
  COLOR_NAME.ROSE_400,
  COLOR_NAME.LILACH_400,
  COLOR_NAME.EGGPLANT,
  COLOR_NAME.TEAL,
  COLOR_NAME.TOMATO,
];

const DashboardSettingsModal = React.memo(
  ({
    type,
    dashboardId,
    onClose,
    isDashboardManagementModal,
  }: {
    type: String,
    dashboardId: String,
    onClose: Function,
    isDashboardManagementModal: boolean,
  }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const store = useStore();
    const isSettingsType = MODAL_TYPES.SETTINGS === type;
    const isNew = MODAL_TYPES.NEW === type;
    const isDuplicate = MODAL_TYPES.DUPLICATE === type;
    const dataDashboards = useSelector(getDataDashboards);
    const getDashboardData = useDashboardState(dashboardId);
    const dashboard = getDashboardData.data || EMPTY_OBJECT;
    const selectorUpdateDashboardSettings = useSelector(getUpdateDashboardSettings);
    const createDuplicateDashboardsData = useSelector(getCreateDuplicateDashboards);
    const userId = useSelector(getProfileId);
    const userProfile = useSelector(getUserProfile);
    const meOwnerOrganization = useSelector(getMeOwnerOrganization);
    const optionsOwner = useSelector(getUsersGroupsListsWithoutDeleteGroup);
    const optionsTags = useSelector(getTagsOptions);
    const isV1Creation = history.location.search.includes('isV1=true');

    useEffect(() => {
      if (isDuplicate && isDashboardManagementModal) {
        dispatch(
          dashboard.isV1 ? actions.fetchDashboardV1({id: dashboardId}) : actions.fetchDashboard({id: dashboardId}),
        );
      }
    }, []);

    useEffect(() => {
      if (!Object.values(dashboard).length) {
        dispatch(actions.setDashboardData(dataDashboards.find((d) => d._id === dashboardId) || EMPTY_OBJECT));
      }
    }, [dataDashboards]);

    const updateDashboardSettings = useAsyncAction(actions.updateDashboardSettings, selectorUpdateDashboardSettings);
    const createDuplicateDashboard = useAsyncAction(actions.createDuplicateDashboard, createDuplicateDashboardsData);

    const memoizedTags = useMemo(
      () => (dashboard.tags || EMPTY_ARRAY).map((tag) => ({value: tag.id, label: tag.display})),
      [dashboard.tags],
    );
    const memoizedOwner = useMemo(
      () => ({
        label: getUserName(optionsOwner, isNew ? userId : dashboard.ownerUser),
        value: isNew ? userId : dashboard.ownerUser,
      }),
      [dashboard.ownerUser, userId, isNew],
    );

    const memoizedIcon = useMemo(
      () =>
        optionsIcons.find((option) => option.value === dashboard.icon) ||
        optionsIcons.find((o) => (dashboard.isV1 ? o.value === 'DefaultOld' : o.value === 'Default')),
      [dashboard.icon],
    );

    const memoizedMode = useMemo(() => optionsMode.find((option) => option.value === dashboard.mode) || optionsMode[0]);

    const initialValues = {
      // eslint-disable-next-line no-nested-ternary
      name: isNew
        ? `${userProfile.firstName}'s dashboard`
        : isSettingsType
        ? dashboard.name
        : `${dashboard.name} - copy`,
      tags: memoizedTags,
      ownerUser: memoizedOwner,
      icon: memoizedIcon,
      mode: memoizedMode,
      color: isNew ? COLOR_NAME.GRAY : dashboard.color || COLOR_NAME.GRAY,
    };

    const actionSubmit = (val) => {
      const tags = val.tags.map((tag) => ({
        display: tag.label,
        id: tag.value,
        isTag: true,
      }));

      if (isSettingsType) {
        const bodySettings = {
          name: val.name,
          tags,
          ownerUser: val.ownerUser.value,
          editableBy: [val.ownerUser.value || dashboard.ownerUser],
          icon: val.icon.value,
          mode: val.mode.value,
          color: val.color,
        };

        return (
          updateDashboardSettings(bodySettings, {dashboardId: dashboard._id, isV1: dashboard.isV1})
            .then(() => {
              onClose();
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.log(error))
        );
      }

      const {autoRefreshInterval, dateRange, ownerOrganization, ownerUser, tiles, selectorsFilter} = dashboard;

      const bodyDuplicate = {
        name: val.name,
        tags,
        editableBy: [val.ownerUser.value || ownerUser],
        autoRefreshInterval,
        dateRange,
        ownerOrganization,
        tiles,
        ownerUser: val.ownerUser.value,
        icon: val.icon.value,
        color: val.color,
        selectorsFilter,
      };

      const bodyCreateDashboard = {
        name: val.name,
        tags,
        editableBy: [val.ownerUser.value || userId],
        tiles: [],
        ownerUser: val.ownerUser.value,
        icon: val.icon.value,
        color: val.color,
        ownerOrganization: meOwnerOrganization,
      };

      return createDuplicateDashboard(isNew ? bodyCreateDashboard : bodyDuplicate, {
        isV1: isV1Creation || dashboard.isV1,
      }).then(() => {
        const response = getCreateDuplicateDashboards(store.getState());
        if (isV1Creation || dashboard.isV1) {
          dispatch(actions.setLastViewDashboard({type: 'dashboardsV2', itemId: response.data._id, data: {}}, {userId}));
          window.location.replace(`${window.location.origin}/#!/dashboards?activeTab=1&tabs=main;${response.data._id}`);
        } else {
          history.push(`/dashboards/${response.data._id}`);
        }
      });
    };
    return (
      <Modal id="dashboard-modal" onClose={onClose} isOpen size={SIZES.SMALL} isAnimation={false} isCloseButtonHidden>
        <TypographyBox variant="h2">
          {// eslint-disable-next-line no-nested-ternary
          isNew ? 'Create a new dashboard' : isSettingsType ? 'Dashboard Settings' : 'Duplicate Dashboard'}
        </TypographyBox>
        <Form initialValues={initialValues} onSubmit={actionSubmit}>
          {({hasValidationErrors, pristine, handleSubmit}) => (
            <React.Fragment>
              <Box width={1} mt={2}>
                <Box mb={2.5}>
                  <Field name="name" validate={isRequired}>
                    {({input: {value, onChange}, meta: {invalid}}) => (
                      <Fragment>
                        <div className="text16reg lineHeight_16 mb_1">Name:</div>
                        <Input
                          automationId="nameInputDashboard"
                          type="text"
                          value={value}
                          onChange={onChange}
                          isInvalid={invalid}
                          invalidMessage="Required"
                        />
                      </Fragment>
                    )}
                  </Field>
                </Box>
                <Box mb={2.5}>
                  <Field name="tags">
                    {({input: {value, onChange}}) => (
                      <Fragment>
                        <div className="text16reg lineHeight_16 mb_1">Labels:</div>
                        <SelectChipsInput
                          value={value.map((option) => ({value: option.value, label: option.label}))}
                          options={optionsTags.map((option) => ({value: option.value, label: option.label}))}
                          onChange={(values) =>
                            onChange(
                              values.map((item) => ({
                                value: item.value,
                                label: item.label,
                              })),
                            )
                          }
                          onRemove={(removed) => {
                            onChange(value.filter((item) => item.value !== removed.value));
                          }}
                          placeholder="Add labels"
                        />
                      </Fragment>
                    )}
                  </Field>
                </Box>
                <Box mb={2.5} width={250}>
                  <Field name="ownerUser">
                    {({input: {value, onChange}}) => (
                      <Fragment>
                        <div className="text16reg lineHeight_16 mb_1">Owner:</div>
                        <SelectAndt
                          automationId="dashboardTileEditSelectOwner"
                          options={optionsOwner}
                          type={TYPE_NEW_SEARCH}
                          theme={THEME_NOT_HIGHLIGHTED}
                          placeholder="Select"
                          value={value}
                          onChange={onChange}
                          optionHeight={40}
                        />
                      </Fragment>
                    )}
                  </Field>
                </Box>
              </Box>
              {(isNew && !isV1Creation) || (isSettingsType && !dashboard.isV1) ? (
                <React.Fragment>
                  <Box display="flex" mb={3}>
                    <Box mr={7.5}>
                      <div className="text16reg lineHeight_16 mb_1">Dashboard Color</div>
                      <Field name="color">
                        {({input: {value, onChange}}) => (
                          <ColorPicker value={value} onChange={onChange} options={colorOptions} menuWidth={160} />
                        )}
                      </Field>
                    </Box>
                    <Box>
                      <div className="text16reg lineHeight_16 mb_1">Dashboard Icon</div>
                      <Field name="icon">
                        {({input: {value, onChange}}) => (
                          <IconPicker
                            value={value}
                            onChange={onChange}
                            options={optionsIcons.filter((o) => o.value !== 'DefaultOld')}
                          />
                        )}
                      </Field>
                    </Box>
                  </Box>
                  <TooltipArea
                    isAlwaysVisible
                    automationId="filtersModeContainer"
                    text="Change this to Apply Mode if you want the filters to only be applied explicitly when you are done filtering, instead of automatically applying each one."
                  >
                    {(info) => (
                      <Box display="flex" pb={9} alignItems="center">
                        <div className="text16reg lineHeight_16 mr_1">Filters Mode:</div>
                        <Field name="mode">
                          {({input: {value, onChange}}) => (
                            <NoSearchBlueLeanSelect
                              onChange={onChange}
                              value={value}
                              options={optionsMode}
                              buttonWidth={90}
                              menuWidth={100}
                            />
                          )}
                        </Field>
                        {info}
                      </Box>
                    )}
                  </TooltipArea>
                </React.Fragment>
              ) : (
                <Box pb={12.5} />
              )}
              <Box width="100%" display="flex" justifyContent="space-between">
                <Button colorSchema={COLORS.GRAY_300} onClick={onClose} text="Cancel" />
                <Button
                  colorSchema={COLORS.BLUE_500}
                  text={
                    // eslint-disable-next-line no-nested-ternary
                    isNew ? 'Create' : isSettingsType ? 'Save' : 'Duplicate'
                  }
                  onClick={handleSubmit}
                  isDisabled={
                    (isSettingsType &&
                      (pristine || hasValidationErrors || selectorUpdateDashboardSettings.isLoading)) ||
                    hasValidationErrors ||
                    createDuplicateDashboardsData.isLoading
                  }
                />
              </Box>
            </React.Fragment>
          )}
        </Form>
      </Modal>
    );
  },
);

export default DashboardSettingsModal;
