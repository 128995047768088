// @flow
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {segmentClickEvent} from 'common/store/actions';
import Box from '@material-ui/core/Box';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import {ReactComponent as EmptyIcon} from 'app/images/empty-dropdown.svg';
import {delay} from 'lodash';
import FormDdl from './FormDdl';
import './FormDdlSelect.module.scss';
import FormDdlSearchBar from './FormDdlSearchBar';
import Spinner, {SIZES} from '../../Spinner';

type PropTypes = {
  options: Array<Object>,
  selected: any,
  button: Node,
  optionComponent: Node,
  onChange: Function,
  width: number,
  maxWidth: number,
  height: number,
  disabled: boolean,
  isMulti: boolean,
  toggleOpen: boolean, // this value, when changed, doesn't matter
  // if from true to false or false to true, will open the DD
  isFlexibleDDL: boolean,
  placement: string,
  popoverContainerClassName: string,
  isUseSearch: boolean,
  buttonWidth: number,
  isLoading: boolean,
  onParentSearchChange: Function,
  onParentSearchValue: string,
  onHoverOpenMenu: boolean,
  heightEmptyDDL: number,
  footerComponent: Node,
  position: String,
  onToggle: Function,
  automationId: String,
  id: String,
  specialCharacterLastSorting: string,
  isDisabledSorting: boolean,
  sortAlphabetic: boolean,
  horizontalOffset: number,
  isOpen: boolean,
};

const FormDdlSelect = (props: PropTypes) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(props.isOpen || false);
  const [highlightText, setHighlightText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(props.options);

  const orderList = () => {
    const {options, isMulti, selected, specialCharacterLastSorting, sortAlphabetic = false} = props;
    if (options && options.length && isMulti && selected && selected) {
      const f = [...options];
      f.sort((a, b) => {
        const aIsSelected = selected.findIndex((s) => s === a) > -1;
        const bIsSelected = selected.findIndex((s) => s === b) > -1;
        if (aIsSelected && !bIsSelected) {
          return -1;
        }
        if (bIsSelected && !aIsSelected) {
          return 1;
        }
        if (sortAlphabetic && ((aIsSelected && bIsSelected) || (!aIsSelected && !bIsSelected))) {
          if (typeof specialCharacterLastSorting === 'undefined') {
            return a > b ? 1 : -1;
          }
          if (b.charAt(0) === specialCharacterLastSorting && a.charAt(0) !== specialCharacterLastSorting) {
            return -1;
          }
          if (a.charAt(0) === specialCharacterLastSorting && b.charAt(0) !== specialCharacterLastSorting) {
            return 1;
          }
          return a > b ? 1 : -1;
        }
        return 0;
      });
      setFilteredOptions(f);
    }
  };

  const onSearchChange = (val) => {
    setHighlightText(val);
    const f = props.options.filter((o) => {
      if (o.type === 'HEADER') {
        return true;
      }

      if (o.label) {
        return o.label.toLocaleLowerCase().indexOf(val.toLocaleLowerCase()) > -1;
      }
      return o.toLocaleLowerCase().indexOf(val.toLocaleLowerCase()) > -1;
    });
    const filtered = f.filter((ele) => {
      if (ele.type !== 'HEADER') {
        return true;
      }
      return f.findIndex((element) => element.type === ele.value) > -1;
    });
    setFilteredOptions(filtered);
  };

  useEffect(() => {
    if (props.onToggle) {
      props.onToggle(isOpen);
    }
    if (props.isUseSearch && props.isMulti) {
      onSearchChange('');
    }
  }, [isOpen]);

  useEffect(() => {
    setFilteredOptions(props.options);
    orderList();
    // The conditions for this dependency is designed to support the functionality in custom time frame
    // where if you add new custom time frame, it should be placed on top and not sort the list
    // so only in DateRangeDDL we have the isDisabledSorting flag turned on
  }, [props.isDisabledSorting ? props.options : props.options.length]);

  useEffect(() => {
    orderList();
  }, [props.selected]);

  useEffect(() => {
    if (typeof props.toggleOpen !== 'undefined') {
      setOpen(true); // Changing toggleOpen, never mind if true or false, will open the menu;
    }
  }, [props.toggleOpen]);

  const toggleOpen = (isHover) => {
    if (!isOpen) {
      orderList();
    }
    if (props.onHoverOpenMenu) {
      delay(() => setOpen(isHover), 200);
      return;
    }

    setOpen(!isOpen);
  };

  const onClick = (e, val) => {
    e.stopPropagation();
    if (props.isMulti) {
      if (val.type === 'HEADER') {
        return;
      }
      dispatch(
        segmentClickEvent({
          type: 'option-selected',
          name: props.automationId || 'form ddl',
          value: val,
          automationId: props.automationId,
        }),
      );
      const index = (props.selected || []).findIndex((s) => s === val);
      if (index > -1) {
        props.onChange((props.selected || []).slice(0, index).concat(props.selected.slice(index + 1)));
      } else {
        props.onChange([...props.selected, val]);
      }
    } else {
      props.onChange(val);
      if (val.type !== 'HEADER') {
        setOpen(false);
      }
    }
  };

  const isSelected = (option) => {
    if (props.isMulti) {
      return (props.selected || []).findIndex((s) => s === option) > -1;
    }

    return props.selected === option;
  };

  const mapKey = (option) => {
    if (typeof option === 'string') {
      return option;
    }
    if (typeof option.label === 'string' || typeof option.text === 'string') {
      return option.startDate || option.relativeLast || option.value;
    }
    return null;
  };

  const popOverComponent = (
    <div className="form-menu" styleName="pop-over-container">
      {(props.isMulti || props.isUseSearch) && (
        <FormDdlSearchBar
          isAutofocus
          value={props.onParentSearchValue ? props.onParentSearchValue : highlightText}
          onChange={props.onParentSearchChange ? props.onParentSearchChange : onSearchChange}
        />
      )}
      {filteredOptions.length ? (
        <div
          styleName={`scroll-container ${props.isFlexibleDDL ? 'flexible' : ''}`}
          style={{height: props.height ? props.height : undefined}}
        >
          {filteredOptions.map((option) => (
            <div key={mapKey(option)} onClick={(e) => onClick(e, option)}>
              {React.cloneElement(props.optionComponent, {
                data: option,
                isSelected: isSelected(option),
              })}
            </div>
          ))}
        </div>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
          height={props.heightEmptyDDL ? props.heightEmptyDDL : 500}
        >
          <EmptyIcon width={101} height={71} />
          <TypographyBox variant="subtitle2" marginTop={2}>
            {highlightText.length > 0 ? 'Nothing Found' : 'Nothing to Show'}
          </TypographyBox>
        </Box>
      )}
      <div styleName="footer-component">{props.footerComponent}</div>
    </div>
  );

  const loading = (
    <div className="form-menu" styleName="pop-over-container is-loading">
      <Spinner color="#3d4c59" size={SIZES.BIG_60} />
    </div>
  );

  let placement = 'bottom';
  if (props.placement) {
    // eslint-disable-next-line prefer-destructuring
    placement = props.placement;
  }
  return (
    <FormDdl
      popoverComponent={props.isLoading ? loading : popOverComponent}
      buttonComponent={props.button}
      popoverContainerClassName={props.popoverContainerClassName}
      onToggle={toggleOpen}
      isOpen={isOpen}
      width={props.width}
      disabled={props.disabled}
      maxWidth={props.maxWidth}
      placement={placement}
      buttonWidth={props.buttonWidth}
      onHoverOpenMenu={props.onHoverOpenMenu}
      position={props.position}
      automationId={props.automationId}
      id={props.id}
      horizontalOffset={props.horizontalOffset}
    />
  );
};

export default FormDdlSelect;
