import TimeZoneCommon from 'common/componentsV2/TimeZone';

import React from 'react';
import {useField} from 'react-final-form';

const selectProps = {
  maxMenuHeight: 250,
  minMenuHeight: 250,
};

const TimeZone = () => {
  const {
    input: {value, onChange},
  } = useField('timeZone');
  return (
    <div>
      <div>Time Zone</div>
      <TimeZoneCommon automationId="timeZone" value={value} onChange={onChange} selectProps={selectProps} />
    </div>
  );
};

export default TimeZone;
