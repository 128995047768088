// @flow
import React, {Fragment, useEffect, useState} from 'react';
import {orderBy} from 'lodash';
import {getChannelsLoading} from 'alerts.channels/store/selectors';
import {useSelector} from 'react-redux';
import {TinyScrollBox} from 'common/componentsV2/boxTools';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import channelTypes from 'channels/constants/channelTypes';
import {StringParam, useQueryParams} from 'use-query-params';
import {ReactComponent as NoChannelsIcon} from 'channels/icons/svgs/NoChannels.svg';
import ChannelsGroup from './ChannelsGroup';
import ChannelItem from './ChannelItem';
import './ChannelsListBody.module.scss';

const sortIteratees = {
  title: (channel) => channel.name,
  owner: (channel) => JSON.parse(channel.tags).owner,
  alertsConnected: (channel) => channel.alerts.length,
  dateCreated: (channel) => channel.createTime,
};

const ChannelListBody = ({
  listState,
  setMixedListState,
  channelsData,
  isNoChannels,
  selectedItem,
  setSelectedItem,
}: {
  listState: boolean,
  channelsData: Array,
  isNoChannels: boolean,
  selectedItem: Object,
  setSelectedItem: Function,
  setMixedListState: Function,
}) => {
  const [queryParams] = useQueryParams({
    sort: StringParam,
    order: StringParam,
    searchQuery: StringParam,
    type: StringParam,
    owner: StringParam,
  });

  const isLoading = useSelector(getChannelsLoading);
  const [openSources, setOpenSources] = useState([]);

  const closeAllSources = () => {
    setOpenSources([]);
  };

  const openAllSources = () => {
    setOpenSources(channelTypes.map((source) => source.id));
  };

  const getChannelsCountByType = (type) => {
    return channelsData.filter((channel) => channel.channelMeta.id === type).length;
  };

  useEffect(() => {
    if (channelsData.length) {
      if (listState === 'COLLAPSE' && openSources.length !== 0) {
        closeAllSources();
      }
      if (listState === 'EXPAND' && openSources.length !== channelTypes.length) {
        openAllSources();
      }
    }
  }, [listState, channelsData]);

  const isSourceToBeDisplayed = (source) => {
    const count = getChannelsCountByType(source.value);
    return (
      count || // has streams
      (!count &&
        queryParams.type &&
        queryParams.type.split(',').includes(source.type) &&
        !queryParams.searchQuery &&
        !queryParams.owner)
    );
  };

  const handleClick = (id) => {
    let openSourcesArr = [...openSources];
    if (openSources.includes(id)) {
      openSourcesArr = openSourcesArr.filter((val) => val !== id);
    } else {
      openSourcesArr.push(id);
    }
    setMixedListState();
    setOpenSources(openSourcesArr);
  };

  const isSourceOpen = (id) => openSources.includes(id);
  const getChannelsByType = (type) => {
    const channelsArr = orderBy(channelsData, sortIteratees[queryParams.sort || 'name'], queryParams.order).filter(
      (channel) => channel.channelMeta.id === type,
    );
    if (channelsArr.length) {
      return channelsArr.map((channel) => (
        <ChannelItem
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          key={`channel-item-${channel.id}`}
          channel={channel}
        />
      ));
    }
    return <div styleName="no-stream">No channels of this type</div>;
  };

  let isEmptyList = true;

  if (isLoading && !channelsData.length) {
    return (
      <div styleName="centered-text">
        <Spinner color="#3d4c59" size={SIZES.BIG_60} />
      </div>
    );
  }

  return (
    <div styleName="container">
      <TinyScrollBox width="100%" pr={1.25} height={1} css={{overflowY: 'auto'}}>
        {isNoChannels ? (
          <div styleName="no-data-container">
            <div styleName="icon">
              <NoChannelsIcon />
            </div>
            <div styleName="title">No Channels</div>
            <div styleName="text">Start getting alerts by creating a channel</div>
          </div>
        ) : null}

        {channelTypes.map((channelType) => {
          if (isSourceToBeDisplayed(channelType)) {
            isEmptyList = false;
            return (
              <Fragment key={`channel-type_${channelType.id}`}>
                <ChannelsGroup
                  channelType={channelType}
                  channelsCount={getChannelsCountByType(channelType.id)}
                  isOpen={isSourceOpen(channelType.id)}
                  onClickFunction={handleClick}
                />
                {isSourceOpen(channelType.id) ? (
                  <div styleName="table-container">{getChannelsByType(channelType.id)}</div>
                ) : null}
              </Fragment>
            );
          }
          return null;
        })}

        {isEmptyList && !isNoChannels ? (
          <div styleName="no-data-container">
            <div styleName="icon">
              <NoChannelsIcon />
            </div>
            <div styleName="title">No Channels to Show</div>
            <div styleName="text">Change Filters to See Channels</div>
          </div>
        ) : null}
      </TinyScrollBox>
    </div>
  );
};

export default ChannelListBody;
