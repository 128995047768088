// @flow
import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {useField} from 'react-final-form';
import {isObject, isString, orderBy} from 'lodash';
import IconTabsBar from 'common/componentsV2/IconTabsBar';
import {ReactComponent as IconStatTile} from 'dashboards/images/statTile.svg';
import {ReactComponent as IconStatTileActive} from 'dashboards/images/statTileActive.svg';
import {ReactComponent as IconStatTileGauge} from 'dashboards/images/statTileGauge.svg';
import {ReactComponent as IconStatTileGaugeActive} from 'dashboards/images/statTileGaugeActive.svg';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import Measure from 'dashboards/components/fields/Measure';
import Title from 'dashboards/components/fields/Title';
import {ExpandableBox} from 'common/componentsV2/ExpandableSections';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import SwitchButton from 'common/componentsV2/SwitchButton';
import Input from 'common/componentsV2/Input';
import {
  OPTIONS_SCALAR_TRANSFORM_TYPE,
  OPTIONS_UNIT_TYPE,
  OPTIONS_VALUE_TYPE_VALUES,
} from 'dashboards/services/dashboardService';
import InputAuto from 'alerts.management/components/editor/simulationArea/utils/InputAuto';
import Tooltip from 'common/componentsV2/Tooltip';
import ColorPicker, {COLOR_NAME} from 'common/componentsV2/ColorPicker';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';

const EMPTY_ARRAY = [];

export const STAT_TILES_VALUES = {
  NUMBER: 'number',
  GAUGE: 'gauge',
};

export const STAT_TILES_ITEMS = [
  {
    label: 'Number',
    value: STAT_TILES_VALUES.NUMBER,
    onImage: IconStatTileActive,
    offImage: IconStatTile,
  },
  {
    label: 'Gauge',
    value: STAT_TILES_VALUES.GAUGE,
    onImage: IconStatTileGaugeActive,
    offImage: IconStatTileGauge,
  },
];

const optionsColors = [
  COLOR_NAME.EUCALIPTUS,
  COLOR_NAME.YELLOW_400,
  COLOR_NAME.ORANGE_400,
  COLOR_NAME.RED_400,
  COLOR_NAME.GRAY_400,
  COLOR_NAME.BLUE,
  COLOR_NAME.TOMATO,
  COLOR_NAME.LILACH_600,
  COLOR_NAME.EGGPLANT,
];

const defaultColorOptions = [
  COLOR_NAME.BLUE,
  COLOR_NAME.TOMATO,
  COLOR_NAME.RED_400,
  COLOR_NAME.LILACH_600,
  COLOR_NAME.EGGPLANT,
];

const additionalProps = {min: 0};

const minHeightThresholdOpened = {minHeight: 260};
const visible = {
  visibility: 'visible',
};

const useStyles = makeStyles(({palette}) => ({
  itemScaleValue: {
    display: 'flex',
    marginTop: 24,
    cursor: 'pointer',
    '&:hover $iconTooltip': {
      color: palette.gray[400],
    },
  },
  iconTooltip: {
    color: palette.gray[300],
  },
  inputWrapper: {
    width: 200,
    height: 44,
    marginTop: 4,
  },
  thresholdItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    '&:hover $iconClearThreshold': {
      ...visible,
    },
  },
  inputThreshold: {
    width: 115,
    height: 44,
    marginRight: 12,
  },
  iconClearThreshold: {
    visibility: 'hidden',
    cursor: 'pointer',
    marginLeft: 12,
  },
  buttonAddThreshold: {
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: palette.blue[500],
    color: palette.white[500],
    borderRadius: 6,
    cursor: 'pointer',
  },
}));

const tooltipText = (option) => {
  if (option.value === OPTIONS_VALUE_TYPE_VALUES.CURRENT) {
    return 'Last value';
  }

  return `${option.label} value of the metric in the selected timeframe`;
};

const OptionComponent = ({data, isSelected}: {data: Object, isSelected: boolean}) => {
  return (
    <OptionComponentSimple
      data={data}
      isSelected={isSelected}
      tooltip={{text: tooltipText(data), icon: 'icn-general16-info'}}
    />
  );
};

const StatTileSettings = () => {
  const classes = useStyles();
  const [isDisplayOptionsOpen, setDisplayOptionsOpen] = useState(false);
  const [isValueOptionsOpen, setValueOptionsOpen] = useState(false);
  const [isThresholdsOptionsOpen, setThresholdsOptionsOpen] = useState(false);
  const [isGaugeOptionsOpen, setGaugeOptionsOpen] = useState(false);

  const {
    input: {value: tileType, onChange: onChangeType},
  } = useField('tileType');
  const {
    input: {value: scalarTransformType, onChange: onChangeScalarTransformType},
  } = useField('scalarTransformType');
  const {
    input: {value: valueUnit, onChange: onChangeUnit},
  } = useField('unit');
  const {
    input: {checked: valueDecimal, onChange: onChangeDecimal},
  } = useField('decimal', {type: 'checkbox'});
  const {
    input: {checked: valueAbbreviate, onChange: onChangeAbbreviate},
  } = useField('abbreviate', {type: 'checkbox'});
  const {
    input: {checked: valueMeasure, onChange: onChangeMeasure},
  } = useField('showMeasure', {type: 'checkbox'});
  const {
    input: {checked: isShowValueLabels, onChange: onChangeValueLabels},
  } = useField('isShowValueLabels', {type: 'checkbox'});
  const {
    input: {checked: valueGraph, onChange: onChangeGraph},
  } = useField('showGraph', {type: 'checkbox'});
  const {
    input: {value: valueNoData, onChange: onChangeNoData},
  } = useField('inputNoData');
  const {
    input: {value: minScaleValue, onChange: onChangeMinScale},
  } = useField('minScale');
  const {
    input: {value: maxScaleValue, onChange: onChangeMaxScale},
  } = useField('maxScale');
  const {
    input: {value: thresholdsValue, onChange: onChangeThresholds},
  } = useField('thresholds');
  const {
    input: {value: valueTitle},
  } = useField('title');

  const thresholdsLength = thresholdsValue.length;
  const isGaugeTileType = tileType === STAT_TILES_VALUES.GAUGE;

  useEffect(() => {
    if (valueTitle) {
      onChangeMeasure(false);
    }
  }, [valueTitle]);

  const handleChangeThresholds = useCallback(
    (value, index) => {
      onChangeThresholds(
        thresholdsValue.map((item, i) => {
          if (i === index) {
            return {
              color: isString(value) ? value : item.color,
              value: isObject(value) ? value.target.value : item.value,
            };
          }
          return item;
        }),
      );
    },
    [thresholdsValue],
  );

  const handleAddThresholds = useCallback(() => {
    const color = defaultColorOptions[thresholdsLength];
    const defaultValue = thresholdsLength > 1 ? Number(thresholdsValue[0].value) + 10 : 100;
    onChangeThresholds([
      {
        value: defaultValue,
        color,
      },
      ...thresholdsValue,
    ]);
  }, [thresholdsValue, thresholdsLength]);

  const handleDeleteThresholds = useCallback(
    (index) => {
      onChangeThresholds(thresholdsValue.filter((item, i) => index !== i));
    },
    [thresholdsValue],
  );

  const handleBlur = useCallback(() => {
    const defaultOption = thresholdsValue[thresholdsLength - 1];
    const thresholdsSorting = orderBy(
      thresholdsValue.filter((o) => o.value !== '') || EMPTY_ARRAY,
      (o) => parseInt(o.value, 10),
      ['desc'],
    );
    onChangeThresholds([...thresholdsSorting, defaultOption]);
  }, [thresholdsValue, thresholdsLength]);

  const handleChangeScalarType = (obj) => {
    onChangeScalarTransformType(obj.value);
  };

  const unitOption = useMemo(
    () => (
      <Fragment>
        <div className="text16reg lineHeight_16 mt_2-5">Unit</div>
        <div className="mt_0-5">
          <SelectAndt
            automationId="unitOptionsStatTile"
            theme={THEME_NOT_HIGHLIGHTED}
            type={TYPE_NEW_SEARCH}
            options={OPTIONS_UNIT_TYPE}
            optionHeight={40}
            onChange={onChangeUnit}
            value={valueUnit}
            placeholder="Duration, Currency, Number"
            buttonWidth={190}
            menuWidth={260}
            minMenuHeight={200}
            maxMenuHeight={250}
            appendToBody={false}
          />
        </div>
      </Fragment>
    ),
    [valueUnit],
  );

  const displayTypeValue = useMemo(() => {
    const selectedOption = OPTIONS_SCALAR_TRANSFORM_TYPE.find((option) => option.value === scalarTransformType);
    return (
      <div className="display_flex alignItems_center mt_1-5">
        <TypographyBox variant="h5" mr={1}>
          Display:
        </TypographyBox>
        <FormDdlSelect
          options={OPTIONS_SCALAR_TRANSFORM_TYPE}
          selected={selectedOption}
          button={<BigDropdownButton blueLean label={selectedOption.label} />}
          optionComponent={<OptionComponent />}
          onChange={handleChangeScalarType}
          width={180}
          maxWidth={180}
          buttonWidth={120}
          automationId="scalarTransformType"
        />
      </div>
    );
  }, [scalarTransformType]);

  const decimalSwitcher = useMemo(
    () => (
      <Fragment>
        <div className="display_flex alignItems_center mt_1-5">
          <div className="text16reg lineHeight_16 mr_1">Decimals</div>
          <SwitchButton isChecked={valueDecimal} onChange={onChangeDecimal} automationId="decimals" />
        </div>
      </Fragment>
    ),
    [valueDecimal],
  );

  const noDataInput = useMemo(
    () => (
      <Fragment>
        <div className="mt_3 pb_2-5 mb_1">
          <div className="text16reg lineHeight_16 mb_0-5">What to display in case there is no value</div>
          <Input type="text" onChange={onChangeNoData} value={valueNoData} placeHolder="N/A" />
        </div>
      </Fragment>
    ),
    [valueNoData],
  );

  const showMeasureSwitcher = useMemo(
    () => (
      <Fragment>
        <div className="display_flex alignItems_center mt_3">
          <div className="text16reg lineHeight_16 mr_1">Show Measure</div>
          <SwitchButton isChecked={valueMeasure} onChange={onChangeMeasure} automationId="showMeasure" />
        </div>
      </Fragment>
    ),
    [valueMeasure],
  );

  const thresholdItem = useMemo(
    () =>
      thresholdsValue.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={`${classes.thresholdItem} ${index === 0 ? 'mt_5' : ''}`} key={`${thresholdsLength}_${index}`}>
          <div className="mr_1-5">
            <ColorPicker
              value={item.color}
              onChange={(e) => handleChangeThresholds(e, index)}
              options={optionsColors}
              buttonSize={16}
              menuWidth={140}
            />
          </div>
          <div className={classes.inputThreshold}>
            <Input
              automationId="thresholdValueGaugeTile"
              fullSize
              type="number"
              placeHolder={index === thresholdsLength - 1 ? 'Base Color' : undefined}
              value={item.value}
              onBlur={handleBlur}
              onChange={(e) => handleChangeThresholds(e, index)}
              additionalProps={additionalProps}
              isDisabled={index === thresholdsLength - 1}
            />
          </div>
          {index === 0 && thresholdsLength < 5 && (
            <div className={classes.buttonAddThreshold} onClick={handleAddThresholds}>
              <i className="icon icn-action16-plusa" />
            </div>
          )}
          {index !== thresholdsLength - 1 && (
            <i
              className={`icon icn-general16-closea ${classes.iconClearThreshold}`}
              onClick={() => handleDeleteThresholds(index)}
            />
          )}
        </div>
      )),
    [thresholdsValue, isThresholdsOptionsOpen, thresholdsLength],
  );

  return (
    <Fragment>
      <Box width={150}>
        <div className="text14med lineHeight_14 mt_1-5 mb_1">Tile Type</div>
        <IconTabsBar
          automationId="statTypeTile"
          onChange={onChangeType}
          items={STAT_TILES_ITEMS}
          value={tileType}
          height={64}
        />
      </Box>
      <div className="mt_3">
        <Measure isSeriesFunctionEnabled />
      </div>
      <div className="mt_3">
        <Title onChangeMeasure={onChangeMeasure} />
      </div>
      {!isGaugeTileType && (
        <div className="mt_3 mb_1">
          <ExpandableBox
            automationId="displayOptionsStatTile"
            label="Display Options"
            expanded={isDisplayOptionsOpen}
            onChange={setDisplayOptionsOpen}
          >
            {unitOption}
            {displayTypeValue}
            {decimalSwitcher}
            <div className="display_flex alignItems_center mt_1-5">
              <div className="text16reg lineHeight_16 mr_1">Abbreviate</div>
              <SwitchButton isChecked={valueAbbreviate} onChange={onChangeAbbreviate} automationId="Abbreviate" />
            </div>
            {showMeasureSwitcher}
            <div className="display_flex alignItems_center mt_1-5">
              <div className="text16reg lineHeight_16 mr_1">Show Graph</div>
              <SwitchButton isChecked={valueGraph} onChange={onChangeGraph} automationId="showGraph" />
            </div>
            {noDataInput}
          </ExpandableBox>
          <div className="mt_1">
            <ExpandableBox
              automationId="thresholdsStatTile"
              label="Threshold and Coloring"
              expanded={isThresholdsOptionsOpen}
              onChange={setThresholdsOptionsOpen}
              rootStyle={isThresholdsOptionsOpen ? minHeightThresholdOpened : undefined}
            >
              {thresholdItem}
            </ExpandableBox>
          </div>
        </div>
      )}
      {isGaugeTileType && (
        <Fragment>
          <div className="mt_3 mb_1">
            <ExpandableBox
              automationId="valueOptionsStatTile"
              label="Value Options"
              expanded={isValueOptionsOpen}
              onChange={setValueOptionsOpen}
            >
              {unitOption}
              {displayTypeValue}
              {decimalSwitcher}
              {noDataInput}
            </ExpandableBox>
          </div>
          <div className="mb_1">
            <ExpandableBox
              automationId="gaugeOptionsStatTile"
              label="Gauge Options"
              expanded={isGaugeOptionsOpen}
              onChange={setGaugeOptionsOpen}
            >
              <div className={classes.itemScaleValue}>
                <Tooltip content="Leave empty to calculate based on all values" placement="right">
                  <div className="display_flex alignItems_center">
                    <div className="text16reg lineHeight_16 mr_1">Min. Scale Value</div>
                    <i className={`icon icn-general16-info ${classes.iconTooltip}`} />
                  </div>
                </Tooltip>
              </div>
              <div className={classes.inputWrapper}>
                <InputAuto
                  automationId="minThresholdValueGaugeTile"
                  allowEmpty
                  delay={500}
                  fullSize
                  type="number"
                  placeHolder="Auto"
                  value={minScaleValue}
                  onChange={onChangeMinScale}
                />
              </div>
              <div className="mt_1-5">
                <div className="display_flex alignItems_center">
                  <div className="text16reg lineHeight_16 mr_1">Max. Scale Value</div>
                </div>
                <div className={classes.inputWrapper}>
                  <InputAuto
                    automationId="maxThresholdValueGaugeTile"
                    allowEmpty
                    delay={500}
                    fullSize
                    type="number"
                    placeHolder="Auto"
                    value={maxScaleValue}
                    onChange={onChangeMaxScale}
                  />
                </div>
              </div>
              {showMeasureSwitcher}
              <div className="display_flex alignItems_center mt_2">
                <div className="text16reg lineHeight_16 mr_1">Show Value Labels</div>
                <SwitchButton
                  isChecked={isShowValueLabels}
                  onChange={onChangeValueLabels}
                  automationId="showValueLabels"
                />
              </div>
            </ExpandableBox>
          </div>
          <ExpandableBox
            automationId="thresholdsGaugeTile"
            label="Threshold and Coloring"
            expanded={isThresholdsOptionsOpen}
            onChange={setThresholdsOptionsOpen}
            rootStyle={isThresholdsOptionsOpen ? minHeightThresholdOpened : undefined}
          >
            {thresholdItem}
          </ExpandableBox>
        </Fragment>
      )}
    </Fragment>
  );
};

export default StatTileSettings;
