// @flow
import React from 'react';
import './FunctionParameterInput.module.scss';
import {FunctionParameters} from 'anodot-objects-models';
import NumericParameterInput from './parameterInputs/NumericParameterInput';
import StringParameterInput from './parameterInputs/StringParameterInput';
import TokenParameterInput from './parameterInputs/TokenParameterInput';
import SelectionParameterInput from './parameterInputs/SelectionParameterInput';
import RelativeTimeParameterInput from './parameterInputs/RelativeTimeParameterInput';

type PropTypes = {
  functionParameter: FunctionParameters,
  functionProperties: Object,
  onValueChange: Function,
  onTouched: Function,
  value: any,
  isFunctionPropertiesLoading: boolean,
  isForceFocusIfAvailable: boolean,
};

export default class FunctionParameterInput extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {}

  onValueChanged = (newValue, isValid, validationErrors, isInit) => {
    this.props.onValueChange(this.props.functionParameter.name, newValue, isValid, validationErrors, isInit);
  };

  renderNumeric = () => (
    <NumericParameterInput
      functionParameter={this.props.functionParameter}
      onValueChange={this.onValueChanged}
      value={this.props.value}
      onTouched={this.props.onTouched}
      isForceFocusIfAvailable={this.props.isForceFocusIfAvailable}
    />
  );

  renderSelection = () => (
    <div>
      <SelectionParameterInput
        onValueChange={this.onValueChanged}
        functionProperties={this.props.functionParameter}
        value={this.props.value}
        onTouched={this.props.onTouched}
      />
    </div>
  );

  renderToken = () => (
    <div>
      <TokenParameterInput
        functionParameter={this.props.functionParameter}
        onValueChange={this.onValueChanged}
        functionProperties={this.props.functionProperties}
        value={this.props.value}
        onTouched={this.props.onTouched}
        isFunctionPropertiesLoading={this.props.isFunctionPropertiesLoading}
      />
    </div>
  );

  renderRelativeTime = () => (
    <div>
      <RelativeTimeParameterInput
        functionParameter={this.props.functionParameter}
        onValueChange={this.onValueChanged}
        value={this.props.value}
        onTouched={this.props.onTouched}
        isForceFocusIfAvailable={this.props.isForceFocusIfAvailable}
      />
    </div>
  );

  renderString = () => (
    <div>
      <StringParameterInput
        functionParameter={this.props.functionParameter}
        onValueChange={this.onValueChanged}
        value={this.props.value}
        onTouched={this.props.onTouched}
        isForceFocusIfAvailable={this.props.isForceFocusIfAvailable}
      />
    </div>
  );

  getRenderFunction = () => {
    switch (this.props.functionParameter.displayType) {
      case 'numeric':
        return this.renderNumeric();
      case 'list_single_selection':
        return this.renderSelection();
      case 'token':
        return this.renderToken();
      case 'relative_time':
        return this.renderRelativeTime();
      case 'string':
        return this.renderString();
      default:
        return null;
    }
  };

  render() {
    return <div styleName="container">{this.getRenderFunction()}</div>;
  }
}
