// @flow
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {omit} from 'lodash';
import {Box} from '@material-ui/core';
import {useQueryParams} from 'use-query-params';
import {makeStyles} from '@material-ui/core/styles';
import {Carousel} from 'react-responsive-carousel';
import {palette} from 'app/styles/theme';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import SiteSvg, {LinkIcon, RegionIcon} from 'topologyGeneral/components/mapNodeIcons/SiteSvg';
import {QUERY_PARAM_MAP} from 'topologyGeneral/services/sidePanelService';
import {getSiteCentralIcon, getBadgeColor, MAP_ITEMS_COLOR_PALETTE} from 'topologyGeneral/services/mapService';
import {isLoadingGtpMapRegionsSitesList} from 'topologyGeneral/store/selectors';
import './SidePanel.module.scss';

const CAROUSEL_SETTINGS = {
  showArrows: false,
  showIndicators: true,
  infiniteLoop: false,
  showThumbs: false,
  showStatus: false,
  emulateTouch: true,
  transitionTime: 400,
};

const useDomainItemStyles = makeStyles(() => ({
  root: {
    width: '98px',
    height: '128px',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: palette.gray['100'],
    flexShrink: 0,
  },
  title: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color: palette.gray['500'],
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: '8px',
  },
  faded: {
    opacity: 0.3,
  },
}));

const EntityItem = ({item, selectedEntityId, selectedSubEntityId, isLast, onEntityClick, onSvgClick}) => {
  if (!item) {
    return null;
  }

  const [siteSvgData, setSiteSvgData] = useState({
    left: {},
    right: {},
    top: {},
    center: {},
  });

  const [isFaded, setIsFaded] = useState(false);

  const getCellsColorArr = (cells, isItemFaded) => {
    const colorArr = [];
    cells.forEach((cell) => {
      let newColor = MAP_ITEMS_COLOR_PALETTE.green;
      if (!isItemFaded && selectedSubEntityId && selectedSubEntityId !== cell.subEntityId) {
        newColor = cell.isError ? MAP_ITEMS_COLOR_PALETTE.opRed : MAP_ITEMS_COLOR_PALETTE.opGreen;
      } else {
        newColor = cell.isError ? MAP_ITEMS_COLOR_PALETTE.red : MAP_ITEMS_COLOR_PALETTE.green;
      }
      colorArr.push(newColor);
    });
    return colorArr;
  };

  const getCenterIconColor = (centerItem, isItemFaded) => {
    let newColor = MAP_ITEMS_COLOR_PALETTE.green;
    if (!isItemFaded && selectedSubEntityId && selectedSubEntityId !== centerItem.subEntityId) {
      newColor = centerItem.isError ? MAP_ITEMS_COLOR_PALETTE.opRed : MAP_ITEMS_COLOR_PALETTE.opGreen;
    } else {
      newColor = centerItem.isError ? MAP_ITEMS_COLOR_PALETTE.red : MAP_ITEMS_COLOR_PALETTE.green;
    }
    return newColor;
  };

  useEffect(() => {
    if (item) {
      let isItemFaded = false;
      switch (item.entityType) {
        case 'node': {
          isItemFaded = selectedEntityId && selectedEntityId !== item.id;
          setSiteSvgData({
            left: {
              items: item.cells.leftWing,
              colorArr: getCellsColorArr(item.cells.leftWing, isItemFaded),
            },
            right: {
              items: item.cells.rightWing,
              colorArr: getCellsColorArr(item.cells.rightWing, isItemFaded),
            },
            top: {
              items: item.cells.topWing,
              colorArr: getCellsColorArr(item.cells.topWing, isItemFaded),
            },
            center: {
              item: omit(item, ['cells']),
              type: getSiteCentralIcon(item.keywords),
              color: getCenterIconColor(item, isItemFaded),
            },
          });
          break;
        }
        case 'link': {
          isItemFaded = selectedEntityId && selectedEntityId !== item.id;
          break;
        }
        case 'region': {
          isItemFaded = selectedEntityId && selectedEntityId !== item.serverId;
          break;
        }
        default:
          break;
      }
      setIsFaded(isItemFaded);
    } else {
      setSiteSvgData({
        left: {},
        right: {},
        top: {},
        center: {},
      });
      setIsFaded(false);
    }
  }, [item, selectedEntityId, selectedSubEntityId]);

  const classes = useDomainItemStyles();

  const onEntityClickLocale = (ev) => {
    if (onEntityClick && !ev.isDefaultPrevented()) {
      onEntityClick(item);
    }
  };

  const onInnerElementClick = (ev, res) => {
    if (onSvgClick) {
      onSvgClick(res);
      ev.preventDefault();
    }
  };

  return (
    <Box
      className={`${classes.root} ${isFaded ? classes.faded : ''}`}
      mr={isLast ? 0 : 1.5}
      onClick={onEntityClickLocale}
    >
      <Box className={classes.title} pr={0.5} pl={0.5}>
        <Box component="span">{item.name || item.title}</Box>
      </Box>
      <Box width={86} height={90} display="flex" alignItems="center" justifyContent="center">
        {item.entityType === 'node' ? <SiteSvg data={siteSvgData} onClick={onInnerElementClick} /> : null}
        {item.entityType === 'link' ? <LinkIcon color={getCenterIconColor(item)} /> : null}
        {item.entityType === 'region' ? (
          <Box position="relative" width={68} height={68}>
            <RegionIcon color={getBadgeColor(item.percent)} />
            <Box
              component="span"
              position="absolute"
              top={0}
              width={68}
              height={68}
              display="flex"
              alignItems="center"
              justifyContent="center"
              color="white.500"
            >
              {item.percent}%
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

EntityItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedEntityId: PropTypes.string,
  selectedSubEntityId: PropTypes.string,
  isLast: PropTypes.bool,
  onEntityClick: PropTypes.func,
  onSvgClick: PropTypes.func,
};

EntityItem.defaultProps = {
  selectedEntityId: '',
  selectedSubEntityId: '',
  isLast: false,
  onEntityClick: null,
  onSvgClick: null,
};

export const EntitiesCarousel = ({entities, isAutoSelect}) => {
  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAM_MAP);
  const isSitesListLoading = useSelector(isLoadingGtpMapRegionsSitesList);

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    setCurrentSlideIndex(0);
  }, [queryParams.regionId, queryParams.siteId]);

  useEffect(() => {
    const slidesBank = [];
    let slide = null;
    let curItem = 0;
    while (curItem < entities.length) {
      if (curItem % 3 === 0) {
        slide = [];
        slidesBank.push(slide);
      }

      slide.push(entities[curItem]);
      curItem += 1;
    }
    setSlides(slidesBank);
  }, [entities, queryParams.regionId]);

  useEffect(() => {
    if (entities.length === 1 && slides.length && isAutoSelect) {
      setQueryParams({selectedEntityId: slides[0][0].entityId});
    } else {
      setQueryParams({selectedEntityId: undefined});
    }
  }, [slides, isAutoSelect]);

  const onEntityItemClick = (item) => {
    if (entities.length !== 1 || !isAutoSelect) {
      setQueryParams({
        selectedEntityId: queryParams.selectedEntityId === item.entityId ? undefined : item.entityId,
        selectedSubEntityId: undefined,
      });
    }
  };

  const onSvgClick = (item) => {
    setQueryParams({
      selectedEntityId: item.sourceEntityId,
      selectedSubEntityId: queryParams.selectedSubEntityId === item.subEntityId ? undefined : item.subEntityId,
    });
  };

  const onSlideChange = (slideIndex) => {
    setCurrentSlideIndex(slideIndex);
  };

  if (isSitesListLoading) {
    return (
      <Box
        styleName="domain-carousel"
        display="flex"
        flexDirection="column"
        width="100%"
        height={98}
        alignItems="center"
        justifyContent="center"
      >
        <Spinner color="#3d4c59" size={SIZES.BIG_60} />
      </Box>
    );
  }

  return (
    <Box styleName="domain-carousel" display="flex" flexDirection="column" width="100%" height={128}>
      <Carousel
        {...CAROUSEL_SETTINGS}
        showIndicators={slides.length > 1}
        selectedItem={currentSlideIndex}
        centerMode={slides.length > 1}
        centerSlidePercentage={slides.length > 1 ? 90 : 100}
        onChange={onSlideChange}
      >
        {slides.map((slide, index) => {
          const isLastSlideFull = slides.length > 1 && slides[slides.length - 1].length === 3;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Box
              height={128}
              width={slides.length > 1 ? 330 : '100%'}
              display="flex"
              justifyContent={isLastSlideFull && currentSlideIndex + 1 === slides.length ? 'flex-end' : 'flex-start'}
              // eslint-disable-next-line react/no-array-index-key
              key={`domain-slide-${index}`}
            >
              {(slide || []).map((innerItem, innerIndex) => (
                <EntityItem
                  key={`node-item-${innerItem.id}`}
                  item={innerItem}
                  isLast={slide.length === innerIndex + 1}
                  isFaded={queryParams.selectedEntityId && queryParams.selectedEntityId !== innerItem.id}
                  selectedSubEntityId={queryParams.selectedSubEntityId}
                  selectedEntityId={queryParams.selectedEntityId}
                  onEntityClick={onEntityItemClick}
                  onSvgClick={onSvgClick}
                />
              ))}
            </Box>
          );
        })}
      </Carousel>
    </Box>
  );
};

EntitiesCarousel.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.object).isRequired,
  isAutoSelect: PropTypes.bool,
  // mapRef: PropTypes.objectOf(PropTypes.any).isRequired,
};

EntitiesCarousel.defaultProps = {
  isAutoSelect: true,
};

const SiteEntitiesCarousel = ({site}) => {
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    setEntities([...site.nodes, ...site.links]);
  }, [site.nodes, site.links]);

  return <EntitiesCarousel entities={entities} />;
};

SiteEntitiesCarousel.propTypes = {
  site: PropTypes.objectOf(PropTypes.any).isRequired,
  // mapRef: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SiteEntitiesCarousel;
