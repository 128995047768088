// @flow
import React from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import connect from 'react-redux/es/connect/connect';
import {
  createNewExpression,
  createNewFunction,
  deleteFunction,
  dragExpressionNode,
  fetchFunctionsMetaData,
  fetchOriginInfo,
  fetchPropAndVal,
  fetchPropAndValList,
  fetchProperties,
  fetchProps,
  selectTreeBranch,
  setCombinedMetric,
  setExpression,
  setExpressionTree,
  setFunction,
  setFunctionParameter,
  setLastExpressionSearch,
  updateSimulation,
} from 'metrics/store/actions';
import './CompositeBuilder.module.scss';
import * as selectors from 'metrics/store/selectors';
import * as profile from 'profile/store/selectors';
import {cloneDeep, get, throttle} from 'lodash';
import shallowEqual from 'common/utils/shallowEqual';
import ExpressionItem from 'common/componentsV2/ExpressionBuilderV2/ExpressionItem';
import ExpressionBuilder from 'common/componentsV2/ExpressionBuilderV2/ExpressionBuilder';
import hashcode from 'common/utils/hashcode';
// eslint-disable-next-line max-len
import FunctionForExpressionBuilder from 'common/componentsV2/ExpressionBuilderV2/FunctionForExpressionBuilder/FunctionForExpressionBuilder';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {getUniqueId} from 'common/utils/guid';
import {segmentClickEvent} from 'common/store/actions';
import Draggable from 'common/componentsV2/dragAndDrop/Draggable';
import DropZone from 'common/componentsV2/dragAndDrop/DropZone';
import {treeVisitor} from 'metrics/services/metricsService';
import {ReactComponent as SwapIcon} from './SwapIcon.svg';

type PropTypes = {
  fetchPropAndValList: Function,
  mainPanelOptions: Array<ExpressionItem>,
  secondaryPanelOptions: Array<ExpressionItem>,
  isFetchPropAndValListApiLoading: boolean,
  fetchProps: Function,
  isFetchPropsLoading: Boolean,
  previewTimeRange: Object,
  functionDefinitions: Array,
  functionDefinitionsFiltered: Array,
  fetchFunctionsMetaData: Function,
  fetchProperties: Function,
  functionProperties: Object,
  // expressionTree: Object,
  setExpression: Function,
  setFunctionParameter: Function,
  setFunction: Function,
  createNewFunction: Function,
  deleteFunction: Function,
  updateSimulation: Function,
  selectTreeBranch: Function,
  selectedElementId: string,
  fetchOriginInfo: Function,
  originInfo: Object,
  setLastExpressionSearch: Function,
  userProfileId: String,
  expressionTreeId: string,
  expressionTrees: Array,
  isFunctionPropertiesLoading: boolean,
  allExpressionsSelected: boolean,
  isShowDisplayOnlyFunctions: boolean,
  enableFilteredFunctions: boolean,
  errors: Array,
  createNewExpression: Function,
  isAllowDuplicateExpression: boolean,
  segmentClickEvent: Function,
  useDropDownExternalProvider: boolean,
  dragExpressionNode: Function,
  setExpressionTree: Function,
};

@connect(
  (state) => ({
    mainPanelOptions: selectors.getMainPanelOptions(state),
    secondaryPanelOptions: selectors.getSecondaryPanelOptions(state),
    isFetchPropAndValLoading: selectors.getIsFetchPropAndValLoading(state),
    isFetchPropsLoading: selectors.getFetchPropIsLoading(state),
    isFetchPropAndValListApiLoading: selectors.getIsFetchPropAndValApiListLoading(state),
    previewTimeRange: selectors.getPreviewTimeRange(state),
    functionDefinitions: selectors.getFunctionDefinitions(state),
    functionDefinitionsFiltered: selectors.getFunctionDefinitionsFiltered(state),
    functionProperties: selectors.getFunctionProperties(state),
    selectedElementId: selectors.getSelectedElementId(state),
    originInfo: selectors.getOriginInfo(state),
    userProfileId: profile.getProfileId(state),
    isFunctionPropertiesLoading: selectors.getPropertiesApiIsLoading(state),
    allExpressionsSelected: selectors.getAllExpressionsSelected(state),
  }),
  {
    fetchPropAndVal,
    fetchPropAndValList,
    fetchProps,
    fetchFunctionsMetaData,
    fetchProperties,
    setCombinedMetric,
    setExpression,
    setFunctionParameter,
    setFunction,
    createNewFunction,
    deleteFunction,
    updateSimulation,
    selectTreeBranch,
    fetchOriginInfo,
    setLastExpressionSearch,
    createNewExpression,
    segmentClickEvent,
    dragExpressionNode,
    setExpressionTree,
  },
)
class CompositeBuilder extends React.PureComponent {
  props: PropTypes;

  // eslint-disable-next-line react/sort-comp
  location = '';

  constructor() {
    super();
    this.functionsDDLs = {};
    this.isDDdisable = true;
  }

  state = {
    isDragging: false,
  };

  componentDidMount() {
    this.props.fetchFunctionsMetaData();
    this.props.fetchOriginInfo();
    const {href} = window.location;
    if (href.indexOf('metrics-explorer') > -1) {
      this.location = 'metrics-explorer';
    }
    if (href.indexOf('alert-manager') > -1) {
      this.location = 'alert-manager';
    }
    // This is where the Drag and Drop can be turned on by using in the url 'useDnd'
    // Eventualy, when dnd is fully available, all the 'disable' thing should go.

    // const {hash} = window.location;
    // const isUseDnd = hash.indexOf('useDnd') > -1;
    // this.isDDdisable = !isUseDnd;
  }

  componentDidUpdate(previous) {
    if (!shallowEqual(previous.previewTimeRange, this.props.previewTimeRange)) {
      this.props.updateSimulation();
    }
  }

  // eslint-disable-next-line react/sort-comp
  getExpressionTree = (expressionTrees) => {
    if (!expressionTrees) {
      return null;
    }
    const ret = expressionTrees.find((et) => et.id === this.props.expressionTreeId);
    return ret.expressionTree;
  };

  componentWillUnmount() {
    const functionArr = [];
    const et = this.getExpressionTree(this.props.expressionTrees);
    if (!et) {
      return;
    }
    const {root} = et;
    const r = this.createFunctionExpressionSetup(functionArr, root, 0);
    const elements = r.filter((ele) => ele.details.type === 'metric').map((ele) => ele.details.searchObject.expression);
    if (elements[0].length) {
      this.props.setLastExpressionSearch(
        {
          type: 'expression-searches',
          data: elements,
          itemId: String(hashcode(elements)),
        },
        {
          userId: this.props.userProfileId,
        },
      );
    }
  }

  compositeObject = {};

  handlePrimarySearchChange = throttle((val) => {
    this.props.fetchPropAndValList(val, JSON.stringify(val));
  }, 1000);

  handleFetchFirstPanel = (val) => {
    this.props.fetchPropAndValList(val, JSON.stringify(val));
  };

  handleSecondarySearch = (val) => {
    this.props.fetchProps(val, JSON.stringify(val));
  };

  handleExpressionChange = (val, id) => {
    const expression = val
      .filter((value) => value.getExpressionTreeObjectification)
      .map((value) => value.getExpressionTreeObjectification());
    const measureIndex = val.findIndex((item) => item.value === 'Measures');

    if (
      measureIndex > -1 &&
      !!expression[measureIndex] &&
      !!expression[measureIndex].value &&
      !!expression[measureIndex].key
    ) {
      this.props.setLastExpressionSearch(
        {
          type: 'last-measures',
          data: expression[measureIndex],
          itemId: String(hashcode(expression[measureIndex])),
        },
        {
          userId: this.props.userProfileId,
        },
      );
    }
    this.handleElementClick(id);
    this.props.setExpression({value: expression, id});
  };

  createFunctionExpressionSetup = (functionArray, functionDetails, level) => {
    const retObj = {};
    Object.keys(functionDetails).forEach((key) => {
      if (key !== 'children') {
        retObj[key] = functionDetails[key];
      } else {
        for (let i = functionDetails.children.length - 1; i >= 0; i--) {
          const child = functionDetails.children[i];
          this.createFunctionExpressionSetup(functionArray, child, level + 1);
        }
        retObj.children = functionDetails.children;
      }
    });
    functionArray.unshift({level, details: retObj});
    return functionArray;
  };

  handleNewFunction = (def) => {
    this.props.segmentClickEvent({category: this.location, name: 'add-function'});
    if (!def.details.uiData.canCreateFunction) {
      return;
    }
    if (def.details.function === '') {
      this.functionsDDLs[def.details.id].openMenu();
      return;
    }
    this.handleElementClick(def.details.id);
    this.props.createNewFunction(def.details.id);
  };

  handleDuplicateFunction = (def) => {
    if (this.props.expressionTrees.length >= 5) {
      return;
    }

    this.props.segmentClickEvent({category: this.location, name: 'duplicate-subtree'});
    const cloneTree = cloneDeep(def.details);
    treeVisitor(cloneTree, 'children', (childNode) => {
      // eslint-disable-next-line no-param-reassign
      childNode.id = getUniqueId();
    });

    let ret = {
      root: {
        ...cloneTree,
        id: getUniqueId(),
      },
    };

    if (cloneTree.type === 'metric') {
      ret = {
        root: {
          children: [cloneTree],
          function: '',
          id: getUniqueId(),
          parameters: [],
          type: 'function',
          uiIndex: 0,
        },
      };
    }

    this.props.createNewExpression(ret);
  };

  handleDeleteFunction = (def, uiData) => {
    this.props.segmentClickEvent({
      category: this.location,
      name: def.details.type === 'metric' ? 'clear-row' : 'delete-row',
    });
    this.props.deleteFunction({id: def.details.id, uiData});
  };

  handleElementClick = (id) => {
    this.props.selectTreeBranch({branchId: id, selectedExpressionId: this.props.expressionTreeId});
  };

  handleSuccessfulDrop = (fromData, intoData) => {
    this.props.dragExpressionNode({fromData, intoData});
  };

  renderActionMenu = (def) => {
    let deleteIcon = '';
    let tooltipDelete = '';
    let deleteId = 'metricExplorerFunctionDelete';
    const {canCreateFunction} = def.details.uiData;
    if (def.details.type === 'metric') {
      deleteIcon = 'icn-action16-clear';
      tooltipDelete = 'Clear';
    } else if (def.details.uiData && def.details.uiData.isDeletable) {
      switch (def.details.uiData.isDeletable) {
        case 'reset':
          deleteIcon = 'icn-action16-clear';
          tooltipDelete = 'Clear';
          deleteId = 'metricExplorerExpressionClear';
          break;
        case 'resetParams':
          deleteIcon = 'icn-action16-clear';
          tooltipDelete = 'Clear parameters';
          break;
        case 'deleteAndChild':
        case 'resetAndChild':
        case 'enable':
          deleteIcon = 'icn-action24-delete';
          tooltipDelete = 'Delete';
          break;
        default:
      }
    }
    return (
      <div styleName="action-menu-container" className="no-external-click">
        <div styleName="action-menu">
          <Tooltip content="New function" type={TYPES.SMALL} placement="top">
            <div
              onClick={() => this.handleNewFunction(def)}
              automation-id="metricExplorerExpressionNewFunction"
              styleName={['icon', !canCreateFunction ? 'disabled' : ''].join(' ')}
              className="icon icn-general16-functions"
            />
          </Tooltip>
          {this.props.isAllowDuplicateExpression && this.props.expressionTrees.length < 5 ? (
            <Tooltip content="Duplicate" type={TYPES.SMALL} placement="top">
              <div
                automation-id="metricExplorerExpressionDuplicate"
                onClick={() => this.handleDuplicateFunction(def)}
                styleName="icon"
                className="icon icn-action24-copy"
              />
            </Tooltip>
          ) : null}
          <Tooltip content={tooltipDelete} type={TYPES.SMALL} placement="top">
            <div
              automation-id={deleteId}
              onClick={() => this.handleDeleteFunction(def, def.details.uiData)}
              styleName={['icon'].join(' ')}
              className={['icon', deleteIcon].join(' ')}
            />
          </Tooltip>
        </div>
      </div>
    );
  };

  renderViewingFlag = () => <div styleName="you-are-viewing">You are viewing this expression</div>;

  findErrorsById = (id) => {
    let ret = null;
    if (!this.props.errors) {
      return null;
    }
    const arr = Object.keys(this.props.errors);
    arr.forEach((key) => {
      if (key === id) {
        const myError = this.props.errors[key];
        if (myError) {
          // eslint-disable-next-line prefer-destructuring
          ret = Object.values(myError.failures)[0];
        }
      } else {
        const err = this.props.errors[key];
        if (err && err.failures) {
          Object.keys(err.failures).forEach((errorKey) => {
            if (errorKey === id) {
              ret = err.failures[errorKey];
            }
          });
        }
      }
    });
    return ret;
  };

  handleFunctionChange = (val) => {
    this.props.segmentClickEvent({category: this.location, name: `chose-function-${val.value.displayName}`});
    this.props.setFunction(val);
  };

  handleDragChange = (val) => {
    this.setState({isDragging: val});
  };

  handleSwitch = (val) => {
    const thisExpressionTree = this.props.expressionTrees.find((et) => et.id === this.props.expressionTreeId)
      .expressionTree;
    if (val && val.children && val.children.length === 2) {
      const store = val.children[0];
      // eslint-disable-next-line no-param-reassign,prefer-destructuring
      val.children[0] = val.children[1];
      // eslint-disable-next-line no-param-reassign
      val.children[1] = store;

      const newExpression = cloneDeep(thisExpressionTree);
      this.props.setExpressionTree(newExpression);
    }
  };

  renderElement = (eleDef, hasMoreThanOneExpression) => {
    let isAddVisibilityFlag = this.props.selectedElementId === eleDef.details.id && hasMoreThanOneExpression;
    if (this.props.expressionTrees.length === 1) {
      isAddVisibilityFlag = false;
    }
    if (this.props.allExpressionsSelected) {
      isAddVisibilityFlag = false;
    }

    const isSelected = get(eleDef.details, 'uiData.isSelected', this.props.allExpressionsSelected);

    if (eleDef.details.type === 'function') {
      const errors = eleDef.details.function === '' ? null : this.findErrorsById(eleDef.details.id);
      return (
        <div styleName={[isSelected ? 'selected' : 'not-selected', 'element'].join(' ')}>
          <div style={{top: '4px', position: 'relative'}}>{this.renderActionMenu(eleDef)}</div>
          {isAddVisibilityFlag && this.renderViewingFlag()}
          {this.isDDdisable ? null : <div styleName="handle" className="icon icn-general16-drag2 handle" />}
          <DropZone
            itemData={{expression: eleDef.details, treeId: this.props.expressionTreeId}}
            acceptType="expression-item"
            landingIndicator={<div styleName="landing-indicator" />}
          >
            <FunctionForExpressionBuilder
              ref={(ele) => {
                this.functionsDDLs[eleDef.details.id] = ele;
              }}
              fetchProperties={this.props.fetchProperties}
              functionDefinitions={
                this.props.enableFilteredFunctions
                  ? this.props.functionDefinitionsFiltered
                  : this.props.functionDefinitions
              }
              functionProperties={this.props.functionProperties}
              isFunctionPropertiesLoading={this.props.isFunctionPropertiesLoading}
              values={eleDef.details}
              onParameterChange={this.props.setFunctionParameter}
              onFunctionChange={this.handleFunctionChange}
              onClick={() => this.handleElementClick(eleDef.details.id)}
              selectedElementId={this.props.selectedElementId}
              isShowDisplayOnlyFunctions={this.props.isShowDisplayOnlyFunctions}
              errors={errors}
              isDragging={this.state.isDragging}
            />
          </DropZone>
        </div>
      );
    }
    if (eleDef.details.type === 'metric') {
      const expression = eleDef.details.searchObject.expression.map((expItem) =>
        ExpressionItem.setExpressionFromObject(expItem, this.props.originInfo),
      );
      return (
        <div styleName={[isSelected ? 'selected' : 'not-selected', 'element'].join(' ')}>
          {this.renderActionMenu(eleDef)}
          {isAddVisibilityFlag && this.renderViewingFlag()}
          {this.isDDdisable ? null : <div styleName="handle" className="icon icn-general16-drag2 handle" />}
          <DropZone
            itemData={{expression: eleDef.details, treeId: this.props.expressionTreeId}}
            acceptType="expression-item"
            landingIndicator={<div styleName="landing-indicator" />}
          >
            <ExpressionBuilder
              mainPanelOptions={this.props.mainPanelOptions}
              secondaryPanelOptions={this.props.secondaryPanelOptions}
              fetchFirstPanel={this.handleFetchFirstPanel}
              expression={expression}
              secondarySearch={this.handleSecondarySearch}
              isPrimaryPanelLoading={this.props.isFetchPropAndValListApiLoading}
              isSecondaryPanelLoading={this.props.isFetchPropsLoading}
              onExpressionChange={(val) => this.handleExpressionChange(val, eleDef.details.id)}
              onClick={() => this.handleElementClick(eleDef.details.id)}
              useTopMenu
            />
          </DropZone>
        </div>
      );
    }
    return null;
  };

  renderTree = (expressionObject, level, hasMoreThanOneExpression) => {
    if (expressionObject.children.length === 0) {
      return (
        <Draggable
          itemData={{expression: expressionObject, treeId: this.props.expressionTreeId}}
          type="expression-item"
          onDragChange={this.handleDragChange}
          onDropIntoValidDropzone={this.handleSuccessfulDrop}
          disabled={this.isDDdisable}
        >
          {this.renderElement({details: expressionObject, level}, hasMoreThanOneExpression)}
        </Draggable>
      );
    }
    return (
      <Draggable
        itemData={{expression: expressionObject, treeId: this.props.expressionTreeId}}
        type="expression-item"
        onDragChange={this.handleDragChange}
        onDropIntoValidDropzone={this.handleSuccessfulDrop}
        disabled={this.isDDdisable}
      >
        <div styleName="tree-container" style={level === 0 ? {} : {marginLeft: '20px'}}>
          {this.renderElement({details: expressionObject, level}, hasMoreThanOneExpression)}
          {expressionObject.children.map((child) => (
            <div style={{position: 'relative'}} key={child.id}>
              {child.uiData.isSwitchButton && (
                <Tooltip content="Switch" type={TYPES.SMALL}>
                  <div onClick={() => this.handleSwitch(expressionObject)} styleName="switch-ico">
                    <SwapIcon />
                  </div>
                </Tooltip>
              )}
              {this.renderTree(child, level + 1)}
            </div>
          ))}
        </div>
      </Draggable>
    );
  };

  render() {
    const {root} = this.getExpressionTree(this.props.expressionTrees);
    if (!root) {
      return null;
    }
    const countMetrics = (JSON.stringify(root).match(/metric/g) || []).length;
    const renderT = this.renderTree(root, 0, countMetrics > 1);

    if (this.props.useDropDownExternalProvider) {
      return (
        <div>
          <div styleName="container">{renderT}</div>
        </div>
      );
    }
    return (
      <DndProvider backend={HTML5Backend}>
        <div>
          <div styleName="container">{renderT}</div>
        </div>
      </DndProvider>
    );
  }
}

export default CompositeBuilder;
