import * as actions from 'bc/store/actions';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import {isStreamRunning} from 'bc/services/dataStreamService';

const baseDataStreamReducer = composeReducers(
  // eslint-disable-next-line complexity
  (state, {type, payload}) => {
    const getSelectedIndex = (id = state.selectedItemId) => state.streams.data.findIndex((item) => item.id === id);

    const red = (item, itemPayload) => ({...item, ...itemPayload});

    const redWrapper = (_payload, index = getSelectedIndex()) => ({
      ...state,
      streams: {
        ...state.streams,
        data: reduceArrayItem(red, state.streams.data, index, _payload || payload),
      },
    });

    switch (type) {
      case actions.setSelectedDataStream.TYPE:
      case actions.previewDataStream.TYPE:
      case actions.editDataStream.TYPE:
        return state.selectedItemId !== payload
          ? {
              ...state,
              selectedItemId: payload,
              selectedStreamPreview: {},
              selectedStreamStats: {},
              selectedStreamPreviewColumnWidth: {},
            }
          : state;
      case actions.createDataStream.TYPE:
      case actions.duplicateStream.TYPE:
        return {...state, isCreatingNewStream: true};
      case actions.editLiveStream.TYPE:
        // eslint-disable-next-line no-case-declarations
        const {data} = state.streams;
        // eslint-disable-next-line no-case-declarations
        const newStream = {...state.streams.data.find((f) => f.id === payload.id)};
        newStream.uiState = {
          id: newStream.id,
        };
        newStream.id = 'EditRunning';
        data.push(newStream);
        return {
          ...state,
          selectedItemId: newStream.id,
          streams: {
            ...state.streams,
            data,
          },
        };
      case actions.createDataStream.success.TYPE:
      case actions.duplicateStream.success.TYPE:
        return {
          ...state,
          streams: {...state.streams, data: [...state.streams.data, payload]},
          selectedItemId: payload.id,
          isCreatingNewStream: false,
        };
      case actions.createDataStream.failure.TYPE:
      case actions.duplicateStream.failure.TYPE:
        // eslint-disable-next-line no-param-reassign
        payload.errorHandler = bcErrorHandler;
        return {...state, isCreatingNewStream: false};
      case actions.deleteDataSource.success.TYPE: // test comment
        return {
          ...state,
          streams: {...state.streams, data: state.streams.data.filter((f) => f.dataSourceId !== payload)},
        };
      case actions.updateDataStream.TYPE:
      case actions.updateDataStreamScheduling.TYPE:
      case actions.updateStreamName.TYPE:
      case actions.startDataStream.TYPE:
      case actions.pauseResumeDataStream.TYPE:
      case actions.runAsyncQueryDataStream.TYPE:
        return {...state, isUpdatingStream: true};
      case actions.updateDataStream.success.TYPE:
      case actions.updateDataStreamScheduling.success.TYPE:
      case actions.startDataStream.success.TYPE: {
        // selectedItemId may not be set (pause/resume stream in data manager)
        let index = state.streams.data.findIndex((i) => i.id === payload.id);
        if (payload.id === 'EditRunning') {
          index = state.streams.data.findIndex((i) => i.id === payload.uiState.id);
        }
        const stream = state.streams.data[index];
        if (isStreamRunning(stream.state) || (stream.state === payload.state && stream.state !== 'running')) {
          return {
            ...state,
            isUpdatingStream: false,
            isStreamNeedUpdating: false,
          };
        }
        return {
          ...redWrapper(
            {
              ...stream,
              state: payload.state,
            },
            index,
          ),
          isUpdatingStream: false,
          isStreamNeedUpdating: false,
        };
      }
      case actions.updateStreamName.success.TYPE:
      case actions.pauseResumeDataStream.success.TYPE: {
        // selectedItemId may not be set (pause/resume stream in data manager)
        const index = state.streams.data.findIndex((i) => i.id === payload.id);
        const stream = {...state.streams.data[index], ...payload};
        return {...redWrapper(stream, index), isUpdatingStream: false};
      }
      case actions.runAsyncQueryDataStream.success.TYPE: {
        // selectedItemId may not be set (pause/resume stream in data manager)
        const index = state.streams.data.findIndex((i) => i.id === payload.id);
        const stream = {...state.streams.data[index], queryServiceState: payload.queryServiceState};
        return {...redWrapper(stream, index), isUpdatingStream: false};
      }
      case actions.updateDataStream.failure.TYPE:
      case actions.updateDataStreamScheduling.failure.TYPE:
      case actions.updateStreamName.failure.TYPE:
      case actions.startDataStream.failure.TYPE:
      case actions.pauseResumeDataStream.failure.TYPE:
      case actions.runAsyncQueryDataStream.failure.TYPE:
        // eslint-disable-next-line no-param-reassign
        payload.errorHandler = bcErrorHandler;
        return {...state, isUpdatingStream: false};
      case actions.deleteDataStream.success.TYPE:
      case actions.deletePipelineStream.success.TYPE:
        return {
          ...state,
          streams: {...state.streams, data: state.streams.data.filter((i) => i.id !== payload)},
        };
      default:
        return state;
    }
  },
  {
    runAsyncDataStreamQuery: makeAsyncReducer(actions.runAsyncQueryDataStream, {
      shouldDestroyData: false,
      errorHandler: bcErrorHandler,
    }),
  },
);

export default baseDataStreamReducer;
