// @flow
import React from 'react';
import {connect} from 'react-redux';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import * as actions from 'admin.cs-portal/store/actions';
import * as selectors from 'admin.cs-portal/store/selectors';
import ErrorsSummaryBlock from './ErrorsSummaryBlock';

import './ErrorsSummary.module.scss';

type PropTypes = {
  selectedCustomer: Object,
  fetchStreamsReport: Function,
  streamErrorsCount: Number,
  areStreamErrorsLoading: Boolean,
  fetchAlertsErrors: Function,
  alertErrorsCount: Number,
  areAlertErrorsLoading: Boolean,
  fetchCompositeErrors: Function,
  compositeErrorsCount: Number,
  areCompositeErrorsLoading: Boolean,
};

@connect(
  (state) => ({
    selectedCustomer: selectors.getSelectedCustomer(state),
    streamErrorsCount: selectors.getStreamErrorsCount(state),
    areStreamErrorsLoading: selectors.getIsStreamsReportLoading(state),
    alertErrorsCount: selectors.getAlertErrorsCount(state),
    areAlertErrorsLoading: selectors.getAreAlertErrorsLoading(state),
    compositeErrorsCount: selectors.getCompositeErrorsCount(state),
    areCompositeErrorsLoading: selectors.getAreCompositeErrorsLoading(state),
  }),
  {
    fetchStreamsReport: actions.fetchStreamsReport,
    fetchAlertsErrors: actions.fetchAlertsErrors,
    fetchCompositeErrors: actions.fetchCompositeErrors,
  },
)
export default class ErrorsSummary extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {selectedCustomer} = this.props;
    if (selectedCustomer && selectedCustomer.value && selectedCustomer.token) {
      this.props.fetchStreamsReport({
        _id: selectedCustomer.value,
      });
      this.props.fetchAlertsErrors({
        token: selectedCustomer.token,
      });
      this.props.fetchCompositeErrors({
        _id: selectedCustomer.value,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {selectedCustomer} = prevProps;
    if (
      selectedCustomer &&
      selectedCustomer.value &&
      selectedCustomer.token &&
      this.props.selectedCustomer !== selectedCustomer
    ) {
      this.props.fetchStreamsReport({
        _id: this.props.selectedCustomer.value,
      });
      this.props.fetchAlertsErrors({
        token: this.props.selectedCustomer.token,
      });
      this.props.fetchCompositeErrors({
        _id: this.props.selectedCustomer.value,
      });
    }
  }

  renderErrorCounts() {
    const {
      areStreamErrorsLoading,
      streamErrorsCount,
      areAlertErrorsLoading,
      alertErrorsCount,
      areCompositeErrorsLoading,
      compositeErrorsCount,
    } = this.props;
    return (
      <div styleName="errorCounts">
        <ErrorsSummaryBlock type="stream" count={streamErrorsCount} isLoading={areStreamErrorsLoading} />
        <ErrorsSummaryBlock type="alert" count={alertErrorsCount} isLoading={areAlertErrorsLoading} />
        <ErrorsSummaryBlock type="composite" count={compositeErrorsCount} isLoading={areCompositeErrorsLoading} />
      </div>
    );
  }

  render() {
    const {areStreamErrorsLoading, areAlertErrorsLoading, areCompositeErrorsLoading} = this.props;
    return (
      <div styleName="container">
        {areStreamErrorsLoading && areAlertErrorsLoading && areCompositeErrorsLoading ? (
          <Spinner color="#3d4c59" size={SIZES.BIG_60} />
        ) : (
          this.renderErrorCounts()
        )}
      </div>
    );
  }
}
