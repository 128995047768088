import React from 'react';
import {isEqual} from 'lodash';
import {useSelector} from 'react-redux';
import {useField} from 'react-final-form';
import SelectChipsInput from 'common/componentsV2/SelectChipsInput';
import {getTagsList} from 'alerts.management/store/selectors';
import TooltipArea from 'common/componentsV2/TooltipArea';

const Labels = () => {
  const {
    input: {onChange, value},
  } = useField('alertTags', {isEqual});
  const options = useSelector(getTagsList);
  return (
    <TooltipArea automationId="labelsContainer" text="Add labels to this alert">
      {(info) => (
        <React.Fragment>
          <div className="text16reg lineHeight_16 mb_1">
            Labels
            {info}
          </div>
          <SelectChipsInput
            value={value.map((option) => ({value: option.name, label: option.name}))}
            options={options.map((option) => ({value: option.name, label: option.name}))}
            onChange={(values) => onChange(values.map((item) => ({name: item.label})))}
            onRemove={(removed) => {
              onChange(value.filter((item) => item.name !== removed.value));
            }}
          />
        </React.Fragment>
      )}
    </TooltipArea>
  );
};

export default Labels;
