import {isEmpty} from 'lodash';
import {combineEpics} from 'redux-observable';
import {makeAsyncEpic, makeFlatAsyncEpic} from 'common/utils/simplifiedAsync';
import * as actions from 'investigation/store/actions';
import * as api from 'investigation/services/api';
import * as FeedbackAPI from 'feedback/services/api';
import * as selectors from 'investigation/store/selectors';
import {getApiTreeForNode, getEmptyTree} from 'common/utils/angularServices';
import {Observable} from 'rxjs/Observable';
import {getQuery} from 'common/store/selectors';
import {getBucketStartTimeEnabled} from 'profile/store/selectors';
import {resolutionTypes} from 'metrics/services/metricsService';

const fetchTriggeredAlertApi = makeAsyncEpic(actions.fetchTriggeredAlertApi, api.fetchTriggeredAlert);
const fetchAlertMetricsApi = makeFlatAsyncEpic(actions.fetchAlertMetrics, api.fetchMetrics);
const fetchAllMetricsApi = makeFlatAsyncEpic(actions.fetchAllMetrics, api.fetchMetrics);
const postAcknowledge = makeAsyncEpic(actions.postAcknowledge, api.postAcknowledge);

const setFeedbackRequest = makeAsyncEpic(actions.setFeedbackRequest, FeedbackAPI.setAlertFeedbackPost);
const deleteFeedbackRequest = makeAsyncEpic(actions.deleteFeedbackRequest, FeedbackAPI.deleteFeedback);
const updateFeedbackRequest = makeAsyncEpic(actions.updateFeedbackRequest, FeedbackAPI.updateFeedback);

const fetchAnomaliesTokenMapApi = makeAsyncEpic(actions.fetchAnomaliesTokenMapApi, api.fetchAnomaliesTokenMap);
const fetchAnomaliesTokenMapApiCorrelations = makeAsyncEpic(
  actions.fetchAnomaliesTokenMapApiCorrelations,
  api.fetchAnomaliesTokenMap,
);
const fetchAnomalyMetricsApi = makeFlatAsyncEpic(actions.fetchAnomalyMetricsApi, api.fetchAnomalyMetrics);

const fetchTimeLine = makeAsyncEpic(actions.fetchTimeLine, api.fetchTimeLine);
const sendComment = makeAsyncEpic(actions.sendComment, api.sendComment);

const fetchAlertMetricDataPoints = (action$, {getState}) =>
  action$.ofType(actions.fetchAlertMetricDataPoints.TYPE).flatMap((action) => {
    const metricFound = selectors.getMetricList(getState())[action.meta.key];

    if (metricFound && metricFound.data) {
      return [actions.fetchAlertMetricDataPoints.success(metricFound.data, action.meta)];
    }

    const res = getEmptyTree();
    res.expressionTree.root.searchObject = {
      ids: [action.payload.metricId],
    };

    const newAction = {
      ...action,
      payload: {
        ...action.payload,
        body: {
          composite: getApiTreeForNode(res.expressionTree.root, res, null, {
            alertId: action.meta.alertId, // the alert trigger ID
            // we dont measure metricID cause it will cause the metrics name to be invalid
            // metricId: action.meta.metricId,
            context: 'investigation',
          }),
        },
      },
    };

    return api
      .fetchAlertMetricDataPoints(newAction, getState)
      .map((payload) => actions.fetchAlertMetricDataPoints.success(payload, newAction.meta))
      .catch((error) => Observable.of(actions.fetchAlertMetricDataPoints.failure(error, newAction.meta)));
  });

const setIncidentFilter = (action$, {getState}) =>
  action$.ofType(actions.setIncidentFilter.TYPE).flatMap(() => {
    const state = getState();
    const filters = selectors.getInvModalIncidentFilters(state);
    const startBucketMode = getBucketStartTimeEnabled(state);
    const trigger = selectors.getInvModalTrigger(state);
    const resolutionTypesArr = Object.values(resolutionTypes);
    const resolutionType = resolutionTypesArr.find((item) => item.value2 === trigger.timeScale);
    const params = {
      resolution: resolutionType.value,
      anomalyId: trigger.groupId,
      startBucketMode,
      expression: !filters.length
        ? {expression: []}
        : {
            expression: filters.map((dimension) => ({
              type: 'property',
              isExact: true,
              key: dimension.key,
              value: dimension.value,
            })),
          },
    };

    return [actions.fetchAnomaliesTokenMapApi(params, {isFiltered: !!filters.length})];
  });

const setCorrelationsFilter = (action$, {getState}) =>
  action$.ofType(actions.setCorrelationsFilter.TYPE).flatMap(() => {
    const state = getState();
    const filters = selectors.getInvModalCorrelationsFilters(state);
    const startBucketMode = getBucketStartTimeEnabled(state);
    const trigger = selectors.getInvModalTrigger(state);
    const resolutionTypesArr = Object.values(resolutionTypes);
    const resolutionType = resolutionTypesArr.find((item) => item.value2 === trigger.timeScale);
    const params = {
      resolution: resolutionType.value,
      anomalyId: trigger.groupId,
      startBucketMode,
      expression: !filters.length
        ? {expression: []}
        : {
            expression: filters.map((dimension) => ({
              type: 'property',
              isExact: true,
              key: dimension.key,
              value: dimension.value,
            })),
          },
    };

    return [actions.fetchAnomaliesTokenMapApiCorrelations(params, {isFiltered: !!filters.length})];
  });

const fetchAnomalyMetrics = (action$, {getState}) =>
  action$.ofType(actions.fetchAnomalyMetrics.TYPE).flatMap((action) => {
    const state = getState();
    const {filters} = action.payload;
    const startBucketMode = getBucketStartTimeEnabled(state);
    const trigger = selectors.getInvModalTrigger(state);
    const resolutionTypesArr = Object.values(resolutionTypes);
    const resolutionType = resolutionTypesArr.find((item) => item.value2 === trigger.timeScale);
    const params = {
      index: action.payload.index || 0,
      resolution: resolutionType.value,
      anomalyId: trigger.groupId,
      startBucketMode,
      expression: !filters.length
        ? {
            expression: [
              {
                type: 'property',
                key: 'what',
                value: action.payload.what,
                isExact: true,
              },
            ],
          }
        : {
            expression: [
              // this part changes the filtering -- it was commented so it would filter only
              // by dimension for ALL the different measures.
              // {
              //   type: 'property',
              //   key: 'what',
              //   value: action.payload.what,
              //   isExact: true,
              // },
              ...filters.map((dimension) => ({
                type: 'property',
                isExact: true,
                key: dimension.key,
                value: dimension.value,
              })),
            ],
          },
    };

    if (action.payload.alertId) {
      return [
        actions.fetchAlertMetrics(
          {alertId: action.payload.alertId, ...params},
          {what: action.payload.what, isMore: action.payload.isMore},
        ),
      ];
    }
    return [actions.fetchAllMetrics(params, {what: action.payload.what, isMore: action.payload.isMore})];
  });

const setFeedback = (action$) =>
  action$
    .ofType(actions.setFeedback.TYPE)
    .switchMap(({payload, meta}) => [
      actions.setFeedbackRequest({...payload, triggeredId: undefined, triggerIds: [payload.triggeredId]}, meta),
    ]);

const deleteFeedback = (action$) =>
  action$.ofType(actions.deleteFeedback.TYPE).switchMap(({payload}) => [actions.deleteFeedbackRequest(payload.id)]);

const updateFeedback = (action$, {getState}) =>
  action$.ofType(actions.updateFeedback.TYPE).switchMap(({payload}) => {
    const state = getState();
    const alert = selectors.getInvModalTrigger(state);
    if (isEmpty(alert)) {
      return [];
    }
    return [actions.updateFeedbackRequest({...payload, triggeredId: undefined, triggerIds: [payload.triggeredId]})];
  });

const setAnomalyAndTriggerId = (action$, {getState}) =>
  action$.ofType(actions.setIsOpen.TYPE).switchMap(({payload}) => {
    if (payload) {
      const {anomalyIdInv, triggerIdInv} = getQuery(getState());
      return [actions.setAnomalyId(anomalyIdInv), actions.setTriggerId(triggerIdInv)];
    }
    return [
      actions.resetFetchTriggeredAlertApi(),
      actions.resetFetchAnomaliesTokenMapApi(),
      actions.resetFetchAnomaliesTokenMapApiCorrelations(),
      actions.resetFetchAlertMetrics({}, {meta: true}),
      actions.resetFetchAllMetrics({}, {meta: true}),
    ];
  });

export default combineEpics(
  setAnomalyAndTriggerId,
  fetchAnomalyMetrics,
  fetchTriggeredAlertApi,
  fetchAlertMetricsApi,
  fetchAllMetricsApi,
  postAcknowledge,
  setFeedback,
  setIncidentFilter,
  setCorrelationsFilter,
  deleteFeedback,
  setFeedbackRequest,
  deleteFeedbackRequest,
  updateFeedback,
  updateFeedbackRequest,
  fetchAlertMetricDataPoints,
  fetchAnomaliesTokenMapApi,
  fetchAnomaliesTokenMapApiCorrelations,
  fetchAnomalyMetricsApi,
  fetchTimeLine,
  sendComment,
);
