// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setGoogleStorageStreamKeyVal as setGoogleStorageStreamKeyValAction} from 'bc/store/actions';
import {get} from 'lodash';
import './GoogleStorageProject.module.scss';
import SelectAndt, {THEME_LIGHT, TYPE_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  // connect
  dataStream: Object,
  isLoadingBuckets: boolean,
  buckets: Object,

  setGoogleStorageStreamKeyVal: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isLoadingBuckets: selectors.getGoogleStorageStreamBucketsIsLoading(state),
    buckets: selectors.getGoogleStorageStreamBucketsItems(state),
  }),
  {
    setGoogleStorageStreamKeyVal: setGoogleStorageStreamKeyValAction,
  },
)
export default class GoogleStorageBucket extends React.PureComponent {
  props: PropTypes;

  bucketChanged = (item) => {
    const {setGoogleStorageStreamKeyVal, dataStream} = this.props;
    setGoogleStorageStreamKeyVal({uiState: {...dataStream.uiState, bucket: item.name}});
  };

  render() {
    const {dataStream, isLoadingBuckets, buckets} = this.props;
    const bucket = get(dataStream.uiState, 'bucket', dataStream.bucket);

    const selectedIndex = buckets.findIndex((val) => val.name === bucket);

    return (
      <div className="shell-col" styleName="root">
        <SelectAndt
          id="gsBucketsDdl"
          className="andt-dropdown"
          disabled={isLoadingBuckets}
          options={buckets}
          getOptionLabel={(val) => val.name}
          getOptionValue={(val) => val.name}
          optionHeight={40}
          type={TYPE_SEARCH}
          theme={THEME_LIGHT}
          value={isLoadingBuckets ? null : buckets[selectedIndex]}
          onChange={this.bucketChanged}
          placeholder={isLoadingBuckets ? 'Loading...' : 'Choose a Bucket'}
          automationId="googleStorageBuckets"
        />
      </div>
    );
  }
}
