// @flow
import {get} from 'lodash';
import React, {useState, useCallback, useEffect, useMemo} from 'react';
import MultiSearchBlueLeanSelect from 'common/componentsV2/ddl/MultiSearchBlueLeanSelect';

const getOptionValue = (option) => option.value;
const getOptionLabel = (option) => option.label;

const SortedFilterOptions = ({
  options: optionsInitial,
  onChange,
  onClearAll,
  onApply,
  onBlur,
  value,
  appliedValue,
}: {
  options: Array,
  onChange: Function,
  onClearAll: Function,
  onApply: Function,
  appliedValue: Array,
  onBlur: Function,
  value: Array,
}) => {
  const [options, setOptions] = useState(optionsInitial);

  const sortMulti = useCallback(() => {
    const multiOptions = [...optionsInitial];
    if (value.length) {
      multiOptions.sort((a, b) => {
        const foundA = value.find((item) => getOptionValue(a) === getOptionValue(item));
        const foundB = value.find((item) => getOptionValue(b) === getOptionValue(item));
        if (foundA && foundB) {
          return getOptionLabel(foundA).localeCompare(getOptionLabel(foundB));
        }
        return foundA && !foundB ? -1 : 0;
      });
      setOptions(
        multiOptions.map((o, index) => ({
          ...o,
          isLastSelected: value.length - 1 === index,
        })),
      );
    } else {
      setOptions(optionsInitial);
    }
  }, [optionsInitial, value]);

  useEffect(() => {
    sortMulti();
  }, [optionsInitial.length]);

  const optionsComputed = useMemo(
    () =>
      options.map((item) => ({
        ...item,
        isDisabled: get(optionsInitial.find((initItem) => initItem.value === item.value), 'isDisabled'),
      })),
    [options, optionsInitial],
  );

  return (
    <MultiSearchBlueLeanSelect
      onBlur={onBlur}
      options={optionsComputed}
      value={value}
      onChange={onChange}
      onClearAll={onClearAll}
      onApply={onApply}
      appliedValue={appliedValue}
      onMenuOpen={sortMulti}
    />
  );
};

export default SortedFilterOptions;
