import React, {useRef, useState} from 'react';
import {Box} from '@material-ui/core';
import BottomPanel, {BOTTOM_PANEL_TYPE} from 'common/componentsV2/BottomPanel';
import SplitButton from 'common/componentsV2/SplitButton';
import './MetricExplorerBottomPanel.module.scss';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import useClickOutside from 'common/hooks/useClickOutside';
import Button, {COLORS, HEIGHTS} from 'common/componentsV2/Button';
import {useDispatch, useSelector} from 'react-redux';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import FormDdl from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdl';
import {useForm} from 'react-final-form';
import {segmentClickEvent} from 'common/store/actions';
import * as selectors from '../../store/selectors';
import SaveAlertSelectExpression from './SaveAlertSelectExpression';
import ShowCompositesContainter from './ShowCompositesContainer';
import * as actions from '../../store/actions';

type PropTypes = {
  onSaveAlert: Function,
  onSaveComposite: Function,
  onSaveToDashboard: Function,
  newExpression: Function,
  validation: Object,
};

const MetricExplorerBottomPanel = ({
  onSaveAlert,
  onSaveComposite,
  onSaveToDashboard,
  newExpression,
  validation,
}: PropTypes) => {
  const [isSaveAlertWarning, setSaveAlertWarning] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [saveAlertType, setSaveAlertType] = useState('');
  const [isMoreOptionsPanelOpen, setMoreOptionsPanelOpen] = useState(false);
  const form = useForm();
  const dispatch = useDispatch();
  const expressionTrees = useSelector(selectors.getExpressionTrees);
  const button = useRef();
  useClickOutside(button, () => setSaveAlertWarning(false));

  const saveAlert = () => {
    setSaveAlertType('alert');
    if (expressionTrees.length > 1) {
      setModalOpen(true);
      return;
    }
    if (validation.errorEmptyExpression || validation.errorFunctionDisplay) {
      setSaveAlertWarning(true);
      return;
    }
    onSaveAlert(expressionTrees[0]);
  };

  const saveComposite = () => {
    setSaveAlertType('composite');
    if (expressionTrees.length > 1) {
      setModalOpen(true);
      return;
    }
    if (validation.errorEmptyExpression || validation.errorEmptyFunction) {
      return;
    }
    onSaveComposite(expressionTrees[0]);
  };

  const saveDashboard = () => {
    setSaveAlertType('dashboard');
    if (validation.errorEmptyExpression) {
      return;
    }
    onSaveToDashboard();
  };

  const handleOptionClick = (onClick, text) => {
    dispatch(segmentClickEvent({type: 'click', name: text}));
    if (onClick) {
      onClick();
    }
  };
  const renderOption = (text, onClick, isDisable, textDisable, automationId, errorAutomationId) => {
    if (!isDisable || expressionTrees.length > 1) {
      return (
        <div
          automation-id={automationId}
          styleName="option"
          onClick={() => {
            handleOptionClick(onClick, text);
          }}
        >
          <span>{text}</span>
        </div>
      );
    }
    return (
      <Tooltip extraTtClass="validation-tooltip" placement="left" content={textDisable} type={TYPES.SMALL}>
        <div automation-id={errorAutomationId} styleName="option" onClick={() => onClick()}>
          <span>{text}</span>
          <div className="icon icn-general16-warning" styleName="warning" />
        </div>
      </Tooltip>
    );
  };

  const onModalClose = () => {
    setModalOpen(false);
  };

  const handleSave = (type, index) => {
    setModalOpen(false);
    if (type === 'alert') {
      onSaveAlert(expressionTrees[index].expressionTree);
    }
    if (type === 'composite') {
      onSaveComposite(expressionTrees[index].expressionTree);
    }
  };

  const handleClearAll = () => {
    dispatch(segmentClickEvent({type: 'click', name: 'clear all'}));
    dispatch(actions.clearAllMetricsExplorer());
    form.reset();
  };

  const renderMoreOptionsPanel = () => (
    <div>
      <div styleName="more-option">
        <div>Show Composites</div>
        <ShowCompositesContainter />
      </div>
      <div styleName="more-option button" onClick={handleClearAll}>
        <div>Clear All</div>
      </div>
    </div>
  );

  const getAlertErrorMessage = () => {
    if (validation.errorEmptyExpression) {
      return 'Cannot create alert with no expression';
    }
    if (validation.errorFunctionDisplay) {
      return 'Cannot create alert with display function';
    }
    return '';
  };

  const toggleActionMenuPanel = (isOpen) => {
    setMoreOptionsPanelOpen(isOpen);
  };

  return (
    <BottomPanel type={BOTTOM_PANEL_TYPE.white}>
      <Box
        display="flex"
        justifyContent="space-between"
        maxWidth={1680}
        width={1}
        mx="90px"
        alignItems="center"
        className="idle-external-click"
      >
        <Modal onClose={onModalClose} isOpen={isModalOpen} size={SIZES.SMALL}>
          <SaveAlertSelectExpression
            expressionTrees={expressionTrees}
            onCancel={() => setModalOpen(false)}
            onSave={handleSave}
            typeOfSave={saveAlertType}
          />
        </Modal>
        <div styleName="left-side-control">
          <Button
            isDisabled={expressionTrees.length > 4}
            text="New Expression"
            icon="icn-action16-plusa"
            onClick={newExpression}
            height={HEIGHTS.REGULAR}
            colorSchema={COLORS.GRAY_300}
            automationId="metricExplorerNewExpressionButton"
          />
          <FormDdl
            popoverComponent={renderMoreOptionsPanel()}
            buttonComponent={<div styleName="three-dots-button" className="icon icn-icon-3-dots" />}
            onToggle={(isOpen) => toggleActionMenuPanel(isOpen)}
            width={200}
            isOpen={isMoreOptionsPanelOpen}
            placement="top"
            automationId="metricExplorerMoreOptionsButton"
          />
        </div>
        <div ref={button} styleName="split-button-container">
          {isSaveAlertWarning && <div styleName="save-alert-error">{getAlertErrorMessage()}</div>}
          <SplitButton
            key={isModalOpen}
            text="Create Alert..."
            automationIdButton="metricExplorerCreateAlertButton"
            automationIdSplitOpen="metricExplorerCreateAlertSplitButton"
            onClick={saveAlert}
            menuComponent={
              <div styleName="options-container">
                {renderOption(
                  'Save a Composite',
                  saveComposite,
                  validation.errorEmptyExpression || validation.errorEmptyFunction,
                  'Unable to create composite without a function',
                  'metricExplorerCreateAlertSaveComposite',
                  'metricExplorerSaveCompositeWithWarning',
                )}
                {renderOption(
                  'Save to Dashboard',
                  saveDashboard,
                  validation.errorEmptyExpression,
                  'Unable to save without an expression',
                  'metricExplorerCreateAlertSaveToDashboard',
                  'metricExplorerSaveToDashboardWithWarning',
                )}
              </div>
            }
            placement="top"
          />
        </div>
      </Box>
    </BottomPanel>
  );
};

export default MetricExplorerBottomPanel;
