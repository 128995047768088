// @flow
import React, {useCallback, useEffect, useRef, useState} from 'react';
import TooltipArea from 'common/componentsV2/TooltipArea';
import {useDispatch, useSelector} from 'react-redux';
import * as selectors from 'alerts.management/store/selectors';
import * as profileSelector from 'profile/store/selectors';
import {alertConditionTypes} from 'alerts.management/services/alertsService';
import * as actions from 'alerts.management/store/actions';
import * as metricsActions from 'metrics/store/actions';
import NotificationBar from 'common/components/NotificationBar';
import {Expandable} from 'common/componentsV2/ExpandableSections';
import {TinyScrollBox} from 'common/componentsV2/boxTools';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import * as metricsSelectors from 'metrics/store/selectors';
import {getQuery} from 'common/store/selectors';
import scrollTo from 'common/componentsV2/boxTools/scrollTo';
import CompositeBuilder from '../simulationArea/conditions/CompositeBuilder';
import Settings from './Settings';
import AlertName from '../simulationArea/filters/AlertName';
import AlertDescription from '../simulationArea/filters/AlertDescription';
import Severity from '../simulationArea/filters/Severity';
import Labels from '../simulationArea/filters/Labels';
import Recipients from '../simulationArea/filters/Recipients';
import DynamicRouting from '../simulationArea/dynamicRouting/DynamicRouting';
import Owner from '../simulationArea/filters/Owner';
import SendAlertOnCondition from './SendAlertOnCondition';
import UpdatePolicyCondition from './UpdatePolicyCondition';
import NoDataCondition from './NoDataCondition';
import FiltersBlock from '../simulationArea/FilterBlock';
import AlertType from '../simulationArea/conditions/AlertType';
import CompositeBuilderActions from '../simulationArea/conditions/CompositeBuilderActions';
import AlertActions from '../simulationArea/alertActions/components/AlertActions';

const getCondition = (conditions, type) => conditions.filter((c) => c.type === type)[0];

type PropTypes = {
  alertDefinitionData: Object,
  alertTitleInputRef: Object,
};

const SimulationFilters = (props: PropTypes) => {
  const emptyMetricsTooltipState = useState(false);
  const [, setEmptyMetricsTooltipVisibility] = emptyMetricsTooltipState;
  // const {setAutoSimulationEnabled} = useContext(AlertSettingsContext);

  const dispatch = useDispatch();
  const queryParams = useSelector(getQuery);
  const alertSimulation = useSelector(selectors.getAlertSimulation);
  const isSimulationDisabled = useSelector(metricsSelectors.getIsTreeEmpty);
  const conditions = useSelector(selectors.getConditions);
  const alertType = useSelector(selectors.getAlertType);
  const influencingNoDataEnabled = useSelector(profileSelector.getInfluencingNoDataEnabled);
  const dynamicRoutingEnabled = useSelector(profileSelector.getDynamicRoutingEnabled);
  const alertActionsEnabled = useSelector(profileSelector.getAlertActionsEnabled);
  const filtersRef = useRef();
  const alertActionsRef = useRef(null);
  const isMetricExplorerModalOpen = useSelector(metricsSelectors.getIsMetricExplorerModalOpen);

  useEffect(() => {
    dispatch(actions.setAlertDefinition(props.alertDefinitionData));
    dispatch(metricsActions.initExpressionTreeModel(props.alertDefinitionData.expressionTreeModel));
    if (queryParams.ref && queryParams.ref === 'investigation') {
      scrollTo(filtersRef, alertActionsRef);
    }
    return () => {
      dispatch(actions.resetExpressionBuilder());
    };
  }, []);

  useEffect(() => {
    const deltaCondition = getCondition(conditions, alertConditionTypes.minDelta);
    if (deltaCondition && deltaCondition.enableAutoTuning) {
      const {minDelta} = alertSimulation.data;
      if (minDelta) {
        const minDeltaValue = minDelta.minDelta;
        dispatch(
          actions.setSelectedAlertMinDeltaEstimation({
            minDeltaEstimation: +minDeltaValue === 0 ? null : minDeltaValue,
            deltaPercentage: minDelta.deltaPercentage,
          }),
        );
      }
    }
  }, [alertSimulation.data, dispatch]);

  useEffect(() => {
    if ((!queryParams.ref || !queryParams.ref === 'investigation') && !isMetricExplorerModalOpen) {
      filtersRef.current.scrollTop = 0;
    }
  }, [isSimulationDisabled]);

  const conditionsClickHandler = useCallback(() => {
    if (isSimulationDisabled) {
      setEmptyMetricsTooltipVisibility(true);
    }
  }, [isSimulationDisabled]);

  const areConditionsVisible = alertType === 'noData' ? influencingNoDataEnabled : true;

  const {updatePolicyInterval} = useSelector(selectors.getSelectedAlertSimulationFilters);
  const isAlertEnableAnomalyUpdate = useSelector(profileSelector.getIsAlertEnableAnomalyUpdate);
  const isUpdatePolicyEnabled = alertType === 'anomaly' ? isAlertEnableAnomalyUpdate : true;

  return (
    <TinyScrollBox
      automation-id="alertSettingsContainer"
      ref={filtersRef}
      width={388}
      pr={1.5}
      height={1}
      pb={25}
      pt={1.5}
      css={{overflowY: 'auto', overflowX: 'hidden'}}
    >
      <NotificationBar />
      <TooltipArea
        automationId="alertTypeContainer"
        text="Choose between anomaly alerts, static threshhold alerts, or no data alerts"
      >
        {(info) => (
          <React.Fragment>
            <div className="text16reg lineHeight_16 mb_1">
              Alert Type
              {info}
            </div>
            <Box mb={5} width={292}>
              <AlertType alertDefinitionData={props.alertDefinitionData} />
            </Box>
          </React.Fragment>
        )}
      </TooltipArea>
      <Grid container spacing={2} direction="column">
        <Grid item automation-id="alertMetricsContainer">
          <Expandable
            automationId="alertMetrics"
            label="Alert Metrics"
            number={1}
            expanded
            actionElement={<CompositeBuilderActions isSimulationDisabled={isSimulationDisabled} />}
          >
            <Box mt={2} mb={4} maxWidth={371}>
              {isMetricExplorerModalOpen ? null : (
                <CompositeBuilder
                  isSimulationDisabled={isSimulationDisabled}
                  emptyMetricsTooltipState={emptyMetricsTooltipState}
                />
              )}
            </Box>
          </Expandable>
        </Grid>
        {areConditionsVisible && (
          <Grid item onClick={conditionsClickHandler} automation-id="alertConditionsContainer">
            <Expandable
              automationId="alertConditions"
              label="Alert Conditions"
              number={2}
              isDisabled={isSimulationDisabled}
              expanded
            >
              <Box mt={3} mb={4}>
                <Settings />
              </Box>
            </Expandable>
          </Grid>
        )}
        <Grid item automation-id="infoAndRecipientsContainer">
          <Expandable
            automationId="infoAndRecipients"
            label="Info & Recipients"
            number={areConditionsVisible ? 3 : 2}
            isDisabled={isSimulationDisabled}
            expanded
          >
            <Box mt={3}>
              <Grid container spacing={3} direction="column">
                <Grid item>
                  <AlertName alertTitleInputRef={props.alertTitleInputRef} />
                </Grid>
                <Grid item>
                  <AlertDescription />
                </Grid>

                <Grid item>
                  <FiltersBlock
                    automationId="alertNotifications"
                    label="Alert Notifications"
                    icon="icn-nav24-alertconsole"
                  >
                    <Grid container spacing={3} direction="column">
                      <Grid item>
                        <SendAlertOnCondition />
                      </Grid>
                      {isUpdatePolicyEnabled && updatePolicyInterval.enabled && (
                        <Grid item>
                          <UpdatePolicyCondition />
                        </Grid>
                      )}
                      <Grid item>
                        <NoDataCondition />
                      </Grid>
                      <Grid item>
                        <Recipients />
                      </Grid>
                      {dynamicRoutingEnabled && (
                        <Grid item>
                          <DynamicRouting />
                        </Grid>
                      )}
                    </Grid>
                  </FiltersBlock>
                </Grid>

                {alertActionsEnabled && (
                  <Grid item>
                    <FiltersBlock automationId="alertActions" extraStyle={{display: 'flex'}}>
                      <Box ref={alertActionsRef}>
                        <AlertActions />
                      </Box>
                    </FiltersBlock>
                  </Grid>
                )}
                <Grid item>
                  <Labels />
                </Grid>
                <Grid item>
                  <Severity />
                </Grid>
                <Grid item>
                  <Owner />
                </Grid>
              </Grid>
            </Box>
          </Expandable>
        </Grid>
      </Grid>
    </TinyScrollBox>
  );
};

export default React.memo(SimulationFilters);
