import React from 'react';
import {Box} from '@material-ui/core';

const AddButton = ({children, onClick}: {children: React.Node, onClick: Function}) => (
  <Box
    fontSize={14}
    fontWeight={500}
    px={2}
    py={1.25}
    borderRadius={6}
    border="solid 2px #c9dbff"
    bgcolor="#e9f0ff"
    display="flex"
    color="blue.500"
    lineHeight={1}
    css={{cursor: 'pointer'}}
    onClick={onClick}
  >
    <div className="icn-action16-plusa mr_1" />
    {children}
  </Box>
);

export default AddButton;
