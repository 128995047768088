import React, {useCallback} from 'react';
import {isEmpty} from 'lodash';
import {useField, useForm} from 'react-final-form';
import {makeStyles} from '@material-ui/core';
import Spinner from 'common/componentsV2/Spinner';

const useStyles = makeStyles(({palette}) => ({
  button: {
    background: palette.blue[100],
    border: `2px solid ${palette.blue[300]}`,
    borderRadius: '6px',
    color: palette.blue[500],
    cursor: 'pointer',
    padding: '10px 12px',
    display: 'inline-block',
    lineHeight: '14px',
    fontWeight: 500,
    pointerEvents: ({errors}) => (isEmpty(errors) ? 'auto' : 'none'),
    opacity: ({errors}) => (isEmpty(errors) ? 1 : 0.5),
  },
  spinnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '16px',
    height: '16px',
    marginRight: '4px',
    '& > div': {
      marginLeft: '-4px',
    },
  },
}));

const Test = ({channelType}: {channelType: string}) => {
  const {submit, getState} = useForm();
  const {submitSucceeded, submitFailed, submitting, errors} = getState();
  const classes = useStyles({errors});
  const {
    input: {value: action, onChange: setAction},
  } = useField('action');
  const onClick = useCallback(() => {
    setAction('test');
    submit();
  }, []);

  const doesMessageExist = action === 'test' && (submitting || submitSucceeded || submitFailed);

  return (
    <React.Fragment>
      <div className={classes.button} automation-id="test" onClick={onClick}>
        {channelType === 'email' ? 'Send Test Email' : 'Test Connection'}
      </div>
      {doesMessageExist && (
        <div className="fontSize_12 fontWeight_500 mt_0-5 display_flex alignItems_center">
          {submitting && (
            <React.Fragment>
              <div className={classes.spinnerWrapper}>
                <Spinner color="#3d4c59" size={24} />
              </div>
              {channelType === 'email' ? 'Sending Email' : 'Testing Connection'}
            </React.Fragment>
          )}
          {submitSucceeded && !submitFailed && (
            <React.Fragment>
              <i className="icon icn-general16-checkmark fontSize_16 color_blue-500 mr_0-5" />
              Success! {channelType === 'email' ? 'Email Sent' : 'Test Passed'}
            </React.Fragment>
          )}
          {submitFailed && (
            <React.Fragment>
              <i className="icon icn-general16-warning fontSize_16 color_red-500 mr_0-5" />
              Test failed. Please check your channel settings.
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default Test;
