// @flow
import React, {useCallback} from 'react';
import {makeStyles} from '@material-ui/core';
import {components} from 'react-select2';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {StringParam, useQueryParam} from 'use-query-params';
import channelTypes from 'channels/constants/channelTypes';

const useStyles = makeStyles((theme) => ({
  optionWrap: {
    position: 'relative',
    display: 'flex',
    marginTop: '4px',
    alignItems: 'center',
  },
  optionWrapSelected: {
    position: 'relative',
    display: 'flex',
    marginTop: '4px',
    alignItems: 'center',
    '&:before': {
      color: theme.palette.blue[500],
    },
  },
  logo: {
    width: '14px',
    height: '14px',
    marginLeft: '6px',
    position: 'relative',
    display: 'inline-block',
  },
}));

const TypeFilter = () => {
  const classes = useStyles();
  const [type, setType] = useQueryParam('type', StringParam);
  const selectTypesArr = type ? type.split(',') : [];
  const optionCurrent = channelTypes.filter((i) => selectTypesArr.includes(i.value));

  const onChange = useCallback(
    (values) => {
      setType(values.length ? values.map((i) => i.value).join(',') : undefined);
    },
    [setType],
  );

  const onClearAll = useCallback(() => {
    setType(undefined);
  }, [setType]);

  return (
    <SelectAndt
      id="typeFilter"
      automationId="channelsTypeFilter"
      extraClassName="alerts-dropdown-btn"
      options={channelTypes}
      buttonWidth={190}
      optionHeight={40}
      menuWidth={240}
      type={TYPE_NEW_MULTI}
      theme={THEME_HIGHLIGHTED}
      onChange={onChange}
      value={optionCurrent}
      isMulti
      placeholder="All"
      isClearable={false}
      closeMenuOnSelect={false}
      customComponent={{
        Option: (p) => (
          <components.Option {...p}>
            <div
              className={`${p.isSelected ? classes.optionWrapSelected : classes.optionWrap} ${
                p.isSelected ? 'icn-selection16-checkboxon' : 'icn-selection16-checkboxoff'
              }`}
            >
              <div className="ml_1 display_flex alignItems_center color_gray-500">
                <p.data.icon width={20} height={20} viewBox="0 0 50 50" />
                <div className="ml_0-5">{p.children}</div>
              </div>
            </div>
          </components.Option>
        ),
      }}
      menuFooterComponent={
        <FormDdlActionBar
          isVisible
          isClearAllDisabled={!optionCurrent || !optionCurrent.length}
          onClearAll={onClearAll}
        />
      }
    />
  );
};

export default TypeFilter;
