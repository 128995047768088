// @flow
import React from 'react';
import {withRouter} from 'react-router-dom';
import Button, {COLORS} from 'common/componentsV2/Button';
import connect from 'react-redux/es/connect/connect';
import {gotoState} from 'common/utils/angularServices';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import * as selector from 'alerts.management/store/selectors';
import {openAlertNameModal, simpleAlertReset, toggleSimpleAlertModal} from 'alerts.management/store/actions';
import {fetchDataStreams} from 'bc/store/actions';
import {getUseNewSimpleAlert} from 'profile/store/selectors';
import SimpleAlertForm from './SimpleAlertForm';
import AlertNameEdit from './AlertNameEdit';

type PropTypes = {
  streamSchemas: Object,
  fetchDataStreams: Function,
  useNewSimpleAlert: boolean,
  simpleAlertReset: Function,
  firstMeasure: Object,
  nameModalData: Object,
  openAlertNameModal: Function,
  toggleSimpleAlertModal: Function,
  isNewModalOpen: boolean,
  isAlertEditorV2Enabled: boolean,
  history: Object,
};

@connect(
  (state) => ({
    streamSchemas: selector.getStreamSchemas(state),
    useNewSimpleAlert: getUseNewSimpleAlert(state),
    firstMeasure: selector.getSimpleAlertFirstMeasure(state),
    nameModalData: selector.getNameModalData(state),
    isNewModalOpen: selector.getIsModalOpen(state),
    isAlertEditorV2Enabled: selector.getAlertEditorV2Enabled(state),
  }),
  {
    fetchDataStreams,
    simpleAlertReset,
    openAlertNameModal,
    toggleSimpleAlertModal,
  },
)
class NewAlertButton extends React.PureComponent {
  props: PropTypes;

  state = {
    isNamePopupOpen: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.nameModalData !== state.prevNameModalData) {
      return {isNamePopupOpen: Object.keys(props.nameModalData).length > 0, prevNameModalData: props.nameModalData};
    }
    return null;
  }

  componentDidMount() {
    this.props.fetchDataStreams();
  }

  onNewAlertClicked = () => {
    const {history, isAlertEditorV2Enabled} = this.props;
    if (this.props.useNewSimpleAlert && Object.keys(this.props.streamSchemas).length > 0) {
      this.props.toggleSimpleAlertModal(true);
    } else if (isAlertEditorV2Enabled) {
      history.push('/alert-manager/new');
    } else {
      gotoState('alerts.edit', {
        id: '',
      });
    }
  };

  onModalClose = () => {
    this.props.simpleAlertReset();
    this.props.toggleSimpleAlertModal(false);
  };

  renderNewPopup = () => (
    <Modal
      classStyle="new-alert"
      id="new-alert"
      isOpen={this.props.isNewModalOpen}
      size={SIZES.SMALL}
      isStatic={this.props.firstMeasure.label}
      onClose={this.onModalClose}
      isNotCentered
    >
      <SimpleAlertForm onClose={this.onModalClose} />
    </Modal>
  );

  closeNamePopup = () => {
    this.setState({isNamePopupOpen: false});
    this.props.openAlertNameModal({});
  };

  renderNamePopup = () => (
    <Modal isOpen={this.state.isNamePopupOpen} onClose={this.closeNamePopup} classStyle="name-popup" size={SIZES.SMALL}>
      <AlertNameEdit
        close={this.closeNamePopup}
        alert={this.props.nameModalData.alert}
        type={this.props.nameModalData.type}
        origin="simple-alert"
      />
    </Modal>
  );

  render() {
    return (
      <div>
        {this.renderNewPopup()}
        {this.renderNamePopup()}
        <Button
          automationId="New Alert Button"
          colorSchema={COLORS.BLUE_500}
          icon="icn-action16-plusa"
          text="New Alert"
          onClick={this.onNewAlertClicked}
        />
      </div>
    );
  }
}

export default withRouter(NewAlertButton);
