import {makeAction, makeAsyncAction} from 'common/utils/simplifiedAsync';

const macAsync = (actionName) => makeAsyncAction(actionName, 'anodot/app/');

export const appInit = makeAction('anodot/APP_INIT');
export const noop = makeAction('anodot/NO_OP');

export const setRoute = makeAction('anodot/SET_ROUTE');
export const routeChanged = makeAction('anodot/ROUTE_CHANGED');
export const replaceRoute = makeAction('anodot/REPLACE_ROUTE');

export const addLoading = makeAction('anodot/ADD_LOADING');
export const removeLoading = makeAction('anodot/REMOVE_LOADING');

export const copyToClipBoard = makeAction('anodot/COPY_TO_CLIPBOARD');

export const segmentPageInit = makeAction('SEGMENT_PAGE_INIT'); // angular
export const segmentCauseEvent = makeAction('SEGMENT_CAUSE_EVENT'); // angular
export const segmentCausingEvent = makeAction('SEGMENT_CAUSING_EVENT');
export const segmentClickEvent = makeAction('SEGMENT_CLICK_EVENT');

export const removeNotification = makeAction('REMOVE_NOTIFICATION');
export const fetchConfigurationFile = macAsync('FETCH_CONFIGURATION_FILE');
