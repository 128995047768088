// @flow
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {useQueryParams} from 'use-query-params';
import {cloneDeep} from 'lodash';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {
  LEAF_REGION_PANEL_TABS,
  PANEL_SIZES,
  QUERY_PARAM_MAP,
  REGIONS_LABEL_PER_LEVEL,
  filteredIssuesList,
} from 'topologyGeneral/services/sidePanelService';
import {MAX_SITES_ZOOM} from 'topologyGeneral/services/mapService';
import TabsBar from 'topologyGeneral/components/common/TabsBar';
import BackButton from 'topologyGeneral/components/common/BackButton';
import SelectedRegionItem from 'topologyGeneral/components/common/SelectedRegionItem';
import DomainCarousel from 'topologyGeneral/components/sidePanel/DomainCarousel';
import LocationsList from 'topologyGeneral/components/sidePanel/LocationsList';
import IssuesSubList from 'topologyGeneral/components/sidePanel/issuesSubPanel/IssuesSubList';
import TinyScrollBox from 'common/componentsV2/boxTools/TinyScrollBox';
import {fetchTopologyMapRegionSitesList} from 'topologyGeneral/store/actions';
import {
  getGtpMapRegions,
  getGtpMapSitesList,
  getGtpMapSites,
  getGtpMapIssues,
  isLoadingGtpMapRegionsSitesList,
} from 'topologyGeneral/store/selectors';
import './SidePanel.module.scss';

const LeafRegionPanel = ({mapRef, isVisible, nodeHoveredId}: PropTypes) => {
  const dispatch = useDispatch();

  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAM_MAP);
  const regionItems = useSelector(getGtpMapRegions);
  const siteList = useSelector(getGtpMapSitesList);
  const allSites = useSelector(getGtpMapSites);
  const issues = useSelector(getGtpMapIssues);
  const isSitesListLoading = useSelector(isLoadingGtpMapRegionsSitesList);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [leafRegionsPanelTabs, setLeafRegionsPanelTabs] = useState(LEAF_REGION_PANEL_TABS);
  const [filteredIssues, setFilteredIssues] = useState([]);

  useEffect(() => {
    const findRegion = regionItems.find((r) => r.id === queryParams.regionId);
    setSelectedRegion(findRegion || null);
  }, [queryParams.regionId, regionItems]);

  useEffect(() => {
    if (selectedRegion && selectedRegion.isLeaf) {
      dispatch(
        fetchTopologyMapRegionSitesList({regionId: selectedRegion.serverId}, {map: mapRef, region: selectedRegion}),
      );
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (selectedRegion && siteList.items && selectedRegion.serverId === siteList.regionId) {
      const fil = filteredIssuesList(issues, null, allSites);
      const cloned = cloneDeep(LEAF_REGION_PANEL_TABS);
      cloned.alertedSites.label += ` (${siteList.items.length}/${siteList.total})`;
      cloned.alerts.label += ` (${fil.relevant}/${fil.total})`;
      setFilteredIssues(fil.filteredIssues);
      setLeafRegionsPanelTabs(cloned);
    } else {
      setLeafRegionsPanelTabs(LEAF_REGION_PANEL_TABS);
    }

    return () => {
      setLeafRegionsPanelTabs(LEAF_REGION_PANEL_TABS);
    };
  }, [selectedRegion, siteList, allSites]);

  const tabClicked = useCallback((tabId) => {
    setQueryParams({leafRegionTabId: tabId});
  }, []);

  const onBackClick = () => {
    setQueryParams({
      leafRegionTabId: undefined,
      domainId: undefined,
      regionId: selectedRegion.level === 0 ? undefined : selectedRegion.parentRegionId,
    });
  };

  const locItemClicked = (locItem) => {
    const newSelectedLocationId = queryParams.siteId === locItem.id ? undefined : locItem.id;
    setQueryParams({siteId: newSelectedLocationId, selectedEntityId: undefined, selectedSubEntityId: undefined});
    if (newSelectedLocationId) {
      const findLoc = siteList.items.find((sl) => sl.id === newSelectedLocationId);
      if (findLoc) {
        mapRef.current.setView(findLoc.geoPoint, MAX_SITES_ZOOM, {
          animate: true,
        });
      }
    }
  };

  if (!isVisible || !selectedRegion) {
    return null;
  }

  return (
    <Box mr={2} ml={2}>
      <Box display="flex" mt={3} mb={1} height={16}>
        <BackButton text={`${REGIONS_LABEL_PER_LEVEL[selectedRegion.level]}`} onClick={onBackClick} />
      </Box>
      <SelectedRegionItem region={selectedRegion} />
      <Box display="flex" mt={2}>
        <DomainCarousel />
      </Box>
      <Box display="flex" borderBottom="1px solid #d4d9e0" pl={0.5} mt={2.75}>
        <TabsBar
          tabs={Object.values(leafRegionsPanelTabs)}
          selectedTabId={queryParams.leafRegionTabId}
          onTabClick={tabClicked}
        />
      </Box>
      <TinyScrollBox
        position="absolute"
        top={274}
        bottom={0}
        right={3}
        left={0}
        css={{overflowY: 'auto', overflowX: 'hidden'}}
      >
        <Box width={PANEL_SIZES.innerPanelWidth} ml={2}>
          {queryParams.leafRegionTabId === leafRegionsPanelTabs.alertedSites.id && (
            <Fragment>
              {isSitesListLoading ? (
                <Box
                  display="flex"
                  flexGrow={1}
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                  width={PANEL_SIZES.innerPanelWidth}
                  position="absolute"
                >
                  <Spinner color="#3d4c59" size={SIZES.BIG_60} />
                </Box>
              ) : (
                <LocationsList
                  items={siteList.items || []}
                  onItemClick={locItemClicked}
                  selectedItemId={nodeHoveredId}
                />
              )}
            </Fragment>
          )}
          {queryParams.leafRegionTabId === leafRegionsPanelTabs.alerts.id && (
            <IssuesSubList issues={filteredIssues} isLoading={isSitesListLoading} />
          )}
        </Box>
      </TinyScrollBox>
    </Box>
  );
};

LeafRegionPanel.propTypes = {
  mapRef: PropTypes.objectOf(PropTypes.any).isRequired,
  nodeHoveredId: PropTypes.string,
  isVisible: PropTypes.bool,
};

LeafRegionPanel.defaultProps = {
  nodeHoveredId: undefined,
  isVisible: true,
};

export default LeafRegionPanel;
