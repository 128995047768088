// @flow
import React from 'react';
import {connect} from 'react-redux';
import {getTypeDetails} from 'bc/services/bcTypes';
import * as selectors from 'bc/store/selectors';
import {
  createDataSource as createDataSourceAction,
  resetAddSourceError as resetAddSourceErrorAction,
} from 'bc/store/actions';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {regionsUrls} from 'bc/services/genericStorageService';
import DataSourceModal from '../../DataSourceModal';
import '../../DataSourceModal.module.scss';

@connect(
  (state) => ({
    isLoading: selectors.getAddDataSourceState(state).isLoading,
    sourceError: selectors.getAddDataSourceState(state).error,
  }),
  {
    createDataSource: createDataSourceAction,
    resetAddSourceError: resetAddSourceErrorAction,
  },
)
export default class CoralogixSourceModal extends React.PureComponent {
  props: {
    isOpen: boolean,
    isViewOnly: boolean,
    isEditable: boolean,
    source: Object,
    sourceType: Object,
    isLoading: boolean,
    sourceError: Object,
    onClose: Function,
    createDataSource: Function,
    resetAddSourceError: Function,
  };

  // eslint-disable-next-line no-extra-boolean-cast
  state = !!this.props.source
    ? {
        regionURL: regionsUrls.find((reg) => reg.value === this.props.source.regionURL).value,
        port: this.props.source.port,
        apiToken: this.props.source.apiToken,
      }
    : {
        regionURL: regionsUrls[0].value,
        port: this.props.sourceType.defaultPort,
        apiToken: '',
      };

  valueChanged = (val, inputId) => {
    const newState = {};
    newState[inputId] = val;
    this.setState(newState);
  };

  regionChanged = (val) => {
    this.setState({regionURL: val.value});
  };

  createBtnClicked = () => {
    const {sourceType, createDataSource} = this.props;

    const obj = {
      type: getTypeDetails(sourceType.type).type,
      name: `${getTypeDetails(sourceType.type).shortName} Data Source ${new Date().getTime().toString()}`,
      regionURL: this.state.regionURL,
      port: this.state.port,
      apiToken: this.state.apiToken,
    };
    createDataSource(obj);
  };

  onCloseInternal = () => {
    this.props.resetAddSourceError();
    this.props.onClose();
  };

  render() {
    const {isOpen, isLoading, source, sourceError, sourceType, isViewOnly, isEditable} = this.props;
    const {regionURL, port, apiToken} = this.state;

    const selectedIndex = regionsUrls.findIndex((val) => regionURL === val.value);

    return (
      <DataSourceModal
        isOpen={isOpen}
        isContinueDisabled={!port || !apiToken}
        isProcessing={isLoading}
        isViewOnly={isViewOnly}
        isRenameEnabled={isEditable}
        source={source}
        sourceType={sourceType}
        logoImageClass={`source-logo-${sourceType.type}`}
        onClose={this.onCloseInternal}
        onContinue={this.createBtnClicked}
      >
        <div styleName="inputs-wrapper">
          <SelectAndt
            id="coralogixRegion"
            automationId="coralogixRegion"
            styleName="region-dropdown"
            className="andt-dropdown"
            options={regionsUrls}
            getOptionLabel={(val) => val.text}
            getOptionValue={(val) => val.value}
            type={TYPE_NO_SEARCH}
            theme={THEME_LIGHT}
            value={regionsUrls[selectedIndex]}
            onChange={this.regionChanged}
            disabled={isViewOnly}
            placeholder="Choose Region URL"
          />

          <input
            type="text"
            automation-id="coralogixport"
            onChange={(e) => this.valueChanged(e.target.value, 'port')}
            placeholder="Port"
            value={port}
          />

          <input
            type="password"
            automation-id="coralogixApiToken"
            onChange={(e) => this.valueChanged(e.target.value, 'apiToken')}
            placeholder="apiToken"
            value={apiToken}
          />

          {!isLoading && sourceError ? (
            <div styleName="error-wrapper error-wrapper-s3">
              <span styleName="error-message">
                <i className="icon icn-warning-icon" /> {sourceError.uMessage}
              </span>
            </div>
          ) : null}

          <div styleName="extra-info">
            For more information on the Coralogix integration, refer to
            <a
              href="https://support.anodot.com/hc/en-us/articles/4402194307090"
              rel="noopener noreferrer"
              target="_blank"
            >
              Anodot Documentation
            </a>
          </div>
        </div>
      </DataSourceModal>
    );
  }
}
