import React from 'react';
import {Box} from '@material-ui/core';
import {useField} from 'react-final-form';
import Checkbox from 'common/componentsV2/Checkbox';
import RadioButtonsList from 'common/componentsV2/RadioButtonsList';

const legendOptions = [{label: 'Under graph', value: 'bottom'}, {label: 'Right-side panel', value: 'right'}];

const LegendOptions = () => {
  const {
    input: {value: legendPosition, onChange: legendPositionOnChange},
    // meta,
  } = useField('legendPosition');

  const {
    input: {value: showLegendValue, onChange: showLegendOnChange},
    // meta,
  } = useField('showLegend');

  return (
    <React.Fragment>
      <Box my={2}>
        <Checkbox isChecked={showLegendValue} text="Show Legend on Tile" onChange={showLegendOnChange} />
      </Box>
      <Box fontSize={14} fontWeight={500} color="#8998a0" mb={1}>
        Position:{' '}
      </Box>
      <RadioButtonsList
        direction="column"
        options={legendOptions}
        value={legendPosition}
        onChange={(option) => legendPositionOnChange(option.value)}
      />
    </React.Fragment>
  );
};

export default LegendOptions;
