import React, {Fragment, memo} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';

const Direction = ({isDrop, isSpike, percentageText, arrow}: PropTypes) => {
  const bothDirections = isDrop && isSpike;

  let dirText = '';
  if (bothDirections) {
    dirText = 'Drop\\Spike in';
  } else {
    dirText = isDrop ? 'Drop in' : 'Spike in';
  }
  return (
    <Fragment>
      {arrow && (
        <Box fontSize={22}>
          {isDrop && (
            <Box component="span" width={bothDirections ? 16 : 22}>
              <i className="icon icn-arrow16-arrowdown" />
            </Box>
          )}
          {isSpike && (
            <Box component="span" ml={bothDirections ? -1.25 : 0}>
              <i className="icon icn-arrow16-arrowup" />
            </Box>
          )}
        </Box>
      )}
      {percentageText && <Box mr={0.75}>{`${percentageText}%`}</Box>}
      <Box mr={arrow ? 0.5 : 0}>{dirText}</Box>
    </Fragment>
  );
};

Direction.defaultProps = {
  arrow: true,
};

Direction.propTypes = {
  arrow: PropTypes.bool,
  isDrop: PropTypes.bool.isRequired,
  isSpike: PropTypes.bool.isRequired,
  percentageText: PropTypes.string.isRequired,
};

export default memo(Direction);
