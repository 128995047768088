import React from 'react';
import {ReactComponent as EmptySearchIcon} from 'app/images/empty-search.svg';
import './EmptySearch.module.scss';

type PropTypes = {
  error: string,
  errorExplanation: string,
};

const EmptySearch = ({error, errorExplanation}: PropTypes) => {
  return (
    <div styleName="container">
      <EmptySearchIcon width="109" height="81" viewBox="0 0 109 81" />
      <div styleName="no-sources">{error}</div>
      <div styleName="change-filters">{errorExplanation}</div>
    </div>
  );
};

export default EmptySearch;
