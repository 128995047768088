// @flow
import React from 'react';
import {Modal as BootstrapModal} from 'react-bootstrap';
import './Modal.module.scss';

type PropTypes = {
  isOpen: boolean,
  onClose: Function,
  size?: string,
  height?: string,
  isCloseButtonHidden?: boolean,
  isStatic?: boolean,
  children?: any,
  id?: string,
  classStyle?: string,
  className?: string,
  isNotCentered: boolean,
  isAnimation?: boolean,
};

export const SIZES = {
  SMALL: 'size-small',
  MEDIUM: 'size-medium',
  LARGE: 'size-large',
  XLARGE: 'size-xlarge',
  XXLARGE: 'size-xxlarge',
  MAXWIDTH: 'size-maxwidth',
};

export default class Modal extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    size: null,
    isCloseButtonHidden: false,
    isStatic: false,
    children: null,
    id: null,
    classStyle: '',
    className: '',
    height: 'auto',
    isAnimation: false,
  };

  render() {
    const {
      isOpen,
      onClose,
      size,
      isStatic,
      isCloseButtonHidden,
      children,
      id,
      classStyle,
      isNotCentered,
      className,
      height,
      isAnimation,
    } = this.props;

    const dialogClassName = ['andt-modal-v2', isNotCentered ? '' : 'centered', classStyle, size || ''].join(' ');

    return (
      <BootstrapModal
        enforceFocus={false}
        id={id}
        show={isOpen}
        onHide={onClose}
        backdrop={isStatic ? 'static' : true}
        dialogClassName={dialogClassName}
        animation={isAnimation}
      >
        <div styleName="content-root" className={className} style={{height}}>
          {!isCloseButtonHidden && (
            <i className="icon icn-general16-closeb" onClick={onClose} styleName="close-modal" />
          )}
          {children && children}
        </div>
      </BootstrapModal>
    );
  }
}
