// @flow
import React, {Fragment} from 'react';
import {Box} from '@material-ui/core';
import {useSelector} from 'react-redux';
import './EventExpressionBuilder.module.scss';
import * as selectors from 'userEvents/store/selectors';

const EventListing = () => {
  const events = useSelector(selectors.getEvents);

  const renderDate = (date) => {
    const newDate = new Date(date * 1000);
    return newDate;
  };

  const renderTopEvents = (topEvents) =>
    topEvents.map((topEvent) => (
      <Fragment>
        <div>Top Events:</div>
        <Box ml={2} pl={1} borderLeft={1} borderColor="#BBB">
          <div>{`Title: ${topEvent.title}`}</div>
          <div>{`Description: ${topEvent.description}`}</div>
          <div>{`Source: ${topEvent.source}`}</div>
          <div>{`Category: ${topEvent.category}`}</div>
          <div>{`StartDate: ${renderDate(topEvent.startDate)}`}</div>
          <div>{`End Date: ${renderDate(topEvent.endDate)}`}</div>
          <div>{`Anodot internal: ${topEvent.anodotInternalContent}`}</div>
        </Box>
      </Fragment>
    ));

  return (
    <div>
      {events.map((evt) => (
        <Box m={2} border={1} borderColor="#BBB" borderRadius={4} p={2} boxSizing="border-box">
          <div>{`Date: ${renderDate(evt.date)}`}</div>
          <div>{`Total Events: ${evt.totalEvents}`}</div>
          {renderTopEvents(evt.topEvents)}
        </Box>
      ))}
    </div>
  );
};

export default EventListing;
