// @flow
import React from 'react';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import {getUniqueId} from 'common/utils/guid';
import {pick} from 'lodash';
import EmptyComponent from './EmptyComponent';

type PropTypes = {
  id: String,
  content: Node,
  hideIfEqualTo: Node,
  extraTtClass: String, // set additional css class for the tooltip object
  children: any,
};

class SmartTooltip extends React.PureComponent {
  props: PropTypes;

  render() {
    const {children, id, content, hideIfEqualTo, extraTtClass} = this.props;
    let tooltip = <EmptyComponent />;
    if (content !== undefined && content !== null) {
      const ttClass = extraTtClass ? {bsClass: `${extraTtClass} tooltip`} : {};
      tooltip = (
        <Tooltip id={id || getUniqueId()} {...ttClass}>
          {content}
        </Tooltip>
      );
    }
    if (hideIfEqualTo && hideIfEqualTo === content) {
      tooltip = <EmptyComponent />;
    }

    return (
      <OverlayTrigger
        placement="top"
        overlay={tooltip}
        {...pick(this.props, ['placement', 'delay', 'delayHide', 'delayShow', 'shouldUpdatePosition'])}
      >
        {children}
      </OverlayTrigger>
    );
  }
}

export default SmartTooltip;
