// @flow
import React from 'react';
import Loading from 'metrics/components/Loading';
import {getDate} from 'common/utils/dateRangeService';
import {getNotificationDelay, pollingIntervals} from 'bc/services/dataStreamService';
import {bcTypes} from 'bc/services/bcTypes';
import './ViewStreamSummaryModalV2.module.scss';

const GaSchedular = (props: {dataStream: Object, timeZoneName: String}) => (
  <div styleName="section">
    <div styleName="vs-section-header">STREAM SETTINGS</div>
    <div styleName="vs-row">
      <div styleName="vs-label">Time Scale</div>
      <div styleName="vs-value">
        <span>{pollingIntervals[props.dataStream.pollingInterval].labelShort}</span>
      </div>
    </div>
    <div styleName="vs-row">
      <div styleName="vs-label">Historical Data</div>
      <div styleName="vs-value">
        <span>{getDate(props.dataStream.historicalDateRange, props.timeZoneName).dateText}</span>
      </div>
    </div>
    <div styleName="vs-row">
      <div styleName="vs-label">Min. Delay for Data</div>
      <div styleName="vs-value">
        <span>{!props.dataStream.delayMinutes ? 'No Delay' : `${props.dataStream.delayMinutes / 60} Hours`}</span>
      </div>
    </div>
    <div styleName="vs-row">
      <div styleName="vs-label">Max. Delay for Data</div>
      <div styleName="vs-value">
        <span>
          {props.dataStream.maxGoldenDelayMinutes === null || props.dataStream.maxGoldenDelayMinutes === undefined
            ? 'Wait for Golden'
            : `${props.dataStream.maxGoldenDelayMinutes / 60} Hours`}
        </span>
      </div>
    </div>
  </div>
);

const GeneralScheduler = (props: {
  dataStream: Object,
  timeZoneName: String,
  timeColumn: String,
  isTimeDefinitionLoading: boolean,
}) => (
  <div styleName="section">
    <div styleName="vs-section-header">STREAM SETTINGS</div>
    {props.timeColumn ? (
      <div styleName="vs-row">
        <div styleName="vs-label">Time Column</div>
        <div styleName="vs-value">{props.isTimeDefinitionLoading ? <Loading /> : props.timeColumn}</div>
      </div>
    ) : null}
    {![bcTypes.kinesis.type, bcTypes.eventhubs.type].includes(props.dataStream.type) &&
    props.dataStream.pollingInterval ? (
      <div styleName="vs-row">
        <div styleName="vs-label">Collect Every</div>
        <div styleName="vs-value">{pollingIntervals[props.dataStream.pollingInterval].labelShort}</div>
      </div>
    ) : null}
    {![bcTypes.kinesis.type, bcTypes.eventhubs.type].includes(props.dataStream.type) ? (
      <div styleName="vs-row">
        <div styleName="vs-label">Collect Since</div>
        <div styleName="vs-value">
          {getDate(props.dataStream.historicalDateRange, props.dataStream.timeZone || props.timeZoneName).dateText}
        </div>
      </div>
    ) : null}
    {props.dataStream.delayMinutes >= 0 ? (
      <div styleName="vs-row">
        <div styleName="vs-label">Delay for Data</div>
        <div styleName="vs-value">
          {props.dataStream.delayMinutes === 0 ? 'No Delay' : `${props.dataStream.delayMinutes} Minutes`}
        </div>
      </div>
    ) : null}
    {props.dataStream.maxBackFillIntervals >= 0 ? (
      <div styleName="vs-row">
        <div styleName="vs-label">Backfill Policy</div>
        <div styleName="vs-value">
          {props.dataStream.maxBackFillIntervals === 0 ? 'No Backfill' : props.dataStream.maxBackFillIntervals}
        </div>
      </div>
    ) : null}
    {props.dataStream.maxMissingFiles >= 0 ? (
      <div styleName="vs-row">
        <div styleName="vs-label">Lagging Files Policy</div>
        <div styleName="vs-value">
          {props.dataStream.maxMissingFiles === 0 ? 'No Wait' : props.dataStream.maxMissingFiles}
        </div>
      </div>
    ) : null}
    {props.dataStream.timeZone ? (
      <div styleName="vs-row">
        <div styleName="vs-label">Time Zone</div>
        <div styleName="vs-value">{props.dataStream.timeZone}</div>
      </div>
    ) : null}
    {props.dataStream.type === bcTypes.s3.type ? (
      <div styleName="vs-row">
        <span styleName="vs-label">No data notification after:</span>
        <span styleName="vs-value">{getNotificationDelay(props.dataStream.notificationDelay)}</span>
      </div>
    ) : null}
  </div>
);

const SchedulerSection = (props: {
  dataStream: Object,
  timeZoneName: String,
  timeColumn: String,
  isTimeDefinitionLoading: boolean,
}) => {
  if (!props.dataStream) {
    return null;
  }

  switch (props.dataStream.type) {
    case bcTypes.google_analytics.type:
      return <GaSchedular dataStream={props.dataStream} timeZoneName={props.timeZoneName} />;
    case bcTypes.adobe.type:
    case bcTypes.bigquery.type:
    case bcTypes.mysql.type:
    case bcTypes.psql.type:
    case bcTypes.mssql.type:
    case bcTypes.mariadb.type:
    case bcTypes.redshift.type:
    case bcTypes.snowflake.type:
    case bcTypes.oracle.type:
    case bcTypes.s3.type:
    case bcTypes.google_storage.type:
    case bcTypes.kinesis.type:
    case bcTypes.eventhubs.type:
    case bcTypes.salesforce.type:
    case bcTypes.databricks.type:
    case bcTypes.athena.type:
    case bcTypes.google_ads.type:
    case bcTypes.teradata.type:
    case bcTypes.athena_sql.type:
    case bcTypes.timestream.type:
      return (
        <GeneralScheduler
          dataStream={props.dataStream}
          timeZoneName={props.timeZoneName}
          timeColumn={props.timeColumn}
          isTimeDefinitionLoading={props.isTimeDefinitionLoading}
        />
      );
    case bcTypes.local_file.type:
    default:
      return null;
  }
};

export default SchedulerSection;
