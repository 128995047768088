import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {palette} from 'app/styles/theme';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {makeStyles} from '@material-ui/core/styles';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import DimensionItem from './DimensionItem';

const OTHER_FROM_LENGTH = 6;

const useStyles = makeStyles(() => ({
  icon: {
    color: palette.gray['350'],
    cursor: 'pointer',
    '&:hover': {
      color: palette.gray['500'],
    },
  },
}));

const DimensionsBreakdownRow = ({
  token,
  filters,
  onSelect,
  isVisible,
  onChangeVisibility,
  visibilityTooltip,
}: PropTypes) => {
  const classes = useStyles();
  const [isOver, setOver] = useState(false);
  const [mergedToken, setMergedToken] = useState([]);
  const [isKeySelected, setIsKeySelected] = useState([]);

  const makePercentage = (allValues, value) => {
    if (allValues.length === 1) {
      return 100;
    }
    return (value / allValues.reduce((acc, obj) => acc + obj.weight, 0)) * 100;
  };

  const combineOthers = (data) => {
    const other = {
      weight: 0,
      anomalyOccurrences: 0,
      id: 'other',
      value: 'Other',
    };

    data.forEach((value, index) => {
      if (index >= OTHER_FROM_LENGTH) {
        other.weight += value.weight;
        other.anomalyOccurrences += value.anomalyOccurrences;
      }
    });

    const slicedData = data.slice(0, OTHER_FROM_LENGTH);

    if (other.weight === 0) {
      return slicedData;
    }
    return [...slicedData, other];
  };

  useEffect(() => {
    setMergedToken(combineOthers(token.data));
  }, [token]);

  useEffect(() => {
    setIsKeySelected(filters.some((filter) => filter.key === token.key));
  }, [filters]);

  if (isKeySelected) {
    return null;
  }

  const renderVisibilityIcon = () => {
    if (!isOver) {
      return null;
    }
    const icon = isVisible ? 'icn-general16-hide' : 'icn-general16-show';
    if (visibilityTooltip === '') {
      return <div onClick={onChangeVisibility} className={['icon', icon, classes.icon].join(' ')} />;
    }
    return (
      <Tooltip type={TYPES.SMALL} content={visibilityTooltip}>
        <div>
          <div onClick={onChangeVisibility} className={['icon', icon, classes.icon].join(' ')} />
        </div>
      </Tooltip>
    );
  };

  return (
    <Box mb={3} percentage={350} onMouseEnter={() => setOver(true)} onMouseLeave={() => setOver(false)}>
      {mergedToken.length && (
        <Box>
          <Box display="flex" justifyContent="space-between" mb={0.5}>
            <Box display="flex" width="40%" height="15px">
              <TypographyBox variant="subtitle4" isEllipsis color={palette.eucaliptus['500']} mr={1}>
                {token.key}
              </TypographyBox>
              {renderVisibilityIcon()}
            </Box>
            <TypographyBox variant="subtitle4" isEllipsis width="58%" textAlign="right">
              <span>{makePercentage(token.data, mergedToken[0].weight).toFixed(0)}%</span>{' '}
              <span style={{color: '#00934E'}}>{mergedToken[0].value}</span>
            </TypographyBox>
          </Box>
          <Box display="flex" height={18} overflow="hidden" css={{borderRadius: 4}}>
            {mergedToken.map((dimension, index) => (
              <DimensionItem
                key={dimension.id}
                index={index}
                percentage={makePercentage(token.data, dimension.weight)}
                isLast={index === mergedToken.length - 1}
                dimension={dimension}
                filters={filters}
                onSelect={onSelect}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

DimensionsBreakdownRow.propTypes = {
  token: PropTypes.objectOf(PropTypes.any).isRequired,
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onChangeVisibility: PropTypes.func.isRequired,
  visibilityTooltip: PropTypes.string.isRequired,
};

export default DimensionsBreakdownRow;
