export default {
  email: 'https://support.anodot.com/hc/en-us/articles/208206905-Anodot-Email-integration',
  slack: 'https://support.anodot.com/hc/en-us/articles/207497499-Anodot-Slack-integration',
  pagerduty: 'https://support.anodot.com/hc/en-us/articles/360002657494-Anodot-PagerDuty-integration',
  jira: 'https://support.anodot.com/hc/en-us/articles/360015193313-Anodot-JIRA-integration',
  opsgenie: 'https://support.anodot.com/hc/en-us/articles/360015904454-Anodot-Opsgenie-integration',
  msteams: 'https://support.anodot.com/hc/en-us/articles/360023167973-Anodot-Microsoft-Teams-integration',
  tamtam: 'https://support.anodot.com/hc/en-us/articles/360013667780-Anodot-TamTam-Chat-integration',
  sns: 'https://support.anodot.com/hc/en-us/articles/360018891020-Anodot-AWS-SNS-integration',
  slackapp: 'https://support.anodot.com/hc/en-us/articles/360021042120-Anodot-Slack-Application',
  webhook: 'https://support.anodot.com/hc/en-us/articles/208206925-Anodot-Webhook-integration',
};
