// @flow
import React, {Fragment} from 'react';
import {segmentClickEvent} from 'common/store/actions';
import {connect} from 'react-redux';
import {Box} from '@material-ui/core';
import ReactDOM from 'react-dom';
import './FormDdl.module.scss';
import fastdom from 'fastdom';
import 'rxjs/add/observable/fromEvent';
import {Observable} from 'rxjs/Observable';
import {noop} from 'lodash';

type PropTypes = {
  popoverComponent: Node,
  buttonComponent: Node,
  onToggle: Function,
  onClickOutside?: Function,
  isOpen: boolean,
  appendToElementById?: string,
  position?: String,
  placement?: String,
  popoverContainerClassName?: String,
  withArrow?: Boolean,
  isCloseMenuOnScroll?: Boolean,
  disabled?: boolean,
  width: number,
  horizontalOffset?: number,
  verticalOffset?: number,
  overflowable?: boolean,
  maxWidth?: number,
  buttonWidth: number,
  onHoverOpenMenu?: boolean,
  exceptionsClickOutside?: Array,
  automationId?: String,
  id?: String,
  maxHeight?: number,
  segmentClickEvent: Function,
};

@connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)
export default class FormDdl extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    appendToElementById: null,
    position: 'left',
    placement: 'bottom',
    withArrow: false,
    popoverContainerClassName: '',
    isCloseMenuOnScroll: true,
    disabled: false,
    onClickOutside: noop,
    horizontalOffset: 0,
    verticalOffset: 0,
    overflowable: false,
    maxWidth: undefined,
    onHoverOpenMenu: false,
    exceptionsClickOutside: [],
    automationId: '',
    id: '',
    maxHeight: 0,
  };

  componentDidMount() {
    this.portal = document.createElement('div');
    this.portal.className = this.props.withArrow ? 'form-ddl-container-style with-arrow' : 'form-ddl-container-style';
    if (this.props.overflowable) {
      this.portal.className = `${this.portal.className} overflowable`;
    }

    if (this.props.isCloseMenuOnScroll) {
      this.scrollEvent$ = Observable.fromEvent(document, 'scroll');
      this.scrollEvent$.subscribe((val) => {
        this.handleScroll(val);
      });
    }

    if (this.props.appendToElementById) {
      this.ddContainer = document.getElementById(this.props.appendToElementById);
      if (this.ddContainer) {
        this.ddContainer.appendChild(this.portal);
      } else {
        document.body.appendChild(this.portal);
      }
    } else {
      document.body.appendChild(this.portal);
    }
    this.mouseEvent$ = Observable.fromEvent(document, 'click');
    this.mouseEvent$.subscribe((val) => {
      this.handleClick(val);
    });

    if (this.props.isCloseMenuOnScroll) {
      this.scrollEvent$ = Observable.fromEvent(document, 'scroll');
      this.scrollEvent$.subscribe((val) => {
        this.handleScroll(val);
      });
    }
  }

  componentWillUnmount() {
    if (this.props.appendToElementById) {
      if (this.ddContainer) {
        this.ddContainer.removeChild(this.portal);
      } else {
        document.body.removeChild(this.portal);
      }
    } else {
      document.body.removeChild(this.portal);
    }
    if (typeof this.mouseEvent$.unsubscribe === 'function') {
      this.mouseEvent$.unsubscribe();
    }
    if (this.props.isCloseMenuOnScroll) {
      if (typeof this.scrollEvent$.unsubscribe === 'function') {
        this.scrollEvent$.unsubscribe();
      }
    }
  }

  buttonRef = (e) => {
    this.toggleButtonRefElement = e;
  };

  containerRef = (e) => {
    this.containerRefElement = e;
  };

  hasParentWithClass = (parentClass, child) => {
    let node = child.parentNode;
    while (node != null) {
      if (node.classList && node.classList.contains(parentClass)) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  };

  handleClick = (e) => {
    const {onToggle, isOpen, onHoverOpenMenu, exceptionsClickOutside} = this.props;
    if (!this.containerRefElement || !this.toggleButtonRefElement) {
      return;
    }

    if (!this.containerRefElement.contains(e.target)) {
      this.props.onClickOutside(e);
    }

    if (exceptionsClickOutside.some((exception) => this.hasParentWithClass(exception, e.target))) {
      return;
    }

    if (
      (this.containerRefElement && this.containerRefElement.contains(e.target)) ||
      this.toggleButtonRefElement.contains(e.target)
    ) {
      return;
    }

    if (isOpen && !onHoverOpenMenu) {
      onToggle(false, e);
    }
  };

  handleHover = (isHover) => {
    const {onToggle, disabled} = this.props;
    if (disabled) {
      return;
    }

    if (onToggle) {
      onToggle(isHover);
    }
  };

  toggleContainer = (e) => {
    const {onToggle, isOpen, disabled} = this.props;
    e.stopPropagation();
    if (disabled) {
      return;
    }

    if (onToggle) {
      this.props.segmentClickEvent({
        type: 'click',
        name: this.props.automationId || 'form ddl',
      });
      onToggle(!isOpen, e);
    }
  };

  handleScroll = (scrollEvn) => {
    const {onToggle} = this.props;
    // form-ddl-container-style => scrolling the inner menu
    // contract-trigger - invoked on resizing
    const isKeepOpen = (scrollEvn.path || scrollEvn.composedPath()).some(
      (i) =>
        i.className &&
        (i.className.indexOf('form-ddl-container-style') !== -1 || i.className.indexOf('contract-trigger') !== -1),
    );
    if (!isKeepOpen) {
      onToggle(false);
    }
  };

  render() {
    const {
      popoverComponent,
      buttonComponent,
      isOpen,
      position,
      placement,
      popoverContainerClassName,
      horizontalOffset,
      buttonWidth,
      onHoverOpenMenu,
      automationId,
      id,
      verticalOffset,
    } = this.props;

    if (isOpen && this.portal) {
      let formDdlBtnRect = {};
      let formDdlPopoverRect = {};
      let calculatedHorizontal = 0;
      let calculatedTop = 0;
      let formDdlPopoverOrigin = 0;

      fastdom.measure(() => {
        formDdlBtnRect = this.toggleButtonRefElement ? this.toggleButtonRefElement.getBoundingClientRect() : 0;
        formDdlPopoverRect = this.containerRefElement ? this.containerRefElement.getBoundingClientRect() : 0;
        const spaceBelow = window.innerHeight - formDdlBtnRect.top - formDdlBtnRect.height;
        const spaceAbove = formDdlBtnRect.top;
        const bottomPlacementTop = formDdlBtnRect.top + formDdlBtnRect.height;
        const topPlacementTop = formDdlBtnRect.top - formDdlPopoverRect.height;

        if (position === 'center') {
          formDdlPopoverOrigin = formDdlPopoverRect.width / 2 - formDdlBtnRect.width / 2 - horizontalOffset;
        }
        if (position === 'right') {
          formDdlPopoverOrigin = formDdlPopoverRect.width - formDdlBtnRect.width - horizontalOffset;
        }
        if (position === 'extreme-right') {
          formDdlPopoverOrigin = formDdlBtnRect.width - horizontalOffset;
        }

        if (position === 'left') {
          formDdlPopoverOrigin = -horizontalOffset;
        }

        // default - position = 'left', placement = 'bottom'
        if (this.props.appendToElementById) {
          if (this.ddContainer) {
            const eleParentRect = this.ddContainer.getBoundingClientRect();
            calculatedHorizontal = `${formDdlBtnRect.left - eleParentRect.left - formDdlPopoverOrigin}px`;
            calculatedTop = `${bottomPlacementTop - eleParentRect.top - verticalOffset}px`;
          } else {
            calculatedHorizontal = `${formDdlBtnRect.left - formDdlPopoverOrigin}px`;
            calculatedTop = `${bottomPlacementTop - verticalOffset}px`;
          }
        } else {
          calculatedHorizontal = `${formDdlBtnRect.left - formDdlPopoverOrigin}px`;
          calculatedTop = `${bottomPlacementTop - verticalOffset}px`;
        }

        if (placement === 'middle') {
          calculatedTop = `${topPlacementTop + formDdlPopoverRect.height / 2 - verticalOffset}px`;
        }

        if (placement === 'top') {
          calculatedTop = `${topPlacementTop}px`;
        }

        // 'auto' with default placement of 'bottom'
        if (placement === 'auto') {
          if (spaceBelow < formDdlPopoverRect.height && spaceAbove > formDdlPopoverRect.height) {
            calculatedTop = `${topPlacementTop - verticalOffset}px`;
          }
        }

        // 'auto' with default placement of 'top'
        if (placement === 'auto-top') {
          calculatedTop = `${topPlacementTop - verticalOffset}px`;

          if (spaceAbove < formDdlPopoverRect.height && spaceBelow > formDdlPopoverRect.height) {
            calculatedTop = `${bottomPlacementTop - verticalOffset}px`;
          }
        }
      });

      fastdom.mutate(() => {
        this.portal.classList.add('form-ddl-container-open');
        this.portal.classList.remove('form-ddl-container-closed');
        this.portal.style.left = calculatedHorizontal;
        this.portal.style.top = calculatedTop;
        if (this.props.maxHeight !== 0) {
          this.portal.style.maxHeight = `${this.props.maxHeight}px`;
        }
      });
    } else if (this.portal) {
      fastdom.mutate(() => {
        this.portal.classList.add('form-ddl-container-closed');
        this.portal.classList.remove('form-ddl-container-open');
      });
    }

    const style = {
      minWidth: this.props.width ? this.props.width : '',
      maxWidth: this.props.maxWidth ? this.props.maxWidth : '',
    };
    const button = (
      <Box
        width={buttonWidth}
        styleName="ddl-btn"
        role="presentation"
        ref={this.buttonRef}
        onClick={!onHoverOpenMenu ? this.toggleContainer : null}
        automation-id={automationId}
        id={id}
      >
        {buttonComponent}
      </Box>
    );

    const child = (
      <Fragment>
        {this.portal &&
          ReactDOM.createPortal(
            <div ref={this.containerRef} style={style} styleName="form-container" className={popoverContainerClassName}>
              {popoverComponent}
            </div>,
            this.portal,
          )}
      </Fragment>
    );

    return (
      <Box
        onMouseEnter={onHoverOpenMenu ? () => this.handleHover(true) : null}
        onMouseLeave={onHoverOpenMenu ? () => this.handleHover(false) : null}
      >
        <div>{button}</div>
        {isOpen && child}
      </Box>
    );
  }
}
