// @flow
import React from 'react';
import {connect} from 'react-redux';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import Button, {COLORS} from 'common/componentsV2/Button';
import * as selectors from 'bc/store/selectors';
import {fetchSegmentMessageTypes as fetchSegmentMessageTypesAction, setSegmentMessageTypes} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import {isStreamEditable} from 'bc/services/dataStreamService';

import './SegmentStreamEditor.module.scss';

const EMPTY_MESSAGE_TYPE = [];

type PropTypes = {
  dataStream: Object,

  // connect
  messageTypes: Array,
  isMessageTypesLoading: boolean,
  setSegmentMessageTypes: Function,
  fetchSegmentMessageTypes: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    messageTypes: selectors.getSegmentMessageTypesItems(state),
    isMessageTypesLoading: selectors.getSegmentMessageTypesIsLoading(state),
  }),
  {
    setSegmentMessageTypes,
    fetchSegmentMessageTypes: fetchSegmentMessageTypesAction,
  },
)
export default class EventTypes extends React.PureComponent {
  props: PropTypes;

  state = {
    messageTypesSelected: EMPTY_MESSAGE_TYPE,
  };

  componentDidMount() {
    const {fetchSegmentMessageTypes, dataStream} = this.props;
    fetchSegmentMessageTypes();
    if (dataStream && dataStream.messageTypes) {
      this.setState({messageTypesSelected: dataStream.messageTypes});
    }
  }

  onChange = (items) => {
    const arr = items.map((item) => item.value);
    this.setState({messageTypesSelected: arr});
  };

  onClick = () => {
    this.props.setSegmentMessageTypes({messageTypes: this.state.messageTypesSelected});
  };

  render() {
    const {dataStream, messageTypes, isMessageTypesLoading} = this.props;
    const selectedMessageTypes = messageTypes.filter((msgType) =>
      this.state.messageTypesSelected.includes(msgType.value),
    );
    const isDisabled = selectedMessageTypes.length === 0;

    return (
      <div className="shell-col">
        <PanelHeader title="Message Type" isEditable={isStreamEditable(dataStream.state)} />

        <div styleName="text-wrap">
          These are the Segment message types. Options are:
          <br />
          Identify (indicates which user),
          <br />
          Track (indicates what the user is doing),
          <br />
          Page (indicates the web page being used)
          <br />
          and Screen (the application screen that is visited by the user).
        </div>

        <div styleName="input-wrap">
          <SelectAndt
            id="messageTypes"
            automationId="messageTypes"
            extraClassName="alerts-dropdown-btn"
            options={messageTypes}
            type={TYPE_NEW_MULTI_NO_SEARCH}
            theme={THEME_HIGHLIGHTED}
            onChange={this.onChange}
            value={selectedMessageTypes || EMPTY_MESSAGE_TYPE}
            isMulti
            isClearable={false}
            closeMenuOnSelect={false}
            buttonWidth={190}
            optionHeight={40}
            isLoading={isMessageTypesLoading}
            disabled={isMessageTypesLoading}
          />
        </div>

        <div styleName="button-wrap">
          <Button
            colorSchema={COLORS.BLUE_500}
            fixedWidth={96}
            text="Collect"
            automation-id="segmentGetTypes"
            onClick={this.onClick}
            isDisabled={isDisabled}
          />
        </div>
      </div>
    );
  }
}
