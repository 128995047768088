const makeChannelPayload = (values, channelType, isCreate) => {
  if (channelType === 'email') {
    return {
      channelData: {
        emailAddresses: [...values.email.map((item) => item.value), ...values.members.map((item) => item.value)],
      },
    };
  }

  if (channelType === 'webhook') {
    return {
      ...(values.authenticate ? {authData: {token: values.password}} : {}),
      channelData: {
        url: values.url,
        ...(values.authenticate ? {authenticate: true, user: values.user} : {}),
      },
    };
  }

  if (channelType === 'pagerduty') {
    return {
      channelData: {
        serviceKey: values.serviceKey,
        serviceName: values.channelName,
      },
    };
  }

  if (channelType === 'jira') {
    return {
      ...(isCreate
        ? {
            authData: {
              token: values.accessToken,
            },
          }
        : {}),
      channelData: {
        projectId: values.jiraProjectId,
        issueType: values.jiraIssueType,
        userEmail: values.jiraUser,
        jiraBaseURL: values.url,
      },
    };
  }

  if (channelType === 'opsgenie') {
    return {
      ...(isCreate
        ? {
            authData: {
              token: values.integrationApiKey,
            },
          }
        : {}),
      channelData: {
        baseUrl: values.urlDropdown === 'new' ? values.url : values.urlDropdown,
      },
    };
  }

  if (channelType === 'sns') {
    return {
      channelData: {topic: values.topic},
    };
  }

  if (['tamtam', 'msteams'].includes(channelType)) {
    return {
      channelData: {url: values.url},
    };
  }

  if (channelType === 'slackapp') {
    return {
      channelData: {
        url: values.url,
      },
    };
  }
  if (channelType === 'slack') {
    return {
      channelData: {
        url: values.url,
        channel: `#${values.channelName}`,
      },
    };
  }
  return {};
};

export default makeChannelPayload;
