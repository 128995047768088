// @flow
import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Gradient from 'common/componentsV2/Gradient';
import {StringParam, useQueryParam, useQueryParams} from 'use-query-params';
import {Alert} from 'anodot-objects-models';
import {getToState} from 'common/utils/angularServices';
import ExpressionItem from 'common/componentsV2/ExpressionBuilderV2/ExpressionItem';
import {parseQS, stringifyQS} from 'common/utils/http';
import createDecorator from 'final-form-focus';
import {Redirect, useHistory, useLocation} from 'react-router-dom';
import {Form, FormSpy} from 'react-final-form';
import './AlertSettings.module.scss';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {cloneDeep, difference, get, identity, isEqual, omit, pick, pickBy, values as lodashValues} from 'lodash';
import {
  createNewAlert,
  executeAlertSimulation,
  fetchAlertConfigurations,
  fetchDashboard,
  setAlertToScrollAndOpen,
  setSimulationFilters,
  showAlertSuccessMessage,
  simpleAlertReset,
  toggleSimpleAlertModal,
  updateAlert,
} from 'alerts.management/store/actions';
import * as profileSelectors from 'profile/store/selectors';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import * as commonSelectors from 'common/store/selectors';
import PageLayout from 'common/componentsV2/PageLayout';
import * as metricsSelectors from 'metrics/store/selectors';
import {setExpression as setEventsExpression, setShouldShowEvents} from 'userEvents/store/actions';
import * as alertActionsSelector from 'alerts.management/components/editor/simulationArea/alertActions/store/selectors';
import {getUsersGroupsListsFull} from 'admin.users/store/selectors';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import {
  setDynamicRoutingFromData,
  setDynamicRoutingFromUI,
  setDynamicRoutingValidation,
} from 'alerts.management/components/editor/simulationArea/dynamicRouting/service';
import {
  fetchCompositeName,
  fetchComposites,
  initAlertSettings,
  setCompositeObject,
  setExpressionBuilderContext,
} from 'metrics/store/actions';
import {defaultEmptyAlert} from 'alerts.management/services/alertManagementService';
import {cleanupSpecialChars, treeVisitor} from 'metrics/services/metricsService';
import deletePropFromObj from 'common/utils/deletePropFromObj';
import {getUniqueId} from 'common/utils/guid';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import Tooltip from 'common/componentsV2/Tooltip';
import AlertSettingsContext from 'alerts.management/components/editor/simulationArea/context';
import AlertPreview from 'alerts.management/components/editor/simulationArea/AlertPreview';
import BottomPanel from 'alerts.management/components/editor/simulationArea/BottomPanel';
import * as amSelectors from 'alerts.management/store/selectors';
import SimulationFilters from 'alerts.management/components/editor/simulationFiltersV2/SimulationFilters';
// TODO: clean up the code
import {convertDefinitionToFilter} from '../components/editor/simulationArea/legacy';
import CalcellationModal from '../components/editor/simulationArea/CancellationModal';
import CompositeNameModal from '../components/editor/simulationArea/CompositeNameModal';
import * as profileSelector from '../../profile/store/selectors';
import {alertConditions, getContextConditionsForType, getMandatoryConditionsForType} from '../services/alertsService';
import * as dateRangeService from '../../common/utils/dateRangeService';

const focusOnErrors = createDecorator();

type PropTypes = {
  match: Object,
  location: Object,
  isClone: boolean,
};

export const makeAlertTitle = (expressionTree, direction, alertType) => {
  const whats = [];
  const properties = [];

  treeVisitor(expressionTree.root, 'children', (childNode) => {
    const expression = get(childNode, 'searchObject.expression', []);
    const what = expression.find((item) => item.key === 'what');
    const prop = expression.find((item) => item.type === 'property' && item.key !== 'what');
    if (what) {
      whats.push(cleanupSpecialChars(what.value));
    }
    if (prop) {
      properties.push(cleanupSpecialChars(prop.key));
    }
  });

  if (whats.length === 0) {
    whats.push(properties[0] || '');
  }

  let dims = [];
  if (get(expressionTree, 'root.function') === 'groupBy' && get(expressionTree, 'root.parameters[1].value')) {
    const parameters = JSON.parse(expressionTree.root.parameters[1].value);
    if (get(parameters, 'properties')) {
      dims = parameters.properties;
    }
  }

  const measure1 = {measure: whats[0]};
  const measure2 = {measure: whats[1]};

  const directions = {
    up: [{value: 'up'}],
    down: [{value: 'down'}],
    both: [{value: 'up'}, {value: 'down'}],
  }[direction] || [{}];

  return amSelectors.generateAlertTitle(measure1, measure2, dims, directions, alertType);
};

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

// TODO: optimize
const removeUIData = (tree) => {
  const treeCleaned = cloneDeep(tree);
  deletePropFromObj(treeCleaned, 'uiData');
  deletePropFromObj(treeCleaned, 'q');
  deletePropFromObj(treeCleaned, 'ids');
  return treeCleaned;
};

const areConditionsChanged = (actualSource, initialSource) => {
  if (!initialSource) {
    return false;
  }
  const actualState = cloneDeep(actualSource);
  const initialState = cloneDeep(initialSource);

  const volumeCondition = actualState.conditions.find((item) => item.type === 'VOLUME_CONDITION');
  const initialVolumeCondition = initialState.conditions.find((item) => item.type === 'VOLUME_CONDITION');
  if (
    (volumeCondition && !volumeCondition.enabled && !initialVolumeCondition) ||
    (volumeCondition &&
      initialVolumeCondition &&
      ((!volumeCondition.enabled && !initialVolumeCondition.enabled) ||
        (volumeCondition.enableAutoTuning && initialVolumeCondition.enableAutoTuning)))
  ) {
    actualState.conditions = actualState.conditions.filter((item) => item.type !== 'VOLUME_CONDITION');
    initialState.conditions = initialState.conditions.filter((item) => item.type !== 'VOLUME_CONDITION');
  }

  const deltaCondition = actualState.conditions.find((item) => item.type === 'DELTA_CONDITION');
  const initialDeltaCondition = initialState.conditions.find((item) => item.type === 'DELTA_CONDITION');
  if (
    deltaCondition &&
    deltaCondition.enableAutoTuning &&
    initialDeltaCondition &&
    initialDeltaCondition.enableAutoTuning
  ) {
    actualState.conditions = actualState.conditions.filter((item) => item.type !== 'DELTA_CONDITION');
    initialState.conditions = initialState.conditions.filter((item) => item.type !== 'DELTA_CONDITION');
  }

  return !isEqual(actualState, initialState);
};

const thresholdMessagesMapping = {
  'Threshold condition must contain a upper and/or a lower threshold': (
    <div className="fontSize_14 fontWeight_400">
      <div className="fontSize_16 fontWeight_500">At least one threshold must be set</div>A static alert needs a lower
      or upper threshold to be set.
    </div>
  ),
  'Threshold condition contains illegal values': (
    <div className="fontSize_14 fontWeight_400">
      <div className="fontSize_16 fontWeight_500">Wrong thresholds</div>
      Upper threshold is smaller than lower threshold
    </div>
  ),
};

const getFilteredConditions = (type, conditions) => {
  const contextConditions = getContextConditionsForType(type);
  const stateConditions = conditions.map((condition) => condition.type);
  const toRemove = difference(stateConditions, contextConditions);
  return conditions.filter((condition) => !toRemove.includes(condition.type));
};

const makeAlertPayload = ({
  values,
  subscribers,
  alert,
  conditions,
  simulationFilters,
  isEmptyFunction,
  expressionTree,
  nameComposition,
  suggested,
  alertId,
  usersGroups,
}) => ({
  body: new Alert({
    ...pick(alert.data, ['owner', 'pausedTime', 'extraEmails', 'advancedModeOpen']),
    subscribers,
    owner: usersGroups.find((item) => item.value === values.ownerId).email,
    ownerId: values.ownerId,
    data: {
      ...alert.data,
      ...values,
      title: values.title,
      story: values.story,
      conditions: getFilteredConditions(simulationFilters.type.value, conditions),
      subscribers,
      ownerId: values.ownerId,
      type: simulationFilters.type.value.replace('anomaly', 'incident'),
      anomalyAlert: ['incident', 'anomaly'].includes(simulationFilters.type.value),
      noDataAlert: simulationFilters.type.value === 'noData' || simulationFilters.noDataAlert,
      staticAlert: simulationFilters.type.value === 'static',
      rollups: simulationFilters.type.value === 'noData' ? 'medium' : simulationFilters.rollup,
      noDataDuration:
        simulationFilters.type.value === 'noData' || simulationFilters.noDataAlert
          ? simulationFilters.noDataDuration.value
          : null,
      updatePolicy: simulationFilters.updatePolicy,
      isInfluencingEvents: values.isInfluencingEvents,
      notifyOnlyOpen: simulationFilters.notifyOnlyOpen,
      expressionTreeModel: {
        compositeCount: 0,
        displayOnly: isEmptyFunction,
        title: cleanupSpecialChars(`${values.title}_${nameComposition.what}`),
        excludeComposites: !values.showComposites,
        namingSchema: isEmptyFunction ? undefined : 'COMPOSITE_V2',
        mtype: 'ALERT',
        filter: null,
        uiIndex: 0,
        expressionTree: removeUIData(isEmptyFunction ? {root: expressionTree.root.children[0]} : expressionTree),
        name: isEmptyFunction
          ? null
          : {
              auto: false,
              prefix: nameComposition.what
                ? `what=${cleanupSpecialChars(nameComposition.what)}.${suggested}`
                : suggested,
            },
      },
      eventsFilter:
        values.events.length > 0 && values.shouldShowEvents
          ? {
              aggregation: {
                aggregationField: null,
                resolution: simulationFilters.type.value === 'noData' ? 'medium' : simulationFilters.rollup,
                topEventSize: 0,
                maxBuckets: 100,
              },
              filter: {
                categories: [],
                q: {
                  expression: values.events.map((item) => item.getExpressionTreeObjectification()),
                },
              },
            }
          : null,
      dynamicRouting: setDynamicRoutingFromUI(values.dynamicRouting),
      alertActions: values.alertActions.map((action) => action.id),
      primaryAlertAction: values.primaryAlertAction,
    },
  }).exportClientFormat(),
  qs: {allowEmptyCompositeRes: true},
  alertId,
});

const AlertSettings = (props: PropTypes) => {
  const store = useStore();
  const history = useHistory();
  const location = useLocation();
  const {alertId} = props.match.params;
  const dispatch = useDispatch();
  const compositeNameDeferred = useRef();
  const submissionDeferred = useRef();
  const alertTitle = useRef();
  const alertTitleInputRef = useRef();
  const formRef = useRef();
  const initialActions = useRef();
  const [initialState, setInitialState] = useState();
  const isReferenceDateEnabled = useSelector(profileSelectors.getIsEnableSimulationReferenceDate);
  const alertSimulation = useSelector(amSelectors.getAlertSimulation);
  const conditions = useSelector(amSelectors.getConditions);
  const {isLoading: isAlertUpdating, eventValidationResult: alertUpdateValidation, event: updateEvent} = useSelector(
    amSelectors.getUpdateAlertResponse,
  );
  const {isLoading: isAlertCreating, eventValidationResult: alertCreateValidation, event: createEvent} = useSelector(
    amSelectors.getCreateNewAlertResponse,
  );

  const [backUrl] = useQueryParam('backUrl', StringParam);

  const [, setQueryParam] = useQueryParams({
    ref: StringParam,
  });

  const alertConfigurations = useSelector(amSelectors.getAlertConfigurations);
  const alertList = alertConfigurations.items || EMPTY_ARRAY;

  const simpleAlert = useSelector(amSelectors.getSimpleAlertObject);
  const validSimpleAlert = useMemo(
    () => (simpleAlert ? {data: {...simpleAlert.exportClientFormat().data, validation: true}} : null),
    [simpleAlert],
  );
  const {source} = parseQS(props.location.search);
  const alert = useMemo(() => {
    if (alertId) {
      const alertState = get(getToState('alerts.edit'), 'data.alertDefinition');
      if (alertState && alertState.data.id === alertId) {
        return {...alertState, data: {...alertState.data, validation: true}};
      }
      const result = alertList.find((item) => item.data.id === alertId);
      return result ? {...result, data: {...result.data}} : result;
    }
    if (source === 'simpleAlert') {
      return validSimpleAlert || defaultEmptyAlert;
    }
    if (['alertsTable', 'compositeToolbar', 'dashboard'].includes(source)) {
      const alertState = get(getToState('alerts.edit'), 'data.alertDefinition');
      return alertState
        ? {
            ...alertState,
            data: {
              ...alertState.data,
              eventsFilter: null,
              validation: true,
            },
          }
        : defaultEmptyAlert;
    }
    return defaultEmptyAlert;
  }, [simpleAlert, alertList, alertId, source]);

  const users = useSelector(commonSelectors.getUsers);
  const resolutions = useSelector(metricsSelectors.getMetricResolutions);
  const startBucketMode = useSelector(profileSelectors.getBucketStartTimeEnabled);
  const isTreeEmpty = useSelector(metricsSelectors.getIsTreeEmpty);
  const expressionTree = useSelector(metricsSelectors.getExpressionTree);
  const simulationFilters = useSelector(amSelectors.getSelectedAlertSimulationFilters);
  const simulationFiltersValidation = useSelector(amSelectors.getSimulationFiltersValidationResults);
  const simulationFiltersValidationResult = simulationFiltersValidation.data;
  const validationData = useSelector(metricsSelectors.getValidationData);
  const compositeNameResponse = useSelector(metricsSelectors.getCompositeNameResponse);
  const previewOptions = useSelector(metricsSelectors.getPreviewOptions);
  const userProfile = useSelector(profileSelectors.getUserProfile);
  const usersGroups = useSelector(getUsersGroupsListsFull);
  const compositesList = useSelector(metricsSelectors.getComposites);

  const alertActions = useSelector(alertActionsSelector.getAlertActionsView);

  const isAlertEnableAnomalyUpdate = useSelector(profileSelector.getIsAlertEnableAnomalyUpdate);
  const alertType = useSelector(amSelectors.getSimulationFiltersType);
  const isUpdatePolicyEnabled = alertType.value === 'anomaly' ? isAlertEnableAnomalyUpdate : true;

  const areFiltersChanged = useMemo(() => areConditionsChanged(simulationFilters, initialState), [
    simulationFilters,
    initialState,
  ]);

  const isEmptyFunction =
    expressionTree.root &&
    expressionTree.root.function === '' &&
    expressionTree.root.children.length === 1 &&
    expressionTree.root.children[0].type === 'metric';

  const isTreeChanged = useMemo(
    () =>
      props.isClone ||
      !alertId ||
      !isEqual(
        removeUIData(isEmptyFunction ? {root: expressionTree.root.children[0]} : expressionTree),
        removeUIData(get(alert, 'data.expressionTreeModel.expressionTree')),
      ),
    [expressionTree, props.isClone, alert, isEmptyFunction],
  );

  const [isCancellationModalOpened, setCancellationModalOpened] = useState(false);
  const [isCompositeNameModalOpened, setCompositeNameModalOpened] = useState(false);
  const [compositeNameError, setCompositeNameError] = useState('');

  const [isManualAlertTitle, setManualAlertTitle] = useState(!!alertId);
  const [initialAlertTitle, setInitialAlertTitle] = useState('');
  const [initialEvents, setInitialEvents] = useState([]);

  const [initialDynamicRouting, setInitialDynamicRouting] = useState({});
  const [initialAlertActions, setInitialAlertActions] = useState([]);

  const autoSimulationState = useState(validSimpleAlert || !!alertId || false);
  const simulationViewActiveState = useState(validSimpleAlert || !!alertId || false);
  const autoSimulationPristineState = useState(true);
  const [autoSimulationEnabled, setAutoSimulationEnabled] = autoSimulationState;
  const [isSimulationViewActive] = simulationViewActiveState;
  const loadingBlurState = useState(true);
  const [isTreeValidationVisible, setTreeValidationVisible] = useState(false);

  const compositeName = get(alert, 'data.expressionTreeModel.name');
  const savedMeasureName =
    // eslint-disable-next-line max-len
    compositeName && !props.isClone
      ? ([...(compositeName.prefix || '').split('what='), ''][1] || '').split('.')[0]
      : '';

  const defaultSubscribers = useMemo(() => (alertId ? EMPTY_ARRAY : [userProfile._id]), [userProfile._id, alertId]);
  const contextValues = useMemo(
    () => ({
      isAutoSimulationEnabled: autoSimulationState[0],
      setAutoSimulationEnabled: autoSimulationState[1],
      isSimulationViewActive: simulationViewActiveState[0],
      setSimulationViewActive: simulationViewActiveState[1],
      isBlurVisible: loadingBlurState[0],
      setBlurVisible: loadingBlurState[1],
      isAutoSimulationPristine: autoSimulationPristineState[0],
      setAutoSimulationPristine: autoSimulationPristineState[1],
    }),
    [autoSimulationState[0], simulationViewActiveState[0], loadingBlurState[0]],
  );

  const connectedTiles = alert && (alert.connectedTiles || alert.data.connectedTiles);

  useEffect(() => {
    if (connectedTiles) {
      dispatch(fetchDashboard({id: connectedTiles[0].dashboardId, schemaType: 'dashboardsV2'}));
    }
  }, [connectedTiles]);

  useEffect(() => {
    if (location.state && location.state.expressionTree) {
      dispatch(setCompositeObject(location.state.expressionTree));
    }
  }, [location]);

  useEffect(() => {
    dispatch(initAlertSettings());
    dispatch(fetchComposites());
    dispatch(simpleAlertReset());
    dispatch(toggleSimpleAlertModal(false));
    if (alertConfigurations.isLoading === undefined) {
      dispatch(fetchAlertConfigurations());
    }
    if (!alertId) {
      dispatch(
        setSimulationFilters({
          isNewAlert: true,
          conditions: [
            {
              type: 'DELTA_CONDITION',
              deltaAbsolute: null,
              deltaPercentage: null,
              deltaDuration: {
                enabled: null,
                rollup: 'long',
                minDuration: 7200,
              },
              id: getUniqueId(),
              enableAutoTuning: true,
            },
            {
              type: 'DURATION_CONDITION',
              enableAutoTuning: false,
              id: getUniqueId(),
              minDuration: 7200,
            },
          ],
          rollup: 'long',
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (alertId && alertConfigurations.isLoading === false) {
      setTimeout(() => {
        const currentFiltersShape = amSelectors.getSelectedAlertSimulationFilters(store.getState());
        setInitialState(currentFiltersShape);
      }, 1000);
      if (!alert) {
        history.replace('/404');
      }
    }
  }, [alertConfigurations.isLoading]);

  const executeSimulation = useCallback(
    (index, size, quickSimMode) => {
      const rollup = simulationFilters.type.value === 'noData' ? 'medium' : simulationFilters.rollup;
      const {referenceDate} = formRef.current.values;
      dispatch(
        executeAlertSimulation({
          analysisToDate: isReferenceDateEnabled ? referenceDate && Math.floor(referenceDate / 1000) : undefined,
          startBucketMode,
          includeBaseline: true,
          timeScale: Object.values(resolutions).find((a) => a.value === rollup).value,
          alert,
          formValues: formRef.current.values,
          index,
          size,
          quickSimMode,
        }),
      );
    },
    [dispatch, startBucketMode, resolutions, alert, simulationFilters, isReferenceDateEnabled],
  );

  const requestCompositeName = useCallback(() => {
    const fromDate = Math.ceil(previewOptions.dateRange.startDate / 1000);
    const toDate = Math.ceil(previewOptions.dateRange.endDate / 1000);
    dispatch(
      fetchCompositeName({
        urlExt: stringifyQS({
          fromDate,
          toDate,
          index: 0,
          size: 5,
          includeSuggestions: true,
          maxDataPoints: 500,
          resolution: previewOptions.timeScale,
        }),
        body: {
          compositeCount: 0,
          displayOnly: false,
          expressionTree: removeUIData(isEmptyFunction ? {root: expressionTree.root.children[0]} : expressionTree),
          id: getUniqueId(),
          name: {
            prefix: '',
            auto: true,
          },
          uiIndex: 0,
          namingSchema: 'COMPOSITE_V2',
          excludeComposites: true,
          mtype: 'COMPOSITE',
        },
      }),
    );
  }, [isEmptyFunction, expressionTree, previewOptions, dispatch]);

  useEffect(() => {
    if (alertConfigurations.isLoading === false && alert) {
      if (alert.data.conditions) {
        setInitialAlertTitle(alert.data.title);

        let computedAlertType = alert.data.type.replace('incident', 'anomaly');

        if (alert.data.staticAlert) {
          computedAlertType = 'static';
        } else if (alert.data.noDataAlert && alert.data.anomalyAlert === false) {
          computedAlertType = 'noData';
        }

        const {filter} = convertDefinitionToFilter(alert);
        const toAddConditions = [];
        const mandatoryConditions = getMandatoryConditionsForType(computedAlertType);
        const stateConditions = filter.conditions.map((c) => c.type);
        const toAdd = difference(mandatoryConditions, stateConditions);
        toAdd.forEach((c) => {
          const cond = alertConditions.find((m) => m.dataObj.type === c).dataObj;
          cond.id = getUniqueId();
          toAddConditions.push(cond);
        });

        dispatch(
          setSimulationFilters({
            ...filter,
            conditions: [...filter.conditions, ...toAddConditions],
            type: {value: computedAlertType},
          }),
        );
      }
      const events = get(alert.data.eventsFilter, 'filter.q.expression', []).map((expItem) =>
        ExpressionItem.setExpressionFromObject(expItem),
      );
      setInitialEvents(events);
      setInitialDynamicRouting(setDynamicRoutingFromData(alert.data.dynamicRouting));
      dispatch(setEventsExpression(events, {}));
      dispatch(setShouldShowEvents(events.length > 0));
    }
  }, [alertConfigurations.isLoading]);

  useEffect(() => {
    setInitialAlertActions(alertActions);
    if (!initialActions.current) {
      initialActions.current = alertActions;
    }
  }, [alertActions]);

  const {direction} = useSelector(amSelectors.getDirectionCondition) || EMPTY_OBJECT;

  useEffect(() => {
    if (!isManualAlertTitle) {
      const autoTitle = makeAlertTitle(expressionTree, direction, alertType.value);
      setInitialAlertTitle(autoTitle);
      setExpressionBuilderContext(`alertId=${alert.id || 'new alert'}.alertName=${autoTitle}.context=alertEditor`);
    }
    if (isTreeValidationVisible) {
      setTreeValidationVisible(false);
    }
  }, [expressionTree, direction, alertType.value]);

  const handleFormChange = (formProps) => {
    if (formProps.dirtyFields.title && !isManualAlertTitle) {
      setManualAlertTitle(true);
    }
    if (formProps.values.title === alertTitle.current) {
      return;
    }
    dispatch(
      setExpressionBuilderContext(
        `alertId=${alert.id || 'new alert'}.alertName=${formProps.values.title}.context=alertEditor`,
      ),
    );
    alertTitle.current = formProps.values.title;
  };

  const validate = useCallback(
    (values) => {
      const simulationFiltersValidationMessage = get(
        lodashValues(get(simulationFiltersValidationResult, 'failures')),
        '[0][0].message',
        '',
      );

      const {updatePolicyInterval} = simulationFilters;
      let validationMessage = get(lodashValues(get(validationData, 'failures')), '[0][0].message', '');
      const validationId = get(lodashValues(get(validationData, 'failures')), '[0][0].id', 0);
      if (validationId === 25 || validationId === 26) {
        validationMessage = '';
      }

      const composites = (compositesList || [])
        .map((item) => item.composite.title)
        .filter((item) => !alertList.some((listItem) => listItem.data.title === item));

      const titleNoUnique =
        alertList.some((item) => item.data.title === values.title && (item.data.id !== alertId || props.isClone)) &&
        'An alert with that name already exists';

      const compositeNotUnique =
        composites.some((item) => item === values.title) && 'Composite with that title already exists';

      const titleMandatory = !values.title && 'Alert name is mandatory';
      const subscribers =
        values.subscribers.length + values.channels.length === 0 ? 'You must provide at least 1 recipient' : undefined;
      const dynamicRoutingValidation = setDynamicRoutingValidation(values.dynamicRouting);
      return pickBy(
        {
          expressionTree: validationMessage,
          influencingMetricsFilter:
            simulationFiltersValidationMessage === 'Influencing metrics must contain an expression'
              ? simulationFiltersValidationMessage
              : '',
          staticThresholdsFilter:
            simulationFilters.type.value === 'static' && thresholdMessagesMapping[simulationFiltersValidationMessage],
          anomalyThresholdsFilter:
            simulationFilters.type.value !== 'static' &&
            simulationFiltersValidationMessage === 'Threshold condition must contain a upper and/or a lower threshold'
              ? simulationFiltersValidationMessage
              : '',
          title: titleNoUnique || compositeNotUnique || titleMandatory,
          subscribers,
          dynamicRouting: dynamicRoutingValidation,
          updatePolicyInterval:
            isUpdatePolicyEnabled &&
            updatePolicyInterval.enabled &&
            !updatePolicyInterval.value &&
            'Minimum time between updates is mandatory',
        },
        identity,
      );
    },
    [simulationFiltersValidationResult, validationData, alertList, simulationFilters, isUpdatePolicyEnabled],
  );

  useEffect(() => {
    if (compositeNameResponse.isLoading === false && compositeNameDeferred.current) {
      const {nameComposition} = compositeNameResponse.data;
      const errorMessage = get(
        lodashValues(get(compositeNameResponse.data, 'validation.failures')),
        '[0][0].message',
        '',
      );
      if (errorMessage) {
        compositeNameDeferred.current.reject({
          message: errorMessage,
          type: 'INVALID_COMPOSITE',
        });
      } else if (nameComposition.what === '') {
        setCompositeNameModalOpened(true);
      } else {
        compositeNameDeferred.current.resolve(nameComposition);
      }
    }
  }, [compositeNameResponse.isLoading]);

  useEffect(() => {
    let alertValidationObject;
    let alertEventObject;
    if (isAlertUpdating === false) {
      alertValidationObject = alertUpdateValidation;
      alertEventObject = updateEvent;
    }
    if (isAlertCreating === false) {
      alertValidationObject = alertCreateValidation;
      alertEventObject = createEvent;
    }
    if (alertValidationObject && submissionDeferred.current) {
      const {alertValidation, compositeValidation} = alertValidationObject;
      if (alertValidation.passed && compositeValidation.passed) {
        submissionDeferred.current.resolve(alertEventObject);
      } else {
        const errorMessage = get(lodashValues(get(compositeValidation, 'failures')), '[0][0].message', '');
        submissionDeferred.current.reject({
          message: errorMessage,
          type: !compositeValidation.passed && 'INVALID_COMPOSITE_NAME',
        });
      }
    }
  }, [isAlertUpdating, isAlertCreating]);

  useEffect(
    () => () => {
      setQueryParam({ref: undefined});
    },
    [],
  );

  const submitAlert = useMemo(
    () => (values, form, completeCallback, previousError) =>
      // eslint-disable-next-line consistent-return
      new Promise((resolve, reject) => {
        if (isEmptyFunction) {
          return resolve({suggested: []});
        }
        compositeNameDeferred.current = {resolve, reject};
        if (previousError) {
          setCompositeNameModalOpened(true);
          setCompositeNameError(previousError);
        } else if (isTreeChanged || !savedMeasureName) {
          requestCompositeName();
        } else {
          compositeNameDeferred.current.resolve({
            what: savedMeasureName,
            suggested: [{key: 'func', value: expressionTree.root.function}],
          });
        }
      })
        .then((nameComposition) => {
          const subscribers = users.data.users.data
            .filter((user) => (values.subscribers || []).includes(user._id))
            .map((user) => ({email: user.email, muted: false}));
          const actionCreator = alertId && !props.isClone ? updateAlert : createNewAlert;
          const suggested = nameComposition.suggested.map((item) => `${item.key}=${item.value}`).join('.');

          dispatch(
            actionCreator(
              makeAlertPayload({
                values: form.getState().values,
                subscribers,
                alert,
                conditions,
                simulationFilters,
                previewOptions,
                isEmptyFunction,
                expressionTree,
                nameComposition,
                suggested,
                alertId,
                usersGroups,
              }),
            ),
          );
          return new Promise((resolve, reject) => {
            submissionDeferred.current = {resolve, reject};
          });
        })
        .then((alertResponse) => {
          const actualValues = form.getState().values;
          dispatch(setAlertToScrollAndOpen(alertResponse.id, {preserveFilters: alertId && !props.isClone}));
          if (alertId && !props.isClone) {
            dispatch(
              showAlertSuccessMessage({
                title: 'Alert was edited successfully',
                moreInfo: `[${actualValues.title}] was edited.
                All the changes you’ve made surely made this alert even better!`,
              }),
            );
          } else {
            dispatch(
              showAlertSuccessMessage({
                title: 'Alert created successfully',
                moreInfo: `[${actualValues.title}] was created successfully`,
              }),
            );
          }
        })
        .catch((error) => {
          if (error.type === 'INVALID_COMPOSITE_NAME') {
            return submitAlert(values, form, completeCallback, error);
          }
          return error;
        }),
    [
      requestCompositeName,
      isEmptyFunction,
      users,
      alert,
      conditions,
      simulationFilters,
      previewOptions,
      isEmptyFunction,
      expressionTree,
      alertId,
      dispatch,
      usersGroups,
      isTreeChanged,
      savedMeasureName,
    ],
  );

  const initialReferenceDate = useMemo(() => new Date().getTime(), []);

  const autoDateRange = useMemo(() => {
    const template = {
      short: '1d',
      medium: '3d',
      long: '1w',
      longlong: '1m',
      weekly: '3m',
    };

    return dateRangeService.getDateValue(
      alert?.data?.rollups ? template[alert.data.rollups] : template[defaultEmptyAlert.data.rollups],
    );
  }, [alert]);

  if ((alertId && alertConfigurations.isLoading !== false) || !alert) {
    return (
      <div className="display_flex justifyContent_center alignItems_center flexGrow_1">
        <Spinner color="#3d4c59" size={SIZES.XX_BIG_150} />
      </div>
    );
  }

  const {
    data: {story, ownerId, subscribers, channels, severity, alertTags, title, isInfluencingEvents},
  } = alert;

  const returnUrl = backUrl || `${simpleAlert ? '/' : '/alert-manager'}${history.location.search}`;

  return (
    <PageLayout
      header={
        <header styleName="page-title">
          <Title type={TITLE_TYPES.PAGE_TITLE}>{alertId && !props.isClone ? 'Edit Alert' : 'Create a New Alert'}</Title>
          {alertId && (
            <Fragment>
              <Tooltip content={title}>
                <TypographyBox
                  ml={1.5}
                  maxWidth={605}
                  variant="h2"
                  color="gray.600"
                  css={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
                >
                  {title}
                </TypographyBox>
              </Tooltip>
              <Tooltip content="Rename">
                <div
                  styleName="edit-icon"
                  onClick={() => alertTitleInputRef.current && alertTitleInputRef.current.focus()}
                >
                  <i className="icon icn-action16-edit" />
                </div>
              </Tooltip>
            </Fragment>
          )}
        </header>
      }
      shouldBottomBar
    >
      <Form
        keepDirtyOnReinitialize
        initialValues={{
          title: initialAlertTitle || undefined,
          story: story || undefined,
          ownerId: ownerId || userProfile.defaultGroup || userProfile._id,
          severity,
          alertTags: alertTags || EMPTY_ARRAY,
          subscribers: subscribers || defaultSubscribers,
          channels: channels || EMPTY_ARRAY,
          events: initialEvents || EMPTY_ARRAY,
          shouldShowEvents: initialEvents.length > 0,
          isInfluencingEvents,
          dynamicRouting: initialDynamicRouting,
          alertActions: initialAlertActions,
          showComposites:
            source === 'simpleAlert' ? isEmptyFunction : !get(alert, 'data.expressionTreeModel.excludeComposites'),
          isAutoShowComposites: !alertId,
          referenceDate: initialReferenceDate,
          dateRange: autoDateRange,
          sort: previewOptions.sort,
          timeScale: alert?.data?.rollups || simulationFilters.rollup,
        }}
        decorators={[focusOnErrors]}
        validate={validate}
        onSubmit={submitAlert}
      >
        {(formProps) => {
          const alertIsDirty =
            formProps.dirty || areFiltersChanged || (isTreeChanged && (!isTreeEmpty || alertId || props.isClone));
          return (
            <AlertSettingsContext.Provider value={contextValues}>
              {formProps.submitSucceeded && <Redirect to={returnUrl} />}
              <FormSpy
                onChange={(newFormProps) => {
                  formRef.current = newFormProps;
                }}
              />
              <FormSpy subscription={{dirtyFields: true, values: true}} onChange={handleFormChange} />
              <CompositeNameModal
                initialName={savedMeasureName}
                error={get(compositeNameError, 'message', '')}
                isOpened={isCompositeNameModalOpened}
                onClose={() => {
                  setCompositeNameModalOpened(false);
                  setCompositeNameError('');
                  compositeNameDeferred.current.reject({message: ''});
                }}
                onConfirm={(name) => {
                  setCompositeNameModalOpened(false);
                  compositeNameDeferred.current.resolve({
                    what: name,
                    suggested: [{key: 'func', value: expressionTree.root.function}],
                  });
                }}
              />
              <CalcellationModal
                isOpened={isCancellationModalOpened}
                onClose={() => setCancellationModalOpened(false)}
                onConfirm={() => history.push(returnUrl)}
              />
              <form className={`display_flex alignItems_stretch flexGrow_1 mt_${alertId ? '-3' : '-1-5'}`}>
                <div className="mr_2-5 flexShrink_0">
                  <div className="display_flex flexDirection_column position_relative height_1">
                    <Gradient />
                    <SimulationFilters alertDefinitionData={alert.data} alertTitleInputRef={alertTitleInputRef} />
                  </div>
                </div>
                <div id="idle-external-click" className="flexGrow_1 display_flex alignItems_center mt_1-5">
                  <AlertPreview alert={alert} executeSimulation={executeSimulation} />
                </div>
              </form>
              <BottomPanel
                connectedTiles={connectedTiles}
                alertSimulation={alertSimulation}
                autoSimulationEnabled={autoSimulationEnabled}
                onExecuteSimulation={() => {
                  setAutoSimulationEnabled(true);
                }}
                isSimulationViewActive={isSimulationViewActive}
                onSave={() => {
                  setTreeValidationVisible(true);
                  formProps.handleSubmit();
                }}
                onCancel={() => {
                  if (alertIsDirty) {
                    setCancellationModalOpened(true);
                  } else {
                    history.push(returnUrl);
                  }
                }}
                isDirty={alertIsDirty || !isEqual(initialActions.current, alertActions)}
                isSimulationDisabled={isTreeEmpty}
                isCreateMode={!alertId || props.isClone}
                isSaving={formProps.submitting}
                submitError={get(formProps, 'submitErrors.message')}
                poppedErrors={
                  !isTreeEmpty && formProps.submitFailed
                    ? Object.values(
                        isTreeValidationVisible ? formProps.errors : omit(formProps.error, 'expressionTree'),
                      )
                    : []
                }
                isAutoSimulationPristine={autoSimulationPristineState[0]}
                alertName={alert.data.title}
              />
            </AlertSettingsContext.Provider>
          );
        }}
      </Form>
    </PageLayout>
  );
};

const AlertSettingsWrapper = (props: {match: Object}) => <AlertSettings {...props} key={props.match.params.alertId} />;

export default AlertSettingsWrapper;
