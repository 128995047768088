// @flow
import React from 'react';
import {connect} from 'react-redux';
import ReactQueryParams from 'common/components/ReactQueryParams';

import {
  setUsersGroupsFilters as setUsersGroupsFiltersAction,
  allUsersCheckboxClick as allUsersCheckboxClickAction,
} from 'admin.users/store/actions';

import {DEFAULT_QUERY_PARAMS} from 'admin.users/services/usersAndGroupsService';
import PageLayout from 'common/componentsV2/PageLayout';
import UserAndGroupsPageHeader from 'admin.users/components/UserAndGroupsPageHeader';
import GroupsSection from 'admin.users/components/GroupsSection';
import UsersSection from 'admin.users/components/UsersSection';
import GroupFormModal from 'admin.users/components/GroupForm/GroupFormModal';
import EditUserModal from 'admin.users/components/EditUser/EditUserModal';
import NewUserModal from 'admin.users/components/NewUser/NewUserModal';
import UsersAndGroupsFilters from 'admin.users/components/UsersAndGroupsFilters';
import UGFooter from 'admin.users/components/footer/UGFooter';
import Footer from 'common/componentsV2/Footer';
import {getIsUsersGroupsFiltersBarOpen} from 'profile/store/selectors';
import {getSelectedUsersCheckbox} from 'admin.users/store/selectors';
import {getAllUsersCheckboxState} from 'admin.users/store/filterSelector';
import BottomPanel, {BOTTOM_PANEL_TYPE} from 'common/componentsV2/BottomPanel';

import './UsersAndGroups.module.scss';

type PropTypes = {
  setQueryParams: Function,

  // connect
  isFiltersBarOpen: boolean,
  allAlertsCheckboxState: String,
  selectedCheckboxes: Array,
  setUsersGroupsFilters: Function,
  allUsersCheckboxClick: Function,
};

@connect(
  (state) => ({
    isFiltersBarOpen: getIsUsersGroupsFiltersBarOpen(state),
    allAlertsCheckboxState: getAllUsersCheckboxState(state),
    selectedCheckboxes: getSelectedUsersCheckbox(state),
  }),
  {
    setUsersGroupsFilters: setUsersGroupsFiltersAction,
    allUsersCheckboxClick: allUsersCheckboxClickAction,
  },
)
export default class UsersAndGroups extends ReactQueryParams {
  props: PropTypes;

  defaultQueryParams = {...DEFAULT_QUERY_PARAMS};

  componentDidMount() {
    this.props.setUsersGroupsFilters(this.queryParams);
  }

  componentDidUpdate(prevProps) {
    if (super.componentDidUpdate) {
      super.componentDidUpdate();
    }

    const {setUsersGroupsFilters, location} = this.props;
    if (prevProps.location.search !== location.search) {
      setUsersGroupsFilters(this.queryParams);
    }
  }

  setQueryParamsWrapper = (queryObj) => {
    this.setQueryParams(queryObj, true);
  };

  render() {
    const {selectedCheckboxes, allUsersCheckboxClick, isFiltersBarOpen, allAlertsCheckboxState} = this.props;

    return (
      <div styleName="page-footer-container">
        <div styleName="page-container">
          <PageLayout header={<UserAndGroupsPageHeader />}>
            <div styleName="page-content-wrapper">
              <GroupsSection setQueryParams={this.setQueryParamsWrapper} />
              <div styleName="main">
                <UsersAndGroupsFilters setQueryParams={this.setQueryParamsWrapper} />
                <UsersSection />
              </div>
            </div>
            <GroupFormModal />
            <EditUserModal />
            <NewUserModal />
          </PageLayout>
        </div>
        <BottomPanel type={BOTTOM_PANEL_TYPE.blue} isActive={selectedCheckboxes.length > 0}>
          <Footer
            selectedItemsCount={selectedCheckboxes.length}
            checkboxState={allAlertsCheckboxState}
            onCheckboxClicked={allUsersCheckboxClick}
            leftPadding={isFiltersBarOpen ? 326 : 20}
          >
            <UGFooter />
          </Footer>
        </BottomPanel>
      </div>
    );
  }
}
