// @flow
import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {fetchStreamLastRun as fetchStreamLastRunAction} from 'bc/store/actions';
import {getFormattedTime, getFormattedLLDate, getCorrectTimezoneName} from 'common/utils/dateService';
import Tooltip from 'common/componentsV2/Tooltip';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {bcTypes} from 'bc/services/bcTypes';
import './ViewStreamSummaryModalV2.module.scss';

const TimeValue = (props: {time: number, timeZoneName: string}) => (
  <Fragment>
    {`${getFormattedLLDate(props.time, props.timeZoneName)}. ${getFormattedTime(props.time, props.timeZoneName)}`}
  </Fragment>
);

type PropTypes = {
  dataStream: Object,
  timeZoneName: String,

  // connect
  streamLastRun: Object,
  fetchStreamLastRun: Function,
  isLoading: Boolean,
};

@connect(
  (state) => ({
    streamLastRun: selectors.getSelectedStreamLastRun(state),
    isLoading: selectors.getSelectedStreamLastRunIsLoading(state),
  }),
  {
    fetchStreamLastRun: fetchStreamLastRunAction,
  },
)
export default class LastRunData extends PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {dataStream, fetchStreamLastRun} = this.props;

    fetchStreamLastRun({
      id: dataStream.id,
    });
  }

  render() {
    const {streamLastRun, isLoading, timeZoneName, dataStream} = this.props;

    let isReady = false;
    if (!isLoading && timeZoneName !== undefined && streamLastRun.data) {
      isReady = true;
    }

    if (!(dataStream.type !== bcTypes.local_file.type && dataStream.state !== 'new')) {
      return null;
    }

    return (
      <div styleName="section">
        <div styleName="vs-section-header">LATEST COLLECTION</div>

        {isReady ? (
          <Fragment>
            <div styleName="vs-row">
              <div styleName="vs-label">Result</div>
              <div styleName="vs-value">{streamLastRun.data.resultStatus}</div>
            </div>
            {streamLastRun.data.resultStatus !== 'OK' && (
              <div styleName="vs-row">
                <div styleName="vs-label">Details</div>
                <div styleName="vs-value">
                  <Tooltip placement="top" content={streamLastRun.data.failureDetails}>
                    <span styleName="last-run-error">{streamLastRun.data.failureDetails}</span>
                  </Tooltip>
                </div>
              </div>
            )}
            <div styleName="vs-row">
              <div styleName="vs-label">Start Time</div>
              <div styleName="vs-value">
                <span>
                  <TimeValue time={streamLastRun.data.startTime} timeZoneName={timeZoneName} />
                </span>
                <span>{`(${getCorrectTimezoneName(timeZoneName)})`}</span>
              </div>
            </div>
            <div styleName="vs-row">
              <div styleName="vs-label">Finish Time</div>
              <div styleName="vs-value">
                <span>
                  <TimeValue time={streamLastRun.data.finishTime} timeZoneName={timeZoneName} />
                </span>
                <span>{`(${getCorrectTimezoneName(timeZoneName)})`}</span>
              </div>
            </div>
            <div styleName="vs-row">
              <div styleName="vs-label">Next Run</div>
              <div styleName="vs-value">
                <span>
                  <TimeValue time={streamLastRun.data.nextTime} timeZoneName={timeZoneName} />
                </span>
                <span>{`(${getCorrectTimezoneName(timeZoneName)})`}</span>
              </div>
            </div>
          </Fragment>
        ) : (
          <div styleName="vs-row last-run-spinner-wrapper">
            <Spinner color="#3d4c59" size={SIZES.BIG_60} />
          </div>
        )}
      </div>
    );
  }
}
