import {makeAction, makeAsyncAction} from 'common/utils/simplifiedAsync';

const ACTION_PREFIX = 'anodot/admin.customers/';
const mac = (actionName) => makeAction(actionName, 'anodot/admin.customers/');
const macAsync = (actionName) => makeAsyncAction(actionName, ACTION_PREFIX);

export const fetchCustomers = macAsync('FETCH_CUSTOMERS');
export const fetchEditCustomerData = macAsync('FETCH_EDIT_CUSTOMER_DATA');
export const fetchCustomerDefaultSettings = macAsync('FETCH_CUSTOMER_DEFAULT_SETTINGS');

export const createCustomer = macAsync('CREATE_CUSTOMER');
export const updateCustomer = macAsync('UPDATE_CUSTOMER');
export const deleteCustomer = macAsync('DELETE_CUSTOMERS');

export const setCustomersListOrder = mac('SET_CUSTOMERS_LIST_ORDER');
export const setCustomersFilters = mac('SET_CUSTOMERS_FILTERS');
export const setCustomersFiltersKeyVal = mac('SET_CUSTOMERS_FILTERS_KEY_VAL');
export const setCustomersFiltersIsOpen = mac('SET_CUSTOMERS_FILTERS_IS_OPEN');

export const toggleNewCustomerModal = mac('TOGGLE_NEW_CUSTOMER_MODAL');
export const toggleCustomerChangeStatusConfirmationModal = mac('TOGGLE_CUSTOMER_CHANGE_STATUS_CONFIRMATION_MODAL');
export const toggleCustomerDeleteConfirmationModal = mac('TOGGLE_CUSTOMER_DELETE_CONFIRMATION_MODAL');
