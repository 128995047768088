// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import SearchBox from 'common/componentsV2/SearchBox';
import {getDataMangerQueryParamsSearchQuery} from 'bc/store/selectors';
import {segmentClickEvent} from 'common/store/actions';

import './DataManagerFilters.module.scss';

type PropTypes = {
  setQueryParams: Function,

  // connect
  searchQuery: String,
  segmentClickEvent: Function,
};

@connect(
  (state) => ({
    searchQuery: getDataMangerQueryParamsSearchQuery(state),
  }),
  {
    segmentClickEvent,
  },
)
export default class SearchQueryFilter extends React.PureComponent {
  props: PropTypes;

  onFilter = (val) => {
    if (val === '') {
      // eslint-disable-next-line no-param-reassign
      val = undefined;
    }
    this.props.segmentClickEvent({
      category: 'data-management-filter',
      name: `search-${val}`,
    });
    this.props.setQueryParams({searchQuery: val});
  };

  render() {
    const {searchQuery} = this.props;
    return (
      <SearchBox
        onFilter={this.onFilter}
        filter={searchQuery}
        placeHolder="Search Streams"
        automationId="dataCollectorSearchTextbox"
      />
    );
  }
}
