// @flow
import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import {getUsersData} from 'admin.users/store/selectors';
import {getIsStreamOwnerEnabled} from 'profile/store/selectors';
import {getFormattedTime, getFormattedLLDate} from 'common/utils/dateService';
import './ViewStreamSummaryModalV2.module.scss';

const TimeValue = (props: {time: number, timeZoneName: string}) => (
  <Fragment>
    {`${getFormattedLLDate(props.time, props.timeZoneName)}. ${getFormattedTime(props.time, props.timeZoneName)}`}
  </Fragment>
);

type PropTypes = {
  dataStream: Object,
  timeZoneName: String,

  // connect
  users: Array,
  isStreamOwner: boolean,
};

@connect(
  (state) => ({
    users: getUsersData(state),
    isStreamOwner: getIsStreamOwnerEnabled(state),
  }),
  {},
)
export default class CreatedSection extends PureComponent {
  props: PropTypes;

  render() {
    const {timeZoneName, dataStream, users, isStreamOwner} = this.props;

    const owner = dataStream.owner && users.length ? users.find((user) => user._id === dataStream.owner) : null;
    const creator = dataStream.creator && users.length ? users.find((user) => user._id === dataStream.creator) : null;
    const mod = dataStream.modifier && users.length ? users.find((user) => user._id === dataStream.modifier) : null;

    return (
      <div styleName="created">
        {isStreamOwner ? (
          <div styleName="vs-row">
            <div styleName="vs-label">Owner</div>
            <div styleName="vs-value">
              {!owner ? (
                <span>Not Set</span>
              ) : (
                <span>
                  <i className="icon icn-general16-user" styleName="owner-icon" />
                  {`${owner.firstName} ${owner.lastName}`}
                </span>
              )}
            </div>
          </div>
        ) : null}
        {creator ? (
          <div styleName="vs-row">
            <div styleName="vs-label">Created By</div>
            <div styleName="vs-value">
              <span>{`${creator.firstName} ${creator.lastName}`}</span>
              <span styleName="sub-time">
                <TimeValue time={dataStream.createTime} timeZoneName={timeZoneName} />
              </span>
            </div>
          </div>
        ) : (
          <div styleName="vs-row">
            <div styleName="vs-label">Created</div>
            <div styleName="vs-value">
              <span>
                <TimeValue time={dataStream.createTime} timeZoneName={timeZoneName} />
              </span>
            </div>
          </div>
        )}
        {mod ? (
          <div styleName="vs-row">
            <div styleName="vs-label">Last Modified</div>
            <div styleName="vs-value">
              <span>{`${mod.firstName} ${mod.lastName}`}</span>
              <span styleName="sub-time">
                <TimeValue time={dataStream.modifyTime} timeZoneName={timeZoneName} />
              </span>
            </div>
          </div>
        ) : (
          <div styleName="vs-row">
            <div styleName="vs-label">Last Modified</div>
            <div styleName="vs-value">
              <span>
                <TimeValue time={dataStream.modifyTime} timeZoneName={timeZoneName} />
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
