// @flow
import React from 'react';
import {useSelector} from 'react-redux';
import Button, {COLORS} from 'common/componentsV2/Button';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CSSTransition from 'react-transition-group/CSSTransition';
import {getIsFetching} from 'userSettings/store/selectors';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import '../pages/userSettings.module.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    left: 60,
    right: 0,
    bottom: 0,
    borderTop: `2px solid ${theme.palette.blue[300]}`,
    backgroundColor: theme.palette.blue[100],
    transition: 'height ease-in-out .3s',
    overflow: 'visible',
    '&:before': {
      content: '""',
      position: 'fixed',
      left: '60px',
      display: 'block',
      height: '72px',
      width: '34px',
      // eslint-disable-next-line max-len
      backgroundImage: `linear-gradient(to right, ${theme.palette.white[500]}, ${
        theme.palette.white[500]
      } 8%, rgba(255, 255, 255, 0))`,
    },
  },
}));

const BottomPanel = ({isOpenPopper, errorNumbers}: {isOpenPopper: boolean, errorNumbers: number}) => {
  const isFetching = useSelector((state) => getIsFetching(state));
  const classes = useStyles();

  return (
    <CSSTransition timeout={300} classNames="bottomBar" appear in>
      <Box className={classes.root}>
        <Box styleName="contentBottomBar">
          <Box position="relative" display="flex">
            {isOpenPopper && (
              <Box
                borderRadius={6}
                position="absolute"
                bgcolor="red.500"
                py={1}
                px={1.5}
                color="white.500"
                fontWeight="bold"
                top={-70}
                right={0}
                zIndex={1}
                maxWidth={350}
              >
                <TypographyBox variant="subtitle2" lineHeight="21px" color="white.500" minWidth="max-content">
                  {`${errorNumbers} Errors to fix`}
                </TypographyBox>
                <Box
                  position="absolute"
                  width={12}
                  height={12}
                  bottom={-6}
                  right={29}
                  bgcolor="red.500"
                  css={{transform: 'rotate(45deg)'}}
                />
              </Box>
            )}
            <Button type="submit" text="Save" colorSchema={COLORS.BLUE_500} isDisabled={isFetching} />
          </Box>
        </Box>
      </Box>
    </CSSTransition>
  );
};

export default BottomPanel;
