// @flow
import React from 'react';
import MultiButton from 'common/componentsV2/multiButton/MultiButton';

type PropsType = {
  value: string,
  onChange: Function,
  providedResolution?: string,
  title?: string,
  extraStyleName?: string,
  isFooterComponent?: boolean,
};

const resolutionDictionary = [
  {value: 'short', label: '1 Min.', labelShort: '1m'},
  {value: 'medium', label: '5 Min.', labelShort: '5m'},
  {value: 'long', label: '1 Hr', labelShort: '1 Hour'},
  {value: 'longlong', label: '1 Day', labelShort: '1 Day'},
  {value: 'weekly', label: '1 Week', labelShort: '1 Week'},
];

const TimeScaleSelector = ({
  value,
  onChange,
  providedResolution,
  title,
  extraStyleName,
  isFooterComponent,
}: PropsType) => {
  const labelType = isFooterComponent ? 'labelShort' : 'label';
  const autoLabel =
    providedResolution === undefined || providedResolution === ''
      ? 'Auto'
      : `Auto (${resolutionDictionary.find((item) => item.value === providedResolution)[labelType]})`;

  const resolutions = [{value: '', label: autoLabel, labelShort: autoLabel}, ...resolutionDictionary];
  return (
    <MultiButton
      onChange={onChange}
      value={resolutions.find((item) => item.value === value).value}
      options={resolutions}
      automationId="Time scale"
      extraClass="multi-button-time-scale"
      extraStyleName={extraStyleName}
      title={title}
      labelKey={labelType}
    />
  );
};

export default TimeScaleSelector;

TimeScaleSelector.defaultProps = {
  title: undefined,
  extraStyleName: undefined,
  providedResolution: undefined,
  isFooterComponent: false,
};
