// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import PopoverSelect from 'common/components/popoverSelect';
import * as selectors from 'bc.cubes/store/selectors';
import {setEditableTopCubeKeyVal as setEditableTopCubeKeyValAction} from 'bc.cubes/store/actions';
import CubeDimensionsSelectedItem from './CubeDimensionsSelectedItem';
import './CubeDescription.module.scss';

type PropTypes = {
  className: string,
  possibleDimensions: Array,
  selectedDimensions: Array,
  editableTopCube: Object,

  setEditableTopCubeKeyVal: Function,
};

@connect(
  (state) => ({
    schema: selectors.getSelectedSchema(state),
    possibleDimensions: selectors.getSelectedSchemaFilteredPossibleDimensions(state),
    selectedDimensions: selectors.getSelectedSchemaFilteredSelectedDimensions(state),
    editableTopCube: selectors.getEditableTopCube(state),
  }),
  {
    setEditableTopCubeKeyVal: setEditableTopCubeKeyValAction,
  },
)
export default class CubeDimensions extends PureComponent {
  props: PropTypes;

  onAddItem = (id) => {
    const {setEditableTopCubeKeyVal, editableTopCube} = this.props;
    const dim = editableTopCube ? editableTopCube.dimensions : [];
    dim.push(id);
    setEditableTopCubeKeyVal({dimensions: dim}, {isPreviewCube: true});
  };

  onRemoveItem = (id) => {
    const {setEditableTopCubeKeyVal, editableTopCube} = this.props;
    const dim = editableTopCube.dimensions.filter((d) => d !== id);
    setEditableTopCubeKeyVal({dimensions: dim}, {isPreviewCube: true});
  };

  // TODO: PopoverSelect - make new prop 'maxSelectedItems' then disable additional selections but can toggle off

  render() {
    const {className, possibleDimensions, selectedDimensions, editableTopCube} = this.props;
    const allSelections = possibleDimensions.length + selectedDimensions.length;
    const maxSelections = allSelections > 3 ? 3 : allSelections - 1;

    return (
      <div styleName="root" id="cube-editor-dimensions" className={className}>
        <div className="andt-section-header-h4">
          {editableTopCube.dimensions.length > 0 ? (
            <a href="#1" styleName="quick-tip">
              Mandatory
            </a>
          ) : null}
          <PopoverSelect
            styleName="dim-popover-select"
            title="Dimensions"
            subtitle={`Up to ${this.maxSelections} options`}
            maxSelections={maxSelections}
            theme="light"
            isHidePanelTitle
            isDisabled={selectedDimensions.length === 3 || !editableTopCube.measure}
            possibleConcepts={possibleDimensions}
            selectedConcepts={selectedDimensions}
            onAddItem={this.onAddItem}
            onRemoveItem={this.onRemoveItem}
            selectedItemComponent={CubeDimensionsSelectedItem}
            isLoading={false}
          />
        </div>
      </div>
    );
  }
}
