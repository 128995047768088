// @flow
import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import {StringParam, useQueryParams} from 'use-query-params';
import PageLayout from 'common/componentsV2/PageLayout';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import FilterButton from 'common/componentsV2/FilterButton';
import * as actions from 'dashboards/store/actions';
import * as selectors from 'dashboards/store/selectors';
import DashboardsFilters from 'dashboards/components/DashboardsFilters';
import DashboardsMain from 'dashboards/components/DashboardsMain';
import DashboardNewButton from 'dashboards/components/DashboardNewButton';
import {DEFAULT_PARAMS, OPTION_TILES_VALUES, optionsImport} from 'dashboards/services/dashboardService';
import {keyBy, omitBy} from 'lodash';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';

const useStyles = makeStyles(({palette}) => ({
  iconWrapper: {
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.gray[200],
    borderRadius: '6px',
    width: '35px',
    height: '35px',
    color: palette.gray[500],
    marginRight: '8px',
  },
}));

const DashboardsManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [queryParams, setQueryParams] = useQueryParams({
    q: StringParam,
    ownerUser: StringParam,
    tags: StringParam,
  });
  const isOpenFilters = useSelector(selectors.isOpenFilters);
  const totalCount = useSelector(selectors.getTotalDashboards);
  const dataDashboards = useSelector(selectors.getDataDashboards);
  const v1Duplicates = useMemo(() => keyBy(dataDashboards, 'v1ID'), [dataDashboards]);
  const duplicatesCount = dataDashboards.filter((item) => v1Duplicates[item._id]).length;
  const dataIsLoading = useSelector(selectors.getFetchDashboardsLoading);

  useEffect(() => {
    dispatch(actions.fetchDataDashboardsManagementPage());
  }, []);

  const onFilter = useCallback((val) => {
    setQueryParams({q: val === '' ? undefined : val});
  }, []);

  const toggleFilters = () => {
    dispatch(actions.updateFilterBtn(isOpenFilters));
  };

  const handleChange = ({value}, name) => {
    setQueryParams({[name]: value});
  };

  const changedFilter = useMemo(() => {
    const obj = omitBy(queryParams, (value, key) => value === DEFAULT_PARAMS[key]);
    return Object.keys(obj).map((item) => ({[item]: obj.item}));
  }, [queryParams]);

  const header = (
    <div className="display_flex width_1 justifyContent_space-between">
      <div className="display_flex">
        <div className="mr_1-5">
          <Title type={TITLE_TYPES.PAGE_TITLE}>Dashboards</Title>
        </div>
        <div className="mr_1-5">
          <Title type={TITLE_TYPES.PAGE_LINE_COUNTER}>{`(${totalCount - duplicatesCount})`}</Title>
        </div>
        <FilterButton tooltipItems={changedFilter} onClick={toggleFilters} />
      </div>
      <div className="display_flex alignItems_center">
        <FormDdlSelect
          options={optionsImport}
          button={
            <div className={classes.iconWrapper}>
              <i className="icon icn-general16-3dot" />
            </div>
          }
          optionComponent={<OptionComponentSimple />}
          onChange={() => history.push(`/dashboards/${OPTION_TILES_VALUES.IMPORT}`)}
          width={220}
          position="right"
          automationId="importDashboardMenu"
          horizontalOffset={-6}
        />
        <DashboardNewButton />
      </div>
    </div>
  );
  return (
    <PageLayout header={header}>
      <div className="display_flex flexGrow_1">
        <DashboardsFilters
          dataQueryParams={queryParams}
          toggleFilters={toggleFilters}
          setQueryParams={setQueryParams}
          onFilter={onFilter}
          onChange={handleChange}
        />
        {dataIsLoading ? (
          <div className="display_flex alignItems_center justifyContent_center height_1vh width_1vw">
            <Spinner color="gray.500" size={SIZES.XX_BIG_100} />
          </div>
        ) : (
          <DashboardsMain isOpenFilters={isOpenFilters} dataQueryParams={queryParams} changedFilter={changedFilter} />
        )}
      </div>
    </PageLayout>
  );
};

export default React.memo(DashboardsManagement);
