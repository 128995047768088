// @flow

import React from 'react';
import './EmptyState.module.scss';

type PropTypes = {
  state: String,
};

const EmptyState = (props: PropTypes) => {
  if (props.state === 'noData') {
    return (
      <div styleName="error-message">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="139" height="110" viewBox="0 0 139 110">
            <g fill="none" fillRule="evenodd">
              <g>
                <g>
                  <path
                    fill="#EEF5FF"
                    d="M59.23 17h21.54c6.687 0 9.112.696 11.556 2.003 2.445 1.308 4.363 3.226 5.67 5.67C99.305 27.119 100 29.544 100 36.23v35.542c0 6.686-.696 9.11-2.003 11.555-1.308 2.445-3.226 4.363-5.67 5.67C89.881 90.305 87.456 91 80.77 91H59.229c-6.686 0-9.11-.696-11.555-2.003-2.445-1.308-4.363-3.226-5.67-5.67C40.695 80.881 40 78.456 40 71.77V36.229c0-6.686.696-9.11 2.003-11.555 1.308-2.445 3.226-4.363 5.67-5.67C50.119 17.695 52.544 17 59.23 17z"
                    transform="translate(-839 -363) translate(839 363)"
                  />
                  <path
                    fill="#D4D9E0"
                    d="M35.692 26l9.147.004c2.395.034 3.69.315 5.035 1.034 1.326.71 2.379 1.762 3.088 3.088.77 1.44 1.038 2.825 1.038 5.566v12.616c0 2.74-.267 4.125-1.038 5.566-.71 1.326-1.762 2.379-3.088 3.088-1.44.77-2.825 1.038-5.566 1.038h-8.616c-2.74 0-4.125-.267-5.566-1.038-1.326-.71-2.379-1.762-3.088-3.088-.77-1.44-1.038-2.825-1.038-5.566l.004-13.147c.034-2.395.315-3.69 1.034-5.035.71-1.326 1.762-2.379 3.088-3.088 1.44-.77 2.825-1.038 5.566-1.038zm8.9 4h-8.9l-.836.01c-1.519.038-2.173.197-2.843.555-.63.337-1.111.818-1.448 1.448-.418.782-.565 1.542-.565 3.679v12.616l.01.836c.038 1.519.197 2.173.555 2.843.337.63.818 1.111 1.448 1.448.67.358 1.324.517 2.843.556l.836.009h8.616l.836-.01c1.519-.038 2.173-.197 2.843-.555.63-.337 1.111-.818 1.448-1.448.358-.67.517-1.324.556-2.843l.009-.836V35.692l-.01-.836c-.038-1.519-.197-2.173-.555-2.843-.337-.63-.818-1.111-1.448-1.448-.782-.418-1.542-.565-3.679-.565zM44 46c1.105 0 2 .895 2 2 0 1.026-.772 1.871-1.767 1.987L44 50h-8c-1.105 0-2-.895-2-2 0-1.026.772-1.871 1.767-1.987L36 46h8zm-2-6c1.105 0 2 .895 2 2 0 1.026-.772 1.871-1.767 1.987L42 44h-6c-1.105 0-2-.895-2-2 0-1.026.772-1.871 1.767-1.987L36 40h6zm2-6c1.105 0 2 .895 2 2 0 1.026-.772 1.871-1.767 1.987L44 38h-8c-1.105 0-2-.895-2-2 0-1.026.772-1.871 1.767-1.987L36 34h8z"
                    transform="translate(-839 -363) translate(839 363)"
                  />
                  <path
                    fill="#D4D9E0"
                    d="M96.681 67.337l-4.28 4.281c-.774.774-.774 2.028 0 2.801.773.774 2.027.774 2.8 0l4.282-4.28 2.84 2.84c.612.612 1.677.178 1.677-.71v-8.463c0-.552-.434-.986-.986-.986H94.55c-.888 0-1.322 1.065-.71 1.677l2.84 2.84zM77 63c-.56 0-1 .44-1 1v8.58c0 .9 1.08 1.34 1.7.7l2.88-2.88L88 77.8V89c0 1.1.9 2 2 2s2-.9 2-2V77c0-.52-.22-1.04-.58-1.42l-8-8.02 2.88-2.88c.62-.6.18-1.68-.72-1.68H77z"
                    transform="translate(-839 -363) translate(839 363) rotate(90 90 77)"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
        <h3>Add Assets</h3>
        <h5>Assets are used across Anodot</h5>
      </div>
    );
  }
  if (props.state === 'filteredOut') {
    return (
      <div styleName="error-message">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="139" height="110" viewBox="0 0 139 110">
            <g fill="none" fillRule="evenodd">
              <g>
                <g>
                  <path
                    fill="#EEF5FF"
                    d="M51.23 17h21.54c6.687 0 9.112.696 11.556 2.003 2.445 1.308 4.363 3.226 5.67 5.67C91.305 27.119 92 29.544 92 36.23v35.542c0 6.686-.696 9.11-2.003 11.555-1.308 2.445-3.226 4.363-5.67 5.67C81.881 90.305 79.456 91 72.77 91H51.229c-6.686 0-9.11-.696-11.555-2.003-2.445-1.308-4.363-3.226-5.67-5.67C32.695 80.881 32 78.456 32 71.77V36.229c0-6.686.696-9.11 2.003-11.555 1.308-2.445 3.226-4.363 5.67-5.67C42.119 17.695 44.544 17 51.23 17z"
                    transform="translate(-839 -363) translate(839 363)"
                  />
                  <path
                    fill="#D7DEE8"
                    d="M78.85 41c10.41 0 18.848 8.434 18.848 18.838 0 4.273-1.423 8.213-3.82 11.373l17.949 17.95c1.564 1.565 1.564 4.101 0 5.666-1.565 1.564-4.101 1.564-5.665 0l-18.39-18.39c-2.657 1.429-5.695 2.24-8.923 2.24C68.44 78.677 60 70.242 60 59.838 60 49.434 68.44 41 78.85 41z"
                    transform="translate(-839 -363) translate(839 363)"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
        <h3>No Assets Found</h3>
        <h5>Change filters to see assets</h5>
      </div>
    );
  }
  return null;
};

export default EmptyState;
