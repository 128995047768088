// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import * as selector from 'admin.users/store/selectors';
import {getOrganizationSettingsBC, getIsStreamOwnerEnabled} from 'profile/store/selectors';
import {fetchOwnerStreamsCount} from 'admin.users/store/actions';
import './UsersModals.module.scss';

type PropTypes = {
  userIds: string,
  userId: string,
  streamsCount: number,
  isBcDisabled: boolean,
  isStreamOwner: boolean,
  fetchOwnerStreamsCount: Function,
};

@connect(
  (state, ownProps) => ({
    userIds: ownProps.userIds,
    userId: selector.getUsersViewDeleteModalUserId(state),
    streamsCount: selector.getOwnerStreamsCountValue(state),
    isBcDisabled: getOrganizationSettingsBC(state).enabled === false,
    isStreamOwner: getIsStreamOwnerEnabled(state),
  }),
  {
    fetchOwnerStreamsCount,
  },
)
export default class StreamsDelegationWarning extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {userId, userIds, isBcDisabled, isStreamOwner} = this.props;

    if (!isBcDisabled && isStreamOwner && userId) {
      this.props.fetchOwnerStreamsCount(this.props.userId);
    }
    if (!isBcDisabled && isStreamOwner && userIds) {
      this.props.fetchOwnerStreamsCount(this.props.userIds);
    }
  }

  render() {
    const {userId, isBcDisabled, streamsCount, isStreamOwner} = this.props;

    if (isBcDisabled || !streamsCount || !isStreamOwner) {
      return null;
    }

    return (
      <div styleName="streams-delegation">
        <i className="icon icn-general16-warning" />
        <div>
          <div styleName="row">
            {userId ? 'This user owns' : 'Those users own'}
            <span styleName="bold">{` ${streamsCount} data streams`}</span>.
          </div>
          <div styleName="row">
            Once you delete this user, the ownership of these streams will be transferred to you.
          </div>
        </div>
      </div>
    );
  }
}
