// @flow
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';
import {segmentClickEvent as segmentTrackingMouseEventAction} from 'common/store/actions';
import Loader from 'common/components/Loader';
import {isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import * as selectors from 'bc/store/selectors';
import {
  fetchSqlTablesViewsList as fetchSqlTablesViewsListAction,
  fetchSqlSchemasList as fetchSqlSchemasListAction,
  setSqlStreamTableName as setSqlStreamTableNameAction,
  setSelectedStreamKeyVal as setSelectedStreamKeyValAction,
  setSqlStreamSchemaName as setSqlStreamSchemaNameAction,
  resetSqlStreamData as resetSqlStreamDataAction,
  fetchSqlQueryPreview as fetchSqlQueryPreviewAction,
  fetchSqlVerifyQuery as fetchSqlVerifyQueryAction,
  setSqlStreamQuery as setSqlStreamQueryAction,
  setIsStreamEditEnabled as setIsStreamEditEnabledAction,
  setSelectedStreamUiKeyVal as setSelectedStreamUiKeyValAction,
  fetchSqlQueryTemplates as fetchSqlQueryTemplatesAction,
  fetchSqlQueryTemplatePreview as fetchSqlQueryTemplatePreviewAction,
} from 'bc/store/actions';
import {isSchemasSupportedBySql, isTemplateQuerySupported} from 'bc/services/sqlService';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import QueryEditorModal from 'bc/components/streams/editor/byType/sql/queryEditorModal/QueryEditorModal';
import SqlSettingsModal from 'bc/components/streams/editor/common/SqlSettingsModal';
import SqlTablePreview from './SqlTablePreview';
import SqlDataSelect from './SqlDataSelect';
import QueryTemplates from './QueryTemplates';
import './SqlData.module.scss';

const DEFAULT_QUERY = `SELECT timecol, col1, col2, col3
FROM mytable
WHERE timecol >= '{{from_time}}' and timecol < '{{to_time}}'
ORDER BY timecol ASC`;

const GUIDELINES = [
  <Fragment>Do not use comments, OFFSET and LIMIT in the query text</Fragment>,
  <Fragment>Include a timestamp column, a measure and a dimension.</Fragment>,
  <Fragment>Include an ORDER BY clause based on the timestamp column</Fragment>,
  <Fragment>
    use &#123;&#123;from_time&#125;&#125; and &#123;&#123;to_time&#125;&#125; variables to create a recurring query.
  </Fragment>,
  <Fragment>
    Note that &#123;&#123;from_time&#125;&#125; is inclusive and &#123;&#123;to_time&#125;&#125; is exclusive
  </Fragment>,
  <Fragment>
    Timestamp formats:
    <br />
    - The default timestamp format is yyyy-MM-dd HH:mm:ss
    <br />- For epoch formats use one of epoch_seconds / epoch_milliseconds / epoch_microseconds . Example
    &#123;&#123;to_time(epoch_seconds)&#125;&#125;
  </Fragment>,
];

type PropTypes = {
  dataStream: Object,
  previewData: Object,
  queryPreviewItems: Object,
  previewDataError: Object,
  isPreviewDataLoading: boolean,
  tablesViewsList: Array<string>,
  tablesViewsListIsLoading: boolean,
  schemasList: Array<string>,
  schemasListIsLoading: boolean,
  isVerifyQueryLoading: boolean,
  verifyQueryData: Object,
  sqlFillAction: boolean,
  sqlStreamType: string,
  queryTemplates: Array<string>,
  isQueryTemplatesLoading: boolean,

  // connect
  fetchSqlVerifyQuery: Function,
  fetchSqlQueryPreview: Function,
  fetchSqlTablesViewsList: Function,
  fetchSqlSchemasList: Function,
  setSqlStreamTableName: Function,
  setSelectedStreamKeyVal: Function,
  setSqlStreamSchemaName: Function,
  setSqlStreamQuery: Function,
  resetSqlStreamData: Function,
  queryPreviewItems: any,
  segmentClickEvent: Function,
  setIsStreamEditEnabled: Function,
  setSelectedStreamUiKeyVal: Function,
  fetchSqlQueryTemplates: Function,
  fetchSqlQueryTemplatePreview: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    tablesViewsList: selectors.getTablesViewsListItems(state),
    tablesViewsListIsLoading: selectors.getTablesViewsListIsLoading(state),
    schemasList: selectors.getSchemasListItems(state),
    schemasListIsLoading: selectors.getSchemasListIsLoading(state),
    previewData: selectors.getSqlQueryPreviewData(state),
    queryPreviewItems: selectors.getSqlQueryStreamQueryPreviewItems(state).columns,
    previewDataError: selectors.getSqlQueryStreamQueryPreviewError(state),
    isPreviewDataLoading: selectors.getSqlQueryStreamQueryPreviewIsLoading(state),
    isVerifyQueryLoading: selectors.getSqlQueryStreamVerifyQueryIsLoading(state),
    verifyQueryData: selectors.getSqlQueryStreamVerifyQueryData(state),
    sqlFillAction: selectors.getSqlFillAction(state),
    sqlStreamType: selectors.getStreamType(state),
    queryTemplates: selectors.getSqlQueryTemplatesData(state),
    isQueryTemplatesLoading: selectors.getSqlQueryTemplatesIsLoading(state),
  }),
  {
    fetchSqlTablesViewsList: fetchSqlTablesViewsListAction,
    fetchSqlSchemasList: fetchSqlSchemasListAction,
    setSqlStreamTableName: setSqlStreamTableNameAction,
    setSqlStreamSchemaName: setSqlStreamSchemaNameAction,
    setSelectedStreamKeyVal: setSelectedStreamKeyValAction,
    setSqlStreamQuery: setSqlStreamQueryAction,
    resetSqlStreamData: resetSqlStreamDataAction,
    fetchSqlVerifyQuery: fetchSqlVerifyQueryAction,
    fetchSqlQueryPreview: fetchSqlQueryPreviewAction,
    segmentClickEvent: segmentTrackingMouseEventAction,
    setIsStreamEditEnabled: setIsStreamEditEnabledAction,
    setSelectedStreamUiKeyVal: setSelectedStreamUiKeyValAction,
    fetchSqlQueryTemplates: fetchSqlQueryTemplatesAction,
    fetchSqlQueryTemplatePreview: fetchSqlQueryTemplatePreviewAction,
  },
)
export default class SqlData extends React.PureComponent {
  props: PropTypes;

  state = {
    isOpenTablePreviewModal: false,
    isOpenSettingsModal: false,
    isOpenQueryEditorModal: false,
    basedOnTemplateId: '',
    editUseCaseQuery: null,
  };

  componentDidMount() {
    const {
      fetchSqlTablesViewsList,
      fetchSqlSchemasList,
      dataStream,
      setSelectedStreamUiKeyVal,
      fetchSqlQueryTemplates,
    } = this.props;

    const payload = {
      type: dataStream.type,
      dataSourceId: dataStream.dataSourceId,
      schemaName: isSchemasSupportedBySql(dataStream.type) ? dataStream.schemaName : '',
    };

    if (isSchemasSupportedBySql(dataStream.type)) {
      fetchSqlSchemasList(payload);
    }
    if (isTemplateQuerySupported(dataStream.type)) {
      fetchSqlQueryTemplates(dataStream.type);
    }
    if (dataStream.schemaName || !isSchemasSupportedBySql(dataStream.type)) {
      fetchSqlTablesViewsList(payload);
    }

    // edit running stream
    if (isStreamRunning(this.props.dataStream.state)) {
      // new "analyze" without sever
      if (isEmpty(dataStream.uiState.tablesViewsMetadata) && !isEmpty(dataStream.schema.columns)) {
        const dataArr = [];
        dataStream.schema.columns.forEach((item) => {
          if (!item.transform) {
            let itemType = 'timestamp';
            if (item.type === 'metric') {
              itemType = 'integer';
            } else if (item.type === 'dimension') {
              itemType = 'other';
            }
            const newItem = {
              name: item.name,
              id: item.name,
              type: itemType,
            };
            if (itemType !== 'other') {
              newItem.newItem = ['number', 'timestamp'];
            }
            dataArr.push(newItem);
          }
        });
        setSelectedStreamUiKeyVal({
          ...dataStream.uiState,
          tablesViewsMetadata: dataArr,
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.resetSqlStreamData();
  }

  closeQueryEditorModal = () => {
    this.setState({isOpenQueryEditorModal: false, editUseCaseQuery: null});
  };

  openQueryEditorModal = () => {
    const {dataStream, segmentClickEvent} = this.props;
    this.setState({isOpenQueryEditorModal: true});
    segmentClickEvent({category: `Data collectors - ${dataStream.type}`, name: 'Query editor'});
  };

  setSelectedRadio = (changeEvent) => {
    const {
      dataStream,
      setSelectedStreamUiKeyVal,
      setSqlStreamTableName,
      setIsStreamEditEnabled,
      setSelectedStreamKeyVal,
    } = this.props;
    const isCustomQuery = changeEvent.target.value !== 'table';
    setSelectedStreamKeyVal({
      customQuery: isCustomQuery,
      basedOnTemplateId: changeEvent.target.value === 'template' ? dataStream.basedOnTemplateId : '',
    });
    setSelectedStreamUiKeyVal({selectedRadio: changeEvent.target.value, customQuery: isCustomQuery});
    if (changeEvent.target.value !== 'template') {
      this.setState({basedOnTemplateId: ''});
    }
    if (dataStream.tableName && changeEvent.target.value === 'table') {
      setSqlStreamTableName({
        tableName: dataStream.tableName,
      });
    } else {
      setIsStreamEditEnabled(false);
    }
  };

  previewClicked = () => {
    this.setState((prevState) => ({isOpenTablePreviewModal: !prevState.isOpenTablePreviewModal}));
  };

  settingsClicked = () => {
    this.setState((prevState) => ({isOpenSettingsModal: !prevState.isOpenSettingsModal}));
  };

  handelContinueClick = (payload) => {
    this.props.fetchSqlVerifyQuery({...payload, type: this.props.dataStream.type});
  };

  chooseTableChanged = (tName) => {
    if (!tName) {
      return;
    }
    this.props.setSqlStreamTableName({
      tableName: tName ? tName.name : null,
    });
  };

  chooseSchemaChanged = (tName) => {
    if (!tName) {
      return;
    }
    this.props.setSqlStreamSchemaName({
      schemaName: tName ? tName.name : null,
      tableName: null,
    });
  };

  setMissingDimPolicy = (isFill) => {
    this.props.setSelectedStreamKeyVal({
      missingDimPolicy: {
        action: isFill ? 'fill' : 'ignore',
        fill: 'unknown',
      },
    });
  };

  onQueryEditItemClick = (templateId) => {
    const selTemplate = this.props.queryTemplates.find((qTemp) => qTemp.id === templateId);
    this.setState({
      editUseCaseQuery: selTemplate,
      isOpenQueryEditorModal: true,
    });
  };

  onQuerySelectClick = (templateId) => {
    if (this.state.basedOnTemplateId === templateId) {
      return;
    }

    this.setState({basedOnTemplateId: templateId});
    const selTemplate = this.props.queryTemplates.find((qTemp) => qTemp.id === templateId);
    this.props.fetchSqlQueryTemplatePreview({
      query: selTemplate.query,
      useLegacySQL: selTemplate.useLegacySQL,
      basedOnTemplateId: selTemplate.id,
    });
  };

  render() {
    const {
      tablesViewsList,
      schemasList,
      dataStream,
      schemasListIsLoading,
      tablesViewsListIsLoading,
      previewData,
      previewDataError,
      isPreviewDataLoading,
      queryPreviewItems,
      fetchSqlQueryPreview,
      isVerifyQueryLoading,
      verifyQueryData,
      setSqlStreamQuery,
      sqlStreamType,
      queryTemplates,
      isQueryTemplatesLoading,
    } = this.props;
    const isEditable = isStreamEditable(dataStream.state);
    const isTemplateQueryEnabled =
      isTemplateQuerySupported(dataStream.type) &&
      (isQueryTemplatesLoading || (!isQueryTemplatesLoading && !!queryTemplates.length));
    const query = dataStream.query ? dataStream.query : DEFAULT_QUERY;

    return (
      <div className="shell-col" styleName="root">
        <PanelHeader title="Table Data" isEditable={isEditable}>
          <button
            type="button"
            automation-id="viewTableButton"
            className="btn btn-icon btn-icon btn-dark-blue"
            disabled={!dataStream.tableName || sqlStreamType !== 'table'}
            onClick={this.previewClicked}
          >
            <i className="icon icn-view-icon" />
          </button>

          <button
            type="button"
            className="btn btn-icon btn-icon btn-dark-blue"
            disabled={false}
            onClick={this.settingsClicked}
          >
            <i className="icon icn-settings-icon" />
          </button>
        </PanelHeader>

        {this.state.isOpenTablePreviewModal && (
          <SqlTablePreview isOpen={this.state.isOpenTablePreviewModal} onClose={this.previewClicked} />
        )}

        {this.state.isOpenSettingsModal && (
          <SqlSettingsModal
            isOpen={this.state.isOpenSettingsModal}
            onClose={this.settingsClicked}
            onChange={this.setMissingDimPolicy}
            sqlFillActionValue={this.props.sqlFillAction}
          />
        )}

        <div styleName="block">
          <div styleName="radio-block">
            <input
              type="radio"
              name="queryType"
              value="table"
              checked={sqlStreamType === 'table'}
              onChange={this.setSelectedRadio}
            />
          </div>

          <div styleName="select-block">
            {isSchemasSupportedBySql(dataStream.type) && (
              <SqlDataSelect
                automationId="sqlSchemaSelect"
                items={schemasList}
                defaultValue={dataStream.schemaName}
                onChange={this.chooseSchemaChanged}
                isLoading={schemasListIsLoading}
                title="Choose Schema"
                disabled={sqlStreamType !== 'table'}
              />
            )}

            <SqlDataSelect
              automationId="sqlTableSelect"
              items={tablesViewsList}
              defaultValue={dataStream.tableName}
              onChange={this.chooseTableChanged}
              disabled={
                (isSchemasSupportedBySql(dataStream.type) && !dataStream.schemaName) || sqlStreamType !== 'table'
              }
              isLoading={
                isSchemasSupportedBySql(dataStream.type)
                  ? dataStream.schemaName && tablesViewsListIsLoading
                  : tablesViewsListIsLoading
              }
              title="Choose Table"
            />
          </div>
        </div>

        <div styleName="block">
          <div styleName="radio-block">
            <input
              type="radio"
              name="queryType"
              value="query"
              checked={sqlStreamType === 'query'}
              onChange={this.setSelectedRadio}
            />
          </div>

          <div styleName="select-block">
            <button
              type="button"
              className="btn btn-raised"
              styleName="btn-compose"
              automation-id="bigQueryComposeQueryButton"
              disabled={sqlStreamType !== 'query'}
              onClick={this.openQueryEditorModal}
            >
              Compose Query
            </button>
          </div>
        </div>

        {isTemplateQueryEnabled && (
          <div styleName="block">
            <div styleName="radio-block">
              <input
                type="radio"
                name="queryType"
                value="template"
                checked={sqlStreamType === 'template'}
                onChange={this.setSelectedRadio}
              />
            </div>

            <div styleName="select-block">
              <div styleName={['use-case-header', sqlStreamType !== 'template' ? 'disabled' : ''].join(' ')}>
                Select use case
              </div>
              {isQueryTemplatesLoading ? (
                <div styleName="use-case-loader">
                  <Loader size="small" />
                </div>
              ) : null}
              <QueryTemplates
                templates={queryTemplates}
                disabled={sqlStreamType !== 'template'}
                onTemplateEditClick={this.onQueryEditItemClick}
                onSelectClick={this.onQuerySelectClick}
                selectedTemplateId={this.state.basedOnTemplateId || dataStream.basedOnTemplateId}
              />
            </div>
          </div>
        )}

        {this.state.isOpenQueryEditorModal && (
          <QueryEditorModal
            isOpen={this.state.isOpenQueryEditorModal}
            onClose={this.closeQueryEditorModal}
            dataStream={dataStream}
            query={query}
            queryTemplate={this.state.editUseCaseQuery}
            runQueryPreview={fetchSqlQueryPreview}
            setStreamQuery={setSqlStreamQuery}
            previewData={previewData}
            previewDataError={previewDataError}
            isPreviewDataLoading={isPreviewDataLoading}
            queryPreviewColumns={queryPreviewItems}
            fetchVerifyQueryFunction={this.handelContinueClick}
            isVerifyQueryLoading={isVerifyQueryLoading}
            verifyQueryData={verifyQueryData}
            guidelines={GUIDELINES}
          />
        )}
      </div>
    );
  }
}
