import {combineEpics} from 'redux-observable';
import fetchCurUserOpenAlertsCountApi from 'app/services/api';

import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';

import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {fetchGroups} from 'admin.users/store/actions';
import * as profileActions from 'profile/store/actions';
import * as profileSelectors from 'profile/store/selectors';
import * as actions from '../actions';

const fetchCurUserOpenAlertsCount = makeAsyncEpic(actions.fetchCurUserOpenAlertsCount, fetchCurUserOpenAlertsCountApi);

const updateBarOnSetMe = (action$, {getState}) =>
  action$.ofType(profileActions.setMe.TYPE, profileActions.fetchMe.success.TYPE).flatMap(({meta}) => {
    const curState = getState();
    const isManagerV2Enabled = profileSelectors.getAlertManagerEnabled(curState);
    const isDashboardV2Enabled = profileSelectors.getDashboardV2Enabled(curState);
    const isNewChannelsEnabled = profileSelectors.getNewChannelsEnabled(curState);
    const ownerOrganization = profileSelectors.getMeOwnerOrganization(curState);
    const isMeAnodot = profileSelectors.isAnodot(curState);
    const invitationId = profileSelectors.getInvitationId(curState);
    const isBcDisabled = profileSelectors.getOrganizationSettingsBC(curState).enabled === false;
    const isTopologyEnabled = profileSelectors.getTopologyEnabled(curState);
    const isAssetsManagementEnabled = profileSelectors.getAssetsManagementEnabled(curState);
    const isIntegrationsPageEnabled = profileSelectors.getIntegrationsPageEnabled(curState);
    const isActivityLogEnabled = profileSelectors.getActivityLogEnabled(curState);
    const changeActionsArr = [];

    if (isManagerV2Enabled) {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'MANAGEMENT',
          subId: 'ALERT_MANAGER',
          navItemProps: {links: ['#!/r/alert-manager']},
        }),
      );
    }

    if (isDashboardV2Enabled) {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'DASHBOARDS',
          subId: 'DASHBOARDSV2',
          navItemProps: {links: ['#!/r/dashboards']},
        }),
      );
    }

    if (isNewChannelsEnabled) {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'INTEGRATIONS',
          subId: 'CHANNELS',
          navItemProps: {links: ['#!/r/channels', '#!/r/channels/new/email']},
        }),
      );
    }

    if (!isMeAnodot && !invitationId && !(meta && meta.skip)) {
      changeActionsArr.push(fetchGroups({ownerOrganization}));
    }

    if (isBcDisabled) {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'INTEGRATIONS',
          subId: 'STREAMS',
          navItemProps: {isHidden: true},
        }),
      );
    } else {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'INTEGRATIONS',
          subId: 'STREAMS',
          navItemProps: {isHidden: false},
        }),
      );
    }

    if (isTopologyEnabled) {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'TOPOLOGY',
          navItemProps: {isHidden: false},
        }),
      );
    } else {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'TOPOLOGY',
          navItemProps: {isHidden: true},
        }),
      );
    }

    if (isAssetsManagementEnabled) {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'MANAGEMENT',
          subId: 'ASSET_MANAGEMENT',
          navItemProps: {isHidden: false},
        }),
      );
    } else {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'MANAGEMENT',
          subId: 'ASSET_MANAGEMENT',
          navItemProps: {isHidden: true},
        }),
      );
    }

    if (isIntegrationsPageEnabled) {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'CATALOG',
          navItemProps: {isHidden: false},
        }),
      );
    } else {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'CATALOG',
          navItemProps: {isHidden: true},
        }),
      );
    }

    if (isActivityLogEnabled) {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'MANAGEMENT',
          subId: 'ACTIVITY_LOG',
          navItemProps: {isHidden: false},
        }),
      );
    } else {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'MANAGEMENT',
          subId: 'ACTIVITY_LOG',
          navItemProps: {isHidden: true},
        }),
      );
    }

    return changeActionsArr;
  });

const appNavBarEpic = combineEpics(fetchCurUserOpenAlertsCount, updateBarOnSetMe);
export default appNavBarEpic;
