// @flow
import React, {useEffect, useState} from 'react';
import Highcharts from 'highcharts';
import MetricName from 'metrics/components/metricName/MetricName';
import {addPlotLine, endLoad, pushSeries} from 'charts/timeSeries/services/timeSeriesHchartService';
import TimeSeriesWithEventsChart from 'charts/timeSeries/components/TimeSeriesWithEventsChart';
import UserEventsChartModal from 'charts/timeSeries/components/UserEventsChartModal';

type PropTypes = {
  disableActions: boolean,
  id: string, // required
  eventsMeta: Object,
  tooltipConditions: Object,
  height: Number,
  index: Number,
  enableClientZoom: boolean,
  theme: Object,
  tooltip: Object,
  bucketStartTimeEnabled: boolean,
  timeScale: string,
  timeZoneName: string,
  onRangeSelection: Function,
  onMouseMove: Function,
  onMouseLeave: Function,

  // for event chart
  events: Array,
  shouldShowEvents: boolean,
  eventsResolution: string,
  onEventClick: Function, // optional

  // must for events modal (used in chart too)
  fromDate: Number, // optional
  toDate: Number, // optional
  processedMetrics: Object,
  onEndLoad: Function, // optional

  onEventSelect: Function, // optional - for both the main chart and the modal
};

const MetricEventsChart = ({
  disableActions,
  id,
  eventsMeta,
  tooltipConditions,
  height,
  index,
  enableClientZoom,
  theme,
  tooltip,
  bucketStartTimeEnabled,
  timeScale,
  timeZoneName,
  onRangeSelection,
  onMouseMove,
  onMouseLeave,

  // for event chart
  onEventClick,
  events,
  shouldShowEvents,
  eventsResolution,

  // must for events modal (used in chart too)
  fromDate,
  toDate,
  processedMetrics,
  onEndLoad,

  onEventSelect,
}: PropTypes) => {
  const [isEventsModalOpen, setEventsModalOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [metricModalFirst, setMetricModalFirst] = useState(null);
  const chartModalId = 'events-modal-chart';

  const executeProcessMetric = (metrics, chartId) => {
    const hchart = Highcharts.charts.find((chart) => chart && chart.renderTo.id === chartId);

    if (eventsMeta.isSimulation) {
      metrics.series.forEach((item) => {
        pushSeries(hchart, {byTreeExp: []}, item.model, item.data, true, 'simulation');
      });

      if (metrics.yPlotLines) {
        metrics.yPlotLines.forEach((plotConfig) => addPlotLine(hchart, plotConfig));
      }

      endLoad(hchart, null, null);
    } else {
      metrics.series.forEach((item) => {
        pushSeries(hchart, {byTreeExp: []}, item.model, item.data, true);
      });

      endLoad(hchart, fromDate || null, toDate || null);
    }

    if (onEndLoad) {
      onEndLoad();
    }
  };

  useEffect(() => {
    if (processedMetrics && processedMetrics.series && processedMetrics.series.length) {
      executeProcessMetric(processedMetrics, eventsMeta.chartId);
    }
  }, [processedMetrics]);

  useEffect(() => {
    if (isEventsModalOpen && processedMetrics && processedMetrics.series && processedMetrics.series.length) {
      setMetricModalFirst(processedMetrics.series[0].model);
      executeProcessMetric(processedMetrics, chartModalId);
    }
  }, [processedMetrics, isEventsModalOpen]);

  const onEventClickHandler = (ev, e) => {
    setEventsModalOpen(true);
    setSelectedEventId(ev.id);
    if (onEventClick) {
      onEventClick(ev, e);
    }
    if (onEventSelect) {
      onEventSelect(ev);
    }
  };

  const onCloseEventsModal = () => {
    setEventsModalOpen(false);
    setSelectedEventId(null);
  };

  const onModalEventClick = (ev) => {
    setSelectedEventId(ev.id);
    if (onEventSelect) {
      onEventSelect(ev);
    }
  };

  return (
    <React.Fragment>
      <TimeSeriesWithEventsChart
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        disableActions={disableActions}
        id={id}
        eventsMeta={eventsMeta}
        events={events}
        shouldShowEvents={shouldShowEvents}
        eventsResolution={eventsResolution}
        index={index}
        tooltip={tooltip}
        height={height}
        bucketStartTimeEnabled={bucketStartTimeEnabled}
        timeScale={timeScale}
        timeZoneName={timeZoneName}
        theme={theme}
        enableClientZoom={enableClientZoom}
        tooltipConditions={tooltipConditions}
        onRangeSelection={onRangeSelection}
        onEventClick={onEventClickHandler}
      />
      {isEventsModalOpen && (
        <UserEventsChartModal
          isOpen={isEventsModalOpen}
          onClose={onCloseEventsModal}
          events={events}
          selectedEventId={selectedEventId}
          timeZoneName={timeZoneName}
          chartTitle={metricModalFirst ? <MetricName metric={metricModalFirst} highlightText={null} /> : null}
          chart={
            <React.Fragment>
              <TimeSeriesWithEventsChart
                disableActions={disableActions}
                id={chartModalId}
                eventsMeta={{
                  chartId: chartModalId,
                }}
                events={events}
                shouldShowEvents={shouldShowEvents}
                eventsResolution={eventsResolution}
                selectedEventId={selectedEventId}
                index={index}
                tooltip={tooltip}
                height={height}
                bucketStartTimeEnabled={bucketStartTimeEnabled}
                timeScale={timeScale}
                timeZoneName={timeZoneName}
                theme={theme}
                enableClientZoom={enableClientZoom}
                tooltipConditions={tooltipConditions}
                onEventClick={onModalEventClick}
              />
            </React.Fragment>
          }
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(MetricEventsChart);
