// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as selectors from 'metrics/store/selectors';
import {resolutionTypes} from 'metrics/services/metricsService';
import {reject} from 'lodash';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  rollup: String,
  onChange: Function,
  resolutions: Object,
  omitRollups: Array,
};

const EMPTY_ARRAY = [];

class TimeScaleConditionComponent extends PureComponent {
  props: PropTypes;

  render() {
    const {resolutions, omitRollups, rollup, onChange} = this.props;
    const resolutionTypesArr = reject(
      Object.values(resolutions),
      (r) => r.value === resolutionTypes.auto.value || !r.enabled || (omitRollups || EMPTY_ARRAY).includes(r.value),
    ).map((item) => ({...item, label: item.text.includes('Minute') ? item.text : item.text.slice(1)}));

    const hasMinutesRollup = resolutionTypesArr.map((item) => item.label).includes('Minute');

    return (
      <SelectAndt
        id="timeScaleFilter"
        automationId="timeScaleFilter"
        extraClassName="alerts-dropdown-btn"
        options={resolutionTypesArr}
        optionHeight={40}
        menuWidth={hasMinutesRollup ? 80 : 110}
        type={TYPE_NEW_NO_SEARCH}
        theme={THEME_NOT_HIGHLIGHTED}
        onChange={(item) => onChange(item.value)}
        value={resolutionTypesArr.find((item) => item.value === rollup)}
        placeholder="All"
      />
    );
  }
}

export default connect(
  (state) => ({
    resolutions: selectors.getMetricResolutions(state),
  }),
  {},
)(TimeScaleConditionComponent);
