/* eslint-disable */
import {ReactComponent as Analitic} from 'dashboards/images/optionsIcon/dashicon_analytics-graph-bar.svg';
import {ReactComponent as Baggage} from 'dashboards/images/optionsIcon/dashicon_baggage.svg';
import {ReactComponent as Parasol} from 'dashboards/images/optionsIcon/dashicon_beach-parasol-water-1.svg';
import {ReactComponent as Bike} from 'dashboards/images/optionsIcon/dashicon_biking-person.svg';
import {ReactComponent as Bin} from 'dashboards/images/optionsIcon/dashicon_bin-2.svg';
import {ReactComponent as Binocular} from 'dashboards/images/optionsIcon/dashicon_binocular.svg';
import {ReactComponent as Camera} from 'dashboards/images/optionsIcon/dashicon_camera-flash.svg';
import {ReactComponent as CoffeMachine} from 'dashboards/images/optionsIcon/dashicon_coffee-machine.svg';
import {ReactComponent as Cog} from 'dashboards/images/optionsIcon/dashicon_cog.svg';
import {ReactComponent as Ink} from 'dashboards/images/optionsIcon/dashicon_content-ink-pen-write.svg';
import {ReactComponent as Pen} from 'dashboards/images/optionsIcon/dashicon_content-pen-3.svg';
import {ReactComponent as TypingMachine} from 'dashboards/images/optionsIcon/dashicon_content-typing-machine.svg';
import {ReactComponent as CryptoMachine} from 'dashboards/images/optionsIcon/dashicon_crypto-currency-bitcoin-imac.svg';
import {ReactComponent as DatingRose} from 'dashboards/images/optionsIcon/dashicon_dating-rose.svg';
import {ReactComponent as ToolPen} from 'dashboards/images/optionsIcon/dashicon_design-tool-pen-station.svg';
import {ReactComponent as DesktopMonitor} from 'dashboards/images/optionsIcon/dashicon_desktop-monitor-upload.svg';
import {ReactComponent as ELearningMachine} from 'dashboards/images/optionsIcon/dashicon_e-learning-monitor.svg';
import {ReactComponent as EmailAction} from 'dashboards/images/optionsIcon/dashicon_email-action-receive.svg';
import {ReactComponent as FilterPicture} from 'dashboards/images/optionsIcon/dashicon_filter-picture.svg';
import {ReactComponent as Flag} from 'dashboards/images/optionsIcon/dashicon_flag.svg';
import {ReactComponent as Gauge} from 'dashboards/images/optionsIcon/dashicon_gauge-dashboard-1.svg';
import {ReactComponent as Golf} from 'dashboards/images/optionsIcon/dashicon_golf-hole.svg';
import {ReactComponent as GraphStats} from 'dashboards/images/optionsIcon/dashicon_graph-stats-circle.svg';
import {ReactComponent as Hammer} from 'dashboards/images/optionsIcon/dashicon_hammer-1.svg';
import {ReactComponent as Headphones} from 'dashboards/images/optionsIcon/dashicon_headphones-human.svg';
import {ReactComponent as Resources} from 'dashboards/images/optionsIcon/dashicon_human-resources-search-employees.svg';
import {ReactComponent as Insurance} from 'dashboards/images/optionsIcon/dashicon_insurance-card.svg';
import {ReactComponent as Flask} from 'dashboards/images/optionsIcon/dashicon_lab-flask-experiment.svg';
import {ReactComponent as Laptop} from 'dashboards/images/optionsIcon/dashicon_laptop-smiley-1.svg';
import {ReactComponent as Like} from 'dashboards/images/optionsIcon/dashicon_like-1.svg';
import {ReactComponent as Login2} from 'dashboards/images/optionsIcon/dashicon_login-2.svg';
import {ReactComponent as Maps} from 'dashboards/images/optionsIcon/dashicon_maps-pin.svg';
import {ReactComponent as Megaphone} from 'dashboards/images/optionsIcon/dashicon_megaphone-1.svg';
// eslint-disable-next-line max-len
import {ReactComponent as Default} from 'dashboards/images/optionsIcon/dashicon_default.svg';
import {ReactComponent as DefaultOld} from 'dashboards/images/optionsIcon/dashicon_default_old.svg';
import {ReactComponent as Messages} from 'dashboards/images/optionsIcon/dashicon_messages-people-person-bubble-oval.svg';
import {ReactComponent as MobilePhone} from 'dashboards/images/optionsIcon/dashicon_mobile-phone-2.svg';
import {ReactComponent as MoneyWallet} from 'dashboards/images/optionsIcon/dashicon_money-wallet-open.svg';
import {ReactComponent as Mouse} from 'dashboards/images/optionsIcon/dashicon_mouse.svg';
import {ReactComponent as Timer} from 'dashboards/images/optionsIcon/dashicon_optimization-timer-1.svg';
import {ReactComponent as Outdoors} from 'dashboards/images/optionsIcon/dashicon_outdoors-tree-valley.svg';
import {ReactComponent as Pencil} from 'dashboards/images/optionsIcon/dashicon_pencil-2.svg';
import {ReactComponent as PhoneActions} from 'dashboards/images/optionsIcon/dashicon_phone-actions-ring.svg';
import {ReactComponent as Monitor} from 'dashboards/images/optionsIcon/dashicon_pin-monitor.svg';
import {ReactComponent as Pin} from 'dashboards/images/optionsIcon/dashicon_pin.svg';
import {ReactComponent as Plane} from 'dashboards/images/optionsIcon/dashicon_plane-trip-international.svg';
import {ReactComponent as Presentation} from 'dashboards/images/optionsIcon/dashicon_presentation-board-graph.svg';
import {ReactComponent as Text} from 'dashboards/images/optionsIcon/dashicon_print-text.svg';
import {ReactComponent as Programming} from 'dashboards/images/optionsIcon/dashicon_programming-flag.svg';
import {ReactComponent as Science} from 'dashboards/images/optionsIcon/dashicon_science-dna.svg';
import {ReactComponent as Screen} from 'dashboards/images/optionsIcon/dashicon_screen-1.svg';
import {ReactComponent as Email} from 'dashboards/images/optionsIcon/dashicon_send-email-2.svg';
import {ReactComponent as Shipment} from 'dashboards/images/optionsIcon/dashicon_shipment-upload.svg';
import {ReactComponent as ShoppingBag} from 'dashboards/images/optionsIcon/dashicon_shopping-bag-heart.svg';
import {ReactComponent as ShoppingBagDownload} from 'dashboards/images/optionsIcon/dashicon_shopping-cart-download.svg';
import {ReactComponent as ShowHat} from 'dashboards/images/optionsIcon/dashicon_show-hat-magician-1.svg';
import {ReactComponent as Stamps} from 'dashboards/images/optionsIcon/dashicon_stamps-famous.svg';
import {ReactComponent as Tablet} from 'dashboards/images/optionsIcon/dashicon_tablet-touch.svg';
import {ReactComponent as Picture} from 'dashboards/images/optionsIcon/dashicon_taking-pictures-circle.svg';
import {ReactComponent as Target} from 'dashboards/images/optionsIcon/dashicon_target-center-2.svg';
import {ReactComponent as Video} from 'dashboards/images/optionsIcon/dashicon_video-game-gamasutra.svg';
import {ReactComponent as VideoPlayer} from 'dashboards/images/optionsIcon/dashicon_video-player-1.svg';
import {ReactComponent as VideoPlayerCloud} from 'dashboards/images/optionsIcon/dashicon_video-player-cloud.svg';
import {ReactComponent as VideoPlayerMonitor} from 'dashboards/images/optionsIcon/dashicon_video-player-monitor.svg';
import {ReactComponent as View} from 'dashboards/images/optionsIcon/dashicon_view.svg';
import {ReactComponent as Cart} from 'dashboards/images/optionsIcon/dashicon_warehouse-cart-packages.svg';
import {ReactComponent as Storage} from 'dashboards/images/optionsIcon/dashicon_warehouse-storage-3.svg';
import {ReactComponent as Wench} from 'dashboards/images/optionsIcon/dashicon_wench-double.svg';
import {ReactComponent as Card} from 'dashboards/images/optionsIcon/dashicon_wireless-payment-credit-card-dollar.svg';
import Highcharts from 'highcharts';

export const optionsIcons = [
  {
    label: 'Default',
    icon: Default,
    value: 'Default',
  },
  {
    label: 'DefaultOld',
    icon: DefaultOld,
    value: 'DefaultOld',
  },
  {
    label: 'Analitic',
    icon: Analitic,
    value: 'Analitic',
  },
  {
    label: 'Baggage',
    icon: Baggage,
    value: 'Baggage',
  },
  {
    label: 'Parasol',
    icon: Parasol,
    value: 'Parasol',
  },
  {
    label: 'Bike',
    icon: Bike,
    value: 'Bike',
  },
  {
    label: 'Bin',
    icon: Bin,
    value: 'Bin',
  },
  {
    label: 'Binocular',
    icon: Binocular,
    value: 'Binocular',
  },
  {
    label: 'Camera',
    icon: Camera,
    value: 'Camera',
  },
  {
    label: 'CoffeMachine',
    icon: CoffeMachine,
    value: 'CoffeMachine',
  },
  {
    label: 'Cog',
    icon: Cog,
    value: 'Cog',
  },
  {
    label: 'Ink',
    icon: Ink,
    value: 'Ink',
  },
  {
    label: 'Pen',
    icon: Pen,
    value: 'Pen',
  },
  {
    label: 'TypingMachine',
    icon: TypingMachine,
    value: 'TypingMachine',
  },
  {
    label: 'CryptoMachine',
    icon: CryptoMachine,
    value: 'CryptoMachine',
  },
  {
    label: 'DatingRose',
    icon: DatingRose,
    value: 'DatingRose',
  },
  {
    label: 'ToolPen',
    icon: ToolPen,
    value: 'ToolPen',
  },
  {
    label: 'DesktopMonitor',
    icon: DesktopMonitor,
    value: 'DesktopMonitor',
  },
  {
    label: 'ELearningMachine',
    icon: ELearningMachine,
    value: 'ELearningMachine',
  },
  {
    label: 'EmailAction',
    icon: EmailAction,
    value: 'EmailAction',
  },
  {
    label: 'FilterPicture',
    icon: FilterPicture,
    value: 'FilterPicture',
  },
  {
    label: 'Flag',
    icon: Flag,
    value: 'Flag',
  },
  {
    label: 'Gauge',
    icon: Gauge,
    value: 'Gauge',
  },
  {
    label: 'Golf',
    icon: Golf,
    value: 'Golf',
  },
  {
    label: 'GraphStats',
    icon: GraphStats,
    value: 'GraphStats',
  },
  {
    label: 'Hammer',
    icon: Hammer,
    value: 'Hammer',
  },
  {
    label: 'Headphones',
    icon: Headphones,
    value: 'Headphones',
  },
  {
    label: 'Resources',
    icon: Resources,
    value: 'Resources',
  },
  {
    label: 'Insurance',
    icon: Insurance,
    value: 'Insurance',
  },
  {
    label: 'Flask',
    icon: Flask,
    value: 'Flask',
  },
  {
    label: 'Laptop',
    icon: Laptop,
    value: 'Laptop',
  },
  {
    label: 'Like',
    icon: Like,
    value: 'Like',
  },
  {
    label: 'Login2',
    icon: Login2,
    value: 'Login2',
  },
  {
    label: 'Maps',
    icon: Maps,
    value: 'Maps',
  },
  {
    label: 'Megaphone',
    icon: Megaphone,
    value: 'Megaphone',
  },
  {
    label: 'Messages',
    icon: Messages,
    value: 'Messages',
  },
  {
    label: 'MobilePhone',
    icon: MobilePhone,
    value: 'MobilePhone',
  },
  {
    label: 'MoneyWallet',
    icon: MoneyWallet,
    value: 'MoneyWallet',
  },
  {
    label: 'Mouse',
    icon: Mouse,
    value: 'Mouse',
  },
  {
    label: 'Timer',
    icon: Timer,
    value: 'Timer',
  },
  {
    label: 'Outdoors',
    icon: Outdoors,
    value: 'Outdoors',
  },
  {
    label: 'Pencil',
    icon: Pencil,
    value: 'Pencil',
  },
  {
    label: 'PhoneActions',
    icon: PhoneActions,
    value: 'PhoneActions',
  },
  {
    label: 'Monitor',
    icon: Monitor,
    value: 'Monitor',
  },
  {
    label: 'Pin',
    icon: Pin,
    value: 'Pin',
  },
  {
    label: 'Plane',
    icon: Plane,
    value: 'Plane',
  },
  {
    label: 'Presentation',
    icon: Presentation,
    value: 'Presentation',
  },
  {
    label: 'Text',
    icon: Text,
    value: 'Text',
  },
  {
    label: 'Programming',
    icon: Programming,
    value: 'Programming',
  },
  {
    label: 'Science',
    icon: Science,
    value: 'Science',
  },
  {
    label: 'Screen',
    icon: Screen,
    value: 'Screen',
  },
  {
    label: 'Email',
    icon: Email,
    value: 'Email',
  },
  {
    label: 'Shipment',
    icon: Shipment,
    value: 'Shipment',
  },
  {
    label: 'ShoppingBag',
    icon: ShoppingBag,
    value: 'ShoppingBag',
  },
  {
    label: 'ShoppingBagDownload',
    icon: ShoppingBagDownload,
    value: 'ShoppingBagDownload',
  },
  {
    label: 'ShowHat',
    icon: ShowHat,
    value: 'ShowHat',
  },
  {
    label: 'Stamps',
    icon: Stamps,
    value: 'Stamps',
  },
  {
    label: 'Tablet',
    icon: Tablet,
    value: 'Tablet',
  },
  {
    label: 'Picture',
    icon: Picture,
    value: 'Picture',
  },
  {
    label: 'Target',
    icon: Target,
    value: 'Target',
  },
  {
    label: 'Video',
    icon: Video,
    value: 'Video',
  },
  {
    label: 'VideoPlayer',
    icon: VideoPlayer,
    value: 'VideoPlayer',
  },
  {
    label: 'VideoPlayerCloud',
    icon: VideoPlayerCloud,
    value: 'VideoPlayerCloud',
  },
  {
    label: 'VideoPlayerMonitor',
    icon: VideoPlayerMonitor,
    value: 'VideoPlayerMonitor',
  },
  {
    label: 'View',
    icon: View,
    value: 'View',
  },
  {
    label: 'Cart',
    icon: Cart,
    value: 'Cart',
  },
  {
    label: 'Storage',
    icon: Storage,
    value: 'Storage',
  },
  {
    label: 'Wench',
    icon: Wench,
    value: 'Wench',
  },
  {
    label: 'Card',
    icon: Card,
    value: 'Card',
  },
];

export const DEFAULT_PARAMS = {
  ownerUser: undefined,
  tags: undefined,
  q: undefined,
};

export const OPTION_TILES_VALUES = {
  SETTINGS: 'settings',
  CONVERT: 'convert',
  CREATE_TYPE: 'createType',
  REVERT: 'revert',
  DUPLICATE: 'duplicate',
  DELETE: 'delete',
  SHOW_LEGEND: 'legend',
  NEW: 'new',
  COPY: 'copy',
  FULL_SIZE: 'fullsize',
  SHARE: 'share',
  CREATE_ALERT: 'alert',
  IMPORT: 'import',
  SAVE_FILTERS: 'save',
  RESTORE_FILTERS: 'restore',
  EDIT_FILTERS: 'editFilters',
};

export const OPTIONS_FILTER_BUTTON = [
  {
    label: 'Save filters for this tile',
    value: OPTION_TILES_VALUES.SAVE_FILTERS,
  },
  {
    label: 'Edit tile filters',
    value: OPTION_TILES_VALUES.EDIT_FILTERS,
  },
  {
    label: 'Restore to dashboard defaults',
    value: OPTION_TILES_VALUES.RESTORE_FILTERS,
  },
];

export const dashboardActions = [
  {
    icon: 'icn-nav16-settings',
    value: OPTION_TILES_VALUES.SETTINGS,
    label: 'Settings',
  },
  {
    icon: 'icn-action16-copy',
    value: OPTION_TILES_VALUES.DUPLICATE,
    label: 'Duplicate Dashboard',
  },
  {
    icon: 'icn-general16-magic',
    value: OPTION_TILES_VALUES.CONVERT,
    label: 'Convert to New Dashboard',
  },
  {
    icon: 'icn-general16-refresh',
    value: OPTION_TILES_VALUES.REVERT,
    label: 'Revert to Old Dashboard',
  },
  {
    icon: 'icn-action16-download',
    value: OPTION_TILES_VALUES.IMPORT,
    label: 'Export dashboard',
  },
  {
    icon: 'icn-action16-delete',
    value: OPTION_TILES_VALUES.DELETE,
    label: 'Delete Dashboard',
  },
];

export const tileActions = [
  {
    icon: 'icn-action16-edit',
    value: OPTION_TILES_VALUES.SETTINGS,
    label: 'Edit Tile',
  },
  {
    icon: 'icn-general16-show',
    value: OPTION_TILES_VALUES.SHOW_LEGEND,
    label: 'Show Legend',
  },
  {
    icon: 'icn-action16-copy',
    value: OPTION_TILES_VALUES.DUPLICATE,
    label: 'Duplicate',
  },
  {
    icon: 'icn-nav24-alertconsole',
    value: OPTION_TILES_VALUES.CREATE_ALERT,
    label: 'Create Alert',
  },
  {
    icon: 'icn-action16-delete',
    value: OPTION_TILES_VALUES.DELETE,
    label: 'Delete Tile',
  },
];

export const optionsImport = [
  {
    label: 'Import dashboard from file',
    value: OPTION_TILES_VALUES.IMPORT,
  },
];

export const allowedUserTileActions = [OPTION_TILES_VALUES.DUPLICATE, OPTION_TILES_VALUES.CREATE_ALERT];

export const OPTION_SORTING_VALUES = {
  LAST_VIEWED: 'lastViewed',
  ALPHABETICAL: 'alphabetical',
  CREATION_DATE: 'creation date',
};

export const OPTION_MODE_VALUES = {
  NORMAL: 'normal',
  APPLY: 'apply',
};

export const optionsSortingDashboardsManagement = [
  {
    label: 'Last Viewed',
    value: OPTION_SORTING_VALUES.LAST_VIEWED,
  },
  {
    label: 'Alphabetical',
    value: OPTION_SORTING_VALUES.ALPHABETICAL,
  },
  {
    label: 'Creation Date',
    value: OPTION_SORTING_VALUES.CREATION_DATE,
  },
];

export const optionsMode = [
  {
    label: 'Normal',
    value: OPTION_MODE_VALUES.NORMAL,
  },
  {
    label: 'Apply',
    value: OPTION_MODE_VALUES.APPLY,
  },
];

export const OPTIONS_UNIT_TYPE_VALUES = {
  PERCENTAGE: 'percentage',
  USD: 'usd',
  EUR: 'eur',
  MS: 'ms',
  SEC: 'sec',
  AUTO: 'auto',
};

export const OPTIONS_UNIT_TYPE = [
  {
    label: 'Auto',
    value: OPTIONS_UNIT_TYPE_VALUES.AUTO,
    display: 'right',
  },
  {
    label: 'NUMBER',
    value: 'NUMBER',
    type: 'HEADER',
    headerClass: 'andt-dropdown-option-selected-signed-header',
  },
  {
    label: 'Percentage',
    value: OPTIONS_UNIT_TYPE_VALUES.PERCENTAGE,
    display: 'right',
  },
  {
    label: 'CURRENCY',
    value: 'CURRENCY',
    type: 'HEADER',
    headerClass: 'andt-dropdown-option-selected-signed-header',
  },
  {
    label: 'USD ($)',
    value: OPTIONS_UNIT_TYPE_VALUES.USD,
    display: 'left',
  },
  {
    label: 'EUR (€)',
    value: OPTIONS_UNIT_TYPE_VALUES.EUR,
    display: 'right',
  },
  {
    label: 'DURATION',
    value: 'DURATION',
    type: 'HEADER',
    headerClass: 'andt-dropdown-option-selected-signed-header',
  },
  {
    label: 'Milliseconds',
    value: OPTIONS_UNIT_TYPE_VALUES.MS,
    display: 'right',
  },
  {
    label: 'Seconds',
    value: OPTIONS_UNIT_TYPE_VALUES.SEC,
    display: 'right',
  },
];

export const OPTIONS_VALUE_TYPE_VALUES = {
  AVERAGE: 'average',
  CURRENT: 'current',
  MAX: 'maximum',
  MEDIAN: 'median',
  MIN: 'minimum',
  STD: 'std',
  SUM: 'sum',
};

export const OPTIONS_VALUE_TYPE = [
  {
    label: 'average',
    value: OPTIONS_VALUE_TYPE_VALUES.AVERAGE,
  },
  {
    label: 'sum',
    value: OPTIONS_VALUE_TYPE_VALUES.SUM,
  },
];

export const OPTIONS_SCALAR_TRANSFORM_TYPE = [
  {
    label: 'Last value',
    value: OPTIONS_VALUE_TYPE_VALUES.CURRENT,
  },
  {
    label: 'Sum',
    value: OPTIONS_VALUE_TYPE_VALUES.SUM,
  },
  {
    label: 'Average',
    value: OPTIONS_VALUE_TYPE_VALUES.AVERAGE,
  },
  {
    label: 'Maximum',
    value: OPTIONS_VALUE_TYPE_VALUES.MAX,
  },
  {
    label: 'Minimum',
    value: OPTIONS_VALUE_TYPE_VALUES.MIN,
  },
];

export const getUserName = (arrayUsers, id, isFull) => {
  const findUser = arrayUsers.find((user) => id === user._id) || {};
  if (findUser.firstName && findUser.lastName) {
    return `${findUser.firstName} ${isFull ? findUser.lastName : findUser.lastName[0]}${!isFull && '.'}`;
  }
  if (findUser.name) {
    return findUser.name;
  }
  if (findUser.label && findUser.type === 'USERS') {
    const result = findUser.label.split(' ');
    const surName = result.length >= 1 ? `${result[1][0].toUpperCase()}.` : '';
    return `${result[0]} ${surName}`;
  }
  if (findUser.label) {
    return findUser.label;
  }
  return '';
};

export const getUnitType = ({value}) => {
  switch (value) {
    case OPTIONS_UNIT_TYPE_VALUES.PERCENTAGE:
      return '%';
    case OPTIONS_UNIT_TYPE_VALUES.USD:
      return '$';
    case OPTIONS_UNIT_TYPE_VALUES.EUR:
      return '€';
    case OPTIONS_UNIT_TYPE_VALUES.MS:
      return 'ms';
    case OPTIONS_UNIT_TYPE_VALUES.SEC:
      return 's';
    default:
      return value !== OPTIONS_UNIT_TYPE_VALUES.AUTO ? value : '';
  }
};

export const DECIMAL_COUNT = 2;

export const createStatHChart = (id, dataPoints) =>
  Highcharts.chart(`container-${id}`, {
    chart: {
      type: 'area',
      spacing: [0, -5, 0, -5],
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
        },
        label: {
          enabled: false,
        },
        lineColor: '#2671ff',
        states: {
          hover: {
            enabled: false,
          },
        },
      },
      series: {
        fillColor: {
          linearGradient: [0, 0, 0, 300],
          stops: [[0, 'rgba(38, 113, 255, .25)'], [1, 'rgba(38, 113, 255, .05)']],
        },
      },
    },
    series: [
      {
        data: dataPoints,
      },
    ],
    xAxis: {
      labels: {
        enabled: false,
      },
      gridLineColor: 'transparent',
      visible: false,
      zoomEnabled: false,
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      title: {
        enabled: false,
      },
      visible: false,
      gridLineColor: 'transparent',
    },
  });

export const SIZE_TILES = {
  GRAPH_TILE: {
    sizeX: 4,
    sizeY: 4,
  },
  STAT_TILE: {
    sizeX: 2,
    sizeY: 2,
  },
  TEXT_TILE: {
    sizeX: 4,
    sizeY: 1,
  },
};
