// @flow
import React from 'react';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import moment from 'moment';
import {getCorrectTimezoneName} from 'common/utils/dateService';

import './TaskLogTable.module.scss';

type PropTypes = {
  titles: Array,
  tasks: Array,
  isLoading: Boolean,
  timeZone: String,
  onAction: Function,
};

export default class TaskLogTable extends React.PureComponent {
  props: PropTypes;

  renderTaskLog = (task) => {
    const {onAction, timeZone, titles} = this.props;
    const timeZoneName = getCorrectTimezoneName(timeZone);

    return (
      <table>
        <thead>
          <tr>
            {titles.map((t, index) => (
              <td key={t} styleName={index > 5 ? 'small' : ''}>
                <small>
                  <b>{t}</b>
                </small>
              </td>
            ))}
          </tr>
        </thead>

        <tbody>
          {task.map((item) => (
            <tr
              key={item.session_id}
              onClick={(e) => {
                e.stopPropagation();
                onAction(item);
              }}
            >
              <td className="ellipsis">{item.state_details}</td>
              <td className="ellipsis">{item.session_id}</td>
              <td className="ellipsis">
                {moment(item.start_time)
                  .tz(timeZoneName)
                  .format('YYYY-MM-DD HH:mm')}
              </td>
              <td className="ellipsis">
                {moment(item.next_time)
                  .tz(timeZoneName)
                  .format('YYYY-MM-DD HH:mm')}
              </td>
              <td className="ellipsis">
                {moment(item.finish_time)
                  .tz(timeZoneName)
                  .format('YYYY-MM-DD HH:mm')}
              </td>
              <td className="ellipsis">{item.assignee}</td>
              <td className="ellipsis" styleName="small">
                {item.initial_state}
              </td>
              <td className="ellipsis" styleName="small">
                {item.current_state}
              </td>
              <td className="ellipsis" styleName="small">
                {item.stream_status}
              </td>
              <td className="ellipsis" styleName="small">
                {item.result_status}
              </td>
              <td className="ellipsis" styleName="small">
                {!item.failure_details ? 'Null' : item.failure_details}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  render() {
    const {tasks, isLoading} = this.props;
    return isLoading ? (
      <Spinner color="#636363" size={SIZES.SMALL_30} />
    ) : (
      <div styleName="container">
        {tasks && tasks.length > 0 ? (
          this.renderTaskLog(tasks)
        ) : (
          <div>
            <b>No Tasks to Present</b>
            <br />
          </div>
        )}
      </div>
    );
  }
}
