import React, {Fragment} from 'react';
import {noop} from 'lodash';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import PageLayout from 'common/componentsV2/PageLayout';
import PageHeader from 'assets/components/header/Header';
import FiltersWrapper from 'assets/components/filter/FiltersWrapper';
import Table from 'assets/components/table/Table';

const useStyles = makeStyles(() => ({
  pageContainer: {
    flex: '1 1 0',
  },

  pageContentWrapper: {
    display: 'flex',
    flex: '1 0 0',
    flexDirection: 'column',
  },

  main: {
    display: 'flex',
    flex: '1 1 0',
  },
}));

const AssetsManagement = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Box className={classes.pageContainer}>
        <PageLayout header={<PageHeader />}>
          <Box className={classes.pageContentWrapper}>
            <Box className={classes.main}>
              <FiltersWrapper setQueryParams={noop} />
              <Table />
            </Box>
          </Box>
        </PageLayout>
      </Box>
    </Fragment>
  );
};

export default AssetsManagement;
