// @flow
/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {BooleanParam, StringParam, useQueryParams} from 'use-query-params';
import {getAlertEditorV2Enabled} from 'alerts.management/store/selectors';
import {getImpactEnabled, getInvestigationV2Enabled, getIsImpersonate} from 'profile/store/selectors';
import {segmentClickEvent, setRoute} from 'common/store/actions';
import './MoreDdlActions.module.scss';
import {getRoutingLocation} from 'common/store/selectors';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import {modalRouting as investigationRouting} from "investigation/services/investigationService";
import {modalRouting1 as impactRouting1, modalRouting2 as impactRouting2} from 'impact/services/constants';

type PropTypes = {
  id: string,
  triggerId: string,
  alertConfigurationId: string,
  anomalyId: string,
  timeScale: string,
  impact: Object,
  isImpactEligible: boolean,
  origin: Object,
  measure: String,
};

const MoreDdlActions = ({triggerId, anomalyId, impact, origin, measure,alertConfigurationId, timeScale, id, isImpactEligible}: PropTypes) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const routingLocation = useSelector(getRoutingLocation);
  const isAlertEditorV2Enabled = useSelector(getAlertEditorV2Enabled);
  const isImpactEnabled = useSelector(getImpactEnabled);
  const isInvestigationV2Enabled = useSelector(getInvestigationV2Enabled);
  const isImpersonating = useSelector(getIsImpersonate);

  const [, setQueryParams] = useQueryParams({
    [investigationRouting.ANOMALY_ID]: StringParam,
    [investigationRouting.TRIGGER_ID]: StringParam,
    [investigationRouting.INVESTIGATION_MODAL]: BooleanParam,

    [impactRouting1.IMPACT_MODAL]: BooleanParam,
    [impactRouting1.ORIGIN_ID]: StringParam,
    [impactRouting1.ORIGIN_TYPE]: StringParam,
    [impactRouting1.ALERT_TRIGGERED_ID]: StringParam,
    [impactRouting1.MEASURE]: StringParam,
    [impactRouting2.IMPACT_ID]: StringParam,
  });

  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    actionItemSelected();
    if (value) {
      dispatch(
        segmentClickEvent({
          type: 'click',
          name: value,
        }),
      );
    }
  }, [value]);

  useEffect(() => {
    setOptions(getSelectOptions());
  }, []);

  const setInvestigationUrl = () => {
    setQueryParams({
      [investigationRouting.ANOMALY_ID]: anomalyId,
      [investigationRouting.TRIGGER_ID]: triggerId,
      [investigationRouting.INVESTIGATION_MODAL]: true,
    });
  };

  const setImpactUrl = () => {
    setQueryParams({
      [impactRouting1.IMPACT_MODAL]: true,
      [impactRouting1.ALERT_TRIGGERED_ID]: triggerId,
      [impactRouting1.ORIGIN_ID]: origin.id,
      [impactRouting1.ORIGIN_TYPE]: origin.type,
      [impactRouting1.MEASURE]: !impact ? measure : undefined,
      [impactRouting2.IMPACT_ID]: impact ? impact.impactId : undefined,
    });
  };

  const actionItemSelected = () => {
    switch (value) {
      case 'tuneAlert':
        if (isAlertEditorV2Enabled) {
          history.push(
            `/alert-manager/edit/${alertConfigurationId}/settings?backUrl=${history.location.pathname}${history.location.search}`,
          );
        } else {
          window.open(`/#!/alert/${alertConfigurationId}`, `_newtab-${alertConfigurationId}`);
        }
        break;
      case 'investigate':
        window.open(
          /*  eslint max-len: ["error", { "ignoreTemplateLiterals": true }] */
          `/#!/anomalies?tabs=main;0&activeTab=1&anomalies=;0(${anomalyId})&duration=;1(1)&durationScale=;minutes(minutes)&delta=;1(1)&deltaType=;percentage(percentage)&resolution=;${timeScale}(${timeScale})&score=;0(0)&state=;both(both)&direction=;both(both)&alertId=;(${triggerId})&sort=;significance(significance)&q=;()&constRange=;1h(c)&startDate=;0(0)&endDate=;0(0)`,
          '_newtab',
        );
        break;
      case 'setImpact':
        setImpactUrl();
        break;
      case 'investigateV2':
        setInvestigationUrl(anomalyId, triggerId);
        break;
      default:
        break;
    }
  };

  const getSelectOptions = () => {
    const ret = [];


    if (anomalyId) {
      ret.push({value: 'investigate', label: 'Investigate'});
      if (isInvestigationV2Enabled || isImpersonating) {
        ret.push({value: 'investigateV2', label: 'Triage'});
      }
    }
    if (isImpactEnabled && isImpactEligible) {
      ret.push({value: 'setImpact', label: 'Set Impact'});
    }

    ret.push({value: 'tuneAlert', label: 'Edit Alert'});
    return ret;
  };

  return (
    <div styleName="more-action-wrapper" role="presentation" onClick={(e) => e.stopPropagation()}>
      <FormDdlSelect
        automationId={`more-actions-ddl-${id}`}
        position="right"
        width={135}
        options={options}
        selected={value}
        button={<div styleName="button-like-dropdown" className="icon icn-icon-3-dots" />}
        optionComponent={<OptionComponentSimple />}
        onChange={(e) => setValue(e.value)}
      />
    </div>
  );
};

export default MoreDdlActions;
