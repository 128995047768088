// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {
  setKinesisStreamUiKeyVal as setKinesisStreamUiKeyValAction,
  applyKinesisStreamFilters as applyKinesisStreamFiltersAction,
} from 'bc/store/actions';
import {
  KINESIS_FILTER_TYPES,
  KINESIS_RECORD_TYPES,
  convertKinesisStreamFiltersToUiFilters,
  isKinesisCollectEnabled,
} from 'bc/services/kinesisService';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import {reduceArrayItem} from 'common/utils/reducers';
import {get} from 'lodash';
import './KinesisStreamEditor.module.scss';
import SelectAndt, {THEME_LIGHT, TYPE_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {
  isStreamEditable as isStreamEditableService,
  isStreamRunning as isStreamRunningService,
} from 'bc/services/dataStreamService';

const reducerHelper = (item, payload) => ({...item, ...payload});

type PropTypes = {
  dataStream: Object,
  paths: Array,

  setKinesisStreamUiKeyVal: Function,
  applyKinesisStreamFilters: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    paths: selectors.getKinesisPathsIncludeCustom(state),
  }),
  {
    setKinesisStreamUiKeyVal: setKinesisStreamUiKeyValAction,
    applyKinesisStreamFilters: applyKinesisStreamFiltersAction,
  },
)
export default class KinesisAdvanceFiltering extends React.PureComponent {
  props: PropTypes;

  state = {
    filters: get(this.props.dataStream, 'uiState.uiFilters', null) ||
      convertKinesisStreamFiltersToUiFilters(this.props.dataStream.filters) || [
        {path: '', selectedValue: '', isInclude: true},
      ],
  };

  isEditable = () => {
    const {
      dataStream: {state, uiState},
    } = this.props;
    const recordType = get(uiState, 'recordType', '');
    const isDelimiter =
      recordType === KINESIS_RECORD_TYPES[1].value ? !!get(uiState, 'recordTypeDelimiter', false) : true;

    return (isStreamEditableService(state) || isStreamRunningService(state)) && recordType && isDelimiter;
  };

  filterChanged = (res) => {
    const f = {...this.state.filters[res.index]};
    switch (res.target) {
      case 'filterType':
        f.isInclude = res.value === 'in';
        break;
      default:
        f[res.target] = res.value;
        break;
    }

    this.setState((prevState) => {
      const filters = reduceArrayItem(reducerHelper, prevState.filters, res.index, f);
      this.props.setKinesisStreamUiKeyVal({
        uiFilters: filters,
      });
      return {
        filters,
      };
    });
  };

  removeFilter = (index) => {
    if (this.state.filters.length === 1 && index === 0) {
      this.setState((prevState) => {
        prevState.filters.splice(index, 1);
        const filters = [...prevState.filters, {path: '', selectedValue: '', isInclude: true}];
        this.props.setKinesisStreamUiKeyVal({
          uiFilters: filters,
        });
        return {
          filters,
        };
      });
    } else {
      this.setState((prevState) => {
        prevState.filters.splice(index, 1);
        const filters = [...prevState.filters];
        this.props.setKinesisStreamUiKeyVal({
          uiFilters: filters,
        });
        return {
          filters,
        };
      });
    }
  };

  addPathFilter = () => {
    this.setState((prevState) => {
      const filters = [...prevState.filters, {path: '', selectedValue: '', isInclude: true}];
      this.props.setKinesisStreamUiKeyVal({
        uiFilters: filters,
      });
      return {
        filters: [...prevState.filters, {path: '', selectedValue: '', isInclude: true}],
      };
    });
  };

  goClicked = () => {
    this.props.applyKinesisStreamFilters();
  };

  isGoDisabled = () => {
    const {dataStream} = this.props;

    return (
      (!get(dataStream, 'uiState.recordTypeDelimiter', null) &&
        get(dataStream, 'uiState.recordType', null) === KINESIS_RECORD_TYPES[1].value) ||
      isKinesisCollectEnabled(dataStream)
    );
  };

  render() {
    const {filters} = this.state;
    const isEditable = this.isEditable();

    return (
      <div className="shell-col" styleName={['advance-root', isEditable ? '' : 'disabled'].join(' ')}>
        <PanelHeader title="Stream Filters" isEditable />

        {filters.map((filter, i) => (
          <FilterItem
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            index={i}
            disabled={!isEditable}
            filter={filter}
            paths={this.props.paths}
            onChange={this.filterChanged}
            onRemove={this.removeFilter}
          />
        ))}

        <div styleName="actions-wrapper">
          <div styleName="add-path-wrapper" onClick={this.addPathFilter}>
            <i className="icon icn-icon-blue-plus" />
            <span>Add Another Path Filter</span>
          </div>
          <button type="button" className="btn btn-raised" disabled={this.isGoDisabled()} onClick={this.goClicked}>
            Collect
          </button>
        </div>
      </div>
    );
  }
}

const FilterItem = (props: {
  filter: Object,
  onChange: Function,
  onRemove: Function,
  index: number,
  disabled: boolean,
  paths: Array,
}) => {
  const inputPathChanged = (path) => {
    props.onChange({value: path.value, target: 'path', index: props.index});
  };

  const inputValueChanged = (e) => {
    props.onChange({value: e.target.value, target: 'selectedValue', index: props.index});
  };

  const filterChanged = (f) => {
    props.onChange({value: f.value, target: 'filterType', index: props.index});
  };

  const removeItem = () => {
    props.onRemove(props.index);
  };

  const selectedPath =
    props.paths.find((path) => path.value === get(props.filter, 'path', '')) || get(props.filter, 'path', '');

  return (
    <div styleName="kinesis-select-block">
      <i styleName="remove-item" className="icon icn-icon-table-delete" onClick={removeItem} />
      <div styleName="path-wrapper">
        <SelectAndt
          type={TYPE_SEARCH}
          theme={THEME_LIGHT}
          onChange={inputPathChanged}
          options={props.paths}
          value={selectedPath}
          placeholder={null}
          isCreatable
          automationId="ksFilterPath"
          optionHeight={40}
        />
      </div>
      <div styleName="values-wrapper">
        <div styleName="operator-wrapper">
          <span className="ellipsis" styleName="input-title">
            Operator
          </span>
          <SelectAndt
            type={TYPE_SEARCH}
            theme={THEME_LIGHT}
            onChange={filterChanged}
            options={KINESIS_FILTER_TYPES}
            value={props.filter.isInclude ? KINESIS_FILTER_TYPES[0] : KINESIS_FILTER_TYPES[1]}
            placeholder=""
            optionHeight={40}
            disabled={props.disabled}
            getOptionLabel={(val) => val.name}
            getOptionValue={(val) => val.value}
            automationId="ksFilterType"
          />
        </div>
        <div styleName="value-wrapper">
          <span className="ellipsis" styleName="input-title">
            Value
          </span>
          <input
            type="text"
            disabled={props.disabled}
            onChange={inputValueChanged}
            placeholder="separate multiple values with commas"
            value={get(props.filter, 'selectedValue', '')}
          />
        </div>
      </div>
      <div styleName="seperator" />
    </div>
  );
};
