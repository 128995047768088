// @flow
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as commonSelectors from 'profile/store/selectors';
import {setStar as setStarAction} from 'alerts.console/store/actions';
import {segmentClickEvent} from 'common/store/actions';
import SmartTooltip from 'common/componentsV2/Tooltip';
import './Acknowledge.module.scss';

type PropTypes = {
  starredBy: Array<string>,
  groupId: string,
  alertId: string,
  uiGroupName: String,

  // connect
  meId: Function,
  isReadOnlyUser: boolean,
  setStar: Function,
  segmentClickEvent: Function,
};

@connect(
  (state) => ({
    meId: commonSelectors.getProfileId(state),
    isReadOnlyUser: commonSelectors.isReadOnlyUser(state),
  }),
  {
    setStar: setStarAction,
    segmentClickEvent,
  },
)
export default class StarAction extends Component {
  props: PropTypes;

  checkIfAckByMe = (starredBy) => starredBy.some((i) => i.id === this.props.meId);

  ackClicked = (ev) => {
    ev.preventDefault();
    const {starredBy, uiGroupName, groupId, alertId, isReadOnlyUser, meId, setStar} = this.props;
    const ackByMe = this.checkIfAckByMe(starredBy);

    if (isReadOnlyUser) return;

    this.props.segmentClickEvent({
      type: 'click',
      name: 'acknowledge alert',
    });
    const payload = {
      isStarred: !ackByMe,
      uiGroupName,
      groupId,
      meId,
      alertId,
    };
    setStar(payload, payload);
  };

  render() {
    const {starredBy} = this.props;
    const isAckByMe = this.checkIfAckByMe(starredBy);

    return (
      <SmartTooltip placement="top" content={isAckByMe ? 'Remove Acknowledge' : 'Acknowledge'} delay={400}>
        <div onClick={this.ackClicked} styleName="ack-action" role="button">
          <i styleName="btn-icon" className="icon icn-action16-acknowledge" />
        </div>
      </SmartTooltip>
    );
  }
}
