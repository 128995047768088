// @flow
import React from 'react';
import {makeStyles} from '@material-ui/core';

type PropsType = {
  children: Node,
  type: string,
  isActive: boolean,
};
export const BOTTOM_PANEL_TYPE = {
  white: 'white',
  blue: 'blue',
};

const useStyles = makeStyles((theme) => ({
  white: {
    backgroundColor: theme.palette.white[500],
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 0,
    right: 0,
    left: 60,
    height: 70,
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
    '&:before': {
      content: "''",
      display: 'block',
      position: 'fixed',
      left: 60,
      bottom: 0,
      width: 20,
      height: 70,
      boxShadow: '0 0 10px 4px rgba(255, 255, 255, 0.9)',
    },
  },
  blue: {
    display: 'flex',
    alignItems: 'center',
    height: 0,
    overflow: 'hidden',
    backgroundColor: theme.palette.blue[100],
    transition: 'all 0.3s ease-in-out',
    zIndex: 1,
    borderTop: 0,
    '& > div': {
      height: 0,
    },
    '&:before': {
      content: '""',
      position: 'fixed',
      left: '60px',
      display: 'block',
      height: '72px',
      width: '34px',
      backgroundImage: `linear-gradient(to right, ${theme.palette.white[500]},
        ${theme.palette.white[500]} 8%,
        rgba(255, 255, 255, 0))`,
    },
  },
  isActive: {
    height: 70,
    borderTop: `2px solid ${theme.palette.blue[300]}`,
    '& > div': {
      height: 0,
    },
  },
}));

const BottomPanel = ({children, type, isActive}: PropsType) => {
  const classes = useStyles();
  return (
    <div
      className={`
      ${type === BOTTOM_PANEL_TYPE.white ? classes.white : classes.blue}
      ${isActive ? classes.isActive : ''}
    `}
    >
      {children}
    </div>
  );
};

export default BottomPanel;
