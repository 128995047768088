// @flow
import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import * as commonSelectors from 'profile/store/selectors';
import Loader from 'common/components/Loader';
import NoData from 'common/components/NoData';
import {TransitionGroup} from 'react-transition-group';
import {getTypeDetails} from 'bc/services/bcTypes';
import {abortFileUpload} from 'bc/services/fileUploadToAwsService';
import {fetchDataSources, fetchDataSourceTypes, setSelectedDataSource} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import ListItem from './SourceListItem';
import './SourcesList.module.scss';
import StreamsLimitModal from './StreamsLimitModal';
import TypographyBox from '../../../common/componentsV2/boxTools/TypographyBox';

type PropTypes = {
  selectedDataSource: string,
  dataSources: Array,
  sourceItemsCount: number,
  isLoading: boolean,
  isLoadingSourceTypes: boolean,
  isBcReadOnly: boolean,
  filterText: String,
  fetchDataSources: Function,
  fetchDataSourceTypes: Function,
  setSelectedDataSource: Function,
  setFileUploadName: Function,
  newStreamsCount: Number,
  incompleteSteamsLimit: Number,
};

@connect(
  (state) => ({
    selectedDataSource: selectors.getSelectedDataSource(state),
    dataSources: selectors.getDataManagerFilteredSourcesAndLookups(state),
    sourceItemsCount: selectors.getDataSourcesItemsNoLookup(state).length,
    newStreamsCount: selectors.getNewStreamsCount(state),
    incompleteSteamsLimit: commonSelectors.getIncompleteSteamsLimit(state),
    isLoading: selectors.getDataSources(state).sources.isLoading,
    isLoadingSourceTypes: selectors.getDataSourceTypes(state).types.isLoading,
    filterText: selectors.getDataManagerFilterText(state),
    isBcReadOnly: selectors.isBcReadOnlyPermision(state),
  }),
  {
    fetchDataSources,
    fetchDataSourceTypes,
    setSelectedDataSource,
  },
)
export default class SourcesList extends React.PureComponent {
  props: PropTypes;

  state = {
    isLimitModalOpen: false,
  };

  componentDidMount() {
    this.props.fetchDataSources();
    this.props.fetchDataSourceTypes();
  }

  componentDidUpdate() {
    if (this.props.isLoading === false && this.props.isLoadingSourceTypes === false) {
      if (
        this.props.dataSources.length === 1 &&
        this.props.dataSources[0].items.length === 1 &&
        !this.props.selectedDataSource &&
        !this.props.filterText.length
      ) {
        if (this.props.dataSources[0].type !== 'lookup') {
          this.props.setSelectedDataSource(this.props.dataSources[0].items[0].id);
        }
      }
    }
  }

  closeProgressModal = () => {
    this.props.setFileUploadName('');
  };

  abortUpload = () => {
    abortFileUpload();
  };

  openStreamsListModal = () => {
    this.setState({isLimitModalOpen: true});
  };

  closeStreamsListModal = () => {
    this.setState({isLimitModalOpen: false});
  };

  render() {
    const {
      isLoadingSourceTypes,
      isLoading,
      isBcReadOnly,
      dataSources,
      sourceItemsCount,
      newStreamsCount,
      incompleteSteamsLimit,
    } = this.props;

    if (isLoading !== false && !dataSources.length) {
      return <Loader styleName="loader-pos" size="small" />;
    }
    return (
      <div styleName="root">
        <div
          styleName={isBcReadOnly ? 'section-header-wrapper disabled' : 'section-header-wrapper'}
          data-segment="name: open-collectors, category: data-collectors"
        >
          {newStreamsCount >= incompleteSteamsLimit ? (
            <button
              className={`btn btn-flat btn-primary andt-section-header ${
                isLoadingSourceTypes !== false || isBcReadOnly ? 'disabled' : ''
              }`}
              styleName="section-header-btn"
              onClick={this.openStreamsListModal}
              type="button"
            >
              Sources
              <i className="icon icn-icon-blue-plus" automation-id="dataCollectorNewStreamButton" />
            </button>
          ) : (
            <Link
              to="/bc/sources-types"
              className="btn btn-flat btn-primary andt-section-header"
              styleName="section-header-btn"
              disabled={isLoadingSourceTypes !== false || isBcReadOnly}
            >
              Sources{' '}
              <i
                className="icon icn-icon-blue-plus"
                styleName="section-header-btn-icon"
                automation-id="dataManagerNewSourceButton"
              />
            </Link>
          )}
        </div>
        {sourceItemsCount === 0 && <NoSources />}
        {dataSources.length !== 0 ? (
          <React.Fragment>
            {sourceItemsCount === 0 && (
              <div styleName="section-list-wrapper">
                <NoDataComponent sourceItemsCount={sourceItemsCount} />
              </div>
            )}
            <List dataSources={dataSources} />
          </React.Fragment>
        ) : (
          <div styleName="section-list-wrapper">
            <NoDataComponent sourceItemsCount={sourceItemsCount} />
          </div>
        )}
        {this.state.isLimitModalOpen ? (
          <StreamsLimitModal
            onClose={this.closeStreamsListModal}
            scenario="incomplete"
            isOpen={this.state.isLimitModalOpen}
          />
        ) : null}
      </div>
    );
  }
}

const List = (props: {dataSources: Array}) => {
  const {dataSources} = props;
  return (
    <div className="shell-col" styleName="list">
      {dataSources.map((sourceGroup) => (
        <div styleName="section-list-wrapper" key={sourceGroup.type}>
          <div styleName="list-header">{getTypeDetails(sourceGroup.type).name}</div>
          <ul automation-id={`dataManagerSource${sourceGroup.type}List`}>
            <TransitionGroup>
              {sourceGroup.items.map((ds) => (
                <ListItem key={ds.id} source={ds} />
              ))}
            </TransitionGroup>
          </ul>
        </div>
      ))}
    </div>
  );
};

const NoSources = () => <TypographyBox variant="subtitle2">Press the plus to create a new source</TypographyBox>;

const NoDataComponent = (props: {sourceItemsCount: Number}) => {
  let title = 'No Sources To Show';
  let text = 'Refine the filters to view streams';
  let isNoStreamImage = false;
  if (props.sourceItemsCount === 0) {
    title = 'Add a Source';
    text = 'To start streaming data into Anodot';
    isNoStreamImage = true;
  }
  return (
    <NoData
      styleName="no-data-pos"
      title={title}
      text={text}
      textStyle="noSourceText"
      isNoStreamImage={isNoStreamImage}
    />
  );
};
