// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import MetricName from 'metrics/components/metricName/MetricName';
import * as commonSelectors from 'profile/store/selectors';
import * as metricsSelectors from 'metrics/store/selectors';
import * as alertConsole from 'alerts.console/store/selectors';
import TimeSeriesChart from 'charts/timeSeries/components/TimeSeriesChart';
import {fetchAlertMetricDataPoints as fetchAlertMetricDataPointsAction} from 'alerts.console/store/actions';
import {CHART_HEIGHT, ANOMALY_BUFFER_TIME} from 'alerts.console/services/alertsConsoleService';
import ImpactChip from 'impact/ImpactChip';
import {themesOptions} from 'impact/impactTheme';
import AnomalyAlertDelta from './AnomalyAlertDelta';
import '../alertContent.module.scss';

let counter = 0;

const TOOLTIP_OPTIONS = {
  showMetricName: false,
  showAnomalyData: true,
  hideMeetIcons: true,
};

type PropTypes = {
  metric: Object,
  anomalyId: string,
  alertId: string,
  uiGroupName: string,
  startDate: number,
  endDate: number,
  timeScale: string,
  queryParamsViews: Object,
  isSingleLine: boolean,
  specialHeader: boolean,

  // connect
  bucketStartTimeEnabled: Boolean,
  timeZoneName: String,
  resolutions: Object,
  startBucketMode: boolean,
  fetchAlertMetricDataPoints: Function,
};

@connect(
  (state) => ({
    resolutions: metricsSelectors.getMetricResolutions(state),
    startBucketMode: commonSelectors.getBucketStartTimeEnabled(state),
    queryParamsViews: alertConsole.getQueryParamsViews(state),
    timeZoneName: commonSelectors.getTimeZoneName(state),
    bucketStartTimeEnabled: commonSelectors.getBucketStartTimeEnabled(state),
  }),
  {
    fetchAlertMetricDataPoints: fetchAlertMetricDataPointsAction,
  },
)
export default class AnomalyAlertMetric extends PureComponent {
  props: PropTypes;

  constructor(props) {
    super(props);
    const {metric, anomalyId, alertId, uiGroupName} = this.props;
    this.id = `console.anomaly.chart_${(counter += 1)}`;
    this.chartEventsMeta = {
      key: `${anomalyId}_${alertId}_${metric.id}`, // unique ID for chart
      chartId: this.id,
      stateGroup: uiGroupName,
    };
  }

  componentDidMount() {
    // use didMount: ensures chart was created
    const {fetchAlertMetricDataPoints, metric, alertId, timeScale, startDate, endDate, startBucketMode} = this.props;
    const bufferTime = ANOMALY_BUFFER_TIME[timeScale] || 0;

    fetchAlertMetricDataPoints(
      {
        metricId: metric.id,
        endDate: endDate + bufferTime,
        startDate,
        startBucketMode,
        includeBaseline: true,
        timeScale: Object.values(this.props.resolutions).find((a) => a.value2 === timeScale).value,
      },
      {
        ...this.chartEventsMeta,
        metricId: metric.id,
        intervals: metric.intervals,
        alertId,
      },
    );
  }

  render() {
    const {
      metric,
      queryParamsViews,
      timeScale,
      timeZoneName,
      bucketStartTimeEnabled,
      isSingleLine,
      specialHeader,
    } = this.props;

    return (
      <div styleName="tile">
        <div styleName={['header', specialHeader ? 'extra-line' : ''].join(' ')}>
          <div styleName="header-row">
            <MetricName isSingleLine={isSingleLine} metric={metric} highlightText={queryParamsViews.searchQuery} />
          </div>

          <div styleName="header-row">
            <div styleName="row-left">
              <AnomalyAlertDelta metric={metric} />
              {metric.impact && (
                <ImpactChip
                  impact={metric.impact}
                  themeName={themesOptions.tiny}
                  tooltipContent="Impact for this Metric"
                  text="Impact:"
                  icon
                />
              )}
            </div>
          </div>
        </div>

        <div styleName={['chart', specialHeader ? 'special-height' : ''].join(' ')} id="chartWrapper">
          <TimeSeriesChart
            id={this.id}
            eventsMeta={this.chartEventsMeta}
            tooltip={TOOLTIP_OPTIONS}
            height={CHART_HEIGHT}
            bucketStartTimeEnabled={bucketStartTimeEnabled}
            timeScale={timeScale}
            timeZoneName={timeZoneName}
            enableClientZoom
          />
        </div>
      </div>
    );
  }
}
