// @flow
import React from 'react';
import {connect} from 'react-redux';
import HeaderRow from 'common/componentsV2/accordion/HeaderRow';
import OuterRow from 'common/componentsV2/accordion/OuterRow';
import * as actions from 'admin.cs-portal/store/actions';
import * as selectors from 'admin.cs-portal/store/selectors';

import './ErrorsView.module.scss';

type PropTypes = {
  areCompositeErrorsLoading: Boolean,
  compositeErrors: Array,
  compositeErrorMessages: Array,
};

@connect(
  (state) => ({
    selectedCustomer: selectors.getSelectedCustomer(state),
    areCompositeErrorsLoading: selectors.getAreCompositeErrorsLoading(state),
    compositeErrors: selectors.getLeanCompositeErrors(state),
    compositeErrorMessages: selectors.getCompositeErrorMessages(state),
  }),
  {
    fetchCompositeErrors: actions.fetchCompositeErrors,
  },
)
export default class CompositeErrors extends React.PureComponent {
  props: PropTypes;

  renderCompositeErrors = () => {
    const {compositeErrors, compositeErrorMessages} = this.props;
    const err = [];
    compositeErrors.forEach((composite, index) =>
      err.push(
        <OuterRow
          key={composite.composite_id}
          titles={[composite.composite_title, composite.composite_message, composite.total_errors]}
          isOpen={false}
          canOpen={false}
          leftAlignLastItem
          showTooltip
          tooltip={[null, null, this.makeTooltip(compositeErrorMessages[index])]}
          tooltipIndices={[2]}
        />,
      ),
    );
    return (
      <div key={compositeErrors.length} style={{maxHeight: '30vh', overflowY: 'auto'}}>
        <HeaderRow titles={['Name', 'Error Message', 'Total Errors']} leftAlignLastItem />
        {err}
      </div>
    );
  };

  makeTooltip = (messages) => {
    const err = [];
    Object.keys(messages).forEach((message, index) => {
      if (messages[message] === 1) {
        // eslint-disable-next-line react/no-array-index-key
        err.push(<p key={index + message}>{message}</p>);
      } else {
        err.push(
          // eslint-disable-next-line react/no-array-index-key
          <p key={index + message}>
            {message} ({messages[message]}
            x)
          </p>,
        );
      }
    });
    return <div style={{textAlign: 'left'}}>{err}</div>;
  };

  render() {
    const {areCompositeErrorsLoading, compositeErrors} = this.props;
    return (
      <div styleName="container">
        <header styleName="section-header">
          <span styleName="title">
            Composite Errors ({!areCompositeErrorsLoading && compositeErrors ? compositeErrors.length : 0})
          </span>
        </header>
        {this.renderCompositeErrors()}
      </div>
    );
  }
}
