import React, {useCallback, useEffect} from 'react';
import {emptyExpressionTree} from 'metrics/store/reducers/expressionBuilderReducer';
import CompositeBuilderActions from 'alerts.management/components/editor/simulationArea/conditions/CompositeBuilderActions';
import {useField} from 'react-final-form';
import {cloneDeep, get, omit} from 'lodash';
import SimpleExpressionContainer from 'dashboards/components/simpleExpression/SimpleExpressionContainer';
import {resetExpressionBuilder} from 'alerts.management/store/actions';
import Tabs from 'common/componentsV2/Tabs';
import {useDispatch, useSelector} from 'react-redux';
import {getExpressionTree, getExpressionTrees} from 'metrics/store/selectors';
import {StringParam, useQueryParam} from 'use-query-params';
import {
  createNewExpression,
  initExpressionTreeModel,
  selectTreeBranch,
  setAllExpressionsSelected,
} from 'metrics/store/actions';
import CompositeBuildersContainer from 'metrics/components/metricExplorer/CompositeBuildersContainer';
import useDeepCompareEffect from 'common/utils/useDeepCompareEffect';
import Button, {COLORS, HEIGHTS} from 'common/componentsV2/Button';

const tabs = [
  {
    label: 'Simple',
    value: 'simple',
  },
  {
    label: 'Advanced',
    value: 'advanced',
  },
];

type PropTypes = {
  isRatioEnabled: boolean,
  isGroupByEnabled: boolean,
  isSeriesFunctionEnabled: boolean,
  isMultipleTrees: boolean,
};

const traverse = (obj, callback) => {
  // eslint-disable-next-line
  for (const k in obj) {
    if (obj[k] && typeof obj[k] === 'object') {
      traverse(obj[k], callback);
    } else {
      callback(obj[k]);
    }
  }
};

const isEmptyExpression = (expressionTree) =>
  get(expressionTree, 'root.children[0].searchObject.expression.length', 1) === 0;

const Measure = ({isRatioEnabled, isGroupByEnabled, isSeriesFunctionEnabled, isMultipleTrees}: PropTypes) => {
  const dispatch = useDispatch();
  const executedExpressionTree = useSelector(getExpressionTree); // simple expression builder
  const expressionTrees = useSelector(getExpressionTrees); // advanced multiple expression trees
  const executedExpressionTrees = expressionTrees.map((tree) => omit(tree, ['previewOptions', 'chartDisplay']));

  const [selectedExpressionId, setSelectedExpressionId] = useQueryParam('selectedExpressionId', StringParam);

  const {
    input: {value: simpleExpressionTree, onChange: onChangeSimpleExpressionTree},
  } = useField('simpleExpressionTree');
  // eslint-disable-next-line max-len
  const {
    input: {value: advancedExpressionTree, onChange: onChangeAdvancedExpressionTree},
  } = useField('advancedExpressionTree');
  const {
    input: {value: expressionTreeType, onChange: onChangeExpressionTreeType},
  } = useField('expressionTreeType');

  const initSimpleExpressionTree = () => {
    dispatch(resetExpressionBuilder({id: simpleExpressionTree.id}));
    dispatch(
      initExpressionTreeModel({
        expressionTree: simpleExpressionTree.expressionTree,
      }),
    );
    if (selectedExpressionId) {
      setSelectedExpressionId();
    }
  };

  const initAdvancedExpressionTrees = () => {
    dispatch(resetExpressionBuilder(advancedExpressionTree.length > 0 ? {id: advancedExpressionTree[0].id} : {}));
    advancedExpressionTree.forEach(({expressionTree: tree}, index) => {
      dispatch(
        initExpressionTreeModel({
          expressionTree: cloneDeep(tree),
          index,
        }),
      );
      if (index < advancedExpressionTree.length - 1) {
        dispatch(createNewExpression({id: advancedExpressionTree[index + 1].id}));
      }
    });
    if (selectedExpressionId) {
      const branchId = get(
        advancedExpressionTree.find((item) => item.id === selectedExpressionId),
        'expressionTree.root.id',
      );
      if (branchId) {
        dispatch(selectTreeBranch({branchId, selectedExpressionId}));
      }
      setSelectedExpressionId();
    } else {
      dispatch(setAllExpressionsSelected(true));
    }
  };

  // Update simulation and change form state on tab change
  const setMode = (value) => {
    if (expressionTreeType === value) {
      return;
    }
    onChangeExpressionTreeType(value);
    if (value === 'simple') {
      initSimpleExpressionTree();
    } else {
      initAdvancedExpressionTrees();
    }
  };

  // Composite execute whenever simple value is changed
  useEffect(() => {
    if (simpleExpressionTree) {
      let hasEmptyItem = false;
      traverse(simpleExpressionTree.expressionTree, (item) => {
        if (item && (!item.value || !item.key) && item.type === 'property') {
          hasEmptyItem = true;
        }
      });
      if (!hasEmptyItem) {
        initSimpleExpressionTree();
      }
    }
  }, [simpleExpressionTree]);

  // Init expression tree model on advanced expression tree FIRST loading
  useEffect(() => {
    if (expressionTreeType === 'advanced' && advancedExpressionTree) {
      initAdvancedExpressionTrees();
    }
  }, []);

  // Update simulation on loading of advanced measure from quick actions
  useDeepCompareEffect(() => {
    if (expressionTreeType === 'advanced') {
      onChangeAdvancedExpressionTree(executedExpressionTrees);
    }
  }, [executedExpressionTrees]);

  // Loading measure into simple expression builder from quick actions
  useEffect(() => {
    if (
      expressionTreeType === 'simple' &&
      !isEmptyExpression(executedExpressionTree) &&
      isEmptyExpression(simpleExpressionTree.expressionTree)
    ) {
      onChangeSimpleExpressionTree({...simpleExpressionTree, expressionTree: executedExpressionTree});
    }
  }, [executedExpressionTree]);

  const newExpression = () => {
    dispatch(createNewExpression());
  };

  const components = [
    {
      name: tabs[0].value,
      component: (
        <SimpleExpressionContainer
          isRatioEnabled={isRatioEnabled}
          isGroupByEnabled={isGroupByEnabled}
          isSeriesFunctionEnabled={isSeriesFunctionEnabled}
        />
      ),
    },
    {
      name: tabs[1].value,
      component: (
        <React.Fragment>
          <CompositeBuildersContainer />
          {/* eslint-disable-next-line react/button-has-type */}
          {isMultipleTrees ? (
            <Button
              isDisabled={expressionTrees.length > 4}
              text="New Expression"
              icon="icn-action16-plusa"
              onClick={newExpression}
              height={HEIGHTS.REGULAR}
              colorSchema={COLORS.GRAY_300}
              automationId="new-expression-button"
            />
          ) : null}
        </React.Fragment>
      ),
    },
  ];

  return (
    <div className="position_relative">
      <Tabs
        value={expressionTreeType}
        onChange={setMode}
        tabs={tabs}
        nameField="measureType"
        text="Measure"
        components={components}
      />
      <div className="position_absolute right_0 top_0">
        <CompositeBuilderActions
          onClear={useCallback(() => {
            if (simpleExpressionTree) {
              onChangeSimpleExpressionTree({expressionTree: emptyExpressionTree});
            } else {
              dispatch(initExpressionTreeModel({expressionTree: emptyExpressionTree}));
            }
          }, [simpleExpressionTree])}
        />
      </div>
    </div>
  );
};

export default Measure;
