/* eslint-disable */
import React from 'react';
import {get} from 'lodash';
import {Box} from '@material-ui/core';
import DimensionsInput from './DimensionsInput';
import AddButton from './AddButton';

const updateObjectInArray = (array, i, updated) => array.map((item, index) => (index !== i ? item : updated));
const removeObjectInArray = (array, i) => array.filter((item, index) => index !== i);

const EMPTY_ARRAY = [];

const DimensionsArray = ({
  value,
  onChange,
  filter,
  numberOfConstantFilters,
}: {
  value: Array,
  onChange: Function,
  filter: Array,
  numberOfConstantFilters: Number,
}) => (
  <div>
    {value.length > 0 && <div className="text16reg mb_1">Filter By Dimensions</div>}
    {value.map((dimension, index) => (
      <div className="display_flex alignItems_center mb_1" key={`${index}/${value.length}`}>
        <Box width={200} mr={1}>
          <DimensionsInput
            filter={filter.slice(0, index + (numberOfConstantFilters || 1))}
            value={dimension || EMPTY_ARRAY}
            onChange={(data) => onChange(updateObjectInArray(value, index, data).slice(0, index + 1))}
          />
        </Box>
        <div className="color_gray-400" role="button" onClick={() => onChange(removeObjectInArray(value, index))}>
          <i className="icon icon icn-general16-closea" />
        </div>
      </div>
    ))}
    {value.length < 3 && get(value[value.length - 1], 'length', 1) !== 0 && (
      <Box width={84} mr={1}>
        <AddButton onClick={() => onChange([...value, []])}>Filter</AddButton>
      </Box>
    )}
  </div>
);

export default DimensionsArray;
