// @flow
import React, {Fragment} from 'react';
import './AlertSummaryPanel.module.scss';
import LabelAndValueCol from 'common/componentsV2/LabelAndValueCol';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import Highlighter from 'react-highlight-words';
import {abbrNum} from 'common/utils/numbers';
import Button, {COLORS} from 'common/componentsV2/Button';
import connect from 'react-redux/es/connect/connect';
import {segmentClickEvent} from 'common/store/actions';
import Tooltip from 'common/componentsV2/Tooltip';
import TagList from 'common/componentsV2/TagList';
import AlertInfoWidget from 'alerts.management/components/manager/alertExpandablePanel/AlertInfoWidget';
import Estimative from 'alerts.management/components/manager/alertExpandablePanel/Estimative';
import * as filterSelector from 'alerts.management/store/filterSelector';
import EstimatedDelta from 'alerts.management/components/manager/alertExpandablePanel/EstimatedDelta';

type PropTypes = {
  alert: Object,
  searchQuery: string,

  // connect
  segmentClickEvent: Function,
};

const getAnomalyAlertSummary = (alert) => {
  const {alertSummaryConfiguration: summary} = alert.details;

  if (alert.alertConfiguration.isAnomalyAlert) {
    return (
      <div styleName="description">
        {summary.measure && (
          <Fragment>
            <p>
              Alert On <span styleName="direction">{summary.anomalyDirection}</span> in
            </p>{' '}
            <Tooltip content={summary.measure} placement="top">
              <p styleName="measure">{summary.measure}</p>
            </Tooltip>
          </Fragment>
        )}
        {!summary.measure && (
          <p>
            Alert On
            {summary.anomalyDirection} in alert metrics
          </p>
        )}
        {summary.dimensions && (
          <p>
            by <span styleName="dimensions">{summary.dimensions}</span>
          </p>
        )}
        <p>{`Alerting on anomalies lasting at least ${summary.duration}.`}</p>
      </div>
    );
  }
  return '';
};

const getStaticAlertSummary = (alert) => {
  const {alertSummaryConfiguration: summary} = alert.details;
  // eslint-disable-next-line no-unused-vars
  let thresholdItem = 'Alerting when ';
  if (summary.threshold && summary.threshold.maxValue !== null && summary.threshold.minValue !== null) {
    // eslint-disable-next-line no-unused-vars
    thresholdItem += `over ${summary.threshold.maxValue},
    or below ${summary.threshold.minValue} for at least ${summary.duration}.`;
  } else if (summary.threshold && summary.threshold.maxValue !== null) {
    // eslint-disable-next-line no-unused-vars
    thresholdItem += `over ${summary.threshold.maxValue} for at least ${summary.duration}.`;
  } else if (summary.threshold && summary.threshold.minValue !== null) {
    // eslint-disable-next-line no-unused-vars
    thresholdItem += `below ${summary.threshold.minValue} for at least ${summary.duration}.`;
  }

  if (alert.alertConfiguration.isStaticAlert) {
    return (
      <div styleName="description">
        {summary.measure && (
          <Fragment>
            <p>Static alert for</p>
            <Tooltip content={summary.measure} placement="top">
              <p styleName="measure">{summary.measure}</p>
            </Tooltip>
          </Fragment>
        )}
        {!summary.measure && <p>Static alert for alert metrics</p>}
        {summary.dimensions && (
          <p>
            by <span styleName="dimensions">{summary.dimensions}</span>
          </p>
        )}
      </div>
    );
  }
  return '';
};

const getNoDataAlertSummary = (alert) => {
  const {alertSummaryConfiguration: summary} = alert.details;

  if (alert.alertConfiguration.isAnomalyAlert) {
    return '';
  }
  if (alert.alertConfiguration.isStaticAlert) {
    return '';
  }
  if (alert.alertConfiguration.isNoDataAlert) {
    return (
      <div styleName="description">
        {summary.measure && (
          <Fragment>
            <p>Alert On No Data for</p>{' '}
            <Tooltip content={summary.measure} placement="top">
              <p styleName="measure">{summary.measure}</p>
            </Tooltip>
          </Fragment>
        )}
        {!summary.measure && <p>Alert On No Data for alert metrics</p>}
        {summary.dimensions && (
          <p>
            by <span styleName="dimensions">{summary.dimensions}</span>
          </p>
        )}
        <p>{`Alerting on missing data for at least ${summary.duration || summary.noDataDuration}.`}</p>
      </div>
    );
  }
  return '';
};

const getAlertTypeChips = (alert) => {
  const {isAnomalyAlert, isStaticAlert} = alert.alertConfiguration;
  let chipElement = <span styleName="chip no-data-chip">No Data Alert</span>;

  if (isAnomalyAlert) {
    chipElement = <span styleName="chip anomaly-chip">Anomaly Alert</span>;
  } else if (isStaticAlert) {
    chipElement = <span styleName="chip static-chip">Static Alert</span>;
  }

  return <div styleName="type-chips-row">{chipElement}</div>;
};

@connect(
  (state) => ({
    searchQuery: filterSelector.getAlertFiltersSearchQuery(state),
  }),
  {
    segmentClickEvent,
  },
)
export default class AlertSummaryPanel extends React.PureComponent {
  props: PropTypes;

  state = {
    isModalOpen: false,
    isShowAllButton: false,
  };

  componentDidMount() {
    this.setState({isShowAllButton: this.descriptionElement.offsetHeight > 150});
  }

  onDescriptionClick = () => {
    this.props.segmentClickEvent({
      type: 'click',
      name: 'See all',
    });
    this.setState({isModalOpen: true});
  };

  renderMinDurOrMinSingInfoWidget = () => {
    let title = '';
    let value = '';
    if (this.props.alert.alertConfiguration.isAnomalyAlert) {
      title = 'Min. Significance Score';
      value = this.props.alert.details.minSignificanceScore;
    } else if (this.props.alert.alertConfiguration.isNoDataAlert) {
      title = 'Min. Duration';
      value = this.props.alert.details.alertSummaryConfiguration.noDataDuration;
    } else {
      title = 'Min. Duration';
      value = this.props.alert.details.alertSummaryConfiguration.duration;
    }
    return <AlertInfoWidget title={title} value={value} icon="icn-general16-direction" />;
  };

  renderByAlertTypeInfoWidget = () => {
    const {delta: deltaCondition} = this.props.alert.alertConfiguration.conditions;
    const delta = deltaCondition || {};

    let title = '';
    let value = '';
    let tooltip = '';
    let valueNode = null;

    if (this.props.alert.alertConfiguration.isAnomalyAlert) {
      title = 'Minimum Delta';
      if (!delta.deltaAbsolute && !delta.deltaPercentage) {
        value = 'Not Set';
        if (delta.enableAutoTuning) {
          valueNode = (
            <span>
              Auto Calculated
              <p style={{lineHeight: '0', margin: '0'}} />
              <EstimatedDelta />
            </span>
          );
        }
      } else if (delta.deltaAbsolute && !delta.deltaPercentage) {
        valueNode = delta.enableAutoTuning ? (
          <span>
            Auto Calculated
            <p style={{lineHeight: '0', margin: '0'}} />
            {abbrNum(delta.deltaAbsolute, 4)}
          </span>
        ) : (
          <span>{abbrNum(delta.deltaAbsolute, 4)}</span>
        );
        tooltip = delta.deltaAbsolute;
      } else if (delta.deltaPercentage && !delta.deltaAbsolute) {
        value = `${abbrNum(delta.deltaPercentage, 4)}%`;
        tooltip = `${delta.deltaPercentage}%`;
      } else if (delta.deltaPercentage && delta.deltaAbsolute) {
        valueNode = (
          <span>
            {abbrNum(delta.deltaAbsolute, 4)}
            <p style={{lineHeight: '0', margin: '0'}} />
            {`${abbrNum(delta.deltaPercentage, 4)}%`}
          </span>
        );
      }
    }

    if (this.props.alert.alertConfiguration.isNoDataAlert) {
      title = 'Alert Updates';
      valueNode = this.props.alert.alertConfiguration.alertOnMissingData ? <span>Yes</span> : <span>No</span>;
    }
    if (this.props.alert.alertConfiguration.isStaticAlert) {
      title = 'Threshold';
      if (
        !this.props.alert.details.alertSummaryConfiguration ||
        this.props.alert.details.alertSummaryConfiguration.threshold
      ) {
        return null;
      }
      const t = this.props.alert.details.alertSummaryConfiguration.threshold;
      valueNode = (
        <span>
          {t.maxValue ? `Upper: ${t.maxValue}` : ''}
          {t.maxValue && t.minValue ? <p style={{lineHeight: '0', margin: '0'}} /> : ''}
          {t.minValue ? `Lower: ${t.minValue}` : ''}
        </span>
      );
    }
    return (
      <AlertInfoWidget title={title} tooltip={tooltip} value={value} valueNode={valueNode} icon="icn-general16-delta" />
    );
  };

  renderTimeScaleInfoWidget = () => (
    <AlertInfoWidget
      title="Time Scale"
      value={this.props.alert.alertConfiguration.timeScaleUI}
      icon="icn-general16-timescale"
    />
  );

  renderMinDurationWidget = () => (
    <AlertInfoWidget
      title="Minimum Duration"
      value={this.props.alert.details.alertSummaryConfiguration.duration}
      icon="icn-general16-timescale"
    />
  );

  renderWarningInfoWidget = () => {
    if (this.props.alert.warnings === 'alert-0-no-warning') {
      return null;
    }
    return (
      <AlertInfoWidget
        tooltip={this.props.alert.alertConfiguration.warningMessage || ''}
        value={this.props.alert.alertConfiguration.warningMessage || ''}
        icon="icn-general16-warning"
        valueClass={this.props.alert.warnings}
      />
    );
  };

  renderInfoWidgets = () => {
    const byAlertTypeWidget = this.renderByAlertTypeInfoWidget();
    return (
      <Fragment>
        <div styleName="column column-2">
          {this.props.alert.alertConfiguration.isNoDataAlert ? null : (
            <div styleName="row">{this.renderTimeScaleInfoWidget()}</div>
          )}
          {this.props.alert.alertConfiguration.isNoDataAlert ? null : (
            <div styleName="row">{this.renderMinDurationWidget()}</div>
          )}
          {!byAlertTypeWidget ? null : <div styleName="row">{byAlertTypeWidget}</div>}
          <div styleName="row">{this.renderMinDurOrMinSingInfoWidget()}</div>
        </div>
      </Fragment>
    );
  };

  isShowEstimative = () => this.props.alert.warnings !== 'alert-2-warning-red';

  render() {
    const {alert, searchQuery} = this.props;

    return (
      <div styleName="container">
        <Modal isOpen={this.state.isModalOpen} onClose={() => this.setState({isModalOpen: false})} size={SIZES.LARGE}>
          <div styleName="description-modal">
            <div>
              <h3 styleName="modal-header">Description</h3>
              <p>{alert.details.description}</p>
            </div>
            <Button colorSchema={COLORS.BLUE_500} text="Done" onClick={() => this.setState({isModalOpen: false})} />
          </div>
        </Modal>
        <div styleName="column column-1">
          {getAlertTypeChips(alert)}
          <div>
            {getAnomalyAlertSummary(alert)}
            {getStaticAlertSummary(alert)}
            {getNoDataAlertSummary(alert)}
          </div>
          {this.isShowEstimative() && <Estimative />}
          {this.renderWarningInfoWidget()}
        </div>
        <div styleName="margin" />
        {this.renderInfoWidgets()}
        <div styleName="margin" />
        <div styleName="column column-3">
          <div styleName="row">
            <TagList
              tags={alert.details.tags}
              onSeeAllClick={this.onTagClick}
              isDismissible={alert.authorization.delete}
              onDismiss={this.onTagDismissClick}
              isRow
            />
          </div>
          <div styleName="marked-background row">
            <LabelAndValueCol
              title="Description"
              isBreakLine
              additionalTitle={
                this.state.isShowAllButton ? (
                  <button type="button" styleName="details-button" onClick={this.onDescriptionClick}>
                    See all
                  </button>
                ) : null
              }
            >
              <div
                styleName="description-content"
                ref={(el) => {
                  this.descriptionElement = el;
                }}
              >
                <Highlighter autoEscape searchWords={[searchQuery]} textToHighlight={alert.details.description} />
              </div>
            </LabelAndValueCol>
          </div>
        </div>
        <div styleName="column column-5" />
      </div>
    );
  }
}
