// @flow

import React from 'react';
/* eslint-disable */
import S3SourceModal from 'bc/components/dataSourceTypes/dataSourceModal/byType/s3/S3SourceModal';
import CurSourceModal from 'bc/components/dataSourceTypes/dataSourceModal/byType/cur/CurSourceModal';
import ParquetSourceModal from 'bc/components/dataSourceTypes/dataSourceModal/byType/parquet/ParquetSourceModal';
import SqlSourceModal from 'bc/components/dataSourceTypes/dataSourceModal/byType/sql/SqlSourceModal';
import DatabricksSourceModal from 'bc/components/dataSourceTypes/dataSourceModal/byType/databricks/DatabricksSourceModal';
import AdobeSourceModal from 'bc/components/dataSourceTypes/dataSourceModal/byType/adobe/AdobeSourceModal';
import MParticleSourceModal from 'bc/components/dataSourceTypes/dataSourceModal/byType/mParticle/MParticleSourceModal';
import GoogleSourceModal from 'bc/components/dataSourceTypes/dataSourceModal/byType/google/GoogleSourceModal';
import FacebookSourceModal from 'bc/components/dataSourceTypes/dataSourceModal/byType/facebook/FacebookSourceModal';
import KinesisSourceModal from 'bc/components/dataSourceTypes/dataSourceModal/byType/kinesis/KinesisSourceModal';
import AwsUsageSourceModal from 'bc/components/dataSourceTypes/dataSourceModal/byType/awsUsage/AwsUsageSourceModal';
import GeneralSourceModal from 'bc/components/dataSourceTypes/dataSourceModal/byType/GeneralSourceModal';
import SegmentSourceModal from 'bc/components/dataSourceTypes/dataSourceModal/byType/segment/SegmentSourceModal';
import GoogleAuctionsSourceModal from 'bc/components/dataSourceTypes/dataSourceModal/byType/googleAuctions/GoogleAuctionsSourceModal';
import CoralogixSourceModal from 'bc/components/dataSourceTypes/dataSourceModal/byType/coralogix/CoralogixSourceModal';
import AthenaSourceModal from 'bc/components/dataSourceTypes/dataSourceModal/byType/athena/AthenaSourceModal';
import TimestreamSourceModal from 'bc/components/dataSourceTypes/dataSourceModal/byType/timestream/TimestreamSourceModal';
import EventhubsSourceModal from 'bc/components/dataSourceTypes/dataSourceModal/byType/eventhubs/EventhubsSourceModal';

/* eslint-enable */
import {salesforceLogin} from './salesforceService';

export const bcTypes = {
  /*
  Possible properties for type

  name: 'Google Analytics',
  shortName: 'GA',
  type: 'google_analytics',
  icon: 'GOOGLE_ANALYTICS.jpg',
  iconStyle: 'ga',
  isAsyncQuery: boolean           //moving from stream editor to preview is async
  isUpdatable: boolean            //in data manager - can we update source properties or just rename it
   */
  google_storage: {
    name: 'Google Storage',
    shortName: 'GS',
    type: 'google_storage',
    icon: 'int_gs.svg',
    iconStyle: 'gs',
    featureFlag: 'BC_ENABLE_COLLECTOR_GCS',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly, me) => (
      <GoogleSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} me={me} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <GeneralSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  google_analytics: {
    name: 'Google Analytics',
    shortName: 'GA',
    type: 'google_analytics',
    icon: 'GOOGLE_ANALYTICS.jpg',
    iconStyle: 'ga',
    featureFlag: 'BC_ENABLE_COLLECTOR_GA_FILE',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly, me) => (
      <GoogleSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} me={me} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <GeneralSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  facebook_ads: {
    name: 'Facebook Ads',
    shortName: 'FBDS',
    type: 'facebook_ads',
    icon: 'FACEBOOK_ADS.jpg',
    iconStyle: 'fbds',
    featureFlag: 'BC_ENABLE_COLLECTOR_FBDS',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly, me) => (
      <FacebookSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} me={me} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <FacebookSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  google_ads: {
    name: 'Google Ads',
    shortName: 'GADS',
    type: 'google_ads',
    icon: 'GOOGLE_SERVICE.jpg',
    iconStyle: 'gads',
    featureFlag: 'BC_ENABLE_COLLECTOR_GADS',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly, me) => (
      <GoogleSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} me={me} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <GeneralSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  bigquery: {
    name: 'Google BigQuery',
    shortName: 'BQ',
    type: 'bigquery',
    icon: 'GOOGLE_BIGQUERY.jpg',
    iconStyle: 'bigquery',
    featureFlag: 'BC_ENABLE_COLLECTOR_BIGQUERY',
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly, me) => (
      <GoogleSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} me={me} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <GeneralSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  adobe: {
    name: 'Adobe Analytics',
    shortName: 'Adobe',
    type: 'adobe',
    icon: 'ADOBE.png',
    iconStyle: 'adobe',
    featureFlag: 'BC_ENABLE_COLLECTOR_ADOBE',
    isAsyncQuery: true,
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <AdobeSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <AdobeSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  local_file: {
    name: 'File Upload',
    shortName: 'File',
    type: 'local_file',
    icon: 'LOCAL_FILE.png',
    iconStyle: 'fu',
    featureFlag: 'BC_ENABLE_COLLECTOR_GA_FILE',
    isEmptyMetricsOrDimensionsAllowed: true,
    view: (sourceServerOptions, source, isEditable) => (
      <GeneralSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
  },
  s3: {
    name: 'AWS S3',
    shortName: 'S3',
    type: 's3',
    icon: 'int_s3.svg',
    iconStyle: 'aws',
    featureFlag: 'BC_ENABLE_COLLECTOR_S3',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <S3SourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <S3SourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  aws_cur: {
    name: 'AWS Cost Monitoring',
    shortName: 'AWS_CUR',
    type: 'aws_cur',
    icon: 'AWS.png',
    iconStyle: 'aws_cur',
    featureFlag: 'BC_ENABLE_COLLECTOR_CUR',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <CurSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <CurSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  aws_usage: {
    name: 'AWS Usage Monitoring',
    shortName: 'AWS_USAGE',
    type: 'aws_usage',
    icon: 'AWS.png',
    iconStyle: 'aws_usage',
    featureFlag: 'BC_ENABLE_COLLECTOR_AWS_USAGE',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <AwsUsageSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <AwsUsageSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  athena: {
    name: 'S3 Parquet',
    shortName: 'Parquet',
    type: 'athena',
    icon: 'PARQUET.png',
    iconStyle: 'athena',
    featureFlag: 'BC_ENABLE_COLLECTOR_PARQUET',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <ParquetSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <ParquetSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  avro: {
    name: 'S3 Avro',
    shortName: 'Avro',
    type: 'avro',
    icon: 'AVRO.png',
    iconStyle: 'avro',
    featureFlag: 'BC_ENABLE_COLLECTOR_AVRO',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <ParquetSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <ParquetSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  salesforce: {
    name: 'SalesForce',
    shortName: 'Salesforce',
    type: 'salesforce',
    icon: 'salesforce.png',
    iconStyle: 'salesforce',
    featureFlag: 'BC_ENABLE_COLLECTOR_SFDC',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly, me) => {
      salesforceLogin(sourceServerOptions, onSuccess, me);
    },
    view: (sourceServerOptions, source, isEditable) => (
      <GeneralSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  mysql: {
    name: 'MySQL',
    shortName: 'MySql',
    type: 'mysql',
    icon: 'MYSQL_AGENT.jpg',
    iconStyle: 'mysql',
    featureFlag: 'BC_ENABLE_COLLECTOR_MYSQL',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <SqlSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <SqlSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  psql: {
    name: 'Postgres',
    shortName: 'Postgres',
    type: 'psql',
    icon: 'PSQL.jpg',
    iconStyle: 'psql',
    featureFlag: 'BC_ENABLE_COLLECTOR_POSTGRES',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <SqlSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <SqlSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  postgres: {
    name: 'Postgres Agent',
    shortName: 'postgres',
    type: 'postgres',
    icon: 'PSQL.jpg',
    iconStyle: 'psql_agent',
    isNoMenu: true,
  },
  mssql: {
    name: 'MS SQL Server',
    shortName: 'MS SQL',
    type: 'mssql',
    icon: 'MSSQL.jpg',
    iconStyle: 'mssql',
    featureFlag: 'BC_ENABLE_COLLECTOR_MSSQL',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <SqlSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <SqlSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  databricks: {
    name: 'Databricks',
    shortName: 'Databricks',
    type: 'databricks',
    icon: 'int_databricks.svg',
    iconStyle: 'databricks',
    featureFlag: 'BC_ENABLE_COLLECTOR_DATABRICKS',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <DatabricksSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <DatabricksSourceModal
        isOpen
        sourceType={sourceServerOptions}
        source={source}
        isViewOnly
        isEditable={isEditable}
      />
    ),
    /* eslint-enable react/display-name */
  },
  mariadb: {
    name: 'MariaDB',
    shortName: 'MariaDB',
    type: 'mariadb',
    icon: 'MARIADB.jpg',
    iconStyle: 'mariadb',
    featureFlag: 'BC_ENABLE_COLLECTOR_MARIADB',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <SqlSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <SqlSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  redshift: {
    name: 'Amazon Redshift',
    shortName: 'Redshift',
    type: 'redshift',
    icon: 'int_redshift.svg',
    iconStyle: 'redshift',
    featureFlag: 'BC_ENABLE_COLLECTOR_REDSHIFT',
    isUpdateble: false,
    isViewable: true,
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <SqlSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <SqlSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  snowflake: {
    name: 'Snowflake',
    shortName: 'Snowflake',
    type: 'snowflake',
    icon: 'snowflake.png',
    iconStyle: 'snowflake',
    featureFlag: 'BC_ENABLE_COLLECTOR_SNOWFLAKE',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <SqlSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <SqlSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  oracle: {
    name: 'Oracle',
    shortName: 'Oracle',
    type: 'oracle',
    icon: 'ORACLE.png',
    iconStyle: 'oracle',
    featureFlag: 'BC_ENABLE_COLLECTOR_ORACLE',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <SqlSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <SqlSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  mparticle: {
    name: 'mParticle',
    shortName: 'mParticle',
    type: 'mparticle',
    icon: 'MPARTICAL.png',
    iconStyle: 'mparticle',
    featureFlag: 'BC_ENABLE_COLLECTOR_MPARTICLE',
    isUpdateble: false,
    isViewable: true,
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <MParticleSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <MParticleSourceModal
        isOpen
        sourceType={sourceServerOptions}
        source={source}
        isViewOnly
        isEditable={isEditable}
      />
    ),
    /* eslint-enable react/display-name */
  },
  kinesis: {
    name: 'Kinesis',
    shortName: 'Kinesis',
    type: 'kinesis',
    icon: 'KINESIS.png',
    iconStyle: 'kinesis',
    featureFlag: 'BC_ENABLE_COLLECTOR_KINESIS',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <KinesisSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <KinesisSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  segment: {
    name: 'Segment',
    shortName: 'segment',
    type: 'segment',
    icon: 'SEGMENT.png',
    iconStyle: 'segment',
    featureFlag: 'BC_ENABLE_COLLECTOR_SEGMENT',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <SegmentSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <SegmentSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  teradata: {
    name: 'Teradata JDBC',
    shortName: 'Teradata',
    type: 'teradata',
    icon: 'Teradata.png',
    iconStyle: 'teradata',
    featureFlag: 'BC_ENABLE_COLLECTOR_TERADATA',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <SqlSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <SqlSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  google_auctions: {
    name: 'Google Auctions',
    shortName: 'Google Auctions',
    type: 'google_auctions',
    icon: 'GOOGLE_SERVICE.jpg',
    iconStyle: 'gads',
    featureFlag: 'BC_ENABLE_COLLECTOR_GOOGLE_AUCTIONS',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly, me) => (
      <GoogleAuctionsSourceModal
        isOpen
        sourceType={sourceServerOptions}
        source={source}
        isViewOnly={isViewOnly}
        me={me}
      />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <GoogleAuctionsSourceModal
        isOpen
        sourceType={sourceServerOptions}
        source={source}
        isViewOnly
        isEditable={isEditable}
      />
    ),
    /* eslint-enable react/display-name */
  },
  coralogix: {
    name: 'Coralogix',
    shortName: 'Coralogix Metrics',
    type: 'coralogix',
    icon: 'Coralogix.png',
    iconStyle: 'coralogix',
    featureFlag: 'BC_ENABLE_COLLECTOR_CORALOGIX',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <CoralogixSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <CoralogixSourceModal
        isOpen
        sourceType={sourceServerOptions}
        source={source}
        isViewOnly
        isEditable={isEditable}
      />
    ),
    /* eslint-enable react/display-name */
  },
  athena_sql: {
    name: 'Athena SQL',
    shortName: 'Athena',
    type: 'athena_sql',
    icon: 'Athena_sql.png',
    iconStyle: 'athena_sql',
    featureFlag: 'BC_ENABLE_COLLECTOR_ATHENA',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <AthenaSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <AthenaSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly isEditable={isEditable} />
    ),
    /* eslint-enable react/display-name */
  },
  timestream: {
    name: 'AWS Timestream JDBC',
    shortName: 'Timestream',
    type: 'timestream',
    icon: 'Timestream.png',
    iconStyle: 'timestream',
    featureFlag: 'BC_ENABLE_COLLECTOR_AWS_TIMESTREAM',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <TimestreamSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <TimestreamSourceModal
        isOpen
        sourceType={sourceServerOptions}
        source={source}
        isViewOnly
        isEditable={isEditable}
      />
    ),
    /* eslint-enable react/display-name */
  },

  eventhubs: {
    name: 'Azure Event Hubs',
    shortName: 'Azure Event Hubs',
    type: 'eventhubs',
    icon: 'EVENTHUBS.png',
    iconStyle: 'eventhubs',
    featureFlag: 'BC_ENABLE_COLLECTOR_AZURE_EVENTHUBS',
    isEmptyMetricsOrDimensionsAllowed: true,
    /* eslint-disable react/display-name */
    action: (sourceServerOptions, source, onSuccess, isViewOnly) => (
      <EventhubsSourceModal isOpen sourceType={sourceServerOptions} source={source} isViewOnly={isViewOnly} />
    ),
    view: (sourceServerOptions, source, isEditable) => (
      <EventhubsSourceModal
        isOpen
        sourceType={sourceServerOptions}
        source={source}
        isViewOnly
        isEditable={isEditable}
      />
    ),
    /* eslint-enable react/display-name */
  },

  lookup: {
    name: 'Lookup Tables',
    shortName: 'Lookup',
    type: 'lookup',
    icon: 'LOOKUP.png',
    iconStyle: 'lookup',
    featureFlag: 'BC_ENABLE_COLLECTOR_LOOKUP',
    isNoMenu: true,
  },
  influx: {
    name: 'InfluxDB',
    shortName: 'Influx',
    type: 'influx_db',
    icon: 'INFLUX_DB.png',
    iconStyle: 'influx_db',
    isNoMenu: true,
  },
  mongo: {
    name: 'MongoDB',
    shortName: 'Mongo',
    type: 'mongo_db',
    icon: 'MONGO_DB.jpg',
    iconStyle: 'mongo_db',
    isNoMenu: true,
  },
  kafka: {
    name: 'Apache Kafka',
    shortName: 'Kafka',
    type: 'apache_kafka',
    icon: 'APACHE_KAFKA.png',
    iconStyle: 'apache_kafka',
    isNoMenu: true,
  },
  victoria: {
    name: 'VictoriaMetrics Agent',
    shortName: 'VictoriaMetrics',
    type: 'victoria',
    icon: 'victoria.jpg',
    iconStyle: 'victoria',
    isNoMenu: true,
  },
  prometheus: {
    name: 'prometheus Agent',
    shortName: 'prometheus',
    type: 'prometheus',
    icon: 'PROMETHEUS.jpg',
    iconStyle: 'prometheus',
    isNoMenu: true,
  },
  splunk: {
    name: 'Splunk Agent',
    shortName: 'Splunk',
    type: 'splunk',
    icon: 'splunk.svg',
    iconStyle: 'splunk_agent',
    isNoMenu: true,
  },
  directory: {
    name: 'Directory (Files) Agent',
    shortName: 'Directory',
    type: 'directory',
    icon: 'directory.svg',
    iconStyle: 'directory_agent',
    isNoMenu: true,
  },
  elastic: {
    name: 'Elasticsearch Agent',
    shortName: 'Elastic',
    type: 'elastic',
    icon: 'ELASTICSEARCH.png',
    iconStyle: 'elasticsearch',
    isNoMenu: true,
  },
  sage: {
    name: 'Sage Agent',
    shortName: 'Sage',
    type: 'sage',
    icon: 'sage.png',
    iconStyle: 'sage_agent',
    isNoMenu: true,
  },
  Monitoring: {
    name: 'Monitoring Agent',
    shortName: 'Monitoring',
    type: 'Monitoring',
    icon: 'directory.svg',
    iconStyle: 'directory_agent',
    isNoMenu: true,
  },
  zabbix: {
    name: 'Zabbix Agent',
    shortName: 'Zabbix',
    type: 'zabbix',
    icon: 'Zabbix.svg',
    iconStyle: 'zabbix_agent',
    isNoMenu: true,
  },
  coralogix_agent: {
    name: 'Coralogix Agent',
    shortName: 'Coralogix',
    type: 'coralogix_agent',
    icon: 'Coralogix',
    iconStyle: 'coralogix_agent',
    isNoMenu: true,
  },
  clickhouse: {
    name: 'ClickHouse',
    shortName: 'ClickHouse',
    type: 'clickhouse',
    icon: 'ClickHouse.png',
    iconStyle: 'clickhouse',
    isNoMenu: true,
  },
  cacti: {
    name: 'Cacti Agent',
    shortName: 'Cacti Agent',
    type: 'cacti',
    icon: 'Cacti.png',
    iconStyle: 'cacti',
    isNoMenu: true,
  },
};

export const getTypeDetails = (type) => {
  if (bcTypes[type] === undefined) {
    return {
      name: type,
      shortName: type,
      type,
      icon: 'directory.svg',
      iconStyle: 'directory_agent',
      isNoMenu: true,
    };
  }
  return bcTypes[type];
};

export const isBcTypeEmptyMetricsOrDimensionsAllowed = (type) =>
  bcTypes[type].isEmptyMetricsOrDimensionsAllowed === true;

export const isMaxBackFillIntervalsShouldBePresent = (type) =>
  [
    'bigquery',
    'mysql',
    'databricks',
    'psql',
    'mssql',
    'mariadb',
    'redshift',
    'teradata',
    'athena_sql',
    'timestream',
  ].indexOf(type) !== -1;

export const bcTypesOptions = Object.values(bcTypes)
  .map((i) => ({
    id: i.type,
    label: i.name,
    value: i.type,
  }))
  .sort((a, b) => a.label.localeCompare(b.label))
  .filter((type) => type.id !== 'lookup');
