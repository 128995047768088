// @flow
import React, {Fragment, useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import {prettifyImpactNumber, CURRENCY_OPTIONS, setSentiment} from 'impact/services/constants';
import {isEmpty} from 'lodash';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {getFormattedMDH} from 'common/utils/dateService';
import {palette} from '../../app/styles/theme';

type PropTypes = {
  alert: Object,
  impact: Object,
  timeZone: String,
  onNoAlertLink: Function,
};

const useStyle = makeStyles(() => ({
  bg: {
    background: palette.white['500'],
    border: `1px solid${palette.gray['200']}`,
    borderRadius: 6,
  },
  impactValue: {
    color: palette.gray['500'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  noAlertBg: {
    width: 320,
    height: 127,
    backgroundColor: palette.gray['100'],
    textAlign: 'center',
  },
  noAlertBgTxt: {
    color: palette.gray['400'],
    lineHeight: '120%',
    fontWeight: '400',
  },
  noAlertBgLink: {
    textDecoration: 'none',
    color: palette.blue['500'],
    cursor: 'pointer',
  },
  dates: {
    display: 'flex',
    justifyContent: 'space-between',
    color: palette.gray['400'],
  },
  date: {
    textAlign: 'center',
    fontSize: 13,
  },
  impactIcon: {
    position: 'relative',
    top: 2,
    marginRight: 10,
    marginLeft: 10,
  },
}));

const ImpactSumLight = (props: PropTypes) => {
  const classes = useStyle();
  const currency = CURRENCY_OPTIONS[props.impact.currency];
  const [isSentimentPositive, setIsSentimentPositive] = useState(false);

  useEffect(() => {
    const {spike, drop} = props.impact;
    if (!isEmpty(props.alert)) {
      setIsSentimentPositive(setSentiment(props.alert.direction, spike, drop));
    }
  }, [props.impact]);

  return (
    <Fragment>
      {!isEmpty(props.alert) ? (
        <Box>
          <TypographyBox textAlign="center" variant="h5" fontWeight="400" mb={1.5}>
            Most Recent Alert:
          </TypographyBox>

          <Box width="320px" height="127px" p={1.5} className={classes.bg}>
            <TypographyBox textAlign="center" variant="subtitle3" mb={3}>
              {props.alert.title}
            </TypographyBox>

            <TypographyBox
              variant="h2"
              mb={2.5}
              className={classes.impactValue}
              style={{flexDirection: currency && currency.direction === 'rtl' ? 'row-reverse' : ''}}
            >
              <TypographyBox component="span" fontSize={16} mr={0.5}>
                {currency.symbol}
              </TypographyBox>
              <TypographyBox component="span">
                {prettifyImpactNumber(props.alert.sumDeltas, props.impact.factor)}
              </TypographyBox>
              <i
                className={`icn-general16-impact ${classes.impactIcon}`}
                style={{color: isSentimentPositive ? palette.green['600'] : palette.tomato['500']}}
              />
            </TypographyBox>

            <Box className={classes.dates}>
              <Box className={classes.date}>
                Started:
                {getFormattedMDH(props.alert.startTime, props.timeZone)}
              </Box>
              <Box className={classes.date}>
                Lasted:
                {getFormattedMDH(props.alert.endTime, props.timeZone)}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={classes.noAlertBg} p={2}>
          <TypographyBox variant="h5" mb={1} className={classes.noAlertBgTxt}>
            Setting an Impact Value for a measure means that the next alert you will get Anodot will show you the
            business impact of the anomaly.
          </TypographyBox>

          <TypographyBox variant="subtitle3">
            <a rel="noreferrer noopener" className={classes.noAlertBgLink} onClick={props.onNoAlertLink}>
              Learn More
            </a>
          </TypographyBox>
        </Box>
      )}
    </Fragment>
  );
};

export default ImpactSumLight;
