// @flow
import React from 'react';
import SmartTooltip from 'common/componentsV2/Tooltip';
import './Acknowledge.module.scss';

const AcknowledgeIndicator = (props: {starredBy: Array}) => {
  const {starredBy} = props;
  return starredBy.length ? (
    <SmartTooltip placement="top" content={`Acknowledged By: ${' '}${starredBy.map((i) => i.fullName).join(', ')}`}>
      <div styleName="ack-indicator">
        <div styleName="wrapper">
          <i styleName="checked-icon" className="icon icn-general16-checkmark" />
          Ack.
        </div>
      </div>
    </SmartTooltip>
  ) : null;
};

export default AcknowledgeIndicator;
