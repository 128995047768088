// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  setMParticleStreamClearAllDiametrics as setMParticleStreamClearAllDiametricsAction,
  setStreamDiametricsEditorFilterTextInput as setStreamDiametricsEditorFilterTextInputAction,
  setMParticleStreamDiametricsChange as setMParticleStreamDiametricsChangeAction,
  removeMParticleStreamDiametrics as removeMParticleStreamDiametricsAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import DimensionsAndMetricsEditor from 'bc/components/streams/editor/common/DimensionsAndMetricsEditor';

type PropTypes = {
  dimensions: Array<Object>,
  metrics: Array<Object>,
  filteredUnassignedColumns: Array<Object>,
  filterTextInput: String,
  setMParticleStreamClearAllDiametrics: Function,
  setStreamDiametricsEditorFilterTextInput: Function,
  setMParticleStreamDiametricsChange: Function,
  removeMParticleStreamDiametrics: Function,
  isStreamUpdating: Boolean,
};

@connect(
  (state) => ({
    dimensions: selectors.getMParticleStreamSelectedDimensions(state),
    metrics: selectors.getMParticleStreamSelectedMetrics(state),
    filterTextInput: selectors.getStreamMetricsDimentionsFilterTextInput(state),
    filteredUnassignedColumns: selectors.getFilteredMParticleStreamUnAssignedColumns(state),
    isStreamUpdating: selectors.getIsDataStreamUpdating(state),
  }),
  {
    setMParticleStreamClearAllDiametrics: setMParticleStreamClearAllDiametricsAction,
    setStreamDiametricsEditorFilterTextInput: setStreamDiametricsEditorFilterTextInputAction,
    setMParticleStreamDiametricsChange: setMParticleStreamDiametricsChangeAction,
    removeMParticleStreamDiametrics: removeMParticleStreamDiametricsAction,
  },
)
export default class MParticleDimensionsAndMetricsEditor extends React.PureComponent {
  props: PropTypes;

  render() {
    const {
      metrics,
      dimensions,
      filterTextInput,
      filteredUnassignedColumns,
      removeMParticleStreamDiametrics,
      setStreamDiametricsEditorFilterTextInput,
      setMParticleStreamDiametricsChange,
      setMParticleStreamClearAllDiametrics,
      isStreamUpdating,
    } = this.props;

    return (
      <DimensionsAndMetricsEditor
        dimensions={dimensions}
        metrics={metrics}
        filteredUnassignedColumns={filteredUnassignedColumns}
        filterTextInput={filterTextInput}
        isStreamUpdating={isStreamUpdating}
        setStreamClearAllDiametrics={setMParticleStreamClearAllDiametrics}
        setMetricDimentionEditorFilterTextInput={setStreamDiametricsEditorFilterTextInput}
        setStreamDiametricsChange={setMParticleStreamDiametricsChange}
        removeStreamDiametrics={removeMParticleStreamDiametrics}
        isRemoveDateSection
      />
    );
  }
}
