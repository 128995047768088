// @flow
import React from 'react';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';
import SmartTooltip from 'common/components/SmartTooltip';
import {reduceArrayItem} from 'common/utils/reducers';
import {createStreamSchemaNewColumn, fetchTransformFunctions} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import {pick, isEmpty} from 'lodash';
import SelectAndt, {THEME_LIGHT, THEME_GREEN, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {components} from 'react-select2';
import * as style from './transform/TransformFuncForm.module.scss';
import AggregationDdl from './what/AggregationDdl';

const reducerHelper = (item, payload) => ({...item, ...payload});

type PropTypes = {
  isOpen: boolean,
  onClose: Function,
  colType: String,
  transformFunctions: Array,
  schemaDimensionColumns: Array,
  schemaMetricColumns: Array,
  createStreamSchemaNewColumn: Function,
  fetchTransformFunctions: Function,
};

const EMPTY_TRANSFORM = {};

@connect(
  (state) => ({
    schemaDimensionColumns: selectors.getSelectedDataStream(state).schema.columns.filter((i) => i.type === 'dimension'),
    schemaMetricColumns: selectors.getSelectedDataStream(state).schema.columns.filter((i) => i.type === 'metric'),
    transformFunctions: selectors.getTransformFunctionsItemsView(state),
  }),
  {
    createStreamSchemaNewColumn,
    fetchTransformFunctions,
  },
)
export default class NewColumnModal extends React.PureComponent {
  props: PropTypes;

  state = {
    transform: EMPTY_TRANSFORM,
    inputs: [],
    colName: '',
    value: '',
    aggregation: null,
    isValid: false,
  };

  componentDidMount() {
    this.props.fetchTransformFunctions();
  }

  componentDidUpdate() {
    this.setIsValid();
  }

  handleClose = (isOk) => {
    if (isOk) {
      const newCol = {
        colName: this.state.colName,
        colType: this.props.colType,
      };
      const transform = {
        name: this.state.transform.name,
        parameters: !(this.state.transform.type === 'BinaryOperation') ? [this.state.value] : [],
      };
      if (this.state.transform.name === 'concat' || this.state.transform.type === 'BinaryOperation') {
        transform.input = this.state.inputs.map((input) => pick(input, ['sourceColumn', 'transform']));
      }

      if (this.state.transform.type === 'Copy') {
        newCol.sourceColumn = this.state.inputs[0].sourceColumn;
      } else {
        newCol.transform = transform;
      }

      if (this.props.colType === 'metric') {
        newCol.targetType = this.state.aggregation;
      }

      this.props.createStreamSchemaNewColumn(newCol);
    } else {
      this.setState({
        transform: EMPTY_TRANSFORM,
        inputs: [],
        colName: '',
        value: '',
        aggregation: null,
        isValid: false,
      });
    }
    this.props.onClose();
  };

  setIsValid = () => {
    const {colType} = this.props;
    let isValid = false;

    if (this.state.transform !== EMPTY_TRANSFORM && this.state.colName !== '') {
      switch (this.state.transform.name) {
        case 'concat':
          isValid = true;
          for (let i = 0; i < this.state.inputs.length; i++) {
            if (isEmpty(this.state.inputs[i])) {
              isValid = false;
              break;
            }
          }
          break;
        case 'const':
          isValid = this.state.value !== '';
          if (
            colType === 'metric' &&
            (this.state.value === '-' || this.state.value[this.state.value.length - 1] === '.')
          ) {
            isValid = false;
          }
          break;
        case 'copy':
          isValid = true;
          if (isEmpty(this.state.inputs[0])) {
            isValid = false;
            break;
          }
          break;

        default:
          break;
      }

      if (this.state.transform.type === 'BinaryOperation') {
        isValid = true;
        for (let j = 0; j < this.state.inputs.length; j++) {
          if (isEmpty(this.state.inputs[j])) {
            isValid = false;
            break;
          }
        }
      }
    }

    if (colType === 'metric' && !this.state.aggregation) {
      isValid = false;
    }

    if (isValid !== this.state.isValid) {
      this.setState({
        isValid,
      });
    }
  };

  nameChanged = (val) => {
    this.setState({colName: val});
  };

  valueChanged = (val) => {
    this.setState({value: val});
  };

  valueNumberChanged = (val) => {
    const re = new RegExp('^-?\\d*\\.?\\d*$');
    if (val !== '' && (val === '-' || re.test(val))) {
      this.setState({value: val});
    } else {
      this.setState({value: ''});
    }
  };

  aggregationChanged = (val) => {
    this.setState({aggregation: val});
  };

  onSelectFuncDropdown = (eventKey) => {
    let inputs = [];
    if (eventKey.type === 'Copy') {
      inputs = [{}];
    } else if (eventKey.type === 'BinaryOperation' || eventKey.name === 'concat') {
      inputs = [{}, {}];
    }
    this.setState({
      transform: eventKey,
      value: '',
      inputs,
    });
  };

  onSelectColumnDropdown = (eventKey, index) => {
    this.setState((prevState) => ({
      inputs: reduceArrayItem(reducerHelper, prevState.inputs, index, {...eventKey}),
    }));
  };

  removeColumn = (index) => {
    this.state.inputs.splice(index, 1);
    this.setState((prevState) => ({
      inputs: [...prevState.inputs],
    }));
  };

  addColumn = () => {
    this.setState((prevState) => ({
      inputs: [...prevState.inputs, {}],
    }));
  };

  getDeviderItem = (transform) => {
    switch (transform.name) {
      case 'add':
        return <span className={style.bigDevider}>+</span>;
      case 'subtract':
        return <span className={style.bigDevider}> -</span>;
      case 'multiply':
        return <span className={style.smallDevider}>X</span>;
      case 'divide':
        return <span className={style.smallDevider}>/</span>;
      default:
        return null;
    }
  };

  render() {
    const {isOpen, onClose, transformFunctions, schemaDimensionColumns, schemaMetricColumns, colType} = this.props;
    const {colName, value, transform, aggregation} = this.state;
    const isMeasureCol = colType === 'metric';
    const filteredTransFunctions = isMeasureCol
      ? transformFunctions.filter((i) => i.type === 'BinaryOperation' || i.type === 'Generate')
      : transformFunctions.filter((i) => i.type === 'Generate' || i.type === 'Join' || i.type === 'Copy');
    const selectedIndex = filteredTransFunctions.findIndex((val) => val.name === this.state.transform.name);
    return (
      <div className={style.newColumn}>
        <Modal
          show={isOpen}
          dialogClassName="bc overflow-override modal-body-right-zero"
          onHide={onClose}
          bsSize="large"
        >
          <Modal.Header bsClass="bc-modal-header">
            <Modal.Title>
              <span styleName={isMeasureCol ? 'property what-property' : 'property'}>New Column</span>
              <span styleName="action">
                Add
                {isMeasureCol ? 'Measure' : 'Dimension'} Column
              </span>
            </Modal.Title>
            <button
              type="button"
              className="btn btn-flat btn-icon-36 btn-secondary"
              onClick={() => this.handleClose(false)}
            >
              <i className="icon icn-icon-table-delete" />
            </button>
          </Modal.Header>

          <Modal.Body>
            <div className={style.transformInner + (isMeasureCol ? ` ${style.newMeasureColumn}` : '')}>
              <div className={style.transformRow}>
                <SelectAndt
                  automation-id="aggregationDDL"
                  id="ddl-tras-create-func"
                  automationId="dataCollectorNewColumn"
                  className={`${style.transformFuncDdl} andt-dropdown`}
                  options={filteredTransFunctions}
                  getOptionLabel={(val) => val.displayName || val.name}
                  getOptionValue={(val) => val.name}
                  value={filteredTransFunctions[selectedIndex]}
                  type={TYPE_NO_SEARCH}
                  theme={THEME_GREEN}
                  onChange={(item) => this.onSelectFuncDropdown(item)}
                  placeholder="Choose"
                  customComponent={{
                    Option: (p) => (
                      <components.Option {...p}>
                        <SmartTooltip content={p.data.description}>
                          <div>{p.data.displayName || p.data.name}</div>
                        </SmartTooltip>
                      </components.Option>
                    ),
                  }}
                />

                <input
                  type="text"
                  automation-id="dataCollectorNewColumnNameTextbox"
                  className={style.newColumnNameInput}
                  required
                  onChange={(e) => this.nameChanged(e.target.value)}
                  placeholder="New Column Name"
                  value={colName}
                />
              </div>
              {transform.name === 'const' && isMeasureCol && (
                <div className={style.transformRow}>
                  <input
                    type="text"
                    automation-id="dataCollectorNewColumnNameTextbox"
                    className={style.newColumnValueInput}
                    required
                    onInput={(e) => this.valueNumberChanged(e.target.value)}
                    placeholder="Insert a numeric constant value"
                    value={value}
                  />
                </div>
              )}
              {transform.name === 'const' && !isMeasureCol && (
                <div className={style.transformRow}>
                  <input
                    type="text"
                    automation-id="dataCollectorNewColumnNameTextbox"
                    className={style.newColumnValueInput}
                    required
                    onChange={(e) => this.valueChanged(e.target.value)}
                    placeholder="Write freely your text for cells"
                    value={value}
                  />
                </div>
              )}
              {transform.name === 'concat' && (
                <span>
                  <div className={style.joinWrapper}>
                    <input
                      type="text"
                      className={style.delimeterInput}
                      required
                      onChange={(e) => this.valueChanged(e.target.value)}
                      placeholder="Add Delimiter"
                      value={value}
                    />
                    <div className={style.transformRow}>
                      {this.state.inputs.map((input, i) => (
                        <SingleColumnDdl
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          index={i}
                          inputs={this.state.inputs}
                          columns={schemaDimensionColumns}
                          onSelect={this.onSelectColumnDropdown}
                          removeColumn={this.removeColumn}
                        />
                      ))}
                    </div>
                  </div>
                  <div
                    className={`${style.transformRow} ${style.addColumnButtonRow}${
                      this.state.inputs.length >= schemaDimensionColumns.length ? ` ${style.invisible}` : ''
                    }`}
                  >
                    <button
                      type="button"
                      className="btn btn-flat btn-icon"
                      automation-id="dataCollectorNewColumnCreateButton"
                      disabled={this.state.inputs.length >= schemaDimensionColumns.length}
                      onClick={() => this.addColumn()}
                    >
                      <i className={`icon icn-icon-white-plus ${style.plusIcon}`} />
                    </button>
                  </div>
                </span>
              )}
              {transform.type === 'BinaryOperation' && (
                <span>
                  <div className={style.joinWrapper}>
                    <div className={style.transformRow}>
                      {this.state.inputs.map((input, i) => (
                        <SingleColumnDdl
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          index={i}
                          deviderItem={this.getDeviderItem(transform)}
                          inputs={this.state.inputs}
                          columns={schemaMetricColumns}
                          onSelect={this.onSelectColumnDropdown}
                          removeColumn={this.removeColumn}
                        />
                      ))}
                    </div>
                  </div>
                </span>
              )}
              {transform.type === 'Copy' && (
                <span>
                  <div className={style.joinWrapper}>
                    <div className={style.transformRow}>
                      <SingleColumnDdl
                        // eslint-disable-next-line react/no-array-index-key
                        key={0}
                        index={0}
                        inputs={this.state.inputs}
                        columns={schemaDimensionColumns}
                        onSelect={this.onSelectColumnDropdown}
                        removeColumn={this.removeColumn}
                      />
                    </div>
                  </div>
                </span>
              )}

              {isMeasureCol && (
                <div className={style.transformRow}>
                  <AggregationDdl
                    aggregation={aggregation}
                    styleName="aggregation-ddl in-modal"
                    onSelect={this.aggregationChanged}
                  />
                </div>
              )}
            </div>
          </Modal.Body>

          <Modal.Footer className={style.footer}>
            <button
              automation-id="createNewColumnButton"
              type="button"
              className={`btn btn-raised btn-outline ${style.transformBtn}`}
              disabled={!this.state.isValid}
              onClick={() => this.handleClose(true)}
            >
              CREATE NEW COLUMN
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const ColumnDdl = (props: {index: number, inputs: Array, columns: Array, onSelect: Function}) => {
  const selectedIndex = props.columns.findIndex((val) => props.inputs[props.index].name === val.name);
  return (
    <SelectAndt
      id={`ddl-column-${props.index}`}
      automationId="columnDdl"
      className={style.columnDdl}
      options={props.columns}
      getOptionLabel={(val) => val.name}
      getOptionValue={(val) => val.name}
      type={TYPE_NO_SEARCH}
      theme={THEME_LIGHT}
      value={props.columns[selectedIndex]}
      onChange={(item) => props.onSelect(item, props.index)}
      placeholder="Column"
    />
  );
};

const SingleColumnDdl = (props: {
  index: number,
  inputs: Array,
  columns: Array,
  onSelect: Function,
  removeColumn: Function,
  deviderItem: Node,
}) => (
  <span className={style.singleColumnRoot}>
    <ColumnDdl index={props.index} inputs={props.inputs} columns={props.columns} onSelect={props.onSelect} />
    <button
      type="button"
      className={`btn btn-flat btn-icon${props.index < 2 ? ` ${style.invisible}` : ''}`}
      onClick={() => props.removeColumn(props.index)}
    >
      <i className={`icon icn-icon-white-close ${style.xIcon}`} automation-id="streamTableNewColumnIcon" />
    </button>
    {props.deviderItem && props.index === 0 ? <div className={style.deviderWrapper}>{props.deviderItem}</div> : null}
  </span>
);
