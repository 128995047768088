// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import Input from 'common/componentsV2/Input';
import Button, {COLORS} from 'common/componentsV2/Button';
import * as selector from 'profile/store/selectors';
import {updateUserNameOnly} from 'profile/store/actions';
import './MandatoryNameModal.module.scss';

type PropTypes = {
  isOpen: boolean,
  meId: string,
  updateUserNameOnly: Function,
};

@connect(
  (state) => ({
    meId: selector.getProfileId(state),
    isOpen: selector.getShowMandatoryRenameModal(state),
  }),
  {
    updateUserNameOnly,
  },
)
export default class MandatoryNameModal extends React.PureComponent {
  props: PropTypes;

  state = {
    firstName: '',
    lastName: '',
  };

  firstNameChange = (e) => {
    this.setState({firstName: e.target.value});
  };

  lastNameChange = (e) => {
    this.setState({lastName: e.target.value});
  };

  onModalClose = () => {};

  continueClicked = () => {
    const {firstName, lastName} = this.state;
    this.props.updateUserNameOnly({
      id: this.props.meId,
      firstName,
      lastName,
    });
  };

  render() {
    const {firstName, lastName} = this.state;

    return (
      <Modal
        classStyle="group-modal"
        id="group-modal"
        isStatic
        isOpen={this.props.isOpen}
        onClose={this.onModalClose}
        isCloseButtonHidden
        size={SIZES.SMALL}
      >
        <div styleName="root">
          <div className="andt-welcome-banner" />
          <div styleName="title">Welcome to Anodot!</div>
          <div styleName="sub-title">We just need your name to continue</div>
          <Input styleName="input-pos" value={firstName} onChange={this.firstNameChange} placeHolder="First Name" />
          <Input styleName="input-pos" value={lastName} onChange={this.lastNameChange} placeHolder="Last Name" />
          <div styleName="footer">
            <Button
              isDisabled={!firstName || !lastName}
              colorSchema={COLORS.BLUE_500}
              text="Continue"
              onClick={this.continueClicked}
            />
          </div>
        </div>
      </Modal>
    );
  }
}
