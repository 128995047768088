import React from 'react';
import ChannelName from '../fields/ChannelName';
import Url from '../fields/Url';
import TimeZone from '../fields/Timezone';
import Members from '../fields/Members';
import Email from '../fields/Email';
import User from '../fields/User';
import Password from '../fields/Password';
import JiraUser from '../fields/JiraUser';
import AccessToken from '../fields/AccessToken';
import IntegrationApiKey from '../fields/IntegrationApiKey';
import ServiceKey from '../fields/ServiceKey';
import Topic from '../fields/Topic';
import Authentication from '../fields/Authentication';
import UrlDropdown from '../fields/UrlDropdown';
import JiraProjectId from '../fields/JiraProjectId';
import JiraIssueType from '../fields/JiraIssueType';
import Test from './Test';

const StandartChannelSettings = ({channelType}: {channelType: string}) => {
  let channelConnectionInput;
  if (channelType === 'pagerduty') {
    channelConnectionInput = <ServiceKey />;
  } else if (channelType === 'sns') {
    channelConnectionInput = <Topic />;
  } else {
    channelConnectionInput = <Url channelType={channelType} />;
  }
  return (
    <React.Fragment>
      <div className="mb_2-5">
        <ChannelName />
      </div>
      <div className="mb_2-5">{channelConnectionInput}</div>
      <div className="display_flex mb_7">
        <div className="flexBasis_50 mr_3">
          <TimeZone />
        </div>
        <div className="flexBasis_50" />
      </div>
      <div className="mb_7">
        <Test channelType={channelType} />
      </div>
    </React.Fragment>
  );
};

const EmailChannelSettings = ({channelType}: {channelType: string}) => {
  return (
    <React.Fragment>
      <div className="mb_2-5">
        <ChannelName />
      </div>
      <div className="display_flex mb_2-5">
        <div className="flexBasis_50 mr_3">
          <Members />
        </div>
        <div className="flexBasis_50" />
      </div>
      <div className="mb_2-5">
        <Email />
      </div>
      <div className="display_flex mb_7">
        <div className="flexBasis_50 mr_3">
          <TimeZone />
        </div>
        <div className="flexBasis_50" />
      </div>
      <div className="mb_7">
        <Test channelType={channelType} />
      </div>
    </React.Fragment>
  );
};

const WebhookChannelSettings = ({channelType}: {channelType: string}) => {
  return (
    <React.Fragment>
      <div className="mb_2-5">
        <ChannelName />
      </div>
      <div className="mb_2-5">
        <Url channelType={channelType} />
      </div>
      <div className="display_flex mb_2-5">
        <div className="flexBasis_50 mr_3">
          <TimeZone />
        </div>
        <div className="flexBasis_50" />
      </div>
      <div className="mb_7">
        <Authentication>
          <div className="mb_2-5">
            <div>User</div>
            <User />
          </div>
          <div className="mb_2-5">
            <div>Password</div>
            <Password />
          </div>
        </Authentication>
      </div>
      <div className="mb_7">
        <Test channelType={channelType} />
      </div>
    </React.Fragment>
  );
};

const JIRAChannelSettings = ({
  channelType,
  initialConnectionStatus,
  isCreate,
}: {
  channelType: string,
  initialConnectionStatus: boolean,
  isCreate: boolean,
}) => {
  return (
    <React.Fragment>
      <div className="mb_2-5">
        <ChannelName />
      </div>
      <div className="mb_2-5">
        <Url channelType={channelType} isCreate={isCreate} />
      </div>
      <div className="display_flex mb_2-5">
        <div className="mr_3">
          <JiraUser isCreate={isCreate} />
        </div>
        <AccessToken isCreate={isCreate} />
      </div>
      <div className={`display_flex ${initialConnectionStatus ? 'mb_2-5' : 'mb_7'}`}>
        <div className="flexBasis_50 mr_3">
          <TimeZone />
        </div>
        <div className="flexBasis_50" />
      </div>
      {initialConnectionStatus && (
        <React.Fragment>
          <div className="display_flex mb_7">
            <div className="flexBasis_50 mr_3">
              <JiraProjectId />
            </div>
            <div className="flexBasis_50">
              <JiraIssueType />
            </div>
          </div>
          <div className="mb_7">
            <Test channelType={channelType} />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const OpsgenieChannelSettings = ({channelType, isCreate}: {channelType: string, isCreate: boolean}) => {
  return (
    <React.Fragment>
      <div className="mb_2-5">
        <ChannelName />
      </div>
      <div className="mb_2-5">
        <UrlDropdown />
      </div>
      <div className="mb_2-5">
        <IntegrationApiKey isCreate={isCreate} />
      </div>
      <div className="display_flex mb_7">
        <div className="flexBasis_50 mr_3">
          <TimeZone />
        </div>
        <div className="flexBasis_50" />
      </div>
      <div className="mb_7">
        <Test channelType={channelType} />
      </div>
    </React.Fragment>
  );
};

export default {
  tamtam: StandartChannelSettings,
  sns: StandartChannelSettings,
  msteams: StandartChannelSettings,
  slack: StandartChannelSettings,
  slackapp: StandartChannelSettings,
  pagerduty: StandartChannelSettings,
  email: EmailChannelSettings,
  webhook: WebhookChannelSettings,
  jira: JIRAChannelSettings,
  opsgenie: OpsgenieChannelSettings,
};
