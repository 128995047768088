import {cloneDeep} from 'lodash';
import {appInit, addLoading, removeLoading, fetchConfigurationFile} from './actions';
import {composeReducers} from '../utils/reducers';

export function reducer(state = {}, {type}) {
  switch (type) {
    case appInit.TYPE:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function outstandingLoadingBarRequests(state = {}, {type, payload}) {
  switch (type) {
    case addLoading.TYPE:
      return {...state, [payload.typePrefix]: true};
    case removeLoading.TYPE:
      return {...state, [payload.typePrefix]: false};
    default:
      return state;
  }
}

export function configurationsData(
  state = {
    configurationFile: [],
  },
  {type, payload, meta},
) {
  switch (type) {
    case fetchConfigurationFile.success.TYPE: {
      const configurationFileIndex = state.configurationFile.findIndex((ele) => ele.name === meta);
      if (configurationFileIndex === -1) {
        return {
          ...state,
          configurationFile: [...state.configurationFile, {name: meta.name, data: payload}],
        };
      }
      const newConfiguration = cloneDeep(state.configurationFile);
      newConfiguration[configurationFileIndex].data = payload;
      return {
        ...state,
        configurationFile: newConfiguration,
      };
    }
    default:
      return state;
  }
}

export default composeReducers(reducer, {outstandingLoadingBarRequests}, {configurationsData});
