// @flow
import React, {Fragment, PureComponent} from 'react';
import {connect} from 'react-redux';
import {isEqual} from 'lodash';
import {
  IsMoreFilterOpen as setAlertFiltersIsMoreAction,
  updateFilterBtn as updateFilterBtnAction,
  updateQueryParams as updateQueryParamsAction,
} from 'alerts.console/store/actions';
import * as alertsConsoleSelectors from 'alerts.console/store/selectors';
import {Expandable} from 'common/componentsV2/ExpandableSections';
import FiltersPanel from 'common/componentsV2/FiltersPanel';
import SearchBox from 'common/componentsV2/SearchBox';
import AlertStatusFilter from './availableFilters/AlertStatusFilter';
import AckFilter from './availableFilters/AckFilter';
import DateRangeFilter from './availableFilters/DateRangeFilter';
import ChannelsFilter from './availableFilters/ChannelsFilter';
import TypeFilter from './availableFilters/TypeFilter';
import SeverityFilter from './availableFilters/SeverityFilter';
import FeedbackPositiveFilter from './availableFilters/FeedbackPositiveFilter';
import FeedbackNegativeFilter from './availableFilters/FeedbackNegativeFilter';
import LabelsFilter from './availableFilters/LabelsFilter';

import './AlertsConsoleFilters.module.scss';

type PropTypes = {
  queryParams: Object,
  defaultQueryParams: Object,
  setQueryParams: Function,
  isFilteredFromEmail: Boolean,
  // connect
  isOpen: Boolean,
  isMoreFilterOpen: Boolean,
  dataQueryParams: Object,
  updateFilterBtn: Function,
  updateQueryParams: Function,
  IsMoreFilterOpen: Function,
};

@connect(
  (state) => ({
    isOpen: alertsConsoleSelectors.getIsFiltersBarOpen(state),
    dataQueryParams: alertsConsoleSelectors.getQueryParamsData(state),
    isMoreFilterOpen: alertsConsoleSelectors.getIsMoreFilterOpen(state),
  }),
  {
    updateFilterBtn: updateFilterBtnAction,
    updateQueryParams: updateQueryParamsAction,
    IsMoreFilterOpen: setAlertFiltersIsMoreAction,
  },
)
export default class FilterToolbar extends PureComponent {
  props: PropTypes;

  state = {
    isFiltersChanges: false,
  };

  componentDidUpdate(prevProps, prevState) {
    this.checkIfFiltersChange(this.props, prevState);
  }

  checkIfFiltersChange = (props, state) => {
    const savedQueryParams = {
      ...props.defaultQueryParams,
      ...props.dataQueryParams,
    };
    const isQueryParamsChanged = !isEqual(savedQueryParams, props.queryParams);
    if (state.isFiltersChanges !== isQueryParamsChanged) {
      this.setState({isFiltersChanges: isQueryParamsChanged});
    }
  };

  resetFilters = (resetToManufacturer) => {
    const {defaultQueryParams, dataQueryParams, setQueryParams, updateQueryParams} = this.props;
    if (resetToManufacturer) {
      // View only
      setQueryParams(defaultQueryParams);
      // Save in Data
      updateQueryParams(true);
    } else {
      const mergedQueryParams = {
        ...defaultQueryParams,
        ...dataQueryParams,
      };
      // View only
      setQueryParams(mergedQueryParams);
    }
  };

  onSaveViewValueChange = (id) => {
    if (id === 'reset') {
      this.resetFilters(true);
    } else {
      this.props.updateQueryParams();
    }
  };

  onFilter = (val) => {
    this.setState({}, () => this.props.setQueryParams({searchQuery: val}));
  };

  onMoreFiltersClicked = (isOn) => {
    this.props.IsMoreFilterOpen(isOn);
  };

  render() {
    const {queryParams, setQueryParams, isFilteredFromEmail, isOpen, updateFilterBtn, isMoreFilterOpen} = this.props;
    const {isFiltersChanges} = this.state;

    return (
      <div styleName="root">
        <FiltersPanel
          isOpen={isOpen}
          onClose={() => updateFilterBtn(true)}
          isClearAll={isFiltersChanges && !isFilteredFromEmail}
          onClearAll={() => this.resetFilters(false)}
          isSaveView={!isFilteredFromEmail}
          onSaveViewChange={this.onSaveViewValueChange}
        >
          <div styleName="filters-row">
            <SearchBox onFilter={this.onFilter} filter={queryParams.searchQuery} placeHolder="Search Alerts" />
          </div>

          <div styleName="filters-row mid">
            <span styleName="filters-row-header">Started During</span>
            <DateRangeFilter setQueryParams={setQueryParams} />
          </div>

          <div styleName="filters-row mid">
            <AlertStatusFilter setQueryParams={setQueryParams} />
          </div>

          <div styleName="filters-row mid">
            <span styleName="filters-row-header">Recipient (Users / Channels)</span>
            <ChannelsFilter setQueryParams={setQueryParams} />
          </div>

          <div styleName="filters-row mid">
            <span styleName="filters-row-header">Acknowledge</span>
            <AckFilter setQueryParams={setQueryParams} />
          </div>

          <Expandable
            automationId="infoAndRecipients"
            label="More Filters"
            expanded={isMoreFilterOpen}
            onChange={this.onMoreFiltersClicked}
            reverse
          >
            <Fragment>
              <div styleName="filters-row mid" style={{marginTop: 20}}>
                <span styleName="filters-row-header">Alert Labels</span>
                <LabelsFilter setQueryParams={setQueryParams} />
              </div>

              <div styleName="filters-row mid feedback-positive">
                <FeedbackPositiveFilter setQueryParams={setQueryParams} />
              </div>

              <div styleName="filters-row mid">
                <FeedbackNegativeFilter setQueryParams={setQueryParams} />
              </div>

              <div styleName="filters-row double" style={{marginBottom: 0}}>
                <div styleName="small">
                  <span styleName="filters-row-header">Alert Type</span>
                  <TypeFilter setQueryParams={setQueryParams} />
                </div>

                <div styleName="small">
                  <span styleName="filters-row-header">Severity</span>
                  <SeverityFilter setQueryParams={setQueryParams} />
                </div>
              </div>
            </Fragment>
          </Expandable>
        </FiltersPanel>
      </div>
    );
  }
}
