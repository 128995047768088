import {combineEpics} from 'redux-observable';
import {broadcast, mergeFilterWithDefinition} from 'common/utils/angularServices';
import * as selectors from 'alerts.management/store/selectors';
import * as metricsSelectors from 'metrics/store/selectors';
import {isEqual, get, difference} from 'lodash';
import * as api from 'alerts.management/services/api';
import {Observable} from 'rxjs/Observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as profileSelector from 'profile/store/selectors';
import * as metricActions from 'metrics/store/actions';
import * as actions from '../actions';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import {alertConditionTypes, getContextConditionsForType} from '../../services/alertsService';

const angularEventName = 'e:andt.metrics.alerts.updateFiltersFromReact';

const getVolumeCondition = makeAsyncEpic(actions.getVolumeCondition, api.getVolumeCondition);

// TODO: Remove later
const isNewAlertEditor = () =>
  location.hash.startsWith('#!/r/alert-manager/edit') ||
  location.hash.startsWith('#!/r/alert-manager/clone') ||
  location.hash.startsWith('#!/r/alert-manager/new');

const isDashboard = () => location.hash.startsWith('#!/r/dashboard');
const isMetricSearch = () => location.hash.startsWith('#!/r/metrics-explorer');

const setValueForDisplay = (value) => {
  if (value || value === 0) {
    return Number(value)
      .toFixed(2)
      .replace(/\.00$/, '');
  }
  return '';
};

const createVolumeCondition = (res, currentVolumeCondition, isSimpleAlert) => {
  if (!res) {
    return {
      type: alertConditionTypes.volumeCondition,
      invalidCondition: true,
      enabled: false,
      enableAutoTuning: true,
      numLastPoints: null,
      rollup: null,
      bound: null,
      value: null,
      errorMsg: get(res, 'volumeConditionValidation.failures[0].message'),
    };
  }
  const autoValues = {
    type: alertConditionTypes.volumeCondition,
    numLastPoints: res.numLastPoints,
    rollup: res.rollup,
    bound: res.bound,
    value: setValueForDisplay(res.value),
  };
  // Not Valid from now or Not Valid from before.
  if (res && !res.passed) {
    return {
      ...autoValues,
      invalidCondition: true,
      enabled: false,
      enableAutoTuning: true,
      errorMsg: get(res, 'volumeConditionValidation.failures[0].message'),
    };
  }
  // Valid from Simple
  if (isSimpleAlert) {
    return autoValues;
  }
  // Valid from Advanced
  return {
    ...autoValues,
    enabled: currentVolumeCondition ? currentVolumeCondition.enabled : true,
    enableAutoTuning: currentVolumeCondition ? currentVolumeCondition.enableAutoTuning : true,
  };
};

const filterChanged = (action$, {getState}) =>
  action$
    .ofType(actions.selectedAlertFilterChanged.TYPE)
    .debounceTime(200)
    .distinctUntilChanged((x, y) => isEqual(x.payload.filter, y.payload.filter))
    .flatMap((action) => {
      if (action.payload.skipValidation) {
        broadcast(angularEventName, action.payload);
        return [];
      }
      const newPayload = mergeFilterWithDefinition(
        action.payload.filter,
        selectors.getSelectedAlertDefinition(getState()),
      );
      const autoVolumeEnabled = profileSelector.getAutoVolumeEnabled(getState());

      let newPayloadEnabledConditionsOnly = [...newPayload.data.conditions];
      if (!autoVolumeEnabled) {
        newPayloadEnabledConditionsOnly = newPayload.data.conditions.filter(
          (con) => con.type !== alertConditionTypes.volumeCondition,
        );
      }
      if (isNewAlertEditor()) {
        newPayload.data.expressionTreeModel = metricsSelectors.getExpressionTreeModelPayload(getState());
        const contextConditions = getContextConditionsForType(action.payload.filter.type.value);
        const stateConditions = newPayloadEnabledConditionsOnly.map((condition) => condition.type);
        const toRemove = difference(stateConditions, contextConditions);
        newPayloadEnabledConditionsOnly = newPayloadEnabledConditionsOnly.filter(
          (condition) => !toRemove.includes(condition.type),
        );
      }
      if (
        !newPayload.data.anomalyAlert ||
        (newPayload.data.anomalyAlert && newPayloadEnabledConditionsOnly.length > 1)
      ) {
        return [
          actions.validateSelectedAlertConditions({
            ...newPayload,
            data: {
              ...newPayload.data,
              conditions: newPayloadEnabledConditionsOnly,
            },
          }),
        ];
      }
      return [];
    });

const initiateValidateSelectedAlertConditions = (action$, {getState}) =>
  action$
    .ofType(actions.initiateValidateSelectedAlertConditions.TYPE)
    .debounceTime(200)
    .flatMap(() => {
      const simFilters = selectors.getSelectedAlertSimulationFilters(getState());
      const newPayload = mergeFilterWithDefinition(simFilters, selectors.getSelectedAlertDefinition(getState()));
      const autoVolumeEnabled = profileSelector.getAutoVolumeEnabled(getState());

      let newPayloadEnabledConditionsOnly = [...newPayload.data.conditions];
      if (!autoVolumeEnabled) {
        newPayloadEnabledConditionsOnly = newPayload.data.conditions.filter(
          (con) => con.type !== alertConditionTypes.volumeCondition,
        );
      }
      if (isNewAlertEditor()) {
        newPayload.data.expressionTreeModel = metricsSelectors.getExpressionTreeModelPayload(getState());
        const contextConditions = getContextConditionsForType(simFilters.type.value);
        const stateConditions = newPayloadEnabledConditionsOnly.map((condition) => condition.type);
        const toRemove = difference(stateConditions, contextConditions);
        newPayloadEnabledConditionsOnly = newPayloadEnabledConditionsOnly.filter(
          (condition) => !toRemove.includes(condition.type),
        );
      }
      if (
        !newPayload.data.anomalyAlert ||
        (newPayload.data.anomalyAlert && newPayloadEnabledConditionsOnly.length > 1)
      ) {
        return [
          actions.validateSelectedAlertConditions({
            ...newPayload,
            data: {
              ...newPayload.data,
              conditions: newPayloadEnabledConditionsOnly,
            },
          }),
        ];
      }
      return [];
    });

const validateSelectedAlertConditions = makeAsyncEpic(
  actions.validateSelectedAlertConditions,
  api.validateSelectedAlertConditions,
);

const validateConditionsSuccess = (action$, {getState}) =>
  action$
    .ofType(actions.validateSelectedAlertConditions.success.TYPE)
    .do((action) => {
      broadcast(angularEventName, {type: 'validation', validateConditionsResult: action.payload});
      if (action.payload.passed) {
        broadcast(angularEventName, {filter: selectors.getSelectedAlertSimulationFilters(getState())});
        // check if filter has the new advanced bool
      }
    })
    .switchMap(() => Observable.empty());

const setSimulationFilters = (action$, {getState}) =>
  action$
    .ofType(
      actions.setSelectedAlertSignificance.TYPE,
      actions.setSelectedAlertMinDuration.TYPE,
      actions.setSelectedAlertMinDurationScale.TYPE,
      actions.setSelectedAlertTimeScale.TYPE,
      actions.setSelectedAlertDirection.TYPE,
      actions.setSelectedAlertStaticThreshold.TYPE,
      // actions.setAdvancedModeOpen.TYPE
      // up to 6 actions only
    )
    .flatMap(() => [
      actions.selectedAlertFilterChanged({filter: selectors.getSelectedAlertSimulationFilters(getState())}),
    ]);

const setSimulationFilters2 = (action$, {getState}) =>
  action$
    .ofType(
      actions.setSelectedAlertType.TYPE,
      actions.setSelectedAlertMinDeltaAbs.TYPE,
      actions.setSelectedAlertMinDeltaPercent.TYPE,
      actions.addConditionToSelectedAlert.TYPE,
      actions.removeConditionFromSelectedAlert.TYPE,
      actions.setSelectedAlertAutoTuneByAnodot.TYPE,
      // up to 6 actions only
    )
    .flatMap((action) => {
      if (get(action, 'meta.isInit')) {
        return [];
      }
      return [actions.selectedAlertFilterChanged({filter: selectors.getSelectedAlertSimulationFilters(getState())})];
    });

const setSimulationFilters3 = (action$, {getState}) =>
  action$
    .ofType(
      actions.setSelectedAlertMinDeltaAutoBoolVal.TYPE,
      // up to 6 actions only
    )
    .flatMap(() => [
      actions.selectedAlertFilterChanged({filter: selectors.getSelectedAlertSimulationFilters(getState())}),
    ]);

const setSimulationFilters4 = (action$, {getState}) =>
  action$
    .ofType(
      actions.setSelectedAlertInfluencingMetrics.TYPE,
      actions.setSelectedAlertInfluencingMetricsSubCondition.TYPE,
      actions.addSelectedAlertInfluencingMetricsSubCondition.TYPE,
      actions.removeSelectedAlertInfluencingMetricsSubCondition.TYPE,
      actions.setSuppress.TYPE,
      // up to 6 actions only
    )
    .flatMap(() => [
      actions.selectedAlertFilterChanged({filter: selectors.getSelectedAlertSimulationFilters(getState())}),
    ]);

const setSimulationFilters5 = (action$, {getState}) =>
  action$
    .ofType(
      actions.setSelectedAlertInfluencingMetricsSubConditionV2.TYPE,
      // up to 6 actions only
    )
    .flatMap(() => [
      actions.selectedAlertFilterChanged({filter: selectors.getSelectedAlertSimulationFilters(getState())}),
    ]);

const setSimulationFiltersByVolumeCon = (action$, {getState}) =>
  action$
    .ofType(
      actions.setVolumeNumOfLastPoints.TYPE,
      actions.setVolumeRollup.TYPE,
      actions.setVolumeEnabled.TYPE,
      actions.setVolumeBound.TYPE,
      actions.setVolumeValue.TYPE,
    )
    .flatMap(() => [
      actions.selectedAlertFilterChanged({filter: selectors.getSelectedAlertSimulationFilters(getState())}),
    ]);

const setSimulationFiltersByDeltaDuration = (action$, {getState}) =>
  action$
    .ofType(actions.setDeltaDurationNumOfPoints.TYPE, actions.setDeltaDurationEnabled.TYPE)
    .flatMap(() => [
      actions.selectedAlertFilterChanged({filter: selectors.getSelectedAlertSimulationFilters(getState())}),
    ]);

const setShouldShowEvents = (action$, {getState}) =>
  action$.ofType(actions.setSelectedAlertShouldShowEvents.TYPE, actions.setIsInfluencingEvents.TYPE).flatMap(() => {
    const simulationFilters = selectors.getSelectedAlertSimulationFilters(getState());
    return [
      actions.selectedAlertFilterChanged({
        triggerEvents: true,
        skipValidation: true,
        filter: simulationFilters,
      }),
      actions.selectedAlertFilterChanged({filter: simulationFilters}),
    ];
  });

const setNoDataDuration = (action$, {getState}) =>
  action$.ofType(actions.setSelectedAlertNoDataDuration.TYPE).flatMap(() => [
    actions.selectedAlertFilterChanged({
      triggerNoDataDuration: true,
      skipValidation: true,
      filter: selectors.getSelectedAlertSimulationFilters(getState()),
    }),
  ]);

const setUpdatePolicy = (action$, {getState}) =>
  action$.ofType(actions.setSelectedAlertUpdatePolicy.TYPE).flatMap(() => [
    actions.selectedAlertFilterChanged({
      setUpdatePolicy: true,
      skipValidation: true,
      filter: selectors.getSelectedAlertSimulationFilters(getState()),
    }),
  ]);

const updateConditionWithoutTriggeringSimulation = (action$, {getState}) =>
  action$
    .ofType(
      actions.setSelectedAlertMaxMetricsAbs.TYPE,
      actions.setSelectedAlertMaxMetricsPercentage.TYPE,
      actions.setSelectedAlertMinMetricsAbs.TYPE,
      actions.setSelectedAlertMinMetricsPercentage.TYPE,
    )
    .flatMap(() => [
      actions.selectedAlertFilterChanged({
        skipValidation: true,
        filter: selectors.getSelectedAlertSimulationFilters(getState()),
      }),
    ]);

const updateNotifyOnlyOpen = (action$, {getState}) =>
  action$.ofType(actions.setSelectedAlertNotifyOnlyOpenBoolVal.TYPE).flatMap(() => [
    actions.selectedAlertFilterChanged({
      updateNotifyOnlyOpen: true,
      skipValidation: true,
      filter: selectors.getSelectedAlertSimulationFilters(getState()),
    }),
  ]);

const setSelectedAlertType = (action$, {getState}) =>
  action$.ofType(actions.setSelectedAlertType.TYPE).flatMap(() => {
    const autoVolumeEnabled = profileSelector.getAutoVolumeEnabled(getState());
    const simulationFilters = selectors.getSelectedAlertSimulationFilters(getState());
    const mergedAlertConfiguration = mergeFilterWithDefinition(
      selectors.getSelectedAlertSimulationFilters(getState()),
      selectors.getSelectedAlertDefinition(getState()),
    );
    if (!autoVolumeEnabled) {
      return [];
    }

    if (isNewAlertEditor()) {
      const expressionTreeModel = metricsSelectors.getExpressionTreeModelPayload(getState());
      const isTreeEmpty = metricsSelectors.getIsTreeEmpty(getState());
      mergedAlertConfiguration.data.expressionTreeModel = expressionTreeModel;
      const volumeCondition = selectors.getVolumeConditions(getState());
      if (!volumeCondition || !volumeCondition.enableAutoTuning || isTreeEmpty) {
        return [];
      }
    }

    if (simulationFilters.type.value !== 'anomaly') {
      return [];
    }
    return [actions.getVolumeCondition(mergedAlertConfiguration.data)];
  });

const setDeltaDurationDefaultsWhenChangingMinDuration = (action$, {getState}) =>
  action$
    .ofType(
      actions.setSelectedAlertMinDuration.TYPE,
      actions.setSelectedAlertTimeScale.TYPE,
      actions.setSelectedAlertMinDurationScale.TYPE,
    )
    .flatMap(() => {
      const simulationFilters = selectors.getSelectedAlertSimulationFilters(getState());
      const deltaDurationEnabled = profileSelector.getDeltaDurationEnabled(getState());
      const {minDurationUI} = selectors.getSelectedAlertSimulationFilters(getState());
      const {rollup} = selectors.getSelectedAlertSimulationFilters(getState());

      if (simulationFilters.type.value === 'anomaly' && deltaDurationEnabled) {
        return [
          actions.setDeltaDurationNumOfPoints({
            value: (function() {
              if (rollup === 'short') {
                return 120; // 1 min to 2 min
              }
              if (rollup === 'medium') {
                return 600; // 5 min to 10 min
              }
              if (rollup === 'long') {
                return 7200; // 1 hour to 2 hours
              }
              return minDurationUI.min;
            })(),
            rollup,
          }),
        ];
      }
      return [];
    });

const getAutoVolumeConditionValuesFromChangingTimeScale = (action$, {getState}) =>
  action$
    .ofType(
      actions.setSelectedAlertTimeScale.TYPE,
      metricActions.expressionTreeUpdated.TYPE,
      metricActions.initExpressionTreeModel.TYPE,
    )
    .flatMap((action) => {
      if (isDashboard() || isMetricSearch()) {
        return [];
      }

      const autoVolumeEnabled = profileSelector.getAutoVolumeEnabled(getState());
      const simulationFilters = selectors.getSelectedAlertSimulationFilters(getState());
      const mergedAlertConfiguration = mergeFilterWithDefinition(
        selectors.getSelectedAlertSimulationFilters(getState()),
        selectors.getSelectedAlertDefinition(getState()),
      );

      const loadedAlert = selectors.getSelectedAlertDefinition(getState());

      if (isNewAlertEditor()) {
        const volumeCondition = selectors.getVolumeConditions(getState());
        mergedAlertConfiguration.data.expressionTreeModel = metricsSelectors.getExpressionTreeModelPayload(getState());

        const volumeCond =
          (get(loadedAlert, 'data.conditions') || []).find((cond) => cond.type === 'VOLUME_CONDITION') || {};

        // eslint-disable-next-line max-len
        if (
          action.type === metricActions.initExpressionTreeModel.TYPE &&
          (volumeCond.enableAutoTuning || !volumeCond.enabled) &&
          autoVolumeEnabled
        ) {
          return [actions.getVolumeCondition(mergedAlertConfiguration.data)];
        }

        if (!volumeCondition || (volumeCondition.enabled && !volumeCondition.enableAutoTuning)) {
          return [];
        }
      }

      if (!autoVolumeEnabled) {
        return [];
      }

      if (simulationFilters.type.value === 'anomaly' && action.type !== metricActions.initExpressionTreeModel.TYPE) {
        return [actions.getVolumeCondition(mergedAlertConfiguration.data)];
      }
      return [];
    });

const getAutoVolumeConditionValues = (action$, {getState}) =>
  action$.ofType(actions.setVolumeEnabled.TYPE, actions.setEnableAutoTuning.TYPE).flatMap((action) => {
    const volumeCondition = selectors.getVolumeConditions(getState());
    const simulationFilters = selectors.getSelectedAlertSimulationFilters(getState());

    const mergedAlertConfiguration = mergeFilterWithDefinition(
      selectors.getSelectedAlertSimulationFilters(getState()),
      selectors.getSelectedAlertDefinition(getState()),
    );

    if (action.meta && action.meta.isNewAlertEditor) {
      mergedAlertConfiguration.data.expressionTreeModel = metricsSelectors.getExpressionTreeModelPayload(getState());
    }

    if (simulationFilters.type.value === 'anomaly') {
      if (volumeCondition && volumeCondition.enabled && volumeCondition.enableAutoTuning) {
        return [actions.getVolumeCondition(mergedAlertConfiguration.data)];
      }
      if (!volumeCondition) {
        return [actions.getVolumeCondition(mergedAlertConfiguration.data)];
      }
    }
    return [];
  });

const setAutoVolumeConditionValues = (action$, {getState}) =>
  action$
    .ofType(actions.getVolumeCondition.success.TYPE, actions.disableVolumeCondition.TYPE)
    .flatMap(({payload, meta}) => {
      const currentVolumeCondition = selectors.getVolumeConditions(getState());
      const updatedVolumeCondition = createVolumeCondition(payload, currentVolumeCondition, meta);
      return [actions.setAutoVolumeConditionValues(updatedVolumeCondition)];
    });

const setVolumeCondition = (action$, {getState}) =>
  action$.ofType(actions.setAutoVolumeConditionValues.TYPE).flatMap(() => {
    const alertSimulationFilters = selectors.getSelectedAlertSimulationFilters(getState());
    if (isNewAlertEditor()) {
      return [];
    }
    return [actions.selectedAlertFilterChanged({filter: alertSimulationFilters})];
  });

const simulationFiltersEpic = combineEpics(
  setSelectedAlertType,
  getVolumeCondition,
  setVolumeCondition,
  setDeltaDurationDefaultsWhenChangingMinDuration,
  getAutoVolumeConditionValuesFromChangingTimeScale,
  setAutoVolumeConditionValues,
  setSimulationFiltersByVolumeCon,
  filterChanged,
  initiateValidateSelectedAlertConditions,
  validateSelectedAlertConditions,
  validateConditionsSuccess,
  setSimulationFilters,
  setSimulationFilters2,
  setSimulationFilters3,
  setSimulationFilters4,
  setSimulationFilters5,
  setShouldShowEvents,
  setUpdatePolicy,
  setNoDataDuration,
  updateConditionWithoutTriggeringSimulation,
  updateNotifyOnlyOpen,
  getAutoVolumeConditionValues,
  setSimulationFiltersByDeltaDuration,
);
export default simulationFiltersEpic;
