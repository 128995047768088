import React, {Fragment, useEffect, useMemo, useRef} from 'react';
import './IntegrationsBoxesContainer.module.scss';
import {useQueryParams, StringParam} from 'use-query-params';
import EmptySearch from 'common/componentsV2/EmptySearch';
import {getParsedItemsList} from './IntegrationsPageService';
import IntegrationsBox from './IntegrationsBox';

const EMPTY_ARRAY = [];

type PropTypes = {
  pageConfig: Array,
};

const IntegrationsBoxesContainer = ({pageConfig}: PropTypes) => {
  const pageTop = useRef(null);
  const [query] = useQueryParams({
    selectedCategory: StringParam,
    filterText: StringParam,
    selectedTab: StringParam,
  });

  const {selectedCategory, filterText, selectedTab} = query;

  useEffect(() => {
    if (pageTop && pageTop.current) {
      pageTop.current.scrollIntoView();
    }
  }, [selectedTab, selectedCategory, filterText]);

  const filterList = pageConfig ? pageConfig[selectedTab] : EMPTY_ARRAY;
  const parsedData = getParsedItemsList(filterList);

  const parsedDataFiltered = parsedData.filter((data) => {
    if (selectedCategory === 'all') {
      return true;
    }
    return data.categoryName === selectedCategory;
  });

  const parsedByTitle = parsedDataFiltered
    .map((data) => {
      const filteredTitles = data.items.filter(
        (item) => item.name.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1,
      );
      return {...data, items: filteredTitles};
    })
    .filter((data) => data.items.length > 0);

  const shown = useMemo(() => {
    let shownList = [];
    parsedByTitle.forEach((pd) => {
      shownList = shownList.concat(pd.items);
    });
    return shownList.length;
  }, [parsedData]);

  const total = useMemo(() => {
    if (filterList && filterList.sourcesItems) {
      return filterList.sourcesItems.length;
    }
    return 0;
  }, [filterList]);

  const filterByTitle = useMemo(() => {
    if (filterList && filterList.sourcesItems) {
      return filterList.sourcesItems.filter(
        (item) => item.name.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1,
      );
    }
    return [];
  }, [filterText, filterList]);

  const renderInnerList = (group) => (
    <div styleName="categories-boxes-container">
      {(group.items || group).map((item) => (
        <IntegrationsBox
          key={item.type}
          isFeatured={group.categoryName === 'featured'}
          boxData={item}
          isChannel={selectedTab === 'channels'}
        />
      ))}
    </div>
  );

  return (
    <Fragment>
      <div ref={pageTop} />
      {filterText === '' ? null : <div styleName="search-results">{`Search Results (${shown}/${total})`}</div>}
      {parsedByTitle.length === 0 && (
        <EmptySearch
          error={`No ${(selectedTab || '').charAt(0).toUpperCase() + (selectedTab || '').slice(1)} to Show`}
          errorExplanation={`Change filters to see ${selectedTab}`}
        />
      )}
      {filterText !== ''
        ? renderInnerList(filterByTitle)
        : parsedDataFiltered.map((category) => (
            // eslint-disable-next-line react/jsx-indent
            <div key={category.categoryName}>
              <div styleName="category-name">{category.categoryTitle}</div>
              {renderInnerList(category)}
            </div>
          ))}
    </Fragment>
  );
};

export default IntegrationsBoxesContainer;
