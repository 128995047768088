import React, {useState, useCallback, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {isEmpty} from 'lodash';
import useHandleResize from 'common/hooks/useHandleResize';
import {fetchAnomalyMetrics, setIncidentFilter} from 'investigation/store/actions';
import {
  getInvAlertMeasures,
  getIncidentTokenMapIsLoading,
  getInvModalTrigger,
  getIncidentTokenMapDataFiltered,
  getFetchAllMetricsIncident,
  getFetchAlertMetricsIncident,
  getInvModalIncidentFilters,
  getIncidentTokenMapCounter,
  getIncidentTokenMapTotal,
} from 'investigation/store/selectors';
import {getTimeZoneName} from 'profile/store/selectors';
import {Box} from '@material-ui/core';
import {palette} from 'app/styles/theme';
import {ExpandableBox} from 'common/componentsV2/ExpandableSections';
import {TypographyBox} from 'common/componentsV2/boxTools';

import Spinner, {SIZES as SpinnerSizes} from 'common/componentsV2/Spinner';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';
import Button, {COLORS as ButtonColors} from 'common/componentsV2/Button';
import ContentLoader from 'common/componentsV2/ContentLoader';

import FiltersChips from 'investigation/components/incident/FiltersChips';
import FakeChevron from 'common/componentsV2/FakeChevron';
import MetricsTable from '../metricsTable/MetricsTable';
import IncidentDetails from './IncidentDetails';
import TokenMapFilters from './TokenMapFilters';

const IncidentTab = () => {
  const style = {
    metricsContainer: {
      marginTop: 8,
      position: 'relative',
      left: 24,
      paddingRight: 24,
      paddingLeft: 1,
      overflowY: 'scroll',
    },
    panelRootStyle: {
      flexShrink: 0,
      padding: 0,
      border: 'none',
      marginTop: 8,
      marginBottom: 16,
    },
    metricLabel: {
      display: 'flex',
      marginTop: 5,
    },
  };

  const dispatch = useDispatch();
  const timeZoneName = useSelector(getTimeZoneName);

  const trigger = useSelector(getInvModalTrigger);
  const alertMeasures = useSelector(getInvAlertMeasures);
  const incidentTokenMap = useSelector(getIncidentTokenMapDataFiltered);
  const isTokenMapLoading = useSelector(getIncidentTokenMapIsLoading);
  const incidentTokenMapMetricsCounter = useSelector(getIncidentTokenMapCounter);
  const incidentTokenMapMetricsTotal = useSelector(getIncidentTokenMapTotal);

  const allMetricsIncident = useSelector(getFetchAllMetricsIncident);
  const alertMetricsIncident = useSelector(getFetchAlertMetricsIncident);

  const filters = useSelector(getInvModalIncidentFilters);

  const [isExpand, setIsExpand] = useState({});
  const [listOrder, setListOrder] = useState({});
  const bodyContainerHeight = useHandleResize([300]);

  const onExpandBoxChange = useCallback(
    (id) => (isExpanded) => {
      setIsExpand((st) => ({...st, [id]: isExpanded}));
    },
    [],
  );

  const onShowMoreMetricsClick = useCallback(
    (what, index, isAlert) => () => {
      dispatch(fetchAnomalyMetrics({what, index, alertId: isAlert ? trigger.id : null, isMore: true, filters}));
    },
    [],
  );

  useEffect(() => {
    if (!isTokenMapLoading && !isEmpty(incidentTokenMap)) {
      alertMeasures.map((what) => {
        dispatch(fetchAnomalyMetrics({what, alertId: trigger.id, filters}));
        dispatch(fetchAnomalyMetrics({what, alertId: null, filters}));
        setIsExpand((st) => ({...st, [`${what}-alert-metrics`]: true}));
      });
    }
  }, [isTokenMapLoading]);

  const isMoreMetrics = (what, isAlert) => {
    const data = isAlert ? alertMetricsIncident : allMetricsIncident;

    return (
      data[what].data.metrics.length <
      data[what].data.metricsCount.total - (isAlert ? 0 : alertMetricsIncident[what].data.metricsCount.total)
    );
  };

  const isMetricsExist = (what) =>
    !!(
      (alertMetricsIncident[what] &&
        !alertMetricsIncident[what].isLoading &&
        alertMetricsIncident[what].data.metrics.length) ||
      (allMetricsIncident[what] && !allMetricsIncident[what].isLoading && allMetricsIncident[what].data.metrics.length)
    );

  const setFilter = (dimension) => {
    if (dimension.id !== 'other') {
      dispatch(setIncidentFilter(dimension));
    }
  };

  return (
    <Grid container>
      <Grid item sm={12}>
        <IncidentDetails />
      </Grid>

      {/* TokenMap */}
      <Grid item sm={4}>
        <TokenMapFilters
          isLoading={isTokenMapLoading}
          tokenMap={incidentTokenMap}
          selectedFilters={filters}
          metricsCounter={incidentTokenMapMetricsCounter}
          totalMetrics={incidentTokenMapMetricsTotal}
          onSelect={setFilter}
        />
      </Grid>

      {/* Metrics */}
      <Grid item sm={8}>
        <Box style={style.metricsContainer} height={bodyContainerHeight}>
          <FiltersChips filters={filters} onSelect={setFilter} />

          {/* Alert Metrics */}
          {Object.keys(alertMetricsIncident).map((what) => {
            const isMetricLoading =
              (alertMetricsIncident[what] && alertMetricsIncident[what].isLoading) ||
              (allMetricsIncident[what] && allMetricsIncident[what].isLoading);
            return (
              <Box mb={3} key={what}>
                {isMetricLoading && <ContentLoader rows={[{width: 90, height: 27}, {width: 415, height: 20}]} />}

                {!isMetricLoading && isMetricsExist(what) && <InnerChip title={what} displayOnly type="measure" />}

                {alertMetricsIncident[what] &&
                  !isMetricLoading &&
                  alertMetricsIncident[what].data &&
                  alertMetricsIncident[what].data.metricsCount && (
                    <Box>
                      {!!alertMetricsIncident[what].data.metrics.length && (
                        <ExpandableBox
                          key={`inc-gr-panel-${what}-alert`}
                          automationId="incWhatGroup"
                          actionElement={<div>actionElement</div>}
                          blockStyle={{marginBottom: '-20px'}}
                          nodeLabel={
                            <TypographyBox variant="subtitle1" color={palette.gray['400']} mt={0.5}>
                              Anomalies in alert{' '}
                              <TypographyBox
                                component="span"
                                color={filters.length ? palette.teal['500'] : palette.gray['400']}
                              >
                                ({alertMetricsIncident[what].data.metricsCount.total})
                              </TypographyBox>
                            </TypographyBox>
                          }
                          expanded={isExpand[`${what}-alert-metrics`]}
                          onChange={onExpandBoxChange(`${what}-alert-metrics`)}
                          rootStyle={style.panelRootStyle}
                        >
                          <MetricsTable
                            metrics={alertMetricsIncident[what].data.metrics}
                            timeZoneName={timeZoneName}
                            listOrder={listOrder}
                            setListOrder={setListOrder}
                          />
                          {alertMetricsIncident[what].isLoadingMore && (
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <Spinner color="#3d4c59" size={SpinnerSizes.MEDIUM_50} />
                            </Box>
                          )}
                          {isMoreMetrics(what, true) && (
                            <Box display="flex" justifyContent="center" pt={1} pb={1}>
                              {!alertMetricsIncident[what].isLoadingMore && (
                                <Button
                                  colorSchema={ButtonColors.TRANSPARENT_BLUE}
                                  text="Show more metrics"
                                  onClick={onShowMoreMetricsClick(
                                    what,
                                    alertMetricsIncident[what].data.metrics.length,
                                    true,
                                  )}
                                />
                              )}
                            </Box>
                          )}
                        </ExpandableBox>
                      )}
                      {!alertMetricsIncident[what].data.metrics.length && (
                        <FakeChevron>
                          <TypographyBox variant="subtitle1" color={palette.gray['400']}>
                            Anomalies in alert
                            <TypographyBox
                              component="span"
                              color={filters.length ? palette.teal['500'] : palette.gray['400']}
                            >
                              {' '}
                              (0)
                            </TypographyBox>
                          </TypographyBox>
                        </FakeChevron>
                      )}
                    </Box>
                  )}

                {/* All Metrics */}
                {isMetricLoading && (
                  <ContentLoader
                    rows={[{width: '100%', height: 40}, {width: '100%', height: 40}, {width: '100%', height: 40}]}
                  />
                )}

                {allMetricsIncident[what] &&
                  !isMetricLoading &&
                  allMetricsIncident[what].data &&
                  allMetricsIncident[what].data.metricsCount &&
                  alertMetricsIncident[what].data.metricsCount && (
                    <Box>
                      {!!allMetricsIncident[what].data.metrics.length && (
                        <ExpandableBox
                          key={`inc-gr-panel-${what}-all`}
                          automationId="incWhatGroup"
                          nodeLabel={
                            <TypographyBox variant="subtitle1" color={palette.gray['400']} mt={0.5}>
                              Anomalies not in the alert{' '}
                              <TypographyBox
                                component="span"
                                color={filters.length ? palette.teal['500'] : palette.gray['400']}
                              >
                                (
                                {allMetricsIncident[what].data.metricsCount.total -
                                  alertMetricsIncident[what].data.metricsCount.total}
                                )
                              </TypographyBox>
                            </TypographyBox>
                          }
                          expanded={isExpand[`${what}-all-metrics`]}
                          onChange={onExpandBoxChange(`${what}-all-metrics`)}
                          rootStyle={style.panelRootStyle}
                        >
                          <MetricsTable
                            metrics={allMetricsIncident[what].data.metrics}
                            timeZoneName={timeZoneName}
                            listOrder={listOrder}
                            setListOrder={setListOrder}
                          />
                          {allMetricsIncident[what].isLoadingMore && (
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <Spinner color="#3d4c59" size={SpinnerSizes.MEDIUM_50} />
                            </Box>
                          )}
                          {isMoreMetrics(what, false) && (
                            <Box display="flex" justifyContent="center" pt={1} pb={1}>
                              {!allMetricsIncident[what].isLoadingMore && (
                                <Button
                                  colorSchema={ButtonColors.TRANSPARENT_BLUE}
                                  text="Show more metrics"
                                  onClick={onShowMoreMetricsClick(
                                    what,
                                    allMetricsIncident[what].data.metrics.length,
                                    false,
                                  )}
                                />
                              )}
                            </Box>
                          )}
                        </ExpandableBox>
                      )}
                      {!allMetricsIncident[what].data.metrics.length && (
                        <FakeChevron>
                          <TypographyBox variant="subtitle1" color={palette.gray['400']}>
                            Anomalies in alert
                            <TypographyBox
                              component="span"
                              color={filters.length ? palette.teal['500'] : palette.gray['400']}
                            >
                              {' '}
                              (0)
                            </TypographyBox>
                          </TypographyBox>
                        </FakeChevron>
                      )}
                    </Box>
                  )}
              </Box>
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
};

export default IncidentTab;
