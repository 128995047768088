// @flow
import React, {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'alerts.management/store/actions';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import * as selectors from 'alerts.management/store/selectors';
import {useField} from 'react-final-form';
import InputAuto from '../simulationArea/utils/InputAuto';
import InputLabel from '../simulationArea/InputLabel';

const UpdatePolicyCondition = () => {
  const {meta} = useField('updatePolicyInterval');

  const {updatePolicyInterval} = useSelector(selectors.getSelectedAlertSimulationFilters);
  const dispatch = useDispatch();
  const onChange = useCallback(
    (value) => {
      dispatch(
        actions.setSelectedAlertUpdatePolicy({
          value,
          enabled: updatePolicyInterval.enabled,
        }),
      );
    },
    [dispatch, updatePolicyInterval.enabled],
  );

  return (
    <React.Fragment>
      <Box mb={1}>
        <InputLabel isSubtitle>Minimum time between updates:</InputLabel>
      </Box>
      <Grid container alignItems="center">
        <Grid item>
          <Box width={82} height={36} mr={1}>
            <InputAuto
              additionalProps={{
                name: 'updatePolicyInterval',
                min: updatePolicyInterval.min,
              }}
              multipleOf={updatePolicyInterval.step}
              fullSize
              delay={1000}
              type="number"
              automationId="updatePolicyInterval"
              className="dark-input"
              value={updatePolicyInterval.value || ''}
              onChange={(e) => onChange(e.target.value)}
              isInvalid={meta.touched && meta.error}
              invalidMessage={meta.error}
            />
          </Box>
        </Grid>
        <Grid item>{updatePolicyInterval.scaleText}</Grid>
      </Grid>
    </React.Fragment>
  );
};

export default UpdatePolicyCondition;
