import * as reducers from 'common/utils/reducers';
import {
  alertTypes,
  getMandatoryConditionsForType,
  alertConditionTypes,
  alertConditions,
  createSubConditionByType,
} from 'alerts.management/services/alertsService';
import {durationScales, resolutionTypes} from 'metrics/services/metricsService';
import {get, difference, isNumber} from 'lodash';
import {getUniqueId} from 'common/utils/guid';
import * as actions from '../actions';

const MIN_DURATION_DEFAULT_VALUE = 600; // 10min

const EMPTY_OBJECT = {};

const getDurationScale = (minDuration) => {
  if (
    minDuration >= durationScales.weeks.min && // fallbacks to previous scales
    minDuration <= durationScales.weeks.max
  ) {
    if (minDuration % durationScales.weeks.seconds === 0) {
      return durationScales.weeks.value;
    }
    if (minDuration % durationScales.days.seconds === 0) {
      return durationScales.days.value;
    }
    if (minDuration % durationScales.hours.seconds === 0) {
      return durationScales.hours.value;
    }
    return durationScales.minutes.value;
  }
  if (
    minDuration >= durationScales.days.min && // fallbacks to previous scales
    minDuration <= durationScales.days.max
  ) {
    if (minDuration % durationScales.days.seconds === 0) {
      return durationScales.days.value;
    }
    if (minDuration % durationScales.hours.seconds === 0) {
      return durationScales.hours.value;
    }
    return durationScales.minutes.value;
  }
  if (
    minDuration >= durationScales.hours.min && //  if the value is between the min and max of an hour
    minDuration <= durationScales.hours.max
  ) {
    if (minDuration % durationScales.hours.seconds === 0) {
      // if it fits exactly to an hour range then set it on hours scale
      return durationScales.hours.value;
    }
    return durationScales.minutes.value; // else fallback to minutes
  }
  if (
    minDuration >= durationScales.minutes.min && // if the value is between the minutes min and max
    minDuration <= durationScales.minutes.max
  ) {
    return durationScales.minutes.value;
  }
  return null;
};

const getUpdatePolicyByRollup = (rollup, enabled) => {
  switch (rollup) {
    case resolutionTypes.short.value:
      return {
        step: 5,
        min: 5,
        value: 5,
        seconds: 60,
        scaleText: 'minutes',
        enabled,
      };
    case resolutionTypes.medium.value:
      return {
        step: 5,
        min: 5,
        value: 15,
        seconds: 60,
        scaleText: 'minutes',
        enabled,
      };
    case resolutionTypes.long.value:
      return {
        step: 5,
        min: 30,
        value: 30,
        seconds: 60,
        scaleText: 'minutes',
        enabled,
      };
    case resolutionTypes.longlong.value:
      return {
        step: 1,
        min: 1,
        value: 3,
        seconds: 60 * 60,
        scaleText: 'hours',
        enabled,
      };
    case resolutionTypes.weekly.value:
      return {
        step: 1,
        min: 1,
        value: 1,
        seconds: 60 * 60 * 24,
        scaleText: 'days',
        enabled,
      };
    default:
      return EMPTY_OBJECT;
  }
};

const getDurationRange = (scale, rollup) => {
  const durationRange = {};

  switch (scale) {
    case durationScales.minutes.value:
      durationRange.step = durationScales.minutes.seconds; // 1m
      if (rollup === resolutionTypes.short.value) {
        // one day{
        durationRange.min = durationScales.minutes.min; // 1m
        durationRange.max = durationScales.minutes.max; // 1m
      } else if (rollup === resolutionTypes.medium.value) {
        // then this is 5 min
        durationRange.min = durationScales.minutes.min * 5; // 5m
        durationRange.max = durationScales.minutes.max; // 100h
        durationRange.step = durationScales.minutes.seconds * 5; // 1m
      } else if (rollup === resolutionTypes.long.value) {
        // then this is an hour
        durationRange.min = durationScales.hours.min; // 1h
        durationRange.max = durationScales.hours.max; // 100h
        durationRange.step = durationScales.hours.seconds; // 1h
      } else if (rollup === resolutionTypes.longlong.value) {
        // one day{
        durationRange.min = durationScales.days.min; // 1d
        durationRange.max = durationScales.days.max; // 7d
        durationRange.step = durationScales.days.seconds; // 1day
      } else if (rollup === resolutionTypes.weekly.value) {
        // one day{
        durationRange.min = durationScales.weeks.min; // 1week
        durationRange.max = durationScales.weeks.max; // 4week
        durationRange.step = durationScales.weeks.seconds; // 1day
      }
      break;

    case durationScales.hours.value:
      durationRange.step = durationScales.hours.seconds; // 1h
      durationRange.min = durationScales.hours.min; // 1h
      durationRange.max = durationScales.hours.max; // 24h

      if (rollup === resolutionTypes.longlong.value) {
        // one day{
        durationRange.min = durationScales.days.min; // 1d
        durationRange.max = durationScales.days.max; // 100d
        durationRange.step = durationScales.days.seconds; // 1day
      } else if (rollup === resolutionTypes.weekly.value) {
        // one day{
        durationRange.min = durationScales.weeks.min; // 1week
        durationRange.max = durationScales.weeks.max; // 4week
        durationRange.step = durationScales.weeks.seconds; // 1week
      }
      break;

    case durationScales.days.value:
      durationRange.step = durationScales.days.seconds; // 1day
      durationRange.min = durationScales.days.min; // 1day
      durationRange.max = durationScales.days.max; // 1day
      if (rollup === resolutionTypes.weekly.value) {
        // one day{
        durationRange.min = durationScales.weeks.min; // 1week
        durationRange.max = durationScales.weeks.max; // 4week
        durationRange.step = durationScales.weeks.seconds; // 1week
      }
      break;

    case durationScales.weeks.value:
      durationRange.min = durationScales.weeks.min; // 1week
      durationRange.max = durationScales.weeks.max; // 4week
      durationRange.step = durationScales.weeks.seconds; // 1week
      break;

    default:
  }

  return durationRange;
};

const getMinDurationSafeValue = (val, range) => {
  let value = val;
  if (!val || val > range.max || val < range.min) {
    value = range.min * 2;
  }

  const modulo = value % range.step;
  if (modulo) {
    value -= modulo;
  }

  return value;
};

const getMinDurationUIModel = (conditions, rollup) => {
  const minDurationCondition = conditions.find((c) => c.type === alertConditionTypes.durationCondition);
  if (!minDurationCondition) {
    return {};
  }

  const minDurationValue = minDurationCondition.minDuration || MIN_DURATION_DEFAULT_VALUE;
  const minDurationScale = getDurationScale(minDurationValue);
  const minDurationRange = getDurationRange(minDurationScale, rollup);
  const minDurationSafeValue = getMinDurationSafeValue(minDurationValue, minDurationRange);

  return {
    value: minDurationSafeValue,
    scale: minDurationScale,
    ...minDurationRange,
  };
};

const getInitialNoDataDurationScale = (noDataDuration) => {
  const res = {
    value: noDataDuration.value,
  };

  // meaning it came from an existing alert
  if (noDataDuration && noDataDuration.value) {
    if (noDataDuration.value >= durationScales.weeks.min) {
      if (noDataDuration.value <= durationScales.weeks.max) {
        // if its in the weeks range
        if (noDataDuration.value % durationScales.weeks.seconds === 0) {
          // if it fits exactly to an hour range then set it on hours scale
          res.scale = durationScales.weeks.value;
        } else if (noDataDuration.value % durationScales.days.seconds === 0) {
          // if it fits exactly to an days range then set it on hours scale
          res.scale = durationScales.days.value;
        } else if (noDataDuration.value % durationScales.hours.seconds === 0) {
          // if it fits exactly to an hour range then set it on hours scale
          res.scale = durationScales.hours.value;
        } else {
          res.scale = durationScales.minutes.value; // else fallback to minutes
        }
      } else {
        // if it exceeded that max value then put the value on the max of the weeks
        res.scale = durationScales.weeks.value;
      }
    } else if (noDataDuration.value >= durationScales.days.min) {
      if (noDataDuration.value <= durationScales.days.max) {
        // if its in the days range
        if (noDataDuration.value % durationScales.days.seconds === 0) {
          // if it fits exactly to an hour range then set it on hours scale
          res.scale = durationScales.days.value;
        } else if (noDataDuration.value % durationScales.hours.seconds === 0) {
          // if it fits exactly to an hour range then set it on hours scale
          res.scale = durationScales.hours.value;
        } else {
          res.scale = durationScales.minutes.value; // else fallback to minutes
        }
      } else {
        // if it exceeded that max value then put the value on the max of the days
        res.scale = durationScales.days.value;
      }
    } else if (noDataDuration.value >= durationScales.hours.min && noDataDuration.value <= durationScales.hours.max) {
      if (noDataDuration.value % durationScales.hours.seconds === 0) {
        // if it fits exactly to an hour range then set it on hours scale
        res.scale = durationScales.hours.value;
      } else {
        res.scale = durationScales.minutes.value; // else fallback to minutes
      }
    } else if (
      noDataDuration.value >= durationScales.minutes.min &&
      noDataDuration.value <= durationScales.minutes.max
    ) {
      res.scale = durationScales.minutes.value;
    }
  } else {
    res.scale = durationScales.hours.value;
    res.value = durationScales.hours.min;
  }

  return res;
};

const getDurationScaleByRollup = (rollup) => {
  switch (rollup) {
    case resolutionTypes.short.value:
      return durationScales.minutes.value;

    case resolutionTypes.medium.value:
      return durationScales.minutes.value;

    case resolutionTypes.long.value:
      return durationScales.hours.value;

    case resolutionTypes.longlong.value:
      return durationScales.days.value;

    case resolutionTypes.weekly.value:
      return durationScales.weeks.value;
    default:
      return null;
  }
};

const setMinDurationDefaults = (value) => {
  if (value === 60) {
    return 300; // 1 minute to 2 minutes
  }

  if (value === 300) {
    return 600; // 5 minutes to 10 minutes
  }

  if (value === 3600) {
    return 7200; // 1 hour to 2 hours
  }
  return value;
};

const simulationFiltersReducer = reducers.composeReducers(
  (
    state = {
      type: {
        value: alertTypes.anomaly.value,
      },
      minDurationUI: {
        value: MIN_DURATION_DEFAULT_VALUE,
        scale: durationScales.minutes.value,
        min: durationScales.minutes.min,
        max: durationScales.minutes.max,
        step: durationScales.minutes.seconds,
      },
      rollup: resolutionTypes.medium.value,
      eventsFilter: {
        shouldShowEvents: false,
      },
      isInfluencingEvents: false,
      noDataAlert: false,
      noDataDuration: {
        scale: durationScales.minutes.value,
        value: 60 * 5,
      },
      updatePolicyInterval: {
        enabled: false,
      },
      conditions: [],
      notifyOnlyOpen: false,
      enableAutoTuneByAnodot: true,
    },
    {type, payload, meta},
  ) => {
    const red = (item, payloadInner) => ({...item, ...payloadInner});

    const findConditionIndexById = (id) => state.conditions.findIndex((c) => c.id === id);

    const findConditionIndexByType = (typeInner) => state.conditions.findIndex((c) => c.type === typeInner);

    const updateConditionById = (id, obj) => {
      const index = findConditionIndexById(id);
      return {
        ...state,
        conditions: reducers.reduceArrayItem(red, state.conditions, index, obj),
      };
    };

    const updateConditionByType = (typeInner, obj) => {
      const index = findConditionIndexByType(typeInner);
      return {
        ...state,
        conditions: reducers.reduceArrayItem(red, state.conditions, index, obj),
      };
    };

    const setPercentageCondition = (typeInner, propName, max = 100) => {
      let val = 0;
      if (payload >= 0) {
        val = payload > max ? max : parseFloat(payload);
      }

      val = Number.isFinite(val) ? val : null;
      const obj = {};
      obj[propName] = val;
      return updateConditionByType(typeInner, obj);
    };

    const setAbsCondition = (typeInner, propName) => {
      let val = payload < 0 ? 0 : parseFloat(payload);
      val = Number.isFinite(val) ? val : null;
      const obj = {};
      obj[propName] = val;
      return updateConditionByType(typeInner, obj);
    };

    const setVolumeCondition = (key, value) =>
      state.conditions.map((condition) => {
        if (condition.type === alertConditionTypes.volumeCondition) {
          return {...condition, [key]: value};
        }
        return condition;
      });

    const setAutoVolumeCondition = (volumeCondition) => {
      const conditionsWithoutVolume = state.conditions.filter(
        (condition) => condition.type !== alertConditionTypes.volumeCondition,
      );

      return [...conditionsWithoutVolume, volumeCondition];
    };

    switch (type) {
      case actions.setSimulationFilters.TYPE: {
        const updatePolicyInterval = getUpdatePolicyByRollup(payload.rollup, false);
        if (payload.updatePolicy) {
          updatePolicyInterval.value = payload.updatePolicy.minInterval / updatePolicyInterval.seconds;
          updatePolicyInterval.enabled = true;
        }

        if (payload.isNewAlert) {
          const removeTypes = payload.conditions.map((item) => item.type);
          // eslint-disable-next-line max-len
          const computedConditions = [
            ...state.conditions.filter((item) => !removeTypes.includes(item.type)),
            ...payload.conditions,
          ];
          return {
            ...state,
            rollup: payload.rollup,
            updatePolicyInterval,
            conditions: computedConditions,
            minDurationUI: getMinDurationUIModel(computedConditions, payload.rollup),
            noDataDuration: getInitialNoDataDurationScale({value: null}),
          };
        }

        return {
          ...state,
          type: {value: payload.type.value},
          minDurationUI: getMinDurationUIModel(payload.conditions, payload.rollup),
          noDataDuration: getInitialNoDataDurationScale(payload.noDataDuration),
          noDataAlert: payload.noDataAlert,
          eventsFilter: payload.eventsFilter,
          isInfluencingEvents: payload.isInfluencingEvents,
          updatePolicy: payload.updatePolicy,
          updatePolicyInterval,
          conditions: payload.conditions.map((c) => ({...c, id: c.id || getUniqueId()})),
          rollup: payload.rollup,
          enableAutoTuneByAnodot: payload.enableAutoTuneByAnodot,
          notifyOnlyOpen: payload.notifyOnlyOpen || false,
          advancedModeOpen: payload.advancedModeOpen,
        };
      }

      case actions.setSelectedAlertType.TYPE: {
        const mandatoryConditions = getMandatoryConditionsForType(payload);
        const stateConditions = state.conditions.map((c) => c.type);
        let toAdd = difference(mandatoryConditions, stateConditions);
        let toRemove = difference(stateConditions, mandatoryConditions);
        if (meta && meta.isNewAlertEditor) {
          if (meta.isInit) {
            toRemove = [];
            toAdd = toAdd.filter((item) => item !== 'DELTA_CONDITION');
          } else {
            // toRemove = toRemove.filter((item) => item !== 'DELTA_CONDITION');
            const threshold = state.conditions.find((c) => c.type === 'THRESHOLD_CONDITION');
            if (
              threshold &&
              (threshold.maxValue === null || threshold.maxValue === undefined) &&
              (threshold.minValue === null || threshold.minValue === undefined)
            ) {
              toRemove = ['THRESHOLD_CONDITION'];
            } else {
              toRemove = [];
            }
          }
        }
        let conditions = [...state.conditions];
        conditions = conditions.filter((c) => !toRemove.includes(c.type));
        toAdd.forEach((c) => {
          const cond = alertConditions.find((m) => m.dataObj.type === c).dataObj;
          cond.id = getUniqueId();
          conditions.push(cond);
        });

        return {
          ...state,
          conditions,
          type: {value: payload},
          minDurationUI: getMinDurationUIModel(conditions, state.rollup),
        };
      }

      case actions.setSelectedAlertSignificance.TYPE:
        return updateConditionByType(alertConditionTypes.significanceCondition, payload);

      case actions.setSelectedAlertMinDuration.TYPE: {
        const newState = updateConditionByType(alertConditionTypes.durationCondition, {minDuration: payload});
        return {...newState, minDurationUI: {...state.minDurationUI, ...{value: payload}}};
      }

      case actions.setSelectedAlertMinDurationScale.TYPE: {
        const range = getDurationRange(payload, state.rollup);
        const val = getMinDurationSafeValue(state.minDurationUI.value, range);
        const newState = updateConditionByType(alertConditionTypes.durationCondition, {minDuration: val});

        return {
          ...newState,
          minDurationUI: {
            ...state.minDurationUI,
            ...range,
            scale: payload,
            value: val,
          },
        };
      }

      case actions.setSelectedAlertTimeScale.TYPE: {
        const scale = getDurationScaleByRollup(payload);
        const range = getDurationRange(scale, payload);
        const val = setMinDurationDefaults(range.min);
        const updatePolicyInterval = getUpdatePolicyByRollup(
          payload,
          get(state.updatePolicyInterval, 'enabled', false),
        );
        const newState = updateConditionByType(alertConditionTypes.durationCondition, {minDuration: val});
        return {
          ...newState,
          rollup: payload,
          minDurationUI: {
            ...state.minDurationUI,
            ...range,
            scale,
            value: val,
          },
          updatePolicyInterval: {...state.updatePolicyInterval, ...updatePolicyInterval},
        };
      }

      case actions.setSelectedAlertAutoTuneByAnodot.TYPE: {
        return {...state, enableAutoTuneByAnodot: payload};
      }

      case actions.setSelectedAlertDirection.TYPE: {
        return updateConditionByType(alertConditionTypes.directionCondition, payload);
      }

      case actions.setSelectedAlertShouldShowEvents.TYPE: {
        return {
          ...state,
          eventsFilter: {
            ...state.eventsFilter,
            shouldShowEvents: payload,
          },
          isInfluencingEvents: !state.eventsFilter.shouldShowEvents ? false : state.shouldShowEvents,
        };
      }

      case actions.setIsInfluencingEvents.TYPE: {
        return {...state, isInfluencingEvents: payload};
      }

      case actions.setSelectedAlertNoDataDuration.TYPE: {
        return {
          ...state,
          noDataDuration: {...state.noDataDuration, ...payload},
        };
      }

      case actions.setSelectedAlertNoDataEnabled.TYPE: {
        return {...state, noDataAlert: payload};
      }

      case actions.setSelectedAlertNotifyOnlyOpenBoolVal.TYPE: {
        return {...state, notifyOnlyOpen: payload};
      }

      case actions.setSelectedAlertStaticThreshold.TYPE: {
        return updateConditionByType(alertConditionTypes.staticThreshold, payload);
      }

      case actions.setSelectedAlertUpdatePolicy.TYPE: {
        let updatePolicy;
        if (!payload.enabled) {
          updatePolicy = null;
        } else {
          updatePolicy = {
            minInterval: state.updatePolicyInterval.seconds * payload.value,
            triggerOnMetricsChange: true,
            type: 'parameterChange',
          };
        }

        return {
          ...state,
          updatePolicyInterval: {...state.updatePolicyInterval, ...payload},
          updatePolicy,
        };
      }

      case actions.setSelectedAlertInfluencingMetrics.TYPE: {
        return updateConditionById(payload.id, payload);
      }

      case actions.setSuppress.TYPE: {
        const index = findConditionIndexById(payload.id);
        return {
          ...state,
          conditions: reducers.reduceArrayItem(red, state.conditions, index, {
            failOnAbsence: !payload.value,
          }),
        };
      }

      case actions.addSelectedAlertInfluencingMetricsSubCondition.TYPE: {
        const index = findConditionIndexById(payload);
        return {
          ...state,
          conditions: reducers.reduceArrayItem(red, state.conditions, index, {
            subConditions: (state.conditions[index].subConditions || []).concat(createSubConditionByType()),
          }),
        };
      }

      case actions.removeSelectedAlertInfluencingMetricsSubCondition.TYPE: {
        const index = findConditionIndexById(payload.id);
        return {
          ...state,
          conditions: reducers.reduceArrayItem(red, state.conditions, index, {
            subConditions: [
              ...state.conditions[index].subConditions.slice(0, payload.index),
              ...state.conditions[index].subConditions.slice(payload.index + 1),
            ],
          }),
        };
      }

      case actions.setSelectedAlertInfluencingMetricsSubCondition.TYPE: {
        const index = findConditionIndexById(payload.id);
        const {subConditions} = state.conditions[index];
        if (payload.subPayload.type && subConditions[payload.index].type !== payload.subPayload.type) {
          const subCon = createSubConditionByType(payload.subPayload.type);
          return {
            ...state,
            conditions: reducers.reduceArrayItem(red, state.conditions, index, {
              subConditions: [
                ...subConditions.slice(0, payload.index),
                subCon,
                ...subConditions.slice(payload.index + 1),
              ],
            }),
          };
        }
        return {
          ...state,
          conditions: reducers.reduceArrayItem(red, state.conditions, index, {
            subConditions: reducers.reduceArrayItem(red, subConditions, payload.index, payload.subPayload),
          }),
        };
      }

      case actions.setSelectedAlertInfluencingMetricsSubConditionV2.TYPE: {
        const index = findConditionIndexById(payload.id);
        return {
          ...state,
          conditions: reducers.reduceArrayItem(red, state.conditions, index, {
            subConditions: payload.subConditions,
          }),
        };
      }

      case actions.addConditionToSelectedAlert.TYPE: {
        meta.id = getUniqueId(); /* eslint-disable-line */
        const newCondition = {...payload, id: meta.id};
        if (newCondition.type === alertConditionTypes.influencingMetrics) {
          const newRollup = ['short', 'medium', 'long'].indexOf(state.rollup) !== -1 ? 'long' : state.rollup;
          newCondition.rollup = newRollup;
        }
        return {...state, conditions: [...state.conditions, newCondition]};
      }

      case actions.removeConditionFromSelectedAlert.TYPE: {
        return {...state, conditions: state.conditions.filter((c) => c.id !== payload)};
      }

      case actions.setSelectedAlertMinDeltaAbs.TYPE: {
        return setAbsCondition(alertConditionTypes.minDelta, 'deltaAbsolute');
      }

      case actions.setSelectedAlertMinDeltaPercent.TYPE: {
        return setPercentageCondition(alertConditionTypes.minDelta, 'deltaPercentage', 1000);
      }

      case actions.setSelectedAlertMinDeltaAutoBoolVal.TYPE: {
        return updateConditionByType(alertConditionTypes.minDelta, {enableAutoTuning: payload});
      }

      case actions.setDeltaDurationEnabled.TYPE: {
        const minDeltaCon = state.conditions.find((con) => con.type === alertConditionTypes.minDelta) || EMPTY_OBJECT;
        return updateConditionByType(alertConditionTypes.minDelta, {
          ...minDeltaCon,
          deltaDuration: {
            ...minDeltaCon.deltaDuration,
            enabled: payload,
          },
        });
      }

      case actions.setDeltaDurationNumOfPoints.TYPE: {
        const minDeltaCon = state.conditions.find((con) => con.type === alertConditionTypes.minDelta) || EMPTY_OBJECT;
        return updateConditionByType(alertConditionTypes.minDelta, {
          ...minDeltaCon,
          deltaDuration: {
            ...minDeltaCon.deltaDuration,
            minDuration: payload.value,
            rollup: payload.rollup,
          },
        });
      }

      case actions.setSelectedAlertMinDeltaEstimation.TYPE: {
        let est = 0;
        if (isNumber(payload.minDeltaEstimation)) {
          est =
            payload.minDeltaEstimation < 1
              ? payload.minDeltaEstimation.toFixed(4)
              : payload.minDeltaEstimation.toFixed(2);
        }
        return updateConditionByType(alertConditionTypes.minDelta, {
          deltaAbsolute: est,
          deltaPercentage: payload.deltaPercentage,
        });
      }

      case actions.setSelectedAlertMaxMetricsAbs.TYPE: {
        return setAbsCondition(alertConditionTypes.maxParticipatingMetrics, 'maxNumOfMetrics');
      }

      case actions.setSelectedAlertMaxMetricsPercentage.TYPE: {
        return setPercentageCondition(alertConditionTypes.maxParticipatingMetrics, 'maxPercentageOfMetrics');
      }

      case actions.setSelectedAlertMinMetricsAbs.TYPE: {
        return setAbsCondition(alertConditionTypes.minParticipatingMetrics, 'minNumOfMetrics');
      }

      case actions.setSelectedAlertMinMetricsPercentage.TYPE: {
        return setPercentageCondition(alertConditionTypes.minParticipatingMetrics, 'minPercentageOfMetrics');
      }

      // *** Volume Condition ***
      case actions.setVolumeNumOfLastPoints.TYPE: {
        return {
          ...state,
          conditions: setVolumeCondition('numLastPoints', payload.value),
        };
      }

      case actions.setVolumeRollup.TYPE: {
        return {
          ...state,
          conditions: setVolumeCondition('rollup', payload.value),
        };
      }

      case actions.setVolumeEnabled.TYPE: {
        return {
          ...state,
          conditions: setVolumeCondition('enabled', !payload),
        };
      }

      case actions.setVolumeBound.TYPE: {
        return {
          ...state,
          conditions: setVolumeCondition('bound', payload.value),
        };
      }

      case actions.setVolumeValue.TYPE: {
        return {
          ...state,
          conditions: setVolumeCondition('value', payload),
        };
      }

      case actions.setEnableAutoTuning.TYPE: {
        return {
          ...state,
          conditions: setVolumeCondition('enableAutoTuning', !payload),
        };
      }

      case actions.setAutoVolumeConditionValues.TYPE: {
        return {
          ...state,
          conditions: setAutoVolumeCondition(payload),
        };
      }

      case actions.getVolumeCondition.failure.TYPE: {
        return state;
      }
      // End of Volume Condition

      case actions.setAdvancedModeOpen.TYPE: {
        return {
          ...state,
          advancedModeOpen: !state.advancedModeOpen,
        };
      }

      default:
        return state;
    }
  },
);

export default simulationFiltersReducer;
