import {combineEpics} from 'redux-observable';
import dataSourcesEpic from './dataSourcesEpic';
import dataStreamsEpic from './dataStreamsEpic';
import dataManagerEpic from './dataManagerEpic';
import googleAnalyticsEpic from './googleAnalyticsEpic';
import googleAuctionsEpic from './googleAuctionsEpic';
import googleAdsEpic from './googleAdsEpic';
import fileUploadEpic from './fileUploadEpic';
import s3Epic from './s3Epic';
import awsCurEpic from './awsCurEpic';
import sqlEpic from './sqlEpic';
import adobeEpic from './adobeEpic';
import bigQueryEpic from './bigQueryEpic';
import googleStorageEpic from './googleStorageEpic';
import mParticleEpic from './mParticleEpic';
import lookupTablesEpic from './lookupTablesEpic';
import kinesisEpic from './kinesisEpic';
import alertsFromStreamEpic from './alertsFromStreamEpic';
import salesforceEpic from './salesforceEpic';
import viewStreamSummaryModalEpic from './viewStreamSummaryModalEpic';
import parquetEpic from './parquetEpic';
import segmentEpic from './segmentEpic';
import facebookAdsEpic from './facebookAdsEpic';
import coralogixEpic from './coralogixEpic';

export default combineEpics(
  dataSourcesEpic,
  dataStreamsEpic,
  dataManagerEpic,
  googleAnalyticsEpic,
  googleAuctionsEpic,
  googleAdsEpic,
  fileUploadEpic,
  parquetEpic,
  s3Epic,
  awsCurEpic,
  sqlEpic,
  adobeEpic,
  bigQueryEpic,
  googleStorageEpic,
  mParticleEpic,
  lookupTablesEpic,
  kinesisEpic,
  salesforceEpic,
  segmentEpic,
  facebookAdsEpic,
  coralogixEpic,
  alertsFromStreamEpic,
  viewStreamSummaryModalEpic,
);
