import React from 'react';
import {error as _notification} from 'react-notification-system-redux';
import SnackBar, {TYPES} from 'common/componentsV2/snackBar/SnackBar';

const notificationOptsDefaults = {
  uid: 'oneAtTheTime',
  position: 'bl',
  autoDismiss: 10,
  dismissible: false,
  children: undefined,
  action: undefined,
  canClose: true,
  isOpenInfoInModal: false,
};

const setNotificationOpts = (params) => ({
  ...params,
  settings: {
    ...notificationOptsDefaults,
    ...params.settings,
  },
});

const getSnackBar = (params, newParams, type) => (
  <SnackBar
    notificationData={params}
    type={type}
    isDismissible={newParams.settings.canClose}
    isOpenInfoInModal={newParams.settings.isOpenInfoInModal}
    uid={newParams.settings.uid}
    actions={newParams.actions}
  />
);

const buildNotificationOpts = (params, type) => {
  const newParams = setNotificationOpts(params);
  return {
    ...newParams.settings,
    children: getSnackBar(params, newParams, type),
  };
};

const convertStringToObj = (params) => {
  if (typeof params === 'string') {
    return {moreInfo: params};
  }
  return params;
};

export const error = (params) => _notification(buildNotificationOpts(convertStringToObj(params), TYPES.ERROR));

export const warning = (params) => _notification(buildNotificationOpts(convertStringToObj(params), TYPES.WARNING));

export const info = (params) => _notification(buildNotificationOpts(convertStringToObj(params), TYPES.INFO));

export const success = (params) => _notification(buildNotificationOpts(convertStringToObj(params), TYPES.SUCCESS));
