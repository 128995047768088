// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {getUsersGroupsListsFull, getUsersGroupsListsFilterByUserProfile} from 'admin.users/store/selectors';
import {getMeOwnerOrganization} from 'profile/store/selectors';
import {fetchUsers, fetchGroups} from 'admin.users/store/actions';
import './UsersGroupsSelect.module.scss';

type PropTypes = {
  value: String,
  onChange: Function,
  disabled: boolean,
  resourceName: String,
  // eslint-disable-next-line react/no-unused-prop-types
  isSameGroup: boolean,
  showGroupSubText: boolean,
  selectProps: Object,
  menuFooterComponent: any,
  onMenuClose: Function,
  refSelectComponent: Function,

  // @connect
  ownerOrganization: String,
  itemsList: Array,
  fetchUsers: Function,
  fetchGroups: Function,
};

const EMPTY_OBJECT = {};

@connect(
  (state, ownProps) => ({
    ownerOrganization: getMeOwnerOrganization(state),
    itemsList: ownProps.isSameGroup ? getUsersGroupsListsFilterByUserProfile(state) : getUsersGroupsListsFull(state),
  }),
  {
    fetchUsers,
    fetchGroups,
  },
)
export default class UsersGroupsSelect extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {ownerOrganization} = this.props;
    this.props.fetchUsers();
    this.props.fetchGroups({ownerOrganization});
  }

  render() {
    const {
      itemsList,
      onChange,
      value,
      disabled,
      resourceName,
      showGroupSubText,
      selectProps,
      onMenuClose,
      menuFooterComponent,
      refSelectComponent,
    } = this.props;

    const customFilterOption = (option, rawInput) => {
      const checkFunction = (variable, rInput) => variable.label.toLowerCase().indexOf(rInput.toLowerCase()) > -1;

      if (option.data.type === 'HEADER') {
        if (rawInput.length === 0) {
          return true;
        }
        return !!itemsList.find(
          (item) => item.type.toLowerCase() === option.data.value.toLowerCase() && checkFunction(item, rawInput),
        );
      }
      return checkFunction(option, rawInput);
    };

    const selectedItem = itemsList.find((item) => item.value === value) || EMPTY_OBJECT;
    const count = selectedItem.count ? selectedItem.count.split(' ')[0] : '';
    const resName = resourceName || 'resource';

    return (
      <div>
        <SelectAndt
          automationId="setAlertOwnerSelect"
          type={TYPE_NEW_SEARCH}
          theme={THEME_NOT_HIGHLIGHTED}
          onChange={onChange}
          options={itemsList}
          value={selectedItem.label ? selectedItem : null}
          placeholder="Search Owner"
          optionHeight={40}
          menuWidth={310}
          filterOption={customFilterOption}
          disabled={disabled}
          showValue
          onMenuClose={onMenuClose}
          refSelectComponent={refSelectComponent}
          menuFooterComponent={menuFooterComponent || null}
          {...(selectProps || EMPTY_OBJECT)}
        />
        {showGroupSubText && (
          <span styleName={['sub-text', selectedItem.type !== 'GROUPS' ? 'invisible' : ''].join(' ')}>
            {`All ${count} members of the ${selectedItem.label} team will be able to edit this ${resName}.`}
          </span>
        )}
      </div>
    );
  }
}
