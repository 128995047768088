import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import EmailDetails from './EmailDetails';
import JiraDetails from './JiraDetails';
import StandartDetails from './StandartDetails';

const useStyles = makeStyles(({palette}) => ({
  gotoIcon: {
    display: 'none',
    '&:hover': {
      color: palette.blue[500],
    },
  },
  alertTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 8px',
    margin: '-4px -8px',
    marginBottom: 0, // -4 + 4
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: palette.blue[100],
      '& $gotoIcon': {
        display: 'block',
      },
    },
  },
}));

const ExpandedPanel = ({channel}: {channel: Object}) => {
  const [isSeeAll, setSeeAll] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className="px_4 pt_1-5 pb_1-5 display_flex" style={{minHeight: '218px'}} id={`scroll-${channel.id}`}>
      <div className="flexGrow_1 mr_3">
        <div className="mb_1-5 fontWeight_500 color_gray-400">Alerts Connected ({channel.alerts.length})</div>
        {(isSeeAll ? channel.alerts : channel.alerts.slice(0, 6)).map((alert) => (
          <div
            role="button"
            className={`${classes.alertTitle}`}
            onClick={() =>
              history.push(`/alert-manager/edit/${alert.data.id}/settings?backUrl=${history.location.pathname}`)
            }
          >
            {alert.data.title}
            <i className={`icon icn-nav16-goto ${classes.gotoIcon}`} />
          </div>
        ))}
        {channel.alerts.length > 6 && !isSeeAll && (
          <div className="mt_1-5 fontWeight_500 color_blue-500" role="button" onClick={() => setSeeAll(true)}>
            See All
          </div>
        )}
      </div>
      <div style={{width: '428px'}} className="display_flex">
        {(channel.channelMeta.id === 'email' && <EmailDetails channel={channel} />) ||
          (channel.channelMeta.id === 'jira' && <JiraDetails channel={channel} />) || (
            <StandartDetails channel={channel} />
          )}
      </div>
    </div>
  );
};

export default ExpandedPanel;
