// @flow
import React from 'react';
import {connect} from 'react-redux';
import ConfirmationModal from 'common/components/modals/ConfirmationModal';
import * as selectors from 'bc/store/selectors';
import {deleteDataSource, updateDataSource, setSelectedDataSource} from 'bc/store/actions';
import {intersection} from 'lodash';
import {getTypeDetails} from 'bc/services/bcTypes';
import Fade from 'app/components/Animations';
import {streamHasMetrics, isAgent} from 'bc/services/dataStreamService';
import SelectAndt, {
  THEME_TRANSPARENT,
  TYPE_SIMPLE,
  DIRECTION_LEFT,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {components} from 'react-select2';
import DeleteMetricsConfirmation from './DeleteMetricsConfirmation';
import './SourceListItem.module.scss';

const deleteWithStreamsModalMessage = (
  <span>Deleting this data source will delete all its linked data streams and any metrics created from them.</span>
);

const deleteWithoutStreamsModalMessage = <span>Are you sure you want to delete this source?</span>;

type PropTypes = {
  // connect
  source: Object,

  // connect
  selectedSourceId: String,
  streams: Array,
  dataSourceTypesItems: Array,
  isBcReadOnly: Boolean,

  deleteDataSource: Function,
  updateDataSource: Function,
  setSelectedDataSource: Function,
};

@connect(
  (state, ownProps) => ({
    source: ownProps.source,
    selectedSourceId: selectors.getSelectedDataSourceId(state),
    streams: selectors.getDataStreamsItems(state),
    dataSourceTypesItems: selectors.getDataSourceTypesItems(state),
    isBcReadOnly: selectors.isBcReadOnlyPermision(state),
  }),
  {
    deleteDataSource,
    updateDataSource,
    setSelectedDataSource,
  },
)
export default class DataSourceListItem extends React.PureComponent {
  props: PropTypes;

  constructor(props) {
    super(props);
    const typeDetails = getTypeDetails(this.props.source.type);
    const options = this.getOptions(typeDetails);
    this.state = {
      isDeleteModalOpen: false,
      isUpdateModalOpen: false,
      shouldDeleteMetrics: false,
      dropdownOptions: options,
      typeDetails,
    };
  }

  closeDeleteModal = () => {
    this.setState({isDeleteModalOpen: false});
  };

  openDeleteModal = () => {
    this.setState({isDeleteModalOpen: true});
  };

  onConfirmDeleteModal = () => {
    if (this.props.selectedSourceId === this.props.source.id) {
      this.props.setSelectedDataSource(this.props.source.id);
    }
    this.props.deleteDataSource({
      id: this.props.source.id,
      type: this.props.source.type,
      shouldDeleteMetrics: this.state.shouldDeleteMetrics,
    });
    this.setState({isDeleteModalOpen: false, shouldDeleteMetrics: false});
  };

  openViewModal = () => {
    const sourceType = this.props.dataSourceTypesItems.find((a) => a.type === this.props.source.type);
    this.setState({isUpdateModalOpen: true});
    this.modal = getTypeDetails(this.props.source.type).view(sourceType, this.props.source, !this.props.isBcReadOnly);
  };

  closeUpdateSourceModal = () => {
    this.setState({isUpdateModalOpen: false});
  };

  actionItemSelected = (select) => {
    switch (select.value) {
      case 'delete':
        this.openDeleteModal();
        break;
      case 'view':
        this.openViewModal();
        break;
      default:
    }
  };

  onCheckBoxChange = (event) => {
    const {target} = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const {name} = target;

    this.setState({
      [name]: value,
    });
  };

  onSourceItemSelect = (event) => {
    const isMoreToggle =
      intersection(['dropdown-value', 'dropdown-toggle', 'icn-icon-3-dots'], event.target.classList).length !== 0;

    if (!event.defaultPrevented && !isMoreToggle) {
      this.props.setSelectedDataSource(this.props.source.id);
    }
  };

  stopPropagation = (e) => e.stopPropagation(); // https://github.com/react-bootstrap/react-bootstrap/issues/3105

  getOptions = (typeDetails) => {
    const ret = [];
    if (typeDetails.isNoMenu) {
      return ret;
    }
    ret.push({value: 'view', label: 'View'});
    if (!this.props.isBcReadOnly) {
      ret.push({
        value: 'delete',
        label: 'Delete',
      });
    }
    return ret;
  };

  render() {
    const {
      streams,
      source: {id, name, family},
      selectedSourceId,
    } = this.props;

    const sourceStreams = streams.filter((i) => i.dataSourceId === id);
    const sourceStreamsWithMetrics = sourceStreams.filter((i) => streamHasMetrics(i.state));
    const isAgentStream = isAgent(family);

    let styleName = 'root';

    if (selectedSourceId) {
      styleName = selectedSourceId === id ? 'root active' : 'root not-active';
    }

    return (
      <Fade {...this.props} exit enter={false} key={`fade_${id}`}>
        <div
          styleName={styleName}
          automation-id="dataManagerSourceListItem"
          onClick={this.onSourceItemSelect}
          role="presentation"
        >
          <div className={`image-${this.state.typeDetails.iconStyle}`} styleName="type-image" />
          <div className="shell-col" styleName="source-name-wrapper">
            <span className="ellipsis" automation-id="dataManagerSourceListItemName" styleName="source-name">
              {name}
            </span>
          </div>
          {false && (
            <div styleName="source-status-wrapper">
              <i className="icon icn-icon-sun-live-stream" styleName="status-icon sun" />
            </div>
          )}

          <div styleName="more-dropdown" automation-id="dataManagerSourceItemDropdown">
            {this.state.dropdownOptions.length > 0 && !isAgentStream && (
              <SelectAndt
                options={this.state.dropdownOptions}
                type={TYPE_SIMPLE}
                theme={THEME_TRANSPARENT}
                direction={DIRECTION_LEFT}
                onChange={(item) => this.actionItemSelected(item)}
                automationId="sourceListItem"
                customComponent={{
                  Option: (p) => (
                    <components.Option {...p}>
                      <div
                        onClick={(evt) => {
                          evt.preventDefault();
                        }}
                        role="presentation"
                      >
                        {p.children}
                      </div>
                    </components.Option>
                  ),
                }}
              />
            )}
          </div>

          {this.state.isDeleteModalOpen && (
            <ConfirmationModal
              onClose={this.closeDeleteModal}
              onConfirm={this.onConfirmDeleteModal}
              title="Delete Data Source?"
              message={sourceStreams.length ? deleteWithStreamsModalMessage : deleteWithoutStreamsModalMessage}
              isOpen={this.state.isDeleteModalOpen}
              buttons={['No, Keep It', 'Yes, Delete It']}
            >
              {sourceStreamsWithMetrics.length > 0 && (
                <DeleteMetricsConfirmation
                  ShouldDeleteMetrics={this.state.shouldDeleteMetrics}
                  onShouldDeleteMetricsChange={this.onCheckBoxChange}
                />
              )}
            </ConfirmationModal>
          )}

          <div onKeyDown={this.stopPropagation} onClick={this.stopPropagation} role="presentation">
            {this.state.isUpdateModalOpen &&
              React.cloneElement(this.modal, {
                isOpen: this.state.isUpdateModalOpen,
                onClose: this.closeUpdateSourceModal,
              })}
          </div>
        </div>
      </Fade>
    );
  }
}
