// @flow
import React from 'react';
import {connect} from 'react-redux';
import SmartTooltip from 'common/components/SmartTooltip';
import {durationScales} from 'metrics/services/metricsService';
import {alertTypes} from 'alerts.management/services/alertsService';
import {broadcast} from 'common/utils/angularServices';
import Checkbox from 'common/componentsV2/Checkbox';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import * as selectors from 'alerts.management/store/selectors';
import * as profileSelectors from 'profile/store/selectors';
import * as metricSelectors from 'metrics/store/selectors';
import * as actions from 'alerts.management/store/actions';
import TooltipArea from 'common/componentsV2/TooltipArea';
import InputAuto from '../simulationArea/utils/InputAuto';

type PropTypes = {
  noDataAlert: boolean,
  noDataDuration: Object,
  alertType: string,
  onChange: Function,
  onEnabledChange: Function,
  isComposite: boolean,
  noDataMinDuration: number,
  noDataMinCompositeDuration: number,
};

class NoDataSelector extends React.PureComponent {
  props: PropTypes;

  state = {noDataTooltip: '', noDataError: false};

  componentDidUpdate(prevProps) {
    if (
      (this.props.noDataDuration.scale !== prevProps.noDataDuration.scale ||
        this.props.isComposite !== prevProps.isComposite) &&
      this.formatDuration() < this.calculateMinDuration()
    ) {
      this.handleValueChange(this.calculateMinDuration());
    }
  }

  calculateMinDuration = () => {
    const {noDataDuration, noDataMinDuration, noDataMinCompositeDuration, isComposite} = this.props;
    const noDataMinDurationComputed = isComposite ? noDataMinCompositeDuration : noDataMinDuration;
    return Math.ceil(noDataMinDurationComputed / durationScales[noDataDuration.scale].seconds);
  };

  formatDuration = () => {
    const {noDataDuration} = this.props;
    // eslint-disable-next-line radix
    return parseInt(noDataDuration.value / durationScales[noDataDuration.scale].seconds);
  };

  validateEventName = 'e:andt.metrics.alerts.validateNoDataDuration';

  handleValueChange = (val) => {
    const {noDataDuration} = this.props;
    const value = val * durationScales[noDataDuration.scale].seconds;
    this.handleChange(value, noDataDuration.scale);
  };

  handleScaleChange = (scale) => {
    const {noDataDuration} = this.props;
    const value = (noDataDuration.value / durationScales[noDataDuration.scale].seconds) * durationScales[scale].seconds;
    this.handleChange(value, scale);
  };

  handleChange = (value, scale, isEnabled) => {
    const data = {
      value,
      validationObject: {
        valid: true,
        validationMessage: '',
      },
      isEnabled:
        isEnabled !== undefined
          ? isEnabled
          : this.props.noDataAlert || this.props.alertType === alertTypes.noData.value,
    };
    broadcast(this.validateEventName, data);
    this.setState({noDataTooltip: data.validationObject.validationMessage, noDataError: !data.validationObject.valid});
    this.props.onChange({value, scale});
  };

  handleYesNoClicked = (val) => {
    this.props.onEnabledChange(val);
    this.handleChange(this.props.noDataDuration.value, this.props.noDataDuration.scale, val);
  };

  render() {
    return (
      <TooltipArea
        text="Choose to be alerted when metrics from this alert are no longer being streamed into Anodot"
        automation-id="noDataConditionContainer"
      >
        {(info) => (
          <React.Fragment>
            {this.props.alertType !== alertTypes.noData.value && (
              <React.Fragment>
                <Checkbox isChecked={this.props.noDataAlert} onChange={(value) => this.handleYesNoClicked(value)}>
                  <div className="text16med lineHeight_16 ml_0-5">Send alert when data is missing</div>
                </Checkbox>
                {info}
              </React.Fragment>
            )}
            {(this.props.noDataAlert || this.props.alertType === alertTypes.noData.value) && (
              <Box mt={this.props.alertType === alertTypes.noData.value ? 0 : 1.5}>
                <Box mb={1}>For At Least:</Box>
                <Grid container>
                  <Grid item>
                    <SmartTooltip content={this.state.noDataError ? this.state.noDataTooltip : null}>
                      <Box width={82} height={36} mr={1}>
                        <InputAuto
                          fullSize
                          delay={1000}
                          type="number"
                          additionalProps={{
                            min: this.calculateMinDuration(),
                          }}
                          automationId="alertSettingsNoDataTextbox"
                          className={`dark-input ${this.state.noDataError ? 'invalid' : ''}`}
                          value={this.formatDuration()}
                          onChange={(e) => this.handleValueChange(e.target.value)}
                        />
                      </Box>
                    </SmartTooltip>
                  </Grid>
                  <Grid item>
                    <NoDataSelectorScale scale={this.props.noDataDuration.scale} onChange={this.handleScaleChange} />
                  </Grid>
                </Grid>
              </Box>
            )}
          </React.Fragment>
        )}
      </TooltipArea>
    );
  }
}

const durationTypes = [
  {
    label: 'Minutes',
    value: 'minutes',
  },
  {
    label: 'Hours',
    value: 'hours',
  },
  {
    label: 'Days',
    value: 'days',
  },
  {
    label: 'Weeks',
    value: 'weeks',
  },
];

// eslint-disable-next-line react/no-multi-comp
const NoDataSelectorScale = React.memo((props: {scale: Object, onChange: Function}) => (
  <Box width={144}>
    <SelectAndt
      id="durationScaleFilter"
      automationId="durationScaleFilter"
      extraClassName="alerts-dropdown-btn"
      options={durationTypes}
      optionHeight={40}
      type={TYPE_NEW_NO_SEARCH}
      theme={THEME_NOT_HIGHLIGHTED}
      onChange={(item) => props.onChange(item.value)}
      value={durationTypes.find((item) => item.value === props.scale)}
      placeholder="All"
    />
  </Box>
));

export default connect(
  (state) => {
    const {noDataAlert, noDataDuration, type} = selectors.getSelectedAlertSimulationFilters(state);
    return {
      noDataAlert,
      noDataDuration,
      alertType: type.value,
      noDataMinDuration: profileSelectors.getOrganizationNoDataMinDuration(state),
      noDataMinCompositeDuration: profileSelectors.getOrganizationNoDataMinCompositeDuration(state),
      isComposite: !metricSelectors.getIsEmptyFunction(state),
    };
  },
  {
    onChange: actions.setSelectedAlertNoDataDuration,
    onEnabledChange: actions.setSelectedAlertNoDataEnabled,
  },
)(NoDataSelector);
