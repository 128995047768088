// @flow
import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import EmptyListImageComponent from 'common/componentsV2/EmptyListImageComponent';
import {getStreamAssets as getStreamAssetsAction} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import InfoItem from './InfoItem';
import './InfoSection.module.scss';

const HeaderRow = (props: {title: String, isNotification: boolean}) => (
  <div styleName={['table-row header-row', !props.isNotification ? 'no-notification' : ''].join(' ')}>
    <div styleName="col-title">{props.title}</div>
    <div styleName="col-owner">Owner</div>
    {props.isNotification ? <div styleName="col-notification">Notifications</div> : null}
    <div styleName="col-link" />
  </div>
);

const AlertsTable = (props: {items: Array}) => {
  if (!props.items.length) {
    return null;
  }

  return (
    <div styleName="table">
      <HeaderRow title={`${props.items.length} Alerts`} isNotification />
      {props.items.map((item) => (
        <InfoItem
          key={`${item.title}-${item.owner}`}
          title={item.title}
          owner={item.owner}
          severity={item.severity}
          notification={item.warningMessage}
          notificationLevel={item.warningLevel}
          link={`#!/r/alert-manager?alertIdToScrollTo=${item.id}`}
        />
      ))}
    </div>
  );
};

const DashboardsTable = (props: {items: Array}) => {
  if (!props.items.length) {
    return null;
  }

  return (
    <div styleName="table">
      <HeaderRow title={`${props.items.length} Dashboards`} />
      {props.items.map((item) => {
        let link = `#!/dashboards?activeTab=1&tabs=main;${item.id}`;
        if (item.version === 'v2') {
          link = `#!/r/dashboards/${item.id}`;
        }

        return (
          <InfoItem
            key={`${item.title}-${item.owner}`}
            title={item.title}
            owner={item.owner}
            link={link}
            isNotification={false}
          />
        );
      })}
    </div>
  );
};

const CompositesTable = (props: {items: Array}) => {
  if (!props.items.length) {
    return null;
  }

  return (
    <div styleName="table">
      <HeaderRow title={`${props.items.length} Composites`} />
      {props.items.map((item) => (
        <InfoItem
          key={`${item.title}-${item.owner}`}
          title={item.title}
          owner={item.owner}
          link="#!/metrics/composite/management"
          isNotification={false}
        />
      ))}
    </div>
  );
};

const EmptyListIndication = (props: {isLoading: boolean, alerts: Array, dashboards: Array, composites: Array}) => {
  if (props.isLoading || props.alerts.length || props.composites.length || props.dashboards.length) {
    return null;
  }

  return (
    <div styleName="empty-list">
      <EmptyListImageComponent />
      <div styleName="empty-text">No Linked Assets</div>
    </div>
  );
};

type PropTypes = {
  dataStreamId: String,

  // connect
  getStreamAssets: Function,
  isLoading: boolean,
  streamId: String,
  alerts: Array,
  dashboards: Array,
  composites: Array,
};

@connect(
  (state) => ({
    isLoading: selectors.getViewStreamSummaryAssetsIsLoading(state),
    streamId: selectors.getViewStreamSummaryAssetsStreamId(state),
    alerts: selectors.getViewStreamSummaryAlertsAssetsItems(state),
    dashboards: selectors.getViewStreamSummaryAllDashboardsAssetsItems(state),
    composites: selectors.getViewStreamSummaryCompositesAssetsItems(state),
  }),
  {
    getStreamAssets: getStreamAssetsAction,
  },
)
export default class InfoSection extends PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {getStreamAssets, dataStreamId, streamId} = this.props;
    if (dataStreamId !== streamId) {
      getStreamAssets(dataStreamId);
    }
  }

  render() {
    const {isLoading, alerts, dashboards, composites} = this.props;

    return (
      <Fragment>
        <AlertsTable items={alerts} />
        <DashboardsTable items={dashboards} />
        <CompositesTable items={composites} />
        {isLoading ? (
          <div styleName="spinner-wrapper">
            <Spinner color="#3d4c59" size={SIZES.XX_BIG_100} />
          </div>
        ) : null}
        <EmptyListIndication isLoading={isLoading} alerts={alerts} dashboards={dashboards} composites={composites} />
      </Fragment>
    );
  }
}
