// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/StreamEditorPanelHeader';
import TimeZone from 'bc/components/streams/editor/common/TimeZone';
import SchedularDateRange from 'bc/components/streams/editor/common/SchedularDateRange';
import PollingInterval from 'bc/components/streams/editor/common/PollingInterval';
import {getAllowedRangeTypesByPollingInterval} from 'bc/services/dataStreamService';
import * as commonSelectors from 'profile/store/selectors';

import '../../sql/sqlSchedular/SqlSchedularEditor.module.scss';

type PropTypes = {
  // connect
  timeZoneName: string,
  dataStream: Object,
  setSelectedStreamKeyVal: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    timeZoneName: commonSelectors.getTimeZoneName(state),
  }),
  {
    setSelectedStreamKeyVal,
  },
)
export default class AdobeSchedulerEditor extends React.PureComponent {
  props: PropTypes;

  pollingChanged = (val) => {
    this.props.setSelectedStreamKeyVal({pollingInterval: val});
  };

  dateRangeChanged = (val) => {
    this.props.setSelectedStreamKeyVal({historicalDateRange: val});
  };

  timeZoneChanged = (obj) => {
    this.props.setSelectedStreamKeyVal(obj);
  };

  delayMinutesChanged = (e) => {
    const val = parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0;
    this.props.setSelectedStreamKeyVal({delayMinutes: val});
  };

  maxBackFillIntervalsChanged = (e) => {
    const val = parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0;
    this.props.setSelectedStreamKeyVal({maxBackFillIntervals: val});
  };

  render() {
    const {dataStream, timeZoneName} = this.props;

    return (
      <div styleName="root">
        <PanelHeader title="Query Schedule" />
        <div styleName="inner-root">
          <div styleName="item">
            <span styleName="item-title">Query every</span>
            <PollingInterval
              pollingInterval={dataStream.pollingInterval}
              allowedValues={['daily', 'h12', 'h8', 'h6', 'h4', 'h3', 'h2', 'hourly', 'm15', 'm5', 'm1']}
              styleName="item-width"
              onChange={(v) => this.pollingChanged(v)}
            />
          </div>

          <div styleName="item collect-on">
            <span className="ellipsis" styleName="item-title">
              Delay (Minutes)
            </span>
            <span styleName="item-description">
              To minimize partial results in your reports, set the delay according to the time it takes the data to be
              available
            </span>
            <input type="number" onChange={this.delayMinutesChanged} min="0" value={dataStream.delayMinutes} />
          </div>
        </div>

        <div styleName="inner-root">
          <div styleName="item">
            <span className="ellipsis" styleName="item-title">
              Time Zone
            </span>
            <span styleName="item-description">Process data according to this time zone.</span>
            <TimeZone
              timeZone={dataStream.timeZone}
              extraClassName="blue-style"
              styleName="item-width"
              onChange={(v) => this.timeZoneChanged(v)}
            />
          </div>

          <div styleName="item collect-on">
            <span className="ellipsis" styleName="item-title">
              Query Backfill Policy
            </span>
            <span styleName="item-description">
              Set policy to backfill missing data records after stream Init.
              <br />
              0: Query last interval, 1: Query preceding and last intervals.
            </span>
            <input
              type="number"
              onChange={this.maxBackFillIntervalsChanged}
              min="0"
              value={dataStream.maxBackFillIntervals}
            />
          </div>
        </div>

        <div styleName="inner-root">
          <div styleName="item">
            <span className="ellipsis" styleName="item-title">
              Collect Since
            </span>
            <span styleName="item-description">Time span of data to query while initializing a stream.</span>
            <div styleName="item-width">
              <SchedularDateRange
                dateRange={dataStream.historicalDateRange}
                styleName="item-width"
                title="Time span"
                isUnix
                allowedRangeTypes={getAllowedRangeTypesByPollingInterval(dataStream.pollingInterval)}
                timeZoneName={timeZoneName}
                onChange={(v) => this.dateRangeChanged(v)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
