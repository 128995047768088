// @flow
import React from 'react';
import './RecipientItem.module.scss';
import Tooltip, {TYPES as TOOLTIP_TYPES} from 'common/componentsV2/Tooltip';

export const TYPES = {
  USER: 'USER',
  SLACK: 'SLACK',
  EMAIL: 'EMAIL',
  PAGER_DUTY: 'PAGER_DUTY',
  WEBHOOK: 'WEBHOOK',
  OPS_GENIE: 'OPS_GENIE',
  JIRA: 'JIRA',
  TAMTAM: 'TAMTAM',
  SNS: 'SNS',
};

type PropTypes = {
  name: string,
  type: string,
};

export default class RecipientItem extends React.PureComponent {
  props: PropTypes;

  render() {
    const {name, type} = this.props;
    // TODO GABPAC - check icons for everyone
    let icon = '';
    switch (type) {
      case TYPES.USER:
        icon = 'icn-general16-user';
        break;
      case TYPES.EMAIL:
        icon = 'icn-general16-mail';
        break;
      case TYPES.SLACK:
        icon = 'icn-other16-slack';
        break;
      case TYPES.PAGER_DUTY:
        icon = 'icn-other16-pagerduty';
        break;
      case TYPES.WEBHOOK:
        icon = 'icn-other16-webhook';
        break;
      case TYPES.OPS_GENIE:
        icon = 'icn-other16-opsgenie';
        break;
      case TYPES.JIRA:
        icon = 'icn-other16-jira';
        break;
      case TYPES.TAMTAM:
        icon = 'icn-other16-tamtam';
        break;
      case TYPES.SNS:
        icon = 'icn-other16-sns';
        break;
      case TYPES.SLACKAPP:
        icon = 'icn-other16-slack';
        break;
      default:
    }

    return (
      <div styleName="container">
        <div className={['icon', icon].join(' ')} />
        <Tooltip content={name} type={TOOLTIP_TYPES.SMALL}>
          <span>{name}</span>
        </Tooltip>
      </div>
    );
  }
}
