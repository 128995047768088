// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setFileUploadProgress, setFileUploadName, setUploadedFileUrl, setUploadFileError} from 'bc/store/actions';
import {segmentCausingEvent} from 'common/store/actions';
import {isBcReadOnlyPermision, getLookupTablesItemsNames} from 'bc/store/selectors';
import {uploadLookup, CSV_TYPES_ARR} from 'bc/services/lookupService';
import ConfirmationModal from 'common/components/modals/ConfirmationModal';
import ErrorModal from 'common/components/modals/ErrorModal';
import './FileUploadToAws.module.scss';

type PropTypes = {
  buttonComponent?: any,
  isBcReadOnly: boolean,
  lookupItems: Array,

  setFileUploadProgress: Function,
  setFileUploadName: Function,
  setUploadedFileUrl: Function,
  setUploadFileError: Function,
  segmentCausingEvent: Function,
};

@connect(
  (state) => ({
    isBcReadOnly: isBcReadOnlyPermision(state),
    lookupItems: getLookupTablesItemsNames(state),
  }),
  {
    setFileUploadProgress,
    setFileUploadName,
    setUploadedFileUrl,
    setUploadFileError,
    segmentCausingEvent,
  },
)
export default class LookupTableUpload extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    buttonComponent: null,
  };

  state = {
    isFileErrorOpen: false,
    fileErrorText: '',
  };

  closeFileErrorModal = () => {
    this.setState({fileErrorText: ''});
    this.setState({isFileErrorOpen: false});
  };

  openLookupModal = () => {
    this.setState({isLookupUploadModalOpen: true});
  };

  closeLookupModal = () => {
    this.setState({isLookupUploadModalOpen: false});
  };

  clicked = () => {
    this.closeLookupModal();
    document.getElementById('fileInput').click();
  };

  uploadFileError = (/* err */) => {
    this.props.setUploadFileError();
    this.props.setFileUploadName('');
  };

  onChange = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      if (!CSV_TYPES_ARR.includes(file.type) && !file.name.toLowerCase().endsWith('.csv')) {
        this.setState({fileErrorText: `The file ${file.name} could not be uploaded because it is not a CSV file.`});
        this.setState({isFileErrorOpen: true});
      } else if (this.props.lookupItems.includes(file.name)) {
        this.setState({
          fileErrorText: `The file ${file.name} could not be uploaded because a file with this name
          already exists.`,
        });
        this.setState({isFileErrorOpen: true});
      } else {
        this.props.segmentCausingEvent({
          segment: {
            category: 'bc/data-manager',
            name: 'Upload-Lookup-Table',
          },
        });
        this.props.setFileUploadProgress(0);
        this.props.setFileUploadName(file.name);
        this.props.setUploadedFileUrl({});
        uploadLookup(file, this.props.setUploadedFileUrl, this.uploadFileError, this.props.setFileUploadProgress);
      }
    }
  };

  render() {
    const {isBcReadOnly} = this.props;
    const {isLookupUploadModalOpen, isFileErrorOpen, fileErrorText} = this.state;

    if (isBcReadOnly) {
      return (
        <span styleName="read-only">
          {this.props.buttonComponent ? (
            <span styleName="disabled-style">{this.props.buttonComponent}</span>
          ) : (
            <span className="btn btn-flat btn-primary andt-section-header" styleName="disabled-style">
              Lookup Tables
              <i className="icon icn-icon-blue-plus" automation-id="dataCollectorNewlookupButton" />
            </span>
          )}
        </span>
      );
    }

    return (
      <form styleName="root">
        <span role="presentation" onClick={this.openLookupModal}>
          {this.props.buttonComponent ? (
            this.props.buttonComponent
          ) : (
            <span className="btn btn-flat btn-primary andt-section-header">
              Lookup Tables
              <i className="icon icn-icon-blue-plus" automation-id="dataCollectorNewlookupButton" />
            </span>
          )}
        </span>
        <input
          id="fileInput"
          type="file"
          onChange={this.onChange}
          automation-id="dataCollectorLookupFileUploadButton"
        />

        {isLookupUploadModalOpen && (
          <ConfirmationModal
            modalSize="large"
            onClose={this.closeLookupModal}
            onConfirm={this.clicked}
            title="Upload Guidelines"
            message="Your uploaded table should follow these guidelines, to make sure Anodot can process it:"
            buttons={['Cancel', 'Choose file']}
            isOpen={isLookupUploadModalOpen}
          >
            <ul className="instruction-list">
              <li> The file should be a CSV file</li>
              <li> The file should contain only 2 columns and up to 10,000 rows</li>
              <li> The first row should contain the column names</li>
              <li> The left column (input column) should contain unique values</li>
            </ul>
          </ConfirmationModal>
        )}

        {isFileErrorOpen && (
          <ErrorModal
            onClose={this.closeFileErrorModal}
            title="Your table wasn't uploaded"
            errorMsg={fileErrorText}
            isOpen={isFileErrorOpen}
          />
        )}
      </form>
    );
  }
}
