// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Tooltip from 'common/componentsV2/Tooltip';
import Loading from 'metrics/components/Loading';
import * as selectors from 'bc/store/selectors';
import {fetchGoogleAdsAccounts, fetchGoogleAdsTemplates} from 'bc/store/actions';
import '../../ViewStreamSummaryModalV2.module.scss';

type PropTypes = {
  dataStream: Object,

  // connect
  accounts: Array,
  templates: Array,
  fetchGoogleAdsAccounts: Function,
  fetchGoogleAdsTemplates: Function,
};

@connect(
  (state) => ({
    accounts: selectors.getGoogleAdsAccountsItems(state),
    templates: selectors.getGoogleAdsTemplatesItemsOrdered(state),
  }),
  {
    fetchGoogleAdsAccounts,
    fetchGoogleAdsTemplates,
  },
)
export default class GoogleAdsDataSection extends PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {dataStream} = this.props;

    this.props.fetchGoogleAdsAccounts(dataStream.dataSourceId, {skip: true});
    this.props.fetchGoogleAdsTemplates(dataStream.type);
  }

  render() {
    const {
      dataStream: {clientCustomerId, reportType},
      accounts,
      templates,
    } = this.props;
    const foundAccount = accounts.find((acc) => acc.id.toString() === clientCustomerId.toString());
    const foundTemplate = templates.find((template) => template.reportType === reportType);

    return (
      <div styleName="section">
        <div styleName="vs-section-header">CONTEXT DATA</div>
        <div styleName="vs-row">
          <div styleName="vs-label">Account</div>
          {foundAccount ? <div styleName="vs-value">{foundAccount.name}</div> : <Loading styleName="spinner" />}
        </div>
        <div styleName="vs-row">
          <div styleName="vs-label">Use Case</div>
          {foundTemplate ? (
            <div styleName="vs-value with-ellipsis">
              <Tooltip placement="top" content={foundTemplate.name} delay={400}>
                <span>{foundTemplate.name}</span>
              </Tooltip>
            </div>
          ) : (
            <Loading styleName="spinner" />
          )}
        </div>
      </div>
    );
  }
}
