import {combineEpics} from 'redux-observable';
import * as profileActions from 'profile/store/actions';
import * as commonActions from 'common/store/actions';
import highchartsInit from 'charts/highchartsInit';
import Highcharts from 'highcharts';
import {getCorrectTimezoneName} from 'common/utils/dateService';

const setTimeZoneToCharts = (action$) =>
  action$.ofType(profileActions.timeZoneChanged.TYPE).flatMap(({payload}) => {
    const tzName = getCorrectTimezoneName(payload);
    Highcharts.setOptions({
      global: {
        timezone: tzName,
      },
    });
    return [];
  });

const init = (action$) =>
  action$
    .ofType(commonActions.appInit.TYPE)
    .map(() => highchartsInit())
    .flatMap(() => []);

export default combineEpics(init, setTimeZoneToCharts);
