/* eslint-disable react/no-unescaped-entities,no-nested-ternary */
// @flow
import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {noop} from 'lodash';
import MultiLevelDropdown from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlList/MultiLevelDropdown';
import OptionComponent from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlList/OptionComponent';
import ButtonListedComponent from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlList/ButtonListedComponent';
import CreateBtnPopover from 'alerts.management/components/editor/simpleAlertForm/CreateBtnPopover';
import SplitButton from 'common/componentsV2/SplitButton';
import Button, {COLORS, WIDTHS} from 'common/componentsV2/Button';
import {
  createSimpleAlert,
  getVolumeCondition,
  simpleAlertCreateAnother,
  simpleAlertGoAdvancedOptions,
  simpleAlertSetDimensions,
  simpleAlertSetFirstMeasure,
  simpleAlertSetIsRatio,
  simpleAlertSetSecondMeasure,
} from 'alerts.management/store/actions';
import {fetchDataStreams} from 'bc/store/actions';
import * as selectors from 'alerts.management/store/selectors';
import * as profileSelector from 'profile/store/selectors';
import DimensionsDdl from 'alerts.management/components/editor/simpleAlertForm/DimensionsDdl';
import DirectionDdl from 'alerts.management/components/editor/simpleAlertForm/DirectionDdl';
import MinDurationValueDdl from 'alerts.management/components/editor/simpleAlertForm/MinDurationValueDdl';
import TimeScaleDdl from 'alerts.management/components/editor/simpleAlertForm/TimeScaleDdl';
import Info from 'common/componentsV2/Info';
import RecipientsDdl from 'alerts.management/components/editor/simpleAlertForm/RecipientsDdl';
import AlertEstimation from './AlertEstimation';
import Spinner, {SIZES} from '../../../../common/componentsV2/Spinner';
import './SimpleAlertForm.module.scss';

type PropTypes = {
  // connect
  alert: Object,
  fetchDataStreams: Function,
  streamSchemas: Object,
  firstMeasure: Object,
  secondMeasure: Object,
  simpleAlertSetFirstMeasure: Function,
  simpleAlertSetSecondMeasure: Function,
  createSimpleAlert: Function,
  simpleAlertSetIsRatio: Function,
  simpleAlertGoAdvancedOptions: Function,
  isRatio: boolean,
  simpleAlertCreateAnother: Function,
  selectedDimensions: Object,
  createSimpleAlertDisabledMessage: String,
  autoVolumeEnabled: Boolean,
  volumeConditions: Object,
  isLoadingVolumeCondition: Boolean,
  getVolumeCondition: Function,
  onClose?: Function,
  isAlertEditorV2Enabled: boolean,
  history: Object,
};

@connect(
  (state) => ({
    isAlertEditorV2Enabled: selectors.getAlertEditorV2Enabled(state),
    alert: selectors.getSimpleAlertObject(state),
    volumeConditions: selectors.getVolumeConditions(state),
    streamSchemas: selectors.getStreamSchemas(state),
    firstMeasure: selectors.getSimpleAlertFirstMeasure(state),
    secondMeasure: selectors.getSimpleAlertSecondMeasure(state),
    simpleAlertSelectedDirection: selectors.getSimpleAlertSelectedDirection(state),
    selectedDimensions: selectors.getSimpleAlertSelectedDimensions(state),
    isRatio: selectors.getSimpleAlertIsRatio(state),
    createSimpleAlertDisabledMessage: selectors.createSimpleAlertDisabledMessage(state),
    autoVolumeEnabled: profileSelector.getAutoVolumeEnabled(state),
    isLoadingVolumeCondition: selectors.getIsLoadingAutoVolumeCondition(state),
  }),
  {
    fetchDataStreams,
    simpleAlertSetFirstMeasure,
    simpleAlertSetSecondMeasure,
    simpleAlertSetDimensions,
    createSimpleAlert,
    simpleAlertSetIsRatio,
    simpleAlertGoAdvancedOptions,
    simpleAlertCreateAnother,
    getVolumeCondition,
  },
)
class SimpleAlertForm extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    onClose: noop,
  };

  state = {
    isRecipientsVisible: false,
    errorMessage: null,
    internalClick: false,
  };

  static getDerivedStateFromProps(props, state) {
    const {createSimpleAlertDisabledMessage} = props;
    const {prevCreateSimpleAlertDisabledMessage} = state;
    if (
      !createSimpleAlertDisabledMessage &&
      prevCreateSimpleAlertDisabledMessage !== createSimpleAlertDisabledMessage
    ) {
      return {errorMessage: null, prevCreateSimpleAlertDisabledMessage: createSimpleAlertDisabledMessage};
    }
    return null;
  }

  componentDidMount() {
    this.props.fetchDataStreams();
  }

  componentDidUpdate(prevProps) {
    const {autoVolumeEnabled, volumeConditions, isLoadingVolumeCondition} = this.props;
    if (
      isLoadingVolumeCondition === false &&
      autoVolumeEnabled &&
      volumeConditions &&
      volumeConditions !== prevProps.volumeConditions
    ) {
      this.createAlert();
      this.props.onClose();
    }
  }

  createAlert = () => {
    this.props.createSimpleAlert();
  };

  onFirstMeasureSelect = (val) => {
    this.props.simpleAlertSetFirstMeasure(val);
    if (this.secondMultilevel) {
      this.secondMultilevel.reset();
    }
  };

  onSecondMeasureSelect = (val) => {
    this.props.simpleAlertSetSecondMeasure(val);
  };

  onAdvanceOptionsClicked = () => {
    const {history, isAlertEditorV2Enabled} = this.props;
    if (isAlertEditorV2Enabled) {
      history.push(`/alert-manager/new?source=simpleAlert${location.search}`);
    } else {
      this.props.simpleAlertGoAdvancedOptions();
    }
  };

  onCreateClicked = () => {
    const {autoVolumeEnabled, createSimpleAlertDisabledMessage} = this.props;
    if (createSimpleAlertDisabledMessage) {
      this.setState({errorMessage: createSimpleAlertDisabledMessage});
      return;
    }
    this.setState({errorMessage: null});
    if (autoVolumeEnabled) {
      this.props.getVolumeCondition(this.props.alert.exportServerFormat(), true);
      return;
    }
    this.createAlert();
    this.props.onClose();
  };

  onRatioToggle = (val) => {
    this.props.simpleAlertSetIsRatio(val);
  };

  onAddRecipientsClick = () => {
    this.setState({isRecipientsVisible: true});
  };

  onCreateAnotherClick = () => {
    if (this.firstMultilevel) {
      this.firstMultilevel.reset();
    }
    if (this.secondMultilevel) {
      this.secondMultilevel.reset();
    }
    this.props.simpleAlertCreateAnother();
  };

  internalClick = () => {
    this.setState((prevState) => ({
      internalClick: !prevState.internalClick,
    }));
  };

  render() {
    const {
      firstMeasure,
      secondMeasure,
      isRatio,
      createSimpleAlertDisabledMessage,
      selectedDimensions,
      isLoadingVolumeCondition,
    } = this.props;
    const isDropDownDisabled = !isRatio ? !firstMeasure.label : !(firstMeasure.label && secondMeasure.label);
    return (
      <div styleName="container">
        <header>Create a New Alert</header>
        <div styleName="alert-me-on">
          <div styleName="flex-align info-container">
            <label>{isRatio ? 'Alert me on the ratio between:' : 'Alert me on:'}</label>
            <div styleName="info">
              <Info
                text={
                  <span>
                    {isRatio
                      ? 'Select the dividend and the divisor measures to create a ratio KPI.'
                      : 'Select a Measure (KPI) to be alerted on. Only measures originating from streams appear here.'}
                  </span>
                }
              />
            </div>
          </div>
          <div styleName="selectors">
            <MultiLevelDropdown
              ref={(firstMultilevel) => {
                this.firstMultilevel = firstMultilevel;
              }}
              optionComponent={<OptionComponent />}
              options={this.props.streamSchemas}
              onSelect={this.onFirstMeasureSelect}
              rowHeight={40}
              isUseSearch
              width={352}
              height={330}
              disabled={!this.props.streamSchemas || this.props.streamSchemas.length === 0}
              button={<ButtonListedComponent placeholder="Select Measure" text={firstMeasure.measure} />}
              secondLevelTitle="Select a Stream"
            />
            {isRatio && (
              <Fragment>
                <div styleName="separator">&</div>
                <MultiLevelDropdown
                  ref={(secondMultilevel) => {
                    this.secondMultilevel = secondMultilevel;
                  }}
                  optionComponent={<OptionComponent />}
                  options={this.props.streamSchemas}
                  onSelect={this.onSecondMeasureSelect}
                  rowHeight={40}
                  isUseSearch
                  width={355}
                  height={330}
                  button={<ButtonListedComponent placeholder="Select Measure" text={secondMeasure.measure} />}
                  disabled={!firstMeasure.label}
                  ddLeftOffset={150}
                  position="right"
                />
              </Fragment>
            )}
          </div>
        </div>
        <div
          styleName={['alert-by-dimensions', 'info-container', !firstMeasure.label ? 'disabled' : ''].join(' ').trim()}
        >
          <label>By:</label>
          <DimensionsDdl disabled={isDropDownDisabled} />
          {selectedDimensions.length === 0 ? <span styleName="optional-label">(Optional)</span> : null}
          <div styleName="info">
            <Info
              text={
                <Fragment>
                  <p>Group the metrics by relevant dimensions. For Example: </p>
                  <p>Country, Device Type (3 Dimensions Maximum)</p>
                </Fragment>
              }
            />
          </div>
        </div>
        <div
          styleName={['when-it-is', 'line', 'info-container', !firstMeasure.label ? 'disabled' : ''].join(' ').trim()}
        >
          <i styleName="icon-up-down" className="icon icn-general16-direction" />
          <span>When it:</span>
          <DirectionDdl disabled={isDropDownDisabled} />
          <div styleName="info">
            <Info
              text={
                <span>
                  A <strong>Spike</strong> means metric values are higher than the calculated baseline.
                  <p />A<strong>Drop</strong> means metric values are lower than the baseline.
                </span>
              }
            />
          </div>
        </div>
        <div styleName={['duration', 'line', 'info-container', !firstMeasure.label ? 'disabled' : ''].join(' ').trim()}>
          <i styleName="icon-clock" className="icon icn-general16-timescale" />
          <span>For at least:</span>
          <div styleName="min-duration">
            <MinDurationValueDdl disabled={isDropDownDisabled} />
          </div>
          <TimeScaleDdl disabled={isDropDownDisabled} />
          <div styleName="info">
            <Info
              text={
                <span>
                  An anomaly needs to be longer than the defined minimum duration in order to trigger the alert.
                </span>
              }
            />
          </div>
        </div>
        {this.state.isRecipientsVisible ? (
          <div styleName={['recipients'].join(' ').trim()}>
            <div styleName="line">
              <i styleName="icon-recipients" className="icon icn-general16-mail" />
              <span>Recipients:</span>
            </div>
            <RecipientsDdl />
          </div>
        ) : null}
        <div styleName="estimation">
          <AlertEstimation errorMessage={this.state.errorMessage} />
        </div>
        <div styleName="actions-footer">
          <Button
            colorSchema={COLORS.GRAY_200}
            widtg={WIDTHS.NARROW}
            text="Advanced Options"
            onClick={this.onAdvanceOptionsClicked}
          />

          <div styleName="create-btn-wrapper">
            {isLoadingVolumeCondition && <Spinner color="#3d4c59" size={SIZES.SMALL_30} />}
            <SplitButton
              isMainDisabled={isLoadingVolumeCondition}
              text="Create"
              onClick={this.onCreateClicked}
              internalClickState={this.state.internalClick}
              menuComponent={
                <CreateBtnPopover
                  ratioToggle={this.onRatioToggle}
                  addRecipientsClick={this.onAddRecipientsClick}
                  createAnotherClick={this.onCreateAnotherClick}
                  createAnotherDisable={!!createSimpleAlertDisabledMessage}
                  isRatio={isRatio}
                  internalClick={this.internalClick}
                />
              }
              placement="top"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SimpleAlertForm);
