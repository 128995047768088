// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc.cubes/store/selectors';
import {setEditableTopCubeKeyVal} from 'bc.cubes/store/actions';
import {get} from 'lodash';

import './CubeDimensionsSelectedItem.module.scss';

const DeselectIcon = () => <div styleName="deselect" className="icon andt-icon-white-close" />;

type PropTypes = {
  id: string,
  uiName: string,
  onRemoveItem: Function,

  // connect
  editableTopCube: Object,
  setEditableTopCubeKeyVal: Function,
};

const EMPTY_ARRAY = [];
@connect(
  (state) => ({
    editableTopCube: selectors.getEditableTopCube(state),
  }),
  {
    setEditableTopCubeKeyVal,
  },
)
export default class CubeDimensionsSelectedItem extends PureComponent {
  props: PropTypes;

  valueChanged = (val) => {
    if (val) {
      this.props.setEditableTopCubeKeyVal({mandatoryDimensions: [this.props.id]}, {isPreviewCube: true});
    } else {
      this.props.setEditableTopCubeKeyVal({mandatoryDimensions: EMPTY_ARRAY}, {isPreviewCube: true});
    }
  };

  render() {
    const {id, uiName, onRemoveItem, editableTopCube} = this.props;

    return (
      <div styleName="panel">
        <div styleName="dimension-panel">
          <span className="ellipsis">{uiName}</span>
          <button
            type="button"
            className="btn btn-flat btn-icon btn-blue-light"
            onClick={() => setTimeout(() => onRemoveItem(id), 0)}
          >
            <DeselectIcon />
          </button>
        </div>
        <div styleName="checkbox-container" className="cb2">
          <input
            type="checkbox"
            id={`mandatory-${id}`}
            checked={get(editableTopCube, 'mandatoryDimensions[0]', '') === id}
            onChange={(e) => this.valueChanged(e.target.checked)}
          />
          <label htmlFor={`mandatory-${id}`} />
        </div>
      </div>
    );
  }
}
