import {
  SourceAwsSns,
  SourceChannelEmails,
  SourceJira,
  SourceOpsgenie,
  SourcePagerDuty,
  SourceSlack,
  SourceTamtam,
  SourceTeams,
  SourceWebhook,
} from 'channels/icons/svgs';

export default [
  {
    id: 'email',
    value: 'email',
    label: 'Email',
    icon: SourceChannelEmails,
  },
  {
    id: 'webhook',
    value: 'webhook',
    label: 'Webhook',
    icon: SourceWebhook,
  },
  {
    id: 'slack',
    value: 'slack',
    label: 'Slack',
    icon: SourceSlack,
  },
  {
    id: 'pagerduty',
    value: 'pagerduty',
    label: 'PagerDuty',
    icon: SourcePagerDuty,
  },
  {
    id: 'jira',
    value: 'jira',
    label: 'JIRA',
    icon: SourceJira,
  },
  {
    id: 'opsgenie',
    value: 'opsgenie',
    label: 'Opsgenie',
    icon: SourceOpsgenie,
  },
  {
    id: 'msteams',
    value: 'msteams',
    label: 'MS Teams',
    icon: SourceTeams,
  },
  {
    id: 'tamtam',
    value: 'tamtam',
    label: 'Tam Tam',
    icon: SourceTamtam,
  },
  {
    id: 'sns',
    value: 'sns',
    label: 'SNS',
    icon: SourceAwsSns,
  },
  {
    id: 'slackapp',
    value: 'slackapp',
    label: 'Slack App',
    icon: SourceSlack,
  },
];
