import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {useField} from 'react-final-form';
import TileStatTemplate from 'dashboards/components/TileStatTemplate';
import useExpressionTreesTile from 'dashboards/components/metricTiles/useExpressionTreesTile';
import TileHeader from 'dashboards/components/metricTiles/TileHeader';
import {getDateValue} from 'common/utils/dateRangeService';
import {get} from 'lodash';
import {makeFiltersPayload, parseFiltersPayload} from 'dashboards/utils';
import {useSelector} from 'react-redux';
import {getDashboardUserSettings} from 'dashboards/store/selectors';
import {isCompositeExpression} from 'metrics/services/metricsService';
import {immutableSetIn} from 'common/hooks/useByTreeExpManager';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const useStyles = makeStyles(() => ({
  wrapperTile: {
    flexGrow: 1,
    borderRadius: 6,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 8,
    '&:hover': {
      '@global': {
        '.editMode, .editWrapper': {
          display: 'flex',
          visibility: 'visible',
        },
      },
    },
  },
}));

const StatTileContainer = ({
  tileId,
  tileData,
  matchUrl,
  isOwnerUser,
  dashboardData,
  isAnonymous,
  isVisible,
}: {
  tileId: string,
  tileData: Object,
  matchUrl: string,
  isOwnerUser: boolean,
  dashboardData: Object,
  isAnonymous: boolean,
  isVisible: boolean,
}) => {
  const chartId = `chart_${tileId}`;
  const classes = useStyles();
  const {data: dashboardUserSettings} = useSelector(getDashboardUserSettings);
  const tileSettingsValue = get(dashboardUserSettings, `tiles[${tileId}]`, EMPTY_OBJECT);

  const {
    input: {value: dateRange},
  } = useField('dateRange');
  const {
    input: {value: filters},
  } = useField('filters');
  const {
    input: {value: timeScale},
  } = useField('timeScale');

  const {
    input: {value: isResize},
  } = useField('isResize');
  const {
    input: {value: isEditableMode},
  } = useField('isEditableMode');

  const commonValues = useMemo(
    () => ({
      dateRange: getDateValue(dateRange),
      timeScale,
      filters: parseFiltersPayload(makeFiltersPayload(filters)),
    }),
    [dateRange, timeScale, filters],
  );

  const computedValues = useMemo(
    () => ({
      dateRange: getDateValue(get(tileSettingsValue, 'dateRange', dateRange)),
      timeScale: get(tileSettingsValue, 'timeScale', timeScale),
      filters: parseFiltersPayload(get(tileSettingsValue, 'selectorsFilter.selectors', makeFiltersPayload(filters))),
    }),
    [tileSettingsValue, dateRange, timeScale, filters],
  );

  // Update 'excludeComposites' value according to condition 'isCompositeExpression()'
  const expressionTrees = get(tileData, 'andtGauge.expressionTrees');
  const key = Object.keys(expressionTrees)[0];
  const compositeObject = useMemo(() => expressionTrees[key], [expressionTrees, key]);
  const showComposites = useMemo(
    () => (isCompositeExpression(compositeObject.expressionTree) ? false : !compositeObject.excludeComposites),
    [compositeObject],
  );
  const computedTileData = useMemo(
    () => immutableSetIn(tileData, `andtGauge.expressionTrees[${key}].excludeComposites`, !showComposites),
    [tileData, showComposites, key],
  );

  const {metrics, validationMessage, isCompositeDataLoading, compositeErrorsMap} = useExpressionTreesTile({
    ...computedValues,
    tileData: computedTileData,
    chartId,
    isVisible,
  });

  return (
    <div className={`${classes.wrapperTile} bgcolor_white-500`}>
      <TileHeader
        dashboardData={dashboardData}
        tileData={tileData}
        isEditableMode={isEditableMode}
        matchUrl={matchUrl}
        formValues={computedValues}
        commonValues={commonValues}
        isOwnerUser={isOwnerUser}
        isAnonymous={isAnonymous}
        validationMessage={validationMessage}
        compositeErrorsMap={compositeErrorsMap}
        isCompositeDataLoading={isCompositeDataLoading}
      />
      <TileStatTemplate
        tileData={tileData}
        metrics={metrics || EMPTY_ARRAY}
        isLoading={isCompositeDataLoading}
        isResize={isResize}
      />
    </div>
  );
};

export default React.memo(StatTileContainer);
