// @flow
import React, {useCallback, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {
  ACTIVITY_VALUE,
  DEFAULT_PARAMS,
  getNameActivityType,
  OPTIONS_ACTION_TYPE,
  OPTIONS_ACTIVITY_TYPE,
  PARAMS_NAME,
  SYSTEM_USER,
} from 'admin.activityLog/services/activityLogService';
import {isEqual} from 'lodash';
import DateRangesDdl, {THEME} from 'common/components/dateTime/DateRangesDdl';
import {getDateValue} from 'common/utils/dateRangeService';
import UsersChannelsFilter from 'alerts.channels/components/UsersChannelsFilter';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import OptionComponentCheckboxHeaders from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentCheckboxHeaders';
import FiltersPanel from 'common/componentsV2/FiltersPanel';

const EMPTY_ARRAY = [];

type PropsType = {
  queryParams: Object,
  setQueryParams: Function,
  isOpenFilters: boolean,
  toggleFilters: Function,
  usersData: Array<Object>,
  data: Array<Object>,
  timeZoneName: string,
};

const useStyles = makeStyles(({typography}) => ({
  labelFilter: {
    ...typography.subtitle1,
    lineHeight: '24px',
    marginBottom: 4,
    display: 'flex',
  },
  labelActivityName: {
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
}));

const ActivityLogFilter = ({
  queryParams,
  setQueryParams,
  isOpenFilters,
  toggleFilters,
  usersData,
  data,
  timeZoneName,
}: PropsType) => {
  const classes = useStyles();

  const usersOptions = useMemo(() => {
    const result = usersData.map((o) => ({
      label: `${o.firstName} ${o.lastName}`,
      value: o._id,
    }));
    result.push(SYSTEM_USER);
    return result;
  }, [usersData]);

  const optionsByActivityType = useMemo(() => {
    const result = [];
    const computedArr = data.flatMap((o) =>
      o.entityType === queryParams[PARAMS_NAME.ACTIVITY]
        ? [{label: o.entityName, value: o.entityDefinition.id || o.entityDefinition._id}]
        : [],
    );

    computedArr.forEach(function(item) {
      const i = result.findIndex((x) => x.value === item.value);
      if (i <= -1) {
        result.push(item);
      }
    });

    return result;
  }, [data, queryParams[PARAMS_NAME.ACTIVITY]]);

  const selectedOptionUser = useMemo(
    () => usersOptions.filter((item) => (queryParams[PARAMS_NAME.USERS] || '').includes(item.value)),
    [usersOptions, queryParams[PARAMS_NAME.USERS]],
  );

  const selectedActionType = useMemo(
    () => OPTIONS_ACTION_TYPE.filter((item) => (queryParams[PARAMS_NAME.ACTION_TYPE] || '').includes(item.value)),
    [queryParams[PARAMS_NAME.ACTION_TYPE]],
  );

  const selectedByActivityId = useMemo(
    () =>
      optionsByActivityType.filter((item) =>
        (queryParams[getNameActivityType(queryParams[PARAMS_NAME.ACTIVITY])] || '').includes(item.value),
      ),
    [optionsByActivityType, queryParams],
  );

  const onClearAll = useCallback(() => {
    setQueryParams(DEFAULT_PARAMS);
  }, []);

  const onClear = useCallback((param) => {
    setQueryParams({[param]: undefined});
  }, []);

  const onChange = useCallback((items, nameField) => {
    const value = items.length ? items.map((item) => item.value || item.name).join(',') : undefined;
    setQueryParams({[nameField]: value});
  }, []);

  const clearActivityTypes = useMemo(() => ({
    [PARAMS_NAME.STREAM]: undefined,
    [PARAMS_NAME.CHANNEL]: undefined,
    [PARAMS_NAME.ALERT]: undefined,
    [PARAMS_NAME.PIPELINE]: undefined,
  }));

  const onChangeHandler = useCallback(({value, constRange}, nameField) => {
    if (String(nameField) === String(PARAMS_NAME.ACTIVITY)) {
      setQueryParams({
        ...clearActivityTypes,
        [PARAMS_NAME.ACTIVITY]: value === ACTIVITY_VALUE.ALL ? undefined : value,
      });
    } else {
      setQueryParams({[nameField]: value || constRange});
    }
  }, []);

  const selectedOption = (options, name) => options.find((o) => o.value === queryParams[name]) || EMPTY_ARRAY;

  return (
    <FiltersPanel
      isOpen={isOpenFilters}
      isSaveView={false}
      isShareLink={false}
      onClose={toggleFilters}
      onClearAll={onClearAll}
      isClearAll={!isEqual(queryParams, DEFAULT_PARAMS)}
    >
      <div className="mt_2-5">
        <div className={classes.labelFilter}>Time Frame</div>
        <DateRangesDdl
          dateRange={getDateValue(
            {[PARAMS_NAME.CONST_RANGE]: queryParams[PARAMS_NAME.CONST_RANGE]},
            false,
            timeZoneName,
          )}
          onChange={(option) => onChangeHandler({value: option[PARAMS_NAME.CONST_RANGE]}, PARAMS_NAME.CONST_RANGE)}
          menuWidth={241}
          timeZoneName={timeZoneName}
          buttonWidth="100%"
          theme={THEME.HIGHLIGHTED}
          allowedRangeTypes={['h1', 'h4', 'd1', 'd3', 'w1', 'w2', 'm1', 'm3']}
          allowedCustomDates={false}
        />
      </div>
      <div className="mt_2-5">
        <div className={classes.labelFilter}>Users</div>
        <UsersChannelsFilter
          onChange={(items) => onChange(items, PARAMS_NAME.USERS)}
          sortedFlattenChannels={usersOptions}
          sortAlphabetic
          value={selectedOptionUser}
          isFullWidthButton
          footerComponent={
            <FormDdlActionBar
              isVisible
              isClearAllDisabled={!selectedOptionUser || !selectedOptionUser.length}
              onClearAll={() => onClear(PARAMS_NAME.USERS)}
            />
          }
          menuWidth={280}
        />
      </div>
      <div className="mt_2-5">
        <div className={classes.labelFilter}>Action Type</div>
        <FormDdlSelect
          options={OPTIONS_ACTION_TYPE}
          selected={selectedActionType}
          button={
            <BigDropdownButton
              isPristine={!selectedActionType.length}
              placeholder="All Actions"
              label={
                selectedActionType.length === 1 ? selectedActionType[0].label : `${selectedActionType.length} Selected`
              }
              shortBlueStyle
              isFullWidth
            />
          }
          optionComponent={<OptionComponentCheckboxHeaders />}
          onChange={(items) => onChange(items, PARAMS_NAME.ACTION_TYPE)}
          width={280}
          maxWidth={280}
          isMulti
          height={260}
          footerComponent={
            <FormDdlActionBar
              isVisible
              isClearAllDisabled={!selectedActionType || !selectedActionType.length}
              onClearAll={() => onClear(PARAMS_NAME.ACTION_TYPE)}
            />
          }
        />
      </div>
      <div className="mt_2-5">
        <div className={classes.labelFilter}>Show Activity of</div>
        <FormDdlSelect
          options={OPTIONS_ACTIVITY_TYPE}
          selected={selectedOption(OPTIONS_ACTIVITY_TYPE, PARAMS_NAME.ACTIVITY)}
          button={
            <BigDropdownButton
              isPristine={!Object.keys(selectedOption(OPTIONS_ACTIVITY_TYPE, PARAMS_NAME.ACTIVITY)).length}
              placeholder="All Activities"
              label={selectedOption(OPTIONS_ACTIVITY_TYPE, PARAMS_NAME.ACTIVITY).label}
              shortBlueStyle
              isFullWidth
            />
          }
          optionComponent={<OptionComponentSimple />}
          onChange={(option) => onChangeHandler(option, PARAMS_NAME.ACTIVITY)}
          width={241}
          maxWidth={241}
        />
      </div>
      {queryParams[PARAMS_NAME.ACTIVITY] && (
        <div className="mt_2-5">
          <div className={classes.labelFilter}>
            <div>Filter By&#xa0;</div>
            <div className={classes.labelActivityName}>{getNameActivityType(queryParams[PARAMS_NAME.ACTIVITY])}</div>
          </div>
          <FormDdlSelect
            options={optionsByActivityType}
            selected={selectedByActivityId}
            isUseSearch
            button={
              <BigDropdownButton
                isPristine={!selectedByActivityId.length}
                placeholder="Select"
                label={
                  selectedByActivityId.length === 1
                    ? selectedByActivityId[0].label
                    : `${selectedByActivityId.length} Selected`
                }
                shortBlueStyle
                isFullWidth
              />
            }
            optionComponent={<OptionComponentCheckboxHeaders />}
            onChange={(items) => onChange(items, getNameActivityType(queryParams[PARAMS_NAME.ACTIVITY]))}
            width={280}
            maxWidth={280}
            height={260}
            placement="top"
            isMulti
            footerComponent={
              <FormDdlActionBar
                isVisible
                isClearAllDisabled={!selectedByActivityId || !selectedByActivityId.length}
                onClearAll={() => onClear(getNameActivityType(queryParams[PARAMS_NAME.ACTIVITY]))}
              />
            }
          />
        </div>
      )}
    </FiltersPanel>
  );
};

export default React.memo(ActivityLogFilter);
