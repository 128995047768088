import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import useClickOutside from 'common/hooks/useClickOutside';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import {segmentClickEvent} from 'common/store/actions';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import CompositeBuilder from '../compositeBuilder/CompositeBuilder';
import * as metricsSelectors from '../../store/selectors';
import './CompositeBuildersContainer.module.scss';
import {
  duplicateExpressionTree,
  removeExpression,
  selectTreeBranch,
  setAllExpressionsSelected,
  setTreeVisible,
} from '../../store/actions';
// TODO: move storage/selectors to common
// eslint-disable-next-line import/order
import {getExpressionErrorsFactory} from 'dashboards/store/selectors';

type PropTypes = {
  isModal: boolean,
  children: React.Node,
};

const CompositeBuildersContainer = ({isModal, children}: PropTypes) => {
  const dispatch = useDispatch();
  const expressionTrees = useSelector(metricsSelectors.getExpressionTrees);
  const selectedExpressionId = useSelector(metricsSelectors.getSelectedExpressionId);
  const allExpressionsSelected = useSelector(metricsSelectors.getAllExpressionsSelected);
  const isAllSelected = useSelector(metricsSelectors.getAllExpressionsSelected);

  const ref = useRef();

  const clickOutside = () => {
    if (!isAllSelected) {
      dispatch(setAllExpressionsSelected(true));
    }
    if (expressionTrees.length === 1) {
      const treeId = expressionTrees[0].id;
      const branchId = expressionTrees[0].expressionTree.root.id;
      dispatch(selectTreeBranch({branchId, selectedExpressionId: treeId}));
    }
  };

  useEffect(() => {
    const stripedTree = JSON.stringify(expressionTrees.map((et) => et.expressionTree.root));
    const numberEmptyFunctions = stripedTree.split('"function":""').length - 1;
    const functionAmount = stripedTree.split('"type":"function"').length - 1 - numberEmptyFunctions;
    const metricsAmount = stripedTree.split('"type":"metric"').length - 1;
    const expressionTreesLength = expressionTrees.length;

    return () => {
      dispatch(
        segmentClickEvent({
          type: 'metric-explorer',
          data: {
            trees: expressionTreesLength,
            functions: functionAmount,
            lines: metricsAmount,
            emptyFunctions: numberEmptyFunctions,
          },
        }),
      );
    };
  }, [JSON.stringify(expressionTrees.map((et) => et.expressionTree.root))]);

  useClickOutside(
    ref,
    clickOutside,
    ['idle-external-click'],
    ['idle-external-click', 'andt-dropdown-menu-list-virtualize'],
  );

  const [closedIds, setClosedIds] = useState([]);

  const getLetter = (index) => String.fromCharCode(65 + index);

  const handleHolderClick = (id) => {
    const index = closedIds.findIndex((c) => c === id);
    if (index > -1) {
      dispatch(segmentClickEvent({type: 'click', name: 'expand-expression'}));
      setClosedIds([...closedIds.slice(0, index), ...closedIds.slice(index + 1)]);
    } else {
      dispatch(segmentClickEvent({type: 'click', name: 'collapse-expression'}));
      setClosedIds([...closedIds, id]);
    }
  };

  const handleContainerClick = (id) => {
    // eslint-disable-next-line no-unused-vars
    const selectedET = expressionTrees.find((et) => et.id === id).expressionTree;
    // dispatch(selectTreeBranch({branchId: selectedET.root.id, selectedExpressionId: id}));
  };

  const handleActionItemSelected = (val, id) => {
    if (val.value === 'delete') {
      dispatch(removeExpression(id));
    }
    if (val.value === 'duplicate' && expressionTrees.length < 5) {
      dispatch(duplicateExpressionTree(id));
    }
  };

  const handleClickVisible = (id, invisible) => {
    dispatch(
      segmentClickEvent(segmentClickEvent({type: 'click', name: invisible ? 'hide-expression' : 'show-expression'})),
    );
    dispatch(setTreeVisible({id, invisible}));
  };

  const compositeErrorsSelector = useMemo(() => getExpressionErrorsFactory('expressionTreesPreview'));
  const compositeErrors = useSelector(compositeErrorsSelector);

  return (
    <DndProvider backend={HTML5Backend}>
      <div styleName="over" ref={ref}>
        {expressionTrees.map((et, index) => (
          <div
            key={index === 0 ? 'initial' : et.id}
            automation-id="metricExplorerExpressionTree"
            onClick={() => handleContainerClick(et.id)}
            styleName={['container', et.id === selectedExpressionId || allExpressionsSelected ? 'selected' : ''].join(
              ' ',
            )}
          >
            {expressionTrees.length > 1 ? (
              <div styleName="top-line">
                <div
                  automation-id="metricExplorerExpressionTreeLetter"
                  styleName={['holder', et.invisible ? 'invisible' : ''].join(' ')}
                  onClick={() => handleHolderClick(et.id)}
                >
                  <div styleName="letter">{getLetter(index)}</div>
                  <div styleName="ico" className="icon icn-arrow16-triangledown" />
                </div>
                <div onClick={(e) => e.stopPropagation()} styleName="holder-menu">
                  <div
                    onClick={() => handleClickVisible(et.id, !et.invisible)}
                    automation-id="metricExplorerExpressionTreeEye"
                    className={['icon', et.invisible ? 'icn-general16-show' : 'icn-general16-hide'].join(' ')}
                    styleName="eye-icon"
                  />
                  <FormDdlSelect
                    options={[
                      {label: 'Delete', value: 'delete', automationId: 'metricExplorerDeleteExpression'},
                      {
                        label: 'Duplicate',
                        value: 'duplicate',
                        isDisabled: expressionTrees.length > 4,
                        automationId:
                          expressionTrees.length > 4
                            ? 'metricExplorerDuplicateExpressionDisabled'
                            : 'metricExplorerDuplicateExpression',
                      },
                    ]}
                    isDisabledSorting
                    button={
                      <div automation-id="metricExplorerExpressionTree3DotsMenu" className="icon icn-general16-3dot" />
                    }
                    optionComponent={<OptionComponentSimple />}
                    onChange={(val) => handleActionItemSelected(val, et.id)}
                    width={125}
                  />
                </div>
              </div>
            ) : null}
            {closedIds.findIndex((c) => c === et.id) > -1 ? null : (
              <CompositeBuilder
                errors={compositeErrors}
                expressionTrees={expressionTrees}
                expressionTreeId={et.id}
                isShowDisplayOnlyFunctions={!isModal}
                isAllowDuplicateExpression={!isModal}
                useDropDownExternalProvider={!isModal}
              />
            )}
            {children}
          </div>
        ))}
      </div>
    </DndProvider>
  );
};

export default React.memo(CompositeBuildersContainer);
