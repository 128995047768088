import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {segmentClickEvent} from 'common/store/actions';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {ReactComponent as TopoActiveIcon} from 'app/images/topo_active.svg';
import {ReactComponent as TopoUnactiveIcon} from 'app/images/topo_unactive.svg';
import './AppNavBar.module.scss';

type NavBarMenuItemProps = {
  item: Object,
  isSelectedItem: Boolean,
};

type MenuItemTooltipProps = {
  item: Object,
  children: React.Node,
};

const MenuItemTooltip = ({item, children}: MenuItemTooltipProps) => (
  <Tooltip placement="right" content={item.title} type={TYPES.NAV_BAR} delay={50}>
    {children}
  </Tooltip>
);

const NavBarTopology = ({item, isSelectedItem}: NavBarMenuItemProps) => {
  const dispatch = useDispatch();

  const reportToSegment = useCallback((menuItem) => {
    dispatch(segmentClickEvent({type: 'click', name: `menu-${menuItem}`}));
  }, []);

  if (!item.isHidden) {
    return (
      <div styleName="item-wrapper" automation-id={item.title}>
        <div styleName={['item', isSelectedItem ? 'active' : ''].join(' ')}>
          <MenuItemTooltip item={item}>
            <a
              href={item.lastVisitedurl || item.links[0]}
              target={item.isNewTab ? '_blank' : '_self'}
              styleName="item-link"
              rel="noreferrer"
              onClick={() => {
                reportToSegment(item.title);
              }}
            >
              {isSelectedItem ? <TopoActiveIcon width={24} height={24} /> : <TopoUnactiveIcon width={24} height={24} />}
            </a>
          </MenuItemTooltip>
        </div>
      </div>
    );
  }
  return null;
};

export default NavBarTopology;
