import React, {useEffect} from 'react';
import Highcharts from 'highcharts';
import {getHchartConfig} from './pieChartSettingsService';

type PropTypes = {
  id: String,
  width: Number,
  height: Number,
  data: Array<Object>,
  tooltipFormatter: Object,
  labels: boolean,
  isSelectable: boolean,
  animation: boolean,
  theme: Object,
  onClickEvent: Function,
  getHighChartRef: Function,
};

const PieChart = (props: PropTypes) => {
  let hchart = null;
  const {
    id,
    width,
    height,
    data,
    onClickEvent,
    labels,
    theme,
    tooltipFormatter,
    getHighChartRef,
    isSelectable,
    animation,
  } = props;

  useEffect(() => {
    hchart = Highcharts.chart(
      id,
      getHchartConfig(data, onClickEvent, labels, theme, tooltipFormatter, isSelectable, animation),
    );
    if (getHighChartRef) {
      getHighChartRef(hchart);
    }
    return () => {
      if (hchart) {
        hchart.destroy();
        if (getHighChartRef) {
          getHighChartRef(null);
        }
      }
    };
  }, []);

  return (
    <div
      id={id}
      style={{
        minWidth: width,
        maxWidth: width,
        height,
        margin: '0',
      }}
    >
      Chart is here
    </div>
  );
};

export default PieChart;
