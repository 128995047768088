// @flow
import React, {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {useField} from 'react-final-form';
import Box from '@material-ui/core/Box';
import {TypographyBox} from 'common/componentsV2/boxTools';
import Checkbox from 'common/componentsV2/Checkbox';
import Input from 'common/componentsV2/Input';
import TextArea from 'common/componentsV2/TextArea';
import {makeStyles} from '@material-ui/core/styles';
import CSSTransition from 'react-transition-group/CSSTransition';
import {getMeOwnerOrganization, getLeanUserProfile} from 'profile/store/selectors';
import CopyToClipboardButton from 'common/components/CopyToClipboardButton';
import {getBaseClusterURL} from 'common/utils/http';
import {samlConfig} from '../../services/userSettingsService';
import '../../pages/userSettings.module.scss';

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.orange[500],
  },
  link: {
    wordBreak: 'break-all',
  },
  btnDetails: {
    display: 'block',
    cursor: 'pointer',
  },
  subLabel: {
    fontWeight: 700,
    fontSize: '14px',
  },
}));

const isValidUrl = (val) =>
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/.test(val);

const SamlAuth = () => {
  const [isShowDetails, setShowDetails] = useState(false);
  const getUserOrg = useSelector((state) => getMeOwnerOrganization(state));
  const leanMe = useSelector((state) => getLeanUserProfile(state));
  const {
    input: {onChange: handleEnforce, checked},
  } = useField('enforceSaml', {type: 'checkbox'});
  const {
    input: {value: valueLogin, onChange: handleLogin},
    meta: {invalid, touched},
  } = useField('loginUrl', {validate: (val) => !val || !isValidUrl(val)});
  const {
    input: {value: valueCert, onChange: handleCert},
  } = useField('cert');

  const consumeUrl = `${getBaseClusterURL(leanMe)}/api/v1/signin/saml/consume/${getUserOrg}`;
  const loginUrl = `${getBaseClusterURL(leanMe)}/signin/?orgId=${getUserOrg}`;

  const classes = useStyles();
  return (
    <Fragment>
      <Box width={480} mb={2.5}>
        <Box mb={1} mt={2.5}>
          <Checkbox
            isChecked={checked}
            onChange={handleEnforce}
            text="For Admin users, enforce SAML single sign-on authentication."
          />
        </Box>
        <Box mt={1}>
          <TypographyBox variant="body1" mb={0.5}>
            The Admin password is ignored.
          </TypographyBox>
          <TypographyBox variant="hintText">
            {/* eslint-disable-next-line max-len */}
            We recommend not to enforce SAML authentication before verifying that Authentication has been set up
            successfully.
          </TypographyBox>
        </Box>
      </Box>
      <Box py={2.5} borderTop={1} borderColor="gray.200">
        <Grid container>
          <Grid item>
            <TypographyBox variant="body1" width={220}>
              Identity Provider Details:
            </TypographyBox>
          </Grid>
          <Grid item>
            <Box width={400}>
              <Box mb={3.5}>
                <TypographyBox variant="body1">
                  Login URL: <span className={classes.label}>Required</span>
                </TypographyBox>
                <Input
                  type="text"
                  value={valueLogin}
                  onChange={handleLogin}
                  placeHolder="SAML HTTP Post Binding provided by your IDP"
                  isInvalid={touched && invalid}
                  fullSize
                />
              </Box>
              <Box mb={3.5}>
                <TypographyBox variant="body1">509 Certificate:</TypographyBox>
                <TextArea minRows={4} maxRows={4} value={valueCert} onChange={handleCert} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box py={2.5} borderTop={1} borderColor="gray.200">
        <Grid container>
          <Grid item>
            <TypographyBox variant="body1" width={220}>
              Service Provider Details:
            </TypographyBox>
          </Grid>
          <Grid item>
            <Box width={400}>
              <Box mb={3.5}>
                <TypographyBox variant="body1">Entity ID (issuer ID):</TypographyBox>
                <Box position="relative">
                  <Input type="text" value={samlConfig.entity} fullSize isDisabled />
                  <Box styleName="copyBtn">
                    <CopyToClipboardButton
                      copyStr={samlConfig.entity}
                      tooltipMessage="Copied to clipboard"
                      shouldUseIcon
                    />
                  </Box>
                </Box>
              </Box>
              <Box mb={3.5}>
                <TypographyBox variant="body1">Assertion Consumer Service URL:</TypographyBox>
                <Box position="relative">
                  <Input type="text" value={consumeUrl} fullSize isDisabled />
                  <Box styleName="copyBtn">
                    <CopyToClipboardButton copyStr={consumeUrl} tooltipMessage="Copied to clipboard" shouldUseIcon />
                  </Box>
                </Box>
              </Box>
              <TypographyBox variant="body1" className={classes.subLabel}>
                Login URL:{' '}
                <Box component="a" href={loginUrl} className={classes.link}>
                  {loginUrl}
                </Box>
              </TypographyBox>
              <TypographyBox variant="hintText">Use this URL for SSO or use the default sign-in form</TypographyBox>
              {isShowDetails && (
                <CSSTransition timeout={300} appear in>
                  <Box>
                    <Box mt={4}>
                      <TypographyBox variant="body1" className={classes.subLabel}>
                        SAML Bindings: Anodot supports HTTP Post Binding for SAML 2.0:
                      </TypographyBox>
                      <TypographyBox variant="body1">{samlConfig.postBindings}</TypographyBox>
                    </Box>
                    <Box mt={4}>
                      <TypographyBox variant="body1" className={classes.subLabel}>
                        Assertion subject (application username):
                      </TypographyBox>
                      <TypographyBox variant="body1">Email</TypographyBox>
                    </Box>
                    <Box mt={4}>
                      <TypographyBox variant="body1" className={classes.subLabel}>
                        NameId Format (Email Address):
                      </TypographyBox>
                      <TypographyBox variant="body1">{samlConfig.nameIdFormat}</TypographyBox>
                    </Box>
                    <Box mt={4}>
                      <TypographyBox variant="body1" className={classes.subLabel}>
                        Attribute Name Format (Uri):
                      </TypographyBox>
                      <TypographyBox variant="body1">urn:oasis:names:tc:SAML:2.0:attrname-format:uri</TypographyBox>
                    </Box>
                    <Box mt={4}>
                      <TypographyBox variant="body1" className={classes.subLabel}>
                        Attribute Statement:
                      </TypographyBox>
                      <TypographyBox variant="body1">{samlConfig.attrNameFormat}</TypographyBox>
                    </Box>
                    <Box mt={4}>
                      <TypographyBox variant="body1" className={classes.subLabel}>
                        Encryption Algorithm:
                      </TypographyBox>
                      <TypographyBox variant="body1">sha256</TypographyBox>
                    </Box>
                  </Box>
                </CSSTransition>
              )}
              <Box component="a" onClick={() => setShowDetails(!isShowDetails)} className={classes.btnDetails} mt={4.5}>
                {isShowDetails ? 'Less details' : 'More details'}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default SamlAuth;
