// @flow
import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {useQueryParams} from 'use-query-params';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {getDateValue} from 'common/utils/dateRangeService';
import {PANEL_SIZES, QUERY_PARAM_MAP} from 'topologyGeneral/services/sidePanelService';
import RegionsPanel from 'topologyGeneral/components/sidePanel/RegionsPanel';
import LeafRegionPanel from 'topologyGeneral/components/sidePanel/LeafRegionPanel';
import SubRegionsPanel from 'topologyGeneral/components/sidePanel/SubRegionsPanel';
import SelectedIssuePanel from 'topologyGeneral/components/sidePanel/SelectedIssuePanel';
import SelectedSitePanel from 'topologyGeneral/components/sidePanel/SelectedSitePanel';
import {
  getGtpMapRegions,
  isLoadingGtpMapRegions,
  isLoadingGtpMapRegionsSitesList,
} from 'topologyGeneral/store/selectors';
import {getTopologyAlerts, getTopologyAnomalies} from 'topologyGeneral/store/actions';

const SidePanel = ({nodeHoveredId, mapRef}: PropTypes) => {
  const dispatch = useDispatch();
  const [queryParams] = useQueryParams(QUERY_PARAM_MAP);
  const [panelVisibilities, setPanelVisibilities] = useState({
    region: false,
    subRegion: false,
    leaf: false,
    selIssue: false,
    selSite: false,
  });
  const regionItems = useSelector(getGtpMapRegions);
  const isLoading = useSelector(isLoadingGtpMapRegions);
  const isSitesLoading = useSelector(isLoadingGtpMapRegionsSitesList);

  useEffect(() => {
    const findRegion = regionItems.find((r) => r.id === queryParams.regionId);
    if (queryParams.issueId) {
      setPanelVisibilities({
        region: false,
        subRegion: false,
        leaf: false,
        selIssue: true,
        selSite: false,
      });
    } else if (queryParams.siteId) {
      setPanelVisibilities({
        region: false,
        subRegion: false,
        leaf: false,
        selIssue: false,
        selSite: true,
      });
    } else if (findRegion) {
      if (findRegion.isLeaf) {
        setPanelVisibilities({
          region: false,
          subRegion: false,
          leaf: true,
          selIssue: false,
          selSite: false,
        });
      } else {
        setPanelVisibilities({
          region: false,
          subRegion: true,
          leaf: false,
          selIssue: false,
          selSite: false,
        });
      }
    } else {
      setPanelVisibilities({
        region: true,
        subRegion: false,
        leaf: false,
        selIssue: false,
        selSite: false,
      });
    }
  }, [queryParams.regionId, regionItems, queryParams.issueId, queryParams.siteId]);

  useEffect(() => {
    const dateRange = getDateValue(queryParams, true);

    if (queryParams.issuesType === 'anomalies') {
      dispatch(getTopologyAnomalies({startDate: dateRange.startDate, endDate: dateRange.endDate}));
    }
    if (queryParams.issuesType === 'alerts') {
      dispatch(getTopologyAlerts({startTime: dateRange.startDate, endTime: dateRange.endDate}));
    }
  }, [
    queryParams.issuesType,
    queryParams.constRange,
    queryParams.startDate,
    queryParams.endDate,
    queryParams.relativeLast,
    queryParams.relativeNext,
  ]);

  return (
    <Box width={PANEL_SIZES.sidePanelWidth} height="100%" flexShrink={0} bgcolor="white.500" position="relative">
      {isLoading || (isSitesLoading && panelVisibilities.selSite) ? (
        <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center" height="100%">
          <Spinner color="#3d4c59" size={SIZES.XX_BIG_100} />
        </Box>
      ) : (
        <Fragment>
          <SelectedIssuePanel isVisible={panelVisibilities.selIssue} />
          <RegionsPanel mapRef={mapRef} nodeHoveredId={nodeHoveredId} isVisible={panelVisibilities.region} />
          <SubRegionsPanel mapRef={mapRef} nodeHoveredId={nodeHoveredId} isVisible={panelVisibilities.subRegion} />
          <LeafRegionPanel mapRef={mapRef} nodeHoveredId={nodeHoveredId} isVisible={panelVisibilities.leaf} />
          <SelectedSitePanel isVisible={panelVisibilities.selSite} />
        </Fragment>
      )}
    </Box>
  );
};

SidePanel.propTypes = {
  mapRef: PropTypes.objectOf(PropTypes.any).isRequired,
  nodeHoveredId: PropTypes.string,
};

SidePanel.defaultProps = {
  nodeHoveredId: undefined,
};

export default SidePanel;
