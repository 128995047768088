// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import * as selector from 'admin.users/store/selectors';
import {getUserAssetsCount, getGroupAssetsCount} from 'admin.users/store/actions';
import './AssetsCountSection.module.scss';

type PropTypes = {
  groupId: string,
  userIds: string,
  header: string,
  userId: string,
  userName: string,
  alertsCount: number,
  anoboardsCount: number,
  dashboardsCount: number,
  channelsCount: number,
  compositesCount: number,
  refreshTokenCount: number,
  isAlertsCountLoading: boolean,
  isAnoboardsCountLoading: boolean,
  isDashboardsCountLoading: boolean,
  isChannelsCountLoading: boolean,
  isCompositesCountLoading: boolean,
  isRefreshTokenCountLoading: boolean,
  getUserAssetsCount: Function,
  getGroupAssetsCount: Function,
};

@connect(
  (state, ownProps) => ({
    groupId: selector.getGroupsDeleteModalGroupId(state),
    userIds: ownProps.userIds,
    header: ownProps.header,
    userId: selector.getUsersViewDeleteModalUserId(state),
    userName: selector.getUsersViewDeleteModalUserName(state),
    alertsCount: selector.getOwnerAlertsCountValue(state),
    anoboardsCount: selector.getOwnerAnoboardsCountValue(state),
    dashboardsCount: selector.getOwnerDashboardsCountValue(state),
    channelsCount: selector.getOwnerChannelsCountValue(state),
    compositesCount: selector.getOwnerCompositesCountValue(state),
    isAlertsCountLoading: selector.getOwnerAlertsCountIsLoading(state),
    isAnoboardsCountLoading: selector.getOwnerAnoboardsCountIsLoading(state),
    isDashboardsCountLoading: selector.getOwnerDashboardsCountIsLoading(state),
    isChannelsCountLoading: selector.getOwnerChannelsCountIsLoading(state),
    isCompositesCountLoading: selector.getOwnerCompositesCountIsLoading(state),
    isRefreshTokenCountLoading: selector.getOwnerRefreshTokensCountIsLoading(state),
    refreshTokenCount: selector.getOwnerRefreshTokensCountValue(state),
  }),
  {
    getUserAssetsCount,
    getGroupAssetsCount,
  },
)
export default class AssetsCountSection extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    if (this.props.userId) {
      this.props.getUserAssetsCount(this.props.userId);
    }
    if (this.props.userIds) {
      this.props.getUserAssetsCount(this.props.userIds);
    }
    if (this.props.groupId) {
      this.props.getGroupAssetsCount(this.props.groupId);
    }
  }

  renderTable = () => {
    const {
      alertsCount,
      anoboardsCount,
      dashboardsCount,
      channelsCount,
      compositesCount,
      refreshTokenCount,
      isAlertsCountLoading,
      isAnoboardsCountLoading,
      isDashboardsCountLoading,
      isChannelsCountLoading,
      isCompositesCountLoading,
      isRefreshTokenCountLoading,
    } = this.props;

    if (this.props.groupId) {
      return (
        <div styleName="table">
          <div styleName="col">
            {isAlertsCountLoading ? (
              <div styleName="is-loading" />
            ) : (
              <div>
                <span styleName="bold">{alertsCount} </span>
                Alerts
              </div>
            )}
            {isAnoboardsCountLoading ? (
              <div styleName="is-loading" />
            ) : (
              <div>
                <span styleName="bold">{anoboardsCount} </span>
                Anobords
              </div>
            )}
          </div>
          <div styleName="col">
            {isDashboardsCountLoading ? (
              <div styleName="is-loading" />
            ) : (
              <div>
                <span styleName="bold">{dashboardsCount} </span>
                Dashboards
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div styleName="table">
        <div styleName="col">
          {isAlertsCountLoading ? (
            <div styleName="is-loading" />
          ) : (
            <div>
              <span styleName="bold">{alertsCount} </span>
              Alerts
            </div>
          )}
          {isDashboardsCountLoading ? (
            <div styleName="is-loading" />
          ) : (
            <div>
              <span styleName="bold">{dashboardsCount} </span>
              Dashboards
            </div>
          )}
          {isAnoboardsCountLoading ? (
            <div styleName="is-loading" />
          ) : (
            <div>
              <span styleName="bold">{anoboardsCount} </span>
              Anobords
            </div>
          )}
        </div>
        <div styleName="col">
          {isChannelsCountLoading ? (
            <div styleName="is-loading" />
          ) : (
            <div>
              <span styleName="bold">{channelsCount} </span>
              Channels
            </div>
          )}
          {isCompositesCountLoading ? (
            <div styleName="is-loading" />
          ) : (
            <div>
              <span styleName="bold">{compositesCount} </span>
              Composites
            </div>
          )}
          {isRefreshTokenCountLoading ? (
            <div styleName="is-loading" />
          ) : (
            <div>
              <span styleName="bold">{refreshTokenCount} </span>
              Access Keys
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const {header, userName} = this.props;

    return (
      <div styleName="user-assets">
        <div styleName="header">{header ? `${header}` : `${userName} has:`}</div>
        {this.renderTable()}
      </div>
    );
  }
}
