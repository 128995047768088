// @flow
import React from 'react';
import {connect} from 'react-redux';
import {fetchGaSegments as fetchGaSegmentsAction, setSelectedStreamGaSegment} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import SelectAndt, {THEME_LIGHT, TYPE_NEW_MULTI_NO_SEARCH_WIDE} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import PanelHeader from '../../../PanelHeader';
import './GaTemplate.module.scss';

type PropTypes = {
  dataStream: Object,
  isLoading: Boolean,
  segments: Object,
  selectedSegments: Array,
  ddlSelectedSegments: Array,

  fetchGaSegments: Function,
  setSelectedStreamGaSegment: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isLoading: selectors.getSegmentsIsLoading(state),
    segments: selectors.getSegmentsData(state),
    selectedSegments: selectors.getSelectedSegments(state),
    ddlSelectedSegments: selectors.getDdlSelectedSegments(state),
  }),
  {
    fetchGaSegments: fetchGaSegmentsAction,
    setSelectedStreamGaSegment,
  },
)
export default class GaSegments extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {dataStream, fetchGaSegments, selectedSegments} = this.props;

    fetchGaSegments({
      dataSourceId: dataStream.dataSourceId,
    });

    if (selectedSegments.length > 0) {
      this.props.setSelectedStreamGaSegment(selectedSegments);
    }
  }

  onChange = (values) => {
    this.props.setSelectedStreamGaSegment(values);
  };

  render() {
    const {isLoading, segments, ddlSelectedSegments} = this.props;

    return (
      <div styleName="root">
        <PanelHeader title="Segments" isEditable="false" />

        <SelectAndt
          type={TYPE_NEW_MULTI_NO_SEARCH_WIDE}
          theme={THEME_LIGHT}
          onChange={this.onChange}
          getOptionLabel={(val) => val.name}
          getOptionValue={(val) => val.id}
          isMulti
          optionHeight={40}
          closeMenuOnSelect={false}
          closeMenuOnScroll={false}
          disabled={isLoading}
          options={segments}
          value={ddlSelectedSegments}
          isClearable={false}
          placeholder={isLoading ? 'Loading...' : 'Choose Segments'}
          automationId="chooseSegments"
        />
      </div>
    );
  }
}
