// @flow
import React, {useCallback, useMemo} from 'react';
import {Box} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import * as selectors from 'metrics/store/selectors';
import {resolutionTypes} from 'metrics/services/metricsService';
import {reject} from 'lodash';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import TooltipArea from 'common/componentsV2/TooltipArea';
import * as actions from 'alerts.management/store/actions';
import {useField} from 'react-final-form';

const TimeScaleCondition = () => {
  const dispatch = useDispatch();
  const {
    input: {value: timeScale, onChange: onChangeTimeScale},
  } = useField('timeScale');
  const setSelectedAlertTimeScale = useCallback(
    (...args) => {
      dispatch(actions.setSelectedAlertTimeScale(...args));
      onChangeTimeScale(...args);
    },
    [dispatch],
  );
  const resolutions = useSelector(selectors.getMetricResolutions);
  const resolutionTypesArr = useMemo(
    () =>
      reject(Object.values(resolutions), (r) => r.value === resolutionTypes.auto.value || !r.enabled).map((item) => ({
        ...item,
        label: item.text,
      })),
    [resolutions],
  );

  return (
    <TooltipArea
      automationId="timeScaleContainer"
      text="Choose to be alerted on a minute / hourly / daily / weekly basis."
    >
      {(info) => (
        <React.Fragment>
          <div className="text16reg lineHeight_16 mb_1">
            Time Scale
            {info}
          </div>
          <Box width={154} automation-id="alertSettingTimeScaleGroup">
            <SelectAndt
              id="timeScaleFilter"
              automationId="timeScaleFilter"
              extraClassName="alerts-dropdown-btn"
              options={resolutionTypesArr}
              optionHeight={40}
              type={TYPE_NEW_NO_SEARCH}
              theme={THEME_NOT_HIGHLIGHTED}
              onChange={(item) => setSelectedAlertTimeScale(item.value)}
              value={resolutionTypesArr.find((item) => item.value === timeScale)}
              placeholder="All"
            />
          </Box>
        </React.Fragment>
      )}
    </TooltipArea>
  );
};

export default React.memo(TimeScaleCondition);
