// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  deleteLookupTable,
  setFileUploadProgress,
  setDeleteLookupError,
  setDownloadLookupError,
  setUploadedFileUrl,
  setUploadFileError,
  setFileUploadName,
  fetchLookupTablePreview,
} from 'bc/store/actions';
import {segmentCausingEvent} from 'common/store/actions';
import {
  uploadLookup,
  getDeleteRequest,
  getDownloadRequest,
  getUsingStreamsNamesList,
  CSV_TYPES_ARR,
} from 'bc/services/lookupService';
import {getFormattedTime, getFormattedLLDate} from 'common/utils/dateService';
import ConfirmationModal from 'common/components/modals/ConfirmationModal';
import ErrorModal from 'common/components/modals/ErrorModal';
import {getLookupPreviewData, getIsLookupPreviewDataLoading, isBcReadOnlyPermision} from 'bc/store/selectors';
import {getUsersData} from 'admin.users/store/selectors';
import {getTimeZoneName} from 'profile/store/selectors';
import DataTablePreviewModal from 'bc/components/streams/editor/common/DataTablePreviewModal';
import StreamItemContainer from './StreamItemContainer';
import './StreamListItem.module.scss';

type PropTypes = {
  name: string,
  id: string,
  type: string,
  lookupList: Array,
  dataStreams: Array,
  deleteLookupTable: Function,
  isBcReadOnly: boolean,
  fileName: string,
  createTime: number,
  modifyTime: number,
  creator: string,
  modifier: string,
  lookupPreviewData: Object,
  isLookupPreviewDataLoading: boolean,
  users: Array,
  timeZoneName: string,
  setFileUploadProgress: Function,
  setFileUploadName: Function,
  setUploadedFileUrl: Function,
  setUploadFileError: Function,
  setDeleteLookupError: Function,
  setDownloadLookupError: Function,
  fetchLookupTablePreview: Function,
  segmentCausingEvent: Function,
};

@connect(
  (state, ownProps) => ({
    id: ownProps.id,
    name: ownProps.name,
    type: ownProps.type,
    state: ownProps.state,
    createTime: ownProps.createTime,
    modifyTime: ownProps.modifyTime,
    creator: ownProps.creator,
    modifier: ownProps.modifier,
    lookupPreviewData: getLookupPreviewData(state),
    isLookupPreviewDataLoading: getIsLookupPreviewDataLoading(state),
    isBcReadOnly: isBcReadOnlyPermision(state),
    users: getUsersData(state),
    timeZoneName: getTimeZoneName(state),
  }),
  {
    deleteLookupTable,
    setFileUploadProgress,
    setFileUploadName,
    setUploadedFileUrl,
    setUploadFileError,
    setDeleteLookupError,
    setDownloadLookupError,
    fetchLookupTablePreview,
    segmentCausingEvent,
  },
)
export default class LookupItem extends React.PureComponent {
  props: PropTypes;

  state = {
    isLookupModalOpen: false,
    isFileErrorOpen: false,
    fileErrorText: '',
    isLookupPreviewModalOpen: false,
  };

  isExitAnimation = false;

  closeLookupPreviewModal = () => {
    this.setState({isLookupPreviewModalOpen: false});
  };

  getLookupPreview = () => {
    this.setState({isLookupPreviewModalOpen: true});
    this.props.fetchLookupTablePreview({
      id: this.props.id,
    });
  };

  openLookupDeleteModal = () => {
    this.setState({isLookupModalOpen: true});
  };

  closeLookupModal = () => {
    this.setState({isLookupModalOpen: false});
  };

  deleteLookupError = () => {
    this.props.setDeleteLookupError();
  };

  deleteLookupSuccess = () => {
    this.props.deleteLookupTable({
      id: this.props.id,
    });
    this.setState({isLookupModalOpen: false});
  };

  uploadFileError = (/* err */) => {
    this.props.setUploadFileError();
    this.props.setFileUploadName('');
  };

  uploadFileSuccess = () => {
    this.props.setFileUploadName();
    this.props.setUploadedFileUrl({});
  };

  downloadLookupError = () => {
    this.props.setDownloadLookupError();
  };

  downloadLookupSuccess = () => {};

  onConfirmLookupModal = () => {
    this.isExitAnimation = true;
    const folder = this.props.fileName.split('/')[0];
    const file = this.props.fileName.split('/')[1];
    getDeleteRequest(file, this.deleteLookupSuccess, this.deleteLookupError, folder);
  };

  getLookup = () => {
    const folder = this.props.fileName.split('/')[0];
    const file = this.props.fileName.split('/')[1];
    getDownloadRequest(file, this.downloadLookupSuccess, this.downloadLookupError, folder);
  };

  closeFileErrorModal = () => {
    this.setState({fileErrorText: ''});
    this.setState({isFileErrorOpen: false});
  };

  onFileChange = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const folder = this.props.fileName.split('/')[0];
      const fileName = this.props.fileName.split('/')[1];
      if (file.name !== fileName) {
        this.setState({
          fileErrorText: `File ${file.name}
          could not be uploaded because the file name is different from the original file name.`,
        });
        this.setState({isFileErrorOpen: true});
      } else if (!CSV_TYPES_ARR.includes(file.type) && !file.name.toLowerCase().endsWith('.csv')) {
        this.setState({fileErrorText: `File ${file.name} could not be uploaded because it is not a CSV file.`});
        this.setState({isFileErrorOpen: true});
      } else {
        this.props.segmentCausingEvent({segment: {category: 'bc/data-manager', name: 'Reload-Lookup-Table'}});
        this.props.setFileUploadProgress(0);
        this.props.setFileUploadName(file.name);
        this.props.setUploadedFileUrl({});
        uploadLookup(file, this.uploadFileSuccess, this.uploadFileError, this.props.setFileUploadProgress, folder);
      }
    }
  };

  lookupActionItemSelected = (eventKey) => {
    switch (eventKey.value) {
      case 'view':
        this.getLookupPreview();
        break;
      case 'download':
        this.getLookup();
        break;
      case 'edit':
        document.getElementById(`fileInput_${this.props.id}`).click();
        break;
      case 'delete':
        this.openLookupDeleteModal();
        break;
      default:
    }
    return null;
  };

  getLookupDropdownOptions = (isDeleteDisabled) => {
    if (isDeleteDisabled) {
      return [{label: 'View', value: 'view'}, {label: 'Download', value: 'download'}, {label: 'Edit', value: 'edit'}];
    }
    return [
      {label: 'View', value: 'view'},
      {label: 'Download', value: 'download'},
      {label: 'Edit', value: 'edit'},
      {label: 'Delete', value: 'delete'},
    ];
  };

  getCreatorModifierStrings = () => {
    const {createTime, modifyTime, creator, modifier, users, timeZoneName} = this.props;
    const userCreator = creator && users.length ? users.find((user) => user._id === creator) : null;
    const createdAt = `${getFormattedLLDate(createTime, timeZoneName)}. ${getFormattedTime(createTime, timeZoneName)}`;
    const creatorString = userCreator
      ? `Created by ${userCreator.firstName} ${userCreator.lastName} on ${createdAt}`
      : `Created on ${createdAt}`;

    if (createTime === modifyTime) {
      return {
        creator: creatorString,
        modifier: '',
      };
    }

    const userModifier = modifier && users.length ? users.find((user) => user._id === modifier) : null;
    const modAt = `${getFormattedLLDate(modifyTime, timeZoneName)}. ${getFormattedTime(modifyTime, timeZoneName)}`;
    const modifierString = userModifier
      ? `Updated by ${userModifier.firstName} ${userModifier.lastName} on ${modAt}`
      : `Updated on ${modAt}`;

    return {
      creator: creatorString,
      modifier: modifierString,
    };
  };

  getStreamsList = (isDeleteDisabled) => {
    let streamsList = null;
    if (isDeleteDisabled) {
      const streamsNames = getUsingStreamsNamesList(this.props.dataStreams, this.props.id);
      if (streamsNames.length) {
        streamsList = 'Used by';
        streamsNames.forEach((name) => {
          streamsList = `${streamsList} ${name},`;
        });
        streamsList = streamsList.slice(0, -1);
      }
    }
    return streamsList;
  };

  doNothing = () => {};

  render() {
    const {
      closeLookupModal,
      onConfirmLookupModal,
      closeLookupPreviewModal,
      closeFileErrorModal,
      isExitAnimation,
    } = this;

    const {isLookupModalOpen, isLookupPreviewModalOpen, isFileErrorOpen, fileErrorText} = this.state;

    const {name, type, id, isBcReadOnly, lookupPreviewData, isLookupPreviewDataLoading, lookupList} = this.props;

    const timeStrings = this.getCreatorModifierStrings();
    const isDeleteDisabled = lookupList.includes(id);
    const streamList = this.getStreamsList(isDeleteDisabled);

    return (
      <StreamItemContainer
        name={name}
        id={id}
        type={type}
        isExitAnimation={isExitAnimation}
        isNameClickable={false}
        moreOptionsItems={this.getLookupDropdownOptions(isDeleteDisabled)}
        moreOptionsIsDisabled={isBcReadOnly}
        moreOptionsOnChange={this.lookupActionItemSelected}
        streamNameClicked={this.doNothing}
        hackStarter={this.doNothing}
      >
        <div styleName="hidden-file">
          <input
            id={`fileInput_${id}`}
            type="file"
            onChange={this.onFileChange}
            automation-id="dataCollectorLookupFileUpdatedButton"
          />
        </div>

        {isLookupModalOpen && (
          <ConfirmationModal
            onClose={closeLookupModal}
            onConfirm={onConfirmLookupModal}
            title="Delete lookup table"
            message="Are you sure you want to delete the lookup table?"
            isOpen={isLookupModalOpen}
          />
        )}

        {isFileErrorOpen && (
          <ErrorModal
            onClose={closeFileErrorModal}
            title="Your table wasn't uploaded"
            errorMsg={fileErrorText}
            isOpen={isFileErrorOpen}
          />
        )}

        {isLookupPreviewModalOpen && (
          <DataTablePreviewModal
            isSmall
            isOpen={isLookupPreviewModalOpen}
            onClose={closeLookupPreviewModal}
            title="Lookup Table Preview"
            subTitle={this.props.fileName.split('/')[1]}
            streamsNames={streamList}
            created={timeStrings.creator}
            modified={timeStrings.modifier}
            previewData={lookupPreviewData}
            isLoading={isLookupPreviewDataLoading}
            fetchPreview={fetchLookupTablePreview}
          />
        )}
      </StreamItemContainer>
    );
  }
}
