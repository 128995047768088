// @flow
import React from 'react';
import {Box} from '@material-ui/core';
import TabItem from './TabItem';

type PropTypes = {
  tabs: Array,
  selectedTabId: String,
  onTabClick: Function,
};
const InvTabsBar = ({tabs, selectedTabId, onTabClick}: PropTypes) => (
  <Box display="flex">
    {(tabs || []).map((tab) => (
      <TabItem
        isEnabled={tab.isEnabled}
        label={tab.label}
        id={tab.id}
        onClick={onTabClick}
        icon={tab.icon}
        isSelected={selectedTabId === tab.id}
        key={`inv-tab-bar-${tab.id}`}
      />
    ))}
  </Box>
);

export default React.memo(InvTabsBar);
