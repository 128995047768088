// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import * as selector from 'alerts.management/store/selectors';
import {calculateEstimatedRate} from 'alerts.management/store/actions';
import {abbrNum} from 'common/utils/numbers';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';

type PropTypes = {
  calculateEstimatedRate: Function,
  minDelta: String,
};

@connect(
  (state) => ({
    minDelta: selector.getEstimationMinDeltaCurrentOpenAlert(state),
  }),
  {
    calculateEstimatedRate,
  },
)
export default class EstimatedDelta extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    if (!this.props.minDelta) {
      this.props.calculateEstimatedRate();
    }
  }

  render() {
    return (
      <div>
        <Tooltip content={this.props.minDelta} type={TYPES.SMALL}>
          <span>{abbrNum(this.props.minDelta, 4)}</span>
        </Tooltip>
      </div>
    );
  }
}
