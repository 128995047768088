// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/StreamEditorPanelHeader';

import '../../sql/sqlSchedular/SqlSchedularEditor.module.scss';

type PropTypes = {
  // connect
  dataStream: Object,
  setSelectedStreamKeyVal: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    setSelectedStreamKeyVal,
  },
)
export default class KinesisSchedulerEditor extends React.PureComponent {
  props: PropTypes;

  pollingChanged = (val) => {
    this.props.setSelectedStreamKeyVal({pollingInterval: val});
  };

  delayMinutesChanged = (e) => {
    const val = parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0;
    this.props.setSelectedStreamKeyVal({delayMinutes: val});
  };

  render() {
    const {dataStream} = this.props;

    return (
      <div styleName="root">
        <PanelHeader title="Query Schedule" />
        <div styleName="inner-root">
          <div styleName="item collect-on">
            <span className="ellipsis" styleName="item-title">
              Delay (Minutes)
            </span>
            <span styleName="item-description">
              To minimize partial results in your reports, set the delay according to the time it takes the data to be
              available
            </span>
            <input type="number" onChange={this.delayMinutesChanged} min="0" value={dataStream.delayMinutes} />
          </div>
        </div>
      </div>
    );
  }
}
