import React from 'react';
import {
  SourceAwsSns,
  SourceChannelEmails,
  SourceJira,
  SourceOpsgenie,
  SourcePagerDuty,
  SourceSlack,
  SourceTamtam,
  SourceTeams,
  SourceWebhook,
  SourceGrafana,
  SourceZapier,
  SourceRivery,
  SourcePowerBI,
} from './svgs';

// eslint-disable-next-line import/prefer-default-export
export const getChannelIcon = (type) => {
  switch (type) {
    case 'sns':
      return <SourceAwsSns />;
    case 'email':
      return <SourceChannelEmails />;
    case 'jira':
      return <SourceJira />;
    case 'opsgenie':
      return <SourceOpsgenie />;
    case 'pagerduty':
      return <SourcePagerDuty />;
    case 'slack':
      return <SourceSlack />;
    case 'tamtam':
      return <SourceTamtam />;
    case 'teams':
      return <SourceTeams />;
    case 'webhook':
      return <SourceWebhook />;
    case 'grafana':
      return <SourceGrafana />;
    case 'zapier':
      return <SourceZapier />;
    case 'rivery':
      return <SourceRivery />;
    case 'powerbi':
      return <SourcePowerBI />;
    default:
      return null;
  }
};
