// @flow
import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {bcTypes} from 'bc/services/bcTypes';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import Spinner, {SIZES as SPINNER_SIZES} from 'common/componentsV2/Spinner';
import OuterRow from 'common/componentsV2/accordion/OuterRow';

import * as actions from 'admin.cs-portal/store/actions';
import * as selectors from 'admin.cs-portal/store/selectors';
import * as profileSelectors from 'profile/store/selectors';

import Accordion from 'common/componentsV2/accordion/Accordion';
import SimpleRow from 'common/componentsV2/accordion/SimpleRow';
import TasksLogTable from './TaskLogTable';

import './StreamsBySource.module.scss';

const TASKS_LOG_TITLES = [
  'State Details',
  'Session ID',
  'Start Time',
  'Next Time',
  'Finish Time',
  'Server', // assignee
  'Initial State',
  'Current State',
  'Stream Status',
  'Result Status',
  'Failure',
];

type PropTypes = {
  // connect
  selectedCustomer: Object,
  timeZone: String,
  fetchStreamsReport: Function,
  fetchTaskLog: Function,
  fetchKibanaLog: Function,
  isDimensionsLoading: Boolean,
  sources: Object,
  sourceCount: Number,
  tasks: Array,
  sessions: Object,
  activeSources: Array,
  taskLogIsLoading: Boolean,
};

@connect(
  (state) => ({
    selectedCustomer: selectors.getSelectedCustomer(state),
    timeZone: profileSelectors.getTimeZoneName(state),
    sources: selectors.getStreamsBySource(state),
    tasks: selectors.getTaskLog(state),
    sessions: selectors.getKibanaLog(state),
    sourceCount: selectors.getSourceCount(state),
    taskLogIsLoading: selectors.getTaskLogIsLoading(state),
  }),
  {
    fetchStreamsReport: actions.fetchStreamsReport,
    fetchTaskLog: actions.fetchTaskLog,
    fetchKibanaLog: actions.fetchKibanaLog,
  },
)
export default class StreamsBySource extends React.PureComponent {
  props: PropTypes;

  state = {
    selectedStream: null,
    showKibanaModal: false,
    timeFormat: 'YYYY-MM-DD HH:mm:ss',
    // openStreams: [],
  };

  componentDidMount() {
    const {selectedCustomer} = this.props;
    if (selectedCustomer && selectedCustomer.value) {
      this.fetchStreamsReport(selectedCustomer);
    }
  }

  componentDidUpdate(prevProps) {
    const {selectedCustomer} = prevProps;
    if (selectedCustomer && selectedCustomer.value && this.props.selectedCustomer !== selectedCustomer) {
      this.fetchStreamsReport(this.props.selectedCustomer);
    }
  }

  fetchStreamsReport = (selectedCustomer) => {
    this.props.fetchStreamsReport({
      _id: selectedCustomer.value,
    });
  };

  fetchTaskLog = (id) => {
    if (this.state.selectedStream === id) {
      this.setState({selectedStream: ''});
    } else {
      this.setState({selectedStream: id});
      this.props.fetchTaskLog({_id: id});
    }

    // if (!this.state.openStreams.includes(id)) {
    //   this.setState((prevState) => ({openStreams: [...prevState.openStreams, id]}));
    //   this.props.fetchTaskLog({_id: id});
    // }
    // else {
    //   this.setState((prevState) => ({openStreams: prevState.openStreams.filter((i) => i !== id)}));
    // }
  };

  renderAccordions = () => {
    const {sources, activeSources} = this.props;
    let sourcesToRender = {};
    Object.keys(sources).forEach((type) => {
      if (activeSources.length > 0) {
        if (activeSources.includes(type)) {
          sourcesToRender[type] = sources[type];
        }
      } else {
        sourcesToRender = sources;
      }
    });
    return Object.keys(sourcesToRender).map((type) => (
      <div styleName="accordion-header" key={type}>
        <div styleName="main-title">
          <div styleName="img" className={`image-${bcTypes[type.toLowerCase()].iconStyle}`} />
          <h3 styleName="source-title">{bcTypes[type.toLowerCase()].name}</h3>
        </div>
        <Accordion allowMultipleOpen>{this.renderSources(type)}</Accordion>
      </div>
    ));
  };

  renderSources = (type) => {
    const {sources, isDimensionsLoading} = this.props;
    return sources[type].map((source) => (
      <OuterRow
        isOpen={false}
        key={Object.keys(source)[0]}
        isLoading={isDimensionsLoading}
        label={Object.keys(source)[0]}
        titles={[`${Object.keys(source)[0]}`, 'Stream ID', 'Status', 'Current State']}
      >
        {this.renderStreams(source[Object.keys(source)[0]])}
      </OuterRow>
    ));
  };

  renderStreams = (streams) => {
    const {taskLogIsLoading, timeZone} = this.props;

    return (
      <ul styleName="streamsContainer" style={{listStyleType: 'none'}}>
        {streams.map((item) => (
          <li key={item.stream_id} onClick={() => this.fetchTaskLog(item.stream_id)}>
            <SimpleRow key={item.stream_id}>
              <span styleName="streamTitle">{item.stream_title}</span>
              <span>{item.stream_id}</span>
              <span>{item.stream_ispaused ? `${item.stream_status} (Paused)` : item.stream_status}</span>
              <span>{item.stream_state}</span>
            </SimpleRow>

            {this.state.selectedStream === item.stream_id ? (
              <TasksLogTable
                onAction={this.openKibanaLogsModal}
                titles={TASKS_LOG_TITLES}
                tasks={this.props.tasks.data}
                timeZone={timeZone}
                isLoading={taskLogIsLoading}
              />
            ) : null}
          </li>
        ))}
      </ul>
    );
  };

  openKibanaLogsModal = (item) => {
    this.setState({showKibanaModal: true});
    this.props.fetchKibanaLog({
      stream_id: item.stream_id,
      session_id: item.session_id,
    });
  };

  renderKibanaLog = () => (
    <table styleName="kibanaLogsTable">
      <thead>
        <tr style={{borderBottom: '1px solid gray', fontWeight: 'bold'}}>
          <td>Time</td>
          <td>Severity</td>
          <td>Reporter</td>
          <td>Message</td>
        </tr>
      </thead>
      <tbody>{this.renderKibanaLogRows()}</tbody>
    </table>
  );

  renderKibanaLogRows = () =>
    this.props.sessions.data.map((item, index) => (
      <tr key={item[0] + item[5]} style={{backgroundColor: index % 2 === 0 ? '#eee' : '#fff'}}>
        <td>
          {moment
            .tz(item[0] / 1000, 'X', this.props.timeZone === 'Browser' ? 'UTC' : this.props.timeZone)
            .format(`${this.state.timeFormat}.SSS`)}
        </td>
        <td>{item[1]}</td>
        <td>{item[4]}</td>
        <td>{item[5]}</td>
      </tr>
    ));

  handleClose = () => {
    this.setState({showKibanaModal: false});
  };

  render() {
    return (
      <div styleName="container">
        <header styleName="section-header">
          <span styleName="title">Sources ({this.props.sourceCount ? this.props.sourceCount : 0})</span>
        </header>
        {this.props.sources && (this.props.timeZone || 'Browser') ? (
          this.renderAccordions()
        ) : (
          <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <Spinner color="#3d4c59" size={SPINNER_SIZES.BIG_60} />
          </div>
        )}

        <Modal onClose={this.handleClose} isOpen={this.state.showKibanaModal} size={SIZES.MAXWIDTH}>
          <h2>Kibana Logs</h2>
          {this.props && this.props.sessions && this.props.sessions.data ? (
            <div styleName="kibana-logs-container">
              <h4>
                Stream ID: {this.props.sessions.data[0][2]}, Session ID: {this.props.sessions.data[0][3]}
              </h4>
            </div>
          ) : null}
          {this.props && this.props.sessions && this.props.sessions.data ? this.renderKibanaLog() : null}
        </Modal>
      </div>
    );
  }
}
