// @flow
import React from 'react';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import {Grid} from '@material-ui/core';
import NothingToShow from 'metrics/components/metricExplorer/NothingToShow';
import Gradient from 'common/componentsV2/Gradient';
import {TypographyBox} from 'common/componentsV2/boxTools';
import MetricsList from 'charts/timeSeries/containers/MetricList';
import LoadingBar from './LoadingBar';
import Sort from './simulationFields/Sort';
import DateRanges from './simulationFields/DateRanges';
import ReferenceDate from './simulationFields/ReferenceDate';

type PropTypes = {
  isSimulation: Boolean,
  metrics: Array,
  autoSimulationState: Object,
  executeSimulation: Function,
  isLoading: Boolean,
  isLoadingMore: Boolean,
  isAnyLoading: Boolean,
  totalAmount: Number,
  loadingLabel: String,
  label: String,
  validationMessage: String,
  blurLabel: Boolean,
  isSimulating: boolean,
  isLoadingBarEnabled: boolean,
  isReferenceDateEnabled: boolean,
  isZoomLoading: boolean,
  timeScale: string,
  alert: Object,
  rootId: string,
  validationId: number,
};

const PreviewTemplate = ({
  rootId,
  isSimulation,
  metrics,
  autoSimulationState,
  executeSimulation,
  isLoading,
  isLoadingMore,
  isAnyLoading,
  totalAmount,
  loadingLabel,
  label,
  validationMessage,
  blurLabel,
  isSimulating,
  isLoadingBarEnabled,
  alert,
  timeScale,
  isZoomLoading,
  isReferenceDateEnabled,
  validationId,
}: PropTypes) => (
  <div automation-id="alert-preview-area" className="display_flex flexDirection_column height_1 flexGrow_1">
    <LoadingBar
      isLoadingBarEnabled={isLoadingBarEnabled}
      loadingLabel={loadingLabel}
      isLoading={isSimulating}
      blurLabel={blurLabel}
      label={label}
    />
    <div className="text16reg lineHeight_16 flexShrink_0">
      {isLoading ? (
        'Loading...'
      ) : (
        <React.Fragment>
          Showing{' '}
          <b>
            {metrics ? metrics.length : 0} out of&nbsp;
            {totalAmount}
          </b>{' '}
          Metrics
        </React.Fragment>
      )}
    </div>

    {isSimulation && isReferenceDateEnabled && (
      <div className="mb_0-5 mt_1 flexShrink_0">
        <Grid container spacing={2}>
          <Grid item>
            <div className="display_flex alignItems_center">
              <div className="text14med-gray400 mr_0-5 lineHeight_14">Reference Date</div>
              <ReferenceDate />
            </div>
          </Grid>
        </Grid>
      </div>
    )}

    {!isSimulation && (
      <div className="mb_0-5 mt_1 flexShrink_0">
        <Grid container spacing={2}>
          <Grid item>
            <div className="display_flex alignItems_center">
              <TypographyBox mr={0.5} variant="subtitle2">
                Time Frame
              </TypographyBox>
              <DateRanges />
            </div>
          </Grid>
          <Grid item>
            <div className="display_flex alignItems_center">
              <TypographyBox mr={0.5} variant="subtitle2">
                Sort By
              </TypographyBox>
              <Sort />
            </div>
          </Grid>
        </Grid>
      </div>
    )}
    {metrics || isLoading ? (
      <React.Fragment>
        {!isLoading && validationMessage && (
          <div className="flexShrink_0 alignSelf_center mt_2 mb_1">
            <NothingToShow
              validationId={validationId}
              validationMessage={validationMessage.replace(' a upper', ' an upper')}
              isAlert
            />
          </div>
        )}
        <div className="display_flex flexDirection_column position_relative height_1 mt_0-5">
          <Gradient />
          <MetricsList
            key={rootId}
            isSimulation={isSimulation}
            metrics={metrics}
            autoSimulationState={autoSimulationState}
            executeSimulation={executeSimulation}
            isLoading={isLoading}
            isZoomLoading={isZoomLoading}
            isLoadingMore={isLoadingMore}
            isAnyLoading={isAnyLoading}
            totalAmount={totalAmount}
            isInvalid={!!validationMessage}
            timeScale={timeScale}
            alert={alert}
          />
        </div>
      </React.Fragment>
    ) : (
      <div>
        <div>Simulation not available</div>
        <div>Metrics not found.</div>
      </div>
    )}
  </div>
);

export default React.memo(PreviewTemplate);
