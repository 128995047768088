// @flow
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import ReactQueryParams from 'common/components/ReactQueryParams';
import moment from 'moment';
import {isEmpty, isEqual, pick} from 'lodash';

import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';

import {rangeTypes, correctTimestampUnix, getDate} from 'common/utils/dateRangeService';
import {DEFAULT_QUERY_PARAMS} from 'alerts.console/services/alertsConsoleService';
import {
  updateQueryParams as updateQueryParamsAction,
  updateFilterBtn as updateFilterBtnAction,
  resetAlertsConsole,
  setQueryParamsToView,
  fetchTriggeredAlertsTotal,
  setAlertsFilters as setAlertsFiltersAction,
  toggleInvestigationModal,
} from 'alerts.console/store/actions';
import * as selectors from 'alerts.console/store/selectors';
import * as profileSelector from 'profile/store/selectors';
import InsightsPanel from 'insightsPanel/InsightsPanel';
import AlertsConsoleFilters from 'alerts.console/components/filters/AlertsConsoleFilters';
import AlertsList from 'alerts.console/components/alertsList/AlertsList';
import AlertsConsoleHeader from 'alerts.console/components/header/AlertsConsoleHeader';
import PageLayout from 'common/componentsV2/PageLayout';
import './AlertsConsole.module.scss';

let isFirstAlertConsoleLifeEver = true;

let prevLifeSearch = null; // store the search query of last visit to alerts-console

type PropTypes = {
  location: Object,
  history: Object,
  // connect
  timeZoneName: String,
  dataQueryParams: Object,
  updateQueryParams: Function,
  isReadOnlyUser: Boolean,
  setQueryParamsToView: Function,
  setAlertsFilters: Function,
  fetchTriggeredAlertsTotal: Function,
  isInsightsPanelEnabled: Boolean,
  investigationModal: Object,
  toggleInvestigationModal: Function,
};

@connect(
  (state) => ({
    timeZoneName: profileSelector.getTimeZoneName(state),
    dataQueryParams: selectors.getQueryParamsData(state),
    isReadOnlyUser: profileSelector.isReadOnlyUser(state),
    isInsightsPanelEnabled: profileSelector.getInsightsPanelEnabled(state),
    investigationModal: selectors.getAlertConsoleInvestigationModal(state),
  }),
  {
    resetAlertsConsole,
    updateQueryParams: updateQueryParamsAction,
    updateFilterBtn: updateFilterBtnAction,
    setQueryParamsToView,
    fetchTriggeredAlertsTotal,
    setAlertsFilters: setAlertsFiltersAction,
    toggleInvestigationModal,
  },
)
export default class AlertsConsole extends ReactQueryParams {
  props: PropTypes;

  defaultQueryParams = {...DEFAULT_QUERY_PARAMS};

  makeObjectParams = (stringParams) => {
    const urlParams = new URLSearchParams(stringParams);
    return Object.fromEntries(urlParams);
  };

  componentDidMount() {
    if (!isEmpty(this.props.dataQueryParams) && !this.isFilteredFromEmail()) {
      this.setQueryParams(this.props.dataQueryParams);
    }

    if (!isEmpty(this.props.dataQueryParams) && isEqual(this.queryParams, this.defaultQueryParams)) {
      this.setQueryParamsWrapper(this.props.dataQueryParams);
    } else {
      this.props.setAlertsFilters(this.queryParams);
    }
    this.initSearchQuery(this.props);
    this.fetchTotalTriggeredAlertsByConstRange();
    // call insightPanel
  }

  componentWillUnmount() {
    prevLifeSearch = this.props.location.search;
    // end insightPanel
  }

  componentDidUpdate(prevProps) {
    if (super.componentDidUpdate) {
      super.componentDidUpdate();
    }
    const prevParams = pick(this.makeObjectParams(prevProps.location.search), Object.keys(DEFAULT_QUERY_PARAMS));
    const currentParams = pick(this.makeObjectParams(this.props.location.search), Object.keys(DEFAULT_QUERY_PARAMS));

    if (!isEqual(prevParams, currentParams)) {
      this.props.setAlertsFilters(this.queryParams);
      this.fetchTotalTriggeredAlertsByConstRange();
    }
  }

  fetchTotalTriggeredAlertsByConstRange() {
    if (this.queryParams.constRange === rangeTypes.c.value) {
      this.props.fetchTriggeredAlertsTotal({startTime: this.queryParams.startTime});
      return;
    }

    if (this.queryParams.constRange === rangeTypes.r.value) {
      const computedDate = getDate(this.queryParams);
      this.props.fetchTriggeredAlertsTotal({
        startTime: correctTimestampUnix(computedDate.startDate),
        endTime: correctTimestampUnix(computedDate.endDate),
      });
      return;
    }

    const startTime = moment()
      .subtract(
        rangeTypes[this.queryParams.constRange].momentStart[0],
        rangeTypes[this.queryParams.constRange].momentStart[1],
      )
      .unix(this.props.timeZoneName);
    this.props.fetchTriggeredAlertsTotal({startTime});
  }

  initSearchQuery = (props) => {
    // both assignments are needed otherwise react will update the component twice
    if (isFirstAlertConsoleLifeEver) {
      isFirstAlertConsoleLifeEver = false;
      if (!props.location.search && isEmpty(this.props.dataQueryParams)) {
        // props.location.search = `?subscribers=${props.me._id}`;
        this.history.replace(this.history.location.pathname + props.location.search);
      }
    } else if (prevLifeSearch && props.location.search !== prevLifeSearch) {
      /* eslint-disable */
      props.location.search = prevLifeSearch;
      /* eslint-enable */
      this.history.replace(this.history.location.pathname + prevLifeSearch);
    }
  };

  setQueryParamsWrapper = (queryObj) => {
    this.setQueryParams(queryObj, true);

    let needsUpdate = false;

    Object.entries(queryObj).forEach((e) => {
      needsUpdate = needsUpdate || this.queryParams[e[0]] !== e[1];
    });

    if (needsUpdate) {
      this.setQueryParams(queryObj, true);
    }
  };

  getDateRange() {
    return rangeTypes[this.queryParams.constRange] ? rangeTypes[this.queryParams.constRange].text : rangeTypes.d3.text;
  }

  isFilteredFromEmail() {
    return (
      this.queryParams.ref &&
      this.queryParams.ref === 'email' &&
      (this.queryParams.triggerIds || this.queryParams.alertConfigurationIds)
    );
  }

  closeInvestigationModal = () => {
    this.props.toggleInvestigationModal({isOpen: false, anomalyId: '', triggerId: ''});
  };

  render() {
    return (
      <Fragment>
        <InsightsPanel enabled={this.props.isInsightsPanelEnabled} setQueryParams={this.setQueryParamsWrapper} />
        <div styleName="page-container">
          <PageLayout header={<AlertsConsoleHeader />}>
            <div styleName="page-content-wrapper">
              <AlertsConsoleFilters
                setQueryParams={this.setQueryParamsWrapper}
                queryParams={this.queryParams}
                defaultQueryParams={this.defaultQueryParams}
                isFilteredFromEmail={this.isFilteredFromEmail()}
              />
              <div styleName="alert-list-container">
                <AlertsList setQueryParams={this.setQueryParamsWrapper} dateRange={this.getDateRange()} />
              </div>
            </div>
          </PageLayout>
        </div>
      </Fragment>
    );
  }
}
