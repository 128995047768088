// @flow
import React from 'react';
import {useField} from 'react-final-form';
import {Box, Grid, makeStyles} from '@material-ui/core';
import Input from 'common/componentsV2/Input';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';

import SubTitle from '../SubTitle';

const useStyles = makeStyles((theme) => ({
  passwordHint: {
    padding: '8px',
    backgroundColor: `${theme.palette.blue[100]}`,
    border: `2px solid ${theme.palette.blue[300]}`,
    borderRadius: '6px',
    marginTop: '28px',
  },
  error: {
    backgroundColor: '#fff0f3',
    border: `2px solid ${theme.palette.red[500]}`,
  },
}));

const isValid = (val) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,101}$/.test(val || '');

const PasswordSettings = () => {
  const {
    input: {value: currentPassword, onChange: handleCurrentPassword},
  } = useField('currentPassword');
  const {
    input: {value: newPassword, onChange: handleNewPassword, onFocus: onFocusNewPassword, onBlur: onBlurNewPassword},
    meta: {invalid: invalidNewPassword, touched: touchedNewPassword, active: activeNewPassword},
  } = useField('newPassword', {
    validate: (val, allVal) => allVal.currentPassword !== undefined && !isValid(val),
  });
  const {
    input: {
      value: confirmNewPassword,
      onChange: handleConfirmNewPassword,
      onFocus: onFocusConfirmPassword,
      onBlur: onBlurConfirmPassword,
    },
    meta: {invalid: invalidConfirmNewPassword, touched: touchedConfirmPassword, active: activeConfirmPassword},
  } = useField('confirmNewPassword', {
    validate: (val, allVal) => allVal.currentPassword !== undefined && !isValid(val),
  });

  const classes = useStyles();

  const isInValidNewPassword = touchedNewPassword && invalidNewPassword;
  const isInValidConfirmPassword = touchedConfirmPassword && invalidConfirmNewPassword;

  return (
    <Box mb={7}>
      <SubTitle title="Password" />
      <Grid container>
        <Grid item>
          <Box mr={1.5}>
            <Box mb={1}>Current Password</Box>
            <Input type="password" value={currentPassword} onChange={handleCurrentPassword} placeHolder="Required" />
          </Box>
        </Grid>
        <Grid item>
          <Box mr={1.5}>
            <Box mb={1}>New Password</Box>
            <Input
              type="password"
              isInvalid={isInValidNewPassword}
              value={newPassword}
              onChange={handleNewPassword}
              onFocus={onFocusNewPassword}
              onBlur={onBlurNewPassword}
              placeHolder="Min. 8 Characters"
            />
          </Box>
        </Grid>
        <Grid item>
          <Box mr={1.5}>
            <Box mb={1}>Confirm Password</Box>
            <Input
              type="password"
              isInvalid={isInValidConfirmPassword}
              value={confirmNewPassword}
              onChange={handleConfirmNewPassword}
              onFocus={onFocusConfirmPassword}
              onBlur={onBlurConfirmPassword}
              placeHolder="Min. 8 Characters"
            />
          </Box>
        </Grid>
        {(activeNewPassword || activeConfirmPassword || isInValidConfirmPassword || isInValidNewPassword) && (
          <Grid item>
            <Box
              className={`${classes.passwordHint} ${
                isInValidConfirmPassword || isInValidNewPassword ? classes.error : ''
              }`}
            >
              <TypographyBox variant="body1">- At least 8 characters</TypographyBox>
              <TypographyBox variant="body1">- Upper and lower case</TypographyBox>
              <TypographyBox variant="body1">- At least 1 number</TypographyBox>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PasswordSettings;
