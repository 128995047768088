import {composeReducers} from 'common/utils/reducers';
import {combineReducers} from 'redux';
import * as actions from '../actions';

const EMPTY_OBJECT = {};

const valuesToSet = (payload) => ({
  id: payload.id,
  originType: payload.originType,
  measure: payload.measure,
  spike: payload.spike,
  drop: payload.drop,
  currency: payload.currency,
  factor: payload.factor,
});

const makeLeanAlert = (alert) => {
  const lastInterval = alert.metrics[0].intervals.length - 1;
  return {
    title: alert.title,
    metric: alert.metrics[0],
    sumDeltas: alert.metrics[0].intervals[lastInterval].sumDeltas,
    direction: alert.metrics[0].intervals[lastInterval].direction,
    startTime: alert.startTime,
    endTime: alert.endTime,
  };
};

const leanAlertsByMeasure = (payload) => {
  const measures = {};

  Object.keys(payload).map((i) => {
    const originId = payload[i].alerts[0].metrics[0].origin.id;
    measures[i + originId] = makeLeanAlert(payload[i].alerts[0]);
  });

  return measures;
};

const modalFromEmail = composeReducers((state = EMPTY_OBJECT, {type, payload}) => {
  switch (type) {
    case actions.isImpactModalOpen.TYPE: {
      return {
        ...state,
        isOpen: payload,
      };
    }
    default:
      return state;
  }
});

const impacts = composeReducers((state = EMPTY_OBJECT, {type, payload}) => {
  switch (type) {
    case actions.addImpacts.TYPE: {
      const leanImpacts = {};

      payload.forEach((impact) => {
        leanImpacts[impact.id] = valuesToSet(impact);
      });
      return {
        ...state,
        ...leanImpacts,
      };
    }
    case actions.addImpact.TYPE: {
      return {
        ...state,
        [payload.id]: {
          ...valuesToSet(payload),
        },
      };
    }
    case actions.setImpactValue.TYPE: {
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          [payload.name]: payload.value,
        },
      };
    }
    case actions.setImpactDefaultValues.TYPE: {
      return {
        ...state,
        [payload.id]: payload,
      };
    }
    default:
      return state;
  }
});

const alertsTriggered = composeReducers((state = EMPTY_OBJECT, {type, payload, meta}) => {
  switch (type) {
    case actions.addAlertById.TYPE: {
      return {
        ...state,
        [meta]: makeLeanAlert(payload),
      };
    }
    case actions.addAlertsByMeasures.TYPE: {
      return {
        ...state,
        ...leanAlertsByMeasure(payload),
      };
    }
    default:
      return state;
  }
});

export default combineReducers({
  modalFromEmail,
  impacts,
  alertsTriggered,
});
