import {combineEpics} from 'redux-observable';
import {makeAsyncEpic, reqTimeingFilterFanction} from 'common/utils/simplifiedAsync';
import * as selectors from 'alerts.channels/store/selectors';
import {createInitialConnection, fetchChannels, postChannel, putChannel, testChannel} from '../actions';
import * as api from '../../services/api';

const isFetchChannels = (action, getState) => {
  if (action.payload && action.payload.refresh) {
    return true;
  }
  const updatedAt = selectors.getChannelsUpdatedAt(getState());
  return reqTimeingFilterFanction(updatedAt);
};

const fetchChannelsEpic = makeAsyncEpic(fetchChannels, api.fetchChannels, isFetchChannels);
const postChannelEpic = makeAsyncEpic(postChannel, api.postChannel);
const testChannelEpic = makeAsyncEpic(testChannel, api.testChannel);
const putChannelEpic = makeAsyncEpic(putChannel, api.putChannel);
const createInitialConnectionEpic = makeAsyncEpic(createInitialConnection, api.createInitialConnection);

const channelsEpics = combineEpics(
  fetchChannelsEpic,
  postChannelEpic,
  testChannelEpic,
  putChannelEpic,
  createInitialConnectionEpic,
);

export default channelsEpics;
