// @flow
import React, {PureComponent} from 'react';
import {abbrNum} from 'common/utils/numbers';
import './Gauges.module.scss';

type PropTypes = {
  selectedItem: Number,
  index: Number,
  description: String,
  value: Number,
  isLoading: Boolean,
  fetchData: Function,
};

export default class Gauge extends PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {index, selectedItem, fetchData} = this.props;
    if (index === selectedItem) {
      fetchData();
    }
  }

  componentDidUpdate() {
    const {index, selectedItem, fetchData} = this.props;
    if (index === selectedItem) {
      fetchData();
    }
  }

  render() {
    const {description, value, isLoading} = this.props;
    return (
      <div styleName={isLoading ? 'gauge-container loading' : 'gauge-container'}>
        <h5 styleName="value">{abbrNum(value, 1, true)}</h5>
        <span styleName="description">{description}</span>
      </div>
    );
  }
}
