import './globalStyles';
import {isEmpty} from 'lodash';
import React, {useEffect} from 'react';
import {Provider, useSelector, useDispatch} from 'react-redux';
import {Route} from 'react-router-dom';
import {createHashHistory} from 'history';
import {ConnectedRouter} from 'connected-react-router';
import Shell from 'app/components/Shell';
import AppNavBar from 'app/components/appNavBar/AppNavBar';
import MandatoryNameModal from 'app/components/MandatoryNameModal';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from 'app/styles/theme';
import DashboardConversionModal from 'dashboards/components/modals/ConversionModal';
import {QueryParamProvider, StringParam, useQueryParam} from 'use-query-params';
import {getUserProfile} from 'profile/store/selectors';
import {fetchMe} from 'profile/store/actions';
import {setGlobalUser, processAndtToken} from './appUtils';
import initSubscriptions from './globalSubscriptions';
import configureStore from './configureStore';
import Gauge from './metrics/components/gauge/Gauge';
import SimulationFilters from './alerts.management/components/editor/simulationFilters/SimulationFiltersWrapper';
import UsersGroupsSelect from './admin.users/components/UsersGroupsSelectWrapper';
import {getAnonymousUser} from './dashboards/store/actions';

// const isDev = process.env.NODE_ENV === 'development';
const originalHistory = createHashHistory({
  basename: '/r',
  hashType: 'hashbang',
});

const store = configureStore(originalHistory);
const history = originalHistory; // isDev ? syncHistoryWithStore(originalHistory, store) : originalHistory;

const Content = () => {
  const [invitationId] = useQueryParam('invitationId', StringParam);
  const dispatch = useDispatch();
  const me = useSelector(getUserProfile);

  useEffect(() => {
    processAndtToken();
    window.angular.element(document).ready(function() {
      if (invitationId) {
        dispatch(getAnonymousUser({invitationId: invitationId.replace(';', '')}));
      } else {
        dispatch(fetchMe());
      }
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(me)) {
      setGlobalUser(me);
    }
  }, [me]);

  if (isEmpty(me)) {
    return null;
  }
  return (
    <>
      <AppNavBar />
      <MandatoryNameModal />
      <div className="shell-col position_relative">
        <div
          className="shell-col stretch_absolute"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{__html: '<ui-view autoscroll="false" class="shell-col"/>'}}
        />
        <Shell />
      </div>
    </>
  );
};

class App extends React.PureComponent {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ConnectedRouter history={history}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <Content />
            </QueryParamProvider>
          </ConnectedRouter>
        </ThemeProvider>
      </Provider>
    );
  }
}

export const AngularDashboardConversionModal = (props) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <DashboardConversionModal {...props} />
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>
);

window.AnodotBusinessCollectors = {
  App,
  Gauge,
  SimulationFilters,
  UsersGroupsSelect,
  AngularDashboardConversionModal,
};

initSubscriptions(App, store);

export default App;
