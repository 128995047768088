import React, {useEffect, useState} from 'react';
import Collapse from '@material-ui/core/Collapse';
import ToggleSwitch from 'common/componentsV2/toggleSwitch/ToggleSwitch';
import './ExpandableSections.module.scss';

export const Expandable = React.memo(
  ({
    label,
    expanded,
    number,
    children,
    isDisabled,
    automationId,
    actionElement,
    onChange,
    justifyLabel = 'space-between',
    icon,
    extraStyle,
    collapsedBottomSpace,
  }: {
    label: string,
    expanded: boolean,
    number: number,
    children: Object,
    isDisabled: boolean,
    automationId: string,
    actionElement: React.Node,
    onChange?: Function,
    justifyLabel?: string,
    icon?: string,
    extraStyle?: Object,
    collapsedBottomSpace?: number,
  }) => {
    const [visible, toggle] = useState(expanded);
    const onToggle = () => {
      toggle(!visible);
      if (onChange) {
        onChange(!visible);
      }
    };
    useEffect(() => {
      toggle(expanded);
    }, [expanded]);
    return (
      <div
        automation-id={`${automationId}_container`}
        className="width_1"
        style={{marginBottom: collapsedBottomSpace && !visible ? collapsedBottomSpace : undefined}}
      >
        <div className="display_flex alignItems_center width_1" style={{justifyContent: justifyLabel}}>
          <div styleName="label" onClick={onToggle} automation-id={automationId} style={extraStyle}>
            {number && <div styleName="number">{number}</div>}
            <div>
              {icon && <i className={icon} style={{marginRight: 8}} />}
              <span>{label}</span>
            </div>
            <span styleName="icon" className={visible ? 'icn-arrow16-chevrondown' : 'icn-arrow16-chevronright'} />
          </div>
          {actionElement}
        </div>
        <div className="pt-1 mt-1" css={{opacity: isDisabled ? 0.2 : 1, pointerEvents: isDisabled ? 'none' : 'auto'}}>
          <Collapse in={visible}>{children}</Collapse>
        </div>
      </div>
    );
  },
);

Expandable.defaultProps = {
  onChange: () => null,
  justifyLabel: '',
  icon: '',
  extraStyle: {},
  collapsedBottomSpace: 0,
};

// eslint-disable-next-line react/no-multi-comp
export const ExpandableSwitch = React.memo(
  ({
    label,
    expanded,
    children,
    onChange,
    automationId,
  }: {
    label: string,
    expanded: Boolean,
    children: Object,
    onChange: Function,
    automationId: string,
  }) => (
    <div automation-id={`${automationId}_container`} styleName={`box ${expanded ? 'opened' : ''}`}>
      {expanded}
      <div styleName="box-header">
        <div styleName="small-label">{label}</div>
        <ToggleSwitch
          automationId={automationId}
          isChecked={expanded}
          onToggle={() => {
            if (onChange) {
              onChange(!expanded);
            }
          }}
        />
      </div>
      {expanded && children}
    </div>
  ),
);

// eslint-disable-next-line react/no-multi-comp
export const ExpandableBox = React.memo(
  ({
    label,
    expanded,
    children,
    onChange,
    automationId,
    rootStyle,
    labelStyle,
    rightHeaderElement,
    nodeLabel,
    blockStyle,
  }: {
    label: string,
    expanded: Boolean,
    children: Object,
    onChange: Function,
    automationId: string,
    rootStyle: Object,
    labelStyle: Object,
    blockStyle: Object,
    rightHeaderElement: Node,
    nodeLabel: Node,
  }) => (
    <div automation-id={`${automationId}_container`} styleName="box opened" style={rootStyle || {}}>
      <div
        role="button"
        className="display_flex"
        style={blockStyle || {}}
        onClick={() => {
          if (onChange && !rightHeaderElement) {
            onChange(!expanded);
          }
        }}
      >
        <div
          className="display_flex flexGrow_1"
          onClick={() => {
            if (onChange && rightHeaderElement) {
              onChange(!expanded);
            }
          }}
        >
          <span styleName="icon-small" className={expanded ? 'icn-arrow16-chevrondown' : 'icn-arrow16-chevronright'} />
          {nodeLabel || (
            <div styleName="small-label" style={labelStyle || {}}>
              {label}
            </div>
          )}
        </div>
        {rightHeaderElement}
      </div>
      <Collapse in={expanded}>{children}</Collapse>
    </div>
  ),
);
