// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {palette} from 'app/styles/theme';
import {getBadgeColor} from 'topologyGeneral/services/mapService';

const useStyles = makeStyles(() => ({
  regionBadge: {
    height: '37px',
    width: '37px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 1,
    fontSize: '14px',
    fontWeight: 400,
    color: palette.white['500'],
  },
}));

const SelectedRegionItem = ({region}: PropTypes) => {
  const classes = useStyles();

  return (
    <Box display="flex" width="100%" height={60} borderRadius="10px" bgcolor="gray.100">
      <Box width={68} display="flex" pl={2} alignItems="center">
        <Box className={classes.regionBadge} bgcolor={getBadgeColor(region.percent)}>
          {`${region.percent}%`}
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" flexGrow={1} pr={2.5}>
        <Box display="flex" flexDirection="column">
          <Box fontSize="14px" fontWeight={400} color="gray.500" lineHeight={1} component="span">
            {region.title}
          </Box>
          <Box fontSize="14px" fontWeight={400} color="gray.400" lineHeight={1} component="span" mt={0.5}>
            {`${region.nodesTotal.toLocaleString()} Nodes`}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

SelectedRegionItem.propTypes = {
  region: PropTypes.objectOf(PropTypes.any).isRequired,
};

// SelectedRegionItem.defaultProps = {};

export default SelectedRegionItem;
