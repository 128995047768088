// @flow
import React from 'react';
import {connect} from 'react-redux';
import {isEditRunning, isStreamEditable} from 'bc/services/dataStreamService';
import {applyAwsCurTemplate as applyAwsCurTemplateAction} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

import './AwsCurTemplate.module.scss';

type PropTypes = {
  dataStream: Object,
  isLoading: boolean,
  templates: Array,
  selectedTemplate: Object,

  // connect
  applyAwsCurTemplate: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),

    isLoading: selectors.awsCurTemplatesIsLoading(state),
    templates: selectors.awsCurTemplatesItems(state),
    selectedTemplate: selectors.awsCurSelectedTemplatesId(state),
  }),
  {
    applyAwsCurTemplate: applyAwsCurTemplateAction,
  },
)
export default class AwsCurTemplates extends React.PureComponent {
  props: PropTypes;

  componentDidUpdate() {
    const {templates, selectedTemplate, applyAwsCurTemplate} = this.props;
    if (templates.length > 0 && selectedTemplate === '') {
      applyAwsCurTemplate(templates[0].id);
    }
  }

  render() {
    const {templates, isLoading, selectedTemplate, applyAwsCurTemplate, dataStream} = this.props;

    const selectedIndex = templates.findIndex((val) => (selectedTemplate ? selectedTemplate === val.id : false));

    return (
      <div styleName="root">
        <SelectAndt
          id="awsCurTemplatesDdl"
          className="andt-dropdown"
          disabled={!isStreamEditable(dataStream.state) && !isEditRunning(dataStream.id)}
          options={templates}
          getOptionLabel={(val) => val.name}
          getOptionValue={(val) => val.id}
          type={TYPE_NEW_NO_SEARCH}
          theme={THEME_NOT_HIGHLIGHTED}
          optionHeight={40}
          value={isLoading ? null : templates[selectedIndex]}
          onChange={(option) => applyAwsCurTemplate(option.id)}
          placeholder={isLoading ? 'Loading...' : 'Choose Use Case'}
          automationId="awsCurChooseTemplate"
        />
      </div>
    );
  }
}
