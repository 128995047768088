import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';
import * as profileSelector from 'profile/store/selectors';
import * as profileActions from 'profile/store/actions';
import {getParameterByUrl} from 'common/utils/browserLoacation';
import {cleanHash} from 'common/utils/utilsService';
import segmentCode from 'common/utils/analyticsService';
import * as actions from '../actions';

function findTargetDom(evtTarget) {
  if (!evtTarget) {
    return null;
  }
  if (evtTarget.tagName === 'BODY') {
    return null;
  }
  return evtTarget.dataset.segment ? evtTarget.dataset.segment : findTargetDom(evtTarget.parentElement);
}

function createObjFromStr(string) {
  if (string) {
    return string.split(',').reduce((obj, str) => {
      const ret = obj;
      const strParts = str.split(':');

      if (strParts[0] && strParts[1]) {
        // <-- Make sure the key & value are not undefined
        // <-- Get rid of extra spaces at beginning of value strings
        ret[strParts[0].replace(/\s+/g, '')] = strParts[1].trim();
      }
      return ret;
    }, {});
  }
  return {name: 'undefined'};
}

function getCustomerInfo(getState) {
  const state = getState();
  const me = profileSelector.getUserProfile(state) || {};
  return {
    isImpersonating: me.impersonatorId,
    organization: profileSelector.getOrganization(state),
    userName: `${me.firstName} ${me.lastName}`,
    firstName: `${me.firstName}`,
    lastName: `${me.lastName}`,
    email: me.email,
    payingCustomer: profileSelector.getPayingCustomer(state),
    selfService: profileSelector.getSelfService(state),
    selfServicePackage: profileSelector.getSelfServicePackage(state),
    id: me._id,
    role: me.roles,
  };
}
function getPageInfo() {
  return {
    hash: window.location.hash,
    pageName: cleanHash(window.location.hash),
  };
}
function extractCustomerData(customerInfo) {
  return {
    accountId: customerInfo.organization._id,
    customer: customerInfo.organization.name,
    userName: customerInfo.userName,
    firstName: customerInfo.firstName,
    lastName: customerInfo.lastName,
    email: customerInfo.email,
    role: customerInfo.role,
    payingCustomer: customerInfo.payingCustomer,
    selfService: customerInfo.payingCustomer,
    selfServicePackage: customerInfo.selfServicePackage,
  };
}
function shouldDisableAnalytic(customerInfo) {
  if (customerInfo.isImpersonating || !window.analytics || !window.analytics.track) return true;
  return false;
}

export const segmentFirstTimeLoadEvent = (action$, {getState}) =>
  action$.ofType(profileActions.setMe.TYPE, profileActions.fetchMe.success.TYPE).flatMap(() => {
    const customerInfo = getCustomerInfo(getState);
    const pageInfo = getPageInfo();
    const ref = getParameterByUrl('ref');
    const analyticsName = segmentCode[getParameterByUrl('analytics')];

    if (shouldDisableAnalytic(customerInfo)) {
      return [];
    }
    window.analytics.identify(customerInfo.id, {
      ...pageInfo,
      host: window.location.hostname,
      ...extractCustomerData(customerInfo),
    });

    if (ref === 'email' && analyticsName) {
      return [actions.segmentCausingEvent({segment: {category: 'email', name: analyticsName}})];
    }

    return [];
  });

export const segmentPageChangedEvent = (action$, {getState}) =>
  action$.ofType(actions.appInit.TYPE).flatMap(() => {
    Observable.fromEvent(window, 'popstate').subscribe(() => {
      const customerInfo = getCustomerInfo(getState);
      const pageInfo = getPageInfo();

      if (shouldDisableAnalytic(customerInfo)) {
        return [];
      }

      window.analytics.page(pageInfo.pageName, {
        ...pageInfo,
        ...extractCustomerData(customerInfo),
      });
      return [];
    });
    return [];
  });

export const segmentCausingEvent = (action$, {getState}) =>
  action$.ofType(actions.segmentCausingEvent.TYPE).flatMap(({payload}) => {
    const customerInfo = getCustomerInfo(getState);
    const pageInfo = getPageInfo();

    if (shouldDisableAnalytic(customerInfo)) {
      return [];
    }
    if (!payload) {
      return [];
    }
    window.analytics.track('causing-event', {
      ...pageInfo,
      ...payload,
      ...extractCustomerData(customerInfo),
    });
    return [];
  });

export const segmentClickEvent = (action$, {getState}) =>
  action$.ofType(actions.segmentClickEvent.TYPE).flatMap(({payload}) => {
    const customerInfo = getCustomerInfo(getState);
    const pageInfo = getPageInfo();

    if (shouldDisableAnalytic(customerInfo)) {
      return [];
    }
    if (!payload) {
      return [];
    }
    window.analytics.track(payload.type || 'click-event', {
      ...pageInfo,
      ...payload,
      ...extractCustomerData(customerInfo),
    });
    return [];
  });

export const segmentClickEventFromAngular = (action$, {getState}) => {
  Observable.fromEvent(document, 'click').subscribe((e) => {
    const customerInfo = getCustomerInfo(getState);
    const pageInfo = getPageInfo();
    const string = findTargetDom(e.target);

    if (shouldDisableAnalytic(customerInfo)) {
      return [];
    }
    if (!string) {
      return [];
    }

    const eventDetails = createObjFromStr(string);

    window.analytics.track('click-angular', {
      ...pageInfo,
      ...eventDetails,
      ...extractCustomerData(customerInfo),
    });
    return [];
  });
  return [];
};
