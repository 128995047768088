import {createSelector} from 'reselect';
import * as profileSelectors from 'profile/store/selectors';
import {isEmpty, cloneDeep, get} from 'lodash';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

export const getAppNavBar = (state) => state.appNavBar;

const filterFeatureActivation = (item, featuresActivation) => {
  if (item.featuresActivation) {
    let ret = true;
    item.featuresActivation.forEach((featureAct) => {
      ret = ret && get(featuresActivation, featureAct);
    });

    return ret;
  }
  return true;
};

const filterItems = (arr, role, featuresActivation) =>
  arr.filter((item) => {
    const fa = filterFeatureActivation(item, featuresActivation);
    if (!item.authorizedFor || (item.authorizedFor.includes(role) && item.authorizedFor.length !== 0 && fa)) {
      if (item.items) {
        // eslint-disable-next-line no-param-reassign
        item.items = filterItems(item.items, role, featuresActivation);
      }
      return true;
    }
    return false;
  });

export const getAppNavBarViews = createSelector(
  getAppNavBar,
  (appNavBar) => appNavBar.views || EMPTY_OBJECT,
);

export const getAppNavBarItems = createSelector(
  getAppNavBarViews,
  (views) => views.items || EMPTY_ARRAY,
);

export const getAppNavBarFilteredItems = createSelector(
  getAppNavBarItems,
  profileSelectors.getUserProfile,
  profileSelectors.getFeaturesActivation,
  (appNavBarItems, me, featuresActivation) => {
    if (isEmpty(me)) {
      return EMPTY_ARRAY;
    }
    const ff = get(me, 'organization.serverConfiguration.featureFlags.featuresActivation', EMPTY_ARRAY);

    const appNavBarItemsClone = cloneDeep(appNavBarItems);
    if (ff.ENABLE_NEW_METRIC_EXPLORER) {
      appNavBarItemsClone.find((item) => item.id === 'METRIC_SEARCH').links = ['#!/r/metrics-explorer'];
    }
    return filterItems(appNavBarItemsClone, me.roles[0], featuresActivation);
  },
);
