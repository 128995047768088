// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as commonSelectors from 'profile/store/selectors';
import Loader from 'common/components/Loader';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {isEmpty} from 'lodash';
import {getTypeDetails} from 'bc/services/bcTypes';
import {getUsedLookupIdsList} from 'bc/services/lookupService';
import moment from 'moment';
import {Observable} from 'rxjs/Observable';
import * as selectors from 'bc/store/selectors';
import ProgressModal from 'bc/components/dataSourceTypes/ProgressModal';
import 'rxjs/add/observable/interval';
import LookupTableUpload from 'bc/components/dataSourceTypes/LookupTableUpload';
import {fetchDataStreams, fetchLookupTables, createDataStream as createDataStreamAction} from 'bc/store/actions';
import {isAgent} from 'bc/services/dataStreamService';
import {segmentCausingEvent} from 'common/store/actions';
import StreamsLimitModal from './StreamsLimitModal';
import StreamItem from './StreamItem';
import LookupItem from './LookupItem';
import NoData from './NoData';
import './StreamsList.module.scss';

const StreamList = (props: {allDataStreams: Array, dataStreams: Array}) => {
  const {allDataStreams, dataStreams} = props;

  const lookupIdsListArr = [];
  if (allDataStreams) {
    allDataStreams.forEach((stream) => {
      if (stream.schema && stream.schema.columns) {
        stream.schema.columns.forEach((item) => {
          lookupIdsListArr.concat(getUsedLookupIdsList(lookupIdsListArr, item));
        });
      }
    });
  }

  return (
    <div>
      {dataStreams.map((streamGroup) => (
        <div styleName="section-list-wrapper" key={streamGroup.type}>
          <div styleName="list-header">{getTypeDetails(streamGroup.type).name}</div>
          <ul automation-id={`dataManagerSource${streamGroup.type}List`}>
            <div>
              {streamGroup.type === 'lookup'
                ? streamGroup.items.map((ds) => (
                    // eslint-disable-next-line react/jsx-indent
                    <LookupItem key={ds.id} dataStreams={allDataStreams} lookupList={lookupIdsListArr} {...ds} />
                  ))
                : streamGroup.items.map((ds) => {
                    if (ds.id === 'EditRunning') {
                      return null;
                    }
                    // eslint-disable-next-line consistent-return
                    return <StreamItem key={ds.id} {...ds} />;
                  })}
            </div>
          </ul>
        </div>
      ))}
    </div>
  );
};

type PropTypes = {
  // connect
  selectedDataSource: Object,
  allDataStreams: Array,
  dataStreams: Object,
  dataStreamsCount: Object,
  sourceItemsCount: Object,
  newStreamsCount: Number,
  incompleteSteamsLimit: Number,
  isLoading: Boolean,
  updateAt: String,
  fileName: String,
  progress: Number,
  isBcReadOnly: Boolean,
  fetchDataStreams: Function,
  fetchLookupTables: Function,
  createDataStream: Function,
  segmentCausingEvent: Function,
};

@connect(
  (state) => ({
    selectedDataSource: selectors.getSelectedDataSource(state),
    allDataStreams: selectors.getDataStreamsItems(state),
    dataStreams: selectors.getDataManagerFilteredStreamsAndLookups(state),
    dataStreamsCount: selectors.getDataManagerStreamsAndLookupsCount(state),
    sourceItemsCount: selectors.getDataSourcesItemsNoLookup(state).length,
    newStreamsCount: selectors.getNewStreamsCount(state),
    incompleteSteamsLimit: commonSelectors.getIncompleteSteamsLimit(state),
    isLoading: selectors.getDataStreams(state).streams.isLoading,
    updateAt: selectors.getDataStreams(state).streams.updateAt,
    fileName: selectors.getFileUploadName(state),
    progress: selectors.getFileUploadProgress(state),
    isBcReadOnly: selectors.isBcReadOnlyPermision(state),
  }),
  {
    fetchDataStreams,
    fetchLookupTables,
    createDataStream: createDataStreamAction,
    segmentCausingEvent,
  },
)
export default class StreamsList extends React.PureComponent {
  props: PropTypes;

  state = {
    isLimitModalOpen: false,
  };

  componentDidMount() {
    this.props.fetchDataStreams();
    this.props.fetchLookupTables();
    this.refreshInterval = Observable.interval(1000 * 60).subscribe(() => this.props.fetchDataStreams());
  }

  componentWillUnmount() {
    this.refreshInterval.unsubscribe();
  }

  openStreamsListModal = () => {
    this.setState({isLimitModalOpen: true});
  };

  closeStreamsListModal = () => {
    this.setState({isLimitModalOpen: false});
  };

  createDataStream = () => {
    const {selectedDataSource, createDataStream, isBcReadOnly, newStreamsCount, incompleteSteamsLimit} = this.props;

    if (selectedDataSource && !isBcReadOnly) {
      if (newStreamsCount >= incompleteSteamsLimit) {
        this.openStreamsListModal();
      } else {
        this.props.segmentCausingEvent({
          category: 'bc/data-manager',
          name: `new stream: ${selectedDataSource.type}`,
        });
        createDataStream({
          type: selectedDataSource.type,
          dataSourceId: selectedDataSource.id,
        });
      }
    }
  };

  render() {
    const {
      isLoading,
      dataStreams,
      allDataStreams,
      selectedDataSource,
      updateAt,
      isBcReadOnly,
      fileName,
      progress,
      dataStreamsCount,
      sourceItemsCount,
    } = this.props;

    const isAgentSource = selectedDataSource && isAgent(selectedDataSource.family);

    if (isLoading !== false && !dataStreams.length) {
      return <Loader styleName="loader-pos" size="small" />;
    }

    return (
      <div styleName="root">
        {this.state.isLimitModalOpen ? (
          <StreamsLimitModal
            isOpen={this.state.isLimitModalOpen}
            onClose={this.closeStreamsListModal}
            scenario="incomplete"
          />
        ) : null}

        <div styleName="section-header-wrapper">
          {selectedDataSource && selectedDataSource.type === 'lookup' ? (
            <LookupTableUpload />
          ) : (
            <SmartTooltip
              placement="right"
              content={!selectedDataSource ? 'First, select a source to create a stream' : ''}
            >
              <button
                className={`btn btn-flat btn-primary andt-section-header ${
                  !selectedDataSource || isBcReadOnly || isAgentSource ? 'disabled' : ''
                }`}
                styleName={
                  !selectedDataSource || isBcReadOnly || isAgentSource
                    ? 'section-header-btn-disabled'
                    : 'section-header-btn'
                }
                onClick={this.createDataStream}
                type="button"
              >
                Streams
                <i className="icon icn-icon-blue-plus" automation-id="dataCollectorNewStreamButton" />
              </button>
            </SmartTooltip>
          )}

          {updateAt && (
            <div styleName="last-refresh">
              {isLoading && <i className="icon icn-icon-initializing spin" />}
              <span styleName="prefix">Last Refresh </span>
              <div styleName="time">{moment.unix(updateAt).format('HH:mm')}</div>
            </div>
          )}
        </div>

        <div className="shell-col" styleName="list">
          {!isEmpty(dataStreams) ? (
            <StreamList dataStreams={dataStreams} allDataStreams={allDataStreams} />
          ) : (
            <div styleName="section-list-wrapper">
              <NoData
                dataStreamsCount={dataStreamsCount}
                sourceItemsCount={sourceItemsCount}
                createStream={this.createDataStream}
                sourceType={selectedDataSource ? selectedDataSource.type : null}
              />
            </div>
          )}
        </div>

        {this.props.fileName ? (
          <ProgressModal
            onClose={this.closeProgressModal}
            onAbort={this.abortUpload}
            fileName={fileName}
            progress={progress}
            isOpen={this.props.fileName !== ''}
          />
        ) : null}
      </div>
    );
  }
}
