// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setSelectedEditor} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import SchedularData from 'bc/components/streams/editor/common/SchedularData';
import {isEditRunning, isStreamEditable} from 'bc/services/dataStreamService';
import {get} from 'lodash';
import './SqlSchedular.module.scss';

type PropTypes = {
  dataStreamState: boolean,
  dataStreamId: String,
  uiState: Object,
  setSelectedEditor: Function,
};

class SqlSchedular extends React.PureComponent {
  props: PropTypes;

  editClicked = () => {
    this.props.setSelectedEditor({
      type: 'schedular_sql',
      editorHeight: 'calc(100% - 210px)',
      maxHeight: '600px',
    });
  };

  render() {
    const {uiState, dataStreamId, dataStreamState} = this.props;
    // const isEditable =
    //   (isStreamEditable(dataStreamState) || isEditRunning(dataStreamId)) &&
    //   get(uiState, 'editEnabled') &&
    //   (get(uiState, 'tablesViewsMetadata') || get(uiState, 'queryPreviewColumns'));
    const isEditable =
      isEditRunning(dataStreamId) ||
      (isStreamEditable(dataStreamState) &&
        get(uiState, 'editEnabled') &&
        (get(uiState, 'tablesViewsMetadata') || get(uiState, 'queryPreviewColumns')));

    return (
      <div className="shell-col" styleName={!isEditable ? 'disabled' : ''}>
        <PanelHeader title="Schedule records collection" onSelect={this.editClicked} isEditable={isEditable} />
        <SchedularData />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    dataStreamState: selectors.getSelectedDataStream(state).state,
    dataStreamId: selectors.getSelectedDataStream(state).id,
    uiState: selectors.getSelectedDataStream(state).uiState,
  }),
  {
    setSelectedEditor,
  },
)(SqlSchedular);
