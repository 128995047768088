// flow
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Transition} from 'react-transition-group';
import * as selectors from 'insightsPanel/store/selectors';
import * as actions from 'insightsPanel/store/actions';
import {fadeOpacity, shouldFetchData} from 'insightsPanel/services/constants';
import TopFeedbackerItem from './TopFeedbackerItem';
import './TopFeedbackers.module.scss';

type PropTypes = {
  title: String,
};

const TopFeedbackers = (props: PropTypes) => {
  const topFeedbackers = useSelector(selectors.getFeedbackPerMeasure);
  const topFeedbackersData = useSelector(selectors.getTopFeedbackersData);
  const isLoading = useSelector(selectors.getIsLoadingFeedbackPerMeasure);
  const isPanelOpen = useSelector(selectors.getIsPanelOpen);
  const numLastDays = useSelector(selectors.getNumLastDays);
  const dispatch = useDispatch();
  const [showData, setShowData] = useState(false);
  const isFirstRun = useRef(true);
  let timer;

  useEffect(() => {
    const params = {
      numLastDays: numLastDays.value,
    };
    if (isPanelOpen && shouldFetchData(topFeedbackers)) {
      dispatch(actions.getAlertsDistribution(params));
    }
  }, [isPanelOpen]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    const params = {
      numLastDays: numLastDays.value,
    };
    dispatch(actions.getAlertsDistribution(params));
  }, [numLastDays]);

  useEffect(() => {
    if (topFeedbackersData) {
      // for some reason Transition duration does not work.
      timer = setTimeout(() => setShowData(true), fadeOpacity.duration);
    }
  }, [topFeedbackersData]);

  useEffect(
    () => () => {
      clearTimeout(timer);
    },
    [isPanelOpen],
  );

  return (
    <div styleName={isLoading ? 'body gray loader' : 'body gray'}>
      {isLoading === false && topFeedbackersData && (
        <Transition in={showData} timeout={0}>
          {(state) => (
            <Fragment>
              <header
                styleName="header"
                style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state], marginBottom: '3px'}}
              >
                {props.title}
              </header>
              <div styleName="main-container">
                {!topFeedbackersData.length ? (
                  <div styleName="no-feedback">
                    <span>Not enough data to show</span>
                  </div>
                ) : (
                  topFeedbackersData.map((i, index) => (
                    <TopFeedbackerItem
                      name={i.name}
                      feedbackCount={i.feedbackCount}
                      index={index}
                      key={`topFeedbackerItem${i.name}`}
                    />
                  ))
                )}
              </div>
            </Fragment>
          )}
        </Transition>
      )}
    </div>
  );
};

export default TopFeedbackers;
