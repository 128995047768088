// @flow
import React, {useEffect} from 'react';
import {get} from 'lodash';
import {Backdrop, makeStyles, Modal} from '@material-ui/core';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ReactComponent as IconGroup} from 'dashboards/images/dashboard_group_2.svg';
import AsyncButton from 'common/componentsV2/AsyncButton';
import * as actions from 'dashboards/store/actions';
import useAsyncAction from 'common/utils/useAsyncAction';
import {getCreateDuplicateDashboards} from 'dashboards/store/selectors';
import useDashboardState from 'dashboards/hooks/useDashboardState';
import {COLOR_NAME} from 'common/componentsV2/ColorPicker';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import Button, {COLORS} from 'common/componentsV2/Button';
import {OPTION_TILES_VALUES} from 'dashboards/services/dashboardService';

type PropsType = {
  onClose: Function,
  dashboardId: string,
  isCreate: boolean,
};

const titleAdvantages = [
  {
    title: 'New Look',
    color: '#ff1551',
  },
  {
    title: 'Faster',
    color: '#0ad7ce',
  },
  {
    title: 'Simple Filters',
    color: '#ffba21',
  },
  {
    title: 'Better Edit Mode',
    color: '#7f4dff',
  },
  {
    title: 'New Tiles',
    color: '#7cb342',
    addText: '(Soon)',
  },
  {
    title: 'Instant Dashboard Templates',
    color: '#24b9ff',
    addText: '(Soon)',
  },
];

const useStyles = makeStyles(({palette, typography}) => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 450,
    height: 'fit-content',
    backgroundColor: palette.white[500],
    borderRadius: 7,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.3)',
    margin: 'auto',
    padding: '20px 40px',
  },
  title: {
    fontSize: 24,
    fontWeight: 300,
    color: palette.gray[600],
    marginBottom: 7,
  },
  hint: {
    ...typography.subtitle2,
    marginBottom: 20,
    lineHeight: '18px',
  },
  contentWrapper: {
    width: 370,
    backgroundColor: palette.gray[100],
    borderRadius: 8,
    padding: '16px 20px 16px 32px',
    marginBottom: 16,
  },
  contentTitle: {
    ...typography.caption,
    fontWeight: 500,
    marginBottom: 8,
  },
  contentRow: {
    ...typography.body1,
    '@global': {
      span: {
        color: palette.gray[400],
        fontStyle: 'italic',
      },
    },
  },
  textButton: {
    ...typography.subtitle3,
    cursor: 'pointer',
  },
  notificationWrapper: {
    backgroundColor: '#fae7eb',
    borderRadius: 8,
    padding: '8px 12px',
    ...typography.subtitle3,
    lineHeight: '18px',
    fontWeight: 400,
  },
  link: {
    ...typography.body1,
    lineHeight: '18px',
    color: palette.blue[500],
  },
}));

const ConversionModal = (props: PropsType) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const dashboard = useDashboardState(props.dashboardId);
  const createDuplicateDashboardsData = useSelector(getCreateDuplicateDashboards);
  const store = useStore();
  const {isCreate} = props;

  useEffect(() => {
    if (!isCreate) {
      dispatch(actions.fetchDashboardV1({id: props.dashboardId}));
    }
  }, []);

  const convertAction = useAsyncAction(actions.createDuplicateDashboard, createDuplicateDashboardsData);

  const handleConvert = () => {
    const emptySelector = {isAll: false, value: '', name: ''};
    convertAction({
      ...dashboard.data,
      color: COLOR_NAME.GRAY,
      icon: 'Default',
      isV1: false,
      v1ID: dashboard.data._id,
      _id: undefined,
      selectorsFilter: {
        selectors: get(dashboard, 'data.selectorsFilter.selectors', []).map((item) => ({
          ...item,
          selectedValue: emptySelector,
        })),
      },
    })
      .then(() => {
        const response = getCreateDuplicateDashboards(store.getState());
        history.push(`/dashboards/${response.data._id}`);
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log(error));
  };

  return (
    <Modal disableEnforceFocus open BackdropComponent={Backdrop} onClose={props.onClose}>
      <div className={classes.wrapper}>
        {dashboard.isLoading === true ? (
          <div className="display_flex alignItems_center justifyContent_center height_1 width_1">
            <Spinner color="gray.500" size={SIZES.XX_BIG_100} />
          </div>
        ) : (
          <React.Fragment>
            <div className={classes.title}>{isCreate ? 'Create a New Dashboard' : 'Convert this dashboard'}</div>
            {isCreate ? (
              <div className={classes.hint}>
                {/* eslint-disable-next-line max-len */}
                You can create a new dashboard with all its advantages or create a classic dashboard with all the
                features you already know.
              </div>
            ) : (
              <div className={classes.hint}>
                {/* eslint-disable-next-line max-len */}
                You can convert this dashboard to a newer version, and keep enjoying constant improvements and new
                features.
              </div>
            )}
            <div className={classes.contentWrapper}>
              <div className="display_flex justifyContent_center align-center mb_1">
                <IconGroup />
              </div>
              <div className={classes.contentTitle}>Dashboards 2.0</div>
              <div className="mb_2">
                {titleAdvantages.map((item) => (
                  <div key={item.title} className="display_flex alignItems_center mb_0-5">
                    <div className="mr_1">
                      <i className="icon icn-general16-checkmark" style={{color: item.color}} />
                    </div>
                    <div className={classes.contentRow}>
                      {item.title} <span>{item.addText}</span>
                    </div>
                  </div>
                ))}
              </div>
              {isCreate ? (
                <div className="display_flex justifyContent_center alignItems_center">
                  <Button
                    automation-id="createNewDashboard"
                    text="Create a New Dashboard"
                    colorSchema={COLORS.BLUE_500}
                    onClick={() => history.push(`/dashboards/${OPTION_TILES_VALUES.NEW}`)}
                  />
                </div>
              ) : (
                <div className="display_flex justifyContent_space-between alignItems_center">
                  <div
                    className={classes.textButton}
                    onClick={() => props.onClose()}
                    automation-id="conversionRemindLater"
                  >
                    Remind me Later
                  </div>
                  <AsyncButton
                    automationId="conversionDashboardV2"
                    text="Convert this dashboard"
                    onClick={handleConvert}
                    isLoading={createDuplicateDashboardsData.isLoading}
                  />
                </div>
              )}
            </div>
            {isCreate ? (
              <div className="display_flex justifyContent_center alignItems_center">
                <div
                  className={classes.textButton}
                  onClick={() => history.push(`/dashboards/${OPTION_TILES_VALUES.NEW}?isV1=true`)}
                  automation-id="createNewDashboardV1"
                >
                  Create a classic dashboard instead
                </div>
              </div>
            ) : (
              <div className={classes.notificationWrapper}>
                Keep in mind that some features will not carry over to the new dashboard.{' '}
                <a
                  href="https://support.anodot.com/hc/en-us/articles/360016194419-Dashboard-Overview"
                  className={classes.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more here.
                </a>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </Modal>
  );
};

export default ConversionModal;
