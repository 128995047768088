import {rangeTypes} from 'common/utils/dateRangeService';
import {NumberParam, StringParam, withDefault} from 'use-query-params';
import {intersection} from 'lodash';

export const MAX_METRICS_FOR_SELECTED_ISSUE = 50;

export const QUERY_PARAM_MAP = {
  searchQuery: withDefault(StringParam, undefined),
  regionsTabId: withDefault(StringParam, 'locations'),
  regionId: withDefault(StringParam, undefined),
  subRegionsTabId: withDefault(StringParam, 'alerts'),
  leafRegionTabId: withDefault(StringParam, 'alerts'),
  domainId: withDefault(StringParam, undefined),
  siteId: withDefault(StringParam, undefined),
  selectedSiteTabId: withDefault(StringParam, 'alerts'),
  selectedEntityId: withDefault(StringParam, undefined),
  selectedSubEntityId: withDefault(StringParam, undefined),
  issueId: withDefault(StringParam, undefined),

  // issues sub panel
  issuesType: withDefault(StringParam, 'alerts'),
  constRange: withDefault(StringParam, rangeTypes.h3.key),
  startDate: withDefault(NumberParam, undefined),
  endDate: withDefault(NumberParam, undefined),
  relativeLast: withDefault(NumberParam, undefined),
  relativeNext: withDefault(NumberParam, undefined),
};

export const ISSUES_OPTIONS = [
  {
    label: 'Open Alerts',
    value: 'alerts',
  },
  {
    label: 'Open Anomalies',
    value: 'anomalies',
  },
];

export const REGIONS_PANEL_TABS = {
  locations: {
    id: 'locations',
    label: 'Locations',
  },
  issues: {
    id: 'issues',
    label: 'Issues',
  },
  search: {
    id: 'search',
    label: 'Search',
  },
};

export const SUB_REGION_PANEL_TABS = {
  alerts: {
    id: 'alerts',
    label: 'Alerts',
  },
  subRegions: {
    id: 'subRegions',
    label: 'Top Alerted',
  },
};

export const REGIONS_LABEL_PER_LEVEL = {
  0: 'Regions',
  1: 'Sub_regions',
  2: 'Sub2_regions',
  3: 'Sub3_regions',
  4: 'Sub4_regions',
  5: 'Sub5_regions',
  6: 'Sub6_regions',
  7: 'Sub7_regions',
  8: 'Sub8_regions',
  9: 'Sub9_regions',
};

export const LEAF_REGION_PANEL_TABS = {
  alerts: {
    id: 'alerts',
    label: 'Alerts',
  },
  alertedSites: {
    id: 'alertedSites',
    label: 'Top Alerted Sites',
  },
};

export const SELECTED_SITE_PANEL_TABS = {
  alerts: {
    id: 'alerts',
    label: 'Alerts',
  },
  info: {
    id: 'info',
    label: 'Info',
  },
};

export const PANEL_SIZES = {
  sidePanelWidth: 396,
  innerPanelWidth: 364,
};

export const createNodeEntity = (site, node) => {
  const cells = {
    leftWing: site.cells.leftWing
      .filter((cell) => cell.relatedNodeId === node.id)
      .map((i) => ({...i, subEntityId: `cell_${i.id}`, sourceEntityId: node.id})),
    rightWing: site.cells.rightWing
      .filter((cell) => cell.relatedNodeId === node.id)
      .map((i) => ({...i, subEntityId: `cell_${i.id}`, sourceEntityId: node.id})),
    topWing: site.cells.topWing
      .filter((cell) => cell.relatedNodeId === node.id)
      .map((i) => ({...i, subEntityId: `cell_${i.id}`, sourceEntityId: node.id})),
  };
  const obj = {
    ...node,
    entityId: node.id,
    entityType: 'node',
    sourceEntityId: node.id,
    subEntityId: `node_${node.id}`,
    cells,
  };
  return obj;
};

export const createLinkEntity = (link) => {
  return {...link, entityType: 'link', entityId: link.id};
};

export const createRegionEntity = (region) => {
  return {...region, entityType: 'region', entityId: region.serverId};
};

export const filteredIssuesList = (issues, regions, sites) => {
  if ((!sites || !sites.length) && (!regions || !regions.length)) {
    return {
      total: issues.length,
      relevant: issues.length,
      filteredIssues: issues,
    };
  }

  if ((regions && regions.length) || (sites && sites.length)) {
    const regionServerIds = (regions || []).map((r) => r.serverId);
    const siteIds = [];
    const nodeIds = [];
    const cellIds = [];
    const interfaceIds = [];
    const linkIds = [];
    (sites || []).forEach((s) => {
      siteIds.push(s.serverId);

      s.nodes.forEach((n) => {
        nodeIds.push(n.id);
        interfaceIds.push(...n.interfaces.map((inter) => inter.id));
      });

      s.cells.leftWing.forEach((c) => {
        cellIds.push(c.id);
      });
      s.cells.rightWing.forEach((c) => {
        cellIds.push(c.id);
      });
      s.cells.topWing.forEach((c) => {
        cellIds.push(c.id);
      });

      s.links.forEach((l) => {
        linkIds.push(l.id);
      });
    });

    const filIssues = issues.filter((issue) => {
      let pass = false;

      if (intersection(regionServerIds, issue.entityMapping.REGION).length) {
        pass = true;
      }

      if (intersection(siteIds, issue.entityMapping.SITE).length) {
        pass = true;
      }

      if (intersection(nodeIds, issue.entityMapping.NODE).length) {
        pass = true;
      }

      if (intersection(cellIds, issue.entityMapping.CELL).length) {
        pass = true;
      }

      if (intersection(interfaceIds, issue.entityMapping.INTERFACE).length) {
        pass = true;
      }

      if (intersection(linkIds, issue.entityMapping.LINK).length) {
        pass = true;
      }

      return pass;
    });

    return {
      total: issues.length,
      relevant: filIssues.length,
      filteredIssues: filIssues,
    };
  }

  return {
    total: issues.length,
    relevant: issues.length,
    filteredIssues: issues,
  };
};
