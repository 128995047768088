// @flow
import React from 'react';
import {Box} from '@material-ui/core';
import moment from 'moment';
import {getCorrectTimezoneName, getFormattedMDH} from 'common/utils/dateService';
import './UserEvents.module.scss';

const UserEventsTooltipTemplate = (props: {event: Object, resolution: string, timeZoneName: string}) => {
  const getDate = () => {
    const momentDate = moment.unix(props.event.date);
    const timeZone = getCorrectTimezoneName(props.timeZoneName);

    if (props.resolution === 'weekly' || props.resolution === 'longlong') {
      return momentDate.tz(timeZone).format('MMM D, YYYY');
    }

    return getFormattedMDH(props.event.date, timeZone);
  };

  return (
    <Box
      borderRadius="6px"
      bgcolor="rgba(255, 255, 255, 0.95)"
      p={1}
      css={{
        boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.2)',
      }}
      fontSize={14}
      color="gray.500"
      fontWeight={500}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {props.event.totalEvents === 1 ? (
        <React.Fragment>
          <span styleName="events-name">{props.event.topEvents[0].title}</span>
          <span styleName="events-date">{getDate()}</span>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <span styleName="events-name">{`${props.event.totalEvents} Events`}</span>
        </React.Fragment>
      )}
    </Box>
  );
};

export default UserEventsTooltipTemplate;
