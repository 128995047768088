// @flow
import React, {Fragment, PureComponent} from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import * as commonSelectors from 'profile/store/selectors';
import * as dateRangeService from 'common/utils/dateRangeService';
import {setSelectedStreamKeyVal as setSelectedStreamKeyValAction} from 'bc/store/actions';
import {getNotificationDelay, pollingIntervals, getMissingHours} from 'bc/services/dataStreamService';
import {bcTypes, isMaxBackFillIntervalsShouldBePresent} from 'bc/services/bcTypes';
import {getAggrIntervalLabel} from 'bc/services/coralogixService';
import './SchedularData.module.scss';

type PropTypes = {
  dataStream: Object,
  timeZoneName: String,
  setSelectedStreamKeyVal: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    timeZoneName: commonSelectors.getTimeZoneName(state),
  }),
  {
    setSelectedStreamKeyVal: setSelectedStreamKeyValAction,
  },
)
export default class SchedularData extends PureComponent {
  props: PropTypes;

  constructor(props) {
    super(props);
    const {dataStream, setSelectedStreamKeyVal} = this.props;

    if (
      dataStream &&
      !(dataStream.maxBackFillIntervals >= 0) &&
      isMaxBackFillIntervalsShouldBePresent(dataStream.type)
    ) {
      setSelectedStreamKeyVal({maxBackFillIntervals: 0});
    }
  }

  render() {
    const {dataStream, timeZoneName} = this.props;
    const historicalTimeZone = dataStream.timeZone || timeZoneName;

    return (
      <Fragment>
        {![bcTypes.kinesis.type, bcTypes.eventhubs.type].includes(dataStream.type) ? (
          <div>
            <span styleName="title">Collect Every</span>
            <span styleName="data">{pollingIntervals[dataStream.pollingInterval].labelShort}</span>
          </div>
        ) : null}

        {dataStream.delayMinutes >= 0 && dataStream.type !== bcTypes.coralogix.type ? (
          <div>
            <span styleName="title">Delay (Minutes)</span>
            <span styleName="data">{dataStream.delayMinutes}</span>
          </div>
        ) : null}
        {dataStream.maxBackFillIntervals >= 0 ? (
          <div>
            <span styleName="title">Backfill Policy</span>
            <span styleName="data">{dataStream.maxBackFillIntervals}</span>
          </div>
        ) : null}
        {dataStream.maxMissingFiles >= 0 ? (
          <div>
            <span styleName="title">Lagging Files Policy</span>
            <span styleName="data">{dataStream.maxMissingFiles}</span>
          </div>
        ) : null}
        {dataStream.timeZone ? (
          <div>
            <span styleName="title">Time Zone</span>
            <span styleName="data">{dataStream.timeZone}</span>
          </div>
        ) : null}
        {dataStream.historicalDateRange ? (
          <div>
            <span styleName="title">Collect Since</span>
            <span styleName="data">
              {dateRangeService.getDate(dataStream.historicalDateRange, historicalTimeZone).dateText}
            </span>
          </div>
        ) : null}
        {dataStream.aggrInterval ? (
          <div>
            <span styleName="title">Bucketing Options</span>
            <span styleName="data">{getAggrIntervalLabel(dataStream.aggrInterval)}</span>
          </div>
        ) : null}
        {[bcTypes.s3.type, bcTypes.coralogix.type].includes(dataStream.type) ? (
          <div>
            <span styleName="title">No data notification after:</span>
            <span styleName="data">{getNotificationDelay(dataStream.notificationDelay)}</span>
          </div>
        ) : null}
        {[bcTypes.coralogix.type].includes(dataStream.type) ? (
          <div>
            <span styleName="title">Fill missing data for:</span>
            <span styleName="data">{getMissingHours(dataStream.fillMissingHours)}</span>
          </div>
        ) : null}

        {dataStream.type === bcTypes.google_ads.type && dataStream.pollingInterval !== 'daily' ? (
          <div>
            <span styleName="title">Daily Accumulation:</span>
            <span styleName="data">{dataStream.notificationDelay === true ? 'Yes' : 'No'}</span>
          </div>
        ) : null}
      </Fragment>
    );
  }
}
