// @flow
import React from 'react';
import {connect} from 'react-redux';
import {getTypeDetails} from 'bc/services/bcTypes';
import * as selectors from 'bc/store/selectors';
import * as actions from 'bc/store/actions';
import DataSourceModal from '../../DataSourceModal';
import '../../DataSourceModal.module.scss';

@connect(
  (state) => ({
    isLoading: selectors.getAddDataSourceState(state).isLoading,
    sourceError: selectors.getAddDataSourceState(state).error,
  }),
  {
    createDataSource: actions.createDataSource,
    updateDataSource: actions.updateDataSource,
    resetAddSourceError: actions.resetAddSourceError,
  },
)
export default class DatabricksSourceModal extends React.PureComponent {
  props: {
    isOpen: boolean,
    isViewOnly: boolean,
    isEditable: boolean,
    source: Object,
    sourceType: Object,
    isLoading: boolean,
    sourceError: Object,
    onClose: Function,
    createDataSource: Function,
    resetAddSourceError: Function,
  };

  // eslint-disable-next-line no-extra-boolean-cast
  state = !!this.props.source
    ? {
        dbHost: this.props.source.dbHost,
        dbPort: this.props.source.dbPort,
        dbName: this.props.source.dbName,
        userName: this.props.source.userName,
        userPassword: this.props.source.userPassword,
        httpPath: this.props.source.httpPath,
        useSSL: this.props.source.useSSL,
        verifyServerCertificate: this.props.source.verifyServerCertificate,
      }
    : {
        dbHost: '',
        dbPort: this.props.sourceType.defaultPort,
        dbName: 'default',
        userName: 'token',
        userPassword: '',
        httpPath: '',
        useSSL: true,
        verifyServerCertificate: false,
      };

  valueChanged = (val, inputId) => {
    const newState = {};
    newState[inputId] = val;
    this.setState(newState);
  };

  createBtnClicked = () => {
    const {sourceType, createDataSource} = this.props;
    createDataSource({
      type: getTypeDetails(sourceType.type).type,
      name: `${getTypeDetails(sourceType.type).shortName} ${
        this.state.dbName
      } Data Source ${new Date().getTime().toString()}`,
      dbHost: this.state.dbHost,
      dbPort: this.state.dbPort,
      dbName: this.state.dbName,
      userName: this.state.userName,
      userPassword: this.state.userPassword,
      httpPath: this.state.httpPath,
      useSSL: this.state.useSSL,
      verifyServerCertificate: this.state.verifyServerCertificate,
    });
  };

  isContinueDisabled = () => {
    const {dbHost, dbPort, dbName, userName, userPassword, httpPath} = this.state;
    return !dbHost || !dbPort || !dbName || !userName || !userPassword || !httpPath;
  };

  onCloseInternal = () => {
    this.props.resetAddSourceError();
    this.props.onClose();
  };

  render() {
    const {isOpen, isLoading, sourceError, source, sourceType, isViewOnly, isEditable} = this.props;
    const {dbHost, dbPort, userName, userPassword, httpPath, useSSL} = this.state;

    return (
      <DataSourceModal
        isOpen={isOpen}
        isContinueDisabled={this.isContinueDisabled()}
        isProcessing={isLoading}
        isViewOnly={isViewOnly}
        isRenameEnabled={isEditable}
        source={source}
        sourceType={sourceType}
        logoImageClass="source-logo-databricks"
        onClose={this.onCloseInternal}
        onContinue={this.createBtnClicked}
      >
        <div styleName="inputs-wrapper">
          <input
            type="text"
            automation-id="dataCollectorDatabricksServerLocationTextbox"
            onChange={(e) => this.valueChanged(e.target.value, 'dbHost')}
            placeholder="Host"
            readOnly={isViewOnly}
            disabled={isViewOnly}
            value={dbHost}
          />

          <input
            type="text"
            automation-id="dataCollectorDatabricksPostTextbox"
            onChange={(e) => this.valueChanged(e.target.value, 'dbPort')}
            placeholder="Port"
            readOnly={isViewOnly}
            disabled={isViewOnly}
            value={dbPort}
          />

          <input
            type="text"
            automation-id="dataCollectorDatabricksUserTextbox"
            onChange={(e) => this.valueChanged(e.target.value, 'userName')}
            placeholder="User"
            readOnly="true"
            disabled={isViewOnly}
            value={userName}
          />

          <input
            type="password"
            automation-id="dataCollectorDatabricksPasswordTextbox"
            onChange={(e) => this.valueChanged(e.target.value, 'userPassword')}
            autoComplete="new-password"
            placeholder="Password"
            readOnly={isViewOnly}
            disabled={isViewOnly}
            value={userPassword}
          />

          <input
            type="text"
            automation-id="dataCollectorDatabricksPathTextbox"
            onChange={(e) => this.valueChanged(e.target.value, 'httpPath')}
            placeholder="HTTP Path (JDBC URL)"
            readOnly={isViewOnly}
            disabled={isViewOnly}
            value={httpPath}
          />

          <div styleName="checkboxs-wrapper">
            <div className="cb2">
              <input
                type="checkbox"
                automation-id="dataCollectorSqlSslCheckbox"
                name="useSSL"
                id="useSSL"
                checked={useSSL}
                readOnly={isViewOnly}
                disabled={isViewOnly}
                onChange={(e) => this.valueChanged(e.target.checked, 'useSSL')}
              />
              <label htmlFor="useSSL">Use SSL</label>
            </div>
          </div>

          {!isLoading && sourceError ? (
            <div styleName="error-wrapper">
              <span styleName="error-message">
                <i className="icon icn-warning-icon" />{' '}
                {sourceError.uMessage || 'Could not get list of databases, check the settings and try again'}
              </span>
            </div>
          ) : null}

          <div styleName="extra-info">
            <span>* Add</span>
            <a
              href="https://support.anodot.com/hc/en-us/articles/360002844454"
              rel="noopener noreferrer"
              target="_blank"
            >
              Anodot server IPs
            </a>
            <span>to the firewall allowlist to enable access to the db.</span>
          </div>
        </div>
      </DataSourceModal>
    );
  }
}
