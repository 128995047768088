// @flow
import React, {Fragment} from 'react';
import {useField} from 'react-final-form';
import Box from '@material-ui/core/Box';
import Input from 'common/componentsV2/Input';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';

import SubTitle from '../SubTitle';

type PropsType = {
  email: string,
};

const isRequired = (val) => val === undefined;

const PersonalInfoSettings = ({email}: PropsType) => {
  const {
    input: {value: firstName, onChange: handleChangeFirstName},
    meta: {invalid: validateFirstName, touched: touchedFirstName},
  } = useField('firstName', {
    validate: isRequired,
    formatOnBlur: true,
    format: (val) => val.trim(),
  });
  const {
    input: {value: lastName, onChange: handleChangeLastName},
    meta: {invalid: validateLastName, touched: touchedLastName},
  } = useField('lastName', {
    validate: isRequired,
    formatOnBlur: true,
    format: (val) => val.trim(),
  });
  return (
    <Fragment>
      <SubTitle title="Your information" />
      <Box display="flex" mb={3}>
        <Box mr={1.5}>
          <Box mb={1}>First Name</Box>
          <Input
            isInvalid={touchedFirstName && validateFirstName}
            value={firstName}
            onChange={handleChangeFirstName}
            invalidMessage="First name is required"
          />
        </Box>
        <Box>
          <Box mb={1}>Last Name</Box>
          <Input
            isInvalid={touchedLastName && validateLastName}
            value={lastName}
            onChange={handleChangeLastName}
            invalidMessage="Last name is required"
          />
        </Box>
      </Box>
      <Box width={200} mb={7}>
        <Box mb={1}>Email Adress</Box>
        <Tooltip type={TYPES.SMALL} content="To change email address, contact your admin.">
          <div>
            <Input value={email} placeHolder="Email" isDisabled />
          </div>
        </Tooltip>
      </Box>
    </Fragment>
  );
};

export default PersonalInfoSettings;
