import React, {useMemo} from 'react';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {OPTION_TILES_VALUES} from 'dashboards/services/dashboardService';
import {getIsDashboardOwnerSelectorFactory} from 'dashboards/store/selectors';

const WarningNavigationIcon = ({tileId, compositeErrorsMap}: {tileId: string, compositeErrorsMap: Object}) => {
  const {url: matchUrl, params} = useRouteMatch('/dashboards/:id');
  const history = useHistory();
  const isOwnerUserSelector = useMemo(() => getIsDashboardOwnerSelectorFactory(params.id), [params.id]);
  const isOwnerUser = useSelector(isOwnerUserSelector);

  const onWarningClick = useMemo(
    () =>
      isOwnerUser
        ? () =>
            history.push(
              `${matchUrl}/${tileId}/${OPTION_TILES_VALUES.SETTINGS}${history.location.search}&selectedExpressionId=${
                Object.keys(compositeErrorsMap)[0]
              }`,
            )
        : undefined,
    [history, matchUrl, tileId, compositeErrorsMap],
  );

  return (
    <Tooltip content="An expression in this tile returns no metrics" type={TYPES.SMALL} placement="top">
      <i className="icon icn-general16-warning" onClick={onWarningClick} />
    </Tooltip>
  );
};

export default WarningNavigationIcon;
