// @flow
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Highcharts from 'highcharts';
import {get} from 'lodash';
import {Box} from '@material-ui/core';
import TimeSeriesWithEventsChart from 'charts/timeSeries/components/TimeSeriesWithEventsChart';
import {fetchAlertMetricDataPoints} from 'investigation/store/actions';
import {getInvModalTrigger, getMetricList} from 'investigation/store/selectors';
import {getBucketStartTimeEnabled} from 'profile/store/selectors';
import {getMetricResolutions} from 'metrics/store/selectors';
import {generateChartSeriesMetricModel, processSeriesData} from 'charts/timeSeries/services/timeSeriesDataService';
import {pushSeries, endLoad, startLoad} from 'charts/timeSeries/services/timeSeriesHchartService';
import './MetricsTable.module.scss';

const TOOLTIP_OPTIONS = {
  showMetricName: false,
  showAnomalyData: true,
};

type PropTypes = {
  metric: Object,
  timeZoneName: String,
};

const MetricsTableExpandablePanel = ({metric, timeZoneName}: PropTypes) => {
  const dispatch = useDispatch();
  const trigger = useSelector(getInvModalTrigger);
  const startBucketMode = useSelector(getBucketStartTimeEnabled);
  const resolutions = useSelector(getMetricResolutions);
  const metricList = useSelector(getMetricList);

  const chartEventsMeta = {
    key: `investigation_modal_${metric.id}`,
    chartId: `investigstion.modal.chart_${metric.id}`,
  };

  useEffect(() => {
    const startDate = metric.bufferedStartTime;
    const endDate = metric.bufferedEndTime;

    dispatch(
      fetchAlertMetricDataPoints(
        {
          metricId: metric.id,
          startDate,
          endDate,
          startBucketMode,
          includeBaseline: true,
          timeScale: Object.values(resolutions).find((a) => a.value2 === trigger.timeScale).value,
        },
        {
          ...chartEventsMeta,
          metricId: metric.id,
          alertId: trigger.id,
        },
      ),
    );
  }, []);

  useEffect(() => {
    const hchart = Highcharts.charts.find((chart) => chart && chart.renderTo.id === chartEventsMeta.chartId);
    const selMetric = metricList[chartEventsMeta.key];
    const selMetricData = get(selMetric, 'data.metrics[0]', null);

    if (selMetric && selMetric.isLoading) {
      startLoad(hchart);
    }

    if (selMetricData && !hchart.series.length) {
      const metricsModel = generateChartSeriesMetricModel(metric, undefined, 0);
      const processedSeriesData = processSeriesData(
        selMetricData.dataPoints,
        selMetricData.baseline,
        metric.intervals || metric.currentAnomalyIntervals,
        null,
      );

      pushSeries(hchart, {byTreeExp: []}, metricsModel, processedSeriesData, true);

      endLoad(hchart, metric.bufferedStartTime, metric.bufferedEndTime);
    }
  }, [metricList, metric]);

  return (
    <Box styleName="container">
      <TimeSeriesWithEventsChart
        disableActions
        id={chartEventsMeta.chartId}
        eventsMeta={chartEventsMeta}
        height={230}
        bucketStartTimeEnabled={startBucketMode}
        timeScale={trigger.timeScale}
        timeZoneName={timeZoneName}
        enableClientZoom
        tooltip={TOOLTIP_OPTIONS}
        chartContainerStyle={{height: '230px', width: '100%'}}
      />
    </Box>
  );
};

export default MetricsTableExpandablePanel;
