// @flow
import React from 'react';
import {connect} from 'react-redux';
import {getTypeDetails} from 'bc/services/bcTypes';
import * as selectors from 'bc/store/selectors';
import {createDataSource, resetAddSourceError} from 'bc/store/actions';
import DataSourceModal from '../../DataSourceModal';

import '../../DataSourceModal.module.scss';

type PropTypes = {
  isOpen: boolean,
  isViewOnly: boolean,
  isEditable: boolean,
  sourceType: Object,
  source: Object,
  onClose: Function,

  // connect
  isLoading: boolean,
  sourceError: Object,

  createDataSource: Function,
  resetAddSourceError: Function,
};

@connect(
  (state) => ({
    isLoading: selectors.getAddDataSourceState(state).isLoading,
    sourceError: selectors.getAddDataSourceState(state).error,
  }),
  {
    createDataSource,
    resetAddSourceError,
  },
)
export default class EventhubsSourceModal extends React.PureComponent {
  props: PropTypes;

  // eslint-disable-next-line no-extra-boolean-cast
  state = !!this.props.source
    ? {
        namespace: this.props.source.namespace,
        keyName: this.props.source.keyName,
        accessKey: this.props.source.accessKey,
        hubName: this.props.source.hubName,
        consumerGroup: this.props.source.consumerGroup,
      }
    : {
        namespace: '',
        keyName: '',
        accessKey: '',
        hubName: '',
        consumerGroup: '',
      };

  valueChanged = (val, inputId) => {
    const newState = {};
    newState[inputId] = val;
    this.setState(newState);
  };

  createBtnClicked = () => {
    const obj = {
      type: getTypeDetails(this.props.sourceType.type).type,
      name: `${getTypeDetails(this.props.sourceType.type).shortName} Data Source ${new Date().getTime().toString()}`,
      namespace: this.state.namespace,
      keyName: this.state.keyName,
      accessKey: this.state.accessKey,
      hubName: this.state.hubName,
    };
    if (this.state.consumerGroup) {
      obj.consumerGroup = this.state.consumerGroup;
    }
    this.props.createDataSource(obj);
  };

  isContinueDisabled = () => {
    const {namespace, keyName, accessKey, hubName} = this.state;
    return !namespace || !keyName || !accessKey || !hubName;
  };

  onClose = () => {
    this.props.resetAddSourceError();
    this.props.onClose();
  };

  render() {
    const {isOpen, source, sourceType, isLoading, sourceError, isViewOnly, isEditable} = this.props;
    const {namespace, keyName, accessKey, hubName, consumerGroup} = this.state;

    return (
      <DataSourceModal
        isOpen={isOpen}
        isProcessing={isLoading}
        isViewOnly={isViewOnly}
        isRenameEnabled={isEditable}
        source={source}
        sourceType={sourceType}
        logoImageClass={`source-logo-${sourceType.type}`}
        onClose={this.onClose}
        isContinueDisabled={this.isContinueDisabled()}
        onContinue={this.createBtnClicked}
      >
        <div styleName="inputs-wrapper">
          <input
            type="text"
            automation-id="eventHubsNamespace"
            onChange={(e) => this.valueChanged(e.target.value, 'namespace')}
            placeholder="namespace"
            readOnly={isViewOnly}
            disabled={isViewOnly}
            value={namespace}
          />

          <input
            type="text"
            automation-id="eventHubsKeyName"
            onChange={(e) => this.valueChanged(e.target.value, 'keyName')}
            placeholder="keyName"
            readOnly={isViewOnly}
            disabled={isViewOnly}
            value={keyName}
          />

          <input
            type="text"
            automation-id="eventHubsAccessKey"
            onChange={(e) => this.valueChanged(e.target.value, 'accessKey')}
            placeholder="accessKey"
            readOnly={isViewOnly}
            disabled={isViewOnly}
            value={accessKey}
          />

          <input
            type="text"
            automation-id="eventHubsHubName"
            onChange={(e) => this.valueChanged(e.target.value, 'hubName')}
            placeholder="hubName (Optional)"
            readOnly={isViewOnly}
            disabled={isViewOnly}
            value={hubName}
          />

          <input
            type="text"
            automation-id="eventHubsConsumerGroup"
            onChange={(e) => this.valueChanged(e.target.value, 'consumerGroup')}
            placeholder="consumerGroup (Optional)"
            readOnly={isViewOnly}
            disabled={isViewOnly}
            value={consumerGroup}
          />

          {!isLoading && sourceError ? (
            <div styleName="error-wrapper error-wrapper-s3">
              <span styleName="error-message">
                <i className="icon icn-warning-icon" /> {sourceError.uMessage}
              </span>
            </div>
          ) : null}

          <div styleName="extra-info">
            <a
              href="https://support.anodot.com/hc/en-us/articles/4405605205138"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more
            </a>
          </div>
        </div>
      </DataSourceModal>
    );
  }
}
