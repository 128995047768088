// @flow
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {palette} from 'app/styles/theme';
import {getBadgeColor, MAP_ITEMS_COLOR_PALETTE} from 'topologyGeneral/services/mapService';

const useStyles = makeStyles(() => ({
  locItemRoot: {
    backgroundColor: palette.white['500'],
    border: `1px solid ${palette.gray['200']}`,
    borderRadius: '6px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.blue['50'],
      borderColor: palette.blue['300'],
    },
  },
  levelGen: {
    height: '37px',
    width: '37px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 1,
    fontSize: '14px',
    fontWeight: 400,
    color: palette.white['500'],
  },
  selected: {
    backgroundColor: palette.blue['50'],
    borderColor: palette.blue['300'],
  },
}));

const LocationsItem = ({item, isSelected, badgeColor, onClick}: PropTypes) => {
  const classes = useStyles();

  const onClickLocale = () => {
    if (onClick) {
      onClick(item);
    }
  };

  return (
    <Box
      height={80}
      mb={1}
      width="100%"
      display="flex"
      onClick={onClickLocale}
      className={`${classes.locItemRoot} ${isSelected ? classes.selected : ''}`}
    >
      <Box width={68} display="flex" pl={2} alignItems="center">
        <Box className={`${classes.levelGen}`} bgcolor={badgeColor}>{`${item.percent}%`}</Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" flexGrow={1} pr={2.5}>
        <Box display="flex" flexDirection="column">
          <Box fontSize="14px" fontWeight={400} color="gray.500" lineHeight={1} component="span">
            {item.title}
          </Box>
          <Box fontSize="14px" fontWeight={400} color="gray.400" lineHeight={1} component="span" mt={0.5}>
            {`${item.nodesTotal.toLocaleString()} Nodes`}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

LocationsItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  isSelected: PropTypes.bool,
  badgeColor: PropTypes.string,
  onClick: PropTypes.func,
};

LocationsItem.defaultProps = {
  onClick: null,
  isSelected: false,
  badgeColor: MAP_ITEMS_COLOR_PALETTE.green,
};

const LocationsList = ({items, selectedItemId, onItemClick}) => {
  return (
    <Fragment>
      {items.map((item) => (
        <LocationsItem
          item={item}
          badgeColor={getBadgeColor(item.percent)}
          isSelected={item.id === selectedItemId}
          onClick={onItemClick}
          key={`loc-item-${item.id}`}
        />
      ))}
    </Fragment>
  );
};

LocationsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  onItemClick: PropTypes.func.isRequired,
  selectedItemId: PropTypes.string,
};

LocationsList.defaultProps = {
  selectedItemId: undefined,
};

export default LocationsList;
