// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import DataSourceTypes from 'bc/components/dataSourceTypes/DataSourceTypes';
import {setDataSourceTypeFilterTextInput as setDataSourceTypeFilterTextInputAction} from 'bc/store/actions';
import SearchBox from 'common/components/SearchBox';
import './AddDataSource.module.scss';
import {Link} from 'react-router-dom';

type PropTypes = {
  filterTextInput: String,
  setDataSourceTypeFilterTextInput: Function,
};

@connect(
  (state) => ({
    filterTextInput: state.bc.dataSourceTypes.filterTextInput,
  }),
  {
    setDataSourceTypeFilterTextInput: setDataSourceTypeFilterTextInputAction,
  },
)
export default class AddDataSource extends PureComponent {
  props: PropTypes;

  render() {
    const {setDataSourceTypeFilterTextInput, filterTextInput} = this.props;
    return (
      <div styleName="root" className="navbar-left-margin">
        <Link styleName="top back" to="/bc/data-manager">
          <i className="icon icn-arrow16-arrowleft" />
          Back
        </Link>

        <div styleName="top">
          <div className="shell">
            <div className="andt-page-header">Source Types</div>
          </div>
          <div>
            <SearchBox filter={filterTextInput} isAutoStretch onFilter={setDataSourceTypeFilterTextInput} />
          </div>
        </div>
        <div styleName="master-detail">
          <DataSourceTypes />
        </div>
      </div>
    );
  }
}
