// @flow
import React, {useCallback, useMemo} from 'react';
import {get} from 'lodash';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import {getUsersGroupsListsWithoutDeleteGroup} from 'admin.users/store/selectors';
import {getProfileId, getUserProfile, isCustomerAdmin} from 'profile/store/selectors';
import Tooltip from 'common/componentsV2/Tooltip';
import {
  allowedUserTileActions,
  dashboardActions,
  getUserName,
  OPTION_TILES_VALUES,
  optionsIcons,
} from 'dashboards/services/dashboardService';
import {setDashboardData, setFavorite, setLastViewDashboard} from 'dashboards/store/actions';
import useV1LinkMaker from 'dashboards/hooks/useV1LinkMaker';
import Highlighter from 'react-highlight-words';
import {COLORS_PICKER} from 'common/componentsV2/ColorPicker';
import ThreeDotsDropDown from 'common/componentsV2/ddl/ThreeDotsDropDown';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    borderRadius: 6,
    backgroundColor: theme.palette.gray[100],
    cursor: 'pointer',
    marginBottom: 50,
    marginLeft: 20,
    '&:hover $hoverRow': {
      visibility: 'visible',
    },
    '&:hover $actions': {
      visibility: 'visible',
    },
  },
  link: {
    display: 'block',
    padding: '8px 16px 12px 24px',
  },
  iconWrapper: {
    position: 'absolute',
    left: 20,
    top: -30,
    width: 60,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    backgroundColor: ({dashboard}) =>
      theme.palette[COLORS_PICKER[dashboard.color || 'gray'] ? COLORS_PICKER[dashboard.color || 'gray'].color : 'gray'][
        COLORS_PICKER[dashboard.color || 'gray'] ? COLORS_PICKER[dashboard.color || 'gray'].contrast : 500
      ],
  },
  title: {
    height: 48,
    marginTop: 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  label: {
    fontSize: '14px',
    color: theme.palette.gray[500],
    maxWidth: 80,
    lineHeight: '18px',
    backgroundColor: theme.palette.gray[300],
    borderRadius: 2,
    padding: '0 4px',
    marginRight: 4,
    '&:last-child': {
      marginRight: 0,
    },
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  hoverRow: {
    display: 'flex',
    justifyContent: 'space-between',
    visibility: 'hidden',
    flexGrow: 1,
    height: 18,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 8,
    top: 8,
    visibility: 'hidden',
  },
  dashboardName: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#26363e',
  },
  userNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.gray[400],
    paddingLeft: '64px',
  },
  iconBox: {
    fill: theme.palette.white[500],
    width: '30px',
    height: '30px',
  },
  userNameText: {
    fontWeight: 400,
    fontSize: '14px',
    color: theme.palette.gray[400],
    lineHeight: '14px',
    marginLeft: '4px',
  },
}));

const CustomLink = ({
  children,
  isV1,
  id,
  className,
  userId,
}: {
  children: React.Node,
  isV1: boolean,
  id: string,
  className: Object,
  userId: string,
}) => {
  const dispatch = useDispatch();
  const makeV1Link = useV1LinkMaker();
  const v1Url = useMemo(() => makeV1Link(id), [id]);

  if (isV1) {
    return (
      <a
        href={v1Url}
        className={className}
        onClick={() => dispatch(setLastViewDashboard({type: 'dashboardsV2', itemId: id, data: {}}, {userId}))}
      >
        {children}
      </a>
    );
  }

  return (
    <Link to={`/dashboards/${id}`} className={className}>
      {children}
    </Link>
  );
};

const LabelItem = React.memo(({dashboard, label, length}: {dashboard: Object, label: string, length: number}) => {
  const classes = useStyles({dashboard});
  return (
    <div className={`${classes.label} ${classes.ellipsis}`}>
      {length ? (
        <Tooltip
          content={dashboard.tags.map((item, index) => {
            if (index >= 2) {
              return (
                <div key={item.id} className={classes.ellipsis}>
                  {item.display}
                </div>
              );
            }
            return '';
          })}
        >
          <div>{`+${length - 2}`}</div>
        </Tooltip>
      ) : (
        <Tooltip content={label.display}>
          <div className={classes.ellipsis}>{label.display}</div>
        </Tooltip>
      )}
    </div>
  );
});

// eslint-disable-next-line react/no-multi-comp
const DashboardItem = React.memo(({dashboard, searchQuery}: {dashboard: Object, searchQuery: string}) => {
  const dispatch = useDispatch();
  const classes = useStyles({dashboard});
  const history = useHistory();
  const userGroupList = useSelector(getUsersGroupsListsWithoutDeleteGroup);
  const userData = useSelector(getUserProfile);
  const isCustomerAdminSelector = useSelector(isCustomerAdmin);
  const userId = useSelector(getProfileId);
  const isFavorite = dashboard.star.includes(userId);
  const isGroup = (userGroupList.find((user) => dashboard.ownerUser === user.value) || {}).type === 'GROUPS';
  const isOwnerUser =
    dashboard &&
    (userId === dashboard.ownerUser || userData.groups.includes(dashboard.ownerUser) || isCustomerAdminSelector);
  const {isV1} = dashboard;
  const computedTileOptions = useMemo(
    () =>
      dashboardActions
        .filter(
          (a) =>
            (isV1 || a.value !== OPTION_TILES_VALUES.CONVERT) &&
            a.value !== OPTION_TILES_VALUES.REVERT &&
            a.value !== OPTION_TILES_VALUES.IMPORT,
        )
        .map((option) => {
          if (!isOwnerUser && !allowedUserTileActions.includes(option.value)) {
            return {...option, disabled: true};
          }

          return option;
        }),
    [isOwnerUser],
  );

  const handleFavorite = useCallback(() => {
    const body = {
      star: !isFavorite,
      user: userId,
    };
    dispatch(setFavorite(body, {dashboardId: dashboard._id, isV1}));
  }, [isV1, dashboard, isFavorite, userId]);

  const handleChangeDashboardSettings = useCallback(
    (option) => {
      dispatch(setDashboardData(dashboard));
      history.push(`/dashboards/${option.value}/${dashboard._id}${history.location.search}`);
    },
    [dashboard],
  );

  const IconElement = useMemo(
    () => get(optionsIcons.find((option) => dashboard.icon === option.value), 'icon') || optionsIcons[0].icon,
    [dashboard],
  );

  const userName = useMemo(() => getUserName(userGroupList, dashboard.ownerUser), [userGroupList, dashboard]);
  const userNameFull = useMemo(() => getUserName(userGroupList, dashboard.ownerUser, true), [userGroupList, dashboard]);

  return (
    <div key={dashboard._id} className={classes.wrapper}>
      <CustomLink isV1={isV1} id={dashboard._id} className={classes.link} userId={userId}>
        <div className="display_flex alignItems_center">
          <div className={classes.iconWrapper}>
            <div className={classes.iconBox}>
              <IconElement />
            </div>
          </div>
          <div className={classes.hoverRow}>
            <div className={classes.userNameWrapper}>
              <i className={`icon ${isGroup ? 'icn-general16-group' : 'icn-general16-user'}`} />
              <Tooltip content={userNameFull}>
                <div className={classes.userNameText}>{userName}</div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={classes.title}>
          <div className={classes.dashboardName}>
            <Highlighter autoEscape searchWords={[searchQuery]} textToHighlight={dashboard.name} />
          </div>
        </div>
        <div className="display_flex mt_3 height_18">
          {dashboard.tags.map((label, index) => {
            if (index < 2) {
              return <LabelItem key={label.id} label={label} dashboard={dashboard} />;
            }
            if (index === 2) {
              return <LabelItem key={label.id} label={label} dashboard={dashboard} length={dashboard.tags.length} />;
            }
            return null;
          })}
        </div>
      </CustomLink>
      <div className={classes.actions}>
        <Tooltip content={isFavorite ? 'Remove From Favorites' : 'Add To Favorites'}>
          <i
            className={`
                    icon
                    ${isFavorite ? 'icn-action16-favorite' : 'icn-action16-unfavorite'}
                  `}
            onClick={handleFavorite}
          />
        </Tooltip>
        <div className="ml_0-5">
          <ThreeDotsDropDown
            options={computedTileOptions}
            onChange={handleChangeDashboardSettings}
            menuWidth={isV1 ? 215 : 200}
          />
        </div>
      </div>
    </div>
  );
});

export default DashboardItem;
