// @flow
import React from 'react';
import {getTypeDetails} from 'bc/services/bcTypes';
import SelectAndt, {
  THEME_TRANSPARENT,
  TYPE_SIMPLE,
  DIRECTION_LEFT,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import SmartTooltip from 'common/components/SmartTooltip';
import './StreamListItem.module.scss';

type PropTypes = {
  name: String,
  id: String,
  type: String,
  isNameClickable: Boolean,
  children: Node,
  moreOptionsItems: Array,
  moreOptionsIsDisabled: Boolean,

  moreOptionsOnChange: Function,
  streamNameClicked: Function,
  hackStarter: Function,
  hasDimreduce: Boolean,
};

export default class StreamItemContainer extends React.PureComponent {
  props: PropTypes;

  render() {
    const {
      name,
      type,
      id,
      isNameClickable,
      children,
      moreOptionsItems,
      moreOptionsIsDisabled,
      moreOptionsOnChange,
      streamNameClicked,
      hackStarter,
      hasDimreduce,
    } = this.props;

    const streamNameStyle = isNameClickable ? 'stream-name running' : 'stream-name';

    return (
      <div key={`fade_${id}`}>
        <div styleName="root" automation-id="dataManagerStreamListItem">
          <div
            className={`image-${getTypeDetails(type).iconStyle}`}
            styleName="type-image"
            onClick={hackStarter}
            role="presentation"
          />
          <div className="shell-col" automation-id="dataManagerStreamListItemName" styleName="stream-name-wrapper">
            {isNameClickable ? (
              <span className="ellipsis" styleName={streamNameStyle} onClick={streamNameClicked} role="presentation">
                {name}
              </span>
            ) : (
              <span className="ellipsis" styleName={streamNameStyle} role="presentation">
                {name}
              </span>
            )}
          </div>

          {hasDimreduce ? (
            <SmartTooltip placement="top" content="Dimension to Measure mapping rules are applied on this stream.">
              <div styleName="dim-reduce" className="icon icn-general16-info" />
            </SmartTooltip>
          ) : null}

          {children}

          <div styleName="more-dropdown" automation-id="dataManagerStreamItemDropdown">
            <SelectAndt
              options={moreOptionsItems}
              disabled={moreOptionsIsDisabled}
              type={TYPE_SIMPLE}
              menuWidth={125}
              theme={THEME_TRANSPARENT}
              onChange={moreOptionsOnChange}
              direction={DIRECTION_LEFT}
              automationId="streamListItem"
            />
          </div>
        </div>
      </div>
    );
  }
}
