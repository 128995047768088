import {createSelector} from 'reselect';
import {get, omit} from 'lodash';
import {getCommon} from 'common/store/selectors';

export const getProfile = (state) => state.profile;

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

export const getProfileData = createSelector(
  getProfile,
  (profile) => profile.data || EMPTY_OBJECT,
);

export const getUserProfile = createSelector(
  getProfileData,
  (profile) => profile.me || EMPTY_OBJECT,
);

export const getIsImpersonate = createSelector(
  getUserProfile,
  (me) => me.impersonatorId,
);

export const getIsNewMetricExplorer = createSelector(
  getUserProfile,
  (me) => get(me, 'organization.serverConfiguration.featureFlags.featuresActivation.ENABLE_NEW_METRIC_EXPLORER', false),
);

export const getLeanUserProfile = createSelector(
  getUserProfile,
  (me) => {
    const leanMe = omit(me, ['imageSrc', '__v', 'aptrinsicHmac', 'profiles', 'createdAt', 'appSettings', 'disabled']);
    if (me.organization) {
      leanMe.organization = omit(me.organization, ['settings']);
    }
    return leanMe;
  },
);

export const getIsMeLoading = createSelector(
  getUserProfile,
  (me) => me.isLoading,
);

export const getMeOwnerOrganization = createSelector(
  getUserProfile,
  (me) => me.ownerOrganization,
);

export const getQueryParamsView = createSelector(
  getProfileData,
  (profile) => profile.queryParamsView,
);

export const getProfileId = createSelector(
  getUserProfile,
  (me) => me._id,
);

export const getFirstName = createSelector(
  getUserProfile,
  (me) => me.firstName,
);

export const getLastName = createSelector(
  getUserProfile,
  (me) => me.lastName,
);

export const getInvitationId = createSelector(
  getUserProfile,
  (me) => me.invitationId || null,
);

export const getOrganization = createSelector(
  getUserProfile,
  (me) => me.organization || EMPTY_OBJECT,
);

export const getTimelineUserEventsStartTimeThreshold = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.timelineUserEventsStartTimeThreshold', EMPTY_OBJECT),
);

export const getActiveSteamsLimit = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.bcActiveSteamsLimit'),
);

export const getIncompleteSteamsLimit = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.bcIncompleteSteamsLimit'),
);

export const getFirstDayOfWeek = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.firstDayOfWeek'),
);

export const getMinInfluencingMetricsConditionRollup = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.minInfluencingMetricsConditionRollup') || 'long',
);

export const getRetentionsValues = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.retentions'),
);

export const getOrganizationSettings = createSelector(
  getOrganization,
  (org) => org.settings || EMPTY_OBJECT,
);

export const getOrganizationFeatureFlags = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation') || EMPTY_OBJECT,
);

export const getAdvanceSearchModeDefault = createSelector(
  getOrganizationSettings,
  (orgSettings) => get(orgSettings, 'composite.useAdvanceSearchMode', null),
);

export const getOrganizationSettingsBC = createSelector(
  getOrganizationSettings,
  (orgSettings) => orgSettings.bc || EMPTY_OBJECT,
);

export const getIsLookupEnabled = createSelector(
  getOrganizationSettingsBC,
  getOrganizationFeatureFlags,
  (bc, featureFlags) => bc.collectors.lookup || featureFlags.BC_ENABLE_COLLECTOR_LOOKUP || false,
);

export const getIsGAdsHourlyEnabled = createSelector(
  getOrganizationSettingsBC,
  getOrganizationFeatureFlags,
  (bc, featureFlags) => featureFlags.BC_ENABLE_COLLECTOR_GADS_HOURLY || false,
);

export const getIsS3ShortIntervalEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.BC_S3_SHORT_POOLING_INTERVAL', false),
);

export const getIsGAShortIntervalEnabled = createSelector(
  getOrganization,
  (org) =>
    get(org, 'serverConfiguration.featureFlags.featuresActivation.BC_GOOGLE_ANALYTICS_SHORT_POOLING_INTERVAL', false),
);

export const getIsStreamOwnerEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.BC_ENABLE_STREAM_OWNER', false),
);

export const getIsOnPremNewStreamEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.BC_ENABLE_ONPREM_NEW_STREAM', false),
);

export const getIsTimelineEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_TIMELINE', false),
);

export const getOrganizationMetricsLimit = createSelector(
  getOrganization,
  (org) => org.numberOfMetrics || 50000, // also in customer.js in main app
);

export const getOrganizationNoDataMinDuration = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.noDataMinDuration', 20 * 60),
);

export const getOrganizationNoDataMinCompositeDuration = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.noDataMinCompositeDuration', 60 * 60),
);

export const getOrganizationMetricsLimitWithGrace = createSelector(
  getOrganization,
  getOrganizationMetricsLimit,
  (org, limit) => get(org, 'serverConfiguration.maxMetricsAllowed', limit * 1.2), // also in customer.js in main app
);

export const getOrganizationRollups = createSelector(
  getOrganization,
  getOrganizationMetricsLimit,
  (org) => get(org, 'serverConfiguration.rollups', EMPTY_ARRAY),
);

export const getIsAlertEnableAnomalyUpdate = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ALERTS_ENABLE_ANOMALY_UPDATE', false),
);

export const getIsEnableSimulationReferenceDate = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_SIMULATION_REFERENCE_DATE', false),
);

export const getSimulationMinDeltaEstimationEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.SIMULATION_ENABLE_MIN_DELTA_ESTIMATION', false),
);

export const getAlertConsoleEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ALERT_CONSOLE_ENABLE_CONSOLE', false),
);

export const getMetricsStreamCubes = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.METRICS_STREAM_CUBES', false),
);

export const getBcUnlimitedFileUploadTimeRange = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.BC_UNLIMITED_FILE_UPLOAD_TIME_RANGE', false),
);

export const getUseNewSimpleAlert = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_NEW_SIMPLE_ALERT', false),
);

export const getAutoVolumeEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.VOLUME_CONDITION_ENABLED', false),
);

export const getDeltaDurationEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_DELTA_DURATION', false),
);

export const getRegressorsEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_REGRESSORS', false),
);

export const getInfluencingNoDataEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_INFLUENCING_NODATA', false),
);

export const getInfluencingStaticEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_INFLUENCING_STATIC', false),
);

export const getLiveEditEnable = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_EDIT_LIVE_STREAM', false),
);

export const getInsightsPanelEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_INSIGHTS_PANEL', false),
);

export const getDashboardV2Enabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.DASHBOARD_ENABLE_V2', false),
);

export const getGraphColoringDashboardV2Enabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_GRAPH_COLORING', false),
);

export const getCreateTileFromAMEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_CREATE_TILE_FROM_AM', false),
);

export const getEnableNewMetricExplorer = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_NEW_METRIC_EXPLORER', false),
);

export const getEnableRefreshButtonMetricExplorer = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_REFRESH_BUTTON_METRIC_EXPLORER', false),
);

export const getInvestigationV2Enabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_INVESTIGATION_V2', false),
);

export const getFeaturesActivation = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.featureFlags.featuresActivation', EMPTY_OBJECT),
);

export const getTimeZoneName = createSelector(
  getUserProfile,
  (me) => get(me, 'appSettings.timeZone.name', null),
);

export const getShouldHideAdvanceMode = createSelector(
  getUserProfile,
  (me) => get(me, 'organization.settings.alertsConfiguration.shouldHideAdvanceMode'),
  (me) => get(me, 'organization.settings.alertsConfiguration.shouldHideAdvanceMode'),
);

export const getNewDataManagerEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_NEW_DATA_MANAGER', false),
);

export const getNewChannelsEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_NEW_CHANNELS', false),
);

export const getAlertManagerEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ALERTS_ENABLE_ALERTS_MANAGER_DISPLAY_V2', false),
);

export const getBucketStartTimeEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_BUCKET_START_TIME', false),
);

export const getStreamRenameEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_ORIGIN_INDEX', false),
);

export const getTopologyEnabled = createSelector(
  getOrganization,
  getIsImpersonate,
  (o, impersonator) => {
    if (impersonator) {
      return true;
    }
    return get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_TOPOLOGY', false);
  },
);

export const getImpactEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_IMPACT', false),
);

export const getDynamicRoutingEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_DYNAMIC_ROUTING', false),
);

export const getAssetsManagementEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ASSETS_MANAGEMENT_ENABLE', false),
);

export const getAlertActionsEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ALERT_ACTIONS_ENABLED', false),
);

export const getSlackappEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_SLACKAPP_CHANNEL', false),
);

export const getIntegrationsPageEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.INTEGRATION_PAGE_ENABLE', false),
);

export const getActivityLogEnabled = createSelector(
  getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ENABLE_AUDIT_LOG', false),
);

export const getMeAppSettings = createSelector(
  getUserProfile,
  (me) => me.appSettings || EMPTY_OBJECT,
);

export const getIsOnBoardingDismiss = createSelector(
  getMeAppSettings,
  (appSettings) => appSettings.isOnBoardingDismiss,
);

export const isReadOnlyUser = createSelector(
  getUserProfile,
  (me) => get(me, 'roles', null) && me.roles[0].indexOf('-read-only') !== -1,
);

export const isAnodot = createSelector(
  getUserProfile,
  (me) => get(me, 'roles', null) && me.roles[0].indexOf('anodot-') !== -1,
);

export const isAnodotUser = createSelector(
  getUserProfile,
  (me) => get(me, 'roles', null) && me.roles[0].indexOf('anodot-user') !== -1,
);

export const isAnodotAdmin = createSelector(
  getUserProfile,
  (me) => get(me, 'roles', null) && me.roles.includes('anodot-admin'),
);

export const getRoles = createSelector(() => [
  {role: 'anodot-admin', name: 'Anodot Admin'},
  {role: 'anodot-user', name: 'Anodot User'},
  {role: 'customer-admin', name: 'Admin'},
  {role: 'customer-user', name: 'User'},
  {role: 'customer-read-only', name: 'Read Only'},
]);

export const isCustomerAdmin = createSelector(
  getUserProfile,
  (me) => get(me, 'roles', null) && me.roles.includes('customer-admin'),
);

export const isCustomerUser = createSelector(
  getUserProfile,
  (me) => get(me, 'roles', null) && me.roles.includes('customer-user'),
);

export const getAllowedRoles = createSelector(
  getRoles,
  isAnodot,
  (allRoles, isAndt) => {
    if (isAndt) {
      return allRoles;
    }
    return allRoles.filter((r) => r.role.indexOf('customer-') !== -1);
  },
);

export const getRoleName = createSelector(
  getUserProfile,
  getRoles,
  (me, roles) => {
    const r = roles.find((i) => me.roles && i.role === me.roles[0]);
    return r && r.name;
  },
);

export const getPageVisited = createSelector(
  getMeAppSettings,
  (appSettings) => appSettings.pageVisited || [],
);

export const getShowMandatoryRenameModal = createSelector(
  getMeAppSettings,
  (appSettings) => get(appSettings, 'usersGroups.isRenameNeeded', false),
);

export const getIsUsersGroupsFiltersBarOpen = createSelector(
  getMeAppSettings,
  (appSettings) => get(appSettings, 'usersGroups.isFiltersBarOpen', true),
);

export const getIsCustomersFiltersBarOpen = createSelector(
  getMeAppSettings,
  (appSettings) => get(appSettings, 'customers.isFiltersBarOpen', true),
);

export const getIsAssetsFiltersBarOpen = createSelector(
  getMeAppSettings,
  (appSettings) => get(appSettings, 'assets.isFiltersBarOpen', true),
);

export const getFeatureOnBoarding = createSelector(
  getOrganization,
  getMeAppSettings,
  getShowMandatoryRenameModal,
  (org, appSettings, isRenameNeeded) => {
    const featureFlag = get(
      org,
      'serverConfiguration.featureFlags.featuresActivation.ENABLE_FIRST_TIME_USER_EXPERIENCE',
      false,
    );
    const {newToFtue} = appSettings;
    return featureFlag && newToFtue && !isRenameNeeded;
  },
);

export const getPagesVisited = createSelector(
  getMeAppSettings,
  (appSettings) => appSettings.pageVisited || EMPTY_ARRAY,
);

export const getPayingCustomer = createSelector(
  getOrganizationSettings,
  (i) => get(i, 'payingCustomer', false),
);

export const getSelfService = createSelector(
  getOrganizationSettings,
  (i) => get(i, 'sierra.selfService', false),
);

export const getSelfServicePackage = createSelector(
  getOrganizationSettings,
  (i) => get(i, 'sierra.selfServicePackage', false),
);

export const getInsightsPanel = createSelector(
  getMeAppSettings,
  (appSettings) => appSettings.insightsPanel || EMPTY_OBJECT,
);

export const getPredefinedDateUserSetting = createSelector(
  getMeAppSettings,
  (appSettings) => appSettings.predefinedDateSettings || EMPTY_OBJECT,
);

export const userSettingIsLoading = createSelector(
  getCommon,
  (common) => get(common, ['outstandingLoadingBarRequests', 'anodot/profile/UPDATE_USER_SETTINGS']),
);

export const getOrgTopologySetting = createSelector(
  getOrganizationSettings,
  (orgSettings) => orgSettings.topology || EMPTY_OBJECT,
);
