import React from 'react';
import {useField} from 'react-final-form';
import Input from 'common/componentsV2/Input';

const additionalProps = {
  autocomplete: 'off',
};

const User = () => {
  const {
    input: {value, onChange},
    meta,
  } = useField('user');
  return (
    <Input
      additionalProps={additionalProps}
      placeHolder="User"
      automationId="user"
      isInvalid={meta.touched && meta.error}
      invalidMessage={meta.error}
      value={value}
      onChange={onChange}
    />
  );
};

export default User;
