import React, {useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useField} from 'react-final-form';
import {get} from 'lodash';
import {getExpressionTrees, getSelectedExpressionId} from 'metrics/store/selectors';
import SwitchButton from 'common/componentsV2/SwitchButton';

const ShowCompositesContainter = () => {
  const {
    input: {value: isShowComposites, onChange: setIsShowComposites},
  } = useField('isShowComposites');

  const {
    input: {value: isAutoShowComposites, onChange: setIsAutoShowComposites},
  } = useField('isAutoShowComposites');

  const {
    input: {value: isShowCompositesEnable},
  } = useField('isShowCompositesEnable');

  const expressionTrees = useSelector(getExpressionTrees);

  const selectedTreeId = useSelector(getSelectedExpressionId);
  const selectedTree = expressionTrees.find((et) => et.id === selectedTreeId);
  const isRootFunctionEmpty = get(selectedTree, 'expressionTree.root.function') === '';

  useEffect(() => {
    if (isAutoShowComposites) {
      setIsShowComposites(isRootFunctionEmpty);
    }
  }, [isRootFunctionEmpty]);

  const switchHandler = useCallback(
    (value) => {
      setIsShowComposites(value);
      if (isAutoShowComposites) {
        setIsAutoShowComposites(false);
      }
    },
    [isAutoShowComposites],
  );

  return (
    <SwitchButton
      isChecked={isShowComposites}
      onChange={switchHandler}
      disabled={!isShowCompositesEnable}
      automationId="showComposite"
    />
  );
};

export default ShowCompositesContainter;
