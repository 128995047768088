import React from 'react';
import {useField} from 'react-final-form';
import Box from '@material-ui/core/Box';
import TextArea from 'common/componentsV2/TextArea';
import TooltipArea from 'common/componentsV2/TooltipArea';
import AutocompleteSelection from 'common/componentsV2/autocompleteSelection/AutocompleteSelection';
import InputLabel from '../InputLabel';

const AlertDescription = () => {
  const {
    input: {value, onChange},
  } = useField('story');
  return (
    <TooltipArea automationId="alertDescriptionContainer" text="Write a short description that explains this alert">
      {(info) => (
        <React.Fragment>
          <Box mb={1}>
            <InputLabel isOptional>
              Description
              {info}
            </InputLabel>
          </Box>
          <AutocompleteSelection onChange={onChange} inputValue={value} automationId="alertDescriptionAutocomplete">
            {(handleChange, handleKeyDown) => (
              <TextArea
                automationId="alertDescription"
                minRows={2}
                placeholder="Short description of the alert"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                value={value}
              />
            )}
          </AutocompleteSelection>
        </React.Fragment>
      )}
    </TooltipArea>
  );
};

export default AlertDescription;
