// @flow
import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as selectors from 'alerts.management/store/selectors';
import * as actions from 'alerts.management/store/actions';
import {ExpandableSwitch} from 'common/componentsV2/ExpandableSections';
import {TypographyBox} from 'common/componentsV2/boxTools';
import Box from '@material-ui/core/Box';
import TooltipArea from 'common/componentsV2/TooltipArea';
import AbsPercentageConditionTemplate from './AbsPercentageConditionTemplate';

const NumberOfMetricsInAnomaly = () => {
  const dispatch = useDispatch();
  const addConditionToSelectedAlert = useCallback((...args) => dispatch(actions.addConditionToSelectedAlert(...args)), [
    dispatch,
  ]);
  const setSelectedAlertMaxMetricsAbs = useCallback(
    (...args) => dispatch(actions.setSelectedAlertMaxMetricsAbs(...args)),
    [dispatch],
  );
  const setSelectedAlertMaxMetricsPercentage = useCallback(
    (...args) => dispatch(actions.setSelectedAlertMaxMetricsPercentage(...args)),
    [dispatch],
  );
  const setSelectedAlertMinMetricsAbs = useCallback(
    (...args) => dispatch(actions.setSelectedAlertMinMetricsAbs(...args)),
    [dispatch],
  );
  const setSelectedAlertMinMetricsPercentage = useCallback(
    (...args) => dispatch(actions.setSelectedAlertMinMetricsPercentage(...args)),
    [dispatch],
  );
  const removeConditionFromSelectedAlert = useCallback(
    (...args) => dispatch(actions.removeConditionFromSelectedAlert(...args)),
    [dispatch],
  );

  const minDurationUI = useSelector(selectors.getSimulationFiltersMinDurationUI);
  const minMetricsInAnomalyCondition = useSelector(selectors.getMinMetricsInAnomalyCondition);
  const maxMetricsInAnomalyCondition = useSelector(selectors.getMaxMetricsInAnomalyCondition);

  const availableMinMetricsInAnomalyCondition = useSelector(selectors.getAvailableMinMetricsInAnomalyCondition);
  const availableMaxMetricsInAnomalyCondition = useSelector(selectors.getAvailableMaxMetricsInAnomalyCondition);

  const isVisible = !!(minMetricsInAnomalyCondition || maxMetricsInAnomalyCondition);

  const addCondition = useCallback(() => {
    addConditionToSelectedAlert(availableMinMetricsInAnomalyCondition.dataObj, {});
    addConditionToSelectedAlert(availableMaxMetricsInAnomalyCondition.dataObj, {});
  }, [availableMinMetricsInAnomalyCondition, availableMaxMetricsInAnomalyCondition, addConditionToSelectedAlert]);

  const removeCondition = useCallback(() => {
    removeConditionFromSelectedAlert(minMetricsInAnomalyCondition.id);
    removeConditionFromSelectedAlert(maxMetricsInAnomalyCondition.id);
  }, [minMetricsInAnomalyCondition, maxMetricsInAnomalyCondition, removeConditionFromSelectedAlert]);

  const subtitleMinimum = useMemo(
    () => (
      <TypographyBox variant="subtitle2" mb={1.5} mt={-1} lineHeight="normal" fontWeight="normal">
        {/* eslint-disable-next-line */}
        Only trigger the alert if it has <i>at least</i> this number of metrics in the anomaly.
      </TypographyBox>
    ),
    [],
  );

  const subtitleMaximum = useMemo(() => (
    <TypographyBox variant="subtitle2" mb={1.5} mt={-1} lineHeight="normal" fontWeight="normal">
      {/* eslint-disable-next-line */}
      Only trigger the alert if it has <i>at most</i> this number of metrics in the anomaly.
    </TypographyBox>
  ));

  const absoluteElement = <Box color="gray.500">Actual #</Box>;
  const percentageElement = <Box color="gray.500">Percent (%)</Box>;

  return (
    <TooltipArea text="Only trigger for this number of alert metrics">
      {(info) => (
        <ExpandableSwitch
          automationId="numberOfMetricsInAnomaly"
          label={
            <React.Fragment>
              Number of metrics in an anomaly
              {info}
            </React.Fragment>
          }
          expanded={isVisible}
          onChange={isVisible ? removeCondition : addCondition}
        >
          {minMetricsInAnomalyCondition && (
            <Box mt={3}>
              <AbsPercentageConditionTemplate
                automationId="minNumberOfMetrics"
                key={minMetricsInAnomalyCondition.id}
                title="Minimum number of metrics"
                subTitle={subtitleMinimum}
                absValue={minMetricsInAnomalyCondition.minNumOfMetrics}
                percentValue={minMetricsInAnomalyCondition.minPercentageOfMetrics}
                onPercentChange={setSelectedAlertMinMetricsPercentage}
                onAbsChange={setSelectedAlertMinMetricsAbs}
                isAbsOnlyNaturalNumbers
                minDurationUI={minDurationUI}
                absoluteElement={absoluteElement}
                percentageElement={percentageElement}
              />
            </Box>
          )}
          {maxMetricsInAnomalyCondition && (
            <Box mt={3}>
              <AbsPercentageConditionTemplate
                automationId="maxNumberOfMetrics"
                key={maxMetricsInAnomalyCondition.id}
                title="Maximum number of metrics"
                subTitle={subtitleMaximum}
                absValue={maxMetricsInAnomalyCondition.maxNumOfMetrics}
                percentValue={maxMetricsInAnomalyCondition.maxPercentageOfMetrics}
                onPercentChange={setSelectedAlertMaxMetricsPercentage}
                onAbsChange={setSelectedAlertMaxMetricsAbs}
                isAbsOnlyNaturalNumbers
                minDurationUI={minDurationUI}
                absoluteElement={absoluteElement}
                percentageElement={percentageElement}
              />
            </Box>
          )}
        </ExpandableSwitch>
      )}
    </TooltipArea>
  );
};

export default React.memo(NumberOfMetricsInAnomaly);
