// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import * as filterSelector from 'admin.users/store/filterSelector';

const EMPTY_ARRAY = [];

type PropTypes = {
  setQueryParams: Function,

  // connect
  groups?: Array,
  selectedGroups: Array,
};

@connect(
  (state) => ({
    groups: filterSelector.getFilterGroupsWithCount(state),
    selectedGroups: filterSelector.getSelectedFilterGroups(state),
  }),
  {},
)
export default class UGGroupsFilter extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    groups: EMPTY_ARRAY,
  };

  onChange = (items) => {
    const groupsVal = items.length ? items.map((item) => item.value).join(',') : null;
    this.props.setQueryParams({groups: groupsVal});
  };

  render() {
    const {groups, selectedGroups} = this.props;

    return (
      <SelectAndt
        automationId="usersGroupsFilter"
        type={TYPE_NEW_MULTI}
        theme={THEME_HIGHLIGHTED}
        onChange={this.onChange}
        getOptionLabel={(val) => val.name}
        getOptionValue={(val) => val.value}
        value={selectedGroups}
        options={groups}
        isMulti
        optionHeight={40}
        isClearable={false}
        closeMenuOnSelect={false}
        menuWidth={230}
        placeholder="Group"
        noAutoReorder
      />
    );
  }
}
