import * as actions from 'bc/store/actions';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';
import {getIsItemSourceMerged, getOriginalName, getSourceIdsArr, updateItemMatch} from 'bc/services/kinesisService';
import {isEmpty} from 'lodash';
import {getUniqueId} from 'common/utils/guid';

const kinesisDataStreamReducer = composeReducers((state, {type, payload}) => {
  const getSelectedIndex = (id = state.selectedItemId) => state.streams.data.findIndex((item) => item.id === id);

  const red = (item, itemPayload) => ({...item, ...itemPayload});

  const redWrapper = (_payload, index = getSelectedIndex()) => ({
    ...state,
    streams: {
      ...state.streams,
      data: reduceArrayItem(red, state.streams.data, index, _payload || payload),
    },
  });

  switch (type) {
    case actions.setKinesisStreamUiKeyVal.TYPE: {
      const stream = state.streams.data[getSelectedIndex()] || [];
      const propsToUpdate = {
        uiState: {
          ...stream.uiState,
          ...payload,
        },
      };
      return {...redWrapper(propsToUpdate)};
    }

    case actions.setKinesisDiametricsStreamAnalysisSchema.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      let unifiedSchema = [];
      let itemMatch = {};
      let timeItem = {};
      let timeDefinitionItem = {};

      if (
        stream.uiState &&
        stream.uiState.analysisResult &&
        stream.uiState.analysisResult.streamSchema &&
        stream.uiState.analysisResult.streamSchema.length
      ) {
        payload.streamSchema.forEach((newItem) => {
          itemMatch = {};
          stream.uiState.analysisResult.streamSchema.forEach((oldItem) => {
            if (newItem.path === oldItem.path) {
              itemMatch = updateItemMatch(stream, newItem);
            }
          });
          if (isEmpty(itemMatch)) {
            itemMatch = {...newItem};
          }
          unifiedSchema.push({...itemMatch});
        });
      } else if (stream.metrics.length || stream.dimensions.length) {
        payload.streamSchema.forEach((newItem) => {
          itemMatch = updateItemMatch(stream, newItem);
          unifiedSchema.push({...itemMatch});
        });
      } else {
        unifiedSchema = [...payload.streamSchema];
      }

      // get time column definition from stream
      if (stream.timeDefinition && stream.timeDefinition.path) {
        timeItem = payload.streamSchema.find((item) => item.name === stream.timeDefinition.path);
        if (!isEmpty(timeItem)) {
          timeItem.type = 'time';
          timeDefinitionItem = {
            path: timeItem.path,
            timePattern: stream.timeDefinition.timePattern,
          };
          if (stream.timeDefinition.timeZone) {
            timeDefinitionItem.timeZone = stream.timeDefinition.timeZone;
          }
        }
      } else if (payload.timeDefinition && payload.timeDefinition.path) {
        timeItem = payload.streamSchema.find((item) => item.path === payload.timeDefinition.path);
        if (!isEmpty(timeItem)) {
          timeDefinitionItem = {
            path: timeItem.path,
            timePattern: payload.timeDefinition.timePattern,
          };
        }
      }

      let unAssignedColumnsItems = unifiedSchema
        .filter((item) => !item.type || (item.type && !['dimension', 'metric'].includes(item.type)))
        .map((item) => item.path);
      if (timeDefinitionItem.path) {
        unAssignedColumnsItems = unAssignedColumnsItems.filter((item) => item !== timeDefinitionItem.path);
      }

      // extract the updated unifiedSchema
      const propsToUpdate = {
        uiState: {
          ...stream.uiState,
          analysisResult: payload,
          unAssignedColumns: unAssignedColumnsItems,
        },
        dimensions: unifiedSchema.filter((item) => item.type === 'dimension').map((item) => getOriginalName(item)),
        metrics: unifiedSchema.filter((item) => item.type === 'metric').map((item) => getOriginalName(item)),
        timeDefinition: timeDefinitionItem,
        schema: {
          columns: [],
          sourceColumns: [],
        },
      };

      // schema population
      let unifiedItem = {};
      propsToUpdate.metrics.forEach((itemName) => {
        const metric = unifiedSchema.find((a) => getOriginalName(a) === itemName);
        unifiedItem = {
          id: metric.id || getUniqueId(),
          name: metric.name !== undefined ? metric.name : metric.path,
          type: 'metric',
        };
        if (metric.sourceColumn) {
          unifiedItem.sourceColumn = metric.sourceColumn;
        }
        if (metric.transform) {
          unifiedItem.transform = {...metric.transform};
        }
        if (!metric.sourceColumn && !metric.transform) {
          unifiedItem.sourceColumn = metric.path;
        }
        if (metric.targetType) {
          unifiedItem.targetType = metric.targetType;
        }
        if (metric.hidden !== undefined) {
          unifiedItem.hidden = metric.hidden;
        }
        const originalName = getOriginalName(metric);
        propsToUpdate.schema.columns.push(unifiedItem);
        let existInSource = false;
        propsToUpdate.schema.sourceColumns.forEach((sourceItem) => {
          if (sourceItem.id === originalName) {
            existInSource = true;
          }
        });
        if (!existInSource) {
          propsToUpdate.schema.sourceColumns.push({
            id: originalName,
            path: originalName,
          });
        }
      });
      propsToUpdate.dimensions.forEach((itemName) => {
        const dimension = unifiedSchema.find((a) => getOriginalName(a) === itemName);
        unifiedItem = {
          id: dimension.id || getUniqueId(),
          name: dimension.name !== undefined ? dimension.name : dimension.path,
          type: 'dimension',
        };
        if (dimension.sourceColumn) {
          unifiedItem.sourceColumn = dimension.sourceColumn;
        }
        if (dimension.transform) {
          unifiedItem.transform = {...dimension.transform};
        }
        if (!dimension.sourceColumn && !dimension.transform) {
          unifiedItem.sourceColumn = dimension.path;
        }
        if (dimension.hidden !== undefined) {
          unifiedItem.hidden = dimension.hidden;
        }
        const originalName = getOriginalName(dimension);
        propsToUpdate.schema.columns.push(unifiedItem);
        let existInSource = false;
        propsToUpdate.schema.sourceColumns.forEach((sourceItem) => {
          if (sourceItem.id === originalName) {
            existInSource = true;
          }
        });
        if (!existInSource) {
          propsToUpdate.schema.sourceColumns.push({
            id: originalName,
            path: originalName,
          });
        }
      });

      // get a list of all the current indexes
      const existingIndexArr = [...propsToUpdate.dimensions, ...propsToUpdate.metrics];

      // compare and copy the user created columns from the current schema
      if (stream.schema && stream.schema.columns) {
        stream.schema.columns.forEach((item) => {
          if (
            item.sourceColumn !== undefined ||
            (item.transform &&
              item.transform.input &&
              item.transform.input.length === 1 &&
              getSourceIdsArr([], item).length === 1)
          ) {
            const sourceColumn = item.sourceColumn || getSourceIdsArr([], item)[0];
            if (
              existingIndexArr.includes(parseInt(sourceColumn, 10)) &&
              getIsItemSourceMerged(unifiedSchema, sourceColumn, item.name)
            ) {
              // user added columns that are a copy of existing column
              propsToUpdate.schema.columns.push({...item});
            }
          } else if (item.transform && item.transform.name === 'const') {
            // user added const column
            propsToUpdate.schema.columns.push({...item});
          } else if (item.transform && item.transform.input && item.transform.input.length > 1) {
            // user added concat columns
            let shouldPush = true;
            getSourceIdsArr([], item).forEach((id) => {
              if (!existingIndexArr.includes(id) || getIsItemSourceMerged(unifiedSchema, id, item.name) === undefined) {
                shouldPush = false;
              }
            });
            if (shouldPush) {
              propsToUpdate.schema.columns.push({...item});
            }
          }
        });
      }

      return {...redWrapper(propsToUpdate)};
    }

    case actions.seKinesisDiametricsChange.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      const streamModifications = {
        uiState: {...stream.uiState},
      };

      const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
      };

      if (payload.source.droppableId === payload.destination.droppableId) {
        switch (payload.destination.droppableId) {
          case 'dmAllColumns': {
            streamModifications.uiState.unAssignedColumns = reorder(
              stream.uiState.unAssignedColumns,
              payload.source.index,
              payload.destination.index,
            );
            break;
          }
          case 'dmMetrics': {
            streamModifications.metrics = reorder(stream.metrics, payload.source.index, payload.destination.index);
            break;
          }
          case 'dmDimensions': {
            streamModifications.dimensions = reorder(
              stream.dimensions,
              payload.source.index,
              payload.destination.index,
            );
            break;
          }
          case 'dmDate': {
            break;
          }
          default:
            return state;
        }
      } else {
        const {streamSchema} = stream.uiState.analysisResult;
        if (!stream.uiState.unAssignedColumns) {
          stream.uiState.unAssignedColumns = [];
        }
        switch (payload.destination.droppableId) {
          case 'dmAllColumns': {
            streamModifications.uiState.unAssignedColumns = [...stream.uiState.unAssignedColumns];
            streamModifications.uiState.unAssignedColumns.splice(payload.destination.index, 0, payload.draggableId);
            break;
          }
          case 'dmMetrics': {
            streamModifications.metrics = [...stream.metrics];
            streamModifications.metrics.splice(payload.destination.index, 0, payload.draggableId);
            break;
          }
          case 'dmDimensions': {
            streamModifications.dimensions = [...stream.dimensions];
            streamModifications.dimensions.splice(payload.destination.index, 0, payload.draggableId);
            break;
          }
          case 'dmDate': {
            const item = streamSchema.find((a) => a.path === payload.draggableId);
            if (stream.timeDefinition && stream.timeDefinition.path) {
              streamModifications.uiState.unAssignedColumns = [...stream.uiState.unAssignedColumns];
              streamModifications.uiState.unAssignedColumns.splice(0, 0, stream.timeDefinition.path);
            }
            streamModifications.timeDefinition = {
              ...stream.timeDefinition,
              path: item.path,
            };
            break;
          }
          default:
            return state;
        }

        switch (payload.source.droppableId) {
          case 'dmAllColumns': {
            streamModifications.uiState.unAssignedColumns = [
              ...(streamModifications.uiState.unAssignedColumns || stream.uiState.unAssignedColumns),
            ];
            streamModifications.uiState.unAssignedColumns.splice(
              streamModifications.uiState.unAssignedColumns.findIndex((a) => a === payload.draggableId),
              1,
            );
            break;
          }
          case 'dmMetrics': {
            streamModifications.metrics = [...stream.metrics];
            streamModifications.metrics.splice(
              streamModifications.metrics.findIndex((a) => a === payload.draggableId),
              1,
            );
            break;
          }
          case 'dmDimensions': {
            streamModifications.dimensions = [...stream.dimensions];
            streamModifications.dimensions.splice(
              streamModifications.dimensions.findIndex((a) => a === payload.draggableId),
              1,
            );
            break;
          }
          case 'dmDate': {
            streamModifications.timeDefinition = {
              ...stream.timeDefinition,
              path: null,
            };
            break;
          }
          default:
            return state;
        }
      }
      return redWrapper(streamModifications);
    }

    case actions.setKinesisDiametricsClearAll.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      const {streamSchema} = stream.uiState.analysisResult;
      const mod = {
        metrics: [],
        dimensions: [],
        timeDefinition: null,
        uiState: {
          ...stream.uiState,
          unAssignedColumns: streamSchema.map((a) => a.path),
        },
      };
      return redWrapper(mod);
    }

    case actions.setKinesisDiametricsTimeDefinition.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      return {...redWrapper({...stream, timeDefinition: {...stream.timeDefinition, ...payload}})};
    }

    case actions.removeKinesisDiametrics.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      const mod = {};
      if (stream.metrics.indexOf(payload) >= 0) {
        mod.metrics = stream.metrics.filter((a) => a !== payload);
      } else if (stream.dimensions.indexOf(payload) >= 0) {
        mod.dimensions = stream.dimensions.filter((a) => a !== payload);
      } else if (stream.timeDefinition.path === payload) {
        mod.timeDefinition = {...stream.timeDefinition, path: null};
      }
      mod.uiState = {
        ...stream.uiState,
        unAssignedColumns: [...stream.uiState.unAssignedColumns, payload],
      };
      return redWrapper(mod);
    }

    default:
      return state;
  }
});

export default kinesisDataStreamReducer;
