// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import UsersChannelsFilter from 'alerts.channels/components/UsersChannelsFilter';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import * as filterSelector from 'alerts.management/store/filterSelector';

const EMPTY_ARRAY = [];

type PropTypes = {
  setQueryParams: Function,

  // connect
  sortedFlattenChannels?: Array,
  selectedRecipients?: any,
};

@connect(
  (state) => ({
    sortedFlattenChannels: filterSelector.getFilterAlertRecipients(state),
    selectedRecipients: filterSelector.getSelectedFilterRecipients(state),
  }),
  {},
)
export default class RecipientsFilter extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    sortedFlattenChannels: EMPTY_ARRAY,
    selectedRecipients: null,
  };

  onChange = (items) => {
    const subscribers = [];
    const channels = [];
    const labels = [];

    items.forEach((i) => {
      labels.push(i.label);
      if (i.type === 'USERS') {
        subscribers.push(i.value);
      } else {
        channels.push(i.value);
      }
    });

    this.props.setQueryParams({
      subscribers: subscribers.length ? subscribers.join(',') : null,
      channels: channels.length ? channels.join(',') : null,
    });
  };

  onClearAll = () => {
    this.props.setQueryParams({subscribers: null, channels: null});
  };

  render() {
    const {selectedRecipients, sortedFlattenChannels} = this.props;
    return (
      <UsersChannelsFilter
        onChange={this.onChange}
        sortedFlattenChannels={sortedFlattenChannels}
        value={selectedRecipients}
        footerComponent={
          <FormDdlActionBar
            isVisible
            isClearAllDisabled={!selectedRecipients || !selectedRecipients.length}
            onClearAll={this.onClearAll}
          />
        }
      />
    );
  }
}
