import React from 'react';
import AlertsTriggeredGauge from 'alerts.console/components/AlertsTriggeredGauge';
import AlertsConfigurationGauge from 'alerts.management/components/AlertsConfigurationGauge';
import DashboardsGauge from 'dashboards/components/DashboardsGauge';
import MetricsGauge from 'metrics/components/MetricsGauge';
import StreamsGauge from 'bc/components/StreamsGauge';

const gaugesToRender = {
  AlertsTriggeredGauge: (props) => <AlertsTriggeredGauge {...props} />,
  AlertsConfigurationGauge: (props) => <AlertsConfigurationGauge {...props} />,
  DashboardsGauge: (props) => <DashboardsGauge {...props} />,
  MetricsGauge: (props) => <MetricsGauge {...props} />,
  StreamsGauge: (props) => <StreamsGauge {...props} />,
};
export default gaugesToRender;
