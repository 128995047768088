// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  setCoralogixDiametricsChange as setCoralogixDiametricsChangeAction,
  removeCoralogixDiametrics as removeCoralogixDiametricsAction,
  setCoralogixDiametricsClearAll as setCoralogixDiametricsClearAllAction,
  setStreamDiametricsEditorFilterTextInput as setStreamDiametricsEditorFilterTextInputAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import DimensionsAndMetricsEditor from 'bc/components/streams/editor/common/DimensionsAndMetricsEditor';

type PropTypes = {
  dimensions: Array<Object>,
  metrics: Array<Object>,
  filteredUnassignedColumns: Array<Object>,
  filterTextInput: String,
  setCoralogixDiametricsChange: Function,
  setCoralogixDiametricsClearAll: Function,
  removeCoralogixDiametrics: Function,
  setStreamDiametricsEditorFilterTextInput: Function,
  isStreamUpdating: Boolean,
};

@connect(
  (state) => ({
    dimensions: selectors.getCoralogixMetaDimensions(state),
    metrics: selectors.getCoralogixMetaMetrics(state),
    filteredUnassignedColumns: selectors.getFilteredCoralogixStreamUnAssignedColumns(state),
    filterTextInput: selectors.getStreamMetricsDimentionsFilterTextInput(state),
    isStreamUpdating: selectors.getIsDataStreamUpdating(state),
  }),
  {
    setCoralogixDiametricsChange: setCoralogixDiametricsChangeAction,
    setCoralogixDiametricsClearAll: setCoralogixDiametricsClearAllAction,
    removeCoralogixDiametrics: removeCoralogixDiametricsAction,
    setStreamDiametricsEditorFilterTextInput: setStreamDiametricsEditorFilterTextInputAction,
  },
)
export default class CoralogixDimensionsAndMetricsEditor extends React.PureComponent {
  props: PropTypes;

  render() {
    const {
      metrics,
      dimensions,
      filterTextInput,
      filteredUnassignedColumns,
      removeCoralogixDiametrics,
      setStreamDiametricsEditorFilterTextInput,
      setCoralogixDiametricsChange,
      setCoralogixDiametricsClearAll,
      isStreamUpdating,
    } = this.props;

    return (
      <DimensionsAndMetricsEditor
        dimensions={dimensions}
        metrics={metrics}
        filteredUnassignedColumns={filteredUnassignedColumns}
        filterTextInput={filterTextInput}
        setStreamDiametricsChange={setCoralogixDiametricsChange}
        setStreamClearAllDiametrics={setCoralogixDiametricsClearAll}
        removeStreamDiametrics={removeCoralogixDiametrics}
        setMetricDimentionEditorFilterTextInput={setStreamDiametricsEditorFilterTextInput}
        isStreamUpdating={isStreamUpdating}
        isRemoveDateSection
      />
    );
  }
}
