export const FEEDBACK_TYPE = {
  GOOD_CATCH: 'GOOD_CATCH',
  NOT_INTERESTING: 'NOT_INTERESTING',
};

export const NOT_INTERESTING_REASONS = [
  {value: 'noBusinessImpact', title: 'No Business Impact'},
  {value: 'incorrectData', title: 'Incorrect Data'},
  {value: 'notAnAnomaly', title: 'Not an Anomaly'},
];

export const FEEDBACK_ORIGIN = {
  ALERT_CONSOLE: 'alertConsole',
  ANOBOARD: 'anoboard',
  INSIGHT_PANEL: 'insightPanel',
  INVESTIGATION: 'investigation',
};

export const FEEDBACK_SIZE = {
  mid: {
    container: {
      width: 70,
    },
    icons: {
      fontSize: 16,
    },
  },
  large: {
    container: {
      width: 90,
    },
    icons: {
      fontSize: 24,
    },
  },
};
