// @flow
import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchStreamHistoryLogs} from 'bc/store/actions';
import * as api from 'bc/services/api';
import {getSelectedStreamHistoryLog, getSelectedStreamHistoryLogIsLoading} from 'bc/store/selectors';
import {Box, Grid, makeStyles} from '@material-ui/core';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import {getFormattedDate, getFormattedLLDate, getFormattedTime} from 'common/utils/dateService';
import get from 'lodash/get';
import {downloadFile} from 'bc/services/fileUploadToAwsService';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import Button, {COLORS} from 'common/componentsV2/Button';
import Tooltip from 'common/componentsV2/Tooltip';
import {format} from 'common/utils/utilsService';

const formatDate = (time, timeZoneName, validate = false, isShowTimeZone = false) => {
  if (validate && (time < Date.now() || new Date(time).getFullYear() - new Date(Date.now()).getFullYear() > 1)) {
    return 'N/A';
  }
  const modifyTime = time / 1000;
  // eslint-disable-next-line max-len
  return `${getFormattedLLDate(modifyTime, timeZoneName)} ${getFormattedTime(modifyTime, timeZoneName)} ${
    isShowTimeZone ? timeZoneName : ''
  }`;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 'fit-content',
  },
  overflow: {
    overflow: 'auto',
  },
  header: {
    position: 'sticky',
    top: 0,
    background: theme.palette.white[500],
    minHeight: '30px',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  row: {
    '&:nth-child(even)': {
      background: theme.palette.gray[100],
    },
  },
  item: {
    minWidth: '170px',
    flexShrink: 0,
    '&.small': {
      minWidth: '100px',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    background: theme.palette.blue[100],
    borderRadius: 6,
    padding: '0 12px',
  },
  download: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    cursor: 'pointer',
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '30px',
  },
  widthXL: {
    minWidth: 210,
  },
}));

const headerTitle = [
  'Start Time',
  'End Time',
  'Result',
  'Errors',
  'Retrieved Records',
  'Faulty Records',
  'Samples sent',
  'Data collected from',
  'Data collected until',
  'Next run on',
  'Session Id',
];

const getTimeNext = (rows) => {
  const array = rows.map((item) => item.nextTime);
  const miliseconds = Math.max(...array);
  return miliseconds;
};

const SimpleTable = (props: {dataStream: Object, timeZoneName: string}) => {
  const dispatch = useDispatch();
  const [timeNext, setTimeNext] = useState(0);
  const getHistoryLog = useSelector(getSelectedStreamHistoryLog);
  const isLoading = useSelector(getSelectedStreamHistoryLogIsLoading);
  const rows = get(getHistoryLog, 'rows', []);
  const {id} = props.dataStream;

  const splitPrefix = (getHistoryLog.filesPrefix || '').split('/');
  const path = splitPrefix.length > 0 && `${splitPrefix[0]}/${splitPrefix[1]}/${splitPrefix[2]}/`;
  const folder = splitPrefix.length > 0 && splitPrefix[3];

  useEffect(() => {
    if (splitPrefix[3] !== id) {
      dispatch(
        fetchStreamHistoryLogs({
          id,
        }),
      );
    }
  }, [id]);

  useEffect(() => {
    if (rows.length > 0) {
      setTimeNext(getTimeNext(rows));
    }
  }, [rows]);

  const classes = useStyles();

  const downloadLogs = () => {
    api
      .getStreamHistoryLogs({
        id,
      })
      .promise.then((payload) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(new Blob([payload], {type: 'text/csv;charset=utf-8'}));
        // eslint-disable-next-line max-len
        a.download = `${props.dataStream.name}_${getFormattedDate(
          props.dataStream.createTime,
          props.timeZoneName,
        )}_stream_log.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  };

  return (
    <Fragment>
      {isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <Spinner color="#3d4c59" size={SIZES.XX_BIG_100} />
        </Box>
      ) : (
        <Fragment>
          <Box display="flex" justifyContent="space-between" flexShrink={0} pb={1.5}>
            <Box className={classes.label}>{`Next refresh in: ${formatDate(timeNext, props.timeZoneName, true)}`}</Box>
            <Box display="flex" alignItems="center">
              To see more details:
              <Button
                automationId="getHistoryLogFile"
                text="Export to CSV"
                icon="icn-action16-download"
                colorSchema={COLORS.TRANSPARENT_BLUE}
                onClick={downloadLogs}
                colorBar="transparent"
              />
            </Box>
          </Box>
          <Box className={classes.overflow}>
            <Box className={classes.wrapper}>
              <Grid container className={classes.header}>
                {headerTitle.map((title, index) => (
                  <Grid key={title} item xs className={`${classes.item} ${index === 9 ? classes.widthXL : ''}`}>
                    <TypographyBox variant="hintText" pl={index === 0 ? 1.5 : 0}>
                      {title}
                    </TypographyBox>
                  </Grid>
                ))}
              </Grid>
              {rows.map((row) => (
                <Grid container key={row.startTime} className={classes.row}>
                  <Grid item xs className={classes.item}>
                    <TypographyBox variant="body1" className={classes.ellipsis} pl={1.5}>
                      {formatDate(row.startTime, props.timeZoneName)}
                    </TypographyBox>
                  </Grid>
                  <Grid item xs className={classes.item}>
                    <TypographyBox variant="body1" className={classes.ellipsis}>
                      {formatDate(row.finishTime, props.timeZoneName)}
                    </TypographyBox>
                  </Grid>
                  <Grid item xs className={classes.item}>
                    <Tooltip content={row.resultStatus}>
                      <TypographyBox variant="body1" className={classes.ellipsis}>
                        {row.resultStatus}
                      </TypographyBox>
                    </Tooltip>
                  </Grid>
                  <Grid item xs className={classes.item}>
                    <Tooltip content={row.failureDetails}>
                      <TypographyBox variant="body1" className={classes.ellipsis}>
                        {row.failureDetails}
                      </TypographyBox>
                    </Tooltip>
                  </Grid>
                  <Grid container item xs className={classes.item}>
                    <TypographyBox variant="body1" className={classes.ellipsis} width={65}>
                      {format(row.inputRows, 3, ',', '.')}
                    </TypographyBox>
                    {row.inputRows > 0 && !window.isOnPremise && (
                      <Tooltip content="Download">
                        <Box
                          className={classes.download}
                          onClick={() =>
                            downloadFile(
                              `${row.sessionId}-in.gz`,
                              getHistoryLog.filesBucket,
                              path,
                              () => null,
                              () => null,
                              folder,
                            )
                          }
                        >
                          <div className="icon icn-nav16-goto" />
                        </Box>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item xs className={classes.item}>
                    <TypographyBox variant="body1" className={classes.ellipsis}>
                      {row.invalidRows}
                    </TypographyBox>
                  </Grid>
                  <Grid container item xs className={classes.item}>
                    <TypographyBox variant="body1" className={classes.ellipsis} width={65}>
                      {format(row.outputMetrics, 3, ',', '.')}
                    </TypographyBox>
                    {row.outputMetrics > 0 && !window.isOnPremise && (
                      <Tooltip content="Download">
                        <Box
                          className={classes.download}
                          onClick={() =>
                            downloadFile(
                              `${row.sessionId}-out.gz`,
                              getHistoryLog.filesBucket,
                              path,
                              () => null,
                              () => null,
                              folder,
                            )
                          }
                        >
                          <div className="icon icn-nav16-goto" />
                        </Box>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item xs className={classes.item}>
                    <TypographyBox variant="body1" className={classes.ellipsis}>
                      {row.initialTimeslot !== null ? formatDate(row.initialTimeslot, props.timeZoneName) : 'N/A'}
                    </TypographyBox>
                  </Grid>
                  <Grid item xs className={classes.item}>
                    <TypographyBox variant="body1" className={classes.ellipsis}>
                      {row.finalTimeslot !== null ? formatDate(row.finalTimeslot, props.timeZoneName) : 'N/A'}
                    </TypographyBox>
                  </Grid>
                  <Grid item xs className={`${classes.item} ${classes.widthXL}`}>
                    <TypographyBox variant="body1" className={`${classes.ellipsis} ${classes.widthXL}`}>
                      {formatDate(row.nextTime, props.timeZoneName, true, true)}
                    </TypographyBox>
                  </Grid>
                  <Grid item xs className={classes.item}>
                    <TypographyBox variant="body1" className={classes.ellipsis}>
                      {row.sessionId}
                    </TypographyBox>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
};

export default SimpleTable;
