// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setSelectedEditor} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import {isStreamEditable} from 'bc/services/dataStreamService';
import {getEditorValidationMessage} from 'bc/services/parquetService';
import DimensionsAndMetrics from 'bc/components/streams/editor/common/DimensionsAndMetrics';
import {get} from 'lodash';

type PropTypes = {
  dataStream: Object,
  dimensions: Array<Object>,
  metrics: Array<Object>,
  timeDefinition: Object,
  setSelectedEditor: Function,
  isUiStateDirty: boolean,
  isLoading: boolean,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    dimensions: selectors.getParquetStreamSelectedDimensions(state),
    metrics: selectors.getParquetStreamSelectedMetrics(state),
    timeDefinition: selectors.getParquetStreamSelectedTimeDefinitionObj(state),
    isUiStateDirty: selectors.isParquetStreamUiStateDirty(state),
    isLoading: selectors.isParquetAnalysisLoading(state),
  }),
  {
    setSelectedEditor,
  },
)
export default class ParquetDimensionsAndMetrics extends React.PureComponent {
  props: PropTypes;

  setSelectedEditor = () => {
    this.props.setSelectedEditor({
      type: 'dm_parquet',
      editorHeight: 'calc(100% - 210px)',
    });
  };

  render() {
    const {dataStream, dimensions, metrics, timeDefinition, isUiStateDirty, isLoading} = this.props;
    const isEditable =
      isStreamEditable(dataStream.state) && get(dataStream, 'uiState.analysisResult.columns') && !isUiStateDirty;

    return (
      <DimensionsAndMetrics
        dataStream={dataStream}
        dimensions={dimensions}
        metrics={metrics}
        timeDefinition={timeDefinition}
        isLoading={isLoading}
        isEditable={isEditable}
        validatorFunction={getEditorValidationMessage}
        onSelectEditor={() => this.setSelectedEditor()}
      />
    );
  }
}
