import {isNumber} from 'lodash';
import * as templating from './SelectAndtStylingTemplates';
/* eslint-disable import/no-cycle */
import {
  THEME_BLUE_DARK,
  THEME_BLUE_LEAN,
  THEME_BLUE_LEAN_MODIFIED,
  THEME_HIGHLIGHTED,
  THEME_HIGHLIGHTED_TRANSPARENT,
  THEME_LIGHT,
  THEME_NOT_HIGHLIGHTED,
} from './SelectAndt';

const control = (base, backgroundColor, borderColor) => ({
  ...base,
  boxSizing: 'border-box',
  border: `1px solid ${borderColor || templating.colors.altoGray}`,
  borderRadius: '6px',

  padding: 'auto 10',
  color: templating.colors.silverGray,
  fontSize: '14px',
  fontWeight: '300',
  backgroundColor,
  cursor: 'pointer',
  boxShadow: null,
  minWidth: '40px',
  whiteSpace: 'nowrap',
  ' input': {
    height: '36px!important',
  },
  '&:hover': {border: `1px solid ${borderColor || templating.colors.altoGray}`},
});

const dropdownIndicator = (base, state) => ({
  ...base,
  opacity: state.isDisabled ? 0.5 : 1,
  padding: '0',
  marginRight: '10px',
  borderStyle: 'solid',
  display: 'inner-block',
  borderWidth: '5px 4px 0',
  borderColor: '#8995a0 transparent transparent',
  transition: 'all 0.5s',
});

export const searchStyle = (theme) => ({
  option: (base, {isFocused, isSelected}) => ({
    ...base,
    ':active': {
      backgroundColor: templating.getColor(theme, 'backgroundColor', true, true),
    },
    fontSize: '14px',
    fontWeight: isSelected ? '900' : '300',
    color: templating.getColor(theme, 'color', isSelected, isFocused),
    padding: '10px 15px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: templating.getColor(theme, 'backgroundColor', isSelected, isFocused),
  }),
  control: (base) => {
    const btnColor = templating.getColor(theme, 'buttonColor');
    const borderColor = templating.getColor(theme, 'buttonMarginColor');
    return control(base, btnColor, borderColor);
  },
  placeholder: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? 0.5 : 1,
    color: templating.getColor(theme, 'buttonTextColor'),
    fontWeight: 'bold',
  }),
  dropdownIndicator: (base, state) => dropdownIndicator(base, state, templating.getColor(theme, 'buttonTextColor')),
  indicatorSeparator: () => {},
  menu: (base) => ({
    ...base,
    marginTop: '0px',
    marginBottom: '0px',
    backgroundColor: templating.getColor(theme, 'backgroundColor'),
  }),
  singleValue: (base, state) => ({
    color: templating.getColor(theme, 'buttonTextColor'),
    '&:div': {color: templating.getColor(theme, 'buttonTextColor')},
    '&:small': {color: templating.getColor(theme, 'buttonTextColor')},
    lineHeight: '0.6',
    whiteSpace: 'nowrap',
    width: 'calc(100% - 6px)',
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  valueContainer: (base) => ({
    ...base,
    flexFlow: 'row-reverse',
    justifyContent: 'flex-end',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: '1060',
    minWidth: '250px',
  }),
  input: (base) => ({
    ...base,
    height: '36px',
  }),
});

export const multiStyle = (theme) => ({
  option: (base, {isFocused, isSelected}) => ({
    ...base,
    ':active': {
      backgroundColor: templating.getColor(theme, 'backgroundColor', true, true),
    },
    fontSize: '14px',
    color: templating.getColor(theme, 'color', isSelected, isFocused),
    fontWeight: '300',
    padding: '10px 12px',
    margin: '4px 0 4px 4px',
    width: 'calc(100% - 8px)',
    borderRadius: '4px',
    height: '38px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    backgroundColor: templating.getColor(theme, 'backgroundColor', isSelected, isFocused),
    display: 'flex',
    whiteSpace: 'nowrap',
    ' .icon-checkbox': {
      color: isSelected ? templating.colors.dodgerBlue : templating.colors.oxfordBlue,
    },
  }),
  control: (base, state) => {
    const ret = control(base, templating.colors.white);
    if (state.isFocused) {
      ret[' .andt-dropdown-placeholder'] = {opacity: '0.5'};
    }
    return ret;
  },
  placeholder: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? 0.5 : 1,
    color: templating.getColor(theme, 'buttonTextColor'),
    fontWeight: 'bold',
    top: 'calc(50% + 2px)',
  }),
  dropdownIndicator: (base, state) => dropdownIndicator(base, state, templating.getColor(theme, 'buttonTextColor')),
  indicatorSeparator: () => {},
  menu: (base) => ({
    ...base,
    marginTop: '0px',
    marginBottom: '0px',
  }),
  menuList: (base) => ({
    ...base,
    position: 'relative',
    top: '-10px',
  }),
  multiValueRemove: (base) => ({
    ...base,
    ':hover': {},
    width: '40px',
    color: templating.colors.oxfordBlue,
    background:
      'linear-gradient(to right,rgba(225,228,231,0) 0%,rgba(225,228,231,0) 20%,rgba(225,228,231,1)' +
      ' 44%,rgba(225,228,231,1) 100%)',
    // backgroundColor: '#e1e4e7',
    position: 'absolute',
    right: '0',
    height: '100%',
    ' .close-button': {
      position: 'absolute',
      right: '3px',
      top: '5px',
      fontSize: '16px',
      color: templating.colors.oxfordBlue,
    },
    ' .icon': {
      opacity: '0.5',
    },
    ' .icon:hover': {
      opacity: '1',
    },
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: '#e1e4e7',
    position: 'relative',
    height: '26px',
    margin: '4px',
    borderRadius: '4px',
    fontSize: '14px',
    lineHeight: '14px',
    ' .multi-value-remove': {
      transition: 'opacity 0.3s',
      opacity: '0!important',
    },
    ':hover': {
      ' .multi-value-remove': {
        opacity: '1!important',
      },
    },
  }),
  multiValueLabel: (base) => ({
    ...base,
    fontSize: '14px',
    lineHeight: '14px',
    padding: '0 6px 2px 2px',
    fontWeight: '500',
    margin: '4px',
    ' .ico': {
      paddingRight: '6px',
      position: 'relative',
      top: '2px',
    },
  }),
  input: (base) => ({
    ...base,
    height: '30px',
    lineHeight: '30px',
    ' input': {
      height: '26px!important',
      position: 'relative',
      bottom: '4px',
    },
  }),
  valueContainer: () => ({
    WebkitOverflowScrolling: 'touch',
    boxSizing: 'border-box',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    overflow: 'hidden',
    padding: '2px',
    position: 'relative',
    backgroundColor: 'rgba(242, 242, 243, 0.5)',
    borderColor: '#c8cdd0',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: '1060',
  }),
});

export const newMultiStyleNoSearch = (theme) => ({
  option: (base, {isFocused, isSelected}) => ({
    ...base,
    ':hover': {
      backgroundColor: templating.getColor(theme, 'backgroundColor', isSelected, isFocused),
    },
    fontSize: '14px',
    color: templating.getColor(theme, 'color', isSelected, isFocused),
    fontWeight: '300',
    padding: '10px 12px',
    margin: '4px 4px 4px 4px',
    width: 'calc(100% - 8px)',
    borderRadius: '4px',
    height: '38px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    backgroundColor: templating.getColor(theme, 'backgroundColor', isSelected, isFocused),
    display: 'flex',
    whiteSpace: 'nowrap',
    ' .icon-checkbox': {
      color: isSelected ? templating.colors.dodgerBlue : templating.colors.oxfordBlue,
    },
  }),
  control: (base, state) => {
    const ret = control(base, templating.colors.white);
    if (state.isFocused) {
      ret[' .andt-dropdown-placeholder'] = {opacity: '0.5'};
    }
    return ret;
  },
  placeholder: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? 0.5 : 1,
    color: templating.getColor(theme, 'buttonTextColor', true, true),
    fontWeight: 'bold',
    top: 'calc(50% + 2px)',
  }),
  dropdownIndicator: (base, state) => {
    const color = state.hasValue
      ? templating.stylingV2[theme].dropdownIndicator.borderColor.hasValue
      : templating.stylingV2[theme].dropdownIndicator.borderColor.noValue;
    return {
      ...base,
      opacity: state.isDisabled ? 0.5 : 1,
      padding: '0',
      marginRight: '8px',
      color,
    };
  },
  indicatorSeparator: () => {},
  menu: (base) => ({
    ...base,
    marginTop: '0px',
    marginBottom: '0px',
  }),
  menuList: (base) => ({
    ...base,
    position: 'relative',
    top: '-10px',
  }),
  multiValueRemove: (base) => ({
    ...base,
    ':hover': {},
    width: '40px',
    color: templating.colors.oxfordBlue,
    background:
      'linear-gradient(to right,rgba(225,228,231,0) 0%,rgba(225,228,231,0) 20%,rgba(225,228,231,1)' +
      ' 44%,rgba(225,228,231,1) 100%)',
    position: 'absolute',
    right: '0',
    height: '100%',
    ' .close-button': {
      position: 'absolute',
      right: '3px',
      top: '5px',
      fontSize: '16px',
      color: templating.colors.oxfordBlue,
    },
    ' .icon': {
      opacity: '0.5',
    },
    ' .icon:hover': {
      opacity: '1',
    },
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: '#e1e4e7',
    position: 'relative',
    height: '26px',
    margin: '4px',
    borderRadius: '4px',
    fontSize: '14px',
    lineHeight: '14px',
    ' .multi-value-remove': {
      transition: 'opacity 0.3s',
      opacity: '0!important',
    },
    ':hover': {
      ' .multi-value-remove': {
        opacity: '1!important',
      },
    },
  }),
  multiValueLabel: (base) => ({
    ...base,
    fontSize: '14px',
    lineHeight: '14px',
    padding: '0 6px 2px 2px',
    fontWeight: '500',
    margin: '4px',
    ' .ico': {
      paddingRight: '6px',
      position: 'relative',
      top: '2px',
    },
  }),
  input: (base) => ({
    ...base,
    height: '30px',
    lineHeight: '30px',
    ' input': {
      height: '26px!important',
      position: 'relative',
      bottom: '4px',
    },
  }),
  valueContainer: () => ({
    fontSize: '14px',
    lineHeight: '34px',
    WebkitOverflowScrolling: 'touch',
    boxSizing: 'border-box',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    overflow: 'hidden',
    padding: '8px',
    position: 'relative',
    borderColor: '#c8cdd0',
    color: templating.getColor('THEME_LIGHT', 'color'),
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: '1060',
  }),
});

export const noSearchStyle = (theme, buttonHeight, maxMenuHeight, isMenuListScrollable) => ({
  option: (base, {isSelected}) => ({
    ...base,
    ':hover': {
      backgroundColor: templating.colorsV2.blue100,
    },
    width: 'auto',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '400',
    color: templating.colorsV2.gray500,
    padding: '9px 8px',
    margin: 4,
    borderRadius: 6,
    backgroundColor: isSelected ? templating.getColor(theme, 'backgroundColor', true, true) : 'transparent',
  }),
  control: (base) => {
    const ret = control(
      base,
      templating.getColor(theme, 'buttonColor'),
      templating.getColor(theme, 'buttonMarginColor'),
    );
    ret[' input'] = {height: '48px'};
    if (buttonHeight) {
      ret.height = `${buttonHeight}px`;
      ret.lineHeight = `${buttonHeight}px`;
    }
    return ret;
  },
  placeholder: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? 0.5 : 1,
    color: templating.getColor(theme, 'buttonTextColor'),
    fontWeight: '400',
  }),
  dropdownIndicator: (base, state) => dropdownIndicator(base, state, templating.getColor(theme, 'buttonTextColor')),
  indicatorSeparator: () => {},
  menu: (base) => ({
    ...base,
    marginTop: '0px',
    marginBottom: '0px',
    minWidth: '125px',
    backgroundColor: templating.getColor(theme, 'backgroundColor'),
    overflowY: 'auto',
  }),
  menuList: (base) => {
    const menuStyle = {
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 6,
      boxShadow: '0 4px 12px -2px rgba(0, 0, 36, 0.25)',
      maxHeight: isMenuListScrollable ? 180 : 9999,
    };
    if (maxMenuHeight) {
      menuStyle.maxHeight = maxMenuHeight;
    }
    return menuStyle;
  },

  singleValue: (base, state) => ({
    color: templating.getColor(theme, 'buttonTextColor'),
    '&:div': {color: templating.getColor(theme, 'buttonTextColor')},
    '&:small': {color: templating.getColor(theme, 'buttonTextColor')},
    lineHeight: '1.2',
    width: 'calc(100% - 6px)',
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: '1060',
  }),
});

// eslint-disable-next-line max-len
export const simpleStyle = (theme, direction, menuWidth = 200, isMenuListScrollable, offsetLeft = 20) => ({
  option: (base, {isFocused, isSelected, isDisabled}) => {
    if (theme === 'THEME_TRANSPARENT') {
      return {
        ...base,
        ':hover': {
          backgroundColor: templating.colorsV2.blue100,
        },
        width: 'auto',
        fontSize: '14px',
        cursor: 'pointer',
        color: '#3d4c59',
        fontWeight: '400',
        padding: '9px 8px',
        margin: 4,
        borderRadius: 6,
        backgroundColor: '#FFFFFF',
        opacity: isDisabled ? 0.5 : 1,
      };
    }

    let color = null;
    let bgColor = null;

    if (theme === 'THEME_BLUE') {
      color = templating.colors.white;
    } else {
      color = isSelected ? templating.colors.skyBlue : templating.colors.darkGray;
    }

    if (isFocused) {
      bgColor = theme === 'THEME_BLUE' ? templating.colors.pictonBlue : templating.colors.lightGray;
    }

    return {
      ...base,
      ':active': {
        backgroundColor: templating.getColor(theme, 'backgroundColor', true, true),
      },
      fontSize: '14px',
      cursor: 'pointer',
      color,
      fontWeight: '300',
      padding: '10px 15px',
      backgroundColor: bgColor,
      opacity: isDisabled ? 0.5 : 1,
    };
  },

  singleValue: () => ({
    display: 'none',
  }),
  placeholder: () => ({
    display: 'none',
  }),

  dropdownIndicator: (base, state) => ({
    ...base,
    padding: '0',
    display: 'inner-block',
    cursor: 'pointer',
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  indicatorsContainer: (base) => ({
    ...base,
    cursor: 'pointer',
  }),
  control: () => {},
  valueContainer: () => ({position: 'absolute'}),
  indicatorSeparator: () => {},
  menu: (base) => {
    let leftPos;
    switch (direction) {
      case 'DIRECTION_LEFT':
        leftPos = `${-menuWidth + offsetLeft}px`;
        break;
      case 'DIRECTION_RIGHT':
        leftPos = '20px';
        break;
      case 'DIRECTION_RIGHT_NO_OFFSET':
        leftPos = '0px';
        break;
      case 'DIRECTION_CENTER':
        leftPos = `${menuWidth / -2 + 40}px`;
        break;
      default:
        leftPos = null;
    }
    return {
      ...base,
      marginTop: '0px',
      marginBottom: '0px',
      backgroundColor: theme === 'THEME_BLUE' ? templating.colors.curiousBlue : templating.colors.white,
      color: theme === 'THEME_BLUE' ? templating.colors.white : 'inherit',
      width: `${menuWidth}px`,
      left: leftPos,
      boxShadow: '0 4px 12px -2px rgba(0, 0, 36, 0.25)',
      borderRadius: 6,
      paddingBottom: 0,
      paddingTop: 0,
      zIndex: 3,
    };
  },
  menuList: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: isMenuListScrollable ? 180 : 9999,
    overflowY: 'auto',
  }),
});

export const optionMulti = () => ({
  header: () => ({
    textTransform: 'uppercase',
    fontSize: '14px',
    lineHeight: '12px',
    color: '#8995a0',
    fontWeight: 'bold',
    position: 'relative',
  }),
  checkbox: () => ({
    width: '22px',
    paddingRight: '16px',
    fontSize: '16px',
    marginTop: '2px',
  }),
  option: () => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    bottom: '-1px',
    marginTop: '2px',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '14px',
  }),
});

export const newMultiStyle = (theme, options, isSearchable, isInputVisible) => ({
  option: (base, {isSelected, isFocused}) => ({
    ...base,
    ':active': {
      backgroundColor: 'rgba(38, 113, 255, 0.05)',
    },
    ':hover': {
      backgroundColor: 'rgba(38, 113, 255, 0.05)',
    },
    fontSize: '14px',
    color: templating.colorsV2.gray500,
    fontWeight: '400',
    padding: '6px 7px',
    margin: '4px 0 3px 4px',
    width: 'calc(100% - 8px)',
    borderRadius: '4px',
    height: '32px',
    lineHeight: '32px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    backgroundColor: isFocused ? 'rgba(38, 113, 255, 0.05)' : templating.colorsV2.white,
    display: 'flex',
    whiteSpace: 'nowrap',
    ' .icon-checkbox': {
      color: isSelected ? templating.colorsV2.blue500 : templating.colorsV2.gray500,
    },
  }),
  control: (base, state) => {
    const ret = control(base, templating.colorsV2.gray200, templating.colorsV2.gray200);

    ret.border = 0;
    ret['&:hover'].border = 0;
    ret.backgroundColor =
      state.hasValue || state.selectProps.showValue === false
        ? templating.stylingV2[theme].control.backgroundColor.notOpen.hasValue
        : templating.stylingV2[theme].control.backgroundColor.notOpen.noValue;
    ret.color = templating.colorsV2.gray500;
    ret.fontSize = '16px';
    ret[' .andt-dropdown-placeholder'] = {
      opacity: state.isDisabled ? 1 : 0.7,
      color: templating.colorsV2.gray500,
      /* fontStyle: 'italic', */
    };
    ret.fontWeight = '400';
    // eslint-disable-next-line no-nested-ternary
    ret.width = isNumber(options.buttonWidth)
      ? `${options.buttonWidth}px`
      : options.buttonWidth !== undefined || options.buttonWidth !== null
      ? options.buttonWidth
      : 'inherit';

    if (state.selectProps.menuIsOpen) {
      ret.backgroundColor = state.hasValue
        ? templating.stylingV2[theme].control.backgroundColor.isOpen.hasValue
        : templating.stylingV2[theme].control.backgroundColor.isOpen.noValue;
    }

    if (theme === THEME_HIGHLIGHTED_TRANSPARENT) {
      if (state.selectProps.menuIsOpen) {
        ret.color = templating.colorsV2.blue500;
      }
      if (!isInputVisible) {
        ret[' input'] = {
          visibility: 'hidden',
        };
      }
    }

    if (theme === THEME_LIGHT) {
      ret[' input'] = {
        height: '52px!important',
      };
      ret.border = `1px solid ${templating.colors.altoGray}`;
      ret['&:hover'].border = `1px solid ${templating.colors.altoGray}`;
    }

    if (theme === THEME_BLUE_LEAN || theme === THEME_BLUE_DARK || theme === THEME_BLUE_LEAN_MODIFIED) {
      ret[' input'] = {
        height: '20px!important',
      };
      ret.height = '20px !important';
      ret.minHeight = '20px';
      ret.paddingLeft = '5px';
      ret[' .andt-dropdown-placeholder'].lineHeight = '20px';
      ret[' .andt-dropdown-placeholder'].height = '20px';
      ret[' .andt-dropdown-placeholder'].fontSize = '16px';
      ret[' .andt-dropdown-placeholder'].color = '#3d4c59';
      if (state.isDisabled) {
        ret[' .andt-dropdown-placeholder'].fontStyle = 'italic';
      }
    }
    if (theme === THEME_BLUE_LEAN_MODIFIED) {
      ret.paddingLeft = 0;
    }
    if ([THEME_HIGHLIGHTED, THEME_NOT_HIGHLIGHTED, THEME_HIGHLIGHTED_TRANSPARENT].indexOf(theme) !== -1) {
      ret[' .andt-dropdown-value-container'] = {
        display: 'flex',
        alignItems: 'center',
      };
    }
    return ret;
  },
  dropdownIndicator: (base, state) => {
    const color = state.hasValue
      ? templating.stylingV2[theme].dropdownIndicator.borderColor.hasValue
      : templating.stylingV2[theme].dropdownIndicator.borderColor.noValue;
    return {
      ...base,
      opacity: state.isDisabled ? 0.5 : 1,
      padding: '0',
      marginRight: '8px',
      color,
    };
  },
  indicatorSeparator: () => {},
  menu: (base) => ({
    ...base,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '6px',
    boxShadow: '0 3px 10px -2px rgba(0,0,0,0.20)',
    marginTop: '0px',
    marginBottom: '0px',
    zIndex: 3,
    width: options.menuWidth ? `${options.menuWidth}px` : 'inherit',
    minHeight: options.minMenuHeight ? `${options.minMenuHeight}px` : '200px',
    maxHeight: '450px',
    left: options.offsetLeft ? `-${options.offsetLeft}px` : '0',
  }),
  menuList: (base) => ({
    ...base,
    position: 'relative',
    top: '-10px',
  }),
  valueContainer: (base, state) => {
    let {height, fontSize, width, maxWidth} = base;
    let padding = '0 5px 0 10px';
    let {color} = templating.stylingV2[theme].valueContainer;
    height = '36px';

    if (theme === THEME_BLUE_LEAN || theme === THEME_BLUE_DARK || theme === THEME_BLUE_LEAN_MODIFIED) {
      height = '20px';
      padding = '0';
      fontSize = '16px';
      color = '#2671ff';
      maxWidth = '260';
    }

    if (theme === THEME_BLUE_LEAN_MODIFIED) {
      color = '#3d4c59';
    }

    if (theme === THEME_HIGHLIGHTED_TRANSPARENT) {
      width = 1;
      height = 1;
    }
    return {
      ...base,
      display: 'block',
      overflow: 'inherit',
      flexFlow: 'row',
      justifyContent: 'flex-start',
      color,
      opacity: state.isDisabled ? 0.5 : 1,
      padding,
      height,
      fontSize,
      width,
      maxWidth,
    };
  },
  input: (base) => ({
    ...base,
    margin: '-6px',
    height:
      theme === THEME_BLUE_LEAN || theme === THEME_BLUE_LEAN_MODIFIED || theme === THEME_BLUE_DARK ? '20px' : '36px',
    opacity: '1',
    display: 'flex',
    alignItems: 'center',
  }),
  placeholder: (base) => ({
    ...base,
    display: theme === THEME_HIGHLIGHTED_TRANSPARENT ? 'none!important' : 'inline-block',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: '1060',
  }),
  clearIndicator: (base) => ({
    ...base,
    display:
      theme === THEME_BLUE_LEAN || theme === THEME_BLUE_LEAN_MODIFIED || theme === THEME_BLUE_DARK
        ? 'none'
        : 'inline-block',
  }),
});

export const newSearchLeanStyle = (theme, options, isSearchable) => ({
  option: (base, {isFocused}) => ({
    ...base,
    ':active': {
      backgroundColor: 'rgba(38, 113, 255, 0.05)',
    },
    ':hover': {
      backgroundColor: 'rgba(38, 113, 255, 0.05)',
    },
    fontSize: '16px',
    fontWeight: '400',
    color: templating.colorsV2.gray500,
    padding: '6px 7px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: '4px 0 3px 4px',
    width: 'calc(100% - 8px)',
    borderRadius: '4px',
    height: '32px',
    lineHeight: '32px',
    backgroundColor: isFocused ? 'rgba(38, 113, 255, 0.05)' : templating.colorsV2.white,
    display: 'flex',
  }),
  control: (base, state) => {
    const ret = control(base, 'transparent', 'transparent');
    ret.backgroundColor = 'transparent';
    ret.border = 0;
    ret['&:hover'].border = 0;
    ret.backgroundColor = 'transparent';
    ret.color = templating.colorsV2.white;
    ret.fontSize = '14px';
    ret.fontWeight = '400';
    ret[' .andt-dropdown-placeholder'] = {
      color: templating.colorsV2.white,
      fontWeight: '400',
      lineHeight: '20px',
      height: '20px',
      fontStyle: 'normal',
    };

    ret.width = options.buttonWidth ? `${options.buttonWidth}px` : 'inherit';

    if (state.selectProps.menuIsOpen) {
      ret.backgroundColor = state.hasValue
        ? templating.stylingV2[theme].control.backgroundColor.isOpen.hasValue
        : templating.stylingV2[theme].control.backgroundColor.isOpen.noValue;
    }

    ret[' input'] = {
      height: '20px!important',
    };
    ret.height = '20px !important';
    ret.minHeight = '20px';
    ret.paddingLeft = '8px';

    return ret;
  },
  dropdownIndicator: (base) => {
    const color = templating.colorsV2.white;
    return {
      ...base,
      opacity: 0.5,
      padding: '0',
      marginRight: '8px',
      color,
    };
  },
  indicatorSeparator: () => {},
  menu: (base) => ({
    ...base,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '6px',
    boxShadow: '0 3px 10px -2px rgba(0,0,0,0.20)',
    marginTop: '0px',
    marginBottom: '0px',
    width: options.menuWidth ? `${options.menuWidth}px` : 'inherit',
    maxHeight: '450px',
    minHeight: isSearchable ? '350px' : 0,
    top: options.offsetTop ? `${options.offsetTop}px` : '0',
  }),
  valueContainer: (base, state) => {
    const height = '20px';
    const padding = '0';
    return {
      ...base,
      flexFlow: 'row',
      justifyContent: 'flex-start',
      color: templating.colorsV2.white,
      opacity: state.isDisabled ? 0.5 : 1,
      padding,
      height,
    };
  },
  menuPortal: (base) => ({
    ...base,
    zIndex: '1060',
    minWidth: '250px',
  }),
  input: (base) => ({
    ...base,
    margin: '-1px',
    height: '20px',
    opacity: '1',
    display: 'flex',
    alignItems: 'center',
  }),
});

export const newSearchStyle = (theme, options, isSearchable) => ({
  option: (base, {isDisabled}) => ({
    ...base,
    ':active': {
      backgroundColor: 'rgba(38, 113, 255, 0.05)',
    },
    ':hover': {
      backgroundColor: 'rgba(38, 113, 255, 0.05)',
    },
    fontSize: '16px',
    fontWeight: '400',
    color: templating.colorsV2.gray500,
    padding: '6px 7px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: '4px 0 3px 4px',
    width: 'calc(100% - 8px)',
    borderRadius: '4px',
    height: '32px',
    lineHeight: '32px',
    backgroundColor: templating.colorsV2.white,
    display: 'flex',
    opacity: isDisabled ? 0.5 : 1,
  }),
  control: (base, state) => {
    const ret = control(base, templating.colorsV2.gray200, templating.colorsV2.gray200);
    ret.backgroundColor = state.hasValue
      ? templating.stylingV2[theme].control.backgroundColor.notOpen.hasValue
      : templating.stylingV2[theme].control.backgroundColor.notOpen.noValue;
    ret.border = 0;
    ret['&:hover'].border = 0;
    ret.color = templating.colorsV2.gray500;
    ret.fontSize = '16px';
    ret.fontWeight = '400';
    ret[' .andt-dropdown-placeholder'] = {
      opacity: state.isDisabled ? 1 : 0.7,
      color: templating.colorsV2.gray500,
      fontWeight: 'normal',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    };

    ret.width = options.buttonWidth ? `${options.buttonWidth}px` : 'inherit';

    if (state.selectProps.menuIsOpen) {
      ret.backgroundColor = state.hasValue
        ? templating.stylingV2[theme].control.backgroundColor.isOpen.hasValue
        : templating.stylingV2[theme].control.backgroundColor.isOpen.noValue;
    }

    if (theme === THEME_BLUE_LEAN || theme === THEME_BLUE_LEAN_MODIFIED || theme === THEME_BLUE_DARK) {
      ret[' input'] = {
        height: '20px!important',
      };
      ret.height = '20px !important';
      ret.minHeight = '20px';
      ret.paddingLeft = '5px';
      ret[' .andt-dropdown-placeholder'].lineHeight = '20px';
      ret[' .andt-dropdown-placeholder'].height = '20px';
      ret[' .andt-dropdown-placeholder'].fontStyle = 'normal';
      ret[' .andt-dropdown-placeholder'].fontWeigth = '400';
      ret[' .andt-dropdown-placeholder'].color = '#3d4c59';
    }

    return ret;
  },
  dropdownIndicator: (base, state) => {
    const color = state.hasValue
      ? templating.stylingV2[theme].dropdownIndicator.borderColor.hasValue
      : templating.stylingV2[theme].dropdownIndicator.borderColor.noValue;
    return {
      ...base,
      opacity: state.isDisabled ? 0.5 : 1,
      padding: '0',
      marginRight: '8px',
      color,
    };
  },
  indicatorSeparator: () => {},
  menu: (base) => ({
    ...base,
    display: 'flex',
    flexDirection: 'column',
    // eslint-disable-next-line no-nested-ternary
    minHeight: options.minMenuHeight ? `${options.minMenuHeight}px` : isSearchable ? '350px' : 0,
    borderRadius: '6px',
    boxShadow: '0 3px 10px -2px rgba(0,0,0,0.20)',
    marginTop: '0px',
    marginBottom: '0px',
    width: options.menuWidth ? `${options.menuWidth}px` : 'inherit',
    maxHeight: options.maxMenuHeight ? `${options.maxMenuHeight}px` : '450px',
  }),
  valueContainer: (base, state) => {
    const height =
      theme === THEME_BLUE_LEAN || theme === THEME_BLUE_LEAN_MODIFIED || theme === THEME_BLUE_DARK
        ? '20px'
        : base.height;
    const padding =
      theme === THEME_BLUE_LEAN || theme === THEME_BLUE_LEAN_MODIFIED || theme === THEME_BLUE_DARK
        ? '0'
        : '0 5px 0 10px';
    let color = theme === THEME_BLUE_LEAN ? '#2671ff' : templating.stylingV2[theme].valueContainer.color;
    if (theme === THEME_BLUE_LEAN_MODIFIED) {
      color = '#3d4c59';
    }
    return {
      ...base,
      flexFlow: 'row',
      justifyContent: 'flex-start',
      color,
      opacity: state.isDisabled ? 0.5 : 1,
      padding,
      height,
    };
  },
  menuPortal: (base) => ({
    ...base,
    zIndex: '1060',
    minWidth: '250px',
  }),
  input: (base) => ({
    ...base,
    margin: '-1px',
    height:
      theme === THEME_BLUE_LEAN || theme === THEME_BLUE_LEAN_MODIFIED || theme === THEME_BLUE_DARK ? '20px' : '36px',
    opacity: '1',
    display: 'flex',
    alignItems: 'center',
  }),
});
