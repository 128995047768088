// @flow
/* eslint-disable no-case-declarations */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import * as actions from 'admin.activityLog/store/actions';
import * as selectors from 'admin.activityLog/store/selectors';
import PageLayout from 'common/componentsV2/PageLayout';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import FilterButton from 'common/componentsV2/FilterButton';
import {omitBy} from 'lodash';
import {
  DEFAULT_PARAMS,
  DEFAULT_PARAMS_CONFIG,
  getActivityTypeByParamName,
  getLabel,
  OPTIONS_ACTION_TYPE,
  OPTIONS_ACTIVITY_TYPE,
  PARAMS_NAME,
  SYSTEM_USER,
} from 'admin.activityLog/services/activityLogService';
import {useQueryParams} from 'use-query-params';
import ActivityLogFilter from 'admin.activityLog/components/ActivityLogFilters';
import ActivityLogMain from 'admin.activityLog/components/ActivityLogMain';
import {getUsersData} from 'admin.users/store/selectors';
import {getMeOwnerOrganization, getTimeZoneName} from 'profile/store/selectors';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {getDateValue, rangeTypes} from 'common/utils/dateRangeService';
import Button, {COLORS} from 'common/componentsV2/Button';

const EMPTY_OBJECT = {};

const ActivityLog = () => {
  const dispatch = useDispatch();
  const isOpenFilters = useSelector(selectors.isOpenFilters);
  const usersData = useSelector(getUsersData);
  const data = useSelector(selectors.getAuditLogData);
  const customerId = useSelector(getMeOwnerOrganization);
  const isLoading = useSelector(selectors.auditLogDataIsLoading);
  const timeZoneName = useSelector(getTimeZoneName);
  const [counter, setCounter] = useState(0);
  const [list, setList] = useState(EMPTY_OBJECT);

  const [queryParams, setQueryParams] = useQueryParams(DEFAULT_PARAMS_CONFIG);

  const dateRange = useMemo(
    () => getDateValue({constRange: queryParams[PARAMS_NAME.CONST_RANGE]}, false, timeZoneName),
    [queryParams[PARAMS_NAME.CONST_RANGE]],
  );

  useEffect(() => {
    dispatch(actions.fetchAuditLog({customerId, fromTime: dateRange.startDate, toTime: dateRange.endDate}));
  }, [queryParams[PARAMS_NAME.CONST_RANGE]]);

  const toggleFilters = useCallback(() => {
    dispatch(actions.updateFilterBtn(isOpenFilters));
  }, [isOpenFilters]);

  const handleExport = useCallback(() => {
    const dataStr = JSON.stringify(list, null, 2);
    const dataUri = `data:application/json;charset=utf-8,${encodeURIComponent(dataStr)}`;
    const exportFileDefaultName = `activity-log${moment().format('DD-M-YYYY k:mm')}.json`;

    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();

    dispatch(
      actions.displayActivitySnackBar({
        title: 'Activity Log Exported successfully!',
      }),
    );
  }, [list]);

  const tooltipItems = useMemo(() => {
    const obj = omitBy(queryParams, (value, key) => value === DEFAULT_PARAMS[key]);
    return Object.entries(obj).map(([key, value]) => {
      switch (key) {
        case PARAMS_NAME.CONST_RANGE:
          return {
            id: value,
            header: 'Date Range',
            value: (Object.values(rangeTypes).find((r) => r.value === value) || EMPTY_OBJECT).text,
          };
        case PARAMS_NAME.USERS:
          const isAnodotUser = value.includes(SYSTEM_USER.value);
          const user = usersData.flatMap((u) => (value.includes(u._id) ? `${u.firstName} ${u.lastName}` : []));
          return {
            header: 'Users',
            value: [...user, isAnodotUser ? SYSTEM_USER.label : []].join(', '),
            id: value,
          };
        case PARAMS_NAME.ACTION_TYPE:
          return {
            header: 'Action Type',
            value: getLabel(OPTIONS_ACTION_TYPE, value),
            id: value,
          };
        case PARAMS_NAME.ACTIVITY:
          return {
            header: 'Show Activity of',
            value: getLabel(OPTIONS_ACTIVITY_TYPE, value),
            id: value,
          };
        case PARAMS_NAME.SOURCE:
        case PARAMS_NAME.STREAM:
        case PARAMS_NAME.ALERT:
        case PARAMS_NAME.CHANNEL:
          return {
            header: `Filter By ${getLabel(OPTIONS_ACTIVITY_TYPE, getActivityTypeByParamName(key))}`,
            value: (data.find((o) => o.entityDefinition.id === value) || EMPTY_OBJECT).entityName,
            id: value,
          };
        default:
          return EMPTY_OBJECT;
      }
    });
  }, [queryParams, usersData, data]);

  const header = (
    <div className="display_flex width_1 justifyContent_space-between">
      <div className="display_flex">
        <div className="mr_1-5">
          <Title type={TITLE_TYPES.PAGE_TITLE}>Activity Log</Title>
        </div>
        <div className="mr_1-5">
          <Title type={TITLE_TYPES.PAGE_LINE_COUNTER}>
            {counter}/{data.length}
          </Title>
        </div>
        <FilterButton tooltipItems={tooltipItems} onClick={toggleFilters} />
      </div>
      <Button
        automationId="exportActivityLog"
        icon="icn-action16-download"
        text="Export Log"
        colorSchema={COLORS.GRAY_200}
        onClick={handleExport}
        isDisabled={!Object.keys(list).length}
      />
    </div>
  );

  return (
    <PageLayout header={header}>
      <div className="display_flex flexGrow_1">
        <ActivityLogFilter
          data={data}
          setQueryParams={setQueryParams}
          queryParams={queryParams}
          isOpenFilters={isOpenFilters}
          toggleFilters={toggleFilters}
          usersData={usersData}
          timeZoneName={timeZoneName}
        />
        {isLoading ? (
          <div className="display_flex alignItems_center justifyContent_center height_1vh width_1vw">
            <Spinner color="gray.500" size={SIZES.XX_BIG_100} />
          </div>
        ) : (
          <ActivityLogMain
            data={data}
            setQueryParams={setQueryParams}
            usersData={usersData}
            queryParams={queryParams}
            setCounter={setCounter}
            setList={setList}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default ActivityLog;
