import {createSelector} from 'reselect';
import {getGeneralTopologyMap} from 'common/store/selectors';
import {intersection, cloneDeep} from 'lodash';
import {createLinkEntity, createNodeEntity, createRegionEntity} from 'topologyGeneral/services/sidePanelService';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

// views section
export const getGeneralTpViewSection = createSelector(
  getGeneralTopologyMap,
  (gtpMap) => gtpMap.views || EMPTY_OBJECT,
);

export const getGtpMapViewRegions = createSelector(
  getGeneralTpViewSection,
  (views) => views.regions || EMPTY_ARRAY,
);

export const getGtpMapViewSitesList = createSelector(
  getGeneralTpViewSection,
  (views) => views.sitesList || EMPTY_OBJECT,
);

export const getGtpMapViewSites = createSelector(
  getGeneralTpViewSection,
  (views) => views.sites || EMPTY_ARRAY,
);

export const getGtpMapViewLinks = createSelector(
  getGeneralTpViewSection,
  (views) => views.links || EMPTY_ARRAY,
);

export const getGtpMapViewDomains = createSelector(
  getGeneralTpViewSection,
  (views) => views.domains || EMPTY_ARRAY,
);

export const getGtpMapIssues = createSelector(
  getGeneralTpViewSection,
  (views) => views.issues || EMPTY_ARRAY,
);

export const getGtpMapSelectedIssue = createSelector(
  getGeneralTpViewSection,
  (views) => views.selectedIssue || null,
);

export const getIsFetchingIssues = createSelector(
  getGeneralTpViewSection,
  (views) => views.isFetchingIssues,
);

export const getIsFetchingSingleIssue = createSelector(
  getGeneralTpViewSection,
  (views) => views.isFetchingSingleIssue,
);

export const getGtpMapRegions = createSelector(
  getGtpMapViewRegions,
  getGtpMapIssues,
  (regions, issues) => {
    const fullRegions = regions.map((region) => {
      let alertCount = 0;
      const alertedNodesIdsArr = [];
      issues.forEach((issue) => {
        const intersectArr = intersection(issue.entityMapping.NODE, region.nodeIds);
        if (intersectArr.length > 0) {
          alertCount += 1;
          intersectArr.forEach((nodeId) => {
            if (alertedNodesIdsArr.indexOf(nodeId) === -1) {
              alertedNodesIdsArr.push(nodeId);
            }
          });
        } else if (
          issue.entityMapping.REGION.indexOf(region.serverId) !== -1 ||
          intersection(issue.entityMapping.SITE, region.siteIds).length > 0
        ) {
          alertCount += 1;
        }
      });

      return {
        ...region,
        alertsTotal: alertCount,
        alertedNodes: alertedNodesIdsArr.length,
        percent: Math.ceil((alertedNodesIdsArr.length / region.nodesTotal) * 100),
      };
    });

    return fullRegions.sort((a, b) => b.percent - a.percent);
  },
);

export const getGtpMapSites = createSelector(
  getGtpMapViewSites,
  getGtpMapIssues,
  (sites, issues) => {
    const fullSites = sites.map((site) => {
      const newSite = cloneDeep(site);
      const nodeIds = site.nodes.map((n) => n.id);
      let alertCount = 0;
      const alertedNodesIdsArr = [];
      issues.forEach((issue) => {
        let advanceAlertCount = false;

        newSite.nodes.forEach((n) => {
          if (issue.entityMapping.NODE.indexOf(n.id) !== -1) {
            if (alertedNodesIdsArr.indexOf(n.id) === -1) {
              alertedNodesIdsArr.push(n.id);
            }
            /* eslint-disable no-param-reassign */
            n.alertsTotal = n.alertsTotal ? n.alertsTotal + 1 : 1;
            n.isError = true;
            /* eslint-enable no-param-reassign */
            advanceAlertCount = true;
          }

          n.interfaces.forEach((inter) => {
            if (issue.entityMapping.INTERFACE.indexOf(inter.id) !== -1) {
              /* eslint-disable no-param-reassign */
              inter.isError = true;
              /* eslint-enable no-param-reassign */
              advanceAlertCount = true;
            }
          });
        });

        newSite.cells.leftWing.forEach((c) => {
          if (issue.entityMapping.CELL.indexOf(c.id) !== -1) {
            /* eslint-disable no-param-reassign */
            c.isError = true;
            /* eslint-enable no-param-reassign */
            advanceAlertCount = true;
          }
        });
        newSite.cells.rightWing.forEach((c) => {
          if (issue.entityMapping.CELL.indexOf(c.id) !== -1) {
            /* eslint-disable no-param-reassign */
            c.isError = true;
            /* eslint-enable no-param-reassign */
            advanceAlertCount = true;
          }
        });
        newSite.cells.topWing.forEach((c) => {
          if (issue.entityMapping.CELL.indexOf(c.id) !== -1) {
            /* eslint-disable no-param-reassign */
            c.isError = true;
            /* eslint-enable no-param-reassign */
            advanceAlertCount = true;
          }
        });

        newSite.links.forEach((l) => {
          /* eslint-disable no-param-reassign */
          if (issue.entityMapping.LINK.indexOf(l.id) !== -1) {
            l.isError = true;
            advanceAlertCount = true;
          }
          if (issue.entityMapping.NODE.indexOf(l.destNodeId) !== -1) {
            l.isDestNodeError = true;
            advanceAlertCount = true;
          }
          if (issue.entityMapping.NODE.indexOf(l.sourceNodeId) !== -1) {
            l.isSourceNodeError = true;
            advanceAlertCount = true;
          }
          if (issue.entityMapping.INTERFACE.indexOf(l.destInterfaceId) !== -1) {
            l.isDestInterfaceError = true;
            advanceAlertCount = true;
          }
          if (issue.entityMapping.INTERFACE.indexOf(l.sourceInterfaceId) !== -1) {
            l.isSourceInterfaceError = true;
            advanceAlertCount = true;
          }
          /* eslint-enable no-param-reassign */
        });

        if (issue.entityMapping.SITE.indexOf(newSite.serverId) !== -1) {
          advanceAlertCount = true;
          newSite.isError = true;
        }

        if (advanceAlertCount) {
          alertCount += 1;
        }
      });

      newSite.alertsTotal = alertCount;
      newSite.alertedNodes = alertedNodesIdsArr.length;
      newSite.percent = Math.ceil((alertedNodesIdsArr.length / nodeIds.length) * 100);
      return newSite;
    });

    return fullSites.sort((a, b) => b.percent - a.percent);
  },
);

export const getGtpMapSitesList = createSelector(
  getGtpMapSites,
  getGtpMapViewSitesList,
  (sites, viewSiteList) => {
    return {
      ...viewSiteList,
      items: sites.slice(0, 100).map((item) => {
        const {id, title, percent, nodesTotal, geoPoint} = item;
        return {id, title, percent, nodesTotal, geoPoint};
      }),
    };
  },
);

export const getGtpMapDomains = createSelector(
  getGtpMapViewDomains,
  getGtpMapIssues,
  (domains, issues) => {
    const fullDomains = domains.map((domain) => {
      let alertCount = 0;
      const alertedNodesIdsArr = [];
      issues.forEach((issue) => {
        const nodeIntersectArr = intersection(issue.entityMapping.NODE, domain.nodeIds);
        const cellIntersectArr = intersection(issue.entityMapping.CELL, domain.cellIds);
        if (nodeIntersectArr.length > 0 || cellIntersectArr.length > 0) {
          alertCount += 1;
          nodeIntersectArr.forEach((nodeId) => {
            if (alertedNodesIdsArr.indexOf(nodeId) === -1) {
              alertedNodesIdsArr.push(nodeId);
            }
          });
        }
      });

      return {
        ...domain,
        alertsTotal: alertCount,
        alertedNodes: alertedNodesIdsArr.length,
        percent: Math.ceil((alertedNodesIdsArr.length / domain.nodesTotal) * 100),
      };
    });

    return fullDomains.sort((a, b) => a.title.localeCompare(b.title));
  },
);

export const getGtpMapLinks = createSelector(
  getGtpMapViewLinks,
  getGtpMapIssues,
  (links, issues) => {
    const fullLinks = links.map((link) => {
      let isError = false;
      let isDestNodeError = false;
      let isSourceNodeError = false;
      let isDestInterfaceError = false;
      let isSourceInterfaceError = false;

      issues.forEach((issue) => {
        if (!isError && issue.entityMapping.LINK.indexOf(link.id) !== -1) {
          isError = true;
        }
        if (!isDestNodeError && issue.entityMapping.NODE.indexOf(link.destNodeId) !== -1) {
          isDestNodeError = true;
        }
        if (!isSourceNodeError && issue.entityMapping.NODE.indexOf(link.sourceNodeId) !== -1) {
          isSourceNodeError = true;
        }
        if (!isDestInterfaceError && issue.entityMapping.INTERFACE.indexOf(link.destInterfaceId) !== -1) {
          isDestInterfaceError = true;
        }
        if (!isSourceInterfaceError && issue.entityMapping.INTERFACE.indexOf(link.sourceInterfaceId) !== -1) {
          isSourceInterfaceError = true;
        }
      });

      return {
        ...link,
        isError,
        isDestNodeError,
        isSourceNodeError,
        isDestInterfaceError,
        isSourceInterfaceError,
      };
    });
    return fullLinks;
  },
);

export const getGtpMapSelectedIssueEntities = createSelector(
  getGtpMapSelectedIssue,
  getGtpMapRegions,
  getGtpMapSites,
  getGtpMapLinks,
  (selectedIssue, regions, sites, links) => {
    if (!selectedIssue) {
      return EMPTY_ARRAY;
    }

    const entities = [];
    regions.forEach((reg) => {
      if (selectedIssue.entityMapping.REGION.indexOf(reg.serverId) !== -1) {
        entities.push(createRegionEntity(reg));
      }
    });

    links.forEach((link) => {
      if (selectedIssue.entityMapping.LINK.indexOf(link.id) !== -1) {
        entities.push(createLinkEntity(link));
      }
    });

    sites.forEach((site) => {
      site.nodes.forEach((node) => {
        let isNodeEntity = false;
        if (selectedIssue.entityMapping.NODE.indexOf(node.id) !== -1) {
          isNodeEntity = true;
        }
        if (!isNodeEntity && node.interfaces.length) {
          isNodeEntity = node.interfaces.some(
            (inter) => selectedIssue.entityMapping.INTERFACE.indexOf(inter.id) !== -1,
          );
        }
        if (!isNodeEntity) {
          const isLeftWing = site.cells.leftWing.some(
            (c) => selectedIssue.entityMapping.CELL.indexOf(c.id) !== -1 && c.relatedNodeId === node.id,
          );
          const isRightWing = site.cells.rightWing.some(
            (c) => selectedIssue.entityMapping.CELL.indexOf(c.id) !== -1 && c.relatedNodeId === node.id,
          );
          const isTopWing = site.cells.topWing.some(
            (c) => selectedIssue.entityMapping.CELL.indexOf(c.id) !== -1 && c.relatedNodeId === node.id,
          );
          isNodeEntity = isLeftWing || isRightWing || isTopWing;
        }

        if (isNodeEntity) {
          entities.push(createNodeEntity(site, node));
        }
      });

      site.links.forEach((link) => {
        if (selectedIssue.entityMapping.LINK.indexOf(link.id) !== -1) {
          entities.push(createLinkEntity(link));
        }
      });
    });

    return entities;
  },
);

export const getGtpMapRegionsHighestLevel = createSelector(
  getGtpMapRegions,
  (regions) => regions.filter((r) => r.level === 0),
);

// data section
export const getGeneralTpDataSection = createSelector(
  getGeneralTopologyMap,
  (gtpMap) => gtpMap.data || EMPTY_OBJECT,
);

export const getFetchGtpMapRegions = createSelector(
  getGeneralTpDataSection,
  (gtpData) => gtpData.fetchTopologyMapRegions || EMPTY_OBJECT,
);

export const isLoadingGtpMapRegions = createSelector(
  getFetchGtpMapRegions,
  (reg) => reg.isLoading,
);

export const getFetchGtpMapRegionsSitesList = createSelector(
  getGeneralTpDataSection,
  (gtpData) => gtpData.fetchTopologyMapRegionSitesList || EMPTY_OBJECT,
);

export const isLoadingGtpMapRegionsSitesList = createSelector(
  getFetchGtpMapRegionsSitesList,
  (regSites) => regSites.isLoading,
);

export const getFetchGtpMapRegionsSitesListData = createSelector(
  getFetchGtpMapRegionsSitesList,
  (regSites) => regSites.data,
);

export const getIsMapDataLoading = createSelector(
  isLoadingGtpMapRegions,
  isLoadingGtpMapRegionsSitesList,
  (isRegLoading, isSitesLoading) => isRegLoading || isSitesLoading,
);

export const getTMFetchTopologyAnomalies = createSelector(
  getGeneralTpDataSection,
  (gtpData) => gtpData.fetchTopologyAnomalies || EMPTY_OBJECT,
);

export const getTMFetchTopologyAnomaliesIsLoading = createSelector(
  getTMFetchTopologyAnomalies,
  (fetchTM) => fetchTM.isLoading,
);

export const getTMFetchTopologyAlerts = createSelector(
  getGeneralTpDataSection,
  (gtpData) => gtpData.fetchTopologyAlerts || EMPTY_OBJECT,
);

export const getTMFetchTopologyAlertsIsLoading = createSelector(
  getTMFetchTopologyAlerts,
  (fetchTM) => fetchTM.isLoading,
);

export const getTMTopologyIssuesIsLoading = createSelector(
  getTMFetchTopologyAnomaliesIsLoading,
  getTMFetchTopologyAlertsIsLoading,
  (anomaliesIsLoading, alertsIsLoading) => anomaliesIsLoading || alertsIsLoading,
);

export const getTMFetchMetricDataPoints = createSelector(
  getGeneralTpDataSection,
  (gtpData) => gtpData.fetchMetricDataPoints || EMPTY_OBJECT,
);
