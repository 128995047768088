// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setFileUploadProgress, setFileUploadName, setUploadedFileUrl, setUploadFileError} from 'bc/store/actions';
import {uploadFile} from 'bc/services/fileUploadToAwsService';
import './FileUploadToAws.module.scss';

class FileUploadToAws extends React.PureComponent {
  props: {
    sourceType: Object,
    setFileUploadProgress: Function,
    setFileUploadName: Function,
    setUploadedFileUrl: Function,
    setUploadFileError: Function,
    text?: string,
  };

  static defaultProps = {
    text: 'START',
  };

  uploadFileError = (/* err */) => {
    this.props.setUploadFileError();
    this.props.setFileUploadName('');
  };

  onChange = (e) => {
    if (e.target.files.length) {
      let {uploadPath} = this.props.sourceType;
      uploadPath = uploadPath[0] === '/' ? uploadPath.slice(1) : uploadPath;

      const file = e.target.files[0];
      this.props.setFileUploadProgress(0);
      this.props.setFileUploadName(file.name);
      this.props.setUploadedFileUrl({});
      uploadFile(
        file,
        this.props.sourceType.uploadBucket,
        uploadPath,
        this.props.setUploadedFileUrl,
        this.uploadFileError,
        this.props.setFileUploadProgress,
      );
    }
  };

  clicked = () => {
    document.getElementById('fileInput').click();
  };

  render() {
    return (
      <form styleName="root">
        <span
          role="presentation"
          onClick={this.clicked}
          automation-id={`sourceTypeActionButton-${this.props.sourceType.name}`}
        >
          {this.props.text}
        </span>
        <input
          id="fileInput"
          type="file"
          onChange={this.onChange}
          automation-id={`sourceTypeActionInput-${this.props.sourceType.name}`}
        />
      </form>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    sourceType: ownProps.sourceType,
  }),
  {
    setFileUploadProgress,
    setFileUploadName,
    setUploadedFileUrl,
    setUploadFileError,
  },
)(FileUploadToAws);
