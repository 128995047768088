// @flow
/* eslint-disable */
import React from 'react';
import {
  SorceIconAdobeAnalytics,
  SorceIconAmazonRedshift,
  SorceIconAmazonS3,
  SorceIconApacheKafka,
  SorceIconAWS,
  SorceIconDatabricks,
  SorceIconDirectory,
  SorceIconElasticSearch,
  SorceIconFile,
  SorceIconGoogle_Ads,
  SorceIconFacebook_Ads,
  SorceIconGoogle_Analytics,
  SorceIconGoogle_BigQuery,
  SorceIconGoogle_Storage,
  SorceIconInfluxDB,
  SorceIconKinesis,
  SorceIconMariaDB,
  SorceIconMongoDB,
  SorceIconmParticle,
  SorceIconMSSQL,
  SorceIconMySQL,
  SorceIconOracle,
  SorceIconParquet,
  SorceIconPostgres,
  SorceIconClickHouse,
  SorceIconCacti,
  SorceIconSage,
  SorceIconSalesforce,
  SorceIconSnowflake,
  SorceIconSplunk,
  SorceIconVictoriaMetrics,
  SorceIconSegment,
  SorceIconZabbix,
  SorceIconCoralogix,
  SorceIconTeradata,
  SorceIconAthena,
  SorceIconTimestream,
  SourceDirectoryAgent,
  SourceStatsD,
  SourceCodaHale,
  SourcePrometheus,
  SourceCollectD,
  SourceAnodotRelay,
  SourceRestApi,
  SourcePython,
  SourceElasticSearch,
  SourceVictoriaMetrics,
  SourceSplunk,
  SourceCoralogix,
  SourceAdtech,
  SourceEcommerce,
  SourceRideShare,
  SourceSiteTraffic,
  SorceIconEventhubs,
  SorceIconSolarwinds,
  SorceIconSNMP,
} from 'bc/svgs';

export const DEFAULT_QUERY_PARAMS = {
  searchQuery: undefined,
  status: undefined,
  type: undefined,
  owner: undefined,
  order: 'desc',
  sort: 'title',
};

export const STATUS = [
  {label: 'Live', value: 'running', id: 'running'},
  {label: 'Done', value: 'finished', id: 'finished'},
  {label: 'Paused', value: 'paused', id: 'paused'},
  {label: 'Completed', value: 'completed', id: 'completed'},
  {label: 'In Progress', value: 'initializing', id: 'initializing'},
  {label: 'Incomplete', value: 'new', id: 'new'},
  {label: 'Error', value: 'error', id: 'error'},
];

export const TABLE_TITLES = [
  {
    id: 0,
    name: 'title',
    title: 'Title',
    isSortable: true,
  },
  {
    id: 1,
    name: 'status',
    title: 'Status',
    isSortable: true,
  },
  {
    id: 2,
    name: 'owner',
    title: 'Owner',
    isSortable: true,
  },
];

export const getStreamsCount = (id, streams) => streams.filter((stream) => stream.dataSourceId === id).length;

export const getStreamCountText = (count) => {
  if (!count) {
    return 'No streams';
  }
  if (count === 1) {
    return '1 Stream';
  }
  return `${count} Streams`;
};

export const getOwnerName = (ownerId, users) => {
  const owner = ownerId && users.length ? users.find((user) => user._id === ownerId) : '';
  if (owner) {
    return `${owner.firstName} ${owner.lastName}`;
  }
  return null;
};

export const isViewStreamAllowed = (stream) => stream.schema && stream.schema.columns && stream.name;

const getErrorText = (status, failureMessage) => {
  switch (status) {
    case 'no_permission':
      return 'Anodot no longer has permission to access the data source';
    case 'invalid_query':
      return 'Stream query is invaid';
    case 'unknown_error':
      return 'an unknown error has occurred';
    case 'failed':
      return failureMessage;
    default:
  }
  return null;
};

export const getStreamChip = (stream) => {
  const {state, status, paused, family, failureMessage} = stream;
  if (family === 'agents') {
    switch (state) {
      case 'RUNNING':
        return {
          score: 200,
          style: 'live',
          text: 'Live',
        };
      case 'STOPPED':
      case 'EDITED':
      case 'DISCONNECTED':
        return {
          score: 210,
          style: 'paused',
          text: 'Paused',
        };
      case 'STARTING':
      case 'CONNECTING':
        return {
          score: 220,
          style: 'init',
          text: 'Initializing',
        };
      case 'START_ERROR':
      case 'RUN_ERROR':
      case 'STOP_ERROR':
        return {
          score: 290,
          style: 'error',
          text: 'Error',
          tooltip: getErrorText(status, failureMessage),
        };
      default:
        return {
          score: 299,
          style: '',
          text: state,
        };
    }
  } else {
    if (paused) {
      return {
        score: 110,
        style: 'paused',
        text: 'Paused',
      };
    }
    if (status === 'failed') {
      return {
        score: 190,
        style: 'error',
        text: 'Error',
        tooltip: getErrorText(status, failureMessage),
      };
    }
    switch (state) {
      case 'running':
        return {
          score: 100,
          style: 'live',
          text: 'Live',
        };
      case 'finished':
        return {
          score: 101,
          style: 'live',
          text: 'Done',
        };
      case 'complete':
        return {
          score: 120,
          style: 'init',
          text: 'Completed',
        };
      case 'initializing_p4':
        return {
          score: 140,
          style: 'init',
          text: 'In progress (5/5)',
          tooltip: 'Finalizing (5/5)',
        };
      case 'initializing_p3':
        return {
          score: 141,
          style: 'init',
          text: 'In progress (4/5)',
          tooltip: 'Streaming (4/5)',
        };
      case 'initializing_p2':
        return {
          score: 142,
          style: 'init',
          text: 'In progress (3/5)',
          tooltip: 'Learning the data (3/5)',
        };
      case 'initializing_p1':
        return {
          score: 143,
          style: 'init',
          text: 'In progress (2/5)',
          tooltip: 'Preparing the data (2/5)',
        };
      case 'initializing_p0':
        return {
          score: 144,
          style: 'init',
          text: 'In progress (1/5)',
          tooltip: 'Initializing (1/5)',
        };
      case 'new':
        return {
          score: 150,
          style: 'incomplete',
          text: 'Incomplete',
        };
      default:
        return {
          score: 199,
          style: '',
          text: state,
        };
    }
  }
};

export const getSourceIcon = (type) => {
  switch (type) {
    case 'adobe':
      return <SorceIconAdobeAnalytics />;
    case 'redshift':
      return <SorceIconAmazonRedshift />;
    case 's3':
      return <SorceIconAmazonS3 />;
    case 'kafka':
    case 'apache_kafka':
      return <SorceIconApacheKafka />;
    case 'aws_cur':
    case 'aws_usage':
      return <SorceIconAWS />;
    case 'databricks':
      return <SorceIconDatabricks />;
    case 'directory':
    case 'Monitoring':
      return <SorceIconDirectory />;
    case 'elastic':
      return <SorceIconElasticSearch />;
    case 'local_file':
      return <SorceIconFile />;
    case 'google_ads':
    case 'google_auctions':
      return <SorceIconGoogle_Ads />;
    case 'facebook_ads':
      return <SorceIconFacebook_Ads />;
    case 'google_analytics':
      return <SorceIconGoogle_Analytics />;
    case 'bigquery':
      return <SorceIconGoogle_BigQuery />;
    case 'google_storage':
      return <SorceIconGoogle_Storage />;
    case 'influx':
    case 'influx_db':
      return <SorceIconInfluxDB />;
    case 'kinesis':
      return <SorceIconKinesis />;
    case 'mariadb':
      return <SorceIconMariaDB />;
    case 'mongo':
    case 'mongo_db':
      return <SorceIconMongoDB />;
    case 'mparticle':
      return <SorceIconmParticle />;
    case 'mssql':
      return <SorceIconMSSQL />;
    case 'mysql':
      return <SorceIconMySQL />;
    case 'oracle':
      return <SorceIconOracle />;
    case 'athena':
      return <SorceIconParquet />;
    case 'psql':
    case 'postgres':
      return <SorceIconPostgres />;
    case 'clickhouse':
      return <SorceIconClickHouse />;
    case 'cacti':
      return <SorceIconCacti />;
    case 'sage':
      return <SorceIconSage />;
    case 'salesforce':
      return <SorceIconSalesforce />;
    case 'snowflake':
      return <SorceIconSnowflake />;
    case 'splunk':
      return <SorceIconSplunk />;
    case 'victoria':
      return <SorceIconVictoriaMetrics />;
    case 'segment':
      return <SorceIconSegment />;
    case 'zabbix':
      return <SorceIconZabbix />;
    case 'coralogix':
      return <SorceIconCoralogix />;
    case 'teradata':
      return <SorceIconTeradata />;
    case 'athena_sql':
      return <SorceIconAthena />;
    case 'timestream':
      return <SorceIconTimestream />;
    case 'directory_agent':
      return <SourceDirectoryAgent />;
    case 'statsd':
      return <SourceStatsD />;
    case 'anodot_relay':
      return <SourceAnodotRelay />;
    case 'collectd':
      return <SourceCollectD />;
    case 'prometheus':
      return <SourcePrometheus />;
    case 'codahale':
      return <SourceCodaHale />;
    case 'rest_api':
      return <SourceRestApi />;
    case 'python_sdk':
      return <SourcePython />;
    case 'elasticsearch':
      return <SourceElasticSearch />;
    case 'victoriametrics':
      return <SourceVictoriaMetrics />;
    case 'splunk_agent':
      return <SourceSplunk />;
    case 'coralogix':
      return <SourceCoralogix />;
    case 'adtechsample':
      return <SourceAdtech />;
    case 'ecomsample':
      return <SourceEcommerce />;
    case 'ridesample':
      return <SourceRideShare />;
    case 'analyticssample':
      return <SourceSiteTraffic />;
    case 'eventhubs':
      return <SorceIconEventhubs />;
    case 'solarwinds_agent':
      return <SorceIconSolarwinds />;
    case 'snmp_agent':
      return <SorceIconSNMP />;
    case 'psql_agent':
      return <SorceIconPostgres />;
  }
};
