import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'admin.cs-portal/store/selectors';
import {downloadCustomerMetricsFile} from 'admin.cs-portal/services/api';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import Button, {COLORS} from 'common/componentsV2/Button';
import CustomersDD from './CustomersDD';
import './CsPortalHeader.module.scss';

type PropTypes = {
  selectedCustomer: Object,
  selected: Object, // selected tab
};

@connect(
  (state) => ({
    selectedCustomer: selectors.getSelectedCustomer(state),
  }),
  {},
)
export default class CsPortalHeader extends React.PureComponent {
  props: PropTypes;

  state = {
    downloadingFile: false,
  };

  downloadFile = () => {
    const {selectedCustomer} = this.props;

    if (selectedCustomer && selectedCustomer.value) {
      this.setState({downloadingFile: true});
      downloadCustomerMetricsFile({userId: selectedCustomer.value}).promise.then((data) => {
        this.setState({downloadingFile: false});
        const arr = data;
        const byteArray = new Uint8Array(arr);
        const a = window.document.createElement('a');
        // use created anchor to body.
        a.href = window.URL.createObjectURL(new Blob([byteArray], {type: 'application/octet-stream'}));
        a.download = 'originTestDown.zip';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    }
  };

  render() {
    const {selectedCustomer} = this.props;
    const {downloadingFile} = this.state;

    return (
      <div styleName="headerContainer">
        <div styleName="header">
          <Title type={TITLE_TYPES.PAGE_TITLE}>CS Portal</Title>
          <CustomersDD />
        </div>
        {selectedCustomer &&
        selectedCustomer.value &&
        this.props.selected &&
        this.props.selected.value === 'metrics' ? (
          <div styleName="download-report-button">
            <Button
              text="Export Metrics"
              colorSchema={COLORS.GRAY_300}
              spinnerColor={COLORS.GRAY_500}
              onClick={this.downloadFile}
              isDisabled={downloadingFile}
              isLoading={downloadingFile}
              icon="icn-action16-download"
            />
          </div>
        ) : null}
      </div>
    );
  }
}
