// @flow
import React, {PureComponent} from 'react';
import {Modal} from 'react-bootstrap';
import {Table, Column, Cell} from 'fixed-data-table-2';
import Loader from 'common/components/Loader';
import {get, omit, isEmpty} from 'lodash';
import ReactResizeDetector from 'react-resize-detector';
import NotificationBar from 'common/components/NotificationBar';
import './DataTablePreviewModal.module.scss';

const TextCell = (props: any) => (
  <Cell {...omit(props, 'previewData')}>{props.previewData.rows[props.rowIndex][props.columnKey]}</Cell>
);

type PropTypes = {
  isOpen: boolean,
  onClose: Function,
  title: string,
  subTitle: string,
  streamsNames: string,
  created?: string,
  modified?: string,
  isLoading: Boolean,
  previewData: Object,
  isSmall: boolean,
};

const SIZE = 500;

class DataTablePreviewModal extends PureComponent {
  props: PropTypes;

  static defaultProps = {
    created: null,
    modified: null,
  };

  state: {
    tableWidth: SIZE,
    tableHeight: SIZE,
    columnWidths: [],
  };

  componentDidMount() {
    this.timeoutHandler1 = setTimeout(() => {
      // wait for the modal animation
      document.getElementsByClassName('modal')[0].addEventListener('click', this.rootClicked, false);
    }, 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutHandler1);
    clearTimeout(this.timeoutHandler2);
    document.getElementsByClassName('modal')[0].removeEventListener('click', this.rootClicked, false);
  }

  handleClose = (isOk, event) => {
    event.preventDefault(); // we don't want to close the editor (FuDimensionsAndMetricsEditor)
    this.props.onClose();
  };

  handleResize = (width, height) => {
    this.setState({
      tableWidth: width || SIZE,
      tableHeight: height || SIZE,
    });
  };

  handleColumnResize = (newColWidth, colKey) => {
    this.setState((prevState) => {
      const locColWidths = prevState.columnWidths || [];
      locColWidths[colKey] = newColWidth;
      return {
        columnWidths: locColWidths,
      };
    });
    this.forceUpdate();
  };

  setInitialTableSize = (elm) => {
    if (!elm) {
      return;
    }
    this.timeoutHandler2 = setTimeout(() => {
      this.handleResize(elm.clientWidth, elm.clientHeight);
      this.forceUpdate();
    });
  };

  rootClicked = (event) => {
    event.preventDefault(); // we don't want to close the editor (FuDimensionsAndMetricsEditor)
  };

  render() {
    const {
      isOpen,
      onClose,
      title,
      subTitle,
      previewData,
      isLoading,
      isSmall,
      created,
      modified,
      streamsNames,
    } = this.props;
    let className = 'modal-p80';
    let size = 'large';
    if (isSmall) {
      className = '';
      size = 'small';
    }
    return (
      <div>
        <Modal show={isOpen} dialogClassName={`bc ${className}`} onHide={onClose} bsSize={size} backdrop="static">
          <Modal.Header bsClass="bc-modal-header">
            <Modal.Title>
              <span>{title}</span>
            </Modal.Title>
            <button
              type="button"
              className="btn btn-flat btn-icon-36 btn-secondary"
              onClick={(event) => this.handleClose(false, event)}
            >
              <i className="icon icn-icon-table-delete" />
            </button>
          </Modal.Header>

          <Modal.Body>
            <div styleName="root">
              <NotificationBar />

              <div styleName="file-name">{subTitle}</div>
              {created ? <div styleName="created">{created}</div> : null}
              {modified ? <div styleName="created">{modified}</div> : null}
              {streamsNames ? <div styleName="created">{streamsNames}</div> : null}

              {isLoading !== false && <Loader size="small" />}

              {!isLoading && isEmpty(previewData) && <div styleName="table no-results">Table is empty</div>}

              {!isLoading && !isEmpty(previewData) && (
                <div styleName="table" id="file-preview-table-container" ref={this.setInitialTableSize}>
                  <ReactResizeDetector handleWidth handleHeight onResize={this.handleResize} />
                  <Table
                    rowHeight={20}
                    rowsCount={get(previewData, 'rows.length', 0)}
                    headerHeight={20}
                    isColumnResizing={false}
                    onColumnResizeEndCallback={this.handleColumnResize}
                    width={get(this.state, 'tableWidth', SIZE)}
                    height={get(this.state, 'tableHeight', SIZE)}
                  >
                    {get(previewData, 'header', []).map((r, i) => (
                      <Column
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        columnKey={i}
                        header={<Cell>{r}</Cell>}
                        width={get(this.state, 'columnWidths', [])[i] || 100}
                        minWidth={20}
                        allowCellsRecycling
                        fixed={false}
                        isResizable
                        cell={<TextCell previewData={previewData} />}
                      />
                    ))}
                  </Table>
                </div>
              )}
            </div>
          </Modal.Body>

          <Modal.Footer />
        </Modal>
      </div>
    );
  }
}

export default DataTablePreviewModal;
