// @flow
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@material-ui/core';
import {cloneDeep, get, isEmpty, isEqual} from 'lodash';
import {
  fetchInfluencingMetricsInfo as fetchInfluencingMetricsInfoAction,
  initiateValidateSelectedAlertConditions,
  setSelectedAlertInfluencingMetrics,
} from 'alerts.management/store/actions';
import {influencingMetricsInfo as influencingMetricsInfoSelector} from 'alerts.management/store/selectors';
import {
  getEmptyExpression,
  getEmptyTree,
  getSearchObjectOriginExpression,
  getSearchObjectPropertyExpression,
  getServerTreesModelFromCompositeDefinition,
  getTreesDisplayPropertiesFromCompositeDefinition,
} from 'common/utils/angularServices';
import {getUniqueId} from 'common/utils/guid';
import {getRootExpressionWithGroupByFunction} from 'metrics/services/compositeService';
import SimpleMeasureDropdown from 'metrics/components/SimpleMeasureDropdown';
import Button, {COLORS, HEIGHTS} from 'common/componentsV2/Button';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import './WhenSection.module.scss';
import SelectAndt, {
  DIRECTION_RIGHT_NO_OFFSET,
  THEME_TRANSPARENT,
  TYPE_SIMPLE,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import MetricExplorerPopup from 'metrics/components/metricExplorer/MetricExplorerPopup';
import {getIsMetricExplorerModalOpen, getSelectedTreeRaw} from 'metrics/store/selectors';
import {
  clearAllMetricsExplorer,
  initExpressionTreeModel,
  retrieveStoredState,
  setMetricExplorerModalOpen,
  storeCurrentState,
} from 'metrics/store/actions';

type PropTypes = {
  id: String,
  influencingMetrics: Object,
  alertTreeModel: Object,
};

const DdlButton = ({text}: {text: String}) => {
  if (!text) {
    return (
      <div styleName="what-empty">
        <span>Select a Measure</span>
        <i className="icon icn-arrow16-triangledown" />
      </div>
    );
  }

  return <div styleName="what-with-value">{text}</div>;
};

const PropertyChip = ({text, onRemove}: {text: String, onRemove: Function}) => (
  <Box display="inline-flex" css={{marginTop: '1px', marginBottom: '1px'}}>
    <InnerChip expression={{label: text}} onRemoveClicked={onRemove} type="dimension" />
  </Box>
);

const AdvancedExpressionButton = ({onClick}: {onClick: Function}) => (
  <div styleName="advanced-button">
    <Button
      text="Advanced Expression"
      colorSchema={COLORS.BLUE_500}
      height={HEIGHTS.TIGHT28}
      icon="icn-action16-edit"
      onClick={onClick}
    />
  </div>
);

const WhenSection = ({id, influencingMetrics, alertTreeModel}: PropTypes) => {
  const lastAlertTreeModelRef = useRef();
  const dispatch = useDispatch();

  const isMetricExplorerOpen = useSelector(getIsMetricExplorerModalOpen);

  const [displayMeasure, setDisplayMeasure] = useState(null);
  const [measureGroupByAggregation, setMeasureGroupByAggregation] = useState('Sum');
  const [matchPropertiesOptions, setMatchPropertiesOptions] = useState(null);
  const [filteredMpOptions, setFilteredMpOptions] = useState(null);
  const [showByDimSection, setShowByDimSection] = useState(false);
  const [shouldOverrideMatchingProps, setShouldOverrideMatchingProps] = useState(false);
  const [isSimpleMode, setIsSimpleMode] = useState(true);
  const [isSimpleDdlOpen, setIsSimpleDdlOpen] = useState(false);
  const [storedAlertTreeModel, setStoredAlertTreeModel] = useState(null);
  const influencingMetricsInfo = useSelector(influencingMetricsInfoSelector)[id];
  const expressionFromExpressionBuilder = useSelector(getSelectedTreeRaw);

  const isSimpleModeExpressionTreeModel = (expTreeModel) => {
    const isSimpleSearchObjectExpression = (exp) => {
      if (exp.length === 1 && exp[0].type === 'property' && exp[0].key === 'what') {
        return true;
      }
      return (
        exp.length === 2 &&
        ((exp[0].type === 'property' &&
          exp[0].key === 'what' &&
          exp[1].type === 'origin' &&
          exp[1].originType.toLowerCase() === 'stream') ||
          (exp[1].type === 'property' &&
            exp[1].key === 'what' &&
            exp[0].type === 'origin' &&
            exp[0].originType.toLowerCase() === 'stream'))
      );
    };

    const {root} = expTreeModel.expressionTree;
    if (root.type === 'metric') {
      const isSimple = isSimpleSearchObjectExpression(root.searchObject.expression);
      return {
        isSimple,
        node: root,
      };
    }
    if (root.type === 'function' && root.function === 'groupBy' && root.children[0].type === 'metric') {
      const isSimple = isSimpleSearchObjectExpression(root.children[0].searchObject.expression);
      const aggregationParam = root.parameters.find((p) => p.name === 'Aggregation');
      return {
        isSimple,
        node: root.children[0],
        groupByAggregationValue: aggregationParam ? aggregationParam.value : null,
      };
    }
    return {
      isSimple: false,
    };
  };

  const fetchInfluencingMetricsInfo = (infTreeModel) => {
    const influencingMetricsTreeModel = infTreeModel || cloneDeep(influencingMetrics.expressionTreeModel);
    if (!isEmpty(influencingMetricsTreeModel) && !isEmpty(alertTreeModel)) {
      setMatchPropertiesOptions(null);
      const simpleInfluencingExpTreeModel = isSimpleModeExpressionTreeModel(influencingMetricsTreeModel);
      if (simpleInfluencingExpTreeModel.isSimple) {
        influencingMetricsTreeModel.expressionTree.root = simpleInfluencingExpTreeModel.node;
      }
      if (infTreeModel) {
        setShouldOverrideMatchingProps(true);
      }
      if (influencingMetricsTreeModel) {
        dispatch(
          fetchInfluencingMetricsInfoAction(
            {
              influencingMetricsTreeModel,
              alertTreeModel: isMetricExplorerOpen ? storedAlertTreeModel : alertTreeModel,
            },
            id,
          ),
        );
      }
    }
  };

  // on mounting
  useEffect(() => {
    fetchInfluencingMetricsInfo();
  }, []);

  // on alertTreeModel - change: clean influencing metrics condition
  useEffect(() => {
    if (
      lastAlertTreeModelRef.current &&
      !isEqual(alertTreeModel, lastAlertTreeModelRef.current && !isMetricExplorerOpen) &&
      !isMetricExplorerOpen
    ) {
      lastAlertTreeModelRef.current = alertTreeModel;
      dispatch(initiateValidateSelectedAlertConditions());
      if (!isMetricExplorerOpen && influencingMetrics.expressionTreeModel) {
        dispatch(
          fetchInfluencingMetricsInfoAction(
            {
              influencingMetricsTreeModel: influencingMetrics.expressionTreeModel,
              alertTreeModel,
            },
            id,
          ),
        );
      }
    }
    if (!lastAlertTreeModelRef.current) {
      lastAlertTreeModelRef.current = alertTreeModel;
    }
  }, [alertTreeModel]);

  useEffect(() => {
    const what = get(influencingMetricsInfo, 'data.what', null);
    const matchingProperties = get(influencingMetricsInfo, 'data.matchingProperties', []);
    const totalMet = get(influencingMetricsInfo, 'data.total', 0);
    const isLoading = get(influencingMetricsInfo, 'isLoading', true);
    if (!isLoading) {
      setDisplayMeasure(what);
      setMatchPropertiesOptions((matchingProperties || []).map((p) => ({value: p, label: p})));
      setShowByDimSection(what && totalMet !== 1);
      if (shouldOverrideMatchingProps) {
        setShouldOverrideMatchingProps(false);

        const simpleInfluencingExpTreeModel = isSimpleModeExpressionTreeModel(influencingMetrics.expressionTreeModel);
        if (
          simpleInfluencingExpTreeModel.isSimple &&
          matchingProperties &&
          matchingProperties.length &&
          totalMet !== 1
        ) {
          let groupByPropsValue = '{"properties":[';
          matchingProperties.forEach((mp, index) => {
            const comma = index === matchingProperties.length - 1 ? '' : ',';
            groupByPropsValue = `${groupByPropsValue}"${mp}"${comma}`;
          });
          groupByPropsValue += ']}';

          const {node} = simpleInfluencingExpTreeModel;

          const influencingMetricsTreeModel = cloneDeep(influencingMetrics.expressionTreeModel);
          influencingMetricsTreeModel.expressionTree.root = getRootExpressionWithGroupByFunction(
            measureGroupByAggregation,
            groupByPropsValue,
            cloneDeep(node),
          );
          dispatch(
            setSelectedAlertInfluencingMetrics({
              expressionTreeModel: influencingMetricsTreeModel,
              matchingProperties: matchingProperties || [],
              id,
            }),
          );
        } else {
          dispatch(
            setSelectedAlertInfluencingMetrics({
              matchingProperties: matchingProperties || [],
              id,
            }),
          );
        }
      }
    }
  }, [influencingMetricsInfo]);

  useEffect(() => {
    const matchingProperties = get(influencingMetrics, 'matchingProperties', []);

    if (!matchPropertiesOptions || matchPropertiesOptions.length === 0) {
      setFilteredMpOptions(null);
    } else {
      setFilteredMpOptions(matchPropertiesOptions.filter((p) => (matchingProperties || []).indexOf(p.value) === -1));
    }
  }, [influencingMetrics, matchPropertiesOptions]);

  useEffect(() => {
    const expressionTreeModel = get(influencingMetrics, 'expressionTreeModel', null);
    if (expressionTreeModel) {
      const simpleInfluencingExpTreeModel = isSimpleModeExpressionTreeModel(expressionTreeModel);

      if (simpleInfluencingExpTreeModel.isSimple !== isSimpleMode) {
        setIsSimpleMode(simpleInfluencingExpTreeModel.isSimple);
      }
    } else if (!isSimpleMode) {
      setIsSimpleMode(true);
    }
  }, [influencingMetrics]);

  const onWhatSelect = (val) => {
    const expression = getEmptyExpression();
    const whatExpression = getSearchObjectPropertyExpression('what', val.measure, true);
    const streamExpression = val.streamId ? getSearchObjectOriginExpression('@Stream', val.streamId, true) : null;
    expression.searchObject.expression.push(whatExpression);
    if (streamExpression) {
      expression.searchObject.expression.push(streamExpression);
    }

    const expressionTreeModelDefaults = {
      displayOnly: true,
      excludeComposites: true,
      scalarTransforms: null,
      name: null,
      mtype: 'ALERT',
      namingSchema: 'COMPOSITE_V2',
    };

    const curTreeModel = getEmptyTree(getUniqueId(), expressionTreeModelDefaults, null, null, expression);
    dispatch(
      setSelectedAlertInfluencingMetrics({
        expressionTreeModel: curTreeModel,
        matchingProperties: [],
        id,
      }),
    );
    fetchInfluencingMetricsInfo(curTreeModel);

    setDisplayMeasure(val.measure);

    const aggregationValues = {
      sum: 'Sum',
      average: 'Avg',
    };
    setMeasureGroupByAggregation(aggregationValues[val.aggregation] || 'Sum');
  };

  const dispatchModifiedTreeModel = (matchProps) => {
    let treeModel = null;
    const simpleInfluencingExpTreeModel = isSimpleModeExpressionTreeModel(influencingMetrics.expressionTreeModel);
    if (simpleInfluencingExpTreeModel.isSimple) {
      treeModel = cloneDeep(influencingMetrics.expressionTreeModel);
      treeModel.expressionTree.root = simpleInfluencingExpTreeModel.node;

      if (matchProps.length) {
        let groupByPropsValue = '{"properties":[';
        matchProps.forEach((mp, index) => {
          const comma = index === matchProps.length - 1 ? '' : ',';
          groupByPropsValue = `${groupByPropsValue}"${mp}"${comma}`;
        });
        groupByPropsValue += ']}';

        treeModel.expressionTree.root = getRootExpressionWithGroupByFunction(
          simpleInfluencingExpTreeModel.groupByAggregationValue || measureGroupByAggregation,
          groupByPropsValue,
          simpleInfluencingExpTreeModel.node,
        );
      }
    }

    if (treeModel) {
      dispatch(
        setSelectedAlertInfluencingMetrics({
          expressionTreeModel: treeModel,
          matchingProperties: matchProps || [],
          id,
        }),
      );
    } else {
      dispatch(
        setSelectedAlertInfluencingMetrics({
          matchingProperties: matchProps || [],
          id,
        }),
      );
    }
  };

  const onChipRemoveClicked = (chip) => {
    const matchingProperties = (influencingMetrics.matchingProperties || []).filter((p) => p !== chip.label);
    dispatchModifiedTreeModel(matchingProperties);
  };

  const onAddMatchingProperty = (item) => {
    const matchingProperties = (influencingMetrics.matchingProperties || []).concat([item.value]);
    dispatchModifiedTreeModel(matchingProperties);
  };

  const onAdvancedExpressionClicked = () => {
    dispatch(setMetricExplorerModalOpen(id));
    setIsSimpleDdlOpen(false);

    let serverTreesModel = {};

    // eslint-disable-next-line no-unused-vars
    let treesDisplayProperties = {};

    const curTreeModel = influencingMetrics.expressionTreeModel;
    if (curTreeModel) {
      curTreeModel.id = curTreeModel.id || getUniqueId();
      serverTreesModel = getServerTreesModelFromCompositeDefinition(curTreeModel);
      // eslint-disable-next-line no-unused-vars
      treesDisplayProperties = getTreesDisplayPropertiesFromCompositeDefinition(curTreeModel);
    }

    const expression = ((Object.values(serverTreesModel) || [])[0] || {}).expressionTree;

    // Since expression builder modal is working on the same expression tree store, we need to store the alert's
    // expression tree in a temp storage and replace it with the influencing metric expression
    // before doing that, keep a local copy of the expression
    setStoredAlertTreeModel(alertTreeModel);
    dispatch(storeCurrentState());
    if (typeof expression === 'undefined') {
      dispatch(clearAllMetricsExplorer());
    } else {
      dispatch(initExpressionTreeModel({expressionTree: expression}));
    }
  };

  const cleanExpressionRoot = (val) => {
    if (val.root.type === 'function' && val.root.function === '') {
      return {
        root: val.root.children[0],
      };
    }
    return val;
  };

  const handleSaveInfluencingMetric = () => {
    const returnExpression = cloneDeep(expressionFromExpressionBuilder);
    const retObj = {
      compositeCount: 0,
      displayOnly: true,
      expressionTree: cleanExpressionRoot(returnExpression),
      filter: {
        function: 'alphanumeric',
        parameters: [
          {
            name: 'Top N',
            value: 10,
          },
        ],
        children: [],
        id: getUniqueId(),
        type: 'function',
      },
      id: getUniqueId(),
      name: null,
      excludeComposites: true,
      mtype: 'ALERT',
      namingSchema: 'COMPOSITE_V2',
      uiIndex: 0,
    };

    dispatch(
      setSelectedAlertInfluencingMetrics({
        expressionTreeModel: retObj,
        matchingProperties: [],
        id,
      }),
    );
    fetchInfluencingMetricsInfo(retObj);
    dispatch(retrieveStoredState());
    dispatch(setMetricExplorerModalOpen(false));
  };

  return (
    <Fragment>
      <MetricExplorerPopup
        onClose={() => dispatch(setMetricExplorerModalOpen(false))}
        isOpen={isMetricExplorerOpen === id}
        onSave={handleSaveInfluencingMetric}
      />
      <div className="text16reg lineHeight_16 mb_0-75">When:</div>
      <Box display="inline-flex">
        {isSimpleMode ? (
          <SimpleMeasureDropdown
            button={<DdlButton text={displayMeasure} />}
            onSelect={onWhatSelect}
            propAndValPrefix="simpleMeasureDD"
            secondLevelTitle="Select a Stream"
            width={312}
            footerComponent={
              <FormDdlActionBar isVisible ApplyButtonText="Advanced Expression" onApply={onAdvancedExpressionClicked} />
            }
            usePropsForOpenState
            onToggle={setIsSimpleDdlOpen}
            isOpen={isSimpleDdlOpen}
          />
        ) : (
          <AdvancedExpressionButton onClick={onAdvancedExpressionClicked} />
        )}
      </Box>
      {showByDimSection ? (
        <Fragment>
          <Box mr={0.25} ml={0.5} display="inline-flex">
            by
          </Box>
          {(influencingMetrics.matchingProperties || []).map((p) => (
            <PropertyChip key={`matchProp-${p}`} text={p} onRemove={onChipRemoveClicked} />
          ))}
          {filteredMpOptions && filteredMpOptions.length ? (
            <Box display="inline-flex" height={32} position="relative" alignItems="center" top={-5}>
              <SelectAndt
                automationId="add-matching-property"
                type={TYPE_SIMPLE}
                theme={THEME_TRANSPARENT}
                onChange={onAddMatchingProperty}
                options={filteredMpOptions}
                customComponent={{
                  DropdownIndicator: (p) => (
                    <i {...p.innerProps} styleName="add-match-property-button" className="icon icn-action16-plusa" />
                  ),
                }}
                direction={DIRECTION_RIGHT_NO_OFFSET}
                optionHeight={40}
                menuWidth={200}
                noAutoReorder
              />
            </Box>
          ) : null}
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default WhenSection;
