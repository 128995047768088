import {combineReducers} from 'redux';
import {emptyReducer, reduceArrayItem} from 'common/utils/reducers';
// import {makeSupportLink} from 'common/utils/browserLoacation';
import * as actions from './actions';

// This JSON represent the menu items.
// type: 'menuItem' - for an icon button.
//       'menuSeparator' - for a separator (doesn't  need any other property).
//       'menuParent' - for a menu icon, needs to have items.
//       'menuUser' - for the user menu, needs tahe logout link in the link property.
// position: top/bottom.
// title: string.
// link: string.
// isNewTab: boolean.
// angularService: {serviceName: '', serviceMethod: ''} - used to refer to an angular function.
// iconName: string - the icon css class name
// hasBadge: boolean.
// hasPlus: boolean,
// authorizedFor: Array of Strings - for list of rolls.
//          Empty Array - for none.
//          Avoid this property - for all.
// isHideOnMobile: boolean.
// items: Array of objects with title and link properties.
//        Is used when the type is: 'menuParent'.
// hasCount: boolean.
const initialState = [
  {
    id: 'ALERTS_CONSOLE',
    type: 'menuItem',
    position: 'top',
    title: 'Alert Console',
    links: ['#!/r/alerts-console'],
    iconName: 'icn-nav24-alertconsole',
    hasBadge: true,
    authorizedFor: ['customer-read-only', 'customer-user', 'customer-admin'],
  },
  {
    id: 'ANOMALIES',
    type: 'menuItem',
    position: 'top',
    title: 'Anomalies',
    links: ['#!/anomalies'],
    iconName: 'icn-nav24-anomalies',
    authorizedFor: ['customer-read-only', 'customer-user', 'customer-admin'],
  },
  {
    id: 'METRIC_SEARCH',
    type: 'menuItem',
    position: 'top',
    title: 'Metric Explorer',
    links: ['#!/metrics/composite'],
    linksExact: true,
    iconName: 'icn-nav24-metricsearch',
    authorizedFor: ['customer-user', 'customer-admin'],
  },
  {
    id: 'DASHBOARDS',
    type: 'menuItem',
    position: 'top',
    title: 'Dashboards',
    links: ['#!/dashboards'],
    iconName: 'icn-nav24-dashboards',
    authorizedFor: ['customer-read-only', 'customer-user', 'customer-admin'],
  },
  {
    id: 'TOPOLOGY',
    type: 'menuItemTopology',
    position: 'top',
    title: 'Topology',
    links: ['#!/r/topology'],
    authorizedFor: ['customer-read-only', 'customer-user', 'customer-admin'],
  },
  {
    id: 'CATALOG',
    type: 'menuItem',
    position: 'bottom',
    title: 'Catalog',
    iconName: 'icn-Catalog24',
    links: ['#!/r/integrations'],
    authorizedFor: window.isOnPremise ? [] : ['customer-user', 'customer-admin'],
  },
  {
    id: 'INTEGRATIONS',
    type: 'menuParent',
    position: 'bottom',
    title: 'Integrations',
    iconName: 'icn-nav24-integrations',
    isHideOnMobile: true,
    items: [
      {
        id: 'STREAMS',
        title: 'Sources',
        links: ['#!/r/bc/data-manager', '#!/r/bc/sources-types', '#!/r/bc/data-streams'],
        authorizedFor: window.isOnPremise
          ? ['customer-admin']
          : ['customer-read-only', 'customer-user', 'customer-admin'],
      },
      {
        id: 'CHANNELS',
        title: 'Channels',
        links: ['#!/channels', '#!/channels/instance/email'],
        authorizedFor: ['customer-user', 'customer-admin'],
      },
    ],
  },
  {
    id: 'MANAGEMENT',
    type: 'menuParent',
    position: 'bottom',
    title: 'Management',
    iconName: 'icn-nav24-management',
    isHideOnMobile: true,
    items: [
      {
        id: 'CUSTOMERS',
        title: 'Customers',
        links: ['#!/r/admin/customers'],
        authorizedFor: ['anodot-user', 'anodot-admin'],
      },
      {
        id: 'ALERT_MANAGER',
        title: 'Alerts',
        links: ['#!/r/alert-manager'],
        authorizedFor: ['customer-read-only', 'customer-user', 'customer-admin'],
        hasCount: true,
      },
      {
        id: 'COMPOSITE_MANAGEMENT',
        title: 'Composites',
        links: ['#!/metrics/composite/management'],
        authorizedFor: ['customer-user', 'customer-admin'],
      },
      {
        id: 'EVENT_SOURCES',
        title: 'Events',
        links: ['#!/r/user/events'],
        authorizedFor: ['customer-admin'],
      },
      {
        id: 'USERS_AND_GROUPS',
        title: 'Users & Groups',
        links: ['#!/r/admin/users-groups'],
        authorizedFor: ['customer-admin', 'anodot-user', 'anodot-admin'],
      },
      {
        id: 'ASSET_MANAGEMENT',
        title: 'Asset Management',
        links: ['#!/r/asset-management'],
        authorizedFor: ['customer-read-only', 'customer-user', 'customer-admin'],
      },
      {
        id: 'FEATURE_FLAGS',
        title: 'Feature Flags',
        links: ['#!/r/admin/features-flags'],
        authorizedFor: ['anodot-user', 'anodot-admin'],
      },
      {
        id: 'FEATURE_TRACKER',
        title: 'Feature Tracker',
        links: ['#!/r/admin/train-tracker'],
        authorizedFor: ['anodot-admin'],
      },
      {
        id: 'CS_PORTAL',
        title: 'CS Portal',
        links: ['#!/r/admin/cs-portal'],
        authorizedFor: ['anodot-admin'],
      },
      {
        id: 'EMAIL_DIGEST',
        title: 'Email Digest',
        links: ['#!/r/admin/email-digest'],
        authorizedFor: ['anodot-admin'],
      },
      {
        id: 'ACTIVITY_LOG',
        title: 'Activity Log',
        links: ['#!/r/admin/activity-log'],
        authorizedFor: ['customer-admin'],
      },
    ],
  },
  {
    id: 'SETTINGS',
    type: 'menuUser',
    position: 'bottom',
    links: ['#!/r/user/settings'],
  },
];

const red = (item, payload) => ({...item, ...payload});

const appNavBar = (state = initialState, {type, payload}) => {
  switch (type) {
    case actions.saveUrlWithParams.TYPE:
      return state.map((item) => {
        if (item.links) {
          let oldBaseUrl = payload.oldURL.split('#!/')[1];
          let newBaseUrl = payload.newURL ? payload.newURL.split('#!/')[1] : null;
          if (oldBaseUrl) {
            // eslint-disable-next-line prefer-destructuring
            oldBaseUrl = oldBaseUrl.split('?')[0];
            oldBaseUrl = `#!/${oldBaseUrl}`;
          } else {
            return item;
          }
          if (newBaseUrl) {
            // eslint-disable-next-line prefer-destructuring
            newBaseUrl = newBaseUrl.split('?')[0];
            newBaseUrl = `#!/${newBaseUrl}`;
          }

          // Navigation to new dashboards
          if (
            item.id === 'DASHBOARDS' &&
            payload.newURL.includes('/r/dashboards') &&
            payload.oldURL.includes('/#!/dashboards')
          ) {
            return {...item, lastVisitedurl: payload.oldURL};
          }

          const lastVisitedurl = oldBaseUrl === newBaseUrl ? payload.newURL : payload.oldURL;

          // Never save urls for new dashboards
          if (item.id === 'DASHBOARDS' && lastVisitedurl.includes('/r/dashboards')) {
            return item;
          }

          if (oldBaseUrl === item.links[0]) {
            return {...item, lastVisitedurl};
          }
        }
        return item;
      });
    case actions.updateNavBarItemById.TYPE: {
      const mainItemIndex = state.findIndex((item) => item.id === payload.id);
      const subItemIndex =
        mainItemIndex !== -1 && state[mainItemIndex].items
          ? state[mainItemIndex].items.findIndex((item) => item.id === payload.subId)
          : -1;

      if (mainItemIndex !== -1 && subItemIndex === -1) {
        return reduceArrayItem(red, state, mainItemIndex, payload.navItemProps);
      }
      if (mainItemIndex !== -1 && subItemIndex !== -1) {
        return reduceArrayItem(red, state, mainItemIndex, {
          items: reduceArrayItem(red, state[mainItemIndex].items, subItemIndex, payload.navItemProps),
        });
      }

      return state;
    }
    default:
      return state;
  }
};

const viewsReducer = combineReducers({
  items: appNavBar,
});

export default combineReducers({
  views: viewsReducer,
  data: emptyReducer,
});
