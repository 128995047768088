import {combineReducers} from 'redux';
import {
  createInitialConnection,
  fetchChannels,
  postChannel,
  putChannel,
  testChannel,
} from 'alerts.channels/store/actions';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';

const EMPTY_ARRAY = [];

const channelsReducer = combineReducers({
  fetchChannels: makeAsyncReducer(fetchChannels, {
    includeUpdateAt: true,
    shouldDestroyData: false,
    defaultData: EMPTY_ARRAY,
  }),
  postChannel: makeAsyncReducer(postChannel, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  testChannel: makeAsyncReducer(testChannel, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  putChannel: makeAsyncReducer(putChannel, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  createInitialConnection: makeAsyncReducer(createInitialConnection, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
});

export default channelsReducer;
