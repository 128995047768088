// @flow
import React from 'react';
import {connect} from 'react-redux';
import Tooltip from 'common/componentsV2/Tooltip';
import {isFinite} from 'lodash';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {Box, Grid} from '@material-ui/core';
import * as selectors from 'alerts.management/store/selectors';
import * as actions from 'alerts.management/store/actions';
import {alertConditionTypes} from 'alerts.management/services/alertsService';
import TooltipArea from 'common/componentsV2/TooltipArea';
import Checkbox from 'common/componentsV2/Checkbox';
import BaloonMessage from 'common/componentsV2/BaloonMessage';
import InputAuto from '../simulationArea/utils/InputAuto';
import FieldError from '../simulationArea/FieldError';

type PropTypes = {
  staticThreshold: Object,
  onChange: Function,
};

const lowerBounds = [
  {
    desc: 'smaller than',
    text: <span>Less than</span>,
    value: 'LOWER',
  },
  {
    desc: 'smaller than or equal to',
    text: <span>Less than or equals to</span>,
    value: 'LOWER_EQUAL',
  },
];

const upperBounds = [
  {
    desc: 'larger than',
    text: <span>Greater than</span>,
    value: 'UPPER',
  },
  {
    desc: 'larger than or equal to',
    text: <span>Greater than or equals to</span>,
    value: 'UPPER_EQUAL',
  },
];

const getStringValue = (val) => {
  if (val !== undefined && val !== null) {
    return val.toString();
  }
  return '';
};

class StaticThresholdCondition extends React.PureComponent {
  props: PropTypes;

  constructor(props) {
    super(props);
    this.state = {
      isInvalidValues: false,
      minCheckbox: props.staticThreshold.minValue !== null && props.staticThreshold.minValue !== undefined,
      maxCheckbox: props.staticThreshold.maxValue !== null && props.staticThreshold.maxValue !== undefined,
      disabledMinValue: '',
      disabledMaxValue: '',
      messageOpened: true,
    };
  }

  componentDidMount() {
    this.setValidity(this.props.staticThreshold.maxValue, this.props.staticThreshold.minValue);
  }

  setValidity = (maxValue, minValue) => {
    const maxVal = parseInt(maxValue, 10);
    const minVal = parseInt(minValue, 10);
    let isInvalidValues = false;

    let isRequired = false;

    if (isFinite(minVal) && isFinite(maxVal)) {
      isInvalidValues = minVal > maxVal;
    }

    if (!isFinite(minVal) && !isFinite(maxVal)) {
      isRequired = true;
    }

    this.setState({isInvalidValues, isRequired});
  };

  parseValue = (val) => (Number.isFinite(parseFloat(val)) ? parseFloat(val) : null);

  handleBlurMax = (e) => {
    const val = this.parseValue(e.target.value);
    this.props.onChange({maxValue: val});
    this.setValidity(val, this.props.staticThreshold.minValue);
  };

  handleBlurMin = (e) => {
    const val = this.parseValue(e.target.value);
    this.props.onChange({minValue: val});
    this.setValidity(this.props.staticThreshold.maxValue, val);
  };

  handleBoundChange = (isUpBounds, value) => {
    const toUpdate = {};
    if (isUpBounds) {
      toUpdate.upperBound = value;
    } else {
      toUpdate.lowerBound = value;
    }
    this.props.onChange(toUpdate);
  };

  render() {
    const {staticThreshold} = this.props;
    const isInvalid = this.state.isInvalidValues || this.state.isRequired;

    const ttMesage = this.state.isInvalidValues ? (
      <span>
        Upper threshold is smaller <br /> than lower threshold
      </span>
    ) : (
      <span>
        At least one threshold <br /> is required
      </span>
    );

    const lowerBound = staticThreshold.lowerBound || lowerBounds[1].value;
    const upperBound = staticThreshold.upperBound || upperBounds[1].value;

    return (
      <TooltipArea automationId="staticThresholdContainer" text="Tooltip content">
        {() => (
          <Box position="relative">
            <Box
              component="input"
              width={0}
              height={0}
              border={0}
              fontSize={0}
              position="absolute"
              name="staticThresholdsFilter"
            />
            {this.state.messageOpened && !this.state.minCheckbox && !this.state.maxCheckbox && (
              <Box position="absolute" top={-110} left="calc((100% - 253px) / 2)">
                <BaloonMessage
                  title="Set at least one threshold"
                  position="top"
                  width={253}
                  onClose={() => this.setState({messageOpened: false})}
                >
                  A static alert needs at least one threshold to function and show you an estimation
                </BaloonMessage>
              </Box>
            )}
            <div className="text16reg lineHeight_16 mb_1-5">Alert if the metric value is</div>
            <Grid container spacing={4} direction="column">
              <Grid item container justify="space-between" alignItems="center" automation-id="aboveThresholdContainer">
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <Box mr={1.5}>
                      <Checkbox
                        automationId="maxCheckboxStatic"
                        isChecked={this.state.maxCheckbox}
                        onChange={() => {
                          this.setState(
                            (state) => ({
                              maxCheckbox: !state.maxCheckbox,
                              ...(state.maxCheckbox ? {disabledMaxValue: this.props.staticThreshold.maxValue} : {}),
                            }),
                            () =>
                              this.props.onChange({
                                maxValue: this.state.maxCheckbox ? this.state.disabledMaxValue : '',
                              }),
                          );
                        }}
                      />
                    </Box>
                    <ThresholdBounds
                      isEnabled={this.state.maxCheckbox}
                      isUpBounds
                      bound={upperBound}
                      onSelect={this.handleBoundChange}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Tooltip content={isInvalid && this.state.maxCheckbox && ttMesage}>
                    <Box width={101} height={30}>
                      <InputAuto
                        isDisabled={!this.state.maxCheckbox}
                        automationId="maxThresholdInputStatic"
                        allowEmpty
                        delay={1000}
                        fullSize
                        type="number"
                        className={`dark-input ${isInvalid ? 'invalid' : ''}`}
                        isInvalid={this.state.maxCheckbox && isInvalid}
                        placeHolder="e.g 5000"
                        value={
                          (this.state.maxCheckbox
                            ? getStringValue(this.props.staticThreshold.maxValue)
                            : getStringValue(this.state.disabledMaxValue)) || ''
                        }
                        onChange={this.handleBlurMax}
                      />
                    </Box>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item container justify="space-between" alignItems="center" automation-id="belowThresholdContainer">
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <Box mr={1.5}>
                      <Checkbox
                        isChecked={this.state.minCheckbox}
                        automationId="minCheckboxStatic"
                        onChange={() => {
                          this.setState(
                            (state) => ({
                              minCheckbox: !state.minCheckbox,
                              ...(state.minCheckbox ? {disabledMinValue: this.props.staticThreshold.minValue} : {}),
                            }),
                            () =>
                              this.props.onChange({
                                minValue: this.state.minCheckbox ? this.state.disabledMinValue : '',
                              }),
                          );
                        }}
                      />
                    </Box>
                    <ThresholdBounds
                      isEnabled={this.state.minCheckbox}
                      isUpBounds={false}
                      bound={lowerBound}
                      onSelect={this.handleBoundChange}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Tooltip content={isInvalid && this.state.minCheckbox && ttMesage}>
                    <Box width={101} height={30}>
                      <InputAuto
                        isDisabled={!this.state.minCheckbox}
                        automationId="minThresholdInputStatic"
                        allowEmpty
                        delay={1000}
                        fullSize
                        type="number"
                        className={`dark-input ${isInvalid ? 'invalid' : ''}`}
                        isInvalid={this.state.minCheckbox && isInvalid}
                        placeHolder="e.g 2000"
                        value={
                          (this.state.minCheckbox
                            ? getStringValue(this.props.staticThreshold.minValue)
                            : getStringValue(this.state.disabledMinValue)) || ''
                        }
                        onChange={this.handleBlurMin}
                      />
                    </Box>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <FieldError name="staticThresholdsFilter" />
          </Box>
        )}
      </TooltipArea>
    );
  }
}

// eslint-disable-next-line react/no-multi-comp
class ThresholdBounds extends React.PureComponent {
  props: {
    isUpBounds: boolean,
    bound: string,
    onSelect: Function,
    isEnabled: boolean,
  };

  render() {
    const bounds = this.props.isUpBounds ? upperBounds : lowerBounds;
    const selectedIndex = bounds.findIndex((val) => val.value === this.props.bound);
    const formattedBounds = bounds.map((item) => ({...item, label: item.text.props.children}));

    return (
      <SelectAndt
        id={`ddl-threshold-${this.props.isUpBounds ? 'up' : 'down'}`}
        className="alerts-dropdown-btn"
        options={formattedBounds}
        menuWidth={180}
        buttonHeight={50}
        optionHeight={40}
        type={TYPE_NEW_NO_SEARCH}
        theme={THEME_BLUE_LEAN}
        value={formattedBounds[selectedIndex]}
        onChange={(v) => this.props.onSelect(this.props.isUpBounds, v.value)}
        placeholder={null}
        automationId={`staticThresholdCondition${this.props.isUpBounds ? 'Up' : 'Down'}`}
        disabled={!this.props.isEnabled}
      />
    );
  }
}

const getCondition = (conditions, type) => conditions.find((condition) => condition.type === type);

export default connect(
  (state) => {
    const {conditions} = selectors.getSelectedAlertSimulationFilters(state);
    const staticThreshold = getCondition(conditions, alertConditionTypes.staticThreshold) || {};
    return {
      staticThreshold,
    };
  },
  {
    onChange: actions.setSelectedAlertStaticThreshold,
  },
)(StaticThresholdCondition);
