// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import * as filterSelector from 'alerts.management/store/filterSelector';

const EMPTY_ARRAY = [];

type PropTypes = {
  setQueryParams: Function,

  // connect
  timeScaleTypes?: Array,
  selectedTimeScale: String,
};

@connect(
  (state) => ({
    timeScaleTypes: filterSelector.getFilterAlertTimeScaleWithCount(state),
    selectedTimeScale: filterSelector.getSelectedFilterTimeScale(state),
  }),
  {},
)
export default class TimeScaleFilter extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    timeScaleTypes: EMPTY_ARRAY,
  };

  onChange = (items) => {
    const timeScaleVal = items.length ? items.map((item) => item.value).join(',') : null;
    this.props.setQueryParams({timeScale: timeScaleVal});
  };

  render() {
    const {timeScaleTypes, selectedTimeScale} = this.props;
    return (
      <SelectAndt
        automationId="alertTimeScaleFilter"
        type={TYPE_NEW_MULTI_NO_SEARCH}
        theme={THEME_HIGHLIGHTED}
        onChange={this.onChange}
        getOptionLabel={(val) => val.text}
        getOptionValue={(val) => val.value}
        value={selectedTimeScale}
        options={timeScaleTypes}
        isMulti
        optionHeight={40}
        isClearable={false}
        closeMenuOnSelect={false}
        menuWidth={230}
        placeholder="Select"
      />
    );
  }
}
