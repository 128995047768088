import React, {Fragment, memo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from 'alerts.management/components/editor/simulationArea/alertActions/store/actions';
import * as selectors from 'alerts.management/components/editor/simulationArea/alertActions/store/selectors';
import {palette} from 'app/styles/theme';
import Box from '@material-ui/core/Box';
import SmartTooltip, {TYPES} from 'common/componentsV2/Tooltip';
import TooltipArea from 'common/componentsV2/TooltipArea';
import Button, {COLORS} from 'common/componentsV2/Button';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {Expandable} from 'common/componentsV2/ExpandableSections';
import AllActionsModal from './AllActionsModal';
import AlertActionItem from './AlertActionItem';
import EditActionModal from './EditActionModal';
import ConfirmationMessageModal from './ConfirmationModal';

const AlertActions = memo(() => {
  const dispatch = useDispatch();

  const alertActions = useSelector(selectors.getAlertActionsView);
  const allAlertActionsIsLoading = useSelector(selectors.getAllAlertActionsIsLoading);
  const updateActionIsLoading = useSelector(selectors.getUpdateActionIsLoading);
  const createActionIsLoading = useSelector(selectors.getCreateActionIsLoading);
  const deleteActionIsLoading = useSelector(selectors.getDeleteActionIsLoading);

  const [alertActionsCounterChange, setAlertActionsCounterChange] = useState(0);
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  const [actionToEditId, setActionToEditId] = useState(null);
  const [actionToDeleteId, setActionToDeleteId] = useState(null);
  const [beingUsed, setBeingUsed] = useState(0);
  const [isAllActionsModalOpen, setIsAllActionsModalOpen] = useState(false);
  const [isEditActionModalOpen, setIsEditActionModalOpen] = useState(false);
  const [isConfirmationMessageModalOpen, setIsConfirmationMessageModalOpen] = useState(false);

  useEffect(() => {
    dispatch(actions.fetchAllActions());
  }, []);

  useEffect(() => {
    setAlertActionsCounterChange((prevCount) => prevCount + 1);
  }, [alertActions]);

  useEffect(() => {
    if (allAlertActionsIsLoading === false && alertActionsCounterChange === 2 && alertActions.length) {
      setIsPanelOpen(false);
    }
  }, [allAlertActionsIsLoading, alertActionsCounterChange]);

  useEffect(() => {
    if (updateActionIsLoading === false) {
      setIsEditActionModalOpen(false);
    }
  }, [updateActionIsLoading]);

  useEffect(() => {
    if (createActionIsLoading === false) {
      setIsEditActionModalOpen(false);
    }
  }, [createActionIsLoading]);

  useEffect(() => {
    if (deleteActionIsLoading === false) {
      setIsConfirmationMessageModalOpen(false);
    }
  }, [deleteActionIsLoading]);

  const actionItemMenuChange = (selection, id, usedInAlert) => {
    switch (selection) {
      case 'setAsPrimary':
        dispatch(actions.setAsPrimary(id));
        break;
      case 'removeActionFromAlert':
        dispatch(actions.removeActionFromAlert(id));
        break;
      case 'editAction':
        setActionToEditId(id);
        setIsEditActionModalOpen(true);
        break;
      case 'deleteAction':
        setIsConfirmationMessageModalOpen(true);
        setActionToDeleteId(id);
        setBeingUsed(usedInAlert);
        break;
      default:
    }
  };

  const deleteAction = () => {
    dispatch(actions.deleteAction([actionToDeleteId], {id: actionToDeleteId}));
  };

  const newAction = () => {
    setActionToEditId(null);
    setIsEditActionModalOpen(true);
  };

  return (
    <Fragment>
      <Expandable
        automationId="infoAndRecipients"
        label="Alert Actions"
        icon="icn-general24-actions"
        extraStyle={{justifyContent: 'space-between', width: '100%'}}
        expanded={isPanelOpen}
        onChange={() => setIsPanelOpen(!isPanelOpen)}
      >
        {allAlertActionsIsLoading && (
          <Box display="flex" justifyContent="center" mt={1}>
            <Spinner color={palette.gray['400']} size={SIZES.MEDIUM_50} />
          </Box>
        )}

        {alertActions.length > 1 && (
          <TooltipArea automationId="alertNameContainer" text="Set a name for this alert">
            {(info) => (
              <Box display="flex" mt={2} mb={1}>
                <i className="icon icn-action16-favorite" />
                <div className="text16reg lineHeight_16 mb_0-5 ml_1">
                  Primary Action
                  {info}
                </div>
              </Box>
            )}
          </TooltipArea>
        )}

        {!allAlertActionsIsLoading &&
          !!alertActions.length &&
          alertActions.map((action) => (
            <AlertActionItem
              key={action.id}
              id={action.id}
              onChange={actionItemMenuChange}
              isPrimary={action.id === alertActions[0].id && alertActions.length > 1}
            />
          ))}
        <Box mt={alertActions.length ? 1 : 2}>
          <SmartTooltip
            type={TYPES.LARGE}
            placement="top"
            content="Define possible actions that can be activated when this alert is triggered."
          >
            <Box>
              <Button
                colorSchema={COLORS.GHOST_BLUE}
                onClick={() => setIsAllActionsModalOpen(true)}
                text="Add Action"
                icon="icn-action16-plusa"
              />
            </Box>
          </SmartTooltip>
        </Box>
        <AllActionsModal
          isModalOpen={isAllActionsModalOpen}
          onClose={() => setIsAllActionsModalOpen(false)}
          onChange={actionItemMenuChange}
          onNewAction={newAction}
        />

        <EditActionModal
          isModalOpen={isEditActionModalOpen}
          onClose={() => setIsEditActionModalOpen(false)}
          id={actionToEditId}
        />

        <ConfirmationMessageModal
          isModalOpen={isConfirmationMessageModalOpen}
          onClose={() => setIsConfirmationMessageModalOpen(false)}
          onClick={deleteAction}
          beingUsed={beingUsed}
          btnText="Delete Action"
          btnColor={COLORS.RED_500}
        />
      </Expandable>
    </Fragment>
  );
});

export default AlertActions;
