/* eslint-disable no-unused-vars */
// @flow
import React from 'react';
import MenuItem from 'common/components/bootstrap/MenuItem';
import HexagonalProgressBar from 'common/components/hexagon/HexagonalProgressBar';
import DropdownButton from 'common/components/bootstrap/DropdownButton';
import {HexagonalButton} from 'common/components/hexagon/HexagonalButton';
import Loader from 'common/components/Loader';
import ToggleButton from 'common/components/ToggleButton';
import './StyleGuide.module.scss';
import SelectAndt, {
  THEME_BLUE,
  THEME_LIGHT,
  TYPE_NO_SEARCH,
  TYPE_SEARCH,
  TYPE_SIMPLE,
  DIRECTION_LEFT,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

const dropdownItems = [
  {
    id: -1,
    value: 'Null',
  },
  {
    id: 1,
    value: 'User ID view',
  },
  {
    id: 2,
    value: 'Item 2',
  },
  {
    id: 3,
    value: 'Item 3',
  },
  {
    id: 4,
    value: 'Item 4',
  },
];

const iconDropdownItems = [
  {
    id: 'pause',
    value: 'Pause',
  },
  {
    id: 'delete',
    value: 'Delete',
  },
  {
    id: 'change_name',
    value: 'Change name',
  },
];

class StyleGuide extends React.PureComponent {
  state = {
    item: null,
    toggle1: true,
    toggle2: false,
  };

  onMenuItemClicked = (eventKey) => {
    // console.log(eventKey);
  };

  onHexButtonClicked = (innerKey) => {
    // console.log(`hex-clicked: ${innerKey}`);
  };

  onDropdownSelect = (eventKey) => {
    if (eventKey === -1) {
      this.setState({item: null});
    } else {
      this.setState({item: dropdownItems.find((i) => i.id === eventKey)});
    }
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    // console.log(acceptedFiles);
    // console.log(rejectedFiles);
  };

  onToggleButtonClick = (res) => {
    this.setState(res);
  };

  render() {
    const options = Array.from(new Array(2000), (_, index) => ({
      label: index % 7 !== 0 ? `Item ${index}` : `Title for ${index}`,
      value: index % 7 !== 0 ? index : `Title for ${index}`,
      isHeader: index % 7 === 0,
    }));
    const selectData = [
      {value: 'chocolate', l: 'Chocolate'},
      {value: 'strawberry', l: 'Strawberry'},
      {value: 'vanilla', l: 'Vanilla'},
      {value: 'bla', l: 'bla'},
      {value: 'yu', l: 'yu'},
      {value: 'jojo', l: 'jojo'},
      {value: 're', l: 're'},
      {value: 'qqq', l: 'qqq'},
    ];
    const alternativeData = [
      {value: 'yellow', label: 'Yellow', color: '#FFC400'},
      {value: 'green', label: 'Green', color: '#36B37E'},
      {value: 'forest', label: 'Forest', color: '#00875A'},
      {value: 'slate', label: 'Slate', color: '#253858'},
      {value: 'silver', label: 'Silver', color: '#666666'},
    ];
    const groupedData = [
      {value: 'Flavors', label: 'Flavors', isHeader: 'true'},
      {value: 'chocolate', label: 'Chocolate'},
      {value: 'strawberry', label: 'Strawberry'},
      {value: 'vanilla', label: 'Vanilla'},
      {value: 'bla', label: 'bla'},
      {value: 'yu', label: 'yu'},
      {value: 'jojo', label: 'jojo'},
      {value: 're', label: 're'},
      {value: 'qqq', label: 'qqq'},
      {value: 'Colors', label: 'Colors', isHeader: 'true'},
      {value: 'yellow', label: 'Yellow', color: '#FFC400'},
      {value: 'green', label: 'Green', color: '#36B37E'},
      {value: 'forest', label: 'Forest', color: '#00875A'},
      {value: 'slate', label: 'Slate', color: '#253858'},
      {value: 'silver', label: 'Silver', color: '#666666'},
    ];

    const onChange = (value) => {
      // console.log('Change selection - value is ', value);
    };
    return (
      <div styleName="root">
        <div>
          <ToggleButton
            styleName="margin-bottom"
            value={this.state.toggle1}
            text="Number 1"
            onChangePropName="toggle1"
            onChange={this.onToggleButtonClick}
          />
          <ToggleButton
            styleName="margin-bottom"
            value={this.state.toggle2}
            text="Number 2"
            onChangePropName="toggle2"
            onChange={this.onToggleButtonClick}
          />
        </div>
        <div>
          Hexogonal Button:
          <HexagonalButton
            onClick={this.onHexButtonClicked}
            imgSrc="modules/andt.app/style/images/GOOGLE_ANALYTICS.jpg"
            eventKey="hexBtn1"
          />
          <HexagonalButton
            onClick={this.onHexButtonClicked}
            imgSrc="http://www.marcus-thoericht.de/public/Kreuzfahrt-180x120.jpg"
            eventKey="hexBtn2"
          />
        </div>
        <br />
        <div>
          Button examples:
          <button type="button" className="btn btn-flat btn-primary">
            Flat Prime
          </button>
          <button type="button" className="btn btn-flat btn-secondary">
            Flat Second
          </button>
          <button type="button" className="btn btn-flat btn-secondary" disabled>
            Flat Disabled
          </button>
          <button type="button" className="btn btn-raised">
            Raised
          </button>
          <button type="button" className="btn btn-raised" disabled>
            Raised Disabled
          </button>
        </div>
        <br />
        <div styleName="table-btn-wrapper">
          Table Button:
          <button type="button" className="btn btn-raised btn-icon-raised">
            T
          </button>
        </div>
        <br />
        <div styleName="table-btn-wrapper">
          Icon Button:
          <button type="button" className="btn btn-flat btn-icon">
            I
          </button>
        </div>
        <br />
        <div styleName="dark-bg">
          Header Button Dark:
          <button type="button" className="btn btn-flat btn-dark">
            Header
          </button>
          <button type="button" className="btn btn-flat btn-dark" disabled>
            Header Disabled
          </button>
        </div>
        <br />
        <div styleName="close-buttons">
          Close Buttons:
          <div>
            <button type="button" className="btn btn-flat btn-icon">
              <div className="x-icon-18-black" />
            </button>
          </div>
          <div styleName="blue-bg">
            <button type="button" className="btn btn-flat btn-icon">
              <div className="x-icon-18-white" />
            </button>
          </div>
          <div styleName="blue-light-bg">
            <button type="button" className="btn btn-flat btn-icon">
              <div className="x-icon-32-cyan" />
            </button>
          </div>
          <div styleName="dark-bg">
            <button type="button" className="btn btn-flat ripple-light" styleName="size-78">
              <div className="x-icon-65-cyan" />
            </button>
          </div>
        </div>
        <br />
        <div>
          Hexagon Loader:
          <Loader size="small" value="99%" />
          <Loader value="99%" />
          <Loader size="large" />
          <Loader size="extra-large" />
        </div>
        <br />
        <div styleName="blue-bg">
          Header Button Blue:
          <button type="button" className="btn btn-flat btn-blue">
            Header
          </button>
          <button type="button" className="btn btn-flat btn-blue" disabled>
            Header Disabled
          </button>
        </div>
        <br />
        <div>
          Select with search
          <SelectAndt
            options={options}
            type={TYPE_SEARCH}
            optionHeight={35}
            onChange={onChange}
            automationId="ignore"
          />
          <br />
          Select without search light theme
          <SelectAndt
            options={selectData}
            type={TYPE_NO_SEARCH}
            getOptionLabel={(sd) => sd.l}
            onChange={onChange}
            placeholder="Get a Light Theme Value!"
            automationId="ignore"
          />
          <br />
          Select without search blue theme
          <SelectAndt
            options={selectData}
            type={TYPE_NO_SEARCH}
            theme={THEME_BLUE}
            onChange={onChange}
            automationId="ignore"
          />
          <br />
          Icon blue theme
          <SelectAndt
            options={selectData}
            type={TYPE_SIMPLE}
            theme={THEME_BLUE}
            onChange={onChange}
            automationId="ignore"
          />
          <br />
          Icon light theme
          <div style={{marginLeft: '400px'}}>
            <SelectAndt
              automationId="ignore"
              style={{float: 'right'}}
              options={selectData}
              type={TYPE_SIMPLE}
              theme={THEME_LIGHT}
              onChange={onChange}
              direction={DIRECTION_LEFT}
            />
          </div>
          <br />
        </div>
        Dropdown example:
        <div styleName="dropdown-wrapper">
          <DropdownButton
            id="dropdown-example1"
            title="Choose view"
            value={this.state.item ? this.state.item.value : null}
            onSelect={this.onDropdownSelect}
          >
            {dropdownItems.map((_item) => (
              <MenuItem key={_item.id} eventKey={_item.id} active={this.state.item && this.state.item.id === _item.id}>
                {_item.value}
              </MenuItem>
            ))}
          </DropdownButton>
          <DropdownButton
            id="dropdown-example2"
            title="Choose view"
            styleName="dropdown-width"
            extraClassName="blue-style"
            value={this.state.item ? this.state.item.value : null}
            onSelect={this.onDropdownSelect}
          >
            {dropdownItems.map((_item) => (
              <MenuItem key={_item.id} eventKey={_item.id} active={this.state.item && this.state.item.id === _item.id}>
                {_item.value}
              </MenuItem>
            ))}
          </DropdownButton>
          <DropdownButton
            id="dropdown-example3"
            extraClassName="icon-dropdown btn-icon"
            noCaret
            value={<span styleName="style-guide-dd-icon">G</span>}
            onSelect={this.onMenuItemClicked}
          >
            {iconDropdownItems.map((_item) => (
              <MenuItem key={_item.id} eventKey={_item.id}>
                {_item.value}
              </MenuItem>
            ))}
          </DropdownButton>
        </div>
        <br />
        <div>
          <HexagonalProgressBar percents="10" />
          <HexagonalProgressBar percents="45" />
          <HexagonalProgressBar percents="88" />
        </div>
        <div>
          Icons:
          <div>
            <ul className="glyphs css-mapping" styleName="icons-container">
              {[
                'icn-icon-resume',
                'icn-icon-chart-area',
                'icn-icon-zoom-out',
                'icn-icon-chart-line',
                'icn-icon-chart-polls',
                'icn-icon-chart-spline',
                'icn-icon-dashboard',
                'icn-icon-drag-dash',
                'icn-icon-function',
                'icn-icon-plus-mini',
                'icn-icon-minus-mini',
                'icn-icon-unsubscribe',
                'icn-icon-list-wrap',
                'icn-icon-grid',
                'icn-icon-ban',
                'icn-icon-time',
                'icn-icon-time-filter',
                'icn-icon-triangle-right',
                'icn-icon-refresh',
                'icn-icon-bookmark-full',
                'icn-icon-bookmark-empty',
                'icn-icon-enlarge-win',
                'icn-icon-reduce-win',
                'icn-alert-on-tile',
                'icn-events',
                'icn-alert-on-tile-empty',
                'icn-icon-3-dots',
                'icn-icon-blackarrow-down',
                'icn-icon-blue-plus',
                'icn-icon-breadcrumbs',
                'icn-icon-help',
                'icn-icon-hamburger',
                'icn-icon-dimmet-group',
                'icn-icon-table-ignore',
                'icn-icon-white-dropdown',
                'icn-icon-white-plus',
                'icn-icon-table-units',
                'icn-icon-tablepopup-plus',
                'icn-icon-table-filter',
                'icn-icon-table-delete',
                'icn-icon-table-aggregation',
                'icn-icon-side-menu',
                'icn-icon-search',
                'icn-icon-include',
                'icn-icon-error',
                'icn-icon-initializing',
                'icn-icon-night-paused',
                'icn-icon-incomplete',
                'icn-icon-sun-live-stream',
                'icn-icon-copy',
                'icn-icon-checkbox-full',
                'icn-icon-checkbox',
                'icn-icon-finetune',
                'icn-icon-pin-off',
                'icn-icon-pin-on',
                'icn-icon-extrainfo',
                'icn-icon-triangle-equal-func',
                'icn-icon-triangle-func',
                'icn-icon-triangle-left',
                'icn-icon-clear-filter',
                'icn-icon-anomaly-type-transient',
                'icn-icon-anomaly-type-pattern-change',
                'icn-view-icon',
                'icn-text-icon',
                'icn-settings-icon',
                'icn-edit-pen-icon',
                'icn-time-stamp-icon',
                'icn-numbers-icon',
                'icn-display-style-icon',
                'icn-complete-icon',
                'icn-warning-icon',
                'icn-signed-v-icon',
                'icn-email-grey-9-icon',
                'icn-slack-grey-9-icon',
                'icn-icon-white-close',
                'icn-webhook-grey-9-icon',
                'icn-users-grey-9-icon',
                'icn-open-alert-icon',
                'icn-snooze-icon',
                'icn-mark-me-empty-icon',
                'icn-anomaly',
                'icn-data-management',
                'icn-management',
                'icn-support',
                'icn-settings',
                'icn-alert',
                'icn-dashboard',
                'icn-search-metrics',
                'icn-avatar',
                'icn-alert-console',
                'icn-general16-copy',
                'icn-general16-dashboard',
                'icn-general24-copy',
                'icn-general24-dashboard',
                'icn-general24-alertconsole',
                'icn-general24-anomalies',
                'icn-general24-data',
                'icn-general24-help',
                'icn-general24-managment',
                'icn-general24-metricsearch',
                'icn-general24-settings',
                'icn-other16-jira',
                'icn-icon-andt-mark-me-empty-icon',
                'icn-other16-slack',
                'icn-other16-jira',
                'icn-action16-copy',
                'icn-action16-delete',
                'icn-action16-edit',
                'icn-action16-plusa',
                'icn-action16-plusb',
                'icn-action24-copy',
                'icn-action24-delete',
                'icn-action24-plusa',
                'icn-action24-plusb',
                'icn-general16-3dot',
                'icn-general16-closea',
                'icn-general16-closeb',
                'icn-general16-help',
                'icn-general16-info',
                'icn-general16-mail',
                'icn-general16-placeholder',
                'icn-general16-search',
                'icn-general16-warning',
                'icn-general24-refresh',
                'icn-selection16-checkboxoff',
                'icn-selection16-checkboxon',
                'icn-selection16-radiooff',
                'icn-selection16-radioon',
                'icn-other16-opsgenie',
                'icn-other16-pagerduty',
              ].map((a, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i}>
                  <div className={`icon ${a}`} />
                  <input type="text" readOnly="readonly" value={a} />
                </li>
              ))}

              <div className="icon icn-icon-edit" />
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default StyleGuide;
