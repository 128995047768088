// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {getUsersData} from 'admin.users/store/selectors';
import {getDataMangerQueryParamsSearchQuery} from 'bc/store/selectors';
import {setSelectedDataStream} from 'bc/store/actions';
import {getStreamChip, getOwnerName, isViewStreamAllowed} from 'bc/services/dataManagerService';
import Tooltip from 'common/componentsV2/Tooltip';
import Highlighter from 'react-highlight-words';

import './DataManagerStreamItem.module.scss';

type PropTypes = {
  stream: Object,
  openStreamViewModal: Function,

  // connect
  users: Array,
  searchQuery: String,
  setSelectedDataStream: Function,
};

@connect(
  (state) => ({
    users: getUsersData(state),
    searchQuery: getDataMangerQueryParamsSearchQuery(state),
  }),
  {
    setSelectedDataStream,
  },
)
export default class DataManagerStreamItemData extends PureComponent {
  props: PropTypes;

  openStreamViewModalClick = () => {
    this.props.setSelectedDataStream(this.props.stream.id);
    this.props.openStreamViewModal();
  };

  doNothing = () => {};

  render() {
    const {stream, users, searchQuery} = this.props;
    const streamChip = getStreamChip(stream);
    const owner = getOwnerName(stream.owner || stream.userId, users);
    const onClickFunction = isViewStreamAllowed(stream) ? this.openStreamViewModalClick : this.doNothing;

    return (
      <div
        styleName={[
          'row',
          streamChip.style === 'paused' ? 'paused' : '',
          isViewStreamAllowed(stream) ? ' linked' : '',
        ].join(' ')}
        onClick={onClickFunction}
        automation-id={`Stream_item_${stream.name}`}
      >
        <div styleName="col-title" automation-id={`Stream_name_${stream.name}`}>
          <Highlighter autoEscape searchWords={[searchQuery]} textToHighlight={stream.name} />
        </div>
        <div styleName="col-status">
          {streamChip.tooltip ? (
            <Tooltip content={streamChip.tooltip} delay={300}>
              <span styleName={`stream-chip ${streamChip.style}`} automation-id={`Stream_Status_${stream.name}`}>
                {streamChip.text}
              </span>
            </Tooltip>
          ) : (
            <span styleName={`stream-chip ${streamChip.style}`} automation-id={`Stream_Status_${stream.name}`}>
              {streamChip.text}
            </span>
          )}
        </div>
        <div styleName="col-owner">{owner}</div>
      </div>
    );
  }
}
