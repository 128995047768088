import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {segmentClickEvent} from 'common/store/actions';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import './AppNavBar.module.scss';

type NavBarMenuItemProps = {
  item: Object,
  isSelectedItem: Boolean,
  isUnderSeparator: Boolean,
};

type MenuItemTooltipProps = {
  item: Object,
  children: React.Node,
};

const MenuItemTooltip = ({item, children}: MenuItemTooltipProps) => (
  <Tooltip placement="right" content={item.title} type={TYPES.NAV_BAR} delay={50}>
    {children}
  </Tooltip>
);

const getProperLink = (lastVisitedUrl, actualLink) => {
  if (actualLink.indexOf('/r/dashboards') !== -1) {
    return actualLink;
  }
  return lastVisitedUrl || actualLink;
};

const NavBarMenuItem = ({item, isSelectedItem, isUnderSeparator}: NavBarMenuItemProps) => {
  const dispatch = useDispatch();

  const reportToSegment = useCallback((menuItem) => {
    dispatch(segmentClickEvent({type: 'click', name: `menu-${menuItem}`}));
  }, []);

  if (!item.isHidden) {
    return (
      <div styleName="item-wrapper" automation-id={item.title}>
        <div
          styleName={['item', isSelectedItem ? 'active' : ''].join(' ')}
          onClick={() => {
            reportToSegment(item.title);
          }}
        >
          <MenuItemTooltip item={item}>
            <a
              href={getProperLink(item.lastVisitedurl, item.links[0])}
              target={item.isNewTab ? '_blank' : '_self'}
              styleName="item-link"
              rel="noreferrer"
            >
              <i styleName={isUnderSeparator ? 'under' : ''} className={`icon ${item.iconName}`} />
            </a>
          </MenuItemTooltip>
        </div>
      </div>
    );
  }
  return null;
};

export default NavBarMenuItem;
