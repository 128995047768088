// @flow
import React, {Fragment, useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {get} from 'lodash';
import {makeStyles} from '@material-ui/core';
import Spinner from 'common/componentsV2/Spinner';
import WarningNavigationIcon from 'dashboards/components/WarningNavigationIcon';
import AlertBellContainer from 'dashboards/components/AlertBellContainer';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import FilterDDL from 'dashboards/components/metricTiles/FilterDDL';
import SelectAndt, {
  DIRECTION_LEFT,
  THEME_TRANSPARENT,
  TYPE_SIMPLE,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import OptionWithIcon from 'common/componentsV2/ddl/selectAndt/innerComponents/OptionWithIcon';
import {allowedUserTileActions, OPTION_TILES_VALUES, tileActions} from 'dashboards/services/dashboardService';
import {setDashboardData} from 'dashboards/store/actions';

const EMPTY_OBJECT = {};

type PropTypes = {
  dashboardData: Object,
  tileData: Object,
  isEditableMode: boolean,
  matchUrl: string,
  formValues: Object,
  commonValues: Object,
  isAnonymous: boolean,
  isOwnerUser: boolean,
  validationMessage: Object,
  compositeErrorsMap: Object,
  isCompositeDataLoading: boolean,
};

const useStyles = makeStyles(({palette}) => ({
  settingHeader: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 24,
    fontSize: 20,
    color: palette.black[500],
    fontWeight: 500,
    padding: '0 8px 0 4px',
    flexShrink: 0,
  },
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  editWrapper: {
    display: 'flex',
    alignItems: 'center',
    visibility: 'hidden',
    color: palette.gray[500],
    backgroundColor: palette.white[500],
    fontSize: 16,
    lineHeight: 1,
    zIndex: 1,
  },
  dragWrapper: {
    alignItems: 'center',
    fontSize: 16,
    color: palette.gray[400],
    zIndex: 1,
  },
  dragIcon: {
    visibility: 'hidden',
    position: 'relative',
    top: -1,
    marginRight: 8,
    '&:hover': {
      cursor: 'grab',
    },
  },
  warningIcon: {
    position: 'relative',
    top: 1,
    color: palette.red[500],
    cursor: 'pointer',
    marginRight: 8,
  },
  fullSize: {
    position: 'absolute',
    right: 10,
    top: 5,
    fontSize: 18,
  },
  iconAction: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: palette.blue[500],
    },
  },
  wrapperFullSize: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: palette.white[500],
    borderRadius: 4,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)',
    padding: 12,
  },
}));

const TileHeader = ({
  dashboardData,
  tileData,
  isEditableMode,
  matchUrl,
  formValues,
  commonValues,
  isAnonymous,
  isOwnerUser,
  validationMessage,
  compositeErrorsMap,
  isCompositeDataLoading,
}: PropTypes) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const tileId = get(tileData, 'id');
  const handleFullSize = useCallback(() => {
    history.push(`${matchUrl}/${tileId}/fullsize`);
  }, [matchUrl, tileId]);

  const computedTileOptions = useMemo(
    () =>
      tileActions.map((option) => {
        if (isAnonymous || (!isOwnerUser && !allowedUserTileActions.includes(option.value))) {
          return {...option, disabled: true};
        }
        return option;
      }),
    [isOwnerUser, isAnonymous],
  );

  const optionsGraphTile = useMemo(() => {
    const isMultipleExpressionBuilder =
      Object.keys(get(tileData, 'lineChart.expressionTrees', EMPTY_OBJECT)).length > 1;
    const isAlertCreated = dashboardData.tileAlerts.some((a) => a.tileId === tileData.id);
    const computedOptions =
      isMultipleExpressionBuilder || isAlertCreated
        ? computedTileOptions.filter((o) => o.value !== OPTION_TILES_VALUES.CREATE_ALERT)
        : computedTileOptions;

    return computedOptions.map((o) => {
      if (o.value === OPTION_TILES_VALUES.SHOW_LEGEND) {
        return {
          ...o,
          label: get(tileData, 'lineChart.legend.enabled') ? 'Hide Legend' : 'Show Legend',
        };
      }

      if (validationMessage && o.value === OPTION_TILES_VALUES.CREATE_ALERT) {
        return {
          ...o,
          disabled: true,
        };
      }

      return o;
    });
  }, [validationMessage, computedTileOptions, dashboardData, tileData]);

  const optionsStatTile = useMemo(() => {
    const isAlertCreated = dashboardData.tileAlerts.some((a) => a.tileId === tileId);
    const computedOptions = isAlertCreated
      ? computedTileOptions.filter((o) => o.value !== OPTION_TILES_VALUES.CREATE_ALERT)
      : computedTileOptions;

    return computedOptions
      .filter((o) => o.value !== OPTION_TILES_VALUES.SHOW_LEGEND)
      .map((o) => {
        if (isAnonymous || (validationMessage && o.value === OPTION_TILES_VALUES.CREATE_ALERT)) {
          return {
            ...o,
            disabled: true,
          };
        }
        return o;
      });
  }, [isAnonymous, validationMessage, computedTileOptions, dashboardData, tileId]);

  const onTileAction = useCallback(
    (option) => {
      if (option.value === OPTION_TILES_VALUES.SHOW_LEGEND) {
        dispatch(
          setDashboardData({
            ...dashboardData,
            tiles: dashboardData.tiles.map((tile) => {
              if (tile.id === tileId) {
                return {
                  ...tile,
                  lineChart: {
                    ...tile.lineChart,
                    legend: {
                      ...tile.lineChart.legend,
                      enabled: !tile.lineChart.legend.enabled,
                    },
                  },
                };
              }
              return tile;
            }),
          }),
        );
      } else {
        history.push(`${matchUrl}/${tileId}/${option.value}${history.location.search}`);
      }
    },
    [dashboardData, tileId, history],
  );

  const options = tileData.lineChart ? optionsGraphTile : optionsStatTile;

  return (
    <div className={`${classes.settingHeader} mb_${get(tileData, 'title.text.length', 0) === 0 ? 0 : 1}`}>
      <div className="display_flex alignItems_center flexShrink_0">
        {isEditableMode && (
          <div className={`${classes.dragWrapper} bgcolor_white-500 dragHandlerClassName`}>
            <i className={`icon icn-general16-draghandle ${classes.dragIcon} ${isEditableMode ? 'editMode' : ''}`} />
          </div>
        )}
        {!isEditableMode && (
          <Fragment>
            {isCompositeDataLoading && (
              <div className="mr_1">
                <Spinner size={24} />
              </div>
            )}
            {validationMessage && (
              <div className={classes.warningIcon}>
                <WarningNavigationIcon tileId={tileData.id} compositeErrorsMap={compositeErrorsMap} />
              </div>
            )}
            {!isAnonymous && <AlertBellContainer dashboardData={dashboardData} tileId={tileData.id} />}
            <div className="mr_1">
              <FilterDDL
                dashboardData={dashboardData}
                tileId={tileId}
                formValues={formValues}
                commonValues={commonValues}
              />
            </div>
          </Fragment>
        )}
      </div>
      <div className={classes.title}>{tileData.title.text}</div>
      <div className="display_flex alignItems_center flexShrink_0">
        <div className={`${classes.editWrapper} editWrapper`}>
          <div className={`${classes.iconAction} mr_1`}>
            <Tooltip content="Fullscreen" type={TYPES.SMALL} placement="top">
              <i className="icon icn-general16-fullscreen" onClick={handleFullSize} />
            </Tooltip>
          </div>
          <SelectAndt
            automationId="dashboardTileEdit"
            type={TYPE_SIMPLE}
            theme={THEME_TRANSPARENT}
            direction={DIRECTION_LEFT}
            options={options}
            onChange={onTileAction}
            optionHeight={40}
            menuWidth={150}
            customComponent={{
              Option: OptionWithIcon,
              DropdownIndicator: (p) => (
                <div
                  className={`${classes.iconAction} display_flex alignItems_center`}
                  color={p.selectProps.menuIsOpen ? 'blue.500' : undefined}
                >
                  <i {...p.innerProps} className="icon icn-nav16-settings" />
                </div>
              ),
            }}
            closeMenuOnSelect={false}
            appendToBody={false}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(TileHeader);
