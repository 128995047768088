// @flow
import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {get} from 'lodash';
import {Backdrop, Modal, Box, makeStyles} from '@material-ui/core';
import {getAnonymousInvitation, revokeAnonymousInvitations} from 'dashboards/store/actions';
import {
  getAnonymousInvitation as getAnonymousInvitationSelector,
  revokeAnonymousInvitations as revokeAnonymousInvitationsSelector,
} from 'dashboards/store/selectors';
import Input from 'common/componentsV2/Input';
import Button, {COLORS} from 'common/componentsV2/Button';
import Checkbox from 'common/componentsV2/Checkbox';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import * as actions from 'common/store/actions';

type PropTypes = {onClose: Function, dashboardId: string};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 600,
    height: 424,
    backgroundColor: theme.palette.white[500],
    borderRadius: 6,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
    margin: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    padding: 24,
  },
}));

const ShareModal = ({onClose, dashboardId}: PropTypes) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [shareFiltersAndSettings, setShareFiltersAndSettings] = useState(true);
  const [isRevokePristine, setIsRevokePristine] = useState(true);
  const anonymousInvitation = useSelector(getAnonymousInvitationSelector);
  const revokedAnonymousInvitations = useSelector(revokeAnonymousInvitationsSelector);

  const copyButtonClicked = useCallback((event, text) => {
    dispatch(actions.copyToClipBoard({event, text, description: 'Copied to clipboard!'}));
  }, []);

  const onRevokeAllClick = () => {
    setIsRevokePristine(false);
    dispatch(
      revokeAnonymousInvitations({
        optionId: dashboardId,
        keepInvitationId: get(anonymousInvitation, 'data.invitationId', ''),
      }),
    );
  };

  useEffect(() => {
    dispatch(getAnonymousInvitation({type: 'dashboard', id: dashboardId, options: {}}));
  }, []);

  const currentUrl = location.href.replace('/share', '') + location.search;
  const internalUrl = shareFiltersAndSettings ? currentUrl : currentUrl.split('?')[0];
  // eslint-disable-next-line max-len
  const publicUrl = `${internalUrl}${internalUrl.includes('?') ? '&' : '?'}invitationId=${get(
    anonymousInvitation,
    'data.invitationId',
    '',
  )}`;

  return (
    <Modal open BackdropComponent={Backdrop} onClose={() => onClose()}>
      <Box automation-id="addGraphStatTileModal" className={classes.wrapper}>
        <Box fontSize={24} mb={2} fontWeight={300}>
          Share Dashboard
        </Box>
        <Box fontSize={18} fontWeight="bold">
          Internal Share
        </Box>
        <Box fontSize={14} mb={1.5}>
          Share this dashboard with others through Anodot (login required)
        </Box>

        <div className="display_flex mb_4">
          <Box height={36} flexGrow={1} mr={1}>
            <Input fullSize value={internalUrl} isDisabled />
          </Box>
          <Button
            icon="icn-action16-copy"
            automationId="copyInternalUrl"
            text="Copy"
            colorSchema={COLORS.BLUE_500}
            onClick={(event) => copyButtonClicked(event, internalUrl)}
          />
        </div>

        <Box fontSize={18} fontWeight="bold">
          Public Share
        </Box>
        <Box fontSize={14} mb={1.5}>
          Share this dashboard with everyone, even outside of Anodot
        </Box>

        <div className="display_flex mb_4">
          <Box height={36} flexGrow={1} mr={1}>
            <Input fullSize value={publicUrl} isDisabled />
          </Box>
          <Button
            icon="icn-action16-copy"
            automationId="copyPublicUrl"
            text="Copy"
            colorSchema={COLORS.BLUE_500}
            onClick={(event) => copyButtonClicked(event, publicUrl)}
          />
        </div>
        <div className="display_flex alignItems_baseline justifyContent_space-between">
          <Checkbox
            automationId="includeCurrentFiltersAndSettings"
            isChecked={shareFiltersAndSettings}
            onChange={() => setShareFiltersAndSettings(!shareFiltersAndSettings)}
          >
            <Box fontSize={16} fontWeight={500} ml={0.5}>
              Include current filters & settings
            </Box>
          </Checkbox>
          <Box fontSize={16} fontWeight={400}>
            <Box color="blue.500" css={{cursor: 'pointer'}} onClick={onRevokeAllClick}>
              Revoke all previous links
            </Box>
            <Box color="gray.500" height={30} display="flex">
              {revokedAnonymousInvitations.isLoading && (
                <Box position="relative" left="-6px">
                  <Spinner color="#3d4c59" size={SIZES.SMALL_30} />
                </Box>
              )}
              {!isRevokePristine && !revokedAnonymousInvitations.isLoading && (
                <div className="display_flex">
                  <Box component="span" color="green.500" fontSize={20} mr={0.25}>
                    <i className="icon icn-general16-checkmark" />
                  </Box>
                  <Box component="span" lineHeight="30px">
                    Revoked
                  </Box>
                </div>
              )}
            </Box>
          </Box>
        </div>
        <Box mt={5.5} alignSelf="center">
          <Button automationId="shareDone" text="Done" colorSchema={COLORS.GRAY_300} onClick={onClose} />
        </Box>
      </Box>
    </Modal>
  );
};

export default ShareModal;
