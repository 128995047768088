// @flow
import React from 'react';
import './NoData.module.scss';
import LookupTableUpload from '../dataSourceTypes/LookupTableUpload';

const NoDataTitle = (props: {sourceType: String}) => (
  <div styleName="no-streams-title">
    {props.sourceType !== 'lookup' ? 'No Streams to Show' : 'No Lookup Tables to Show'}
  </div>
);

const NoData = (props: {
  createStream: Function,
  sourceType: String,
  dataStreamsCount: Object,
  sourceItemsCount: Number,
}) => (
  <div styleName="no-streams-wrapper">
    {(props.sourceItemsCount !== 0 || (props.sourceItemsCount === 0 && props.sourceType === 'lookup')) && (
      <div>
        {props.dataStreamsCount.all === 0 || props.dataStreamsCount.bySource === 0 ? (
          // no streams created
          <div>
            <div styleName="no-streams-image" />
            <NoDataTitle sourceType={props.sourceType} />
            <div styleName="no-streams-description">
              {props.sourceType === null && (
                <span>
                  Add a Stream
                  <br />
                  to Start Collecting Data.
                </span>
              )}
              {props.sourceType !== 'lookup' && props.sourceType !== null && (
                <span>
                  <span styleName="no-stream-create-stream" onClick={props.createStream}>
                    Add a Stream
                  </span>
                  <span>
                    for this Source
                    <br />
                    to Start Collecting Data.
                  </span>
                </span>
              )}
              {props.sourceType === 'lookup' && (
                <LookupTableUpload
                  buttonComponent={<span styleName="no-stream-create-stream">Add a Lookup Table</span>}
                />
              )}
            </div>
          </div>
        ) : (
          // no streams found
          <div>
            <div styleName="not-found-streams-image" />
            <NoDataTitle sourceType={props.sourceType} />
            {props.dataStreamsCount.bySource === 0 ? (
              <div styleName="no-streams-description">
                {`No ${props.sourceType !== 'lookup' ? 'streams' : 'Lookup Tables'} To Show`}
              </div>
            ) : (
              <div styleName="no-streams-description">
                {`Refine the filters to view ${props.sourceType !== 'lookup' ? 'streams' : 'lookup tables'}`}
              </div>
            )}
          </div>
        )}
      </div>
    )}
  </div>
);

export default NoData;
