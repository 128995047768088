// @flow
import React from 'react';
import {connect} from 'react-redux';

import * as actions from 'admin.cs-portal/store/actions';
import * as customersActions from 'admin.customers/store/actions';
import * as selectors from 'admin.customers/store/selectors';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  // connect
  fetchCustomers: Function,
  changeCustomer: Function,
  isLoading: boolean,
};

@connect(
  (state) => ({
    customers: selectors.getLeanCustomers(state),
    isLoading: selectors.getIsLoadingCustomers(state),
  }),
  {
    fetchCustomers: customersActions.fetchCustomers,
    changeCustomer: actions.changeCustomer,
  },
)
export default class CustomersDD extends React.PureComponent {
  props: PropTypes;

  state = {
    customers: [],
    customer: null,
  };

  static getDerivedStateFromProps(props, state) {
    const {customers} = props;
    const {prevCustomers} = state;
    if (customers !== prevCustomers) {
      return {customers: customers.filter((i) => i.label !== 'Anodot Org'), prevCustomers: customers};
    }
    return null;
  }

  componentDidMount() {
    this.props.fetchCustomers();
  }

  handleSelection = (value) => {
    this.setState({customer: value});
    this.props.changeCustomer(value);
  };

  render() {
    const {isLoading} = this.props;

    return (
      <SelectAndt
        type={TYPE_NEW_SEARCH}
        optionHeight={40}
        menuWidth={305}
        buttonWidth={305}
        className="andt-dropdown"
        disabled={isLoading}
        options={this.state.customers}
        theme={THEME_NOT_HIGHLIGHTED}
        value={this.state.customer}
        onChange={this.handleSelection}
        placeholder={isLoading ? 'Loading Customers...' : 'Select a Customer'}
        automationId="customers"
      />
    );
  }
}
