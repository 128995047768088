// @flow
import React from 'react';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_MULTI_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import connect from 'react-redux/es/connect/connect';
import * as selector from 'alerts.management/store/selectors';
import {simpleAlertSetDirection} from 'alerts.management/store/actions';

const DIRECTION_OPTIONS = [
  {
    label: 'Spikes',
    value: 'up',
  },
  {
    label: 'Drops',
    value: 'down',
  },
];

type PropTypes = {
  disabled: boolean,

  simpleAlertSelectedDirection: Object,
  simpleAlertSetDirection: Function,
};

@connect(
  (state) => ({
    simpleAlertSelectedDirection: selector.getSimpleAlertSelectedDirection(state),
  }),
  {
    simpleAlertSetDirection,
  },
)
export default class DirectionDdl extends React.PureComponent {
  props: PropTypes;

  onChange = (items) => {
    this.props.simpleAlertSetDirection(items);
  };

  render() {
    return (
      <SelectAndt
        automationId="simpleAlertDirection"
        type={TYPE_NEW_MULTI_NO_SEARCH}
        theme={THEME_BLUE_LEAN}
        onChange={this.onChange}
        options={DIRECTION_OPTIONS}
        value={this.props.simpleAlertSelectedDirection}
        placeholder="Direction"
        optionHeight={40}
        menuWidth={150}
        multiMinNumOptions={1}
        isMulti
        isClearable={false}
        closeMenuOnSelect={false}
        multiDelimiter=" / "
        disabled={this.props.disabled}
      />
    );
  }
}
