// @flow
import React, {PureComponent} from 'react';
import Notifications from 'react-notification-system-redux';
import {connect} from 'react-redux';

type PropTypes = {notifications: Array};

@connect((state) => ({notifications: state.notifications}))
export default class NotificationBar extends PureComponent {
  props: PropTypes;

  componentDidMount() {
    document.body.appendChild(this.notificationContainer);
  }

  componentWillUnmount() {
    document.body.removeChild(this.notificationContainer);
  }

  render() {
    return (
      <div
        ref={(e) => {
          this.notificationContainer = e;
        }}
      >
        <Notifications notifications={this.props.notifications} />
      </div>
    );
  }
}
