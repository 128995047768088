// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {palette} from 'app/styles/theme';
import {getBadgeColor} from 'topologyGeneral/services/mapService';

const useStyles = makeStyles(() => ({
  regionTitle: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    color: palette.gray['500'],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  infoTitle: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: palette.gray['400'],
    flexShrink: 0,
  },
  infoNumber: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: palette.gray['600'],
  },
  alertsBadge: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    borderRadius: '10px',
    color: palette.white['500'],
    flexShrink: 0,
  },
  totalAlerts: {
    backgroundColor: palette.gray['100'],
    color: palette.gray['500'],
    borderRadius: '6px',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 500,
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '4px',
    paddingRight: '4px',
    marginTop: '8px',
  },
}));

const DomainItemTooltip = ({item}: PropTypes) => {
  const classes = useStyles();

  return (
    <Box className="gtp-tp-wrapper" pt={1.5} pb={1.5} pl={2} pr={2} borderRadius="6px">
      <Box display="flex">
        <Box component="span" className={classes.regionTitle} mr={0.5}>
          {item.title}
        </Box>
        <Box component="span" className={classes.alertsBadge} pr={0.75} pl={0.75} bgcolor={getBadgeColor(item.percent)}>
          {`${item.percent}%`}
        </Box>
      </Box>
      <Box display="flex" pt={1}>
        <Box display="flex" flexDirection="column" flexGrow={1} alignItems="start">
          <Box component="span" className={classes.infoTitle}>
            Alerted Nodes
          </Box>
          <Box component="span" className={classes.infoNumber}>
            {item.alertedNodes.toLocaleString()}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="start">
          <Box component="span" className={classes.infoTitle}>
            Total Nodes
          </Box>
          <Box component="span" className={classes.infoNumber}>
            {item.nodesTotal.toLocaleString()}
          </Box>
        </Box>
      </Box>
      <Box className={classes.totalAlerts}>{`${item.alertsTotal.toLocaleString()} Total Alerts`}</Box>
    </Box>
  );
};

DomainItemTooltip.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DomainItemTooltip;
