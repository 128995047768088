import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Radio from '../common/componentsV2/Radio';
import {NOT_INTERESTING_REASONS} from '../alerts.console/components/alertsList/feedback/constants';
import Button, {COLORS, HEIGHTS} from '../common/componentsV2/Button';
import '../alerts.console/components/alertsList/feedback/Feedback.module.scss';

const FeedbackPopover = ({feedbackType, onSubmit, onClose}) => {
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');
  const [isDone, setIsDone] = useState(false);
  const closePopoverTimer = () =>
    setTimeout(() => {
      onClose();
    }, 1000);
  const submit = () => {
    onSubmit({reason, comment});
    setIsDone(true);
    closePopoverTimer();
  };

  useEffect(() => () => {
    clearTimeout(closePopoverTimer);
  });

  return (
    <div styleName="content-body" onClick={(e) => e.stopPropagation()}>
      <header>Thank you for your feedback!</header>
      <div styleName="wrapper" style={{display: isDone ? 'none' : 'block'}}>
        <p>
          {feedbackType === 'GOOD_CATCH' ? 'Why this alert was a good catch?' : 'Why this alert was not interesting?'}
        </p>

        {feedbackType === 'NOT_INTERESTING' && (
          <div>
            <Radio options={NOT_INTERESTING_REASONS} onSelect={setReason} groupName="NOT_INTERESTING_REASONS" />
          </div>
        )}
        <textarea
          placeholder={feedbackType === 'GOOD_CATCH' ? 'Optional' : 'Optional, tell us more...'}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <div styleName="submit-wrapper">
          <Button
            isDisabled={!comment.length && !reason.length}
            colorSchema={COLORS.BLUE_500}
            onClick={submit}
            height={HEIGHTS.TIGHT28}
            text="Submit"
          />
        </div>
      </div>
    </div>
  );
};

FeedbackPopover.propTypes = {
  feedbackType: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FeedbackPopover;
