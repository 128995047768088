// @flow
import React, {PureComponent, Fragment} from 'react';
import {Modal} from 'react-bootstrap';
import Script from 'react-load-script';
import {segmentClickEvent} from 'common/store/actions';
import Loader from 'common/components/Loader';
import {loadStyleSheet} from 'common/utils/css';
import {isEmpty} from 'lodash';
import ConfirmationModal from 'common/components/modals/ConfirmationModal';
import connect from 'react-redux/es/connect/connect';
import QueryPreviewTable from './QueryPreviewTable';
import QueryPreview from './QueryPreview';
import QueryGuideLines from './QueryGuideLines';
import './QueryEditorModal.module.scss';

type PropTypes = {
  isOpen: boolean,
  onClose: Function,
  dataStream: Object,
  previewData: Object,
  queryPreviewColumns: Object,
  isPreviewDataLoading: boolean,
  isVerifyQueryLoading: boolean,
  verifyQueryData: Object,
  previewDataError: Object,
  useLegacySQL: boolean,
  hideActualQuery: boolean,
  query: string,
  guidelines: string,
  queryTemplate: Object,

  runQueryPreview: Function,
  setStreamQuery: Function,
  fetchVerifyQueryFunction: Function,
  toggleLegacySQL: Function,
  segmentClickEvent: Function,
};

@connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)
export default class QueryEditorModal extends PureComponent {
  props: PropTypes;

  state = {
    codeMirrorLoaded: false,
    sqlModeLoaded: false,
    activeTab: 0,
    isConfirmModalOpen: false,
    isConfirmInvokable: false,
    currentPreviewQuery: '', // query sent to preview API
    currentCodeMirrorQuery: '', // current query written inside editor
  };

  componentDidMount() {
    loadStyleSheet('codemirrorStylesheet', 'statics/query.editor/codemirror/lib/codemirror.css');
  }

  componentDidUpdate() {
    const {codeMirrorLoaded, sqlModeLoaded} = this.state;
    const {query, isVerifyQueryLoading, queryTemplate} = this.props;
    const fQuery = queryTemplate ? queryTemplate.query : query;

    if (codeMirrorLoaded && sqlModeLoaded && !this.CodeMirror) {
      /* eslint-disable no-undef */
      const editor = CodeMirror.fromTextArea(document.getElementById('codeSql'), {
        lineNumbers: true,
        autofocus: true,
        mode: 'text/x-mariadb',
      });
      /* eslint-enable no-undef */

      // TODO: Check what can be done instead
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({currentCodeMirrorQuery: fQuery, currentPreviewQuery: query});
      editor.doc.setValue(fQuery);
      editor.on('change', this.handleCodeMirrorTextChange);
      this.CodeMirror = editor;
    }

    if (isVerifyQueryLoading === false && this.state.isConfirmInvokable) {
      if (isEmpty(this.props.verifyQueryData)) {
        const isTemplateQueryUnchanged =
          this.props.queryTemplate && this.props.queryTemplate.query === this.state.currentCodeMirrorQuery;
        const newStreamQuery = {
          query: this.CodeMirror.doc.getValue(),
          useLegacySQL: this.props.useLegacySQL,
          queryPreviewColumns: this.props.queryPreviewColumns,
        };
        if (isTemplateQueryUnchanged) {
          newStreamQuery.basedOnTemplateId = this.props.queryTemplate.id;
        }
        this.props.setStreamQuery(newStreamQuery);
        // TODO: Check what can be done instead
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({isConfirmInvokable: false});
        this.props.onClose();
      } else {
        // TODO: Check what can be done instead
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({isConfirmModalOpen: true, isConfirmInvokable: false});
      }
    }
  }

  componentWillUnmount() {
    if (this.CodeMirror) {
      this.CodeMirror.off('change', this.handleCodeMirrorTextChange);
    }
  }

  handleClose = () => {
    this.props.segmentClickEvent({
      category: `Data collectors - ${this.props.dataStream.type}`,
      name: 'Query editor - X Close',
    });

    this.props.onClose();
  };

  setActiveTab = (tabIndex) => {
    this.setState({activeTab: tabIndex});
  };

  handleCodeMirrorScriptLoad = () => {
    this.setState({
      codeMirrorLoaded: true,
    });
  };

  handleSqlModeScriptLoad = () => {
    this.setState({
      sqlModeLoaded: true,
    });
  };

  handleNext = () => {
    this.props.fetchVerifyQueryFunction({
      query: this.CodeMirror.doc.getValue(),
    });
    this.setState({isConfirmInvokable: true});
  };

  handlePreviewQuery = () => {
    this.setState({
      activeTab: 1,
      currentPreviewQuery: this.CodeMirror.doc.getValue(),
    });

    this.props.segmentClickEvent({
      category: `Data collectors - ${this.props.dataStream.type}`,
      name: 'Query editor - Run',
    });

    this.props.runQueryPreview({
      query: this.CodeMirror.doc.getValue(),
      useLegacySQL: this.props.useLegacySQL,
    });
  };

  toggleLegacySQL = (val) => {
    this.props.toggleLegacySQL(val);
  };

  getEditor = (isDirty) => {
    const {codeMirrorLoaded, sqlModeLoaded, currentCodeMirrorQuery} = this.state;
    const {isPreviewDataLoading, useLegacySQL, toggleLegacySQL, queryTemplate} = this.props;

    const isTemplateQueryUnchanged = queryTemplate && queryTemplate.query === currentCodeMirrorQuery;

    if (codeMirrorLoaded && sqlModeLoaded) {
      return (
        <Fragment>
          <div styleName="editor-wrapper">
            <textarea id="codeSql" />
          </div>
          <div styleName="actions-wrapper">
            {toggleLegacySQL ? (
              <div className="cb2">
                <input
                  type="checkbox"
                  automation-id="dataCollectorBigQueryUseLegacySQLCheckbox"
                  name="useLegacySQL"
                  id="useLegacySQL"
                  checked={useLegacySQL}
                  onChange={(e) => this.toggleLegacySQL(e.target.checked)}
                />
                <label htmlFor="useLegacySQL">Use Legacy SQL</label>
              </div>
            ) : (
              <div className="cb2" />
            )}
            <div styleName="btn-preview-wrap">
              {isDirty && !isTemplateQueryUnchanged && <div styleName="dirty-msg">Query changed, please run again</div>}
              <button
                type="button"
                className="btn btn-raised btn-outline"
                onClick={this.handlePreviewQuery}
                disabled={isPreviewDataLoading}
              >
                Run
              </button>
            </div>
          </div>
        </Fragment>
      );
    }
    return <Loader />;
  };

  closeConfirmModal = () => {
    this.setState({isConfirmModalOpen: false});
  };

  onConfirmModal = () => {
    const isTemplateQueryUnchanged =
      this.props.queryTemplate && this.props.queryTemplate.query === this.state.currentCodeMirrorQuery;
    const newStreamQuery = {
      query: this.CodeMirror.doc.getValue(),
      useLegacySQL: this.props.useLegacySQL,
      queryPreviewColumns: this.props.queryPreviewColumns,
    };
    if (isTemplateQueryUnchanged) {
      newStreamQuery.basedOnTemplateId = this.props.queryTemplate.id;
    }
    this.closeConfirmModal();
    this.props.setStreamQuery(newStreamQuery);

    this.props.segmentClickEvent({
      category: `Data collectors - ${this.props.dataStream.type}`,
      name: 'Query editor - Confirm Close',
    });

    this.props.onClose();
  };

  handleCodeMirrorTextChange = (codeMirror) => {
    this.setState({currentCodeMirrorQuery: codeMirror.doc.getValue()});
  };

  showGuidelines() {
    this.props.segmentClickEvent({
      category: `Data collectors - ${this.props.dataStream.type}`,
      name: 'Query editor - Guidelines',
    });
    this.setActiveTab(0);
  }

  renderSwitch(activeTab) {
    const {isPreviewDataLoading, previewData, previewDataError, guidelines} = this.props;

    switch (activeTab) {
      case 0:
        return <QueryGuideLines guidelines={guidelines} />;
      case 1:
        return (
          <QueryPreviewTable
            previewData={previewData}
            previewDataError={previewDataError}
            isPreviewDataLoading={isPreviewDataLoading}
          />
        );
      case 2:
        return (
          <QueryPreview
            previewData={previewData}
            previewDataError={previewDataError}
            isPreviewDataLoading={isPreviewDataLoading}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const {isOpen, onClose, queryPreviewColumns, verifyQueryData, isVerifyQueryLoading, hideActualQuery} = this.props;

    const {
      currentPreviewQuery,
      currentCodeMirrorQuery,
      codeMirrorLoaded,
      sqlModeLoaded,
      activeTab,
      isConfirmModalOpen,
    } = this.state;

    const isQueryChangedFromLastPreview =
      !isEmpty(queryPreviewColumns) && currentPreviewQuery !== currentCodeMirrorQuery;
    const isContinueBtnDisabled = isEmpty(queryPreviewColumns) || isQueryChangedFromLastPreview;
    const showActualQueryTab = !hideActualQuery;

    return (
      <div>
        {!codeMirrorLoaded && (
          <Script url="statics/query.editor/codemirror/lib/codemirror.js" onLoad={this.handleCodeMirrorScriptLoad} />
        )}
        {codeMirrorLoaded && !sqlModeLoaded && (
          <Script url="statics/query.editor/codemirror/mode/sql/sql.js" onLoad={this.handleSqlModeScriptLoad} />
        )}

        <Modal show={isOpen} onHide={onClose} dialogClassName="bc sql-query-modal" backdrop="static">
          <Modal.Header bsClass="bc-modal-header">
            <Modal.Title>
              <span>Query Editor</span>
            </Modal.Title>
            <button type="button" className="btn btn-flat btn-icon-36 btn-secondary" onClick={this.handleClose}>
              <i className="icon icn-icon-table-delete" automation-id="exitQueryEditor" />
            </button>
          </Modal.Header>

          <Modal.Body>
            <div styleName="root">
              {this.getEditor(isQueryChangedFromLastPreview)}
              <div styleName="info-wrapper">
                <div styleName="tabs-block">
                  <div
                    role="presentation"
                    automation-id="guidelinesTab"
                    styleName={activeTab === 0 ? 'tab active' : 'tab'}
                    onClick={() => this.showGuidelines()}
                  >
                    Guidelines
                  </div>
                  <div
                    role="presentation"
                    automation-id="guidelinesTab"
                    styleName={activeTab === 1 ? 'tab active' : 'tab'}
                    onClick={() => this.setActiveTab(1)}
                  >
                    Preview Results
                  </div>
                  {showActualQueryTab && (
                    <div
                      role="presentation"
                      automation-id="actualQueryTab"
                      styleName={activeTab === 2 ? 'tab active' : 'tab'}
                      onClick={() => this.setActiveTab(2)}
                    >
                      Actual Query
                    </div>
                  )}
                </div>
                {this.renderSwitch(activeTab)}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button type="button" className="btn btn-raised" disabled={isContinueBtnDisabled} onClick={this.handleNext}>
              {isVerifyQueryLoading && <i className="icon ion-load-c spin" />}
              CONTINUE
            </button>
          </Modal.Footer>
        </Modal>

        {isConfirmModalOpen && (
          <ConfirmationModal
            onClose={this.closeConfirmModal}
            onConfirm={this.onConfirmModal}
            styleName="confirm-modal"
            title="Illegal query"
            message={
              <span>
                {verifyQueryData ? verifyQueryData.description : ''}
                <br /> Are you sure you want to continue?
              </span>
            }
            isOpen={isConfirmModalOpen}
          />
        )}
      </div>
    );
  }
}
