import {composeReducers} from 'common/utils/reducers';
import {updateSSO, verifyDomains} from 'userSettings/store/actions';
import * as actions from '../actions';

const initialState = {
  isLoading: true,
  me: null,
};

const dataReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case actions.fetchMe.success.TYPE:
    case actions.setMe.TYPE: {
      const cleanPayload = {};
      Object.keys(payload).forEach((i) => {
        if (Object.prototype.hasOwnProperty.call(payload, i)) {
          if (i[0] !== '$') {
            // clean angular props
            cleanPayload[i] = payload[i];
          }
        }
      });
      return {
        ...state,
        isLoading: false,
        me: {
          ...cleanPayload,
        },
      };
    }

    // case actions.setMe.TYPE: {
    //   return {
    //
    //   };
    // }

    case actions.updateUserSettings.TYPE: {
      return {
        me: {
          ...state.me,
          appSettings: payload,
        },
      };
    }

    case actions.dismissOnBoarding.TYPE: {
      return {
        ...state,
        me: {
          ...state.me,
          appSettings: {
            ...state.me.appSettings,
            isOnBoardingDismiss: payload,
          },
        },
      };
    }

    case actions.updateUserNameOnly.TYPE: {
      return {
        ...state,
        isLoading: true,
        me: {
          ...state.me,
          firstName: payload.firstName,
          lastName: payload.lastName,
          appSettings: {
            ...state.me.appSettings,
            usersGroups: {
              ...state.me.appSettings.usersGroups,
              isRenameNeeded: false,
            },
          },
        },
      };
    }

    case actions.updateUserNameOnly.success.TYPE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case actions.updateMe.TYPE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actions.updateMe.success.TYPE: {
      return {
        ...state,
        isLoading: false,
        me: {
          ...state.me,
          ...payload,
        },
      };
    }

    case updateSSO.TYPE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case updateSSO.success.TYPE: {
      return {
        ...state,
        isLoading: false,
        me: {
          ...state.me,
          organization: {
            ...state.me.organization,
            ...payload,
          },
        },
      };
    }
    case verifyDomains.TYPE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case verifyDomains.success.TYPE: {
      return {
        ...state,
        isLoading: false,
        me: {
          ...state.me,
          organization: {
            ...state.me.organization,
            settings: {
              ...state.me.organization.settings,
              auth: {
                ...state.me.organization.settings.auth,
                oauth: {
                  ...state.me.organization.settings.auth.oauth,
                  google: {
                    ...state.me.organization.settings.auth.google,
                    domains: payload,
                  },
                },
              },
            },
          },
        },
      };
    }

    default:
      return state;
  }
};

export default composeReducers(dataReducer);
