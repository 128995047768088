import {combineEpics} from 'redux-observable';
import {makeAsyncEpic, makeFlatAsyncEpic} from 'common/utils/simplifiedAsync';
import {fetchUsers} from 'admin.users/store/actions';
import {getUniqueId} from 'common/utils/guid';
import {success} from 'common/utils/notifications/notificationsService';
import * as profileActions from 'profile/store/actions';
import {TYPE_REFERER} from 'common/componentsV2/modal/SaveToDashboardModal';
import * as api from '../services/api';
import * as actions from './actions';

const fetchDashboards = makeAsyncEpic(actions.fetchDashboards, api.fetchDashboards);
const fetchDashboardsV1 = makeAsyncEpic(actions.fetchDashboardsV1, api.fetchDashboardsV1);
const fetchDashboard = makeAsyncEpic(actions.fetchDashboard, api.fetchDashboard);
const fetchDashboardUserSettings = makeAsyncEpic(actions.fetchDashboardUserSettings, api.fetchDashboardUserSettings);
const updateDashboardUserSettings = makeAsyncEpic(actions.updateDashboardUserSettings, api.updateDashboardUserSettings);
const updateDashboard = makeAsyncEpic(actions.updateDashboard, api.updateDashboard);
const fetchDashboardsTotal = makeAsyncEpic(actions.fetchDashboardsTotal, api.fetchDashboardsTotal);
const updateDashboardSettings = makeAsyncEpic(actions.updateDashboardSettings, api.updateDashboardSettings);
const createDuplicateDashboard = makeAsyncEpic(actions.createDuplicateDashboard, api.createDuplicateDashboard);
const deleteDashboard = makeAsyncEpic(actions.deleteDashboard, api.deleteDashboard);
const setFavorite = makeAsyncEpic(actions.setFavorite, api.setFavorite);
const fetchDatapoints = makeFlatAsyncEpic(actions.fetchDatapoints, api.fetchDataPoints);
const fetchLastViewDashboards = makeAsyncEpic(actions.fetchLastViewDashboards, api.fetchLastViewedDashboards);
const setLastViewDashboard = makeAsyncEpic(actions.setLastViewDashboard, api.setLastViewedDashboard);
const fetchDashboardV1 = makeAsyncEpic(actions.fetchDashboardV1, api.fetchDashboardV1);
const fetchDashboardListV1 = makeAsyncEpic(actions.fetchDashboardListV1, api.fetchDashboardListV1);
const fetchDashboardListV2 = makeAsyncEpic(actions.fetchDashboardListV2, api.fetchDashboardListV2);
const updateDashboardV1 = makeAsyncEpic(actions.updateDashboardV1, api.updateDashboardV1);
const addTile = makeAsyncEpic(actions.addTile, api.addTile);
const copyTile = makeAsyncEpic(actions.copyTile, api.updateDashboard);
const getAllFilters = makeAsyncEpic(actions.getAllFilters, api.getAllFilters);
const getAnonymousInvitation = makeAsyncEpic(actions.getAnonymousInvitation, api.getAnonymousInvitation);
const revokeAnonymousInvitations = makeAsyncEpic(actions.revokeAnonymousInvitations, api.revokeAnonymousInvitations);
const getAnonymousUser = makeAsyncEpic(actions.getAnonymousUser, api.getAnonymousUser);
const getTriggeredAlerts = makeAsyncEpic(actions.getTriggeredAlerts, api.getTriggeredAlerts);
const uploadDashboard = makeAsyncEpic(actions.uploadDashboard, api.uploadDashboard);

const getAnonymousUserSuccess = (action$) =>
  action$.ofType(actions.getAnonymousUser.success.TYPE).flatMap(({payload}) => {
    if (!payload) {
      return [];
    }
    sessionStorage.setItem('andt-token', payload.token);
    sessionStorage.setItem('andt-token-ttl', JSON.parse(atob(payload.token.split('.')[1])).exp);
    sessionStorage.setItem('andt-token-iat', JSON.parse(atob(payload.token.split('.')[1])).iat);
    return [profileActions.setMe(payload.user)];
  });

const addTileSuccess = (action$) =>
  action$.ofType(actions.addTile.success.TYPE).flatMap(({payload, meta}) => {
    const {name} = payload;
    const id = payload._id;
    const isAlertMng = meta.type === TYPE_REFERER.ALERT_MNG;
    const {isNewDashboard} = meta;

    const getTitle = () => {
      if (isAlertMng && isNewDashboard) {
        return 'Dashboard was Created';
      }

      if (isAlertMng) {
        return 'Tile was Created';
      }

      return 'Saved to Dashboard';
    };

    const getDescription = () => {
      if (isAlertMng && isNewDashboard) {
        return `[${name}] was created with this alert as a tile`;
      }

      if (isAlertMng) {
        return `The alert was saved as a tile to [${name}]`;
      }

      return `The expression was saved to [${name}]`;
    };

    const successObj = {
      title: getTitle(),
      description: getDescription(),
      actions: [
        {
          label: isAlertMng ? 'Go to Dashboard' : 'See Dashboard',
          callback: () => {
            window.location.href = `#!/r/dashboards/${id}`;
          },
        },
      ],
      settings: {
        uid: getUniqueId(),
      },
    };
    return [success(successObj)];
  });

const updateDashboardV1Success = (action$) =>
  action$.ofType(actions.updateDashboardV1.success.TYPE).flatMap(({payload}) => {
    const {name} = payload;
    const id = payload._id;

    const successObj = {
      title: 'Saved to Dashboard',
      description: `The expression was saved to "${name}"`,
      actions: [
        {
          label: 'See Dashboard',
          callback: () => {
            window.location.href = `#!/dashboards?tabs=main;${id}&activeTab=1`;
          },
        },
      ],
      settings: {
        uid: getUniqueId(),
      },
    };
    return [success(successObj)];
  });

const fetchDataDashboardsManagementPage = (action$) =>
  action$
    .ofType(actions.fetchDataDashboardsManagementPage.TYPE)
    .switchMap(() => [
      actions.fetchDashboards(),
      actions.fetchDashboardsV1(),
      fetchUsers(),
      {type: actions.fetchDataDashboardsManagementPage.success.TYPE},
    ]);

const displayDashboardSnackBar = (action$) =>
  action$.ofType(actions.displayDashboardSnackBar.TYPE).switchMap(({payload}) => [
    success({
      title: payload.title,
      description: payload.description,
      settings: {
        uid: 'dashboard_export_success_msg',
      },
    }),
  ]);

export default combineEpics(
  fetchDashboards,
  fetchDashboardsV1,
  fetchDashboard,
  updateDashboard,
  fetchDashboardsTotal,
  updateDashboardSettings,
  createDuplicateDashboard,
  deleteDashboard,
  setFavorite,
  fetchDataDashboardsManagementPage,
  fetchDatapoints,
  fetchLastViewDashboards,
  setLastViewDashboard,
  fetchDashboardListV1,
  fetchDashboardListV2,
  updateDashboardV1,
  addTile,
  addTileSuccess,
  updateDashboardV1Success,
  fetchDashboardV1,
  fetchDashboardUserSettings,
  updateDashboardUserSettings,
  copyTile,
  getAllFilters,
  getAnonymousInvitation,
  revokeAnonymousInvitations,
  getAnonymousUser,
  getAnonymousUserSuccess,
  getTriggeredAlerts,
  uploadDashboard,
  displayDashboardSnackBar,
);
