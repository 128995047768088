import React, {memo, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {palette} from '../../../app/styles/theme';
import {ALERT_STATUS} from '../../services/investigationService';

const TimelineStatusLine = ({alertStatus, isLast, top}: PropTypes) => {
  const [lineColor, setLineColor] = useState('');

  useEffect(() => {
    if (isLast && alertStatus === ALERT_STATUS.OPEN) {
      setLineColor(`linear-gradient(0deg, ${palette.white['500']} 25%, ${palette.red['500']} 100%)`);
    }
    if (isLast && alertStatus === ALERT_STATUS.CLOSED) {
      setLineColor(`linear-gradient(0deg, ${palette.white['500']} 25%, ${palette.gray['200']} 100%)`);
    }
    if (!isLast && alertStatus === ALERT_STATUS.OPEN) {
      setLineColor(palette.red['500']);
    }
    if (!isLast && alertStatus === ALERT_STATUS.CLOSED) {
      setLineColor(palette.gray['200']);
    }
  }, []);

  return (
    <Box
      style={{
        background: lineColor,
        top,
        position: 'absolute',
        flexGrow: '1',
        marginLeft: 16,
        width: alertStatus === ALERT_STATUS.OPEN ? 2 : 1,
        height: isLast ? 'calc(100% + 150px)' : '100%',
      }}
    />
  );
};

TimelineStatusLine.defaultProps = {
  top: 0,
};

TimelineStatusLine.propTypes = {
  alertStatus: PropTypes.string.isRequired,
  isLast: PropTypes.bool.isRequired,
  top: PropTypes.number,
};

export default memo(TimelineStatusLine);
