// @flow
import React, {PureComponent} from 'react';
import connect from 'react-redux/es/connect/connect';
import * as selectors from 'alerts.management/store/selectors';
import * as actions from 'alerts.management/store/actions';
import {VOLUME_BOUND_OPTIONS, VOLUME_DURATION_OPTIONS} from 'alerts.management/services/durationAndScaleService';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import BaloonMessage from 'common/componentsV2/BaloonMessage';
import ToggleSwitch from 'common/componentsV2/toggleSwitch/ToggleSwitch';
import Input from 'common/componentsV2/Input';
import Checkbox from 'common/componentsV2/Checkbox';
import Spinner, {SIZES as SPINNER_SIZES} from 'common/componentsV2/Spinner';
import {makeSupportLink} from 'common/utils/browserLoacation';
import Box from '@material-ui/core/Box';
import TooltipArea from 'common/componentsV2/TooltipArea';

type PropTypes = {
  // connect
  volumeConditions: Object,
  validRollupOptions: Array,
  isLoadingAutoVolume: Boolean,
  setVolumeNumOfLastPoints: Function,
  setVolumeRollup: Function,
  setVolumeEnabled: Function,
  setVolumeBound: Function,
  setEnableAutoTuning: Function,
  setVolumeValue: Function,
};

@connect(
  (state) => ({
    volumeConditions: selectors.getVolumeConditions(state),
    validRollupOptions: selectors.getValidRollupOptions(state),
    isLoadingAutoVolume: selectors.getIsLoadingAutoVolumeCondition(state),
  }),
  {
    setVolumeRollup: actions.setVolumeRollup,
    setVolumeEnabled: actions.setVolumeEnabled,
    setVolumeNumOfLastPoints: actions.setVolumeNumOfLastPoints,
    setVolumeBound: actions.setVolumeBound,
    setEnableAutoTuning: actions.setEnableAutoTuning,
    setVolumeValue: actions.setVolumeValue,
  },
)
export default class VolumeCondition extends PureComponent {
  props: PropTypes;

  state = {
    // eslint-disable-next-line react/no-unused-state
    isErrorMsgDisplay: true,
  };

  selectedRollup = (options, currentRollup) => {
    let selectedRollup = options.find((i) => i.value === currentRollup);
    if (selectedRollup) {
      return selectedRollup;
    }
    selectedRollup = options.length > 1 ? options[1] : options[0];
    return selectedRollup;
  };

  selectedNumOfLastPoints = (options, currentNumLastPoints) => {
    const numOfLastPointsItem = options.find((i) => i.value === currentNumLastPoints);
    return numOfLastPointsItem || options[0];
  };

  setVolumeNumOfLastPoints = (lastPoint) => {
    this.props.setVolumeNumOfLastPoints(lastPoint);
  };

  setVolumeRollup = (rollupValue) => {
    this.props.setVolumeRollup(rollupValue);
  };

  setVolumeBound = (boundValue) => {
    this.props.setVolumeBound(boundValue);
  };

  setVolumeValue = (event) => {
    this.props.setVolumeValue(event.target.value);
  };

  setEnableAutoTuning = (enableAutoTuning) => {
    const {setEnableAutoTuning} = this.props;
    setEnableAutoTuning(enableAutoTuning, {isNewAlertEditor: true});
  };

  setVolumeEnabled = (enabled) => {
    const {setVolumeEnabled} = this.props;
    setVolumeEnabled(enabled, {isNewAlertEditor: true});
  };

  errorMsg = {
    volumeConditions: this.props.volumeConditions,
    invalidCondition: (errorMsg) => (
      <Box my={1}>
        <BaloonMessage
          tag="New"
          title="New alert condition"
          link="Learn more"
          url={makeSupportLink('https://support.anodot.com/hc/en-us/articles/360010343780')}
          /* eslint-disable-next-line react/no-unused-state */
          onClick={() => this.setState((prevState) => ({isErrorMsgDisplay: !prevState.isErrorMsgDisplay}))}
        >
          {errorMsg}
          This condition is here to help you reduce unimportant alerts, but works only for metrics that are defined as a
          sum.
        </BaloonMessage>
      </Box>
    ),
    noValue: () => (
      <Box my={1}>
        <BaloonMessage title="Condition is not yet in effect">
          We are unable to calculate the threshold value right now. The condition is valid and will take effect once the
          value can be calculated. In the meantime, all alerts will be sent as usual.
        </BaloonMessage>
      </Box>
    ),
  };

  render() {
    const {
      volumeConditions: {invalidCondition, enabled, numLastPoints, rollup, bound, value, enableAutoTuning},
      validRollupOptions,
      isLoadingAutoVolume,
    } = this.props;
    const rollupOptions = [...validRollupOptions];
    const selectedRollup = this.selectedRollup(rollupOptions, rollup);
    const numOfLastPointsOptions = VOLUME_DURATION_OPTIONS[selectedRollup.value].numLastPoints;
    const selectedNumOfLastPoints = this.selectedNumOfLastPoints(numOfLastPointsOptions, numLastPoints);
    const boundOptions = Object.values(VOLUME_BOUND_OPTIONS);
    const selectedBound = boundOptions.find((i) => i.value === bound);
    return (
      <div automation-id="volumeConditionContainer">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          pt={2}
          mb={2}
          borderTop="1px solid"
          borderColor="gray.300"
        >
          <TooltipArea text="This condition will prevent alerts from being triggered if the metric value was higher/lower in the time interval that was chosen.">
            {(info) => (
              <Box display="flex">
                <Box maxWidth={160} css={{'& label': {alignItems: 'flex-start'}, opacity: invalidCondition ? 0.25 : 1}}>
                  <Checkbox
                    isDisabled={invalidCondition}
                    isChecked={enabled}
                    onChange={() => this.setVolumeEnabled(enabled)}
                  >
                    <div className="text16med ml_0-5">Ignore Low Volume Anomalies</div>
                  </Checkbox>
                </Box>
                {info}
              </Box>
            )}
          </TooltipArea>
          {enabled && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              css={{opacity: invalidCondition ? 0.25 : 1}}
            >
              {isLoadingAutoVolume && (
                <Box position="absolute" right={145} bottom={14}>
                  <Spinner color="#fff" size={SPINNER_SIZES.SMALL_30} />
                </Box>
              )}
              <Box
                mr={1.5}
                color={enableAutoTuning ? 'purple.600' : 'gray.400'}
                fontWeight="bold"
                fontSize={16}
                css={{opacity: enabled ? 1 : 0.5}}
              >
                Automatic
              </Box>
              <ToggleSwitch
                disabled={!enabled || invalidCondition}
                theme="purple"
                isChecked={enableAutoTuning}
                onToggle={() => this.setEnableAutoTuning(enableAutoTuning)}
              />
            </Box>
          )}
        </Box>
        {enabled && (
          <div>
            <Box css={{opacity: invalidCondition ? 0.25 : 1}}>
              <div className="text16reg lineHeight_16 mb_1 whiteSpace_nowrap">
                Alerts will not be triggered if the metric value is
              </div>
              <Box display="flex" alignItems="center">
                <SelectAndt
                  automationId="volumeBound"
                  disabled={enableAutoTuning || !enabled}
                  type={TYPE_NEW_NO_SEARCH}
                  theme={THEME_BLUE_LEAN}
                  onChange={this.setVolumeBound}
                  options={boundOptions}
                  value={isLoadingAutoVolume ? '' : selectedBound}
                  isMulti={false}
                  optionHeight={40}
                  buttonWidth={85}
                  menuWidth={115}
                  isClearable={false}
                  closeMenuOnSelect={false}
                />
                <Box fontSize={16} mx={1} lineHeight="32px">
                  than
                </Box>
                <div className="display_flex">
                  {(invalidCondition || (value || !enableAutoTuning)) && (
                    <Box width={130} height={27} mr={1}>
                      <Input
                        fullSize
                        isDisabled={enableAutoTuning || !enabled}
                        type="number"
                        name="volume-value"
                        value={isLoadingAutoVolume ? '' : value || ''}
                        onChange={this.setVolumeValue}
                      />
                    </Box>
                  )}

                  {!value && enableAutoTuning && !invalidCondition && (
                    <Box width={80} height={27}>
                      <Input
                        fullSize
                        isDisabled
                        type="number"
                        name="volume-value"
                        value={isLoadingAutoVolume ? '' : value || ''}
                        onChange={this.setVolumeValue}
                      />
                    </Box>
                  )}
                </div>
              </Box>
              <div className="display_flex alignItems_center">
                <Box fontSize={16} mr={1} lineHeight="32px">
                  in the last
                </Box>
                <div className="mr_1">
                  <SelectAndt
                    automationId="volumeNumOfLastPoints"
                    disabled={enableAutoTuning || !enabled}
                    type={TYPE_NEW_NO_SEARCH}
                    theme={THEME_BLUE_LEAN}
                    onChange={this.setVolumeNumOfLastPoints}
                    options={numOfLastPointsOptions}
                    value={isLoadingAutoVolume ? '' : selectedNumOfLastPoints}
                    isMulti={false}
                    optionHeight={40}
                    buttonWidth={53}
                    menuWidth={75}
                    isClearable={false}
                    closeMenuOnSelect={false}
                  />
                </div>
                <SelectAndt
                  automationId="volumeRollup"
                  disabled={enableAutoTuning || !enabled}
                  type={TYPE_NEW_NO_SEARCH}
                  theme={THEME_BLUE_LEAN}
                  onChange={this.setVolumeRollup}
                  options={rollupOptions}
                  value={isLoadingAutoVolume ? '' : selectedRollup}
                  isMulti={false}
                  optionHeight={40}
                  buttonWidth={73}
                  menuWidth={115}
                  isClearable={false}
                  closeMenuOnSelect={false}
                />
              </div>{' '}
            </Box>

            {!value && enableAutoTuning && !invalidCondition && <div>{this.errorMsg.noValue()}</div>}
          </div>
        )}
      </div>
    );
  }
}
/* eslint-enable */
