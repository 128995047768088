// @flow
import React, {memo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, makeStyles} from '@material-ui/core';
import {palette} from 'app/styles/theme';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {setFeedback, deleteFeedback, updateFeedback} from 'investigation/store/actions';
import {getInvModalTrigger} from 'investigation/store/selectors';
import {getProfileId, getUserProfile} from 'profile/store/selectors';
import {FEEDBACK_TYPE, NOT_INTERESTING_REASONS, FEEDBACK_ORIGIN} from 'feedback/services/constants';
import Feedback from 'feedback/Feedback';

const css = makeStyles(() => ({
  feedback: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    border: '2px solid',
    borderColor: palette.gray['300'],
    height: '36px',
    marginLeft: '12px',
    paddingRight: '12px',
    paddingLeft: '15px',
  },
}));

const FeedbackContainer = () => {
  const classes = css();
  const dispatch = useDispatch();
  const meId = useSelector(getProfileId);
  const meProfile = useSelector(getUserProfile);
  const trigger = useSelector(getInvModalTrigger);

  const [othersGoodCatch, setOthersGoodCatch] = useState([]);
  const [otherNotInteresting, setOtherNotInteresting] = useState([]);
  const [isGoodCatchByMe, setIsGoodCatchByMe] = useState(false);
  const [isNotInterestingByMe, setIsNotInterestingByMe] = useState(false);

  const setFeedbackParams = (type) => ({
    type,
    userId: meId,
    triggeredId: trigger.id,
    origin: FEEDBACK_ORIGIN.INVESTIGATION,
    userName: `${meProfile.firstName}${' '}${meProfile.lastName}` || 'Unknown',
  });

  const setIsFeedbackByMe = (type) => {
    const feedbackByMe = trigger.feedback.find((f) => f.userId === meId);
    if (feedbackByMe) {
      return feedbackByMe.type === FEEDBACK_TYPE[type];
    }
    return false;
  };

  const setOtherFeedback = (type) =>
    trigger.feedback.filter((f) => f.type === FEEDBACK_TYPE[type] && f.userId !== meId);

  useEffect(() => {
    setOthersGoodCatch(setOtherFeedback(FEEDBACK_TYPE.GOOD_CATCH));
    setOtherNotInteresting(setOtherFeedback(FEEDBACK_TYPE.NOT_INTERESTING));
    setIsGoodCatchByMe(setIsFeedbackByMe(FEEDBACK_TYPE.GOOD_CATCH));
    setIsNotInterestingByMe(setIsFeedbackByMe(FEEDBACK_TYPE.NOT_INTERESTING));
  }, [trigger]);

  const setGoodCatch = () => {
    const params = setFeedbackParams(FEEDBACK_TYPE.GOOD_CATCH);
    dispatch(setFeedback(params, {alertId: params.triggeredId}));
  };

  const deleteGoodCatch = () => {
    const feedback = trigger.feedback.find((f) => f.userId === meId);
    const params = setFeedbackParams(FEEDBACK_TYPE.GOOD_CATCH);
    dispatch(deleteFeedback({...params, id: feedback.id}, {alertId: params.triggeredId, type: params.type}));
  };

  const setNotInteresting = () => {
    const params = setFeedbackParams(FEEDBACK_TYPE.NOT_INTERESTING);
    dispatch(setFeedback(params, {alertId: params.triggeredId}));
  };

  const deleteNotInteresting = () => {
    const feedback = trigger.feedback.find((f) => f.userId === meId);
    const params = setFeedbackParams(FEEDBACK_TYPE.NOT_INTERESTING);
    dispatch(deleteFeedback({...params, id: feedback.id}, {alertId: params.triggeredId, type: params.type}));
  };

  const updateGoodCatch = ({comment}) => {
    const feedback = trigger.feedback.find((f) => f.userId === meId);
    const params = setFeedbackParams(FEEDBACK_TYPE.GOOD_CATCH);
    dispatch(
      updateFeedback({
        ...params,
        id: feedback.id,
        comment,
      }),
    );
  };

  const updateNotInteresting = ({reason, comment}) => {
    const feedback = trigger.feedback.find((f) => f.userId === meId);
    const params = setFeedbackParams(FEEDBACK_TYPE.NOT_INTERESTING);
    dispatch(
      updateFeedback({
        ...params,
        id: feedback.id,
        reason: reason ? NOT_INTERESTING_REASONS.find((r) => r.value === reason).title : '',
        comment,
      }),
    );
  };

  return (
    <Box className={classes.feedback}>
      <TypographyBox variant="subtitle3" mr={5}>
        Feedback
      </TypographyBox>
      <Feedback
        isGoodCatchByMe={isGoodCatchByMe}
        isNotInterestingByMe={isNotInterestingByMe}
        othersGoodCatch={othersGoodCatch}
        otherNotInteresting={otherNotInteresting}
        onSetGoodCatch={setGoodCatch}
        onSetNotInteresting={setNotInteresting}
        onRemoveGoodCatch={deleteGoodCatch}
        onRemoveNotInteresting={deleteNotInteresting}
        onUpdateGoodCatch={updateGoodCatch}
        onUpdateNotInteresting={updateNotInteresting}
        size="large"
      />
    </Box>
  );
};

export default memo(FeedbackContainer);
