import {createSelector} from 'reselect';
import {isString} from 'lodash';

export const getRoot = (state) => state.adminCustomers;

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

// ****************Data*****************

export const getData = createSelector(
  getRoot,
  (i) => i.data,
);

export const getCustomers = createSelector(
  getData,
  (i) => i.customers,
);
export const getCustomersActionsData = createSelector(
  getData,
  (i) => i.customersActionsData,
);

export const getCustomersData = createSelector(
  getCustomers,
  (i) => i.data || EMPTY_ARRAY,
);
export const getCustomersListData = createSelector(
  getCustomers,
  // Hide the Anodot internal customers
  (i) => i.data.filter((customer) => customer.kind !== 'anodot') || EMPTY_ARRAY,
);

export const getCustomersDataCount = createSelector(
  getCustomersListData,
  (customersData) => customersData.length,
);
export const getIsLoadingCustomers = createSelector(
  getCustomers,
  (c) => c.isLoading,
);
export const getIsLoadingCustomerData = createSelector(
  getCustomersActionsData,
  (c) => c.isLoadingCustomerData,
);
export const getIsLoadingDefaultSettings = createSelector(
  getCustomersActionsData,
  (c) => c.isLoadingDefaultSettings,
);
export const getIsSubmittingCustomerData = createSelector(
  getCustomersActionsData,
  (c) => c.isSubmittingCustomerData,
);

export const getLeanCustomers = createSelector(
  getCustomers,
  (customersList) =>
    customersList.data.map((customer) => ({
      value: customer._id,
      label: customer.name,
      token: customer.apiToken,
      metrics: customer.numberOfMetrics,
    })),
);

export const getCustomersViewsSection = createSelector(
  getRoot,
  (customers) => customers.views || EMPTY_ARRAY,
);

export const getCustomersListViews = createSelector(
  getCustomersViewsSection,
  (customers) => customers.customersList || EMPTY_ARRAY,
);

export const getCustomersFilters = createSelector(
  getCustomersViewsSection,
  (viewSection) => viewSection.filters || EMPTY_ARRAY,
);

export const getCustomersListOrder = createSelector(
  getCustomersListViews,
  (customersView) => customersView.listOrder,
);

export const getEditCustomerData = createSelector(
  getCustomers,
  (i) => i.editCustomersData || EMPTY_OBJECT,
);
export const getCustomerDefaultSettings = createSelector(
  getCustomersActionsData,
  (i) => i.defaultSettings || EMPTY_OBJECT,
);
export const getCustomerSectionCustomersList = createSelector(
  getCustomersListData,
  (customersData) => {
    if (!customersData.length) {
      return EMPTY_ARRAY;
    }
    return customersData.map((customer) => ({
      id: customer._id,
      name: customer.name,
      description: customer.description,
      status: customer.disabled,
      tags: customer.tags,
      settings: customer.settings,
      kind: customer.kind,
      numberOfMetrics: customer.numberOfMetrics,
      dnsPrefix: customer.dnsPrefix,
      apiToken: customer.apiToken,
      created: customer.created,
      modified: customer.modified,
      paying: customer.settings.payingCustomer,
      selfService: customer.settings.sierra ? customer.settings.sierra.selfService : false,
    }));
  },
);

export const getSortedCustomerSectionCustomersList = createSelector(
  getCustomerSectionCustomersList,
  getCustomersListOrder,
  (customersList, listOrder) => {
    const sortingFunction = (arr, sortBy, direction) =>
      arr.sort((a, b) => {
        let itemA;
        let itemB;
        switch (sortBy) {
          case 'self-service':
            itemA = a.selfService ? 1 : 0;
            itemB = b.selfService ? 1 : 0;
            break;
          case 'paying':
            itemA = a[sortBy] ? 1 : 0;
            itemB = b[sortBy] ? 1 : 0;
            break;
          case 'tags':
            itemA = a[sortBy].toString().toLowerCase();
            itemB = b[sortBy].toString().toLowerCase();
            break;
          default:
            itemA = isString(a[sortBy]) ? a[sortBy].toLowerCase() : a[sortBy];
            itemB = isString(b[sortBy]) ? b[sortBy].toLowerCase() : b[sortBy];
        }

        if (itemA < itemB) {
          return -1 * direction;
        }
        if (itemA > itemB) {
          return direction;
        }
        return 0;
      });

    return [...sortingFunction(customersList, listOrder.column, listOrder.direction === 'desc' ? -1 : 1)];
  },
);

export const getCustomersViewStatusModal = createSelector(
  getCustomersListViews,
  (viewSection) => viewSection.customerStatusModal,
);

export const getCustomersViewDeleteModal = createSelector(
  getCustomersListViews,
  (viewSection) => viewSection.deleteModal,
);

export const getCustomersViewStatusModalCustomerName = createSelector(
  getCustomersViewStatusModal,
  getCustomerSectionCustomersList,
  (statusModal, sectionCustomerList) => {
    const findCustomer = sectionCustomerList.find((customer) => customer.id === statusModal.customerId);
    return findCustomer ? findCustomer.name : 'Unknown';
  },
);
export const getCustomersViewDeleteModalCustomerName = createSelector(
  getCustomersViewDeleteModal,
  getCustomerSectionCustomersList,
  (deleteModal, sectionCustomerList) => {
    const findCustomer = sectionCustomerList.find((customer) => customer.id === deleteModal.customerId);
    return findCustomer ? findCustomer.name : 'Unknown';
  },
);
