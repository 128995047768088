import React, {memo, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import RespondersTooltip from 'feedback/RespondersTooltip';
import {FEEDBACK_TYPE, FEEDBACK_SIZE} from 'alerts.console/components/alertsList/feedback/constants';
import FeedbackPopover from './FeedbackPopover';
import FormDdl from '../common/componentsV2/ddl/multiSelectFormDdl/FormDdl';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 'auto',
  },
  feedbackWrapper: {
    display: 'flex',
    alignItems: (props) => (props.size === 'large' ? 'flex-end' : 'baseline'),
  },
  goodCatch: {
    color: (props) => (props.isGoodCatchByMe ? theme.palette.blue['500'] : theme.palette.gray['400']),
    '&:hover': {
      color: theme.palette.blue['500'],
    },
  },
  goodCatchNumber: {
    width: 13,
    color: (props) => (props.isGoodCatchByMe ? theme.palette.blue['500'] : theme.palette.gray['500']),
    paddingBottom: '2px',
  },
  notInteresting: {
    position: 'relative',
    top: 2,
    marginRight: 2,
    color: (props) => (props.isNotInterestingByMe ? theme.palette.blue['500'] : theme.palette.gray['400']),
    '&:hover': {
      color: theme.palette.blue['500'],
    },
  },
  notInterestingNumber: {
    width: 13,
    display: 'contents',
    marginBottom: '2px',
    color: (props) => (props.isNotInterestingByMe ? theme.palette.blue['500'] : theme.palette.gray['500']),
  },
  feedbackPopover: {
    minWidth: '243px !important',
  },
}));

const Feedback = ({
  isGoodCatchByMe,
  othersGoodCatch,
  isNotInterestingByMe,
  otherNotInteresting,
  onSetGoodCatch,
  onUpdateGoodCatch,
  onRemoveGoodCatch,
  onSetNotInteresting,
  onUpdateNotInteresting,
  onRemoveNotInteresting,
  isGoodCatchDisplay,
  isNotInterestingDisplay,
  size,
}: PropTypes) => {
  const [isGoodCatchPopoverDisplay, setIsGoodCatchPopoverDisplay] = useState(null);
  const [isNotInterestingPopoverDisplay, setIsNotInterestingPopoverDisplay] = useState(null);
  const [isDisableTooltip, setIsDisableTooltip] = useState(false);
  const classes = useStyles({
    isGoodCatchByMe,
    othersGoodCatch,
    isNotInterestingByMe,
    otherNotInteresting,
    size,
  });

  const setGoodCatch = (e) => {
    e.preventDefault();
    setIsDisableTooltip(true);

    if (isGoodCatchByMe) {
      onRemoveGoodCatch();
    }

    if (!isGoodCatchByMe && !isNotInterestingByMe) {
      onSetGoodCatch();
    }

    if (!isGoodCatchByMe && isNotInterestingByMe) {
      onRemoveNotInteresting();
      onSetGoodCatch();
    }
  };

  const setNotInteresting = (e) => {
    e.preventDefault();
    setIsDisableTooltip(true);

    if (isNotInterestingByMe) {
      onRemoveNotInteresting();
    }
    if (!isNotInterestingByMe && !isGoodCatchByMe) {
      onSetNotInteresting();
    }

    if (!isNotInterestingByMe && isGoodCatchByMe) {
      onRemoveGoodCatch();
      onSetNotInteresting();
    }
  };

  const onToggleGoodCatch = useCallback(() => {
    if (isGoodCatchByMe) {
      setIsGoodCatchPopoverDisplay(false);
    } else {
      setIsGoodCatchPopoverDisplay(true);
      setIsNotInterestingPopoverDisplay(false);
    }
  }, [isGoodCatchByMe]);

  const onToggleNotInteresting = useCallback(() => {
    if (isNotInterestingByMe) {
      setIsNotInterestingPopoverDisplay(false);
    } else {
      setIsNotInterestingPopoverDisplay(true);
      setIsGoodCatchPopoverDisplay(false);
    }
  }, [isNotInterestingByMe]);

  return (
    <Box
      className={classes.container}
      style={{width: isGoodCatchDisplay && isNotInterestingDisplay ? FEEDBACK_SIZE[size].container.width : 'auto'}}
    >
      {isGoodCatchDisplay && (
        <FormDdl
          buttonComponent={
            <RespondersTooltip
              otherResponders={othersGoodCatch}
              isFeedbackByMe={isGoodCatchByMe}
              isDisabled={isDisableTooltip}
              placeholder="Good catch"
            >
              <Box className={classes.feedbackWrapper}>
                <Box component="span" className={classes.goodCatchNumber}>
                  {isGoodCatchByMe ? othersGoodCatch.length + 1 : othersGoodCatch.length}
                </Box>
                <Box
                  component="span"
                  onClick={setGoodCatch}
                  className={`icon icn-general24-like ${classes.goodCatch}`}
                  style={{fontSize: FEEDBACK_SIZE[size].icons.fontSize}}
                />
              </Box>
            </RespondersTooltip>
          }
          isOpen={isGoodCatchPopoverDisplay}
          onToggle={onToggleGoodCatch}
          popoverComponent={
            <FeedbackPopover
              feedbackType={FEEDBACK_TYPE.GOOD_CATCH}
              onSubmit={onUpdateGoodCatch}
              onClose={() => setIsGoodCatchPopoverDisplay(false)}
            />
          }
          placement="auto"
          position="right"
          popoverContainerClassName={classes.feedbackPopover}
        />
      )}
      {isNotInterestingDisplay && (
        <FormDdl
          buttonComponent={
            <RespondersTooltip
              otherResponders={otherNotInteresting}
              isFeedbackByMe={isNotInterestingByMe}
              isDisabled={isDisableTooltip}
              placeholder="Not Interesting"
            >
              <Box
                className={classes.feedbackWrapper}
                style={{flexDirection: isGoodCatchDisplay ? 'row' : 'row-reverse'}}
              >
                <Box
                  component="span"
                  onClick={setNotInteresting}
                  className={`icon icn-general24-dislike ${classes.notInteresting}`}
                  style={{
                    fontSize: FEEDBACK_SIZE[size].icons.fontSize,
                    marginRight: isGoodCatchDisplay ? 5 : 0,
                    marginLeft: isGoodCatchDisplay ? 0 : 5,
                  }}
                />
                <Box component="span" className={classes.notInterestingNumber}>
                  {isNotInterestingByMe ? otherNotInteresting.length + 1 : otherNotInteresting.length}
                </Box>
              </Box>
            </RespondersTooltip>
          }
          isOpen={isNotInterestingPopoverDisplay}
          onToggle={onToggleNotInteresting}
          popoverComponent={
            <FeedbackPopover
              feedbackType={FEEDBACK_TYPE.NOT_INTERESTING}
              onSubmit={onUpdateNotInteresting}
              onClose={() => setIsNotInterestingPopoverDisplay(false)}
            />
          }
          placement="auto"
          position="right"
          popoverContainerClassName={classes.feedbackPopover}
        />
      )}
    </Box>
  );
};

Feedback.defaultProps = {
  isGoodCatchDisplay: true,
  isNotInterestingDisplay: true,
  size: 'mid',
};

Feedback.propTypes = {
  isGoodCatchByMe: PropTypes.bool.isRequired,
  othersGoodCatch: PropTypes.arrayOf(PropTypes.object).isRequired,
  isNotInterestingByMe: PropTypes.bool.isRequired,
  otherNotInteresting: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSetGoodCatch: PropTypes.func.isRequired,
  onSetNotInteresting: PropTypes.func.isRequired,
  onRemoveGoodCatch: PropTypes.func.isRequired,
  onRemoveNotInteresting: PropTypes.func.isRequired,
  onUpdateGoodCatch: PropTypes.func.isRequired,
  onUpdateNotInteresting: PropTypes.func.isRequired,
  isGoodCatchDisplay: PropTypes.bool,
  isNotInterestingDisplay: PropTypes.bool,
  size: PropTypes.string,
};

export default memo(Feedback);
