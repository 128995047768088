// @flow
import React, {Fragment, PureComponent} from 'react';
import {Box, withStyles} from '@material-ui/core';
import Highlighter from 'react-highlight-words';

import SmartTooltip from 'common/components/SmartTooltip';
import {connect} from 'react-redux';
import * as selectors from 'profile/store/selectors';
import {copyToClipBoard as copyToClipBoardAction} from 'common/store/actions';
import {omit, omitBy} from 'lodash';
import {getAlertEditorV2Enabled} from 'alerts.management/store/selectors';
import {success as successAction} from 'common/utils/notifications/notificationsService';
import {clipboardCopied} from 'common/utils/notifications/generalNotificationCodes';

const styles = ({palette, typography}) => ({
  container: {
    display: 'flex',
  },
  wrapper: {
    fontSize: 16,
    display: ({isSingleLine}) => (isSingleLine ? 'block' : 'flex'),
    flexWrap: 'wrap',
    maxHeight: ({isSingleLine}) => (isSingleLine ? 'auto' : '55px'),
    overflow: 'hidden',
    minHeight: ({isSpecialHeader}) => (isSpecialHeader ? '25px' : 'inherit'),
  },
  highlightWrapper: {
    display: ({isSingleLine}) => (isSingleLine ? 'inline' : 'block'),
    height: ({isSpecialHeader}) => (isSpecialHeader ? '25px' : 'inherit'),
    fontWeight: 500,
    color: palette.purple[600],
    cursor: 'default',
  },
  for: {
    height: ({isSpecialHeader}) => (isSpecialHeader ? '25px' : 'inherit'),
    marginRight: 4,
    marginLeft: 4,
    display: ({isSingleLine}) => (isSingleLine ? 'inline' : 'block'),
    color: '#8998a0',
    fontWeight: 'normal',
    pointerEvents: 'none',
  },
  tooltipBox: {
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
    maxWidth: 493,
    borderRadius: 8,
    '&.tooltip': {
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  customPlacement: {
    '&.tooltip': {
      marginTop: -18,
      marginLeft: -12,
    },
    '@global': {
      '.tooltip-inner': {
        position: 'relative',
        transform: 'translate(100%, -50%)',
      },
    },
  },
  tooltipContent: {
    color: palette.gray[500],
    fontWeight: 500,
    fontSize: 14,
  },
  propsListKey: {
    width: 80,
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    flexShrink: 0,
    marginRight: 8,
    fontSize: 12,
    color: palette.gray[400],
    lineHeight: '16px',
    overflow: 'hidden',
  },
  propsListValue: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 56,
    maxWidth: 348,
    lineHeight: '16px',
    color: palette.mint[600],
    wordBreak: 'break-all',
  },
  value: {
    height: ({isSpecialHeader}) => (isSpecialHeader ? '25px' : 'inherit'),
    lineHeight: '16px',
    color: '#009263',
    marginRight: 6,
    padding: 4,
    marginBottom: ({isSpecialHeader}) => (isSpecialHeader ? '6px' : '2px'),
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 6,
    cursor: 'pointer',
    backgroundColor: palette.gray[100],
    '&:hover': {
      backgroundColor: palette.blue[100],
    },
  },
  isClickable: {
    textTransform: 'lowercase',
    cursor: 'pointer',
    color: palette.gray[400],
    '&:hover': {
      color: 'rgb(38, 113, 255) !important',
    },
    '&:visited': {
      color: palette.gray[400],
    },
  },
  ellipsis: {
    ...typography.ellipsis,
  },
});

type PropTypes = {
  metric: Object,
  highlightText: string,
  tooltipPlacement?: string,
  children: React.Node,
  classes: Object,
  isSingleLine: boolean,
  success: Function,

  // connect
  leanMe: Object,
  copyToClipBoard: Function,
  isAlertEditorV2Enabled: boolean,
  isSpecialHeader: boolean,
};

const getClickableLink = ({type, id}, isAlertEditorV2Enabled) => {
  if (type === 'ALERT') {
    if (isAlertEditorV2Enabled) {
      return `#!/r/alert-manager/edit/${id}/settings`;
    }
    return `#!/alerts/${id}`;
  }
  if (type === 'STREAM') {
    return `#!/r/bc/data-manager?streamPreviewId=${id}`;
  }
  if (type === 'COMPOSITE') {
    return `/#!/metrics/composite/management/${id}`;
  }
  return '';
};

@connect(
  (state) => ({
    leanMe: selectors.getLeanUserProfile(state), // used for customer support debug easter egg
    isAlertEditorV2Enabled: getAlertEditorV2Enabled(state),
  }),
  {
    copyToClipBoard: copyToClipBoardAction,
    success: successAction,
  },
)
@withStyles(styles)
export default class NameFormat20 extends PureComponent {
  props: PropTypes;

  static defaultProps = {
    tooltipPlacement: 'top',
  };

  metricContainerClicked = () => {
    this.containerClicksCounter = this.containerClicksCounter || 0;
    const {leanMe, metric, copyToClipBoard} = this.props;

    if (leanMe.impersonatorId && (this.containerClicksCounter + 1) % 3 === 0) {
      const res = {
        env: {
          href: window.location.href,
          host: window.location.host,
        },
        userInfo: leanMe,
        metricInfo: omit(metric, ['baseline', 'dataPoints']),
      };
      copyToClipBoard({event: {}, text: JSON.stringify(res)});
    }
  };

  copyDimension = (value) => {
    navigator.clipboard.writeText(value);
    this.props.success({...clipboardCopied, description: 'Dimension value copied to clipboard'});
  };

  render() {
    const {
      metric,
      highlightText,
      tooltipPlacement,
      children,
      isAlertEditorV2Enabled,
      classes,
      isSingleLine,
      // eslint-disable-next-line no-unused-vars
      isSpecialHeader,
    } = this.props;
    const metricProps = metric.properties.concat(metric.tags.map((t) => ({...t, value: `#${t.value}`})));
    const {origin} = metric;

    const lastPropsKeys = ['target_type', 'mtype', 'func'];
    const lastProps = lastPropsKeys
      .map((key) => {
        const prop = metricProps.find((item) => item.key === key);
        return (
          prop && {
            ...prop,
            isLast: true,
          }
        );
      })
      .filter((item) => item);

    let propsList = [...Object.values(omitBy(metricProps, (item) => lastPropsKeys.includes(item.key))), ...lastProps];

    if (origin) {
      propsList = [
        ...propsList,
        {
          key: `@${origin.type}`,
          value: origin.title,
          isLast: true,
          isClickable: true,
        },
      ];
    }

    const tooltip = (
      <div className="fontSize_14 fontWeight_500">
        {metric.what && <div className="display_flex color_purple-500 mb_1-5">{metric.what}</div>}

        {propsList.map((kv, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="display_flex alignItems_baseline mb_0-5" key={`${kv.key}_${index}`}>
            <div className={classes.propsListKey}>{kv.key}</div>
            <div className={classes.propsListValue}>{kv.value}</div>
          </div>
        ))}
      </div>
    );

    return (
      <div className={`${classes.container}`}>
        <div
          className={`${classes.wrapper} ${isSingleLine && classes.ellipsis}`}
          onClick={this.metricContainerClicked}
          role="presentation"
        >
          {metric.what && (
            <SmartTooltip
              placement="left"
              content={tooltip}
              extraTtClass={`metric-name-tooltip ${classes.tooltipBox} ${classes.customPlacement}`}
            >
              <div className={classes.highlightWrapper}>
                {highlightText ? (
                  <Highlighter
                    autoEscape
                    highlightStyle={{backgroundColor: '#fcd176'}}
                    searchWords={[highlightText]}
                    textToHighlight={metric.what}
                  />
                ) : (
                  metric.what
                )}
              </div>
            </SmartTooltip>
          )}
          {metric.what && metric.properties.length > 0 && <div className={classes.for}>for</div>}

          {propsList.map((p, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={isSingleLine ? 'display_inline' : 'display_block'} key={p.key + i}>
              <Fragment>
                {p.isClickable && (
                  <SmartTooltip
                    placement={tooltipPlacement}
                    content={<div className={classes.tooltipContent}>{p.value}</div>}
                    extraTtClass={`metric-name-tooltip ${classes.tooltipBox}`}
                  >
                    <a
                      className={`${classes.value} ${classes.isClickable} ${
                        isSingleLine ? 'display_inline' : 'display_block'
                      }`}
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                      rel="noreferrer"
                      href={getClickableLink(origin, isAlertEditorV2Enabled)}
                    >
                      {p.key}
                    </a>
                  </SmartTooltip>
                )}

                {!p.isClickable && (
                  <SmartTooltip
                    placement={tooltipPlacement}
                    content={<Box css={styles.tooltipContent}>{p.key}</Box>}
                    extraTtClass={`metric-name-tooltip ${classes.tooltipBox}`}
                  >
                    <div
                      onClick={() => this.copyDimension(p.value)}
                      className={`${isSingleLine ? 'display_inline' : 'display_block'} ${classes.value}`}
                    >
                      {highlightText ? (
                        <Highlighter
                          autoEscape
                          highlightStyle={{backgroundColor: '#fcd176'}}
                          searchWords={[highlightText]}
                          textToHighlight={p.value}
                        />
                      ) : (
                        p.value
                      )}
                    </div>
                  </SmartTooltip>
                )}
              </Fragment>
            </div>
          ))}
        </div>
        {children}
      </div>
    );
  }
}
