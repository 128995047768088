// @flow
import React from 'react';
import './AggregationModal.module.scss';
import SelectAndt, {THEME_TRANSPARENT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  disabled?: boolean,
  aggregation: String,
  className?: String,
  onSelect: Function,
};

const aggregationsList = [{key: 'gauge', name: 'Average'}, {key: 'counter', name: 'Sum'}];

export default class AggregationDdl extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    disabled: false,
    className: '',
  };

  render() {
    const {aggregation, disabled, className, onSelect} = this.props;
    const selectedIndex = aggregationsList.findIndex((val) => (aggregation ? aggregation === val.key : false));

    return (
      <SelectAndt
        className={className ? `${className} andt-dropdown` : 'andt-dropdown'}
        disabled={disabled}
        automationId="aggregations"
        id="dateRangesPicker"
        options={aggregationsList}
        getOptionLabel={(val) => val.name}
        getOptionValue={(val) => val.key}
        type={TYPE_NO_SEARCH}
        theme={THEME_TRANSPARENT}
        value={aggregationsList[selectedIndex]}
        onChange={(item) => onSelect(item.key)}
        placeholder="Choose aggregation"
      />
    );
  }
}
