// @flow
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {TypographyBox} from 'common/componentsV2/boxTools';

const TabItem = ({label, id, isSelected, icon, onClick, isEnabled}: PropTypes) => {
  const clicked = () => {
    onClick(id);
  };

  return (
    <Fragment>
      {isEnabled && (
        <Box
          pr={2}
          pl={2}
          height={36}
          display="inline-flex"
          alignItems="center"
          borderColor="gray.200"
          borderRadius="18px"
          border="solid 2px"
          mr={1.5}
          onClick={clicked}
          bgcolor={isSelected ? 'gray.200' : 'white.500'}
          css={{cursor: 'pointer'}}
        >
          {icon ? (
            <Box mr={0.5} display="flex" alignItems="center">
              {icon}
            </Box>
          ) : null}
          <TypographyBox variant="h5" color={isSelected ? 'blue.500' : 'gray.400'} fontWeight={400}>
            {label}
          </TypographyBox>
        </Box>
      )}
    </Fragment>
  );
};

TabItem.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool.isRequired,
};

export default React.memo(TabItem);
