// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import UsersChannelsFilter from 'alerts.channels/components/UsersChannelsFilter';
import * as filterSelector from 'alerts.management/store/filterSelector';
import {segmentClickEvent} from 'common/store/actions';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';

type PropTypes = {
  setQueryParams: Function,

  // connect
  ownerList: Array,
  selectedOwners: Array,
  segmentClickEvent: Function,
};

@connect(
  (state) => ({
    ownerList: filterSelector.getFilterOwnerList(state),
    selectedOwners: filterSelector.getSelectedFilterOwner(state),
  }),
  {
    segmentClickEvent,
  },
)
export default class OwnerFilter extends React.PureComponent {
  props: PropTypes;

  onChange = (items) => {
    const ownerVal = items.length ? items.map((item) => item.value).join(',') : null;
    this.props.segmentClickEvent({category: 'alert-manager', name: `filter-owner-${ownerVal}`});
    this.props.setQueryParams({owner: ownerVal});
  };

  onClearAll = () => {
    this.props.segmentClickEvent({
      category: 'alert-manager',
      name: 'filter-owner-clear-all',
    });
    this.props.setQueryParams({owner: null});
  };

  render() {
    const {selectedOwners, ownerList} = this.props;
    return (
      <UsersChannelsFilter
        onChange={this.onChange}
        sortedFlattenChannels={ownerList}
        value={selectedOwners}
        footerComponent={
          <FormDdlActionBar
            isVisible
            isClearAllDisabled={!selectedOwners || !selectedOwners.length}
            onClearAll={this.onClearAll}
          />
        }
      />
    );
  }
}
