// @flow
import React from 'react';
import TableListItem, {CHECKBOX_VISIBILITY_TYPES} from 'common/componentsV2/table/TableListItem';
import ExpandedPanel from '../expandedPanel/ExpandedPanel';
import ChannelItemData from './ChannelItemData';
import ChannelItemActions from './ChannelItemActions';

const ChannelItem = ({
  channel,
  selectedItem,
  setSelectedItem,
}: {
  channel: Object,
  selectedItem: Function,
  setSelectedItem: Function,
}) => {
  return (
    <div className="display_flex" id={`scrollPosition-${channel.id}`}>
      <TableListItem
        key={channel.id}
        id={channel.id}
        style={{width: '100%'}}
        headerComponent={<ChannelItemData channel={channel} />}
        expandedPanel={<ExpandedPanel channel={channel} />}
        checkboxVisibility={CHECKBOX_VISIBILITY_TYPES.alwaysHide}
        setSelectedItem={(id) => (id === selectedItem ? setSelectedItem() : setSelectedItem(id))}
        isSelected={selectedItem === channel.id}
        actionToolbarComponent={<ChannelItemActions id={channel.id} />}
      />
    </div>
  );
};

export default ChannelItem;
