import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {combineReducers} from 'redux';
import * as alertsActions from 'alerts.console/store/actions';
import * as actions from '../actions';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const fetchSingleImpact = composeReducers(
  makeAsyncReducer(actions.getSingleImpact, {
    defaultData: EMPTY_OBJECT,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const fetchImpactsList = composeReducers(
  makeAsyncReducer(actions.getImpactsByOrigin, {
    defaultData: EMPTY_ARRAY,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const updateImpact = composeReducers(
  makeAsyncReducer(actions.putImpact, {
    defaultData: EMPTY_OBJECT,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const createImpact = composeReducers(
  makeAsyncReducer(actions.postImpact, {
    defaultData: EMPTY_OBJECT,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const removeImpact = composeReducers(
  makeAsyncReducer(actions.deleteImpact, {
    defaultData: EMPTY_OBJECT,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const fetchTriggeredAlertsByMeasure = composeReducers(
  makeAsyncReducer(actions.getTriggeredAlertsByMeasure, {
    defaultData: EMPTY_ARRAY,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const alertsTriggeredData = composeReducers(
  makeAsyncReducer(alertsActions.fetchTriggeredAlertForSnooze, {
    defaultData: EMPTY_ARRAY,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

export default combineReducers({
  fetchSingleImpact,
  fetchImpactsList,
  alertsTriggeredData,
  updateImpact,
  createImpact,
  removeImpact,
  fetchTriggeredAlertsByMeasure,
});
