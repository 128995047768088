// @flow
import React from 'react';
import SeverityMarker from 'common/componentsV2/SeverityMarker';
import WarningSign from 'common/componentsV2/WarningSign';
import Highlighter from 'react-highlight-words';
import Tooltip, {TYPES} from '../../../common/componentsV2/Tooltip';
import './ExpandableListItemHeader.module.scss';

type PropTypes = {
  searchQuery: string,
  alert: Object,
};

export default class AlertsManagerHeader extends React.PureComponent {
  props: PropTypes;

  isShowCircle = () => {
    const searchString = this.props.alert.description;
    if (!this.props.searchQuery || this.props.searchQuery.length === 0) {
      return false;
    }
    return searchString.indexOf(this.props.searchQuery) > 0;
  };

  render() {
    const {alert, searchQuery} = this.props;

    return (
      <div
        styleName={['container', 'list-item', alert.alertConfiguration.status === 'active' ? 'active' : ''].join(' ')}
      >
        <div styleName="checkbox" />
        <div styleName="title">
          <SeverityMarker severity={alert.alertConfiguration.severity} />
          <Tooltip content={alert.title} type={TYPES.SMALL} delay={500}>
            <div styleName="title-text" automation-id="Alert title">
              <Highlighter autoEscape searchWords={[searchQuery]} textToHighlight={alert.title} />
            </div>
          </Tooltip>
          {this.isShowCircle() ? <div styleName="highlighter-circle" /> : null}
        </div>
        <div styleName="field warnings">
          <WarningSign notification={alert.alertConfiguration.warningMessage} warningLevel={alert.warnings} />
        </div>
        <Tooltip content={alert.owner} type={TYPES.SMALL}>
          <div styleName="field owner">{alert.owner}</div>
        </Tooltip>
        <div styleName="field dateModified">{alert.dateModified}</div>
        <div styleName="field status">{alert.status}</div>
        <div styleName="field estimation">{alert.estimation}</div>
      </div>
    );
  }
}
