import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {combineReducers} from 'redux';
import {fileUploadErrorHandler} from 'assets/services/errorCodes';
import * as actions from '../actions';

const EMPTY_ARRAY = [];

const dynamicRoutingFiles = composeReducers(
  makeAsyncReducer(actions.getDynamicRoutingFiles, {
    defaultData: EMPTY_ARRAY,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const uploadDynamicRoutingFile = composeReducers(
  makeAsyncReducer(actions.uploadDynamicRoutingFile, {defaultData: null, errorHandler: fileUploadErrorHandler}),
);

const viewDynamicRoutingFile = composeReducers(
  makeAsyncReducer(actions.viewDynamicRoutingFile, {
    defaultData: EMPTY_ARRAY,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

export default combineReducers({
  dynamicRoutingFiles,
  uploadDynamicRoutingFile,
  viewDynamicRoutingFile,
});
