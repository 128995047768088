/* eslint-disable */
import moment from 'moment';
import 'moment-timezone';
import {isString} from 'lodash';
import {timestamp} from 'rxjs/operator/timestamp';

const DATE_FORMAT = 'l';
const TIME_FORMAT = 'HH:mm';
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const getCorrectTimezoneName = (tzName) => {
  if (!tzName || tzName === 'Browser') {
    return moment.tz.guess();
  }
  return tzName;
};

/**
 * return the minimum format a date can be displayed in.
 * date-now < 1 day -> hh:mm PM
 * date-now > 1 year -> MMM D YYYY
 * date-now > 1 day -> MMM D
 * @param timestamp
 */

export const getFormattedDateMini = (timestamp, timezoneName) => {
  const correctTimeZone = getCorrectTimezoneName(timezoneName);
  const timeStamp = moment.unix(timestamp);
  const now = moment();

  if (now.diff(timeStamp, 'year') >= 1) {
    return timeStamp.tz(correctTimeZone).format('ll'); // Sep 4 1986
  }
  if (timeStamp.tz(correctTimeZone).dayOfYear() === now.tz(correctTimeZone).dayOfYear()) {
    return timeStamp.tz(correctTimeZone).format('h:mm A'); // 8:30 PM
  }
  return timeStamp.tz(correctTimeZone).format('MMM D'); // Sep 4
};

export const getFormattedDateFull = (timestamp, timezoneName) => {
  const correctTz = getCorrectTimezoneName(timezoneName);
  const m = moment.unix(timestamp);

  return m.tz(correctTz).format('lll'); // Sep 4, 1986 11:30 AM
};

export const getFormattedDate = (timestamp, timezoneName) => {
  const correctTz = getCorrectTimezoneName(timezoneName);
  const m = moment.unix(timestamp);

  return m.tz(correctTz).format(DATE_FORMAT); // 05/17/2018
};

export const getFormattedLLDate = (timestamp, timezoneName) => {
  const correctTz = getCorrectTimezoneName(timezoneName);
  const m = moment.unix(timestamp);
  return m.tz(correctTz).format('ll'); // Sep 4, 1986
};

export const getFormattedMDH = (timestamp, timezoneName) => {
  const correctTz = getCorrectTimezoneName(timezoneName);
  const m = moment.unix(timestamp);
  return m.tz(correctTz).format('MMM D, h:mm A'); // Sep 7, 4:15 PM
};

export const getFormattedTime = (timestamp, timezoneName) => {
  const correctTz = getCorrectTimezoneName(timezoneName);
  const m = moment.unix(timestamp);

  return m.tz(correctTz).format(TIME_FORMAT); // 18:33
};

export const getFormattedDateTime = (timestamp, timezoneName) => {
  const correctTz = getCorrectTimezoneName(timezoneName);
  if (isString(timestamp)) {
    return moment(timestamp)
      .tz(correctTz)
      .format(DATE_TIME_FORMAT); // 05/17/2018 18:33
  }
  const m = moment.unix(timestamp);
  return m.tz(correctTz).format(DATE_TIME_FORMAT); // 05/17/2018 18:33
};

/**
 * return the minimum format a date can be displayed at.
 * date-now < 1 day -> hh:mm PM
 * date-now > 1 year -> MMM D YYYY
 * date-now > 1 day -> MMM D
 * @param startTimestamp
 * @param endTimestamp
 * @param includeAgo whether or not to include the 'ago' string
 */
export const getDateRelativeSince = (startTimestamp, endTimestamp, includeAgo) => {
  let end = moment();
  const start = moment.unix(startTimestamp);

  if (endTimestamp) {
    end = moment.unix(endTimestamp);
  }

  return start.from(end, !includeAgo);
};

export const getFormattedHumanDuration = (durationInSeconds, mode = null) => {
  if (mode === 'short') {
    if (durationInSeconds >= 86400) {
      return `${Math.round(durationInSeconds / 86400)}d`;
    }
    if (durationInSeconds >= 3600) {
      return `${Math.round(durationInSeconds / 3600)}h`;
    }
    return `${Math.round(durationInSeconds / 60)}m`;
  }

  if (mode === 'absolute') {
    if (durationInSeconds % 86400 === 0) {
      return `${Math.round(durationInSeconds / 86400)} days`;
    }
    if (durationInSeconds % 3600 === 0) {
      return `${Math.round(durationInSeconds / 3600)} hours`;
    }
    return `${Math.round(durationInSeconds / 60)} minutes`;
  }

  let response = '';
  const days = Math.floor(durationInSeconds / 86400);
  const hours = Math.floor((durationInSeconds - days * 86400) / 3600);
  const minutes = Math.floor((durationInSeconds - days * 86400 - hours * 3600) / 60);
  if (days) {
    response += `${days}d `;
  }
  if (hours) {
    response += `${hours}h `;
  }
  if (minutes) {
    response += `${minutes}m`;
  }
  return response;
};

export const getIsBetweenTime = (firstDate, secondDate = new Date(), timeRange, timezoneName) => {
  if (timezoneName) {
    const correctTz = getCorrectTimezoneName(timezoneName);
    const m = moment.unix(firstDate).tz(correctTz);
    const now = moment.unix(secondDate).tz(correctTz);
    return moment(m).isSame(now, timeRange);
  }
  return moment(moment.unix(firstDate)).isSame(secondDate, timeRange);
};

export const getHumanDate = (categories) => {
  if (categories.length <= 14) {
    return categories.map((epochTime) => {
      const isBetweenTime = getIsBetweenTime(epochTime, 'week');
      if (isBetweenTime) {
        return moment.unix(epochTime).format('ddd'); // Sun
      }
      return moment.unix(epochTime).format('M/D'); // 1/5
    });
  }
  return categories.map((epochTime) => moment.unix(epochTime).format('M/D')); // 1/5
};

export const getDay = (timestamp, timezoneName) => {
  const isToday = getIsBetweenTime(timestamp, undefined, 'day', timezoneName);
  const correctTz = getCorrectTimezoneName(timezoneName);
  const m = moment.unix(timestamp).tz(correctTz);

  if (isToday) {
    return 'Today'; // Today
  }
  return m.format('MMM D, YYYY'); // Sep 4, 2021
};
