// @flow
import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {palette} from 'app/styles/theme';
import {makeStyles} from '@material-ui/core/styles';
import Modal, {SIZES as MODAL_SIZES} from 'common/componentsV2/modal/Modal';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {TinyScrollBox, TypographyBox} from 'common/componentsV2/boxTools';
import {getTimeZoneName} from 'profile/store/selectors';
import * as assetsSelectors from 'assets/store/selectors';
import * as actions from 'assets/store/actions';
import {compareFunction, TITLES} from 'alerts.management/components/editor/simulationArea/dynamicRouting/service';
import {prepareFile} from 'assets/services/service';
import RoutingModalTableItem from './RoutingModalTableItem';
import RoutingModalFooter from './RoutingModalFooter';
import RoutingModalOpenBtn from './RoutingModalOpenBtn';

const useStyles = makeStyles(() => ({
  titles: {
    padding: '0 15px 3px 0',
    borderBottom: `1px solid ${palette.gray['200']}`,
  },
  title: {
    color: palette.gray['400'],
    cursor: 'pointer',
  },
  activeTitle: {
    color: palette.gray['600'],
    cursor: 'default',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 290,
  },
}));

const RoutingModal = (props: {onChange: Function, value: String}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const timeZone = useSelector(getTimeZoneName);
  const dynamicRoutingFilesData = useSelector(assetsSelectors.getDynamicRoutingFilesData);
  const dynamicRoutingFilesIsLoading = useSelector(assetsSelectors.getDynamicRoutingFilesIsLoading);
  const uploadFileIsLoading = useSelector(assetsSelectors.getUploadFileIsLoading);
  const uploadFileError = useSelector(assetsSelectors.getUploadFileError);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const [activeTitle, setActiveTitle] = useState(null);
  const [direction, setDirection] = useState('asc');
  const [dynamicRoutingFilesDataSorted, setDynamicRoutingFilesDataSorted] = useState([]);

  const foundSelectedTable = (tableId) => dynamicRoutingFilesData.find((i) => i.id === tableId);

  const openModal = () => {
    setIsModalOpen(true);
    dispatch(actions.getDynamicRoutingFiles());
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const changeTable = (tableId) => {
    setSelectedTable(foundSelectedTable(tableId));
  };

  const implementTable = () => {
    props.onChange(selectedTable.id);
    closeModal();
  };

  const uploadDynamicRoutingFile = (e) => {
    const file = prepareFile(e);
    dispatch(actions.addDynamicRoutingFile({file}));
  };

  const sort = (sortBy, type, isActive) => {
    const updateDirection = direction === 'desc' && isActive ? 'asc' : 'desc';

    setDirection(updateDirection);
    setActiveTitle(sortBy);

    const sortedList = dynamicRoutingFilesData.sort((a, b) =>
      compareFunction[type][updateDirection](a[sortBy], b[sortBy]),
    );
    setDynamicRoutingFilesDataSorted([...sortedList]);
  };

  useEffect(() => {
    if (props.value) {
      dispatch(actions.getDynamicRoutingFiles());
    }
  }, []);

  useEffect(() => {
    if (dynamicRoutingFilesData.length && props.value) {
      sort(TITLES.CREATION_DATE.label, TITLES.CREATION_DATE.type, false);
      changeTable(props.value);
    }
    if (dynamicRoutingFilesData.length) {
      sort(TITLES.CREATION_DATE.label, TITLES.CREATION_DATE.type, false);
    }
  }, [dynamicRoutingFilesData]);

  useEffect(() => {
    if (uploadFileIsLoading === false && !uploadFileError) {
      dispatch(actions.getDynamicRoutingFiles());
    }
  }, [uploadFileIsLoading]);

  return (
    <Fragment>
      <RoutingModalOpenBtn openModal={openModal} selectedRoutingFile={foundSelectedTable(props.value)} />
      <Modal
        onClose={closeModal}
        isOpen={isModalOpen}
        size={MODAL_SIZES.LARGE}
        isCloseButtonHidden
        isStatic={false}
        height="485px"
      >
        <Box mb={2}>
          <div className="text20med-gray600 mb_1">Select a Routing table</div>
          <span className="text14reg lineHeight_14">
            Select a table that should be referenced for the Dynamic routing instructions, or upload a new table.
            <br />
            Dynamic routing only supports mail addresses for recipients.
          </span>
        </Box>

        <Grid container className={classes.titles}>
          <Grid item sm={8}>
            <Grid container>
              <Grid item sm={1} />
              <Grid item sm={11}>
                <TypographyBox
                  variant="caption"
                  component="span"
                  className={activeTitle === TITLES.TITLE.label ? classes.activeTitle : classes.title}
                  onClick={() => sort(TITLES.TITLE.label, TITLES.TITLE.type, activeTitle === TITLES.TITLE.label)}
                >
                  Table Name
                </TypographyBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={2}>
            <TypographyBox
              variant="caption"
              component="span"
              className={activeTitle === TITLES.CREATION_DATE.label ? classes.activeTitle : classes.title}
              onClick={() =>
                sort(TITLES.CREATION_DATE.label, TITLES.CREATION_DATE.type, activeTitle === TITLES.CREATION_DATE.label)
              }
            >
              Added
            </TypographyBox>
          </Grid>
          <Grid item sm={2}>
            <TypographyBox
              variant="caption"
              component="span"
              className={activeTitle === TITLES.EDIT_DATE.label ? classes.activeTitle : classes.title}
              onClick={() =>
                sort(TITLES.EDIT_DATE.label, TITLES.EDIT_DATE.type, activeTitle === TITLES.EDIT_DATE.label)
              }
            >
              Last Edited
            </TypographyBox>
          </Grid>
        </Grid>

        {dynamicRoutingFilesIsLoading && (
          <Box className={classes.loader}>
            <Spinner color="#3d4c59" size={SIZES.X_BIG_90} />
          </Box>
        )}

        {!dynamicRoutingFilesIsLoading && (
          <TinyScrollBox
            width="100%"
            pr={1.25}
            height="290px"
            css={{overflowY: 'auto', overflowX: 'hidden', flexShrink: 0}}
          >
            {dynamicRoutingFilesDataSorted.map((i) => (
              <RoutingModalTableItem
                onChange={changeTable}
                activeTable={selectedTable && selectedTable.id === i.id}
                key={i.id}
                timeZone={timeZone}
                {...i}
              />
            ))}
          </TinyScrollBox>
        )}
        <RoutingModalFooter
          onUploadFile={uploadDynamicRoutingFile}
          onClose={closeModal}
          selectedTable={selectedTable}
          onImplementTable={implementTable}
        />
      </Modal>
    </Fragment>
  );
};

export default RoutingModal;
