// @flow
import React, {PureComponent} from 'react';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {getFormattedDateMini} from 'common/utils/dateService';
import moment from 'moment';
import './SnoozeAction.module.scss';

type PropTypes = {
  summary: Object,
};

export default class SnoozeIndicator extends PureComponent {
  props: PropTypes;

  constructor(props) {
    super(props);
    this.snoozeType = this.checkSnoozeType();
    const isSnoozeDisplay = this.setTtlDate();
    this.state = {summaryTtlDate: isSnoozeDisplay};
  }

  setTtlDate = () => {
    const {snoozeSummary, stlSummary} = this.props.summary;

    if (snoozeSummary) {
      return moment.unix(snoozeSummary.minResumeTime).toDate();
    }
    if (stlSummary) {
      return moment.unix(stlSummary.minResumeTime).toDate();
    }
    return null;
  };

  dateChanged = (date) => {
    this.setState({summaryTtlDate: date});
  };

  checkSnoozeType = () => {
    const {snoozedMetrics, stlMetrics} = this.props.summary;

    if (snoozedMetrics > 0 && stlMetrics > 0) {
      // red: #D0021B
      return 'snoozed-and-stl';
    }
    if (snoozedMetrics > 0 && stlMetrics === 0) {
      // yellow: #FDB21C
      return 'snoozed';
    }
    if (snoozedMetrics === 0 && stlMetrics > 0) {
      // blue: #38AEE8
      return 'stl';
    }
    return '';
  };

  setTooltipContent = () => {
    const {snoozedMetrics, stlMetrics, totalMetrics} = this.props.summary;
    const date = getFormattedDateMini(moment(this.state.summaryTtlDate).unix());

    if (snoozedMetrics === totalMetrics && stlMetrics === totalMetrics) {
      return (
        <span>
          Snooze alerts and pause learning till:
          {date}
        </span>
      );
    }
    return (
      <div style={{textAlign: 'left'}}>
        <span>
          Snoozed till:
          {date}
        </span>

        {(this.snoozeType === 'snoozed' || this.snoozeType === 'snoozed-and-stl') && (
          <div>
            <span>{snoozedMetrics === totalMetrics ? 'All metrics:' : `${snoozedMetrics} metrics:`} </span>
            <span>Snoozed updates</span>
          </div>
        )}

        {(this.snoozeType === 'stl' || this.snoozeType === 'snoozed-and-stl') && (
          <div>
            <span>{stlMetrics === totalMetrics ? 'All metrics:' : `${stlMetrics} metrics:`} </span>
            <span>Pause learning</span>
          </div>
        )}
      </div>
    );
  };

  render() {
    const {summaryTtlDate} = this.state;

    return summaryTtlDate ? (
      <div styleName="snooze-container">
        <div styleName="resume-time">{getFormattedDateMini(moment(summaryTtlDate).unix(), 'Browser')} </div>
        <SmartTooltip placement="top" content={this.setTooltipContent()}>
          <div styleName="value-container">
            <i className="icon icn-action24-snooze" styleName={`snooze-icon ${this.snoozeType}`} />
          </div>
        </SmartTooltip>
      </div>
    ) : null;
  }
}
