// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedEditor} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import SchedularData from 'bc/components/streams/editor/common/SchedularData';
import {isEditRunning, isStreamEditable} from 'bc/services/dataStreamService';
import '../../sql/sqlSchedular/SqlSchedular.module.scss';

type PropTypes = {
  // connect
  dataStream: Object,
  isLoadingDataSets: boolean,
  dataSetsError: Object,
  setSelectedEditor: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isLoadingDataSets: selectors.getBigQueryStreamDataSetsIsLoading(state),
    dataSetsError: selectors.getBigQueryStreamDataSetsError(state),
  }),
  {
    setSelectedEditor,
  },
)
export default class BqSchedular extends React.PureComponent {
  props: PropTypes;

  openEditor = () => {
    this.props.setSelectedEditor({
      type: 'scheduler_bigquery',
      editorHeight: 'calc(100% - 210px)',
      maxHeight: '600px',
    });
  };

  render() {
    const {dataStream, isLoadingDataSets, dataSetsError} = this.props;
    const isEditable =
      (isStreamEditable(dataStream.state) || isEditRunning(dataStream.id)) &&
      !isLoadingDataSets &&
      !dataSetsError.uMessage;

    return (
      <div className="shell-col" automation-id="bigQueryScheduler" styleName={!isEditable ? 'disabled' : ''}>
        <PanelHeader title="Query Schedule" onSelect={this.openEditor} isEditable={isEditable} />
        <SchedularData />
      </div>
    );
  }
}
