// @flow
import React from 'react';
import {segmentClickEvent} from 'common/store/actions';
import {connect} from 'react-redux';
import Input from 'common/componentsV2/Input';
import {Subject} from 'rxjs/Subject';
import './SearchBox.module.scss';

const EMPTY_OBJECT = {};
const EMPTY_FUNCTION = () => null;

type PropTypes = {
  filter: String,
  onFilter: Function,

  analytics?: Object,
  placeHolder?: String,
  isDisabled?: boolean,
  isAutoFocus?: Boolean,
  onFocus?: Boolean,
  onBlur?: Boolean,
  isAutoFocus?: Boolean,
  debounceTime?: Number,
  automationId?: string,
  segmentClickEvent: Function,
};

@connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)
export default class SearchBox extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    placeHolder: 'Search',
    isDisabled: false,
    isAutoFocus: false,
    analytics: EMPTY_OBJECT,
    onFocus: EMPTY_FUNCTION,
    onBlur: EMPTY_FUNCTION,
    debounceTime: 300,
    automationId: undefined,
  };

  state = {
    searchQuery: this.props.filter || '',
  };

  static getDerivedStateFromProps(props, state) {
    if (props.filter !== state.prevFilter) {
      return {searchQuery: props.filter, prevFilter: props.filter};
    }
    return null;
  }

  componentDidMount() {
    this.searchQuerySubject = new Subject()
      .debounceTime(this.props.debounceTime)
      .distinctUntilChanged((x, y) => x === y)
      .map((x) => this.props.onFilter(x));

    this.searchQuerySubscription = this.searchQuerySubject.subscribe();
    setTimeout(() => {
      this.searchQuerySubject.next(this.props.filter || '');
    }, 0);
    // this.searchQuerySubject.next('');
  }

  componentWillUnmount() {
    this.searchQuerySubscription.unsubscribe();
  }

  onChange = (e) => {
    const val = e.target.value;
    this.props.segmentClickEvent({
      type: 'search',
      name: this.props.placeHolder || this.props.automationId,
      automationId: this.props.automationId,
      value: val,
    });
    this.setState({searchQuery: val});
    this.searchQuerySubject.next(e.target.value);
  };

  onClearAll = () => {
    this.setState({searchQuery: ''});
    this.searchQuerySubject.next('');
  };

  render() {
    const {placeHolder, isDisabled, isAutoFocus, analytics, onFocus, onBlur, automationId} = this.props;
    const {searchQuery} = this.state;
    const additionalProps = {onFocus, onBlur};

    return (
      <div styleName="input-wrapper">
        <i className="icon icn-general16-search" styleName="search-icon" />
        <Input
          onChange={this.onChange}
          value={searchQuery}
          styleName="root"
          placeHolder={placeHolder}
          isDisabled={isDisabled}
          isAutoFocus={isAutoFocus}
          additionalProps={additionalProps}
          data-andt-analytics-category={analytics ? analytics.category : null}
          data-andt-analytics-action={analytics ? analytics.action : null}
          automationId={automationId}
        />
        {searchQuery ? (
          <i className="icon icn-general16-closeb" styleName="close-icon" onClick={this.onClearAll} />
        ) : null}
      </div>
    );
  }
}
