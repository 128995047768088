import {rangeTypes} from 'common/utils/dateRangeService';

export const CHART_HEIGHT = 250;

export const DEFAULT_QUERY_PARAMS = {
  order: 'desc',
  sort: 'updatedTime',
  searchQuery: '',
  size: 100,
  index: 0,
  startTime: null, // for relative ranges we want to keep the URL clean
  endTime: null,
  relativeLast: null,
  relativeNext: null,
  constRange: rangeTypes.d1.key,
  status: undefined,
  ack: undefined,
  types: undefined,
  severities: undefined,
  labels: undefined,
  subscribers: undefined,
  channels: undefined,
  triggerIds: undefined,
  ref: undefined,
  alertConfigurationIds: undefined,
  positiveFeedback: undefined,
  negativeFeedback: undefined,
};

export const TABLE_TITLES = [
  {id: 0, name: 'startTime', title: 'Started'},
  {id: 1, name: 'updatedTime', title: 'Updated'},
  {
    id: 2,
    name: 'duration',
    title: 'Duration',
    isSortable: false,
  },
  {id: 3, name: 'score', title: 'Score'},
  {
    id: 4,
    name: 'metrics',
    title: 'Metrics',
    isSortable: false,
  },
];

export const CAN_BE_SAVED_QUERY_PARAMS = [
  'constRange',
  'status',
  'ack',
  'subscribers',
  'channels',
  'types',
  'labels',
  'severities',
  'positiveFeedback',
  'negativeFeedback',
];

export const ALERT_STATUS = [{label: 'All', value: undefined, id: 'all'}, {label: 'Open', value: 'OPEN', id: 'open'}];

export const ACK = [
  {label: 'All', value: undefined, id: 'all'},
  {label: 'Acknowledged', value: 'ACK', id: 'ack'},
  {label: 'Not Acknowledged', value: 'NOACK', id: 'noAck'},
];

export const TYPE = [
  {label: 'Anomaly', value: 'anomaly', id: 'anomaly'},
  {label: 'Static', value: 'static', id: 'static'},
  {label: 'No Data', value: 'noData', id: 'noData'},
];

export const SEVERITY = [
  {label: 'Info', value: 'INFO', id: 'info'},
  {label: 'Low', value: 'LOW', id: 'low'},
  {label: 'Medium', value: 'MEDIUM', id: 'medium'},
  {label: 'High', value: 'HIGH', id: 'high'},
  {label: 'Critical', value: 'CRITICAL', id: 'critical'},
];

export const FEEDBACK = {
  feedback: {
    id: 'feedback',
    title: 'Feedback',
  },
  positiveFeedback: {
    id: 'positiveFeedback',
    label: 'Good Catch',
  },
  negativeFeedback: {
    id: 'negativeFeedback',
    label: 'Not Interesting',
  },
};

export const REFRESH_ALERTS = ['now', 'off', '1min', '5min', '10min', '15min', '30min', '1hour'];

export const FILTER_KEY_HEADERS = {
  searchQuery: {
    id: 'searchQuery',
    label: 'Search:',
  },
  constRange: {
    id: 'constRange',
    label: 'Date range:',
  },
  status: {
    id: 'status',
    label: 'Status:',
  },
  ack: {
    id: 'ack',
    label: 'Acknowleged:',
  },
  usersAndChannels: {
    id: 'usersAndChannels',
    label: 'Users And Channels:',
  },
  types: {
    id: 'types',
    label: 'Alert Type',
  },
  labels: {
    id: 'labels',
    label: 'Labels',
  },
  severities: {
    id: 'severities',
    label: 'Severities',
  },
  ...FEEDBACK,
};

export const ALLOWED_FILTER_KEYS = {
  constRange: rangeTypes,
  status: {
    ALL: {
      label: 'All',
      value: 'undefined',
    },
    OPEN: {
      label: 'Open',
      value: 'open',
    },
  },
  ack: {
    ALL: {
      label: 'All',
      value: 'undefined',
    },
    ACK: {
      label: 'Acknowledged',
      value: 'ack',
    },
    NOACK: {
      label: 'Not Acknowledged',
      value: 'noAck',
    },
  },
  types: TYPE,
  severities: SEVERITY,
  ...FEEDBACK,
};

export const ANOMALY_BUFFER_TIME = {
  '1m': 120,
  '5m': 600,
  '1h': 3600,
  '1d': 86400,
  '1w': 0,
};
