export const TOP_PANEL_OPTIONS = {
  ALL: {
    value: 'all',
    listDescription: [
      {valueType: 'measures', count: 20},
      {valueType: 'dimensions', count: 20},
      {valueType: 'streams', count: 10},
      {valueType: 'alerts', count: 10},
      {valueType: 'composites', count: 10},
      {valueType: 'forecasts', count: 10},
      {valueType: 'tags', count: 10},
    ],
  },
  MEASURES: {
    value: 'measures',
    listDescription: [{valueType: 'measures', count: 50}],
  },
  DIMENSIONS: {
    value: 'dimensions',
    listDescription: [{valueType: 'dimensions', count: 50}],
  },
  STREAMS: {
    value: 'streams',
    listDescription: [{valueType: 'streams', count: 50}],
  },
  TAGS: {
    value: 'tags',
    listDescription: [{valueType: 'tags', count: 50}],
  },
  SOURCES: {
    value: 'sources',
    listDescription: [
      {valueType: 'streams', count: 20},
      {valueType: 'alerts', count: 20},
      {valueType: 'composites', count: 20},
      {valueType: 'forecasts', count: 20},
    ],
  },
  SEARCH: {
    value: 'search',
    listDescription: [
      {valueType: 'measures', count: 20},
      {valueType: 'dimensions', count: 20},
      {valueType: 'propvals', count: 5}, // TODO -- fix the exact way this one has to be called in the server
      {valueType: 'streams', count: 5},
      {valueType: 'alerts', count: 5},
      {valueType: 'composites', count: 5},
      {valueType: 'forecasts', count: 5},
      {valueType: 'tags', count: 10},
    ],
  },
};

export const TOP_PANEL_OPTIONS_ARR = [
  TOP_PANEL_OPTIONS.ALL.value,
  TOP_PANEL_OPTIONS.MEASURES.value,
  TOP_PANEL_OPTIONS.DIMENSIONS.value,
  TOP_PANEL_OPTIONS.STREAMS.value,
];

export const hasParentWithClass = (parentClass, child) => {
  let node = child.parentNode;
  while (node != null) {
    if (node.classList && node.classList.contains(parentClass)) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};
