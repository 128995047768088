import {combineEpics} from 'redux-observable';

import * as init from './init';
import * as requests from './requests';
import * as routes from './routes';
import * as general from './general';
import * as analytics from './analytics';
import * as notificationSystemEpic from './notificationSystemEpic';
import * as configurationEpic from './configurationEpic';

export default combineEpics(
  ...Object.values(init),
  ...Object.values(requests),
  ...Object.values(routes),
  ...Object.values(general),
  ...Object.values(analytics),
  ...Object.values(notificationSystemEpic),
  ...Object.values(configurationEpic),
);
