// @flow
import React, {PureComponent} from 'react';
import connect from 'react-redux/es/connect/connect';
import Gauge from 'common/componentsV2/gauges/Gauge';

import {fetchAlertsConfigurationTotal as fetchAlertsConfigurationTotalAction} from 'alerts.management/store/actions';
import * as selectors from 'alerts.management/store/selectors';

type PropTypes = {
  selectedItem: Number,
  index: Number,
  description: String,
  // Connect
  value: Number,
  isLoading: Boolean,
  fetchAlertsConfigurationTotal: Function,
};

@connect(
  (state) => ({
    value: selectors.getAlertConfigurationsTotal(state),
    isLoading: selectors.getIsLoadingAlertConfigurationsTotal(state),
  }),
  {
    fetchAlertsConfigurationTotal: fetchAlertsConfigurationTotalAction,
  },
)
export default class AlertsConfigurationGauge extends PureComponent {
  props: PropTypes;

  fetchData = () => {
    const {value, fetchAlertsConfigurationTotal} = this.props;
    if (value === null) {
      fetchAlertsConfigurationTotal();
    }
  };

  render() {
    const {value, isLoading, description, selectedItem, index} = this.props;
    return (
      <Gauge
        description={description}
        value={value}
        isLoading={isLoading}
        selectedItem={selectedItem}
        index={index}
        fetchData={this.fetchData}
      />
    );
  }
}
