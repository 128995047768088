// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import CubesList from 'bc.cubes/components/CubesList';
import SearchBox from 'common/components/SearchBox';
import {
  setSelectedSchema as setSelectedSchemaAction,
  newCube as newCubeAction,
  searchCube as searchCubeAction,
} from 'bc.cubes/store/actions';
import './CubesManager.module.scss';
import NewCubeModal from '../components/NewCubeModal';
import CubesSorting from '../components/CubesSorting';

type PropTypes = {
  // connect
  setSelectedSchema: Function,
  newCube: Function,
  searchCube: Function,
};

@connect(
  () => ({}),
  {
    searchCube: searchCubeAction,
    setSelectedSchema: setSelectedSchemaAction,
    newCube: newCubeAction,
  },
)
export default class CubesManager extends PureComponent {
  props: PropTypes;

  state = {
    newCubeModalOpen: false,
    searchInput: '',
  };

  componentDidMount() {
    this.props.setSelectedSchema('');
  }

  onNewCubeClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.state.newCubeModalOpen) {
      this.setState({newCubeModalOpen: true});
    }
  };

  handleModalClose = () => {
    this.setState({newCubeModalOpen: false});
  };

  handleModalConfirm = (id) => {
    const {setSelectedSchema, newCube} = this.props;
    setSelectedSchema(id);
    this.handleModalClose();
    newCube({streamSchemaId: id});
  };

  onSearch = (value) => {
    this.setState({searchInput: value});
    this.props.searchCube(value);
  };

  render() {
    return (
      <div styleName="root">
        <NewCubeModal
          isOpen={this.state.newCubeModalOpen}
          onClose={this.handleModalClose}
          onStartClicked={this.handleModalConfirm}
        />
        <div styleName="top">
          <div className="andt-page-header">Cubes</div>
          <div>
            <SearchBox filter={this.state.searchInput} isAutoStretch onFilter={this.onSearch} />
          </div>
        </div>
        <div styleName="user-options">
          <div styleName="add-cube-holder">
            <a href="#1" styleName="add-cube" onClick={this.onNewCubeClicked}>
              Add Cube <i className="icon icn-icon-blue-plus" />
            </a>
          </div>
          <CubesSorting />
        </div>
        <div styleName="cubes">
          <CubesList />
        </div>
      </div>
    );
  }
}
