import {makeAction, makeAsyncAction} from 'common/utils/simplifiedAsync';

const mac = (actionName) => makeAction(actionName, 'anodot/bc/');
const macAsync = (actionName) => makeAsyncAction(actionName, 'anodot/bc/');

export const updateDataMangerQueryParams = mac('UPDATE_DATA_MANGER_QUERY_PARAMS');
export const setDataManagerFiltersIsOpen = mac('SET_DATA_MANAGER_FILTERS_IS_OPEN');
export const setDataManagerModalFilter = mac('SET_DATA_MANAGER_MODAL_FILTER');
export const setDataManagerOpenedSources = mac('SET_DATA_MANAGER_OPENED_SOURCES');

//* fetchAlerts\Dashbaords\Anoboards\Composites who use the stream
export const getStreamAssets = mac('GET_STREAM_ASSETS');
export const fetchStreamDashboards = macAsync('FETCH_STREAM_DASHBOARDS');
export const fetchStreamDashboardsV2 = macAsync('FETCH_STREAM_DASHBOARDS_V2');
export const fetchStreamAlerts = macAsync('FETCH_STREAM_ALERTS');
export const fetchStreamComposites = macAsync('FETCH_STREAM_COMPOSITES');

//* **data source types
export const fetchDataSourceTypes = macAsync('FETCH_DATA_SOURCE_TYPES');
export const fetchDataSourceIntegrationTypes = macAsync('FETCH_DATA_SOURCE_INTEGRATION_TYPES');
export const setDataSourceTypeFilterTextInput = mac('SET_DATA_SOURCE_TYPE_FILTER_TEXT_INPUT_VAL');
export const setDataSourceTypeFilterText = mac('SET_DATA_SOURCE_TYPE_FILTER_TEXT_VAL');
export const setIsTimeZoneConfirmModalOpen = mac('SET_IS_TIME_ZONE_CONFIRMATION_OPEN');

//* **data manager
export const setDataManagerFilterTextInput = mac('SET_DATA_MANAGER_FILTER_TEXT_INPUT_VAL');
export const setDataManagerFilterText = mac('SET_DATA_MANAGER_FILTER_TEXT_VAL');
export const setDataManagerStreamPreviewId = mac('SET_DATA_MANAGER_STREAM_PREVIEW_ID');

//* **data source
export const fetchDataSources = macAsync('FETCH_DATA_SOURCES');
export const testDataSource = macAsync('TEST_DATA_SOURCE');
export const createDataSource = macAsync('CREATE_DATA_SOURCE');
export const updateDataSource = macAsync('UPDATE_DATA_SOURCE');
export const deleteDataSource = macAsync('DELETE_DATA_SOURCE');
export const resetAddSourceError = mac('RESET_ADD_SOURCE_ERROR');
export const setSelectedDataSource = mac('SET_SELECTED_DATA_SOURCE');

//* **data stream
export const fetchDataStreams = macAsync('FETCH_DATA_STREAMS');
export const createDataStream = macAsync('CREATE_DATA_STREAM');
export const updateDataStream = macAsync('UPDATE_DATA_STREAM');
export const updateDataStreamScheduling = macAsync('UPDATE_DATA_STREAM_SCHEDULING');
export const deleteDataStream = macAsync('DELETE_DATA_STREAM');
export const deletePipelineStream = macAsync('DELETE_PIPELINE_STREAM');
export const startDataStream = macAsync('START_DATA_STREAM');
export const pauseResumeDataStream = macAsync('PAUSE_RESUME_DATA_STREAM');
export const editDataStream = mac('EDIT_DATA_STREAM');
export const previewDataStream = mac('PREVIEW_DATA_STREAM');
export const setSelectedDataStream = mac('SET_SELECTED_DATA_STREAM');
export const setSelectedEditor = mac('SET_SELECTED_STREAM_EDITOR');
export const setSelectedEditorBounced = mac('SET_SELECTED_STREAM_EDITOR_BOUNCED');

export const openSchedulerEditor = mac('OPEN_SCHEDULER_EDITOR');
export const openSchedulerEditorBounced = mac('OPEN_SCHEDULER_EDITOR_BOUNCED');

export const runAsyncQueryDataStream = macAsync('RUN_ASYNC_QUERY_DATA_STREAM');
export const updateStreamName = macAsync('UPDATE_STREAM_NAME');
export const updateAgentOwner = macAsync('UPDATE_AGENT_OWNER');
export const duplicateStream = macAsync('DUPLICATE_STREAM');
export const editLiveStream = mac('EDIT_LIVE_STREAM');

export const fetchDataStreamCardinality = macAsync('FETCH_DATA_STREAM_CARDINALITY');
export const fetchStreamLastRun = macAsync('FETCH_STREAM_LAST_RUN');
export const fetchStreamHistoryLogs = macAsync('FETCH_STREAM_HISTORY_LOGS');

export const createAlertsFromStream = mac('CREATE_ALERTS_FROM_STREAM');
export const createAlertFromStreamApi = macAsync('CREATE_ALERT_FROM_STREAM_API');
export const createSelectedAlertsAndDashboard = macAsync('CREATE_SELECTED_ALERTS_AND_DASHBOARD');

//* **data streams preview
export const setStreamPreviewColumnWidth = mac('SET_STREAM_PREVIEW_COLUMN_WIDTH');
export const getStreamPreviewData = mac('GET_STREAM_PREVIEW_DATA'); // will also call get data streamS if needed
export const fetchDataStreamPreview = macAsync('FETCH_STREAM_PREVIEW_DATA');
export const fetchDataStreamStats = macAsync('FETCH_STREAM_STATS_DATA');

//* **data live streams preview
export const fetchLiveDataStreamPreview = macAsync('FETCH_LIVE_STREAM_PREVIEW_DATA');
export const fetchLiveDataStreamStats = macAsync('FETCH_LIVE_STREAM_STATS_DATA');

//* **stream schema
export const setStreamSchemaTransform = mac('SET_STREAM_SCHEMA_TRANSFORM');
export const setStreamSchemaColumnKeyVal = mac('SET_STREAM_SCHEMA_COLUMN_KEY_VAL');
export const createStreamSchemaNewColumn = mac('CREATE_STREAM_SCHEMA_NEW_COLUMN');
export const deleteStreamSchemaColumn = mac('DELETE_STREAM_SCHEMA_COLUMN');

//* **ga acounts
export const fetchGoogleAnalyticsAccounts = macAsync('FETCH_GOOGLE_ANALYTICS_ACCOUNTS');
export const setSelectedStreamGaAccount = mac('SET_SELECTED_STREAM_GA_ACCOUNT');
export const setSelectedStreamGaSegment = mac('SET_SELECTED_STREAM_GA_SEGMENT');

//* **ga properties
export const fetchGoogleAnalyticsProperties = macAsync('FETCH_GOOGLE_ANALYTICS_PROPERTIES');
export const setSelectedStreamGaProperty = mac('SET_SELECTED_STREAM_GA_PROPERTY');

//* **ga dimetric
export const fetchMetadata = macAsync('FETCH_DIMETRIC_METADATA');
export const fetchCubes = macAsync('FETCH_DIMETRIC_CUBES');

//* **ga views
export const fetchGoogleAnalyticsViews = macAsync('FETCH_GOOGLE_VIEWS_PROPERTIES');
export const setSelectedStreamGaView = mac('SET_SELECTED_STREAM_GA_VIEW');

//* **ga transforms functions
export const fetchTransformFunctions = macAsync('FETCH_TRANSFORM_FUNCTIONS');

//* **ga templates
export const fetchStreamTemplates = macAsync('FETCH_STREAM_TEMPLATES');
export const fetchGaSegments = macAsync('FETCH_GA_SEGMENTS');
export const createStreamTemplate = macAsync('CREATE_STREAM_TEMPLATE');

//* **google auctions
export const fetchGoogleAuctionsEmail = macAsync('FETCH_GOOGLE_AUCTIONS_EMAIL');
export const fetchGoogleAuctionsStreamAnalysis = macAsync('FETCH_GOOGLE_AUCTIONS_STREAM_ANALYSIS');
export const setGoogleAuctionsDiametricsStreamAnalysisSchema = mac(
  'SET_GOOGLE_AUCTIONS_DIAMETRICS_STREAM_ANALYSIS_SCHEMA',
);
export const fetchGoogleAuctionsTemplates = macAsync('FETCH_GOOGLE_AUCTIONS_TEMPLATES');
export const setAuctionStreamDiametricsChange = mac('SET_GOOGLE_AUCTIONS_STREAM_DIAMETRICS_CHANGE');
export const removeAuctionStreamDiametrics = mac('REMOVE_GOOGLE_AUCTIONS_STREAM_DIAMETRICS');
export const setAuctionStreamClearAllDiametrics = mac('SET_GOOGLE_AUCTIONS_CLEAR_ALL_DIAMETRICS');

//* ** sql
export const fetchMysqlDataSourceListDatabases = macAsync('FETCH_MYSQL_DATA_SOURCE_LIST_DATABASES');
export const resetMysqlDataSourceListDatabases = mac('RESET_MYSQL_DATA_SOURCE_LIST_DATABASES');
export const fetchSqlTablesViewsList = macAsync('FETCH_SQL_TABLES_VIEWS_LIST');
export const fetchSqlSchemasList = macAsync('FETCH_SQL_SCHEMAS_LIST');
export const fetchSqlTableData = macAsync('FETCH_SQL_TABLE_METADATA');
export const fetchSqlTablePreview = macAsync('FETCH_SQL_TABLE_PREVIEW');
export const setSqlTableData = mac('SET_SQL_TABLE_DATA');
export const setSqlStreamTableName = mac('SET_SQL_STREAM_TABLE_NAME');
export const setSqlStreamSchemaName = mac('SET_SQL_STREAM_SCHEMA_NAME');
export const setSqlStreamClearAllDiametricsAndUnAssigned = mac('SET_SQL_STREAM_CLEAR_ALL_DIAMETRICS_AND_UNASSIGNED');
export const setIsStreamEditEnabled = mac('SET_IS_STREAM_EDIT_ENABLED');
export const setSqlStreamClearAllDiametrics = mac('SET_SQL_STREAM_CLEAR_ALL_DIAMETRICS');
export const setSqlStreamDiametricsChange = mac('SET_SQL_STREAM_DIAMETRICS_CHANGE');
export const setSqlStreamQuery = mac('SET_SQL_STREAM_QUERY');
export const removeSqlStreamDiametrics = mac('REMOVE_SQL_STREAM_DIAMETRICS');
export const resetSqlStreamData = mac('RESET_SQL_STREAM_DATA');
export const fetchSqlQueryPreview = macAsync('FETCH_SQL_QUERY_PREVIEW');
export const fetchSqlVerifyQuery = macAsync('FETCH_SQL_VERIFY_QUERY');
export const fetchSqlQueryTemplates = macAsync('FETCH_SQL_QUERY_TEMPLATES');
export const fetchSqlQueryTemplatePreview = macAsync('FETCH_SQL_QUERY_TEMPLATES_PREVIEW');

//* **selected stream
export const getGoogleAnalyticsAccountsViewsProperties = mac('GET_GOOGLE_ANALYTICS_STREAM_ACCOUNTS_VIEWS_PROPERTIES');
export const fetchFuFileProperties = macAsync('FETCH_FU_FILE_PROPERTIES');
export const fetchFuCsvFormatAnalysis = macAsync('FETCH_FU_CSV_FORMAT_ANALYSIS');
export const fetchFuPreview = macAsync('FETCH_FU_PREVIEW');
export const setSelectedStreamKeyVal = mac('SET_SELECTED_STREAM_KEY_VAL');
export const autoSaveDataStreamDebounced = mac('AUTO_SAVE_STREAM_DEBOUNCED');
export const setSelectedStreamMetricsAndDimensions = mac('SET_SELECTED_STREAM_METRICS_&_DIMENSIONS');
export const setSelectedStreamGaExtraUiStateKeyVal = mac('SET_SELECTED_STREAM_GA_EXTRA_UI_STATE_KEY_VAL');
export const applyTemplateOnSelectedStream = mac('APPLY_TEMPLATE_ON_SELECTED_STREAM');
export const setFuStreamFileFormat = mac('SET_FU_STREAM_FILE_FORMAT');
export const setSelectedStreamUiKeyVal = mac('SET_SELECTED_STREAM_UI_KEY_VAL');

// google storage
export const setGoogleStorageStreamFileFormat = mac('SET_GS_STREAM_FILE_FORMAT');
export const applyGoogleStorageStreamUiState = mac('APPLY_GS_STREAM_UI_STATE');
export const setGoogleStorageStreamKeyVal = mac('SET_GS_STREAM_KEY_VAL');
export const fetchGoogleStoragePreview = macAsync('FETCH_GS_PREVIEW');
export const setGoogleStorageStreamUiKeyVal = mac('SET_GS_STREAM_UI_KEY_VAL');
export const fetchGoogleStorageBuckets = macAsync('FETCH_GS_BUCKETS');
export const fetchGoogleStorageCsvFormatAnalysis = macAsync('FETCH_GS_CSV_FORMAT_ANALYSIS');
export const fetchGoogleStorageProjects = macAsync('FETCH_GS_PROJECTS');
export const setGoogleStorageStreamProjectId = mac('SET_GS_STREAM_PROJECT_ID');

export const setStreamDiametricsEditorFilterTextInput = mac('SET_STREAM_DIAMETRICS_EDITOR_FILTER_TEXT_INPUT_VAL');

//* **file dimetrics
export const setFileStreamDiametricsAnalysisSchema = mac('SET_FILE_STREAM_DIAMETRICS_ANALYSIS_SCHEMA');
export const setGoogleStorageStreamDiametricsAnalysisSchema = mac(
  'SET_GOOGLE_STORAGE_STREAM_DIAMETRICS_ANALYSIS_SCHEMA',
);
export const removeFileStreamDiametrics = mac('REMOVE_FILE_STREAM_DIAMETRICS');
export const setFileStreamClearAllDiametrics = mac('SET_FILE_STREAM_CLEAR_ALL_DIAMETRICS');
export const setFileStreamDiametricsTimeDefinition = mac('SET_FILE_STREAM_DIAMETRICS_TIME_DEFINITION');
export const setFileStreamDiametricsChange = mac('SET_FILE_STREAM_DIAMETRICS_CHANGE');

//* **s3
export const fetchS3CsvFormatAnalysis = macAsync('FETCH_S3_CSV_FORMAT_ANALYSIS');
export const setFileUploadProgress = mac('SET_FILE_UPLOAD_PROGRESS');
export const setFileUploadName = mac('SET_FILE_UPLOAD_NAME');
export const setUploadedFileUrl = mac('SET_UPLOADED_FILE_URL');
export const setUploadFileError = mac('SET_UPLOAD_FILE_ERROR');
export const setS3StreamFileFormat = mac('SET_S3_STREAM_FILE_FORMAT');
export const setS3StreamFilePathAndNamePattern = mac('SET_S3_STREAM_FILE_PATH_&_NAME_PATTERN');
export const setS3StreamDiametricsAnalysisSchema = mac('SET_S3_STREAM_DIAMETRICS_ANALYSIS_SCHEMA');
export const setS3StreamKeyVal = mac('SET_S3_STREAM_KEY_VAL');
export const fetchS3Preview = macAsync('FETCH_S3_PREVIEW');
export const setS3StreamUiKeyVal = mac('SET_S3_STREAM_UI_KEY_VAL');

//* **AWS CUR
export const fetchAwsCurReports = macAsync('FETCH_AWS_CUR_REPORTS');
export const fetchAwsCurManifest = macAsync('FETCH_AWS_CUR_MANIFEST');
export const fetchAwsTemplates = macAsync('FETCH_AWS_CUR_TEMPLATES');
export const applyAwsCurTemplate = mac('APPLY_AWS_CUR_TEMPLATE');
export const applyAwsCurTags = mac('APPLY_AWS_CUR_TAGS');

export const setAwsCurReports = mac('SET_AWS_CUR_REPORTS');
export const setAwsCurManifest = mac('SET_AWS_CUR_MANIFEST');
export const setAwsTemplates = mac('SET_AWS_CUR_TEMPLATE');

//* **adobe analytics
export const fetchAdobeBookmarks = macAsync('FETCH_ADOBE_BOOKMARKS');
export const fetchAdobeReportDescription = macAsync('FETCH_ADOBE_REPORT_DESCRIPTION');
export const setAdobeStreamUiBookmarkId = mac('SET_ADOBE_STREAM_UI_BOOKMARK_ID');
export const setAdobeStreamMetricsAndDimensions = mac('SET_ADOBE_STREAM_METRICS_&_DIMENSIONS');

//* **bigquery
export const fetchBigQueryDataSets = macAsync('FETCH_BIGQUERY_DATA_SETS');
export const fetchBigQueryProjects = macAsync('FETCH_BIGQUERY_PROJECTS');
export const setBigQueryStreamDataSet = mac('SET_BIGQUERY_STREAM_DATA_SET');
export const setBigQueryStreamProjectId = mac('SET_BIGQUERY_STREAM_PROJECT_ID');
export const setBigQueryStreamQuery = mac('SET_BIGQUERY_STREAM_QUERY');
export const fetchBigQueryPreview = macAsync('FETCH_BIGQUERY_PREVIEW');
export const fetchBigQueryVerifyQuery = macAsync('FETCH_BIGQUERY_VERIFY_QUERY');
export const setBigQueryStreamClearAllDiametrics = mac('SET_BIGQUERY_STREAM_CLEAR_ALL_DIAMETRICS');
export const setBigQueryStreamDiametricsChange = mac('SET_BIGQUERY_STREAM_DIAMETRICS_CHANGE');
export const removeBigQueryStreamDiametrics = mac('REMOVE_BIGQUERY_STREAM_DIAMETRICS');

//* **mParticle
export const fetchMParticleAnoToken = macAsync('FETCH_MPARTICLE_ANO_TOKEN');
export const fetchMParticleEventTypes = macAsync('FETCH_MPARTICLE_EVENT_TYPES');
export const fetchMParticleEventMetadata = macAsync('FETCH_MPARTICLE_EVENT_METADATA');
export const setMParticleStreamEventType = mac('SET_MPARTICLE_STREAM_EVENT_TYPE');
export const setMParticleStreamClearAllDiametrics = mac('SET_MPARTICLE_STREAM_CLEAR_ALL_DIAMETRICS');
export const setMParticleStreamDiametricsChange = mac('SET_MPARTICLE_STREAM_DIAMETRICS_CHANGE');
export const removeMParticleStreamDiametrics = mac('REMOVE_MPARTICLE_STREAM_DIAMETRICS');

//* **Segment
export const fetchSegmentAnoToken = macAsync('FETCH_SEGMENT_ANO_TOKEN');
export const fetchSegmentMessageTypes = macAsync('FETCH_SEGMENT_MESSAGE_TYPES');
export const setSegmentMessageTypes = mac('SET_SEGMENT_MESSAGE_TYPE');
export const fetchSegmentMessageMetadata = macAsync('FETCH_SEGMENT_MESSAGE_METADATA');
export const setSegmentStreamClearAllDiametrics = mac('SET_SEGMENT_STREAM_CLEAR_ALL_DIAMETRICS');
export const setSegmentStreamDiametricsChange = mac('SET_SEGMENT_STREAM_DIAMETRICS_CHANGE');
export const removeSegmentStreamDiametrics = mac('REMOVE_SEGMENT_STREAM_DIAMETRICS');

//* **Kinesis
export const fetchKinesisPathsAndValues = macAsync('FETCH_KINESIS_PATHS_ANS_VALUES');
export const applyKinesisStreamFilters = mac('APPLY_KINESIS_STREAM_FILTERS');
export const setKinesisStreamUiKeyVal = mac('SET_KINESIS_STREAM_UI_KEY_VAL');
export const fetchKinesisStreamAnalysis = macAsync('FETCH_KINESIS_STREAM_ANALYSIS');
export const setKinesisDiametricsStreamAnalysisSchema = mac('SET_KINESIS_DIAMETRICS_STREAM_ANALYSIS_SCHEMA');

export const seKinesisDiametricsChange = mac('SET_KINESIS_DIAMETRICS_CHANGE');
export const setKinesisDiametricsClearAll = mac('SET_KINESIS_DIAMETRICS_CLEAR_ALL');
export const setKinesisDiametricsTimeDefinition = mac('SET_KINESIS_DIAMETRICS_TIME_DEFINITION');
export const removeKinesisDiametrics = mac('REMOVE_KINESIS_DIAMETRICS');

//* **Salesforce
export const fetchSalesforceObjects = macAsync('FETCH_SALESFORCE_OBJECTS');
export const fetchSalesforceObjectData = macAsync('FETCH_SALESFORCE_OBJECT_DATA');
export const setSalesforceStreamKeyVal = mac('SET_SALESFORCE_STREAM_KEY_VAL');
export const setSalesforceStreamDiametricsChange = mac('SET_SALESFORCE_STREAM_DIAMETRICS_CHANGE');
export const setSalesforceStreamClearAllDiametrics = mac('SET_SALESFORCE_STREAM_CLEAR_ALL_DIAMETRICS');
export const removeSalesforceStreamDiametrics = mac('REMOVE_SALESFORCE_STREAM_DIAMETRICS');
export const fetchSalesforceQueryPreview = macAsync('FETCH_SALESFORCE_QUERY_PREVIEW');
export const fetchSalesforceVerifyQuery = macAsync('FETCH_SALESFORCE_VERIFY_QUERY');
export const setSalesforceStreamQuery = mac('SET_SALESFORCE_STREAM_QUERY');
export const setSalesforceObjectData = mac('SET__SALESFORCE_OBJECT_DATA');

//* ** Lookup
export const fetchLookupTables = macAsync('FETCH_LOOKUP_TABLES');
export const createLookupTable = macAsync('CREATE_LOOKUP_TABLE');
export const deleteLookupTable = macAsync('DELETE_LOOKUP_TABLE');
export const updateLookupTable = macAsync('UPDATE_LOOKUP_TABLE');
export const setDeleteLookupError = mac('SET_DELETE_LOOKUP_ERROR');
export const setDownloadLookupError = mac('SET_DOWNLOAD_LOOKUP_ERROR');
export const fetchLookupTablePreview = macAsync('FETCH_LOOKUP_TABLE_PREVIEW');

//* ** viewStreamSummaryModal
export const getViewStreamSummaryDimetricsTimeDefinition = mac('GET_VIEW_STREAM_SUMMARY_DIMETRICS_TIME_DEFINITION');
export const setViewStreamSummaryDimetricsTimeDefinition = mac('SET_VIEW_STREAM_SUMMARY_DIMETRICS_TIME_DEFINITION');
export const setViewStreamSummaryAssets = mac('SET_VIEW_STREAM_SUMMARY_ASSETS');

//* **gads acounts
export const fetchGoogleAdsAccounts = macAsync('FETCH_GOOGLE_ADS_ACCOUNTS');
export const fetchGoogleAdsTemplates = macAsync('FETCH_GOOGLE_ADS_TEMPLATES');
export const setSelectedGoogleAdsTemplate = mac('SET_SELECTED_GOOGLE_ADS_TEMPLATE');

//* **create alerts and dashboards
export const fetchDashAndAlertTitles = macAsync('FETCH_DASH_AND_ALERT_TITLES');

//* **parquet
export const fetchParquetAnalysis = macAsync('FETCH_PARQUET_ANALYSIS');
export const removeParquetStreamDiametrics = mac('REMOVE_PARQUET_STREAM_DIAMETRICS');
export const setParquetDiametricsStreamAnalysisSchema = mac('SET_PARQUET_DIAMETRICS_STREAM_ANALYSIS_SCHEMA');
export const setParquetStreamClearAllDiametrics = mac('SET_PARQUET_STREAM_CLEAR_ALL_DIAMETRICS');
export const setParquetStreamDiametricsTimeDefinition = mac('SET_PARQUET_STREAM_DIAMETRICS_TIME_DEFINITION');
export const setParquetStreamDiametricsChange = mac('SET_PARQUET_STREAM_DIAMETRICS_CHANGE');

//* **facebook ads
export const fetchFacebookAdsAccounts = macAsync('FETCH_FACEBOOK_ADS_ACCOUNTS');
export const fetchFacebookAdsReports = macAsync('FETCH_FACEBOOK_ADS_REPORTS');
export const fetchFacebookAdsTemplates = macAsync('FETCH_FACEBOOK_ADS_TEMPLATES');
export const setSelectedFacebookAdsTemplate = mac('SET_SELECTED_FACEBOOK_ADS_TEMPLATE');

//* ** coralogix
export const fetchCoralogixMetrics = macAsync('FETCH_CORALOGIX_METRICS');
export const fetchCoralogixLabels = macAsync('FETCH_CORALOGIX_LABELS');
export const fetchCoralogixMeasures = macAsync('FETCH_CORALOGIX_MEASURES');
export const setCoralogixLabels = mac('SET_CORALOGIX_LABELS');
export const setCoralogixMeasures = mac('SET_CORALOGIX_MEASURES');
export const applyCoralogixStreamFilters = macAsync('APPLAY_CORALOGIX_STREAM_FILTERS');
export const setCoralogixDiametricsChange = mac('SET_CORALOGIX_DIAMETRICS_CHANGE');
export const setCoralogixDiametricsClearAll = mac('SET_CORALOGIX_DIAMETRICS_CLEAR_ALL');
export const removeCoralogixDiametrics = mac('REMOVE_CORALOGIX_DIAMETRICS');

//* ** athena sql
export const fetchAthenaWorkgroupsList = macAsync('FETCH_ATHENA_WORKGROUPS_LIST');
export const resetAthenaWorkgroupList = mac('RESET_ATHENA_WORKGROUPS_LIST');
export const fetchAthenaWorkgroupDescription = macAsync('FETCH_ATHENA_WORKGROUP_DESCRIPTION');
export const resetAthenaWorkgroupDescription = mac('RESET_ATHENA_WORKGROUPS_DESCRIPTION');
export const fetchAthenaDatabasesList = macAsync('FETCH_ATHENA_DATABASES_LIST');
export const resetAthenaDatabasesList = mac('RESET_ATHENA_DATABASES_LIST');

//* ** aws timestream
export const fetchTimestreamDataSourceListDatabases = macAsync('FETCH_TIMESTREAM_DATABASES_LIST');
export const resetTimestreamDataSourceListDatabases = mac('RESET_TIMESTREAM_DATABASES_LIST');

export const fetchStreamsStats = macAsync('FETCH_STREAM_STATS');
