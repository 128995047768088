// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {getTypeDetails} from 'bc/services/bcTypes';
import {getSourceIcon, getStreamCountText} from 'bc/services/dataManagerService';
import Button, {COLORS, HEIGHTS as BUTTON_HEIGHTS, WIDTHS as BUTTON_WIDTHS} from 'common/componentsV2/Button';
import ConfirmationModal from 'common/components/modals/ConfirmationModal';
import * as selectors from 'bc/store/selectors';
import * as commonSelectors from 'profile/store/selectors';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import {createDataStream, deleteDataSource, setSelectedDataSource} from 'bc/store/actions';
import {streamHasMetrics} from 'bc/services/dataStreamService';
import DeleteMetricsConfirmation from 'bc/components/dataManager/DeleteMetricsConfirmation';
import './DataManagerSourceItem.module.scss';
import {ACTIVITY_VALUE, PARAMS_NAME} from 'admin.activityLog/services/activityLogService';
import {rangeTypes} from 'common/utils/dateRangeService';

type PropTypes = {
  source: Object,
  isOpen: Boolean,
  isBcReadOnly: Boolean,
  streamsCount: Number,
  streams: Array,
  openModal: Function,
  onClickFunction: Function,
  createDataStream: Function,
  openSourceViewModal: Function,

  // connect
  incompleteSteamsLimit: Number,
  newStreamsCount: Number,
  deleteDataSource: Function,
  setSelectedDataSource: Function,
  isActivityLogEnabled: Boolean,
  isCustomerAdmin: Boolean,
  isOnPremNewStreamEnabled: Boolean,
  userProfile: Object,
};

const renderOptions = (isActivityLogEnabled) => {
  const res = [];
  res.push({label: 'Delete', value: 'delete'});
  if (isActivityLogEnabled) {
    res.push({
      value: 'activity',
      label: 'View Activity Log',
    });
  }
  return res;
};

@connect(
  (state) => ({
    incompleteSteamsLimit: commonSelectors.getIncompleteSteamsLimit(state),
    newStreamsCount: selectors.getNewStreamsCount(state),
    isActivityLogEnabled: commonSelectors.getActivityLogEnabled(state),
    isCustomerAdmin: commonSelectors.isCustomerAdmin(state),
    userProfile: commonSelectors.getUserProfile(state),
    isOnPremNewStreamEnabled: commonSelectors.getIsOnPremNewStreamEnabled(state),
  }),
  {
    createDataStream,
    deleteDataSource,
    setSelectedDataSource,
  },
)
export default class DataManagerSourceItem extends PureComponent {
  props: PropTypes;

  state = {
    isSourceConfirmationModalOpen: false,
    shouldDeleteMetrics: false,
  };

  stopPropagation = (e) => e.stopPropagation();

  onNewStreamClicked = () => {
    const {source, isBcReadOnly, newStreamsCount, incompleteSteamsLimit, openModal} = this.props;

    if (!isBcReadOnly) {
      if (newStreamsCount >= incompleteSteamsLimit) {
        openModal();
      } else {
        this.props.createDataStream({
          type: source.type,
          dataSourceId: source.id,
        });
      }
    }
  };

  openSourceConfirmationModal = () => {
    this.props.setSelectedDataSource(this.props.source.id);
    this.setState({isSourceConfirmationModalOpen: true});
  };

  closeSourceConfirmationModal = () => {
    this.setState({isSourceConfirmationModalOpen: false});
  };

  onConfirmDeleteSourceModal = () => {
    this.props.deleteDataSource({
      id: this.props.source.id,
      type: this.props.source.type,
      shouldDeleteMetrics: this.state.shouldDeleteMetrics,
    });
    this.setState({isSourceConfirmationModalOpen: false, shouldDeleteMetrics: false});
  };

  onCheckBoxChange = (event) => {
    const {target} = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const {name} = target;

    this.setState({
      [name]: value,
    });
  };

  actionItemSelected = (select) => {
    switch (select.value) {
      case 'delete':
        this.openSourceConfirmationModal();
        break;
      case 'activity':
        window.open(
          `/#!/r/admin/activity-log?${[PARAMS_NAME.ACTIVITY]}=${ACTIVITY_VALUE.DATA_SOURCE}&${[PARAMS_NAME.SOURCE]}=${
            this.props.source.id
          }&${PARAMS_NAME.CONST_RANGE}=${rangeTypes.m3.value}`,
        );
        break;
      default:
    }
  };

  openSourceViewModalClick = () => {
    this.props.openSourceViewModal(this.props.source);
  };

  render() {
    const {
      source,
      onClickFunction,
      isOpen,
      streamsCount,
      isBcReadOnly,
      streams,
      isOnPremNewStreamEnabled,
      userProfile,
    } = this.props;

    const deleteWithStreamsModalMessage = (
      <span>Deleting this data source will delete all its linked data streams and any metrics created from them.</span>
    );
    const deleteWithoutStreamsModalMessage = <span>Are you sure you want to delete this source?</span>;

    const sourceStreams = streams.filter((i) => i.dataSourceId === source.id);
    const sourceStreamsWithMetrics = sourceStreams.filter((i) => streamHasMetrics(i.state));

    // new stream button enabled check
    let isAuthorizedNewStream = !source.family || source.family !== 'agents';
    if (isAuthorizedNewStream) {
      const authorizedRoles = ['anodot-admin', 'anodot-user', 'reseller-admin', 'reseller-user', 'customer-admin'];
      isAuthorizedNewStream = userProfile.roles.some((r) => authorizedRoles.includes(r));
      if (isAuthorizedNewStream && window.isOnPremise) {
        // also check feature flag for OnPremise users
        isAuthorizedNewStream = isOnPremNewStreamEnabled;
      }
    }

    return (
      <div styleName="itemContainer" key={source.id}>
        <span
          styleName="titleWrapper"
          automation-id={`Source_title_${isOpen ? 'expended' : 'collapsed'}`}
          onClick={() => onClickFunction(source.id)}
        >
          <span styleName="arrow">
            <span className={`icon ${isOpen ? 'icn-arrow16-chevrondown' : 'icn-arrow16-chevronright'}`} />
          </span>
          <span styleName="logo">{getSourceIcon(source.type)}</span>
          <span styleName="name">{source.name}</span>
          <span styleName="type">
            {isOpen ? getTypeDetails(source.type).name : getStreamCountText(streamsCount)}
            {['coralogix'].includes(source.type) && source.family === 'agent' ? ' Agent' : ''}
            {(!source.family || source.family !== 'agents') && !isBcReadOnly && (
              <span styleName="actions-wrap" onClick={(e) => this.stopPropagation(e)}>
                <span
                  styleName="action-button"
                  onClick={this.openSourceViewModalClick}
                  automation-id={`edit_source_${source.name}`}
                >
                  <i styleName="btn-icon" className="icon icn-action16-edit" />
                </span>
                <span styleName="action-select" automation-id={`3_dot_source_extension_${source.name}`}>
                  <FormDdlSelect
                    placement="auto"
                    options={renderOptions(this.props.isActivityLogEnabled && this.props.isCustomerAdmin)}
                    button={<i styleName="btn-icon" className="icon icn-general16-3dot" />}
                    optionComponent={<OptionComponentSimple />}
                    onChange={(item) => this.actionItemSelected(item)}
                    width={125}
                  />
                </span>
              </span>
            )}
          </span>
        </span>
        <span styleName="button">
          {isAuthorizedNewStream && (
            <Button
              colorSchema={COLORS.BLUE_500}
              height={BUTTON_HEIGHTS.REGULAR}
              width={BUTTON_WIDTHS.REGULAR}
              icon="icn-action16-plusa"
              text="New Stream"
              onClick={this.onNewStreamClicked}
              automation-id="dataManagerNewSourceButton"
            />
          )}
        </span>

        {this.state.isSourceConfirmationModalOpen && (
          <ConfirmationModal
            onClose={this.closeSourceConfirmationModal}
            onConfirm={this.onConfirmDeleteSourceModal}
            title="Delete Data Source?"
            message={sourceStreams.length ? deleteWithStreamsModalMessage : deleteWithoutStreamsModalMessage}
            isOpen={this.state.isSourceConfirmationModalOpen}
            buttons={['No, Keep It', 'Yes, Delete It']}
          >
            {sourceStreamsWithMetrics.length > 0 && (
              <DeleteMetricsConfirmation
                ShouldDeleteMetrics={this.state.shouldDeleteMetrics}
                onShouldDeleteMetricsChange={this.onCheckBoxChange}
              />
            )}
          </ConfirmationModal>
        )}
      </div>
    );
  }
}
