// @flow
import React, {useCallback, useEffect, useState} from 'react';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

import './SchedulerEditorModal.module.scss';

type PropTypes = {
  fillMissingHours: String,
  onChange: Function,
};

const options = [{text: 'Hours', value: 'h'}, {text: 'Days', value: 'd'}];

const getValue = (fillMissingHours) => {
  if (parseInt(fillMissingHours, 10) % 24 === 0) {
    return parseInt(fillMissingHours, 10) / 24;
  }
  return parseInt(fillMissingHours, 10);
};

const getType = (fillMissingHours) => {
  if (parseInt(fillMissingHours, 10) % 24 === 0) {
    return 'd';
  }
  return 'h';
};

const MissingHours = ({fillMissingHours, onChange}: PropTypes) => {
  const [missingHoursValue, setMissingHoursValue] = useState(getValue(fillMissingHours));
  const [missingHoursType, setMissingHoursType] = useState(getType(fillMissingHours));

  useEffect(() => {
    let missingHours = parseInt(missingHoursValue, 10);
    if (missingHoursType === 'd') {
      missingHours *= 24;
    }
    onChange(missingHours);
  }, [missingHoursValue, missingHoursType]);

  const saveValue = useCallback(
    (val) => {
      setMissingHoursValue(val);
    },
    [onChange],
  );

  const saveType = useCallback(
    (type, val) => {
      setMissingHoursType(type);
      if (type === 'd' && parseInt(val, 10) > 21) {
        setMissingHoursValue(21);
      }
    },
    [onChange],
  );

  let selectedIndex = options.length > 1 ? options.findIndex((val) => val.value === missingHoursType) : 0;
  if (selectedIndex === -1) {
    selectedIndex = 0;
  }

  return (
    <div>
      <div styleName="titleWrapper">Fill missing data for:</div>

      <div styleName="slideWrapper">
        <div styleName="slideWrapper-text">
          <input
            type="number"
            min={0}
            max={missingHoursType === 'h' ? 504 : 21}
            onChange={(e) => saveValue(e.target.value)}
            styleName="textInput_3"
            automation-id="MissingHoursNumber"
            value={missingHoursValue}
          />
        </div>

        <div styleName="slideWrapper-slider">
          <SelectAndt
            id="schedulerEditorModalHistoricalDateRange"
            automationId="NotificationDelayType"
            options={options}
            getOptionLabel={(val) => val.text}
            getOptionValue={(val) => val.value}
            type={TYPE_NEW_NO_SEARCH}
            theme={THEME_NOT_HIGHLIGHTED}
            value={options[selectedIndex]}
            onChange={(val) => saveType(val.value, missingHoursValue)}
            placeholder="Date range"
            optionHeight={40}
            buttonWidth={180}
            isClearable={false}
          />
        </div>
      </div>
    </div>
  );
};

export default MissingHours;
