// @flow
import React, {Fragment, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Checkbox from 'common/componentsV2/Checkbox';
import {useField} from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import {TypographyBox} from 'common/componentsV2/boxTools';
import Input from 'common/componentsV2/Input';
import * as selectors from 'userSettings/store/selectors';
import * as actions from 'userSettings/store/actions';
import Button, {COLORS} from 'common/componentsV2/Button';
import CopyToClipboardButton from 'common/components/CopyToClipboardButton';

import '../../pages/userSettings.module.scss';

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'absolute',
    top: '50%',
    right: '-100px',
    transform: 'translateY(-50%)',
  },
  domain: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 25px 8px 8px',
    borderBottom: `1px solid ${theme.palette.gray[200]}`,
    '&:hover': {
      borderColor: `${theme.palette.blue[300]}`,
      backgroundColor: `${theme.palette.blue[100]}`,
    },
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  removeBtn: {
    cursor: 'pointer',
    '&:hover': {
      color: `${theme.palette.blue[500]}`,
    },
  },
  unverified: {
    color: `${theme.palette.rose[500]}`,
  },
  unverifiedTitle: {
    position: 'absolute',
    top: '50%',
    right: '-60px',
    transform: 'translateY(-50%)',
    color: `${theme.palette.rose[500]}`,
    fontSize: '10px',
  },
  verifyBtn: {
    display: 'inline-flex',
    color: `${theme.palette.blue[500]}`,
    marginTop: '8px',
    paddingLeft: '8px',
    cursor: 'pointer',
  },
}));

const Google0Auth = () => {
  const dispatch = useDispatch();
  const getDomains = useSelector((state) => selectors.getDomainsOauth(state));
  const [domains, setDomains] = useState(getDomains);
  const verificationId = useSelector((state) => selectors.getVerificationId(state));
  const organizationId = useSelector((state) => selectors.getOrganizationId(state));
  const {
    input: {onChange, checked},
  } = useField('autoProvisioning', {type: 'checkbox'});
  const {
    input: {onChange: handleDomain, value},
  } = useField('domainInput');
  const {
    input: {onChange: handleDomainsList},
  } = useField('domains');

  const verificationDomain = `anodot-domain-verification=${verificationId}`;
  const classes = useStyles();

  useEffect(() => {
    if (domains !== getDomains) {
      handleDomainsList(domains);
    }
  }, [domains, getDomains]);

  const addDomain = () => {
    setDomains((prevState) => [
      ...prevState,
      {
        address: value,
        verified: false,
      },
    ]);
    handleDomain(' ');
  };

  const removeDomain = (indexDomain) => {
    setDomains((prevState) => prevState.filter((domain, index) => index !== indexDomain));
  };

  const handleVerify = () => {
    dispatch(actions.verifyDomains(domains, {organizationId}));
  };

  const isDomainNeedVerify = domains.some((domain) => !domain.verified);

  return (
    <Fragment>
      <Box my={2.5}>
        <Checkbox isChecked={checked} onChange={onChange} text="Auto provision new users" />
      </Box>
      {checked && (
        <Grid container>
          <Grid item>
            <TypographyBox variant="body1" width={220}>
              Domains:
            </TypographyBox>
          </Grid>
          <Grid item>
            <Box width={400}>
              <Box mb={3.5}>
                <TypographyBox variant="body1">Domain Verification DNS TXT Record:</TypographyBox>
                <Box position="relative">
                  <Input type="text" value={verificationDomain} placeholder="Domain to add" fullSize isDisabled />
                  <Box styleName="copyBtn">
                    <CopyToClipboardButton
                      copyStr={verificationDomain}
                      tooltipMessage="Copied to clipboard"
                      shouldUseIcon
                    />
                  </Box>
                </Box>
              </Box>
              <Box mb={3.5}>
                <TypographyBox variant="body1">Add Domain:</TypographyBox>
                <Box position="relative" mb={1}>
                  <Input type="text" value={value} onChange={handleDomain} fullSize />
                  <Box className={classes.button}>
                    <Button text="Add" colorSchema={COLORS.BLUE_500} onClick={addDomain} icon="icn-action16-plusa" />
                  </Box>
                </Box>
                {domains.map((domain, index) => (
                  <Box
                    key={domain.address}
                    className={`${classes.domain} ${!domain.verified ? classes.unverified : ''}`}
                  >
                    <TypographyBox
                      variant="caption"
                      className={classes.ellipsis}
                      width="85%"
                      color={`${!domain.verified ? 'rose.500' : 'inherit'}`}
                    >
                      {domain.address}
                    </TypographyBox>
                    <i
                      className={`icon icn-general16-closea ${classes.removeBtn}`}
                      onClick={() => removeDomain(index)}
                    />
                    {!domain.verified && <div className={classes.unverifiedTitle}>Unverified</div>}
                  </Box>
                ))}
                {isDomainNeedVerify && (
                  <Box onClick={handleVerify} className={classes.verifyBtn}>
                    Verify
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default Google0Auth;
