// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {
  fetchGoogleStorageProjects as fetchGoogleStorageProjectsAction,
  setGoogleStorageStreamProjectId as setGoogleStorageStreamProjectIdAction,
} from 'bc/store/actions';
import {get} from 'lodash';
import './GoogleStorageProject.module.scss';
import SelectAndt, {THEME_LIGHT, TYPE_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  // connect
  dataStream: Object,
  isLoadingProjects: boolean,
  projects: Object,

  fetchGoogleStorageProjects: Function,
  setGoogleStorageStreamProjectId: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isLoadingProjects: selectors.getGoogleStorageStreamProjectsIsLoading(state),
    projects: selectors.getGoogleStorageStreamProjectsItems(state),
  }),
  {
    fetchGoogleStorageProjects: fetchGoogleStorageProjectsAction,
    setGoogleStorageStreamProjectId: setGoogleStorageStreamProjectIdAction,
  },
)
export default class GoogleStorageProject extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {fetchGoogleStorageProjects, dataStream: stream} = this.props;
    fetchGoogleStorageProjects({dataSourceId: stream.dataSourceId});
  }

  projectChanged = (item) => {
    const {setGoogleStorageStreamProjectId} = this.props;
    setGoogleStorageStreamProjectId({projectId: item.projectId});
  };

  render() {
    const {dataStream, isLoadingProjects, projects} = this.props;
    const projectId = get(dataStream.uiState, 'projectId', dataStream.projectId);

    const selectedIndex = projects.findIndex((val) => projectId === val.projectId);
    return (
      <div className="shell-col" styleName="root">
        <SelectAndt
          id="bigQueryProjectsDdl"
          disabled={isLoadingProjects}
          options={projects}
          getOptionLabel={(val) => val.name}
          getOptionValue={(val) => val.projectId}
          optionHeight={40}
          type={TYPE_SEARCH}
          theme={THEME_LIGHT}
          value={isLoadingProjects ? null : projects[selectedIndex]}
          onChange={this.projectChanged}
          placeholder={isLoadingProjects ? 'Loading...' : 'Choose a Project'}
          automationId="googleStorageProject"
        />
      </div>
    );
  }
}
