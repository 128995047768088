// @flow
import React, {useMemo, useRef} from 'react';
import {get, isEqual} from 'lodash';
import {Backdrop, Modal} from '@material-ui/core';
import {Form, useField} from 'react-final-form';
import StatTileSettings, {STAT_TILES_VALUES} from 'dashboards/components/modals/statTile/StatTileSettings';
import StatContainer from 'dashboards/components/modals/statTile/StatContainer';
import useAsyncAction from 'common/utils/useAsyncAction';
import {updateDashboard} from 'dashboards/store/actions';
import {findFreeSpace, makeStatTilePayload, makeUpdateTilePayload} from 'dashboards/utils';
import {emptyExpressionTree} from 'metrics/store/reducers/expressionBuilderReducer';
import ModalContainer from 'dashboards/components/modals/ModalContainer';
import {OPTION_TILES_VALUES, OPTIONS_UNIT_TYPE} from 'dashboards/services/dashboardService';
import useDashboardState from 'dashboards/hooks/useDashboardState';
import useAlertUpdateConfirmation from 'dashboards/hooks/useAlertUpdateConfirmation';
import {getUniqueId} from 'common/utils/guid';
import {makeTileDataCompatible} from 'dashboards/compatibility';
import {COLOR_NAME} from 'common/componentsV2/ColorPicker';
import {isCompositeExpression} from 'metrics/services/metricsService';

type PropTypes = {onClose: Function, data: Object, layout: Object, dashboardId: string, type: String, tileType: Object};

const EMPTY_ARRAY = [];

const emptySimpleExpressionTree = {
  id: getUniqueId(),
  expressionTree: emptyExpressionTree,
};

const AddStatModalContainer = ({onClose, data: rawData, layout, dashboardId, type, tileType}: PropTypes) => {
  const data = useMemo(() => makeTileDataCompatible(rawData), [rawData]);
  const dashboardStateRef = useRef();
  const dashboard = useDashboardState(dashboardId);
  dashboardStateRef.current = dashboard;
  const isNew = type === OPTION_TILES_VALUES.NEW;
  const isGaugeTile = tileType === STAT_TILES_VALUES.GAUGE;

  const updateDashboardAsync = useAsyncAction(updateDashboard, dashboard);

  const {
    input: {value: dateRangeValue},
  } = useField('dateRange', {isEqual});

  const {
    input: {value: timeScaleValue},
  } = useField('timeScale');

  const {askForConfirmation, confirmationModalElement} = useAlertUpdateConfirmation({
    tileData: data,
    alerts: dashboard.data.tileAlerts,
    dashboard,
  });

  const handleAsyncSubmit = (formValues) =>
    askForConfirmation(formValues).then((isConfirmed) => {
      if (isConfirmed) {
        const tile = makeStatTilePayload(
          data,
          {
            ...formValues,
            expressionTrees:
              formValues.expressionTreeType === 'simple'
                ? [formValues.simpleExpressionTree]
                : formValues.advancedExpressionTree,
          },
          findFreeSpace(layout, {x: data.sizeX, y: data.sizeY}),
        );
        return (
          updateDashboardAsync(makeUpdateTilePayload(dashboardStateRef.current.data, tile, data.id, layout))
            .then(() => {
              onClose();
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.log(error))
        );
      }
      return null;
    });

  const compositeObjectData = useMemo(() => Object.values(data.andtGauge.expressionTrees)[0], [data]);
  const showComposites = useMemo(
    () => (isCompositeExpression(compositeObjectData.expressionTree) ? false : !compositeObjectData.excludeComposites),
    [compositeObjectData],
  );

  const advancedExpressionTree = useMemo(
    () =>
      Object.entries(data.andtGauge.expressionTrees).map(([id, compositeObject]) => ({
        id,
        expressionTree: compositeObject.expressionTree,
      })),
    [data],
  );

  const simpleExpressionTree =
    data.expressionTreeType === 'simple' ? advancedExpressionTree[0] : emptySimpleExpressionTree;

  const {mainValue} = get(data, 'andtGauge.displayProperties.byTreeExp[0].options.andtGauge', {});
  const unit = useMemo(
    () => OPTIONS_UNIT_TYPE.find((item) => item.value === mainValue.unitText) || OPTIONS_UNIT_TYPE[0],
    [mainValue.unitText],
  );

  const initialValues = {
    tileType: mainValue.tileType || (isGaugeTile ? STAT_TILES_VALUES.GAUGE : STAT_TILES_VALUES.NUMBER),
    scalarTransformType: get(compositeObjectData, 'scalarTransforms[0].function', 'current'),
    title: get(data, 'title.text', ''),
    unit,
    decimal: mainValue.displayDecimal,
    abbreviate: mainValue.abbreviate,
    showMeasure: mainValue.showMeasure,
    showGraph: mainValue.showGraph,
    inputNoData: mainValue.inputNoData || 'No Data',
    simpleExpressionTree: data.expressionTreeType === 'simple' ? simpleExpressionTree : emptySimpleExpressionTree,
    advancedExpressionTree: data.expressionTreeType === 'advanced' ? advancedExpressionTree : EMPTY_ARRAY,
    expressionTreeType: data.expressionTreeType,

    dateRange: dateRangeValue,
    show: Object.values(data.andtGauge.expressionTrees)[0].filter.parameters[0].value,
    sort: Object.values(data.andtGauge.expressionTrees)[0].filter.function,
    timeScale: timeScaleValue,
    showComposites,
    isAutoShowComposites: isNew,

    minScale: mainValue.minScale || null,
    maxScale: mainValue.maxScale || null,
    isShowValueLabels: get(mainValue, 'isShowValueLabels', true),
    thresholds: mainValue.thresholds || [
      {
        value: '',
        color: COLOR_NAME.BLUE,
      },
    ],
  };

  return (
    <Modal disableEnforceFocus open BackdropComponent={Backdrop} onClose={onClose}>
      <div>
        <Form initialValues={initialValues} onSubmit={handleAsyncSubmit}>
          {({handleSubmit}) => (
            <React.Fragment>
              <ModalContainer
                isStatModal
                title={`${isNew ? 'Add' : 'Edit'} Tile`}
                settingContainer={StatTileSettings}
                previewContainer={StatContainer}
                onClose={onClose}
                onSubmit={handleSubmit}
                isLoading={dashboard.isLoading}
              />
              {confirmationModalElement}
            </React.Fragment>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default AddStatModalContainer;
