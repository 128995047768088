import React, {useCallback, useEffect, useMemo, useState} from 'react';
// import deepSearch from 'common/utils/deepSearch';
import {useDispatch, useSelector} from 'react-redux';
import {useField} from 'react-final-form';
import * as metricsSelectors from 'metrics/store/selectors';
import {getEnableNewMetricExplorer} from 'profile/store/selectors';
import {initExpressionTreeModel} from 'metrics/store/actions';
import {makeStyles} from '@material-ui/core';
import {getUniqueId} from 'common/utils/guid';
import FormDdl from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdl';
import SwitchButton from 'common/componentsV2/SwitchButton';
import {isCompositeExpression} from 'metrics/services/metricsService';

const useStyles = makeStyles(() => ({
  option: {
    display: 'flex',
    height: 40,
    alignItems: 'center',
    padding: 12,
    cursor: 'pointer',
  },
}));

const CompositeBuilderActions = ({
  isSimulationDisabled,
  onClear,
}: {
  isSimulationDisabled: boolean,
  onClear: Function,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const expressionTree = useSelector(metricsSelectors.getExpressionTree);
  const [isMoreOptionsPanelOpen, setMoreOptionsPanelOpen] = useState(false);
  const isComposite = useMemo(() => isCompositeExpression(expressionTree), [expressionTree]);
  // const compositeChip = useMemo(
  //   () => deepSearch(expressionTree, 'originType', (k, v) => v.toLowerCase() === 'composite'),
  //   [expressionTree],
  // );

  const isNewMetricExplorerEnabled = useSelector(getEnableNewMetricExplorer);
  const {
    input: {checked: showCompositesValue, onChange: onChangeShowComposites},
  } = useField('showComposites', {type: 'checkbox'});

  const {
    input: {checked: isAutoShowComposites, onChange: setIsAutoShowComposites},
  } = useField('isAutoShowComposites', {type: 'checkbox'});

  useEffect(() => {
    if (isComposite) {
      onChangeShowComposites(false);
    } else if (isAutoShowComposites) {
      onChangeShowComposites(true);
    }
  }, [isComposite]);

  const toggleActionMenuPanel = (isOpen) => {
    setMoreOptionsPanelOpen(isOpen);
  };

  const handleShowInMetricExplorer = useCallback(() => {
    window.sessionStorage.setItem('metricExplorerTree', JSON.stringify(expressionTree));
    window.open('/#!/r/metrics-explorer');
  }, [expressionTree]);

  const handleDelete = () => {
    setIsAutoShowComposites(true);
    onChangeShowComposites(true);
    if (onClear) {
      onClear();
    } else {
      dispatch(
        initExpressionTreeModel({
          expressionTree: {
            root: {
              searchObject: {
                expression: [],
              },
              children: [],
              type: 'metric',
              id: getUniqueId(),
              uiIndex: 0,
            },
          },
        }),
      );
    }
  };

  const switchHandler = (value) => {
    onChangeShowComposites(value);
    if (setIsAutoShowComposites) {
      setIsAutoShowComposites(false);
    }
  };

  const renderMoreOptionsPanel = useMemo(
    () => (
      <div>
        <div className={classes.option}>
          <div className="mr_0-5">Show Composites</div>
          <SwitchButton
            isChecked={showCompositesValue}
            onChange={switchHandler}
            disabled={isComposite}
            automationId="showComposites"
          />
        </div>
        <div className={classes.option} onClick={handleDelete} aria-disabled={isSimulationDisabled}>
          <i className="icon icn-action16-delete mr_0-5" />
          <div>Clear Expression Tree</div>
        </div>
        {isNewMetricExplorerEnabled && (
          <div className={classes.option} onClick={handleShowInMetricExplorer} aria-disabled={isSimulationDisabled}>
            <i className="icon icn-nav16-goto mr_0-5" />
            <div>Show In Metric Explorer</div>
          </div>
        )}
      </div>
    ),
    [
      handleShowInMetricExplorer,
      isSimulationDisabled,
      isNewMetricExplorerEnabled,
      showCompositesValue,
      onChangeShowComposites,
      isComposite,
    ],
  );

  return (
    <FormDdl
      popoverComponent={renderMoreOptionsPanel}
      buttonComponent={<div className="icon icn-general16-3dot" />}
      onToggle={(isOpen) => toggleActionMenuPanel(isOpen)}
      width={200}
      isOpen={isMoreOptionsPanelOpen}
      placement="top"
      automationId="alertMoreOptions"
    />
  );
};

export default CompositeBuilderActions;
