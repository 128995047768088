/* eslint-disable import/prefer-default-export */
/* eslint-disable prefer-template */

export const EVENTS_PLOT_LINE_ID = 'simulation-user-events';
export const EVENT_PLOT_LINE_COLOR = {
  normal: 'transparent',
  hover: '#11d8f0',
};

export const getPlotLineOptions = (eventData, uniqueId, isSelected) => {
  const selectedClass = isSelected ? 'selected' : '';
  const label = {
    text:
      '<i style="position: absolute; top: -48px; left:-4px; font-size: 16px"' +
      `class="icon icn-general16-marker andt-user-events-icon ${selectedClass}"` +
      'data-plot-line-id="' +
      uniqueId +
      '"><span class="andt-user-events-icon-mask"/>' +
      '</i>',
    verticalAlign: 'bottom',
    textAlign: 'center',
    y: 50,
    x: -4,
    useHTML: true,
    rotation: 0,
  };

  return {
    id: EVENTS_PLOT_LINE_ID,
    className: `${EVENTS_PLOT_LINE_ID} ${uniqueId}`,
    color: EVENT_PLOT_LINE_COLOR.normal,
    width: 2,
    value: eventData.date * 1000,
    zIndex: 1, // get over the grid lines
    label,
  };
};
