import {makeAction, makeAsyncAction} from 'common/utils/simplifiedAsync';

const mac = (actionName) => makeAction(actionName, 'anodot/admin.users/');
const macAsync = (actionName) => makeAsyncAction(actionName, 'anodot/admin.users/');

export const impersonate = macAsync('IMPERSONATE');
export const unimpersonate = macAsync('UNIMPERSONATE');

export const createUsers = macAsync('CREATE_USERS');
export const fetchUsers = macAsync('FETCH_USERS');
export const fetchUsersEnforced = macAsync('FETCH_USERS_ENFORCED');
export const fetchUser = macAsync('FETCH_USER');
export const updateUser = macAsync('UPDATE_USER');
export const deleteUser = macAsync('DELETE_USER');
export const updateUserStatus = macAsync('UPDATE_USER_STATUS');
export const resetPasswordRequest = macAsync('RESET_PASSWORD_REQUEST');

export const fetchGroupsEditNewUser = macAsync('FETCH_GROUPS_EDIT_NEW_USER');
export const fetchGroups = macAsync('FETCH_GROUPS');
export const createGroup = macAsync('CREATE_GROUP');
export const updateGroup = macAsync('UPDATE_GROUP');
export const deleteGroup = macAsync('DELETE_GROUP');
export const addGroupsToUser = macAsync('ADD_GROUPS_TO_USER');
export const cleanFetchGroups = mac('CLEAN_FETCH_GROUPS');

export const setGroupSectionIsOpen = mac('SET_GROUP_SECTION_IS_OPEN');
export const getGroupAssetsCount = mac('GET_GROUP_ASSETS_COUNT');
export const toggleDeleteGroupModal = mac('TOGGLE_DELETE_GROUP_MODAL');
export const setDeleteGroupModalKeyVal = mac('SET_DELETE_GROUP_MODAL_KEY_VAL');

export const toggleGroupsModal = mac('TOGGLE_GROUPS_MODAL');
export const setGroupProps = mac('SET_GROUP_PROPS');
export const setUserListOrder = mac('SET_USER_LIST_ORDER');

export const toggleUserEditModal = mac('TOGGLE_USER_EDIT_MODAL');
export const setUserEditModalKeyVal = mac('SET_USER_EDIT_MODAL_KEY_VAL');

export const toggleNewUserModal = mac('TOGGLE_NEW_USER_MODAL');
export const addToUsersList = mac('ADD_TO_USERS_LIST');
export const removeFromUsersList = mac('REMOVE_FROM_USERS_LIST');
export const setNewUserModalOrg = mac('SET_NEW_USER_MODAL_ORG');
export const setNewUserModalKeyVal = mac('SET_NEW_USER_MODAL_KEY_VAL');

export const toggleUserChangeStatusConfirmationModal = mac('TOGGLE_USER_CHANGE_STATUS_CONFIRMATION_MODAL');

export const toggleDeleteUserModal = mac('TOGGLE_DELETE_USER_MODAL');
export const setDeleteUserModalKeyVal = mac('SET_DELETE_USER_MODAL_KEY_VAL');
export const getUserAssetsCount = mac('GET_USER_ASSETS_COUNT');
export const fetchOwnerDashboardsCount = macAsync('FETCH_OWNER_DASHBOARDS_COUNT');
export const fetchOwnerAnoboardsCount = macAsync('FETCH_OWNER_ANOBOARDS_COUNT');
export const fetchOwnerAlertsCount = macAsync('FETCH_OWNER_ALERTS_COUNT');
export const fetchOwnerChannelsCount = macAsync('FETCH_OWNER_CHANNELS_COUNT');
export const fetchOwnerCompositesCount = macAsync('FETCH_OWNER_COMPOSITES_COUNT');
export const fetchOwnerRefreshTokensCount = macAsync('FETCH_OWNER_REFRESH_TOKENS_COUNT');
export const fetchOwnerStreamsCount = macAsync('FETCH_OWNER_STREAMS_COUNT');

export const setUsersGroupsFilters = mac('SET_USERS_GROUPS_FILTERS');
export const setUsersGroupsFiltersKeyVal = mac('SET_USERS_GROUPS_FILTERS_KEY_VAL');
export const setUsersGroupsFiltersIsOpen = mac('SET_USERS_GROUPS_FILTERS_IS_OPEN');

export const singleUserCheckboxClick = mac('SINGLE_USER_CHECKBOX_CLICK');
export const setUsersCheckboxAll = mac('SET_USERS_CHECKBOX_ALL');
export const allUsersCheckboxClick = mac('ALL_USERS_CHECKBOX_CLICK');
export const editUsersBulk = mac('EDIT_USERS_BULK');
export const editUsersBulkApi = macAsync('EDIT_USERS_BULK_API');
export const deleteUsersBulkApi = macAsync('DELETE_USERS_BULK_API');
export const toggleBulkDeleteUsersModal = mac('TOGGLE_BULK_DELETE_USERS_MODAL');
export const setBulkDeleteUsersModalKeyVal = mac('SET_BULK_DELETE_USERS_MODAL_KEY_VAL');
