// @flow
import React, {PureComponent} from 'react';
import AnomalyAlertMetric from './AnomalyAlertMetric';
import '../alertContent.module.scss';

type PropTypes = {
  alert: Object,
  uiGroupName: string,
  getWrapperStyle: Function,
  isSingleLine: boolean,
  specialHeader: boolean,
};

class AnomalyAlert extends PureComponent {
  props: PropTypes;

  componentDidMount() {
    const alertContentHeight = this.anomalyAlertMetric.clientHeight;
    this.props.getWrapperStyle(alertContentHeight);
  }

  render() {
    const {alert, uiGroupName, isSingleLine, specialHeader} = this.props;

    return (
      <div
        styleName="alert-content"
        ref={(c) => {
          this.anomalyAlertMetric = c;
        }}
      >
        <div styleName="sep" />
        {alert.metrics.map((m) => (
          <AnomalyAlertMetric
            metric={m}
            anomalyId={alert.groupId}
            alertId={alert.id}
            uiGroupName={uiGroupName}
            startDate={m.displayStartTime}
            endDate={m.displayEndTime}
            timeScale={alert.timeScale}
            key={m.id}
            isSingleLine={isSingleLine}
            specialHeader={specialHeader}
          />
        ))}
      </div>
    );
  }
}

export default AnomalyAlert;
