// @flow
import React from 'react';
import ConfModal from 'common/componentsV2/modal/ConfModal';

type PropsType = {
  onClose: Function,
  onConfirm: Function,
  isLoading: boolean,
};

const SaveModal = ({onClose, onConfirm, isLoading}: PropsType) => (
  <ConfModal
    isOpen
    onClose={onClose}
    onConfirm={onConfirm}
    confirmText="Save Dashboard"
    title="You are about to override the default filters"
    isAnimation={false}
    isAsync
    isLoading={isLoading}
  >
    <div className="fontSize14 lineHeight_18 mb_6">
      {/* eslint-disable-next-line max-len */}
      If you change filters while in edit mode, you essentially making them the default filtering for this dashboard.
      Everyone who will enter this dashboard from now on will see these filters with their selected values by default.
      They can change them for themselves at any time.
    </div>
  </ConfModal>
);

export default SaveModal;
