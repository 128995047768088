// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {
  fetchBigQueryProjects as fetchBigQueryProjectsAction,
  setBigQueryStreamProjectId as setBigQueryStreamProjectIdAction,
} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import {isEditRunning, isStreamEditable} from 'bc/services/dataStreamService';
import SelectAndt, {THEME_LIGHT, TYPE_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  dataStream: Object,

  // connect
  isLoadingProjects: boolean,
  projects: Object,
  fetchBigQueryProjects: Function,
  setBigQueryStreamProjectId: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isLoadingProjects: selectors.getBigQueryStreamProjectsIsLoading(state),
    projects: selectors.getBigQueryStreamProjectsItems(state),
  }),
  {
    fetchBigQueryProjects: fetchBigQueryProjectsAction,
    setBigQueryStreamProjectId: setBigQueryStreamProjectIdAction,
  },
)
export default class Project extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {fetchBigQueryProjects, dataStream: stream} = this.props;
    fetchBigQueryProjects({dataSourceId: stream.dataSourceId});
  }

  projectChanged = (item) => {
    const {setBigQueryStreamProjectId} = this.props;
    setBigQueryStreamProjectId({projectId: item.projectId});
  };

  render() {
    const {dataStream, isLoadingProjects, projects} = this.props;
    const selectedIndex = projects.findIndex((val) => val.projectId === dataStream.projectId);
    const isEditable = isStreamEditable(dataStream.state) || isEditRunning(dataStream.id);

    return (
      <div className="shell-col">
        <PanelHeader title="Stream Context" isEditable={isEditable} />

        <SelectAndt
          optionHeight={40}
          className="andt-dropdown"
          options={projects}
          getOptionLabel={(val) => val.name}
          getOptionValue={(val) => val.projectId}
          type={TYPE_SEARCH}
          theme={THEME_LIGHT}
          value={isLoadingProjects ? null : projects[selectedIndex]}
          onChange={this.projectChanged}
          placeholder={isLoadingProjects ? 'Loading...' : 'Choose a Project'}
          automationId="bigQueryProject"
        />
      </div>
    );
  }
}
