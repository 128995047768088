import {combineEpics} from 'redux-observable';
import {error} from 'common/utils/notifications/notificationsService';
import * as actions from '../actions';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';

const debouncedSearch = (action$) =>
  action$
    .ofType(actions.setDataManagerFilterTextInput.TYPE)
    .debounceTime(200)
    .distinctUntilChanged((x, y) => x.payload === y.payload)
    .map((x) => actions.setDataManagerFilterText(x.payload));

const setUploadFileError = (action$) =>
  action$.ofType(actions.setUploadFileError.TYPE).flatMap((/* action */) => [error('File upload failed')]);

const dataManagerEpic = combineEpics(debouncedSearch, setUploadFileError);

export default dataManagerEpic;
