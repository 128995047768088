import React, {PureComponent} from 'react';
import {Observable} from 'rxjs/Observable';
import {FixedSizeList as List} from 'react-window';
import {ReactComponent as EmptyIcon} from 'app/images/empty-dropdown.svg';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import {getFocusOptionSearchable} from 'common/componentsV2/ddl/selectAndt/communication';
import DDLLoader from 'common/componentsV2/DDLLoader';

type PropTypes = {
  // accepted values by the original Select
  children: any,
  selectProps: any,
  options: any,
  maxHeight: number,
  isLoading: boolean,
};

export default class MenuListVirtualize extends PureComponent {
  props: PropTypes;

  constructor(props) {
    super(props);
    this.documentEvent = Observable.fromEvent(document, 'keydown').subscribe((e) => {
      if (this.menuRefEle && (e.key === 'ArrowDown' || e.key === 'ArrowUp') && this.currentValue) {
        this.menuRefEle.children[0].scrollTop = this.props.selectProps.optionHeight * this.currentValue;
      }
    });

    this.focusOptionSearchableEvent = getFocusOptionSearchable().subscribe((res) => {
      this.currentValue = res;
    });
  }

  componentWillUnmount() {
    this.documentEvent.unsubscribe();
    this.focusOptionSearchableEvent.unsubscribe();
  }

  documentEvent = null;

  focusOptionSearchableEvent = null;

  menuRef = (e) => {
    this.menuRefEle = e;
  };

  renderFooter = () => {
    const {selectProps} = this.props;
    return selectProps.menuFooterComponent ? selectProps.menuFooterComponent : null;
  };

  render() {
    const {options, children, maxHeight, selectProps, isLoading} = this.props;

    if (isLoading) {
      return <DDLLoader />;
    }

    if (!Array.isArray(children) && !children.key && !selectProps.isSearchable) {
      return <div />;
    }
    if (selectProps.isSearchable && !Array.isArray(children) && !children.key) {
      return (
        <div className="display_flex flexDirection_column alignItems_center justifyContent_center flexGrow_1">
          <EmptyIcon width={101} height={71} />
          <TypographyBox variant="subtitle2" marginTop={2}>
            {selectProps.inputValue.length > 0 ? 'Nothing Found' : 'Nothing to Show'}
          </TypographyBox>
        </div>
      );
    }
    const correctMaxHeight = selectProps.maxHeight < maxHeight ? selectProps.maxHeight : maxHeight;
    const opsHeight = options.length * +selectProps.optionHeight + 5;
    let windowHeight = opsHeight > correctMaxHeight ? correctMaxHeight : opsHeight;
    if (selectProps.isInputInMenu) {
      const footerHeight = selectProps.menuFooterComponent ? 40 : 0;
      windowHeight = correctMaxHeight - footerHeight;
    }

    return (
      <div
        className={[
          this.props.selectProps.menuListClass || '',
          'andt-dropdown-menu-list-virtualize',
          'display_flex',
          'flexDirection_column',
        ].join(' ')}
        automation-id="dropdownList"
        ref={this.menuRef}
      >
        <List height={windowHeight} itemCount={children.length} itemSize={+this.props.selectProps.optionHeight}>
          {({index, style}) => (
            <div
              style={{
                ...style,
                borderBottom: this.props.options[index].isLastSelected ? '1px solid #D4D9E0' : undefined,
              }}
            >
              {children[index]}
            </div>
          )}
        </List>
        {this.renderFooter()}
      </div>
    );
  }
}
