// @flow
import React, {PureComponent} from 'react';
import connect from 'react-redux/es/connect/connect';
import Gauge from 'common/componentsV2/gauges/Gauge';

import {fetchDashboardsTotal as fetchDashboardsTotalAction} from 'dashboards/store/actions';
import * as selectors from 'dashboards/store/selectors';

type PropTypes = {
  selectedItem: Number,
  index: Number,
  description: String,
  // Connect
  value: Number,
  isLoading: Boolean,
  fetchDashboardsTotal: Function,
};

@connect(
  (state) => ({
    value: selectors.getFetchDashboardTotal(state),
    isLoading: selectors.getIsLoadingFetchDashboardTotal(state),
  }),
  {
    fetchDashboardsTotal: fetchDashboardsTotalAction,
  },
)
export default class DashboardsGauge extends PureComponent {
  props: PropTypes;

  fetchData = () => {
    const {value, fetchDashboardsTotal} = this.props;
    if (value === null) {
      fetchDashboardsTotal();
    }
  };

  render() {
    const {value, isLoading, description, selectedItem, index} = this.props;
    return (
      <Gauge
        description={description}
        value={value}
        isLoading={isLoading}
        index={index}
        selectedItem={selectedItem}
        fetchData={this.fetchData}
      />
    );
  }
}
