/* eslint-disable max-len */
import {delete_, get, post, put} from 'common/utils/http';

const root = 'api/v1/bc/';
const metricsRoot = 'api/v1/metrics/';
const metricsReport = 'api/v1/reports/metrics/';

//* fetchAlerts\Dashbaords\Anoboards\Composites who use the stream
export const fetchStreamDashboards = ({payload}) =>
  get(`api/v1/dashboards/total?schemaType=${payload.schemaType}&streamId=${payload.streamId}`);
export const fetchStreamAlerts = ({payload}) => get(`api/v1/alerts/total?streamId=${payload}`);
export const fetchStreamComposites = ({payload}) => get(`api/v1/metrics/composite/total?streamId=${payload}`);

//* **configs
export const fetchCubes = () => get('/ga/dimetrics/cubes.json');
export const fetchMetadata = () => get('/ga/dimetrics/metadata.json');

//* **data source
export const fetchDataSourceTypes = () => get(`${root}data-sources/types`);
export const fetchDataSourceIntegrationTypes = () => get(`${root}data-sources/integration-types`);
export const fetchDataSources = () => get(`${root}data-sources/`);
export const createDataSource = ({payload}) => post(`${root}data-sources?type=${payload.type}`, payload);
export const testDataSource = ({payload}) =>
  post(`${root}data-sources/${payload.type}/test`, {
    dbHost: payload.dbHost,
    dbPort: payload.dbPort,
    dbName: payload.dbName,
    userName: payload.userName,
    userPassword: payload.userPassword,

    account: payload.account,
    useSSL: payload.useSSL,
    region: payload.region,
    role: payload.role,
    warehouse: payload.warehouse,
  });
export const updateDataSource = ({payload}) => put(`${root}data-sources/${payload.id}?type=${payload.type}`, payload);
export const deleteDataSource = ({payload}) =>
  delete_(`${root}data-sources/${payload.id}?type=${payload.type}&deleteMetrics=${payload.shouldDeleteMetrics}`);

//* **data stream
export const fetchDataStreams = () => get(`${root}data-streams/`);
export const createDataStream = ({payload}) => post(`${root}data-streams?type=${payload.type}`, payload);
export const updateDataStream = ({payload}) => put(`${root}data-streams/${payload.id}?type=${payload.type}`, payload);

export const updateDataStreamScheduling = ({payload}) =>
  put(`${root}data-streams/${payload.id}/update-schedule`, payload);
export const startDataStream = ({payload}) =>
  put(`${root}data-streams/${payload.id}/state?action=${payload.action}`, payload);
export const startLiveDataStream = ({payload}) =>
  put(`${root}data-streams/${payload.id}?action=${payload.action}&shouldRewind=${payload.shouldRewind}`, payload);
export const deleteDataStream = ({payload}) =>
  delete_(`${root}data-streams/${payload.id}?type=${payload.type}&deleteMetrics=${payload.shouldDeleteMetrics}`);
export const deletePipelineStream = ({payload}) =>
  delete_(`${root}agents?pipelineId=${payload.pipelineId}&deleteMetrics=${payload.shouldDeleteMetrics}`);
export const fetchDataStreamPreview = ({payload}) => get(`${root}data-streams/${payload.id}/preview`);
export const fetchDataStreamStats = ({payload}) => get(`${root}data-streams/${payload.id}/stats`);

export const fetchLiveDataStreamPreview = ({payload}) => post(`${root}data-streams/preview`, payload);
export const fetchLiveDataStreamStats = ({payload}) => post(`${root}data-streams/stats`, payload);

export const runAsyncDataStreamQuery = ({payload}) => get(`${root}data-streams/${payload.id}/async-query`);
export const createAlertFromStreamApi = ({payload}) => post(`${root}data-streams/${payload.id}/alerts`, payload);
export const createSelectedAlertsAndDashboard = ({payload}) =>
  post(`${root}data-streams/${payload.id}/bc_templates`, payload);
export const updateStreamName = ({payload}) =>
  put(`${root}data-streams/${payload.id}/rename?newName=${payload.newName}`);
export const updateAgentOwner = ({payload}) => put(`${root}agents/${payload.id}/set-owner?owner=${payload.owner}`);
export const duplicateStream = ({payload}) => post(`${root}data-streams/${payload.id}/duplicate`, payload);

export const fetchDataStreamCardinality = ({payload}) =>
  get(`${metricsRoot}cardinality?startTime=${payload.startTime}&endTime=${payload.endTime}&stream=${payload.stream}`);
export const fetchStreamLastRun = ({payload}) => get(`${root}data-streams/${payload.id}/last_run`);
export const fetchStreamHistoryLogs = ({payload}) => get(`${root}data-streams/${payload.id}/stream_log`);
export const getStreamHistoryLogs = (payload) => get(`${root}data-streams/${payload.id}/stream_log_csv`);

//* **ga
export const fetchGaAccounts = ({payload}) => get(`${root}data-sources/google-analytics/${payload}/accounts`);
export const fetchGaProperties = ({payload}) =>
  get(`${root}data-sources/google-analytics/${payload.dataSourceId}/accounts/${payload.accountId}/properties`);

export const fetchGaViews = (payload) =>
  get(
    `${root}data-sources/google-analytics/${payload.dataSourceId}/accounts/${payload.accountId}/properties/${payload.propertyId}/views`,
  );
export const fetchStreamTemplates = ({payload}) => get(`${root}data-streams/templates?type=${payload}`);
export const fetchGaSegments = ({payload}) =>
  get(`${root}data-sources/google-analytics/${payload.dataSourceId}/segments`);
export const fetchTransformFunctions = () => get(`${root}data-streams/transforms`);
export const createStreamTemplate = ({payload}) => post(`${root}data-streams/templates?type=${payload.type}`, payload);

//* **google auctions
export const fetchGoogleAuctionsEmail = () => get(`${root}data-sources/google-auctions/email`);
export const getGoogleAuctionsSignedRequest = (payload) =>
  get(`${root}data-sources/google-auctions/${payload.dataSourceId}/example-destination`, payload);
export const fetchGoogleAuctionsStreamAnalysis = ({payload}) =>
  post(`${root}data-sources/google-auctions/${payload.dataSourceId}/parsing/analyze`, {
    path: payload.path,
  });

//* ** file upload / aws
export const getS3SignedRequest = (payload) =>
  get(
    `${root}sign-s3?folder=${payload.folder}&file-name=${payload.name}&file-type=${payload.type}&bucket=${payload.bucket}&path=${payload.path}`,
  );
//* ** file delete / aws
export const deleteS3File = (payload) =>
  delete_(
    `${root}delete-s3?folder=${payload.folder}&file-name=${payload.file}&bucket=${payload.bucket}&path=${payload.path}`,
  );

//* ** dimReduce / Sparse Filter
export const fetchDimreduce = (payload) => get(`${root}data-streams/${payload.id}/dimred`);
export const fetchSparseFilter = (payload) => get(`${root}data-streams/${payload.id}/sparse_filter`);
export const createDimreduce = (payload) => post(`${root}data-streams/${payload.streamId}/dimred`, payload.dimred);
export const createSparseFilter = (payload) =>
  post(`${root}data-streams/${payload.streamId}/sparse_filter`, payload.sparse);

//* ** file download / aws
export const getS3File = (payload) =>
  get(`${root}get-s3?folder=${payload.folder}&file-name=${payload.file}&bucket=${payload.bucket}&path=${payload.path}`);
export const fetchFuCsvFormatAnalysis = ({payload}) =>
  post(`${root}data-sources/local-file/${payload.dataSourceId}/parsing/analyze`, {fileFormat: payload.fileFormat});
export const fetchFuPreview = ({payload}) =>
  post(`${root}data-sources/local-file/${payload.dataSourceId}/parsing/preview`, {fileFormat: payload.fileFormat});
export const fetchFuFileProperties = ({payload}) => get(`${root}data-sources/local-file/${payload}/properties`);

//* ** lookup
export const fetchLookupTables = () => get(`${root}lookup-table/`);
export const getLookupSignedRequest = () => get(`${root}lookup-table/uploads-prefix`);
export const createLookupTable = ({payload}) => post(`${root}lookup-table/`, payload);
export const updateLookupTable = ({payload}) => put(`${root}lookup-table/${payload}`);
export const deleteLookupTable = ({payload}) => delete_(`${root}lookup-table/${payload.id}`, payload);
export const fetchLookupTablePreview = ({payload}) => get(`${root}lookup-table/${payload.id}/preview`, payload);

//* ** s3
export const fetchS3CsvFormatAnalysis = ({payload}) =>
  post(`${root}data-sources/s3/${payload.dataSourceId}/parsing/analyze`, {
    path: payload.path,
    fileNameSuffix: payload.fileNameSuffix,
    fileNamePrefix: payload.fileNamePrefix,
    fileNamePattern: payload.fileNamePattern,
    fileFormat: payload.fileFormat,
  });

//* ** aws cur
export const fetchAwsCurReports = ({payload}) =>
  get(`${root}data-sources/cur/${payload.dataSourceId}/report-names?reportPrefix=${payload.reportPath}`);

export const fetchAwsCurManifest = ({payload}) =>
  get(`${root}data-sources/cur/${payload.dataSourceId}/manifest?path=${payload.path}`);

//* ** google cloud storage
export const fetchGoogleStorageCsvFormatAnalysis = ({payload}) =>
  post(`${root}data-sources/google-storage/${payload.dataSourceId}/parsing/analyze`, {
    projectId: payload.projectId,
    bucket: payload.bucket,
    path: payload.path,
    fileNameSuffix: payload.fileNameSuffix,
    fileNamePrefix: payload.fileNamePrefix,
    fileNamePattern: payload.fileNamePattern,
    fileFormat: payload.fileFormat,
  });
export const fetchGoogleStorageProjects = ({payload}) =>
  get(`${root}data-sources/google-storage/${payload.dataSourceId}/projects`);

export const fetchGoogleStorageBuckets = ({payload}) =>
  get(`${root}data-sources/google-storage/${payload.dataSourceId}/buckets?projectId=${payload.projectId}`);

export const fetchGoogleStoragePreview = ({payload}) =>
  post(`${root}data-sources/google-storage/${payload.dataSourceId}/parsing/preview`, {
    projectId: payload.projectId,
    bucket: payload.bucket,
    path: payload.path,
    fileNameSuffix: payload.fileNameSuffix,
    fileNamePrefix: payload.fileNamePrefix,
    fileNamePattern: payload.fileNamePattern,
    fileFormat: payload.fileFormat,
  });

export const fetchS3Preview = ({payload}) =>
  post(`${root}data-sources/s3/${payload.dataSourceId}/parsing/preview`, {
    path: payload.path,
    fileNameSuffix: payload.fileNameSuffix,
    fileNamePrefix: payload.fileNamePrefix,
    fileNamePattern: payload.fileNamePattern,
    fileFormat: payload.fileFormat,
    fileName: payload.fileName,
  });

//* ** sql
export const fetchMysqlDataSourceListDatabases = ({payload}) =>
  post(`${root}data-sources/${payload.type}/list_databases`, {
    dbHost: payload.dbHost,
    dbPort: payload.dbPort,
    userName: payload.userName,
    userPassword: payload.userPassword,
    useSSL: payload.useSSL,
    verifyServerCertificate: payload.verifyServerCertificate,
  });
export const fetchSqlSchemasList = ({payload}) =>
  get(`${root}data-sources/${payload.type}/${payload.dataSourceId}/schemas`);
export const fetchSqlTablesViewsList = ({payload}) =>
  get(`${root}data-sources/${payload.type}/${payload.dataSourceId}/tables?schemaName=${payload.schemaName}`);
export const fetchSqlTableData = ({payload}) =>
  get(
    `${root}data-sources/${payload.type}/${payload.dataSourceId}/metadata?tableName=${payload.tableName}&schemaName=${payload.schemaName}`,
  );
export const fetchSqlTablePreview = ({payload}) =>
  get(
    `${root}data-sources/${payload.type}/${payload.dataSourceId}/preview?tableName=${payload.tableName}&schemaName=${payload.schemaName}`,
  );
export const fetchSqlVerifyQuery = ({payload}) =>
  post(`${root}data-sources/${payload.type}/verify-query`, {query: payload.query});

//* ** adobe
export const fetchAdobeBookmarks = ({payload}) =>
  get(`${root}data-sources/${payload.type}/${payload.dataSourceId}/bookmarks?folderLimit=0&folderOffset=0`);
export const fetchAdobeReportDescription = ({payload}) =>
  get(`${root}data-sources/${payload.type}/${payload.dataSourceId}/reportDescription?bookmarkId=${payload.bookmarkId}`);

//* ** bigquery
export const fetchBigQueryVerifyQuery = ({payload}) =>
  post(`${root}data-sources/big-query/verify-query`, {query: payload.query});
export const fetchBigQueryProjects = ({payload}) =>
  get(`${root}data-sources/big-query/${payload.dataSourceId}/projects`);
export const fetchBigQueryDataSets = ({payload}) =>
  get(`${root}data-sources/big-query/${payload.dataSourceId}/datasets?projectId=${payload.projectId}`);
export const fetchBigQueryPreview = ({payload}) =>
  post(`${root}data-sources/big-query/${payload.dataSourceId}/preview`, {
    query: payload.query,
    projectId: payload.projectId,
    useLegacySQL: payload.useLegacySQL,
    defaultDataset: payload.defaultDataset,
    timeZone: payload.timeZone,
    pollingInterval: payload.pollingInterval,
  });

export const fetchSqlQueryPreview = ({payload}) =>
  post(`${root}data-sources/${payload.streamType}/${payload.dataSourceId}/preview_query`, {
    query: payload.query,
    timeZone: payload.timeZone,
    pollingInterval: payload.pollingInterval,
    historyRange: payload.historyRange,
  });

//* ** segment
export const fetchSegmentAnoToken = () => get(`${root}data-sources/segment/anotoken`);
export const fetchSegmentMessageTypes = () => get(`${root}data-sources/segment/message-types`);
export const fetchSegmentMessageMetadata = ({payload}) =>
  get(`${root}data-sources/segment/${payload.dataSourceId}/message-metadata?messageTypes=${payload.messageTypes}`);

//* ** mParticle
export const fetchMParticleAnoToken = () => get(`${root}data-sources/mparticle/anotoken`);
export const fetchMParticleEventTypes = () => get(`${root}data-sources/mparticle/event-types`);
export const fetchMParticleEventMetadata = ({payload}) =>
  get(`${root}data-sources/mparticle/${payload.dataSourceId}/event-metadata?eventType=${payload.eventType}`);

//* ** Kinesis
export const fetchKinesisPathsAndValues = ({payload}) =>
  get(`${root}data-sources/${payload.type}/${payload.dataSourceId}/all-paths?recordType=${payload.recordType}`);
export const fetchKinesisStreamAnalysis = ({payload}) =>
  post(`${root}data-sources/${payload.type}/${payload.dataSourceId}/analyze?recordType=${payload.recordType}`, {
    filters: payload.filters,
  });

//* ** Salesforce
export const fetchSalesforceObjects = ({payload}) =>
  get(`${root}data-sources/salesforce/${payload.dataSourceId}/objects`);
export const fetchSalesforceObjectData = ({payload}) =>
  get(`${root}data-sources/salesforce/${payload.dataSourceId}/object/${payload.obj}`);
export const fetchSalesforceQueryPreview = ({payload}) =>
  post(`${root}data-sources/salesforce/${payload.dataSourceId}/preview_query`, {
    query: payload.query,
    timeZone: payload.timeZone,
    pollingInterval: payload.pollingInterval,
  });
// there is no verify api so we reuse the preview
export const fetchSalesforceVerifyQuery = ({payload}) =>
  post(`${root}data-sources/salesforce/${payload.dataSourceId}/preview_query`, {
    query: payload.query,
    timeZone: payload.timeZone,
    pollingInterval: payload.pollingInterval,
  });

//* **gads
export const fetchGoogleAdsAccounts = ({payload}) => get(`${root}data-sources/google-ads/${payload}/accounts`);

//* **parquet
export const fetchParquetAnalysis = ({payload}) =>
  post(`${root}data-sources/s3-athena/${payload.dataSourceId}/schema`, {
    inputLocation: payload.inputLocation,
    partitionFolderFormat: payload.partitionFolderFormat,
    inputFormat: payload.inputFormat,
    compression: payload.compression,
  });

//* **facebook ads
export const fetchFacebookAdsAccounts = ({payload}) => get(`${root}data-sources/facebook-ads/${payload}/accounts`);
export const fetchFacebookAdsReports = ({payload}) =>
  get(`${root}data-sources/facebook-ads/${payload}/report-metadata`);
export const fetchFacebookAdsTemplates = () => get(`${root}data-streams/templates?type=FACEBOOK_ADS`);

//* **coralogix
export const fetchCoralogixMetrics = ({payload}) =>
  get(`${root}data-sources/${payload.type}/${payload.dataSourceId}/metrics`);
export const fetchCoralogixLabels = ({payload}) =>
  get(`${root}data-sources/${payload.type}/${payload.dataSourceId}/labels?metricName=${payload.metricName}`);
export const fetchCoralogixMeasures = ({payload}) =>
  get(`${root}data-sources/${payload.type}/${payload.dataSourceId}/measures?metricName=${payload.metricName}`);

//* ** sql
export const fetchAthenaWorkgroupsList = ({payload}) =>
  post(`${root}data-sources/athena_sql/list_workgroups`, {
    roleARN: payload.roleARN,
    region: payload.region,
    externalId: payload.externalId,
  });

export const fetchAthenaWorkgroupDescription = ({payload}) =>
  post(`${root}data-sources/athena_sql/describe_workgroup`, {
    roleARN: payload.roleARN,
    region: payload.region,
    externalId: payload.externalId,
    workgroup: payload.workgroup,
  });

export const fetchAthenaDatabasesList = ({payload}) =>
  post(`${root}data-sources/athena_sql/list_databases`, payload.obj);

export const fetchTimestreamDataSourceListDatabases = ({payload}) =>
  post(`${root}data-sources/${payload.type}/list_databases`, {
    roleARN: payload.roleARN,
    region: payload.region,
    externalId: payload.externalId,
  });

//* genarate dashbords and alerts
export const fetchDashAndAlertTitles = ({payload}) =>
  get(`${root}generate-dash-alert-titles?type=${payload.streamType}`);

export const fetchStreamsStats = ({payload}) => get(`${metricsReport}stats?userId=${payload._id}`);
