import React, {useRef, useEffect} from 'react';
import {get} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@material-ui/core';
import {selectTreeBranch, processTreeOutsideState} from 'metrics/store/actions';
import CompositeBuilder from 'metrics/components/compositeBuilder/CompositeBuilder';
import {getTreeOutsideState, getIsRootSelected, getExpressionTrees} from 'metrics/store/selectors';
import BaloonMessage from 'common/componentsV2/BaloonMessage';

function useClickOutsideHandler(callback, isFocusOutsideTree) {
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (document.querySelector('.menu-open') && document.querySelector('.menu-open').contains(event.target)) {
      callback(isFocusOutsideTree);
      return;
    }

    if (document.querySelector('.showMoreArea') && document.querySelector('.showMoreArea').contains(event.target)) {
      return;
    }

    if ([...document.querySelectorAll('.form-ddl-container-open')].some((node) => node.contains(event.target))) {
      callback(false);
      return;
    }
    if (ref.current) {
      const isChipClicked = event.target.classList.contains('value-chip');
      const isInputClicked = event.target.tagName === 'INPUT';
      const isInside = ref.current.contains(event.target);

      let parentNodeClassName = get(event, 'target.parentNode.className', '');
      let grandParentNodeClassName = get(event, 'target.parentNode.parentNode.className', '');

      if (!parentNodeClassName.startsWith) {
        parentNodeClassName = '';
      }

      if (!grandParentNodeClassName.startsWith) {
        grandParentNodeClassName = '';
      }

      const isRootFunctionClicked = parentNodeClassName.startsWith('FunctionForExpressionBuilder');
      const isFunctionParameterClicked = parentNodeClassName.startsWith('andt-dropdown-uno-multivalue');
      const isNestedFunctionClicked = parentNodeClassName.endsWith('andt-dropdown-value-container');
      const isArrowClicked = grandParentNodeClassName.endsWith('andt-dropdown-control');

      if (grandParentNodeClassName.includes('copy-container-wrapper')) {
        callback(false);
        return;
      }

      const isFunctionDropdownClicked =
        isInside && (isRootFunctionClicked || isFunctionParameterClicked || isNestedFunctionClicked || isArrowClicked);
      callback(!(isInside && (isChipClicked || isInputClicked || isFunctionDropdownClicked)));
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  });

  return ref;
}

const CompositeBuilderWrapper = ({
  isSimulationDisabled,
  emptyMetricsTooltipState,
}: {
  isSimulationDisabled: boolean,
  emptyMetricsTooltipState: Object,
}) => {
  const dispatch = useDispatch();
  const [isEmptyMetricsTooltipVisible, setEmptyMetricsTooltipVisibility] = emptyMetricsTooltipState;
  const expressionTrees = useSelector(getExpressionTrees);
  const expressionTreeId = expressionTrees[0].id;

  const isRootSelected = useSelector(getIsRootSelected);
  const isFocusOutsideTree = useSelector(getTreeOutsideState);
  const expressionBuilderRef = useClickOutsideHandler((isOutside) => {
    if (!isRootSelected && isOutside) {
      dispatch(
        selectTreeBranch({
          selectedExpressionId: expressionTreeId,
          branchId: get(expressionTrees[0], 'expressionTree.root.id'),
        }),
      );
    }
    if (isFocusOutsideTree !== isOutside) {
      dispatch(processTreeOutsideState(isOutside));
    }
  }, isFocusOutsideTree);
  return (
    <React.Fragment>
      <Box position="relative" ref={expressionBuilderRef}>
        <Box component="input" width={0} height={0} border={0} fontSize={0} position="absolute" name="expressionTree" />
        <CompositeBuilder
          enableFilteredFunctions
          expressionTrees={expressionTrees}
          expressionTreeId={expressionTreeId}
        />
        {isSimulationDisabled && isEmptyMetricsTooltipVisible && (
          <Box position="absolute" top="calc(100% + 10px)" right={0}>
            <BaloonMessage
              onClose={() => setEmptyMetricsTooltipVisibility(false)}
              title="You Need Some Metrics…"
              link="Create Alerts Like a Pro"
              url="https://support.anodot.com/hc/en-us/articles/360035653953-Creating-New-Alerts"
              position="bottom"
              width={253}
              height={145}
            >
              Before you can configure the alert conditions, you need to create a metric for it. Try clicking on the
              measures on the right side for a quick start.
            </BaloonMessage>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default CompositeBuilderWrapper;
