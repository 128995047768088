// @flow
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useQueryParams} from 'use-query-params';
import {intersection} from 'lodash';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {QUERY_PARAM_MAP} from 'topologyGeneral/services/sidePanelService';
import GeneralTopologyMap from 'topologyGeneral/components/GeneralTopologyMap';
import SidePanel from 'topologyGeneral/components/sidePanel/SidePanel';
import {fetchTopologyMapRegions} from 'topologyGeneral/store/actions';
import {
  getGtpMapRegions,
  getGtpMapSites,
  isLoadingGtpMapRegionsSitesList,
  getIsMapDataLoading,
  getGtpMapLinks,
  getGtpMapSelectedIssue,
} from 'topologyGeneral/store/selectors';
import './GeneralTopologyPage.module.scss';

const EMPTY_ARRAY = [];

const GeneralTopologyPage = () => {
  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAM_MAP);
  const mapRef = useRef();
  const dispatch = useDispatch();

  const allLinks = useSelector(getGtpMapLinks);
  const allSites = useSelector(getGtpMapSites);
  const selectedIssue = useSelector(getGtpMapSelectedIssue);
  const isSitesLoading = useSelector(isLoadingGtpMapRegionsSitesList);
  const isMapDataLoading = useSelector(getIsMapDataLoading);
  const [nodeHoveredId, setNodeHoveredId] = useState(undefined);

  const allRegions = useSelector(getGtpMapRegions);
  const [sites, setSites] = useState([]);
  const [regions, setRegions] = useState([]);
  const [links, setLinks] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({});

  useEffect(() => {
    dispatch(fetchTopologyMapRegions());
    mapRef.current = null;
  }, []);

  useEffect(() => {
    if (allRegions.length) {
      let procAllRegions = allRegions;
      if (selectedIssue && selectedIssue.id === queryParams.issueId) {
        procAllRegions = allRegions.map((reg) => {
          if (selectedIssue.entityMapping.REGION.includes(reg.serverId)) {
            return reg;
          }
          return {...reg, unSelected: true};
        });
      }

      const zeroLevelRegions = procAllRegions.filter((r) => r.level === 0);

      if (!queryParams.regionId) {
        setRegions(zeroLevelRegions);
      }

      const findRegion = procAllRegions.find((r) => r.id === queryParams.regionId);
      if (!findRegion) {
        setRegions(zeroLevelRegions);
        setSelectedRegion({});
        if (queryParams.regionId) {
          setQueryParams({regionId: undefined});
        }
      } else {
        setSelectedRegion(findRegion);
        setRegions(procAllRegions.filter((r) => r.parentRegionId === queryParams.regionId));
      }
    } else {
      setRegions([]);
    }
  }, [queryParams.regionId, allRegions, queryParams.issueId, selectedIssue]);

  useEffect(() => {
    if (allLinks.length) {
      let procAllLinks = allLinks;
      if (selectedIssue && selectedIssue.id === queryParams.issueId) {
        procAllLinks = allLinks.map((link) => {
          if (selectedIssue.entityMapping.REGION.includes(link.id)) {
            return link;
          }
          return {...link, unSelected: true};
        });
      }
      setLinks(procAllLinks);
    } else {
      setLinks([]);
    }
  }, [allLinks, queryParams.issueId, selectedIssue]);

  useEffect(() => {
    if (allSites.length) {
      const sitesArr = [];
      let selSite = null;
      allSites.forEach((it) => {
        if (queryParams.siteId && queryParams.siteId === it.id) {
          selSite = {...it};
          return;
        }
        if (queryParams.siteId && queryParams.siteId !== it.id) {
          sitesArr.push({...it, unSelected: true});
        } else {
          sitesArr.push({...it});
        }
      });
      sitesArr.sort((a, b) => b.percent - a.percent);
      if (selSite) {
        sitesArr.unshift(selSite);
      }

      let domainSelectedSiteArray = null;
      if (queryParams.domainId) {
        domainSelectedSiteArray = sitesArr
          .map((sItem) => {
            let isDomainSelected = false;
            const nodes = sItem.nodes.map((n) => {
              const locIsDomSelected = n.domain.indexOf(queryParams.domainId) !== -1;
              if (locIsDomSelected) {
                isDomainSelected = locIsDomSelected;
              }
              return {...n, isDomainSelected: locIsDomSelected};
            });
            const topWing = sItem.cells.topWing.map((c) => {
              const locIsDomSelected = c.domain.indexOf(queryParams.domainId) !== -1;
              if (locIsDomSelected) {
                isDomainSelected = locIsDomSelected;
              }
              return {...c, isDomainSelected: locIsDomSelected};
            });
            const rightWing = sItem.cells.rightWing.map((c) => {
              const locIsDomSelected = c.domain.indexOf(queryParams.domainId) !== -1;
              if (locIsDomSelected) {
                isDomainSelected = locIsDomSelected;
              }
              return {...c, isDomainSelected: locIsDomSelected};
            });
            const leftWing = sItem.cells.leftWing.map((c) => {
              const locIsDomSelected = c.domain.indexOf(queryParams.domainId) !== -1;
              if (locIsDomSelected) {
                isDomainSelected = locIsDomSelected;
              }
              return {...c, isDomainSelected: locIsDomSelected};
            });
            return {...sItem, nodes, cells: {topWing, rightWing, leftWing}, isDomainSelected};
          })
          .sort((a, b) => {
            if (b.isDomainSelected && !a.isDomainSelected) {
              return 1;
            }
            if (!b.isDomainSelected && a.isDomainSelected) {
              return -1;
            }
            return 0;
          });
      }

      let selectedIssueSiteArray = null;
      if (selectedIssue && selectedIssue.id === queryParams.issueId) {
        const selectedSites = [];
        const unSelectedSites = [];
        sitesArr.forEach((site) => {
          if (selectedIssue.entityMapping.SITE.includes(site.serverId)) {
            selectedSites.push(site);
            return;
          }

          if (selectedIssue.entityMapping.NODE.length || selectedIssue.entityMapping.INTERFACE.length) {
            const nodeIds = [];
            const interfaceIds = [];
            site.nodes.forEach((n) => {
              nodeIds.push(n.id);
              interfaceIds.push(...n.interfaces.map((i) => i.id));
            });

            if (intersection(selectedIssue.entityMapping.NODE, nodeIds).length) {
              selectedSites.push(site);
              return;
            }

            if (intersection(selectedIssue.entityMapping.INTERFACE, interfaceIds).length) {
              selectedSites.push(site);
              return;
            }
          }

          const cellIds = [
            ...site.cells.leftWing.map((c) => c.id),
            ...site.cells.rightWing.map((c) => c.id),
            ...site.cells.topWing.map((c) => c.id),
          ];
          if (intersection(selectedIssue.entityMapping.CELL, cellIds).length) {
            selectedSites.push(site);
            return;
          }

          const linkIds = site.links.map((l) => l.id);
          if (intersection(selectedIssue.entityMapping.LINK, linkIds).length) {
            selectedSites.push(site);
            return;
          }
          unSelectedSites.push({...site, unSelected: true});
        });
        selectedIssueSiteArray = selectedSites.concat(unSelectedSites);
      }
      setSites(selectedIssueSiteArray || domainSelectedSiteArray || sitesArr);
    } else {
      setSites([]);
    }
  }, [queryParams.siteId, allSites, queryParams.domainId, queryParams.issueId, selectedIssue]);

  const getMapRef = (map) => {
    mapRef.current = map;
  };

  const onRegionClick = (item) => {
    setQueryParams({regionId: item.id});
  };
  const onRegionMouseEvent = (ev) => {
    setNodeHoveredId(ev.eventName === 'mouseenter' ? ev.item.id : undefined);
  };

  const onSiteClick = (item) => {
    setQueryParams({siteId: item.id, selectedEntityId: undefined, selectedSubEntityId: undefined, issueId: undefined});
  };
  const onSiteMouseEvent = (ev) => {
    setNodeHoveredId(ev.eventName === 'mouseenter' ? ev.item.id : undefined);
  };

  return (
    <div className="shell" styleName="root">
      <SidePanel mapRef={mapRef} nodeHoveredId={nodeHoveredId} />
      <GeneralTopologyMap
        regions={selectedRegion.isLeaf ? EMPTY_ARRAY : regions.sort((a, b) => a.percent - b.percent)}
        sites={selectedRegion.isLeaf && !isSitesLoading ? sites : EMPTY_ARRAY}
        links={selectedRegion.isLeaf && sites.length ? links : EMPTY_ARRAY}
        getMapRef={getMapRef}
        onRegionNodeClick={onRegionClick}
        onRegionNodeMouseEvent={onRegionMouseEvent}
        onSiteNodeClick={onSiteClick}
        onSiteNodeMouseEvent={onSiteMouseEvent}
      />
      {isMapDataLoading ? (
        <div styleName="map-data-is-loading">
          <Spinner color="#3d4c59" size={SIZES.XX_BIG_100} />
        </div>
      ) : null}
    </div>
  );
};

export default GeneralTopologyPage;
