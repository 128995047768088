// @flow
import React, {useEffect, useState, useRef} from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {TinyScrollBox, TypographyBox} from 'common/componentsV2/boxTools';
import Modal, {SIZES as MODAL_SIZES} from 'common/componentsV2/modal/Modal';
import {Cell, Column, Table} from 'fixed-data-table-2';
import {get} from 'lodash';

import './CsvPreviewModal.module.scss';
import {makeStyles} from '@material-ui/core/styles';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {palette} from 'app/styles/theme';

type PropTypes = {
  previewData: Object,
  onClose: Function,
  isOpen: Boolean,
  title: String,
  subTitle: String,
  isLoading: Boolean,
  ownerName: String,
};

const MODAL_HEIGHT = 600;
const ROW_HEIGHT = 20;

const useStyles = makeStyles(() => ({
  loader: {
    width: '100%',
    height: '85%',
    position: 'relative',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lines: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    color: palette.gray['400'],
  },
}));

const TextCell = (props: any) => (
  <Cell className="CsvPreviewModalCell">{props.rows[props.rowIndex][props.columnKey]}</Cell>
);

const CsvPreviewModal = (props: PropTypes) => {
  const classes = useStyles();
  const [tableWidth, setTableWidth] = useState(0);
  const [rows, setRows] = useState([]);
  const tableContainer = useRef();

  useEffect(() => {
    if (tableContainer.current) {
      setTableWidth(tableContainer.current.scrollWidth);
    }
  }, [props.isLoading]);

  useEffect(() => {
    setRows(get(props.previewData, 'rows', []));
    return () => {
      setRows([]);
    };
  }, [props.previewData]);

  return (
    <Modal
      onClose={() => props.onClose()}
      isOpen={props.isOpen}
      size={MODAL_SIZES.MEDIUM}
      isCloseButtonHidden={false}
      isStatic
      height={MODAL_HEIGHT}
    >
      <Grid container>
        <Grid item sm={9}>
          <TypographyBox variant="h2" mb={1}>
            {props.title}
          </TypographyBox>
          <TypographyBox variant="subtitle2" mb={2}>
            {props.subTitle}
          </TypographyBox>
          <TypographyBox variant="subtitle2" mb={2}>
            Created by: {props.ownerName}
          </TypographyBox>
        </Grid>

        <Grid item sm={3} className={classes.lines}>
          <TypographyBox variant="subtitle2" mb={2}>
            Lines {rows.length}
          </TypographyBox>
        </Grid>
      </Grid>

      {props.isLoading && (
        <Box className={classes.loader}>
          <Spinner color="#3d4c59" size={SIZES.BIG_60} />{' '}
        </Box>
      )}

      {!props.isLoading && (
        <TinyScrollBox
          width="100%"
          height={MODAL_HEIGHT - 115}
          css={{overflowY: 'auto', overflowX: 'hidden', flexShrink: 0}}
          ref={tableContainer}
        >
          <Table
            rowHeight={ROW_HEIGHT}
            rowsCount={rows.length}
            headerHeight={0}
            scrollLeft={0}
            scrollTop={0}
            isColumnResizing={false}
            width={tableWidth}
            height={rows.length * ROW_HEIGHT + 20}
          >
            {rows.map((row, index) => (
              <Column
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                columnKey={index}
                width={tableWidth / 2}
                allowCellsRecycling
                isResizable={false}
                isReorderable={false}
                fixed={false}
                cell={<TextCell rows={rows} />}
              />
            ))}
          </Table>
        </TinyScrollBox>
      )}
    </Modal>
  );
};

export default CsvPreviewModal;
