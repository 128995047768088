import React, {useCallback, useRef, useState, useEffect} from 'react';
import {Box, Popper} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import {get} from 'lodash';
import Button, {COLORS} from 'common/componentsV2/Button';
import * as metricsSelectors from 'metrics/store/selectors';
import * as amSelectors from 'alerts.management/store/selectors';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import BottomPanel, {BOTTOM_PANEL_TYPE} from 'common/componentsV2/BottomPanel';
import ConfModal from 'common/componentsV2/modal/ConfModal';
import {detachAlertFromTilePayload} from 'dashboards/utils';
import useAsyncAction from 'common/utils/useAsyncAction';
import {setDashboardData, updateDashboard} from 'dashboards/store/actions';
import {showAlertSuccessMessage} from 'alerts.management/store/actions';
import Estimation from 'alerts.management/components/editor/simulationArea/Estimation';
import useDashboardState from 'dashboards/hooks/useDashboardState';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

type PropTypes = {
  alertSimulation: Object,
  onExecuteSimulation: Function,
  isSimulationViewActive: Boolean,
  onSave: Function,
  onCancel: Function,
  isDirty: Boolean,
  autoSimulationEnabled: Boolean,
  isSimulationDisabled: Boolean,
  isCreateMode: Boolean,
  poppedErrors: Array,
  submitError: string,
  isSaving: boolean,
  isAutoSimulationPristine: boolean,
  connectedTiles?: Array,
  alertName: string,
};

const BottomPanelContainer = React.memo(
  ({
    alertSimulation,
    onExecuteSimulation,
    onSave,
    onCancel,
    isSaving,
    isDirty,
    autoSimulationEnabled,
    isSimulationViewActive,
    isSimulationDisabled,
    isCreateMode,
    poppedErrors,
    submitError,
    isAutoSimulationPristine,
    connectedTiles,
    alertName,
  }: PropTypes) => {
    const dispatch = useDispatch();
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
    const {totalEstimation} = alertSimulation.data;
    const isSimulationLoading = alertSimulation.isLoading;
    const isCompositeDataLoading = useSelector(metricsSelectors.getCompositeDataIsLoading);
    const simulationFiltersValidationLoading = useSelector(amSelectors.getSimulationFiltersValidationResults).isLoading;
    const dashboard = useSelector(amSelectors.getFetchDashboard);
    const isConnectedTiles = !!(connectedTiles || EMPTY_ARRAY).length;
    const connectedTileObject = isConnectedTiles ? connectedTiles[0] : EMPTY_OBJECT;
    const dashboardUpdateSelector = useDashboardState(isConnectedTiles ? connectedTiles[0].dashboardId : null);
    const isSomeThingLoading = isCompositeDataLoading || simulationFiltersValidationLoading;
    const updateAlertIsLoading = useSelector(amSelectors.getUpdateAlertResponse).isLoading;
    const createAlertIsLoading = useSelector(amSelectors.getCreateNewAlertResponse).isLoading;
    const isAlertThingLoading = updateAlertIsLoading || createAlertIsLoading || dashboardUpdateSelector.isLoading;

    const alertType = useSelector(amSelectors.getAlertType);
    const submitButtonRef = useRef();

    const updateDashboardAsync = useAsyncAction(updateDashboard, dashboardUpdateSelector);

    useEffect(() => {
      if (dashboard.data) {
        dispatch(setDashboardData(dashboard.data));
      }
    }, [dashboard]);

    const handleClick = useCallback(() => {
      if (isConnectedTiles) {
        setIsOpenConfirmModal(!isOpenConfirmModal);
      } else {
        onSave();
      }
    }, [isConnectedTiles, isOpenConfirmModal]);

    const onDetachAlert = useCallback(() => {
      updateDashboardAsync(
        detachAlertFromTilePayload(dashboard.data, connectedTileObject.tileId, connectedTileObject.alertId),
      ).then(() => {
        setIsOpenConfirmModal(false);
        dispatch(
          showAlertSuccessMessage({
            title: 'Alert updated successfully',
            moreInfo: `[${alertName}] was updated successfully`,
          }),
        );
      });
    }, [dashboard, connectedTileObject]);

    return (
      <BottomPanel type={BOTTOM_PANEL_TYPE.white}>
        <ConfModal
          isOpen={isOpenConfirmModal}
          onClose={handleClick}
          onConfirm={onSave}
          confirmText="Update Alert & Tile"
          cancelText="Detach Alert"
          title="Saving this alert will modify it’s tile"
          isCloseButtonHidden={false}
          additionalAction={onDetachAlert}
          isLoading={isAlertThingLoading}
        >
          <div>This alert is connected to a tile in the dashboard:</div>
          <div>[{get(dashboard, 'data.name')}]</div>
          <div className="mt_2 mb_6">Editing this alert will update the dashboard tile as well.</div>
        </ConfModal>
        <Box display="flex" justifyContent="space-between" maxWidth={1680} width={1} mx="90px" alignItems="center">
          <div />
          {alertType !== 'noData' && (
            <Estimation
              totalEstimation={totalEstimation}
              isSimulationLoading={isSimulationLoading}
              isSimulationViewActive={isSimulationViewActive}
              autoSimulationEnabled={autoSimulationEnabled}
              onExecuteSimulation={onExecuteSimulation}
              isSimulationDisabled={isSimulationDisabled}
              isAutoSimulationPristine={isAutoSimulationPristine}
            />
          )}
          <div>
            <Button automationId="cancelEditor" colorSchema={COLORS.TRANSPARENT} text="Cancel" onClick={onCancel} />
            <Popper
              open={(submitButtonRef.current && (poppedErrors.length > 0 || !!submitError)) || false}
              anchorEl={submitButtonRef.current}
              placement="top-end"
              disablePortal
            >
              <Box
                borderRadius={6}
                position="relative"
                width={287}
                bgcolor="red.500"
                py={1}
                px={1.5}
                color="white.500"
                fontWeight={500}
                fontSize={16}
                bottom={-8}
              >
                {poppedErrors.map((error, index, source) => (
                  <React.Fragment>
                    <Box key={error}>{error}</Box>
                    {index < source.length - 1 && (
                      <Box key={`${error}_line`} height="1px" my={1} bgcolor="white.500" css={{opacity: 0.5}} />
                    )}
                  </React.Fragment>
                ))}
                {submitError && <div>{submitError}</div>}
                <Box
                  position="absolute"
                  width={12}
                  height={12}
                  bottom={-6}
                  right={54}
                  bgcolor="red.500"
                  css={{transform: 'rotate(45deg)'}}
                />
              </Box>
            </Popper>
            <Box
              ref={submitButtonRef}
              position="relative"
              display="inline-block"
              css={{
                '& > div': {
                  position: 'absolute',
                  left: 'calc(50% - 15px)',
                  top: 'calc(50% - 15px)',
                },
                '& [class*=text-container]': {
                  visibility: isSaving ? 'hidden' : 'visible',
                },
                pointerEvents: isSaving ? 'none' : 'auto',
              }}
            >
              {isSaving && <Spinner color="#ffffff" size={SIZES.SMALL_30} />}
              <Button
                isDisabled={!isDirty || isSimulationDisabled || isSomeThingLoading}
                colorSchema={COLORS.BLUE_500}
                text={isCreateMode ? 'Create Alert' : 'Update Alert'}
                onClick={handleClick}
                automationId="saveAlert"
              />
            </Box>
          </div>
        </Box>
      </BottomPanel>
    );
  },
);

BottomPanelContainer.defaultProps = {
  connectedTiles: EMPTY_ARRAY,
};

export default BottomPanelContainer;
