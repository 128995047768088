// @flow
import React from 'react';
import {connect} from 'react-redux';
import SmartTooltip from 'common/components/SmartTooltip';
import {
  deleteDataStream,
  editDataStream,
  previewDataStream,
  pauseResumeDataStream,
  duplicateStream,
  editLiveStream,
  setSelectedDataStream,
  setDataManagerStreamPreviewId,
  setViewStreamSummaryAssets,
} from 'bc/store/actions';
import {segmentCausingEvent} from 'common/store/actions';
import {
  getStreamStatusText,
  isStreamEditable,
  isStreamFinished,
  isStreamFailed,
  isStreamFileUpload,
  isStreamRunning,
  isAlowedEditRunning,
  isAgent,
} from 'bc/services/dataStreamService';
import {
  setStateModel,
  gotoState,
  getEmptyTree,
  getEmptyExpression,
  getSearchObjectOriginExpression,
  getSearchObjectPropertyExpression,
  getTreesDisplayPropertiesFromCompositeDefinition,
} from 'common/utils/angularServices';
import {cleanupSpecialChars} from 'metrics/services/metricsService';
import ConfirmationModal from 'common/components/modals/ConfirmationModal';
import HackFileJsonModal from 'bc/components/streams/preview/HackFileJsonModal';
import {getDataManagerStreamPreviewId, getNewStreamsCount, isBcReadOnlyPermision} from 'bc/store/selectors';
import * as commonSelectors from 'profile/store/selectors';
import StreamProgressBar from './StreamProgressBar';
import ViewStreamSummaryModal from './viewStreamSummaryModalV2/ViewStreamSummaryModalV2';
import EditLiveStreamModal from './editLiveStreamModal/EditLiveStreamModal';
import DeleteMetricsConfirmation from './DeleteMetricsConfirmation';
import StreamItemContainer from './StreamItemContainer';
import './StreamListItem.module.scss';
import StreamsLimitModal from './StreamsLimitModal';

type PropTypes = {
  name: string,
  id: string,
  type: string,
  state: string,
  status: string,
  family: string,
  paused: string,
  failureMessage: string,
  queryServiceState: string,
  hasDimreduce: boolean,
  streamPreviewId: string,
  dataStream: Object,
  deleteDataStream: Function,
  editDataStream: Function,
  previewDataStream: Function,
  pauseResumeDataStream: Function,
  duplicateStream: Function,
  editLiveStream: Function,
  setSelectedDataStream: Function,
  setDataManagerStreamPreviewId: Function,
  setViewStreamSummaryAssets: Function,
  segmentCausingEvent: Function,
  isBcReadOnly: boolean,
  isLiveEditEnable: boolean,
  isStreamRenameEnabled: boolean,
  newStreamsCount: Number,
  incompleteSteamsLimit: Number,
};

@connect(
  (state, ownProps) => ({
    id: ownProps.id,
    name: ownProps.name,
    type: ownProps.type,
    state: ownProps.state,
    status: ownProps.status,
    paused: ownProps.paused,
    family: ownProps.family,
    failureMessage: ownProps.failureMessage,
    queryServiceState: ownProps.queryServiceState,
    hasDimreduce: ownProps.hasDimreduce,
    dataStream: ownProps,
    streamPreviewId: getDataManagerStreamPreviewId(state),
    isLiveEditEnable: commonSelectors.getLiveEditEnable(state),
    isBcReadOnly: isBcReadOnlyPermision(state),
    isStreamRenameEnabled: commonSelectors.getStreamRenameEnabled(state),
    newStreamsCount: getNewStreamsCount(state),
    incompleteSteamsLimit: commonSelectors.getIncompleteSteamsLimit(state),
  }),
  {
    deleteDataStream,
    editDataStream,
    previewDataStream,
    pauseResumeDataStream,
    duplicateStream,
    editLiveStream,
    setSelectedDataStream,
    setDataManagerStreamPreviewId,
    setViewStreamSummaryAssets,
    segmentCausingEvent,
  },
)
export default class StreamItem extends React.PureComponent {
  props: PropTypes;

  state = {
    isModalOpen: false,
    isStreamViewModalOpen: false,
    isLiveStreamEditModalOpen: false,
    shouldDeleteMetrics: true,
    hackIsOpenFileJsonModal: false,
    isLimitModalOpen: false,
  };

  componentDidMount() {
    if (this.props.streamPreviewId === this.props.id) {
      this.openStreamViewModal();
    }
  }

  isExitAnimation = false;

  hackCounter = 0;

  hackFileJsonClicked = () => {
    this.setState((prevState) => ({hackIsOpenFileJsonModal: !prevState.hackIsOpenFileJsonModal}));
  };

  hackStarter = (event) => {
    if (event.shiftKey) {
      this.hackCounter += 1;
      if (this.hackCounter % 5 === 0) {
        this.hackCounter = 0;
        if (!this.state.hackIsOpenFileJsonModal) {
          this.hackFileJsonClicked();
        }
      }
    }
  };

  openStreamViewModal = () => {
    this.props.setSelectedDataStream(this.props.id);
    this.setState({isStreamViewModalOpen: true});
  };

  closeStreamViewModal = () => {
    if (this.props.streamPreviewId) {
      this.props.setDataManagerStreamPreviewId(null);
      this.props.setSelectedDataStream(null);
      // eslint-disable-next-line prefer-destructuring
      window.location.hash = window.location.hash.split('?')[0];
    }
    this.props.setViewStreamSummaryAssets({assets: {streamId: null}});
    this.setState({isStreamViewModalOpen: false});
  };

  openLiveStreamEditModal = () => {
    this.props.setSelectedDataStream(this.props.id);
    this.setState({isLiveStreamEditModalOpen: true});
  };

  closeLiveStreamEditModal = () => {
    this.setState({isLiveStreamEditModalOpen: false});
  };

  openDeleteModal = () => {
    this.setState({isModalOpen: true});
  };

  closeModal = () => {
    this.setState({isModalOpen: false});
  };

  onConfirmModal = () => {
    this.isExitAnimation = true;
    this.props.deleteDataStream({
      id: this.props.id,
      type: this.props.type,
      shouldDeleteMetrics: this.state.shouldDeleteMetrics,
    });
    this.setState({isModalOpen: false, shouldDeleteMetrics: true});
  };

  onCheckBoxChange = (event) => {
    const {target} = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const {name} = target;

    this.setState({
      [name]: value,
    });
  };

  openStreamsListModal = () => {
    this.setState({isLimitModalOpen: true});
  };

  closeStreamsListModal = () => {
    this.setState({isLimitModalOpen: false});
  };

  actionItemSelected = (eventKey) => {
    switch (eventKey.value) {
      case 'delete':
        this.openDeleteModal();
        break;
      case 'view':
        this.openStreamViewModal();
        break;
      case 'show-metrics':
        this.showMetrics();
        break;
      case 'edit':
        return this.props.queryServiceState === 'complete'
          ? this.props.previewDataStream(this.props.id)
          : this.props.editDataStream(this.props.id);
      case 'edit-live':
        this.props.segmentCausingEvent({category: 'bc/data-manager', name: 'edit-live'});
        this.openLiveStreamEditModal();
        break;
      case 'edit-advanced':
        return this.props.editLiveStream({id: this.props.id});
      case 'duplicate':
        if (this.props.newStreamsCount >= this.props.incompleteSteamsLimit) {
          this.openStreamsListModal();
          return null;
        }
        return this.props.duplicateStream({id: this.props.id});

      case 'pause':
      case 'resume':
        return this.props.pauseResumeDataStream({id: this.props.id, action: eventKey.value});
      default:
    }
    return null;
  };

  getQueryServiceStatus = () => {
    let msg = '';
    switch (this.props.queryServiceState) {
      case 'running':
        msg = 'Executing Query';
        break;
      case 'complete':
        msg = 'Report is ready';
        break;
      default:
        return null;
    }
    return <div styleName="stream-exec-report">{msg}</div>;
  };

  getStatusIcon = () => {
    let icon = '';
    let style = '';

    if (isAgent(this.props.family)) {
      switch (this.props.state) {
        case 'RUNNING':
          icon = 'icn-icon-sun-live-stream';
          style = 'sun';
          break;
        case 'STOPPED':
        case 'EDITED':
        case 'DISCONNECTED':
          icon = 'icn-icon-night-paused';
          style = 'moon';
          break;
        case 'STARTING':
        case 'CONNECTING':
          icon = 'icn-icon-initializing';
          style = 'initializing';
          break;
        case 'START_ERROR':
        case 'RUN_ERROR':
        case 'STOP_ERROR':
        case 'RUNNING_ERROR':
          icon = 'icn-icon-error';
          style = 'error';
          break;
        default:
          return null;
      }
    } else if (this.props.paused) {
      icon = 'icn-icon-night-paused';
      style = 'moon';
    } else if (isStreamFailed(this.props.status)) {
      icon = 'icn-icon-error';
      style = 'error';
    } else {
      switch (this.props.state) {
        case 'running':
          icon = 'icn-icon-sun-live-stream';
          style = 'sun';
          break;
        case 'initializing_p0':
        case 'initializing_p1':
        case 'initializing_p2':
        case 'initializing_p3':
        case 'initializing_p4':
          icon = 'icn-icon-initializing';
          style = 'initializing';
          break;
        case 'new':
          icon = 'icn-icon-incomplete';
          style = 'incomplete';
          break;
        case 'finished':
          icon = 'icn-complete-icon';
          style = 'complete';
          break;
        default:
          return null;
      }
    }
    if (icon) {
      return <i className={`icon ${icon}`} styleName={`status-icon ${style}`} />;
    }
    return null;
  };

  getStreamStateText = (state, status, failureMessage) => {
    if (getStreamStatusText(status, failureMessage)) {
      return 'Error';
    }
    switch (state) {
      case 'initializing_p0':
      case 'initializing_p1':
      case 'initializing_p2':
      case 'initializing_p3':
      case 'initializing_p4':
        return 'In Progress';
      case 'new':
        return 'Incomplete';
      case 'running':
        return 'Live';
      case 'finished':
        return 'Completed';
      default:
    }
    return null;
  };

  getAgentsStateText = (state) => {
    switch (state) {
      case 'RUNNING':
        return 'Live';
      case 'STOPPED':
      case 'EDITED':
      case 'DISCONNECTED':
        return 'Paused';
      case 'STARTING':
      case 'CONNECTING':
        return 'Initializing';
      case 'START_ERROR':
      case 'RUN_ERROR':
      case 'STOP_ERROR':
      case 'RUNNING_ERROR':
        return 'Error';
      default:
        return '';
    }
  };

  showMetrics = () => {
    const {state, status, name, id, dataStream, isStreamRenameEnabled, family} = this.props;
    const whats = [];
    const serverTreesModel = {};
    const node = getEmptyExpression();
    const aggregation = {
      counter: 'Sum',
      gauge: 'Avg',
    };
    let groupByAggregation = null;

    if (this.isStreamNameClickable(state, status) || isAgent(family)) {
      dataStream.schema.columns.forEach((col) => {
        if (col.type === 'metric') {
          whats.push(cleanupSpecialChars(col.name));
          groupByAggregation = !groupByAggregation ? aggregation[col.targetType] : groupByAggregation;
        }
      });

      if (isAgent(family)) {
        node.searchObject.expression.push(getSearchObjectPropertyExpression('#pipeline_id', id, true));
        node.searchObject.expression.push(getSearchObjectPropertyExpression('#pipeline_type', dataStream.type, true));
      } else {
        const originExpressionVal = isStreamRenameEnabled ? id : name;
        node.searchObject.expression.push(getSearchObjectOriginExpression('@Stream', originExpressionVal, true));
      }

      const tree = getEmptyTree(null, {}, null, null, node, node);
      serverTreesModel[tree.id] = tree;

      setStateModel('metricComposite', {
        serverTreesModel,
        shouldValidateOnLoad: true,
        treesDisplayProperties: getTreesDisplayPropertiesFromCompositeDefinition(tree),
      });
      gotoState('metricComposite');
    }
  };

  isStreamNameClickable = (state, status) => status === 'ok' && (state === 'running' || state === 'finished');

  getDropdownOptions = () => {
    const ret = [];
    const {isLiveEditEnable, isBcReadOnly, state, dataStream, status, paused, family} = this.props;

    // if (this.isStreamNameClickable(state, status)) {
    ret.push({
      label: 'View',
      value: 'view',
    });
    // }
    if (isAgent(family)) {
      ret.push({label: 'Show Metrics', value: 'show-metrics'});
    } else if (isBcReadOnly && this.isStreamNameClickable(state, status)) {
      ret.push({
        label: 'Show Metrics',
        value: 'show-metrics',
      });
    } else {
      if (isStreamEditable(state)) {
        ret.push({
          label: 'Edit',
          value: 'edit',
        });
      } else if (this.isStreamNameClickable(state, status)) {
        ret.push({
          label: 'Show Metrics',
          value: 'show-metrics',
        });
      }
      if (isAlowedEditRunning(dataStream.type) && isStreamRunning(state) && isLiveEditEnable) {
        ret.push({
          label: 'Edit',
          value: 'edit-advanced',
        });
      } else if (!isStreamFileUpload(dataStream.type) && isStreamRunning(state) && !paused) {
        ret.push({
          label: 'Edit',
          value: 'edit-live',
        });
      }
      if (isStreamFailed(status)) {
        ret.push({
          label: 'Resume',
          value: 'resume',
        });
      } else if (!(isStreamEditable(state) || isStreamFinished(state))) {
        ret.push({
          label: paused ? 'Resume' : 'Pause',
          value: paused ? 'resume' : 'pause',
        });
      }
      if (!isStreamFailed(status)) {
        ret.push({
          label: 'Duplicate',
          value: 'duplicate',
        });
      }
      ret.push({
        label: 'Delete',
        value: 'delete',
      });
    }
    return ret;
  };

  render() {
    const {
      closeModal,
      onConfirmModal,
      closeStreamViewModal,
      closeLiveStreamEditModal,
      onCheckBoxChange,
      getStreamStateText,
      getStatusIcon,
      getAgentsStateText,
      hackFileJsonClicked,
      getQueryServiceStatus,
      isExitAnimation,
    } = this;

    const {
      isModalOpen,
      isStreamViewModalOpen,
      isLiveStreamEditModalOpen,
      shouldDeleteMetrics,
      hackIsOpenFileJsonModal,
      isLimitModalOpen,
    } = this.state;

    const {state, status, name, type, id, paused, isBcReadOnly, failureMessage, hasDimreduce, family} = this.props;

    const statusNode = (
      <div styleName="stream-status-text">{paused ? 'Paused' : getStreamStateText(state, status, failureMessage)}</div>
    );

    const agentNode = <div styleName="stream-status-text">{getAgentsStateText(state)}</div>;

    /* eslint-disable no-nested-ternary */
    return (
      <StreamItemContainer
        name={name}
        id={id}
        type={type}
        isAgent={isAgent(family)}
        isExitAnimation={isExitAnimation}
        hasDimreduce={hasDimreduce}
        isNameClickable
        moreOptionsItems={this.getDropdownOptions()}
        moreOptionsIsDisabled={isStreamEditable(state) && isBcReadOnly}
        moreOptionsOnChange={this.actionItemSelected}
        streamNameClicked={this.openStreamViewModal}
        hackStarter={this.hackStarter}
      >
        <StreamProgressBar state={state} status={status} />

        {getQueryServiceStatus()}

        <div styleName="stream-status-wrapper" automation-id="dataManagerStreamListItemStatus">
          {getStatusIcon()}
          {getStreamStatusText(status, failureMessage) ? (
            <SmartTooltip placement="top" content={getStreamStatusText(status, failureMessage)}>
              {statusNode}
            </SmartTooltip>
          ) : isAgent(family) ? (
            agentNode
          ) : (
            statusNode
          )}
        </div>

        {isModalOpen && (
          <ConfirmationModal
            onClose={closeModal}
            onConfirm={onConfirmModal}
            title="Delete stream"
            message="Are you sure you want to delete the stream?"
            isOpen={isModalOpen}
          >
            {!isStreamEditable(state) && (
              <DeleteMetricsConfirmation
                shouldDeleteMetrics={shouldDeleteMetrics}
                onShouldDeleteMetricsChange={onCheckBoxChange}
              />
            )}
          </ConfirmationModal>
        )}

        {isStreamViewModalOpen && (
          <ViewStreamSummaryModal
            onClose={closeStreamViewModal}
            isOpen={isStreamViewModalOpen}
            isAgent={isAgent(family)}
          />
        )}

        {isLiveStreamEditModalOpen && (
          <EditLiveStreamModal onClose={closeLiveStreamEditModal} isOpen={isLiveStreamEditModalOpen} />
        )}

        {hackIsOpenFileJsonModal && (
          <HackFileJsonModal
            isOpen={hackIsOpenFileJsonModal}
            streamId={this.props.dataStream.id}
            onClose={hackFileJsonClicked}
          />
        )}

        {isLimitModalOpen ? (
          <StreamsLimitModal
            isOpen={this.state.isLimitModalOpen}
            onClose={this.closeStreamsListModal}
            scenario="incomplete"
          />
        ) : null}
      </StreamItemContainer>
    );
  }
}
