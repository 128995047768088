import React, {useState, useCallback, useEffect, Fragment} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {isEmpty} from 'lodash';
import useHandleResize from 'common/hooks/useHandleResize';
import {fetchAnomalyMetrics, setCorrelationsFilter} from 'investigation/store/actions';
import {
  getCorrelationsTokenMapIsLoading,
  getCorrelationsTokenMapDataFiltered,
  getFetchAlertMetricsCorrelation,
  getInvModalCorrelationsFilters,
  getCorrelationsTokenMapCounter,
  getCorrelationsTokenMapTotal,
  getInvOtherMeasures,
} from 'investigation/store/selectors';
import {getTimeZoneName} from 'profile/store/selectors';
import {Box} from '@material-ui/core';
import {palette} from 'app/styles/theme';
import {ExpandableBox} from 'common/componentsV2/ExpandableSections';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {ReactComponent as EmptyState} from 'investigation/images/correlationsEmptyState.svg';

import Spinner, {SIZES as SpinnerSizes} from 'common/componentsV2/Spinner';
import ContentLoader from 'common/componentsV2/ContentLoader';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';
import Button, {COLORS as ButtonColors} from 'common/componentsV2/Button';

import FiltersChips from 'investigation/components/incident/FiltersChips';
import TokenMapFilters from 'investigation/components/incident/TokenMapFilters';
import MetricsTable from '../metricsTable/MetricsTable';

const CorrelationTab = () => {
  const style = {
    emptyState: {
      width: '100%',
      marginTop: '20%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    metricsContainer: {
      marginTop: 8,
      position: 'relative',
      left: 24,
      paddingRight: 24,
      paddingLeft: 1,
      overflowY: 'scroll',
    },
    panelRootStyle: {
      flexShrink: 0,
      padding: 0,
      border: 'none',
      marginTop: 16,
      marginBottom: 16,
    },
    anoMapLabel: {
      display: 'flex',
      alignItems: 'center',
      marginTop: -4,
      marginLeft: -5,
    },
    metricLabel: {
      display: 'flex',
      marginTop: 5,
    },
  };
  const dispatch = useDispatch();
  const timeZoneName = useSelector(getTimeZoneName);

  const isTokenMapLoading = useSelector(getCorrelationsTokenMapIsLoading);
  const correlationsTokenMap = useSelector(getCorrelationsTokenMapDataFiltered);
  const tokenMapMetricsCounter = useSelector(getCorrelationsTokenMapCounter);
  const tokenMapMetricsTotal = useSelector(getCorrelationsTokenMapTotal);

  const allMetrics = useSelector(getFetchAlertMetricsCorrelation);

  const otherMeasures = useSelector(getInvOtherMeasures);

  const filters = useSelector(getInvModalCorrelationsFilters);

  const [isExpand, setIsExpand] = useState({});
  const [listOrder, setListOrder] = useState({});
  const bodyContainerHeight = useHandleResize([300]);

  useEffect(() => {
    if (isTokenMapLoading === undefined) {
      dispatch(setCorrelationsFilter());
    }
  }, []);

  useEffect(() => {
    if (!isTokenMapLoading && !isEmpty(correlationsTokenMap)) {
      const firstWhat = Object.keys(correlationsTokenMap)[0];
      dispatch(fetchAnomalyMetrics({what: firstWhat, alertId: null, filters, isMore: false}));

      setIsExpand((st) => ({...st, [`${firstWhat}-all-metrics`]: true}));
    }
  }, [isTokenMapLoading]);

  const onExpandBoxChange = useCallback(
    (id) => (isExpanded) => {
      dispatch(fetchAnomalyMetrics({what: id, alertId: null, filters, isMore: false}));
      setIsExpand((st) => ({...st, [`${id}-all-metrics`]: isExpanded}));
    },
    [],
  );

  const onShowMoreMetricsClick = useCallback(
    (what, index) => () => {
      dispatch(fetchAnomalyMetrics({what, index, isMore: true, filters}));
    },
    [],
  );

  const isMoreMetrics = (what) => allMetrics[what].data.metrics.length < allMetrics[what].data.metricsCount.total;

  const setFilter = (dimension) => {
    if (dimension.id !== 'other') {
      dispatch(setCorrelationsFilter(dimension));
    }
  };

  if (!otherMeasures.length && !isTokenMapLoading) {
    return (
      <Box style={style.emptyState}>
        <EmptyState />
        <TypographyBox variant="subtitle2" color={palette.gray['500']} mt={2}>
          After analyzing all the metrics,{' '}
          <TypographyBox variant="subtitle1" mt={1}>
            We found the incident is contained within the original measure, no other measures are affected.
          </TypographyBox>
        </TypographyBox>
      </Box>
    );
  }

  return (
    <Grid container>
      {/* TokenMap */}
      <Grid item sm={4}>
        <TokenMapFilters
          tokenMap={correlationsTokenMap}
          selectedFilters={filters}
          isLoading={isTokenMapLoading}
          metricsCounter={tokenMapMetricsCounter}
          totalMetrics={tokenMapMetricsTotal}
          onSelect={setFilter}
        />
      </Grid>

      {/* Metrics */}
      <Grid item sm={8}>
        <Box style={style.metricsContainer} height={bodyContainerHeight}>
          <FiltersChips filters={filters} onSelect={setFilter} />

          {/* All Metrics */}
          {otherMeasures.map((what) => (
            <Box mb={3} mt={1} key={what}>
              <InnerChip title={what} displayOnly type="measure" />
              <ExpandableBox
                key={`inc-gr-panel-${what}-all`}
                automationId="incWhatGroup"
                nodeLabel={
                  <TypographyBox variant="h5" color={palette.gray['400']} mt={0.5}>
                    Anomalies in correlation{' '}
                    <TypographyBox component="span" color={filters.length ? palette.teal['500'] : palette.gray['400']}>
                      {tokenMapMetricsCounter[what]?.anomalyOccurrences}
                    </TypographyBox>
                  </TypographyBox>
                }
                expanded={isExpand[`${what}-all-metrics`]}
                onChange={onExpandBoxChange(what)}
                rootStyle={style.panelRootStyle}
              >
                <div>
                  {allMetrics[what] && allMetrics[what].isLoading && (
                    <ContentLoader
                      rows={[
                        {width: 90, height: 27},
                        {width: 415, height: 20},
                        {width: '100%', height: 40},
                        {width: '100%', height: 40},
                        {width: '100%', height: 40},
                      ]}
                    />
                  )}
                  {allMetrics[what] &&
                    allMetrics[what].data &&
                    allMetrics[what].data.metricsCount &&
                    !allMetrics[what].isLoading && (
                      <Fragment>
                        <MetricsTable
                          metrics={allMetrics[what].data.metrics}
                          timeZoneName={timeZoneName}
                          listOrder={listOrder}
                          setListOrder={setListOrder}
                        />
                        {allMetrics[what].isLoadingMore && (
                          <Box display="flex" alignItems="center" justifyContent="center">
                            <Spinner color="#3d4c59" size={SpinnerSizes.MEDIUM_50} />
                          </Box>
                        )}
                        {isMoreMetrics(what) && (
                          <Box display="flex" justifyContent="center" pt={1} pb={1}>
                            {!allMetrics[what].isLoadingMore && (
                              <Button
                                colorSchema={ButtonColors.TRANSPARENT_BLUE}
                                text="Show more metrics"
                                onClick={onShowMoreMetricsClick(what, allMetrics[what].data.metrics.length)}
                              />
                            )}
                          </Box>
                        )}
                        {!allMetrics[what].data.metrics.length && (
                          <TypographyBox variant="h5" color={palette.gray['400']} margin="16px 0 0 22px">
                            Anomalies in correlation{' '}
                            <TypographyBox
                              component="span"
                              color={filters.length ? palette.teal['500'] : palette.gray['400']}
                            />
                            (0)
                          </TypographyBox>
                        )}
                      </Fragment>
                    )}
                </div>
              </ExpandableBox>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default CorrelationTab;
