// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedEditor} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import SchedularData from 'bc/components/streams/editor/common/SchedularData';
import {isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import './SalesforceSchedular.module.scss';

type PropTypes = {
  // connect
  dataStream: Object,
  isLoadingDataSets: boolean,
  setSelectedEditor: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isLoadingDataSets: selectors.getSalesforceIsLoading(state),
  }),
  {
    setSelectedEditor,
  },
)
export default class SalesforceSchedular extends React.PureComponent {
  props: PropTypes;

  openEditor = () => {
    this.props.setSelectedEditor({
      type: 'scheduler_salesforce',
      editorHeight: 'calc(100% - 210px)',
      maxHeight: '600px',
    });
  };

  render() {
    const {
      dataStream: {state},
      isLoadingDataSets,
    } = this.props;
    const isEditable = (isStreamEditable(state) || isStreamRunning(state)) && !isLoadingDataSets;

    return (
      <div className="shell-col" automation-id="salesforceQueryScheduler" styleName={!isEditable ? 'disabled' : ''}>
        <PanelHeader title="Query Schedule" onSelect={this.openEditor} isEditable={isEditable} />
        <SchedularData />
      </div>
    );
  }
}
