import React, {useEffect, useState, memo, useCallback} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {palette} from 'app/styles/theme';
import {getIncidentTokenMapIsLoading} from 'investigation/store/selectors';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import './TooltipExtraClass.module.scss';

const colorMap = {
  0: palette.gray['500'],
  1: palette.gray['400'],
  2: palette.gray['350'],
  3: palette.gray['300'],
  4: palette.gray['300'],
  5: palette.gray['300'],
  6: 'repeating-linear-gradient(135deg, rgb(137, 149, 160) 0, rgb(137, 149, 160) 1px, rgb(181, 187, 195) 2px, rgb(181, 188, 196) 8px)',
  selected: palette.teal['500'],
};

const DimensionItem = ({dimension, percentage, isLast, index, filters, onSelect}: PropTypes) => {
  const isTokenMapLoading = useSelector(getIncidentTokenMapIsLoading);
  const [isSelectedItem, setIsSelectedItem] = useState(null);

  useEffect(() => {
    setIsSelectedItem(filters.some((f) => f.id === dimension.id));
  }, [filters]);

  const createTooltipContent = (
    <div>
      <div>
        {dimension.value}
        {dimension.id === 'other' ? 'Other' : ''}: {percentage.toFixed(2)}%
      </div>
      <div>{dimension.anomalyOccurrences} Metrics</div>
    </div>
  );

  const setFilter = useCallback(() => {
    onSelect(dimension);
  }, [dimension]);

  return (
    <Tooltip
      content={createTooltipContent}
      type={TYPES.SMALL}
      hideIfEqualTo={isTokenMapLoading ? createTooltipContent : null}
      extraTtClass="dimensionItem"
    >
      <Box
        onClick={setFilter}
        width={`${percentage}%`}
        css={{
          flexGrow: '1',
          height: '100%',
          boxSizing: 'border-box',
          background: isSelectedItem ? colorMap.selected : colorMap[index],
          borderRight: isLast || isSelectedItem || percentage === 0 ? 'none' : `2px solid ${palette.gray['100']}`,
          cursor: dimension.id !== 'other' ? 'pointer' : 'default',
          '&:hover': {
            background:
              index === 6
                ? 'repeating-linear-gradient(135deg, rgb(137, 149, 160) 0, rgb(137, 149, 160) 1px, #00BBD4 2px, #00BBD4 8px)'
                : palette.teal['500'],
          },
        }}
      />
    </Tooltip>
  );
};

DimensionItem.propTypes = {
  index: PropTypes.number.isRequired,
  dimension: PropTypes.objectOf(PropTypes.any).isRequired,
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default memo(DimensionItem);
