// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {getGroupsModalGroupUsers, getUsersDataForGroupsModal} from 'admin.users/store/selectors';
import {setGroupProps} from 'admin.users/store/actions';
import MembersItem from './MembersItem';
import './Sections.module.scss';

type PropTypes = {
  users: Array,
  selectedUsers: Array,
  setGroupProps: Function,
};

@connect(
  (state) => ({
    users: getUsersDataForGroupsModal(state),
    selectedUsers: getGroupsModalGroupUsers(state),
  }),
  {
    setGroupProps,
  },
)
export default class PropertiesSection extends React.PureComponent {
  props: PropTypes;

  userSelected = (items) => {
    this.props.setGroupProps({selectedUsers: items});
  };

  userRemoveClicked = (id) => {
    this.props.setGroupProps({selectedUsers: this.props.selectedUsers.filter((item) => item.id !== id)});
  };

  render() {
    const {selectedUsers} = this.props;

    return (
      <div styleName="members">
        <div styleName="members-header">
          <span styleName="cat-label">Add Members</span>
          <div styleName="user-selection-wrapper">
            <SelectAndt
              automationId="addUserToGroupSelect"
              type={TYPE_NEW_MULTI}
              theme={THEME_NOT_HIGHLIGHTED}
              onChange={this.userSelected}
              options={this.props.users}
              value={this.props.selectedUsers}
              isMulti
              placeholder="Search Anodot Users"
              optionHeight={40}
              menuWidth={290}
              isClearable={false}
              closeMenuOnSelect={false}
              showValue={false}
              getOptionLabel={(val) => val.name}
              getOptionValue={(val) => val.email}
            />
          </div>
        </div>
        <div styleName={['members-body', selectedUsers.length > 9 ? 'content-box' : ''].join(' ')}>
          {selectedUsers.map((user) => (
            <MembersItem item={user} onRemoveClick={this.userRemoveClicked} labelKey="name" key={user.id} />
          ))}
        </div>
      </div>
    );
  }
}
