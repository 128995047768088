import React, {Fragment, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {selectors as commonSelectors} from 'common';
import {isImpactModalOpen} from 'impact/store/actions';
import {
  setIsOpen as investigationSetIsOpen,
  setIsMinimize as investigationSetIsMinimize,
} from 'investigation/store/actions';
import LoadingBar from 'common/components/LoadingBar';
import NotificationBar from 'common/components/NotificationBar';
import ImpactModal from 'impact/ImpactModal';
import InvestigationModal from 'investigation/pages/InvestigationModal';
import {modalRouting as investigationRouting} from 'investigation/services/investigationService';
import {modalRouting1 as impactRouting1, modalRouting2 as impactRouting2} from 'impact/services/constants';

const checking = (routingLocation, modalRouting) => {
  if (!routingLocation || !routingLocation.query) {
    return false;
  }
  return Object.values(modalRouting).every((queryParam) => routingLocation.query[queryParam]);
};

const Overlays = () => {
  const dispatch = useDispatch();
  const routingLocation = useSelector(commonSelectors.getRoutingLocation);

  const openImpactModalOpen = () => {
    if (checking(routingLocation, impactRouting1) || checking(routingLocation, impactRouting2)) {
      dispatch(isImpactModalOpen(true));
    }
  };

  const openInvestigationModalOpen = () => {
    if (checking(routingLocation, investigationRouting)) {
      dispatch(investigationSetIsOpen(true));
      dispatch(investigationSetIsMinimize(false));
    }
  };

  useEffect(() => {
    openImpactModalOpen();
    openInvestigationModalOpen();
  }, [routingLocation]);

  return (
    <Fragment>
      <LoadingBar className="loading-bar" />
      <NotificationBar />
      <ImpactModal />
      <InvestigationModal />
    </Fragment>
  );
};

export default Overlays;
