// @flow
import React, {PureComponent} from 'react';
import connect from 'react-redux/es/connect/connect';
import Gauge from 'common/componentsV2/gauges/Gauge';

import {fetchMetricsTotal as fetchMetricsTotalAction} from 'metrics/store/actions';
import * as selectors from 'metrics/store/selectors';

type PropTypes = {
  selectedItem: Number,
  index: Number,
  description: String,
  // Connect
  value: Number,
  isLoading: Boolean,
  fetchMetricsTotal: Function,
};

@connect(
  (state) => ({
    value: selectors.getFetchMetricsTotal(state),
    isLoading: selectors.getIsLoadingMetricsTotal(state),
  }),
  {
    fetchMetricsTotal: fetchMetricsTotalAction,
  },
)
export default class MetricsGauge extends PureComponent {
  props: PropTypes;

  fetchData = () => {
    const {value, fetchMetricsTotal} = this.props;
    if (value === null) {
      fetchMetricsTotal();
    }
  };

  render() {
    const {value, isLoading, description, index, selectedItem} = this.props;
    return (
      <Gauge
        description={description}
        value={value}
        isLoading={isLoading}
        selectedItem={selectedItem}
        index={index}
        fetchData={this.fetchData}
      />
    );
  }
}
