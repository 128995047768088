// @flow
import React from 'react';
import {segmentClickEvent} from 'common/store/actions';
import {noop} from 'lodash';
import {connect} from 'react-redux';
import FormDdl from './ddl/multiSelectFormDdl/FormDdl';
import './SplitButton.module.scss';

type PropTypes = {
  text: string,
  menuComponent: Node,
  onClick: Function,

  onMoreClick?: Function,
  isMainDisabled?: boolean,
  isMoreDisabled?: boolean,
  colorSchema?: string,
  height?: string,
  width?: string,
  className?: string,
  position?: string,
  placement?: string,
  automationIdButton: string,
  automationIdSplitOpen: string,
  segmentClickEvent: Function,
};

export const COLORS = {
  // TODO - colorSchema
  AZURE_500: 'azure-500',
  GRAY_200: 'gray-200', // #e9e9f0;
  GRAY_300: 'gray-300',
  GRAY_400: 'gray-400',
  GRAY_500: 'gray-500',
  INDIGO_500: 'indigo-500',
  BLUE_500: 'blue-500',
  RED_500: 'red-500',
  GREEN_500: 'green-500',
  WHITE: 'white',
  TRANSPARENT: 'transparent',
};

export const HEIGHTS = {
  TIGHT30: 'tight-30',
  TIGHT28: 'tight-28',
  REGULAR: 'regular-height',
  TALL: 'tall',
};

export const WIDTHS = {
  WIDE: 'wide',
  REGULAR: 'regular-width',
  NARROW: 'narrow',
};

@connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)
export default class SplitButton extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    onMoreClick: noop,
    isMainDisabled: false,
    isMoreDisabled: false,
    colorSchema: COLORS.BLUE_500,
    width: WIDTHS.REGULAR,
    height: HEIGHTS.REGULAR,
    className: '',
    position: 'right',
    placement: 'auto-top',
  };

  state = {
    isOpen: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (typeof props.internalClickState === 'boolean' && !state.isOpen !== false) {
      return {isOpen: false};
    }
    return null;
  }

  rootStyle = [];

  leftBtnStyle = [];

  rightBtnStyle = [];

  onClick = (e) => {
    if (!this.props.isMainDisabled) {
      this.props.segmentClickEvent({
        type: 'click',
        name: this.props.text,
      });
      this.props.onClick(e);
    }
  };

  onMoreClick = (e) => {
    if (!this.props.isMoreDisabled) {
      this.props.onMoreClick(e);
      this.setState((prevState) => ({isOpen: !prevState.isOpen}));
    }
  };

  renderMoreButtonComponent = () => (
    <div automation-id={this.props.automationIdSplitOpen} styleName={this.rightBtnStyle.join(' ')}>
      <i className="icon icn-arrow16-triangledown" />
    </div>
  );

  render() {
    const {
      text,
      colorSchema,
      height,
      width,
      className,
      isMainDisabled,
      isMoreDisabled,
      menuComponent,
      position,
      placement,
      automationIdButton,
    } = this.props;
    const {isOpen} = this.state;

    this.rootStyle = ['root', colorSchema, height, className];
    this.leftBtnStyle = ['left-btn', width, isMainDisabled ? 'disabled' : ''];
    this.rightBtnStyle = ['right-btn', height, isMoreDisabled ? 'disabled' : ''];

    return (
      <div styleName={this.rootStyle.join(' ')}>
        <div
          automation-id={automationIdButton}
          styleName={this.leftBtnStyle.join(' ')}
          role="presentation"
          onClick={this.onClick}
        >
          {text}
        </div>
        <div styleName="divider" />
        <FormDdl
          popoverContainerClassName="split-button-popover-container"
          position={position}
          placement={placement}
          popoverComponent={menuComponent}
          buttonComponent={this.renderMoreButtonComponent()}
          onToggle={this.onMoreClick}
          isOpen={isOpen}
          automationId={`${automationIdButton}MoreOptions`}
        />
      </div>
    );
  }
}
