// @flow
import React, {Fragment, PureComponent} from 'react';
import {connect} from 'react-redux';
import {fetchTrainTrackerData} from 'admin.trainTracker/store/actions';
import * as selectors from 'admin.trainTracker/store/selectors';
import Loader from 'common/components/Loader';
import TitleHeaderTableOrder from 'common/componentsV2/table/TitleHeaderTableOrder';
// eslint-disable-next-line import/no-cycle
import TableRow from './TableRow';
import './Table.module.scss';

export const ENVS = [
  {
    key: 'master',
    text: 'Master',
  },
  {
    key: 'monitoring',
    text: 'Monitoring',
  },
  {
    key: 'poc',
    text: 'POC',
  },
  {
    key: 'frankfurt',
    text: 'Frankfurt',
  },
  {
    key: 'mumbai',
    text: 'Mumbai',
  },
  {
    key: 'sydney',
    text: 'Sydney',
  },
  {
    key: 'us',
    text: 'US',
  },
];

const NOOP = () => {};

type PropTypes = {
  // connect
  isLoading: boolean,
  data: Array<Object>,

  fetchTrainTrackerData: Function,
};

@connect(
  (state) => ({
    isLoading: selectors.getFetchedTrainTrackerIsLoading(state),
    data: selectors.getFetchedTrainTrackerDataProcessed(state),
  }),
  {
    fetchTrainTrackerData,
  },
)
export default class Table extends PureComponent {
  props: PropTypes;

  state = {};

  componentDidMount() {
    const {fetchTrainTrackerData: fetchData} = this.props;
    fetchData();
  }

  render() {
    const {isLoading, data} = this.props;
    data.toString();
    if (isLoading) {
      return <Loader />;
    }

    return (
      <Fragment>
        <div styleName="table-header">
          <div styleName="col-header col-pr">
            <TitleHeaderTableOrder title="Pull Request" columnName="Pull Request" onChange={NOOP} />
          </div>
          <div styleName="col-header col-date">
            <TitleHeaderTableOrder title="Date" columnName="Date" onChange={NOOP} />
          </div>
          <div styleName="col-header col-author">
            <TitleHeaderTableOrder title="Author" columnName="Author" onChange={NOOP} />
          </div>
          {ENVS.map((env) => (
            <div key={env.key} styleName="col-header col-env">
              <TitleHeaderTableOrder title={env.text} columnName={env.text} onChange={NOOP} />
            </div>
          ))}
        </div>

        {data.map((pr) => (
          <TableRow key={pr.id} pr={pr} />
        ))}
      </Fragment>
    );
  }
}
