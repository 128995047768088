// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import EstimationDisplay from 'common/componentsV2/EstimationDisplay';
import * as selector from '../../../store/selectors';
import {calculateEstimatedRate} from '../../../store/actions';

type PropTypes = {
  calculateEstimatedRate: Function,
  isLoading: boolean,
  estimationText: String,
  timeAmount: String,
};
@connect(
  (state) => ({
    estimationText: selector.getEstimationCurrentOpenAlert(state),
    isLoading: selector.getExecuteEstimationIsLoading(state),
    timeAmount: selector.getOpenAlertEstimationTimeAmount(state),
  }),
  {
    calculateEstimatedRate,
  },
)
export default class Estimative extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    if (!this.props.estimationText) {
      this.props.calculateEstimatedRate();
    }
  }

  render() {
    const {isLoading, estimationText, timeAmount} = this.props;
    return (
      <EstimationDisplay
        isLoading={isLoading}
        estimationText={estimationText}
        errorMessage={null}
        disabled={null}
        tooltip={timeAmount}
      />
    );
  }
}
