// @flow
import React, {useEffect, useMemo, useState} from 'react';
import {get} from 'lodash';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import {optionsIcons} from 'dashboards/services/dashboardService';
import {COLORS_PICKER} from 'common/componentsV2/ColorPicker';
import * as selectors from 'dashboards/store/selectors';
import * as actions from 'dashboards/store/actions';
import {getProfileId} from 'profile/store/selectors';
import Highlighter from 'react-highlight-words';

type PropTypes = {
  dashboardData: Object,
  history: Object,
  isFullSize: boolean,
};

const HEADER_OPTIONS = {
  FAVORITE: {
    label: 'Favorites',
    value: 'favorites',
    type: 'HEADER',
  },
  RECENT: {
    label: 'Recent',
    value: 'recent',
    type: 'HEADER',
  },
  ALL_DASHBOARDS: {
    label: 'All Dashboards',
    value: 'all dashboards',
    type: 'HEADER',
  },
};

const pushToResult = (arr, result, length) => {
  arr.forEach((item, index) => {
    if (index <= length) {
      result.push({
        value: item._id,
        label: item.name,
        icon: item.icon,
        color: item.color,
      });
    }
  });
};

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: 6,
    marginRight: 8,
    fill: theme.palette.white[500],
    backgroundColor: (data) =>
      theme.palette[COLORS_PICKER[data.color || 'gray'].color][COLORS_PICKER[data.color || 'gray'].contrast],
  },
  optionHeader: {
    padding: '8px 8px 8px 12px',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    marginLeft: 4,
    marginRight: 4,
    borderRadius: 6,
    '&:hover': {
      backgroundColor: theme.palette.blue[100],
    },
  },
  arrowWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 35,
    height: 35,
    backgroundColor: theme.palette.gray[300],
    borderRadius: '50%',
    color: theme.palette.gray[500],
    marginRight: 8,
    fontSize: 18,
  },
  buttonIconWrapper: {
    width: '16px',
    height: '16px',
  },
  dashboardName: {
    color: theme.palette.gray[600],
    fontWeight: 400,
    fontSize: '30px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

const OptionComponent = ({data, searchStr}: {data: Object, searchStr: string}) => {
  const classes = useStyles(data);
  if (data.type) {
    return (
      <div className={classes.optionHeader}>
        <div className="text14med-gray400 lineHeight_14">{data.label}</div>
      </div>
    );
  }

  const IconComponent = get(optionsIcons.find((option) => data.icon === option.value), 'icon') || optionsIcons[0].icon;

  return (
    <div className={classes.option}>
      <div className={classes.iconWrapper}>
        <div className={classes.buttonIconWrapper}>
          <IconComponent />
        </div>
      </div>
      <div className="text16reg lineHeight_16">
        <Highlighter autoEscape searchWords={[searchStr]} textToHighlight={data.label} />
      </div>
    </div>
  );
};

const DashboardTitle = ({dashboardData, history, isFullSize}: PropTypes) => {
  const dispatch = useDispatch();
  const classes = useStyles(dashboardData);
  const dashboards = useSelector(selectors.getDataDashboardsV2);
  const userId = useSelector(getProfileId);
  const sortingDashboards = useSelector(selectors.getSortingDashboards);
  const [options, setOptions] = useState([]);
  const [searchStr, setSearchStr] = useState('');

  useEffect(() => {
    if (!dashboards.length && !isFullSize) {
      dispatch(actions.fetchDashboards());
    }
  }, []);

  const {lastView} = sortingDashboards;
  const lastViewIds = useMemo(() => (lastView || []).map((l) => l.id), [lastView]);
  const favoriteList = useMemo(
    () =>
      dashboards.filter((d) => d.star.includes(userId) && d._id !== dashboardData._id && !lastViewIds.includes(d._id)),
    [dashboards, dashboardData, userId, lastViewIds],
  );
  const restList = useMemo(
    () =>
      dashboards.filter((d) => !d.star.includes(userId) && d._id !== dashboardData._id && !lastViewIds.includes(d._id)),
    [dashboards, dashboardData, userId],
  );
  const recentList = useMemo(
    () =>
      lastViewIds
        .map((id) => dashboards.find((d) => d._id === id && d._id !== dashboardData._id))
        .filter((f) => f !== undefined),
    [lastViewIds, dashboards, dashboardData],
  );

  const previewOptions = useMemo(() => {
    const favoriteListLength = favoriteList.length;
    const recentListLength = recentList.length;
    const result = [];
    if (favoriteListLength) {
      result.push(HEADER_OPTIONS.FAVORITE);
      pushToResult(favoriteList, result, 2);
    }
    if (recentListLength) {
      result.push(HEADER_OPTIONS.RECENT);
      pushToResult(recentList, result, 2);
    }
    const computedLengthAllDashboards = 6 - result.length;
    if (!!restList.length && computedLengthAllDashboards > 0) {
      result.push(HEADER_OPTIONS.ALL_DASHBOARDS);
      pushToResult(restList, result, computedLengthAllDashboards);
    }
    return result;
  }, [dashboards, lastView, favoriteList, restList, recentList]);

  useEffect(() => {
    setOptions(previewOptions);
  }, [dashboards, lastView]);

  const ButtonIcon =
    get(optionsIcons.find((option) => dashboardData.icon === option.value), 'icon') || optionsIcons[0].icon;

  const buttonComponent = (
    <div className="display_flex alignItems_center">
      <div className={classes.iconWrapper}>
        <div className={classes.buttonIconWrapper}>
          <ButtonIcon />
        </div>
      </div>
      <div className={classes.dashboardName}>{dashboardData.name}</div>
      <div className="ml_0-5 mt_1 font text18reg icn-arrow16-chevrondown" />
    </div>
  );

  const onChange = ({value, type}) => {
    if (type !== 'HEADER') {
      history.push(`/dashboards/${value}`);
    }
  };

  const onSearchChange = (val) => {
    setSearchStr(val);
    const cleanVal = val.toLocaleLowerCase();
    const result = [];
    const filteredFavoriteList = favoriteList.filter((f) => f.name.toLocaleLowerCase().includes(cleanVal));
    const rest = restList.filter((r) => r.name.toLocaleLowerCase().includes(cleanVal));
    const recent = recentList.filter((r) => r.name.toLocaleLowerCase().includes(cleanVal));

    if (filteredFavoriteList.length) {
      result.push(HEADER_OPTIONS.FAVORITE);
      pushToResult(filteredFavoriteList, result, filteredFavoriteList.length);
    }

    if (recent.length) {
      result.push(HEADER_OPTIONS.RECENT);
      pushToResult(recent, result, recent.length);
    }

    if (rest.length) {
      result.push(HEADER_OPTIONS.ALL_DASHBOARDS);
      pushToResult(rest, result, rest.length);
    }
    setOptions(val ? result : previewOptions);
  };

  if (isFullSize) {
    return (
      <div
        role="button"
        className="display_flex alignItems_center"
        onClick={() => history.push(`/dashboards/${dashboardData._id}${history.location.search}`)}
      >
        <div className={classes.arrowWrapper}>
          <i className="icon icn-arrow16-arrowleft" />
        </div>
        <div className={classes.title30}>{dashboardData.name}</div>
      </div>
    );
  }

  return (
    <FormDdlSelect
      options={options}
      selected={{}}
      optionComponent={<OptionComponent searchStr={searchStr} />}
      button={buttonComponent}
      onChange={onChange}
      isUseSearch
      onParentSearchChange={onSearchChange}
      onParentSearchValue={searchStr}
      onHoverOpenMenu
      heightEmptyDDL={344}
    />
  );
};

export default DashboardTitle;
