// @flow
import React from 'react';
import './SeverityMarker.module.scss';
import Tooltip, {TYPES} from './Tooltip';

type PropTypes = {
  severity: string,
};

export default class SeverityMarker extends React.PureComponent {
  props: PropTypes;

  render() {
    const {severity} = this.props;
    let notification = '';
    switch (severity) {
      case 'critical':
        notification = 'Critical Severity';
        break;
      case 'high':
        notification = 'High Severity';
        break;
      case 'medium':
        notification = 'Medium Severity';
        break;
      case 'low':
        notification = 'Low Severity';
        break;
      case 'info':
        notification = 'Info';
        break;
      default:
    }

    return (
      <Tooltip content={notification} type={TYPES.SMALL}>
        <div styleName={['color-marker', severity].join(' ')} />
      </Tooltip>
    );
  }
}
