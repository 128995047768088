// @flow
import React from 'react';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import Button, {COLORS as ButtonColors} from 'common/componentsV2/Button';
import AsyncButton from 'common/componentsV2/AsyncButton';
import Spinner, {SIZES as SIZE_SPINNER} from 'common/componentsV2/Spinner';
import './ConfModal.module.scss';

type PropTypes = {
  isOpen: boolean,
  onClose: Function,
  onConfirm: Function,
  title?: String,
  isDanger?: boolean,
  size?: string,
  isStatic?: boolean,
  isCancelable?: boolean,
  isConfirmDisabled?: boolean,
  children?: any,
  id?: string,
  classStyle?: string,
  cancelText?: string,
  confirmText?: string,
  isAnimation?: boolean,
  isLoading?: boolean,
  isAsync: boolean,
  isCloseButtonHidden?: boolean,
  additionalAction?: Function,
};

export default class ConfModal extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    size: SIZES.SMALL,
    isStatic: true,
    isCancelable: true,
    isConfirmDisabled: false,
    children: null,
    id: null,
    isDanger: false,
    classStyle: '',
    title: '',
    cancelText: 'Cancel',
    confirmText: 'OK',
    isAnimation: true,
    isLoading: false,
    isCloseButtonHidden: true,
    additionalAction: null,
  };

  render() {
    const {
      isAsync,
      isOpen,
      onClose,
      size,
      isCancelable,
      isStatic,
      children,
      id,
      classStyle,
      cancelText,
      confirmText,
      onConfirm,
      isDanger,
      isConfirmDisabled,
      title,
      isAnimation,
      isLoading,
      isCloseButtonHidden,
      additionalAction,
    } = this.props;

    const modalClassStyle = ['conf-modal', classStyle].join(' ');

    return (
      <Modal
        enforceFocus
        isCloseButtonHidden={isCloseButtonHidden}
        classStyle={modalClassStyle}
        id={id}
        isStatic={isStatic}
        isOpen={isOpen}
        onClose={onClose}
        size={size}
        isAnimation={isAnimation}
      >
        <div styleName="root">
          {title && <div styleName="title">{title}</div>}
          {additionalAction && isLoading ? (
            <div className="alignSelf_center">
              <Spinner color="gray.500" size={SIZE_SPINNER.MEDIUM_50} />
            </div>
          ) : (
            <React.Fragment>
              <div styleName="body">{children && children}</div>
              <div styleName="footer">
                {isCancelable && (
                  <span styleName="cancel-btn">
                    <Button
                      text={cancelText}
                      colorSchema={ButtonColors.GRAY_200}
                      onClick={additionalAction || onClose}
                    />
                  </span>
                )}
                {isAsync ? (
                  <AsyncButton isLoading={isLoading} onClick={onConfirm}>
                    {confirmText}
                  </AsyncButton>
                ) : (
                  <Button
                    text={confirmText}
                    colorSchema={isDanger ? ButtonColors.RED_500 : ButtonColors.BLUE_500}
                    onClick={onConfirm}
                    isDisabled={isConfirmDisabled}
                  />
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </Modal>
    );
  }
}
