// @flow

import React from 'react';

import './ErrorsSummaryBlock.module.scss';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';

type PropTypes = {
  type: String,
  count: Number,
  isLoading: Boolean,
};

export default class ErrorsSummaryBlock extends React.PureComponent {
  props: PropTypes;

  render() {
    const {type, count, isLoading} = this.props;
    let title;
    let icon;
    switch (type) {
      case 'stream':
        title = 'Stream';
        icon = 'icn-nav24-data';
        break;
      case 'alert':
        title = 'Alert';
        icon = 'icn-nav24-alertconsole';
        break;
      case 'composite':
        title = 'Composite';
        icon = 'icn-icon-function';
        break;
      default:
        title = '';
        icon = '';
        break;
    }
    return (
      <div styleName="container">
        <span styleName="icon" className={`icon ${icon}`} />
        <div>
          {isLoading ? (
            <Spinner color="#3d4c59" size={SIZES.BIG_60} />
          ) : (
            <h4>
              <b>{count}</b> {title} {count > 1 ? 'Errors' : 'Error'}
            </h4>
          )}
        </div>
      </div>
    );
  }
}
