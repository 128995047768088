// @flow
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Observable} from 'rxjs';
import {isMobile} from 'react-device-detect';
import {get} from 'lodash';
import {signOut} from 'profile/store/actions';
import * as selectors from 'profile/store/selectors';
import * as appSelectors from 'app/store/selectors';
import {saveUrlWithParams} from 'app/store/actions';
import {fetchAlertConfigurations} from 'alerts.management/store/actions';
import {ReactComponent as Logo} from 'app/images/logo-anodot-new.svg';

import NavBarUser from './NavBarUser';
import NavBarMenuParent from './NavBarMenuParent';
import NavBarMenuItem from './NavBarMenuItem';
import NavBarTopology from './NavBarTopology';

import ImpersonationMenuItem from './ImpersonateMenuItem';
import './AppNavBar.module.scss';

const getStrippedUrl = () => window.location.hash.split('?')[0];

const NavBarSeparator = () => <div styleName="separator" />;

const AppNavBar = () => {
  const dispatch = useDispatch();

  const handleClick = (item) => () => {
    if (item === 'logout') {
      dispatch(signOut());
    } else if (item.angularService) {
      if (window.angular) {
        const angularService = window.angular
          .element(document.body)
          .injector()
          .get(item.angularService.serviceName);

        angularService[item.angularService.serviceMethod]();
      }
    }
  };

  const [selectedItem, setSelectedItem] = useState(getStrippedUrl());
  const history = useHistory();
  let underSeparator = false;

  const appNavBarFilteredItems = useSelector((state) => appSelectors.getAppNavBarFilteredItems(state));
  const me = useSelector((state) => selectors.getUserProfile(state));
  const getRoleName = useSelector((state) => selectors.getRoleName(state));
  const isCustomerAdmin = useSelector((state) => selectors.isCustomerAdmin(state));
  const isCustomerUser = useSelector((state) => selectors.isCustomerUser(state));
  const isAnodotAdmin = useSelector(selectors.isAnodotAdmin);

  const isImpersonating = get(me, 'impersonatorId');
  const idUser = get(me, '_id', null);

  useEffect(() => {
    const windowLocationChangeSubscription = Observable.fromEvent(window, 'hashchange').subscribe((event) => {
      setSelectedItem(getStrippedUrl());
      dispatch(saveUrlWithParams({oldURL: event.oldURL, newURL: event.newURL}));
    });
    return () => {
      windowLocationChangeSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (isCustomerAdmin || isCustomerUser) {
      dispatch(fetchAlertConfigurations());
    }
  }, [isCustomerAdmin, isCustomerUser, idUser]);

  const isSelectedItem = (item) => {
    if (!item.items) {
      if (item.linksExact) {
        return item.links.find((l) => l === selectedItem);
      }
      return !!item.links.find((l) => selectedItem.indexOf(l) !== -1);
    }
    return !!item.items.find((i) => i.links && i.links.find((l) => selectedItem.indexOf(l) !== -1));
  };

  const getNavBarItem = (item, key) => {
    if (!isMobile || !item.isHideOnMobile) {
      switch (item.type) {
        case 'menuItem':
          return (
            <NavBarMenuItem
              key={key}
              item={item}
              isSelectedItem={isSelectedItem(item)}
              isUnderSeparator={underSeparator}
            />
          );

        case 'menuItemTopology':
          return <NavBarTopology key={key} item={item} isSelectedItem={isSelectedItem(item)} />;

        case 'menuBottomStart':
          return <div className="menuBottom" />;

        case 'menuSeparator':
          if (!underSeparator) {
            underSeparator = true;
          }
          return <NavBarSeparator key={key} />;

        case 'menuParent':
          return (
            <NavBarMenuParent
              key={key}
              item={item}
              handleClick={handleClick}
              isSelectedItem={isSelectedItem(item)}
              selectedItem={selectedItem}
              isUnderSeparator={underSeparator}
            />
          );

        case 'menuUser':
          return (
            <NavBarUser
              key={key}
              me={me}
              isSelectedItem={isSelectedItem(item)}
              userRole={getRoleName}
              handleClick={handleClick}
            />
          );
        default:
          return null;
      }
    }
    return null;
  };

  if (!me._id || me.roles.includes('anonymous-user')) {
    return '';
  }

  return (
    <div
      styleName="nav-bar"
      automation-id="drawer_saas"
      className="idle-external-click"
      style={{minHeight: appNavBarFilteredItems.length * 60}}
    >
      <div
        styleName="main-logo"
        onClick={() => history.push(isAnodotAdmin ? '/admin/users-groups' : '/alerts-console')}
        role="presentation"
      >
        <Logo width={34} height={34} />
      </div>

      <div styleName="nav-bar-top">
        {appNavBarFilteredItems.filter((item) => item.position === 'top').map((item, key) => getNavBarItem(item, key))}
      </div>

      <div styleName="nav-bar-bottom">
        {appNavBarFilteredItems
          .filter((item) => item.position === 'bottom')
          .map((item, key) => getNavBarItem(item, key))}

        {isImpersonating && <ImpersonationMenuItem me={me} />}
      </div>
    </div>
  );
};

export default AppNavBar;
