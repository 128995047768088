import React from 'react';
import {useField} from 'react-final-form';

const Authentication = ({children}: {children: React.Node}) => {
  const {
    input: {value, onChange},
  } = useField('authentication');
  return (
    <React.Fragment>
      <div
        role="button"
        className="fontWeight_500 display_flex alignItems_center mb_1"
        onClick={() => onChange(!value)}
      >
        {value ? (
          <span className="icon icn-arrow16-chevrondown mr_1" />
        ) : (
          <span className="icon icn-arrow16-chevronright mr_1" />
        )}
        <div className="mr_1">Authentication</div>
        <div className="fontSize_14 color_gray-400">Optional</div>
      </div>
      {value && children}
    </React.Fragment>
  );
};

export default Authentication;
