// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {palette} from 'app/styles/theme';
import Highlighter from 'react-highlight-words';
import SmartTooltip, {TYPES} from 'common/componentsV2/Tooltip';
import IssueDate from 'topologyGeneral/components/sidePanel/issuesSubPanel/IssueDate';
import {ReactComponent as StatusIcon} from 'topologyGeneral/images/open_alert.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    backgroundColor: palette.white['500'],
    border: `1px solid ${palette.gray['200']}`,
    borderRadius: '8px',
    padding: '8px',
    marginBottom: '8px',
    height: '80px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.blue['50'],
      borderColor: palette.blue['300'],
    },
  },
  dataName: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: '-1px',
    lineHeight: 1.2,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  started: {
    fontSize: 12,
    fontWeight: 400,
    color: palette.gray['400'],
    marginBottom: '2px',
    display: 'flex',
    alignItems: 'center',
  },
  date: {
    fontSize: 14,
    fontWeight: 400,
    color: palette.gray['500'],
    paddingLeft: '3px',
    paddingRight: '10px',
    lineHeight: 1,
  },
}));

const useChipStyles = makeStyles(() => ({
  chip: {
    display: 'flex',
    alignItems: 'center',
    height: '16px',
    borderRadius: '2px',
    paddingRight: '4px',
    paddingLeft: '4px',
    backgroundColor: palette.gray['200'],
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '10px',
    marginRight: '4px',
  },
  static: {
    color: palette.red['500'],
  },
  noData: {
    color: palette.orange['500'],
  },
}));

const IssueTypeChip = ({item}: PropTypes) => {
  const classes = useChipStyles();
  let chipElement = null;

  if (item.isStatic) {
    chipElement = <Box className={`${classes.chip} ${classes.static}`}>Static</Box>;
  } else if (item.isNoData) {
    chipElement = <Box className={`${classes.chip} ${classes.noData}`}>No Data</Box>;
  }

  return chipElement;
};

IssueTypeChip.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

const IssueItem = ({item, timeZoneName, textHighlight, onClick}: PropTypes) => {
  const classes = useStyles();

  const locClick = () => {
    if (onClick) {
      onClick(item);
    }
  };

  return (
    <Box className={classes.root} onClick={locClick}>
      <Box display="flex" flexShrink={0} width={16} mr={1}>
        {item.isOpen && (
          <SmartTooltip placement="top" content="Open Issue" delay={400}>
            <StatusIcon />
          </SmartTooltip>
        )}
      </Box>
      <Box display="flex" flexGrow={1} flexDirection="column" pr={1} justifyContent="space-between">
        <SmartTooltip placement="top" content={item.name} delay={400} type={TYPES.LARGE}>
          <div className={classes.dataName}>
            <Highlighter autoEscape searchWords={[textHighlight]} textToHighlight={item.name} />
          </div>
        </SmartTooltip>
        <Box className={classes.started}>
          Started
          <Box className={classes.date}>
            <IssueDate startDate={item.startTime} timeZone={timeZoneName} />
          </Box>
          <IssueTypeChip item={item} />
        </Box>
      </Box>
      <Box display="flex" flexShrink={0} flexDirection="column" alignItems="center" justifyContent="center" pr={1}>
        <Box fontSize={16} fontWeight={400} color="gray.500" lineHeight="24px">
          {item.total}
        </Box>
        <Box fontSize={12} fontWeight={500} color="gray.400" lineHeight="16px">
          {item.totalText}
        </Box>
      </Box>
    </Box>
  );
};

IssueItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  onClick: PropTypes.func,
  timeZoneName: PropTypes.string,
  textHighlight: PropTypes.string,
};

IssueItem.defaultProps = {
  onClick: null,
  timeZoneName: '',
  textHighlight: '',
};

export default IssueItem;
