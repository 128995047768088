// @flow

import React from 'react';
import {Grid} from '@material-ui/core';
import RadioButton from './RadioButton';

type PropTypes = {
  options: Array,
  value: String,
  onChange: Function,
  direction: string,
};
const RadioButtonsList = ({options, value, onChange, direction}: PropTypes) => (
  <Grid container spacing={1} direction={direction}>
    {options.map((opt) => (
      <RadioButton
        key={opt.label}
        checked={opt.value === value}
        label={opt.label}
        onClick={() => onChange(opt)}
        isDisabled={opt.disabled}
      />
    ))}
  </Grid>
);

export default RadioButtonsList;
