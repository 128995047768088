// @flow
import React from 'react';
import './FormDdlSearchBar.module.scss';

type PropTypes = {
  onChange: Function,
  value: string,
  isAutofocus: boolean,
};

export default class FormDdlSearchBar extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    if (this.input && this.props.isAutofocus) {
      setTimeout(() => {
        if (this.input) {
          this.input.focus();
        }
      }, 500);
    }
  }

  onChange = (e) => {
    const {onChange} = this.props;
    onChange(e.target.value);
  };

  render() {
    return (
      <div styleName="root">
        <i className="icon icn-general16-search" />
        <input
          ref={(input) => {
            this.input = input;
          }}
          onChange={this.onChange}
          value={this.props.value}
          placeholder="Search"
          autoFocus={this.props.isAutofocus}
        />
      </div>
    );
  }
}
