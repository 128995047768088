// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as selectors from 'metrics/store/selectors';
import {resolutionTypes} from 'metrics/services/metricsService';
import {reject} from 'lodash';
import ConditionContainer from './ConditionContainer';
import './TimeScaleCondition.module.scss';

type PropTypes = {
  rollup: String,
  onChange: Function,
  resolutions: Object,
  useContainer: Boolean,
  omitRollups: Array,
};

const EMPTY_ARRAY = [];

class TimeScaleCondition extends PureComponent {
  props: PropTypes;

  render() {
    const {resolutions, omitRollups, rollup, onChange, useContainer} = this.props;
    const resolutionTypesArr = reject(
      Object.values(resolutions),
      (r) => r.value === resolutionTypes.auto.value || !r.enabled || (omitRollups || EMPTY_ARRAY).includes(r.value),
    ).reverse();
    const body = (
      <div styleName="buttons-container" automation-id="alertSettingTimeScaleGroup">
        {resolutionTypesArr.map((s) => (
          <button
            type="button"
            key={s.value}
            automation-id={`alertSettingTimeScale${s.value}Button`}
            className={`btn btn-raised btn-outline btn-outline-large ${
              s.value === rollup ? 'btn-outline-light' : 'btn-outline-dark'
            }`}
            onClick={() => onChange(s.value)}
          >
            <div styleName="text-num">{s.shortText.num}</div>
            <div styleName="text-label">{s.shortText.label}</div>
          </button>
        ))}
      </div>
    );

    return !useContainer ? (
      body
    ) : (
      <ConditionContainer title="Time Scale">
        <div styleName="empty-container" />
        {body}
      </ConditionContainer>
    );
  }
}

export default connect(
  (state) => ({
    resolutions: selectors.getMetricResolutions(state),
  }),
  {},
)(TimeScaleCondition);
