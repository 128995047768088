// @flow
import React, {PureComponent} from 'react';
import Highlighter from 'react-highlight-words';
import {getFormattedMDH} from 'common/utils/dateService';
import './UserEventsTable.module.scss';

type PropTypes = {
  event: Object,
  searchString: string,
  timeZoneName: string,
};

export default class UserEventsRowHeader extends PureComponent {
  props: PropTypes;

  render = () => {
    const {event, searchString, timeZoneName} = this.props;

    return (
      <div styleName="table-row-header" role="none">
        <div styleName="col-title">
          <div className="ellipsis">
            <Highlighter autoEscape searchWords={[searchString]} textToHighlight={event.title} />
          </div>
        </div>
        <div styleName="col-category">{event.category}</div>
        <div styleName="col-startDate">{getFormattedMDH(event.startDate, timeZoneName)}</div>
        <div styleName="col-endDate">{getFormattedMDH(event.endDate, timeZoneName)}</div>
      </div>
    );
  };
}
