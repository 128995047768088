// @flow
import React, {useCallback} from 'react';
import {palette} from 'app/styles/theme';
import {makeStyles} from '@material-ui/core';

type PropTypes = {
  title: string,
  columnName: string,
  onChange: Function,
  isActive: Boolean,
  selectedDirection: string,
  automationId: string,
};

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&:hover': {
      '& $arrow': {
        color: palette.blue['500'],
      },
      '& $text': {
        color: `${palette.blue['500']} !important`,
      },
    },
  },
  text: {
    fontWeight: '500',
    fontSize: 12,
    cursor: 'pointer',
    marginBottom: '4px',
    color: palette.gray['400'],
  },
  arrow: {
    color: palette.gray['500'],
    cursor: 'pointer',
  },
}));

const TitleHeaderTableOrder = ({columnName, title, isActive, onChange, selectedDirection, automationId}: PropTypes) => {
  const classes = useStyles();

  const onSort = useCallback(() => {
    onChange(columnName);
  }, [selectedDirection]);

  return (
    <div
      role="button"
      onClick={onSort}
      onKeyPress={onSort}
      tabIndex={0}
      className={classes.wrapper}
      automation-id={automationId || `TitleHeaderTableOrder-${title}`}
    >
      <div className={classes.text} style={{color: isActive ? palette.gray['500'] : palette.gray['400']}}>
        {title}
      </div>
      {isActive && (
        <div
          className={
            selectedDirection === 'desc'
              ? `${classes.arrow} icon icn-arrow16-arrowdown`
              : `${classes.arrow} icon icn-arrow16-arrowup`
          }
        />
      )}
    </div>
  );
};

export default TitleHeaderTableOrder;
