// @flow
import React from 'react';
import './WarningSign.module.scss';
import Tooltip, {TYPES} from './Tooltip';

type PropTypes = {
  warningLevel: string,
  notification: string,
};

export default class WarningSign extends React.PureComponent {
  props: PropTypes;

  render() {
    const {warningLevel, notification} = this.props;
    const t = warningLevel; // HACK: to avoid the styleName compiler problem
    return (
      <Tooltip content={notification} type={TYPES.SMALL} placement="top">
        <div styleName={t} className="icon icn-general16-warning" />
      </Tooltip>
    );
  }
}
