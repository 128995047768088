import {resolutionTypes, durationScales} from 'metrics/services/metricsService';

const createVolumePointsArray = (maxNumber) => {
  const arr = [];
  for (let i = 1; i <= maxNumber; i++) {
    arr.push({value: i, label: `${i}`});
  }
  return arr;
};

export const VOLUME_DURATION_OPTIONS = {
  long: {
    value: 'long',
    label: 'Hour',
    ms: 1000 * 60 * 60,
    numLastPoints: createVolumePointsArray(23),
  },
  longlong: {
    value: 'longlong',
    label: 'Day',
    ms: 1000 * 60 * 60 * 24,
    numLastPoints: createVolumePointsArray(7),
  },
  weekly: {
    value: 'weekly',
    label: 'Week',
    ms: 1000 * 60 * 60 * 24 * 7,
    numLastPoints: createVolumePointsArray(3),
  },
};

export const VOLUME_BOUND_OPTIONS = {
  lower: {
    value: 'LOWER',
    label: 'Lower',
  },
  upper: {
    value: 'UPPER',
    label: 'Higher',
  },
};

export const DURATION_OPTIONS = {
  minutes: [
    {
      label: '1',
      value: 1,
      seconds: durationScales.minutes.seconds,
      rollup: 'short',
    },
    {
      label: '2',
      value: 2,
      seconds: durationScales.minutes.seconds * 2,
      rollup: 'short',
    },
    {
      label: '5',
      value: 5,
      seconds: durationScales.minutes.seconds * 5,
      rollup: 'medium',
    },
    {
      label: '10',
      value: 10,
      seconds: durationScales.minutes.seconds * 10,
      rollup: 'medium',
    },
    {
      label: '20',
      value: 20,
      seconds: durationScales.minutes.seconds * 20,
      rollup: 'medium',
    },
  ],
  hours: [
    {
      label: '1',
      value: 1,
      seconds: durationScales.hours.seconds,
      rollup: 'long',
    },
    {
      label: '2',
      value: 2,
      seconds: durationScales.hours.seconds * 2,
      rollup: 'long',
    },
    {
      label: '3',
      value: 3,
      seconds: durationScales.hours.seconds * 3,
      rollup: 'long',
    },
    {
      label: '4',
      value: 4,
      seconds: durationScales.hours.seconds * 4,
      rollup: 'long',
    },
    {
      label: '5',
      value: 5,
      seconds: durationScales.hours.seconds * 5,
      rollup: 'long',
    },
  ],
  days: [
    {
      label: '1',
      value: 1,
      seconds: durationScales.days.seconds,
      rollup: 'longlong',
    },
    {
      label: '2',
      value: 2,
      seconds: durationScales.days.seconds * 2,
      rollup: 'longlong',
    },
    {
      label: '3',
      value: 3,
      seconds: durationScales.days.seconds * 3,
      rollup: 'longlong',
    },
    {
      label: '7',
      value: 7,
      seconds: durationScales.days.seconds * 7,
      rollup: 'longlong',
    },
    {
      label: '14',
      value: 14,
      seconds: durationScales.days.seconds * 14,
      rollup: 'longlong',
    },
  ],
  weeks: [
    {
      label: '1',
      value: 1,
      seconds: durationScales.weeks.seconds,
      rollup: 'weekly',
    },
    {
      label: '2',
      value: 2,
      seconds: durationScales.weeks.seconds * 2,
      rollup: 'weekly',
    },
    {
      label: '3',
      value: 3,
      seconds: durationScales.weeks.seconds * 3,
      rollup: 'weekly',
    },
    {
      label: '4',
      value: 4,
      seconds: durationScales.weeks.seconds * 4,
      rollup: 'weekly',
    },
    {
      label: '5',
      value: 5,
      seconds: durationScales.weeks.seconds * 5,
      rollup: 'weekly',
    },
  ],
};

export const getRollupByTimeScaleAndDurationVal = (timeScale, durationVal) => {
  switch (timeScale) {
    case durationScales.minutes.value:
      return durationVal < 5 ? resolutionTypes.short.value : resolutionTypes.medium.value;
    case durationScales.hours.value:
      return resolutionTypes.long.value;
    case durationScales.days.value:
      return resolutionTypes.longlong.value;
    case durationScales.weeks.value:
      return resolutionTypes.weekly.value;
    default:
      return resolutionTypes.medium.value;
  }
};

export const getTimeScaleFromRollup = (rollup) => {
  switch (rollup) {
    case resolutionTypes.short.value:
    case resolutionTypes.medium.value:
      return durationScales.minutes.value;
    case resolutionTypes.long.value:
      return durationScales.hours.value;
    case resolutionTypes.longlong.value:
      return durationScales.days.value;
    case resolutionTypes.weekly.value:
      return durationScales.weeks.value;
    default:
      return durationScales.minutes.value;
  }
};
