import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as commonSelectors from 'profile/store/selectors';
import * as api from '../../services/api';
import * as actions from '../actions';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';
import {error} from '../../../common/utils/notifications/notificationsService';

const isBcEnabledFilterCall = (action, getState) => !!commonSelectors.getOrganizationSettingsBC(getState()).enabled;

const fetchLookupTables = makeAsyncEpic(actions.fetchLookupTables, api.fetchLookupTables, isBcEnabledFilterCall);
const createLookupTable = makeAsyncEpic(actions.createLookupTable, api.createLookupTable);
const deleteLookupTable = makeAsyncEpic(actions.deleteLookupTable, api.deleteLookupTable);
const updateLookupTable = makeAsyncEpic(actions.updateLookupTable, api.updateLookupTable);
const fetchLookupTablePreview = makeAsyncEpic(actions.fetchLookupTablePreview, api.fetchLookupTablePreview);

const setDeleteLookupError = (action$) =>
  action$.ofType(actions.setDeleteLookupError.TYPE).flatMap((/* action */) => [error('File delete failed')]);

const setDownloadLookupError = (action$) =>
  action$.ofType(actions.setDownloadLookupError.TYPE).flatMap((/* action */) => [error('File download failed')]);

const lookupTablesEpic = combineEpics(
  fetchLookupTables,
  createLookupTable,
  deleteLookupTable,
  updateLookupTable,
  fetchLookupTablePreview,
  setDeleteLookupError,
  setDownloadLookupError,
);

export default lookupTablesEpic;
