// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import * as filterSelector from 'alerts.management/store/filterSelector';

const EMPTY_ARRAY = [];

type PropTypes = {
  setQueryParams: Function,

  // connect
  tagsList?: Array,
  selectedTags: String,
};

@connect(
  (state) => ({
    tagsList: filterSelector.getTagListFilter(state),
    selectedTags: filterSelector.getSelectedFilterTag(state),
  }),
  {},
)
export default class TagsFilter extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    tagsList: EMPTY_ARRAY,
  };

  onChange = (items) => {
    const tagVal = items.length ? items.map((item) => item.name).join(',') : null;
    this.props.setQueryParams({tag: tagVal});
  };

  onClearAll = () => {
    this.props.setQueryParams({tag: null});
  };

  render() {
    const {tagsList, selectedTags} = this.props;
    const sortedTagsList = tagsList.sort((a, b) => b.count - a.count);
    return (
      <SelectAndt
        automationId="alertTagsFilter"
        type={TYPE_NEW_MULTI}
        theme={THEME_HIGHLIGHTED}
        onChange={this.onChange}
        options={sortedTagsList}
        value={selectedTags}
        isMulti
        placeholder="Labels"
        optionHeight={40}
        menuWidth={270}
        isClearable={false}
        closeMenuOnSelect={false}
        getOptionLabel={(val) => (val.val ? val.val : val.name)}
        getOptionValue={(val) => val.name}
        menuFooterComponent={
          <FormDdlActionBar
            isVisible
            isClearAllDisabled={!selectedTags || !selectedTags.length}
            onClearAll={this.onClearAll}
          />
        }
      />
    );
  }
}
