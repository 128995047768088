// @flow
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import {getUsersData} from 'admin.users/store/selectors';
import Highlighter from 'react-highlight-words';

const useStyles = makeStyles(({palette}) => ({
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: palette.gray[500],
    paddingTop: '16px',
    height: '48px',
  },
  col: {
    flex: '0 0 170px',
  },
  colSmall: {
    flex: '0 0 120px',
  },
  colTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    lineHeight: '16px',
    flexGrow: 1,
    marginLeft: '32px',
    fontWeight: 500,
  },
}));

const ChannelItemData = ({channel, searchQuery}: {channel: Object, searchQuery: string}) => {
  const classes = useStyles();
  const users = useSelector(getUsersData);

  const usersNamesMap = useMemo(
    () => users.reduce((acc, user) => ({...acc, [user.email]: `${user.firstName} ${user.lastName}`}), {}),
    [users],
  );
  const ownerEmail = JSON.parse(channel.tags).owner;

  return (
    <div className={classes.row} role="button">
      <div className={classes.colTitle}>
        <Highlighter autoEscape searchWords={[searchQuery]} textToHighlight={channel.name} />
      </div>
      <div className={classes.col}>{usersNamesMap[ownerEmail] || ownerEmail}</div>
      <div className={classes.col}>{channel.alerts.length}</div>
      <div className={classes.colSmall}>-</div>
    </div>
  );
};

export default ChannelItemData;
