/* eslint-disable react/no-multi-comp */
// @flow
import React, {PureComponent} from 'react';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import DiametricsDraggableItem from './DiametricsDraggableItem';
import './DiametricsDraggableList.module.scss';

const getItemIndexOrName = (item) => {
  if (item.index >= 0) {
    return `id_${item.index}`;
  }
  if (item.path) {
    return item.path;
  }
  return item.name;
};

type PropTypes = {
  items: Array,
  id: String,
  onRemoveItem: Function,
  shouldHideScroll: Boolean,
};

class Item extends PureComponent {
  props: {
    provided: Object,
    item: Object,
    onRemoveItem: Function,
  };

  render() {
    const {provided, item} = this.props;
    return (
      <DiametricsDraggableItem
        name={item.name || item.path}
        automation-id="streamEditorDiametricsItem"
        style={provided.draggableStyle}
        itemKey={getItemIndexOrName(item)}
        possibleTypes={item.possibleTypes || [item.type]}
        onRemove={this.props.onRemoveItem}
      />
    );
  }
}

class DiametricsDraggableList extends PureComponent {
  props: PropTypes;

  render() {
    return (
      <Droppable droppableId={this.props.id} type="DIAMETRIC">
        {(provided, snapshot) => (
          <div
            automation-id="streamEditorDiametricsList"
            ref={provided.innerRef}
            {...provided.droppableProps}
            styleName={[
              'list',
              snapshot.isDraggingOver ? 'list-dragging-over' : '',
              this.props.shouldHideScroll ? 'no-scroll' : '',
            ].join(' ')}
          >
            {this.props.items.map((c, index) => (
              <Draggable draggableId={getItemIndexOrName(c)} type="DIAMETRIC" key={getItemIndexOrName(c)} index={index}>
                {(provided2, snapshot2) => (
                  <div>
                    <div
                      ref={provided2.innerRef}
                      styleName={[
                        'list-item-container',
                        snapshot2.isDragging ? 'list-item-container-dragging' : '',
                      ].join(' ')}
                      {...provided2.draggableProps}
                      {...provided2.dragHandleProps}
                    >
                      <Item
                        provided={provided2}
                        snapshot={snapshot2}
                        item={c}
                        droppableId={this.props.id}
                        onRemoveItem={this.props.onRemoveItem}
                      />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}

export default DiametricsDraggableList;
