// @flow
import React from 'react';
import './PreviewGraph.module.scss';
import connect from 'react-redux/es/connect/connect';
import {setPreviewTimeRange} from 'metrics/store/actions';
import {CHART_HEIGHT} from '../../../alerts.console/services/alertsConsoleService';
import TimeSeriesChart from '../../../charts/timeSeries/components/TimeSeriesChart';
import * as selector from '../../../metrics/store/selectors';
import MetricName from '../../../metrics/components/metricName/MetricName';
import AnomalyAlertDelta from '../../../alerts.console/components/alertsList/alertContent/anomalyAlert/AnomalyAlertDelta';
import DateRangesDdl from '../../components/dateTime/DateRangesDdl';
import {THEME_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from '../ddl/selectAndt/SelectAndt';
import Spinner, {SIZES} from '../Spinner';

const TOOLTIP_OPTIONS = {
  showMetricName: false,
  showAnomalyData: true,
};

type PropTypes = {
  getMetricsForCompositeData: Object,
  setPreviewTimeRange: Function,
  previewTimeRange: Object,
  isLoading: boolean,
};

@connect(
  (state) => ({
    getMetricsForCompositeData: selector.getMetricsForCompositeData(state),
    previewTimeRange: selector.getPreviewTimeRange(state),
    isLoading: selector.getCompositeDataIsLoading(state),
  }),
  {
    setPreviewTimeRange,
  },
)
export default class PreviewGraphs extends React.PureComponent {
  props: PropTypes;

  onDateRangesChanged = (val) => {
    this.props.setPreviewTimeRange(val);
  };

  render() {
    return (
      <div styleName="graphs">
        <div>
          <DateRangesDdl
            title="Time Range"
            dateRange={this.props.previewTimeRange}
            onChange={this.onDateRangesChanged}
            type={TYPE_NEW_NO_SEARCH}
            theme={THEME_HIGHLIGHTED}
            className="alerts-dropdown-btn"
            allowedRangeTypes={['h1', 'h4', 'd1', 'd3', 'w1', 'm1', 'm3', 'm6', 'y1']}
          />
        </div>
        {this.props.isLoading ? (
          <div styleName="graphs">
            <div styleName="spinner">
              <Spinner color="#333" size={SIZES.XX_BIG_150} />
            </div>
          </div>
        ) : (
          this.props.getMetricsForCompositeData.map((metric) => (
            <div key={metric.id}>
              <div styleName="header">
                <div styleName="header-row">
                  <MetricName metric={metric} />
                </div>

                <div styleName="header-row">
                  <div styleName="row-left">
                    <AnomalyAlertDelta metric={metric} />
                  </div>
                </div>
              </div>
              <div styleName="chart">
                <TimeSeriesChart
                  id={`expression-builder-${metric.id}`}
                  eventsMeta={this.chartEventsMeta}
                  tooltip={TOOLTIP_OPTIONS}
                  height={CHART_HEIGHT}
                  bucketStartTimeEnabled
                  timeScale=""
                  timeZoneName="timezonename"
                  enableClientZoom
                />
              </div>
            </div>
          ))
        )}
      </div>
    );
  }
}
