import React, {useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {get, pickBy, identity} from 'lodash';
import {Form, Field} from 'react-final-form';
import createDecorator from 'final-form-focus';
import {Backdrop, Modal, makeStyles} from '@material-ui/core';
import Button, {COLORS} from 'common/componentsV2/Button';
import AsyncButton from 'common/componentsV2/AsyncButton';
import useTileAlertSync from 'dashboards/hooks/useTileAlertSync';
import useCompositeName from 'dashboards/hooks/useCompositeName';
import * as amSelectors from 'alerts.management/store/selectors';
import {fetchAlertConfigurations} from 'alerts.management/store/actions';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import Input from 'common/componentsV2/Input';
import {fetchComposites} from 'metrics/store/actions';
import * as metricsSelectors from 'metrics/store/selectors';

const focusOnErrors = createDecorator();

type PropTypes = {
  attachedAlertId: string,
  onConfirm: Function,
  onClose: Function,
  onDetachAlert: Function,
  dashboardId: string,
  onConfirm: Function,
  compositeObject: Object,
  isUpdateDashboardLoading: boolean,
};

const useStyles = makeStyles(({palette}) => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    backgroundColor: palette.white[500],
    borderRadius: 6,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
    padding: '24px',
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    opacity: 0.4,
    fontSize: 20,
    color: palette.gray[500],
  },
  error: {
    alignSelf: 'center',
    borderRadius: 6,
    position: 'relative',
    width: 287,
    backgroundColor: palette.red[500],
    padding: '8px 12px',
    color: palette.white[500],
    fontWeight: 500,
    fontSize: 16,
  },
  compositeName: {
    height: 36,
    flexGrow: 1,
    marginRight: 8,
  },
}));

// eslint-disable-next-line max-len
const AlertUpdateConfirmationModal = ({
  attachedAlertId,
  onConfirm,
  onClose,
  onDetachAlert,
  compositeObject,
  dashboardId,
  isUpdateDashboardLoading,
}: PropTypes) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {updateAlert} = useTileAlertSync({dashboardId});
  const alertList = useSelector(amSelectors.getAlertConfigurationsItems);

  useEffect(() => {
    dispatch(fetchComposites());
    dispatch(fetchAlertConfigurations());
  }, []);

  const alert = alertList.find((item) => item.data.id === attachedAlertId);

  const {makeCompositeNameProps, isManualCompositeNameInput, isCompositeNameLoading} = useCompositeName({
    compositeObject,
  });

  const compositeName = get(alert, 'data.expressionTreeModel.name');
  const savedMeasureName = compositeName
    ? ([...(compositeName.prefix || '').split('what='), ''][1] || '').split('.')[0]
    : '';

  const compositesList = useSelector(metricsSelectors.getComposites);

  const validate = useCallback(
    (values) => {
      const compositeNameMandatory =
        isManualCompositeNameInput && !values.compositeName && 'Composite name is mandatory';

      return pickBy(
        {
          compositeName: compositeNameMandatory,
        },
        identity,
      );
    },
    [compositesList, isManualCompositeNameInput],
  );

  return (
    <Modal disableEnforceFocus open BackdropComponent={Backdrop} onClose={onClose}>
      <div className="width_1 height_1 flex_center">
        <Form
          decorators={[focusOnErrors]}
          initialValues={{compositeName: savedMeasureName}}
          validate={validate}
          onSubmit={(values) =>
            updateAlert({
              ...alert,
              data: {
                ...alert.data,
                compositeObject: {
                  ...compositeObject,
                  ...makeCompositeNameProps(alert.data.title, values.compositeName),
                },
              },
            }).then(onConfirm)
          }
        >
          {({handleSubmit, submitErrors, submitting}) => (
            <div className={classes.wrapper}>
              <div className={classes.closeButton} role="button">
                <i className="icon icn-general16-closeb" onClick={onClose} />
              </div>
              <div className="fontSize_16 mb_2 fontWeight_500">Saving this tile will modify it’s alert</div>
              <React.Fragment>
                {isCompositeNameLoading || isUpdateDashboardLoading ? (
                  <div className="alignSelf_center">
                    <Spinner color="gray.500" size={SIZES.MEDIUM_50} />
                  </div>
                ) : (
                  <React.Fragment>
                    {isManualCompositeNameInput && (
                      <React.Fragment>
                        <div className="fontSize_16 mb_1-5">Composite Name</div>
                        <div className="display_flex mb_4">
                          <div className={classes.compositeName}>
                            <Field name="compositeName">
                              {({input: {value, onChange}, meta: {error, touched}}) => (
                                <Input
                                  additionalProps={{name: 'compositeName'}}
                                  isInvalid={touched && error}
                                  invalidMessage={error}
                                  fullSize
                                  value={value}
                                  onChange={onChange}
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                    <div>
                      This tile is connected to the alert:
                      <div className="mb_2">[{get(alert, 'data.title', '')}]</div>
                      Editing this tile will update the alert as well.
                    </div>
                    {Object.values(submitErrors || {}).map((error) => (
                      <div className={classes.error}>{error}</div>
                    ))}
                    <div className="mt_8-5 display_flex justifyContent_flex-end">
                      <div className="mr_1-5">
                        <Button
                          automationId="cancel"
                          text="Detach Alert"
                          colorSchema={COLORS.GRAY_300}
                          onClick={onDetachAlert}
                        />
                      </div>
                      <AsyncButton isLoading={submitting} colorSchema="blue.500" onClick={handleSubmit}>
                        Update Tile & Alert
                      </AsyncButton>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            </div>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default AlertUpdateConfirmationModal;
