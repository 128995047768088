// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {
  applyCoralogixStreamFilters as applyCoralogixStreamFiltersAction,
  setSelectedStreamUiKeyVal as setSelectedStreamUiKeyValAction,
} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import {reduceArrayItem} from 'common/utils/reducers';
import {get, isEqual} from 'lodash';
import SelectAndt, {THEME_LIGHT, TYPE_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {CORALOGIX_FILTER_TYPES} from 'bc/services/coralogixService';
import {ReactComponent as NoData} from 'bc/svgs/no-streams.svg';
import {isStreamEditable, isEditRunning} from 'bc/services/dataStreamService';

import './CoralogixData.module.scss';

const reducerHelper = (item, payload) => ({...item, ...payload});

type PropTypes = {
  dataStream: Object,
  labels: Object,
  measures: Object,
  isLoadingLabels: Boolean,
  isLoadingMeasures: Boolean,

  // connect
  setSelectedStreamUiKeyVal: Function,
  applyCoralogixStreamFilters: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    labels: selectors.getCoralogixStreamLabelsItems(state),
    isLoadingLabels: selectors.getCoralogixStreamLabelsIsLoading(state),
    isLoadingMeasures: selectors.getCoralogixStreamMeasuresIsLoading(state),
  }),
  {
    setSelectedStreamUiKeyVal: setSelectedStreamUiKeyValAction,
    applyCoralogixStreamFilters: applyCoralogixStreamFiltersAction,
  },
)
export default class CoralogixFiltering extends React.PureComponent {
  props: PropTypes;

  state = {
    isDirty: false,
    filters: get(this.props.dataStream, 'uiState.uiFilters', [{label: '', selectedValue: [], isInclude: true}]),
  };

  componentDidUpdate() {
    if (
      this.props.dataStream.uiState &&
      this.props.dataStream.uiState.uiFilters &&
      !isEqual(this.props.dataStream.uiState.uiFilters, this.state.filters)
    ) {
      /* eslint-disable-next-line react/no-did-update-set-state */
      this.setState({
        filters: get(this.props.dataStream, 'uiState.uiFilters', [{label: '', selectedValue: [], isInclude: true}]),
      });
    }
  }

  filterChanged = (res) => {
    const f = {...this.state.filters[res.index]};
    switch (res.target) {
      case 'filterType':
        f.isInclude = res.value === 'in';
        break;
      case 'label':
        f.selectedValue = [];
        f[res.target] = res.value;
        break;
      default:
        f[res.target] = res.value;
        break;
    }

    this.setState((prevState) => {
      const filters = reduceArrayItem(reducerHelper, prevState.filters, res.index, f);
      this.props.setSelectedStreamUiKeyVal({
        uiFilters: filters,
      });
      return {
        filters,
        isDirty: true,
      };
    });
  };

  removeFilter = (index) => {
    if (this.state.filters.length === 1 && index === 0) {
      this.setState((prevState) => {
        prevState.filters.splice(index, 1);
        const filters = [
          ...prevState.filters,
          {
            label: '',
            selectedValue: [],
            isInclude: true,
          },
        ];
        this.props.setSelectedStreamUiKeyVal({
          uiFilters: filters,
        });
        return {
          filters,
          isDirty: true,
        };
      });
    } else {
      this.setState((prevState) => {
        prevState.filters.splice(index, 1);
        const filters = [...prevState.filters];
        this.props.setSelectedStreamUiKeyVal({
          uiFilters: filters,
        });
        return {
          filters,
          isDirty: true,
        };
      });
    }
  };

  addLabelFilter = () => {
    this.setState((prevState) => {
      const filters = [...prevState.filters, {label: '', selectedValue: [], isInclude: true}];
      this.props.setSelectedStreamUiKeyVal({
        uiFilters: filters,
      });
      return {
        filters: [...prevState.filters, {label: '', selectedValue: [], isInclude: true}],
        isDirty: true,
      };
    });
  };

  goClicked = () => {
    const labelsArr = [];
    // eslint-disable-next-line
    for (const key of Object.keys(this.props.labels)) {
      labelsArr.push(key);
    }
    this.props.applyCoralogixStreamFilters({
      labels: labelsArr,
      measures: this.props.measures,
    });
    this.setState({isDirty: false});
  };

  render() {
    const {filters} = this.state;
    const {dataStream, isLoadingLabels, isLoadingMeasures} = this.props;

    const isEditable =
      isStreamEditable(dataStream.state) &&
      get(this.props.dataStream, 'metricName', false) !== false &&
      dataStream.metrics.length !== 0 &&
      dataStream.dimensions.length !== 0 &&
      !isLoadingLabels &&
      !isLoadingMeasures;

    if (!this.props.dataStream.metricName) {
      return (
        <div className="shell-col" styleName="advance-root">
          <PanelHeader title="Stream Filters" isEditable={isEditable} />
          <div styleName="no-data-container">
            <div styleName="icon">
              <NoData />
            </div>
            <div styleName="title">Nothing To Show</div>
            <div styleName="text">Choose a metric to continue</div>
          </div>
        </div>
      );
    }

    if (this.props.isLoadingLabels && this.props.dataStream.metricName) {
      return (
        <div className="shell-col" styleName="advance-root">
          <PanelHeader title="Stream Filters" isEditable={isEditable} />
          <div styleName="centered-logo">
            <Spinner color="#3d4c59" size={SIZES.BIG_60} />
          </div>
          <div styleName="centered-text">
            <div styleName="text">Loading Available Filters</div>
          </div>
        </div>
      );
    }

    return (
      <div className="shell-col" styleName="advance-root">
        <PanelHeader title="Stream Filters" isEditable={isEditable} />

        {filters.map((filter, i) => (
          <FilterItem
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            index={i}
            isEditable={isEditable}
            isEditRunning={isEditRunning(dataStream.id)}
            filter={filter}
            onChange={this.filterChanged}
            onRemove={this.removeFilter}
            labels={this.props.labels}
          />
        ))}

        <div styleName="actions-wrapper">
          <div styleName="add-path-wrapper" onClick={this.addLabelFilter}>
            <i className="icon icn-icon-blue-plus" />
            <span>Add Another Path Filter</span>
          </div>
          <button
            type="button"
            className="btn btn-raised"
            disabled={!isEditable || !this.state.isDirty}
            onClick={this.goClicked}
          >
            Apply
          </button>
        </div>
      </div>
    );
  }
}

const FilterItem = (props: {
  filter: Object,
  onChange: Function,
  onRemove: Function,
  index: number,
  labels: Array,
  isEditable: Boolean,
  isEditRunning: Boolean,
}) => {
  const inputLabelChanged = (l) => {
    props.onChange({value: l.value, target: 'label', index: props.index});
  };

  const inputValueChanged = (e) => {
    const values = e.map((i) => i.value);
    props.onChange({value: values, target: 'selectedValue', index: props.index});
  };

  const filterChanged = (f) => {
    props.onChange({value: f.value, target: 'filterType', index: props.index});
  };

  const removeItem = () => {
    props.onRemove(props.index);
  };

  const labelsArr = [];
  const labelValuesArr = [];

  // eslint-disable-next-line
  for (const key of Object.keys(props.labels)) {
    labelsArr.push({label: key, value: key});
  }

  const selectedLabelIndex = labelsArr.findIndex((label) => label.value === get(props.filter, 'label', ''));

  if (selectedLabelIndex > -1) {
    props.labels[labelsArr[selectedLabelIndex].value].forEach((val) => {
      labelValuesArr.push({label: val, value: val});
    });
  }

  const selectedValue = [];
  props.filter.selectedValue.forEach((x) => {
    const selectedValueIndex = labelValuesArr.findIndex((label) => label.value === x);
    if (selectedValueIndex !== -1) {
      selectedValue.push(labelValuesArr[selectedValueIndex]);
    } else {
      selectedValue.push({label: x, value: x});
    }
  });

  return (
    <div styleName="coralogix-select-block">
      <i styleName="remove-item" className="icon icn-icon-table-delete" onClick={removeItem} />

      <div styleName="path-wrapper">
        {props.isEditRunning ? (
          <input
            type="text"
            automationId="coralogixFilterLabelInput"
            placeholder="label"
            readOnly
            disabled
            value={get(props.filter, 'label', '')}
          />
        ) : (
          <SelectAndt
            disabled={!props.isEditable}
            type={TYPE_SEARCH}
            theme={THEME_LIGHT}
            onChange={inputLabelChanged}
            options={labelsArr}
            value={labelsArr[selectedLabelIndex]}
            getOptionLabel={(val) => val.label}
            getOptionValue={(val) => val.value}
            placeholder="Choose label field to filter"
            isCreatable
            id="coralogixFilterLabel"
            automationId="coralogixFilterLabel"
            optionHeight={40}
          />
        )}
      </div>

      <div styleName="values-wrapper">
        <div styleName="operator-wrapper">
          <span className="ellipsis" styleName="input-title">
            Operator
          </span>
          <SelectAndt
            type={TYPE_SEARCH}
            theme={THEME_LIGHT}
            onChange={filterChanged}
            options={CORALOGIX_FILTER_TYPES}
            value={props.filter.isInclude ? CORALOGIX_FILTER_TYPES[0] : CORALOGIX_FILTER_TYPES[1]}
            placeholder=""
            optionHeight={40}
            disabled={!props.isEditable}
            getOptionLabel={(val) => val.label}
            getOptionValue={(val) => val.value}
            id="coralogixFilterType"
            automationId="coralogixFilterType"
          />
        </div>
        <div styleName="value-wrapper">
          <span className="ellipsis" styleName="input-title">
            Value
          </span>

          <SelectAndt
            type={TYPE_SEARCH}
            theme={THEME_LIGHT}
            onChange={inputValueChanged}
            options={labelValuesArr}
            value={selectedValue}
            placeholder="Choose value to filter"
            optionHeight={40}
            disabled={!props.isEditable}
            getOptionLabel={(val) => val.label}
            getOptionValue={(val) => val.value}
            id="coralogixFilterValue"
            automationId="coralogixFilterValue"
            isClearable={false}
            closeMenuOnSelect={false}
            closeMenuOnScroll={false}
            isCreatable
            useBoxHalf
            isMulti
          />
        </div>
      </div>

      <div styleName="seperator" />
    </div>
  );
};
