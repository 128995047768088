export const shouldFetchData = (state) => {
  if (!state.data) {
    return true;
  }
  // Don't refresh data if the last refresh is less than 30 min
  return state.updateAt < Math.round(new Date().getTime() / 1000) - 1800;
};

export const panelSliding = {
  defaultStyle: {
    transition: 'right 300ms, box-shadow 300ms',
    right: '-400px',
    boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
  },
  transitionStyles: {
    entering: {
      right: '-400px',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
    },
    entered: {
      right: '0',
      boxShadow: '0 0 14px 0 rgba(0, 0, 0, 0.2)',
    },
    exiting: {
      right: '0',
      boxShadow: '0 0 14px 0 rgba(0, 0, 0, 0.2)',
    },
    exited: {
      right: '-400px',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
    },
  },
};

export const fadeOpacity = {
  duration: 1750, // magic number
  defaultStyle: {
    transition: 'opacity 350ms ease-in-out',
    opacity: 0,
  },
  transitionStyles: {
    entering: {opacity: 0},
    entered: {opacity: 1},
    exiting: {opacity: 0},
    exited: {opacity: 0},
  },
};

export const numLastDaysOptions = [
  {
    label: 'Last 7 Days',
    value: 7,
    id: 'numLastDays7',
    constRange: 'w1',
    segmentName: 'Select Dropdown 7d',
  },
  {
    label: 'Last 14 Days',
    value: 14,
    id: 'numLastDays10',
    constRange: 'w2',
    segmentName: 'Select Dropdown 14d',
  },
  {
    label: 'Last 30 Days',
    value: 30,
    id: 'numLastDays30',
    constRange: 'm1',
    segmentName: 'Select Dropdown 30d',
  },
];
