// @flow
import React, {Fragment} from 'react';
import connect from 'react-redux/es/connect/connect';
import ConfModal from 'common/componentsV2/modal/ConfModal';
import AssetsCountSection from 'admin.users/components/AssetsCountSection';
import DelegateUserSection from 'admin.users/components/UsersTable/DelegateUserSection';
import StreamsDelegationWarning from 'admin.users/components/UsersTable/StreamsDelegationWarning';
import {isAnodot} from 'profile/store/selectors';
import * as selectors from 'admin.users/store/selectors';
import {toggleBulkDeleteUsersModal, setBulkDeleteUsersModalKeyVal, deleteUsersBulkApi} from 'admin.users/store/actions';
import './UGFooterGeneral.module.scss';

type PropTypes = {
  isMeAnodot: boolean,
  isBulkDeleteSelectedUsers: boolean,
  delegateUserId: string,
  isDelegate: boolean,
  delegateUserList: Array,
  selectedUsersCheckbox: Array,
  selectedDelegateUser: Object,
  bulkDeleteModal: Object,
  toggleBulkDeleteUsersModal: Function,
  setBulkDeleteUsersModalKeyVal: Function,
  deleteUsersBulkApi: Function,
};

@connect(
  (state) => ({
    isMeAnodot: isAnodot(state),
    isBulkDeleteSelectedUsers: selectors.isBulkDeleteSelectedUsers(state),
    delegateUserId: selectors.getBulkDeleteUsersModalDelegateUserId(state),
    isDelegate: selectors.getBulkDeleteUsersModalIsDelegate(state),
    delegateUserList: selectors.getBulkDeleteUsersModalDelegateUsersList(state),
    selectedUsersCheckbox: selectors.getSelectedUsersCheckbox(state),
    selectedDelegateUser: selectors.getBulkDeleteUsersModalDelegateUser(state),
    bulkDeleteModal: selectors.getBulkDeleteUsersModal(state),
  }),
  {
    toggleBulkDeleteUsersModal,
    setBulkDeleteUsersModalKeyVal,
    deleteUsersBulkApi,
  },
)
export default class DeleteUserModal extends React.PureComponent {
  props: PropTypes;

  onModalClose = () => {
    this.props.toggleBulkDeleteUsersModal({isOpen: false});
  };

  onConfirm = () => {
    this.props.deleteUsersBulkApi({
      userIds: this.props.selectedUsersCheckbox.join(','),
      delegateUserId: this.props.delegateUserId,
    });
    this.props.toggleBulkDeleteUsersModal({isOpen: false});
  };

  setIsUserDelegate = (isDelegate) => {
    this.props.setBulkDeleteUsersModalKeyVal({
      isDelegate,
      delegateUserId: isDelegate ? '' : 'andt-none',
    });
  };

  userDefaultSelected = (itemId) => {
    this.props.setBulkDeleteUsersModalKeyVal({
      delegateUserId: itemId,
    });
  };

  isDeleteDisabled = () => {
    const {isMeAnodot, isBulkDeleteSelectedUsers} = this.props;

    if (!isMeAnodot) {
      return false;
    }

    return !isBulkDeleteSelectedUsers;
  };

  render() {
    const {selectedUsersCheckbox, isDelegate, delegateUserList, selectedDelegateUser, isMeAnodot} = this.props;
    const {isOpen} = this.props.bulkDeleteModal;

    return (
      <ConfModal
        isOpen={isOpen}
        onClose={this.onModalClose}
        onConfirm={this.onConfirm}
        isDanger
        isConfirmDisabled={this.isDeleteDisabled()}
        confirmText="Delete Users"
        title="Delete Users"
      >
        <div styleName="delete-body">
          {!isMeAnodot ? (
            <Fragment>
              <span>Deleting users is permanent and cannot be undone.</span>
              <AssetsCountSection header="Users have:" userIds={selectedUsersCheckbox.join(',')} />
              <StreamsDelegationWarning userIds={selectedUsersCheckbox.join(',')} />
              <DelegateUserSection
                isDelegate={isDelegate}
                usersList={delegateUserList}
                selectedDelegateUser={selectedDelegateUser}
                onIsDelegateChange={this.setIsUserDelegate}
                onUserDelegateSelect={this.userDefaultSelected}
              />
            </Fragment>
          ) : (
            <Fragment>
              <span>Can only delete users from Anodot Org.</span>
            </Fragment>
          )}
        </div>
      </ConfModal>
    );
  }
}
