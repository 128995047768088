export const VALID_CSV_VALUES = {
  label: 'CSV',
  types: ['.csv', 'application/vnd.ms-excel', 'text/csv'],
  size: 10000000, // 10 MB
};

export const prepareFile = (e, title, existingFiles) => {
  const file = {
    data: e.target.files[0],
    errorCode: null,
  };

  const isFileExist =
    existingFiles && existingFiles.length
      ? existingFiles.some((i) => i.name === file.data.name || i.title === file.data.name)
      : null;

  if (!VALID_CSV_VALUES.types.includes(file.data.type)) {
    file.errorCode = 10000;
  }
  if (file.data.size > VALID_CSV_VALUES.size) {
    file.errorCode = 10001;
  }
  if (title && title !== file.data.name) {
    file.errorCode = 10002;
  }
  if (isFileExist) {
    file.errorCode = 10003;
  }
  return file;
};

export const TYPES = {
  LOOKUP: 'lookup',
  DYNAMIC_ROUTING: 'dynamic-routing',
};

export const FILTER_TYPES_OPTIONS = [
  {label: 'Lookup Table', value: TYPES.LOOKUP},
  {label: 'Dynamic Routing', value: TYPES.DYNAMIC_ROUTING},
];

export const commonStyle = {
  tableRow: {
    padding: '12px 24px',
    paddingRight: 24,
  },
  tableItem: {
    padding: '0 8px',
  },
};

export const filterKeyHeaders = {
  searchQuery: {
    id: 'searchQuery',
    label: 'Search:',
  },
  type: {
    id: 'type',
    label: 'Type:',
  },
  owner: {
    id: 'owner',
    label: 'Owner',
  },
};
