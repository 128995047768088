// @flow
import React, {Fragment} from 'react';
import './StyleGuideComponents.module.scss';
import Spinner from 'common/componentsV2/Spinner';

export default class SpinnerSection extends React.Component {
  render() {
    return (
      <Fragment>
        <h4>Spinner</h4>
        <div styleName="presentation">
          <div styleName="item">
            <h5>Spinner Size 30 px</h5>
            <Spinner size={30} color="#3d4c59" />
          </div>
          <div styleName="item">
            <h5>Spinner Size 60 px</h5>
            <Spinner size={60} color="#3d4c59" />
          </div>
          <div styleName="item">
            <h5>Spinner Size 90 px</h5>
            <Spinner size={90} color="#3d4c59" />
          </div>
        </div>
      </Fragment>
    );
  }
}
