import {get, post, delete_} from 'common/utils/http';

const root = 'api/v1/user-events/';

// *** user events categories
export const fetchUserEventsCategories = () => get(`${root}categories`);
export const createUserEventsCategories = ({payload}) => post(`${root}categories`, payload);
export const deleteUserEventsCategory = ({payload}) => delete_(`${root}categories/${payload}`);

// *** user events sources
export const fetchUserEventsSources = () => get(`${root}sources`);
export const createUserEventsSource = ({payload}) => post(`${root}sources`, payload);
export const deleteUserEventsSource = ({payload}) => delete_(`${root}sources/${payload}`);

export const fetchPropAndValListApi = ({payload}) => post('/api/v1/search/user-events/propandval', payload);
export const fetchPropsApi = ({payload}) => post('/api/v1/search/user-events/props', payload);
export const execute = ({payload}) => post(`/api/v1/user-events/execute${payload.url}`, payload.body);
