/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable */
import * as actions from 'bc/store/actions';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';
import {getUniqueId} from 'common/utils/guid';
import {isEqual} from 'lodash';

const googleAuctionsDataStreamReducer = composeReducers((state, {type, payload}) => {
  const getSelectedIndex = (id = state.selectedItemId) => state.streams.data.findIndex((item) => item.id === id);

  const red = (item, itemPayload) => ({...item, ...itemPayload});

  const redWrapper = (_payload, index = getSelectedIndex()) => ({
    ...state,
    streams: {
      ...state.streams,
      data: reduceArrayItem(red, state.streams.data, index, _payload || payload),
    },
  });

  // gets sources ids used by items recursively
  const getSourceIdsArr = (sourcesIdArr, item) => {
    if (item.sourceColumn !== undefined) {
      if (!sourcesIdArr.includes(item.sourceColumn)) {
        sourcesIdArr.push(item.sourceColumn);
      }
    } else if (item.transform && item.transform.input) {
      item.transform.input.forEach((a) => {
        getSourceIdsArr(sourcesIdArr, a);
      });
    }
    return sourcesIdArr;
  };

  // looks for fileSchema item in the schema
  // note: sourceColumn might have a number stored as a string
  const getItemInSchema = (columns, item) =>
    columns.find(
      (a) =>
        (a.sourceColumn && item.name.toString() === a.sourceColumn) ||
        (a.transform && a.transform.input && a.transform.input.length === 1 && item.name === getSourceIdsArr([], a)[0]),
    );

  const getIsItemSourceMerged = (unifiedFileSchema, itemId, itemName) =>
    unifiedFileSchema.find(
      (a) =>
        ((a.sourceColumn && a.sourceColumn === itemId) ||
          (getSourceIdsArr([], a)[0] && getSourceIdsArr([], a)[0] === itemId)) &&
        a.name !== itemName &&
        a.isMerged,
    );

  switch (type) {
    case actions.setGoogleAuctionsDiametricsStreamAnalysisSchema.TYPE: {
      const fileSchema = payload.fileSchema || []; // file schema may be null for corrupted files
      const stream = state.streams.data[getSelectedIndex()];

      let unifiedFileSchema = [];
      let itemMatch = {};

      // compare and merge the payload and current fileSchema
      if (stream.uiState && stream.uiState.analysisResult && stream.uiState.analysisResult.fileSchema) {
        // loop the new file schema
        fileSchema.forEach((newItem) => {
          itemMatch = {...newItem};
          // loop the old file schema
          stream.uiState.analysisResult.fileSchema.forEach((oldItem) => {
            // look for item match in the old file schema
            if (
              (stream.fileFormat && stream.fileFormat.hasHeader === true && isEqual(newItem, oldItem)) ||
              ((!stream.fileFormat || stream.fileFormat.hasHeader !== true) &&
                (newItem.name === oldItem.name &&
                  newItem.type === oldItem.type &&
                  isEqual(newItem.possibleTypes, oldItem.possibleTypes)))
            ) {
              // look for the item in metrics or dimensions
              if (stream.metrics.includes(newItem.name) || stream.dimensions.includes(newItem.name)) {
                itemMatch = {
                  ...newItem,
                  ...getItemInSchema(stream.schema && stream.schema.columns ? stream.schema.columns : [], newItem),
                  isMerged: true,
                };
              } else {
                itemMatch = {
                  ...newItem,
                };
                if (itemMatch.type) {
                  delete itemMatch.type;
                }
              }
            }
          });
          unifiedFileSchema.push({...itemMatch});
        });
      } else if (stream.metrics.length || stream.dimensions.length) {
        // no uiState and running stream
        fileSchema.forEach((newItem) => {
          itemMatch = {...newItem};
          if (stream.metrics.includes(newItem.name) || stream.dimensions.includes(newItem.name)) {
            itemMatch = {
              ...newItem,
              ...getItemInSchema(stream.schema && stream.schema.columns ? stream.schema.columns : [], newItem),
              isMerged: true,
            };
          } else {
            itemMatch = {
              ...newItem,
            };
            if (itemMatch.type) {
              delete itemMatch.type;
            }
          }
          unifiedFileSchema.push({...itemMatch});
        });
      } else {
        // no uiState and no dimensions, metrics and schema
        unifiedFileSchema = fileSchema;
      }

      // extract the updated unifiedFileSchema
      const propsToUpdate = {
        uiState: {
          ...stream.uiState,
          analysisResult: {...payload},
          unAssignedColumns: unifiedFileSchema.filter((item) => !item.type).map((item) => item.name),
        },
        fileFormat: payload.fileFormat,
        dimensions: unifiedFileSchema.filter((item) => item.type === 'dimension').map((item) => item.name),
        metrics: unifiedFileSchema.filter((item) => item.type === 'metric').map((item) => item.name),
        schema: {
          columns: [],
          sourceColumns: [],
        },
      };

      // schema population
      let unifiedItem = {};
      propsToUpdate.metrics.forEach((itemName) => {
        const metric = unifiedFileSchema.find((a) => a.name === itemName);
        unifiedItem = {
          id: metric.id || getUniqueId(),
          name: metric.name,
          type: 'metric',
        };
        if (metric.transform) {
          unifiedItem.transform = {...metric.transform};
        } else {
          unifiedItem.sourceColumn = metric.name;
        }
        if (metric.targetType) {
          unifiedItem.targetType = metric.targetType;
        }
        if (metric.hidden !== undefined) {
          unifiedItem.hidden = metric.hidden;
        }
        propsToUpdate.schema.columns.push(unifiedItem);
        propsToUpdate.schema.sourceColumns.push({
          id: metric.name,
          name: metric.name,
        });
      });
      propsToUpdate.dimensions.forEach((itemName) => {
        const dimension = unifiedFileSchema.find((a) => a.name === itemName);
        unifiedItem = {
          id: dimension.id || getUniqueId(),
          name: dimension.name,
          type: 'dimension',
        };
        if (dimension.transform) {
          unifiedItem.transform = {...dimension.transform};
        } else {
          unifiedItem.sourceColumn = dimension.name;
        }
        if (dimension.hidden !== undefined) {
          unifiedItem.hidden = dimension.hidden;
        }
        propsToUpdate.schema.columns.push(unifiedItem);
        propsToUpdate.schema.sourceColumns.push({
          id: dimension.name,
          name: dimension.name,
        });
      });

      // get a list of all the current indexes
      const existingIndexArr = [...propsToUpdate.dimensions, ...propsToUpdate.metrics];

      // compare and copy the user created columns from the current schema
      if (stream.schema && stream.schema.columns) {
        stream.schema.columns.forEach((item) => {
          if (
            item.sourceColumn !== undefined ||
            (item.transform &&
              item.transform.input &&
              item.transform.input.length === 1 &&
              getSourceIdsArr([], item).length === 1)
          ) {
            const sourceColumn = item.sourceColumn || getSourceIdsArr([], item)[0];
            if (
              existingIndexArr.includes(sourceColumn) &&
              getIsItemSourceMerged(unifiedFileSchema, sourceColumn, item.name)
            ) {
              // user added columns that are a copy of existing column
              propsToUpdate.schema.columns.push({...item});
            }
          } else if (item.transform && item.transform.name === 'const') {
            // user added const column
            propsToUpdate.schema.columns.push({...item});
          } else if (item.transform && item.transform.input && item.transform.input.length > 1) {
            // user added concat columns
            let shouldPush = true;
            getSourceIdsArr([], item).forEach((id) => {
              if (
                !existingIndexArr.includes(name) ||
                getIsItemSourceMerged(unifiedFileSchema, id, item.name) === undefined
              ) {
                shouldPush = false;
              }
            });
            if (shouldPush) {
              propsToUpdate.schema.columns.push({...item});
            }
          }
        });
      }

      // add special dimensions
      if (stream.dimensions.indexOf('_ACCOUNT_ID_') < 0) {
        propsToUpdate.dimensions.push('_ACCOUNT_ID_', '_REPORT_NAME_');
        propsToUpdate.schema.columns.push(
          {
            id: getUniqueId(),
            sourceColumn: '_ACCOUNT_ID_',
            name: 'Account Id',
            type: 'dimension',
          },
          {
            id: getUniqueId(),
            sourceColumn: '_REPORT_NAME_',
            name: 'Report Name',
            type: 'dimension',
          },
        );

        propsToUpdate.schema.sourceColumns.push(
          {
            id: '_ACCOUNT_ID_',
            name: '_ACCOUNT_ID_',
          },
          {
            id: '_REPORT_NAME_',
            name: '_REPORT_NAME_',
          },
        );
      }

      return {...redWrapper(propsToUpdate)};
    }

    case actions.setAuctionStreamClearAllDiametrics.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      const metricsDimensions = stream.dimensions.concat(stream.metrics);
      const mod = {
        metrics: [],
        dimensions: [],
        schema: {
          columns: [],
          sourceColumns: [],
        },
        uiState: {
          ...stream.uiState,
          unAssignedColumns: [...stream.uiState.unAssignedColumns, ...metricsDimensions],
        },
      };
      return redWrapper(mod);
    }

    case actions.removeAuctionStreamDiametrics.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      const mod = {};
      if (stream.metrics.indexOf(payload) >= 0) {
        mod.metrics = stream.metrics.filter((a) => a !== payload);
      } else if (stream.dimensions.indexOf(payload) >= 0) {
        mod.dimensions = stream.dimensions.filter((a) => a !== payload);
      }
      mod.uiState = {
        ...stream.uiState,
        unAssignedColumns: [...stream.uiState.unAssignedColumns, payload],
      };
      return redWrapper(mod);
    }

    case actions.setAuctionStreamDiametricsChange.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      const streamModifications = {
        uiState: {...stream.uiState},
      };

      const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
      };

      if (payload.source.droppableId === payload.destination.droppableId) {
        switch (payload.destination.droppableId) {
          case 'dmAllColumns': {
            streamModifications.uiState.unAssignedColumns = reorder(
              stream.uiState.unAssignedColumns,
              payload.source.index,
              payload.destination.index,
            );
            break;
          }
          case 'dmMetrics': {
            streamModifications.metrics = reorder(stream.metrics, payload.source.index, payload.destination.index);
            break;
          }
          case 'dmDimensions': {
            streamModifications.dimensions = reorder(
              stream.dimensions,
              payload.source.index,
              payload.destination.index,
            );
            break;
          }
          default:
            return state;
        }
      } else {
        switch (payload.destination.droppableId) {
          case 'dmAllColumns': {
            streamModifications.uiState.unAssignedColumns = [...stream.uiState.unAssignedColumns];
            streamModifications.uiState.unAssignedColumns.splice(payload.destination.index, 0, payload.draggableId);
            break;
          }
          case 'dmMetrics': {
            streamModifications.metrics = [...stream.metrics];
            streamModifications.metrics.splice(payload.destination.index, 0, payload.draggableId);
            break;
          }
          case 'dmDimensions': {
            streamModifications.dimensions = [...stream.dimensions];
            streamModifications.dimensions.splice(payload.destination.index, 0, payload.draggableId);
            break;
          }
          default:
            return state;
        }

        switch (payload.source.droppableId) {
          case 'dmAllColumns': {
            streamModifications.uiState.unAssignedColumns = [
              ...(streamModifications.uiState.unAssignedColumns || stream.uiState.unAssignedColumns),
            ];
            streamModifications.uiState.unAssignedColumns.splice(
              streamModifications.uiState.unAssignedColumns.findIndex((a) => a === payload.draggableId),
              1,
            );
            break;
          }
          case 'dmMetrics': {
            streamModifications.metrics = [...stream.metrics];
            streamModifications.metrics.splice(
              streamModifications.metrics.findIndex((a) => a === payload.draggableId),
              1,
            );
            break;
          }
          case 'dmDimensions': {
            streamModifications.dimensions = [...stream.dimensions];
            streamModifications.dimensions.splice(
              streamModifications.dimensions.findIndex((a) => a === payload.draggableId),
              1,
            );
            break;
          }
          default:
            return state;
        }
      }
      return redWrapper(streamModifications);
    }

    default:
      return state;
  }
});

export default googleAuctionsDataStreamReducer;
