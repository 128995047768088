import {resolutionTypes} from 'metrics/services/metricsService';
import {getUniqueId} from 'common/utils/guid';
import {alertSeverity, alertTypes, serverAnomalyDirection} from './alertsService';

export const allowedFilterKeys = {
  whose: {
    all: {
      label: 'All Alerts',
      value: 'all',
    },
    my: {
      label: 'My Alerts',
      value: 'my',
    },
    sub: {
      label: 'Subscribed',
      value: 'sub',
    },
  },
  status: {
    all: {
      label: 'All',
      value: 'all',
    },
    active: {
      label: 'Active',
      value: 'active',
    },
    paused: {
      label: 'Paused',
      value: 'paused',
    },
  },
  type: alertTypes,
  severity: alertSeverity,
  anomalyDirection: {
    up: {
      label: 'Up',
      value: serverAnomalyDirection.up,
    },
    down: {
      label: 'Down',
      value: serverAnomalyDirection.down,
    },
    both: {
      label: 'Up & Down',
      value: serverAnomalyDirection.both,
    },
  },
  timeScale: resolutionTypes,
};

export const DEFAULT_QUERY_PARAMS = {
  searchQuery: '',
  whose: allowedFilterKeys.whose.all.value,
  status: allowedFilterKeys.status.all.value,
  type: null,
  severity: null,
  notifications: false,
  timeScale: null,
  owner: null,
  stream: null,
  subscribers: null,
  channels: null,
  anomalyDirection: null,
  tag: null,
};

export const defaultEmptyAlert = {
  data: {
    ownerId: null,
    title: 'New Alert',
    story: '',
    type: 'anomaly',
    severity: 'high',
    rollups: 'long',
    anomalyAlert: true,
    staticAlert: false,
    noDataAlert: false,
    notifyOnlyOpen: false,
    origin: null,
    channels: [],
    contacts: [],
    subscribers: null,
    pauseTime: null,
    paused: false,
    connectedTiles: null,
    alertTags: null,
    noDataDuration: null,
    eventsFilter: {
      aggregation: {
        aggregationField: null,
        resolution: 'medium',
        topEventSize: 0,
        maxBuckets: 100,
      },
      filter: {
        categories: [],
        q: {
          expression: [],
        },
      },
    },
    conditions: null,
    updatePolicy: null,
    expressionTreeModel: {
      expressionTree: {
        root: {
          children: [
            {
              searchObject: {
                expression: [],
              },
              children: [],
              type: 'metric',
              id: '651c-3a713b862fa8',
              uiIndex: 0,
            },
          ],
          function: '',
          id: getUniqueId(),
          parameters: [],
          type: 'function',
          uiIndex: 0,
        },
      },
    },
    id: null,
    triggerCount: null,
    validation: true,
  },
};
