// @flow
import React, {useCallback} from 'react';
import {makeStyles} from '@material-ui/core';
import {StringParam, useQueryParams} from 'use-query-params';
import FiltersPanel from 'common/componentsV2/FiltersPanel';
import {DEFAULT_QUERY_PARAMS} from 'bc/services/dataManagerService';
import SearchQueryFilter from './SearchQueryFilter';
import TypeFilter from './TypeFilter';
import OwnerFilter from './OwnerFilter';

type PropTypes = {
  setFiltersOpen: Function,
  areFiltersOpen: boolean,
};

const useStyles = makeStyles((theme) => ({
  filtersRow: {
    width: '100%',
    marginBottom: '20px',
  },
  filtersRowHeader: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.gray[500],
    display: 'block',
  },
}));

const ChannelsFilters = ({areFiltersOpen, setFiltersOpen}: PropTypes) => {
  const classes = useStyles();
  const [, setQueryParams] = useQueryParams({
    searchQuery: StringParam,
    type: StringParam,
    owner: StringParam,
  });

  const onCloseFiltersClicked = useCallback(() => {
    setFiltersOpen(false);
  }, [setFiltersOpen]);

  const resetFilters = useCallback(() => {
    setQueryParams({
      searchQuery: DEFAULT_QUERY_PARAMS.searchQuery,
      type: DEFAULT_QUERY_PARAMS.type,
      owner: DEFAULT_QUERY_PARAMS.owner,
    });
  }, [setQueryParams]);

  return (
    <FiltersPanel
      isOpen={areFiltersOpen}
      onClearAll={resetFilters}
      isSaveView={false}
      isShareLink={false}
      onClose={onCloseFiltersClicked}
    >
      <div className={classes.filtersRow}>
        <SearchQueryFilter />
      </div>
      <div className={classes.filtersRow}>
        <span className={classes.filtersRowHeader}>Channel Type</span>
        <TypeFilter />
      </div>
      <div className={classes.filtersRow}>
        <span className={classes.filtersRowHeader}>Owner</span>
        <OwnerFilter setQueryParams={setQueryParams} />
      </div>
    </FiltersPanel>
  );
};

export default ChannelsFilters;
