// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {isAnodot} from 'profile/store/selectors';
import {getUsersDataCount} from 'admin.users/store/selectors';
import {getFilteredUserSectionUsersListCount} from 'admin.users/store/filterSelector';
import {fetchUsers, fetchUsersEnforced} from 'admin.users/store/actions';
import {fetchCustomers} from 'admin.customers/store/actions';
import UsersTable from 'admin.users/components/UsersTable/UsersTable';
import UserStatusModal from 'admin.users/components/UsersTable/UserStatusModal';
import DeleteUserModal from 'admin.users/components/UsersTable/DeleteUserModal';
import './UserGroupsSections.module.scss';

type PropTypes = {
  allUsersCount: number,
  filteredCount: number,
  isAnodot: boolean,
  fetchUsers: Function,
  fetchUsersEnforced: Function,
  fetchCustomers: Function,
};

@connect(
  (state) => ({
    allUsersCount: getUsersDataCount(state),
    filteredCount: getFilteredUserSectionUsersListCount(state),
    isAnodot: isAnodot(state),
  }),
  {
    fetchUsers,
    fetchUsersEnforced,
    fetchCustomers,
  },
)
export default class UsersSection extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    if (this.props.isAnodot) {
      this.props.fetchCustomers();
      this.props.fetchUsersEnforced();
    } else {
      this.props.fetchUsers();
    }
  }

  render() {
    const {allUsersCount, filteredCount} = this.props;

    return (
      <div styleName="user-section">
        <div styleName="header">
          <span>Users</span>
          <span styleName="counter">
            {allUsersCount === filteredCount ? `(${allUsersCount})` : `(${filteredCount}/${allUsersCount})`}
          </span>
        </div>
        <UsersTable />
        <UserStatusModal />
        <DeleteUserModal />
      </div>
    );
  }
}
