// @flow
import React from 'react';
import {useSelector} from 'react-redux';
import {Form} from 'react-final-form';
import ErrorBoundary from 'errorBoundaries/ErrorBoundary';
import Box from '@material-ui/core/Box';
import * as selectors from 'userSettings/store/selectors';

import TokenSettings from './TokenSettings';

const ApiTokenManagement = () => {
  const getApiTokens = useSelector((state) => selectors.getApiTokens(state));
  const initialValuesForm = {
    key: getApiTokens,
  };
  const actionSubmit = () => {};
  return (
    <ErrorBoundary>
      <Box flexGrow={1}>
        <Form initialValues={initialValuesForm} onSubmit={actionSubmit}>
          {(formProps) => (
            <form onSubmit={formProps.handleSubmit}>
              <TokenSettings />
            </form>
          )}
        </Form>
      </Box>
    </ErrorBoundary>
  );
};

export default ApiTokenManagement;
