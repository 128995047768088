// @flow
import React from 'react';
import './UserEventsTable.module.scss';

type PropTypes = {
  event: Object,
};

const EventPropery = ({property}: {property: Object}) => (
  <div styleName="property">
    <span styleName="key">{property.key}</span>
    <span styleName="value">{property.value}</span>
  </div>
);

export default class UserEventsExpendablePanel extends React.PureComponent {
  props: PropTypes;

  render() {
    const {event} = this.props;

    return (
      <div styleName="container">
        {event.properties.map((pr) => (
          <EventPropery property={pr} key={`${event.id}-${pr.key}`} />
        ))}
      </div>
    );
  }
}
