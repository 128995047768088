// @flow
import React from 'react';
import Tooltip, {TYPES} from './Tooltip';
import './AvatarBadge.module.scss';

type PropTypes = {
  text: string,
  tooltipText?: string,
  colorSchema?: string,
  className?: string,
  isReverse?: boolean,
  isShadow?: boolean,
};

export const COLORS = {
  GRAY: 'gray',
  ROSE: 'rose',
  TEAL: 'teal',
  TOMATO: 'tomato',
  EUCALIPTUS: 'eucaliptus',
  LILAC: 'lilac',
  INDIGO: 'indigo',
  MINT: 'mint',
  YELLOW: 'yellow',
};

export default class AvatarBadge extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    tooltipText: undefined,
    colorSchema: COLORS.GRAY,
    className: '',
    isReverse: false,
    isShadow: false,
  };

  rootStyle = [];

  render() {
    const {colorSchema, className, isReverse, isShadow, text, tooltipText} = this.props;

    this.rootStyle = ['outer-ring', colorSchema, isReverse ? 'reverse' : '', isShadow ? 'shadow' : ''];

    return (
      <div styleName={this.rootStyle.join(' ')} className={className}>
        <Tooltip content={tooltipText} hideIfEqualTo={undefined} type={TYPES.SMALL} delay={500}>
          <div styleName="inner-ring">
            <div styleName="badge-area">
              <span>{text}</span>
            </div>
          </div>
        </Tooltip>
      </div>
    );
  }
}
