// @flow
import React, {PureComponent} from 'react';

import './IntervalsDropDown.module.scss';
import SelectAndt, {THEME_TRANSPARENT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {components} from 'react-select2';
import DEFAULT_INTERVALS_PERIODS from './intervalsService';

type PropTypes = {
  onAction: Function,
  listOfIntervals?: Object,
  dataSegment?: String,
};

export default class IntervalsDropDown extends PureComponent {
  props: PropTypes;

  static defaultProps = {
    listOfIntervals: null,
    dataSegment: null,
  };

  constructor(props) {
    super(props);

    this.listOfIntervals = this.props.listOfIntervals
      ? DEFAULT_INTERVALS_PERIODS.filter((d) => this.props.listOfIntervals.some((i) => d.id === i))
      : DEFAULT_INTERVALS_PERIODS;

    this.state = {
      currentInterval: this.listOfIntervals[1],
    };
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  handleSelection = (selectedItem) => {
    if (selectedItem.value === 'now') {
      this.props.onAction();
    } else {
      this.setState({currentInterval: selectedItem});
      this.runMethodByInterval(selectedItem.value);
    }
  };

  runMethodByInterval = (interval) => {
    clearInterval(this.intervalId);

    if (!interval) {
      return;
    }
    this.intervalId = setInterval(() => {
      this.props.onAction();
    }, interval);
  };

  render() {
    const {currentInterval} = this.state;
    const selectedIndex = this.listOfIntervals.findIndex((val) => currentInterval.value === val.value);

    return (
      <div styleName="refresh-alerts-dd">
        <SelectAndt
          automationId="intervals"
          options={this.listOfIntervals}
          getOptionLabel={(val) => val.label}
          getOptionValue={(val) => val.value}
          type={TYPE_NO_SEARCH}
          theme={THEME_TRANSPARENT}
          value={this.listOfIntervals[selectedIndex]}
          onChange={this.handleSelection}
          placeholder=""
          customComponent={{
            SingleValue: (p) => (
              <components.SingleValue {...p}>
                <div styleName="wrapper">
                  <i className="icon icn-general16-refresh" />
                  <span styleName="title">{currentInterval.value !== undefined ? currentInterval.title : ''}</span>
                </div>
              </components.SingleValue>
            ),
            Option: (props) => {
              if (props.data.value === 'now') {
                return (
                  <div style={{borderBottom: '1px solid #e1e1e1'}}>
                    <components.Option {...props}>
                      <div>{props.data.label}</div>
                    </components.Option>
                  </div>
                );
              }
              return <components.Option {...props} />;
            },
          }}
        />
      </div>
    );
  }
}
