// @flow
import React from 'react';
import './StreamProgressBar.module.scss';
import {getStreamStatusText} from '../../services/dataStreamService';

const getStreamStateProgressText = (state, status) => {
  if (getStreamStatusText(status)) {
    return null;
  }
  switch (state) {
    case 'initializing_p0':
      return 'Initializing (1/5)';
    case 'initializing_p1':
      return 'Preparing the data (2/5)';
    case 'initializing_p2':
      return 'Learning the data (3/5)';
    case 'initializing_p3':
      return 'Streaming (4/5)';
    case 'initializing_p4':
      return 'Finalizing (5/5)';
    default:
      return null;
  }
};

const getStreamProgressStep = (state, status) => {
  if (getStreamStatusText(status)) {
    return 0;
  }
  switch (state) {
    case 'initializing_p0':
      return 1;
    case 'initializing_p1':
      return 2;
    case 'initializing_p2':
      return 3;
    case 'initializing_p3':
      return 4;
    case 'initializing_p4':
      return 5;
    default:
      return 0;
  }
};

const stepsFacade = Array(5).fill(0);

const StreamProgressBar = (props: {state: String, status: String}) => {
  if (props.state.indexOf('initializing') !== 0) {
    return null;
  }
  const step = getStreamProgressStep(props.state, props.status);

  return (
    <div styleName="stream-progress">
      <div styleName="progress-bar">
        {stepsFacade.map((a, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} styleName={['segment', step >= i + 1 ? 'segment-full' : 'segment-empty'].join(' ')} />
        ))}
      </div>
      <div styleName="progress-title">{getStreamStateProgressText(props.state, props.status)}</div>
    </div>
  );
};

export default StreamProgressBar;
