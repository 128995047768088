// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import MultiButton from 'common/componentsV2/multiButton/MultiButton';
import {allowedFilterKeys} from 'admin.users/services/usersAndGroupsService';
import * as filterSelector from 'admin.users/store/filterSelector';

type PropTypes = {
  setQueryParams: Function,
  status: String,
};

const STATUS_ALERTS = Object.values(allowedFilterKeys.status);

@connect(
  (state) => ({
    status: filterSelector.getFiltersStatus(state),
  }),
  {},
)
export default class UGStatusFilter extends React.PureComponent {
  props: PropTypes;

  onChange = (val) => {
    this.props.setQueryParams({status: val});
  };

  render() {
    const {status} = this.props;
    return (
      <MultiButton value={status} options={STATUS_ALERTS} onChange={this.onChange} automationId="usersStatusFilter" />
    );
  }
}
