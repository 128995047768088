// @flow
import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import {fetchAlerts} from 'alerts.console/store/actions';
import * as selectors from 'alerts.console/store/selectors';
import AlertsListHeader from './AlertsListHeader';
import AlertsListBody from './AlertsListBody';

type PropTypes = {
  setQueryParams: Function,
  dateRange: String,
};

@connect(
  (state) => ({
    displayedTotalAlerts: selectors.getDisplayedTotalAlerts(state),
    alertsTriggeredTotal: selectors.getAlertsTriggeredTotal(state),
  }),
  {
    fetchAlerts,
  },
)
export default class AlertsList extends PureComponent {
  props: PropTypes;

  render() {
    const {setQueryParams, dateRange} = this.props;

    return (
      <Fragment>
        <AlertsListHeader setQueryParams={setQueryParams} dateRange={dateRange} />
        <AlertsListBody />
      </Fragment>
    );
  }
}
