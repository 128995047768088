import React, {useMemo} from 'react';
import {useField} from 'react-final-form';
import {get} from 'lodash';
import {useSelector} from 'react-redux';
import {Box, makeStyles} from '@material-ui/core';
import {TinyScrollBox, TypographyBox} from 'common/componentsV2/boxTools';
import NotificationBar from 'common/components/NotificationBar';
import AsyncButton from 'common/componentsV2/AsyncButton';
import {getIsTreeEmpty, getSelectedBranch, getSelectedExpressionId} from 'metrics/store/selectors';
import {getExpressionErrorsFactory} from 'dashboards/store/selectors';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1300,
    minWidth: 1200,
    minHeight: 640,
    maxHeight: 797,
    backgroundColor: theme.palette.white[500],
    borderRadius: 4,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
    margin: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    padding: 24,
  },
  scrollBox: {
    height: '100%',
    width: 415,
    paddingRight: 16,
    paddingBottom: 25,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

type PropTypes = {
  title: string,
  settingContainer: React.Node,
  previewContainer: React.Node,
  onClose: Function,
  onSubmit: Function,
  isLoading: boolean,
  isStatModal: boolean,
};

const ModalContainer = ({
  title,
  settingContainer: SettingsContainer,
  previewContainer: PreviewContainer,
  onClose,
  onSubmit,
  isLoading,
  isStatModal,
}: PropTypes) => {
  const selectedBranch = useSelector(getSelectedBranch);
  const selectedBranchId = get(selectedBranch, 'root.id');
  const selectedExpressionId = useSelector(getSelectedExpressionId);

  const {
    input: {value: expressionTreeType},
  } = useField('expressionTreeType');
  const {
    input: {value: simpleExpressionTree},
  } = useField('simpleExpressionTree');
  const {
    input: {value: advancedExpressionTree},
  } = useField('advancedExpressionTree');

  const expressionTreeIds = useMemo(
    () => (expressionTreeType === 'simple' ? [simpleExpressionTree] : [...advancedExpressionTree, selectedBranchId]),
    [expressionTreeType, simpleExpressionTree, advancedExpressionTree, selectedBranchId],
  );
  const classes = useStyles();
  const isTreeEmpty = useSelector(getIsTreeEmpty);
  const compositeErrorsSelector = useMemo(() => getExpressionErrorsFactory('expressionTreesPreview'), [
    expressionTreeIds,
    selectedExpressionId,
    isStatModal,
  ]);
  const compositeErrors = useSelector(compositeErrorsSelector);
  const areValidExpressions = get(
    compositeErrors[selectedBranchId] || compositeErrors[selectedExpressionId],
    'passed',
    false,
  );

  return (
    <Box automation-id="addGraphStatTileModal" className={classes.wrapper}>
      <Box flexGrow={1} display="flex" flexDirection="column">
        <TypographyBox fontSize={24} fontWeight={300} mb={1.5}>
          {title}
        </TypographyBox>
        <Box display="flex" alignItems="stretch" flexGrow={1} flexShrink={0} flex={1}>
          <Box mr={2.5} flexShrink={0}>
            <Box position="relative" height={1}>
              <TinyScrollBox automation-id="graphStatSettingsContainer" className={classes.scrollBox}>
                <NotificationBar />
                <SettingsContainer />
              </TinyScrollBox>
            </Box>
          </Box>
          <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center">
            <PreviewContainer />
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" flexShrink={0}>
        <Box mr={1.5}>
          <AsyncButton colorSchema="gray.300" onClick={onClose}>
            Cancel
          </AsyncButton>
        </Box>
        <AsyncButton isDisabled={isTreeEmpty || !areValidExpressions} onClick={onSubmit} isLoading={isLoading}>
          Save tile
        </AsyncButton>
      </Box>
    </Box>
  );
};

export default React.memo(ModalContainer);
