// @flow
import React, {PureComponent} from 'react';
import {get} from 'lodash';
import SmartTooltip from 'common/components/SmartTooltip';
import ToggleSwitch from 'common/componentsV2/toggleSwitch/ToggleSwitch';
import Spinner, {SIZES as SPINNER_SIZES} from 'common/componentsV2/Spinner';
import Checkbox from 'common/componentsV2/Checkbox';
import Slider from 'react-rangeslider';
import {durationScales, resolutionTypes} from 'metrics/services/metricsService';
import ConditionContainer from './ConditionContainer';
import './MinDelta.module.scss';

const tooltipContent =
  'Using both percentage and number value means the alert will only be triggered if the ' +
  'metric passes both criteria: value and percentage';

type PropTypes = {
  title: String,
  subTitle: String,
  absValue: Number,
  percentValue: Number,
  id: String,
  isAbsOnlyNaturalNumbers: boolean,
  deltaDurationEnabled: boolean,
  enableAutoFeature: boolean,
  enableAutoBoolVal: boolean,
  enableAutoIsLoading: boolean,
  minDurationUI: Object,
  rollup: String,
  deltaDuration: Object,
  onAutoBoolValChange: Function,
  onPercentChange: Function,
  onAbsChange: Function,
  setDeltaDurationEnabled: Function,
  setDeltaDurationNumOfPoints: Function,
};

class AbsPercentageConditionTemplate extends PureComponent {
  props: PropTypes;

  state = {
    isDeltaDurationActive: false,
  };

  componentDidMount() {
    this.setIsActive();
  }

  componentDidUpdate() {
    this.setIsActive();
  }

  setIsActive = () => {
    const {minDurationUI} = this.props;
    const rollupBySec = this.getRollupBySecond();
    this.setState({isDeltaDurationActive: minDurationUI && minDurationUI.value > rollupBySec});
  };

  autoCheckedChanged = (value) => {
    const {onAutoBoolValChange} = this.props;
    onAutoBoolValChange(!value);
  };

  onPercentChange = (e) => {
    this.props.onPercentChange(e.target.value);
  };

  onAbsChange = (e) => {
    this.props.onAbsChange(e.target.value);
  };

  setDeltaDurationEnabled = (isDeltaDurationEnabled) => {
    this.props.setDeltaDurationEnabled(!isDeltaDurationEnabled);
  };

  setDeltaDurationNumOfPoints = (value) => {
    const {setDeltaDurationNumOfPoints, rollup} = this.props;
    setDeltaDurationNumOfPoints({value, rollup});
  };

  formatDuration = () => {
    const {deltaDuration, rollup} = this.props;
    const deltaDurationValue = get(deltaDuration, 'minDuration', 0);

    return parseFloat(deltaDurationValue / durationScales[resolutionTypes[rollup].units].seconds);
  };

  getUnitsByRollup = () => resolutionTypes[this.props.rollup].units;

  getRollupBySecond = () => resolutionTypes[this.props.rollup].ms / 1000;

  deltaDurationInactiveMsgRendered = () => {
    const numOfPointsFormatted = this.formatDuration();
    let deltaDurationUnits = this.getUnitsByRollup();
    if (numOfPointsFormatted === 1 && deltaDurationUnits.slice(-1) === 's') {
      deltaDurationUnits = deltaDurationUnits.slice(0, deltaDurationUnits.length - 1);
    }
    return (
      <div styleName="msg-wrapper">
        <i styleName="info" className="icon icn-general16-info" />
        <p>
          In order to use this condition, the Minimum Duration for the anomaly needs to be larger than{' '}
          <span>
            {numOfPointsFormatted} {deltaDurationUnits}
          </span>
        </p>
      </div>
    );
  };

  render() {
    const {absValue, percentValue, minDurationUI, deltaDuration, deltaDurationEnabled} = this.props;
    const {isDeltaDurationActive} = this.state;
    const deltaDurationValue = get(deltaDuration, 'minDuration', '');
    const enabled = get(deltaDuration, 'enabled', false);
    const numOfPointsFormatted = this.formatDuration();
    const deltaDurationUnits = this.getUnitsByRollup();
    const rollupBySec = this.getRollupBySecond();
    const safeAutoBoolVal = !!this.props.enableAutoBoolVal;

    return (
      <ConditionContainer {...this.props}>
        <div className="shell-col" automation-id="alertSettingsPercentagePane">
          <div styleName="basic-values-container">
            <SmartTooltip placement="top" content={tooltipContent}>
              <div styleName="percent-wrapper">
                <input
                  type="number"
                  className="dark-input"
                  styleName="percent-input"
                  placeholder="Percentage"
                  min="0"
                  max="1000"
                  disabled={safeAutoBoolVal}
                  value={percentValue || ''}
                  onChange={this.onPercentChange}
                />
                <span>%</span>
                <label>Percentage</label>
              </div>
            </SmartTooltip>

            <SmartTooltip placement="top" content={tooltipContent}>
              <div styleName="abs-wrapper">
                <input
                  type="number"
                  className="dark-input"
                  styleName="abs-input"
                  placeholder="Absolute"
                  disabled={safeAutoBoolVal}
                  step={safeAutoBoolVal ? '0.1' : '1'}
                  value={absValue || ''}
                  onChange={this.onAbsChange}
                />
                <label>Absolute Value</label>
              </div>
            </SmartTooltip>

            {this.props.enableAutoFeature && (
              <div className="cb2 analytics" styleName="auto-chk-wrapper">
                {this.props.enableAutoIsLoading && (
                  <div styleName="loader-wrapper">
                    <Spinner color="#fff" size={SPINNER_SIZES.SMALL_30} />
                  </div>
                )}
                <ToggleSwitch isChecked={safeAutoBoolVal} onToggle={() => this.autoCheckedChanged(safeAutoBoolVal)} />
                <p>Auto-Calculate</p>
              </div>
            )}
          </div>

          {deltaDurationEnabled && (
            <div styleName={isDeltaDurationActive ? 'delta-duration-wrapper' : 'delta-duration-wrapper disabled'}>
              <header>
                Alert will be triggered if delta lasts at least:
                <strong>
                  {' '}
                  {numOfPointsFormatted} {deltaDurationUnits}
                </strong>
              </header>
              <div styleName="delta-duration-checkbox">
                <Checkbox
                  isDisabled={!isDeltaDurationActive}
                  isChecked={enabled && isDeltaDurationActive}
                  onChange={() => this.setDeltaDurationEnabled(enabled)}
                  className="cb2"
                />
                <p>Override Delta Duration</p>
              </div>
              {!isDeltaDurationActive && this.deltaDurationInactiveMsgRendered()}
              {enabled && isDeltaDurationActive && (
                <div styleName="values">
                  <div styleName="slider-wrapper">
                    <Slider
                      value={deltaDurationValue}
                      onChange={(value) => this.setDeltaDurationNumOfPoints(value)}
                      min={rollupBySec}
                      max={minDurationUI.value}
                      step={rollupBySec}
                      tooltip={false}
                    />
                    <div styleName="input-wrapper">
                      <input disabled type="number" name="volume-value" value={numOfPointsFormatted} />
                      <label>{deltaDurationUnits}</label>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </ConditionContainer>
    );
  }
}

export default AbsPercentageConditionTemplate;
