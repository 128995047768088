import React from 'react';
import PropTypes from 'prop-types';
import SmartTooltip from 'common/componentsV2/Tooltip';

import './RespondersTooltip.module.scss';

const maxUsersToDisplay = 4;

const RespondersTooltip = ({otherResponders, isDisabled, placeholder, isFeedbackByMe, children}: PropTypes) => {
  const tooltipContent = () => {
    const moreRespondersNumber = otherResponders.length - maxUsersToDisplay;

    if (isDisabled) {
      return null;
    }
    if (isFeedbackByMe) {
      return 'Remove feedback';
    }
    if (!otherResponders.length && !isFeedbackByMe) {
      return placeholder;
    }
    return (
      <div styleName="feedback-tooltip-content">
        <ul>
          {isFeedbackByMe ? <li style={{margin: 0, padding: 0, listStyle: 'none'}}>You</li> : null}
          {otherResponders.slice(0, maxUsersToDisplay).map((i) => (
            <li key={i}>{i.userName}</li>
          ))}
          {moreRespondersNumber > 0 ? (
            <li>
              More
              {moreRespondersNumber}
            </li>
          ) : null}
        </ul>
      </div>
    );
  };

  return (
    <SmartTooltip placement="top" content={tooltipContent()}>
      {children}
    </SmartTooltip>
  );
};

RespondersTooltip.defaultProps = {
  isDisabled: false,
  placeholder: '',
};

RespondersTooltip.propTypes = {
  otherResponders: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFeedbackByMe: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default RespondersTooltip;
