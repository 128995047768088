// @flow
import React, {PureComponent} from 'react';
import {noop} from 'lodash';
import TableListItem, {CHECKBOX_VISIBILITY_TYPES} from 'common/componentsV2/table/TableListItem';
import DataManagerStreamItemData from './DataManagerStreamItemData';
import DataManagerStreamItemActions from './DataManagerStreamItemActions';

import './DataManagerStreamItem.module.scss';

type PropTypes = {
  stream: Object,
  isBcReadOnly: Boolean,
  openStreamViewModal: Function,
  openConfirmationModal: Function,
  openLiveStreamEditModal: Function,
  openStreamsLimitModal: Function,
};

export default class DataManagerStreamItem extends PureComponent {
  props: PropTypes;

  render() {
    const {
      stream,
      isBcReadOnly,
      openStreamViewModal,
      openConfirmationModal,
      openLiveStreamEditModal,
      openStreamsLimitModal,
    } = this.props;

    return (
      <div styleName="table">
        <TableListItem
          key={stream.id}
          id={stream.id}
          style={{width: '100%'}}
          headerComponent={<DataManagerStreamItemData stream={stream} openStreamViewModal={openStreamViewModal} />}
          checkboxVisibility={CHECKBOX_VISIBILITY_TYPES.alwaysHide}
          setSelectedItem={noop}
          isSelected={false}
          actionToolbarComponent={
            <DataManagerStreamItemActions
              stream={stream}
              isBcReadOnly={isBcReadOnly}
              openStreamViewModal={openStreamViewModal}
              openConfirmationModal={openConfirmationModal}
              openLiveStreamEditModal={openLiveStreamEditModal}
              openStreamsLimitModal={openStreamsLimitModal}
            />
          }
        />
      </div>
    );
  }
}
