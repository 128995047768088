import {combineReducers} from 'redux';
import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import * as actions from 'admin.activityLog/store/actions';

const fetchAuditLog = composeReducers(makeAsyncReducer(actions.fetchAuditLog), (state = {}, {type, payload}) => {
  switch (type) {
    case actions.fetchAuditLog.success.TYPE:
      return {
        ...state,
        data: payload.entries,
      };
    default:
      return state;
  }
});

export default combineReducers({
  fetchAuditLog,
});
