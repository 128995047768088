// @flow
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {noop} from 'lodash';
import {Form, FormSpy} from 'react-final-form';
import {useDispatch, useSelector} from 'react-redux';
import './MetricExplorer.module.scss';
import {useHistory} from 'react-router-dom';
import * as metricsSelectors from 'metrics/store/selectors';
import {createNewExpression, initExpressionTreeModel, setViewStateProperty} from 'metrics/store/actions';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import {TinyScrollBox} from 'common/componentsV2/boxTools';
import PageLayout from 'common/componentsV2/PageLayout';
import {Box, Grid} from '@material-ui/core';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import * as dateRangeService from 'common/utils/dateRangeService';
import ExpressionTreesPreviewContainer from 'common/containers/ExpressionTreesPreviewContainer';
import CompositeBuildersContainer from 'metrics/components/metricExplorer/CompositeBuildersContainer';
import MetricExplorerBottomPanel from 'metrics/components/metricExplorer/MetricExplorerBottomPanel';
import SaveToDashboardModal, {TYPE_REFERER} from 'common/componentsV2/modal/SaveToDashboardModal';
import SaveToCompositeModal from 'metrics/components/metricExplorer/SaveToCompositeModal';
import {validateExpression} from 'metrics/services/metricsService';
import MetricExplorerModalBottomPanel from 'metrics/components/metricExplorer/MetricExplorerModalBottomPanel';

const defaultMetricExplorer = {
  dateRange: dateRangeService.getDateValue('1w'),
  timeScale: '',
  multiPreviewOptions: {
    '*': {
      sort: 'alphanumeric',
      show: 10,
    },
  },
  byTreeExp: {
    '*': {
      type: 'line',
      stacking: null,
      yAxis: {
        type: 'linear',
        lowerRange: '',
        higherRange: '',
        opposite: false,
        isStateDirty: false,
        minorTickInterval: 'auto',
      },
    },
  },
  byTreeExpId: '*',
  isAutoDateRange: true,
  isShowComposites: true,
  isShowCompositesEnable: true,
  isAutoShowComposites: true,
};

type PropTypes = {
  isModal: boolean,
  onModalCancel: Function,
  onModalSave: Function,
};

const MetricExplorer = ({isModal, onModalCancel, onModalSave}: PropTypes) => {
  const formRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const expressionTree = useSelector(metricsSelectors.getExpressionTree);
  const viewState = useSelector(metricsSelectors.getViewState);
  const [isSaveToDashboardModalOpen, setSaveToDashboardModalOpen] = useState(false);
  const [isSaveToCompositeModalOpen, setSaveToCompositeModalOpen] = useState(false);
  const [selectedTreeForComposite, setSelectedTreeForComposite] = useState({});
  const [validation, setValidation] = useState({
    errorEmptyExpression: false,
    errorFunctionDisplay: false,
    errorEmptyFunction: false,
  });

  const onSaveAlert = (val) => {
    history.push({pathname: '/alert-manager/new', search: '', state: val});
  };
  const onSaveComposite = (val) => {
    setSelectedTreeForComposite(val);
    setSaveToCompositeModalOpen(true);
  };

  const onSaveToDashboard = () => setSaveToDashboardModalOpen(true);

  useEffect(() => {
    setValidation(validateExpression(expressionTree));
  }, [expressionTree]);

  useEffect(() => {
    const preloadedExpressionTree = sessionStorage.getItem('metricExplorerTree');
    if (preloadedExpressionTree) {
      dispatch(
        initExpressionTreeModel({
          expressionTree: JSON.parse(preloadedExpressionTree),
        }),
      );
      sessionStorage.removeItem('metricExplorerTree');
    }
    return () => {
      dispatch(setViewStateProperty({previousByTreeExp: formRef.current.values.byTreeExp}));
    };
  }, []);

  const newExpression = () => {
    dispatch(createNewExpression());
  };

  const renderSaveToDashboardModal = (
    <Modal
      isOpen={isSaveToDashboardModalOpen}
      size={SIZES.SMALL}
      onClose={() => setSaveToDashboardModalOpen(false)}
      isCloseButtonHidden
    >
      <SaveToDashboardModal onCancel={() => setSaveToDashboardModalOpen(false)} type={TYPE_REFERER.METRIC_EXPLORER} />
    </Modal>
  );

  const renderSaveToCompositeModal = (
    <Modal
      isOpen={isSaveToCompositeModalOpen}
      size={SIZES.SMALL}
      onClose={() => setSaveToCompositeModalOpen(false)}
      isCloseButtonHidden
    >
      <SaveToCompositeModal
        onClose={() => setSaveToCompositeModalOpen(false)}
        selectedTree={selectedTreeForComposite}
      />
    </Modal>
  );

  const handleSaveModalExpression = () => {
    onModalSave();
  };

  const handleCancelModal = () => {
    onModalCancel();
  };

  const initialValues = useMemo(
    () => ({...defaultMetricExplorer, byTreeExp: viewState.previousByTreeExp || defaultMetricExplorer.byTreeExp}),
    [viewState.previousByTreeExp],
  );

  const renderContent = (
    <Form initialValues={initialValues} onSubmit={noop}>
      {() => (
        <div className="display_flex flexDirection_column flexGrow_1">
          <FormSpy
            onChange={(form) => {
              formRef.current = form;
            }}
          />
          {renderSaveToDashboardModal}
          {renderSaveToCompositeModal}
          <form className="display_flex alignItems_stretch mt_-1-5 flexGrow_1">
            <Box minHeight={isModal ? '65vh' : '100%'} mr={2.5} flexShrink={0} css={{overflowY: 'auto'}}>
              <Box element="expression-container" display="flex" flexDirection="column" position="relative" height={1}>
                <TinyScrollBox element="expression-container" width={388} height={1}>
                  <Grid item automation-id="metricExplorerContainer">
                    <Box display="flex" mt={1.625} mb={4} maxWidth={388}>
                      <CompositeBuildersContainer isModal={isModal} />
                    </Box>
                  </Grid>
                </TinyScrollBox>
              </Box>
            </Box>
            <Box flexGrow={1} display="flex" alignItems="center" mt={1.5} className="idle-external-click">
              <ExpressionTreesPreviewContainer isModal />
            </Box>
          </form>
          {isModal ? (
            <MetricExplorerModalBottomPanel onSave={handleSaveModalExpression} onCancel={handleCancelModal} />
          ) : (
            <MetricExplorerBottomPanel
              key={`${isSaveToDashboardModalOpen}${isSaveToCompositeModalOpen}`}
              onSaveAlert={onSaveAlert}
              onSaveComposite={onSaveComposite}
              onSaveToDashboard={onSaveToDashboard}
              validation={validation}
              newExpression={newExpression}
            />
          )}
        </div>
      )}
    </Form>
  );

  if (isModal) {
    return (
      <div>
        <div styleName="modal-header">Influencing Metric</div>
        {renderContent}
      </div>
    );
  }

  return (
    <PageLayout
      header={
        <header styleName="page-title">
          <Title type={TITLE_TYPES.PAGE_TITLE}>Metric Explorer</Title>
        </header>
      }
      shouldBottomBar
    >
      {renderContent}
    </PageLayout>
  );
};

export default React.memo(MetricExplorer);
