import React from 'react';
import './OptionComponentCheckbox.module.scss';

type PropTypes = {
  data: Object,
  isSelected: boolean,
};

const OptionComponentCheckbox = (props: PropTypes) => (
  <div styleName="container" className="form-option">
    {props.isSelected ? (
      <div styleName="ico" className="icon  icn-selection16-checkboxon" />
    ) : (
      <div styleName="ico" className="icon icn-selection16-checkboxoff" />
    )}
    <div styleName="label">{typeof props.data === 'string' ? props.data : props.data.label}</div>
  </div>
);

export default OptionComponentCheckbox;
