// @flow
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {segmentClickEvent as segmentTrackingMouseEventAction} from 'common/store/actions';
import * as selectors from 'bc/store/selectors';
import {
  fetchSalesforceObjectData as fetchSalesforceObjectDataAction,
  fetchSalesforceObjects as fetchSalesforceObjectsAction,
  setSalesforceStreamKeyVal as setSalesforceStreamKeyValAction,
  fetchSalesforceQueryPreview as fetchSalesforceQueryPreviewAction,
  fetchSalesforceVerifyQuery as fetchSalesforceVerifyQueryAction,
  setSalesforceStreamQuery as setSalesforceStreamQueryAction,
} from 'bc/store/actions';
import {isEditRunning, isStreamEditable as isStreamEditableService} from 'bc/services/dataStreamService';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import './SalesforceStreamEditor.module.scss';
import SelectAndt, {THEME_LIGHT, TYPE_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import QueryEditorModal from '../sql/queryEditorModal/QueryEditorModal';

const DEFAULT_QUERY = `SELECT Amount, StageName, LeadSource FROM Opportunity
WHERE( Amount  >  999 AND LeadSource LIKE 'link%')
ORDER BY LeadSource
LIMIT 1000`;

const GUIDELINES = [
  <Fragment>Specify the fields you wish to retrieve (select * from table is not supported in SOQL).</Fragment>,
  <Fragment>Specify the object of objects.</Fragment>,
  <Fragment>No need to use timestamp/date fields, the query timestamp will be used as timestamp in Anodot.</Fragment>,
  <Fragment>
    use &#123;&#123;from_time&#125;&#125; and &#123;&#123;to_time&#125;&#125; variables to create a recurring query.
  </Fragment>,
  <Fragment>
    Note that &#123;&#123;from_time&#125;&#125; is inclusive and &#123;&#123;to_time&#125;&#125; is exclusive
  </Fragment>,
];

type PropTypes = {
  dataStream: Object,
  isLoading: Boolean,
  objects: Array,

  // connect
  selectedObject: String,
  previewData: Object,
  queryPreviewItems: Object,
  previewDataError: Object,
  isPreviewDataLoading: boolean,
  isQuery: boolean,
  fetchSalesforceObjects: Function,
  fetchSalesforceObjectData: Function,
  setSalesforceStreamKeyVal: Function,
  fetchSalesforceQueryPreview: Function,
  fetchSalesforceVerifyQuery: Function,
  setSalesforceStreamQuery: Function,
  segmentClickEvent: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isLoading: selectors.getSalesforceIsLoading(state),
    objects: selectors.getSalesforceObjectsSorted(state),
    selectedObject: selectors.getSalesforceSelectedObject(state),
    previewData: selectors.getSalesforceQueryPreviewData(state),
    queryPreviewItems: selectors.getSalesforceQueryStreamQueryPreviewItems(state).streamSchema,
    previewDataError: selectors.getSalesforceQueryStreamQueryPreviewError(state),
    isPreviewDataLoading: selectors.getSalesforceQueryStreamQueryPreviewIsLoading(state),
    isQuery: selectors.getSalesforceIsQuery(state),
  }),
  {
    setSalesforceStreamKeyVal: setSalesforceStreamKeyValAction,
    fetchSalesforceObjects: fetchSalesforceObjectsAction,
    fetchSalesforceObjectData: fetchSalesforceObjectDataAction,
    fetchSalesforceQueryPreview: fetchSalesforceQueryPreviewAction,
    fetchSalesforceVerifyQuery: fetchSalesforceVerifyQueryAction,
    setSalesforceStreamQuery: setSalesforceStreamQueryAction,
    segmentClickEvent: segmentTrackingMouseEventAction,
  },
)
export default class SalesforceContext extends React.PureComponent {
  props: PropTypes;

  state = {
    isStreamEditable: isStreamEditableService(this.props.dataStream),
    selectedRadio: this.props.isQuery ? 'query' : 'table',
    isOpenQueryEditorModal: false,
  };

  componentDidMount() {
    const {dataStream, fetchSalesforceObjects, fetchSalesforceObjectData} = this.props;
    const {dataSourceId} = dataStream;

    fetchSalesforceObjects({
      dataSourceId,
    });

    if (isEditRunning(dataStream.id) && dataStream.objects) {
      fetchSalesforceObjectData({
        dataSourceId,
        obj: dataStream.objects[0],
      });
    }
  }

  setSelectedRadio = (changeEvent) => {
    this.setState({selectedRadio: changeEvent.target.value});
  };

  closeQueryEditorModal = () => {
    this.setState({isOpenQueryEditorModal: false});
  };

  openQueryEditorModal = () => {
    const {dataStream, segmentClickEvent} = this.props;
    this.setState({isOpenQueryEditorModal: true});
    segmentClickEvent({category: `Data collectors - ${dataStream.type}`, name: 'Query editor'});
  };

  handelContinueClick = (payload) => {
    this.props.fetchSalesforceVerifyQuery({
      ...payload,
      timeZone: this.props.dataStream.timeZone,
      pollingInterval: this.props.dataStream.pollingInterval.toUpperCase(),
      dataSourceId: this.props.dataStream.dataSourceId,
    });
    this.closeQueryEditorModal();
  };

  onObjSelect = (obj) => {
    const {setSalesforceStreamKeyVal, fetchSalesforceObjectData, dataStream} = this.props;
    const {dataSourceId} = dataStream;
    setSalesforceStreamKeyVal({objects: [obj.name]});
    fetchSalesforceObjectData({
      dataSourceId,
      obj: obj.name,
    });
  };

  render() {
    const {
      dataStream,
      isLoading,
      objects,
      selectedObject,
      fetchSalesforceQueryPreview,
      setSalesforceStreamQuery,
      previewData,
      previewDataError,
      isPreviewDataLoading,
      queryPreviewItems,
    } = this.props;
    const {isStreamEditable, selectedRadio} = this.state;
    const selectedObj = objects.find((obj) => selectedObject === obj.name) || null;
    const query = dataStream.query || DEFAULT_QUERY;

    return (
      <div className="shell-col">
        <PanelHeader title="Stream Filters" isEditable={isStreamEditable} />

        <div styleName="panel">
          <div styleName="radio-panel">
            <input
              type="radio"
              name="queryType"
              value="table"
              checked={selectedRadio === 'table'}
              onChange={this.setSelectedRadio}
            />
          </div>

          <div styleName="salesforce-select">
            <SelectAndt
              type={TYPE_SEARCH}
              theme={THEME_LIGHT}
              onChange={this.onObjSelect}
              options={objects}
              value={selectedObj}
              placeholder="Choose Object"
              optionHeight={40}
              disabled={isLoading || objects.length === 0 || selectedRadio !== 'table'}
              isLoading={isLoading}
              getOptionLabel={(val) => val.label}
              getOptionValue={(val) => val.name}
              automationId="salesForceObject"
            />
          </div>
        </div>

        <div styleName="panel">
          <div styleName="radio-panel">
            <input
              type="radio"
              name="queryType"
              value="query"
              checked={selectedRadio === 'query'}
              onChange={this.setSelectedRadio}
            />
          </div>

          <div styleName="select-panel">
            <button
              type="button"
              className="btn btn-raised"
              styleName="btn-compose"
              automation-id="bigQueryComposeQueryButton"
              disabled={this.state.selectedRadio !== 'query'}
              onClick={this.openQueryEditorModal}
            >
              Compose Query
            </button>
            {this.state.isOpenQueryEditorModal && (
              <QueryEditorModal
                isOpen={this.state.isOpenQueryEditorModal}
                onClose={this.closeQueryEditorModal}
                dataStream={dataStream}
                query={query}
                runQueryPreview={fetchSalesforceQueryPreview}
                setStreamQuery={setSalesforceStreamQuery}
                hideActualQuery
                previewData={previewData}
                previewDataError={previewDataError}
                isPreviewDataLoading={isPreviewDataLoading}
                queryPreviewColumns={queryPreviewItems}
                fetchVerifyQueryFunction={this.handelContinueClick}
                isVerifyQueryLoading=""
                verifyQueryData=""
                guidelines={GUIDELINES}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
