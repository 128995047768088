// @flow
import React, {Fragment, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useField} from 'react-final-form';
import {makeStyles} from '@material-ui/core/styles';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {Box, Grid} from '@material-ui/core';
import Input from 'common/componentsV2/Input';
import Button, {COLORS} from 'common/componentsV2/Button';
import * as actions from 'userSettings/store/actions';
import * as selectors from 'userSettings/store/selectors';
import moment from 'moment';
import {getUsersData} from 'admin.users/store/selectors';
import CopyToClipboardButton from 'common/components/CopyToClipboardButton';

import ConfirmModal from './ConfirmModal';

import '../../pages/userSettings.module.scss';

const DATE_FORMAT = 'lll';

const useStyles = makeStyles((theme) => ({
  title: {
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.gray[200]}`,
    height: '40px',
    alignItems: 'center',
    paddingRight: '25px',
  },
  token: {
    position: 'relative',
    padding: '8px 25px 8px 0',
    borderBottom: `1px solid ${theme.palette.gray[200]}`,
    '&:hover': {
      borderColor: `${theme.palette.blue[300]}`,
      backgroundColor: `${theme.palette.blue[100]}`,
    },
  },
  button: {
    position: 'absolute',
    top: '50%',
    right: '-100px',
    transform: 'translateY(-50%)',
  },
  removeBtn: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    '&:hover': {
      color: `${theme.palette.blue[500]}`,
    },
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  relative: {
    position: 'relative',
  },
  tokenCopyBtn: {
    opacity: 0,
    height: '18px',
  },
  tokenWrapper: {
    '&:hover $tokenCopyBtn': {
      opacity: 1,
    },
  },
  revoked: {
    color: `${theme.palette.red[500]}`,
    '& $ellipsis': {
      color: `${theme.palette.red[500]}`,
    },
  },
}));

const TokenSettings = () => {
  const dispatch = useDispatch();
  const tokens = useSelector((state) => selectors.getTokens(state));
  const users = useSelector((state) => getUsersData(state));

  const [modalOpen, setModalOpen] = useState(false);
  const [tokenId, setTokenId] = useState('');
  const {
    input: {value},
  } = useField('key');
  const {
    input: {value: inputValue, onChange: inputOnChange},
  } = useField('name');

  useEffect(() => {
    dispatch(actions.fetchRefreshTokens());
  }, []);

  const showModal = (id) => {
    setModalOpen(true);
    setTokenId(id);
  };

  const hideModal = () => {
    setModalOpen(false);
  };

  const removeToken = () => {
    hideModal();
    dispatch(actions.revokeToken(null, {id: tokenId}));
  };

  const addToken = () => {
    dispatch(
      actions.refreshTokens({
        name: inputValue,
      }),
    );
    inputOnChange(' ');
  };

  const classes = useStyles();

  const getUserName = (createdBy) => {
    let userName = '';
    users.find((item) => {
      if (item._id === createdBy) {
        userName = `${item.firstName} ${item.lastName[0].toUpperCase()}`;
        return true;
      }

      return false;
    });

    return userName;
  };

  return (
    <Box py={2.5} borderTop={1} borderColor="gray.200">
      <Grid container>
        <Grid item>
          <TypographyBox variant="body1" width={220}>
            Data Collection:
          </TypographyBox>
        </Grid>
        <Grid item>
          <Box width={400}>
            <Box mb={3.5} position="relative">
              <TypographyBox variant="body1">Key:</TypographyBox>
              <Box position="relative">
                <Input type="text" value={value} fullSize isDisabled />
                <Box styleName="copyBtn">
                  <CopyToClipboardButton copyStr={value} tooltipMessage="Copied to clipboard" shouldUseIcon />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <TypographyBox variant="body1" width={220}>
            Access Keys:
          </TypographyBox>
        </Grid>
        <Grid item>
          <Box width={400}>
            <Box mb={3.5}>
              <TypographyBox variant="body1">Name:</TypographyBox>
              <Box position="relative">
                <Input value={inputValue} onChange={inputOnChange} />
                <Box className={classes.button}>
                  <Button text="Add" colorSchema={COLORS.BLUE_500} onClick={addToken} icon="icn-action16-plusa" />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {tokens && tokens.length > 0 && (
        <Fragment>
          <Grid container className={classes.title}>
            <Grid item xs={3}>
              <TypographyBox variant="h5">Name</TypographyBox>
            </Grid>
            <Grid item xs={2}>
              <TypographyBox variant="h5">Token</TypographyBox>
            </Grid>
            <Grid item xs={3}>
              <TypographyBox variant="h5">Created By</TypographyBox>
            </Grid>
            <Grid item xs={2}>
              <TypographyBox variant="h5">Created At</TypographyBox>
            </Grid>
            <Grid item xs={2}>
              <TypographyBox variant="h5">Revoked At</TypographyBox>
            </Grid>
          </Grid>
          {tokens.map((token) => (
            <Fragment key={token.token}>
              <Box className={`${classes.token} ${token.revoked ? classes.revoked : null}`}>
                <Grid container alignItems="center">
                  <Grid item xs={3}>
                    <TypographyBox variant="body1" className={classes.ellipsis}>
                      {token.name}
                    </TypographyBox>
                  </Grid>
                  <Grid container item xs={2} className={classes.tokenWrapper}>
                    <Grid item>
                      <TypographyBox variant="body1" className={classes.ellipsis}>
                        {token.token && `${token.token.slice(0, 5)}**********`}
                      </TypographyBox>
                    </Grid>
                    <Grid item className={classes.tokenCopyBtn}>
                      <Box pl={1}>
                        <CopyToClipboardButton
                          className="inline"
                          copyStr={token.token}
                          tooltipMessage="Copied to clipboard"
                          shouldUseIcon
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <TypographyBox variant="body1" className={classes.ellipsis}>
                      {getUserName(token.createdBy)}
                    </TypographyBox>
                  </Grid>
                  <Grid item xs={2}>
                    <TypographyBox variant="body1" className={classes.ellipsis}>
                      {token.created && moment(token.created).format(DATE_FORMAT)}
                    </TypographyBox>
                  </Grid>
                  <Grid item xs={2}>
                    <TypographyBox variant="body1" className={classes.ellipsis}>
                      {token.revoked && moment(token.revoked).format(DATE_FORMAT)}
                    </TypographyBox>
                  </Grid>
                </Grid>
                {!token.revoked && (
                  <i
                    className={`icon icn-general16-closea ${classes.removeBtn}`}
                    onClick={() => showModal(token._id)}
                  />
                )}
              </Box>
            </Fragment>
          ))}
          <ConfirmModal isOpen={modalOpen} onClose={hideModal} onConfirm={removeToken} />
        </Fragment>
      )}
    </Box>
  );
};

export default TokenSettings;
