// @flow
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {palette} from 'app/styles/theme';

export const SITE_TYPES = {
  single: 'single',
  multi: 'multi',
  router: 'router',
  server: 'server',
};

export const LinkIcon = ({color}) => {
  return (
    <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="24.5922" y1="28.865" x2="3.04432" y2="2.74631" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <rect width="4" height="4" rx="1" fill={color} />
      <rect x="24" y="28" width="4" height="4" rx="1" fill={color} />
    </svg>
  );
};

LinkIcon.propTypes = {
  color: PropTypes.string,
};

LinkIcon.defaultProps = {
  color: palette.red['500'],
};

export const RegionIcon = ({color}) => {
  return (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle r="34" cx="34" cy="34" strokeWidth="2" opacity="0.1" fill={color} />
      <circle r="26" cx="34" cy="34" strokeWidth="2" opacity="0.25" fill={color} />
      <circle r="18" cx="34" cy="34" strokeWidth="2" opacity="1" fill={color} />
    </svg>
  );
};

RegionIcon.propTypes = {
  color: PropTypes.string,
};

RegionIcon.defaultProps = {
  color: palette.red['500'],
};

export const CentralIcon = ({color, type, onClick, item}) => {
  const locOnClick = (ev) => {
    if (onClick) {
      onClick(ev, item || type);
    }
  };

  switch (type) {
    case SITE_TYPES.server:
      return (
        <g key="central-icon-router" onClick={locOnClick}>
          <rect width="20" height="7" rx="2" fill={color} transform="translate(33 33)" />
          <rect y="8" width="20" height="7" rx="2" fill={color} transform="translate(33 33)" />
          <rect y="16" width="20" height="7" rx="2" fill={color} transform="translate(33 33)" />
          <path
            d="M4.5 5C5.32843 5 6 4.32843 6 3.5C6 2.67157 5.32843 2 4.5 2C3.67157 2 3 2.67157 3 3.5C3 4.32843 3.67157 5 4.5 5Z"
            fill="white"
            transform="translate(33 33)"
          />
          <path
            d="M4.5 13C5.32843 13 6 12.3284 6 11.5C6 10.6716 5.32843 10 4.5 10C3.67157 10 3 10.6716 3 11.5C3 12.3284 3.67157 13 4.5 13Z"
            fill="white"
            transform="translate(33 33)"
          />
          <path
            d="M4.5 21C5.32843 21 6 20.3284 6 19.5C6 18.6716 5.32843 18 4.5 18C3.67157 18 3 18.6716 3 19.5C3 20.3284 3.67157 21 4.5 21Z"
            fill="white"
            transform="translate(33 33)"
          />
        </g>
      );
    case SITE_TYPES.router:
      return (
        <g key="central-icon-router" onClick={locOnClick}>
          <circle className="central-icon-fill" cx="13" cy="13" r="13" transform="matrix(0 1 1 0 30 32)" fill={color} />
          <path
            d="M12.9503 9.34896L12.9503 9.34948C12.7988 9.34358 12.6765 9.26468 12.6133 9.1629L12.585 9.11728L12.5456 9.08075L10.261 6.95959L10.2609 6.95953C10.1124 6.82165 10.1124 6.61136 10.2609 6.47348C10.4266 6.31974 10.7067 6.31973 10.8724 6.47347C10.8724 6.47348 10.8724 6.47348 10.8724 6.47348L11.8916 7.41939L12.5637 8.04312L12.5637 7.12621L12.5638 2.92485V2.92484C12.5638 2.749 12.7223 2.5665 12.9686 2.5665C13.2148 2.5665 13.3733 2.74897 13.3733 2.92481C13.3733 2.92482 13.3733 2.92483 13.3733 2.92484L13.373 7.18357L13.3729 8.10033L14.045 7.47685L15.1266 6.47353L15.1267 6.47348C15.2923 6.31973 15.5724 6.31973 15.7381 6.47348C15.8867 6.61136 15.8867 6.82165 15.7381 6.95953L15.738 6.95963L13.4487 9.08566L13.4115 9.1202L13.3841 9.16293C13.3684 9.18737 13.3486 9.21153 13.3238 9.23453L13.5959 9.52773L13.3238 9.23453C13.241 9.31137 13.1261 9.35291 13.005 9.34966L12.9952 9.3494L12.9853 9.34962L12.9736 9.34977L12.9503 9.34896Z"
            fill="white"
            stroke="white"
            strokeWidth="0.8"
            transform="matrix(0 1 1 0 30 32)"
          />
          <path
            d="M12.9503 16.6506L12.9503 16.65C12.7988 16.6559 12.6765 16.7348 12.6133 16.8366L12.585 16.8822L12.5456 16.9188L10.261 19.0399L10.2609 19.04C10.1124 19.1779 10.1124 19.3882 10.2609 19.526C10.4266 19.6798 10.7067 19.6798 10.8724 19.526C10.8724 19.526 10.8724 19.526 10.8724 19.526L11.8916 18.5801L12.5637 17.9564L12.5637 18.8733L12.5638 23.0747V23.0747C12.5638 23.2505 12.7223 23.433 12.9686 23.433C13.2148 23.433 13.3733 23.2505 13.3733 23.0747C13.3733 23.0747 13.3733 23.0747 13.3733 23.0747L13.373 18.8159L13.3729 17.8992L14.045 18.5227L15.1266 19.526L15.1267 19.526C15.2923 19.6798 15.5724 19.6798 15.7381 19.526C15.8867 19.3882 15.8867 19.1779 15.7381 19.04L15.738 19.0399L13.4487 16.9138L13.4115 16.8793L13.3841 16.8366C13.3684 16.8121 13.3486 16.788 13.3238 16.765L13.5959 16.4718L13.3238 16.765C13.241 16.6881 13.1261 16.6466 13.005 16.6498L12.9952 16.6501L12.9853 16.6499L12.9736 16.6497L12.9503 16.6506Z"
            fill="white"
            stroke="white"
            strokeWidth="0.8"
            transform="matrix(0 1 1 0 30 32)"
          />
          <path
            d="M2.56706 12.9508L2.56653 12.9508C2.57243 12.7993 2.65134 12.677 2.75312 12.6138L2.79873 12.5854L2.83526 12.5461L4.95642 10.2615L4.95649 10.2614C5.09437 10.1129 5.30466 10.1129 5.44254 10.2614C5.59628 10.4271 5.59628 10.7072 5.44254 10.8729C5.44254 10.8729 5.44254 10.8729 5.44254 10.8729L4.49662 11.8921L3.8729 12.5642L4.78981 12.5642L8.99117 12.5643H8.99118C9.16702 12.5643 9.34951 12.7228 9.34951 12.969C9.34951 13.2153 9.16704 13.3738 8.99121 13.3738C8.9912 13.3738 8.99119 13.3738 8.99118 13.3738L4.73245 13.3735L3.81569 13.3734L4.43916 14.0455L5.44248 15.1271L5.44254 15.1271C5.59628 15.2928 5.59628 15.5729 5.44254 15.7386C5.30466 15.8871 5.09437 15.8871 4.95649 15.7386L4.95639 15.7385L2.83035 13.4492L2.79581 13.412L2.75308 13.3846C2.72864 13.3689 2.70448 13.3491 2.68149 13.3243L2.38829 13.5964L2.68149 13.3243C2.60464 13.2415 2.5631 13.1265 2.56635 13.0055L2.56662 12.9957L2.5664 12.9858L2.56624 12.9741L2.56706 12.9508Z"
            fill="white"
            stroke="white"
            strokeWidth="0.8"
            transform="matrix(0 1 1 0 30 32)"
          />
          <path
            d="M23.4314 12.9891L23.4321 12.9748L23.431 12.9463L23.4316 12.9462C23.4249 12.8091 23.3582 12.6959 23.2689 12.6299L23.2593 12.623L23.248 12.6155L23.2035 12.5873L23.1679 12.5486L21.061 10.2603L21.061 10.2602C20.9257 10.1133 20.7212 10.1133 20.5859 10.2602C20.4331 10.4262 20.4331 10.708 20.5859 10.8741L20.5859 10.8741L21.5238 11.8933L22.1411 12.5642L21.2295 12.5642L17.0071 12.5643H17.0071C16.8313 12.5643 16.6488 12.7228 16.6488 12.969C16.6488 13.2153 16.8312 13.3738 17.0071 13.3738C17.0071 13.3738 17.0071 13.3738 17.0071 13.3738L21.2864 13.3735L22.1979 13.3734L21.5808 14.0443L20.586 15.1258L20.5859 15.1259C20.4331 15.292 20.4331 15.5738 20.5859 15.7398C20.7212 15.8867 20.9257 15.8867 21.061 15.7398L21.0611 15.7397L23.1687 13.4511L23.2034 13.4134L23.2465 13.3857C23.2706 13.3702 23.2946 13.3504 23.3175 13.3255C23.394 13.2424 23.4356 13.1263 23.4319 13.0036L23.4314 12.9891Z"
            fill="white"
            stroke="white"
            strokeWidth="0.8"
            transform="matrix(0 1 1 0 30 32)"
          />
        </g>
      );
    case SITE_TYPES.multi:
      return (
        <g key="central-icon-multi" onClick={locOnClick}>
          <circle className="central-icon-fill" opacity="0.1" r="12" transform="matrix(0 1 1 0 43 43)" fill={color} />
          <circle
            className="central-icon-fill"
            opacity="0.25"
            r="9.17647"
            transform="matrix(0 1 1 0 43 43.0002)"
            fill={color}
          />
          <circle className="central-icon-fill" r="6.35294" transform="matrix(0 1 1 0 42.9999 42.9999)" fill={color} />
        </g>
      );
    case SITE_TYPES.single:
    default:
      return (
        <g key="central-icon-single" onClick={locOnClick}>
          <circle opacity="0.1" r="12" transform="matrix(0 1 1 0 43 43)" fill="transparent" />
          <path
            d="M36 50.1067L38.2256 50.1067L41.8169 37.4197C41.477 37.1837 41.2246 36.852 41.0957 36.472C40.9669 36.092 40.9681 35.6832 41.0993 35.304C41.2305 34.9248 41.485 34.5945 41.8263 34.3603C42.1677 34.1261 42.5784 34 43 34C43.4216 34 43.8323 34.1261 44.1737 34.3603C44.515 34.5945 44.7695 34.9248 44.9007 35.304C45.0319 35.6833 45.0331 36.092 44.9043 36.472C44.7754 36.852 44.523 37.1837 44.1831 37.4197L47.7744 50.1067L50 50.1067C50.2652 50.1067 50.5196 50.2065 50.7071 50.384C50.8946 50.5615 51 50.8023 51 51.0534C51 51.3044 50.8946 51.5452 50.7071 51.7227C50.5196 51.9003 50.2652 52 50 52L36 52C35.7348 52 35.4804 51.9003 35.2929 51.7227C35.1054 51.5452 35 51.3044 35 51.0534C35 50.8023 35.1054 50.5615 35.2929 50.384C35.4804 50.2065 35.7348 50.1067 36 50.1067ZM44.3453 43.4804L41.6547 43.4804L40.8508 46.3202L45.1492 46.3202L44.3453 43.4804ZM43.9433 42.0604L43 38.7278L42.0566 42.0604L43.9433 42.0604ZM45.5511 47.7402L40.4489 47.7402L39.7789 50.1067L46.2211 50.1067L45.5511 47.7402Z"
            fill={color}
            className="central-icon-fill"
          />
        </g>
      );
  }
};

CentralIcon.propTypes = {
  color: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  item: PropTypes.objectOf(PropTypes.any),
};

CentralIcon.defaultProps = {
  color: palette.red['500'],
  type: SITE_TYPES.single,
  onClick: null,
  item: null,
};

export const LeftWing = ({color, level, colorArr, items, onClick}) => {
  const locLevel = items ? items.length : level;
  const end = 5;
  const start = end - locLevel < 0 ? 0 : end - locLevel;

  const locOnClick = (ev, item) => {
    if (onClick) {
      onClick(ev, item);
    }
  };

  const leaves = [
    <g key="left-wing-5" onClick={(ev) => locOnClick(ev, items ? items[4] : 'l4')}>
      <path
        d="M10.6393 66.5114C9.7457 67.1607 8.49056 66.9649 7.88457 66.0414C3.40092 59.2085 1 51.2016 1 43C1 34.7984 3.40092 26.7915 7.88457 19.9586C8.49055 19.0351 9.7457 18.8393 10.6393 19.4886L30.8694 34.1866C31.763 34.8359 31.9453 36.0816 31.4407 37.0642C30.502 38.8922 30.0058 40.926 30.0058 43C30.0058 45.074 30.502 47.1078 31.4407 48.9358C31.9453 49.9184 31.763 51.1641 30.8694 51.8134L10.6393 66.5114Z"
        fill={colorArr ? colorArr[4] || color : color}
        className="wing"
      />
      <path
        d="M10.6393 66.5114C9.7457 67.1607 8.49056 66.9649 7.88457 66.0414C3.40092 59.2085 1 51.2016 1 43C1 34.7984 3.40092 26.7915 7.88457 19.9586C8.49055 19.0351 9.7457 18.8393 10.6393 19.4886L30.8694 34.1866C31.763 34.8359 31.9453 36.0816 31.4407 37.0642C30.502 38.8922 30.0058 40.926 30.0058 43C30.0058 45.074 30.502 47.1078 31.4407 48.9358C31.9453 49.9184 31.763 51.1641 30.8694 51.8134L10.6393 66.5114Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#left-wing-1)"
      />
    </g>,
    <g key="left-wing-4" onClick={(ev) => locOnClick(ev, items ? items[3] : 'l3')}>
      <path
        d="M13.8754 64.1603C9.40678 58.0098 7 50.6025 7 43C7 35.3975 9.40678 27.9902 13.8754 21.8397L32.4327 35.3224C30.8113 37.554 29.938 40.2416 29.938 43C29.938 45.7584 30.8113 48.446 32.4327 50.6776L13.8754 64.1603Z"
        fill={colorArr ? colorArr[3] || color : color}
        className="wing"
      />
      <path
        d="M13.8754 64.1603C9.40678 58.0098 7 50.6025 7 43C7 35.3975 9.40678 27.9902 13.8754 21.8397L32.4327 35.3224C30.8113 37.554 29.938 40.2416 29.938 43C29.938 45.7584 30.8113 48.446 32.4327 50.6776L13.8754 64.1603Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#left-wing-2)"
      />
    </g>,
    <g key="left-wing-3" onClick={(ev) => locOnClick(ev, items ? items[2] : 'l2')}>
      <path
        d="M18.7295 60.6336C15.0056 55.5081 13 49.3354 13 43C13 36.6646 15.0056 30.4919 18.7295 25.3664L32.4813 35.3577C30.8674 37.579 29.9981 40.2543 29.9981 43C29.9981 45.7457 30.8674 48.421 32.4813 50.6423L18.7295 60.6336Z"
        fill={colorArr ? colorArr[2] || color : color}
        className="wing"
      />
      <path
        d="M18.7295 60.6336C15.0056 55.5081 13 49.3354 13 43C13 36.6646 15.0056 30.4919 18.7295 25.3664L32.4813 35.3577C30.8674 37.579 29.9981 40.2543 29.9981 43C29.9981 45.7457 30.8674 48.421 32.4813 50.6423L18.7295 60.6336Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#left-wing-3)"
      />
    </g>,
    <g key="left-wing-2" onClick={(ev) => locOnClick(ev, items ? items[1] : 'l1')}>
      <path
        d="M23.5836 57.1068C20.6045 53.0065 19 48.0683 19 43C19 37.9317 20.6045 32.9935 23.5836 28.8932L32.4541 35.3379C30.836 37.565 29.9645 40.2472 29.9645 43C29.9645 45.7528 30.836 48.435 32.4541 50.6621L23.5836 57.1068Z"
        fill={colorArr ? colorArr[1] || color : color}
        className="wing"
      />
      <path
        d="M23.5836 57.1068C20.6045 53.0065 19 48.0683 19 43C19 37.9317 20.6045 32.9935 23.5836 28.8932L32.4541 35.3379C30.836 37.565 29.9645 40.2472 29.9645 43C29.9645 45.7528 30.836 48.435 32.4541 50.6621L23.5836 57.1068Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#left-wing-4)"
      />
    </g>,
    <g key="left-wing-1" onClick={(ev) => locOnClick(ev, items ? items[0] : 'l0')}>
      <path
        d="M28.4377 53.5801C26.2034 50.5049 25 46.8012 25 43C25 39.1988 26.2034 35.4951 28.4377 32.4199L32.4937 35.3667C30.8817 37.5854 30.0135 40.2575 30.0135 43C30.0135 45.7425 30.8817 48.4146 32.4937 50.6333L28.4377 53.5801Z"
        fill={colorArr ? colorArr[0] || color : color}
        className="wing"
      />
      <path
        d="M28.4377 53.5801C26.2034 50.5049 25 46.8012 25 43C25 39.1988 26.2034 35.4951 28.4377 32.4199L32.4937 35.3667C30.8817 37.5854 30.0135 40.2575 30.0135 43C30.0135 45.7425 30.8817 48.4146 32.4937 50.6333L28.4377 53.5801Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#left-wing-5)"
      />
    </g>,
  ];

  return leaves.slice(start, end);
};

LeftWing.propTypes = {
  level: PropTypes.number,
  color: PropTypes.string,
  colorArr: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
};

LeftWing.defaultProps = {
  level: 5,
  color: palette.red['500'],
  colorArr: null,
  items: null,
  onClick: null,
};

export const RightWing = ({color, level, colorArr, items, onClick}) => {
  const locLevel = items ? items.length : level;
  const end = 5;
  const start = end - locLevel < 0 ? 0 : end - locLevel;

  const locOnClick = (ev, item) => {
    if (onClick) {
      onClick(ev, item);
    }
  };

  const leaves = [
    <g key="right-wing-5" onClick={(ev) => locOnClick(ev, items ? items[4] : 'r4')}>
      <path
        d="M75.7661 20.057C76.6709 19.4234 77.9224 19.641 78.5122 20.5749C82.8759 27.4851 85.1367 35.5327 84.9936 43.733C84.8505 51.9333 82.3102 59.8971 77.708 66.6508C77.0859 67.5636 75.8276 67.7373 74.9454 67.0726L54.9749 52.0238C54.0928 51.359 53.9323 50.1103 54.4539 49.1367C55.4244 47.3253 55.9561 45.3005 55.9923 43.2268C56.0285 41.1531 55.5678 39.111 54.6611 37.2668C54.1738 36.2756 54.3778 35.0332 55.2826 34.3997L75.7661 20.057Z"
        fill={colorArr ? colorArr[4] || color : color}
        className="wing"
      />
      <path
        d="M75.7661 20.057C76.6709 19.4234 77.9224 19.641 78.5122 20.5749C82.8759 27.4851 85.1367 35.5327 84.9936 43.733C84.8505 51.9333 82.3102 59.8971 77.708 66.6508C77.0859 67.5636 75.8276 67.7373 74.9454 67.0726L54.9749 52.0238C54.0928 51.359 53.9323 50.1103 54.4539 49.1367C55.4244 47.3253 55.9561 45.3005 55.9923 43.2268C56.0285 41.1531 55.5678 39.111 54.6611 37.2668C54.1738 36.2756 54.3778 35.0332 55.2826 34.3997L75.7661 20.057Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#right-wing-1)"
      />
    </g>,
    <g key="right-wing-4" onClick={(ev) => locOnClick(ev, items ? items[3] : 'r3')}>
      <path
        d="M72.4895 22.3513C76.8501 28.5788 79.1272 36.027 78.9945 43.6283C78.8618 51.2296 76.3261 58.5938 71.7509 64.6653L53.4317 50.8609C55.0918 48.6579 56.0118 45.986 56.06 43.228C56.1081 40.47 55.2819 37.7675 53.6997 35.508L72.4895 22.3513Z"
        fill={colorArr ? colorArr[3] || color : color}
        className="wing"
      />
      <path
        d="M72.4895 22.3513C76.8501 28.5788 79.1272 36.027 78.9945 43.6283C78.8618 51.2296 76.3261 58.5938 71.7509 64.6653L53.4317 50.8609C55.0918 48.6579 56.0118 45.986 56.06 43.228C56.1081 40.47 55.2819 37.7675 53.6997 35.508L72.4895 22.3513Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#right-wing-2)"
      />
    </g>,
    <g key="right-wing-3" onClick={(ev) => locOnClick(ev, items ? items[2] : 'r2')}>
      <path
        d="M67.5746 25.7927C71.2084 30.9824 73.106 37.1892 72.9954 43.5236C72.8849 49.858 70.7718 55.9948 66.9591 61.0544L53.3838 50.8247C55.0362 48.6319 55.952 45.9722 55.9999 43.2269C56.0478 40.4816 55.2254 37.7916 53.6505 35.5424L67.5746 25.7927Z"
        fill={colorArr ? colorArr[2] || color : color}
        className="wing"
      />
      <path
        d="M67.5746 25.7927C71.2084 30.9824 73.106 37.1892 72.9954 43.5236C72.8849 49.858 70.7718 55.9948 66.9591 61.0544L53.3838 50.8247C55.0362 48.6319 55.952 45.9722 55.9999 43.2269C56.0478 40.4816 55.2254 37.7916 53.6505 35.5424L67.5746 25.7927Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#right-wing-3)"
      />
    </g>,
    <g key="right-wing-2" onClick={(ev) => locOnClick(ev, items ? items[1] : 'r1')}>
      <path
        d="M62.6597 29.2342C65.5667 33.3859 67.0848 38.3513 66.9963 43.4189C66.9079 48.4864 65.2174 53.3958 62.1673 57.4436L53.4106 50.845C55.0673 48.6465 55.9855 45.9799 56.0335 43.2275C56.0816 40.4751 55.257 37.7781 53.6781 35.5231L62.6597 29.2342Z"
        fill={colorArr ? colorArr[1] || color : color}
        className="wing"
      />
      <path
        d="M62.6597 29.2342C65.5667 33.3859 67.0848 38.3513 66.9963 43.4189C66.9079 48.4864 65.2174 53.3958 62.1673 57.4436L53.4106 50.845C55.0673 48.6465 55.9855 45.9799 56.0335 43.2275C56.0816 40.4751 55.257 37.7781 53.6781 35.5231L62.6597 29.2342Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#right-wing-4)"
      />
    </g>,
    <g key="right-wing-1" onClick={(ev) => locOnClick(ev, items ? items[0] : 'r0')}>
      <path
        d="M57.7447 32.6756C59.925 35.7894 61.0636 39.5135 60.9973 43.3141C60.9309 47.1148 59.6631 50.7969 57.3754 53.8327L53.3715 50.8155C55.022 48.6252 55.9367 45.9687 55.9846 43.2266C56.0324 40.4846 55.211 37.7977 53.6379 35.5512L57.7447 32.6756Z"
        fill={colorArr ? colorArr[0] || color : color}
        className="wing"
      />
      <path
        d="M57.7447 32.6756C59.925 35.7894 61.0636 39.5135 60.9973 43.3141C60.9309 47.1148 59.6631 50.7969 57.3754 53.8327L53.3715 50.8155C55.022 48.6252 55.9367 45.9687 55.9846 43.2266C56.0324 40.4846 55.211 37.7977 53.6379 35.5512L57.7447 32.6756Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#right-wing-5)"
      />
    </g>,
  ];

  return leaves.slice(start, end);
};

RightWing.propTypes = {
  level: PropTypes.number,
  color: PropTypes.string,
  colorArr: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
};

RightWing.defaultProps = {
  level: 5,
  color: palette.red['500'],
  colorArr: null,
  items: null,
  onClick: null,
};

export const TopWing = ({color, level, colorArr, items, onClick}) => {
  const locLevel = items ? items.length : level;
  const end = 5;
  const start = end - locLevel < 0 ? 0 : end - locLevel;

  const locOnClick = (ev, item) => {
    if (onClick) {
      onClick(ev, item);
    }
  };

  const leaves = [
    <g key="top-wing-5" onClick={(ev) => locOnClick(ev, items ? items[4] : 't4')}>
      <path
        d="M19.4886 10.6393C18.8393 9.7457 19.0351 8.49055 19.9586 7.88457C26.7915 3.40092 34.7984 1 43 1C51.2016 1 59.2085 3.40092 66.0414 7.88457C66.9649 8.49055 67.1607 9.7457 66.5114 10.6393L51.8134 30.8694C51.1641 31.763 49.9184 31.9453 48.9358 31.4407C47.1078 30.502 45.074 30.0058 43 30.0058C40.926 30.0058 38.8922 30.502 37.0642 31.4407C36.0816 31.9453 34.8359 31.763 34.1866 30.8694L19.4886 10.6393Z"
        fill={colorArr ? colorArr[4] || color : color}
        className="wing"
      />
      <path
        d="M19.4886 10.6393C18.8393 9.7457 19.0351 8.49055 19.9586 7.88457C26.7915 3.40092 34.7984 1 43 1C51.2016 1 59.2085 3.40092 66.0414 7.88457C66.9649 8.49055 67.1607 9.7457 66.5114 10.6393L51.8134 30.8694C51.1641 31.763 49.9184 31.9453 48.9358 31.4407C47.1078 30.502 45.074 30.0058 43 30.0058C40.926 30.0058 38.8922 30.502 37.0642 31.4407C36.0816 31.9453 34.8359 31.763 34.1866 30.8694L19.4886 10.6393Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#top-wing-1)"
      />
    </g>,
    <g key="top-wing-4" onClick={(ev) => locOnClick(ev, items ? items[3] : 't3')}>
      <path
        d="M21.8397 13.8754C27.9902 9.40678 35.3976 7 43 7C50.6025 7 58.0098 9.40678 64.1603 13.8754L50.6776 32.4327C48.446 30.8113 45.7584 29.938 43 29.938C40.2416 29.938 37.554 30.8113 35.3224 32.4327L21.8397 13.8754Z"
        fill={colorArr ? colorArr[3] || color : color}
        className="wing"
      />
      <path
        d="M21.8397 13.8754C27.9902 9.40678 35.3976 7 43 7C50.6025 7 58.0098 9.40678 64.1603 13.8754L50.6776 32.4327C48.446 30.8113 45.7584 29.938 43 29.938C40.2416 29.938 37.554 30.8113 35.3224 32.4327L21.8397 13.8754Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#top-wing-2)"
      />
    </g>,
    <g key="top-wing-3" onClick={(ev) => locOnClick(ev, items ? items[2] : 't2')}>
      <path
        d="M25.3664 18.7295C30.4919 15.0056 36.6646 13 43 13C49.3354 13 55.5081 15.0056 60.6336 18.7295L50.6423 32.4813C48.421 30.8674 45.7457 29.9981 43 29.9981C40.2543 29.9981 37.579 30.8674 35.3577 32.4813L25.3664 18.7295Z"
        fill={colorArr ? colorArr[2] || color : color}
        className="wing"
      />
      <path
        d="M25.3664 18.7295C30.4919 15.0056 36.6646 13 43 13C49.3354 13 55.5081 15.0056 60.6336 18.7295L50.6423 32.4813C48.421 30.8674 45.7457 29.9981 43 29.9981C40.2543 29.9981 37.579 30.8674 35.3577 32.4813L25.3664 18.7295Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#top-wing-3)"
      />
    </g>,
    <g key="top-wing-2" onClick={(ev) => locOnClick(ev, items ? items[1] : 't1')}>
      <path
        d="M28.8932 23.5836C32.9935 20.6045 37.9317 19 43 19C48.0683 19 53.0065 20.6045 57.1068 23.5836L50.6621 32.4541C48.435 30.836 45.7528 29.9645 43 29.9645C40.2472 29.9645 37.565 30.836 35.3379 32.4541L28.8932 23.5836Z"
        fill={colorArr ? colorArr[1] || color : color}
        className="wing"
      />
      <path
        d="M28.8932 23.5836C32.9935 20.6045 37.9317 19 43 19C48.0683 19 53.0065 20.6045 57.1068 23.5836L50.6621 32.4541C48.435 30.836 45.7528 29.9645 43 29.9645C40.2472 29.9645 37.565 30.836 35.3379 32.4541L28.8932 23.5836Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#top-wing-4)"
      />
    </g>,
    <g key="top-wing-1" onClick={(ev) => locOnClick(ev, items ? items[0] : 't0')}>
      <path
        d="M32.4199 28.4377C35.4951 26.2034 39.1988 25 43 25C46.8012 25 50.5049 26.2034 53.5801 28.4377L50.6333 32.4937C48.4146 30.8817 45.7425 30.0135 43 30.0135C40.2575 30.0135 37.5854 30.8817 35.3667 32.4937L32.4199 28.4377Z"
        fill={colorArr ? colorArr[0] || color : color}
        className="wing"
      />
      <path
        d="M32.4199 28.4377C35.4951 26.2034 39.1988 25 43 25C46.8012 25 50.5049 26.2034 53.5801 28.4377L50.6333 32.4937C48.4146 30.8817 45.7425 30.0135 43 30.0135C40.2575 30.0135 37.5854 30.8817 35.3667 32.4937L32.4199 28.4377Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#top-wing-5)"
      />
    </g>,
  ];

  return leaves.slice(start, end);
};

TopWing.propTypes = {
  level: PropTypes.number,
  color: PropTypes.string,
  colorArr: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
};

TopWing.defaultProps = {
  level: 5,
  color: palette.red['500'],
  colorArr: null,
  items: null,
  onClick: null,
};

export const WingMaskDef = () => {
  return (
    <Fragment>
      <mask id="left-wing-1" maskUnits="userSpaceOnUse" x="0" y="18" width="33" height="50" fill="black">
        <rect fill="white" y="18" width="33" height="50" />
        <path d="M10.6393 66.5114C9.7457 67.1607 8.49056 66.9649 7.88457 66.0414C3.40092 59.2085 1 51.2016 1 43C1 34.7984 3.40092 26.7915 7.88457 19.9586C8.49055 19.0351 9.7457 18.8393 10.6393 19.4886L30.8694 34.1866C31.763 34.8359 31.9453 36.0816 31.4407 37.0642C30.502 38.8922 30.0058 40.926 30.0058 43C30.0058 45.074 30.502 47.1078 31.4407 48.9358C31.9453 49.9184 31.763 51.1641 30.8694 51.8134L10.6393 66.5114Z" />
      </mask>
      <mask id="left-wing-2" maskUnits="userSpaceOnUse" x="6" y="20" width="28" height="46" fill="black">
        <rect fill="white" x="6" y="20" width="28" height="46" />
        <path d="M13.8754 64.1603C9.40678 58.0098 7 50.6025 7 43C7 35.3975 9.40678 27.9902 13.8754 21.8397L32.4327 35.3224C30.8113 37.554 29.938 40.2416 29.938 43C29.938 45.7584 30.8113 48.446 32.4327 50.6776L13.8754 64.1603Z" />
      </mask>
      <mask id="left-wing-3" maskUnits="userSpaceOnUse" x="12" y="23" width="22" height="40" fill="black">
        <rect fill="white" x="12" y="23" width="22" height="40" />
        <path d="M18.7295 60.6336C15.0056 55.5081 13 49.3354 13 43C13 36.6646 15.0056 30.4919 18.7295 25.3664L32.4813 35.3577C30.8674 37.579 29.9981 40.2543 29.9981 43C29.9981 45.7457 30.8674 48.421 32.4813 50.6423L18.7295 60.6336Z" />
      </mask>
      <mask id="left-wing-4" maskUnits="userSpaceOnUse" x="18" y="27" width="16" height="32" fill="black">
        <rect fill="white" x="18" y="27" width="16" height="32" />
        <path d="M23.5836 57.1068C20.6045 53.0065 19 48.0683 19 43C19 37.9317 20.6045 32.9935 23.5836 28.8932L32.4541 35.3379C30.836 37.565 29.9645 40.2472 29.9645 43C29.9645 45.7528 30.836 48.435 32.4541 50.6621L23.5836 57.1068Z" />
      </mask>
      <mask id="left-wing-5" maskUnits="userSpaceOnUse" x="24" y="31" width="10" height="24" fill="black">
        <rect fill="white" x="24" y="31" width="10" height="24" />
        <path d="M28.4377 53.5801C26.2034 50.5049 25 46.8012 25 43C25 39.1988 26.2034 35.4951 28.4377 32.4199L32.4937 35.3667C30.8817 37.5854 30.0135 40.2575 30.0135 43C30.0135 45.7425 30.8817 48.4146 32.4937 50.6333L28.4377 53.5801Z" />
      </mask>
      <mask id="right-wing-1" maskUnits="userSpaceOnUse" x="53" y="18" width="33" height="51" fill="black">
        <rect fill="white" x="53" y="18" width="33" height="51" />
        <path d="M75.7661 20.057C76.6709 19.4234 77.9224 19.641 78.5122 20.5749C82.8759 27.4851 85.1367 35.5327 84.9936 43.733C84.8505 51.9333 82.3102 59.8971 77.708 66.6508C77.0859 67.5636 75.8276 67.7373 74.9454 67.0726L54.9749 52.0238C54.0928 51.359 53.9323 50.1103 54.4539 49.1367C55.4244 47.3253 55.9561 45.3005 55.9923 43.2268C56.0285 41.1531 55.5678 39.111 54.6611 37.2668C54.1738 36.2756 54.3778 35.0332 55.2826 34.3997L75.7661 20.057Z" />
      </mask>
      <mask id="right-wing-2" maskUnits="userSpaceOnUse" x="52" y="20" width="28" height="47" fill="black">
        <rect fill="white" x="52" y="20" width="28" height="47" />
        <path d="M72.4895 22.3513C76.8501 28.5788 79.1272 36.027 78.9945 43.6283C78.8618 51.2296 76.3261 58.5938 71.7509 64.6653L53.4317 50.8609C55.0918 48.6579 56.0118 45.986 56.06 43.228C56.1081 40.47 55.2819 37.7675 53.6997 35.508L72.4895 22.3513Z" />
      </mask>
      <mask id="right-wing-3" maskUnits="userSpaceOnUse" x="51" y="24" width="23" height="39" fill="black">
        <rect fill="white" x="51" y="24" width="23" height="39" />
        <path d="M67.5746 25.7927C71.2084 30.9824 73.106 37.1892 72.9954 43.5236C72.8849 49.858 70.7718 55.9948 66.9591 61.0544L53.3838 50.8247C55.0362 48.6319 55.952 45.9722 55.9999 43.2269C56.0478 40.4816 55.2254 37.7916 53.6505 35.5424L67.5746 25.7927Z" />
      </mask>
      <mask id="right-wing-4" maskUnits="userSpaceOnUse" x="52" y="27" width="16" height="32" fill="black">
        <rect fill="white" x="52" y="27" width="16" height="32" />
        <path d="M62.6597 29.2342C65.5667 33.3859 67.0848 38.3513 66.9963 43.4189C66.9079 48.4864 65.2174 53.3958 62.1673 57.4436L53.4106 50.845C55.0673 48.6465 55.9855 45.9799 56.0335 43.2275C56.0816 40.4751 55.257 37.7781 53.6781 35.5231L62.6597 29.2342Z" />
      </mask>
      <mask id="right-wing-5" maskUnits="userSpaceOnUse" x="51" y="31" width="11" height="25" fill="black">
        <rect fill="white" x="51" y="31" width="11" height="25" />
        <path d="M57.7447 32.6756C59.925 35.7894 61.0636 39.5135 60.9973 43.3141C60.9309 47.1148 59.6631 50.7969 57.3754 53.8327L53.3715 50.8155C55.022 48.6252 55.9367 45.9687 55.9846 43.2266C56.0324 40.4846 55.211 37.7977 53.6379 35.5512L57.7447 32.6756Z" />
      </mask>
      <mask id="top-wing-1" maskUnits="userSpaceOnUse" x="18" y="0" width="50" height="33" fill="black">
        <rect fill="white" x="18" width="50" height="33" />
        <path d="M19.4886 10.6393C18.8393 9.7457 19.0351 8.49055 19.9586 7.88457C26.7915 3.40092 34.7984 1 43 1C51.2016 1 59.2085 3.40092 66.0414 7.88457C66.9649 8.49055 67.1607 9.7457 66.5114 10.6393L51.8134 30.8694C51.1641 31.763 49.9184 31.9453 48.9358 31.4407C47.1078 30.502 45.074 30.0058 43 30.0058C40.926 30.0058 38.8922 30.502 37.0642 31.4407C36.0816 31.9453 34.8359 31.763 34.1866 30.8694L19.4886 10.6393Z" />
      </mask>
      <mask id="top-wing-2" maskUnits="userSpaceOnUse" x="20" y="6" width="46" height="28" fill="black">
        <rect fill="white" x="20" y="6" width="46" height="28" />
        <path d="M21.8397 13.8754C27.9902 9.40678 35.3976 7 43 7C50.6025 7 58.0098 9.40678 64.1603 13.8754L50.6776 32.4327C48.446 30.8113 45.7584 29.938 43 29.938C40.2416 29.938 37.554 30.8113 35.3224 32.4327L21.8397 13.8754Z" />
      </mask>
      <mask id="top-wing-3" maskUnits="userSpaceOnUse" x="23" y="12" width="40" height="22" fill="black">
        <rect fill="white" x="23" y="12" width="40" height="22" />
        <path d="M25.3664 18.7295C30.4919 15.0056 36.6646 13 43 13C49.3354 13 55.5081 15.0056 60.6336 18.7295L50.6423 32.4813C48.421 30.8674 45.7457 29.9981 43 29.9981C40.2543 29.9981 37.579 30.8674 35.3577 32.4813L25.3664 18.7295Z" />
      </mask>
      <mask id="top-wing-4" maskUnits="userSpaceOnUse" x="27" y="18" width="32" height="16" fill="black">
        <rect fill="white" x="27" y="18" width="32" height="16" />
        <path d="M28.8932 23.5836C32.9935 20.6045 37.9317 19 43 19C48.0683 19 53.0065 20.6045 57.1068 23.5836L50.6621 32.4541C48.435 30.836 45.7528 29.9645 43 29.9645C40.2472 29.9645 37.565 30.836 35.3379 32.4541L28.8932 23.5836Z" />
      </mask>
      <mask id="top-wing-5" maskUnits="userSpaceOnUse" x="31" y="24" width="24" height="10" fill="black">
        <rect fill="white" x="31" y="24" width="24" height="10" />
        <path d="M32.4199 28.4377C35.4951 26.2034 39.1988 25 43 25C46.8012 25 50.5049 26.2034 53.5801 28.4377L50.6333 32.4937C48.4146 30.8817 45.7425 30.0135 43 30.0135C40.2575 30.0135 37.5854 30.8817 35.3667 32.4937L32.4199 28.4377Z" />
      </mask>
    </Fragment>
  );
};

const SiteSvg = ({onClick, data}) => {
  return (
    <svg
      width="86"
      height="85"
      viewBox="0 0 86 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <g className="central-icon">
        <CentralIcon
          item={data.center.item}
          color={data.center.color}
          type={data.center.type || SITE_TYPES.single}
          onClick={onClick}
        />
      </g>
      <g className="left-wing">
        <LeftWing items={data.left.items} level={0} colorArr={data.left.colorArr} onClick={onClick} />
      </g>
      <g className="right-wing">
        <RightWing items={data.right.items} level={0} colorArr={data.right.colorArr} onClick={onClick} />
      </g>
      <g className="top-wing">
        <TopWing items={data.top.items} level={0} colorArr={data.top.colorArr} onClick={onClick} />
      </g>
    </svg>
  );
};

SiteSvg.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onClick: PropTypes.func,
};

SiteSvg.defaultProps = {
  onClick: () => {},
};

export default SiteSvg;
