// @flow
import React, {Fragment} from 'react';
import Box from '@material-ui/core/Box';
import {options} from 'userSettings/store/selectors';
import SubTitle from 'userSettings/components/SubTitle';
import RadioItem from 'userSettings/components/RadioItem';
import SamlAuth from './SamlAuth';
import Google0Auth from './Google0Auth';
import AzureActiveDirectoryAuth from './AzureActiveDirectoryAuth';

const renderBody = (name) => {
  switch (name) {
    case options[0].nameField:
      return <SamlAuth />;
    case options[1].nameField:
      return <Google0Auth />;
    case options[2].nameField:
      return <AzureActiveDirectoryAuth />;
    default:
      return null;
  }
};

const SsoConfigurationsSection = () => (
  <Fragment>
    <SubTitle title="Single Sign-On Configuration" />
    {options.map((item) => (
      <Box mb={4} key={item.nameField}>
        <RadioItem item={item} groupName="radioSignOnConfig">
          {renderBody(item.nameField)}
        </RadioItem>
      </Box>
    ))}
  </Fragment>
);

export default SsoConfigurationsSection;
