// @flow

import React from 'react';
import {connect} from 'react-redux';
import * as actions from 'common/store/actions';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import {getRoutingLocation} from 'common/store/selectors';

import './Snackbar.module.scss';

type PropTypes = {
  notificationData: {
    title: String,
    description: String,
    moreInfo: String,
    children: any,
    isOpenInfoInModal: boolean,
  },
  type: String,
  uid: String,
  isDismissible: boolean,
  actions: [],
  // eslint-disable-next-line react/no-unused-prop-types
  location: any,

  // connect
  removeNotification: Function,
  segmentClickEvent: Function,
};

export const TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
};

@connect(
  (state) => ({
    location: getRoutingLocation(state),
  }),
  {
    removeNotification: actions.removeNotification,
    segmentClickEvent: actions.segmentClickEvent,
  },
)
export default class SnackBar extends React.PureComponent {
  props: PropTypes;

  state = {
    moreInfo: false,
    isModalOpen: false,
  };

  componentDidMount() {
    this.props.segmentClickEvent({
      type: 'notification',
      notificationType: this.props.type,
      notificationData: this.props.notificationData,
    });
  }

  removeNotification = () => {
    const {removeNotification, uid} = this.props;
    removeNotification(uid);
    this.onModalClose();
  };

  showMoreInfo = () => {
    if (this.props.notificationData.isOpenInfoInModal) {
      this.setState({isModalOpen: true});
    } else {
      const {moreInfo} = this.state;
      this.setState({moreInfo: !moreInfo});
    }
  };

  onModalClose = () => {
    this.setState({isModalOpen: false});
  };

  render() {
    const {notificationData, type, isDismissible} = this.props;
    const formattedDescription =
      notificationData.description && notificationData.description.length
        ? notificationData.description.split('\n')
        : null;
    const {moreInfo} = this.state;

    return (
      <div
        styleName={`notification-child-container ${type}`}
        ref={(e) => {
          this.notificationContainer = e;
        }}
      >
        <Modal
          isCloseButtonHidden={false}
          size={SIZES.LARGE}
          isOpen={this.state.isModalOpen}
          onClose={this.onModalClose}
        >
          <div styleName="modal-message">
            <p>{notificationData.moreInfo}</p>
          </div>
        </Modal>
        {isDismissible && <i className="icn-general16-closeb" onClick={this.removeNotification} />}
        {notificationData.title && <h2>{notificationData.title}</h2>}

        {notificationData.children && <p>{notificationData.children}</p>}

        {formattedDescription && (
          <div>
            {formattedDescription.map((item) => (
              <p key={item}>{item}</p>
            ))}
            {moreInfo && <p>{notificationData.moreInfo}</p>}
          </div>
        )}

        {!notificationData.description && notificationData.moreInfo && <p>{notificationData.moreInfo}</p>}

        {(this.props.actions && this.props.actions.length) ||
        (notificationData.description && notificationData.moreInfo && !moreInfo) ? (
          <div styleName="actions-bar">
            {notificationData.description && notificationData.moreInfo && !moreInfo && (
              <a styleName="more-info-btn" onClick={this.showMoreInfo}>
                <span>More Info</span>
              </a>
            )}

            {this.props.actions &&
              this.props.actions.length &&
              this.props.actions.map((action) => (
                <a styleName="more-info-btn" onClick={action.callback} key={`snakeBar-action-${action.label}`}>
                  <span>{action.label}</span>
                </a>
              ))}
          </div>
        ) : null}
      </div>
    );
  }
}
