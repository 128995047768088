// @flow
import React from 'react';
import {useSelector} from 'react-redux';
import Loader from 'common/components/Loader';
import {Route, Switch, Redirect} from 'react-router-dom';
import {selectors as commonSelectors} from 'common';
import ErrorBoundary from 'errorBoundaries/ErrorBoundary';
import AddDataSource from 'bc/pages/AddDataSource';
import DataManagerRouter from 'bc/pages/DataManagerRouter';
import StyleGuide from 'styleGuide/pages/StyleGuide';
import StyleGuideV2 from 'styleGuide/pages/StyleGuideV2';
import DataStreamEditor from 'bc/pages/DataStreamEditor';
import DataStreamPreview from 'bc/pages/DataStreamPreview';
import AlertsConsole from 'alerts.console/pages/AlertsConsole';
import CubeEditor from 'bc.cubes/pages/CubeEditor';
import CubesManager from 'bc.cubes/pages/CubesManager';
import DnsTrackerPage from 'admin.dnsTracker/pages/DnsTrackerPage';
import FeaturesFlags from 'admin.features/pages/FeatureFlags';
import TrainTrackerPage from 'admin.trainTracker/pages/TrainTrackerPage';
import CsPortal from 'admin.cs-portal/pages/CsPortal';
import EmailDigest from 'admin.email-digest/pages/EmailDigest';
import UsersAndGroups from 'admin.users/pages/UsersAndGroups';
import ActivityLog from 'admin.activityLog/pages/ActivityLog';
import CustomersRouter from 'admin.customers/routers/CustomerRouter';
import Page404 from 'common/pages/404';
import TopologyRouter from 'topologyGeneral/pages/TopologyRouter';

import UserEvents from 'userEvents/pages/UserEvents';
import AlertManager from 'alerts.management/pages/AlertManager';
import AlertSettings from 'alerts.management/pages/AlertSettings';
import AssetsManagement from 'assets/pages/AssetsManagement';
import ExpressionBuilderStyleGuide from 'common/componentsV2/ExpressionBuilderV2/ExpressionBuilderStyleGuide';
import EventExpressionBuilderDisplayContainer from 'userEvents/components/eventsExpressionBuilder/EventExpressionBuilderDisplayContainer';
import UserSettings from 'userSettings/pages/UserSettings';
import MetricExplorer from 'metrics/components/metricExplorer/MetricExplorer';
import DashboardsRouter from 'dashboards/components/routers/DashboardRouter';
import IntegrationsPage from 'bc/pages/integrations/IntegrationsPage';
import Channels from 'channels/pages/Channels';
import * as profileSelectors from 'profile/store/selectors';
import Overlays from './Overlays';

const Shell = () => {
  const isLoading = useSelector((state) => state.common.isLoading);
  const routingLocation = useSelector(commonSelectors.getRoutingLocation);
  const isAnodotAdmin = useSelector(profileSelectors.isAnodotAdmin);

  const isOverflowX = routingLocation.pathname.includes('/bc/data-streams/');

  // unmount react components for angular routes
  if (window.location.hash.indexOf('#!/r/') === -1 && window.location.hash !== '#!/') {
    return null;
  }

  return (
    <div
      automation-id="app-shell"
      className={`
        shell-col
        ${routingLocation.pathname.startsWith('/bc') ? 'bc' : ''}
        ${isOverflowX ? 'scrollX' : ''}
      `}
    >
      <Overlays />

      <ErrorBoundary showFallback={false}>
        <div className="shell-col">
          <div className="andt-page-outlet shell-col">
            {isLoading ? <Loader /> : null}
            <Switch>
              <Route
                exact
                path="/"
                render={() => <Redirect to={isAnodotAdmin ? '/admin/users-groups' : '/alerts-console'} />}
              />
              <Route exact path="/bc" render={() => <Redirect to="/bc/data-manager" />} />
              <Route path="/bc/data-manager" component={DataManagerRouter} />
              <Route path="/bc/sources-types" component={AddDataSource} />
              <Route exact path="/bc/data-streams/:id" component={DataStreamEditor} />
              <Route exact path="/bc/data-streams/:id/preview" component={DataStreamPreview} />

              <Route exact path="/asset-management" component={AssetsManagement} />

              <Route exact path="/bc/schemas/all/cubes" component={CubesManager} />
              <Route exact path="/bc/schemas/:schemaId/cube/:cubeId" component={CubeEditor} />
              <Route exact path="/bc/schemas/:schemaId/new-cube" component={CubeEditor} />

              <Route exact path="/alerts-console" component={AlertsConsole} />

              <Route path="/dashboards/:id?" component={DashboardsRouter} />

              <Route exact path="/user/settings" component={UserSettings} />
              <Route exact path="/user/events" component={UserEvents} />

              <Route exact path="/admin/dns-tracker" component={DnsTrackerPage} />
              <Route exact path="/admin/features-flags" component={FeaturesFlags} />
              <Route exact path="/admin/train-tracker" component={TrainTrackerPage} />
              <Route exact path="/admin/cs-portal" component={CsPortal} />
              <Route exact path="/admin/users-groups" component={UsersAndGroups} />
              <Route exact path="/admin/customers/:id?" component={CustomersRouter} />
              <Route exact path="/admin/email-digest" component={EmailDigest} />
              <Route exact path="/admin/activity-log" component={ActivityLog} />

              <Route path="/style-guide-v2" component={StyleGuideV2} />
              <Route path="/expression-builder" component={ExpressionBuilderStyleGuide} />
              <Route path="/event-expression-builder" component={EventExpressionBuilderDisplayContainer} />
              <Route path="/style-guide" component={StyleGuide} />
              <Route path="/alert-manager/edit/:alertId/settings" component={AlertSettings} />
              <Route path="/metrics-explorer" component={MetricExplorer} />
              <Route
                path="/alert-manager/clone/:alertId/settings"
                render={(props) => <AlertSettings isClone {...props} />}
              />
              <Route path="/alert-manager/new" component={AlertSettings} />
              <Route path="/alert-manager" component={AlertManager} />
              <Route path="/topology" component={TopologyRouter} />
              <Route path="/integrations" component={IntegrationsPage} />
              <Route path="/channels" component={Channels} />
              <Route path="/404" component={Page404} />
              <Route
                path="/:path"
                render={(data) => {
                  const browserUrl = window.location.hash.slice(4);
                  if (browserUrl.startsWith(data.match.url)) {
                    return <Redirect to={isAnodotAdmin ? '/admin/users-groups' : '/alerts-console'} />;
                  }
                  return null;
                }}
              />
            </Switch>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default Shell;
