import {cloneDeepWith} from 'lodash';

// eslint-disable-next-line consistent-return
const removeSelectorVariables = (value) => {
  if (value && value.type === 'metric' && value.searchObject) {
    return {
      ...value,
      searchObject: {
        ...value.searchObject,
        expression: value.searchObject.expression.filter(
          (expressionItem) => expressionItem.type !== 'selector' && !expressionItem.value.startsWith('$'),
        ),
      },
    };
  }
};

// eslint-disable-next-line import/prefer-default-export
export const makeTileDataCompatible = (data) => {
  const contentKey = data.lineChart ? 'lineChart' : 'andtGauge';
  return {
    ...data,
    expressionTreeType: data.expressionTreeType || 'advanced',
    [contentKey]: {
      ...data[contentKey],
      expressionTrees: cloneDeepWith(data[contentKey].expressionTrees, removeSelectorVariables),
    },
  };
};
