// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {
  fetchKinesisPathsAndValues as fetchKinesisPathsAndValuesAction,
  setKinesisStreamUiKeyVal as setKinesisStreamUiKeyValAction,
} from 'bc/store/actions';
import {
  isStreamEditable as isStreamEditableService,
  isStreamRunning as isStreamRunningService,
} from 'bc/services/dataStreamService';
import {KINESIS_RECORD_TYPES, KINESIS_RECORD_TYPES_DELIMITER} from 'bc/services/kinesisService';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import './KinesisStreamEditor.module.scss';
import SelectAndt, {THEME_LIGHT, TYPE_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {isEmpty} from 'lodash';

type PropTypes = {
  dataStream: Object,

  // connect
  fetchKinesisPathsAndValues: Function,
  setKinesisStreamUiKeyVal: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    fetchKinesisPathsAndValues: fetchKinesisPathsAndValuesAction,
    setKinesisStreamUiKeyVal: setKinesisStreamUiKeyValAction,
  },
)
export default class KinesisContext extends React.PureComponent {
  props: PropTypes;

  state = {
    recordTypeFilter: this.props.dataStream.recordType || '',
  };

  componentDidMount() {
    const {dataStream, fetchKinesisPathsAndValues, setKinesisStreamUiKeyVal} = this.props;
    const {dataSourceId} = dataStream;

    if (this.state.recordTypeFilter !== '') {
      this.setUpdatedRecord({
        recordType: `${this.state.recordTypeFilter.split('json')[0]}json`,
        recordTypeDelimiter: this.state.recordTypeFilter.split('json')[1],
      });
      fetchKinesisPathsAndValues({
        type: dataStream.type,
        dataSourceId,
        recordType: this.state.recordTypeFilter,
      });
    } else {
      this.setUpdatedRecord({
        recordType: '',
        recordTypeDelimiter: '',
      });
    }

    // edit running stream
    if (isStreamRunningService(dataStream.state)) {
      let shouldUpdate = false;
      let timeDefinitionObj = {};
      const streamSchemaArr = [];
      const filtersArr = [];

      // fill uiState analysisResult streamSchema
      if (
        !dataStream.uiState.analysisResult ||
        (isEmpty(dataStream.uiState.analysisResult.streamSchema) && !isEmpty(dataStream.schema.columns))
      ) {
        if (dataStream.metrics.length || dataStream.dimensions.length) {
          dataStream.metrics.forEach((item) => {
            streamSchemaArr.push({
              path: item,
              type: 'metric',
              possibleTypes: ['metric'],
            });
          });
          dataStream.dimensions.forEach((item) => {
            streamSchemaArr.push({
              path: item,
              type: 'dimension',
              possibleTypes: ['dimension'],
            });
          });
          shouldUpdate = true;
        }
      }

      // fill uiState analysisResult timeDefinition
      if (
        !dataStream.uiState.analysisResult ||
        !dataStream.uiState.analysisResult.timeDefinition ||
        (isEmpty(dataStream.uiState.analysisResult.timeDefinition) && !isEmpty(dataStream.timeDefinition))
      ) {
        timeDefinitionObj = dataStream.timeDefinition;
        shouldUpdate = true;
      }

      // fill uiState uiFilters
      if (isEmpty(dataStream.uiState.analysisResult) && !isEmpty(dataStream.filters)) {
        dataStream.filters.forEach((item) => {
          const filterObj = {
            path: item.path,
          };
          if (item.value) {
            filterObj.isInclude = true;
            filterObj.selectedValue = item.value;
          } else if (item.notValue) {
            filterObj.isInclude = true;
            filterObj.selectedValue = item.notValue;
          }
          filtersArr.push(filterObj);
        });
        shouldUpdate = true;
      }

      const analysisResult = {};
      if (shouldUpdate) {
        if (streamSchemaArr.length) {
          analysisResult.streamSchema = streamSchemaArr;
        }
        if (!isEmpty(timeDefinitionObj)) {
          analysisResult.timeDefinition = timeDefinitionObj;
        }

        setKinesisStreamUiKeyVal({
          ...dataStream.uiState,
          analysisResult,
          uiFilters: filtersArr,
        });
      }
    }
  }

  setUpdatedRecord = (obj) => {
    this.setState(obj);
    this.props.setKinesisStreamUiKeyVal(obj);
  };

  recordTypeChange = (eventKey) => {
    this.setUpdatedRecord({
      recordType: eventKey.value,
      recordTypeDelimiter: '',
    });
    if (eventKey === KINESIS_RECORD_TYPES[0]) {
      this.props.fetchKinesisPathsAndValues({
        type: this.props.dataStream.type,
        dataSourceId: this.props.dataStream.dataSourceId,
        recordType: eventKey.value,
      });
    }
  };

  recordDelimiterChange = (eventKey) => {
    this.setUpdatedRecord({
      recordTypeDelimiter: eventKey.value,
    });
    this.props.fetchKinesisPathsAndValues({
      type: this.props.dataStream.type,
      dataSourceId: this.props.dataStream.dataSourceId,
      recordType: this.state.recordType + eventKey.value,
    });
  };

  render() {
    const {recordType, recordTypeDelimiter} = this.state;
    const {
      dataStream: {state},
    } = this.props;

    const selectedRecordType = KINESIS_RECORD_TYPES.find((val) => recordType === val.value) || null;
    const selectedRecordTypeDelimiter =
      KINESIS_RECORD_TYPES_DELIMITER.find((val) => recordTypeDelimiter === val.value) || null;

    return (
      <div className="shell-col">
        <PanelHeader title="Set Up" isEditable={isStreamEditableService(state)} />

        <div styleName="kinesis-select-block">
          <SelectAndt
            type={TYPE_SEARCH}
            theme={THEME_LIGHT}
            onChange={this.recordTypeChange}
            options={KINESIS_RECORD_TYPES}
            value={selectedRecordType}
            placeholder="Choose Record Type"
            optionHeight={40}
            getOptionLabel={(val) => val.name}
            getOptionValue={(val) => val.value}
            automationId="ksRecordType"
          />
        </div>

        <div styleName="kinesis-select-block">
          <SelectAndt
            type={TYPE_SEARCH}
            theme={THEME_LIGHT}
            onChange={this.recordDelimiterChange}
            options={KINESIS_RECORD_TYPES_DELIMITER}
            value={selectedRecordTypeDelimiter}
            placeholder="Choose Record Delimiter"
            optionHeight={40}
            disabled={selectedRecordType === null || selectedRecordType === KINESIS_RECORD_TYPES[0]}
            getOptionLabel={(val) => val.name}
            getOptionValue={(val) => val.value}
            automationId="ksRecordTypeDelimiter"
          />
        </div>
      </div>
    );
  }
}
