import React from 'react';
import {useField} from 'react-final-form';
import Input from 'common/componentsV2/Input';

const validate = (value) => {
  return !value && 'Channel Name is mandatory';
};

const ChannelName = () => {
  const {
    input: {value, onChange, onBlur},
    meta,
  } = useField('channelName', {validate});
  return (
    <div>
      {/* eslint-disable-next-line */}
      <div>Channel's Name</div>
      <Input
        placeHolder="Channel's Name"
        automationId="channelName"
        isInvalid={meta.touched && meta.error}
        invalidMessage={meta.error}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default ChannelName;
