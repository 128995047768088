// @flow
import React from 'react';
import {withRouter} from 'react-router-dom';
import Button, {
  COLORS as BUTTON_COLORS,
  HEIGHTS as BUTTON_HEIGHTS,
  WIDTHS as BUTTON_WIDTHS,
} from 'common/componentsV2/Button';
import './AlertManagerActionToolbar.module.scss';
import SelectAndt, {
  DIRECTION_CENTER,
  DIRECTION_LEFT,
  THEME_TRANSPARENT,
  TYPE_SIMPLE,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {components} from 'react-select2';
import connect from 'react-redux/es/connect/connect';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import {getToState, gotoState, showCompositeDelayModal} from 'common/utils/angularServices';
import Tooltip from 'common/componentsV2/Tooltip';
import {getAlertEditorV2Enabled} from 'alerts.management/store/selectors';
import {
  deleteAlert,
  duplicateAlert,
  pauseResumeAlertStart,
  subscribeUnsubscribeToAlertStart,
} from 'alerts.management/store/actions';
import {ACTIVITY_VALUE, PARAMS_NAME} from 'admin.activityLog/services/activityLogService';
import {rangeTypes} from 'common/utils/dateRangeService';
import {
  getActivityLogEnabled,
  getCreateTileFromAMEnabled,
  isCustomerAdmin as getIsCustomerAdmin,
} from 'profile/store/selectors';
import SaveToDashboardModal, {TYPE_REFERER} from 'common/componentsV2/modal/SaveToDashboardModal';
import {get} from 'lodash';

type PropTypes = {
  alert: Object,
  onDropdownStateChange: Function,
  pauseResumeAlertStart: Function,
  deleteAlert: Function,
  subscribeUnsubscribeToAlertStart: Function,
  duplicateAlert: Function,
  isAlertEditorV2Enabled: boolean,
  history: Object,
  isActivityLogEnabled: boolean,
  isCustomerAdmin: boolean,
  isCreateTileFromAMEnabled: boolean,
};

const VALUES_MODAL = {
  DELETE: 'delete',
  DASHBOARD: 'dashboard',
};

const EMPTY_ARRAY = [];

@withRouter
@connect(
  (state) => ({
    isAlertEditorV2Enabled: getAlertEditorV2Enabled(state),
    isActivityLogEnabled: getActivityLogEnabled(state),
    isCustomerAdmin: getIsCustomerAdmin(state),
    isCreateTileFromAMEnabled: getCreateTileFromAMEnabled(state),
  }),
  {
    deleteAlert,
    pauseResumeAlertStart,
    subscribeUnsubscribeToAlertStart,
    duplicateAlert,
  },
)
export default class AlertManagerActionToolbar extends React.PureComponent {
  props: PropTypes;

  state = {
    isModalOpen: false,
  };

  onMoreButtonSelect = (selection) => {
    switch (selection.value) {
      case 'delete':
        this.setState({isModalOpen: VALUES_MODAL.DELETE});
        break;
      case 'compositeDelay':
        showCompositeDelayModal(this.props.alert.details.expressionTreeModel);
        break;
      case 'duplicate':
        this.onDuplicateClick();
        break;
      case 'activity':
        window.open(
          `/#!/r/admin/activity-log?${[PARAMS_NAME.ACTIVITY]}=${ACTIVITY_VALUE.ALERTS}&${[PARAMS_NAME.ALERT]}=${
            this.props.alert.id
          }&${PARAMS_NAME.CONST_RANGE}=${rangeTypes.m3.value}`,
        );
        break;
      case 'dashboard':
        this.setState({isModalOpen: VALUES_MODAL.DASHBOARD});
        break;
      default:
    }
  };

  handleSubscribeUnsubscribe = () => {
    const action = this.props.alert.isMeSubscribed ? 'unsubcribe' : 'subscribe';
    this.props.subscribeUnsubscribeToAlertStart({
      action,
      id: this.props.alert.id,
    });
  };

  onPauseButtonSelect = (selection) => {
    this.onMenuStateChange('close');
    this.props.pauseResumeAlertStart({
      action: 'pause',
      id: this.props.alert.id,
      time: selection.value,
    });
  };

  onResumeClick = () => {
    this.props.pauseResumeAlertStart({
      action: 'resume',
      id: this.props.alert.id,
      time: null,
    });
  };

  onMenuStateChange = (state) => {
    this.props.onDropdownStateChange(state);
  };

  onEditClick = () => {
    const {history, isAlertEditorV2Enabled} = this.props;
    if (isAlertEditorV2Enabled) {
      history.push(`/alert-manager/edit/${this.props.alert.id}/settings${history.location.search}`);
    } else {
      getToState('alerts.edit').data.originHref = window.location.href;
      gotoState('alerts.edit', {
        id: this.props.alert.id,
      });
    }
  };

  onDuplicateClick = () => {
    const {history, isAlertEditorV2Enabled} = this.props;
    if (isAlertEditorV2Enabled) {
      history.push(`/alert-manager/clone/${this.props.alert.id}/settings`);
    } else {
      this.props.duplicateAlert(this.props.alert.id);
    }
  };

  onCloseModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  renderModal = () => {
    if (this.state.isModalOpen === VALUES_MODAL.DELETE) {
      return (
        <Modal isOpen size={SIZES.SMALL} onClose={this.onCloseModal} isCloseButtonHidden>
          <div styleName="modal-recipients">
            <h5>Delete Alert</h5>
            <p>Are you sure you want to delete this alert?</p>
            <div styleName="modal-recipients-buttons">
              <Button colorSchema={BUTTON_COLORS.GRAY_400} text="Cancel" onClick={this.onCloseModal} />
              <Button
                colorSchema={BUTTON_COLORS.RED_500}
                text="Delete"
                onClick={() => {
                  this.props.deleteAlert(this.props.alert.id);
                }}
              />
            </div>
          </div>
        </Modal>
      );
    }

    if (this.state.isModalOpen === VALUES_MODAL.DASHBOARD) {
      return (
        <Modal isOpen size={SIZES.SMALL} onClose={this.onCloseModal} isCloseButtonHidden>
          <SaveToDashboardModal
            onCancel={this.onCloseModal}
            type={TYPE_REFERER.ALERT_MNG}
            alertId={this.props.alert.id}
          />
        </Modal>
      );
    }

    return '';
  };

  render() {
    const {alert, isActivityLogEnabled, isCustomerAdmin, isCreateTileFromAMEnabled} = this.props;
    const moreOptions = [];

    let isShowCompositeDelay = false;
    if (alert) {
      isShowCompositeDelay =
        alert.details.expressionTreeModel.expressionTree.root.function && alert.details.enableCompositeDelayAutoCalc;
    }

    if (isActivityLogEnabled && isCustomerAdmin) {
      moreOptions.push({
        value: 'activity',
        label: 'View Activity Log',
        icon: 'icn-format_list_bulleted_black_24dp2x1',
      });
    }
    moreOptions.push({
      value: 'duplicate',
      label: 'Duplicate',
      icon: 'icn-action16-copy',
    });
    if (isShowCompositeDelay) {
      moreOptions.push({value: 'compositeDelay', label: 'Composite Delay', icon: 'icn-general16-timescale'});
    }

    if (isCreateTileFromAMEnabled) {
      moreOptions.push({
        value: 'dashboard',
        label: 'Create Dashboard Tile',
        icon: 'icn-nav24-dashboards',
        disabled: get(alert, 'details.connectedTiles', EMPTY_ARRAY).length,
      });
    }

    moreOptions.push({value: 'delete', label: 'Delete', icon: 'icn-action16-delete'});

    return (
      <div styleName="action-toolbox">
        <div styleName="btn" automation-id="Alert manager action toolbar button">
          <Tooltip content="Edit" delay={300}>
            <div>
              <Button
                colorSchema={BUTTON_COLORS.BLUE_500}
                text="Edit"
                onClick={this.onEditClick}
                icon="icn-action16-edit"
                height={BUTTON_HEIGHTS.TIGHT30}
                width={BUTTON_WIDTHS.REGULAR}
                automationId="alertEdit"
              />
            </div>
          </Tooltip>
        </div>
        <div styleName="btn">
          <Tooltip content={alert.isMeSubscribed ? 'Unsubscribe' : 'Subscribe'} delay={300}>
            <div>
              <Button
                colorSchema={BUTTON_COLORS.GRAY_300}
                width={BUTTON_WIDTHS.NARROW}
                onClick={this.handleSubscribeUnsubscribe}
                icon={alert.isMeSubscribed ? 'icn-general16-unsubscribe' : 'icn-general16-mail'}
                isDisabled={!alert.authorization.subscribe_unsubscribe}
                height={BUTTON_HEIGHTS.TIGHT30}
                automationId="alertSubscriptionToggle"
              />
            </div>
          </Tooltip>
        </div>
        <div styleName="btn">
          {this.props.alert.alertConfiguration.status === 'active' ? (
            <Tooltip content="Pause" delay={300}>
              <div>
                <SelectAndt
                  type={TYPE_SIMPLE}
                  theme={THEME_TRANSPARENT}
                  options={[
                    {value: 'hour', label: 'Pause for an hour'},
                    {value: 'day', label: 'Pause for a day'},
                    {value: 'week', label: 'Pause for a week'},
                    {value: 'indefinitely', label: 'Pause indefinitely'},
                  ]}
                  onChange={this.onPauseButtonSelect}
                  direction={DIRECTION_CENTER}
                  menuWidth={150}
                  automationId="alertPause"
                  onMenuOpen={() => this.onMenuStateChange('open')}
                  onMenuClose={() => this.onMenuStateChange('close')}
                  disabled={!alert.authorization.pause_resume}
                  customComponent={{
                    DropdownIndicator: (p) => (
                      <i {...p.innerProps} styleName="button-like-dropdown" className="icon icn-action16-pause" />
                    ),
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <Tooltip content="Resume" delay={300}>
              <div>
                <Button
                  colorSchema={BUTTON_COLORS.GRAY_300}
                  width={BUTTON_WIDTHS.NARROW}
                  onClick={this.onResumeClick}
                  icon="icn-action16-resume"
                  isDisabled={!alert.authorization.pause_resume}
                  height={BUTTON_HEIGHTS.TIGHT30}
                />
              </div>
            </Tooltip>
          )}
        </div>
        <div styleName="btn">
          <Tooltip content="More" delay={300}>
            <div>
              <SelectAndt
                type={TYPE_SIMPLE}
                theme={THEME_TRANSPARENT}
                options={moreOptions}
                onChange={this.onMoreButtonSelect}
                direction={DIRECTION_LEFT}
                menuWidth={200}
                automationId="alertMoreOptions"
                onMenuOpen={() => this.onMenuStateChange('open')}
                onMenuClose={() => this.onMenuStateChange('close')}
                isOptionDisabled={(opt) => (opt.value === 'delete' && !alert.authorization.delete) || opt.disabled}
                offsetLeft={30}
                customComponent={{
                  Option: (p) => (
                    <components.Option {...p}>
                      <div className="display_flex">
                        <div styleName="dropdown-option-icon" className={['icon', p.data.icon].join(' ')} />
                        {p.data.value === 'dashboard' && p.data.disabled ? (
                          <Tooltip content="This alert is connected to a tile" delay={300}>
                            <div>{p.children}</div>
                          </Tooltip>
                        ) : (
                          p.children
                        )}
                      </div>
                    </components.Option>
                  ),
                  DropdownIndicator: (p) => (
                    <i {...p.innerProps} styleName="button-like-dropdown" className="icon icn-general16-3dot" />
                  ),
                }}
              />
            </div>
          </Tooltip>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}
