import React, {useEffect, useMemo, useState} from 'react';
import {success} from 'common/utils/notifications/notificationsService';
import opsgenieOptions from 'channels/constants/opsgenieOptions';
import {getUsersData} from 'admin.users/store/selectors';
import {getUserProfile} from 'profile/store/selectors';
import makeChannelPayload from 'channels/utils/makeChannelPayload';
import {
  createInitialConnection,
  fetchChannels,
  postChannel,
  putChannel,
  testChannel,
} from 'alerts.channels/store/actions';
import useAsyncAction from 'common/utils/useAsyncAction';
import {
  getActiveChannels,
  getCreateInitialConnection,
  getFetchChannels,
  getPostChannel,
  getPutChannel,
  getTestChannel,
} from 'alerts.channels/store/selectors';
import {get, isEmpty, keyBy} from 'lodash';
import channelTypes from 'channels/constants/channelTypes';
import infoLinks from 'channels/constants/infoLinks';
import AsyncButton from 'common/componentsV2/AsyncButton';
import {Form} from 'react-final-form';
import {Backdrop, makeStyles, Modal} from '@material-ui/core';
import {useDispatch, useSelector, useStore} from 'react-redux';
import settingsComponentsMap from '../components/channelSettings/settingsComponents';

const useStyles = makeStyles(({palette}) => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    width: 445,
    height: 'fit-content',
    backgroundColor: palette.white[500],
    borderRadius: 7,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.3)',
    margin: 'auto',
    padding: '16px 24px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '36px',
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontSize: '14px',
    marginBottom: '40px',
  },
  logo: {
    width: '20px',
    height: '20px',
    marginRight: '6px',
  },
  initialConnectionValidation: {
    display: 'flex',
    width: '160px',
  },
  moreInfo: {
    color: palette.blue[500],
    '&:hover, &:active': {
      color: palette.blue[500],
    },
  },
}));

const opsgenieSet = new Set(opsgenieOptions.map((item) => item.value));

const channelsByValue = keyBy(channelTypes, 'value');

const getUserName = (users, email) => {
  const user = users.find((item) => item.email === email);
  if (user) {
    return `${user.firstName} ${user.lastName}`;
  }
  return email;
};

const makeErrorHandler = (errorName) => (response) => {
  if (get(response, 'data.validationErrors.errors[0]')) {
    return {
      [errorName]: response.data.validationErrors.errors[0].description,
    };
  }
  if (response.error) {
    return {
      [errorName]: response.error.uMessage,
    };
  }
  return null;
};

const NewEditChannel = ({match, onClose}: {match: Object, onClose: Function}) => {
  const {channelId, channelType} = match.params;
  const [initialConnectionStatus, setInitialConnectionStatus] = useState(false);
  const channelsData = useSelector(getActiveChannels);
  const me = useSelector(getUserProfile);
  const classes = useStyles();
  const store = useStore();
  const dispatch = useDispatch();
  const users = useSelector(getUsersData);

  const postChannelAsync = useAsyncAction(postChannel, getPostChannel, makeErrorHandler('submitError'));
  const putChannelAsync = useAsyncAction(putChannel, getPutChannel, makeErrorHandler('submitError'));
  const testChannelAsync = useAsyncAction(testChannel, getTestChannel, makeErrorHandler('testError'));
  const createInitialConnectionAsync = useAsyncAction(
    createInitialConnection,
    getCreateInitialConnection,
    makeErrorHandler('connectionError'),
  );
  const fetchChannelsAsync = useAsyncAction(fetchChannels, getFetchChannels, makeErrorHandler('fetchChannelsError'));

  const modalType = channelId ? get(channelsData.find((item) => item.id === channelId), 'channelMeta.id') : channelType;

  useEffect(() => {
    if (modalType === 'jira' && channelId) {
      createInitialConnectionAsync({
        channelId,
      }).then((errors) => {
        if (!errors) {
          setInitialConnectionStatus(true);
        }
        return errors;
      });
    }
  }, [channelsData]);

  const channel = channelId ? channelsData.find((item) => item.id === channelId) : null;
  const tags = channel ? JSON.parse(channel.tags) : {};

  const saveChannel = ({action, ...values}) => {
    if (action === 'createInitialConnection') {
      return createInitialConnectionAsync({
        accessToken: values.accessToken,
        jiraBaseURL: values.url,
        userEmail: values.jiraUser,
      }).then((errors) => {
        if (!errors) {
          setInitialConnectionStatus(true);
        }
        return errors;
      });
    }

    const payload = {
      channelType: modalType,
      body: {
        ...makeChannelPayload(values, modalType, !channelId),
        name: values.channelName,
        timezone: values.timeZone,
        tags: JSON.stringify({
          type: modalType,
          owner: !channelId ? me.email : tags.owner,
          ...(modalType === 'email' && values.members.length > 0
            ? {users: values.members.map((item) => item.value)}
            : {}),
        }),
        state: 'ACTIVE',
      },
    };
    if (action === 'test') {
      return testChannelAsync({...payload, channelId});
    }
    const asyncAction = channelId ? putChannelAsync({...payload, channelId}) : postChannelAsync(payload);
    return asyncAction.then((errors) => {
      if (!errors) {
        return fetchChannelsAsync({refresh: true}).then(() => {
          dispatch(
            success({
              settings: {
                autoDismiss: 2.5,
              },
              title: `${channelType} Channel ${!channelId ? 'Created' : 'Updated'} Successfully`,
              description: `"${values.channelName}".\r\n You can connect alerts to your channel in Alert Management`,
            }),
          );
          if (!channelId) {
            const response = getPostChannel(store.getState());
            onClose(response.data.id);
          } else {
            onClose();
          }
        });
      }
      return errors;
    });
  };

  const initialValues = useMemo(
    () =>
      channel
        ? {
            channelName: channel.channelData.channel ? channel.channelData.channel.slice(1) : channel.name,
            timeZone: channel.timezone,
            email: (channel.channelData.emailAddresses || [])
              .filter((item) => !(tags.users || []).includes(item))
              .map((item) => ({label: item, value: item})),
            members: (tags.users || []).map((item) => ({label: getUserName(users, item), value: item})),
            authentication: channel.channelData.authenticate,
            integrationApiKey: '',
            accessToken: null,
            jiraIssueType: channel.channelData.issueType,
            jiraProjectId: channel.channelData.projectId,
            jiraUser: channel.channelData.userEmail,
            user: channel.channelData.user,
            password: channel.authData && channel.authData.token,
            serviceKey: channel.channelData.serviceKey,
            topic: channel.channelData.topic,
            url:
              channel.channelData.url ||
              channel.channelData.jiraBaseURL ||
              (opsgenieSet.has(channel.channelData.baseUrl) ? '' : channel.channelData.baseUrl),
            urlDropdown: opsgenieSet.has(channel.channelData.baseUrl) ? channel.channelData.baseUrl : 'new',
          }
        : {
            members: [],
            email: [],
            urlDropdown: opsgenieOptions[0].value,
          },
    [channel],
  );

  const SettingsComponent = settingsComponentsMap[modalType];
  if (!SettingsComponent) {
    return null;
  }
  return (
    <Modal disableEnforceFocus open BackdropComponent={Backdrop} onClose={onClose}>
      <div className={classes.wrapper}>
        <Form initialValues={initialValues} onSubmit={saveChannel}>
          {({handleSubmit, values, submitFailed, errors, submitting, form: {change}}) => {
            const onClick = () => {
              change('action', 'submit');
              handleSubmit();
            };
            const onInitialConnectionClick = () => {
              change('action', 'createInitialConnection');
              handleSubmit();
            };
            const Icon = channelsByValue[modalType].icon;
            return (
              <React.Fragment>
                <div className={classes.title}>
                  <Icon width={30} height={30} viewBox="0 0 50 50" />
                  <div className="ml_0-5">
                    {`${channelId ? 'Edit' : 'New'} ${channelsByValue[modalType].label} Channel`}
                  </div>
                </div>

                <SettingsComponent
                  channelType={modalType}
                  isCreate={!channelId}
                  initialConnectionStatus={initialConnectionStatus}
                />

                {submitFailed && values.action === 'createInitialConnection' && (
                  <div className="display_flex justifyContent_flex-end mb_1 fontWeight_500 fontSize_12">
                    <div className={classes.initialConnectionValidation}>
                      <i className="icon icn-general16-warning fontSize_16 color_red-500 mr_0-5 flexShrink_0" />
                      <div>Connection failed. Please check your Jira settings.</div>
                    </div>
                  </div>
                )}

                <div className="display_flex justifyContent_space-between alignItems_center">
                  <a href={infoLinks[modalType]} target="_blank" rel="noopener noreferrer" className={classes.moreInfo}>
                    <i className="icon icn-general16-info mr_0-5" />
                    More Info
                  </a>
                  <div className="display_flex">
                    <div className="mr_1-5">
                      <AsyncButton
                        automationId="cancelNewChannel"
                        text="Cancel"
                        colorSchema="gray.300"
                        isLoading={false}
                        onClick={() => onClose()}
                      />
                    </div>
                    {modalType === 'jira' && !initialConnectionStatus ? (
                      <AsyncButton
                        isDisabled={!isEmpty(errors)}
                        isLoading={values.action === 'createInitialConnection' && submitting}
                        automationId="createInitialConnection"
                        text="Create initial connection"
                        onClick={onInitialConnectionClick}
                      />
                    ) : (
                      <AsyncButton
                        isDisabled={!isEmpty(errors)}
                        isLoading={values.action === 'submit' && submitting}
                        automationId="createChannel"
                        text={`${channelId ? 'Update' : 'Create'} Channel`}
                        onClick={onClick}
                      />
                    )}
                  </div>
                </div>
              </React.Fragment>
            );
          }}
        </Form>
      </div>
    </Modal>
  );
};

export default NewEditChannel;
