import React, {useState, useEffect, memo} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import {Box, makeStyles} from '@material-ui/core';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {
  getFetchTriggeredAlertIsLoading,
  getInvModalTrigger,
  getMoreAlertsInAnomalyGroup,
} from 'investigation/store/selectors';
import {ReactComponent as StatusIcon} from 'investigation/images/open_alert.svg';
import Button, {COLORS} from 'common/componentsV2/Button';
import {palette} from 'app/styles/theme';
import {StringParam, useQueryParams} from 'use-query-params';
import {Severity} from '../tabs/Badges';
import FeedbackContainer from './FeedbackContainer';
import Acknowledged from './Acknowledged';
import MoreActions from './MoreActions';

const useActionBarStyles = makeStyles(() => ({
  main: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px 0 16px',
    backgroundColor: palette.gray['100'],
  },
  title: {
    position: 'relative',
    height: '100%',
    display: 'flex',
  },
  nav: {
    position: 'absolute',
    top: 11,
    left: 6,
    display: 'flex',
  },
  alertsTitle: {
    fontWeight: 500,
    backgroundColor: palette.gray['300'],
    color: palette.gray['500'],
    padding: '0 4px',
    borderRadius: 4,
  },
  iconArrow: {
    position: 'relative',
    top: 2,
    fontSize: 16,
  },
  iconBtn: {
    height: '36px',
    width: '36px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.gray['500'],
  },
  displayAck: {
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    borderRadius: '6px',
    paddingRight: '10px',
    paddingLeft: '10px',
    marginRight: '12px',
    backgroundColor: palette.blue['300'],
    color: palette.blue['500'],
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 500,
  },
  displayAckIcon: {
    marginRight: '4px',
  },
}));

const InvModalHeader = ({onShareClicked}: PropTypes) => {
  const [, setQueryParams] = useQueryParams({
    triggerId: StringParam,
  });
  const classes = useActionBarStyles();
  const trigger = useSelector(getInvModalTrigger);
  const isLoading = useSelector(getFetchTriggeredAlertIsLoading);
  const alertsGroupIds = useSelector(getMoreAlertsInAnomalyGroup);
  const [currentTriggerIndex, setCurrentTriggerIndex] = useState(0);
  const [isNext, setIsNext] = useState(false);
  const [isPrev, setIsPrev] = useState(false);

  useEffect(() => {
    if (alertsGroupIds.length > 1) {
      const triggerIndex = alertsGroupIds.findIndex((id) => id === trigger.id);
      setCurrentTriggerIndex(triggerIndex);
      setIsNext(alertsGroupIds.length > triggerIndex + 1);
      setIsPrev(triggerIndex > 0);
    }
  }, [trigger, alertsGroupIds]);

  const nextTrigger = () => {
    if (isNext) {
      setQueryParams({
        triggerIdInv: alertsGroupIds[currentTriggerIndex + 1],
      });
    }
  };

  const prevTrigger = () => {
    if (isPrev) {
      setQueryParams({
        triggerIdInv: alertsGroupIds[currentTriggerIndex - 1],
      });
    }
  };

  if (isLoading || isEmpty(trigger)) {
    return <Box className={classes.main} />;
  }

  return (
    <Box className={classes.main}>
      <Box className={classes.title}>
        {alertsGroupIds.length > 1 && (
          <Box className={classes.nav}>
            <i
              onClick={prevTrigger}
              className={`${classes.iconArrow} icon icn-arrow16-triangleleft`}
              style={{
                opacity: isPrev ? '1' : '0.45',
                cursor: isPrev ? 'pointer' : 'default',
              }}
            />

            <TypographyBox className={classes.alertsTitle}>
              {currentTriggerIndex + 1}/{alertsGroupIds.length} Alerts
            </TypographyBox>
            <i
              onClick={nextTrigger}
              className={`${classes.iconArrow} icon icn-arrow16-triangleright`}
              style={{
                opacity: isNext ? '1' : '0.45',
                cursor: isNext ? 'pointer' : 'default',
              }}
            />
          </Box>
        )}
        <Box display="flex" alignItems="center" flexBasis={0} flexGrow={1} pr={1.5}>
          {trigger.status.toLowerCase() === 'open' ? (
            <StatusIcon style={{marginRight: '6px', flexShrink: 0}} />
          ) : (
            <Box width={22} />
          )}
          <TypographyBox className="ellipsis" color="gray.400" fontSize="20px" mr={1} width="85%">
            {trigger.title}
          </TypographyBox>
          <Severity level={trigger.severity} />
        </Box>
      </Box>

      <Box display="flex" alignItems="center" flexShrink="0">
        <Acknowledged />
        <FeedbackContainer />
        <Box ml={1.5}>
          <Button
            colorSchema={COLORS.GRAY_300}
            icon="icn-general16-share"
            extraClassName={classes.iconBtn}
            onClick={onShareClicked}
          />
        </Box>
        <MoreActions />
      </Box>
    </Box>
  );
};

InvModalHeader.propTypes = {
  onShareClicked: PropTypes.func.isRequired,
};

export default memo(InvModalHeader);
