// @flow
import React from 'react';
import {connect} from 'react-redux';
import {startDataStream as startDataStreamAction} from 'bc/store/actions';
import {streamStartActions} from 'bc/services/dataStreamService';
import * as selectors from 'bc/store/selectors';

import Button, {COLORS} from 'common/componentsV2/Button';
import {cleanHash} from 'common/utils/utilsService';
import SelectLiveStreamItModal from 'bc/components/streams/preview/SelectLiveStreamItModal';
import './WizardStepMenu.module.scss';

type PropTypes = {
  dataStream: Object,
  dataStreams: Array,

  // connect
  startDataStream: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    dataStreams: selectors.getDataStreamsItems(state),
  }),
  {
    startDataStream: startDataStreamAction,
  },
)
export default class SavePartialStream extends React.PureComponent {
  props: PropTypes;

  state = {
    isModalOpen: false,
    shouldRewind: false,
  };

  onShouldRewind = (val) => {
    this.setState(() => ({shouldRewind: val}));
  };

  openModal = () => {
    this.setState({isModalOpen: true});
  };

  closeModal = () => {
    this.setState({isModalOpen: false});
  };

  onConfirmModal = () => {
    this.props.startDataStream({
      id: this.props.dataStream.id,
      action: streamStartActions.start,
      origin: cleanHash(window.location.hash),
      shouldRewind: this.state.shouldRewind,
    });
    this.closeModal();
  };

  render() {
    const {dataStream, dataStreams} = this.props;

    let originalDataStream = null;
    if (dataStream.uiState.id) {
      originalDataStream = dataStreams.find((ds) => ds.id === dataStream.uiState.id);
    }
    const isDisabled =
      originalDataStream &&
      dataStream.maxMissingFiles === originalDataStream.maxMissingFiles &&
      dataStream.historicalDateRange === originalDataStream.historicalDateRange &&
      dataStream.timeZone === originalDataStream.timeZone &&
      dataStream.pollingInterval === originalDataStream.pollingInterval &&
      dataStream.name === originalDataStream.name &&
      dataStream.delayMinutes === originalDataStream.delayMinutes &&
      dataStream.owner === originalDataStream.owner &&
      dataStream.maxBackFillIntervals === originalDataStream.maxBackFillIntervals &&
      dataStream.aggrInterval === originalDataStream.aggrInterval &&
      dataStream.notificationDelay === originalDataStream.notificationDelay;

    return (
      <div>
        <Button
          colorSchema={COLORS.BLUE_500}
          text="Save"
          isDisabled={isDisabled}
          onClick={this.openModal}
          automation-id="dataCollectorStreamTableDisabledButton"
        />
        <SelectLiveStreamItModal
          onClose={this.closeModal}
          onStreamData={this.onConfirmModal}
          onShouldRewind={this.onShouldRewind}
          shouldRewindState={this.state.shouldRewind}
          dataStreamId={dataStream.uiState.id}
          dataStreamName={dataStream.name}
          isOpen={this.state.isModalOpen}
        />
      </div>
    );
  }
}
