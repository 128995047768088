// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import Button, {
  COLORS as BUTTON_COLORS,
  WIDTHS as BUTTON_WIDTHS,
  HEIGHTS as BUTTON_HEIGHTS,
} from 'common/componentsV2/Button';
import Tooltip from 'common/componentsV2/Tooltip';
import SelectAndt, {
  DIRECTION_LEFT,
  THEME_TRANSPARENT,
  TYPE_SIMPLE,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {
  toggleUserEditModal,
  toggleUserChangeStatusConfirmationModal,
  toggleDeleteUserModal,
  resetPasswordRequest,
  impersonate,
} from 'admin.users/store/actions';
import {isAnodot, isAnodotUser, getUserProfile} from 'profile/store/selectors';
import Box from '@material-ui/core/Box';
import './UsersActionToolbar.module.scss';

type PropTypes = {
  user: Object,

  // connect
  me: Object,
  isAndt: boolean,
  isAndtUser: boolean,
  toggleUserEditModal: Function,
  toggleUserChangeStatusConfirmationModal: Function,
  toggleDeleteUserModal: Function,
  resetPasswordRequest: Function,
  impersonate: Function,
};

@connect(
  (state) => ({
    me: getUserProfile(state),
    isAndt: isAnodot(state),
    isAndtUser: isAnodotUser(state),
  }),
  {
    toggleUserEditModal,
    toggleUserChangeStatusConfirmationModal,
    toggleDeleteUserModal,
    resetPasswordRequest,
    impersonate,
  },
)
export default class UsersActionToolbar extends React.PureComponent {
  props: PropTypes;

  onEditClicked = () => {
    this.props.toggleUserEditModal({isOpen: true, userId: this.props.user.id, orgName: this.props.user.organization});
  };

  onImpersonateClick = () => {
    this.props.impersonate({userId: this.props.user.id});
  };

  onMoreButtonSelect = (item) => {
    switch (item.value) {
      case 'enable':
      case 'disable':
        this.props.toggleUserChangeStatusConfirmationModal({
          isOpen: true,
          userId: this.props.user.id,
          action: item.value,
        });
        break;
      case 'delete':
        this.props.toggleDeleteUserModal({isOpen: true, userId: this.props.user.id});
        break;
      case 'reset':
        this.props.resetPasswordRequest({
          email: this.props.user.email,
          captcha: null,
        });
        break;
      default:
        break;
    }
  };

  getMoreOptions = () => {
    const {user, me, isAndt} = this.props;

    let moreOptions = [
      {value: user.status ? 'enable' : 'disable', label: user.status ? 'Enable User' : 'Disable User'},
      {value: 'delete', label: 'Delete'},
    ];

    if (!user.status) {
      moreOptions.splice(1, 0, {value: 'reset', label: 'Reset Password'});
    }

    if (user.id === me._id) {
      moreOptions = moreOptions.filter((option) => ['enable', 'disable', 'delete'].indexOf(option.value) === -1);
    }

    if (isAndt) {
      moreOptions = moreOptions.filter((option) => ['delete'].indexOf(option.value) === -1);
    }

    return moreOptions;
  };

  renderImpersonateButton = () => {
    const {user, isAndt} = this.props;
    if (!isAndt || user.userRole.indexOf('anodot-') !== -1) {
      return null;
    }
    return (
      <div styleName="btn">
        <Tooltip content="Impersonate" delay={300}>
          <div>
            <Button
              colorSchema={BUTTON_COLORS.GRAY_300}
              width={BUTTON_WIDTHS.NARROW}
              onClick={this.onImpersonateClick}
              icon="icn-action16-impersonate"
              height={BUTTON_HEIGHTS.TIGHT30}
            />
          </div>
        </Tooltip>
      </div>
    );
  };

  render() {
    const {isAndtUser} = this.props;

    return (
      <div styleName="action-toolbox">
        {!isAndtUser && (
          <div styleName="btn">
            <Tooltip content="Edit" delay={300}>
              <Button
                colorSchema={BUTTON_COLORS.BLUE_500}
                text="Edit"
                onClick={this.onEditClicked}
                icon="icn-action16-edit"
                height={BUTTON_HEIGHTS.TIGHT30}
                width={BUTTON_WIDTHS.REGULAR}
                automationId="userEditButton"
              />
            </Tooltip>
          </div>
        )}
        {this.renderImpersonateButton()}
        {!isAndtUser && (
          <div styleName="btn">
            <Tooltip content="More" delay={300}>
              <div>
                <SelectAndt
                  type={TYPE_SIMPLE}
                  theme={THEME_TRANSPARENT}
                  options={this.getMoreOptions()}
                  onChange={this.onMoreButtonSelect}
                  direction={DIRECTION_LEFT}
                  menuWidth={150}
                  automationId="userMoreOptions"
                  customComponent={{
                    DropdownIndicator: (p) => (
                      <Box display="flex" alignItems="center" height={36}>
                        <i {...p.innerProps} styleName="button-like-dropdown" className="icon icn-general16-3dot" />
                      </Box>
                    ),
                  }}
                />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
}
