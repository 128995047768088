// @flow
import React from 'react';
import {Box} from '@material-ui/core';

type PropTypes = {
  tag: String,
  title: String,
  children: Object,
  link: String,
  url: String,
  onClose: Function,
  storageKey: String,
  position: string,
  width: number,
  height: number,
  icon: string,
};
const BaloonMessage = ({
  tag,
  title,
  children,
  link,
  url,
  onClose,
  storageKey,
  position,
  width,
  height,
  icon,
}: PropTypes) => {
  const [storageValue, setStorageValue] = React.useState(localStorage.getItem(storageKey) || '');

  React.useEffect(() => {
    localStorage.setItem(storageKey, storageValue);
  }, [storageValue]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setStorageValue(true);
    }
  };

  return !storageValue ? (
    <Box
      borderRadius={6}
      border={position ? '' : 'solid 2px'}
      borderColor="blue.300"
      bgcolor="blue.100"
      color="gray.500"
      lineHeight="normal"
      padding={icon ? '8px 16px 8px 36px' : '8px 16px 8px 16px'}
      position="relative"
      width={width}
      height={height}
      css={{
        wordBreak: 'break-word',
        '&:hover .icon': {
          opacity: 1,
        },
      }}
    >
      {position === 'bottom' && (
        <Box
          position="absolute"
          width={12}
          height={12}
          top={-6}
          right={22}
          bgcolor="blue.100"
          css={{transform: 'rotate(45deg)'}}
        />
      )}
      {position === 'top' && (
        <Box
          position="absolute"
          width={12}
          height={12}
          bottom={-6}
          right="calc(50% - 6px)"
          bgcolor="blue.100"
          css={{transform: 'rotate(45deg)'}}
        />
      )}
      {tag && (
        <Box
          borderRadius={9.5}
          bgcolor="blue.500"
          padding="1px 5px"
          color="white.500"
          display="inline-block"
          mb={0.5}
          fontSize={12}
          fontWeight="bold"
        >
          {tag}
        </Box>
      )}
      <Box
        mb={title && 1}
        display="flex"
        fontSize={0}
        justifyContent="space-between"
        fontWeight="bold"
        css={{wordBreak: 'break-all'}}
      >
        {icon && <i className={icon} style={{fontSize: 24, position: 'absolute', left: 8, top: 5}} />}
        {title && <Box fontSize={14}>{title}</Box>}
        {(onClose || storageKey) && (
          <Box
            width={12}
            height={12}
            color="gray.400"
            right={6}
            top={4}
            position="absolute"
            fontSize={14}
            onClick={handleClose}
            className="icon icn-general16-closeb"
            css={{
              cursor: 'pointer',
              opacity: 0,
            }}
          />
        )}
      </Box>
      {children}
      {url && (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <Box color="blue.500" fontWeight={500} mt={1}>
            {link}
          </Box>
        </a>
      )}
    </Box>
  ) : null;
};

export default BaloonMessage;
