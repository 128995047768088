import React, {memo} from 'react';
import {Box} from '@material-ui/core';

import FilterChipItem from 'investigation/components/incident/FilterChipItem';
import PropTypes from 'prop-types';
import Tokens from './Tokens';

const FilterChips = ({filters, onSelect}: PropTypes) => {
  return (
    <Box display="flex" flexWrap="wrap" mb={1}>
      {!!filters.length &&
        filters.map((dimension) => <FilterChipItem key={dimension.id} dimension={dimension} onSelect={onSelect} />)}
    </Box>
  );
};

Tokens.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default memo(FilterChips);
