/* eslint-disable no-param-reassign */
import {createSelector} from 'reselect';
import {get} from 'lodash';
import {selectors as commonSelectors} from 'common';
import {getUsersData} from 'admin.users/store/selectors';
import {getAckAlerts, getNoAckAlerts} from 'alerts.console/store/selectors';
import {abbrNum, setMaxPercentToDisplayTrimmed} from 'common/utils/numbers';
import {selectors as profileSelector} from '../../profile';

export const {getInvestigation} = commonSelectors;

const EMPTY_OBJECT = {};

// View
export const getInvestigationView = createSelector(
  getInvestigation,
  (inv) => inv.views,
);

export const getInvModalTrigger = createSelector(
  getInvestigationView,
  (view) => view.trigger || EMPTY_OBJECT,
);

export const getInvAlertMeasures = createSelector(
  getInvestigationView,
  (view) => view.alertMeasures,
);

export const getInvOtherMeasures = createSelector(
  getInvestigationView,
  (view) => view.otherMeasures,
);

export const getInvAnomalyStarted = createSelector(
  getInvestigationView,
  (view) => view.anomalyStarted,
);

export const getInvScore = createSelector(
  getInvestigationView,
  (view) => view.score,
);

export const getInvWhere = createSelector(
  getInvestigationView,
  (view) => view.where,
);

export const getInvModalIncident = createSelector(
  getInvestigationView,
  (modal) => modal.incident,
);

export const getInvModalIncidentFilters = createSelector(
  getInvModalIncident,
  (incident) => incident.filters,
);

export const getInvModalCorrelations = createSelector(
  getInvestigationView,
  (view) => view.correlations,
);

export const getInvModalCorrelationsFilters = createSelector(
  getInvModalCorrelations,
  (correlations) => correlations.filters,
);

export const getIsOpen = createSelector(
  getInvestigationView,
  (view) => view.isOpen,
);

export const getIsMinimize = createSelector(
  getInvestigationView,
  (view) => view.isMinimize,
);

export const getAnomalyId = createSelector(
  getInvestigationView,
  (view) => view.anomalyId,
);

export const getTriggerId = createSelector(
  getInvestigationView,
  (view) => view.triggerId,
);

// *** Data ****

export const getMoreAlertsInAnomalyGroup = createSelector(
  getAckAlerts,
  getNoAckAlerts,
  getAnomalyId,
  (ackAlertsGroups, noAckAlertsGroups, anomalyId) => {
    if (ackAlertsGroups.alertGroups.length || noAckAlertsGroups.alertGroups.length) {
      const group = [...ackAlertsGroups.alertGroups, ...noAckAlertsGroups.alertGroups].find((i) => i.id === anomalyId);
      return group && group.alerts ? group.alerts.map((alert) => alert.id) : [];
    }
    return [];
  },
);

export const getInvestigationData = createSelector(
  getInvestigation,
  (inv) => inv.data,
);

// fetch triggered alert
export const getInvDataFetchTriggeredAlert = createSelector(
  getInvestigationData,
  (data) => data.fetchTriggeredAlert || EMPTY_OBJECT,
);

export const getInvDataFetchTriggeredAlertData = createSelector(
  getInvDataFetchTriggeredAlert,
  (trigger) => trigger.data,
);

export const getFetchTriggeredAlertIsLoading = createSelector(
  getInvDataFetchTriggeredAlert,
  (trigger) => trigger.isLoading,
);

export const getOverview = createSelector(
  getInvDataFetchTriggeredAlertData,
  (trigger) => {
    const overview = EMPTY_OBJECT;

    if (!trigger || !trigger.metrics || !trigger.metrics.length) {
      return overview;
    }

    const lastMetric = trigger.metrics[trigger.metrics.length - 1];
    const lastInterval = lastMetric.intervals[lastMetric.intervals.length - 1];
    const isDrop = lastInterval.direction === 'DOWN';
    overview.isDrop = !overview.isDrop ? isDrop : overview.isDrop;
    overview.isSpike = !overview.isSpike ? !isDrop : overview.isSpike;
    const toVal = lastInterval.peak;
    const fromVal = isDrop
      ? lastInterval.peak + lastInterval.deltaAbsolute
      : lastInterval.peak - lastInterval.deltaAbsolute;
    overview.percentageText = setMaxPercentToDisplayTrimmed(lastInterval.deltaPercentage);
    overview.fromToText = `(from ${abbrNum(fromVal, 2)} to ${abbrNum(toVal, 2)})`;

    return overview;
  },
);

// fetch Incident TokenMap
export const getIncidentTokenMap = createSelector(
  getInvestigationData,
  (data) => data.fetchAnomaliesTokenMap,
);

export const getIncidentTokenMapData = createSelector(
  getIncidentTokenMap,
  (anomaliesTokenMap) => anomaliesTokenMap.data,
);

export const getIncidentTokenMapCounter = createSelector(
  getIncidentTokenMapData,
  (data) => (data && data.metricsCounter ? data.metricsCounter : EMPTY_OBJECT),
);

export const getIncidentTokenMapTotal = createSelector(
  getIncidentTokenMapData,
  (data) => (data && data.totalMetrics ? data.totalMetrics : EMPTY_OBJECT),
);

export const getIncidentTokenMapIsLoading = createSelector(
  getIncidentTokenMap,
  (anomaliesTokenMap) => anomaliesTokenMap.isLoading,
);

export const getIncidentTokenMapDataFiltered = createSelector(
  getIncidentTokenMapData,
  getInvAlertMeasures,
  (tokenMap, measures) => {
    const filteredTokens = {};

    if (!tokenMap || !measures.length) {
      return filteredTokens;
    }

    measures.forEach((what) => {
      if (tokenMap.dimMap[what]) {
        const filteredFromMetaData = {};
        Object.keys(tokenMap.dimMap[what]).forEach((key) => {
          if (key !== 'func' && key !== 'mtype') {
            filteredFromMetaData[key] = tokenMap.dimMap[what][key];
          }
        });
        filteredTokens[what] = filteredFromMetaData;
      }
    });

    return filteredTokens;
  },
);

// fetch tokenMap Correlations
export const getCorrelationsTokenMap = createSelector(
  getInvestigationData,
  (data) => data.fetchAnomaliesTokenMapCorrelations,
);

export const getCorrelationsTokenMapData = createSelector(
  getCorrelationsTokenMap,
  (anomaliesTokenMap) => anomaliesTokenMap.data,
);

export const getCorrelationsTokenMapCounter = createSelector(
  getCorrelationsTokenMapData,
  (data) => (data && data.metricsCounter ? data.metricsCounter : EMPTY_OBJECT),
);

export const getCorrelationsTokenMapTotal = createSelector(
  getCorrelationsTokenMapData,
  (data) => (data && data.totalMetrics ? data.totalMetrics : EMPTY_OBJECT),
);

export const getCorrelationsTokenMapIsLoading = createSelector(
  getCorrelationsTokenMap,
  (anomaliesTokenMap) => anomaliesTokenMap.isLoading,
);

export const getCorrelationsTokenMapDataFiltered = createSelector(
  getCorrelationsTokenMapData,
  getInvOtherMeasures,
  (tokenMap, measures) => {
    const filteredTokens = {};

    if (!tokenMap || !measures.length) {
      return filteredTokens;
    }
    measures.forEach((what) => {
      if (tokenMap.dimMap[what]) {
        filteredTokens[what] = tokenMap.dimMap[what];
      }
    });
    return filteredTokens;
  },
);

// Data fetch alert's metrics
export const getFetchAlertMetrics = createSelector(
  getInvestigationData,
  (data) => data.fetchAlertMetrics || EMPTY_OBJECT,
);

// Data fetch all metrics
export const getFetchAllMetrics = createSelector(
  getInvestigationData,
  (data) => data.fetchAllMetrics || EMPTY_OBJECT,
);

export const getFetchAllMetricsIsLoading = createSelector(
  getInvestigationData,
  (data) => data.fetchAllMetrics || EMPTY_OBJECT,
);

export const getFetchAlertMetricsIncident = createSelector(
  getFetchAlertMetrics,
  getInvAlertMeasures,
  (metrics, measures) => {
    const filteredMetrics = {};
    Object.keys(metrics).forEach((what) => {
      if (measures.includes(what)) {
        filteredMetrics[what] = metrics[what];
      }
    });
    return filteredMetrics;
  },
);

export const getFetchAllMetricsIncident = createSelector(
  getFetchAllMetrics,
  getFetchAlertMetricsIncident,
  getInvAlertMeasures,
  (allData, alertData, measures) => {
    const relevantData = {};

    Object.keys(allData).forEach((what) => {
      const all = allData[what];
      const alert = alertData[what];
      if (all && all.data && alert && alert.data && measures.includes(what)) {
        const allMetrics = all.data.metrics;
        const alertMetrics = alert.data.metrics;
        const filteredMetrics = allMetrics.filter((i) =>
          alertMetrics.length === 0 ? allMetrics : !alertMetrics.some((j) => i.id === j.id),
        );

        relevantData[what] = {
          ...all,
          data: {
            ...all.data,
            metrics: filteredMetrics,
          },
        };
      }
    });
    return relevantData;
  },
);

export const getFetchAlertMetricsCorrelation = createSelector(
  getFetchAllMetrics,
  getInvOtherMeasures,
  (metrics, measures) => {
    const filteredMetrics = {};
    Object.keys(metrics).forEach((what) => {
      if (measures.includes(what)) {
        filteredMetrics[what] = metrics[what];
      }
    });
    return filteredMetrics;
  },
);

export const getFeedbackRequest = createSelector(
  getInvestigationData,
  (data) => data.feedbackRequest || EMPTY_OBJECT,
);

export const getMetricList = createSelector(
  getInvestigationData,
  (data) => data.metricList || EMPTY_OBJECT,
);

export const getFeedbackRequestId = createSelector(
  getFeedbackRequest,
  (feedbackRequest) => get(feedbackRequest, 'data.id', null),
);

export const getFeedbackRequestIsLoading = createSelector(
  getFeedbackRequest,
  (feedbackRequest) => feedbackRequest.isLoading,
);

// Timeline
export const getTimeline = createSelector(
  getInvestigationData,
  (investigationData) => investigationData.fetchTimeline,
);

export const getTimelineData = createSelector(
  getTimeline,
  (timeline) => timeline.data,
);

export const getTimelineIsLoading = createSelector(
  getTimeline,
  (timeline) => timeline.isLoading,
);

export const getPostComment = createSelector(
  getInvestigationData,
  (timeline) => timeline.postComment,
);

export const getPostCommentIsLoading = createSelector(
  getPostComment,
  (postComment) => postComment.isLoading,
);

export const getInvestigationSettings = createSelector(
  profileSelector.getMeAppSettings,
  (appSettings) => get(appSettings, 'investigation', null),
);

export const getActionsInvestigationSettings = createSelector(
  getInvestigationSettings,
  (investigation) => get(investigation, 'actions', null),
);

// view
export const getTimelineView = createSelector(
  getInvestigationView,
  getUsersData,
  (investigationView, users) => {
    if (users && users.length) {
      return investigationView.timeline.map((tle) => {
        let userFound = null;
        users.find((user) => {
          if (tle.userId && tle.userId === user._id) {
            userFound = user;
          }
          return null;
        });
        if (userFound) {
          return {
            ...tle,
            firstName: userFound.firstName,
            lastName: userFound.lastName,
          };
        }
        return tle;
      });
    }
    return [];
  },
);
