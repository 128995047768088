import React from 'react';
import './OptionComponentSimple.module.scss';
import SmartTooltip from 'common/componentsV2/Tooltip';

type PropTypes = {
  data: Object,
  isSelected: boolean,
  tooltip: Object,
};

const OptionComponentSimple = (props: PropTypes) => (
  <div
    styleName={[
      'container',
      props.isSelected ? 'selected' : '',
      props.data.isDisabled ? 'disabled' : '',
      props.data.type === 'HEADER' ? 'header' : '',
    ].join(' ')}
    className="form-option idle-external-click"
  >
    <div styleName={`label ${props.tooltip ? 'tooltip' : ''}`}>
      {props.data.icon && <i className={`icon ${props.data.icon}`} styleName="icon" />}
      <div automation-id={props.data.automationId} styleName="ellipsis">
        {props.data.label || props.data.text}
      </div>
      {props.tooltip && (
        <SmartTooltip content={props.tooltip.text}>
          <i styleName="tooltip-icon" className={`icon ${props.tooltip.icon}`} />
        </SmartTooltip>
      )}
    </div>
    {props.isSelected && <i className="icon icn-general16-checkmark" />}
  </div>
);

export default OptionComponentSimple;
