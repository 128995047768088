// @flow
import React, {useCallback, useRef} from 'react';
import ReactDOM from 'react-dom';
import {useDispatch, useSelector} from 'react-redux';
import * as selectors from 'alerts.management/store/selectors';
import {segmentClickEvent} from 'common/store/actions';
import {Box, makeStyles} from '@material-ui/core';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';

export const HEIGHT_SUBMENU_ITEM = 39;
const HEIGHT_TITLE = 56;
const MARGIN_BOTTOM_SUBMENU = 14;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 240,
    display: 'block',
    opacity: 1,
    position: 'absolute',
    left: 60,
    zIndex: 9999,
    height: 'auto',
    backgroundColor: theme.palette.white[500],
    textAlign: 'center',
    overflow: 'hidden',
    boxShadow: '0 4px 14px -1px rgba(0, 0, 0, 0.15)',
    borderRadius: 16,
    whiteSpace: 'nowrap',
    paddingBottom: 3,
  },
  title: {
    color: theme.palette.gray[350],
    cursor: 'default',
    padding: '20px 0 15px 22px',
    textAlign: 'left',
    fontWeight: 700,
    fontSize: 14,
    height: HEIGHT_TITLE,
  },
  subMenu: {
    margin: '0 12px',
    marginBottom: MARGIN_BOTTOM_SUBMENU,
  },
  subMenuItem: {
    lineHeight: '39px',
    height: HEIGHT_SUBMENU_ITEM,
    textAlign: 'left',
    paddingTop: 0,
    paddingLeft: 10,
    paddingBottom: 0,
    borderRadius: 4,
    fontSize: 15,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.blue[100],
      color: theme.palette.blue[500],
      '& $subMenuItemLink': {
        color: theme.palette.blue[500],
      },
    },
  },
  subMenuItemLink: {
    color: theme.palette.gray[500],
    display: 'inline-block',
    width: 215,
    marginLeft: -11,
    paddingLeft: 10,
    cursor: 'pointer',
  },
  active: {
    backgroundColor: theme.palette.blue[100],
    color: theme.palette.gray[500],
    '& $subMenuItemLink': {
      color: theme.palette.blue[500],
    },
  },
  label: {
    padding: '0 8px',
    color: theme.palette.white[500],
    fontWeight: 700,
    textTransform: 'uppercase',
    lineHeight: '24px',
    borderRadius: 12,
    marginLeft: 8,
  },
  new: {
    backgroundColor: theme.palette.teal[500],
  },
  plus: {
    backgroundColor: theme.palette.mint[500],
  },
  newPageLabel: {
    textTransform: 'uppercase',
    borderRadius: 9.5,
    backgroundColor: theme.palette.azure[500],
    padding: '2px 7px',
    lineHeight: '18px',
    color: theme.palette.white[500],
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'bold',
    marginLeft: 5,
  },
}));

type NavBarSubMenuItemProps = {
  item: Object,
  selectedItem: string,
  handleClick: Function,
  parentElement: Node,
};

const LABEL_TYPES = {
  NEW: 'new',
  PLUS: 'plus',
};

const body = document.getElementsByTagName('body')[0];

const NavBarSubMenuItem = ({item, selectedItem, handleClick, parentElement}: NavBarSubMenuItemProps) => {
  const dispatch = useDispatch();

  const reportToSegment = useCallback((menuItem) => {
    dispatch(segmentClickEvent({type: 'click', name: `menu-${menuItem}`}));
  }, []);

  const element = useRef();
  const alertConfigurationsItems = useSelector(selectors.getAlertConfigurationsCount);
  const classes = useStyles();
  const visibleSubItems = item.items.filter((sub) => !sub.isHidden);

  const offsetTopParent = (parentElement.current && parentElement.current.offsetTop) || 0;
  const heightParentElement = (parentElement.current && parentElement.current.clientHeight) || 0;
  // eslint-disable-next-line max-len
  const positionSubMenu =
    offsetTopParent -
    (visibleSubItems.length * HEIGHT_SUBMENU_ITEM + HEIGHT_TITLE + MARGIN_BOTTOM_SUBMENU) +
    heightParentElement;

  const renderLabel = (type, count = 1) => (
    <div className={`${classes.label} ${type === LABEL_TYPES.NEW ? classes.new : classes.plus}`}>
      {type === LABEL_TYPES.NEW ? 'new' : `+${count}`}
    </div>
  );

  return ReactDOM.createPortal(
    <div className={classes.wrapper} style={{top: positionSubMenu}} ref={element} automation-id="subMenu">
      <div className={classes.title}>{item.title.toUpperCase()}</div>
      <div className={classes.subMenu}>
        {/* eslint-disable-next-line complexity */}
        {visibleSubItems.map((_item) => {
          if (_item.links) {
            return (
              <TypographyBox
                variant="subtitle2"
                key={_item.id}
                className={`
                    ${classes.subMenuItem}
                    ${_item.links && selectedItem === _item.links[0] ? classes.active : ''}
                  `}
                automation-id={_item.title}
              >
                <a
                  className={classes.subMenuItemLink}
                  href={_item.links[0]}
                  target={_item.isNewTab ? '_blank' : '_self'}
                  rel="noreferrer"
                  onClick={() => {
                    reportToSegment(_item.title);
                  }}
                >
                  <Box display="flex" alignItems="center">
                    {_item.title}
                    {(_item.isNewTab && renderLabel(LABEL_TYPES.NEW)) ||
                      (_item.hasPlus && renderLabel(LABEL_TYPES.PLUS))}
                    &nbsp;
                    {_item.hasCount && alertConfigurationsItems !== null ? `(${alertConfigurationsItems})` : ''}
                    {_item.isNewPage && <span className={classes.newPageLabel}>new</span>}
                  </Box>
                </a>
              </TypographyBox>
            );
          }
          return (
            <div
              key={_item.id}
              className={`
                  ${classes.subMenuItem}
                  ${_item.links && selectedItem === _item.links[0] ? classes.active : ''}
                `}
              onClick={() => {
                reportToSegment(_item.title);
                handleClick(_item);
              }}
              role="presentation"
              automation-id={_item.title}
            >
              {_item.title}
              {(_item.isNewTab && renderLabel(LABEL_TYPES.NEW)) || (_item.hasPlus && renderLabel(LABEL_TYPES.PLUS))}
              &nbsp;
              {_item.hasCount && alertConfigurationsItems !== null ? `(${alertConfigurationsItems})` : ''}
            </div>
          );
        })}
      </div>
    </div>,
    body,
  );
};

export default NavBarSubMenuItem;
