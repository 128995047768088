/* eslint-disable react/no-unescaped-entities */
// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {
  fetchGoogleStoragePreview,
  setGoogleStorageStreamFileFormat,
  setGoogleStorageStreamUiKeyVal,
  applyGoogleStorageStreamUiState,
  fetchGoogleStorageBuckets,
} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import {isStreamEditable} from 'bc/services/dataStreamService';
import FileSettings from 'bc/components/streams/editor/common/FileSettings/FileSettings';
import ConfirmationModal from 'common/components/modals/ConfirmationModal';
import {fileDatePatterns, allowedPollingIntervalsByFileDatePattern} from 'bc/services/genericStorageService';
import SmartTooltip from 'common/components/SmartTooltip';
import {take, get} from 'lodash';
import GsFilePreview from './GoogleStorageFilePreview';
import GoogleStorageFileNamePattern from './GoogleStorageFileNamePattern';
import GoogleStorageProject from './GoogleStorageProject';
import GoogleStorageBucket from './GoogleStorageBucket';
import './GoogleStorageData.module.scss';

type PropTypes = {
  dataStream: Object,
  analysisResult: Object,
  hasProjectAndBucket: boolean,
  isUiStateDirty: boolean,
  setGoogleStorageStreamFileFormat: Function,
  setGoogleStorageStreamUiKeyVal: Function,
  applyGoogleStorageStreamUiState: Function,
  fetchGoogleStorageBuckets: Function,
};

const EMPTY_ARRAY = [];

const getConfirmModalMessage = (fileNamePattern) => (
  <span>
    Timestamp pattern was changed to exclude minutes {fileNamePattern === 'yyyyMMdd' ? 'and hours' : ''}, collection
    interval will be updated to {fileNamePattern === 'yyyyMMdd' ? 'daily' : 'hourly'}
  </span>
);

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    analysisResult: selectors.getFileStreamAnalysisResult(state),
    isUiStateDirty: selectors.isGoogleStorageStreamUiStateDirty(state),
    hasProjectAndBucket: selectors.isGoogleStorageProjectAndBucket(state),
  }),
  {
    fetchGoogleStoragePreview,
    setGoogleStorageStreamFileFormat,
    setGoogleStorageStreamUiKeyVal,
    applyGoogleStorageStreamUiState,
    fetchGoogleStorageBuckets,
  },
)
export default class GoogleStorageData extends React.PureComponent {
  props: PropTypes;

  state = {
    isOpenFileSettingsModal: false,
    isOpenFilePreviewModal: false,
    isOpenChagePollingIntervalModal: false,
    path: get(this.props.dataStream, 'uiState.path', this.props.dataStream.path),
    fileNameSuffix: get(this.props.dataStream, 'uiState.fileNameSuffix', this.props.dataStream.fileNameSuffix),
    fileNamePrefix: get(this.props.dataStream, 'uiState.fileNamePrefix', this.props.dataStream.fileNamePrefix),
    fileNamePattern: get(this.props.dataStream, 'uiState.fileNamePattern', this.props.dataStream.fileNamePattern),
  };

  valueChanged = (val, inputId) => {
    const newState = {};
    newState[inputId] = val;
    this.setState(newState);
    this.props.setGoogleStorageStreamUiKeyVal(newState);
  };

  settingsClicked = () => {
    const {isOpenFileSettingsModal} = this.state;
    this.setState({isOpenFileSettingsModal: !isOpenFileSettingsModal});
  };

  fileSettingsChanged = (fileFormat) => {
    this.props.setGoogleStorageStreamFileFormat({
      fileFormat,
    });
  };

  previewClicked = () => {
    const {isOpenFilePreviewModal} = this.state;
    this.setState({isOpenFilePreviewModal: !isOpenFilePreviewModal});
  };

  togglePatternChangedConfirmModal = () => {
    const {isOpenChagePollingIntervalModal} = this.state;
    this.setState({isOpenChagePollingIntervalModal: !isOpenChagePollingIntervalModal});
  };

  onConfirmModal = () => {
    const {dataStream} = this.props;
    const allowedValues = allowedPollingIntervalsByFileDatePattern[this.state.fileNamePattern];
    this.togglePatternChangedConfirmModal();

    this.props.applyGoogleStorageStreamUiState({
      projectId: get(dataStream, 'uiState.projectId', dataStream.projectId),
      bucket: get(dataStream, 'uiState.bucket', dataStream.bucket),
      path: this.state.path,
      fileNameSuffix: this.state.fileNameSuffix,
      fileNamePrefix: this.state.fileNamePrefix,
      fileNamePattern: this.state.fileNamePattern,
      pollingInterval: allowedValues[allowedValues.length - 1],
    });
  };

  goClicked = () => {
    const {dataStream} = this.props;
    if (
      fileDatePatterns[this.state.fileNamePattern].order <
        fileDatePatterns[this.props.dataStream.fileNamePattern].order &&
      !(dataStream.pollingInterval === 'daily' && this.state.fileNamePattern === 'yyyyMMdd')
    ) {
      this.togglePatternChangedConfirmModal();
    } else {
      this.props.applyGoogleStorageStreamUiState({
        projectId: get(dataStream, 'uiState.projectId', dataStream.projectId),
        bucket: get(dataStream, 'uiState.bucket', dataStream.bucket),
        path: this.state.path,
        fileNameSuffix: this.state.fileNameSuffix,
        fileNamePrefix: this.state.fileNamePrefix,
        fileNamePattern: this.state.fileNamePattern,
      });
    }
  };

  revertClicked = () => {
    const {dataStream} = this.props;
    if (dataStream.projectId !== dataStream.uiState.projectId) {
      this.props.fetchGoogleStorageBuckets({dataSourceId: dataStream.dataSourceId, projectId: dataStream.projectId});
    }

    this.props.setGoogleStorageStreamUiKeyVal({
      projectId: dataStream.projectId,
      bucket: dataStream.bucket,
      path: dataStream.path,
      fileNameSuffix: dataStream.fileNameSuffix,
      fileNamePrefix: dataStream.fileNamePrefix,
      fileNamePattern: dataStream.fileNamePattern,
    });
  };

  render() {
    const {fileFormat, fileName, fileErrors, rowsErrors} = this.props.analysisResult;
    const {hasProjectAndBucket, isUiStateDirty, dataStream} = this.props;
    const isEditable = isStreamEditable(dataStream.state);
    const areErrorsPresent = (fileErrors && fileErrors.length) || (rowsErrors && rowsErrors.length);

    return (
      <div className="shell-col" styleName="root">
        <PanelHeader title="Files to Collect" isEditable={isEditable}>
          <button
            type="button"
            className="btn btn-icon btn-icon btn-dark-blue"
            disabled={!fileName || isUiStateDirty}
            onClick={this.previewClicked}
          >
            <i className="icon icn-view-icon" />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-icon btn-dark-blue"
            disabled={!fileName || !isEditable || isUiStateDirty}
            onClick={this.settingsClicked}
          >
            <i className="icon icn-settings-icon" />
          </button>
        </PanelHeader>

        {this.state.isOpenFileSettingsModal && (
          <FileSettings
            isOpen={this.state.isOpenFileSettingsModal}
            onClose={this.settingsClicked}
            onChange={this.fileSettingsChanged}
            fileFormat={fileFormat}
            fileName={fileName}
          />
        )}

        {this.state.isOpenFilePreviewModal && (
          <GsFilePreview isOpen={this.state.isOpenFilePreviewModal} onClose={this.previewClicked} />
        )}

        {this.state.isOpenChagePollingIntervalModal && (
          <ConfirmationModal
            onClose={this.togglePatternChangedConfirmModal}
            onConfirm={this.onConfirmModal}
            title="Change Collection Interval"
            message={getConfirmModalMessage(this.state.fileNamePattern)}
            isOpen={this.state.isOpenChagePollingIntervalModal}
          />
        )}

        <div styleName="inputs-wrapper">
          <GoogleStorageProject />

          <GoogleStorageBucket />

          <span className="ellipsis" styleName="input-title">
            Files Path
          </span>
          <span styleName="input-description">[Optional] Specify a path relative to the data source.</span>
          <input
            type="text"
            onChange={(e) => this.valueChanged(e.target.value, 'path')}
            placeholder="Relative path within the bucket"
            value={get(dataStream.uiState, 'path', dataStream.path)}
          />

          <GoogleStorageFileNamePattern
            value={get(dataStream.uiState, 'fileNamePattern', dataStream.fileNamePattern)}
            styleName="file-pattern"
            onChange={(pattern) => this.valueChanged(pattern, 'fileNamePattern')}
          />

          <span className="ellipsis" styleName="input-title">
            Filename Prefix
          </span>
          <span styleName="input-description">
            Filename prefix before date format "YYYYMMDDHH". (eg. <b>data_</b>
            2018031822_datafile.csv)
          </span>
          <input
            type="text"
            onChange={(e) => this.valueChanged(e.target.value, 'fileNamePrefix')}
            placeholder="example: data_"
            value={get(dataStream.uiState, 'fileNamePrefix', dataStream.fileNamePrefix)}
          />

          <span className="ellipsis" styleName="input-title">
            Filename Suffix
          </span>
          <span styleName="input-description">
            Filename suffix after date format "YYYYMMDDHH". (eg. data_2018031822
            <b>_datafile.csv</b>)
          </span>
          <input
            type="text"
            onChange={(e) => this.valueChanged(e.target.value, 'fileNameSuffix')}
            placeholder="example: _datafile.csv"
            value={get(dataStream.uiState, 'fileNameSuffix', dataStream.fileNameSuffix)}
          />

          <button
            type="button"
            className="btn btn-raised"
            disabled={!isUiStateDirty && !areErrorsPresent && !hasProjectAndBucket}
            onClick={this.goClicked}
          >
            {areErrorsPresent ? 'RETRY' : 'GO!'}
          </button>
          <button
            type="button"
            className="btn btn-flat btn-secondary"
            styleName="revert-btn"
            disabled={!dataStream.uiState || !isUiStateDirty}
            onClick={this.revertClicked}
          >
            REVERT
          </button>
        </div>

        {(fileErrors || EMPTY_ARRAY).length > 0 && (
          <div>
            <div styleName="error-title">File Errors</div>
            {fileErrors.map((e) => (
              <div styleName="error" key={e.message}>
                <div className="ellipsis">{e.message}</div>
              </div>
            ))}
          </div>
        )}

        {(rowsErrors || EMPTY_ARRAY).length > 0 && (
          <div>
            <div styleName="error-title">Row Errors</div>
            {take(rowsErrors, 20).map((e) => (
              <div styleName="error" className="shell" key={e.rowIdx}>
                <div styleName="row-error-num">{e.rowIdx}</div>
                {e.row ? (
                  <SmartTooltip placement="top" content={e.row}>
                    <div className="ellipsis">{e.message}</div>
                  </SmartTooltip>
                ) : (
                  <div className="ellipsis">{e.message}</div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
