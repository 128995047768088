// @flow
import React from 'react';
import {Box, makeStyles} from '@material-ui/core';

export const SIZE = {
  SMALL: {
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 400,
    padding: '4px',
    height: '22px',
  },
  MEDIUM: {
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: 500,
    padding: '5px 8px',
    height: '26px',
  },
};

const SEVERITY_TYPES = [
  {
    label: 'Info',
    value: 'info',
    color: '#17406f',
    bgcolor: 'rgba(23, 64, 111, 0.3)',
  },
  {
    label: 'Low',
    value: 'low',
    color: '#30de50',
    bgcolor: 'rgba(48, 222, 80, 0.3)',
  },
  {
    label: 'Medium',
    value: 'medium',
    color: '#ffac3d',
    bgcolor: 'rgba(255, 172, 61, 0.3)',
  },
  {
    label: 'High',
    value: 'high',
    color: '#f9691f',
    bgcolor: 'rgba(249, 105, 31, 0.3)',
  },
  {
    label: 'Critical',
    value: 'critical',
    color: '#d10f37',
    bgcolor: 'rgba(209, 15, 55, 0.3)',
  },
];

const TRIGGER_TYPES = [
  {
    label: 'Anomaly',
    value: 'anomaly',
    color: '#2671ff',
    bgcolor: 'rgba(38, 113, 255, 0.3)',
  },
  {
    label: 'Static',
    value: 'static',
    color: '#D10F37',
    bgcolor: 'rgba(209, 15, 55, 0.3)',
  },
  {
    label: 'No Data',
    value: 'noData',
    color: '#f9691f',
    bgcolor: 'rgba(249, 105, 31, 0.3)',
  },
];

const useSeverityStyles = makeStyles(() => ({
  severity: {
    borderRadius: '4px',
    border: 0,
    display: 'inline-block',
    backgroundColor: (props) => props.bgcolor,
    color: (props) => props.color,
    fontSize: (props) => props.size.fontSize,
    lineHeight: (props) => props.size.lineHeight,
    fontWeight: (props) => props.size.fontWeight,
    padding: (props) => props.size.padding,
    height: (props) => props.size.height,
  },
}));

export const Severity = ({level, size}: {level: String, size: Object}) => {
  const sevFound = SEVERITY_TYPES.find((sev) => sev.value === level) || null;
  if (!sevFound) {
    return null;
  }

  const classes = useSeverityStyles({...sevFound, size: size || SIZE.SMALL});

  return (
    <Box className={classes.severity} flexShrink={0}>
      {sevFound.label}
    </Box>
  );
};

export const TriggerType = ({type, size}: {type: String, size: Object}) => {
  const triTypeFound = TRIGGER_TYPES.find((tri) => tri.value === type) || null;
  if (!triTypeFound) {
    return null;
  }

  const classes = useSeverityStyles({...triTypeFound, size: size || SIZE.SMALL});

  return (
    <Box className={classes.severity} flexShrink={0}>
      {triTypeFound.label}
    </Box>
  );
};
