import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import Button, {COLORS} from 'common/componentsV2/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Modal, {SIZES as MODAL_SIZES} from 'common/componentsV2/modal/Modal';
import {makeStyles} from '@material-ui/core/styles';
import {TypographyBox} from 'common/componentsV2/boxTools';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import AutocompleteSelection, {optionHeight} from 'common/componentsV2/autocompleteSelection/AutocompleteSelection';
import * as actions from '../store/actions';
import InputAuto from '../../utils/InputAuto';
import {ALERT_ACTION_PARAMS, commonStyle, INITIAL_ACTION} from '../services/service';
import * as selectors from '../store/selectors';
import ConfirmationMessageModal from './ConfirmationModal';

const mandatoryFields = [ALERT_ACTION_PARAMS.ACTION_NAME, ALERT_ACTION_PARAMS.BTN_NAME, ALERT_ACTION_PARAMS.DATA];

const useStyles = makeStyles(() => ({
  header: {
    height: commonStyle.headerHeight,
  },
  body: {
    height: commonStyle.listHeight,
  },
  footer: {},
  fieldWrapper: {
    marginTop: 24,
  },
  loaderMask: {
    position: 'absolute',
    zIndex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(255 255 255 / 35%)',
    width: '94%',
    height: '88%',
  },
}));

const EditActionsModal = (props: PropTypes) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const allActionsData = useSelector(selectors.getAllAlertActionsData);
  const allActionsIsLoading = useSelector(selectors.getAllAlertActionsIsLoading);
  const createActionData = useSelector(selectors.getCreateActionData);
  const createActionIsLoading = useSelector(selectors.getCreateActionIsLoading);
  const updateActionData = useSelector(selectors.getUpdateActionData);
  const updateActionIsLoading = useSelector(selectors.getUpdateActionIsLoading);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [action, setAction] = useState(INITIAL_ACTION);
  const [isConfirmationMessageModalOpen, setIsConfirmationMessageModalOpen] = useState(false);

  const setValidation = () => {
    // eslint-disable-next-line
    for (const key in action) {
      if (mandatoryFields.includes(key) && !action[key]) {
        setIsBtnDisabled(true);
        return;
      }
    }
    setIsBtnDisabled(false);
  };

  useEffect(() => {
    if (!props.id) {
      setAction(INITIAL_ACTION);
    }
    if (props.id && allActionsData.length) {
      setAction(allActionsData.find((a) => a.id === props.id));
    }
  }, [allActionsData, props.id]);

  useEffect(() => {
    if (createActionIsLoading === false && createActionData && createActionData.length) {
      props.onClose();
    }
  }, [createActionData, createActionIsLoading]);

  useEffect(() => {
    if (updateActionIsLoading === false && updateActionData && updateActionData.length) {
      props.onClose();
      setIsConfirmationMessageModalOpen(false);
    }
  }, [updateActionData, updateActionIsLoading]);

  useEffect(() => {
    setValidation();
  }, [action]);

  const createAction = () => {
    dispatch(actions.createAction([action]));
  };

  const updateAction = () => {
    dispatch(actions.updateAction([action]));
  };

  const editField = (fieldName, value) => {
    // Extract updated value to handle both events and new value
    const newValue = value.target ? value.target.value : value;
    setAction((prevState) => ({
      ...prevState,
      [fieldName]: newValue,
    }));
  };

  return (
    <Fragment>
      <Modal
        onClose={props.onClose}
        isOpen={props.isModalOpen}
        size={MODAL_SIZES.LARGE}
        isCloseButtonHidden
        isStatic={false}
        height="485px"
      >
        <Grid container>
          <Grid item sm={12} className={classes.header}>
            <TypographyBox variant="h2" mb={1}>
              {action[ALERT_ACTION_PARAMS.ACTION_ID] ? 'Edit Action' : 'New Action'}
            </TypographyBox>
            <div className="text14reg lineHeight_14 mb_1">
              Enter the link below that includes the call for the action.
            </div>
            <div className="text14reg lineHeight_14">
              When the alert is triggered, this action will be available for the user. Once activated the link opens.
            </div>
          </Grid>

          <Grid item sm={12} className={classes.body}>
            {(allActionsIsLoading || createActionIsLoading || updateActionIsLoading) && (
              <Box className={classes.loaderMask}>
                <Spinner color="#ccc" size={SIZES.BIG_60} />
              </Box>
            )}
            <Grid container>
              <Grid item sm={5} className={classes.fieldWrapper}>
                <TypographyBox variant="subtitle3" mb={1}>
                  Action Name
                </TypographyBox>
                <Box height={45}>
                  <AutocompleteSelection
                    onChange={(e) => editField(ALERT_ACTION_PARAMS.ACTION_NAME, e)}
                    inputValue={action[ALERT_ACTION_PARAMS.ACTION_NAME]}
                    panelHeight={6 * optionHeight}
                  >
                    {(handleChange, handleKeyDown) => (
                      <InputAuto
                        placeHolder="Enter a name for this action"
                        fullSize
                        delay={1000}
                        type="text"
                        automationId="1"
                        value={action[ALERT_ACTION_PARAMS.ACTION_NAME]}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                      />
                    )}
                  </AutocompleteSelection>
                </Box>
              </Grid>
              <Grid item sm={10} className={classes.fieldWrapper}>
                <TypographyBox variant="subtitle3" mb={1}>
                  Link
                </TypographyBox>
                <Box height={45}>
                  <AutocompleteSelection
                    onChange={(e) =>
                      editField(ALERT_ACTION_PARAMS.DATA, {
                        [ALERT_ACTION_PARAMS.DATA_URL]: e.target ? e.target.value : e,
                      })
                    }
                    inputValue={action[ALERT_ACTION_PARAMS.DATA][ALERT_ACTION_PARAMS.DATA_URL]}
                    panelHeight={4 * optionHeight}
                  >
                    {(handleChange, handleKeyDown) => (
                      <InputAuto
                        placeHolder="Enter the URL to be opened"
                        fullSize
                        delay={1000}
                        type="text"
                        automationId="2"
                        value={action[ALERT_ACTION_PARAMS.DATA][ALERT_ACTION_PARAMS.DATA_URL]}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                      />
                    )}
                  </AutocompleteSelection>
                </Box>
              </Grid>
              <Grid item sm={5} className={classes.fieldWrapper}>
                <TypographyBox variant="subtitle3" mb={1}>
                  What will the button say (max 20 chars.)
                </TypographyBox>
                <Box height={45}>
                  <AutocompleteSelection
                    onChange={(e) => editField(ALERT_ACTION_PARAMS.BTN_NAME, e)}
                    inputValue={action[ALERT_ACTION_PARAMS.BTN_NAME]}
                    panelHeight={6 * optionHeight}
                    bottomOffset={45}
                  >
                    {(handleChange, handleKeyDown) => (
                      <InputAuto
                        placeHolder="e.g Open in Grafana"
                        fullSize
                        delay={1000}
                        type="text"
                        automationId="3"
                        value={action[ALERT_ACTION_PARAMS.BTN_NAME]}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                      />
                    )}
                  </AutocompleteSelection>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item sm={12} className={classes.footer}>
            <Grid container>
              <Grid container item sm={6}>
                {action[ALERT_ACTION_PARAMS.ACTION_ID] && (
                  <TypographyBox component="span" variant="subtitle2">
                    This link is used in {action.usedInAlerts} alerts
                  </TypographyBox>
                )}
              </Grid>

              <Grid container item sm={6} justify="flex-end">
                <Button text="Cancel" onClick={() => props.onClose(false)} />
                <Box ml={1.5}>
                  {action[ALERT_ACTION_PARAMS.ACTION_ID] ? (
                    <Button
                      text="Update Action"
                      onClick={() => setIsConfirmationMessageModalOpen(true)}
                      isDisabled={isBtnDisabled}
                      colorSchema={COLORS.BLUE_500}
                    />
                  ) : (
                    <Button
                      text="Create Action"
                      onClick={createAction}
                      isDisabled={isBtnDisabled}
                      colorSchema={COLORS.BLUE_500}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>

      <ConfirmationMessageModal
        isModalOpen={isConfirmationMessageModalOpen}
        onClose={() => setIsConfirmationMessageModalOpen(false)}
        onClick={updateAction}
        beingUsed={action.usedInAlerts}
        btnText="Save Link"
        btnColor={COLORS.BLUE_500}
      />
    </Fragment>
  );
};

EditActionsModal.propTypes = {
  id: PropTypes.string,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

EditActionsModal.defaultProps = {
  id: null,
};

export default EditActionsModal;
