// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedEditor} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import SchedularData from 'bc/components/streams/editor/common/SchedularData';
import {isStreamEditable} from 'bc/services/dataStreamService';
import '../../sql/sqlSchedular/SqlSchedular.module.scss';

type PropTypes = {
  // connect
  dataStream: Object,
  setSelectedEditor: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    setSelectedEditor,
  },
)
export default class adobeScheduler extends React.PureComponent {
  props: PropTypes;

  openEditor = () => {
    this.props.setSelectedEditor({
      type: 'scheduler_adobe',
      editorHeight: 'calc(100% - 210px)',
      maxHeight: '600px',
    });
  };

  render() {
    const {
      dataStream: {state},
    } = this.props;
    const isEditable = isStreamEditable(state);

    return (
      <div className="shell-col" styleName={!isEditable ? 'disabled' : ''}>
        <PanelHeader title="Schedule Report" onSelect={this.openEditor} isEditable={isStreamEditable(state)} />
        <SchedularData />
      </div>
    );
  }
}
