import moment from 'moment';
import {get, map, intersection, findIndex, forEach, trim} from 'lodash';
import {broadcast} from './common/utils/angularServices';

const getEnvName = (window) => {
  if (window.isProduction) {
    if (window.isUs) {
      return 'US';
    }
    if (window.isSydney) {
      return 'SYDNEY';
    }
    if (window.isMumbai) {
      return 'MUMBAI';
    }
    if (window.isFrankfurt) {
      return 'FRANKFURT';
    }
    return 'PRODUCTION';
  }
  return 'NOT PRODUCTION';
};

const allRoleInfos = [
  {role: 'anodot-admin', name: 'Anodot Admin'},
  {role: 'anodot-user', name: 'Anodot User'},
  // {role: 'reseller-admin', name: 'Reseller Admin'},
  // {role: 'reseller-user', name: 'Reseller User'},
  {role: 'customer-admin', name: 'Admin'},
  {role: 'customer-user', name: 'User'},
  {role: 'customer-read-only', name: 'Read Only User'},
];

const isRoleEqualOrLowerThen = function(role) {
  // eslint-disable-next-line no-param-reassign
  role = role.role || role;
  const index = findIndex(allRoleInfos, function(i) {
    return i.role === role;
  });
  return allRoleInfos.slice(index);
};

const getRolesEqualOrLowerThen = function(role) {
  return map(isRoleEqualOrLowerThen(role), 'role');
};

export const setGlobalUser = function(newVal) {
  const me = newVal;
  if (me && !me.imageSrc) {
    me.imageSrc = 'src/modules/andt.common/style/images/user/user_icon.png';
  }

  if (!window.isOnPremise) {
    if (window.isProduction || window.isPoc) {
      if (me && intersection(me.roles, getRolesEqualOrLowerThen('customer-admin')).length) {
        if (!window.aptrinsic) {
          // eslint-disable-next-line no-console
          console.warn('Aptrinsic load failure');
        } else {
          window.aptrinsic(
            'identify',
            {
              id: me._id,
              email: me.email,
              firstName: me.firstName,
              lastName: me.lastName,
              userHash: me.aptrinsicHmac,
              env: getEnvName(window),
            },
            {
              id: me.organization._id,
              name: me.organization.name,
              payingCustomer: get(me, 'organization.settings.payingCustomer', false),
              selfService: get(me, 'organization.settings.sierra.selfService', false),
              selfServicePackage: get(me, 'organization.settings.sierra.selfServicePackage', null),
            },
          );
        }
      }
    }

    if (me && window.isProduction && window.FS) {
      if (me.impersonatorId) {
        window.FS.shutdown();
      } else {
        window.FS.restart();
        window.FS.identify(me._id, {
          displayName: `${me.firstName} ${me.lastName}`,
          org: get(me, 'organization.name', ''),
          email: me.email,
          role: me.role,
        });
      }
    }
  }

  const growlContainer = document.getElementById('andtGrowl');

  if (growlContainer && growlContainer.getElementsByClassName('growl-item').length) {
    // growl.success('', {ttl: 1});
  }

  if (!window.andtInitialMe) {
    let loaderElm = document.getElementById('loader');
    loaderElm.classList.add('loader-fade-out');
    setTimeout(() => {
      loaderElm.parentNode.removeChild(loaderElm);
      loaderElm = null;
    }, 5000);

    window.andtInitialMe = me;
    window.angular.bootstrap(document, ['andt.app'], {strictDi: true});
  }

  broadcast('andtAuthMeChanged', me);
  moment.updateLocale('en', {
    week: {
      dow: get(me, 'organization.serverConfiguration.firstDayOfWeek', 1),
    },
  });
};

function getTokenFromUrl() {
  let token = '';
  const search = window.location.hash.split('?')[1];
  const queries = search ? search.split('&') : [];
  let newHash = `${window.location.hash.split('?')[0]}?`;

  forEach(queries, function(query) {
    const q = query.split('=');
    token = q[0] === 'token' ? q[1] : '';

    if (!token) {
      newHash = `${newHash + query}&`;
    }
  });
  newHash = trim(trim(newHash, '?'), '&');
  if (newHash !== window.location.hash) {
    window.location.hash = newHash;
  }

  if (token.length) {
    // if we got here than truncate storage, we dont want the renew (or anything else) process to take old tokens from the storage
    localStorage.removeItem('andt-token');
    localStorage.removeItem('andt-token-ttl');
    localStorage.removeItem('andt-token-iat');
  }
  return token;
}

export const processAndtToken = () => {
  const token = getTokenFromUrl() || localStorage.getItem('andt-token');

  if (!token && window.location.hash.indexOf('invitationId') !== -1) {
    return 'anonymous';
  }

  if (!token) {
    window.location.href = `${window.location.origin}/signin/`;
    // return;
  }

  if (token) {
    localStorage.setItem('andt-token', token);
    localStorage.setItem('andt-token-ttl', JSON.parse(atob(token.split('.')[1])).exp);
    localStorage.setItem('andt-token-iat', JSON.parse(atob(token.split('.')[1])).iat);
  }

  // eslint-disable-next-line no-undef
  FastClick.attach(document.body); // mobile fastclick support
  return token;
};
