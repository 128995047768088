// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {getFormattedDateMini, getFormattedHumanDuration} from 'common/utils/dateService';
import StaticListItem from 'common/componentsV2/table/StaticListItem';
import {get} from 'lodash';
import './AlertTriggersTable.module.scss';

type PropTypes = {
  trigger: Object,
};

class AlertTriggersTableRow extends React.PureComponent {
  props: PropTypes;

  handleClick = () => {
    const {alerts} = this.props.trigger;
    let triggerId = null;
    if (alerts.length > 0) {
      triggerId = alerts[0].id;
    }
    if (triggerId) {
      window.open(
        // eslint-disable-next-line max-len
        `/#!/r/alerts-console?ref=email&triggerIds=${triggerId}&status=&size=100&index=0&startTime=&endTime=&constRange=y1&ack=&subscribers=&channels=`,
        '_blank',
      );
    }
  };

  render() {
    const {trigger} = this.props;
    const score = get(trigger, 'alerts[0].metrics[0].intervals[0].score', null);
    return (
      <StaticListItem handleClick={this.handleClick}>
        <div styleName="title">{trigger.name}</div>
        <div styleName="startTime">{getFormattedDateMini(trigger.startTime)}</div>
        <div styleName="updateTime">{getFormattedDateMini(trigger.updateTime)}</div>
        <div styleName="duration">{getFormattedHumanDuration(trigger.updateTime - trigger.startTime)}</div>
        <div styleName="score">{score !== null ? Math.round(score * 100) : ''}</div>
        <div styleName="link" />
      </StaticListItem>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertTriggersTableRow);
