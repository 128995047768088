// @flow
import React, {PureComponent} from 'react';
import connect from 'react-redux/es/connect/connect';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {STATUS} from 'bc/services/dataManagerService';
import {segmentClickEvent} from 'common/store/actions';
import {getDataMangerQueryParams} from 'bc/store/selectors';

type PropTypes = {
  setQueryParams: Function,

  // connect
  dataQueryParams: Object,
  segmentClickEvent: Function,
};

@connect(
  (state) => ({
    dataQueryParams: getDataMangerQueryParams(state),
  }),
  {
    segmentClickEvent,
  },
)
export default class StatusFilter extends PureComponent {
  props: PropTypes;

  onChange = (values) => {
    const {setQueryParams} = this.props;
    const currentValues = values.length ? values.map((i) => i.value).join(',') : undefined;
    if (!currentValues || currentValues.includes('all')) {
      setQueryParams({status: undefined});
    } else {
      this.props.segmentClickEvent({
        category: 'data-management-filter',
        name: `status-${currentValues}`,
      });
      setQueryParams({status: currentValues});
    }
  };

  render() {
    const {dataQueryParams} = this.props;
    const selectedStatusArr = dataQueryParams.status ? dataQueryParams.status.split(',') : [];
    const optionCurrent = STATUS.filter((i) => selectedStatusArr.includes(i.value));
    return (
      <SelectAndt
        id="statusFilter"
        automationId="DataManagerStatusFilter"
        extraClassName="alerts-dropdown-btn"
        options={STATUS}
        type={TYPE_NEW_MULTI_NO_SEARCH}
        theme={THEME_HIGHLIGHTED}
        onChange={this.onChange}
        value={optionCurrent}
        isMulti
        placeholder="All"
        isClearable={false}
        closeMenuOnSelect={false}
        buttonWidth={190}
        optionHeight={40}
      />
    );
  }
}
