import moment from 'moment';
import 'moment-timezone';
import {defaultsDeep, isEmpty} from 'lodash';
import {abbrNum} from 'common/utils/numbers';
import {COLOR_NAME} from 'common/componentsV2/ColorPicker';

export const POINT_HOVER_DIAMETER = 10;

export const METRIC_SERIES = {
  AVERAGE: 'average',
  AVERAGE_BASELINE: 'average_baseline',
  ANOMALY_PRIMARY: 'anomalyPrimary',
  ANOMALY_SECONDARY: 'anomalySecondary',
};

export const HCHART_TYPES = {
  AREA_RANGE: 'arearange',
  LINE: 'line',
  SPLINE: 'spline',
  COLUMN: 'column',
  AREA: 'area',
};

export const CHART_COLORS = {
  LABEL_COLOR: '#A2A2A2',
  LABEL_COLOR_DARK: '#404a52',
  GRID_LINE_COLOR: '#E0E0E0',
  ANOMALY_LINE_COLOR: '#ff8f24',
  UNSATISFIED_ANOMALY_LINE_COLOR: '#3d4c59',
  NO_DATA_LINE_COLOR: '#8995A0',
  NO_DATA_COLOR: '#b5bbc3',
  SERIES_COLORS: [
    '#2671ff',
    '#e91e63',
    '#00bbd4',
    '#8947cd',
    '#ff5653',
    '#3f51b6',
    '#00934e',
    '#bf8563',
    '#00b7f1',
    '#f06292',
    '#1a896a',
    '#d083e8',
    '#7cb342',
    '#800661',
  ],
  SERIES_COLORS_DASHBOARD: [
    COLOR_NAME.BLUE,
    COLOR_NAME.ROSE,
    COLOR_NAME.TEAL,
    COLOR_NAME.PURPLE,
    COLOR_NAME.TOMATO,
    COLOR_NAME.INDIGO_400,
    COLOR_NAME.EUCALIPTUS,
    COLOR_NAME.BROWN_400,
    COLOR_NAME.AZURE,
    COLOR_NAME.ROSE_400,
    COLOR_NAME.MINT_600,
    COLOR_NAME.LILACH_400,
    COLOR_NAME.LIME,
    COLOR_NAME.EGGPLANT,
  ],
  CONFIDANCE_COLORS: [
    'rgba(0,153,219,0.2)',
    'rgba(126,178,109,0.2)',
    'rgba(234,184,57,0.2)',
    'rgba(110,208,224,0.2)',
    'rgba(239,132,60,0.2)',
    'rgba(226,77,66,0.2)',
    'rgba(31,120,193,0.2)',
    'rgba(186,67,169,0.2)',
    'rgba(112,93,160,0.2)',
    'rgba(80,134,66,0.2)',
    'rgba(204,163,0,0.2)',
    'rgba(68,126,188,0.2)',
    'rgba(193,92,23,0.2)',
    'rgba(137,15,2,0.2)',
    'rgba(10,67,124,0.2)',
    'rgba(109,31,98,0.2)',
    'rgba(88,68,119,0.2)',
    'rgba(183,219,171,0.2)',
    'rgba(244,213,152,0.2)',
    'rgba(112,219,237,0.2)',
    'rgba(249,186,143,0.2)',
    'rgba(242,145,145,0.2)',
    'rgba(130,181,216,0.2)',
    'rgba(229,168,226,0.2)',
    'rgba(174,162,224,0.2)',
    'rgba(98,158,81,0.2)',
    'rgba(229,172,14,0.2)',
    'rgba(100,176,200,0.2)',
    'rgba(224,117,45,0.2)',
    'rgba(191,27,0,1)',
    'rgba(10,80,161,0.2)',
    'rgba(150,45,130,0.2)',
    'rgba(97,77,147,0.2)',
    'rgba(154,196,138,0.2)',
    'rgba(242,201,109,0.2)',
    'rgba(101,197,219,0.2)',
    'rgba(249,147,78,0.2)',
    'rgba(234,100,96,0.2)',
    'rgba(81,149,206,0.2)',
    'rgba(214,131,206,0.2)',
    'rgba(128,110,183,0.2)',
    'rgba(63,104,51,0.2)',
    'rgba(150,115,2,0.2)',
    'rgba(47,87,94,0.2)',
    'rgba(153,68,10,0.2)',
    'rgba(88,20,12,0.2)',
    'rgba(5,43,81,0.2)',
    'rgba(81,23,73,0.2)',
    'rgba(63,43,91,0.2)',
    'rgba(224,249,215,0.2)',
    'rgba(252,234,202,0.2)',
    'rgba(207,250,255,0.2)',
    'rgba(249,226,210,0.2)',
    'rgba(252,226,222,0.2)',
    'rgba(186,223,244,0.2)',
    'rgba(249,217,249,0.2)',
    'rgba(222,218,247,0.2)',
  ],
};

export const SIMULATION_CHART_COLORS = {
  ANOMALY_LINE_COLOR: '#eb6f07',
  UNSATISFIED_ANOMALY_LINE_COLOR: '#8995a0',
};

function yAxisFormatter() {
  return abbrNum(this.value, 3, true);
}

function xAxisFormatter() {
  if (this.dateTimeLabelFormat === "%b '%y") {
    const minXaxisYear = moment(this.axis.min).year();
    const maxXaxisYear = moment(this.axis.max).year();
    if (minXaxisYear === maxXaxisYear) {
      this.dateTimeLabelFormat = '%b';
    }
  }
  return this.axis.defaultLabelFormatter.call(this);
}

const THEMES = {
  preview: {
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%e %b',
        week: '%e %b',
      },
      lineColor: '#d4d9e0',
      gridLineColor: '#e9e9f0',
      padding: 20,
      labels: {
        style: {
          color: '#8995a0',
          fontWeight: 500,
          whiteSpace: 'nowrap',
        },
      },
    },
    yAxis: {
      lineColor: '#d4d9e0',
      gridLineColor: '#e9e9f0',
      title: {
        text: null,
      },
      labels: {
        style: {
          color: '#3d4c59',
          fontWeight: 500,
        },
        formatter: yAxisFormatter,
      },
    },
  },
  simulation: {
    chart: {
      plotBackgroundColor: '#e9f0ff',
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%e %b',
      },
      lineColor: '#c9dbff',
      gridLineColor: '#c9dbff',
      gridLineDashStyle: 'dash',
      padding: 20,
      labels: {
        style: {
          color: '#8995a0',
          fontWeight: 500,
          whiteSpace: 'nowrap',
        },
      },
    },
    yAxis: {
      lineColor: '#c9dbff',
      gridLineColor: '#c9dbff',
      gridLineDashStyle: 'dash',
      title: {
        text: null,
      },
      labels: {
        style: {
          color: '#3d4c59',
          fontWeight: 500,
        },
        formatter: yAxisFormatter,
      },
    },
  },
};

export const getLineChartDefaultDisplayProperties = () => ({
  type: HCHART_TYPES.LINE,
  stacking: null,
  yAxis: {
    opposite: false,
    max: null,
    min: null,
    type: 'linear',
    minorTickInterval: 'auto',
  },
});

export const getYAxisDefaults = (isOpposite) => ({
  opposite: isOpposite,
  endOnTick: false,
  startOnTick: false,
  gridLineWidth: 1,
  gridLineColor: CHART_COLORS.GRID_LINE_COLOR,
  title: {
    text: null,
  },
  labels: {
    style: {
      color: '#3d4c59',
      fontWeight: 500,
    },
    formatter: yAxisFormatter,
  },
  // showEmpty: true,
});

export const getThresholdPlotLineConfig = () => ({
  color: '#D10F37',
  width: 2,
  zIndex: 5,
  dashStyle: 'Dash',
  label: {
    align: 'left',
    x: 10,
    style: {
      fontWeight: 'bold',
      color: '#D10F37',
      fontSize: '12px',
      textTransform: 'uppercase',
      textOutline: '#fff',
      textOutlineWidth: 2,
    },
  },
});

export const getGraphTilePlotLineConfig = () => ({
  color: '#8995A0',
  width: 2,
  zIndex: 5,
  dashStyle: 'Dash',
  label: {
    align: 'left',
    x: 0,
    y: -15,
    useHTML: true,
    style: {
      fontWeight: 500,
      color: '#fff',
      fontSize: '14px',
      textTransform: 'uppercase',
      textOutline: '#fff',
      textOutlineWidth: 2,
      borderRadius: '3px',
      padding: '4px',
    },
  },
});

export const getCrosshairConfig = () => ({
  dashStyle: 'Dash',
  width: 1,
  color: '#8995a0',
  zIndex: 2,
});

export const getAxisZones = (config) => {
  if (!config || isEmpty(config) || isEmpty(config.zones)) {
    return {};
  }

  const zones = [];
  (config.zones || []).forEach((aZone) => {
    zones.push({
      value: aZone.startValue,
      color: config.primeColor,
      dashStyle: config.primeDashStyle,
    });
    zones.push({
      value: aZone.endValue,
      color: aZone.color || config.zoneColor,
      dashStyle: aZone.dashStyle || config.zoneDashStyle,
    });
  });

  if (zones.length) {
    zones.push({
      color: config.primeColor,
      dashStyle: config.primeDashStyle,
    });

    return {
      zoneAxis: config.zoneAxis ? config.zoneAxis : 'x',
      zones,
    };
  }

  return {};
};

export const getHchartConfig = (isMobile, theme, isInactiveMode = false) =>
  defaultsDeep(THEMES[theme] || {}, {
    chart: {
      pinchType: isMobile ? 'x' : null,
      zoomType: isMobile ? null : 'x',
      resetZoomButton: {
        theme: {
          display: 'none',
        },
      },
      backgroundColor: CHART_COLORS.BACKGROUND_COLOR,
      events: {},
      spacing: [5, 0, 5, 0],
    },
    lang: {
      noData: 'No Data',
    },
    noData: {
      style: {
        fontSize: '18px',
        color: CHART_COLORS.NO_DATA_COLOR,
      },
      useHTML: true,
    },
    boost: {
      // https://www.highcharts.com/docs/advanced-chart-features/boost-module
      enabled: false, // till this is fixed: https://github.com/highcharts/highcharts/issues/8164
      // seriesThreshold: 50, //A number that specifies how many series the chart must contain before
      // it enters boost mode. Defaults to 50
      // at series object level->
      // series|plotOptions.series.boostThreshold The number of points that needs to be in the series
      // or the boost to kick in. Defaults to 5000.
      useGPUTranslations: true,
    },
    tooltip: {
      enabled: true,
      hideDelay: 0,
      shared: false,
      followPointer: true,
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      animation: false,
    },
    xAxis: {
      type: 'datetime',
      tickPosition: 'inside',
      tickWidth: 0,
      lineWidth: 1,
      lineColor: CHART_COLORS.GRID_LINE_COLOR,
      gridLineWidth: 1,
      gridLineColor: CHART_COLORS.GRID_LINE_COLOR,
      labels: {
        style: {
          color: '#8995a0',
          fontWeight: 500,
          whiteSpace: 'nowrap',
        },
        formatter: xAxisFormatter,
      },
      crosshair: getCrosshairConfig(),
      units: [
        ['minute', [1, 2, 5, 10, 15, 30]],
        ['hour', [1, 2, 3, 4, 6, 8, 12]],
        ['day', [1, 3, 5]],
        ['week', [1, 2]],
        ['month', [1, 3, 6]],
        ['year', null],
      ],
    },
    yAxis: [getYAxisDefaults(false)],
    plotOptions: {
      series: {
        stickyTracking: true,
        states: {
          hover: {
            halo: {
              size: 0,
            },
          },
          inactive: {
            opacity: isInactiveMode ? 0.5 : 1,
          },
        },
        turboThreshold: 1,
        events: {},
        marker: {
          lineColor: null,
          states: {
            hover: {
              lineColor: '#ffffff',
              lineWidthPlus: 2,
            },
          },
        },
      },
      column: {
        grouping: false,
      },
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    series: [],
  });

export const getGlobalSettings = () => ({
  global: {
    timezone: moment.tz.guess(), // gets locale time zone name
    //  useUTC: true
  },
  lang: {
    loading: '<i class="icon ion-load-c spin"</i>',
    noData: 'No Data to display - change time range or metric selection',
  },
  chart: {
    pinchType: 'x',
    zoomType: 'x',
    panning: false,
    animation: false,
    backgroundColor: null,
    alignTicks: false,
    showAxes: true,
    marginBottom: null,
    style: {
      fontFamily: 'inherit',
    },
  },
  plotOptions: {
    series: {
      animation: false,
      fillOpacity: 0.2,
    },
  },
  title: {
    text: null,
  },
  colors: CHART_COLORS.SERIES_COLORS,
  noData: {
    position: {
      x: 0,
    },
    style: {
      fontWeight: 'normal',
      fontSize: '12px',
      color: '#979797',
    },
  },
  loading: {
    style: {
      position: 'absolute',
      left: 0,
      backgroundColor: 'transparent',
      opacity: 0.5,
      textAlign: 'center',
    },
    labelStyle: {
      position: 'absolute',
      left: '0px',
      top: '0px',
    },
  },
  credits: {
    enabled: false,
  },
});
