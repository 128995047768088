import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {Observable} from 'rxjs';
import * as actions from '../actions';
import * as api from '../../services/api';
import * as selectors from '../selectors';

const fetchBigQueryDataSets = makeAsyncEpic(actions.fetchBigQueryDataSets, api.fetchBigQueryDataSets);
const fetchBigQueryProjects = makeAsyncEpic(actions.fetchBigQueryProjects, api.fetchBigQueryProjects);
const fetchBigQueryVerifyQuery = makeAsyncEpic(actions.fetchBigQueryVerifyQuery, api.fetchBigQueryVerifyQuery);

const setBigQueryStreamProjectId = (action$, {getState}) =>
  action$.ofType(actions.setBigQueryStreamProjectId.TYPE).flatMap((action) => {
    const stream = selectors.getSelectedDataStream(getState());
    return [actions.fetchBigQueryDataSets({dataSourceId: stream.dataSourceId, projectId: action.payload.projectId})];
  });

const fetchBigQueryProjectsSuccess = (action$, {getState}) =>
  action$.ofType(actions.fetchBigQueryProjects.success.TYPE).flatMap((action) => {
    const stream = selectors.getSelectedDataStream(getState());
    if (action.payload && action.payload.length > 0) {
      if (!stream.projectId) {
        return [actions.setBigQueryStreamProjectId({projectId: action.payload[0].projectId})];
      }
      return [actions.fetchBigQueryDataSets({dataSourceId: stream.dataSourceId, projectId: stream.projectId})];
    }
    return [];
  });

const fetchBigQueryPreview = (action$, {getState}) =>
  action$.ofType(actions.fetchBigQueryPreview.TYPE).switchMap((action) => {
    const stream = selectors.getSelectedDataStream(getState());

    return api
      .fetchBigQueryPreview({
        payload: {
          useLegacySQL: action.payload.useLegacySQL,
          query: action.payload.query,
          dataSourceId: stream.dataSourceId,
          projectId: stream.projectId,
          defaultDataset: stream.defaultDataset || null,
          timeZone: stream.timeZone,
          pollingInterval: stream.pollingInterval,
        },
      })
      .map((payload) => actions.fetchBigQueryPreview.success(payload, action.meta))
      .catch((error) => Observable.of(actions.fetchBigQueryPreview.failure(error, action.meta)));
  });

const bigQueryEpic = combineEpics(
  fetchBigQueryDataSets,
  fetchBigQueryProjects,
  fetchBigQueryPreview,
  fetchBigQueryVerifyQuery,
  setBigQueryStreamProjectId,
  fetchBigQueryProjectsSuccess,
);
export default bigQueryEpic;
