// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  setFileUploadName,
  setSelectedStreamKeyVal,
  setSelectedStreamUiKeyVal,
  setUploadedFileUrl,
  fetchGoogleAuctionsStreamAnalysis,
  fetchGoogleAuctionsTemplates,
} from 'bc/store/actions';
import {get} from 'lodash';
import * as selectors from 'bc/store/selectors';
import {uploadFile, CSV_TYPES_ARR} from 'bc/services/googleAuctionsService';
import TimeZone from 'bc/components/streams/editor/common/TimeZone';
import Button, {COLORS} from 'common/componentsV2/Button';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import './Reports.module.scss';
import {THEME_LIGHT} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import Loader from 'common/components/Loader';
import ErrorModal from 'common/components/modals/ErrorModal';

type PropTypes = {
  dataStream: Object,
  isLoading: boolean,
  templates: Array,

  setFileUploadName: Function,
  setUploadedFileUrl: Function,
  setSelectedStreamKeyVal: Function,
  setSelectedStreamUiKeyVal: Function,
  fetchGoogleAuctionsStreamAnalysis: Function,
  fetchGoogleAuctionsTemplates: Function,
};

@connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isLoading: selectors.getGoogleAuctionsTemplates(state).isLoading,
    templates: selectors.getGoogleAuctionsTemplatesItems(state),
  }),
  {
    setFileUploadName,
    setUploadedFileUrl,
    fetchGoogleAuctionsStreamAnalysis,
    setSelectedStreamKeyVal,
    setSelectedStreamUiKeyVal,
    fetchGoogleAuctionsTemplates,
  },
)
export default class Reports extends React.PureComponent {
  props: PropTypes;

  state = {
    fileName: get(this.props.dataStream, 'uiState.fileName', null),
    reportName: get(this.props.dataStream, 'reportName', null),
    timeZone: get(this.props.dataStream, 'timeZone', null),
    isFileErrorOpen: false,
    fileErrorText: '',
  };

  componentDidMount() {
    const {dataStream} = this.props;
    this.props.fetchGoogleAuctionsTemplates(dataStream.type);
  }

  fileUploadSuccess = (path, folder) => {
    this.props.setFileUploadName();
    this.props.setUploadedFileUrl({});
    this.props.setSelectedStreamUiKeyVal({
      path,
      folder,
    });
    this.props.setSelectedStreamUiKeyVal({fileName: this.state.fileName});
  };

  addFile = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];

      if (!CSV_TYPES_ARR.includes(file.type) && !file.name.toLowerCase().endsWith('.csv')) {
        this.setState({fileErrorText: `File ${file.name} could not be uploaded because it is not a CSV file.`});
        this.setState({isFileErrorOpen: true});
      }

      this.setState({fileName: file.name});
      uploadFile({
        dataSourceId: this.props.dataStream.dataSourceId,
        fileData: file,
        successFunc: this.fileUploadSuccess,
      });
    }
  };

  onFileButtonClick = () => {
    document.getElementById('fileInput').click();
  };

  timeZoneChanged = (obj) => {
    this.setState(obj);
  };

  nameChanged = (val) => {
    this.setState({reportName: val});
  };

  onButtonClick = () => {
    this.props.setSelectedStreamKeyVal({
      reportName: this.state.reportName,
      timeZone: this.state.timeZone,
    });

    const dataObj = {
      dataSourceId: this.props.dataStream.dataSourceId,
      path: `${this.props.dataStream.uiState.path + this.props.dataStream.uiState.folder}/${
        this.props.dataStream.uiState.fileName
      }`,
    };
    this.props.fetchGoogleAuctionsStreamAnalysis(dataObj);
  };

  setSelectedRadio = (changeEvent) => {
    this.props.setSelectedStreamKeyVal({basedOnTemplateId: changeEvent.target.value});
  };

  closeFileErrorModal = () => {
    this.setState({fileErrorText: ''});
    this.setState({isFileErrorOpen: false});
  };

  render() {
    const {reportName, timeZone, isFileErrorOpen, fileErrorText} = this.state;
    const {dataStream, templates, isLoading} = this.props;

    return (
      <div styleName="root">
        <PanelHeader title="Google Auction Report" isEditable="true" />

        <div styleName="radio-wrapper">
          <div styleName="hidden-file">
            <input onChange={this.addFile} type="file" name="file" id="fileInput" placeholder="" />
          </div>

          <div styleName="label">Report name:</div>
          <input
            type="text"
            onChange={(e) => this.nameChanged(e.target.value)}
            placeholder="Report name"
            value={reportName}
            styleName="item-width"
          />

          <div styleName="label">Time zone:</div>
          <TimeZone
            timeZone={timeZone}
            styleName="item-width"
            theme={THEME_LIGHT}
            onChange={(v) => this.timeZoneChanged(v)}
          />

          <div styleName="label">Sample file:</div>
          <Button
            colorSchema={COLORS.BLUE_500}
            text="Select Sample File"
            isDisabled={false}
            automation-id="dataCollectorGoogleAuctionFile"
            onClick={this.onFileButtonClick}
          />
        </div>

        <div>
          <div styleName="file-data">
            {dataStream && dataStream.uiState && dataStream.uiState.fileName && (
              <div>
                File Name:
                {dataStream.uiState.fileName}
              </div>
            )}
          </div>
        </div>

        <div styleName="collect-button">
          <Button
            colorSchema={COLORS.BLUE_500}
            text="Collect"
            isDisabled={!dataStream.uiState || !dataStream.uiState.path || !reportName || !timeZone}
            automation-id="dataCollectorGoogleAuctionAnalyze"
            onClick={this.onButtonClick}
          />
        </div>

        <div>
          <PanelHeader title="Select Use Case:" isEditable="true" />

          {isLoading !== false && <Loader />}

          <div styleName="radio-wrapper">
            <div styleName="radio-block">
              <input
                key="noneRadio"
                id="noneRadio"
                type="radio"
                name="templateRadio"
                value=""
                checked={
                  !dataStream.basedOnTemplateId ||
                  (dataStream.basedOnTemplateId && dataStream.basedOnTemplateId.toString() === '')
                }
                onChange={this.setSelectedRadio}
              />
              <label htmlFor="noneRadio">None</label>
            </div>

            {templates.map((template) => (
              <div styleName="radio-block">
                <input
                  key={template.id}
                  id={template.id}
                  type="radio"
                  name="templateRadio"
                  value={template.id}
                  checked={
                    dataStream.basedOnTemplateId && dataStream.basedOnTemplateId.toString() === template.id.toString()
                  }
                  onChange={this.setSelectedRadio}
                />
                <label htmlFor={template.id}>{template.name}</label>
              </div>
            ))}

            {isFileErrorOpen && (
              <ErrorModal
                onClose={this.closeFileErrorModal}
                title="Your file wasn't uploaded"
                errorMsg={fileErrorText}
                isOpen={isFileErrorOpen}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
