/* eslint-disable */
import React, {Fragment, memo} from 'react';
import {Box} from '@material-ui/core';
import SmartTooltip from 'common/components/SmartTooltip';
import {palette} from 'app/styles/theme';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tooltipBox: {
    borderRadius: '8px',
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
  },
  propertyBox: {
    display: 'inline',
    color: '#009263',
    marginRight: 6,
    marginBottom: '4px',
    fontWeight: 500,
    padding: '0 4px',
    borderRadius: 3,
    backgroundColor: palette.gray[100],
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#e9f0ff',
    },
  },
  clickableLabel: {
    display: 'inline',
    color: '#8995a0',
    '&:hover': {
      color: '#8995a0',
    },
    '&:visited': {
      color: '#8995a0',
    },
  },
  tooltipText: {
    color: palette.gray[500],
    fontWeight: 500,
    fontSize: 14,
  },
  simpleLabel: {},
}));

const styles = {
  tooltipBox: {
    borderRadius: '8px',
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    maxWidth: 250,
  },
  tooltipContent: {
    color: palette.gray['500'],
    fontWeight: '500',
    fontSize: 14,
  },
  value: {
    lineHeight: '16px',
    color: '#009263',
    marginRight: 6,
    padding: 4,
    marginBottom: 2,
    fontSize: 14,
    fontWeight: '500',
    borderRadius: 6,
    cursor: 'default',
    backgroundColor: palette.gray['100'],
    '&:hover': {
      backgroundColor: palette.blue['100'],
    },
  },
  isClickable: {
    textTransform: 'lowercase',
    cursor: 'pointer',
    color: palette.gray['400'],
    '&:hover': {
      color: 'rgb(38, 113, 255) !important',
    },
    '&:visited': {
      color: '#8995a0',
    },
  },
};

const NameFormatShort = (props: PropTypes) => {
  const classes = useStyles();
  const tooltip = (
    <div>
      {props.measure && (
        <Box display="flex" fontSize={14} fontWeight={400} color="#8996a0" mb={0.5}>
          Measure:&nbsp;
          <Box color="#6826ab" fontWeight={500}>
            {props.measure}
          </Box>
        </Box>
      )}

      {props.dimensions.map((d, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box display="flex" fontSize={14} fontWeight={400} color="#8996a0" key={`${d.key}_${index}`}>
          {d.key}
          :&nbsp;
          <Box color="#009263" fontWeight={500}>
            {d.value}
          </Box>
        </Box>
      ))}
    </div>
  );

  return (
    <Box
      css={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
      fontSize={16}
      display="block"
      flexWrap="wrap"
      role="presentation"
    >
      {props.measure && (
        <SmartTooltip
          placement={props.tooltipPlacement}
          content={tooltip}
          extraTtClass={`metric-name-tooltip ${classes.tooltipBox}`}
        >
          <Box display="inline" fontWeight="500" color="purple.600" css={{cursor: 'default'}}>
            {props.measure}
          </Box>
        </SmartTooltip>
      )}
      {props.dimensions.length > 0 && (
        <Box mr={0.5} ml={0.5} display="inline" color="#8998a0" fontWeight="normal" css={{pointerEvents: 'none'}}>
          for
        </Box>
      )}

      {props.dimensions.map((p, i) => (
        <Box display="inline" key={p.key + i}>
          <Fragment>
            <SmartTooltip
              placement={props.tooltipPlacement}
              extraTtClass={`metric-name-tooltip ${classes.tooltipBox}`}
              content={<Box css={styles.tooltipContent}>{p.key}</Box>}
            >
              <Box display="inline" css={styles.value}>
                {p.value}
              </Box>
            </SmartTooltip>
          </Fragment>
        </Box>
      ))}
    </Box>
  );
};

NameFormatShort.defaultProps = {
  tooltipPlacement: 'top',
  dimensions: [],
};

NameFormatShort.propTypes = {
  measure: PropTypes.string.isRequired,
  dimensions: PropTypes.arrayOf(PropTypes.object),
  tooltipPlacement: PropTypes.string,
};

export default memo(NameFormatShort);
