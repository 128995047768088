// @flow
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getMergedTags} from 'alerts.management/store/selectors';
import {getSelectedTags} from 'alerts.console/store/filterSelector';
import {getAlertsTags} from 'alerts.management/store/actions';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';

type PropTypes = {
  setQueryParams: Object,
};

const LabelsFilter = ({setQueryParams}: PropTypes) => {
  const dispatch = useDispatch();
  const labels = useSelector(getMergedTags);
  const selectedLabels = useSelector(getSelectedTags);
  const [sortedLabels, setSortedLabels] = useState([]);

  const sortByName = (a, b) => {
    const nameA = a.value.toUpperCase();
    const nameB = b.value.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    dispatch(getAlertsTags());
  }, []);

  useEffect(() => {
    setSortedLabels(labels.sort((a, b) => sortByName(a, b)));
  }, [labels]);

  const onChange = (items) => {
    const currentValues = items.length ? items.map((item) => item.value).join(',') : undefined;
    setQueryParams({labels: currentValues});
  };

  const onClearAll = () => {
    setQueryParams({labels: undefined});
  };

  return (
    <SelectAndt
      automationId="alertlabelsFilter"
      type={TYPE_NEW_MULTI}
      theme={THEME_HIGHLIGHTED}
      onChange={onChange}
      options={sortedLabels}
      value={selectedLabels}
      isMulti
      placeholder="Labels"
      optionHeight={40}
      menuWidth={280}
      isClearable={false}
      closeMenuOnSelect={false}
      getOptionLabel={(item) => item.label}
      getOptionValue={(item) => item.value}
      menuFooterComponent={
        <FormDdlActionBar
          isVisible
          isClearAllDisabled={!selectedLabels || !selectedLabels.length}
          onClearAll={onClearAll}
        />
      }
    />
  );
};

export default LabelsFilter;
