// @flow
import React, {useState} from 'react';
import useInput from './useInput';

type PropTypes = {
  options: Array,
  onSelect: Function,
  groupName: String,
};

export default function Radio(props: PropTypes) {
  const [options] = useState(props.options);
  const [option, setOption] = useInput('', props.onSelect);

  const {groupName} = props;
  return (
    <form>
      {options.map((i) => (
        <label key={i.value}>
          <input type="radio" name={groupName} value={i.value} checked={option === i.value} onChange={setOption} />
          <span style={{opacity: option === i.value ? '1' : '.7'}}>{i.title}</span>
        </label>
      ))}
    </form>
  );
}
