// @flow
/* eslint-disable */
import React, {Fragment} from 'react';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {segmentClickEvent} from 'common/store/actions';
import {connect} from 'react-redux';

import './TabItem.module.scss';

type PropTypes = {
  label: String,
  onClick: Function,
  isSelected?: boolean,
  tabId?: any,
  titleBlock?: string,
  isVisible?: boolean,
  marginTopTitle?: number,
  segmentClickEvent: Function,
};

@connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)
export default class TabItem extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    isSelected: false,
    tabId: undefined,
    titleBlock: undefined,
    isVisible: true,
    marginTopTitle: undefined,
  };

  tabClicked = () => {
    const {label, tabId, onClick} = this.props;
    this.props.segmentClickEvent({
      type: 'click',
      name: `tab-${label}`,
    });
    onClick(tabId !== undefined ? tabId : label);
  };

  render() {
    const {label, isSelected, titleBlock, isVisible, marginTopTitle} = this.props;
    if (!isVisible) {
      return '';
    }

    return (
      <Fragment>
        {isVisible && (
          <Fragment>
            {titleBlock && (
              <TypographyBox variant="subtitle1" ml={1.5} mt={marginTopTitle && marginTopTitle} mb={1} color="gray.400">
                {titleBlock}
              </TypographyBox>
            )}
            <div styleName={`tab-item ${isSelected ? 'selected' : ''}`} role="presentation" onClick={this.tabClicked}>
              <span styleName="label">{label}</span>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}
/* eslint-enable */
