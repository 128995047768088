/* eslint-disable max-len,no-lonely-if,complexity */
import {createSelector} from 'reselect';
import {selectors as commonSelectors} from 'common';
import * as usersSelectors from 'admin.users/store/selectors';
import * as channelsSelectors from 'alerts.channels/store/selectors';
import * as profileSelectors from 'profile/store/selectors';
import {getDataStreamsItems} from 'bc/store/selectors';
import {
  alertConditions,
  alertConditionTypes,
  getDefaultAlertDefinition,
  getAlertWarningLevel,
  getAlertWarningMessage,
} from 'alerts.management/services/alertsService';
import {allowedFilterKeys as filterKeys} from 'alerts.management/services/alertManagementService';
import {
  findExpressionInTree,
  hasFunctionRoot,
  getEmptyFunction,
  getRatioPairFunction,
  getRootExpressionWithGroupByFunction,
} from 'metrics/services/compositeService';
import {getFormattedHumanDuration} from 'common/utils/dateService';
import {cleanupSpecialChars} from 'metrics/services/metricsService';
import moment from 'moment';
import {isEmpty, get, intersection, cloneDeep, isString, uniqBy} from 'lodash';
import {getPageNameSegment} from 'common/utils/http';
import {
  findFuncInTree,
  getEmptyExpression,
  getSearchObjectOriginExpression,
  getSearchObjectPropertyExpression,
} from 'common/utils/angularServices';
import {Alert} from 'anodot-objects-models';
import {
  getRollupByTimeScaleAndDurationVal,
  DURATION_OPTIONS,
  VOLUME_DURATION_OPTIONS,
} from 'alerts.management/services/durationAndScaleService';
import {THREE_STATES} from 'common/componentsV2/Checkbox';
import {getTimeRangeByRollup} from 'common/utils/dateRangeService';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

export const {getAlertsManagement, getRoutingLocation} = commonSelectors;
export const {getPageVisited} = profileSelectors;

//* data
export const getAlertsManagementData = createSelector(
  getAlertsManagement,
  (alerts) => alerts.data,
);

//* data - executeAlertSimulation
export const getAlertSimulation = createSelector(
  getAlertsManagementData,
  (data) => data.executeAlertSimulation,
);

export const getAlertSimulationUniqueMetrics = createSelector(
  getAlertSimulation,
  (alertSimulation) => uniqBy(alertSimulation.data.metrics, 'id'),
);

//* data - alert
export const getAlert = createSelector(
  getAlertsManagementData,
  (data) => data.fetchAlert,
);

export const getAlertData = createSelector(
  getAlert,
  (fetchAlert) => fetchAlert.data,
);

export const getAlertIsLoading = createSelector(
  getAlert,
  (fetchAlert) => fetchAlert.isLoading,
);

//* data - alert save
export const getUpdateAlertResponse = createSelector(
  getAlertsManagementData,
  (data) => data.updateAlert,
);

//* data - alert save
export const getCreateNewAlertResponse = createSelector(
  getAlertsManagementData,
  (data) => data.createNewAlert,
);

//* data - fetchAlertConfigurations
export const getAlertConfigurations = createSelector(
  getAlertsManagementData,
  (data) => data.fetchAlertConfigurations,
);

//* data - fetchAlertConfigurationsTotal
export const getAlertConfigurationsTotal = createSelector(
  getAlertsManagementData,
  (data) => get(data, 'fetchAlertsConfigurationTotal.data.total', null),
);

export const getIsLoadingAlertConfigurationsTotal = createSelector(
  getAlertsManagementData,
  (data) => get(data, 'fetchAlertsConfigurationTotal.isLoading', false),
);

//* data -fetchTriggeredAlertsByConfiguration
export const getTriggeredAlertsByConfiguration = createSelector(
  getAlertsManagementData,
  (data) => data.fetchTriggeredAlertsByConfiguration,
);

//* data -editAlertBulkApi
export const getEditAlertBulkApi = createSelector(
  getAlertsManagementData,
  (data) => data.editAlertBulkApi,
);

export const getDeleteAlertBulkApi = createSelector(
  getAlertsManagementData,
  (data) => data.deleteAlertBulkApi,
);

export const getDeleteAlertBulkApiIsLoading = createSelector(
  getDeleteAlertBulkApi,
  (api) => api.isLoading,
);

//* data - executeEstimation
export const getExecuteEstimation = createSelector(
  getAlertsManagementData,
  (data) => data.executeEstimation,
);

//* data - executeEstimation
export const getExecuteEstimationData = createSelector(
  getExecuteEstimation,
  (estimation) => estimation.data,
);

//* data - executeEstimation
export const getExecuteEstimationText = createSelector(
  getExecuteEstimationData,
  (estimationData) => estimationData.totalEstimation || null,
);

export const isBulkLoading = createSelector(
  getEditAlertBulkApi,
  (editAlertBulkApi) => editAlertBulkApi.isLoading,
);

//* data -fetchTriggeredAlertsByConfiguration isLoading
export const getTriggeredAlertsByConfigurationIsLoading = createSelector(
  getTriggeredAlertsByConfiguration,
  (data) => data.isLoading || false,
);

//* data -fetchTriggeredAlertsByConfiguration alertGroups
export const getTriggeredAlertsByConfigurationList = createSelector(
  getTriggeredAlertsByConfiguration,
  (data) => data.items.alertGroups || EMPTY_ARRAY,
);

export const getTriggeredAlertsByConfigurationCount = createSelector(
  getTriggeredAlertsByConfiguration,
  (data) => data.items.total || EMPTY_ARRAY,
);

//* views
export const getAlertsManagementViews = createSelector(
  getAlertsManagement,
  (alerts) => alerts.views,
);

//* views - alert edit
export const getAlertEdit = createSelector(
  getAlertsManagementViews,
  (alertsView) => alertsView.alertEdit,
);

//* views - alert manager

export const getAlertManager = createSelector(
  getAlertsManagementViews,
  (alertsView) => alertsView.alertManager,
);

export const getisLoadingBulk = createSelector(
  getAlertsManagementViews,
  (alertsView) => alertsView.isLoadingBulk,
);

export const getSelectedAlertsCheckbox = createSelector(
  getAlertManager,
  (alertManager) => alertManager.selectedAlertsCheckbox || EMPTY_ARRAY,
);

export const getAlertFiltersIsMore = createSelector(
  getAlertManager,
  (alertManager) => alertManager.alertFiltersIsMore,
);

export const getSelectedItemId = createSelector(
  getAlertManager,
  (alertManager) => alertManager.selectedItemId || '',
);

export const getEstimations = createSelector(
  getAlertManager,
  (alertManager) => alertManager.estimations || EMPTY_ARRAY,
);

export const getInfluencingMetrics = createSelector(
  getAlertManager,
  (alertManager) => alertManager.influencingMetrics || EMPTY_ARRAY,
);

export const getInfluencingMetricsIsLoading = createSelector(
  getAlertsManagementData,
  (data) => {
    if (data.fetchInfluencingMetricsInfo) {
      return data.fetchInfluencingMetricsInfo.isLoading;
    }
    return false;
  },
);

export const streamMetricsCountIsLoading = createSelector(
  getAlertsManagementData,
  (data) => {
    if (data.fetchDataStreamMetricsCountApiCall) {
      return data.fetchDataStreamMetricsCountApiCall.isLoading;
    }
    return false;
  },
);

export const getStreamMetricCount = createSelector(
  getAlertManager,
  (alertManager) => alertManager.streamMetricCount || EMPTY_ARRAY,
);

export const getAlertToScrollAndOpen = createSelector(
  getAlertManager,
  (alertManager) => alertManager.alertToScrollAndOpen || '',
);

export const getStreamMetricCountCurrentAlert = createSelector(
  getStreamMetricCount,
  getSelectedItemId,
  (streamMetricCount, id) => {
    const ret = streamMetricCount.find((stream) => stream.id === id);
    if (ret) {
      return ret.size;
    }
    return null;
  },
);

export const getEstimationCurrentOpenAlert = createSelector(
  getEstimations,
  getSelectedItemId,
  (estimations, id) => {
    const alertEstimated = estimations.find((estimation) => estimation.id === id);
    if (alertEstimated) {
      return alertEstimated.text;
    }
    return null;
  },
);

export const getEstimationMinDeltaCurrentOpenAlert = createSelector(
  getEstimations,
  getSelectedItemId,
  (estimations, id) => {
    const alertEstimated = estimations.find((estimation) => estimation.id === id);
    if (alertEstimated) {
      return alertEstimated.minDelta;
    }
    return null;
  },
);

export const getExecuteEstimationIsLoading = createSelector(
  getExecuteEstimation,
  (data) => data.isLoading,
);

export const getDashboardLinks = createSelector(
  getAlertManager,
  (alertManager) => alertManager.dashboardLinks || EMPTY_ARRAY,
);

export const getDashboardList = createSelector(
  getAlertManager,
  (alertManager) => alertManager.dashboardList || EMPTY_ARRAY,
);

export const getFetchDashboard = createSelector(
  getAlertsManagementData,
  (data) => data.fetchDashboard || EMPTY_OBJECT,
);

export const getFetchSimulationData = createSelector(
  getAlertsManagementData,
  (data) => data.fetchSimulationData,
);

export const getDashboardLinkForCurrentOpenAlert = createSelector(
  getDashboardLinks,
  getSelectedItemId,
  (dashboardLinks, alertId) => {
    const itemFound = dashboardLinks.find((dashboardLink) => dashboardLink.id === alertId);
    return itemFound || null;
  },
);

export const getFetchDashboardIsLoading = createSelector(
  getFetchDashboard,
  (data) => data.isLoading,
);

export const getSelectedAlertTab = createSelector(
  getAlertManager,
  (alertManager) => alertManager.selectedAlertTab,
);

export const getAlertForSelectedId = createSelector(
  getSelectedItemId,
  getAlertConfigurations,
  (id, alertConfigurations) => alertConfigurations.items.find((item) => item.data.id === id) || EMPTY_OBJECT,
);

export const getUsersAndChannelsForSelectedId = createSelector(
  getAlertForSelectedId,
  (alert) =>
    alert.data
      ? {
          channels: alert.data.channels,
          users: alert.data.subscribers,
        }
      : {
          channels: EMPTY_ARRAY,
          users: EMPTY_ARRAY,
        },
);

export const getAlertManagementKeyVal = createSelector(
  getAlertManager,
  (alertManager) => alertManager.alertManagementKeyVal || EMPTY_OBJECT,
);

//* alert edit - alert definition
export const getSelectedAlertDefinition = createSelector(
  getAlertEdit,
  (alerts) => alerts.alertDefinition,
);

export const getSelectedAlertExpressionTreeModel = createSelector(
  getSelectedAlertDefinition,
  (ad) => ad.data.expressionTreeModel,
);

//* alert edit - simulation filters
export const getSelectedAlertSimulationFilters = createSelector(
  getAlertEdit,
  (alerts) => alerts.simulationFilters,
);

export const getSimulationFiltersType = createSelector(
  getSelectedAlertSimulationFilters,
  (filters) => filters.type,
);

export const getSimulationFiltersMinDurationUI = createSelector(
  getSelectedAlertSimulationFilters,
  (filters) => filters.minDurationUI,
);

export const getSimulationFiltersRollup = createSelector(
  getSelectedAlertSimulationFilters,
  (filters) => filters.rollup,
);

export const getConditions = createSelector(
  getSelectedAlertSimulationFilters,
  (alertSimulation) => alertSimulation.conditions,
);

const getCondition = (conditions, type) => conditions.find((condition) => condition.type === type);

export const getThresholdCondition = createSelector(
  getConditions,
  (conditions) => getCondition(conditions, alertConditionTypes.staticThreshold),
);

export const getSignificanceCondition = createSelector(
  getConditions,
  (conditions) => getCondition(conditions, alertConditionTypes.significanceCondition),
);

export const getDirectionCondition = createSelector(
  getConditions,
  (conditions) => getCondition(conditions, alertConditionTypes.directionCondition),
);

export const getVolumeConditionFilter = createSelector(
  getConditions,
  (conditions) => getCondition(conditions, alertConditionTypes.volumeCondition),
);

export const getMinMetricsInAnomalyCondition = createSelector(
  getConditions,
  (conditions) => getCondition(conditions, alertConditionTypes.minParticipatingMetrics),
);

export const getMaxMetricsInAnomalyCondition = createSelector(
  getConditions,
  (conditions) => getCondition(conditions, alertConditionTypes.maxParticipatingMetrics),
);

export const getStaticThresholdCondition = createSelector(
  getConditions,
  (conditions) => getCondition(conditions, alertConditionTypes.staticThreshold),
);

export const getDeltaCondition = createSelector(
  getConditions,
  (conditions) => getCondition(conditions, alertConditionTypes.minDelta) || EMPTY_OBJECT,
);

export const getInfluencingMetricsIds = createSelector(
  getConditions,
  (conditions) =>
    conditions.filter((cond) => cond.type === alertConditionTypes.influencingMetrics).map((cond) => cond.id),
);

// Data
export const getVolumeConditions = createSelector(
  getSelectedAlertSimulationFilters,
  (alertSimulation) =>
    alertSimulation.conditions.find((condition) => condition.type === alertConditionTypes.volumeCondition),
);
// Data
export const getVolumeConditionResponse = createSelector(
  getAlertsManagementData,
  (data) => data.getVolumeCondition,
);
// Data
export const getVolumeCondition = createSelector(
  getAlertsManagementData,
  (data) => data.getVolumeCondition.data,
);
// Data
export const getIsLoadingAutoVolumeCondition = createSelector(
  getAlertsManagementData,
  (data) => data.getVolumeCondition.isLoading,
);

export const getValidRollupOptions = createSelector(
  getSelectedAlertSimulationFilters,
  (alertSimulation) => {
    const resolutions = Object.values(VOLUME_DURATION_OPTIONS);

    if (!VOLUME_DURATION_OPTIONS[alertSimulation.rollup]) {
      return resolutions;
    }

    return resolutions.filter((i) => i.ms >= VOLUME_DURATION_OPTIONS[alertSimulation.rollup].ms);
  },
);

export const getSelectedAlertInfluencingMetrics = createSelector(
  getSelectedAlertSimulationFilters,
  (sf) => sf.conditions.filter((c) => c.type === alertConditionTypes.influencingMetrics),
);

export const getSelectedAlertConditions = createSelector(
  getSelectedAlertSimulationFilters,
  (alerts) => alerts.conditions || EMPTY_ARRAY,
);

export const getUpdatePolicy = createSelector(
  getSelectedAlertSimulationFilters,
  (sf) => sf.updatePolicy,
);

export const getAlertType = createSelector(
  getSelectedAlertSimulationFilters,
  (sf) => sf.type.value,
);

export const getSelectedAlertAvailableConditions = createSelector(
  getSelectedAlertConditions,
  profileSelectors.getAutoVolumeEnabled,
  (sf, enabledVolume) => {
    const curConditions = sf.map((a) => a.type);
    const allowedAlertConditions = alertConditions.filter(
      (con) =>
        con.dataObj.type !== alertConditionTypes.volumeCondition ||
        (con.dataObj.type === alertConditionTypes.volumeCondition && enabledVolume),
    );
    return allowedAlertConditions.filter((a) => {
      const typeOccurrences = curConditions.filter((t) => t === a.dataObj.type).length;
      return !typeOccurrences || (a.maxOccurrences && typeOccurrences < a.maxOccurrences);
    });
  },
);

const getAvailableCondition = (availableConditions, type) =>
  availableConditions.find((con) => con.dataObj.type === type);

export const getAvailableInfluencingMetricsCondition = createSelector(
  getSelectedAlertAvailableConditions,
  (availableConditions) => getAvailableCondition(availableConditions, alertConditionTypes.influencingMetrics),
);

export const getAvailableMinMetricsInAnomalyCondition = createSelector(
  getSelectedAlertAvailableConditions,
  (availableConditions) => getAvailableCondition(availableConditions, alertConditionTypes.minParticipatingMetrics),
);

export const getAvailableMaxMetricsInAnomalyCondition = createSelector(
  getSelectedAlertAvailableConditions,
  (availableConditions) => getAvailableCondition(availableConditions, alertConditionTypes.maxParticipatingMetrics),
);

export const getAvailableStaticThresholdCondition = createSelector(
  getSelectedAlertAvailableConditions,
  (availableConditions) => getAvailableCondition(availableConditions, alertConditionTypes.staticThreshold),
);

//* alert edit - simulation filters validation result
export const getSimulationFiltersValidationResults = createSelector(
  getAlertEdit,
  (alerts) => alerts.simulationFiltersValidationResult,
);

//* alert edit - influencing metrics info
export const influencingMetricsInfo = createSelector(
  getAlertEdit,
  (alerts) => alerts.influencingMetricsInfo,
);

//* alert edit - estimations
export const simulationFiltersIsLoadingEstimations = createSelector(
  getAlertEdit,
  (alerts) => alerts.simulationFiltersIsLoadingEstimations.isLoading !== false,
);

//* alert edit - estimations
export const simulationFiltersIsDirty = createSelector(
  getAlertEdit,
  (alerts) => alerts.simulationFiltersIsDirty,
);

//* alert manager - filters
export const getAlertFilters = createSelector(
  getAlertManager,
  (alertManager) => alertManager.filters,
);

//* alert manager - alertConfigurations - isLoading
export const getAlertConfigurationsIsLoading = createSelector(
  getAlertConfigurations,
  (alertConfigurations) => alertConfigurations.isLoading !== false,
);

//*  alert manager - alertConfigurations - items
export const getAlertConfigurationsItems = createSelector(
  getAlertConfigurations,
  (alertConfigurations) => alertConfigurations.items || EMPTY_ARRAY,
);

//* alert manager - alertConfigurations - updatedAt
export const getAlertConfigurationsUpdatedAt = createSelector(
  getAlertConfigurations,
  (alertConfigurations) => alertConfigurations.updateAt,
);

//* alert manager - alertConfigurations - items count
export const getAlertConfigurationsCount = createSelector(
  getAlertConfigurationsItems,
  (items) => items.length,
);

const setConditions = (conditions) => {
  const ret = {};
  conditions.forEach((condition) => {
    switch (condition.type) {
      case 'DURATION_CONDITION':
        ret.duration = condition.minDuration;
        break;
      case 'DIRECTION_CONDITION':
        if (condition.direction === 'both') {
          ret.direction = 'Spike & Drop';
        } else if (condition.direction === 'up') {
          ret.direction = 'Spike';
        } else if (condition.direction === 'down') {
          ret.direction = 'Drop';
        } else {
          ret.direction = condition.direction;
        }
        break;
      case 'SIGNIFICANCE_CONDITION':
        ret.significance = condition.minSignificance;
        break;
      case 'DELTA_CONDITION':
        ret.delta = condition;
        break;
      default:
    }
  });
  return ret;
};

const getTimeScaleUi = (rollup) => {
  const resolution = {
    auto: {value: '', text: 'Auto'},
    short: {value: 'short', text: '1 Minute'},
    medium: {value: 'medium', text: '5 Minutes'},
    long: {value: 'long', text: '1 Hour'},
    longlong: {value: 'longlong', text: '1 Day'},
    weekly: {value: 'weekly', text: '1 Week'},
  };
  return resolution[rollup].text;
};

const getMinSignificanceScore = (conditions) => {
  const significance = conditions.find((condition) => condition.type === 'SIGNIFICANCE_CONDITION');
  if (significance) {
    return parseInt(significance.minSignificance * 100, 10);
  }
  return 'N/A';
};

const getMinDelta = (conditions) => {
  const delta = conditions.find((condition) => condition.type === 'DELTA_CONDITION');
  if (delta) {
    return delta.deltaPercentage ? String(delta.deltaPercentage).concat('%') : '0%';
  }
  return 'N/A';
};

const getAnomalyDirection = (conditions) => {
  const directionCondition = conditions.find((condition) => condition.type === 'DIRECTION_CONDITION');
  if (directionCondition) {
    return directionCondition.direction;
  }
  return null;
};

export const getAlertMeasure = (treeModel) => {
  if (!treeModel.name || !treeModel.name.prefix) {
    if (!hasFunctionRoot(treeModel)) {
      const whatExp = treeModel.expressionTree.root.searchObject.expression.find((exp) => exp.key === 'what');
      return whatExp ? whatExp.value : null;
    }
    return null;
  }

  let measure = null;
  const keyValPairs = treeModel.name.prefix.split('.');
  keyValPairs.forEach((pair) => {
    const keyVal = pair.split('=');
    if (keyVal[0] === 'what') {
      // eslint-disable-next-line prefer-destructuring
      measure = keyVal[1];
    }
  });
  return measure;
};

export const getAlertDimensions = (treeModel) => {
  const groupByFunc = findFuncInTree(treeModel.expressionTree.root, 'groupBy');
  if (groupByFunc) {
    const groupByParam = groupByFunc.parameters.find((param) => param.name === 'Group By');
    if (groupByParam) {
      return JSON.parse(groupByParam.value).properties.join(', ');
    }
  }
  return null;
};

export const getAlertDuration = (conditions) => {
  const durationCondition = conditions.find((condition) => condition.type === 'DURATION_CONDITION');
  if (durationCondition) {
    return getFormattedHumanDuration(durationCondition.minDuration);
  }
  return null;
};

export const getDeltaDuration = (conditions) => {
  const deltaCondition = conditions.find((condition) => condition.type === 'DELTA_CONDITION');
  if (deltaCondition && deltaCondition.deltaDuration) {
    return getFormattedHumanDuration(deltaCondition.deltaDuration.minDuration);
  }
  return null;
};

export const getVolumeConditionForAlert = (conditions) =>
  conditions.find((condition) => condition.type === 'VOLUME_CONDITION');

export const getNumOfMetricsMin = (conditions) => {
  const minCond = conditions.find((condition) => condition.type === 'MIN_PARTICIPATING_METRICS_CONDITION');

  let ret = '';
  if (minCond) {
    if (minCond.minNumOfMetrics && !minCond.minPercentageOfMetrics) {
      ret = `Min. ${minCond.minNumOfMetrics}`;
    } else if (!minCond.minNumOfMetrics && minCond.minPercentageOfMetrics) {
      ret = `Min. ${minCond.minPercentageOfMetrics}%`;
    } else if (minCond.minNumOfMetrics && minCond.minPercentageOfMetrics) {
      ret = `Min. ${minCond.minNumOfMetrics} | ${minCond.minPercentageOfMetrics}%`;
    }
  }
  if (!minCond) {
    ret = 'None';
  }
  return ret;
};

export const getNumOfMetricsMax = (conditions) => {
  const maxCond = conditions.find((condition) => condition.type === 'MAX_PARTICIPATING_METRICS_CONDITION');

  let ret = '';
  if (maxCond) {
    if (maxCond.maxNumOfMetrics && !maxCond.maxPercentageOfMetrics) {
      ret = `${ret} Max. ${maxCond.maxNumOfMetrics}`;
    } else if (!maxCond.maxNumOfMetrics && maxCond.maxPercentageOfMetrics) {
      ret = `${ret} Max. ${maxCond.maxPercentageOfMetrics}%`;
    } else if (maxCond.maxNumOfMetrics && maxCond.maxPercentageOfMetrics) {
      ret = `${ret} Max. ${maxCond.maxNumOfMetrics} | ${maxCond.maxPercentageOfMetrics}%`;
    }
  }
  if (!maxCond) {
    ret = 'None';
  }
  return ret;
};

export const getMinThreshold = (conditions) => {
  const thresholdCond = conditions.find((condition) => condition.type === 'THRESHOLD_CONDITION');
  if (!thresholdCond) {
    return 'None';
  }
  return thresholdCond.minValue;
};

export const getSummaryAnomalyDirection = (conditions) => {
  const anomalyDirection = getAnomalyDirection(conditions);
  if (anomalyDirection === 'both') {
    return 'Spike/Drop';
  }
  if (anomalyDirection === 'up') {
    return 'Spike';
  }
  if (anomalyDirection === 'down') {
    return 'Drop';
  }
  return null;
};

export const getAlertThreshold = (conditions) => {
  const thresholdCondition = conditions.find((condition) => condition.type === 'THRESHOLD_CONDITION');
  return thresholdCondition || null;
};

const getInfluencingTreeModels = (conditions) => {
  const influencingConditions = conditions.filter((condition) => condition.type === 'INFLUENCING_METRICS_CONDITION');

  if (!influencingConditions.length) {
    return null;
  }

  const expressionTreeModels = [];
  influencingConditions.forEach((condition) => {
    expressionTreeModels.push(condition.expressionTreeModel);
  });
  return expressionTreeModels;
};

export const getAlertListOrder = createSelector(
  getAlertManager,
  (alertManager) => alertManager.alertListOrder,
);

export const getAlertEditorV2Enabled = createSelector(
  profileSelectors.getOrganization,
  (o) => get(o, 'serverConfiguration.featureFlags.featuresActivation.ALERTS_ENABLE_ALERTS_EDITOR_DISPLAY_V2', false),
);

//* alert manager alerts - items transformed
export const getAlertManagerHeadersList = createSelector(
  getAlertConfigurationsItems,
  usersSelectors.getUsersData,
  usersSelectors.getGroupsDataList,
  profileSelectors.getUserProfile,
  profileSelectors.isReadOnlyUser,
  (items, users, groups, me, isReadOnlyUser) => {
    if (!items.length || !users.length) {
      return EMPTY_ARRAY;
    }
    const prossesedAlerts = items.map((item) => {
      const foundUser = users.find((user) => user._id === item.ownerId || user.email === item.owner);
      const foundGroup = groups.find((group) => group.id === item.ownerId);
      const foundModifiedBy = users.find((user) => user._id === item.modifierId || user.email === item.modifiedBy);
      const pauseTime = +moment().unix() - +item.data.pauseTime;
      const isPaused = item.data.paused || pauseTime < 0;
      const ownerId =
        item.data.ownerId || item.ownerId || (foundUser && foundUser._id) || (foundGroup && foundGroup._id);
      let owner = foundUser ? `${foundUser.firstName} ${foundUser.lastName}` : 'Unknown';
      owner = foundGroup ? foundGroup.name : owner;
      // const {dynamicRouting} = item.data;

      const checkIsMeOwner =
        me.email === item.owner ||
        me._id === ownerId ||
        (me.groups && foundGroup && me.groups.indexOf(foundGroup.id) !== -1);

      const checkIsMeSubscribed = () => {
        const isMeSubscriber = (item.data.subscribers || []).indexOf(me._id) !== -1;

        return isMeSubscriber;
      };

      const checkIsMeCustomerAdmin = !!me.roles.find((role) => role === 'customer-admin');

      const checkIsMeCustomer = !!me.roles.find((role) => role.indexOf('customer') > -1);

      const sendAlertWhen = () => {
        const noData = item.data.noDataAlert;
        const isStatic = item.data.staticAlert;
        const onChange = item.data.updatePolicy ? item.data.updatePolicy.triggerOnMetricsChange : false;
        const onOpenOnly = item.data.notifyOnlyOpen;
        let ret = '';
        if (noData) {
          ret = 'No Data Starts';
        }
        if (isStatic) {
          ret = 'Breach Starts';
        }
        ret = `${ret}${onChange ? ' / Updated' : ''}${onOpenOnly ? '' : ' / Ends'}`;
        if (!noData && !isStatic) {
          ret = `Open${onChange ? ' / Update' : ''}${onOpenOnly ? '' : ' / Close'}`;
        }
        return ret;
      };

      let streamName = null;
      const expressionNode = findExpressionInTree(item.data.expressionTreeModel.expressionTree.root);
      expressionNode.searchObject.expression.forEach((exp) => {
        if (exp.type === 'origin' && exp.originType === 'STREAM') {
          streamName = exp.value;
        }
      });

      const alertTypeArr = [];
      if (item.data.anomalyAlert) {
        alertTypeArr.push('Anomaly');
      }
      if (item.data.staticAlert) {
        alertTypeArr.push('Static');
      }
      if (item.data.noDataAlert) {
        alertTypeArr.push('No Data');
      }
      // TODO GABPAC - check at master with different users the actual authorization
      return {
        id: item.data.id,
        title: item.data.title,
        description: item.data.story,
        owner,
        modifiedBy: foundModifiedBy ? `${foundModifiedBy.firstName} ${foundModifiedBy.lastName}` : 'Unknown',
        dateModified: moment.unix(item.data.lastModifiedTime).format('ll'),
        dateModifiedValue: item.data.lastModifiedTime,
        dateCreated: moment.unix(item.data.creationTime).format('ll'),
        status: isPaused ? 'Paused' : 'Active',
        estimation: item.data.triggerCount ? item.data.triggerCount : '0',
        warnings: getAlertWarningLevel(item.data.validation),
        isMeSubscribed: checkIsMeSubscribed(),
        alertConfiguration: {
          status: isPaused ? 'paused' : 'active',
          severity: item.data.severity,
          isAnomalyAlert: item.data.anomalyAlert,
          isStaticAlert: item.data.staticAlert,
          isNoDataAlert: item.data.noDataAlert,
          ownerEmail: item.owner,
          ownerId,
          subscriberIds: item.data.subscribers,
          channelIds: item.data.channels,
          timeScale: item.data.rollups,
          anomalyDirection: getAnomalyDirection(item.data.conditions),
          timeScaleUI: getTimeScaleUi(item.data.rollups),
          conditions: setConditions(item.data.conditions),
          warningMessage: getAlertWarningMessage(item.data.validation),
          alertOnMissingData: item.data.noDataAlert,
          isInfluencingEvents: item.data.isInfluencingEvents,
          streamName,
          // dynamicRouting: setDynamicRouting(item.data.dynamicRouting),
          // dynamicRouting: item.data.dynamicRouting,
        },
        details: {
          type: alertTypeArr.join(', '),
          includedMetrics: 'N/A',
          sendAlertWhen: sendAlertWhen(),
          alertOnMissingData: item.data.noDataAlert ? 'Yes' : 'No',
          minSignificanceScore: getMinSignificanceScore(item.data.conditions),
          minDelta: getMinDelta(item.data.conditions),
          description: item.data.story,
          expressionTreeModel: item.data.expressionTreeModel,
          influencingTreeModels: getInfluencingTreeModels(item.data.conditions),
          isCorrelateWithEvents: item.data.eventsFilter ? 'Yes' : 'No',
          enableCompositeDelayAutoCalc:
            me.organization.serverConfiguration.featureFlags.featuresActivation.COMPOSITE_ENABLE_DELAY_AUTO_CALC,
          paused: {
            pausedBy: item.data.pausedBy,
            pausedId: item.data.pausedId,
            pausedTime: item.data.pauseTime,
            paused: item.data.paused,
          },
          alertSummaryConfiguration: {
            measure: getAlertMeasure(item.data.expressionTreeModel),
            dimensions: getAlertDimensions(item.data.expressionTreeModel),
            duration: getAlertDuration(item.data.conditions),
            noDataDuration: getFormattedHumanDuration(item.data.noDataDuration),
            threshold: getAlertThreshold(item.data.conditions),
            anomalyDirection: getSummaryAnomalyDirection(item.data.conditions),
            deltaDuration: getDeltaDuration(item.data.conditions),
            volumeCondition: getVolumeConditionForAlert(item.data.conditions),
            numOfMetricsMin: getNumOfMetricsMin(item.data.conditions),
            numOfMetricsMax: getNumOfMetricsMax(item.data.conditions),
            minThreshold: getMinThreshold(item.data.conditions),
          },
          tags: item.data.alertTags ? item.data.alertTags.map((tag) => tag.name) : [],
          connectedTiles: item.data.connectedTiles || item.connectedTiles,
        },
        authorization: {
          delete: checkIsMeOwner || checkIsMeCustomerAdmin,
          pause_resume: checkIsMeCustomer,
          subscribe_unsubscribe: !isReadOnlyUser && checkIsMeCustomer,
        },
      };
    });
    return prossesedAlerts;
  },
);

export const getSelectedManagerHeaderAlert = createSelector(
  getAlertManagerHeadersList,
  getSelectedItemId,
  (items, id) => items.find((item) => item.id === id) || EMPTY_OBJECT,
);

export const getSelectedManagerAlertInfluencingIdArray = createSelector(
  getSelectedManagerHeaderAlert,
  (alert) => {
    if (!alert || isEmpty(alert) || !alert.details.influencingTreeModels) {
      return EMPTY_ARRAY;
    }

    const influencingConditionsIdArray = [];
    alert.details.influencingTreeModels.forEach((model) => {
      influencingConditionsIdArray.push(model.id);
    });

    return influencingConditionsIdArray;
  },
);

export const getInfluencingMetricsWhatCurrentAlert = createSelector(
  getInfluencingMetrics,
  getSelectedManagerAlertInfluencingIdArray,
  (infMetrics, infIdArray) => {
    if (!infIdArray.length) {
      return 'NA';
    }

    const ret = infMetrics.filter((infMetric) => infIdArray.indexOf(infMetric.id) !== -1);
    const whatArr = ret.map((item) => item.what);
    return whatArr.join(', ');
  },
);

//* ** alert manager alerts - items transformed - filtered
export const getFilteredAlertManagerHeadersList = createSelector(
  getAlertManagerHeadersList,
  getAlertFilters,
  profileSelectors.getUserProfile,
  (items, filters) =>
    items.filter((item) => {
      if (filters.searchQuery) {
        if (
          item.title.toLowerCase().indexOf(filters.searchQuery.toLowerCase()) === -1 &&
          item.description.toLowerCase().indexOf(filters.searchQuery.toLowerCase()) === -1
        ) {
          return false;
        }
      }

      if (filters.tag) {
        const selectedTags = filters.tag.split(',');
        if (
          filters.tag !== 'null' &&
          item.details.tags.findIndex((alertTag) => selectedTags.indexOf(alertTag) !== -1) === -1
        ) {
          return false;
        }
      }

      if (filters.type) {
        if (
          !(
            (filters.type.indexOf(filterKeys.type.anomaly.value) !== -1 && item.alertConfiguration.isAnomalyAlert) ||
            (filters.type.indexOf(filterKeys.type.static.value) !== -1 && item.alertConfiguration.isStaticAlert) ||
            (filters.type.indexOf(filterKeys.type.noData.value) !== -1 && item.alertConfiguration.isNoDataAlert)
          )
        ) {
          return false;
        }
      }

      if (filters.severity && filters.severity.indexOf(item.alertConfiguration.severity) === -1) {
        return false;
      }

      if (filters.timeScale && filters.timeScale.split(',').indexOf(item.alertConfiguration.timeScale) === -1) {
        return false;
      }

      if (filters.whose !== filterKeys.whose.all.value) {
        // 'whose' values are not used currently
        // const isMeOwner = me.email === item.alertConfiguration.ownerEmail || me._id === item.alertConfiguration.ownerId;
        // const isMeSubscriber = (item.alertConfiguration.subscriberIds || []).indexOf(me._id) !== -1;
        // if (
        //   (filters.whose === filterKeys.whose.my.value && !isMeOwner) ||
        //   (filters.whose === filterKeys.whose.sub.value && !isMeSubscriber)
        // ) {
        //   return false;
        // }
      }

      if (filters.status !== filterKeys.status.all.value) {
        if (
          (item.alertConfiguration.status === filterKeys.status.paused.value &&
            filters.status !== filterKeys.status.paused.value) ||
          (item.alertConfiguration.status === filterKeys.status.active.value &&
            filters.status !== filterKeys.status.active.value)
        ) {
          return false;
        }
      }

      if (filters.notifications === true && item.warnings === 'alert-0-no-warning') {
        return false;
      }

      if (filters.owner && filters.owner.split(',').indexOf(item.alertConfiguration.ownerId) === -1) {
        return false;
      }

      if (filters.stream && filters.stream.split(',').indexOf(item.alertConfiguration.streamName) === -1) {
        return false;
      }

      if (filters.subscribers && filters.channels) {
        const intersecUsers = intersection(filters.subscribers.split(','), item.alertConfiguration.subscriberIds);
        const intersecChannels = intersection(filters.channels.split(','), item.alertConfiguration.channelIds);
        if (!intersecUsers.length && !intersecChannels.length) {
          return false;
        }
      } else if (filters.subscribers) {
        if (!intersection(filters.subscribers.split(','), item.alertConfiguration.subscriberIds).length) {
          return false;
        }
      } else if (filters.channels) {
        if (!intersection(filters.channels.split(','), item.alertConfiguration.channelIds).length) {
          return false;
        }
      }
      if (
        filters.anomalyDirection &&
        filters.anomalyDirection.indexOf(item.alertConfiguration.anomalyDirection) === -1
      ) {
        return false;
      }

      return true;
    }),
);

export const getFilteredAlertManagerHeadersListCount = createSelector(
  getFilteredAlertManagerHeadersList,
  (filteredAlerts) => filteredAlerts.length,
);

export const getSortedAlertManagerHeadersList = createSelector(
  getFilteredAlertManagerHeadersList,
  getAlertListOrder,
  (filteredAlerts, order) => {
    const sortingFunction = (arr, sortBy, direction) => {
      if (sortBy === 'dateModified') {
        return arr.sort((a, b) => {
          const aa = a.dateModifiedValue;
          const bb = b.dateModifiedValue;
          if (aa < bb) {
            return -1 * direction;
          }
          if (aa > bb) {
            return direction;
          }
          return 0;
        });
      }
      return arr.sort((a, b) => {
        const itemA = isString(a[sortBy]) ? a[sortBy].toLowerCase() : a[sortBy];
        const itemB = isString(b[sortBy]) ? b[sortBy].toLowerCase() : b[sortBy];
        if (itemA < itemB) {
          return -1 * direction;
        }
        if (itemA > itemB) {
          return direction;
        }
        return 0;
      });
    };

    return [...sortingFunction(filteredAlerts, order.column, order.direction === 'desc' ? -1 : 1)];
  },
);

export const getAllAlertCheckbox = createSelector(
  getSelectedAlertsCheckbox,
  getSortedAlertManagerHeadersList,
  (selectedAlerts, shownAlerts) => shownAlerts.length === selectedAlerts.length,
);

export const getAllAlertsCheckboxHalfChecked = createSelector(
  getSelectedAlertsCheckbox,
  getSortedAlertManagerHeadersList,
  (selectedAlerts, shownAlerts) => selectedAlerts.length > 0 && selectedAlerts.length < shownAlerts.length,
);

export const getAllAlertsCheckboxState = createSelector(
  getSelectedAlertsCheckbox,
  getSortedAlertManagerHeadersList,
  (selectedAlerts, shownAlerts) => {
    if (selectedAlerts.length === shownAlerts.length) {
      return THREE_STATES.FULL_CHECKED;
    }
    if (selectedAlerts.length > 0 && selectedAlerts.length < shownAlerts.length) {
      return THREE_STATES.HALF_CHECKED;
    }
    return THREE_STATES.UNCHECKED;
  },
);

export const getRecipients = createSelector(
  getUsersAndChannelsForSelectedId,
  usersSelectors.getUsersData,
  channelsSelectors.getChannelsData,
  (alert, users, channels) => {
    if (!alert || !users || !channels) {
      return EMPTY_ARRAY;
    }
    const ret = [];
    const alertUsers = alert.users;
    const alertChannels = alert.channels;
    if (alertUsers) {
      alertUsers.forEach((alertUser) => {
        const subscribedUser = users.find((user) => user._id === alertUser);
        if (subscribedUser) {
          ret.push({name: `${subscribedUser.firstName} ${subscribedUser.lastName}`, type: 'USER', id: alertUser});
        }
      });
    }

    if (alertChannels && alertChannels.length > 0) {
      alertChannels.forEach((alertChannel) => {
        const subscribedChannel = channels.find((channel) => channel.id === alertChannel);
        if (subscribedChannel && subscribedChannel.state !== 'INACTIVE') {
          ret.push({name: subscribedChannel.name, type: subscribedChannel.channelMeta.type, id: alertChannel});
        }
      });
    }
    return ret;
  },
);

export const getUsersAndChannels = createSelector(
  usersSelectors.getUsersData,
  channelsSelectors.getChannelsData,
  (users, channels) => ({users, channels}),
);

export const getDeleteAlertId = createSelector(
  getAlertManager,
  (alertManager) => alertManager.deleteAlertId || '',
);

export const getDuplicateAlertId = createSelector(
  getAlertManager,
  (alertManager) => alertManager.duplicateAlertId || '',
);

export const getDuplicateAlertData = createSelector(
  getDuplicateAlertId,
  getAlertConfigurationsItems,
  (dupAlertId, rawAlertItems) => rawAlertItems.find((alert) => get(alert, 'data.id', null) === dupAlertId) || null,
);

export const getIsFirstTimeVisit = createSelector(
  getPageVisited,
  getRoutingLocation,
  (pageVisited, getRoutingLocationFirstTimeVisit) => {
    const isThisPageVisited = pageVisited.find(
      (i) => getPageNameSegment(getRoutingLocationFirstTimeVisit.pathname) === i.name && i.firstTimeSig,
    );
    const date = new Date();
    const now = date.getTime();
    if (isThisPageVisited && isThisPageVisited.firstTimeSig) {
      const passingTime = isThisPageVisited ? now - isThisPageVisited.firstTimeSig : 0;
      return passingTime < 30 * 1000;
    }
    return true;
  },
);

export const getTagsListNew = createSelector(
  getAlertsManagementData,
  (data) => data.fetchTags.data,
);

export const getMergedTags = createSelector(
  getTagsListNew,
  (tags) =>
    Object.keys(tags).map((tagName) => ({
      label: tagName,
      value: tagName.toLowerCase(),
    })),
);

export const getTagsList = createSelector(
  getAlertConfigurationsItems,
  (items) => {
    const tags = [];
    items.forEach((item) => {
      if (item.data.alertTags) {
        item.data.alertTags.forEach((alertTag) => {
          const t = tags.find((tag) => tag.name === alertTag.name);
          if (t) {
            t.count += 1;
          } else {
            tags.push({name: alertTag.name, count: 1});
          }
        });
      }
    });
    tags.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    return tags;
  },
);

export const getAlertsSelectedCheckbox = createSelector(
  getSelectedAlertsCheckbox,
  getAlertConfigurationsItems,
  (checkedIds, items) => {
    const ret = [];
    checkedIds.forEach((checkedId) => {
      ret.push(items.find((item) => item.data.id === checkedId));
    });
    return ret;
  },
);

export const getTagsListWithSelectedCheckboxes = createSelector(
  getAlertsSelectedCheckbox,
  getTagsList,
  (alerts, tags) => {
    const ret = [];
    tags.forEach((tag) => {
      const obj = {...tag, ids: []};
      alerts.forEach((alert) => {
        if (alert.data.alertTags && alert.data.alertTags.findIndex((alertTag) => alertTag.name === tag.name) > -1) {
          obj.ids.push(alert.data.id);
        }
      });
      ret.push(obj);
    });
    return ret;
  },
);

export const getRecipientsWithSelectedCheckboxes = createSelector(
  getAlertsSelectedCheckbox,
  channelsSelectors.getSortedFlattenChannels,
  (alerts, recipients) => {
    const ret = [];
    recipients.forEach((recipient) => {
      const obj = {...recipient, ids: []};
      alerts.forEach((alert) => {
        if (
          (alert.data.subscribers &&
            alert.data.subscribers.findIndex((subscriber) => subscriber === recipient.value) > -1) ||
          (alert.data.channels && alert.data.channels.findIndex((channel) => channel === recipient.value) > -1)
        ) {
          obj.ids.push(alert.data.id);
        }
      });
      ret.push(obj);
    });
    return ret;
  },
);

export const getFullyFunctionalDataStreams = createSelector(
  getDataStreamsItems,
  (streams) =>
    streams.filter(
      (stream) => stream.state === 'running' && stream.status === 'ok' && !stream.paused && !!stream.schema,
    ),
);

export const getStreamSchemas = createSelector(
  getFullyFunctionalDataStreams,
  (streams) => {
    const allMeasurementsList = [];
    const multiLevelMeasurementsList = [];
    const aggregation = {
      counter: 'sum',
      gauge: 'average',
    };
    (streams || []).forEach((item) => {
      const schemaId = item.id;
      const schemaName = item.name;
      const schemaMeasurements = item.schema.columns.filter((col) => col.type === 'metric' && !col.hidden);
      const schemaDimensions = item.schema.columns
        .filter((col) => col.type === 'dimension' && !col.hidden)
        .map((dim) => dim.name);

      schemaMeasurements.forEach((schemaMeasure) => {
        const cleanMeasureName = cleanupSpecialChars(schemaMeasure.name);
        allMeasurementsList.push({
          value: cleanMeasureName,
          label: cleanMeasureName,
          streamName: schemaName,
          streamId: schemaId,
          dimensions: schemaDimensions,
          measure: cleanMeasureName,
          aggregation: aggregation[schemaMeasure.targetType],
        });
      });
    });

    allMeasurementsList.forEach((measure) => {
      const index = multiLevelMeasurementsList.findIndex((item) => item.value === measure.value);

      if (index === -1) {
        multiLevelMeasurementsList.push(measure);
      } else if (multiLevelMeasurementsList[index].multi) {
        multiLevelMeasurementsList[index].multi.push({...measure, value: measure.streamId, label: measure.streamName});
      } else {
        multiLevelMeasurementsList.push({
          value: multiLevelMeasurementsList[index].value,
          label: multiLevelMeasurementsList[index].label,
          multi: [
            {
              ...multiLevelMeasurementsList[index],
              value: multiLevelMeasurementsList[index].streamId,
              label: multiLevelMeasurementsList[index].streamName,
            },
            {...measure, value: measure.streamId, label: measure.streamName},
          ],
        });
        multiLevelMeasurementsList.splice(index, 1);
      }
    });
    multiLevelMeasurementsList.forEach((multilevelMeasurement) => {
      if (multilevelMeasurement.multi) {
        multilevelMeasurement.multi.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      }
    });
    multiLevelMeasurementsList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    return multiLevelMeasurementsList;
  },
);

export const getSimpleAlertFirstMeasure = createSelector(
  getAlertsManagementViews,
  (data) => data.simpleAlert.firstMeasure || EMPTY_OBJECT,
);

export const getSimpleAlertSelectedDimensions = createSelector(
  getAlertsManagementViews,
  (data) => data.simpleAlert.selectedDimension || EMPTY_ARRAY,
);

export const getSimpleAlertSelectedDirection = createSelector(
  getAlertsManagementViews,
  (data) => data.simpleAlert.selectedDirection || EMPTY_ARRAY,
);

export const getSimpleAlertSelectedTimeScale = createSelector(
  getAlertsManagementViews,
  (data) => data.simpleAlert.selectedTimeScale,
);

export const getSimpleAlertTimeScaleOptions = createSelector(
  profileSelectors.getOrganizationRollups,
  (orgRollups) => {
    if (!orgRollups.length) {
      return EMPTY_ARRAY;
    }
    if (orgRollups.findIndex((rollup) => rollup === 'WEEKLY') === -1) {
      return Object.keys(DURATION_OPTIONS).filter((item) => item !== 'weeks');
    }
    return Object.keys(DURATION_OPTIONS);
  },
);

export const getSimpleAlertMinDurationOptions = createSelector(
  profileSelectors.getOrganizationRollups,
  getSimpleAlertSelectedTimeScale,
  (orgRollups, timeScale) => {
    if (!orgRollups.length || !timeScale) {
      return EMPTY_ARRAY;
    }
    if (timeScale === 'minutes' && orgRollups.findIndex((rollup) => rollup === 'SHORTROLLUP') === -1) {
      return DURATION_OPTIONS[timeScale].filter((item) => item.rollup === 'medium');
    }
    return DURATION_OPTIONS[timeScale];
  },
);

export const getSimpleAlertSelectedDurationValue = createSelector(
  getAlertsManagementViews,
  (data) => data.simpleAlert.selectedDurationValue || EMPTY_OBJECT,
);

export const getSimpleAlertRecipients = createSelector(
  getAlertsManagementViews,
  (data) => data.simpleAlert.selectedRecipients || EMPTY_ARRAY,
);

export const getSimpleAlertSecondMeasure = createSelector(
  getAlertsManagementViews,
  (data) => data.simpleAlert.secondMeasure || EMPTY_OBJECT,
);

export const getAvailableDimensions = createSelector(
  getSimpleAlertFirstMeasure,
  getSimpleAlertSecondMeasure,
  (firstMeasure, secondMeasure) => {
    if (Object.keys(secondMeasure).length === 0) {
      return firstMeasure.dimensions;
    }
    const ret = [];
    firstMeasure.dimensions.forEach((dim1) => {
      secondMeasure.dimensions.forEach((dim2) => {
        if (dim1 === dim2) {
          ret.push(dim1);
        }
      });
    });
    return ret;
  },
);

const checkIfMeasuresHaveCommonDimension = (measure1, measure2) => {
  let ret = false;
  measure1.dimensions.forEach((dim1) => {
    measure2.dimensions.forEach((dim2) => {
      if (dim1 === dim2) {
        ret = true;
      }
    });
  });
  return ret;
};

export const getSimpleAlertSecondMeasureOptions = createSelector(
  getSimpleAlertFirstMeasure,
  getStreamSchemas,
  (firstMeasure, schemas) => {
    if (Object.keys(firstMeasure).length === 0) {
      return EMPTY_ARRAY;
    }
    const retArr = [];
    schemas.forEach((schema) => {
      if (schema !== firstMeasure) {
        if (!schema.multi) {
          if (checkIfMeasuresHaveCommonDimension(schema, firstMeasure)) {
            retArr.push(cloneDeep(schema));
          }
        } else {
          const multiArr = [];
          schema.multi.forEach((subSchema) => {
            if (subSchema !== firstMeasure) {
              if (checkIfMeasuresHaveCommonDimension(subSchema, firstMeasure)) {
                multiArr.push(cloneDeep(subSchema));
              }
            }
          });
          if (multiArr.length > 0) {
            retArr.push({
              ...schema,
              multi: multiArr,
            });
          }
        }
      }
    });
    return retArr;
  },
);

export const getSimpleAlertIsRatio = createSelector(
  getAlertsManagementViews,
  (data) => data.simpleAlert.isRatio,
);

export const generateAlertTitle = (m1, m2, dims, directions, alertType) => {
  let title = '';

  const typeTitles = {
    static: 'Static',
    noData: 'No Data',
  };

  if (['static', 'noData'].includes(alertType)) {
    title = `${typeTitles[alertType]} on ${m1.measure}`;
  } else if (directions.length === 2) {
    title = `Anomaly on ${m1.measure}`;
  } else {
    if (directions[0].value === 'up') {
      title = `Spike in ${m1.measure}`;
    } else if (directions[0].value === 'down') {
      title = `Drop in ${m1.measure}`;
    }
  }

  if (m2.measure) {
    title += `/${m2.measure}`;
  }

  if (dims.length) {
    title += m2.measure ? ' per ' : ' by ';
    dims.forEach((dimName, index) => {
      title += `{{${dimName}}}`;
      title = index < dims.length - 1 ? `${title}, ` : title;
    });
  }

  return title;
};

export const getSimpleAlertObject = createSelector(
  profileSelectors.getStreamRenameEnabled,
  profileSelectors.getUserProfile,
  usersSelectors.getUsersData,
  getSimpleAlertFirstMeasure,
  getSimpleAlertSecondMeasure,
  getSimpleAlertSelectedDimensions,
  getSimpleAlertSelectedDirection,
  getSimpleAlertSelectedTimeScale,
  getSimpleAlertSelectedDurationValue,
  getSimpleAlertIsRatio,
  getSimpleAlertRecipients,
  (
    isStreamRenameEnabled,
    me,
    users,
    measure1,
    measure2,
    selectedDims,
    selectedDirection,
    selectedTimeScale,
    selectedDurationValue,
    isRatio,
    recipients,
  ) => {
    if (isEmpty(me) || isEmpty(measure1) || (isRatio && isEmpty(measure2)) || !recipients || !recipients.length) {
      return null;
    }

    let root;
    let childRoot1;
    let compositeName;
    const aggregationValues = {
      sum: 'Sum',
      average: 'Avg',
    };
    const seriesFunction = {
      sum: 'sumSeries',
      average: 'averageSeries',
    };

    let groupByPropsValue = '{"properties":[';
    selectedDims.forEach((dimension, index) => {
      const comma = index === selectedDims.length - 1 ? '' : ',';
      groupByPropsValue = `${groupByPropsValue}"${cleanupSpecialChars(dimension)}"${comma}`;
    });
    groupByPropsValue += ']}';

    const measure1Measure = cleanupSpecialChars(measure1.measure);
    const measure1StreamName = cleanupSpecialChars(measure1.streamName);
    const measure1Aggregation = cleanupSpecialChars(measure1.aggregation);
    const measure2Measure = cleanupSpecialChars(measure2.measure);
    const measure2StreamName = cleanupSpecialChars(measure2.streamName);
    const measure2Aggregation = cleanupSpecialChars(measure2.aggregation);

    const node1 = getEmptyExpression();
    node1.searchObject.expression.push(getSearchObjectPropertyExpression('what', measure1Measure));
    node1.searchObject.expression.push(
      getSearchObjectOriginExpression(
        '@Stream',
        isStreamRenameEnabled ? measure1.streamId : measure1StreamName,
        isStreamRenameEnabled,
      ),
    );

    if (selectedDims.length) {
      childRoot1 = getRootExpressionWithGroupByFunction(
        aggregationValues[measure1Aggregation],
        groupByPropsValue,
        node1,
      );
      compositeName = `what=${measure1Measure}.func=${aggregationValues[measure1Aggregation]}`;
    } else {
      childRoot1 = getEmptyFunction();
      childRoot1.function = seriesFunction[measure1Aggregation];
      childRoot1.children = [node1];
      compositeName = `what=${measure1Measure}.func=${seriesFunction[measure1Aggregation]}`;
    }
    root = childRoot1;

    if (isRatio) {
      let childRoot2;
      let compositeFuncName = 'ratio';
      const node2 = getEmptyExpression();
      node2.searchObject.expression.push(getSearchObjectPropertyExpression('what', measure2Measure));
      node2.searchObject.expression.push(
        getSearchObjectOriginExpression(
          '@Stream',
          isStreamRenameEnabled ? measure2.streamId : measure2StreamName,
          isStreamRenameEnabled,
        ),
      );

      if (selectedDims.length) {
        childRoot2 = getRootExpressionWithGroupByFunction(
          aggregationValues[measure2Aggregation],
          groupByPropsValue,
          node2,
        );
        root = getRatioPairFunction([childRoot1, childRoot2]);
      } else {
        compositeFuncName = 'divideSeries';
        childRoot2 = getEmptyFunction();
        childRoot2.function = seriesFunction[measure2Aggregation];
        childRoot2.children = [node2];

        root = getEmptyFunction();
        root.function = 'divideSeries';
        root.children = [childRoot1, childRoot2];
      }

      compositeName = `what=${measure1Measure}/${measure2Measure}.func=${compositeFuncName}`;
    }

    const alertDefinition = getDefaultAlertDefinition(
      me,
      generateAlertTitle(measure1, measure2, selectedDims, selectedDirection),
      root,
      'simple-alert',
    );
    alertDefinition.data.expressionTreeModel.name = {
      auto: false,
      prefix: compositeName,
    };

    const alert = new Alert(alertDefinition);
    const subscriberIds = [];
    const tagSubscribers = [];
    const channelsIds = [];
    recipients.forEach((recipient) => {
      if (recipient.type === 'USERS') {
        subscriberIds.push(recipient.value);
      } else {
        channelsIds.push(recipient.value);
      }
    });

    users.forEach((user) => {
      if (subscriberIds.indexOf(user._id) !== -1) {
        tagSubscribers.push({email: user.email, muted: false});
      }
    });

    alert.subscribers = subscriberIds;
    alert.channels = channelsIds;
    alert.tag.subscribers = tagSubscribers;

    const durationCondition = alert.conditions.find((item) => item.type === alertConditionTypes.durationCondition);
    durationCondition.minDuration = selectedDurationValue.seconds;

    const directionCondition = alert.conditions.find((item) => item.type === alertConditionTypes.directionCondition);
    directionCondition.direction = selectedDirection.length === 2 ? 'both' : selectedDirection[0].value;

    alert.rollups = getRollupByTimeScaleAndDurationVal(selectedTimeScale, selectedDurationValue.value);

    const minDeltaCondition = alert.conditions.find((item) => item.type === alertConditionTypes.minDelta);
    minDeltaCondition.deltaDuration = {
      ...minDeltaCondition.deltaDuration,
      rollup: alert.rollups,
      minDuration: selectedDurationValue.seconds,
    };

    return alert;
  },
);

export const getEstimationestimationDateRange = createSelector(
  getSimpleAlertObject,
  getExecuteEstimationData,
  (
    alert,
    // estimationData,
  ) => {
    // let percentMetrics = '';
    // if (estimationData && estimationData.compositeValidation) {
    //   percentMetrics = ` and ${(
    //     (estimationData.totalMetricsAnalyzed * 100) /
    //     estimationData.compositeValidation.totalMetricsOutput
    //   ).toFixed(1)}% of metrics`;
    // }
    // if (alert) {
    //   return `Alert Simulation based on at most ${
    //     getTimeRangeByRollup(alert.rollups).shortText
    //   } of data${percentMetrics}`;
    // }
    if (alert) {
      return `Based on ${getTimeRangeByRollup(alert.rollups).shortText} of data`;
    }
    return '';
  },
);

export const getOpenAlertEstimationTimeAmount = createSelector(
  getAlertConfigurationsItems,
  getSelectedItemId,
  // getExecuteEstimationData,
  (
    alerts,
    selectedAlertId,
    // estimationData,
  ) => {
    // let percentMetrics = '';
    const alert = alerts.find((a) => a.data.id === selectedAlertId);

    // percentMetrics was deleted by request of UX design team.
    // It will be left here for reference and future use

    // if (estimationData && estimationData.compositeValidation) {
    //   percentMetrics = ` and ${(
    //     (estimationData.totalMetricsAnalyzed * 100) /
    //     estimationData.compositeValidation.totalMetricsOutput
    //   ).toFixed(1)}% of metrics`;
    // }
    // if (alert) {
    //   return `Based on ${
    //     getTimeRangeByRollup(alert.rollups).shortText
    //   } of data${percentMetrics}`;
    // }
    if (alert) {
      return `Based on ${getTimeRangeByRollup(alert.rollups).shortText} of data`;
    }
    return '';
  },
);

export const getNameModalData = createSelector(
  getAlertManager,
  (alertManager) => alertManager.nameModal,
);

export const getIsModalOpen = createSelector(
  getAlertsManagementViews,
  (data) => data.simpleAlert.newAlertModalOpen,
);

export const getRetryNameCounter = createSelector(
  getAlertManager,
  (alertManager) => alertManager.retryNameCounter,
);

export const createSimpleAlertDisabledMessage = createSelector(
  getSimpleAlertFirstMeasure,
  getSimpleAlertSecondMeasure,
  getSimpleAlertSelectedDimensions,
  getSimpleAlertIsRatio,
  getSimpleAlertRecipients,
  (measure1, measure2, selectedDims, isRatio, rescip) => {
    if (isEmpty(measure1)) {
      return 'Select a measure to create an alert';
    }
    if (isRatio && isEmpty(measure2)) {
      return 'Select second measure to create a ratio alert';
    }
    if (!rescip || !rescip.length) {
      return 'Add at least one recipient to create an alert';
    }
    return null;
  },
);
